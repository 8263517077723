import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../actions/modalActions";
import "../../../modal/css/localModal.css";
import "../../../modal/css/template.css";

import PayUsingCC from "./PayUsingCC";

import "./CCPayReceiveTest.css";

const cc_setup_test_fee = process.env.REACT_APP_CC_SETUP_TEST_FEE;
const cc_setup_test_description = process.env.REACT_APP_CC_SETUP_DESCRIPTION;

const readMeCode = "0100000000";

class CCPayReceiveTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 1,
      platformFee: 0.01,
      makeCCPayFlag: false,
    };
  }

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };
  handleReturn = async () => {
    this.props.returnToCallingFn();
  };

  handlePayment = async () => {
    await this.setState({
      makeCCPayFlag: true,
    });
  };

  render() {
    // console.log("this.props:", this.props);

    let accIdShow;

    accIdShow = (
      <div className="text-center acc_id">
        <b>
          Pay to Acc Id:&nbsp;
          <font color="#224f99">{this.props.accountId}</font>
        </b>
      </div>
    );

    let testShowPanel;
    // testShowPanel = (
    //   <div className="text-center show_text">
    //     <p align="justify">
    //       You will use $1 for testing. Credit Card service provide (Stripe) will
    //       charge 2.9% + 30c = 59c and Baanda Platform will charge 1c. Rest 40c
    //       will get to your bank account. This test is optional but will give you
    //       a pease of mind about disbursement. Click 'Test-pay' button to provide
    //       CC information and then pay.
    //     </p>
    //   </div>
    // );
    testShowPanel = (
      <div className="text-center show_text">
        <b>Test Pay</b>
        <p align="justify">
          This test is optional but highly recommended. We want to be sure that
          the disbursement of your funds from items you sell to customers on the
          Baanda platform is setup and working correctly before you begin.
        </p>
        <p align="justify">
          Click “Test-Pay’ to provide a personal credit card and pay yourself.
          You will use $1.00 for testing. Our credit card service provider will
          charge 2.9% + $0.30 per transaction and Baanda will charge you $0.01.
        </p>
        <p align="justify">
          If everything is setup correctly, after (within 3 business dayse) you
          should see a $0.40 deposit in your bank account. (Note - need to add
          how often Stripe pays out)
        </p>
      </div>
    );

    let buttonPanel;
    buttonPanel = (
      <div className="text-center">
        <button
          type="button"
          onClick={this.handlePayment}
          className="btn_reg_80"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Test-pay
        </button>
        &nbsp;
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
        &nbsp;
        <button
          type="button"
          onClick={this.handleReturn}
          className="btn_back_main"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
      </div>
    );
    // back-ground
    let cardPanel;
    if (this.state.makeCCPayFlag) {
      testShowPanel = null;
      cardPanel = (
        <div>
          <div className="card-box-cc box-shadow-cc">
            <div className="cc-header-x">Pay with Credit Card</div>
            <PayUsingCC
              amount={this.state.amount}
              stripaccId={this.props.accountId}
              platformFee={parseFloat(cc_setup_test_fee)}
              baandaId={this.props.auth.user.baandaId}
              communityId={this.props.communityId} // Get the community id from props
              description={cc_setup_test_description}
              callingModule="CCPayReceiveTest"
            />
          </div>
        </div>
      );
    }

    let outputPanel = (
      <div>
        {buttonPanel}
        {accIdShow}
        {testShowPanel}
        {cardPanel}
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CCPayReceiveTest.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CCPayReceiveTest));
