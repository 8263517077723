import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios"; 
// import ReactLoading from "react-loading";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

// import ReactLoading from "react-loading";

import "./TaxSetup.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateCatalogItem = "/routes/dashboard/updateCatalogItem";

const readMeCode = "1112111000";

class TaxSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      catalog: null,

      taxableType: "no",
      taxPaidBy: "customer",
      taxMgmtType: "compliant",

      taxCodeList: [],
      avaResult: null,
      loadingFlag: false,
      showTaxCodeFlag: false,
      taxPercentage: 0,

      taxCodeLookVia: "typein",
      taxCode: "",

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    // console.log('this.props.catalog.taxInfo:', this.props.catalog.taxInfo);
    // console.log('this.props.catalog.taxInfo.toBeComplient:', this.props.catalog.taxInfo.toBeComplient)
    let taxableType = 'no';
    if ( this.props.catalog.taxable) taxableType = 'yes';
    await this.setState({
      catalog: this.props.catalog,
      taxPaidBy: this.props.catalog.taxInfo.taxPaidBy,
      taxPercentage: this.props.catalog.taxInfo.manualPercentage,
      taxCode: this.props.catalog.taxInfo.taxCode,
      taxMgmtType: this.props.catalog.taxInfo.toBeComplient,
      taxableType,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  returntoCatalog = () => {
    // console.log('returntoCatalog:', this.state.catalog);
    this.props.returnToCaller(this.state.catalog);
  };

  handleTaxType = async (e) => {
    await this.setState({
      taxableType: e.target.value,
    });
  };


  taxHandlingType = async (e) => {
    let value = e.target.value;
    let showTaxCodeFlag = false;
    if (value === "fullcompliant") {
      showTaxCodeFlag = true;
    }
    await this.setState({
      taxMgmtType: value,
      // loadingFlag: false,
      showTaxCodeFlag,
    });
  };

  handleTaxCodeFindPath = async (e) => {
    await this.setState({
      taxCodeLookVia: e.target.value,
    });
  };

  handleTaxPaidBy = async (e) => {
    await this.setState({
      taxPaidBy: e.target.value,
    });
  };

  // setThisTaxCode = async (obj) => {};

  handleSave = async () => {
    // console.log("To save ...");
    let taxable = false;
    if (this.state.taxableType === "yes") taxable = true;

    let taxInfo = {
      toBeComplient: this.state.taxMgmtType,
      taxPaidBy: this.state.taxPaidBy,
      manualPercentage: parseFloat(this.state.taxPercentage),
      taxCode: this.state.taxCode,
    };

    let coopStoreId = 0;
    if ( this.props.store) {
 
      coopStoreId = this.props.store.coopStoreId;
    }

    let input = {
      communityId: this.state.catalog.communityId,
      coopStoreId,
      itemId: this.state.catalog.itemId,
      updtmode: "taxmgmt",
      taxInfo,
      taxable,
      clientProgram: "ServiceMgmt.js",
      clientFunction: "handleSave",
    };
    let url = baandaServer + updateCatalogItem;
    // console.log("url:", url);
    // console.log("input:", input);

    try {
      let retserv = await axios.post(url, input);
      // console.log("retserv:", retserv);
      if (retserv.data.status === "success") {
        await this.setState({
          catalog: retserv.data.Msg,
          errMsg: "Successfully updated.",
          errFlag: false,
        });
      } else {
        await this.setState({
          errMsg: retserv.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  onChangePrice = async (e) => {
    let value = e.target.value;
    // await this.setState({ [e.target.name]: e.target.value });
    // console.log("e.target.value:", value);

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        itemPrice: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('TaxSetup...');

    // let uploadingSpin;
    // if (this.state.loadingFlag) {
    //   uploadingSpin = (
    //     <div>
    //       <ReactLoading
    //         type={"spokes"}
    //         color={"#237547"}
    //         height={25}
    //         width={25}
    //         className="comm-entry-spinner"
    //       />
    //     </div>
    //   );
    // } else {
    //   uploadingSpin = null;
    // }

    let modalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let buttonPanel;
    buttonPanel = (
      <div className="cat-disc-button-row">
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.handleSave}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>

        <button
          className="btn_back_main"
          type="button"
          onClick={this.returntoCatalog}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
        {modalButton}
      </div>
    );

    let taxHeaderPanel;
    taxHeaderPanel = (
      <div>
        <div className="cat-tax-header">Item's Sales-Tax Setup</div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Item is: &nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="no"
                  checked={this.state.taxableType === "no"}
                  onChange={this.handleTaxType}
                />{" "}
                Not taxable
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="yes"
                  checked={this.state.taxableType === "yes"}
                  onChange={this.handleTaxType}
                />{" "}
                Taxable
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let taxHandlingTypePanel;
    if (this.state.taxableType === "yes") {
      taxHandlingTypePanel = (
        <div className="tax-handling-row">
          <div className="row name-panel-row">
            <div className="col text-center radio-font-style">
              <strong>Sales tax is: &nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="selfmgmt"
                    checked={this.state.taxMgmtType === "selfmgmt"}
                    onChange={this.taxHandlingType}
                  />{" "}
                  Self Managed
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="compliant"
                    checked={this.state.taxMgmtType === "compliant"}
                    onChange={this.taxHandlingType}
                  />{" "}
                  Standard
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="fullcompliant"
                    checked={this.state.taxMgmtType === "fullcompliant"}
                    onChange={this.taxHandlingType}
                    disabled
                  />{" "}
                  Full Compliant (2018 SCOTUS)   
                </label>
              </div>
            </div>
            {/* {uploadingSpin} */}
          </div>
        </div>
      );
    }

    let taxCollectionPanel;
    if (this.state.taxableType === "yes") {
      taxCollectionPanel = (
        <div className="tax-coll-row">
          <div className="row name-panel-row">
            <div className="col text-center radio-font-style">
              <strong>Tax is paid by: &nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="customer"
                    checked={this.state.taxPaidBy === "customer"}
                    onChange={this.handleTaxPaidBy}
                  />{" "}
                  Customer
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="business"
                    checked={this.state.taxPaidBy === "business"}
                    onChange={this.handleTaxPaidBy}
                  />{" "}
                  Business
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let findTaxCodeWay;
    if (this.state.showTaxCodeFlag) {
      findTaxCodeWay = (
        <div className="tax-handling-row">
          <div className="row name-panel-row">
            <div className="col text-center radio-font-style">
              <strong>TaxCode lookup via &nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="cascade"
                    checked={this.state.taxCodeLookVia === "cascade"}
                    onChange={this.handleTaxCodeFindPath}
                    disabled
                  />{" "}
                  Cascade Category
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="keyword"
                    checked={this.state.taxCodeLookVia === "keyword"}
                    onChange={this.handleTaxCodeFindPath}
                    disabled
                  />{" "}
                  Key Word
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="typein"
                    checked={this.state.taxCodeLookVia === "typein"}
                    onChange={this.handleTaxCodeFindPath}
                  />{" "}
                  Type TaxCode
                </label>
              </div>
            </div>
          </div>
          <div className="text-center tax-code-find-via">
            Cascade Category and Key Word search for tax code search-n-select is
            in progress. Partner company is working on providing suitable data
            to make that happen.
          </div>
        </div>
      );
    }

    let getTaxCodePanel;
    if (
      this.state.taxMgmtType === "fullcompliant" &&
      this.state.taxCodeLookVia === "typein"
    ) {
      getTaxCodePanel = (
        <div className="text-center tax-code-entry-row">
          Enter Tax Code&nbsp;&nbsp;
          <input
            name="taxCode"
            type="text"
            value={this.state.taxCode}
            onChange={this.onChange}
            size="10"
            maxLength="10"
            className="tax-code-text"
            placeholder="tax code"
          />&nbsp;Default taxCode: P0000000
        </div>
      );
    }

    let getTaxPercentPanel;
    if (
      this.state.taxableType === "yes" &&
      this.state.taxMgmtType === "selfmgmt"
    ) {
      getTaxPercentPanel = (
        <div className="text-center tax-percent-lbl">
          Tax Percentage&nbsp;
          <input
            name="taxPercentage"
            type="number"
            value={this.state.taxPercentage}
            onChange={this.onChangePrice}
            size="10"
            className="cat-tax-percent"
            step=".01"
            placeholder="0.00"
            autoComplete="off"
          />
        </div>
      );
    }

    // let theListPanel;
    // let bool = true;
    // if (this.state.avaResult && this.state.avaResult.filteredSize > 0) {
    //   theListPanel = (
    //     <div>
    //       <div className="text-center tax-list-summary">
    //         Total rows: <b>{this.state.avaResult.originalSize}</b>
    //         &nbsp;&nbsp;Active rows: <b>{this.state.avaResult.filteredSize}</b>
    //       </div>
    //       <div className="row">
    //         <div className="col-2 tax-row-lbl text-center">ParentCode</div>
    //         <div className="col-1 tax-row-lbl text-center">TypeId</div>
    //         <div className="col-2 tax-row-lbl text-center">TaxCode</div>
    //         <div className="col-6 tax-row-lbl text-center">TaxCode</div>
    //         <div className="col-1 tax-row-lbl text-center">Select</div>
    //       </div>
    //       <div className="fixedsize-code-list-view">
    //         {this.state.avaResult.theCodeList.map((obj, i) => (
    //           <div key={i}>
    //             <div className={`${bool ? "row-color-a" : "row-color-b"}`}>
    //               <div className="row">
    //                 <div className="col-2">{obj.parentTaxCode}</div>
    //                 <div className="col-1">{obj.taxCodeTypeId}</div>
    //                 <div className="col-2">{obj.taxCode}</div>
    //                 <div className="col-6">{obj.description}</div>
    //                 <div className="col-1 text-left">
    //                   <button
    //                     onClick={() => this.setThisTaxCode(obj)}
    //                     className="btn-catalog-tax-open"
    //                     style={{
    //                       cursor: this.state.disabled ? "default" : "pointer",
    //                     }}
    //                   >
    //                     This
    //                   </button>
    //                 </div>
    //               </div>
    //             </div>
    //             {(bool = !bool)}
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   );
    // }

    // let taxCompliantPanel;
    // taxCompliantPanel = <div className="compliant-row"></div>;

    let outputPanel;
    outputPanel = (
      <div className="cat-taxsetup-box">
        {taxHeaderPanel}
        {taxCollectionPanel}
        {taxHandlingTypePanel}
        {/* {theListPanel} */}
        {findTaxCodeWay}
        {getTaxCodePanel}
        {getTaxPercentPanel}
        {buttonPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center cat-tax-msg-err"
              : "text-center cat-tax-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

TaxSetup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TaxSetup));
