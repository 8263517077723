import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";

import {
  GET_ERRORS,
  SET_CURRENT_USER,
  SET_MSG_TRANSFER,
  SET_INITQA_DONE,
  // SET_INITPROFILE_DONE // - intends to use the same as login for jwt token update
} from "./types";
import {
  REGISTER_API_POST,
  LOGIN_API_POST,
  USER_PROFILE_API_POST,
  GET_FRESH_USER_DATA,
} from "./api";

// stripeKey={process.env.REACT_APP_STRIPE_KEY}
const baandaServer = process.env.REACT_APP_BAANDA_SERVER;

// Register User

// export const registerUser = (userData, history) => (dispatch) => {
export const registerUser =  (userData) =>  (dispatch) => {
  // console.log("userData:", userData);
  let url = baandaServer + REGISTER_API_POST;
  // console.log("authAction url:", url);
  axios
    .post(url, userData)
    .then((res) => {
      // console.log("2. register res:", res.data);
      dispatch(setMsgTransfer(res.data));
      return res.data;
    })
    .catch((err) => {
      console.log("authaction err:", err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const loginUser = (userData) => async (dispatch) => {
  // console.log("authAction login userData:", userData);
  let url = baandaServer + LOGIN_API_POST;
  // console.log("url:", url );
  try {
    let res = await axios.post(url, userData);
    const { token } = res.data;
    // console.log("token:", token);
    const decoded = jwt_decode(token);
    // console.log("1. decoded:", decoded);
    decoded.token = token;
    // console.log("2 decoded:", decoded);
    // Set current user
    let setcurruser = await setCurrentUser(decoded);
    // console.log("setcurruser:", setcurruser);
    let scu = {
      avatar: setcurruser.payload.avatar,
      baandaId: setcurruser.payload.baandaId,
      cell: setcurruser.payload.cell,
      email: setcurruser.payload.email,
      iat: setcurruser.payload.iat, // Issued at (datetime)
      isAdmin: setcurruser.payload.isAdmin,
      isInitDone: setcurruser.payload.isInitDone,
      isInitProfileDone: setcurruser.payload.isInitProfileDone,
      name: setcurruser.payload.name,
      isStoreOwner: setcurruser.payload.isStoreOwner,
      token: decoded.token
    };
    let modscu = {
      type: "SET_CURRENT_USER",
      payload: scu,
    };
    // console.log("modscu:", modscu);

    dispatch(modscu);
    // dispatch(setcurruser);
  } catch (err) {
    console.log("authAction loginUser err:", err);
    dispatch({
      type: GET_ERRORS,
      payload: err.response.data,
    });
  }
};

export const getFreshUserData = (baandaId) => (dispatch) => {
  // console.log('getFreshUserData login userData:', userData);
  // console.log("authAction getFreshUserData baandaId:", baandaId);
  let param = "baandaId=" + baandaId;
  let url = baandaServer + GET_FRESH_USER_DATA + param;
  // console.log("getFreshUserData url:", url);
  axios
    .get(url)
    .then((res) => {
      // console.log("Received getFreshUserData response:", res);
      // Save to localStorage
      const { token } = res.data;
      // console.log('getFreshUserData token:', token);
      // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // console.log('etFreshUserData decoded:', decoded);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {
      console.log("authAction getFreshUserData err:", err);
      let errdata;
      if (!err.response && !err.response.data) {
        errdata = "Network issues. Check internet connection.";
      } else errdata = err.response.data;
      dispatch({
        type: GET_ERRORS,
        payload: errdata,
      });
    });
};

export const postUserProfile = (profileData) => (dispatch) => {
  // console.log("inside postUserProfile", profileData);
  let url = baandaServer + USER_PROFILE_API_POST;
  let toUpdateData = {
    baandaid: profileData.user.baandaId,
    profile: profileData.profile,
  };
  // console.log("url:", url);
  // console.log("toUpdateData:", toUpdateData);
  axios
    .post(url, toUpdateData)
    .then((res) => {
      // console.log("Received login response:", res);
      // Save to localStorage
      const { token } = res.data;
      // // Set token to ls
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // // Decode token to get user data
      const decoded = jwt_decode(token);
      // // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) => {
      console.log("authAction postUserProfile err:", err);
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// Set logged in users
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

// Set message transfer from action to caller via reducer
export const setMsgTransfer = (returnMessage) => {
  // console.log("setMsgTransfer: ", returnMessage);
  return {
    type: SET_MSG_TRANSFER,
    payload: returnMessage,
  };
};

export const setQAInitDone = (user) => {
  user.isInitDone = true;

  return {
    type: SET_INITQA_DONE,
    payload: user,
  };
};

// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove the token from localstorage
  localStorage.removeItem("jwtToken");
  // Remove the auth header for future request
  setAuthToken(false);
  // set thecurrent user to {} which will set isAuthenticated  to fales
  dispatch(setCurrentUser({}));
};
