import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import "./BrDefine.css";

import basicComposeTemplate from "./compose/images/basicComp.jpg";
import sliderTemplate from "./compose/images/sliderComp.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveBroadcastInit = "/routes/interact/saveBroadcastInit";
const ifExistsBroadcastName = "/routes/interact/ifExistsBroadcastName?";
const getBroadcasts = "/routes/interact/getBroadcasts?";

class BrDefine extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      broadcastInitType: "new",
      broadcastName: "",
      description: "",
      letterSubject: "",

      broadcastId: 0,
      theBroadcast: null,

      updtMsg: "",
      updtErrflg: false,

      status: "prepmode",
      broadcastNameToFilter: "",
      showTemplateOnPopup: false,

      compTypeheight: 1,
      selectedCompType: "",

      filteredBroadcasts: [],
      selectedBroadcast: null,
      filtered: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    let broadcastId = 0;
    let status = this.state.status;
    let broadcastName = this.state.broadcastName;
    let description = this.state.description;
    let letterSubject = this.state.letterSubject;
    console.log("componentDidMount the.props:", this.props);

    // await this.getFilteredBroadcasts();

    if (this.props.theBroadcast && this.props.theBroadcast.broadcastId) {
      broadcastId = this.props.theBroadcast.broadcastId;
      status = this.props.theBroadcast.status;
      broadcastName = this.props.theBroadcast.broadcastName;
      description = this.props.theBroadcast.description;
      letterSubject = this.props.theBroadcast.letterSubject;
    }
    await this.setState({
      deviceSize,
      theBroadcast: this.props.theBroadcast,
      broadcastId,
      status,
      broadcastName,
      description,
      letterSubject,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleBroadcastInit = async (e) => {
    let value = e.target.value;
    let showFilterPopup = false;
    if (value !== "new") showFilterPopup = true;
    // let status = this.state.status;
    // if ( value === 'republish') status = 'published';
    // else status = 'prepmode';

    let theBroadcast = this.state.theBroadcast;
    let broadcastName = this.state.broadcastName;
    let broadcastId = this.state.broadcastId;
    let description = this.state.description;
    let letterSubject = this.state.letterSubject;
    if (value === "new") {
      theBroadcast = null;
      broadcastName = "";
      broadcastId = 0;
      description = "";
      letterSubject = "";
    }
    await this.setState({
      broadcastInitType: e.target.value,
      showFilterPopup,
      // status,
      theBroadcast,
      broadcastName,
      broadcastId,
      description,
      letterSubject,
    });

    this.props.returnToCaller(this.state.theBroadcast);
  };

  closeFilterPopup = async () => {
    await this.setState({
      showFilterPopup: false,
      broadcastInitType: "new",
      filtered: false,
    });
  };

  broadcastNameIfExists = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&broadcastName=" +
      this.state.broadcastName +
      "&broadcastType=email";
    let url = baandaServer + ifExistsBroadcastName + params;
    console.log("url:", url);
    let doExists = false;
    try {
      let retexist = await axios.get(url);
      console.log("retexist:", retexist);
      if (
        (retexist.data.status === "success") &
        (retexist.data.Msg === "doesnotexist")
      )
        doExists = true;
    } catch (err) {
      console.log("err:", err.message);
    }
    return doExists;
  };

  validateForSave = async () => {
    let isValid = true;
    let msg = "";
    let nameExists = true;
    if (this.state.broadcastInitType === "new") {
      nameExists = await this.broadcastNameIfExists();
      if (!nameExists) msg = "The broadcast name exists; it must be unique.";
    }
    if (this.state.broadcastInitType === "copy") {
      let iof = this.state.broadcastName.indexOf("(copy)");
      if (iof !== -1) {
        nameExists = false;
        msg = "Must rename the copied Broadcast Name to save";
      }
    }
    console.log("nameExists:", nameExists);
    if (!nameExists) {
      isValid = false;
    }

    if (this.state.selectedCompType === "") {
      msg += " Must select composition type to save; ";
      isValid = false;
    }

    return { status: isValid, Msg: msg };
  };

  dBUpdateDataPackage = async () => {
    let input = {
      communityId: this.props.communityId,
      coopStoreId: this.props.coopStoreId,
      communityName: this.props.communityName,
      authorBaandaId: this.props.authorBaandaId,
      authorName: this.props.auth.user.name,
      authorEmail: this.props.auth.user.email,
      authorCell: this.props.auth.user.cell.number,
      broadcastId: this.state.broadcastId,
      broadcastName: this.state.broadcastName,
      description: this.state.description,
      letterSubject: this.state.letterSubject,
      selectedCompType: this.state.selectedCompType,
      broadcastType: "email",
      module: "define",
      clientProgram: "BrDefine.js",
      clientFunction: "saveBroadcast",
    };

    return input;
  };

  saveBroadcast = async () => {
    console.log("Will save");
    try {
      let isValid = await this.validateForSave();
      console.log("isValid:", isValid);

      if (isValid.status) {
        let input = await this.dBUpdateDataPackage();
        let url = baandaServer + saveBroadcastInit;
        console.log(">>> url:", url, " input:", input);
        let brdret = await axios.post(url, input);
        console.log("brdret:", brdret);
        if (brdret.data.status === "success") {
          await this.setState({
            updtMsg: "Successfully Updated",
            updtErrflg: false,
            theBroadcast: brdret.data.Msg,
          });
          this.props.returnToCaller(this.state.theBroadcast);
        } else {
          await this.setState({
            updtMsg: brdret.data.Msg,
            updtErrflg: true,
          });
        }
      } else {
        await this.setState({
          updtMsg: isValid.Msg,
          updtErrflg: true,
        });
      }
    } catch (err) {
      await this.setState({
        updtMsg: err.message,
        updtErrflg: true,
      });
    }
  };

  getFilteredBroadcasts = async () => {
    console.log("in getFilteredBroadcasts");
    let params =
      "broadcastName=" +
      this.state.broadcastNameToFilter +
      "&communityId=" +
      this.props.communityId +
      "&status=" +
      this.state.status +
      "&baandaId=" +
      this.props.auth.user.baandaId +
      "&filterType=" +
      this.state.broadcastInitType;
    let url = baandaServer + getBroadcasts + params;
    console.log("getFilteredBroadcasts url:", url);
    try {
      let getbrd = await axios.get(url);
      console.log("getbrd:", getbrd);
      if (getbrd.data.status === "success") {
        await this.setState({
          filteredBroadcasts: getbrd.data.Msg,
          filtered: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  handleABroadcast = async (brd) => {
    console.log("handleABroadcast brd:", brd);
    console.log("this.state:", this.state);
    // if (this.state.broadcastInitType === "copy") {
    //   brd.broadcastName = brd.broadcastName + " (copy)";
    //   brd.broadcastId = 0;
    //   await this.setState({
    //     theBroadcast: brd,
    //     broadcastName: brd.broadcastName,
    //     description: brd.description,
    //     letterSubject: brd.letterSubject,
    //     showFilterPopup: false,
    //     broadcastId: 0,
    //     filteredBroadcasts: [],
    //     filtered: false,
    //   });
    // }
    // if (
    //   this.state.broadcastInitType === "wip" ||
    //   this.state.broadcastInitType === "republish"
    // ) {
    let broadcastName = brd.broadcastName;
    let broadcastId = brd.broadcastId;
    await this.setState({
      theBroadcast: brd,
      broadcastName,
      description: brd.description,
      letterSubject: brd.letterSubject,
      showFilterPopup: false,
      broadcastId,
      filteredBroadcasts: [],
      filtered: false,
    });
    // }

    this.props.returnToCaller(this.state.theBroadcast);
  };

  handleCompTypeSelected = async () => {
    // console.log("in handleCompTypeSelected");
    let value = document.getElementById("compTypeSelect").value;
    console.log("value:", value);
    let selectedCompType = value;

    await this.setState({
      compTypeheight: 1,
      selectedCompType,
    });
  };

  onfocus = async () => {
    await this.setState({
      compTypeheight: 5,
    });
  };

  onblur = async () => {
    await this.setState({
      compTypeheight: 1,
    });
  };

  viewTemplate = () => {
    console.log("In view ...");
    this.setState({
      showTemplateOnPopup: true,
    });
  };

  exitFromPopup = () => {
    this.setState({
      showTemplateOnPopup: false,
    });
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    let selectionPanel;
    selectionPanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Select an action&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="new"
                checked={this.state.broadcastInitType === "new"}
                onChange={this.handleBroadcastInit}
              />{" "}
              New (from scratch)
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="existing"
                checked={this.state.broadcastInitType === "existing"}
                onChange={this.handleBroadcastInit}
              />{" "}
              Existing
            </label>
          </div>
        </div>
      </div>
    );

    let buttonSavePanel = (
      <div>
        <button
          className="btn_reg_60 "
          type="button"
          onClick={this.saveBroadcast}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>
      </div>
    );

    let buttonViewTemplate = (
      <div>
        <button
          className="btn_reg_80 "
          type="button"
          onClick={this.viewTemplate}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          View Type
        </button>
      </div>
    );

    let compTypePanel;
    compTypePanel = (
      <div>
        <select
          size={this.state.compTypeheight}
          onFocus={this.onfocus}
          onBlur={this.onblur}
          id="compTypeSelect"
          name="compTypeSelect"
          onChange={this.handleCompTypeSelected}
          onSelect={this.handleCompTypeSelected}
          className="composition_select_type"
          defaultValue={"DEFAULT"}
        >
          <option className="" value="DEFAULT" disabled>
            Select composition type
          </option>
          <option className="" value="basic">
            Basic composition
          </option>
          <option className="" value="newsletter">
            News Letter
          </option>
          <option className="" value="slider">
            Slider
          </option>
          <option className="" value="video">
            Video
          </option>
        </select>
      </div>
    );

    let newPanel;
    // if (this.state.broadcastInitType === "new" || this.state.broadcastInitType === "copy") {
    newPanel = (
      <div>
        {this.state.theBroadcast ? (
          <div className="row broadcast-name-row">
            <div className="col-3 text-right broadcast-def-lbl">
              Campaign Name:
            </div>
            <div className="col-9 text-left">
              {this.state.theBroadcast.broadcastName}
            </div>
          </div>
        ) : (
          <div className="row broadcast-name-row">
            <div className="col-3 text-right broadcast-def-lbl">
              Campaign Name:
            </div>
            <div className="col-9 text-left">
              <input
                name="broadcastName"
                type="text"
                value={this.state.broadcastName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="broadcast-name-inp"
                placeholder="Enter unique broadcast name"
              />
            </div>
          </div>
        )}

        <div className="text-center broadcast-define-chars">
          (Allowed 500 chars) &nbsp;&nbsp;Used:&nbsp;
          {this.state.description.length}
        </div>
        <div className="row broadcast-name-row">
          <div className="col-3 text-right broadcast-def-lbl">Description:</div>
          <div className="col-9 text-left">
            <textarea
              name="description"
              maxLength="1000"
              placeholder="Description - internal."
              rows="3"
              wrap="hard"
              spellCheck="true"
              className="broadcast-define-descrition"
              onChange={this.onChange}
              value={this.state.description}
              required
            />
          </div>
        </div>
        <div className="row broadcast-name-row">
          <div className="col-3 text-right broadcast-def-lbl">
            Letter Subject:
          </div>
          <div className="col-9 text-left">
            <input
              name="letterSubject"
              type="text"
              value={this.state.letterSubject}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="broadcast-name-inp"
              placeholder="Subject of the broadcast letter"
            />
          </div>
        </div>
        <div className="row br-define-comptype-pos">
          <div className="col-6 text-right">{ this.state.theBroadcast ? "" : compTypePanel}</div>
          <div className="col-3 text-right"> { this.state.theBroadcast ? "" :buttonViewTemplate}</div>
          <div className="col-3 text-left">{buttonSavePanel}</div>
        </div>
        {/* <div className="text-center br-define-save-btn-pos">
          {buttonSavePanel}
        </div> */}
        <div
          className={
            this.state.updtErrflg
              ? "text-center brd-define-msg-err"
              : "text-center brd-define-msg"
          }
        >
          {this.state.updtMsg}
        </div>
      </div>
    );
    // }

    let closeFilterPanel;
    closeFilterPanel = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.closeFilterPopup}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let goSearchButton;
    goSearchButton = (
      <button
        className="btn_reg_40"
        onClick={this.getFilteredBroadcasts}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search" />
      </button>
    );

    let backButton = (
      <button
        className="btn_back_main comp-back-btn"
        type="button"
        onClick={this.exitFromPopup}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let searchOutputPanel;
    let bool = true;
    if (this.state.filteredBroadcasts.length > 0) {
      searchOutputPanel = (
        <div className="text-center">
          <div className="brd-filtered-box">
            <div>
              {this.state.filteredBroadcasts.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={`${
                        bool
                          ? "row-existing-campaign-dr"
                          : "row-existing-campaign-lt"
                      }`}
                    >
                      <div className="row">
                        <div className="col-9 text-left broadcast-filt-name-lbl">
                          {obj.broadcastName}
                        </div>
                        <div className="col-3 text-center">
                          <button
                            className="btn_reg_30_small"
                            type="button"
                            onClick={() => this.handleABroadcast(obj)}
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                          >
                            <i className="fa fa-folder-open" />
                          </button>
                        </div>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    } else if (this.state.filtered) {
      searchOutputPanel = (
        <div className="text-center">
          <div className="brd-filtered-box">
            <div className="text-center broadcast-no-records-found">
              No records found for&nbsp;{this.state.broadcastInitType}
            </div>
          </div>
        </div>
      );
    }

    let filterNameInputpanel;
    filterNameInputpanel = (
      <div>
        <div className="row broadcast-name-row">
          <div className="col-3 text-right broadcast-filter-lbl">
            Campaigns:
          </div>
          <div className="col-9 text-left">
            <input
              name="broadcastNameToFilter"
              type="text"
              value={this.state.broadcastNameToFilter}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="broadcast-filter-name-inp"
              placeholder="Enter part/full broadcast name"
            />{" "}
            &nbsp;{goSearchButton}
          </div>
        </div>
        {searchOutputPanel}
      </div>
    );

    let showFilterPanel;
    if (this.state.showFilterPopup) {
      let header = "Existing Broadcasts";
      showFilterPanel = (
        <div className="brd-define-filter-popup">
          <div className="text-center brd-def-filter-header">
            {header}&nbsp;&nbsp;{closeFilterPanel}
          </div>
          <div>{filterNameInputpanel}</div>
        </div>
      );
    }

    let showTemplatePanel;
    if (this.state.showTemplateOnPopup) {
      let temp;
      if (this.state.selectedCompType === "basic") temp = basicComposeTemplate;
      if (this.state.selectedCompType === "slider") temp = sliderTemplate;
      // if (this.state.popSelectedTemplate === "template3") temp = template3;
      // if (this.state.popSelectedTemplate === "template4") temp = template4;

      showTemplatePanel = (
        <div className="show-comp-template-box">
          <div className="template-pop-header text-center">
            Template: {this.state.selectedCompType.toUpperCase()}&nbsp;&nbsp;
            {backButton}
          </div>
          <div className="text-center pop-image-placement">
            <img src={temp} alt="Template 1" hight="600px" width="350px" />
          </div>
        </div>
      );
    }

    let activePanel;
    // if (this.state.broadcastInitType) {
    activePanel = (
      <div>
        {newPanel}
        {showFilterPanel}
        {showTemplatePanel}
      </div>
    );
    // }

    let outputPanel = (
      <div>
        <div className="text-ecnter define-header">
          Email Broadcast Initiation
        </div>
        {selectionPanel}
        {activePanel}
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

BrDefine.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// const mapDispatchToProps = (dispatch) => ({
//   hideModal: () => dispatch(hideModal()),
//   showModal: (modalProps, modalType) => {
//     dispatch(showModal({ modalProps, modalType }));
//   },
// });

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(withRouter(BrDefine));
