import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
 
import CustomerMain from "./customer/CustomerMain";
import WorkforceRouter from "../workforce/WorkforceRouter";

import "./ActionMain.css";

class ActionMain extends Component {
  constructor(props) {
    super(props);

    this.state = {

      authorizedFuncs: [],
      deviceSize: "small",

      workforceActionFlag: false,
      customerFlag: false,
      projectFlag: false, // To be used in the release 2.

      stuff: "abcd",
      role: "",
    };
  }

  componentDidMount = async () => {
    // console.log("in ActionMain...");
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setAccessTraffic();

    await this.setState({
      authorizedFuncs: this.props.authorizedFuncs,
      deviceSize,
 
    });
  };

  setAccessTraffic = async () => {
    let workforceActionFlag = false;
    let customerFlag = false;

    if (this.props.comingFrom === "accesslist") {
      if (this.props.isMember) workforceActionFlag = true;
    } else {
      if (this.props.isCustomer) customerFlag = true;
    }

    this.setState({
      workforceActionFlag,
      customerFlag,
    });
  };

  render() {
    // console.log("ActionMain props :", this.props);
    // console.log("ActionMain states:", this.state);
    console.log('ActionMain...');

    let actionLandingButtons;
    if (this.state.workforceActionFlag) {
      actionLandingButtons = (
        <div className="text-center market_header_spacing_member">
          Community Member Ops
        </div>
      );
    }
    if (this.state.customerFlag) {
      actionLandingButtons = (
        <div className="text-center market_header_spacing_customer">
          Customer Ops
        </div>
      );
    }

    let outputPanel;

    if (this.state.projectFlag) {
      // console.log("In here ... PM");
      return (
        <Redirect
          to={`/projects/${this.props.communityid}/${this.state.stuff}`}
        />
      );
      // return (<Redirect to={`/projects`} />);
    }

    if (this.state.customerFlag) {
      let store = null;
      if ( this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <div className="action-landing-buttons">{actionLandingButtons}</div>
          <CustomerMain
            isCustomer={this.props.isCustomer}
            communityId={this.props.communityId}
            community={this.props.community}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    if (this.state.workforceActionFlag) {
      let store = null;
      if ( this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <div className="action-landing-buttons">{actionLandingButtons}</div>
          <WorkforceRouter
            isCustomer={this.props.isCustomer}
            communityId={this.props.communityId}
            community={this.props.community}
            isMember={this.props.isMember}
            role={this.props.role}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

ActionMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// const mapDispatchToProps = (dispatch) => ({
//   hideModal: () => dispatch(hideModal()),
//   showModal: (modalProps, modalType) => {
//     dispatch(showModal({ modalProps, modalType }));
//   },
// });

export default withRouter(connect(mapStateToProps)(ActionMain));
