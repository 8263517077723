import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import axios from "axios";
import moment from "moment";

import "./ScheduleToAttend.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAnEvent = "/routes/inetract/getAnEvent?";

const readMeCode = "1112211000";

class ScheduleToAttend extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      errMsg: "",
      errFlag: false,

      schMsg: "",

      maxAllowed: 0,
      toSelectFrom: [],
      selected: [],
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let tsel = [];
    let maxAllowed = 0;
    for (var i = 0; i < this.props.taggedByEvent.length; i++) {
      // console.log(i, '. this.props.taggedByEvent[i].eventId:', this.props.taggedByEvent[i].eventId)
      let tevent = await this.getAnEventByEventId(
        this.props.taggedByEvent[i].eventId
      );
      // console.log(i, ' tevent:', tevent);

      if (tevent.status === "success") {
        for (var j = 0; j < tevent.Msg.services.publishedShifts.length; j++) {
          // console.log(j, '. tevent.Msg.services.publishedShifts[j]: ', tevent.Msg.services.publishedShifts[j])
          if (
            moment(
              tevent.Msg.services.publishedShifts[j].shiftDate
            ).isSameOrAfter(moment())
          ) {
            // if (
            //   tevent.Msg.services.publishedShifts[j].participants.length <
            //   tevent.Msg.services.maxRecipantAllowed
            // ) {
              let tselObj = tevent.Msg.services.publishedShifts[j];
              tselObj.eventId = tevent.Msg.eventId;
              tselObj.maxRecipantAllowed =
                tevent.Msg.services.maxRecipantAllowed;

              // tsel.push(tevent.Msg.services.publishedShifts[j]);
              tsel.push(tselObj);
              maxAllowed = tevent.Msg.services.maxRecipantAllowed;
            // }
          }
        }
      }
    }
    let schMsg;
    if (deviceSize === "big") {
      schMsg =
        "Note: For limited slots, there is a possibility that by the time you checkout, the slots may be filled. You will be notified if that happens during the checkout process.";
    } else {
      schMsg =
        "Note: There is a possibility that by the time you checkout, the slots may fill out. You will be notified then.";
    }

    if (tsel.length === 0) {
      schMsg = "No vaccancy. All slots are filled out.";
    } else {
      let seq = 1;

      tsel.forEach((obj) => {
        obj.seqNo = seq;
        seq++;
      });
    }
    await this.setState({
      toSelectFrom: tsel,
      schMsg,
      maxAllowed,
      deviceSize,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getAnEventByEventId = async (eventId) => {
    let param = "eventId=" + eventId;
    let url = baandaServer + getAnEvent + param;

    let Msg = null,
      status = "success",
      errMsg = "",
      errFlag = false;
    try {
      let retit = await axios.get(url);

      if (retit.data.status === "success") {
        if (retit.data.Msg) {
          Msg = retit.data.Msg;
          errMsg = "";
          errFlag = false;
        } else {
          Msg = null;
          errMsg = "No document/record found for eventId: " + eventId;
          errFlag = true;
          status = "error";
        }
      } else {
        Msg = null;
        errMsg = retit.data.Msg + " for eventId: " + eventId;
        errFlag = true;
        status = "error";
      }
    } catch (err) {
      console.log("err:", err.message);
      Msg = null;
      errMsg = err.message + " for eventId: " + eventId;
      errFlag = true;
      status = "error";
    }

    return { status, Msg, errMsg, errFlag };
  };

  returnToOriginExit = () => {
    this.props.returnToCaller(null);
  };

  returnToOriginDone = () => {
    this.props.returnToCaller(this.state.selected);
  };

  handleSelect = async (obj) => {
    let toself = [];
    let seltd = [...this.state.selected];
    this.state.toSelectFrom.forEach((elm1) => {
      if (elm1.seqNo !== obj.seqNo) toself.push(elm1);
      else seltd.push(elm1);
    });

    await this.setState({
      toSelectFrom: toself,
      selected: seltd,
    });
  };

  handleRemove = async (obj) => {
    let toself = [...this.state.toSelectFrom];
    let seltd = [];
    this.state.selected.forEach((elm1) => {
      if (elm1.seqNo !== obj.seqNo) seltd.push(elm1);
      else toself.push(elm1);
    });

    await this.setState({
      toSelectFrom: toself,
      selected: seltd,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ScheduleToAttend...');

    let sel, min;
    if (this.state.deviceSize === "small") {
      sel = "Sel";
      min = "Mins";
    } else {
      sel = "Select";
      min = "Minutes";
    }

    let buttonPanel = (
      <div className="cat-sch-button-row">
        <button
          className="btn_reg_50"
          type="button"
          onClick={this.returnToOriginDone}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>
        <button
          className="btn_back_main"
          type="button"
          onClick={this.returnToOriginExit}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
      </div>
    );

    // let slots;
    // if (this.state.deviceSize === "small ") {
    //   slots = "M-S:";
    // } else {
    //   slots = "Max Slots:";
    // }

    let showAvailableSchedules;
    let bool = true;
    if (this.state.toSelectFrom.length > 0) {
      if (this.state.deviceSize === "big") {
        showAvailableSchedules = (
          <div className="">
            {/* <div className="">
              <div className="row cat-sch-available-row">
                <div className="col-7 text-center cat-sch-available-lbl">
                  Availablity
                </div>
                <div className="col-3 text-right cat-sch-available-data-lbl">
                  {slots}&nbsp;{this.state.maxAllowed}
                </div>
              </div>
            </div> */}
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-2 text-right sch-srv-sel">{sel}</div>
                  <div className="col-4 text-center sch-provider-header-lbl">
                    Date
                  </div>
                  <div className="col-3 text-center sch-provider-header-lbl">
                    Starts at
                  </div>
                  <div className="col-2 text-center sch-provider-header-lbl">
                    {min}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-1 text-center sch-provider-header-srv-lbl">
                    Bk/Av
                  </div>
                  <div className="col-11 text-center sch-provider-header-srv-lbl">
                    Servers
                  </div>
                </div>
              </div>
            </div>
            <div className="service-scheduling-box">
              {this.state.toSelectFrom.map((obj, i) => {
                let servers = "";
                obj.serviceProviders.forEach((elm) => {
                  servers = servers + elm.name + "; ";
                });
                return (
                  <div key={i}>
                    <div
                      className={`${bool ? "row-color-dr" : "row-color-lt"}`}
                    >
                      <div className="row">
                        <div className="col-md-6 text-left">
                          <div className="row">
                            <div className="col-3 text-center sch-provider-lbl">
                              {this.state.maxAllowed >
                              obj.participants.length ? (
                                <button
                                  className="btn_reg_30"
                                  type="button"
                                  onClick={() => this.handleSelect(obj)}
                                  style={{
                                    cursor: this.state.disabled
                                      ? "default"
                                      : "pointer",
                                  }}
                                >
                                  <i className="fa fa-check" />
                                </button>
                              ) : (
                                <button
                                  className="btn_reg_30_disabled"
                                  type="button"
                                >
                                  <i className="fa fa-check" />
                                </button>
                              )}
                            </div>
                            <div className="col-4 text-center sch-provider-lbl">
                              {obj.shiftDateString}
                            </div>
                            <div className="col-3 text-center sch-provider-lbl">
                              {obj.startTimeStr}
                            </div>
                            <div className="col-2 text-center sch-provider-lbl">
                              {obj.duration}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 text-center sch-provider-srv-lbl">
                          <div className="row">
                            <div className="col-2 text-center cat-sch-bk-data">
                              {obj.participants.length}/{obj.maxRecipantAllowed}
                            </div>
                            <div className="col-10 text-center">{servers}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        showAvailableSchedules = (
          <div className="">
            <div className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-1 text-left sch-srv-sel">{sel}</div>
                  <div className="col-4 text-left sch-provider-header-lbl">
                    Date
                  </div>
                  <div className="col-4 text-left sch-provider-header-lbl">
                    Starts at
                  </div>
                  <div className="col-3 text-left sch-provider-header-lbl">
                    {min}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-1 text-left sch-srv-sel">&nbsp;</div>
                  <div className="col-3 text-right sch-provider-header-srv-lbl">
                    Bk/Av
                  </div>
                  <div className="col-8 text-center sch-provider-header-srv-lbl">
                    Servers
                  </div>
                </div>
              </div>
            </div>
            <div className="service-scheduling-box">
              {this.state.toSelectFrom.map((obj, i) => {
                let servers = "";
                obj.serviceProviders.forEach((elm) => {
                  servers += elm.name + "; ";
                });
                return (
                  <div key={i}>
                    <div
                      className={`${bool ? "row-color-dr" : "row-color-lt"}`}
                    >
                      <div className="row">
                        <div className="col-md-6 text-left">
                          <div className="row">
                            <div className="col-1 text-center sch-provider-lbl">
                              {this.state.maxAllowed >
                              obj.participants.length ? (
                                <button
                                  className="btn_reg_30 cat-schedule-btn-pos"
                                  type="button"
                                  onClick={() => this.handleSelect(obj)}
                                  style={{
                                    cursor: this.state.disabled
                                      ? "default"
                                      : "pointer",
                                  }}
                                >
                                  <i className="fa fa-check" />
                                </button>
                              ) : (
                                <button
                                  className="btn-sch-sel-full"
                                  type="button"
                                >
                                  <i className="fa fa-check" />
                                </button>
                              )}
                            </div>
                            <div className="col-4 text-center sch-provider-lbl">
                              {obj.shiftDateString}
                            </div>
                            <div className="col-3 text-center sch-provider-lbl">
                              {obj.startTimeStr}
                            </div>
                            <div className="col-2 text-center sch-provider-lbl">
                              {obj.duration}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 text-center cat-scheule-small-row-pos">
                          <div className="row">
                            <div className="col-1 text-left sch-srv-sel">
                              &nbsp;
                            </div>
                            <div className="col-3 text-right sch-provider-header-srv-lbl">
                              {obj.participants.length}/{obj.maxRecipantAllowed}
                            </div>
                            <div className="col-8 text-center sch-provider-header-srv-lbl">
                              {servers}
                            </div>
                          </div>
 
                        </div>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }
    }

    let bool1 = true;
    let selectedPanel;
    if (this.state.selected.length > 0) {
 
      selectedPanel = (
        <div>
          <div className="text-center selected-panel-header">Selected</div>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                <div className="col-2 text-left sch-srv-sel">{sel}</div>
                <div className="col-4 text-center sch-provider-header-lbl">
                  Date
                </div>
                <div className="col-3 text-center sch-provider-header-lbl">
                  Starts at
                </div>
                <div className="col-2 text-center sch-provider-header-lbl">
                  {min}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="text-center sch-provider-header-srv-lbl">
                Servers
              </div>
            </div>
          </div>
          <div className="service-scheduling-box">
            {this.state.selected.map((obj, i) => {
              let servers = "";
              obj.serviceProviders.forEach((elm) => {
                servers += elm.name + "; ";
              });
              return (
                <div key={i}>
                  <div className={`${bool1 ? "row-color-dr" : "row-color-lt"}`}>
                    <div className="row">
                      <div className="col-md-6 text-left">
                        <div className="row">
                          <div className="col-3 text-center ">
                            <button
                              className="btn_exit_del sch-provider-del-btn"
                              type="button"
                              style={{
                                cursor: this.state.disabled
                                  ? "default"
                                  : "pointer",
                              }}
                              onClick={() => this.handleRemove(obj)}
                            >
                              <i className="fa fa-times" />
                            </button>
                          </div>
                          <div className="col-4 text-center sch-provider-lbl">
                            {obj.shiftDateString}
                          </div>
                          <div className="col-3 text-center sch-provider-lbl">
                            {obj.startTimeStr}
                          </div>
                          <div className="col-2 text-center sch-provider-lbl">
                            {obj.duration}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 text-center sch-provider-srv-lbl">
                        <div className="row">
                          <div className="col">{servers}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(bool1 = !bool1)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        {showAvailableSchedules}
        {selectedPanel}
        <div className="text-center service-scheduling-msg">
          {this.state.schMsg}
        </div>
      </div>
    );
    return (
      <div>
        <div className="text-center cat-schedule-top-btn-pos">
          {buttonPanel}
        </div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ScheduleToAttend.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScheduleToAttend));
