import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import axios from "axios";

import CommunityDirectory from "./directory/CommunityDirectory";
import FinancialSummary from "./finSummary/FinancialSummary";
import InvoiceCommunity from "./invoice/InvoiceCommunity";
import CustomerList from "./customer/CustomerList";
import MyPaymentList from "./mypayments/MyPaymentList";
import GeneralLedger from "./gl/GeneralLedger";
import ExpenseList from "./expense/ExpenseList";
import GoodsInventory from "./inventory/GoodsInventory";

import ModalContainer from "../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../actions/modalActions";
import "../../modal/css/localModal.css";
import "../../modal/css/template.css";

import "./ReportLanding.css";

const reports = [
  // { name: "Community directory", access: "all" },
  { name: "Community Account Summary", access: "reportfinance" },
  { name: "Customer Information", access: "reportfinance" },
  { name: "Invoices", access: "reportinvoice" },
  { name: "Inventory", access: "reportinvoice" },
  // { name: "My income & payments received", access: "reportgeneral" },
  { name: "Payment History", access: "reportfinance" },
  // { name: "Receivables", access: "reportfinance" },
  // { name: "Events Log", access: "reportgeneral" },
  { name: "Expense Reports", access: "reportfinance" },
  // { name: "Shippng Reports", access: "reportgeneral" },
  { name: "General Ledger", access: "reportfinance" },
];

class ReportLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      availableReports: [],

      message: "",

      showDirectory: false,
      showFinancialSummary: false,
      showReportList: true,
      showInventory: false,
      showInvoice: false,
      showCustomer: false,
      showMyPayment: false,
      showGeneralLedger: false,
      showExpense: false
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setAccessableReports();

    await this.setState({
      deviceSize,

      showDirectory: false,
    });
  };

  setAccessableReports = async () => {
    let availableReports = [];

    let cd = { name: "Community directory", access: "all" };
    availableReports.push(cd);

    reports.forEach((rep) => {
      if ( this.props.store) {
        if ( rep.name !== 'Community Account Summary') {
          if (this.props.authorizedFuncs.includes(rep.access)) {
            availableReports.push(rep);
          }
        }
      } else {
        if (this.props.authorizedFuncs.includes(rep.access)) {
          availableReports.push(rep);
        }
      }

    });

    this.setState({
      availableReports,
    });
  };

  selectThisReport = async (obj) => {
    // console.log("obj:", obj);
    let showDirectory = false;
    let showFinancialSummary = false;
    let showInvoice = false;
    let showInventory = false;
    let showCustomer = false;
    let showMyPayment = false;
    let showGeneralLedger = false;
    let showExpense = false;
    if (obj.name === "Community directory") showDirectory = true;

    if (obj.name === "Community Account Summary") showFinancialSummary = true;

    if (obj.name === "Invoices") showInvoice = true;
    if (obj.name === 'Inventory') showInventory = true;
    if (obj.name === "Customer Information") showCustomer = true;
    if (obj.name === "Payment History") showMyPayment = true;
    if (obj.name === "General Ledger") showGeneralLedger = true;
    if ( obj.name === "Expense Reports" ) showExpense = true;

    this.setState({
      showDirectory,
      showFinancialSummary,
      showInvoice,
      showInventory,
      showCustomer,
      showMyPayment,
      showGeneralLedger,
      showExpense
    });
  };

  returnFromAReport = async () => {
    this.setState({
      showReportList: true,
      showDirectory: false,
      showFinancialSummary: false,
      showInvoice: false,
      showInventory: false,
      showCustomer: false,
      showGeneralLedger: false,
      showMyPayment: false,
      showExpense: false
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ReportLanding...')

    let reportListPanel;
    let bool = true;
    if (this.state.availableReports.length > 0) {
      reportListPanel = (
        <div className="text-center">
          <div className="row sub-item-list-row">
            <div className="col-3 text-center sub-item-list-head">
              Operations
            </div>
            <div className="col-9 text-left sub-item-list-head">
              Report Names
            </div>
          </div>
          <div className="fixedsize-reports-list">
            {this.state.availableReports.map((obj, i) => {
              // console.log("690 obj:", obj);

              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-3 text-center">
                      <button
                        className="btn-sub-line-edit"
                        type="button"
                        onClick={() => this.selectThisReport(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Select
                      </button>
                    </div>
                    <div className="col-9 text-left report-names-lbl">
                      {obj.name}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
          <div className="text-center report-list-msg">
            {this.state.message}
          </div>
        </div>
      );
    }

    let reportHeader = (
      <div className="text-center mem-report-header">Reports</div>
    );

    let outputPanel;
    if (this.state.showReportList) {
      outputPanel = (
        <div className="report-list-box">
          {reportHeader}
          {reportListPanel}
        </div>
      );
    }

    let store = null;
    if (this.props.store) store = this.props.store;

    if (this.state.showDirectory) {
      outputPanel = (
        <div>
          <CommunityDirectory
            returnToCaller={this.returnFromAReport}
            communityId={this.props.communityId}
            community={this.props.community}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showFinancialSummary) {
      outputPanel = (
        <div>
          <FinancialSummary
            returnToCaller={this.returnFromAReport}
            communityId={this.props.communityId}
            community={this.props.community}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showInvoice) {
      outputPanel = (
        <div>
          <InvoiceCommunity
            returnToCaller={this.returnFromAReport}
            communityId={this.props.communityId}
            community={this.props.community}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showInventory) {
      outputPanel = (
        <div>
          <GoodsInventory
            returnToCaller={this.returnFromAReport}
            communityId={this.props.communityId}
            community={this.props.community}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showCustomer) {
      outputPanel = (
        <div>
          <CustomerList
            returnToCaller={this.returnFromAReport}
            communityId={this.props.communityId}
            community={this.props.community}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showMyPayment) {
      outputPanel = (
        <div>
          <MyPaymentList
            returnToCaller={this.returnFromAReport}
            communityId={this.props.communityId}
            community={this.props.community}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showGeneralLedger) {
      outputPanel = (
        <div>
          <GeneralLedger
            returnToCaller={this.returnFromAReport}
            communityId={this.props.communityId}
            community={this.props.community}
            store={store}
          />
        </div>
      );
    }

    if ( this.state.showExpense) {
      outputPanel = (
        <div>
          <ExpenseList
            returnToCaller={this.returnFromAReport}
            communityId={this.props.communityId}
            community={this.props.community}
            store={store}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ReportLanding.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReportLanding)
);
