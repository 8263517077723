import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import axios from "axios";

import { hideModal } from "../../../actions/modalActions";
import "../../css/localModal.css";

import "./ShowHelp.css";

const helpVideoPoster = process.env.REACT_APP_HELP_VIDEO_POSTER;

const passCode = process.env.REACT_APP_INFO_SERVER_CODE;
const baandaHelpServer = process.env.REACT_APP_BAANDA_HELP_SERVER;
const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getHelpInfo = "/routes/help/getHelpInfo?";
const isBaandaAdmin = "/routes/admin/isBaandaAdmin?";

class ShowHelp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todotext: "",
      deviceSize: "",

      inputParam: null,

      helpInfo: null,

      errMsg: "",
      errFlag: false,
      isBaandaAdmin: "no",
    };

    this.closeModal = this.closeModal.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    // console.log('In ShowHelp component did mount');
    let tp = JSON.stringify(this.props);
    // console.log('tp:', tp);
    let ts = JSON.parse(tp);
    // console.log('ts:', ts);
    await this.checkIfBaandaAdmin(this.props.auth.user.email);
    await this.getInfoData(ts.infoId);
    await this.setState({
      inputParam: ts,
      deviceSize,
    });
  };

  checkIfBaandaAdmin = async (email) => {
    // console.log("email:", email);
    let param = "email=" + email;
    let url = baandaServer + isBaandaAdmin + param;
    // console.log("url:", url);
    try {
      let adret = await axios.get(url);
      // console.log("adret:", adret);
      // console.log("adret.data.Msg:", adret.data.Msg);

      if (adret.data.status === "success") {
        // console.log("adret.data.Msg.auth:", adret.data.Msg.auth);

        await this.setState({
          isBaandaAdmin: adret.data.Msg.msg,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  getInfoData = async (infoId) => {
    // console.log("getInfoData infoId:", infoId);
    let params = "passCode=" + passCode + "&infoId=" + infoId;
    let url = baandaHelpServer + getHelpInfo + params;
    try {
      let info = await axios.get(url);
      // console.log("info:", info);
      if (info.data.status === "success") {
        await this.setState({
          helpInfo: info.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        await this.setState({
          helpInfo: null,
          errMsg:
            "This SHOULD NOT HAPPEN IN PRODUCTION. Check the infoId : " +
            infoId +
            " . Test whether this infoId based content has not been entered by Baanda Admin ... OR... there is a type on the readMeCode on top of the code/program that is calling this help (i).",
          errFlag: true,
        });
      }
    } catch (err) {
      await this.setState({
        errMsg:
          err.message + " Please contact Baanda support at jit@baanda.com.",
        errFlag: true,
      });
    }
  };

  closeModal(e) {
    e.preventDefault();
    // console.log("closeModal.func : " + this.state.todotext);
    const modTask = {
      todotext: this.state.todotext,
    };

    this.props.hideModal(modTask);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    // console.log("props ShowHelp:" + JSON.stringify(this.props));
    // console.log("this.state:", this.state);
    console.log("ShowHelp ...");

    let closeButton = (
      <button
        type="button"
        className="btn-close-modal"
        onClick={this.closeModal}
        onChange={this.onChange}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        X
      </button>
    );

    let seeVideoPanel;
    let isVideo = false;
    let imagePanel;
    let isImage = false;

    if (this.state.helpInfo && this.state.helpInfo.fileUploads.length > 0) {
      this.state.helpInfo.fileUploads.forEach((obj) => {
        if (obj.type === "image" && obj.seqno === 1) {
          // Image for large screen
          isImage = true;
          imagePanel = (
            <div>
              <img
                src={obj.location}
                alt="pic"
                className="show-help-img-large"
              />
            </div>
          );
        }

        if (obj.type === "video") {
          isVideo = true;
          seeVideoPanel = (
            <div>
              <video
                className="video-show-for-help"
                controls
                preload="auto"
                poster={helpVideoPoster}
              >
                <source src={obj.location} type="video/mp4" />
              </video>
            </div>
          );
        }
      });
    }

    let contentBodyPanel;
    if (this.state.helpInfo) {
      contentBodyPanel = (
        <div className="">
          {this.state.helpInfo.paragraphs.map((obj, i) => {
            return (
              <div key={i}>
                <div className="row">
                  <div className="col help-title-lbl-x text-left">
                    {obj.title}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-left">
                    <p align="justify" className="show-help-content">
                      {obj.description}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div className="container">
        <div className="modal-content-z">
          <div className="row text-center justify-content-center">
            <div className="modal-header-text">
              {this.state.helpInfo ? this.state.helpInfo.panelHeading : null}{" "}
              {closeButton}
            </div>
          </div>

          <div>
            <div className="fixedsize-start-here-x">
              {this.state.isBaandaAdmin === "yes" ? (
                <div className="text-center">
                  <font color="white" size="3">
                    {this.state.inputParam
                      ? this.state.inputParam.infoId
                      : null}
                  </font>
                </div>
              ) : null}

              {isVideo ? (
                <div className="row">
                  <div className="col">
                    <div className="text-center">{seeVideoPanel}</div>
                  </div>
                </div>
              ) : null}

              {contentBodyPanel}
              {isImage ? (
                <div className="row image-row-pos1">
                  <div className="col">
                    <div className="text-center">{imagePanel}</div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn-modal-help-close"
              onClick={this.closeModal}
              onChange={this.onChange}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <strong>Close</strong> &nbsp;
              <div className="float-right">
                <i className="far fa-window-close" />
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ShowHelp.propTypes = {
  hideModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { hideModal })(ShowHelp);
