// const sortArrayOfObjects = async (data, property, ascDsc) => {
//   // console.log("data:", data, ' property:', property, ' ascDsc:', ascDsc);
//   let sorted;
//   sorted = data.sort(compare(property, ascDsc));

//   return sorted;
// };

// const compare = (property, ascDsc) => {
//   // console.log('compare property:', property, ' ascDsc:', ascDsc);
//   let updown = 1;
//   if (ascDsc === "asc") {
//     updown = -1;
//   }
//   return function (a, b) {
//     const A = a[property].toUpperCase();
//     const B = b[property].toUpperCase();
//     // console.log("A:", A, " B:", B); 
//     if (A > B) {
//       return 1 * updown;
//     } else if (A < B) {
//       return -1 * updown;
//     } else {
//       return 0;
//     }
//   };
// };

const sortArrayOfObjects = async (data, property, ascDsc) => {
  // console.log('property:', property);
  // console.log('data: ', data);
  let sorted;
  if ( ascDsc === 'dsc') {
    sorted = data.sort((a,b) => (a[property] > b[property] ) ? 1 : -1 );
    // console.log(' sorted:', sorted);
  } else {
    sorted =  data.sort((a,b) => (a[property] > b[property] ) ? -1 : 1 );
    // console.log(' sorted:', sorted);
  }
  // sorted = data.sort(compare(property, ascDsc));

  return sorted;
};

export { sortArrayOfObjects } ;
