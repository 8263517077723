import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactLoading from "react-loading";
import moment from "moment";

import axios from "axios";

import "./ReceivePayment.css";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import ReceiveMoneyViaCC from "../../../account/finance/communities/ReceiveMoneyViaCC";
import ReceiveCCForSubscription from "../../../account/finance/communities/ReceiveCCForSubscription";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const initCommSubscription = "/routes/stripe/initCommSubscription";
const getUserByEmailAbsolute = "/routes/users/getUserByEmailAbsolute?";
const initialTaskSetup = "/routes/task/initialTaskSetup";
const handleInvoiceOfSales = "/routes/dashboard/handleInvoiceOfSales";
const getBaandaServicePrice = "/routes/architecture/getBaandaServicePrice?";

const transactionFeeServiceName =
  process.env.REACT_APP_FINANCIAL_TRANSACTION_FEE;

const Checkbox = (props) => <input type="checkbox" {...props} />;

class ReceivePayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      browserTimeZone: "",

      customerName: "",
      customerEmail: "",
      customerCell: "",

      custBaandaProfile: null,

      paymentMethod: "creditcard",

      cashReceivedFlag: false,
      checkReceivedFlag: false,

      // payViaCheckCashFlag: false,
      regularCCPayFlag: false,
      subscriptionCCPayFlag: false,
      payViaCryptoFlag: false,

      checkRoutingNo: "",
      checkAccountNo: "",
      checkNo: "",
      checkDate: "",

      hasSubscription: false,
      // requireTaskSetup: false,

      message: "",
      errFlag: false,

      loadingFlag: false,

      subscriptionRetObj: null,
      payHandler: "",
      sfretObj: null,

      regularPaymentMgmtFlag: true,
      payBySinglePayCCFlag: false,

      invoiceData: null,
      inputData: null,

      clientSecret: "",
      StripePaymentId: "",
      subscriptionObj: null,

      ccPaymentChannel: "singlepay",

      baandaFeeType: "",
      baandaFeePercent: 0,
      serviceShortDescription: "",

      ccAllowed: true,
      paymentAllowed: false,

      taskOnlySetupDone: false,

      cashCheckFlag: true,
      subType: "",

      userCreationType: "",
      temprand: "",
      pkgComposedFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let message = "";
    let paymentMethod = "creditcard";
    let ccAllowed = true;
    if (this.props.community.stripe.id === null) {
      message =
        "Payment via CC is not setup by the merchant. Buy in person using cash/check.";
      ccAllowed = false;
      paymentMethod = "cash";
    }
    let paymentAllowed = false;
    if (this.props.caller === "seller") {
      paymentAllowed = true;
    } else {
      if (ccAllowed) paymentAllowed = true;
    }
    let cashCheckFlag = true;
    let subType = "";
    if (
      this.props.invoice.subscription &&
      this.props.invoice.subscription.allowSubscription
    ) {
      cashCheckFlag = false;
      if (
        this.props.invoice.periodicDeliveryData &&
        this.props.invoice.periodicDeliveryData.periodicShipData &&
        this.props.invoice.periodicDeliveryData.periodicShipData.enabled
      ) {
        if (
          this.props.invoice.periodicDeliveryData.periodicShipData
            .deliveryDuration === "open"
        ) {
          subType = "deliveryopenended";
        } else {
          subType = "deliveryfixedended";
        }
      } else {
        subType = this.props.invoice.subscription.subscriptionType;
        if (subType === "continuous") subType = "openended";
        else subType = "installment";
      }
    }

    let customerName = "";
    if (this.props.caller === "buyer") customerName = this.props.auth.user.name;

    let pkgComposedFlag = false;
    this.props.itemsInCart.forEach((crt) => {
      if (crt.pkgComposedFlag) pkgComposedFlag = true;
    });

    this.setState({
      deviceSize,
      browserTimeZone,
      message,
      ccAllowed,
      paymentAllowed,
      paymentMethod,
      customerName,
      cashCheckFlag,
      subType,
      pkgComposedFlag,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  handlePaymentMethod = async (e) => {
    await this.setState({
      paymentMethod: e.target.value,
    });
  };

  handleCashReceived = async () => {
    await this.setState((prevstate) => ({
      cashReceivedFlag: !prevstate.cashReceivedFlag,
    }));
  };

  handleCheckReceived = async () => {
    await this.setState((prevstate) => ({
      checkReceivedFlag: !prevstate.checkReceivedFlag,
    }));
  };

  validateEmailSyntax = async (email) => {
    // console.log('email:', email, ' typeof email:', typeof email);
    if (email !== "" && typeof email === "string") {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      // return re.test(email.toString.toLowercase());
      return re.test(email);
    } else return false;
  };

  setCustomer = async () => {
    // console.log("In setcustomer ... ");
    // let customerName = "";
    let customerEmail = "";
    let customerCell = "";
    let isError = false;
    let errMsg = "";

    if (this.state.customerName === "") {
      isError = true;
      errMsg = "Please specify the customer's name.";
    }
    if (this.props.caller === "seller") {
      // console.log("in seller path");
      if (this.state.customerEmail === "") {
        isError = true;
        errMsg = errMsg + " Must provide an email.";
      } else {
        let validate = await this.validateEmailSyntax(this.state.customerEmail);
        // console.log("validate:", validate);
        if (!validate) {
          isError = true;
          errMsg = errMsg + " Invalid email syntax.";
        }
      }
      if (!isError) {
        customerEmail = this.state.customerEmail;
        customerCell = this.state.customerCell;
      }
    } else {
      customerEmail = this.props.auth.user.email;
      customerCell = this.props.auth.user.cell.number;
    }

    await this.setState({
      // customerName,
      customerEmail,
      customerCell,
      message: errMsg,
      errFlag: isError,
    });

    return isError;
  };

  invoicePayUp = async () => {
    // console.log("Continue ... invoicePayUp ...");
    let isError = await this.setCustomer();

    // console.log("isError:", isError);

    if (!isError) {
      if (this.props.invoice.toPayAmount > 0) {
        let sfret = await this.getBaandaServiceFeePercent();
        // console.log("sfret:", sfret);
        let baandaFeeType = "";
        let baandaFeePercent = 0;
        let baandaFeeMinimum = 0;
        let serviceShortDescription = "";
        let sfretObj = null;
        if (sfret.status === "success") {
          baandaFeeType = sfret.Msg.chargeType;
          baandaFeePercent = sfret.Msg.creditsCharged;
          baandaFeeMinimum = sfret.Msg.minimumAmt;
          serviceShortDescription = sfret.Msg.shortDescription;
          sfretObj = sfret.Msg;

          // console.log("1 sfretObj:", sfretObj);

          // let payViaCheckCashFlag = false;
          let regularCCPayFlag = false;
          let payViaCryptoFlag = false;
          let payret;
          let payHandler = "";
          let ccPaymentChannel = "";
          let errFlag = false;
          let message = "";
          let exitfromCheckoutFlag = false;

          // console.log("this.state.paymentMethod:", this.state.paymentMethod);
          if (this.state.paymentMethod === "creditcard") {
            if (
              this.props.invoice.isSubscription &&
              this.props.invoice.subscription.allowSubscription
            ) {
              // console.log(
              //   "1 in subscription ... to call handleSubscriptionCCPayments"
              // );
              await this.stripeSubscriptionSetup(sfretObj);
              // console.log("1. subret:", subret);
              ccPaymentChannel = "subscription";
              // if ( subret.status === 'success' ) this.handleExit('totalout');
            } else {
              // console.log("2  handleRegularCCPayments sfretObj:", sfretObj);
              payret = await this.handleRegularCCPayments(sfretObj);
              // console.log("2. payret:", payret);
              if (
                payret &&
                payret.status === "error" &&
                payret.Msg.customerBaandaId === 0
              ) {
                errFlag = true;
                message =
                  "Email is not a Baanda. Check for typo, or register. Then, customer can return here, or request for quote from store.";
              }
              regularCCPayFlag = true;
              ccPaymentChannel = "singlepay";
            }
            payHandler = "Stripe";
          } else if (this.state.paymentMethod === "crypto") {
            payViaCryptoFlag = true;
            payHandler = "CoinBase"; // May be CoinPayments
          } else {
            // console.log("3. heading to handleCashCheckpayments");
            let cashpay = await this.handleCashCheckpayments(sfretObj);
            // console.log("3. >>>>>>>>>>>>>>>> 3. cashpay:", cashpay);
            // payViaCheckCashFlag = true;
            payHandler = "In Person";
            if (cashpay.status === "error") {
              errFlag = true;
              message = cashpay.Msg;
            } else {
              this.handleExit("totalout");
            }
          }

          this.setState({
            // payViaCheckCashFlag,
            regularCCPayFlag,
            payViaCryptoFlag,
            payHandler,
            message,
            errFlag,
            sfretObj,
            ccPaymentChannel,
            baandaFeeType,
            baandaFeePercent,
            baandaFeeMinimum,
            serviceShortDescription,
            exitfromCheckoutFlag,
          });
        }
      } else {
        // console.log(
        //   "in here ... pay amount is zero... this.props.invoice.requireTaskSetup:",
        //   this.props.invoice.requireTaskSetup
        // );
        if (this.props.invoice.requireTaskSetup) {
          // Enter into tasklist for every custom service. Hare there will be
          // more than one in a single cart, but if there is one, it will be
          // handled here. If same item with custom service is included in
          // a cart, it should be rejected there itself.
          this.props.itemsInCart.forEach(async (elm) => {
            // console.log("elm:", elm);
            if (elm.service && elm.service.type === "custom") {
              // Just make entries in the tasklist for sell initiation.
              await this.executeTaskListEntryOnly(elm);
            }
          });
        }
      }
    }
  };

  checkIsBaanda = async () => {
    let param = "email=" + this.state.customerEmail;
    let url = baandaServer + getUserByEmailAbsolute + param;
    // console.log("url:", url);
    let baandaId = 0;
    let status = "success";
    let msg = "";
    let custBaandaProfile = null;
    let userCreationType = "";
    let temprand = "";
    try {
      let isb = await axios.get(url);
      // console.log("isb.data:", isb.data);
      if (isb.data.status === "success") {
        baandaId = isb.data.Msg.baandaId;
        custBaandaProfile = isb.data.Msg.profileInfo;
        userCreationType = isb.data.Msg.userCreationType;
        temprand = isb.data.Msg.temprand;
      } else {
        status = "error";
        msg = isb.data.Msg;
      }
    } catch (err) {
      status = "error";
      msg = err.memssage;
    }

    // console.log(">>>>checkIsBaanda msg:", msg);

    await this.setState({
      custBaandaProfile,
      userCreationType,
      temprand,
    });

    return { status, baandaId, msg };
  };

  createTaskListEntry = async (item) => {
    // console.log("createTaskListEntry item:", item);
    let baanda;
    let baandaId = 0;
    let customerName = this.state.customerName;
    let customerEmail = this.state.customerEmail;
    let customerCell = "";
    let taskInput = null;
    let canProcessTask = "success";
    let userCreationType = "";
    let errMsg = "";
    let temprand = "";
    if (this.props.caller === "seller") {
      baanda = await this.checkIsBaanda(this.state.customerEmail);
      // console.log("baanda:", baanda);
      if (baanda.status === "success") {
        // console.log("in here ... baanda:", baanda);
        // console.log("baanda.baandaId:", baanda.baandaId);
        baandaId = baanda.baandaId;
        customerCell = this.state.custBaandaProfile.cell.number;
        userCreationType = baanda.userCreationType;
        temprand = baanda.temprand;
      } else {
        // For custom task, force them to create an account.
        canProcessTask = "error";
        errMsg = baanda.msg;
        await this.setState({
          message: baanda.msg,
          errFlag: true,
        });
      }
    } else {
      baandaId = this.props.auth.user.baandaId;
      customerName = this.props.auth.user.name;
      customerEmail = this.props.auth.user.email;
      customerCell = this.props.auth.user.cell.number;
    }

    // console.log("canProcessTask:", canProcessTask);
    let taskAssignedTo = 0;
    let serverName = "";
    let serverEmail = "";
    let serverCell = "";
    // let serverId = 0 ;
    if (item.service.customFirstResponder) {
      taskAssignedTo = item.service.customFirstResponder.baandaId;
      serverName = item.service.customFirstResponder.name;
      serverEmail = item.service.customFirstResponder.email;
      serverCell = item.service.customFirstResponder.cell;
    }

    let coopStoreId = 0;
    let coopStoreName = "";
    let coopStoreKeeper = "";
    let coopStoreLocation = "";
    if (this.props.store) {
      coopStoreId = this.props.store.coopStoreId;
      coopStoreName = this.props.store.displayStoreName;
      coopStoreKeeper = this.props.store.displayStoreKeeper;
      coopStoreLocation =
        this.props.store.storeLocation.street +
        ", " +
        this.props.store.storeLocation.city +
        ", " +
        this.props.store.storeLocation.state;
    }

    if (canProcessTask === "success") {
      taskInput = {
        communityId: this.props.community.communityId,
        coopStoreId,
        coopStoreName,
        coopStoreKeeper,
        coopStoreLocation,
        signBoardShort: this.props.community.signBoardShort,
        signBoardLong: this.props.community.signBoardLong,
        communityCaption: this.props.community.commCaption,
        taskAssignedTo,
        itemId: item.itemId,
        itemName: item.itemName,
        serverName,
        serverEmail,
        serverCell,
        customerId: baandaId,
        customerEmail,
        customerName,
        customerCell,
        clientProgram: "ReceivePayment.js",
        clientFunction: "taskListEntry",
        invoice: this.props.invoice,
        cartId: this.props.cartId,
        updatedByBid: this.props.auth.user.baandaId,
        userCreationType,
        temprand,
      };
    } else {
      taskInput = errMsg;
    }

    // console.log("taskInput:", taskInput);

    return { status: canProcessTask, Msg: taskInput };
  };

  validateTaskItemSetup = async (item) => {
    let msg = "";
    let sta = "success";
    if (!item.service.customFirstResponder) {
      msg =
        "Please contact the business. Task item's First Responder must be setup for this transaction.";
      sta = "error";
    }

    return { status: sta, Msg: msg };
  };

  executeTaskListEntryOnly = async (item) => {
    // console.log("in executeTaskListEntryOnly item:", item);
    // console.log('in executeTaskListEntryOnly store:', this.props.store);

    let isValid = await this.validateTaskItemSetup(item);
    // console.log('isValid:', isValid);
    if (isValid.status === "success") {
      let inp = await this.createTaskListEntry(item);
      // console.log("inp:", inp);

      if (inp.status === "success") {
        let url = baandaServer + initialTaskSetup;
        // console.log("url: ", url, " inp.Msg:", inp.Msg);
        try {
          let setTsk = await axios.post(url, inp.Msg);
          // console.log("setTsk.data:", setTsk.data);

          if (setTsk.data.status === "error") {
            await this.setState({
              message: setTsk.data.Msg,
              errFlag: true,
            });
          } else {
            this.setState({
              message: "Successfully Processed. ",
              errFlag: false,
              showContinueButton: false,
              taskOnlySetupDone: true,
            });
          }
        } catch (err) {
          console.log("err:", err.message);
        }
      }
    } else {
      this.setState({
        message: isValid.Msg,
        errFlag: true,
      });
    }
  };

  createPaymentInput = async (baandafee) => {
    let taskListEntries = [];
    let theState = "success";
    this.props.itemsInCart.forEach(async (obj) => {
      if (obj.service && obj.service.type === "custom") {
        // console.log("in here 111");
        let tlist = await this.createTaskListEntry(obj);
        // console.log("tlist", tlist);
        if (tlist.status === "success") taskListEntries.push(tlist.Msg);
        else theState = "error";
      }
    });
    let baandaId = 0;
    let customerName = this.state.customerName;
    let customerEmail = this.state.customerEmail;
    let customerCell = this.state.customerCell;
    if (this.props.caller === "seller") {
      let baanda = await this.checkIsBaanda(customerEmail);
      // console.log("baanda:", baanda);
      if (baanda.status === "success") {
        baandaId = baanda.baandaId;
        if (
          this.state.custBaandaProfile.cell &&
          typeof this.state.custBaandaProfile.cell === "object"
        ) {
          customerCell = this.state.custBaandaProfile.cell.number;
        }
      }
    } else {
      baandaId = this.props.auth.user.baandaId;
      customerEmail = this.props.auth.user.email;
      if (typeof this.props.auth.user.cell === "string")
        customerCell = this.props.auth.user.cell;
      else if (typeof this.props.auth.user.cell === "object")
        customerCell = this.props.auth.user.cell.number;
      else customerCell = "";
    }

    let baandaFeeType = baandafee.chargeType;
    let baandaFeePercent = baandafee.creditsCharged;
    let baandaFeeMinimum = baandafee.minimumAmt;
    let shortDescription = baandafee.shortDescription;

    // OK for single CC payment
    let ccData = {
      clientSecret: this.state.clientSecret,
      StripePaymentId: this.state.StripePaymentId,
      subscriptionObj: this.state.subscriptionObj,
      paymentMethod: this.state.paymentMethod,
      ccPaymentChannel: this.state.ccPaymentChannel,
      finalStripeResponse: null,
      subsciprtionTransId: 0,
    };

    let communityInfo = {
      communityId: this.props.community.communityId,
      StripeAccId: this.props.community.stripe.id,
      finMMDD: this.props.community.finMMDD,
      finYYYY: this.props.community.finYYYY,
      commName: this.props.community.commName,
      caption: this.props.community.commCaption,
      signBoardShort: this.props.community.signBoardShort,
      signBoardLong: this.props.community.signBoardLong,
      sellerContactEmail: this.props.community.contact[0].contactEmail
    };

    let coopAgMstr = null;
    let coopPayState = null;
    let displayStoreName = "";
    let displayStoreKeeper = "";
    let coopStoreLocation = "";
    let coopStoreId = 0;
    if (this.props.store) {
      coopAgMstr = {
        discountAmt: this.props.store.agreementMaster.discountAmt,
        discountForMonths: this.props.store.agreementMaster.discountForMonths,
        meterValue: this.props.store.agreementMaster.meterValue,
      };
      coopPayState = this.props.store.coopPayState;
      displayStoreName = this.props.store.displayStoreName;
      displayStoreKeeper = this.props.store.displayStoreKeeper;
      coopStoreLocation =
        this.props.store.storeLocation.street +
        ", " +
        this.props.store.storeLocation.city +
        ", " +
        this.props.store.storeLocation.state;
      coopStoreId = this.props.coopStoreId;
    }

    let deliveryData = this.props.invoice.deliveryData;
    deliveryData.phone = this.state.customerCell;

    let payInput;
    if (theState === "success") {
      payInput = {
        taskListEntries,
        invoice: this.props.invoice,
        community: communityInfo,
        coopStoreId,
        displayStoreName,
        displayStoreKeeper,
        coopStoreLocation,
        coopAgMstr,
        coopPayState,
        customerBaandaId: baandaId,
        customerName,
        customerEmail,
        customerCell,
        paymentMethod: this.state.paymentMethod,
        checkRoutingNo: this.state.checkRoutingNo,
        checkAccountNo: this.state.checkAccountNo,
        checkNo: this.state.checkNo,
        checkDate: this.state.checkDate,
        clientProgram: "ReceivePayment.js",
        clientFunction: "CreatePaymentInput",
        updatedBy: this.props.auth.user.baandaId,
        baandaServiceFeePercent: baandaFeePercent,
        baandaServiceFeeMinimum: baandaFeeMinimum,
        BaandaServiceFeeType: baandaFeeType,
        baandaServiceDescription: shortDescription,
        updatedByBaandaId: this.props.auth.user.baandaId,
        payHandler: this.state.payHandler,
        servicePriceObj: baandafee,
        ccData,
        cartId: this.props.cartId,
        itemsInCart: this.props.itemsInCart,
        paymentFor: "invoice",
        browserTimeZone: this.state.browserTimeZone,
        periodicDeliveryData: this.props.invoice.periodicDeliveryData,
        deliveryData: this.props.invoice.deliveryData,
        taxInfo: this.props.invoice.salesTaxBreakdown,
        taxPaidByCustomer: this.props.invoice.salesTaxCustomer,
        taxPaidByMerchant: this.props.invoice.salesTaxMerchant,
        pkgComposedFlag: this.state.pkgComposedFlag,
      };
    } else {
      payInput = { customerBaandaId: baandaId };
    }
    return { status: theState, Msg: payInput };
  };

  // No subscription / installment
  handleRegularCCPayments = async (baandaFee) => {
    // console.log("handleRegularCCPayments baandaFee:", baandaFee);
    let inp = await this.createPaymentInput(baandaFee);
    // console.log("handleRegularCCPayments inp:", inp);
    // console.log("this.state.message:", this.state.message);
    if (inp.status === "success") {
      this.setState({
        invoiceData: inp.Msg,
        regularPaymentMgmtFlag: false,
        payBySinglePayCCFlag: true,
      });
      return inp;
    } else {
      return inp;
    }
  };

  handleCashCheckpayments = async (baandaFee) => {
    // console.log("in cashcheck... baandaFee:", baandaFee);
    await this.setState({
      loadingFlag: true,
    });

    let inp = await this.createPaymentInput(baandaFee);
    // console.log("handleCashCheckpayments inp:", inp);
    let status = "success";
    let msg = "";
    if (inp.status === "success") {
      let url = baandaServer + handleInvoiceOfSales;
      try {
        let ccret = await axios.post(url, inp.Msg);
        // console.log("ccret:", ccret);
        if (ccret.data.status === "success") {
          let msg = "Thank you.";
          if (inp.Msg.taskListEntries.length > 0)
            msg =
              msg +
              " Inform customer to check into their Ops(operation)-Tasks in their business portal.";
          await this.setState({
            message: msg,
            errFlag: false,
          });
        } else {
          await this.setState({
            message: ccret.data.Msg,
            errFlag: true,
          });
          msg = ccret.data.Msg;
          status = "error";
        }
      } catch (err) {
        console.log("ReceivePayment handleCahCheckPayment err:", err.message);
        await this.setState({
          message: err.message,
          errFlag: true,
        });
        msg = err.message;
        status = "error";
      }
    } else {
      this.setState({
        message: inp.Msg,
        errFlag: true,
      });
      msg = inp.Msg;
      status = "error";
    }

    this.setState({
      loadingFlag: false,
    });
    // console.log('******* status:', status);
    return { status, Msg: msg };
  };

  calculateCancelAt = (noOfInstallment) => {
    let invDate = moment();
    let cancelAt = invDate.add({ days: 5, months: noOfInstallment });
    // if (this.props.invoice.subscription.subscriptionType === "fixedendtime") {
    // cancelAt = invDate.add({
    //   days: 5,
    //   months: this.props.invoice.subscription.numberOfInstallment,
    // });
    // }

    return cancelAt;
  };

  getBaandaServiceFeePercent = async () => {
    // let feePercent = 0;
    let status = "success";
    let errMsg = "";
    let errFlag = false;
    let msg = null;
    let param = "serviceName=" + transactionFeeServiceName;
    let url = baandaServer + getBaandaServicePrice + param;
    try {
      let prret = await axios.get(url);
      // console.log("prret.data:", prret.data);
      if (prret.data.status === "success") {
        msg = prret.data.Msg;
        errMsg = "";
        errFlag = false;
      } else {
        errMsg = prret.data.Msg;
        errFlag = true;
      }
    } catch (err) {
      errMsg = err.message;
      errFlag = true;
    }

    await this.setState({
      errMsg,
      errFlag,
    });

    return { status, Msg: msg };
  };

  stripeSubscriptionSetup = async (baandafee) => {
    // console.log("stripeSubscriptionSetup baandafee:", baandafee);

    this.setState({
      loadingFlag: true,
      errMsg: "",
      errFlag: false,
    });

    let baandaServiceFeePercent = baandafee.creditsCharged;
    // console.log("baandaServiceFeePercent:", baandaServiceFeePercent);

    let subSetupState = "success";
    let errMsg = "";
    let msg = "";
    let errFlag = false;

    // --------------------------------------------------
    // Process for getting data for subscriptionLog.
    let sellerCommunityId = 0;
    let sellerCommunityName = "";
    let customerCommunityId = "";
    let customerCommunityName = "";
    let customerBaandaId = 0;
    let customerEmail = "";
    let customerName = "";
    let customerCell = "";
    let subType = "";
    let created_by_baandaId = this.props.auth.user.baandaId;

    let isBaanda = await this.checkIsBaanda();
    // console.log("isBaanda:", isBaanda);
    // { status, baandaId, msg }  custBaandaProfile
    if (this.props.caller === "seller") {
      customerCell = this.state.customerCell;
      if (isBaanda.status !== "error") {
        customerBaandaId = isBaanda.baandaId;
        if (this.state.customerCell === "") {
          if (customerBaandaId !== 0) {
            if (
              this.state.custBaandaProfile &&
              this.state.custBaandaProfile.cell
            ) {
              if (typeof this.state.custBaandaProfile.cell === "object")
                customerCell = this.state.custBaandaProfile.cell.number;
              if (typeof this.state.custBaandaProfile.cell === "string")
                customerCell = this.state.custBaandaProfile.cell;
            }
          }
        }
      }
      customerName = this.state.customerName;
      customerEmail = this.state.customerEmail;
    } else {
      customerBaandaId = this.props.auth.user.baandaId;
      customerName = this.props.auth.user.name;
      customerEmail = this.props.auth.user.email;
      if (typeof this.props.auth.user.cell === "object") {
        if (this.props.auth.user.cell)
          customerCell = this.props.auth.user.cell.number;
      }
      if (typeof this.props.auth.user.cell === "string") {
        customerCell = this.props.auth.user.cell;
      }
    }

    sellerCommunityId = this.props.community.communityId;
    sellerCommunityName = this.props.community.commName;
    subType = this.state.subType;

    // It is possible that the email is not baanda and hence the baandaId is 0.
    // This should be filled in when the emial holder registrs and become a Baanda.

    // --------------------------------------------------
    let productName = "";
    let description = "";
    let upfrontPayAmount = this.props.invoice.toPayAmount;
    let subscriptionAmount = 0;
    let cancelAt = null;
    let itemId = 0;
    let itemImage = "";
    let tradeBetween = null;
    let forMonths = 0;
    let itemType = "";
    let unitType = "";

    this.props.itemsInCart.forEach((obj) => {
      if (obj.subscription && obj.subscription.allowSubscription) {
        if (obj.periodicShipData && obj.periodicShipData.enabled) {
          subscriptionAmount = parseFloat(
            this.props.invoice.periodicDeliveryData.periodSubscriptionAmt
          );
          itemId = obj.itemId;
          productName = this.props.invoice.periodicDeliveryData.lineItemName;
          description =
            productName + " (subscription based periodic/monthly delivery)";
          if (
            this.props.invoice.periodicDeliveryData.periodicShipData
              .deliveryDuration === "fixed"
          ) {
            cancelAt = this.calculateCancelAt(
              parseFloat(
                this.props.invoice.periodicDeliveryData.periodicShipData
                  .numberOfDelivery
              )
            );
          }
          tradeBetween = {
            sellerCommunityId,
            sellerCommunityName,
            customerCommunityId,
            customerCommunityName,
            customerBaandaId,
            customerEmail,
            customerName,
            customerCell,
          };
          this.props.invoice.periodicDeliveryData.tradeBetween = tradeBetween;
        } else {
          let type = "";
          subscriptionAmount = this.props.invoice.subscriptionAmt;
          if (obj.subscription.subscriptionType === "fixedendtime") {
            type = "installment";
            let numberOfInstallment = parseFloat(
              this.props.invoice.subscription.numberOfInstallment - 1
            );
            forMonths = numberOfInstallment;
            cancelAt = this.calculateCancelAt(numberOfInstallment);
            // subscriptionAmount = this.props.invoice.subscriptionAmt;
          } else {
            type = "membership";
            // subscriptionAmount = this.props.invoice.subscriptionAmt;
          }
          productName = obj.itemName;
          description =
            obj.itemName + " purchased via subscription is of type " + type;
          itemId = obj.itemId;
          itemImage = obj.itemImage;
          itemType = obj.itemType;
          unitType = obj.unitType;
        }
      }
    });

    let url = baandaServer + initCommSubscription;

    let price = {
      registrationFee: parseFloat(upfrontPayAmount.toFixed(2)),
      monthlyFee: parseFloat(subscriptionAmount.toFixed(2)),
      regiCouponPercent: 0,
      monthlyCouponPercent: 0,
      forMonths,
      couponName: "",
      salesRep: "",
      couponCreatedOn: new Date().toISOString().split("T")[0],
    };

    let deliveryData = this.props.invoice.deliveryData;
    deliveryData.phone = this.state.customerCell;

    let subSetupInput = {
      upfrontPayAmount: parseFloat(upfrontPayAmount.toFixed(2)),
      subscriptionAmount: parseFloat(subscriptionAmount.toFixed(2)),
      application_fee_percent: parseFloat(baandaServiceFeePercent),
      productName,
      description,
      interval: "month", // This may be changed later to biweekely, annually etc. per Stripe doc
      intervalCount: 1, // now it is monthly, but later it may be bi-monthly, by weekly and the count may be 2,3 etc.
      baandaServiceFeePercent, // Get the service fee. This may be done at the server side from service Name
      connectedStripeAccountOfMerchant: this.props.community.stripe.id, // This is from community.stripe.id
      cancelAt, // Calculated date if installment or null (membership)
      clientProgram: "ReceivePayment.js",
      clientFunction: "stripeSubscriptionSetup",
      price,
      sellerCommunityId,
      sellerCommunityName,
      customerCommunityId,
      customerCommunityName,
      customerBaandaId,
      customerEmail,
      customerName,
      customerCell,
      itemName: description,
      itemId,
      itemImage,
      itemType,
      unitType,
      subType,
      created_by_baandaId,
      periodicDeliveryData: this.props.invoice.periodicDeliveryData,
      deliveryData,
      taxInfo: this.props.invoice.salesTaxBreakdown,
      taxPaidByCustomer: this.props.invoice.salesTaxCustomer,
      taxPaidByMerchant: this.props.invoice.salesTaxMerchant,
      pkgComposedFlag: this.state.pkgComposedFlag
    };
    try {
      let inp = await this.createPaymentInput(baandafee);

      // console.log("url:", url, " subSetupInput:", subSetupInput, " inp:", inp);

      let retsub = await axios.post(url, subSetupInput);

      if (retsub.data.status === "success") {
        if (inp.status === "success") {
          // console.log("@@@ retsub:", retsub.data.Msg);
          this.setState({
            subscriptionCCPayFlag: true,
            regularPaymentMgmtFlag: false,
            showDisplayFlag: false,
            inputData: {
              clientSecret:
                retsub.data.Msg.ccInfo.subscription.latest_invoice
                  .payment_intent.client_secret,
              invoiceData: inp.Msg, // To be entered in the next step.
              ccInfo: retsub.data.Msg.ccInfo,
              transactionId: retsub.data.Msg.transactionId,
            },
          });
        } else {
          errMsg = inp.Msg;
          errFlag = true;
        }
      } else {
        errMsg = retsub.data.Msg;
        errFlag = true;
      }
    } catch (err) {
      console.log("stripeSubscriptionSetup err:", err.message);
      errMsg = err.message;
      errFlag = true;
    }

    this.setState({
      loadingFlag: false,
      errMsg,
      errFlag,
    });

    // console.log("{ status: subSetupState, Msg: msg }:", subSetupState, msg);
    return { status: subSetupState, Msg: msg };
  };

  handleExit = async (output) => {
    // console.log("output:", output);
    if (output !== "cancel") {
      this.props.returnToCaller("totalout");
    } else {
      await this.setState({
        regularPaymentMgmtFlag: true,
        payBySinglePayCCFlag: false,
        subscriptionCCPayFlag: false,
      });
    }
  };

  exitFromPayment = () => {
    this.props.returnToCaller("cancel");
  };

  returnToCatalogAfterTaskSetup = () => {
    this.props.returnToCaller("success");
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);
    console.log("ReceivePayment...");

    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div className="subscription-spinner-pos text-center">
          <ReactLoading
            type={"spokes"}
            color={"#1f3d6b"}
            height={30}
            width={30}
          />
        </div>
      );
    }

    let continueButton;
    if (this.state.paymentAllowed) {
      continueButton = (
        <button
          className="btn_reg_80_cont"
          type="button"
          onClick={this.invoicePayUp}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Continue
        </button>
      );
    }

    let exitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitFromPayment}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let customerPanel;
    let cname;
    if (this.state.deviceSize === "small") {
      cname = "Recpnt";
    } else {
      cname = "Recipient:";
    }
    if (this.props.caller === "seller") {
      customerPanel = (
        <div>
          <div className="row">
            <div className="col sellers-name">
              Seller: {this.props.auth.user.name}
            </div>
          </div>
          <div className="text-left inv-pay-customer-data">
            Get Customer's Info
          </div>
          <div className="row addr-row">
            <div className="col-3 cat-pay-recpnt text-right">{cname}</div>
            <div className="col-9 text-left">
              <input
                name="customerName"
                type="text"
                value={this.state.customerName}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="inv-pay-customer-name"
                placeholder="Enter customer's name"
              />
            </div>
          </div>
          <div className="row addr-row">
            <div className="col-3 pick-addr-lbl text-right">Email</div>
            <div className="col-9 text-left">
              <input
                name="customerEmail"
                type="text"
                value={this.state.customerEmail}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="inv-pay-customer-name"
                placeholder="Enter customer's email"
              />
            </div>
          </div>
          <div className="row addr-row">
            <div className="col-3 pick-addr-lbl text-right">Cell</div>
            <div className="col-9 text-left">
              <PhoneInput
                placeholder="Enter Cell number"
                value={this.state.customerCell}
                onChange={(customerCell) => this.setState({ customerCell })}
                country="US"
                className="cat-recv-pay-phoneFlag"
              />
            </div>
          </div>
        </div>
      );
    } else {
      customerPanel = (
        <div>
          <div className="row addr-row">
            <div className="col-3 pick-addr-lbl text-right">{cname}</div>
            <div className="col-9 text-left">
              <input
                name="customerName"
                type="text"
                value={this.state.customerName}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="inv-pay-customer-name"
                placeholder="Enter customer's name"
              />
            </div>
          </div>
          <div className="row rec-pay-buyer-row">
            <div className="col sellers-name">
              Invoice to: {this.props.auth.user.name} (
              {this.props.auth.user.email})
            </div>
          </div>
        </div>
      );
    }

    let creditcard, crypto, paywith, invamt, payamt, subscrip;
    if (this.state.deviceSize === "small") {
      creditcard = "CC";
      crypto = "Crypto (FR)";
      paywith = "Use";
      invamt = "Inv Amt";
      payamt = "Pay Now";
      subscrip = "SubScrpt Amt";
    } else {
      creditcard = "Credit Card";
      crypto = "Crypto Currency (FR)";
      paywith = "Pay using";
      invamt = "Invoice Amount";
      payamt = "Pay Now";
      subscrip = "Subscription Amt";
    }

    let subscriptionDuration = "";
    if (this.props.invoice.periodicDeliveryData) {
      if (
        this.props.invoice.periodicDeliveryData.periodicShipData
          .deliveryDuration === "open"
      ) {
        subscriptionDuration = " till canceled.";
      } else {
        subscriptionDuration =
          " for " +
          (this.props.invoice.periodicDeliveryData.periodicShipData
            .numberOfDelivery -
            1) +
          " additional months.";
      }
    } else {
      if (
        this.props.invoice.subscription &&
        this.props.invoice.subscription.subscriptionType === "fixedendtime"
      ) {
        subscriptionDuration =
          " for " +
          (this.props.invoice.subscription.numberOfInstallment - 1) +
          " additional months.";
      } else {
        subscriptionDuration = " till canceled.";
      }
    }

    let subscriptionAmountPanel;
    if (
      this.props.invoice.subscription &&
      this.props.invoice.subscription.allowSubscription
    ) {
      subscriptionAmountPanel = (
        <div className="text-cnter subscription-msg">
          {invamt} is $
          {this.commaFormattedCurrency(
            parseFloat(this.props.invoice.totalInvoiceAmount.toFixed(2))
          )}
          ; {payamt} $
          {this.commaFormattedCurrency(
            parseFloat(this.props.invoice.toPayAmount.toFixed(2))
          )}
          ; {subscrip} $
          {this.commaFormattedCurrency(
            parseFloat(this.props.invoice.subscriptionAmt.toFixed(2))
          )}{" "}
          per month
          {subscriptionDuration}
          {/* {this.props.invoice.subscription.subscriptionType === "fixedendtime"
            ? " for " +
              (this.props.invoice.subscription.numberOfInstallment - 1) +
              " more, " +
              this.props.invoice.subscription.intervalType +
              "."
            : null} */}
        </div>
      );
    }

    let paymentMethodPanel;
    paymentMethodPanel = (
      <div>
        <div className="row inv-pay-method-selection">
          <div className="col text-center radio-font-style">
            <strong>{paywith}&nbsp;&nbsp;</strong>
            {this.props.caller === "seller" && this.state.cashCheckFlag ? (
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="cash"
                    checked={this.state.paymentMethod === "cash"}
                    onChange={this.handlePaymentMethod}
                  />{" "}
                  Cash
                </label>
              </div>
            ) : null}
            {this.props.caller === "seller" && this.state.cashCheckFlag ? (
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="check"
                    checked={this.state.paymentMethod === "check"}
                    onChange={this.handlePaymentMethod}
                  />{" "}
                  Check
                </label>
              </div>
            ) : null}
            {this.props.community.stripe.id === null ||
            this.props.community.stripe.id === "" ? null : (
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="creditcard"
                    checked={this.state.paymentMethod === "creditcard"}
                    onChange={this.handlePaymentMethod}
                  />{" "}
                  {creditcard}
                </label>
              </div>
            )}

            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="crypto"
                  checked={this.state.paymentMethod === "crypto"}
                  onChange={this.handlePaymentMethod}
                  disabled
                />{" "}
                {crypto}
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let toPayPanel;
    toPayPanel = (
      <div className="text-center to-pay-amount">
        To pay amount: $
        {this.commaFormattedCurrency(this.props.invoice.toPayAmount)}
      </div>
    );

    let totalInvoicePanel;
    if (this.state.invoiceData) {
      totalInvoicePanel = (
        <div className="text-center to-pay-amount">
          Total Invoice Amount: $
          {this.commaFormattedCurrency(this.props.invoice.toPayAmount)}
          <div className="text-center cc-show-customer-name">
            Payer: {this.state.invoiceData.customerName}
          </div>
        </div>
      );
    }

    let showContinueButton = false;
    let cashCheckPanel;
    let continueBtnMsg = "";
    if (this.state.paymentMethod === "cash") {
      cashCheckPanel = (
        <div className="text-center rec-cash-check-payment">
          <Checkbox
            checked={this.state.cashReceivedFlag}
            onChange={this.handleCashReceived}
          />{" "}
          Cash Received
        </div>
      );
      if (this.state.cashReceivedFlag) {
        if (this.state.customerName === "" || this.state.customerEmail === "") {
          continueBtnMsg =
            "For cash/check transaction, recipient name and email are mandatory.";
        } else {
          showContinueButton = true;
        }
      }
    }

    if (this.state.paymentMethod === "check") {
      cashCheckPanel = (
        <div className="text-center rec-cash-check-payment">
          Check payble to {this.props.community.checkPayToName}.&nbsp;
          <Checkbox
            checked={this.state.checkReceivedFlag}
            onChange={this.handleCheckReceived}
          />{" "}
          Check Received
        </div>
      );
      if (this.state.checkReceivedFlag) showContinueButton = true;
    }

    if (this.state.paymentMethod === "creditcard") showContinueButton = true;

    let checkPanel;
    if (this.state.paymentMethod === "check" && this.state.checkReceivedFlag) {
      checkPanel = (
        <div>
          <div className="row inv-check-row">
            <div className="col-3 pick-addr-lbl text-right">Routing #</div>
            <div className="col-9 text-left">
              <input
                name="checkRoutingNo"
                type="text"
                value={this.state.checkRoutingNo}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="inv-check-routing"
                placeholder=""
              />
            </div>
          </div>
          <div className="row inv-check-row">
            <div className="col-3 pick-addr-lbl text-right">Account #</div>
            <div className="col-9 text-left">
              <input
                name="checkAccountNo"
                type="text"
                value={this.state.checkAccountNo}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="inv-check-accno"
                placeholder=""
              />
            </div>
          </div>
          <div className="row inv-check-row">
            <div className="col-3 pick-addr-lbl text-right">Check #</div>
            <div className="col-9 text-left">
              <input
                name="checkNo"
                type="text"
                value={this.state.checkNo}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="inv-check-no"
                placeholder=""
              />
            </div>
          </div>
          <div className="row inv-check-row">
            <div className="col-3 pick-addr-lbl text-right">Check Date</div>
            <div className="col-9 text-left">
              <input
                name="checkDate"
                type="text"
                value={this.state.checkDate}
                onChange={this.onChange}
                size="10"
                maxLength="10"
                className="inv-check-date"
                placeholder="mm-dd-yyyy"
              />
            </div>
          </div>
        </div>
      );
    }

    let outputPanel;
    // Single or one time payment
    if (this.state.payBySinglePayCCFlag) {
      // console.log("this.state.invoiceData:", this.state.invoiceData);
      outputPanel = (
        <div>
          {totalInvoicePanel}
          <ReceiveMoneyViaCC
            inputData={this.state.invoiceData}
            deviceSize={this.state.deviceSize}
            paymentAmount={this.state.invoiceData.invoice.toPayAmount}
            stripeaccId={this.props.community.stripe.id}
            handleExit={(msg) => this.handleExit(msg)}
          />
        </div>
      );
    }

    // Subscription payment
    if (this.state.subscriptionCCPayFlag) {
      outputPanel = (
        <div>
          <ReceiveCCForSubscription
            inputData={this.state.inputData}
            deviceSize={this.state.deviceSize}
            StripeAccId={this.props.community.stripe.id}
            handleExit={this.handleExit}
            paymentFor="invoice-subscription"
          />
        </div>
      );
    }

    if (this.state.regularPaymentMgmtFlag) {
      outputPanel = (
        <div>
          <div className="text-center checkout-payment-header">
            Invoice Processing
          </div>
          {toPayPanel}
          {customerPanel}
          {subscriptionAmountPanel}
          {this.props.invoice.toPayAmount > 0 ? paymentMethodPanel : null}
          {cashCheckPanel}
          {checkPanel}
          <div className="text-center recv-payment-checkout-btn-pos">
            {showContinueButton ? continueButton : continueBtnMsg} {exitButton}
          </div>
          <div className="text-center">{uploadingSpin}</div>
          <div
            className={
              this.state.errFlag
                ? "text-center rec-pay-msg-err"
                : "text-center rec-pay-msg"
            }
          >
            {this.state.message}
          </div>
          <div className="text-center inv-pay-legend">FR: Future Release</div>
        </div>
      );
    }

    if (this.state.taskOnlySetupDone) {
      outputPanel = (
        <div>
          <div className="text-center checkout-payment-header">
            Invoice Processing
          </div>
          <div>
            <button
              className="btn_back_main"
              type="button"
              onClick={this.returnToCatalogAfterTaskSetup}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
          <div
            className={
              this.state.errFlag
                ? "text-center rec-pay-msg-err"
                : "text-center rec-pay-msg"
            }
          >
            {this.state.message}
          </div>
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

ReceivePayment.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(withRouter(ReceivePayment));
