import React, { Component } from "react";

import "./PresentMaps.css";

import axios from "axios";

import MainMap from "../utils/maputil/MainMap";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getUserByBaandaId = "/routes/users/getUserByBaandaId?";
const getForwardGeoloc = "/routes/shared/getForwardGeoloc?";
const getMapDistance = "/routes/shared/getMapDistance";

const locationiqKey = process.env.REACT_APP_LOCATIONIQ_KEY;

class PresentMaps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      name: "",
      commGeoLoc: null,

      pointOfInterest: [],

      userLocation: null,
      communityLocation: null,
      userAddress: "",
      communityAddress: "",

      showMapFlag: false,
      disttime: null,
    };
  }

  componentDidMount = async () => {
    await this.getUser();
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getCommunityLocation();

    await this.createGeoIntelData();

    await this.getDistances();

    this.setState({
      showMapFlag: true,
      deviceSize,
    });
  };

  getUser = async () => {
    try {
      let param = "baandaId=" + this.props.baandaId;
      let url = baandaServer + getUserByBaandaId + param;
      let useret = await axios.get(url);

      if (useret.data.Msg.length === 1) {
        let addr = useret.data.Msg[0].profileInfo.address;

        let street = "&street=" + addr.street;
        let city = "&city=" + addr.city;
        let state = "&state=" + addr.state;
        let country = "&country=USA";
        let postalcode = "&postalcode=" + addr.postalAddress;
        let searchString = street + city + state + country + postalcode;

        let userAddr =
          addr.street +
          ", " +
          addr.city +
          ", " +
          addr.state +
          " " +
          addr.postalAddress +
          ", USA";

        this.setState({
          userAddress: userAddr,
        });

        let retUserLoc = await this.getGeolocation(searchString);

        if (retUserLoc.status === "success") {
          let userLoc = {
            addr: userAddr,
            lat: retUserLoc.Msg.data.Msg[0].lat,
            lon: retUserLoc.Msg.data.Msg[0].lon,
          };

          this.setState({
            userLocation: userLoc,
          });
        }
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  getCommunityLocation = async () => {
    let ct = this.props.communityLocation.postalAddress;
    let street = "&street=" + ct.line1;
    let city = "&city=" + ct.city;
    let state = "&state=" + ct.state;
    let country = "&country=" + ct.country;
    let postalcode = "&postalcode=" + ct.postalAddress;
    let searchString = street + city + state + country + postalcode;
    // console.log("community searchString:", searchString);
    let communityAddress =
      ct.line1 +
      ", " +
      ct.city +
      ", " +
      ct.state +
      " " +
      ct.postalcode +
      ", USA";

    await this.setState({
      communityAddress,
    });

    let retcomloc = await this.getGeolocation(searchString);

    if (retcomloc.status === "success") {
      let communityLoc = {
        addr: communityAddress,
        lat: retcomloc.Msg.data.Msg[0].lat,
        lon: retcomloc.Msg.data.Msg[0].lon,
      };

      await this.setState({
        communityLocation: communityLoc,
      });
    }
  };

  getGeolocation = async (searchString) => {
    try {
      let param =
        "location=https://us1.locationiq.com/v1/search.php?key=" +
        locationiqKey +
        searchString +
        "&format=json";
      let url = baandaServer + getForwardGeoloc + param;
      //   console.log("url:", url);
      let ret = await axios.get(url);
      //   console.log("&&&& location ret:", ret);

      return { status: "success", Msg: ret };
    } catch (err) {
      console.log("err:", err.message);
      return { status: "error", Msg: err.message };
    }
  };

  createGeoIntelData = async () => {
    // console.log("inside createGeoIntelData");
    let pointOfInterest = [];
    let userObj = {
      id: "1",
      nameOfPlace: "Your Home",
      address: this.state.userLocation.addr,
      shortDescription: "Your Home",
      intelForBubble: "", // "Future: To be filled in later in development",
      intelForCard: "", // "Future: Focus of activity",
      context: "", //"Future: context of this intel",
      lat: this.state.userLocation.lat,
      lng: this.state.userLocation.lon,
    };
    pointOfInterest.push(userObj);

    let commObj = {
      id: "2",
      nameOfPlace: "Community",
      address: this.state.communityLocation.addr,
      shortDescription: this.props.communityCaption,
      intelForBubble: "Future: To be filled in later in development",
      intelForCard: "Future: Focus of activity",
      context: "Future: context of this intel",
      lat: this.state.communityLocation.lat,
      lng: this.state.communityLocation.lon,
    };
    pointOfInterest.push(commObj);

    await this.setState({
      pointOfInterest,
    });
  };

  getDistances = async () => {
    let urld = baandaServer + getMapDistance;
    let data = {
      origin: this.state.userAddress,
      destination: this.state.communityAddress,
    };

    try {
      let distTime = await axios.post(urld, data);

      let disttime = distTime.data.Msg.rows[0].elements[0];

      this.setState({
        disttime,
      });
    } catch (err) {
      console.log("getDistance err:", err.message);
    }
  };

  returnToShowInvite = async () => {
    alert("Return button was clicked ...");
  };

  returnToOrigin = () => {
    this.props.returnToCaller();
    // console.log('here hhh')
  };

  // testFn = () => {
  //   console.log("kjsadkjahsj");
  // };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("PresentMaps...");

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToOrigin}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let distance, duration;
    if (this.state.disttime) {
      distance = this.state.disttime.distance.text;
      duration = this.state.disttime.duration.text;
    }
    let mapShowPanel;
    if (this.state.showMapFlag) {
 
        mapShowPanel = (
          <div>
            <div className="row">
              <div className="col">
                {" "}
                <div className="joint-view-map-box-x">
                  <MainMap
                    data={this.state.pointOfInterest}
                    distance={distance}
                    // returnToShowInvite={this.returnToShowInvite}
                  />
                </div>
              </div>
            </div>
          </div>
        );
       
    }

    let mapLabelPanel;
    mapLabelPanel = (
      <div className="row">
        <div className="col-11 map-box-intel text-center">
          {this.state.deviceSize === "big" ? (
            <div className="map-header text-center">Travel Intelligence</div>
          ) : null}
          Driving distance is&nbsp;{distance}
          &nbsp; with average drive time of&nbsp;
          {duration}{" "}
        </div>
        {this.state.deviceSize === "big" ? (
          <div className="col-1 big-back-btn">{backButton} </div>
        ) : (
          <div className="col-1">&nbsp;</div>
        )}
      </div>
    );

    return (
      <div>
        {mapShowPanel}
        {mapLabelPanel}
        {this.state.deviceSize === "small" ? <div className="map-small-back">{backButton}</div> : null}
        {/* {backButton} */}
      </div>
    );
  }
}

export default PresentMaps;
