import React, { Component } from "react";

import axios from "axios";

import "./CatalogItemFilter.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCatagories = "/routes/dashboard/getCatagories?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

class CatalogItemFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",

      filterType: "all",
      catagories: [],
      selheight: 3,

      categorySelected: "",
      itemName: "",

      invClassification: "catalog",
      deviceSize: "",
      merchandiseType: "goods",

      onlyPublishedItems: true,
    };
  } 

  onChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleOnlyPublished = async () => {
    await this.setState((prevstate) => ({
      onlyPublishedItems: !prevstate.onlyPublishedItems,
    }));
  };

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    let merchandiseType = "goods";
    if (this.props.merchandiseType)
      merchandiseType = this.props.merchandiseType;
    await this.setState({
      deviceSize,
      merchandiseType,
    });
  };

  returnToItemList = async () => {
    let data = {
      ops: "cancel",
    };
    this.props.returnToCaller(data);
  };

  handleFilter = () => {
    let isPublished = this.state.onlyPublishedItems;
    if (this.props.role === "customer" || this.props.role === "prospect") isPublished = true;
    let retData = {
      ops: "fetch",
      onEntry: false,
      invClassification: this.state.invClassification,
      filterType: this.state.filterType,
      category: this.state.categorySelected,
      itemType: this.state.merchandiseType,
      searchItemName: this.state.itemName,
      isPublished,
    };

    // console.log("====== Item Fliter retData:", retData);

    this.props.returnToCaller(retData);
  }; 

  handleFilterType = async (e) => {
    await this.setState({
      filterType: e.target.value,
    });
    if (this.state.filterType === "category") {
      await this.getCategories();
    }
  };

  handleItemType = async (e) => {
    await this.setState({
      merchandiseType: e.target.value,
    });
  };

  getCategories = async () => {

    let coopStoreId = 0;
    if ( this.props.store) coopStoreId = this.props.store.coopStoreId;

    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&categoryOf=item&itemType=" +
      this.state.merchandiseType +
      "&invClassification=catalog&clientProgram=CatalogItemFilter.js&clientFunction=getCategories";
    let url = baandaServer + getCatagories + params;
    // console.log("url:", url);
    try {
      let retcat = await axios.get(url);
      // console.log("retcat:", retcat);
      await this.setState({
        catagories: retcat.data.Msg,
      });
      // console.log("retcat:", retcat);
    } catch (err) {
      console.log("err:", err.message);
    }
    if (document.getElementById("mySelect"))
      document.getElementById("mySelect").value = "DEFAULT";
  };

  onfocus = async () => {
    let selht = 4;
    if (this.state.catagories.length < 4)
      selht = this.state.catagories.length + 1;
    await this.setState({
      selheight: selht,
    });
  };

  onblur = async () => {
    await this.setState({
      selheight: 3,
    });
  };

  handleCategorySelected = async () => {
    let value = document.getElementById("mySelect").value;
    await this.setState({
      categorySelected: value,
      selheight: 3,
    });
    // console.log("value >>>:", value);
  };

  onChangeClassification = async (e) => {
    await this.setState({
      invClassification: e.target.value,
    });

    if (this.state.filterType === "category") {
      await this.getCategories();
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("CatalogItemFilter...");

    let cancelBtn;
    cancelBtn = (
      <button
        className="btn_back_main"
        onClick={this.returnToItemList}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let sellist = this.state.catagories.map((obj, i) => {
      return (
        <option key={i} value={obj.value}>
          {obj.label}&nbsp;
        </option>
      );
    });

    let categoryPanel;
    if (this.state.filterType === "category") {
      categoryPanel = (
        <div>
          <div>
            <div className="row select_panel_inventory">
              <div className="col text-center div_catitem_select">
                <select
                  size={this.state.selheight}
                  id="mySelect"
                  name="mySelect"
                  onChange={this.handleCategorySelected}
                  className="inv-item-select-xx"
                  onFocus={this.onfocus}
                  onBlur={this.onblur}
                >
                  <option value="DEFAULT" disabled>
                    Select an Category
                  </option>
                  {sellist}
                </select>
              </div>
            </div>
            {/* <div className="row">
              <div className="col text-center select_item_msg">
                Please select a category to filter.
              </div>
            </div> */}
          </div>
        </div>
      );
    }

    let itname;
    if (this.state.deviceSize === "small") {
      itname = "Name";
    } else {
      itname = "Item Name";
    }

    let namePanel;
    if (this.state.filterType === "name") {
      namePanel = (
        <div>
          <div className="row name-row">
            <div className="col-3 text-right cat-search-filter-lbl">
              {itname}
            </div>
            <div className="col-9 text-left input_placement">
              <input
                name="itemName"
                type="text"
                value={this.state.itemName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="cat-search-by-name"
                placeholder="Enter item name to filter"
                spellCheck="false"
              />
            </div>
          </div>
          <div>
            <p className="text-cente name-msg">Part/full of item name.</p>
          </div>
        </div>
      );
    }

    let itemTypePanel;
    // if (this.props.caller === "viewpagicatalog") {
    itemTypePanel = (
      <div>
        <div className="row">
          <div className="col text-center radio-fonts">
            <strong>Merchandise Type: &nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="goods"
                  checked={this.state.merchandiseType === "goods"}
                  onChange={this.handleItemType}
                />{" "}
                Goods
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="service"
                  checked={this.state.merchandiseType === "service"}
                  onChange={this.handleItemType}
                />{" "}
                Service
              </label>
            </div>
            {/* <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="education"
                    checked={this.state.merchandiseType === "education"}
                    onChange={this.handleItemType}
                  />{" "}
                  Education
                </label>
              </div> */}
          </div>
        </div>
      </div>
    );
    // }

    let publishPanel;
    if (this.props.authorizedFuncs) {
      if (
        this.props.authorizedFuncs.includes("catalog") ||
        this.props.authorizedFuncs.includes("catalogsetup") ||
        this.props.role === "creator"
      ) {
        // if (this.props.caller === "viewpagicatalog") {
        publishPanel = (
          <div className="publish-row">
            <b>Published Status</b>&nbsp;
            <Checkbox
              checked={this.state.onlyPublishedItems}
              onChange={this.handleOnlyPublished}
            />
            &nbsp;&nbsp;
            {this.state.onlyPublishedItems ? (
              <b>Published</b>
            ) : (
              <b>Not-Published</b>
            )}
          </div>
        );
        // }
      }
    }

    let fetch;
    if ( this.state.deviceSize === 'big') {
      fetch = "Fetch";
    } else {
      fetch = "Get";
    }

    let searchPanel;
    searchPanel = (
      <div>
        <div className="catalog-filter-header">
          Filter Catalog Items &nbsp;{cancelBtn}
        </div>
        {itemTypePanel}
        {publishPanel}
        <div className="row publish-row">
          <div className="col text-center radio-fonts">
            <strong>Select by &nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="all"
                  checked={this.state.filterType === "all"}
                  onChange={this.handleFilterType}
                />{" "}
                All
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="name"
                  checked={this.state.filterType === "name"}
                  onChange={this.handleFilterType}
                />{" "}
                Name
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="category"
                  checked={this.state.filterType === "category"}
                  onChange={this.handleFilterType}
                />{" "}
                Category
              </label>
            </div>
 
            <button
              className="btn_reg_60"
              onClick={this.handleFilter}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {fetch}
            </button>
            <div className="item-filter-space" />
            {categoryPanel}
            {namePanel}
            &nbsp;
 
          </div>
        </div>
      </div>
    );

    let outputPanel = <div className="catalog-search-box">{searchPanel}</div>;

    return <div>{outputPanel}</div>;
  }
}

export default CatalogItemFilter;
