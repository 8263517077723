import React, { Component } from "react";

import axios from "axios";

import "./BasicCompReview.css";

import fbicon from "../../images/icons8-facebook-48.png";
import insticon from "../../images/instagram.png";
import twiticon from "../../images/icons8-twitter-48.png";
import youicon from "../../images/youtube.png";
import storeicon from "../../images/store.jpg";
import websiteicon from "../../images/website.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCampaignFooter = "/routes/interact/getCampaignFooter?";

class BasicCompReview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showReviewFlag: true,

      bannerLocation: "",
      imageLocation: "",

      introbgColor: "",
      isPersonal: false,
      salute: "",
      signoff: "",

      footer: null,

      bodyForm: null,
    };
  }

  componentDidMount = async () => {
    let bannerLocation = "";
    let imageLocation = "";
    let introbgColor = "";
    let isPersonal = false;
    let salute = "";
    let bodyForm = null;

    let bmc = this.props.theBroadcast.mailComposition;
    if (bmc) {
      if (this.props.theBroadcast.mailComposition.fileUploads.length > 0) {
        this.props.theBroadcast.mailComposition.fileUploads.forEach((obj) => {
          if (obj.compositionComponent === "banner") {
            bannerLocation = obj.location;
          }
          if (obj.compositionComponent === "basicCompImage") {
            imageLocation = obj.location;
          }
        });
      }

      introbgColor = bmc.introBodyFormat.bgColor;
      if (bmc.interactionType === "personal") {
        isPersonal = true;
        salute = bmc.salute + " " + this.props.yourName + ", ";
      }

      bodyForm = bmc.introBodyFormat;
    }

    await this.getFooter();

    this.setState({
      bannerLocation,
      imageLocation,
      introbgColor,
      isPersonal,
      salute,
      bodyForm,
    });
  };

  getFooter = async () => {
    let param = "communityId=" + this.props.communityId;
    let url = baandaServer + getCampaignFooter + param;
    try {
      let fret = await axios.get(url);
      if (fret.data.status === "success") {
        this.setState({
          footer: fret.data.Msg.campaignFooterObj,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: fret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  exitFromPopup = () => {
    this.props.returnToCaller();
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    let backFromTemplatePop = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitFromPopup}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let bannerPanel;
    bannerPanel = (
      <div className="text-center">
        <img
          src={this.state.bannerLocation}
          alt="banner"
          hight="50"
          width="457"
          className="basic-review-banner-pos"
        />
      </div>
    );

    // style={{
    //   color: this.state.color,
    //   width: wd,
    //   height: ht,
    //   border: bdr,
    //   backgroundColor: this.state.bgColor,
    //   fontFamily: this.state.fontFamily,
    //   fontSize: this.state.fontSize,
    // }}
    let bcb = this.props.theBroadcast.mailComposition;

    let introBodyFontSize, introBodyFontFamily;
    if (this.state.bodyForm) {
      introBodyFontSize = this.state.bodyForm.fontSize;
      introBodyFontFamily = this.state.bodyForm.fontFamily;
    }

    let titleFontSize,
      titleColor,
      titlebgColor,
      titleIsBold,
      titleFontFamily,
      titleAlign;
    if (bcb.introTitleFormat) {
      titleFontSize = bcb.introTitleFormat.fontSize;
      titleColor = bcb.introTitleFormat.color;
      titlebgColor = bcb.introTitleFormat.bgColor;
      titleIsBold = bcb.introTitleFormat.isBold;
      titleFontFamily = bcb.introTitleFormat.fontFamily;
      titleAlign = bcb.introTitleFormat.align;
    }

    let introTitlePanel;
    if (bcb.introTitle !== "") {
      introTitlePanel = (
        <div
          style={{
            backgroundColor: titlebgColor,
            width: "457px",
            fontSize: titleFontSize,
            fontFamily: titleFontFamily,
            color: titleColor,
            textAlign: titleAlign,
          }}
          className="comp-title-adj"
        >
          {titleIsBold ? <b>{bcb.introTitle}</b> : bcb.introTitle}
        </div>
      );
    }

    let introPersonalizePanel;
    if (this.state.isPersonal) {
      introPersonalizePanel = (
        <div
          style={{
            backgroundColor: this.state.introbgColor,
            width: "457px",
            fontSize: introBodyFontSize,
            fontFamily: introBodyFontFamily,
          }}
        >
          <div>{this.state.salute}</div>
          <div>&nbsp;</div>
        </div>
      );
    }

    let introPanel;
    introPanel = (
      <div
        style={{
          backgroundColor: this.state.introbgColor,
          width: "457px",
          fontSize: "9pt",
          paddingLeft: "5px",
          paddingRight: "10px",
          fontFamily: introBodyFontFamily,
          color: "#3262a8",
        }}
      >
        <div> {bcb.introBody} </div>
        <div>&nbsp;</div>
        {/* {bcb.introBody} */}
        {/* <p align="justify"> {bcb.introBody}</p> */}
      </div>
    );

    let topPanel;
    topPanel = (
      <div
        style={{
          backgroundColor: this.state.introbgColor,
          width: "360px",
          marginLeft: "3px",
          paddingLeft: "3px",
        }}
      >
        {introTitlePanel}
        {introPersonalizePanel}
        {introPanel}
      </div>
    );

    let imagePanel;

    if (this.state.imageLocation !== "") {
      imagePanel = (
        <div className="image-panel-back">
          <img
            src={this.state.imageLocation}
            alt="banner"
            hight="auto"
            width="457px"
            marginLeft="3px"
          />
        </div>
      );
    }

    let signoffPanel;
    if (
      this.props.theBroadcast.mailComposition.interactionType === "personal"
    ) {
      signoffPanel = (
        <div className="">
          <div className="row">
            <div className="col text-left basic-comp-signoff-rev">
              {this.props.theBroadcast.mailComposition.signoff}
            </div>
          </div>
          <div className="row">
            <div className="col text-left basic-comp-signoff-name">
              {this.props.theBroadcast.mailComposition.senderName}
            </div>
          </div>
          <div className="row">
            <div className="col text-left basic-comp-signoff-position">
              ( {this.props.theBroadcast.mailComposition.senderPosition} )
            </div>
          </div>
        </div>
      );
    }

    let arr = this.props.theBroadcast.mailComposition.basicCompBody;
    let listPanel = (
      // <div className="comp-body-list-box">
      <div
        style={{
          backgroundColor: "#ffffff",
          marginLeft: "3px",
          paddingLeft: "3px",
          paddingRight: "15px",
          width: "457px",
        }}
      >
        {arr.map((obj, i) => (
          <div key={i}>
            <div className="row ">
              <div className="col">
                {obj.title !== "" ? (
                  <div className="row">
                    <div
                      className="col comp-body-title"
                      style={{
                        backgroundColor: obj.titleFormat.bgColor,
                        textAlign: obj.titleFormat.align,
                        color: obj.titleFormat.color,
                        fontFamily: obj.titleFormat.fontFamily,
                        fontSize: obj.titleFormat.fontSize,
                      }}
                    >
                      {obj.title}
                    </div>
                  </div>
                ) : null}
                {obj.writeup !== "" ? (
                  <div className="row">
                    <div
                      className="col comp-body-title"
                      style={{
                        backgroundColor: obj.writeupFormat.bgColor,
                        textAlign: obj.writeupFormat.align,
                        color: obj.writeupFormat.color,
                        fontFamily: obj.writeupFormat.fontFamily,
                        fontSize: obj.writeupFormat.fontSize,
                      }}
                    >
                      {obj.writeup}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <br />
          </div>
        ))}
        {signoffPanel}
      </div>
    );

    let footerPanel;
    if (this.state.footer) {
      footerPanel = (
        <div
          className="footer-box"
          style={{
            backgroundColor: "#ffffff",
            marginLeft: "3px",
            paddingLeft: "3px",
            paddingRight: "15px",
            width: "457px",
          }}
        >
          <div className="text-center">
            {this.state.footer.activeFacebook ? (
              <a
                href={this.state.footer.facebookLink}
                target="_blank"
                rel="noreferrer"
              >
                <img src={fbicon} alt="fbicon" hight="auto" width="30px" />
              </a>
            ) : null}
            &nbsp;
            {this.state.footer.activeInstagram ? (
              <a
                href={this.state.footer.instagramLink}
                target="_blank"
                rel="noreferrer"
              >
                <img src={insticon} alt="insticon" hight="auto" width="30px" />
              </a>
            ) : null}
            {this.state.footer.activeTwitter ? (
              <a
                href={this.state.footer.twitterLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={twiticon} alt="insticon" hight="auto" width="30px" />
              </a>
            ) : null}
            {this.state.footer.activeYoutube ? (
              <a
                href={this.state.footer.youtubeLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={youicon} alt="insticon" hight="auto" width="30px" />
              </a>
            ) : null}
            {this.state.footer.activeStore ? (
              <a
                href={this.state.footer.storeLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img src={storeicon} alt="insticon" hight="auto" width="35px" />
              </a>
            ) : null}
            {this.state.footer.activeWebsite ? (
              <a
                href={this.state.footer.websiteLink}
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  src={websiteicon}
                  alt="insticon"
                  hight="auto"
                  width="35px"
                />
              </a>
            ) : null}
          </div>
          <div className="text-center comp-footer-msg">
            <p className="comp-footer-padding" align="justify">
              {this.state.footer.footerMessage}
            </p>
          </div>
          <div className="text-center comp-unsubscribe-msg">
            <u>Unsubscribe</u>
          </div>
        </div>
      );
    }

    let showTemplatePanel;
    showTemplatePanel = (
      <div className="basic-email-review">
        <div className="template-pop-header text-center">
          Email Review &nbsp;{backFromTemplatePop}
        </div>
        {bannerPanel}
        {topPanel}
        {imagePanel}

        {listPanel}
        {footerPanel}
      </div>
    );

    let outputPanel;
    outputPanel = <div>{showTemplatePanel}</div>;

    return <div>{outputPanel}</div>;
  }
}

export default BasicCompReview;
