import React, { Component } from 'react'

import queryString from "query-string";

class TestCampaign extends Component {
  constructor(props){
    super(props);

    this.state = {

    }
  }
  componentDidMount = async () => {
    const values = queryString.parse(this.props.location.search);
    console.log("1. Login onmount values:", values);
    if (!this.isObjEmpty(values)) {
       
        this.setState({
          communityId: values.communityId,
          broadcastId: values.broadcastId,
          opsType: values.opstype,
          message: "",
          errFlag: false,
        });
      
    }  
  };

  isObjEmpty = (obj) => {
    let len = Object.getOwnPropertyNames(obj).length;
    if (len > 0) return false;
    return true;
  };

  render() {
    console.log('this.props:', this.props);
    console.log('this.state:', this.state);
    return (
      <div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        TestCampaign
        
      </div>
    )
  }
}

export default TestCampaign;
