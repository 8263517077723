import React, { Component } from "react";

import "./ViewQuote.css";

class ViewQuote extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalQuote: 0.0,
      //   lastChangeOrderNo: 0,
      inputdata: null,

      currQuoteNo: 0,

      deviceSize: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let totalQuote = 0;
    this.props.inputdata.lineItems.forEach((obj) => {
      totalQuote += parseFloat(obj.lineItemCost);
    });

    this.setState({
      totalQuote,
      deviceSize,
      inputdata: this.props.inputdata,
      //   lastChangeOrderNo: this.props.inputdata.changeOrderSeqno,
      currQuoteNo: this.props.inputdata.changeOrderSeqno,
    });
  };

  loadDataForView = async (direction) => {
    let currQuoteNo = this.state.currQuoteNo;
    if (direction === "prev") {
      currQuoteNo--;
    } else if (direction === "next") {
      currQuoteNo++;
    }

    let ttq = this.state.inputdata.theTask.quotes[currQuoteNo - 1];

    let lineItems = [];
    ttq.lineItem.forEach((li) => {
      let liobj = {
        lineItemCost: li.cost,
        lineItemNo: li.seqno,
        lineItemDescription: li.description,
        lineItemMilestone: li.milestone,
      };
      lineItems.push(liobj);
    });

    let lineTerms = [];
    ttq.lineTerms.forEach((lt) => {
      let ltobj = {
        termLineItemNo: lt.seqno,
        termDescription: lt.description,
      };
      lineTerms.push(ltobj);
    });

    let input = {
      changeOrderSeqno: this.state.changeOrderSeqno,
      lineItems,
      lineTerms,
      serviceAt: {
        street: ttq.serviceAddrStreet,
        city: ttq.serviceAddrCity,
        state: ttq.serviceAddrState,
        zip: ttq.serviceAddrZip,
      },
      contingency: {
        maxDelay: parseFloat(ttq.maxDelayAllowed),
        percent: parseFloat(ttq.contingencyPercent),
      },
      startByAfterRetained: parseFloat(ttq.startByAfterRetained),
      retainerPer: parseFloat(ttq.retainerPercent),
      invoiceAt: ttq.invoiceAt,
      taxAmount: parseFloat(ttq.estimatedTaxAmount),
    };

    this.setState({
      inputdata: input,
      currQuoteNo,
    });
  };

  returnToQuote = () => {
    this.props.returnToCaller();
  };

  formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");

    //Check if the input is of correct
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      //Remove the matched extension code
      //Change this to format for any country code.
      let intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }

    return null;
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  render() {
    // console.log("this.props:", this.props); 
    // console.log("this.state:", this.state);
    console.log('ViewQuote...');

    let exitButton = (
      <button
        onClick={this.returnToQuote}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
       <i className="fas fa-step-backward" />
      </button>
    );

    let prevButton;
    if (this.state.currQuoteNo > 0) {
      prevButton = (
        <button
          onClick={this.handlePrevious}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          className="btn_reg_40"
        >
          <i className="fa fa-step-backward" />
        </button>
      );
    }

    let nextButton;
    if (
      this.state.theTask &&
      this.state.currQuoteNo < this.state.theTask.quotes.length
    ) {
      nextButton = (
        <button
          onClick={this.handlePrevious}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          className="btn_reg_40"
        >
          <i className="fa fa-step-forward" />
        </button>
      );
    }

    let baandaid, totalestimate;
    if (this.state.deviceSize === "big") {
      baandaid = "BaandaId";
      totalestimate = "Total Estimate";
    } else {
      baandaid = "B-Id";
      totalestimate = "Total";
    }

    let upperPanel = (
      <div>
        <div className="row upper-panel-quote-view">
          <div className="col-md-6">
            <div className="text-center quote-view-target-head">
              Quotation Target
            </div>
            <div className="row quote-top-row-pos">
              <div className="col-3 text-right quote-view-target-lbl">Name</div>
              <div className="col-9 text-left quote-view-target-txt">
                {this.props.inputdata.theTask.targetContact.name}
              </div>
            </div>
            <div className="row quote-top-row-pos">
              <div className="col-3 text-right quote-view-target-lbl">
                Email
              </div>
              <div className="col-9 text-left quote-view-target-txt">
                {this.props.inputdata.theTask.targetContact.email}
              </div>
            </div>
            <div className="row quote-top-row-pos">
              <div className="col-3 text-right quote-view-target-lbl">Cell</div>
              <div className="col-9 text-left quote-view-target-txt">
                {this.formatPhoneNumber(
                  this.props.inputdata.theTask.targetContact.cell
                )}
              </div>
            </div>
            <div className="row quote-top-row-pos">
              <div className="col-3 text-right quote-view-target-lbl">
                {baandaid}
              </div>
              <div className="col-9 text-left quote-view-target-txt">
                {this.props.inputdata.theTask.targetContact.baandaId}
              </div>
            </div>
            <div className="text-center quote-view-target-head">Service At</div>
            <div className="row quote-top-row-pos">
              <div className="col-3 text-right quote-view-target-lbl">
                Address
              </div>
              <div className="col-9 text-left quote-view-target-txt">
                {this.props.inputdata.serviceAt.street},{" "}
                {this.props.inputdata.serviceAt.city},{" "}
                {this.props.inputdata.serviceAt.state}{" "}
                {this.props.inputdata.serviceAt.zip}
              </div>
            </div>
            <div className="row quote-top-row-pos">
              <div className="col-3 text-right quote-view-target-lbl">
                Proj Mgr.
              </div>
              <div className="col-9 text-left quote-view-manager-txt">
                {this.props.inputdata.manager.pmName} (
                {this.props.inputdata.manager.pmEmail} |{" "}
                {this.formatPhoneNumber(this.props.inputdata.manager.pmCell)} |{" "}
                {this.props.inputdata.manager.pmBaandaId})
              </div>
            </div>
          </div>

          {/* <div className="col-1 text-center q-view-vline"></div> */}
          <div className="col-md-6">
            <div className="text-center quote-view-target-head">Summary</div>
            <div className="row quote-top-row-pos">
              <div className="col-6 text-right quote-view-target-lbl-r">
                Line total
              </div>
              <div className="col-6 text-left quote-view-target-txt-r">
                $ {this.commaFormattedCurrency(this.state.totalQuote)}
              </div>
            </div>
            <div className="row quote-top-row-pos">
              <div className="col-6 text-right quote-view-target-lbl-r">
                Contingency
              </div>
              <div className="col-6 text-left quote-view-target-txt-r">
                ${" "}
                {this.commaFormattedCurrency(
                  this.state.totalQuote *
                    (this.props.inputdata.contingency.percent / 100)
                )}
              </div>
            </div>
            <div className="row quote-top-row-pos">
              <div className="col-6 text-right quote-view-target-lbl-r">
                Tax Estimamte
              </div>
              <div className="col-6 text-left quote-view-target-txt-r">
                $ {this.commaFormattedCurrency(this.props.inputdata.taxAmount)}
              </div>
            </div>
            <div className="row quote-top-row-pos">
              <div className="col-6 text-right quote-view-target-lbl-r">
                {totalestimate}
              </div>
              <div className="col-6 text-left quote-view-target-txt-r">
                ${" "}
                {this.commaFormattedCurrency(
                  this.state.totalQuote +
                    this.props.inputdata.taxAmount +
                    this.state.totalQuote *
                      (this.props.inputdata.contingency.percent / 100)
                )}
              </div>
            </div>

            <div className="row quote-top-row-pos">
              <div className="col-6 text-right quote-view-target-lbl-r">
                Retainer
              </div>
              <div className="col-6 text-left quote-view-target-txt-r">
                ${" "}
                {this.commaFormattedCurrency(
                  this.state.totalQuote *
                    (this.props.inputdata.retainerPer / 100)
                )}
              </div>
            </div>

            <div className="row quote-top-row-pos-2">
              <div className="col text-center quote-view-target-lbl-r">
                Max delay allowed without penalty is{" "}
                {this.props.inputdata.contingency.maxDelay} days.
              </div>
            </div>
            <div className="row quote-top-row-pos">
              <div className="col text-center quote-view-target-lbl-r">
                Project starts by {this.props.inputdata.startByAfterRetained}{" "}
                days of being retained.
              </div>
            </div>
            <div className="row quote-top-row-pos">
              <div className="col text-center quote-view-target-lbl-r">
                The task will require approimately{" "}
                {this.props.inputdata.daysToFinishTheTask} days.
              </div>
            </div>
          </div>
        </div>
        <hr className="new1" />
      </div>
    );


    let bool = true;
    let lineItemsListPanel = (
      <div>
        <div className="text-center view-conditions-pos">
          Service Line Items
        </div>
        {this.state.deviceSize === "big" ? (
          <div className="row quote-list-header">
            <div className="col-1 text-center quote-list-item-view-lbl">#</div>
            <div className="col-9 text-left quote-list-item-view-lbl">
              Description
            </div>
            <div className="col-2 text-center quote-list-item-view-lbl">
              Amount
            </div>
          </div>
        ) : (
          <div className="row quote-list-header">
            <div className="col-1 text-right quote-list-item-view-lbl-left">#</div>
            <div className="col-9 text-left quote-list-item-view-lbl-desc">
              Description
            </div>
            <div className="col-2 text-center quote-list-item-view-lbl-right">
              Amt
            </div>
          </div>
        )}

        {/* <div className="quote-list-box"> */}
        <div className="view-item-list-rows">
          {this.props.inputdata.lineItems.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    bool
                      ? "row row-col-item-list-dr"
                      : "row row-col-item-list-lt"
                  }
                >
                  <div className="col-1 text-left quote-list-item-view-lbl">
                    &nbsp;{obj.lineItemNo}.&nbsp;
                  </div>
                  <div className="col-9 text-left quote-view-lineitem-desc">
                    {obj.lineItemDescription}.{" "}
                    {obj.lineItemMilestone ? (
                      <i className="fa fa-star" />
                    ) : null}
                  </div>
                  <div className="col-2 text-right quote-view-lineitem-cost">
                    {this.commaFormattedCurrency(obj.lineItemCost)}
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let bool1 = true;
    let conditionsListPanel = (
      <div>
        <div className="text-center view-conditions-pos">
          Terms & Conditions
        </div>
        <div className="row quote-view-condition-header">
          <div className="col-1 text-center quote-list-item-view-lbl"># </div>
          <div className="col-11 text-left quote-list-item-view-lbl">
            Description
          </div>
        </div>
        <div className="view-conditions-rows">
          {this.props.inputdata.lineTerms.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    bool1
                      ? "row row-col-item-list-dr"
                      : "row row-col-item-list-lt"
                  }
                >
                  <div className="col-1 text-left quote-list-item-lbl">
                    {obj.termLineItemNo}.&nbsp;
                  </div>
                  <div className="col-11 text-left quote-view-condition-des">
                    {obj.termDescription}.
                  </div>
                </div>
                {(bool1 = !bool1)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let quoteViewHeaderPanel;
    quoteViewHeaderPanel = (
      <div className="row">
        <div
          className={
            this.state.deviceSize === "big"
              ? "col-md-6 test-right quote-view-overall-header"
              : "col-md-6 test-center quote-view-overall-header"
          }
        >
          Quotation | Curr CO#: {this.state.currQuoteNo}
        </div>
        <div
          className={
            this.state.deviceSize === "big"
              ? "col-md-6 test-left quote-view-overall-header"
              : "col-md-6 test-center quote-view-overall-header"
          }
        >
          {exitButton}&nbsp;&nbsp;{prevButton}&nbsp;View&nbsp;{nextButton}
        </div>
      </div>
    );

    let controllerMsg = "";
    if (this.props.inputdata.theTask.controller === "seller")
      controllerMsg = "Seller working on it";
    else controllerMsg = "Customer to react.";

    let outputPanel;
    outputPanel = (
      <div className="biz-task-quote-view-box">
        {quoteViewHeaderPanel}
        {upperPanel}
        {lineItemsListPanel}
        {conditionsListPanel}
        <div className="text-center task-view-controller">{controllerMsg}</div>
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

export default ViewQuote;
