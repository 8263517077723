import React, { Component } from "react";

import axios from "axios";

import "./MakeTeam.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getMembersOfCommunity = "/routes/dashboard/getMembersOfCommunity?";
const saveUpdtEvent = "/routes/interact/saveUpdtEvent";

class MakeTeam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      theEvent: null,
      theTeam: [],
      members: [],
      memberName: "",
      selectedMember: null,
      memberSelHeight: 4,
      updtMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let theTeam = [];

    if (this.props.communityId && this.props.communityId > 0)
      await this.getMembers();

    if (this.props.theEvent && this.props.theEvent.theTeam)
      theTeam = this.props.theEvent.theTeam;

    await this.setState({
      theEvent: this.props.theEvent,
      theTeam,
    });

    if (document.getElementById("taskMemSelected"))
      document.getElementById("taskMemSelected").value = "DEFAULT";
  };

  onChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
  };

  getMembers = async () => {
    // console.log("getMembers this.props:", this.props);
    // console.log("getMembers this.props.communityId:", this.props.communityId);
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&memberName=" +
      this.state.memberName +
      "&clientProgram=SelectCartHolder&clientFunction=getMembers";
    let url = baandaServer + getMembersOfCommunity + params;
    // console.log("url:", url);
    try {
      let mems = await axios.get(url);
      // console.log("mems:", mems);
      await this.setState({
        members: mems.data.Msg,
      });
    } catch (err) {
      // console.log("Err:", err.message);
    }
  };

  packDataForDB = async () => {
    let input = {
      communityId: this.props.communityId,
      eventId: this.state.theEvent.eventId,
      theTeam: this.state.theTeam,
      module: "teamdefine",
      clientProgram: "MakeTeam.js",
      clientFunction: "handleTeamSave",
    };

    return input;
  };

  handleTeamSave = async () => {
    let url = baandaServer + saveUpdtEvent;

    let inp = await this.packDataForDB();
    // console.log('inp:', inp);
    // console.log(" url:", url, " inp:", inp);
    try {
      let resp = await axios.post(url, inp);
      // console.log("rest:", resp);
      if (resp.data.status === "success") {
        this.setState({
          updtMsg: "Team infomation updated.",
        });
        // this.props.returnToCaller(this.state.theEvent);
      }
    } catch (err) {
      this.setState({
        updtMsg: err.message,
        errFlag: true,
      });
    }
  };

  createTheTaskTeam = async (obj) => {
    // console.log('>>> obj:', obj);
    let theTeam = this.state.theTeam;
    let exists = false;
    this.state.theTeam.forEach((elm) => {
      // console.log('>>> elm:', elm);
      if (obj.baandaId === elm.baandaId) exists = true;
    });
    if (!exists) {
      let memObj = {
        name: obj.name,
        email: obj.email,
        baandaId: obj.baandaId,
      };
      theTeam.push(memObj);
    }
    await this.setState({
      theTeam,
      selectedMember: obj,
    });
  };

  handleMemberSelect = async () => {
    let value = document.getElementById("taskMemSelected").value;
    let obj = JSON.parse(value);
    // console.log("obj:", obj);

    await this.createTheTaskTeam(obj);
    if (document.getElementById("taskMemSelected"))
      document.getElementById("taskMemSelected").value = "DEFAULT";
  };

  handleMemberDelete = async (mem) => {
    // console.log("======= mem:", mem);
    let newTeam = [];
    this.state.theTeam.forEach((obj) => {
      if (obj.baandaId !== mem.baandaId) {
        newTeam.push(obj);
      }
    });
    await this.setState({
      theTeam: newTeam,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("MakeTeam...");

    let sellist;
    if (this.state.members && this.state.members.length > 0) {
      sellist = this.state.members.map((obj, i) => {
        return (
          <option key={i + 2} value={JSON.stringify(obj)}>
            {obj.name}&nbsp;({obj.email})
          </option>
        );
      });
    }

    let memberDropdownPanel;
    memberDropdownPanel = (
      <div>
        <div className="row">
          <div className="col">
            <select
              size={this.state.memberSelheight}
              // onFocus={this.onfocus}
              // onBlur={this.onblur}
              id="taskMemSelected"
              name="taskMemSelected"
              onChange={this.handleMemberSelect}
              className="task-team-selection"
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select members for the task
              </option>
              {sellist}
            </select>
          </div>
        </div>
      </div>
    );
    let selectPanel;
    selectPanel = (
      <div>
        <div className="row">
          <div className="col text-center">
            <input
              name="memberName"
              type="text"
              value={this.state.memberName}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="member-for-tasks"
              placeholder="Filter by name ..."
            />
            &nbsp;&nbsp;
            <button onClick={this.getMembers} className="btn_reg_60">
              Filter
            </button>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col">{memberDropdownPanel}</div>
        </div>
      </div>
    );

    let buttonPanel;
    // console.log('this.props.theEvent.eventStatus:', this.props.theEvent.eventStatus)
    if (
      this.props.theEvent.eventStatus === "published" ||
      this.props.theEvent.eventStatus === "canceled"
    ) {
      buttonPanel = (
        <div className="text-center upload-published-msg">
          This event has been published. Task management is inactive. You can
          copy and re-publish if needed.
        </div>
      );
    } else {
      buttonPanel = (
        <button
          onClick={this.handleTeamSave}
          className="btn_reg_70"
          type="button"
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          Save
        </button>
      );
    }

    let bool = true;
    let teamPanel;
    teamPanel = (
      <div className="cal-task-team-box">
        {this.state.theTeam.map((obj, i) => (
          <div key={i}>
            <div
              className={`${
                bool ? "row-tasteam-member-a" : "row-tasteam-member-b"
              }`}
            >
              <div className="row">
                <div className="col-10 text-left pickup-text">
                  {obj.name}&nbsp;({obj.email})
                </div>
                <div className="col-2 text-center">
                  <button
                    onClick={() => this.handleMemberDelete(obj)}
                    className="btn-message-delete"
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    <i className="fas fa-trash-alt" />
                  </button>
                </div>
              </div>
            </div>
            {(bool = !bool)}
          </div>
        ))}
      </div>
    );

    let outputPanel = (
      <div>
        {selectPanel}
        {teamPanel}
        {buttonPanel}
        <div className="team-update-msg">{this.state.updtMsg}</div>
      </div>
    );

    return (
      <div>
        <h5>Make Task Team</h5>
        {outputPanel}
      </div>
    );
  }
}

export default MakeTeam;
