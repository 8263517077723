import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import { sortArrayOfDates } from "../../../../utils/sortArrayOfDates";

import "./TalkLog.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveLogTalk ="/routes/coop/saveLogTalk";

class TalkLog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      store: null,
      talk: "",
      talkLog: [],

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async() => {
    let talkLog = sortArrayOfDates(this.props.store.talkLog, "Date", "dsc");
    // console.log('talklog:', talkLog);
    this.setState({
      talkLog
    })
  }

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleTalkLog = async() => {
    if ( this.state.talk.length > 0 ) {
      let input = {
        communityId: this.props.store.communityId,
        coopStoreId: this.props.store.coopStoreId,
        talk: this.state.talk,
        baandaId: this.props.auth.user.baandaId,
        name : this.props.auth.user.name
      }
      let url = baandaServer + saveLogTalk;
      // console.log('url:', url, ' input:', input);
      try {
        let talkret = await axios.post(url, input);
        if ( talkret.data.status === 'success') {
          this.props.returnToCaller({ from: 'talklog'})
        } else {
          this.setState({
            errMsg: talkret.data.Msg,
            errFlag: true
          });
        }
      }catch(err){
        this.setState({
          errMsg: err.message,
          errFlag: true
        });
      }

    }
  }

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('TalkLog...');

    let saveButton;
    saveButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleTalkLog}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let headerPanel;
    headerPanel = (
      <div className="text-center coop-talklog-head">
        Talk Log &nbsp;&nbsp;&nbsp;{saveButton}
      </div>
    );

    let talkPanel = (
      <div className="row coop-talklog-talk-row">
        <div className="col-1 text-right coop-talklog-lbl">Talk</div>
        <div className="col-11 text-left">
          <textarea
            name="talk"
            maxLength="2000"
            placeholder=""
            rows="2"
            wrap="hard"
            spellCheck="true"
            className="coop-talklog-talk"
            onChange={this.onChange}
            value={this.state.talk}
            required
          />
        </div>
      </div>
    );

    let bool = true;
    let showTalkLog;
    showTalkLog = (
      <div className="keeper-admin-qna-show-box">
        {this.state.talkLog.map((obj, i) => {
          return (
            <div key={i}>
              <div
                className={
                  bool
                    ? "row row-col-coop-admin-dr"
                    : "row row-col-coop-admin-lt"
                }
              >
                <div className="col">
                  <div className="row">
                    <div className="col text-left coop-talklog-name">
                      By: {obj.byName} on: {obj.Date.substring(0, 10)}
                    </div>
                  </div>

                  <div className="text-left coop-talklog-message">
                    {obj.message}
                  </div>
                </div>
              </div>
              {(bool = !bool)}
            </div>
          );
        })}
      </div>
    );

    let outputPanel = (
      <div className="keeper-talk-log-box">
        {headerPanel}
        {talkPanel}
        {showTalkLog}
        <div className={this.state.errFlag ? "text-center coop-str-msg-err" : "text-center coop-str-msg"}>{this.state.errMsg}</div>
      </div>
    );

    return (
      <div>{outputPanel}</div>
    )
  }
}

TalkLog.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

//   const mapDispatchToProps = (dispatch) => ({
//     hideModal: () => dispatch(hideModal()),
//     showModal: (modalProps, modalType) => {
//       dispatch(showModal({ modalProps, modalType }));
//     },
//   });

export default connect(mapStateToProps, null)(withRouter(TalkLog));
