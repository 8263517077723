import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import BuyReturnPostage from "./BuyReturnPostage";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./ConductRefundCancel.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const processRefundCancelResponse =
  "/routes/finance/processRefundCancelResponse";
const getCustomerFinIntel = "/routes/finance/getCustomerFinIntel?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "11131111112";

class ConductRefundCancel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      retCanResponseOps: "moreinfo",
      itemReceived: false,

      responseComment: "",
      opsConfirmed: false,
      amountReturned: 0.0,
      fateOfReturnedItem: "restack",
      maxAmount: 0,

      purchasePostFlag: false,

      errMsg: "",
      errFlag: false,

      showRespondFlag: true,
      canRequestReturnPostage: false,

      custIntel: null,
      custIntelMsg: "",

      retPostageExists: false,
      message: "",
      selectedForRefund: null,
      trackingUrl: "",
      procuredReturnPost: false,

      approveDirection: "Click 'Approve' or 'Decline' to finalize decision. Must 'Confirm Operation' & click Respond to process."
    };
  }

  componentDidMount = async () => {
    let maxAmount = 0;
    if (this.props.theInvoice.initPayMedium === "creditcard") {
      if (
        this.props.theInvoice.creditCardObject &&
        this.props.theInvoice.creditCardObject.stripeResponse &&
        this.props.theInvoice.creditCardObject.stripeResponse.paymentConfirm
      ) {
        maxAmount =
          this.props.theInvoice.creditCardObject.stripeResponse.paymentConfirm
            .amount / 100;
      } else {
        maxAmount = this.props.theInvoice.financeBreakdown.amountPaid;
      }
    } else {
      maxAmount = this.props.theInvoice.financeBreakdown.amountPaid;
    }

    let canRequestReturnPostage = false;
    let retcalId = parseFloat(this.props.returnCancel.refundCancelId);
    this.props.theInvoice.refunds.forEach((elm) => {
      if (
        retcalId === parseFloat(elm.refundCancelId) &&
        elm.requestReturnPostage
      )
        canRequestReturnPostage = true;
    });

    await this.getCustomerIntel();
    let custIntelMsg = "";
    if (this.state.custIntel) {
      custIntelMsg =
        "Total Goods-Services= $" +
        this.commaFormattedCurrency(this.state.custIntel.GoodsSales) +
        " Cancel= $" +
        this.commaFormattedCurrency(this.state.custIntel.cancelAmount) +
        " Refund= $" +
        this.commaFormattedCurrency(this.state.custIntel.refundAmount) +
        " Tasks= $" +
        this.commaFormattedCurrency(this.state.custIntel.taskAmount);
    }

    let retPostageExists = false;
    let procuredReturnPost = false;
    this.props.theInvoice.refunds.forEach((ref) => {
      if (ref.refundCancelId === this.props.returnCancel.refundCancelId)
        retPostageExists = this.props.returnCancel.requestReturnPostage;
      if (ref.returnPostageObject) {
        procuredReturnPost = true;
      }
    });

    let message = "";
    let selectedForRefund = null;
    let trackingUrl = "";
    this.props.theInvoice.refunds.forEach((ref) => {
      if (ref.refundCancelId === this.props.refundCancelId) {
        selectedForRefund = ref;
        if (ref.customerRefundDoneFlag) {
          message = "Customer refund actions are complete.";
        } else {
          message = "Customer needs to complete refund tasks.";
        }
        if (
          ref.returnPostageObject &&
          ref.returnPostageObject.carrierName !== ""
        ) {
          trackingUrl = ref.returnPostageObject.trackingUrl;
        }
      }
    });

    this.setState({
      maxAmount,
      amountReturned: maxAmount,
      canRequestReturnPostage,
      custIntelMsg,
      retPostageExists,
      message,
      trackingUrl,
      selectedForRefund,
      procuredReturnPost,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getCustomerIntel = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&baandaId=" +
      this.props.theInvoice.invoiceOfId +
      "&coopStoreId=" +
      coopStoreId;
    let url = baandaServer + getCustomerFinIntel + params;
    try {
      let cret = await axios.get(url);
      if (cret.data.status === "success") {
        this.setState({
          custIntel: cret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: cret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  onChangePrice = async (e) => {
    let value = e.target.value;
    if (value > this.state.maxAmount) {
      this.setState({
        errMsg:
          "Refund amount cannot exceed the max refundable amount of the invoice.",
        errFlag: true,
      });
    } else {
      if (this.countDecimals(value) > 2) {
        let tmp = parseFloat(value).toFixed(2);
        let val = parseFloat(tmp);
        this.setState({
          [e.target.name]: val,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          [e.target.name]: value,
          errMsg: "",
          errFlag: false,
        });
      }
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleSelectOps = async (e) => {
    await this.setState({
      retCanResponseOps: e.target.value,
    });
  };

  handleTodoWithReturnedItem = async (e) => {
    await this.setState({
      fateOfReturnedItem: e.target.value,
    });
  };

  handleItemReceived = async () => {
    await this.setState((prevstate) => ({
      itemReceived: !prevstate.itemReceived,
    }));
  };

  handleOpsConfirmed = async () => {
    await this.setState((prevstate) => ({
      opsConfirmed: !prevstate.opsConfirmed,
    }));
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  validateRefundOps = async () => {
    let msg = "";
    let isValid = "success";
    if (this.state.responseComment.length < 5) {
      msg = "Must have a comment of 5 chars or more.";
      isValid = "error";
    }

    return { status: isValid, Msg: msg };
  };

  handleRefundCancelResponse = async () => {
    let isValid = await this.validateRefundOps();
    if (isValid.status === "success") {
      this.setState({
        errMsg: "",
        errFlag: false,
      });
      await this.processCanelRefund();
      // console.log("Done processing ... processCanelRefund");
      // this.props.returnToCaller("done");
    } else {
      this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }
  };

  processCanelRefund = async () => {
    let url = baandaServer + processRefundCancelResponse;

    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let theInvoice = null;
    let returnCancel = null;
    let fateOfReturnedItem = "";
    if (this.state.retCanResponseOps === "approved") {
      theInvoice = this.props.theInvoice;
      returnCancel = this.props.returnCancel;
      // Restack only if there are subitems that needs that restacking.
      if (theInvoice.invoiceRaw)
        fateOfReturnedItem = this.state.fateOfReturnedItem;
    }
    if (this.state.retCanResponseOps === "declined") {
      theInvoice = this.props.theInvoice;
      returnCancel = this.props.returnCancel;
    }

    let opsType = "cancel";
    if (this.props.theInvoice.invoiceCreationType === "regular")
      opsType = "refund";

    let refundCancelId = 0;
    if (this.props.returnCancel)
      refundCancelId = this.props.returnCancel.refundCancelId;

    let input = {
      invoiceId: parseFloat(this.props.invoiceId),
      accessPath: this.props.accessPath,
      refundCancelId,
      responseComment: this.state.responseComment,
      communityId: this.props.communityId,
      coopStoreId,
      responseOps: this.state.retCanResponseOps,
      refundAmount: this.state.amountReturned,
      theInvoice,
      returnCancel,
      fateOfReturnedItem,
      actionBy: "business",
      opsType,
      selectedForRefund: this.state.selectedForRefund,
      baandaId: this.props.auth.user.baandaId,
    };
    // console.log("url:", url, " input:", input);

    try {
      let canret = await axios.post(url, input);
      // console.log("canret:", canret);
      if (canret.data.status === "success") {
        this.setState({
          errMsg: "Successfully processed.",
          errFlag: false,
          retCanResponseOps: "moreinfo",
          itemReceived: false,
          responseComment: "",
          opsConfirmed: false,
          amountReturned: 0.0,
          fateOfReturnedItem: "restack",
          maxAmount: 0,
          showRespondFlag: false,
        });
      } else {
        this.setState({
          errMsg: canret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handlePurchasePostage = () => {
    this.setState({
      purchasePostFlag: true,
    });
  };

  returnFromBuying = (msg) => {
    let message = "";
    let procuredReturnPost = false;
    if (msg) {
      if ((msg = "success"))
        message =
          "Successfully procured return-postage. It will be available on customer side.";
      procuredReturnPost = true;
    }

    this.setState({
      purchasePostFlag: false,
      errMsg: message,
      retPostageExists: false,
      procuredReturnPost,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ConductRefundCancel...");

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let itemReceivedPanel;
    if (
      this.props.theInvoice.invoiceCreationType === "regular" &&
      this.state.showRespondFlag
    ) {
      itemReceivedPanel = (
        <div className="text-center rec-cash-check-payment">
          <Checkbox
            checked={this.state.itemReceived}
            onChange={this.handleItemReceived}
          />{" "}
          Item(s) has been returned.
        </div>
      );
    }

    let returnitemreqToDoPanel;
    if (this.state.itemReceived) {
      returnitemreqToDoPanel = (
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Fate of returned Item&nbsp;&nbsp;</strong>

            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="restack"
                  checked={this.state.fateOfReturnedItem === "restack"}
                  onChange={this.handleTodoWithReturnedItem}
                />{" "}
                Re-stock
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="writeoff"
                  checked={this.state.fateOfReturnedItem === "writeoff"}
                  onChange={this.handleTodoWithReturnedItem}
                />{" "}
                Write Off
              </label>
            </div>
          </div>
        </div>
      );
    }

    let commentPanel;
    commentPanel = (
      <div className="ret-can-amt-pos">
        <div className="text-left ret-can-respond-comment-head">
          Response comment &nbsp;(Character count:{" "}
          {this.state.responseComment.length})
        </div>
        <div className="row">
          <div className="col ">
            <textarea
              name="responseComment"
              maxLength="1000"
              placeholder="Comment on return and/or ask for information etc.."
              rows="4"
              wrap="hard"
              spellCheck="true"
              className="input-ret-can-textarea"
              onChange={this.onChange}
              value={this.state.responseComment}
              required
            />
          </div>
        </div>
      </div>
    );

    let returnPostageBtn;
    returnPostageBtn = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={this.handlePurchasePostage}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Buy
      </button>
    );

    let buttonPanel = (
      <div className="ret-can-resp-btn-pos">
        <div className="row ">
          {this.state.canRequestReturnPostage &&
          this.state.retPostageExists &&
          !this.state.procuredReturnPost ? (
            <div className="col-5 text-center ret-can-buy-postage-lbl">
              Buy Return Postage {returnPostageBtn}
            </div>
          ) : null}
          {this.state.canRequestReturnPostage &&
          !this.state.retPostageExists &&
          this.state.procuredReturnPost ? (
            <div className="col-5 text-center ret-can-buy-postage-lbl">
              Return postage purchased
            </div>
          ) : null}
          <div className="col-4 text-right ret-can-resp-confirm-lbl">
            <Checkbox
              checked={this.state.opsConfirmed}
              onChange={this.handleOpsConfirmed}
            />{" "}
            Confirm operation
          </div>
          {this.state.opsConfirmed ? (
            <div className="col-3 text-left">
              <button
                className="btn_reg_80"
                type="button"
                onClick={this.handleRefundCancelResponse}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Respond
                {/* <i className="fa fa-cog" /> */}
              </button>
            </div>
          ) : (
            <div className="col-3">&nbsp;</div>
          )}
        </div>
        {this.props.theInvoice &&
        this.props.theInvoice.refundCancelState &&
        this.props.theInvoice.refundCancelState.requestReturnPostage ? (
          <div className="text-center customer-postage-buy-note">
            Customer requested return postage. Click Buy then respond with a
            note.
          </div>
        ) : (
          ""
        )}
      </div>
    );

    let amountReturnedPanel;
    if (
      this.state.retCanResponseOps === "approved" &&
      this.props.theInvoice.invoiceCreationType === "regular"
    ) {
      amountReturnedPanel = (
        <div className="row ret-can-resp-approve-amt-pos">
          <div className="col-4 text-right ret-can-resp-approve-amt-lbl">
            Return Amount $
          </div>
          <div className="col-8 text-left">
            <input
              name="amountReturned"
              type="number"
              value={this.state.amountReturned}
              onChange={this.onChangePrice}
              className="ret-can-amt-amount"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
            &nbsp;&nbsp;&nbsp; Max refundable amount: ${" "}
            {this.commaFormattedCurrency(this.state.maxAmount)}
          </div>
        </div>
      );
    }

    if (
      this.state.retCanResponseOps === "approved" &&
      this.props.theInvoice.invoiceCreationType === "subscription"
    ) {
      amountReturnedPanel = (
        <div className="text=center ret-can-resp-approve-msg">
          Cancel subscription
        </div>
      );
    }

    let opsSelectPanel;
    opsSelectPanel = (
      <div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Operation&nbsp;&nbsp;</strong>

            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="moreinfo"
                  checked={this.state.retCanResponseOps === "moreinfo"}
                  onChange={this.handleSelectOps}
                />{" "}
                Request more info
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="approved"
                  checked={this.state.retCanResponseOps === "approved"}
                  onChange={this.handleSelectOps}
                />{" "}
                Approve
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="declined"
                  checked={this.state.retCanResponseOps === "declined"}
                  onChange={this.handleSelectOps}
                />{" "}
                Decline
              </label>
            </div>
          </div>
        </div>
        <div className="tetx-center cust-intel-msg">
          <b>Customer Intel:</b> {this.state.custIntelMsg}
        </div>
      </div>
    );

    let returnPostagePanel;
    if (this.state.purchasePostFlag) {
      returnPostagePanel = (
        <div>
          <BuyReturnPostage
            toReturnItems={this.props.returnCancel.toReturnItems}
            returnToCaller={this.returnFromBuying}
            deliveryData={this.props.theInvoice.deliveryData}
            credits={this.props.community.credits}
            communityId={this.props.communityId}
            customerName={this.props.theInvoice.customerName}
            invoiceId={this.props.theInvoice.invoiceId}
            toAddressDefault={this.props.community.shippingMeta.fromAddress[0]}
            returnCancel={this.props.returnCancel}
          />
        </div>
      );
    }

    let outputPanel;

    outputPanel = (
      <div className="">
        <div className="row">
          <div className="col-7 text-right ret-can-respond-head">Response &nbsp;&nbsp;{infoButton}</div>
          <div className="col-5 text-right ret-can-respond-head-info">
            InvoiceId: {this.props.invoiceId} | ReturnCancelId:{" "}
            {this.props.returnCancel.refundCancelId}
          </div>
        </div>

        {returnPostagePanel}
        {this.state.showRespondFlag ? opsSelectPanel : null}
        {this.state.retCanResponseOps === "approved" ? itemReceivedPanel : null}
        {this.state.retCanResponseOps === "approved"
          ? returnitemreqToDoPanel
          : null}
        {this.state.showRespondFlag ? commentPanel : null}
        {this.state.showRespondFlag ? amountReturnedPanel : null}
        {this.state.showRespondFlag ? buttonPanel : null}
        <div className="text-center customer-refund-state-msg">
          {this.state.message}
        </div>
        {this.state.trackingUrl === "" ? null : (
          <div className="text-center ship-tracking-url">
            <a href={this.state.trackingUrl} target="_blank" rel="noreferrer">
              Track Return Shipment
            </a>
          </div>
        )}
        <div
          className={
            this.state.errFlag
              ? "text-center com-refund-cancel-msg-err"
              : "text-center com-refund-cancel-msg"
          }
        >
          {this.state.errMsg}
        </div>
        <div className="text-center seller-respond-direction">{this.state.approveDirection}</div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ConductRefundCancel.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConductRefundCancel)
);
