import React, { Component } from 'react';

import './UnlockCatalog.css';

class UnlockCatalog extends Component {
    render() {
        return (
            <div className="text-center crypto-config-msg">
                <h4>Catalog Unlocking</h4>
                When someone sets up a catalog item, the item is locked. In extreme scenario, super user will be able to unlock it.
                <p>Coming in the future release.</p>
            </div>
        );
    }
}

export default UnlockCatalog;