import React, { Component } from "react";

import moment from "moment";

import ViewPdfFile from "../../../../utils/components/fileView/ViewPdfFile";

import "./ViewBizState.css";

class ViewBizState extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",

      showNotesFlag: true,
      showPdfFlag: false,
    };
  }

  returnToStartBusiness = () => {
    this.props.returnToCaller();
  };

  showDocuments = async () => {
    await this.setState({
      showNotesFlag: false,
      showPdfFlag: true,
    });
  };

  returnFromShowDoc = async () => {
    await this.setState({
      showNotesFlag: true,
      showPdfFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ViewBizState...');

    let buttonPanel;
    buttonPanel = (
      <div>
        <button
          className="btn-bizstart-view-cancel"
          onClick={this.returnToStartBusiness}
          type="button"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Return
        </button>
        <button
          className="btn-bizstart-view-pdf"
          type="button"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          onClick={this.showDocuments}
        >
          Pdf&nbsp;<i className="fa fa-paperclip" />  
        </button>
      </div>
    );

    let noteShowPanel;
    noteShowPanel = (
      <div>
        <div className="text-center action-header">
          View Board's Notes & Documents
        </div>
        <div className="fixedsize-startbiz-notes">
          <div>
            {this.props.govNotes.map((obj, i) => (
              <div key={i}>
                <div className="row">
                  <div className="col asset-activity-lines text-left">
                    {i + 1}.&nbsp;{moment(obj.noteDate).format("L")}
                    &nbsp;:&nbsp;{obj.note}]&nbsp;
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );

    let outputPanel;
    if (this.state.showNotesFlag) {
      outputPanel = (
        <div className="bizstart-box">
          {noteShowPanel}
          {buttonPanel}
        </div>
      );
    }

    if (this.state.showPdfFlag) {
      outputPanel = (
        <div>
          <ViewPdfFile
            returnToCaller={this.returnFromShowDoc}
            communityId={this.props.communityId}
            filesToView={this.props.fileUploads}
            domain="Attached Business Documents"
          />
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

export default ViewBizState;
