import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../../../modal/components/ModalContainer";
import {
  showModal,
  hideModal,
} from "../../../../../../../../actions/modalActions";
import "../../../../../../../../modal/css/localModal.css";
import "../../../../../../../../modal/css/template.css";

import "./TagAnItem.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveBroadcastWip = "/routes/interact/saveBroadcastWip";
const getTagCatalogItem = "/routes/interact/getTagCatalogItem?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "0000000000";

class TagAnItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      willTag: false,

      itemType: "goods",
      itemName: "",

      errMsg: "",
      errFlag: false,

      selectedItem: null,

      items: [],
    };
  }

  componentDidMount = () => {
    let willTag = false;
    let itemType = "goods";
    let itemName = "";
    let selectedItem = null;
    if (this.props.theBroadcast.tag) {
      if (this.props.theBroadcast.tag.tagRefId > 0) {
        willTag = this.props.theBroadcast.tag.tagged;
        itemType = this.props.theBroadcast.tag.tagType;
        selectedItem = {
          itemId: this.props.theBroadcast.tag.tagRefId,
          itemName: this.props.theBroadcast.tag.tagName,
          itemType: this.props.theBroadcast.tag.tagType
        };
        itemName = this.props.theBroadcast.tag.tagName;
      }
    };

    this.setState({
        willTag,
        itemType,
        itemName,
        selectedItem
    })
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleTagging = async () => {
    this.setState((prevstate) => ({
      willTag: !prevstate.willTag,
    }));
  };

  handleItemSearch = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&itemName=" +
      this.state.itemName +
      "&itemType=" +
      this.state.itemType;
    let url = baandaServer + getTagCatalogItem + params;
    try {
      let catret = await axios.get(url);
      if (catret.data.status === "success") {
        if (catret.data.Msg.items.length > 0) {
          this.setState({
            items: catret.data.Msg.items,
            errMsg: "",
            errFlag: false,
            selectedItem: null,
          });
        } else {
          this.setState({
            items: [],
            errMsg: "No items found with your search condition.",
            errFlag: true,
            selectedItem: null,
          });
        }
      } else {
        this.setState({
          errMsg: catret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleItemType = async (e) => {
    this.setState({
      itemType: e.target.value,
      errMsg: "",
      errFlag: false,
    });
  };

  handleSelectThis = (obj) => {
    console.log("obj:", obj);
    this.setState({
      selectedItem: obj,
    });
  };

  packageInputForDB = () => {
    let tag = {
      tagged: true,
      domain: "catalog",
      tagRefId: this.state.selectedItem.itemId,
      tagName: this.state.selectedItem.itemName,
      tagType: this.state.itemType,
    };
    let input = {
      basicCompBody: this.state.compArray,
      communityId: this.props.communityId,
      broadcastId: this.props.broadcastId,
      tag,
      templateType: this.props.selectedTemplate,
      updateType: "tagitem",
      clientProgram: "TagAnItem",
      clientFunction: "handleTagSave",
    };

    return input;
  };

  handleTagSave = async () => {
    console.log("inside uploadFileInDB");
    let input = this.packageInputForDB();
    let url = baandaServer + saveBroadcastWip;
    console.log("### url:", url, " input:", input);
    try {
      let resp = await axios.post(url, input);
      console.log("Upload resp:", resp);
      if (resp.data.status === "success") {
        console.log("In here ... 999");
        this.setState({
          errMsg: "Successfully updated.",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: resp.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("uploadFileInDB:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let searchButton = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={this.handleItemSearch}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-search" />
      </button>
    );

    let tagConsentPanel;
    tagConsentPanel = (
      <div className="row want-to-tag-pos">
        <div className="col text-center want-to-tag-lbl">
          <Checkbox
            checked={this.state.willTag}
            onChange={this.handleTagging}
          />{" "}
          Want to tag a Catalog Item with the campaign?
        </div>
      </div>
    );

    let saveBtn;
    if (this.state.selectedItem) {
      saveBtn = (
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.handleTagSave}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>
      );
    }

    let itemTypePanel;
    if (this.state.willTag) {
      itemTypePanel = (
        <div className="text-center comp-item-type-pos">
          <div className="row">
            <div className="col text-center mms-ops-type">
              <strong>Select Item Type </strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="goods"
                    checked={this.state.itemType === "goods"}
                    onChange={this.handleItemType}
                  />{" "}
                  Goods
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="service"
                    checked={this.state.itemType === "service"}
                    onChange={this.handleItemType}
                  />{" "}
                  Service
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let searchByNamePanel;
    if (this.state.willTag) {
      searchByNamePanel = (
        <div className="row tag-name-row-pos">
          <div className="col-3 text-right tag-name-lbl">Item Name</div>
          <div className="col-7 text-right">
            <input
              name="itemName"
              type="text"
              value={this.state.itemName}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="com-tag-name-to-search"
              placeholder=""
            />{" "}
          </div>
          <div className="col-2 text-left tag-search-btn-pos">
            {searchButton}
          </div>
        </div>
      );
    }

    let listPanel;
    if (this.state.willTag) {
      let bool = true;
      listPanel = (
        <div>
          <div className="row">
            <div className="col-2">&nbsp;</div>
            <div className="col-1 tag-item-list-head">Ops</div>
            <div className="col-9 text-center tag-item-list-head">
              Item Name (Count: {this.state.items.length})
            </div>
          </div>
          <div className="comp-item-list-box">
            {this.state.items.map((obj, i) => (
              <div key={i}>
                <div
                  className={`${
                    bool ? "row-basic-body-list-dr" : "row-basic-body-list-lt"
                  }`}
                >
                  <div className="row ">
                    <div className="col-1 text-left">
                      <button
                        className="btn_reg_40"
                        type="button"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                        onClick={() => this.handleSelectThis(obj)}
                      >
                        <i className="fas fa-check" />
                      </button>
                    </div>

                    <div className="col-11 text-left">{obj.itemName}</div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            ))}
          </div>
          {this.state.selectedItem ? (
            <div className="row tag-selected-item-pos">
              <div className="col-4 text-right tag-selected-item-lbl">
                Selected Item
              </div>
              <div className="col-8 text-left tag-selected-item-txt">
                <mark>
                  {this.state.selectedItem.itemName} (Id:{" "}
                  {this.state.selectedItem.itemId})
                </mark>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row items-no-of-row-pos">
            <div className="col text-center total-items-selected">
              {saveBtn}
            </div>
          </div>
        </div>
      );
    }

    let outputPanel = (
      <div>
        <div className="text-center tag-item-head">
          Tag a Catalog Item {infoModalButton}
        </div>
        {tagConsentPanel}
        {itemTypePanel}
        {searchByNamePanel}
        {listPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center comp-tag-msg-err"
              : "text-center comp-tag-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );
    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

TagAnItem.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TagAnItem));
