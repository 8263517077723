import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import moment from "moment";

import LoadfileToS3 from "../../../../utils/components/fileUpload/LoadFileToS3";
import ViewBizState from "./ViewBizState";

import "./StartBusiness.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getMembersOfCommunity = "/routes/dashboard/getMembersOfCommunity?";
const getOwners = "/routes/dashboard/getOwnership?";
const saveBoardSetup = "/routes/dashboard/saveBoardSetup";

const Checkbox = (props) => <input type="checkbox" {...props} />;
const maxpdfSize = 10000000; // 10mb  -- about 10 minutes

const readMeCode = "1159100000";

class StartBusiness extends Component {
  constructor(props) {
    super(props);

    this.state = {
      members: [],
      board: [],

      docvalidFileType: ["pdf"],
      docS3FileData: null,
      ifDeletedFlag: false,

      attachDocFlag: false,
      showBoardFlag: true,
      showSetupFlag: false,
      showRecordFlag: false,
      controlButtonFlag: true,

      docSubDirName: "",

      totalShares: 10000,
      shareMultiplier: 1,

      govNote: "",
      availablePool: 100,
      sharesSplit: false,
      bizStartFlag: true,
      billOnDayOfMonth: 1,
      conBaandaId: 0,
      conName: "",
      conEmail: "",

      okToRecordFlag: false,
      allowShareSplitFlag: false,
      fileMediumType: "pdf",

      deviceSize: "",
      selheight: 1,

      lastOwnerRec: null,

      errorFlag: false,
      errorMsg: "",
      recordInvalid: false,
      recordErrMsg: "",
      fileName: "",
      attachmentCaption: "",

      govNotes: [],
      fileUploads: [],
      canViewButtonFlag: false,
      viewDocFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.getMembers();
    await this.getOwners();
    await this.setState({
      deviceSize,
      conBaandaId: this.props.auth.user.baandaId,
      conName: this.props.auth.user.name,
      conEmail: this.props.auth.user.email,
    });
  };

  getOwners = async () => {
    try {
      let params =
        "communityId=" +
        this.props.communityId +
        "&clientProgram=StartBusiness&clientFunction=getOwners";
      let url = baandaServer + getOwners + params;
      let ownr = await axios.get(url);
      // console.log(">>> ownr.data:", ownr.data);
      let canViewButtonFlag = false;
      if (ownr.data.status === "success") {
        if (
          ownr.data.Msg &&
          ownr.data.Msg.communityId === this.props.communityId
        ) {
          if (ownr.data.Msg.govNote.length > 0) canViewButtonFlag = true;
          await this.setState({
            lastOwnerRec: ownr.data.Msg,
            board: ownr.data.Msg.board,
            bizStartFlag: false,
            totalShares: ownr.data.Msg.totalShares,
            govNotes: ownr.data.Msg.govNote,
            fileUploads: ownr.data.Msg.fileUploads,
            canViewButtonFlag,
          });
        }
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return res;
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  getMembers = async () => {
    let params = "communityId=" + this.props.communityId + "&memberName=";
    let url = baandaServer + getMembersOfCommunity + params;
    try {
      let retmem = await axios.get(url);
      if (retmem.data.status === "success" && retmem.data.Msg.length > 0) {
        await this.setState({
          members: retmem.data.Msg,
        });
      }
      // console.log("retmem:", retmem);
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        // message: msg,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleMemberSelected = async (e) => {
    let indata = e.target.value;
    // console.log('indata:', indata);
    if (indata !== "default") {
      let data = JSON.parse(e.target.value);
      // console.log("data:", data);
      let brd = this.state.board;
      let ispush = true;
      this.state.board.forEach((obj) => {
        if (obj.baandaId === data.baandaId) ispush = false;
      });
      if (ispush) {
        brd.push(data);
        await this.setState({
          board: brd,
        });
      }
    }
  };

  onfocus = async () => {
    await this.setState({
      selheight: 4,
    });
  };

  onblur = async () => {
    await this.setState({
      selheight: 1,
    });
  };

  handleRemoveFromBoard = async (toDel) => {
    // console.log("toDel:", toDel);
    // let brd = this.state.board;
    let brdt = [];
    this.state.board.forEach((obj) => {
      if (obj.baandaId !== toDel.baandaId) {
        brdt.push(obj);
      }
    });

    await this.setState({
      board: brdt,
    });
  };

  randfn = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  readyDocAttach = async () => {
    let randomct, commrn, subBucket, fileType;
    if (this.state.docSubDirName === "") {
      randomct = this.randfn(100000000, 999999999);
      commrn = randomct.toString(16);
      subBucket =
        "cid" +
        this.props.communityId +
        "-authby" +
        this.props.auth.user.baandaId +
        "-boardDoc" +
        commrn;
    } else subBucket = this.state.docSubDirName;
    let docS3FileData = null;
    if (this.state.docS3FileData) docS3FileData = this.state.docS3FileData;

    fileType = ["pdf"];
    await this.setState({
      docvalidFileType: fileType,
      docSubDirName: subBucket,
      docMaxFileSize: maxpdfSize,
      docS3FileData,
    });
  };

  handleOpsButton = async (value) => {
    // console.log("data:", value);

    if (value === "attach") {
      await this.readyDocAttach();
    }
    if (value === "record") {
      await this.setState({
        recordInvalid: false,
        recordErrMsg: "",
        fileName: "",
        okToRecordFlag: false,
      });
      let fileName = "";
      let recordInvalid = false;
      let recordErrMsg = "";
      if (this.state.docS3FileData && this.state.docS3FileData.key !== "") {
        fileName = this.state.docS3FileData.key.split("/").pop;
      } else {
        recordInvalid = true;
        recordErrMsg =
          "You have to upload document to assert your change. Please,write the note, even if you are the only member, sign by all parties, scan as pdf and upload for future reference. || ";
      }
      let isValid = false;
      let name = "",
        bid = 0;
      this.state.members.forEach((obj) => {
        if (obj.email === this.state.conEmail) {
          isValid = true;
          name = obj.name;
          bid = obj.baandaId;
        }
      });
      if (!isValid) {
        recordInvalid = true;
        recordErrMsg =
          recordErrMsg + " Contact email is not a member of your community.";
      }

      await this.setState({
        recordInvalid,
        recordErrMsg,
        fileName,
        conName: name,
        conBaandaId: bid,
      });
    }

    await this.setState({
      attachDocFlag: false,
      showBoardFlag: false,
      showSetupFlag: false,
      showRecordFlag: false,
      viewDocFlag: false,
      errorFlag: false,
      errorMsg: "",
    });

    switch (value) {
      case "record":
        await this.setState({
          showRecordFlag: true,
        });
        break;
      case "setup":
        await this.setState({
          showSetupFlag: true,
        });
        break;
      case "board":
        await this.setState({
          showBoardFlag: true,
        });
        break;
      case "attach":
        await this.setState({
          attachDocFlag: true,
        });
        break;
      case "view":
        await this.setState({
          viewDocFlag: true,
        });
        break;

      default:
    }
  };

  callerOfDocView = async () => {
    await this.setState({
      controlButtonFlag: true,
      attachDocFlag: false,
      showBoardFlag: false,
      showSetupFlag: false,
      showRecordFlag: false,
      viewDocFlag: false,
      errorFlag: false,
      errorMsg: "",
    });
  };

  onChangeShares = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 0) {
      let tmp = parseFloat(value).toFixed(0);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  onBillDayOfMonth = async (e) => {
    let value = e.target.value;
    if (value < 29 && value > 0) {
      if (this.countDecimals(value) > 0) {
        let tmp = parseFloat(value).toFixed(0);
        let val = parseFloat(tmp);
        await this.setState({
          [e.target.name]: val,
        });
      } else {
        await this.setState({
          [e.target.name]: value,
        });
      }
    }
  };

  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  callerOfDocAttachment = async (fileData) => {
    // console.log(">>> fileData:", fileData);
    let docS3Data = this.state.docS3FileData;
    if (fileData) docS3Data = fileData;
    await this.setState({
      controlButtonFlag: true,
      attachDocFlag: false,
      ifDeleteFlag: docS3Data.ifDeletedFlag,
      docS3FileData: docS3Data.s3FileData, // If return is null, retain the original docS3FileData state.
    });
  };

  handleSplitConfirn = async () => {
    await this.setState((prevstate) => ({
      sharesSplit: !prevstate.sharesSplit,
    }));
  };

  handleDisclaimer = async () => {
    await this.setState((prevstate) => ({
      okToRecordFlag: !prevstate.okToRecordFlag,
    }));
  };

  handleSetupDone = async () => {
    await this.setState({
      attachDocFlag: false,
      showBoardFlag: false,
      showSetupFlag: false,
      showRecordFlag: false,
    });
  };

  validateInputData = async () => {
    let isValid = true,
      msg = "";
    if (this.state.board.length === 0) {
      isValid = false;
      msg = "Board, or governing body, must have at least one member. || ";
    }
    if (this.state.docS3FileData === null) {
      isValid = false;
      msg = msg + "Must upload supporting document (pdf). || ";
    } else if (
      this.state.docS3FileData &&
      this.state.docS3FileData.location &&
      this.state.docS3FileData.location === ""
    ) {
      isValid = false;
      msg = msg + "Must upload supporting document (pdf). || ";
    }
    if (this.state.totalShares < 100) {
      isValid = false;
      msg = msg + "Number of Shares cannot be less than 100. || ";
    }

    if (this.state.shareMultiplier === 0 || this.state.shareMultiplier > 3) {
      isValid = false;
      msg = msg + "Share multiplier can be 1, 2, or 3. 1 is by default.";
    }

    if (this.state.attachmentCaption === "") {
      isValid = false;
      msg = msg + "Must Provide a unique biz-start attachment name. ";
    } else {
      let uname = true;
      this.state.fileUploads.forEach((obj) => {
        if (obj.fileName === this.state.attachmentCaption) uname = false;
      });
      if (!uname) {
        isValid = false;
        msg = msg + "The attachment fileName exists. It must be unique.";
      }
    }

    let retValid = { status: isValid, msg: msg };
    return retValid;
  };

  packageInputData = async () => {
    let brd = [];
    let brdo;
    if (this.state.board.length > 0) {
      this.state.board.forEach((obj) => {
        brdo = {
          baandaId: obj.baandaId,
          name: obj.name,
          email: obj.email,
        };
        brd.push(brdo);
      });
    }

    let inpData = {
      initiationFlag: this.state.bizStartFlag, // Shareholder will be initiated once from here
      communityId: this.props.communityId,
      totalShares: this.state.totalShares,
      govNote: {
        note: this.state.govNote,
        noteDate: moment(),
      },
      board: brd,
      shareHolders: [
        {
          baandaId: 0,
          percentOwnership: 100,
          shares: this.state.totalShares,
          shareType: "assigned",
        },
      ],
      contactPerson: {
        baandaId: this.state.conBaandaId,
        name: this.state.conName,
        email: this.state.conEmail,
      },
      billiedOnDayOfMonth: this.state.billOnDayOfMonth,
      shareMultiplier: this.state.shareMultiplier,
      // ownershipDoc: {
      //   bucket: this.state.docS3FileData.bucket,
      //   dirname: this.state.docSubDirName, // subsirectory
      //   key: this.state.docS3FileData.key,
      //   s3Url: this.state.docS3FileData.location,
      // },

      ownershipDoc: {
        key: this.state.docS3FileData.key,
        type: this.state.fileMediumType,
        location: this.state.docS3FileData.location,
        fileName: this.state.attachmentCaption,
        subBucket: this.state.docSubDirName,
      },
      baandaId: this.props.auth.user.baandaId,
    };

    return inpData;
  };

  handleRecordSave = async () => {
    let isValid = await this.validateInputData();
    if (isValid.status) {
      let inpData = await this.packageInputData();
      // console.log("inpData:", inpData);
      let url = baandaServer + saveBoardSetup;
      try {
        let recsave = await axios.post(url, inpData);
        if (recsave.data.status === "success") {
          await this.getMembers();
          await this.getOwners();
          await this.setState({
            messageFlag: true,
            message: "Succesfully recorded ... ",
            controlButtonFlag: false,
            attachDocFlag: false,
            showBoardFlag: false,
            showSetupFlag: false,
            showRecordFlag: false,
          });
        } else {
          await this.setState({
            errorFlag: true,
            message: recsave.data.Msg,
          });
        }
      } catch (err) {
        console.log("startBusiness.js handleRecordSavev() err:", err.message);
      }
    } else {
      await this.setState({
        errorFlag: true,
        errorMsg: isValid.msg,
        attachDocFlag: false,
        showBoardFlag: false,
        showSetupFlag: false,
        showRecordFlag: false,
        okToRecordFlag: false,
      });
    }
  };

  recordingDone = async () => {
    await this.setState({
      controlButtonFlag: true,
      messageFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('StarBusiness...');

    let sellist = this.state.members.map((obj, i) => {
      return (
        <option key={i + 2} value={JSON.stringify(obj)}>
          {obj.name}&nbsp;({obj.email})
        </option>
      );
    });

    let selMemDropdown;
    if (this.state.members.length > 0) {
      selMemDropdown = (
        <div>
          <div className="row">
            <div className="col text-center div_pos_item_select">
              <select
                // size={selheight}
                size={this.state.selheight}
                onFocus={this.onfocus}
                onBlur={this.onblur}
                onChange={this.handleMemberSelected}
                className="bizstart_member_select"
              >
                {/* <option key={1000} value="default" selected="selected" disabled> */}
                <option key={1000} value="default">
                  Select Members for the board
                </option>
                {sellist}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col text-center select_item_msg">
              Please select member for governing body
            </div>
          </div>
        </div>
      );
    }
    let headerPanel = (
      <div className="row strtbiz-header-row">
        <div className="col-9 start-head-lbl text-center">
          Start Your Community Business
        </div>
        <div className="col-3 text-center">
          <button
            className="btn-modal-startbiz"
            type="button"
            onClick={this.openAlertModal}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Readme First&nbsp;
            <i className="fas fa-info-circle" />
          </button>
        </div>
      </div>
    );

    let boardMemberPanel;
    if (this.state.showBoardFlag) {
      boardMemberPanel = (
        <div>
          {selMemDropdown}
          <div className="row">
            <div className="col text-center item-list-header">
              Governing Members (The Board){" "}
            </div>
          </div>
          <div className="fixedsize_startbiz">
            {this.state.board.map((obj, i) => (
              <div key={i}>
                <div className="row">
                  <div className="col line-board-members text-left">
                    <button
                      className="btn-delete-po-line"
                      type="button"
                      // onClick={() => this.handleRemoveFromBoard(`${i}`)}
                      onClick={() => this.handleRemoveFromBoard(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      <i className="fas fa-trash-alt" />
                    </button>
                    &nbsp;&nbsp; &nbsp;{obj.name}&nbsp;({obj.email})
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="space_below" />
        </div>
      );
    }

    let bizstartlbl, record, attach, board, setup, dayOfMon, conEmail;
    if (this.state.deviceSize === "small") {
      bizstartlbl = "Init";
      record = "Record";
      attach = "Atch";
      board = "Board";
      setup = "Setup";
      dayOfMon = "BillDay";
      conEmail = "Contact";
    } else {
      bizstartlbl = "Initiate Business";
      record = "Record";
      attach = "Attach";
      board = "Board";
      setup = "Setup";
      dayOfMon = "Bill day-of-month";
      conEmail = "Contact Email";
    }

    let buttonPanel;
    if (this.state.controlButtonFlag) {
      buttonPanel = (
        <div>
          <div className="row ">
            <div className="col text-center biz-start-buttons">
              {bizstartlbl} &nbsp;&nbsp;
              <button
                onClick={() => this.handleOpsButton("board")}
                className="btn-bizstart"
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {board}
              </button>
              <button
                onClick={() => this.handleOpsButton("setup")}
                className="btn-bizstart"
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {setup}
              </button>
              <button
                onClick={() => this.handleOpsButton("attach")}
                className="btn-bizstart-attach"
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {attach}&nbsp;
                <i className="fa fa-paperclip" />
              </button>
              {this.state.canViewButtonFlag ? (
                <button
                  onClick={() => this.handleOpsButton("view")}
                  className="btn-bizstart-attach"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  View&nbsp;
                  <i className="fa fa-paperclip" />
                </button>
              ) : null}
              <button
                onClick={() => this.handleOpsButton("record")}
                className="btn-bizstart-rec"
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {record}
              </button>
            </div>
          </div>
        </div>
      );
    }

    let mult;
    if (this.state.deviceSize === "small") {
      mult = "Multiply";
    } else {
      mult = "Share Multiplier";
    }

    // sharesSplit: false,
    // bizStartFlag: true,
    let shareMultiplierPanel;
    if (this.state.sharesSplit) {
      shareMultiplierPanel = (
        <div className="row">
          <div className="col-3 text-right startbiz-lbl">{mult}</div>
          <div className="col-9 text-left startbiz-lbl">
            <input
              name="shareMultiplier"
              type="number"
              value={this.state.shareMultiplier}
              onChange={this.onChangeShares}
              size="1"
              className="bizstart-share-mult"
              step=".01"
              placeholder="0"
              autoComplete="off"
            />
          </div>
        </div>
      );
    }

    let errorPanel;
    if (this.state.errorFlag) {
      errorPanel = (
        <div className="bizstart-error-box text-center">
          <b>Error:</b>&nbsp;{this.state.errorMsg}
        </div>
      );
    }

    let messagePanel;
    if (this.state.messageFlag) {
      messagePanel = (
        <div className="bizstart-message-box">
          {this.state.message}
          <div>
            <button
              className="btn-bizstart"
              onClick={this.recordingDone}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              OK
            </button>
          </div>
        </div>
      );
    }

    let setupPanel;
    if (this.state.showSetupFlag) {
      setupPanel = (
        <div className="bizstart-box">
          <div className="row row-spacing">
            <div className="col-3 text-right startbiz-lbl">Total Shares</div>
            <div className="col-9 text-left startbiz-lbl">
              {this.state.bizStartFlag ? (
                <input
                  name="totalShares"
                  type="number"
                  value={this.state.totalShares}
                  onChange={this.onChangeShares}
                  size="20"
                  className="bizstart-share"
                  step=".01"
                  placeholder="0.00"
                  autoComplete="off"
                />
              ) : (
                <p>
                  {this.commaFormattedCurrency(this.state.totalShares)} &nbsp;
                </p>
              )}
            </div>
          </div>
          <div className="row row-spacing">
            <div className="col-3 text-right startbiz-lbl">Investment Pool</div>
            <div className="col-9 text-left">
              {this.state.availablePool}%&nbsp;available for trading.
            </div>
          </div>
          <div className="row row-spacing">
            <div className="col-3 text-right startbiz-lbl">Meeting Note</div>
            <div className="col-9 text-left">
              <textarea
                name="govNote"
                value={this.state.govNote}
                onChange={this.onChange}
                size="1000"
                className="bizstart-note"
                rows="3"
                placeholder="Meeting minutes ... starting your business comments."
                autoComplete="off"
              />
            </div>
          </div>
          {this.state.sharesSplit ? (
            <div className="row row-spacing">
              <div className="col text-center startbiz-lbl">
                Multiply Shares&nbsp;:&nbsp;
                <Checkbox
                  checked={this.state.sharesSplit}
                  onChange={this.handleSplitConfirn}
                />{" "}
                &nbsp; <b>Caution. Click 'Readme First' for details.</b>
              </div>
            </div>
          ) : null}

          {shareMultiplierPanel}
          <div className="row row-spacing">
            <div className="col-3 text-right startbiz-lbl">{dayOfMon}</div>
            <div className="col-9 text-left startbiz-lbl">
              <input
                name="billOnDayOfMonth"
                type="number"
                value={this.state.billOnDayOfMonth}
                onChange={this.onBillDayOfMonth}
                size="20"
                className="bizstart-dom"
                step="1"
                placeholder="1"
                autoComplete="off"
              />{" "}
              &nbsp; Must be less than 29 and not zero (0)
            </div>
          </div>
          <div className="row row-spacing">
            <div className="col-3 text-right startbiz-lbl">{conEmail}</div>
            <div className="col-9 text-left startbiz-lbl">
              <input
                name="conEmail"
                type="text"
                value={this.state.conEmail}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="con-email"
                placeholder="Main contact email ..."
              />
            </div>
          </div>

          <div className="row">
            <div className="col-8">&nbsp;</div>
            <div className="col-4 text-center">
              <button
                className="btn-bizstart"
                onClick={this.handleSetupDone}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      );
    }

    let docNamePanel = (
      <div className="row row-spacing">
        <div className="col-3 text-right startbiz-lbl">Doc Name</div>
        <div className="col-9 text-left startbiz-lbl">
          <input
            name="attachmentCaption"
            type="text"
            value={this.state.attachmentCaption}
            onChange={this.onChange}
            size="50"
            maxLength="50"
            className="con-email"
            placeholder="Enter Unique Docname ..."
          />
        </div>
      </div>
    );

    let recordPanel;
    if (this.state.showRecordFlag) {
      recordPanel = (
        <div>
          <div className="bizstart-message-box">
            <p align="justify">
              <b>Disclaimer:</b> Baanda Platform is not responsible for the
              authenticity of the content. It is up to the recorder to validate
              the authenticity of the information entered and the legality of
              the documents uploaded. If your information is incorrect then you
              may not be able to setup creditcard based payment (e-comm) and do
              proper banking. If you are setting up your business prior to
              proper registration, then upload a place-holder doc. Inform the
              legalese of the number of initial share you have floated so they
              can mention the same in their legal document. Upload the document
              when available.
            </p>
          </div>
          <div>
            Check&nbsp;
            <Checkbox
              checked={this.state.okToRecordFlag}
              onChange={this.handleDisclaimer}
            />{" "}
            &nbsp;to agree to the recording conditions.
          </div>
          {docNamePanel}
          <div className="bizstart-message-box">
            <p align="justify" className="rec-summary">
              <b>Recording:</b> The board members selected will be tagged as the
              current board members. The number of shares in pool is&nbsp;
              {this.state.totalShares}. The main contact person is&nbsp;{" "}
              {this.state.conName}&nbsp;with email&nbsp;{this.state.conEmail}.
              The community's service providers (Baanda Platform) will raise
              invoice on&nbsp;{this.state.billOnDayOfMonth} th day of every
              month.
            </p>
            {this.state.recordInvalid ? (
              <p align="justify" className="rec-err">
                <b>Error</b>&nbsp;{this.state.recordErrMsg}
              </p>
            ) : null}
          </div>
          {/* {this.state.okToRecordFlag && !this.state.recordInvalid ? ( */}
          {this.state.okToRecordFlag ? (
            <div className="row">
              <div className="col-8">&nbsp;</div>
              <div className="col-4 text-center">
                <button
                  className="btn-bizstart"
                  onClick={this.handleRecordSave}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Save
                </button>
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    let attcahDocPanel;
    if (this.state.attachDocFlag) {
      attcahDocPanel = (
        <div>
          <LoadfileToS3
            returnToCaller={this.callerOfDocAttachment}
            communityId={this.props.communityId}
            fileType={this.state.docvalidFileType}
            type={this.state.fileMediumType}
            maxSize={maxpdfSize}
            s3subdir={this.state.docSubDirName}
            requestor="Board-Biz Doc (pdf)"
            s3FileData={this.state.docS3FileData}
          />
        </div>
      );
    }

    let viewDocPanel;
    if (this.state.viewDocFlag) {
      viewDocPanel = (
        <div>
          <ViewBizState
            returnToCaller={this.callerOfDocView}
            fileUploads={this.state.fileUploads}
            govNotes={this.state.govNotes}
          />
        </div>
      );
    }

    let outputPanel = (
      <div>
        {/* {selMemDropdown} */}
        {buttonPanel}
        {setupPanel}
        {recordPanel}
        {attcahDocPanel}
        {viewDocPanel}
        {errorPanel}
        {messagePanel}
        {boardMemberPanel}
      </div>
    );

    return (
      <div>
        <hr className="startbiz" />
        {headerPanel}
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

StartBusiness.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StartBusiness));
