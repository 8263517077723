import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import moment from "moment";
import axios from "axios";

import "./ShowDetailDoc.css";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveILComments = "/routes/dashboard/saveILComments";

class ShowDetailDoc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",

      pdfThing: null,
      idThing: null,
      deviceSize: "",

      showImageFlag: false,
      ilNote: "",
    };
  }

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    let idThing = {},
      pdfThing = {};
    this.props.data.investDocs.forEach((obj) => {
      if (obj.contentType === "Id-picture") {
        idThing = {
          s3Url: obj.s3Url,
          fileName: obj.docName,
        };
      }
      if (obj.contentType === "Invest-Loan-Doc") {
        pdfThing = {
          s3Url: obj.s3Url,
          fileName: obj.docName,
        };
      }
    });
    await this.setState({
      deviceSize,
      idThing,
      pdfThing,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  returnToList = () => {
    console.log('I am here ...');  
    this.props.returnToCaller();
  };

  openPdfModal = async () => {
    await this.setState({
      showImageFlag: false,
    });
    let msg = {
      Header: "Loan-Investment Document",
      Body: {
        s3Url: this.state.pdfThing.s3Url,
        // fileName: this.state.fileNameToDisplay,
        fileName: this.state.pdfThing.fileName,

        footnote: "Footnote",
      },
      Footer: "Footer",
    };

    this.props.showModal(
      {
        open: true,
        title: "Alert - Start Here Header",
        message: msg,
        closeModal: this.closeModal,
      },
      "showPdf"
    );
  };

  showId = async () => {
    await this.setState({
      showImageFlag: true,
    });
  };

  handleSave = async () => {
    let url = baandaServer + saveILComments;
    let inpData = {
      investId: this.props.data.investId,
      noteEx: {
        authorBaandaId: this.props.auth.user.baandaId,
        authorName: this.props.auth.user.name,
        dateCreated: moment(),
        note: this.state.ilNote,
      },
    };
    try {
      let ret = await axios.post(url, inpData);
      console.log('$$$$ ret.data.status:', ret.data.status);
      if ( ret.data.status === 'success') {
          console.log('I am here 111');
          this.returnToList();
      } else console.log('I am here 222');
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    let da = this.props.data;
    let buttonPanel = (
      <div>
        <button
          onClick={this.returnToList}
          className="btn-il-detailshow-cancel"
        >
          Return
        </button>
        {this.state.idThing ? (
          <button onClick={this.showId} className="btn-il-detailshow">
            ShowId
          </button>
        ) : null}
        {this.state.pdfThing ? (
          <button onClick={this.openPdfModal} className="btn-il-detailshow">
            ShowDoc
          </button>
        ) : null}
        <button onClick={this.handleSave} className="btn-il-detailshow">
          Save
        </button>
      </div>
    );

    let notesExchangedPanel = (
      <div>
        <div className="text-center action-header">Comments</div>
        <div className="fixedsize-il-detail">
          {da.notesExchanged.length > 0 ? (
            <div>
              {da.notesExchanged.map((obj, i) => (
                <div key={i}>
                  <div className="row">
                    <div className="col line-item-note-il text-left">
                      {i + 1}.&nbsp;{moment(obj.dateCreated).format("L")}
                      &nbsp;[By:&nbsp;{obj.authorName}]&nbsp;--
                      {obj.note}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center">No comments yet.</div>
          )}
        </div>
        <div className="row">
          <div className="col-3 text-right ilNote-text">Comment</div>
          <div className="col-9 text-left">
            <textarea
              name="ilNote"
              value={this.state.ilNote}
              onChange={this.onChange}
              maxLength="200"
              rows="2"
              className="action-note"
              placeholder="Enter an action note."
            />
          </div>
        </div>
      </div>
    );

    let loanInvHeader = <div className="li-header">Loan-Ivestment Details</div>;

    let imgPanel;
    if (this.state.showImageFlag) {
      if (this.state.idThing) {
        imgPanel = (
          <div className="il-show-img-box">
            <img
              src={this.state.idThing.s3Url}
              alt="pic"
              className="id-showdetail-pic"
            />
          </div>
        );
      } else {
        imgPanel = <div>No Image Available</div>;
      }
    }

    let equityPanel;
    let loanPanel;
    if (da.ilType === "equity") {
      equityPanel = (
        <div className="row">
          <div className="col text-left il-text-pad">
            Investor invested $
            <b>
              {this.commaFormattedCurrency(parseFloat(da.investLoanAmount))}
            </b>{" "}
            in <b>{da.investLoanForm}</b> to receive{" "}
            <b>{da.equityDoc.shares}</b> shares on{" "}
            <b>{da.equityDoc.onDate.substring(0, 10)}</b>. It will be vested in{" "}
            <b>{da.debitDoc.willVestInMonths}</b> months.
          </div>
        </div>
      );
    } else {
      loanPanel = (
        <div className="row">
          <div className="col test-left il-text-pad">
            Lender remitted $
            <b>
              {this.commaFormattedCurrency(parseFloat(da.investLoanAmount))}
            </b>{" "}
            at the rate of <b>{da.debitDoc.interestRate}</b> APR that will vest
            in <b>{da.debitDoc.willVestInMonths}</b> months.
          </div>
        </div>
      );
    }

    let displayPanel = (
      <div>
        {loanInvHeader}
        <div className="row">
          <div className="col il-show-header text-left il-text-pad">
            {" "}
            This &nbsp;<b>{da.investmentName}</b>&nbsp;document has been created
            on&nbsp;<b>{da.createDate.substring(0, 10)}</b>
          </div>
        </div>
        <div className="row">
          <div className="col text-left il-text-pad">
            Lender is&nbsp;
            <b>{da.party.name}</b>&nbsp;with email&nbsp;<b>{da.party.email}</b>
          </div>
        </div>
        {da.party.originType === "individual" ? null : (
          <div className="row">
            <div className="col text-left il-text-pad">
              Organization participating is <b>{da.party.orgName}</b> at{" "}
              <b>{da.party.orgAddress}</b>
            </div>
          </div>
        )}{" "}
        <div className="row">
          <div className="col text-left il-text-pad">
            Id type&nbsp;<b>{da.party.idType}</b> is with Id #{" "}
            <b>{da.party.idNumber}</b>
          </div>
        </div>
        <div className="row">
          <div className="col text-center">{imgPanel}</div>
        </div>
        {da.ilType === "equity" ? equityPanel : loanPanel}
        {notesExchangedPanel}
      </div>
    );

    let outputPanel = (
      <div className="il-box">
        {displayPanel}
        {buttonPanel}
      </div>
    );
    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ShowDetailDoc.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShowDetailDoc));
