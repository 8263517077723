import React, { Component } from "react";

import "./CommunityDirectory.css";

class CommunityDirectory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      members: [],
    };
  }

  componentDidMount = async() => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setState({
        deviceSize,
        members: this.props.community.members
      });
  }

  exitReport = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("CommunityDirectory...")

    let memberListPanel;
    let bool = true;
    if (this.state.members.length > 0) {
      memberListPanel = (
        <div className="text-center">
          <div className="row">
            
            <div className="col-9 text-left member-list-head">
              Name, email, phone, role, baandaId, join date
            </div>
          </div>
          <div className="fixedsize-members-list">
            {this.state.members.map((obj, i) => {
              // console.log("690 obj:", obj);

              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                     
                    <div className="col text-left rep-member-list-lbl">
                      {obj.name}, {obj.email}, {obj.cell}, {obj.role}, {obj.baandaId}, {obj.joinDate.substring(0,10)}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
          <div className="text-center report-list-msg">
            {this.state.message}
          </div>
        </div>
      );
    }

    let exitButton = (
        <button
          onClick={this.exitReport}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          className="btn_back_main"
        >
          <i className="fas fa-step-backward" />
        </button>
      );

    let outputPanel = (
        <div className="members-list-box">
            <div className="text-center community-header-text">Community Members&nbsp;{exitButton}</div>
            {memberListPanel}
        </div>
    )


    return (
      <div>
         {outputPanel}
      </div>
    );
  }
}

export default CommunityDirectory;
