import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Range, getTrackBackground } from "react-range";

import axios from "axios";

import moment from "moment";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./Operate.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveUpdtEvent = "/routes/interact/saveUpdtEvent";
const getAnEvent = "/routes/inetract/getAnEvent?";

const STEP = 0.1;
const MIN = 0;
const MAX = 100;

const readMeCode = "1145200000";

class Operate extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      deviceSize: "",

      tasklist: [],
      theEvent: null,
      text: "",
      taskId: 0,

      chosenEmoji: null,

      value: 10,
      // values: [50],
      percentDone: [1],

      taskComment: "",
      taskStatus: "inprogress",
      allocated: 0,
      spent: 0,
      // percentDone: 1,
      taskOpsPanelFlag: false,
      selectedTask: null,

      errMsg: '',
      errFlag: false
    };
  }

  componentDidMount = async () => {

    let tet = await this.getTheEvent(this.props.theEvent.eventId);
    // console.log('tet:', tet);
    this.setState({
      tasklist: tet.prepTasks,
      theEvent: tet,
    });
  };

  // This is to show the info panel
  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getTheEvent = async (eventId) => {
    let param = "eventId=" + eventId;

    let url = baandaServer + getAnEvent + param;
    // console.log("url:", url);
    let theEvent = null;
    try {
      let eret = await axios.get(url);
      if (eret.data.status === "success") {
        theEvent = eret.data.Msg;
      } else {
        console.log("Err data:", eret.data.Msg);
        this.setState({
          errMsg: eret.data.Msg,
          errFlag: true
        });
      }
    } catch (err) {
      // console.log("Err sys:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true
      });
    }
    return theEvent;
  };

  handleTaskOpen = async (task) => {
    // console.log("tasK:", task);
    await this.setState({
      taskId: task.taskId,
      allocated: task.amountAllocated,
      spent: task.amountSpent,
      percentDone: [task.percentageDone],
      taskOpsPanelFlag: true,
      selectedTask: task,
      taskComment: "",
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  changeSlide = async (obj) => {
 
    this.setState({
      percentDone: obj,
    });
  };

  handleStatusType = async (e) => {
    await this.setState({
      taskStatus: e.target.value,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  packDataForDB = async () => {
    let preptask = [];

    this.state.tasklist.forEach((obj) => {
      let comments = obj.comments;
      let percentageDone = obj.percentageDone;
      let status = obj.status;
      if (obj.taskId === this.state.taskId) {
        status = this.state.taskStatus;
        percentageDone = parseFloat(this.state.percentDone[0].toFixed(1));
        let comm = {
          byName: this.props.auth.user.name,
          byEmail: this.props.auth.user.email,
          comment: this.state.taskComment,
          commentedOn: moment(),
          percentAssociate: percentageDone,
        };
        comments.push(comm);
      }
      let modp = {
        taskId: obj.taskId,
        subTeam: obj.subTeam,
        taskName: obj.taskName,
        description: obj.description,
        comments: comments,
        percentageDone: percentageDone,
        startDate: obj.startDate,
        endDate: obj.endDate,
        amountAllocated: obj.amountAllocated,
        amountSpent: obj.amountSpent,
        status: status,
      };
      preptask.push(modp);
    });

    let input = {
      communityId: this.props.communityId,
      eventId: this.state.theEvent.eventId,
      prepOpsTasks: preptask,
      module: "taskoperation",
      clientProgram: "Operate.js",
      clientFunction: "handleTaskOpsSave",
    };

    return input;
  };

  handleTaskOpsSave = async () => {
    let url = baandaServer + saveUpdtEvent;

    let inp = await this.packDataForDB();
    // console.log("inp:", inp);
    if (inp.prepOpsTasks.length > 0) {
      // console.log(" url:", url);
      try {
        let resp = await axios.post(url, inp);
        // console.log("rest:", resp);
        if (resp.data.status === "success") {
          await this.setState({
            theEvent: resp.data.Msg.theEvent,
            updtMsg: "Task state updated.",
            updateErrFlag: false,
            tasklist: resp.data.Msg.theEvent.prepTasks,
            endDate: moment(),
            startDate: moment(),
            taskName: "",
            description: "",
            subTeam: [],
            taskId: 0,
            taskComment: "",
            allocatedAmt: 0,
            allocatedAmount: 0,
            memberSelHeight: 4,
            taskOpsPanelFlag: false,
          });
        } else {
          this.setState({
            updtMsg: resp.data.Msg,
            updateErrFlag: true
          })
        }
      } catch (err) {
        this.setState({
          updtMsg: err.message,
          updateErrFlag: true
        })
        // this.props.returnToCaller(this.state.theEvent);
      }
    } else {
      await this.setState({
        updtMsg: "No valid & complete tasks to update.",
        updateErrFlag: true,
      });
    }
  };

  handleTaskClear = async () => {
    await this.setState({
      taskId: 0,
      allocated: 0,
      spent: 0,
      percentDone: [0],
      taskOpsPanelFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('Operate...');

    let bool = true;
    let taskListPanel;
    if (this.state.tasklist.length > 0) {
      taskListPanel = (
        <div className="cal-task-list-box">
          {this.state.tasklist.map((obj, i) => (
            <div key={i}>
              <div className={`${bool ? "row-ops-task-a" : "row-ops-task-b"}`}>
                <div
                  className={
                    obj.taskId === this.state.taskId
                      ? "row task-ops-active-row"
                      : "row"
                  }
                >
                  <div className="col-9 text-left">{obj.taskName}</div>
                  <div className="col-3 text-center">
                    <button
                      onClick={() => this.handleTaskOpen(obj)}
                      className="btn_reg_50"
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      Open
                    </button>
                  </div>
                </div>
              </div>
              {(bool = !bool)}
            </div>
          ))}
        </div>
      );
    }

    let commentlbl, alloc, spent;
    // let percentlbl, commentlbl, alloc, spent;
    if (this.state.deviceSize === "small") {
      // percentlbl = "Done";
      commentlbl = "Comment";
      alloc = "Alloc";
      spent = "Spent";
    } else {
      // percentlbl = "Task State";
      commentlbl = "Comment on work done/issues for this task.";
      alloc = "Allocated Amount";
      spent = "Spent Amount";
    }

    // The test case is in c:\>baanda-system\aaa-test-ver17 for reference
    let sliderPanel;
    sliderPanel = (
      <div>
        <Range
          values={this.state.percentDone}
          step={STEP}
          min={MIN}
          max={MAX}
          onChange={(obj) => this.changeSlide(obj)}
          renderTrack={({ props, children }) => (
            <div
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
              className="range-style"
            >
              <div
                ref={props.ref}
                style={{
                  height: "5px",
                  width: "100%",
                  borderRadius: "4px",
                  background: getTrackBackground({
                    values: this.state.percentDone,
                    colors: ["#308249", "#bf4739"],
                    min: MIN,
                    max: MAX,
                  }),
                  alignSelf: "center",
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              {...props}
              style={{
                ...props.style,
                height: "20px",
                width: "20px",
                borderRadius: "10px",
                backgroundColor: "#FFF",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                boxShadow: "0px 2px 6px #AAA",
              }}
            >
              <div
                style={{
                  height: "16px",
                  width: "10px",
                  backgroundColor: isDragged ? "black" : "#634e20",
                }}
              />
            </div>
          )}
        />
        <div className="col-2 text-level done-text-lbl-msg">
          {this.state.percentDone[0].toFixed(1)}&nbsp;%&nbsp;done.
        </div>
      </div>
    );

    // sliderPanel = (
    //   <div>
    //     <div className="row">
    //       <div className="col-2 text-right done-text-lbl">{percentlbl}</div>
    //       <div className="col-8 text-left">
    //         <Slider
    //           min={0}
    //           max={100}
    //           value={this.state.percentDone}
    //           onChangeStart={this.handleChangeStart}
    //           onChange={this.handleChange}
    //           onChangeComplete={this.handleChangeComplete}
    //         />
    //       </div>
    //       <div className="col-2 text-level done-text-lbl-msg">
    //         {this.state.percentDone}&nbsp;%&nbsp;done.
    //       </div>
    //     </div>
    //   </div>
    // );

    let taskStatusPanel;
    taskStatusPanel = (
      <div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Task Status is&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="inprogress"
                  checked={this.state.taskStatus === "inprogress"}
                  onChange={this.handleStatusType}
                />{" "}
                Start (in progress)
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="finished"
                  checked={this.state.taskStatus === "finished"}
                  onChange={this.handleStatusType}
                />{" "}
                Finished
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="canceled"
                  checked={this.state.taskStatus === "canceled"}
                  onChange={this.handleStatusType}
                />{" "}
                Canceled
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let budgetPanel;
    budgetPanel = (
      <div>
        <div className="text-center finance-header">Task's Financial State</div>
        <div className="row">
          <div className="col-6 text-center budget-lbl">
            {alloc}&nbsp;$&nbsp;
            {this.commaFormattedCurrency(this.state.allocated)}
          </div>
          <div className="col-6 text-center budget-lbl">
            {spent}&nbsp;$&nbsp;{this.commaFormattedCurrency(this.state.spent)}
          </div>
        </div>
      </div>
    );

    let buttonPanel = (
      <div>
        <button
          onClick={this.handleTaskOpsSave}
          className="btn_reg_50"
          type="button"
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          Save
        </button>
        <button
          onClick={this.handleTaskClear}
          className="btn_reg_50"
          type="button"
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          Clear
        </button>
      </div>
    );

    let taskOpsPanel;
    if (this.state.taskOpsPanelFlag) {
      taskOpsPanel = (
        <div>
          <div className="text-center done-text-lbl">{commentlbl}</div>
          <div className="row">
            <div className="col">
              <textarea
                name="taskComment"
                maxLength="1000"
                placeholder="Comment on the task or issues ..."
                rows="4"
                wrap="hard"
                spellCheck="true"
                className="cal-event-desc"
                onChange={this.onChange}
                value={this.state.taskComment}
                required
              />
            </div>
          </div>
          {sliderPanel}
          {taskStatusPanel}
          {budgetPanel}
          {buttonPanel}
        </div>
      );
    }

    let taskModalButtonPanel;
    taskModalButtonPanel = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let outputPanel = (
      <div>
        {taskListPanel}
        {taskOpsPanel}
      </div>
    );

    return (
      <div>
        <div className="text-center task-ops-header">
          Task's Operational Status&nbsp;&nbsp;{taskModalButtonPanel}
        </div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

Operate.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Operate));
