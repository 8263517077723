import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import LoadfileToS3 from "../../../../utils/components/fileUpload/LoadFileToS3";

import Payment from "./pay/Payment";

import "./Record.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getPricngs = "/routes/myaccount/getAllPricing?";
const getMembersOfCommunity = "/routes/dashboard/getMembersOfCommunity?";
const getShareData = "/routes/dashboard/getShareData?";

const maxpdfSize = 10000000; // 10mb  -- about 10 minutes
const maxIdSize = 1000000;

const emailDropMsg =
  "Registration confirms the email's validity. Additionally, a lender, or an investor, will have access to the community activity and may provide advices or suggestions. If the investor, or the lender, is not a member of your community, please invite them first using an admin utility option.";

class Record extends Component {
  constructor(props) {
    super(props);

    this.state = {
      investId: 0,

      recordErrorFlag: false,
      recordErrorMsg: "",

      recordMsgFlag: false,
      recordWarning: "",

      startFlag: true,
      originFlag: false,
      attachDocFlag: false,
      attachIdFlag: false,
      paymentFlag: false,
      showinvestmentNamePanelFlag: true,

      controlButtonFlag: true,

      investmentName: "",
      invDescription: "",
      recordDate: new Date(),
      investLoanAmt: 0,
      investLoanType: "equity",
      investVestInMonths: 12,

      numberOfShares: 0,
      loanInterestRate: 0.0,
      loanVestInMonths: 12,

      originType: "individual", // may be individual or organizaiton
      orgName: "",
      orgAddress: "", // Write the one line address

      invName: "",
      invEmail: "",
      invPhone: "",
      invBaandaId: 0,
      invIdType: "license",
      invIdNumber: "",

      invIdIssueDate: new Date(),
      invIdExpDate: new Date(),
      
      invNote: "",

      docS3FileData: null, // Contains the attachment S3DataFile loaded in S3
      docvalidFileType: [],
      docMaxFileSize: 0,
      docSubDirName: "",

      idValidFileType: [],
      idSubDirName: "",
      idMaxFileSize: maxIdSize,

      idS3FileData: null, // Contains the id attachment loaded in s3
      paymentS3FileData: null,

      s3Url: "",
      deviceSize: "",
      priceList: [],
      members: [],
      share: {},
      investmentHandlingFee: 0,
      investmentHandlingMinAmt: 0,
      loanHandlingFee: 0,
      loanHandlingMinAmt: 0,
    };
  }

  componentDidMount = async () => {
    // console.log("componentDidMount this.props:", this.props);
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.getBaandaPricing();
    await this.getMembers();
    await this.getShareData();
    await this.setState({
      deviceSize,
    });
  };

  getMembers = async () => {
    let params = "communityId=" + this.props.communityId + "&memberName=";
    let url = baandaServer + getMembersOfCommunity + params;
    try {
      let retmem = await axios.get(url);
      if (retmem.data.status === "Success" && retmem.data.Msg.length > 0) {
        await this.setState({
          members: retmem.data.Msg,
        });
      }
      // console.log("retmem:", retmem);
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  getShareData = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&clientProgram=Record&clientFunction=getMembers";
    let url = baandaServer + getShareData + params;
    // console.log("getShareData url:", url);
    try {
      let shareData = await axios.get(url);
      // console.log("shareData:", shareData);
      // console.log("shareData.data.Msg:", shareData.data.Msg);
      if (shareData.data.status === "success") {
        await this.setState({
          share: shareData.data.Msg,
        });
      }
    } catch (err) {
      console.log("Record.js getShareData err:", err.message);
    }
  };

  getBaandaPricing = async () => {
    try {
      let url = baandaServer + getPricngs;
      let resp = await axios.get(url);
      console.log("Record pricing resp:", resp);
      let investmentHandlingFee = 0;
      let investmentHandlingMinAmt = 0;
      let loanHandlingFee = 0;
      let loanHandlingMinAmt = 0;
      if (resp.data.Msg.length > 0) {
        let pl = [];
        resp.data.Msg.forEach((obj) => {
          pl.push(obj);
          if (obj.serviceName === "investmentHandlingFee") {
            investmentHandlingFee = obj.creditsCharged;
            investmentHandlingMinAmt = obj.minimumAmt;
          }
          if (obj.serviceName === "loanHandlingFee") {
            loanHandlingFee = obj.creditsCharged;
            loanHandlingMinAmt = obj.minimumAmt;
          }
        });
        await this.setState({
          priceList: pl,
          investmentHandlingFee,
          investmentHandlingMinAmt,
          loanHandlingFee,
          loanHandlingMinAmt,
        });
      }
    } catch (err) {
      console.log("componentDidMount records err:", err.message);
    }
  };

  openAlertModal = () => {
    let msg = {
      Header: "Investment & Loan Recording",
      Body: {
        oneLineSummary: `Elaborate on nuances of investment and loan recording`,
        steps: [
          {
            step: "Types",
            stepNote: "duual.' ",
          },
          {
            step: "Business Type = Business",
            stepNote:
              "It may be individual or Business. The business may be multi-member-LLC, private partnership, private corporation, unincorporated, public corporation, public partnership.",
          },
          {
            step: "Business (Community) Legal Info",
            stepNote:
              "It is important that you veryfy and provide business information ",
          },
          {
            step: "Business Type = Individual",
            stepNote: "cation.",
          },
        ],
        footnote: "elease.",
      },
      Footer: "This is the footer",
    };

    this.props.showModal(
      {
        open: true,
        title: "Alert - Start Here Header",
        message: msg,
        closeModal: this.closeModal,
      },
      "infoModal"
    );
  };

  errorPanelClose = async () => {
    await this.setState({
      recordErrFlag: false,
    });
  };

  messagePanelClose = async () => {
    await this.setState({
      recordMsgFlag: false,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleRecDate = async (date) => {
    await this.setState({
      recordDate: date,
    });
  };

  handleRevIdDate = async (date) => {
    await this.setState({
      invIdIssueDate: date,
    });
  };

  handleRevIdExpDate = async (date) => {
    await this.setState({
      invIdExpDate: date,
    });
  };

  onChangePrice = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  onChangeShares = async (e) => {
    let value = e.target.value;
    if (value <= this.state.share.sharesAvailableToTrade) {
      if (this.countDecimals(value) > 0) {
        let tmp = parseFloat(value).toFixed(0);
        let val = parseFloat(tmp);
        await this.setState({
          [e.target.name]: val,
        });
      } else {
        await this.setState({
          [e.target.name]: value,
        });
      }
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleInvestLoanType = async (e) => {
    let value = e.target.value;
    // console.log("value:", value);
    await this.setState({
      investLoanType: value,
      numberOfShares: 0,
      loanInterestRate: 0.0,
    });
  };

  randfn = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  readyDocAttach = async () => {
    let randomct, commrn, subBucket, fileType;
    if (this.state.docSubDirName === "") {
      randomct = this.randfn(100000000, 999999999);
      commrn = randomct.toString(16);
      subBucket =
        "cid" +
        this.props.communityId +
        "-authby" +
        this.props.auth.user.baandaId +
        "-investLoanDoc" +
        commrn;
    } else subBucket = this.state.docSubDirName;
    let docS3FileData = null;
    if (this.state.docS3FileData) docS3FileData = this.state.docS3FileData;

    fileType = ["pdf"];
    await this.setState({
      docvalidFileType: fileType,
      docSubDirName: subBucket,
      docMaxFileSize: maxpdfSize,
      docS3FileData,
    });
  };

  readyIdAttach = async () => {
    console.log("readyIdAttach");
    let randomct, commrn, subBucket, fileType;
    if (this.state.docSubDirName === "") {
      randomct = this.randfn(100000000, 999999999);
      commrn = randomct.toString(16);
      subBucket =
        "cid" +
        this.props.communityId +
        "-authby" +
        this.props.auth.user.baandaId +
        "-originId" +
        commrn;
    } else subBucket = this.state.docSubDirName;
    let idS3FileData = null;
    if (this.state.idS3FileData) idS3FileData = this.state.idS3FileData;

    fileType = ["png", "jpg"];
    await this.setState({
      idValidFileType: fileType,
      idSubDirName: subBucket,
      idMaxFileSize: maxIdSize,
      idS3FileData,
    });
  };

  validateForPayment = async () => {
    let isValid = true;
    let msg = "",
      warn = "";
    // will set recordErrMsg
    if (this.state.investmentName.trim() === "") {
      isValid = false;
      msg = "Investment must have a name. || ";
    }
    if (this.state.investLoanAmt === 0) {
      isValid = false;
      msg =
        msg + "There must be an amount associated with loan or investment. || ";
    }
    if (this.state.investLoanType === "equity") {
      if (this.state.numberOfShares === 0) {
        isValid = false;
        msg =
          msg +
          "For equity investment, there must be number of shares allocated. || ";
      }
      if (this.state.investVestInMonths === 0) {
        isValid = false;
        msg = msg + "Investment vesting in months is not specified. || ";
      }
    }

    if (this.state.investLoanType === "debit") {
      if (this.state.loanVestInMonths === 0) {
        isValid = false;
        msg = msg + "Loan vesting in months is not specified. || ";
      }
    }
    if (this.state.invEmail.trim() === "") {
      isValid = false;
      msg = msg + "Must specify origin's, or contact, email || ";
    } else {
      let iv = true;
      this.state.members.forEach(async (obj) => {
        if (obj.email === this.state.invEmail.trim()) {
          iv = false;
          await this.setState({
            invName: obj.name,
            invBaandaId: obj.baandaId,
          });
        }
      });
      if (iv) {
        isValid = false;
        msg = "Investor email is not a member of this community. || ";
      }
    }
    if (this.state.originType === "organization" && this.state.orgName === "") {
      isValid = false;
      msg = msg + "Organization name is mandatory for orgainzations. || ";
    }
    if (!this.state.idS3FileData) {
      isValid = false;
      msg = msg + "Must upload investor's Id picture (jpg or png). || ";
    } else if (this.state.idS3FileData.key === "") {
      isValid = false;
      msg = msg + "Must upload investor's Id picture (jpg or png). || ";
    }
    if (!this.state.docS3FileData) {
      if (this.state.originType === "organization") {
        isValid = false;
        msg =
          msg +
          "Must attach loan-inevstment doc where origin type is organization";
      } else {
        warn =
          "Warning: No supporting doc has been attached. It is highly recommended to store individualized conditions.";
      }
    }

    await this.setState({
      recordErrorMsg: msg,
      recordWarning: warn,
    });

    return isValid;
  };

  handleOpsButton = async (value) => {
    // console.log("data:", value);

    if (value === "attach") {
      await this.readyDocAttach();
    }

    if (value === "payment") {
      let isValid = await this.validateForPayment();

      if (!isValid) value = "error";
      // console.log("isValid: ", isValid, " value:", value);
    }

    await this.setState({
      startFlag: false,
      originFlag: false,
      attachDocFlag: false,
      paymentFlag: false,
      recordErrFlag: false,
      attachIdFlag: false,
    });

    switch (value) {
      case "start":
        await this.setState({
          startFlag: true,
        });
        break;
      case "origin":
        await this.setState({
          originFlag: true,
        });
        break;
      case "attach":
        await this.setState({
          attachDocFlag: true,
        });
        break;
      case "payment":
        await this.setState({
          paymentFlag: true,
          controlButtonFlag: false,
          showinvestmentNamePanelFlag: false,
        });
        break;
      case "error":
        await this.setState({
          recordErrFlag: true,
        });
        break;

      default:
    }
  };

  handleInvIdType = async (e) => {
    // e.preventDefault();
    await this.setState({
      invIdType: e.target.value,
    });
  };

  handleOriginType = async (e) => {
    // e.preventDefault();
    await this.setState({
      originType: e.target.value,
    });
  };

  closeOrigin = async () => {
    await this.setState({
      originFlag: false,
    });
  };

  callerOfDocAttachment = async (fileData) => {
    // console.log(">>> fileData:", fileData);
    let docS3Data;
    if (fileData.ifDeletedFlag) {
      docS3Data = null;
    } else {
      docS3Data = this.state.docS3FileData;
      if (fileData.s3FileData) docS3Data = fileData.s3FileData;
    }
    // let docS3Data = this.state.docS3FileData;
    // if (fileData) docS3Data = fileData;
    await this.setState({
      startFlag: false,
      originFlag: false,
      attachDocFlag: false,
      docS3FileData: docS3Data, // If return is null, retain the original docS3FileData state.
    });
  };

  callerOfOriginIdAttachment = async (fileData) => {
    // console.log(">>> fileData:", fileData);
    let idS3Data;
    if (fileData.ifDeletedFlag) {
      idS3Data = null;
    } else {
      idS3Data = this.state.itemS3FileData;
      if (fileData.s3FileData) idS3Data = fileData.s3FileData;
    }
    // let idS3Data = this.state.idS3FileData;
    // if (fileData) idS3Data = fileData;
    await this.setState({
      startFlag: false,
      originFlag: true,
      attachDocFlag: false,
      attachIdFlag: false,
      idS3FileData: idS3Data, // If return is null, retain the original docS3FileData state.
    });
  };

  callerOfPayment = async (status) => {
    // console.log("status:", status);
    if (status === "success") {
      await this.initiateState();
      await this.getShareData(); // Gets the  number of shares left
    } else {
      await this.setState({
        startFlag: true,
        originFlag: false,
        attachDocFlag: false,
        attachIdFlag: false,
        paymentFlag: false,
        controlButtonFlag: true,
        showinvestmentNamePanelFlag: true,
      });
    }
  };

  initiateState = async () => {
    await this.setState({
      investId: 0,
      recordErrorFlag: false,
      recordErrorMsg: "",
      recordMsgFlag: false,
      recordWarning: "",
      startFlag: true,
      originFlag: false,
      attachDocFlag: false,
      attachIdFlag: false,
      paymentFlag: false,
      showinvestmentNamePanelFlag: true,

      controlButtonFlag: true,

      investmentName: "",
      invDescription: "",
      recordDate: new Date(),
      investLoanAmt: 0,
      investLoanType: "equity",

      numberOfShares: 0,
      loanInterestRate: 0.0,
      loanVestInMonths: 12,

      invName: "",
      invEmail: "",
      invPhone: "",
      invBaandaId: 0,
      invIdType: "license",
      invIdNumber: "",
      invIdIssueDate: new Date(),
      invIdExpDate: new Date(),
      invNote: "",

      docS3FileData: null,
      docvalidFileType: [],
      docMaxFileSize: 0,
      docSubDirName: "",

      idValidFileType: [],
      idSubDirName: "",
      idMaxFileSize: maxIdSize,

      idS3FileData: null,
      paymentS3FileData: null,

      s3Url: "",
      deviceSize: "",
      share: {},
    });
  };

  closeStart = async () => {
    await this.setState({
      startFlag: false,
    });
  };

  attachOriginId = async () => {
    await this.readyIdAttach();
    await this.setState({
      attachIdFlag: true,
      attachDocFlag: false,
      originFlag: false,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
    return res;
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    let errorPanel;
    if (this.state.recordErrFlag) {
      errorPanel = (
        <div className="error-box">
          <p align="justify" className="message-text">
            <b>Errors:</b>&nbsp;{this.state.recordErrorMsg}
          </p>
          <div className="text-right">
            <button
              className="btn-err"
              onClick={this.errorPanelClose}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Close
            </button>
          </div>
        </div>
      );
    }

    let messagePanel;
    if (this.state.recordMsgFlag) {
      messagePanel = (
        <div className="message-box">
          <p align="justify" className="message-text">
            <b>Message:</b>&nbsp;{this.state.poProcessMsg}
          </p>
          <div className="text-rightbox    ">
            <button
              className="btn-err"
              onClick={this.messagePanelClose}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Close
            </button>
          </div>
        </div>
      );
    }

    let ilname,
      recDate,
      desc,
      amt,
      invIdDate,
      invIdExpDate,
      inNote,
      share,
      liType,
      vest,
      intRate;
    if (this.state.deviceSize === "small") {
      ilname = "Name";
      recDate = "RecDate";
      desc = "Describe";
      amt = "Amount";
      invIdDate = "Isu. Date";
      invIdExpDate = "Exp. Date";
      inNote = "ID-Note";
      share = "Shares";
      liType = "Type";
      vest = "Vest in";
      intRate = "APR";
    } else {
      ilname = "Invest-Loan Name";
      recDate = "Record Date";
      desc = "Describe";
      amt = "Amount";
      invIdDate = "Id Issue Date";
      invIdExpDate = "Id Exp. Date";
      inNote = "Identity Note";
      share = "Shares";
      liType = "Loan-Investment Type";
      vest = "Will be vested in";
      intRate = "Annual % Rate";
    }

    let equityPanel;
    if (this.state.share && this.state.investLoanType === "equity") {
      equityPanel = (
        <div>
          <div className="row">
            <div className="col rec-lbl text-center">
              Shares left for trading is{" "}
              <mark>
                {this.commaFormattedCurrency(
                  parseFloat(this.state.share.sharesAvailableToTrade) -
                    this.state.numberOfShares
                )}
              </mark>
            </div>
          </div>
          {this.state.investLoanAmt > 0 ? (
            <div className="row">
              <div className="col-3 rec-lbl text-right">{share}</div>
              <div className="col-9 text-left">
                <input
                  name="numberOfShares"
                  type="number"
                  value={this.state.numberOfShares}
                  onChange={this.onChangeShares}
                  size="20"
                  className="inv-loan-share"
                  step="1"
                  placeholder="0"
                  autoComplete="off"
                />
              </div>
            </div>
          ) : null}

          <div className="row">
            <div className="col rec-lbl text-center">
              {this.state.investLoanAmt > 0 ? (
                <div>
                  {this.state.numberOfShares > 0
                    ? "Price per share calculated is $" +
                      (
                        this.state.investLoanAmt / this.state.numberOfShares
                      ).toFixed(2)
                    : "Enter number of shares."}
                </div>
              ) : (
                "Specify amount to enter the number of shares."
              )}
            </div>
          </div>
          {this.state.investLoanAmt > 0 ? (
            <div className="row">
              <div className="col-3 rec-lbl text-right">{vest}</div>
              <div className="col-9 text-left">
                <input
                  name="investVestInMonths"
                  type="number"
                  value={this.state.investVestInMonths}
                  onChange={this.onChangeShares}
                  size="20"
                  className="inv-loan-amt"
                  step=".01"
                  placeholder="0"
                  autoComplete="off"
                />{" "}
                &nbsp; months
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    let loanPanel;
    if (this.state.investLoanType === "debit") {
      loanPanel = (
        <div>
          <div className="row">
            <div className="col-3 rec-lbl text-right">{intRate}</div>
            <div className="col-9 text-left">
              <input
                name="loanInterestRate"
                type="number"
                value={this.state.loanInterestRate}
                onChange={this.onChangePrice}
                size="20"
                className="inv-loan-amt"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 rec-lbl text-right">{vest}</div>
            <div className="col-9 text-left">
              <input
                name="loanVestInMonths"
                type="number"
                value={this.state.loanVestInMonths}
                onChange={this.onChangeShares}
                size="20"
                className="inv-loan-amt"
                step=".01"
                placeholder="0"
                autoComplete="off"
              />{" "}
              &nbsp; months
            </div>
          </div>
        </div>
      );
    }

    let startPanel;
    if (this.state.startFlag) {
      startPanel = (
        <div className="record-box">
          <div className="row">
            <div className="col-3 rec-lbl text-right">{recDate}</div>
            <div className="col-9 text-left inv-date">
              <DatePicker
                selected={this.state.recordDate}
                onChange={this.handleRecDate}
                // onSelect={this.handleDeliveryDate}
                name="recordDate"
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>
          <div className="row il-name">
            <div className="col-3 rec-lbl text-right">{ilname}</div>
            <div className="col-9 text-left">
              <input
                name="investmentName"
                type="text"
                value={this.state.investmentName}
                onChange={this.onChange}
                size="45"
                maxLength="50"
                className="record-text"
                placeholder="Investment-loan name ..."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 rec-lbl text-right">{desc}</div>
            <div className="col-9 text-left">
              <textarea
                name="invDescription"
                value={this.state.invDescription}
                onChange={this.onChange}
                // size="50"
                maxLength="400"
                rows="3"
                className="rec-textarea"
                placeholder="Enter a investment Note "
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 rec-lbl text-right">{amt}&nbsp;$</div>
            <div className="col-9 text-left">
              <input
                name="investLoanAmt"
                type="number"
                value={this.state.investLoanAmt}
                onChange={this.onChangePrice}
                size="20"
                className="inv-loan-amt"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="row inv-res">
            <div className="col text-center radio-fonts">
              <strong>{liType}&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="equity"
                    checked={this.state.investLoanType === "equity"}
                    onChange={this.handleInvestLoanType}
                  />{" "}
                  Equity (invest)
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="debit"
                    checked={this.state.investLoanType === "debit"}
                    onChange={this.handleInvestLoanType}
                  />{" "}
                  Debit (Loan)
                </label>
              </div>
            </div>
          </div>
          {equityPanel}
          {loanPanel}
          {/* Will hold equity or loan panel */}
          <div className="row">
            <div className="col-8 id-textarea-lbl text-right">&nbsp;</div>
            <div className="col-4 text-center ">
              <button
                onClick={this.closeStart}
                className="btn-inv-done"
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Done
              </button>
            </div>
          </div>
          <div className="height-below" />
        </div>
      );
    }

    let investmentNamePanel;
    if (this.state.showinvestmentNamePanelFlag) {
      investmentNamePanel = (
        <div className="row">
          <div className="col-3 text-right name-lbl">
            <b>{ilname}</b>:
          </div>
          <div className="col-9 text-left name-lbl">
            {this.state.investmentName === ""
              ? "Not Specified"
              : this.state.investmentName}
          </div>
        </div>
      );
    }

    let start, orign, payment, attach, recordlbl;
    if (this.state.deviceSize === "small") {
      start = "Start";
      orign = "Origin";
      payment = "Next";
      attach = "Pdf";
      recordlbl = "";
    } else {
      start = "Start";
      orign = "Origin";
      payment = "Next";
      attach = "DocPdf";
      recordlbl = "Invoice-Loan";
    }

    let recordPanelButtons;
    if (this.state.controlButtonFlag) {
      recordPanelButtons = (
        <div>
          <div className="row line-btn-panel">
            <div className="col text-center line-panel-header">
              {recordlbl} &nbsp;&nbsp;
              <button
                onClick={() => this.handleOpsButton("start")}
                className="btn-inv-loan-sm"
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {start}
              </button>
              <button
                onClick={() => this.handleOpsButton("origin")}
                className="btn-inv-loan-sm"
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {orign}
              </button>
              <button
                onClick={() => this.handleOpsButton("attach")}
                className="btn-attach"
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {attach}&nbsp;
                <i className="fa fa-paperclip" />
              </button>
              <button
                onClick={() => this.handleOpsButton("payment")}
                className="btn-inv-loan-sm"
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {payment}
              </button>
              <button
                className="btn-modal-record"
                type="button"
                onClick={this.openAlertModal}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <i className="fas fa-info-circle" />
              </button>
            </div>
          </div>
        </div>
      );
    }

    let attachId, email, orgName, orgAddr, cemail;
    if (this.state.deviceSize === "small") {
      attachId = "Id";
      email = "Email";
      cemail = "Email";
      orgName = "Org-Name";
      orgAddr = "Address";
    } else {
      attachId = "Id-Pic";
      email = "Origin Email";
      cemail = "Contact Email";
      orgName = "Org's Name";
      orgAddr = "Org's Address";
    }

    let originIndividualPanel;
    if (this.state.originType === "individual") {
      originIndividualPanel = (
        <div>
          <div className="row">
            <div className="col-3 rec-date rec-lbl text-right">{invIdDate}</div>
            <div className="col-9 text-left inv-date">
              <DatePicker
                selected={this.state.invIdIssueDate}
                onChange={this.handleRevIdDate}
                // onSelect={this.handleDeliveryDate}
                name="invIdIssueDate"
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 rec-date rec-lbl text-right">
              {invIdExpDate}
            </div>
            <div className="col-9 text-left inv-date">
              <DatePicker
                selected={this.state.invIdExpDate}
                onChange={this.handleRevIdExpDate}
                // onSelect={this.handleDeliveryDate}
                name="invIdIssueDate"
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>
          <div className="row inv-res">
            <div className="col text-center radio-fonts">
              <strong>Id Type: &nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="license"
                    checked={this.state.invIdType === "license"}
                    onChange={this.handleInvIdType}
                  />{" "}
                  Drv. License
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="dmvid"
                    checked={this.state.invIdType === "dmvid"}
                    onChange={this.handleInvIdType}
                  />{" "}
                  DMV Id
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="passport"
                    checked={this.state.invIdType === "passport"}
                    onChange={this.handleInvIdType}
                  />{" "}
                  Passport
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3 rec-lbl text-right">Id #</div>
            <div className="col-9 rec-text text-left">
              <input
                name="invIdNumber"
                type="text"
                value={this.state.invIdNumber}
                onChange={this.onChange}
                size="45"
                maxLength="50"
                className="record-license"
                placeholder="Id Number..."
              />
            </div>
          </div>
          <div className="row">
            <div className="col rec-text text-center">
              <div className="dropdown">
                <button
                  type="button"
                  className="btn-il-hover"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Why email must be community member?
                </button>
                <div className="dropdown-content msg-box">
                  <p align="justify">{emailDropMsg}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3 rec-lbl text-right">{email}</div>
            <div className="col-9 rec-text text-left">
              <input
                name="invEmail"
                type="text"
                value={this.state.invEmail}
                onChange={this.onChange}
                size="45"
                maxLength="50"
                className="record-license"
                placeholder="Investor's baanda email."
              />
            </div>
          </div>
        </div>
      );
    }

    let originOrgPanel;
    if (this.state.originType === "organization") {
      originOrgPanel = (
        <div>
          <div className="row">
            <div className="col-3 rec-lbl text-right">{orgName}</div>
            <div className="col-9 rec-text text-left">
              <input
                name="orgName"
                type="text"
                value={this.state.orgName}
                onChange={this.onChange}
                size="45"
                maxLength="50"
                className="record-license"
                placeholder="Org Name ..."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 rec-lbl text-right">{orgAddr}</div>
            <div className="col-9 rec-text text-left">
              <input
                name="orgAddress"
                type="text"
                value={this.state.orgAddress}
                onChange={this.onChange}
                size="45"
                maxLength="100"
                className="record-license"
                placeholder="Org Address..."
              />
            </div>
          </div>
          <div className="row">
            <div className="col rec-text text-center">
              <div className="dropdown">
                <button
                  type="button"
                  className="btn-il-hover"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Why email must be community member?
                </button>
                <div className="dropdown-content msg-box">
                  <p align="justify">{emailDropMsg}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-3 rec-lbl text-right">{cemail}</div>
            <div className="col-9 rec-text text-left">
              <input
                name="invEmail"
                type="text"
                value={this.state.invEmail}
                onChange={this.onChange}
                size="45"
                maxLength="50"
                className="record-license"
                placeholder="Investor's baanda email."
              />
            </div>
          </div>
        </div>
      );
    }

    let originPanel;
    if (this.state.originFlag) {
      originPanel = (
        <div className="record-box">
          <div className="origin text-center">Investor - Lender Identity</div>
          <div className="row inv-res">
            <div className="col text-center radio-fonts">
              <strong>Origin Type: &nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="individual"
                    checked={this.state.originType === "individual"}
                    onChange={this.handleOriginType}
                  />{" "}
                  Individual
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="organization"
                    checked={this.state.originType === "organization"}
                    onChange={this.handleOriginType}
                  />{" "}
                  Organization
                </label>
              </div>
            </div>
          </div>
          {originIndividualPanel}
          {originOrgPanel}
          <div className="row">
            <div className="col-3 id-textarea-lbl text-right">{inNote}</div>
            <div className="col-9 text-left">
              <textarea
                name="invNote"
                value={this.state.invNote}
                onChange={this.onChange}
                maxLength="400"
                rows="3"
                className="rec-textarea"
                placeholder="Additional identity references ... "
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 id-textarea-lbl text-right">&nbsp;</div>
            <div className="col-6 text-center ">
              <button
                onClick={this.closeOrigin}
                className="btn-inv-done"
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Done
              </button>
              <button
                onClick={this.attachOriginId}
                className="btn-attach"
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {attachId}&nbsp;
                <i className="fa fa-paperclip" />
              </button>
            </div>
          </div>
        </div>
      );
    }

    let attcahDocPanel;
    if (this.state.attachDocFlag) {
      attcahDocPanel = (
        <div>
          <LoadfileToS3
            returnToCaller={this.callerOfDocAttachment}
            communityId={this.props.communityId}
            fileType={this.state.docvalidFileType}
            maxSize={this.state.docMaxFileSize}
            s3subdir={this.state.docSubDirName}
            requestor="Invest-Loan-Doc (pdf)"
            s3FileData={this.state.docS3FileData}
            type="pdf"
          />
        </div>
      );
    }

    let attachIdPanel;
    if (this.state.attachIdFlag) {
      attachIdPanel = (
        <div>
          <LoadfileToS3
            returnToCaller={this.callerOfOriginIdAttachment}
            communityId={this.props.communityId}
            fileType={this.state.idValidFileType}
            maxSize={this.state.idMaxFileSize}
            s3subdir={this.state.idSubDirName}
            requestor="Id-picture (png, jpg)"
            s3FileData={this.state.idS3FileData}
            type="image"
          />
        </div>
      );
    }

    let paymentPanel;
    if (this.state.paymentFlag) {
      paymentPanel = (
        <div>
          <Payment
            returnToCaller={this.callerOfPayment}
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            ilState={this.state}
          />
        </div>
      );
    }

    let outputPanel = (
      <div>
        {recordPanelButtons}
        {attcahDocPanel}
        {attachIdPanel}
        {startPanel}
        {originPanel}
        {paymentPanel}
        {messagePanel}
        {errorPanel}
      </div>
    );

    return (
      <div className="inv-panel-position">
        <hr className="adj" />
        {investmentNamePanel}
        {outputPanel}
        <ModalContainer />
        <div className="base-height-rec" />
      </div>
    );
  }
}

Record.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Record));
