import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ShipPickup from "./delivery/ShipPickup";
import CreateInvoice from "./invoice/CreateInvoice";
import ReceivePayment from "../../../finance/ReceivePayment";

import "./CheckoutCart.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";

class CheckoutCart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      deliveryFlag: false,

      shipPickDone: false,
      deliveryData: null,

      createInvoiceFlag: false,
      takePaymentFlag: false,
      transactionDoneFlag: false,
      invoice: null,
      community: null,
      showCheckoutExitFlag: true,

      errMsg: '',
      errFlag: false
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let createInvoiceFlag = false;
    let deliveryFlag = false;
    await this.getCommunityInfo();
    if ( this.state.community ) {
      this.props.itemsInCart.forEach((obj) => {
        if (obj.itemType === "goods") deliveryFlag = true;
      });
    }
    // this.props.itemsInCart.forEach((obj) => {
    //   if (obj.itemType === "goods") deliveryFlag = true;
    // });

    if (!deliveryFlag && this.props.itemsInCart.length > 0)
      createInvoiceFlag = true;

    // await this.getCommunityInfo();
    // console.log('on mount state community :', this.state.community);
    // console.log('on mount deliveryFlag:', deliveryFlag);

    await this.setState({
      deviceSize,
      deliveryFlag,
      createInvoiceFlag,
    });
  };

  getCommunityInfo = async () => {
    // console.log('in getCommunityInfo ...');

    let parms = "communityId=" + this.props.community.communityId;
    let url = baandaServer + getCommunityInfo + parms;
    try {
      let retData = await axios.get(url);
      // console.log("CheckoutCart retData: ", retData);
      await this.setState({
        community: retData.data.Msg,
      });
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: false
      })
    }
  };

  goBackToCatalog = () => {
    this.props.returnToCaller("cancel");
  };

  returnFromShipPickup = async (spdata) => {
    console.log("spdata:", spdata); 

    await this.setState({
      deliveryFlag: false,
      shipPickDone: true,
      createInvoiceFlag: true,
      deliveryData: spdata,
    });
  };

  reDoShipPick = async () => {
    await this.setState({
      deliveryFlag: true,
      shipPickDone: false,
      createInvoiceFlag: false,
    });
  };

  returnFromInvoice = async (invoice) => {
    // console.log("return from invoice - invoice", invoice);
    invoice.deliveryData = this.state.deliveryData;
    await this.setState({
      deliveryFlag: false,
      shipPickDone: false,
      createInvoiceFlag: false,
      takePaymentFlag: true,
      invoice,
      showCheckoutExitFlag: false
    });
  };

  returnFromPayment = async (input) => {
    // console.log('returnFrompayment input:', input);
    let msg = 'cancel';
    if ( input === 'success' || input === 'totalout') msg = 'totalout'
    await this.setState({
      createInvoiceFlag: false,
      takePaymentFlag: false,
      // transactionDoneFlag: true, 
    });
    // console.log('msg:', msg);
    this.props.returnToCaller(msg);
  };

  totalExitOut = (input) => {
    // console.log("CheckoutCart eotal exit input:", input);
    this.props.returnToCaller(input);
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    console.log('CheckoutCart...');

    let checkoutExitButton;
    checkoutExitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.goBackToCatalog}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let shipButton;
    if (this.state.shipPickDone) {
      if (this.state.deviceSize === "small") {
        // console.log("here here 222");
        shipButton = (
          <button
            className="btn-checkout-delivery"
            type="button"
            onClick={this.reDoShipPick}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fa fa-ship" />
          </button>
        );
      } else {
        shipButton = (
          <button
            className="btn-checkout-delivery"
            type="button"
            onClick={this.reDoShipPick}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fa fa-ship" />
            &nbsp;
            <i className="fa fa-truck" />
          </button>
        );
      }
    }

    let outputPanel;
    if (this.state.deliveryFlag) {
      let store = null;
      let coopStoreId = 0;
      if ( this.props.store) {
        store = this.props.store;
        coopStoreId = this.props.store.storeId
      }
      outputPanel = (
        <div>
          <ShipPickup
            community={this.props.community}
            store={store}
            coopStoreId={coopStoreId}
            returnToCaller={this.returnFromShipPickup}
            itemsInCart={this.props.itemsInCart}
            caller={this.props.caller}
          />
        </div>
      );
    }

    if (this.state.createInvoiceFlag) {
      let store = null;
      let coopStoreId = 0;
      if ( this.props.store) {
        store = this.props.store;
        coopStoreId = this.props.store.storeId
      }
      outputPanel = (
        <div>
          <CreateInvoice
            deliveryData={this.state.deliveryData}
            community={this.state.community}
            store={store}
            coopStoreId={coopStoreId}
            returnToCaller={this.returnFromInvoice}
            itemsInCart={this.props.itemsInCart}
          />
        </div>
      );
    }

    if (this.state.takePaymentFlag) {
      // outputPanel = <div>Will go for payment</div>;
      let store = null;
      let coopStoreId = 0;
      if ( this.props.store) {
        store = this.props.store;
        coopStoreId = this.props.store.coopStoreId;
      }
      outputPanel = (
        <div>
          <ReceivePayment
            deliveryData={this.state.deliveryData}
            community={this.state.community}
            store={store}
            coopStoreId={coopStoreId}
            returnToCaller={this.returnFromPayment}
            itemsInCart={this.props.itemsInCart}
            cartId={this.props.cartId}
            invoice={this.state.invoice}
            caller={this.props.caller}
            calledFrom="catalogcheckout"
          />
        </div>
      );
    }

    // Unreachable for the transactionDoneFlag is never set to true. 
    // To be discussed in future if needed.
    if (this.state.transactionDoneFlag) {
      outputPanel = (
        <div className="text-center transaction-done-msg">
          Transaction complete successfully
          <div className="text-center">
            <button
              className="btn-total-exit"
              type="button"
              onClick={this.totalExitOut}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Exit&nbsp;
              <i className="far fa-check-circle" />
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="catalog-checkout-box">
        <div className="row">
          <div className="col-3">&nbsp;</div>
          <div className="col-6 text-center catalog-checkout-header">
            Checkout
          </div>
          <div className="col-3 text-center ship-button-col-pos">
            {shipButton}&nbsp;
            {this.state.showCheckoutExitFlag ? checkoutExitButton : null}
          </div>
        </div>
        {outputPanel}
      </div>
    );
  }
}

CheckoutCart.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

//   const mapDispatchToProps = (dispatch) => ({
//     hideModal: () => dispatch(hideModal()),
//     showModal: (modalProps, modalType) => {
//       dispatch(showModal({ modalProps, modalType }));
//     },
//   });

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(withRouter(CheckoutCart));
