import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactLoading from "react-loading";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import UpdateSetup from "./UpdateSetup";
import IndiUpdateSetup from "./individual/IndUpdateSetup";

// import CCPayReceive from "../../../../account/finance/communities/CCPayReceive";
import CCPayReceive from "../../../../account/finance/communities/CCPayReceiveTest";

import "./BeginSetup.css";

// Note for support personnel in future.
// most of the following (unlike most of other API directly and completely defined in routes.js in the server side, few of the following is functions of account setup. Hence, you would need to  get to file /routes/stripe/account and then find the API in it.)
const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAccountBasic = "/routes/stripe/account/getAccountBasic?";
// Check in route/stripe/stripeAccount to get all other stripe setup API.
const getAccountFull = "/routes/stripe/account/getAccountFull?";
const createStripAccount = "/routes/stripe/connectSetup/createStripAccount";
const deleteStripeAccount = "/routes/stripe/deleteStripeAccount";
const setOkToUseCC = "/routes/stripe/setOkToUseCC";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";

const readMeCode = "1151100000";

class BeginSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bizType: "",
      bizTypeInDb: null,
      bizTypeChange: false,
      startoverflag: false,
      okToDelete: false,
      transactFlag: false,
      stripeAccId: null,

      initiateDoneFlag: false,

      nextFlag: false,
      backFlag: false,
      flowIndex: 0,

      deviceSize: "",
      statusMsg: "",
      statusClickedFlag: false,
      statusFullFlag: false,
      testPayFlag: false,

      loadingFlag: false,
      completeFlag: false,

      statusInitMsg: "",
      statusBPMsg: "", // BP - BusinessProfile
      statusCMsg: "", // C - company
      statusPMsg: "", // P - Person (without identity)
      statusPIMsg: "", // PI - Person with Identity pictures
      statusEAMsg: "", // EA - External Account / Banking
      statusSummary: "",
      statusInitFlag: true,
      statusBPFlag: false,
      statusCFlag: false,
      statusPFlag: false,
      statusPIFlag: false,
      statusEAFlag: false,
      statusApproveFlag: false,
      accIdToDelete: "",

      requirements: "",
      errMsg: "",

      pendingRequirements: "",
      pendingReqLength: 0
    };
  }

  componentDidMount = async () => {
    await this.getAccountBasic();
  };

  getAccountBasic = async () => {
    let deviceSize;
    let param = "communityId=" + this.props.communityId;
    let url = baandaServer + getAccountBasic + param;
    // console.log("BeginSetup getAccountBasic url:", url);
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    try {
      let resp = await axios.get(url);
      // console.log("getAccountBasic onMount resp:", resp.data);
      if (!resp.data.msg.id) {
        await this.setState({
          initiateDoneFlag: false,
          deviceSize,
          statusClickedFlag: false,
        });
      } else {
        await this.setState({
          initiateDoneFlag: true,
          deviceSize,
          statusClickedFlag: false,
          bizType: resp.data.msg.business_type,
          stripeAccId: resp.data.msg.id,
          bizTypeInDb: resp.data.msg.business_type,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  getAccountFull = async () => {
    // console.log("In getAccountFull");
    await this.setState({
      statusClickedFlag: true,
    });
    if (
      !this.state.initiateDoneFlag ||
      !this.state.stripeAccId ||
      this.state.stripeAccId === ""
    ) {
      await this.setState({
        statusMsg:
          "You have not initiated your setup and have no Stripe Account yet.",
        statusClickedFlag: true,
      });
    } else {
      let param = "accId=" + this.state.stripeAccId;
      let url = baandaServer + getAccountFull + param;
      // console.log("getAccountFull url:", url);
      try {
        let resp = await axios.get(url);
        // console.log("resp:", resp.data);
        // Need to put more logic to see what will be the message and
        // what will be completeFlag state.
        if (resp.data.status === "success") {
          await this.filterStripeAccuntForStatus(resp.data.msg);
        }
        // console.log("BeginSetup this.state:", this.state);
        await this.setState({
          completeFlag: false,
          statusMsg: "Status: Initiatied CC account setting.",
          initiateDoneFlag: true,
          statusClickedFlag: true,
        });
      } catch (err) {
        console.log("err:", err.message);
      }
    }
  };

  filterStripeAccuntForStatus = async (inp) => {
    // console.log("filterStripeAccuntForStatus inp:", inp);

    let initFlag = false,
      initmsg = null;
    if (inp.account.id) {
      initFlag = true;
      initmsg = "Setup process initiated.";
    } else initmsg = "Setup process has not been initiated.";

    let bpFlag = false,
      bpmsg = null;
    if (inp.account.business_profile && inp.account.business_profile.mcc) {
      bpFlag = true;
      bpmsg = "Completed pending verification.";
    } else bpmsg = "Setup is incomplete.";

    let cFlag = false,
      cmsg = null;
    if (this.state.bizType === "company") {
      if (inp.account.company && inp.account.company.tax_id_provided) {
        cFlag = true;
        cmsg = "Setup is complete pending verification.";
      } else cmsg = "setup is incomplete.";
    } else {
      cFlag = true;
    }

    let eaFlag = false,
      eamsg = null;
    if (inp.account.external_accounts.data.length > 0) {
      eaFlag = true;
      eamsg = "Setup is complete pending verification.";
    } else eamsg = "Setup is incomplete.";

    let pFlag = false,
      pmsg = null;
    if (inp.person && inp.person.data.length > 0) {
      pFlag = true;
      pmsg = "Setup is complete pending verification.";
    } else pmsg = "Setup is incomplete.";

    let vmsg = "",
      vflg = false;
    if (this.state.bizType === "company") {
      if (
        inp.account.company.verification.document.front &&
        inp.person.data[0].verification.document.front
      ) {
        vmsg = "Documents (company & person ID) have been loaded";
        vflg = true;
      } else vmsg = "Documents (company & person ID) have not been loaded yet.";
    }
    if (this.state.bizType === "individual") {
      if (
        inp.account.individual &&
        inp.account.individual.verification &&
        inp.account.individual.verification.status === "verified"
      ) {
        if (
          inp.account.individual.verification.document.back &&
          inp.account.individual.verification.document.front
        ) {
          vmsg = "Documents (individual's Id) have been loaded";
          vflg = true;
        } else {
          vmsg = "Documents (individual's Id) has not yet been fully loaded.";
        }
      } else {
        vmsg = "Documents (individual's Id) has not been loaded yet.";
      }
    }

    let requirements = "";
    if (
      inp.account.requirements &&
      inp.account.requirements.currently_due &&
      inp.account.requirements.currently_due.length > 0
    ) {
      inp.account.requirements.currently_due.forEach((elm) => {
        requirements = requirements + elm + " | ";
      });
    } else requirements = "none";
    // console.log("requirements:", requirements);

    let okToUseCC = false;
    if (requirements === "none") {
      try {
        let params = "communityId=" + this.props.communityId;
        let url = baandaServer + getCommunityInfo + params;
        // console.log("getComm url:", url);
        let retcom = await axios(url);
        // console.log("retcom:", retcom);
        // console.log(
        //   "cardsetup:",
        //   retcom.data.Msg.adminTaskStates.creditCardSetup
        // );
        if (retcom.data.status === "success") {
          okToUseCC = retcom.data.Msg.adminTaskStates.creditCardSetup;
        }
      } catch (err) {
        console.log("err:", err.message);
      }
    }

    let req = inp.account.requirements;
    // console.log(">>> req:", req);
    let cdeadline = req.current_deadline;
    let cduelen = req.currently_due.length;
    let dreason = req.disabled_reason;
    let errlen = req.errors.length;
    let eduelen = req.eventually_due.length;
    let pduelen = req.past_due.length;
    let pverificationlen = req.pending_verification.length;

    let allDonePart = false;
    // console.log("cdeadline:", cdeadline);
    // console.log("dreason:", dreason);
    // console.log("vflg:", vflg);
    if (!cdeadline && !dreason && vflg) {
      // console.log("I am here ... ");
      allDonePart = true;
    }
    // if ( !dreason && vflg) {
    //   console.log('I am here ... ');
    //   allDonePart = true;
    // }

    let vlens = false;
    if (
      cduelen === 0 &&
      errlen === 0 &&
      eduelen === 0 &&
      pduelen === 0 &&
      pverificationlen === 0
    )
      vlens = true;

    // Check if all information is provided and no requirements left at
    // company.verification and person.verification
    let allDone;
    if (allDonePart && vlens) allDone = true;
    else allDone = false;

    // console.log(
    //   "allDonePart:",
    //   allDonePart,
    //   "  vlens:",
    //   vlens,
    //   "  allDone:",
    //   allDone
    // );

    let finalmsg = "Waiting ... for setup completion.";
    if (allDone) finalmsg = "Your setup info-input is complete.";

    await this.setState({
      statusInitFlag: initFlag,
      statusBPFlag: bpFlag,
      statusCFlag: cFlag,
      statusPFlag: pFlag,
      statusPIFlag: vflg,
      statusEAFlag: eaFlag,
      statusApproveFlag: allDone,
      statusInitMsg: initmsg,
      statusBPMsg: bpmsg, // BP - BusinessProfile
      statusCMsg: cmsg, // C - company
      statusPMsg: pmsg, // P - Person (without identity)
      statusPIMsg: vmsg, // PI - Person with Identity pictures
      statusEAMsg: eamsg, // ES - External Account / Banking
      statusSummary: finalmsg,
      requirements,
      okToUseCC,
    });

    // console.log("this.state.statusApproveFlag:", this.state.statusApproveFlag);
    return true;
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  onChangeBizToDelete = async (e) => {
    e.preventDefault();
    await this.setState({ accIdToDelete: e.target.value });
    // console.log("this.state.accIdToDelete:", this.state.accIdToDelete);
    // console.log("this.state.stripeAccId:", this.state.stripeAccId);
    if (this.state.accIdToDelete === this.state.stripeAccId) {
      await this.setState({
        okToDelete: true,
      });
    } else {
      await this.setState({
        okToDelete: false,
      });
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        // message: msg,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleCancel = async () => {
    this.props.cancelledBeginSetup();
  };

  handleInitiate = async () => {
    await this.setState({
      loadingFlag: true,
    });
    // console.log("handleInitiate");
    let inpData = {
      communityId: this.props.communityId, // : 1234;
      // stripeAcctype: "custom",
      // business_type: "company", // It is
      business_type: this.state.bizType,
      // country: "US",          // Now it all in USA
      email: this.props.auth.user.email,
    };

    let url = baandaServer + createStripAccount;
    // console.log("handleInitiate inpData:", inpData, "  url:", url);
    try {
      let ret = await axios.post(url, inpData);
      // console.log("ret.data:", ret.data);
      if (ret.data.msg.id) {
        await this.setState({
          initiateDoneFlag: true,
          stripeAccId: ret.data.msg.id,
          completeFlag: false,
          statusMsg: "Message: Successfully initiatied your cc setup account.",
        });
      }
    } catch (err) {
      // console.log("err:", err.message);
      await this.setState({
        initiateDoneFlag: false,
        completeFlag: false,
        statusMsg: "Error:" + err.message,
      });
    }
    await this.setState({
      loadingFlag: false,
    });
  };

  handleBusinessType = async () => {
    let value = document.getElementById("bizType").value;
    // console.log("value:", value);
    let errMsg = "";
    await this.setState({
      bizType: value,
      // initiateDoneFlag: false,
    });

    if (this.state.bizType === "startover") {
      if (this.state.stripeAccId) {
        await this.setState({
          startoverflag: true,
          bizTypeChange: true,
          errMsg,
        });
      } else {
        // console.log("in here 390 ...");
        await this.setState({
          startoverflag: false,
          bizTypeChange: false,
          errMsg: "No Stripe account to delete.",
        });
      }
    } else {
      if (this.state.bizType !== this.state.bizTypeInDb) {
        if (this.state.stripeAccId) {
          await this.setState({
            bizTypeChange: true,
            startoverflag: false,
            errMsg,
          });
        } else {
          await this.setState({
            bizTypeChange: false,
            startoverflag: false,
            errMsg,
          });
        }
      } else {
        await this.setState({
          bizTypeChange: false,
          startoverflag: false,
          errMsg,
        });
      }
    }
  };

  stateOfSetup = async () => {
    try {
      let param = "accId=" + this.state.stripeAccId;
      let url = baandaServer + getAccountFull + param;
      // console.log("getAccountFull url:", url);
      try {
        let resp = await axios.get(url);
        // console.log("resp:", resp.data);
        // Need to put more logic to see what will be the message and
        // what will be completeFlag state.
        let requirements = "";
        let reqLength = 0;
        if (resp.data.status === "success") {
          let inp = resp.data.msg;
          if (
            inp.account.requirements &&
            inp.account.requirements.currently_due &&
            inp.account.requirements.currently_due.length > 0
          ) {
            // console.log('inp.account.requirements.currently_due.length:', inp.account.requirements.currently_due.length);
            reqLength = inp.account.requirements.currently_due.length;
            inp.account.requirements.currently_due.forEach((elm) => {
              requirements = requirements + elm + " | ";
            });
          } else requirements = "none";
        }
        // console.log('requirements:', requirements);
        // console.log("BeginSetup this.state:", this.state);
        this.setState({
          pendingRequirements: requirements,
          pendingReqLength: reqLength
        });
      } catch (err) {
        console.log("err:", err.message);
      }
    } catch (err) {}
  };

  handleNext = async () => {
    // console.log("next");
    await this.stateOfSetup();
    await this.setState({
      nextFlag: true,
      statusClickedFlag: false,
      startoverflag: false,
    });
  };

  handleBackBegin = async () => {
    await this.setState({
      nextFlag: false,
    });
  };

  handleDelete = async () => {
    let url = baandaServer + deleteStripeAccount;
    let data = {
      commId: this.props.communityId,
      accid: this.state.stripeAccId,
    };
    // console.log("handleDelete data:", data, " url:", url);
    try {
      await axios.post(url, data);
      // console.log("delres:", delres);
      await this.setState({
        bizType: "",
        bizTypeInDb: null,
        bizTypeChange: false,
        startoverflag: false,
        okToDelete: false,
        transactFlag: false,
        stripeAccId: null,
        initiateDoneFlag: false,
        nextFlag: false,
        backFlag: false,
        flowIndex: 0,
        statusMsg: "",
        statusClickedFlag: false,
        statusFullFlag: false,
        loadingFlag: false,
        completeFlag: false,
        statusInitMsg: "",
        statusBPMsg: "", // BP - BusinessProfile
        statusCMsg: "", // C - company
        statusPMsg: "", // P - Person (without identity)
        statusPIMsg: "", // PI - Person with Identity pictures
        statusEAMsg: "", // EA - External Account / Banking
        statusSummary: "",
        statusInitFlag: true,
        statusBPFlag: false,
        statusCFlag: false,
        statusPFlag: false,
        statusPIFlag: false,
        statusEAFlag: false,
        statusApproveFlag: false,
        accIdToDelete: "",
      });
      // console.log("this.state:", this.state);
      // console.log("this.state.stripeAccId:", this.state.stripeAccId);
      await this.getAccountBasic();
    } catch (err) {
      console.log("handleDelete  err: ", err.message);
    }
  };

  handleTestPay = async () => {
    await this.setState((prevState) => {
      return {
        statusClickedFlag: !prevState.statusClickedFlag,
        testPayFlag: !prevState.testPayFlag,
      };
    });
    // console.log("this.state.testPayFlag:", this.state.testPayFlag);
  };

  returnToSetup = async () => {
    await this.setState({
      statusClickedFlag: true,
      testPayFlag: false,
    });
  };

  // This is where you update community.adminTaskStates.creditCardSetup as true.
  // Before this is true, the option to do business with cc will not be available.
  // This should only be clicked if and only if all setup are OK and test pay
  // has successfully disbursed 31c into commuity bank and paid stripe 2.9% + 30c
  // as charge.
  handleStartEcomm = async () => {
    // alert('this needs to be completed. Present test does not includ webhook based feedback from Stripe. Show All-OK button when webhooks confirm readiness and the manual Test-pay has been successfully performed. Ask the user to manually type something so this is not clicked by mistake. Right now, it is faked to continue show casing.')
    try {
      let inp = {
        communityId: this.props.communityId,
        clientProgram: "BeginSetup.js",
        clientFunction: "handleStartEcomm",
      };
      let url = baandaServer + setOkToUseCC;
      let retcc = await axios.post(url, inp);
      // console.log("retcc:", retcc);
      if (retcc.data.status === "success") {
        await this.setState({
          statusMsg: "You are all set for E-comm",
          okToUseCC: true,
        });
      } else {
        await this.setState({
          statusMsg: "All-OK Error: " + retcc.data.Msg,
        });
      }
    } catch (err) {
      console.log("cc all OK err:", err.message);
    }
  };

  render() {
    // console.log("BeginSetup this.state:", this.state);
    // console.log("BeginSetup this.props:", this.props);
    console.log("BeginSetup CC");

    // Upload Spinner _____________________________________________
    let uploadingSpin;

    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div>
          <ReactLoading
            type={"spokes"}
            color={"#195670"}
            height={30}
            width={30}
          />
        </div>
      );
    } else {
      uploadingSpin = null;
    }

    // let biztypelocal;
    // // if (this.state.bizTypeInDb ) {
    // if (this.state.bizTypeInDb === "company") {
    //   if (this.state.deviceSize === "small") biztypelocal = "Curr BizType";
    //   else biztypelocal = "Business Type Now";
    // } else {
    //   if (this.state.deviceSize === "small")
    //     biztypelocal = "Curr BizType: None";
    //   else biztypelocal = "Business Type Now: None";
    // }

    let basePanel;
    // if (!this.state.initiateDoneFlag) {
    basePanel = (
      <div>
        <div className="function_header">
          <u>Begin Setup Initiation</u>
        </div>
        <div className="row">
          <div className="col-md-6 text-left show_setup_text">
            <b>Email</b>: {this.props.auth.user.email}
          </div>
          <div className="col-md-6 text-left show_setup_text">
            <b>Account Type</b>:&nbsp;Custom
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 text-left show_setup_text">
            {/* <b>Country</b>: US&nbsp;&nbsp;||&nbsp;&nbsp; <b>{biztypelocal}</b>
            :&nbsp;{this.state.bizTypeInDb} */}
            &nbsp;
          </div>{" "}
          <div className="col-md-6 text-left show_setup_text">
            <b>Business Type:&nbsp;</b>
            <select
              id="bizType"
              name="bizType"
              onChange={this.handleBusinessType}
              className="biztype_select"
              defaultValue={"DEFAULT"}
            >
              <option value="DEFAULT" disabled>
                Select business type
              </option>
              <option value="company">Business (EIN based)</option>
              <option value="individual">Individual (SSN based)</option>
              <option value="startover">startover - delete past</option>
            </select>
          </div>
        </div>
      </div>
    );
    // }

    let deleteButtonPanel;
    if (this.state.okToDelete) {
      deleteButtonPanel = (
        <div>
          <button
            onClick={this.handleDelete}
            className="btn_reg_70"
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Delete
          </button>
          &nbsp;
        </div>
      );
    }

    let deletePanel;
    if (this.state.startoverflag) {
      deletePanel = (
        <div>
          <div className="text-center init_note">
            Enter the current CC Acc Id to delete.
          </div>
          <div className="row">
            <div className="col">
              <input
                name="accIdToDelete"
                type="text"
                value={this.state.accIdToDelete}
                onChange={this.onChangeBizToDelete}
                size="100"
                maxLength="100"
                className="input_bpname"
                spellCheck="false"
                placeholder="CC Acc Id"
              />
            </div>
          </div>
          {deleteButtonPanel}
        </div>
      );
    }

    let bizTypeChangePanel;
    if (this.state.bizTypeChange) {
      bizTypeChangePanel = (
        <div className="text-center init_note">
          You have an active cc account. You need to select Business_type =
          startover (delete past) to first delete and then re-start.
        </div>
      );
    }

    let actionButton;
    if (!this.state.bizTypeChange && this.state.bizType !== "") {
      if (!this.state.startoverflag && this.state.errMsg === "") {
        if (!this.state.initiateDoneFlag) {
          actionButton = (
            <button
              onClick={this.handleInitiate}
              className="btn_reg_80"
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Initiate
            </button>
          );
        } else {
          actionButton = (
            <button
              onClick={this.handleNext}
              className="btn_reg_60"
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Next
            </button>
          );
        }
      }
    }

    let accIdShow;
    if (this.state.stripeAccId) {
      accIdShow = (
        <div className="init_note">
          (Business Type:{" "}
          {this.state.bizType === "individual" ? "Individual" : "Company"}
          )&nbsp;<b>CC Acc Id:&nbsp;{this.state.stripeAccId}</b>
        </div>
      );
    }

    let testPayPanelButton;
    if (this.state.statusApproveFlag) {
      testPayPanelButton = (
        <div className="row">
          <div className="col-8 test-pay-msg">
            Click 'Read Me' to get the details of this test-pay funciton.
          </div>
          <div className="col-4 text-center dropdown-st">
            <button
              onClick={this.handleTestPay}
              className="btn_reg_80"
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Test Pay
            </button>
            <button
              onClick={this.handleStartEcomm}
              className="btn_reg_90"
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              All-OK&nbsp;
              <i className="fa fa-thumbs-up" />
            </button>
            <div className="dropdown-content-st">
              <p align="justify" className="info-text">
                Only press All-Ok after you have confirmed with a test payment
                that your bank account has received the funds. This is the last
                manual check that will confirm that your customers’ credit card
                payments will end up in your bank account or your business’ bank
                account. Please reach out to Baanda Support at info@baanda.com
                if you have any questions.
              </p>
            </div>
          </div>
        </div>
      );
    }

    let statusPanel;
    if (this.state.statusClickedFlag) {
      let individualOrPerson;
      if (this.state.bizType === "individual")
        individualOrPerson = "Individual";
      if (this.state.bizType === "company") individualOrPerson = "Person";

      statusPanel = (
        <div>
          {/* {accIdShow} */}
          <div className="status_msg_head">
            <u>Status Itemized</u>
          </div>
          {this.state.statusInitFlag ? (
            <div align="justify" className="status_msg_line">
              <i className="fas fa-angle-double-right" />
              &nbsp;Initiation:&nbsp;{this.state.statusInitMsg}
            </div>
          ) : (
            <div align="justify" className="status_msg_line_err">
              <i className="fas fa-angle-double-right" />
              &nbsp;Initiation:&nbsp;{this.state.statusInitMsg}
            </div>
          )}
          {this.state.statusBPFlag ? (
            <div align="justify" className="status_msg_line">
              <i className="fas fa-angle-double-right" />
              &nbsp;Business Profile:&nbsp;{this.state.statusBPMsg}
            </div>
          ) : (
            <div align="justify" className="status_msg_line_err">
              <i className="fas fa-angle-double-right" />
              &nbsp;Business Profile:&nbsp;{this.state.statusBPMsg}
            </div>
          )}
          {this.state.statusCFlag && this.state.bizType === "company" ? (
            <div align="justify" className="status_msg_line">
              <i className="fas fa-angle-double-right" />
              &nbsp;Company:&nbsp;{this.state.statusCMsg}
            </div>
          ) : null}
          {this.state.statusEAFlag ? (
            <div align="justify" className="status_msg_line">
              <i className="fas fa-angle-double-right" />
              &nbsp;Banking:&nbsp;{this.state.statusEAMsg}
            </div>
          ) : (
            <div align="justify" className="status_msg_line_err">
              <i className="fas fa-angle-double-right" />
              &nbsp;Banking:&nbsp;{this.state.statusEAMsg}
            </div>
          )}
          {this.state.statusPFlag ? (
            <div align="justify" className="status_msg_line">
              <i className="fas fa-angle-double-right" />
              &nbsp;{individualOrPerson} :&nbsp;{this.state.statusPMsg}
            </div>
          ) : (
            <div align="justify" className="status_msg_line_err">
              <i className="fas fa-angle-double-right" />
              &nbsp;{individualOrPerson} :&nbsp;{this.state.statusPMsg}
            </div>
          )}
          {this.state.statusPIFlag ? (
            <div align="justify" className="status_msg_line">
              <i className="fas fa-angle-double-right" />
              &nbsp;ID Verification:&nbsp;{this.state.statusPIMsg}
            </div>
          ) : (
            <div align="justify" className="status_msg_line_err">
              <i className="fas fa-angle-double-right" />
              &nbsp;ID Verification:&nbsp;{this.state.statusPIMsg}
            </div>
          )}
          {this.state.statusApproveFlag ? (
            <div align="justify" className="status_msg_line">
              <i className="fas fa-angle-double-right" />
              &nbsp;Summary:&nbsp;{this.state.statusSummary}
            </div>
          ) : (
            <div align="justify" className="status_msg_line_err">
              <i className="fas fa-angle-double-right" />
              &nbsp;Summary:&nbsp;{this.state.statusSummary}
            </div>
          )}
          {this.state.requirements === "none" ? (
            <div align="justify" className="status_msg_line">
              <i className="fas fa-angle-double-right" />
              &nbsp;Requirements Pending:&nbsp;{this.state.requirements}
            </div>
          ) : (
            <div align="justify" className="status_msg_line_err">
              <i className="fas fa-angle-double-right" />
              &nbsp;Requirements Pending:&nbsp;{this.state.requirements}
            </div>
          )}
          {/* {this.state.requirements === "none" ? (
            <div align="justify" className="status_msg_line">
              <i className="fas fa-angle-double-right" />
              &nbsp;Requirements Pending:&nbsp;{this.state.requirements}
            </div>
          ) : (
            <div align="justify" className="status_msg_line_err">
              <i className="fas fa-angle-double-right" />
              &nbsp;Requirements Pending:&nbsp;{this.state.requirements}
            </div>
          )} */}
          {this.state.okToUseCC ? (
            <div align="justify" className="status_msg_line">
              <i className="fas fa-angle-double-right" />
              &nbsp;Readiness:&nbsp;All set to do E-Commerce using CC.
            </div>
          ) : (
            <div align="justify" className="status_msg_line_err">
              <i className="fas fa-angle-double-right" />
              &nbsp;Readiness:&nbsp;Check Requriements Pending. When 'none'
              perform Test Pay. If all right click All-OK.
            </div>
          )}

          {testPayPanelButton}
        </div>
      );
    }

    let testPayPanel;
    if (this.state.testPayFlag) {
 
      testPayPanel = (
        <div>
          <CCPayReceive
            accountId={this.state.stripeAccId}
            returnToCallingFn={this.returnToSetup}
            payType="setup-test-pay"
            communityId={this.props.communityId}
          />
        </div>
      );
    }

    let initiateNote;
    let deviceSizeMsg;
    if (this.state.bizType && !this.state.bizTypeChange) {
      if (!this.state.initiateDoneFlag) {
        if (!this.state.statusClickedFlag) {
          initiateNote = (
            <div className="init_note">
              <p align="justify">
                Thank you for selecting your business type. If you made a
                mistake in your selection, hit Cancel. The information needed to
                set up your account with our credit card service provider is
                specific to different types of businesses. Please make sure you
                choose correctly. Click Initiate to begin the process.
              </p>
            </div>
          );
        } else {
          initiateNote = (
            <div className="init_note">
              <p align="justify">{this.state.statusMsg}</p>
            </div>
          );
        }
      } else {
        if (this.state.deviceSize === "small") {
          deviceSizeMsg =
            "Note: A lot of information will be asked during setup. A larger device (laptop, desktop etc.) will be much more convenient. Accuracy of your information is very important for every aspect of that would be verified before credit card based payment (e-comm) is activated.";
        }
        if (this.state.statusClickedFlag) {
          if (!this.state.initiateDoneFlag) {
            initiateNote = (
              <div className="init_note">
                <p align="justify">{this.state.statusMsg}</p>
              </div>
            );
          } else {
            initiateNote = statusPanel;
            // Show status
          }
        } else {
          initiateNote = (
            <div className="init_note">
              {/* <p>
                <b>Acc Id:&nbsp;{this.state.stripeAccId}</b>
              </p> */}
              <p align="justify">
                Click ‘Next’ to set up or modify your credit card account. You
                can click ‘Status’ to check your progress.
              </p>
              <p align="justify">
                <font color="">
                  <b>{deviceSizeMsg}</b>
                </font>
              </p>
            </div>
          );
        }
      }
    } else {
      if (this.state.statusClickedFlag) {
        if (this.state.stripeAccId) {
          initiateNote = statusPanel;
        } else {
          initiateNote = (
            <div className="init_note">
              <p align="justify">{this.state.statusMsg}</p>
            </div>
          );
        }
      } else {
        if (this.state.startoverflag) {
          initiateNote = deletePanel;
        } else {
          initiateNote = bizTypeChangePanel;
        }
      }
    }

    let updatepanel;

    // console.log("this.state.initiateDoneFlag:", this.state.initiateDoneFlag);
    // console.log("this.state.nextFlag:", this.state.nextFlag);
    // console.log("this.state.bizType:", this.state.bizType);

    if (this.state.initiateDoneFlag && this.state.nextFlag) {
      basePanel = null;
      if (this.state.bizType === "company") {
        updatepanel = (
          <div>
            <UpdateSetup
              stripeAccId={this.state.stripeAccId}
              businessType={this.state.bizType}
              handleBackBegin={this.handleBackBegin}
              communityId={this.props.communityId}
              pendingRequirements={this.state.pendingRequirements} 
              pendingReqLength={this.state.pendingReqLength}
            />
          </div>
        );
      }
      if (this.state.bizType === "individual") {
        updatepanel = (
          <div>
            <IndiUpdateSetup
              stripeAccId={this.state.stripeAccId}
              businessType={this.state.bizType}
              handleBackBegin={this.handleBackBegin}
              communityId={this.props.communityId}
              pendingRequirements={this.state.pendingRequirements} 
              pendingReqLength={this.state.pendingReqLength}
            />
          </div>
        );
      }
    }

    let commonButtons = (
      <div>
        <button
          onClick={this.handleCancel}
          className="btn_reg_70"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Cancel
        </button>
        &nbsp;
        <button
          onClick={this.getAccountFull}
          className="btn_reg_70"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Status
        </button>
        &nbsp;
        {actionButton}
        &nbsp; &nbsp;
        <button
          className="btn_reg_100"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Read Me &nbsp;
          <i className="fas fa-info-circle" />
        </button>
        &nbsp;
        <div>{accIdShow}</div>
      </div>
    );

    let outputPanel;

    if (!this.state.nextFlag && !this.state.testPayFlag) {
      // console.log("I am here 111");
      outputPanel = (
        <div>
          {commonButtons}
          {uploadingSpin}
          <div className="fixedsize_stripe">{initiateNote}</div>
          <div className="space_below_status" />
        </div>
      );
    }

    if (
      this.state.testPayFlag &&
      !this.state.nextFlag &&
      !this.state.statusClickedFlag
    ) {
      // console.log("I am here 222");
      basePanel = null;
      outputPanel = <div>{testPayPanel}</div>;
    }

    if (
      this.state.nextFlag &&
      !this.state.testPayFlag &&
      !this.state.statusClickedFlag
    ) {
      console.log("I am here 333");
      outputPanel = <div>{updatepanel}</div>;
    }

    return (
      <div>
        {basePanel}
        <div className="cc-setup-begin-err">{this.state.errMsg}</div>
        {outputPanel}

        <ModalContainer />
      </div>
    );
  }
}

BeginSetup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BeginSetup));
