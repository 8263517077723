import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { sortArrayOfDates } from "../../../../utils/sortArrayOfDates";

import "./PayCtrlDetail.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getLastFourCardNo = "/routes/payctrl/getLastFourCardNo?";
const getASubscriptionState = "/routes/payctrl/getASubscriptionState?";
const handleCommSubDetailProcess = "/routes/payctrl/handleCommSubDetailProcess";
const getPaymentFailedReason = "/routes/payctrl/getPaymentFailedReason?";

const baandaCommunityId = process.env.REACT_APP_BAANDA_COMMUNITY_ID;

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1131100000";

class payCtrlDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      subscriptionActions: "messages",
      pauseState: "",
      pauseType: "delaypay",
      pauseDurationType: "enddate",

      pauseResumeDate: new Date(),

      last4: "",
      sendMessage: "",
      subscriptionState: null,
      subscriptionStatus: "",

      showPauseFlag: false,
      showCancelFlag: false,
      showResumeFlag: false,
      showProcessButtonFlag: true,
      showAmountDetailsFlag: false,

      confirmOps: false,
      opsMessage: "",

      subSelected: null,
      sortedMsgs: [],

      restrictAccess: true,

      errMsg: "",
      errFlag: false,
      payFailErroMsg: "",
      subPayErr: false,
    };
  }

  componentDidMount = async () => {
    await this.getLast4OfAttachedCard();
    await this.setSellerControlState();

    if (this.props.subSelected.lastSubscriptionState === "payment_failed") {
      await this.getTheErrorReason(this.props.subSelected.subscriptionId);
    }

    let sortedMsgs = await this.sortMessages(this.props.subSelected.messages);
    this.setState({
      subSelected: this.props.subSelected,
      sortedMsgs,
    });
  };

  getTheErrorReason = async (subId) => {
    let paymentIntentId = "";
    if (this.props.subSelected.paymentFailedObj) {
      paymentIntentId = this.props.subSelected.paymentFailedOb.payment_intent;
      let param =
        "subscriptionId=" +
        subId +
        "&paymentIntentId=" +
        paymentIntentId +
        "&sellerStripeAcc=" +
        this.props.subSelected.sellerStripeAccId;
      let url = baandaServer + getPaymentFailedReason + param;

      try {
        // console.log("geterr url:", url);
        let resret = await axios.get(url);
        // console.log("resret:", resret);
        if (resret.data.status === "success") {
          this.setState({
            payFailErroMsg: resret.data.Msg,
            subPayErr: true,
            errFlag: false,
            errMsg: "",
          });
        } else {
          this.setState({
            payFailErroMsg: resret.data.Msg,
            errMsg:
              "Error not available in the webhookEventsLog. Request support at info@baanda.com.",
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          payFailErroMsg:
            "Error not available in the webhookEventsLog. Request support at info@baanda.com.",
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg:
          "Cannot request reason for payment failure without a payment intent.",
        errFlag: true,
      });
    }
  };

  sortMessages = async (msgs) => {
    // console.log("msgs:", msgs);
    let sorted = await sortArrayOfDates(msgs, "messageDate", "dsc");
    return sorted;
  };

  setSellerControlState = async () => {
    await this.retrieveSubscriptionState();
    // console.log(
    //   "95 this.state.subscriptionState:",
    //   this.state.subscriptionState
    // );

    let showPauseFlag = false;
    let showCancelFlag = false;
    let showResumeFlag = false;
    let subscriptionActions = this.state.subscriptionActions;
    let errMsg = "";
    let errFlag = false;
    let pauseState = "";
    let subscriptionStatus = this.state.subscriptionStatus;
    if (this.state.subscriptionStatus === "active") {
      showCancelFlag = true;
    }
    if (this.state.subscriptionStatus === "past_due") {
      showCancelFlag = true;
    }
    if (
      this.state.subscriptionState &&
      this.state.subscriptionState.cancel_at_period_end
    ) {
      showPauseFlag = false;
      showCancelFlag = false;
      errMsg =
        "This subscription has been canceled. Subscribe again to reactivate.";
      errFlag = true;
      subscriptionActions = "messages";
      subscriptionStatus = "Canceled";
    }

    if (
      this.state.subscriptionState &&
      this.state.subscriptionState.pause_collection
    ) {
      // console.log("in here 178");
      showPauseFlag = true;
      // if (
      //   !this.state.subscriptionState.pause_collection.resumes_at ||
      //   this.state.subscriptionState.pause_collection.resumes_at === 0
      // ) {
      showResumeFlag = true;
      // }
    }

    if (this.state.subscriptionState.pause_collection) {
      showCancelFlag = true;
      subscriptionActions = "messages";
      pauseState = "paused";
    }

    // await this.setOpsMessage();

    this.setState({
      showPauseFlag,
      showCancelFlag,
      showResumeFlag,
      subscriptionActions,
      errMsg,
      errFlag,
      pauseState,
      subscriptionStatus,
    });

    await this.setOpsMessage();
  };

  hnadleFinStartDate = async (date) => {
    await this.setState({
      pauseResumeDate: date,
    });
    await this.setOpsMessage();
  };

  getLast4OfAttachedCard = async () => {
    let customerId = this.props.subSelected.subscriptionSetupObj.customer.id;
    let sellerStripeAcc = this.props.sellerStripeId;
    let params =
      "customerId=" + customerId + "&sellerStripeAcc=" + sellerStripeAcc;
    let url = baandaServer + getLastFourCardNo + params;
    try {
      let lret = await axios.get(url);
      if (lret.data.status === "success") {
        this.setState({
          stripePaymentData: lret.data.Msg,
          last4: lret.data.Msg.last4,
        });
      } else {
        this.setState({
          errMsg: lret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  retrieveSubscriptionState = async () => {
    let sellerStripeAcc = this.props.sellerStripeId;
    let subscriptionId = this.props.subSelected.subscriptionId;
    let params =
      "subscriptionId=" +
      subscriptionId +
      "&sellerStripeAcc=" +
      sellerStripeAcc;
    try {
      let url = baandaServer + getASubscriptionState + params;
      // console.log("retrieveSubscriptionState url:", url);
      let subret = await axios.get(url);
      // console.log("subret.data:", subret.data);
      if (subret.data.status === "success") {
        await this.setState({
          subscriptionState: subret.data.Msg,
          subscriptionStatus: subret.data.Msg.status,
        });
      } else {
        this.setState({
          errMsg: this.state.errMsg + " | " + subret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  returnToMainPCtrl = () => {
    this.props.returnToCaller();
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleActions = async (e) => {
    let showProcessButtonFlag = false;
    let value = e.target.value;
    // console.log("handleActions value:", value);
    if (value === "messages") showProcessButtonFlag = true;

    await this.setState({
      subscriptionActions: e.target.value,
      showProcessButtonFlag,
      confirmOps: false,
    });

    await this.setOpsMessage();
  };

  handlePauseType = async (e) => {
    // let value = e.target.value;
    await this.setState({
      pauseType: e.target.value,
    });

    await this.setOpsMessage();
  };

  handlePauseDurationType = async (e) => {
    // let value = e.target.value;
    // console.log("handlePauseType value: ", value);

    await this.setState({
      pauseDurationType: e.target.value,
    });

    await this.setOpsMessage();
  };

  setOpsMessage = async () => {
    let opsMessage = "";
    if (this.state.subscriptionActions === "pause") {
      if (this.state.pauseType === "delaypay") {
        opsMessage =
          "This subscription has been paused. The pay amount will be accrued.";
      }
      if (this.state.pauseType === "nopay") {
        opsMessage =
          "This subscription has been paused. The pay amount during the pause time is forgiven.";
      }

      if (this.state.pauseDurationType === "enddate") {
        opsMessage += " The pause will resume at " + this.state.pauseResumeDate;
      }
      if (this.state.pauseDurationType === "noenddate") {
        opsMessage +=
          " The pause will resume when the pause is manually terminated (resumed) by the seller.";
      }
    }
    if (this.state.subscriptionActions === "cancel") {
      opsMessage =
        "This subscription will be canceled at the end of this period. All subsequent services, backed by this subscrption, will stop.";
    }

    this.setState({
      opsMessage,
      sendMessage: opsMessage,
    });
  };

  monthDiff = async (d1, d2) => {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    // console.log("d2.getFullYear():", d2.getFullYear());
    // console.log("d1.getFullYear():", d1.getFullYear());

    // console.log("1 mo:", months);
    // console.log("d1.getMonth():", d1.getMonth());
    // console.log("d2.getMonth():", d2.getMonth());
    months -= d1.getMonth();
    // console.log("2 mo:", months);
    months += d2.getMonth();
    // console.log("3 mo:", months);

    return months <= 0 ? 0 : months;
  };

  validatePauseReq = async () => {
    let isValid = true;

    if (this.state.pauseDurationType === "enddate") {
      let today = new Date();
      let mo = await this.monthDiff(today, this.state.pauseResumeDate);
      // console.log("mo:", mo);
      if (mo < 1) {
        isValid = false;
      }
    }
    return isValid;
  };

  handleCtrlProcess = async () => {
    if (
      this.state.subscriptionActions === "messages" ||
      this.state.subscriptionActions === "declined"
    ) {
      await this.postMessages();
    }

    if (
      this.state.subscriptionActions === "pause" ||
      this.state.subscriptionActions === "cancel"
    ) {
      let isValid = true;
      if (this.state.subscriptionActions === "pause") {
        let val = await this.validatePauseReq();
        // console.log("val:", val);
        if (!val) isValid = false;
      }
      if (isValid) await this.handlePauseNCancel();
      else {
        this.setState({
          errMsg: "Minimum of a month gap is needed for pause-till-date.",
          errFlag: true,
        });
      }
    }

    if (this.state.subscriptionActions === "resume") {
      await this.handleResume();
    }
  };

  postMessages = async () => {
    let msgObj = {
      messageBy: "seller",
      messengerBaandaId: this.props.auth.user.baandaId,
      messengerName: this.props.auth.user.name,
      messageDate: new Date(),
      message: this.state.sendMessage,
    };
    let input = {
      reqFor: this.state.subscriptionActions,
      msgObj,
      communityId: this.props.subSelected.sellerCommunityId,
      subTranId: this.props.subSelected.subTranId,
      // customerRequest: 'replied'
    };
    // console.log("input:", input);
    await this.postInDB(input);
  };

  handlePauseNCancel = async () => {
    // console.log("in handlePauseNCancel ...");
    let customerCommunityId = 0;
    if (this.props.subSelected) {
      if (
        this.props.subSelected.customerCommunityId !== "" ||
        this.props.subSelected.customerCommunityId !== 0
      )
        customerCommunityId = parseFloat(
          this.props.subSelected.customerCommunityId
        );

      if (!this.props.subSelected.customerCommunityId) customerCommunityId = 0;
    }
    let msgObj = {
      messageBy: "seller",
      messengerBaandaId: this.props.auth.user.baandaId,
      messengerName: this.props.auth.user.name,
      messageDate: new Date(),
      message: this.state.sendMessage,
    };

    let input = {
      subscriptionId: this.props.subSelected.subscriptionId,
      pauseType: this.state.pauseType,
      pauseDurationType: this.state.pauseDurationType,
      pauseResumeDateInt: this.state.pauseResumeDate.getTime(),
      sellerStripeId: this.props.sellerStripeId,
      reqFor: this.state.subscriptionActions,
      customerCommunityId,
      invoiceId:
        this.props.subSelected.subscriptionSetupObj.subscription.latest_invoice
          .id,
      subTranId: this.props.subSelected.subTranId,
      msgObj,
      calledByCommunityId: this.props.communityId,
      restrictAccess: this.state.restrictAccess,
    };

    await this.postInDB(input);
  };

  handleResume = async () => {
    let customerCommunityId = 0;
    if (this.props.subSelected) {
      if (
        this.props.subSelected.customerCommunityId !== "" ||
        this.props.subSelected.customerCommunityId !== 0
      )
        customerCommunityId = parseFloat(
          this.props.subSelected.customerCommunityId
        );

      if (!this.props.subSelected.customerCommunityId) customerCommunityId = 0;
    }

    let sendMessage = "";
    if (this.state.sendMessage === "")
      sendMessage = "The paused subscription has been resumed.";
    let msgObj = {
      messageBy: "seller",
      messengerBaandaId: this.props.auth.user.baandaId,
      messengerName: this.props.auth.user.name,
      messageDate: new Date(),
      message: sendMessage,
    };

    let input = {
      subscriptionId: this.props.subSelected.subscriptionId,
      sellerStripeId: this.props.sellerStripeId,
      reqFor: this.state.subscriptionActions,
      customerCommunityId,
      subTranId: this.props.subSelected.subTranId,
      pausedOnDate: this.props.subSelected.pausedOnDate,
      msgObj,
      calledByCommunityId: this.props.communityId,
    };

    await this.postInDB(input);
  };

  postInDB = async (input) => {
    // console.log("in postInDB");
    try {
      let url = baandaServer + handleCommSubDetailProcess;
      // console.log("postDB url:", url, " input:", input);
      let msgret = await axios.post(url, input);
      if (msgret.data.status === "success") {
        // console.log("msgret.data.Msg:", msgret.data.Msg);
        // console.log("msgret.data.Msg.messages:", msgret.data.Msg.Msg);
        let sortedMsgs = await this.sortMessages(msgret.data.Msg.Msg.messages);
        this.setState({
          errMsg: "Successfully updated",
          errFlag: false,
          sortedMsgs,
        });
        await this.setSellerControlState();
      } else {
        this.setState({
          errMsg: msgret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("postMessage err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleConfirm = async () => {
    let showProcessButtonFlag = false;
    if (!this.state.confirmOps) showProcessButtonFlag = true;
    this.setState((prevstate) => ({
      confirmOps: !prevstate.confirmOps,
      showProcessButtonFlag,
    }));
  };

  handleRestrictAccess = async () => {
    // console.log("in handleRestrictAccess");
    this.setState((prevstate) => ({
      restrictAccess: !prevstate.restrictAccess,
    }));
  };

  showAmountDetails = async () => {
    this.setState({
      showAmountDetailsFlag: true,
    });
  };

  returnFromAmountDetails = async () => {
    this.setState({
      showAmountDetailsFlag: false,
    });
  };

  padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  getJSDateFormated = (intdt) => {
    let dt = new Date(intdt);
    return [
      this.padTo2Digits(dt.getDate()),
      this.padTo2Digits(dt.getMonth() + 1),
      dt.getFullYear(),
    ].join("/");
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("PayCtrlDetail...");

    let ss = this.props.subSelected;

    let requestFor = ss.requestFor;

    if (requestFor === "") requestFor = "No request.";
    if (this.state.subscriptionStatus === "Canceled") requestFor = "N/A";

    let showOperations = true;
    if (ss.status === "declined") showOperations = false;
    if (ss.isCanceled) showOperations = false;

    let infoButto = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let exitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToMainPCtrl}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let processButton;
    if (this.state.showProcessButtonFlag) {
      processButton = (
        <button
          className="btn_reg_80"
          type="button"
          onClick={this.handleCtrlProcess}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Process
        </button>
      );
    }

    let buttonAmtMore;
    buttonAmtMore = (
      <button
        className="btn-payctrl-amount-more"
        type="button"
        onClick={this.showAmountDetails}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        . . .
      </button>
    );

    let amountDetailPanel;
    if (this.state.showAmountDetailsFlag) {
      amountDetailPanel = (
        <div className="ctrl-pay-details-amt">
          <div className="text-center cust-pay-ctrl-amout-head">
            Amount Details&nbsp;&nbsp;
            <button
              className="btn_back_main"
              type="button"
              onClick={this.returnFromAmountDetails}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Monthly Fee
            </div>
            <div className="col-8 text-left ctrl-pay-details-text">
              ${" "}
              {this.commaFormattedCurrency(
                this.props.subSelected.inputData.price.monthlyFee
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Registration
            </div>
            <div className="col-8 text-left ctrl-pay-details-text">
              ${" "}
              {this.commaFormattedCurrency(
                this.props.subSelected.inputData.price.registrationFee
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Created on
            </div>
            <div className="col-8 text-left ctrl-pay-details-text">
              {this.props.subSelected.created_at.substring(0, 10)}
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Discount
            </div>
            <div className="col-8 text-left ctrl-pay-details-text">
              {this.props.subSelected.inputData.price.couponName !== ""
                ? this.props.subSelected.inputData.price.couponName
                : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Monthly Disc
            </div>
            <div className="col-8 text-left ctrl-pay-details-text">
              {this.commaFormattedCurrency(
                this.props.subSelected.inputData.price.monthlyCouponPercent
              )}{" "}
              %
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Discount For
            </div>
            <div className="col-8 text-left ctrl-pay-details-text">
              {this.props.subSelected.inputData.price.forMonths} months
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Registration Disc
            </div>
            <div className="col-8 text-left ctrl-pay-details-text">
              {this.commaFormattedCurrency(
                this.props.subSelected.inputData.price.regiCouponPercent
              )}{" "}
              %
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Ref Subscription Id
            </div>
            <div className="col-8 text-left ctrl-pay-details-text">
              {this.state.subscriptionState.id}
            </div>
          </div>
        </div>
      );
    }

    let restrictAccessPanel;

    if (parseFloat(this.props.communityId) === parseFloat(baandaCommunityId)) {
      restrictAccessPanel = (
        <div className="pay-ctrl-service-access">
          <Checkbox
            checked={this.state.restrictAccess}
            onChange={this.handleRestrictAccess}
          />{" "}
          Stop Store Access
        </div>
      );
    }

    let confirmPanel;
    if (ss && this.state.subscriptionActions !== "messages") {
      confirmPanel = (
        <div className="row pay-ctrl-seller-msg-pos">
          <div className="col-8 text-left pay-ctrl-seller-msg-txt">
            {this.state.opsMessage}
          </div>
          {this.state.subscriptionActions === "" ? (
            <div className="col-4 text-left pay-ctrl-seller-msg-txt">
              &nbsp;
            </div>
          ) : (
            <div className="col-4 text-left pay-ctrl-seller-msg-txt">
              <Checkbox
                checked={this.state.confirmOps}
                onChange={this.handleConfirm}
              />{" "}
              Confirm (caution - final)
            </div>
          )}
        </div>
      );
    }

    let actionsPanel;
    actionsPanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Request's Response&nbsp;&nbsp;</strong>
          {!this.state.showPauseFlag ? (
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="pause"
                  checked={this.state.subscriptionActions === "pause"}
                  onChange={this.handleActions}
                />{" "}
                Pause
              </label>
            </div>
          ) : null}

          {this.state.showCancelFlag ? (
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="cancel"
                  checked={this.state.subscriptionActions === "cancel"}
                  onChange={this.handleActions}
                />{" "}
                Cancel
              </label>
            </div>
          ) : null}

          {this.state.showResumeFlag ? (
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="resume"
                  checked={this.state.subscriptionActions === "resume"}
                  onChange={this.handleActions}
                />{" "}
                Resume Pause
              </label>
            </div>
          ) : null}

          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="messages"
                checked={this.state.subscriptionActions === "messages"}
                onChange={this.handleActions}
              />{" "}
              Messages
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="declined"
                checked={this.state.subscriptionActions === "declined"}
                onChange={this.handleActions}
              />{" "}
              Decline
            </label>
          </div>
          <div className="form-check form-check-inline text-right  stop-access-pos">
            {this.state.subscriptionActions === "cancel" ||
            this.state.subscriptionActions === "pause"
              ? restrictAccessPanel
              : null}
          </div>
          {/* { this.state.subscriptionActions === "cancel" || this.state.subscriptionActions === "pause"? <div className="text-right"> {restrictAccessPanel}</div> : null} */}
        </div>
      </div>
    );

    let detailTopPanel;
    if (ss) {
      let pauseStatus = "";
      // let cancelStatus = '';
      if (
        this.state.subscriptionState &&
        !this.state.subscriptionState.cancel_at_period_end
      ) {
        if (this.state.subscriptionState.pause_collection) {
          if (
            this.state.subscriptionState.pause_collection.behavior ===
            "keep_as_draft"
          )
            pauseStatus = "Paused; Amount to accru; ";
          if (
            this.state.subscriptionState.pause_collection.behavior ===
            "mark_uncollectible"
          )
            pauseStatus = "Paused; Amount is forgiven; ";

          if (
            this.state.subscriptionState.pause_collection.resumes_at &&
            this.state.subscriptionState.pause_collection.resumes_at !== 0
          )
            pauseStatus +=
              "Resumes on " +
              this.getJSDateFormated(
                this.state.subscriptionState.pause_collection.resumes_at
              );
          if (
            !this.state.subscriptionState.pause_collection.resumes_at ||
            this.state.subscriptionState.pause_collection.resumes_at === 0
          )
            pauseStatus += " To be resumed manually.";
        }
      }

      detailTopPanel = (
        <div>
          <div className="row">
            <div className="col-6 text-left pay-ctrl-top-head">
              Customer (type: {ss.customerType})
            </div>
            <div className="col-4 text-left pay-ctrl-top-head">
              Refrence Invoice Id {ss.refInvoiceId}
            </div>
          </div>

          <div className="row">
            <div className="col-3 text-right pay-ctrl-detail-lbl">
              Community
            </div>
            <div className="col-9 text-left pay-ctrl-detail-txt">
              {ss.customerCommunityName} (id: {ss.customerCommunityId})
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right pay-ctrl-detail-lbl">Name</div>
            <div className="col-9 text-left pay-ctrl-detail-txt">
              {ss.customerName} ({ss.customerEmail})
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right pay-ctrl-detail-lbl">Item</div>
            <div className="col-9 text-left pay-ctrl-detail-txt">
              {ss.itemName}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right pay-ctrl-detail-lbl-amt">
              Amount
            </div>
            <div className="col-9 text-left pay-ctrl-detail-txt">
              $ {this.commaFormattedCurrency(ss.inputData.price.monthlyFee)} /
              month&nbsp;{buttonAmtMore}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right pay-ctrl-detail-lbl">
              Card (last4)
            </div>
            <div className="col-9 text-left pay-ctrl-detail-txt">
              ---- ---- ---- {this.state.last4}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right pay-ctrl-detail-lbl">
              Request For
            </div>
            <div className="col-3 text-left pay-ctrl-detail-txt">
              <font color="#a3561a">{requestFor.toUpperCase()}</font>
            </div>
            <div className="col-3 text-right pay-ctrl-detail-lbl">Response</div>
            <div className="col-3 text-left pay-ctrl-detail-txt">
              {ss.isPaused && !ss.isCanceled ? "Paused" : null}
              {ss.isCanceled ? "" : null}
              {!ss.isCanceled && ss.status === "declined" ? (
                <font color="red">&nbsp;Declined</font>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right pay-ctrl-detail-lbl">
              Subscription Status
            </div>
            <div className="col-9 text-left pay-ctrl-detail-txt">
              {this.state.subscriptionStatus.toUpperCase()}; {pauseStatus}
            </div>
          </div>
        </div>
      );
    }

    let sellerPanel;
    if (ss) {
      sellerPanel = (
        <div>
          <div className="row">
            <div className="col-3 text-right pay-ctrl-detail-lbl">
              Seller Community
            </div>
            <div className="col-9 text-left pay-ctrl-detail-txt">
              {ss.sellerCommunityName} (id: {ss.sellerCommunityId})
            </div>
          </div>
        </div>
      );
    }

    let sendMessagePanel;
    let placemsg;
    if (ss) {
      placemsg = "Send a message to " + ss.customerName + " in 250 characters";
      sendMessagePanel = (
        <div className="">
          <div className="row">
            <div className="col-8 text-left cust-pay-ctrl-det-msg">
              Write Message:
            </div>
            <div className="col-4 text-center cust-pay-ctrl-det-msg-chars">
              Chars Used: {this.state.sendMessage.length}
            </div>
          </div>
          <div className="row">
            <div className="col text-left">
              <textarea
                name="sendMessage"
                maxLength="250"
                placeholder={placemsg}
                rows="3"
                wrap="hard"
                spellCheck="true"
                className="payctrl-msg_textarea"
                onChange={this.onChange}
                value={this.state.sendMessage}
                required
              />
            </div>
          </div>
        </div>
      );
    }

    let messagePanel;
    let bool = true;
    if (ss) {
      if (
        this.state.subscriptionActions === "messages" ||
        ss.status === "declined"
      ) {
        if (this.state.sortedMsgs.length > 0) {
          messagePanel = (
            <div>
              <div className="row sub-item-list-row">
                <div className="col-2 text-center pay-ctrl-comm-list-head">
                  Originator
                </div>
                <div className="col-7 text-left pay-ctrl-comm-list-head">
                  Messenger's Name
                </div>
                <div className="col-3 text-left pay-ctrl-comm-list-head">
                  Dated
                </div>
              </div>
              <div className="fixedsize-comm-payctrl-msg-box">
                {this.state.sortedMsgs.map((obj, i) => {
                  return (
                    <div key={i}>
                      <div
                        className={
                          bool ? "row-col-schdule-dr" : "row-col-schdule-lt"
                        }
                      >
                        <div className="row">
                          <div className="col-2 text-center pay-ctrl-msg-lbl">
                            {obj.messageBy.toUpperCase()}
                          </div>
                          <div className="col-7 text-left pay-ctrl-msg-lbl">
                            {obj.messengerName}
                          </div>
                          <div className="col-3 text-left pay-ctrl-msg-lbl">
                            {obj.messageDate.substring(0, 10)}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col text-left pay-ctrl-msg-txt">
                            {obj.message}
                          </div>
                        </div>
                      </div>
                      {(bool = !bool)}
                    </div>
                  );
                })}
              </div>
              {/* <div
                className={
                  this.state.errFlag
                    ? "text-center sub-item-msg-err"
                    : "text-center sub-item-msg"
                }
              >
                {this.state.errMsg}
              </div> */}
            </div>
          );
        } else {
          messagePanel = (
            <div>
              <div className="text-center pay-ctrl-detail-no-msg">
                No prior messages exists as of now.
              </div>
              {/* {sendMessagePanel} */}
            </div>
          );
        }
      }
    }

    let pauseBasePanel;
    let pauseTillTypePanel;
    if (ss) {
      if (this.state.subscriptionActions === "pause") {
        pauseBasePanel = (
          <div className="row name-panel-row">
            <div className="col text-center radio-font-style">
              <strong>Pause Type&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="delaypay"
                    checked={this.state.pauseType === "delaypay"}
                    onChange={this.handlePauseType}
                  />{" "}
                  Delayed payment
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="nopay"
                    checked={this.state.pauseType === "nopay"}
                    onChange={this.handlePauseType}
                  />{" "}
                  Payment forgiven
                </label>
              </div>
            </div>
            {/* {restrictAccessPanel} */}
          </div>
        );

        pauseTillTypePanel = (
          <div className="row name-panel-row">
            <div className="col text-center radio-font-style">
              <strong>Duration Type&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="enddate"
                    checked={this.state.pauseDurationType === "enddate"}
                    onChange={this.handlePauseDurationType}
                  />{" "}
                  Pause till Date
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="noenddate"
                    checked={this.state.pauseDurationType === "noenddate"}
                    onChange={this.handlePauseDurationType}
                  />{" "}
                  Pause till resumed
                </label>
              </div>
            </div>
          </div>
        );
      }
    }

    let dateSelectPanel;
    if (ss) {
      if (
        this.state.subscriptionActions === "pause" &&
        this.state.pauseDurationType === "enddate"
      ) {
        dateSelectPanel = (
          <div className="row">
            <div className="col-6 text-right person_label">Till Date:</div>
            <div className="col-6 text-left dob_sizing">
              {" "}
              <DatePicker
                selected={this.state.pauseResumeDate}
                onChange={this.hnadleFinStartDate}
                onSelect={this.hnadleFinStartDate}
                name="pauseResumeDate"
                minDate={new Date()}
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>
        );
      }
    }

    let cancelActiondPanel;
    if (ss) {
      if (ss.isCanceled) {
        cancelActiondPanel = (
          <div className="text-center payctrl-seller-cancel">&nbsp;</div>
        );
      } else if (ss.status === "declined") {
        cancelActiondPanel = (
          <div className="text-center payctrl-seller-declined">
            Request for {ss.requestFor} has been declined.
          </div>
        );
      } else {
        if (
          this.state.subscriptionState &&
          this.state.subscriptionState.cancel_at_period_end
        ) {
          cancelActiondPanel = <div>&nbsp;</div>;
        } else cancelActiondPanel = <div>{actionsPanel}</div>;
        // cancelActiondPanel = <div>{actionsPanel}</div>;
      }
    }

    let lastPayErrorPanel;
    if (
      this.props.subSelected.lastSubscriptionState === "payment_failed" &&
      this.state.subPayErr
    ) {
      lastPayErrorPanel = (
        <div className="row">
          <div className="col-4 text-right pay-error-lbl">
            Last Subscription Pay Failed
          </div>
          <div className="col-8 text-left pay-error-lbl">
            Reason: {this.state.payFailErroMsg}
          </div>
        </div>
      );
    }

    let outputPanel;

    if (ss) {
      outputPanel = (
        <div className="payment-ctrl-detail-box">
          <div className="pay-ctrl-detail-head">
            Seller's Pay Control {exitButton} {processButton} {infoButto}
          </div>
          {detailTopPanel}
          {amountDetailPanel}
          {sellerPanel}
          {cancelActiondPanel}
          {/* { this.state.subscriptionActions === "cancel" || this.state.subscriptionActions === "pause"? <div className="text-right"> {restrictAccessPanel}</div> : null} */}
          {messagePanel}
          {showOperations ? pauseBasePanel : null}
          {showOperations ? pauseTillTypePanel : null}
          {showOperations ? dateSelectPanel : null}
          {showOperations ? sendMessagePanel : null}
          {showOperations ? confirmPanel : null}
          {lastPayErrorPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center pay-ctrl-detail-msg-err"
                : "text-center pay-ctrl-detail-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

payCtrlDetail.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(payCtrlDetail)
);
