import React, { Component } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";

import "./InvoiceCommunity.css";

import InvoiceDetails from "./InvoiceDetails";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getInvoices = "/routes/invoice/getInvoices?";

class InvoiceCommunity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearchPanel: true,
      showInvoiceList: false,
      showInvoiceDetail: false,

      dateFirst: new Date(),
      dateSecond: new Date().setDate(new Date().getDate() + 1),

      filterType: "all",
      customerName: "",
      customerEmail: "",
      invoiceId: 0,

      invoiceIdForDetail: 0,
 
      errMsg: "",
      errFlag: false,
      invoiceList: []
    };
  }

  exitReport = () => {
    this.props.returnToCaller();
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  getInvoices = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&beginDate=" +
      this.state.dateFirst +
      "&endDate=" +
      this.state.dateSecond +
      "&filterType=" +
      this.state.filterType + "&customerName=" + this.state.customerName + "&customerEmail=" + this.state.customerEmail + "&invoiceId=" + this.state.invoiceId;
    let url = baandaServer + getInvoices + params;
    // console.log("url:", url);
    try {
      let inv = await axios.get(url);
      if (inv.data.status === "success") {
        this.setState({
          invoiceList: inv.data.Msg,
        });
      } else {
        this.setState({
          errMsg: inv.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getInvoices err:", err.messsage);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleFetchInvoices = async () => {
    // console.log("In handleFetchInvoices ...");
    await this.getInvoices();
    this.setState({
      showInvoiceDetail: false,
      showInvoiceList: true,
      showSearchPanel: false,
    });
  };

  handleDateFirst = async (date) => {
    let result = new Date(date);
    result.setDate(result.getDate() + 1);
    this.setState({
      dateFirst: date,
      dateSecond: result,
    });
  };

  handleDateSecond = async (date) => {
    this.setState({
      dateSecond: date,
    });
  };

  handleInvoiceFilterType = async (e) => {
    this.setState({
      filterType: e.target.value,
    });
  };

  handleSearchInvoices = async () => {
    this.setState({
      showInvoiceDetail: false,
      showInvoiceList: false,
      showSearchPanel: true,
      invoiceList: [],
    });
  };

  selectThisInvoiceToShow = async (obj) => {
    // console.log("obj:", obj);
    this.setState({
      invoiceIdForDetail: obj.invoiceId,
      showInvoiceDetail: true,
      showInvoiceList: false,
      showSearchPanel: false,
    });
  };

  returnFromInvDetails = async () => {
    this.setState({
      invoiceIdForDetail: 0,
      showInvoiceDetail: false,
      showInvoiceList: true,
      showSearchPanel: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('InvoiceCommunity...');

    let exitButton = (
      <button
        onClick={this.exitReport}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );
 
    let fetchButton = (
      <button
        onClick={this.handleFetchInvoices}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_60"
      >
        Fetch
      </button>
    );
// <i className="fas fa-search-plus" />

    let searchButton = (
      <button
        onClick={this.handleSearchInvoices}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_70"
      >
        {this.state.deviceSize === "big" ? "Search" : <i className="fas fa-search-plus" />} 
      </button>
    );

    let invoiceTypePanel;
    invoiceTypePanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Filter by&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="all"
                checked={this.state.filterType === "all"}
                onChange={this.handleInvoiceFilterType}
              />{" "}
              All
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="name"
                checked={this.state.filterType === "name"}
                onChange={this.handleInvoiceFilterType}
              />{" "}
              By Name
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="email"
                checked={this.state.filterType === "email"}
                onChange={this.handleInvoiceFilterType}
              />{" "}
              By email
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="invnumber"
                checked={this.state.filterType === "invnumber"}
                onChange={this.handleInvoiceFilterType}
              />{" "}
              By Invoice #
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="pending"
                checked={this.state.filterType === "pending"}
                onChange={this.handleInvoiceFilterType}
              />{" "}
              Pending
            </label>
          </div>
        </div>
      </div>
    );

    let nameSearchPanel;
    if (this.state.filterType === "name") {
      nameSearchPanel = (
        <div>
          <div className="row inv-name-search-row">
            <div className="col-4 text-right invoice-report-lbl">
              Customer Name
            </div>
            <div className="col-8 text-left">
              <input
                name="customerName"
                type="text"
                value={this.state.customerName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="customer-name-text"
                placeholder="Enter part of full name"
              />
            </div>
          </div>
        </div>
      );
    }

    let emailSearchPanel;
    if (this.state.filterType === "email") {
      emailSearchPanel = (
        <div>
          <div className="row inv-name-search-row">
            <div className="col-4 text-right invoice-report-lbl">
              Customer Email
            </div>
            <div className="col-8 text-left">
              <input
                name="customerEmail"
                type="text"
                value={this.state.customerEmail}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="customer-name-text"
                placeholder="Enter email"
              />
            </div>
          </div>
        </div>
      );
    }

    let invoiceIdSearchPanel;
    if (this.state.filterType === "invnumber") {
      invoiceIdSearchPanel = (
        <div>
          <div className="row inv-name-search-row">
            <div className="col-4 text-right invoice-report-lbl">
              Invoice Id
            </div>
            <div className="col-8 text-left">
              <input
                name="invoiceId"
                type="number"
                value={this.state.invoiceId}
                onChange={this.onChange}
                size="10"
                maxLength="10"
                className="customer-invoice-id"
                placeholder="Enter invoiceId"
              />
            </div>
          </div>
        </div>
      );
    }

    let dateSelectionPanel;
    dateSelectionPanel = (
      <div className="filter-date-row">
        <div className="row">
          <div className="col-2 text-right invoice-report-lbl">From</div>
          <div className="col-4 text-left">
            <DatePicker
              selected={this.state.dateFirst}
              onChange={this.handleDateFirst}
              onSelect={this.handleDateFirst}
              name="prdob"
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="col-2 text-right invoice-report-lbl">To</div>
          <div className="col-4 text-left">
            <DatePicker
              selected={this.state.dateSecond}
              onChange={this.handleDateSecond}
              onSelect={this.handleDateSecond}
              name="prdob"
              dateFormat="MM/dd/yyyy"
              minDate={this.state.dateSecond}
            />
          </div>
        </div>
      </div>
    );

    let showInvoiceList;
    let bool = true;
    if (this.state.showInvoiceList) {
      if (this.state.invoiceList.length > 0) {
        showInvoiceList = (
          <div>
            <div className="row sub-item-list-row">
              <div className="col-1 text-center sub-item-list-head">Ops</div>
              <div className="col-9 text-left sub-item-list-head">
                Customer Name | Email | Invoice Date | invoiceId | Amount
              </div>
              <div className="col-2 text-left sub-item-list-head">
                Count: {this.state.invoiceList.length}
              </div>
            </div>
            <div className="fixedsize-invoice-list-rep">
              {this.state.invoiceList.map((obj, i) => {
                // console.log("690 obj:", obj);

                return (
                  <div key={i}>
                    <div
                      className={
                        bool
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-1 text-center">
                        <button
                          className="btn-invoice-detail-open"
                          type="button"
                          onClick={() => this.selectThisInvoiceToShow(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          ...
                        </button>
                      </div>

                      <div className="col-11 text-left invoice-list-lines">
                        {obj.customerName} | {obj.customerEmail} |{" "}
                        {obj.invoiceDate.substring(0, 10)} | {obj.invoiceId} | ${" "}
                        {this.commaFormattedCurrency(
                          obj.financeBreakdown.totalInvoiceAmount
                        )}
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
            <div
              className={
                this.state.errFlag
                  ? "text-center sub-item-msg-err"
                  : "text-center sub-item-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        );
      } else {
        showInvoiceList = (
          <div className="fixedsize-invoice-list-rep">
               No invoice found with your search condition.
          </div>
        )
      }
    }

    let searchPanel;
    if (this.state.showSearchPanel) {
      searchPanel = (
        <div className="invoice-search-box">
          <div className="text-center invoice-report-header">
            Search Invoice&nbsp;{fetchButton}
          </div>
          {invoiceTypePanel}
          <div className="date-row">{dateSelectionPanel}</div>
          {nameSearchPanel}
          {emailSearchPanel}
          {invoiceIdSearchPanel}
        </div>
      );
    }

    let outputPanel;
    if (this.state.showInvoiceDetail) {
      outputPanel = (
        <div>
          <InvoiceDetails
            returnToCaller={this.returnFromInvDetails}
            communityId={this.props.communityId}
            invoiceId={this.state.invoiceIdForDetail}
          />
        </div>
      );
    } else {
      outputPanel = (
        <div>
          <div className="text-center invoice-report-header">
            Invoice Reports {exitButton}{" "}
            {this.state.showSearchPanel ? null : searchButton}
          </div>
          {searchPanel}
          {showInvoiceList}
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

export default InvoiceCommunity;
