import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import axios from "axios";

import "./Production.css";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

class Production extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      showLandingMsg: true,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setState({
      deviceSize,
    });
  };

  openAlertModal = () => {
    let msg = {
      Header: "Digital Content Production",
      Body: {
        oneLineSummary: `This has all the information about the activities of the store..`,
        steps: [
          {
            step: "POS (Point 0f Sales)",
            stepNote:
              "At POS, you will be able to sell your items to members of your community. Even if your community is public, one has to join without invite first to buy goods or services.",
          },
          {
            step: "Inventory",
            stepNote:
              "This will let you enter, update, inventory of items and prices of your catalog of good and services.",
          },
          {
            step: "PO (Purchase Order)",
            stepNote:
              "This will enable you to purchase items, raise purchase orders (if needed), to fill your inventory.",
          },
          {
            step: "Finance",
            stepNote:
              "This is your accounts payable and receivable. By default, it is set to receivable. For cash payments, your (creators) acceptance is accepted as received.",
          },
        ],
        footnote:
          "This is your own market space (your business). Everything you need to do in your store is available here. If/when you want to request new functions, please head to your MyAccount and send Request Improvement to Baanda team.",
      },
      Footer: "This is the footer",
    };
    this.props.showModal(
      {
        open: true,
        title: "Alert - Start Here Header",
        message: msg,
        closeModal: this.closeModal,
      },
      "infoModal"
    );
  };

  returnToInventoryAdj = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('Production...');

    let returnbutton = (
      <button
        className="btn-inv-prod-exit"
        type="button"
        onClick={this.returnToInventoryAdj}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        X
      </button>
    );

    let openModalButton = (
      <button
        className="btn-modal-edu-production"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let landingMsg;
    if (this.state.showLandingMsg) {
      landingMsg = (
        <div className="digital-service-row-pos">
          <p align="justify" className="digital-srv-msg">
            The 'Digital Service' is suitable for various kinds of educational and training services. Please refer the information (i) for detail directive. 
          </p>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div className="inv-production-box">
        <div className="inv-prod-header">
          Education Carriculum Production&nbsp;{returnbutton}&nbsp;{openModalButton}
        </div>
        {landingMsg}
      </div>
    );
    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

Production.propTypes = {
    auth: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  const mapDispatchToProps = (dispatch) => ({
    hideModal: () => dispatch(hideModal()),
    showModal: (modalProps, modalType) => {
      dispatch(showModal({ modalProps, modalType }));
    },
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(Production));
 
