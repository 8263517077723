import React, { Component } from "react";

import axios from "axios";
import FormatCompEntry from "./FormatCompEntry";

import { sortArrayOfObjects } from "../../../../../../../../utils/sortArrayOfObjects";

import UploadCompositionDA from "../../daloads/UploadCompositionDA";

import { arraySortNum } from "../../../../../../../../utils/sortArrayOfObjectsNum";

import "./FrameCompose.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveBroadcastWip = "/routes/interact/saveBroadcastWip";
const getABroadcast = "/routes/interact/getABroadcast?";

class FrameCompose extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currSeqNo: 1,
      toEditSeqNo: 0,
      title: "",
      writeup: "",
      deletedArrObj: null,

      defaultTitleFormat: {
        fontFamily: "Arial, sans-serif",
        fontSize: "12pt",
        color: "black",
        bgColor: "#ffffff", // white
        isBold: false,
        align: "left",
      },

      titleFormat: {
        fontFamily: "Arial, sans-serif",
        fontSize: "12pt",
        color: "black",
        bgColor: "#ffffff", // white
        isBold: false,
        align: "left",
      },

      defaultWriteupFormat: {
        fontFamily: "Arial, sans-serif",
        fontSize: "11pt",
        color: "black",
        bgColor: "#ffffff", // white
        isBold: false,
        align: "left",
      },

      writeupFormat: {
        fontFamily: "Arial, sans-serif",
        fontSize: "11pt",
        color: "black",
        bgColor: "#ffffff", // white
        isBold: false,
        align: "left",
      },

      openDAUploadFlag: false,
      showFormatFlag: false,
      editorialFlag: true,
      editFlag: false,
      deleteFlag: false,

      errFlag: false,
      errMsg: "",

      theBroadcast: null,
      daType: "image",
      compArray: [],
    };
  }

  componentDidMount = () => {
    let compArray = [...this.props.theBroadcast.mailComposition.fileUploads];

    let sorted = arraySortNum(compArray, "seqNo", "dsc");
    console.log("sorted:", sorted);
    let currSeqNo = sorted.length + 1;
    this.setState({
      currSeqNo,
      compArray: sorted,
      theBroadcast: this.props.theBroadcast,
    });
  };

  onChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeSeqno = async (e) => {
    let value = e.target.value;

    if (this.countDecimals(value) > 1) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        itemPrice: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  // handleMerge = async () => {
  //   if (this.state.writeup !== "" || this.state.title !== "") {
  //     let coarr = [];
  //     console.log('this.state.toEditSeqNo:', this.state.toEditSeqNo);
  //     this.state.compArray.forEach(car => {
  //       if ( car.seqNo !== this.state.toEditSeqNo) coarr.push(car);
  //     })

  //     let coObj = {
  //       title: this.state.title,
  //       titleFormat: this.state.titleFormat,
  //       writeup: this.state.writeup,
  //       writeupFormat: this.state.writeupFormat,
  //       seqNo: this.state.currSeqNo,
  //     };
  //     coarr.push(coObj);

  //     // console.log("1 coarr:", coarr);
  //     let tmpArr = await sortArrayOfObjects(coarr, "seqNo", "dsc");
  //     // console.log("2 tmpArr:", tmpArr);

  //     let seq = 1;
  //     let resequenced = [];
  //     tmpArr.forEach((trr) => {
  //       trr.seqNo = seq;
  //       resequenced.push(trr);
  //       seq++;
  //     });

  //     this.setState({
  //       compArray: resequenced,
  //       currSeqNo: resequenced.length+1,
  //       titleFormat: this.state.defaultTitleFormat,
  //       writeupFormat: this.state.defaultWriteupFormat,
  //       editFlag: false,
  //       title: "",
  //       writeup: "",
  //       errMsg: "",
  //       errFlag: false,
  //       toEditSeqNo: 0
  //     });
  //   } else {
  //     this.setState({
  //       errMsg: "Both paragraph and title cannot be blank.",
  //       errFlag: true,
  //     });
  //   }
  // };

  handleDeleteThis = (obj) => {
    console.log("handleDeleteThis obj:", obj);
    let darr = [];
    let delObj = null;
    this.state.compArray.forEach((arr) => {
      if (arr.seqNo !== obj.seqNo) darr.push(arr);
      else delObj = arr;
    });
    console.log("delObj:", delObj);
    if (obj.location !== "") {
      this.setState({
        toEditSeqNo: obj.seqNo,
        currSeqNo: obj.seqNo,
        errMsg:
          "Delete the image prior to deleting the " +
          obj.seqNo +
          " frame. Click Image to delete.",
        errFlag: true,
        title: obj.sliderBody.title,
        writeup: obj.sliderBody.writeup,
        deleteFlag: true,
      });
    } else {
      this.setState({
        toEditSeqNo: obj.seqNo,
        currSeqNo: obj.seqNo,
        errMsg: "Will delete in future ...",
        errFlag: true,
        title: obj.sliderBody.title,
        writeup: obj.sliderBody.writeup,
        deleteFlag: true,
      });
      // let seq = 1;
      // let resequenced = [];
      // darr.forEach((trr) => {
      //   trr.seqNo = seq;
      //   resequenced.push(trr);
      //   seq++;
      // });

      // this.setState({
      //   compArray: resequenced,
      //   currSeqNo: resequenced.length + 1,
      //   title: "",
      //   writeup: "",
      //   toEditSeqNo: obj.seqNo,
      //   deletedArrObj: delObj,
      //   deleteFlag: true,
      // });
    }
  };

  handleEditThis = (obj) => {
    console.log("handleEditThis obj:", obj);
    let title, writeup, toEditSeqNo, currSeqNo;
    this.state.compArray.forEach((arr) => {
      if (arr.seqNo === obj.seqNo) {
        title = arr.sliderBody.title;
        writeup = arr.sliderBody.writeup;
        toEditSeqNo = arr.seqNo;
        currSeqNo = arr.seqNo;
      }
    });

    this.setState({
      title,
      writeup,
      toEditSeqNo,
      currSeqNo,
      editFlag: true,
      deleteFlag: false,
      errMsg: '',
      errFlag: false
    });
  };

  handleTitleFormat = (type) => {
    console.log("type:", type);
    let format;
    let btnType;
    if (type === "title") {
      format = this.state.titleFormat;
      btnType = "Intro";
    }
    if (type === "body") {
      format = this.state.writeupFormat;
      btnType = "Body";
    }

    this.setState({
      showFormatFlag: true,
      callingField: type,
      format,
      btnType,
    });
  };

  returnFromFormat = (newFormat) => {
    console.log(">> newFormat:", newFormat);
    let titleFormat = this.state.titleFormat;
    let writeupFormat = this.state.writeupFormat;
    console.log(">> this.state.btnType:", this.state.btnType);

    let cArr = [];
    this.state.compArray.forEach((crr) => {
      if (crr.seqNo !== this.state.toEditSeqNo) {
        cArr.push(crr);
      } else {
        if (this.state.btnType === "Intro") {
          crr.titleFormat = newFormat;
        }
        if (this.state.btnType === "Body") {
          crr.writeupFormat = newFormat;
        }
        cArr.push(crr);
      }
    });

    this.setState({
      showFormatFlag: false,
      writeupFormat,
      titleFormat,
      compArray: cArr,
    });
  };

  handleMerge = async () => {
    if (this.state.writeup !== "" || this.state.title !== "") {
      let coarr = [];
      console.log("this.state.toEditSeqNo:", this.state.toEditSeqNo);
      this.state.compArray.forEach((car) => {
        if (car.seqNo !== this.state.toEditSeqNo) coarr.push(car);
      });

      let coObj = {
        title: this.state.title,
        titleFormat: this.state.titleFormat,
        writeup: this.state.writeup,
        writeupFormat: this.state.writeupFormat,
        seqNo: this.state.currSeqNo,
      };
      coarr.push(coObj);

      // console.log("1 coarr:", coarr);
      let tmpArr = await sortArrayOfObjects(coarr, "seqNo", "dsc");
      // console.log("2 tmpArr:", tmpArr);

      let seq = 1;
      let resequenced = [];
      tmpArr.forEach((trr) => {
        trr.seqNo = seq;
        resequenced.push(trr);
        seq++;
      });

      this.setState({
        compArray: resequenced,
        currSeqNo: resequenced.length + 1,
        titleFormat: this.state.defaultTitleFormat,
        writeupFormat: this.state.defaultWriteupFormat,
        editFlag: false,
        title: "",
        writeup: "",
        errMsg: "",
        errFlag: false,
        toEditSeqNo: 0,
      });
    } else {
      this.setState({
        errMsg: "Both paragraph and title cannot be blank.",
        errFlag: true,
      });
    }
  };

  packageInputForDB = () => {
    let input = null;
    console.log("this.state.toEditSeqNo:", this.state.toEditSeqNo);
    if (parseFloat(this.state.toEditSeqNo) === 0) {
      if (this.state.title === "" && this.state.writeup === "") {
        return { status: "error", Msg: "Title and writeup cannot be blank." };
      } else {
        let sliderBody = {
          title: this.state.title,
          titleFormat: this.state.titleFormat,
          writeup: this.state.writeup,
          writeupFormat: this.state.writeupFormat,
        };
        let seqNo = this.state.currSeqNo;
        input = {
          sliderBody,
          seqNo,
          communityId: this.props.communityId,
          broadcastId: this.props.broadcastId,
          templateType: this.props.selectedTemplate,
          compositionComponent: "frames",
          actionType: "new",
          updateType: "body",
          clientProgram: "FrameCompose",
          clientFunction: "handleFrameWriteupSave",
        };
        return { status: "success", Msg: input };
      }
    } else {
      // Merge then create input ...
      let actionType = "";
      if (this.state.editFlag) actionType = "edit";
      if (this.state.deleteFlag) actionType = "delete";
      input = {
        fileUploads: this.state.compArray,
        deletedArrObj: this.state.deletedArrObj,
        communityId: this.props.communityId,
        broadcastId: this.props.broadcastId,
        templateType: this.props.selectedTemplate,
        compositionComponent: "frames",
        actionType,
        updateType: "body",
        clientProgram: "FrameCompose",
        clientFunction: "handleFrameWriteupSave",
      };
      return { status: "success", Msg: input };
    }
  };

  handleFrameWriteupSave = async () => {
    console.log("inside uploadFileInDB");
    let pack = this.packageInputForDB();
    if (pack.status === "success") {
      let input = pack.Msg;
      let url = baandaServer + saveBroadcastWip;
      console.log("### url:", url, " input:", input);
      // try {
      //   let resp = await axios.post(url, input);
      //   console.log("Upload resp:", resp);
      //   if (resp.data.status === "success") {
      //     console.log("In here ... 999");
      //     await this.setCompArray();
      //     this.setState({
      //       errMsg: "Successfully updated.",
      //       errFlag: false,
      //     });
      //   } else {
      //     this.setState({
      //       errMsg: resp.data.Msg,
      //       errFlag: true,
      //     });
      //   }
      // } catch (err) {
      //   console.log("uploadFileInDB:", err.message);
      //   this.setState({
      //     errMsg: err.message,
      //     errFlag: true,
      //   });
      // }
    } else {
      this.setState({
        errFlag: true,
        errMsg: pack.Msg,
      });
    }
  };

  setCompArray = async () => {
    await this.getTheBroadcast();
    let cArr = [...this.state.theBroadcast.mailComposition.fileUploads];
    if (this.state.editFlag || this.state.deleteFlag) {
      this.handleNewFrame();
    }

    let sorted = arraySortNum(cArr, "seqNo", "dsc");
    this.setState({
      compArray: sorted,
      toEditSeqNo: 0,
      editFlag: false,
      deleteFlag: false,
    });
  };

  getTheBroadcast = async () => {
    console.log("in getTheBroadcast");
    let params = "broadcastId=" + this.props.theBroadcast.broadcastId;

    let url = baandaServer + getABroadcast + params;
    console.log("getTheBroadcast url:", url);
    try {
      let getbrd = await axios.get(url);
      console.log("getbrd:", getbrd);
      if (getbrd.data.status === "success") {
        this.setState({
          theBroadcast: getbrd.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleImage = () => {
    this.setState({
      openDAUploadFlag: true,
      editorialFlag: false,
    });
  };

  returnFromUploadCompositionDA = async () => {
    console.log("returnFromUploadCompositionDA ...");
    await this.getTheBroadcast();

    this.setState({
      compositionComponent: this.state.compositionComponent,
      daType: this.state.daType,
      openDAUploadFlag: false,
      openBodyFlag: false,
      reviewBtnFlag: false,
      openReviewFlag: false,
      editorialFlag: true,
    });
  };

  handleNewFrame = () => {
    let newSeqNo = this.state.compArray.length + 1;
    this.setState({
      currSeqNo: newSeqNo,
      title: "",
      writeup: "",
      errMsg: "",
      errFlag: false,
      toEditSeqNo: 0,
      deleteFlag: false,
      editFlag: false,
    });
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    let mergeButton;
    if (!this.state.deleteFlag && ( this.state.title !== '' || this.state.writeup !== '')) {
      mergeButton = (
        <button
          className="btn_reg_80"
          type="button"
          onClick={this.handleFrameWriteupSave}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Merge <i className="fas fa-object-ungroup" />
        </button>
      );
    }

    let imageButton = (
      <button
        className="btn_reg_80"
        type="button"
        onClick={this.handleImage}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Image <i className="fas fa-image" />
      </button>
    );

    let newButton;
    if (
      this.state.compArray.length > 0 &&
      this.state.currSeqNo <= this.state.compArray.length
    ) {
      newButton = (
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.handleNewFrame}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          New
        </button>
      );
    }

    let formatBtn1 = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={() => this.handleTitleFormat("title")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-paint-roller" />
      </button>
    );

    let formatBtn2 = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={() => this.handleTitleFormat("body")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-paint-roller" />
      </button>
    );

    let editorialPanel;
    editorialPanel = (
      <div className="comp-body-edit-box">
        <div className="row com-body-entry-row">
          <div className="col-2 text-right basic-body-lbl">Para Title</div>
          <div className="col-9 text-left">
            <input
              name="title"
              type="text"
              value={this.state.title}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="com-basic-title"
              placeholder=""
            />{" "}
          </div>
          <div className="col-1 text-left">{formatBtn1}</div>
        </div>
        <div className="row com-body-entry-row">
          <div className="col-2 text-right basic-body-lbl">Paragraph</div>
          <div className="col-9 text-left">
            <textarea
              name="writeup"
              maxLength="2000"
              placeholder="This para writeup in 2000 chars or less."
              rows="5"
              wrap="hard"
              spellCheck="true"
              className="basic_body_textarea"
              onChange={this.onChange}
              value={this.state.writeup}
              required
            />
          </div>
          <div className="col-1 text-left format-body-btn-pos">
            {formatBtn2}
          </div>
        </div>

        <div className="row basic-edit-ctrl-pos">
          <div className="col-2 text-right basic-body-lbl">seqNo</div>
          {this.state.editFlag && this.state.compArray.length > 1 ? (
            <div className="col-2 text-left">
              <input
                name="currSeqNo"
                type="number"
                value={this.state.currSeqNo}
                onChange={this.onChangeSeqno}
                size="10"
                maxLength="10"
                className="basic-body-seqno"
                placeholder=""
              />{" "}
            </div>
          ) : (
            <div className="col-2 text-left">{this.state.currSeqNo}</div>
          )}

          <div className="col-2 text-center basic-body-char-cnt">
            Char count = {this.state.writeup.length}
          </div>
          <div className="col-2 text-right">
            {mergeButton}  
          </div>
          <div className="col-2 text-left">
            {imageButton}
          </div>
          <div className="col-2 text-center">{newButton}</div>
        </div>
      </div>
    );

    let listPanel;
    let bool = true;
    console.log(
      "this.state.compArray.sliderBody:",
      this.state.compArray.sliderBody
    );
    if (this.state.compArray.length > 0) {
      console.log("In here AAAA");
      listPanel = (
        <div className="comp-body-list-box">
          {this.state.compArray.map((obj, i) => (
            <div key={i}>
              <div
                className={`${
                  bool ? "row-basic-body-list-dr" : "row-basic-body-list-lt"
                }`}
              >
                <div className="row ">
                  <div className="col-2 text-left">
                    <button
                      className={
                        obj.location === ""
                          ? "btn_exit_del_subctive"
                          : "btn_exit_del"
                      }
                      type="button"
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                      onClick={() => this.handleDeleteThis(obj)}
                    >
                      <i className="fas fa-trash-alt" />
                    </button>
                    <button
                      className="btn_reg_40"
                      type="button"
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                      onClick={() => this.handleEditThis(obj)}
                    >
                      <i className="fas fa-pen" />
                    </button>
                  </div>
                  <div className="col-1 text-center">{obj.seqNo}</div>
                  <div className="col-5 text-left">
                    {obj.sliderBody
                      ? obj.sliderBody.title.substring(0, 40)
                      : "No title defined."}
                  </div>
                  <div className="col-4 text-left">
                    {obj.sliderBody
                      ? obj.sliderBody.writeup.substring(0, 30)
                      : "No Title defined."}
                  </div>
                </div>
              </div>
              {(bool = !bool)}
            </div>
          ))}
        </div>
      );
    } else {
      console.log("In here BBBB");
      listPanel = (
        <div className="comp-body-list-box">Yet to start a write up.</div>
      );
    }

    let formatPanel;
    if (this.state.showFormatFlag) {
      formatPanel = (
        <div className="">
          <FormatCompEntry
            callingField={this.state.callingField}
            format={this.state.format}
            selectedTemplate={this.props.selectedTemplate}
            returnToCaller={this.returnFromFormat}
          />
        </div>
      );
    }

    let uploadPanel;
    if (this.state.openDAUploadFlag) {
      uploadPanel = (
        <div>
          <UploadCompositionDA
            theBroadcast={this.state.theBroadcast}
            communityId={this.props.communityId}
            broadcastId={this.props.broadcastId}
            selectedTemplate={this.props.selectedTemplate}
            compositionComponent={this.props.compositionComponent}
            currSeqNo={this.state.currSeqNo}
            returnToCaller={this.returnFromUploadCompositionDA}
            daType={this.state.daType}
          />
        </div>
      );
    }

    let outputPanel;

    if (this.state.editorialFlag) {
      outputPanel = (
        <div>
          <div className="text-center basic-body-edit-head">The Editorial</div>
          <div className="row">
            <div className="col">{editorialPanel}</div>
          </div>
          {formatPanel}
          <div className="row frame-write-head-pos">
            <div className="col-2 text-center basic-body-lbl">Ops</div>
            <div className="col-1 text-center basic-body-lbl">Seq#</div>
            <div className="col-5 text-left basic-body-lbl">Title</div>
            <div className="col-4 text-left basic-body-lbl">Paragraph</div>
          </div>
          <div className="row">
            <div className="col">{listPanel}</div>
          </div>
          {/* <div className="row save-btn-row-pos">
            <div className="col text-center">&nbsp;</div>
          </div> */}
          <div className="row frame-err-msg-pos">
            <div
              className={
                this.state.errFlag
                  ? "col text-center basic-body-save-msg-err"
                  : "col text-center basic-body-save-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        </div>
      );
    }

    if (this.state.openDAUploadFlag) {
      outputPanel = (
        <div>
          <div className="text-center basic-body-edit-head">The Editorial</div>
          {uploadPanel}
          {/* <div className="row save-btn-row-pos-msg">
            <div
              className={
                this.state.errFlag
                  ? "col text-center basic-body-save-msg-err"
                  : "col text-center basic-body-save-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div> */}
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

export default FrameCompose;
