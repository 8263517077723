import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./ScheduleTime.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveUpdtEvent = "/routes/interact/saveUpdtEvent";

const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const readMeCode    = "1111211300";
// const readMeCode = "0100000000";

class ScheduleTime extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      timingType: "periodic",
      startHour: 8,
      startminutes: 0,
      breakHour: 12,
      breakminutes: 0,
      breakduration: 0,
      gapminutes: 0,
      serviceSlotMinutes: 30,
      startampm: "am",
      breakampm: "pm",
      repeat: 1,

      customDurationHours: 1,
      customDurationMinutes: 0,
      endampm: "am",

      outputSlots: [],

      showSaveBtn: false,
      theEvent: null,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let startHour = this.state.startHour;
    let startminutes = this.state.startminutes;
    let breakHour = this.state.breakHour;
    let breakminutes = this.state.breakminutes;
    let startampm = this.state.startampm;
    let breakampm = this.state.breakampm;
    let breakduration = this.state.breakduration;
    let gapminutes = this.state.gapminutes;
    let serviceSlotMinutes = this.state.serviceSlotMinutes;
    let repeat = this.state.repeat;
    
    let tprp = this.props.theEvent.services.periodicTimeScheduleInfo;
    if ( tprp && tprp.startHour > 0 ) {
      startHour = tprp.startHour;
      startminutes = tprp.startMinutes;
      breakHour = tprp.breakStartHour;
      breakminutes = tprp.breakStartMinutes;
      startampm = tprp.ampm;
      breakampm = tprp.breakampm;
      breakduration = tprp.breakDuration;
      gapminutes = tprp.gaps;
      serviceSlotMinutes = tprp.serviceSlots;
      repeat = tprp.repeat;
    } 

    let outputSlots = [];
    if ( this.props.theEvent.services.scheduledSlots.length > 0) outputSlots = this.props.theEvent.services.scheduledSlots;
    // console.log('didMount outputSlots:', outputSlots);

    await this.setState({
      deviceSize,
      theEvent: this.props.theEvent,
      startHour,
      startminutes,
      breakHour,
      breakminutes,
      startampm,
      breakampm,
      breakduration,
      gapminutes,
      serviceSlotMinutes,
      repeat,
      outputSlots,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChangeHours = async (e) => {
    e.preventDefault();
    let value = parseFloat(e.target.value);
    if (this.countDecimals(value) === 0) {
      if (value <= 12 && value > 0) {
        await this.setState({ [e.target.name]: e.target.value });
      }
    }
  };

  onChangeMinutes = async (e) => {
    e.preventDefault();
    let name = e.target.name;
    // console.log("onChangeMinute name:", name);
    let value = e.target.value;
    // console.log("value:", value);
    if (this.countDecimals(value) === 0) {
      // await this.setState({ [e.target.name]: e.target.value })
      if ( name === "breakduration") {
        if (value <= 480 && value >= 0) {
          await this.setState({ [e.target.name]: e.target.value });
        }
      }
      if (name === "serviceSlotMinutes" ) {
        if (value <= 720 && value >= 0) {
          await this.setState({ [e.target.name]: e.target.value });
        }
      }
      if (
        name === "startminutes" ||
        name === "breakminutes" ||
        name === "customDurationMinutes"
      ) {
        if (value <= 59 && value >= 0) {
          await this.setState({ [e.target.name]: e.target.value });
        }
      }
      if (name === "gapminutes" ) {   // Max gap is 8 hours or 480 minutes
        if (value <= 480 && value >= 0) {
          await this.setState({ [e.target.name]: e.target.value });
        }
      }
    }
    // gapminutes breakduration
  };

  onChangeRepeat = async (e) => {
    e.preventDefault();
    let value = parseFloat(e.target.value);
    if (this.countDecimals(value) === 0) {
      if (value >= 1 && value < 20) {
        await this.setState({ [e.target.name]: e.target.value });
      }
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleTimingType = async (e) => {
    await this.setState({
      timingType: e.target.value,
    });
    await this.clearUpdateDirect();
  };

  handleStartAmpm = async (e) => {
    await this.setState({
      startampm: e.target.value,
    });
  };

  handleAmPm = async (e) => {
    await this.setState({
      startampm: e.target.value,
    });
  };

  handleBreakAmPm = async (e) => {
    await this.setState({
      breakampm: e.target.value,
    });
  };

  handleEndAmPm = async (e) => {
    await this.setState({
      endampm: e.target.value,
    });
  };

  clearUpdateDirect = async () => {
    await this.setState({
      startHour: 8,
      startminutes: 0,
      breakHour: 12,
      breakminutes: 0,
      breakduration: 0,
      gapminutes: 0,
      serviceSlotMinutes: 30,
      startampm: "am",
      breakampm: "pm",
      repeat: 1,

      outputSlots: [],
      showSaveBtn: false,
    });
  };

  calServiceTimeSlots = async () => {
    // console.log("calServiceTimeSlots");
    // Initialize
    let x1; // Start time to work in the day
    if (this.state.startampm === "am") {
      x1 =
        parseFloat(this.state.startHour) * 60 +
        parseFloat(this.state.startminutes);
    } else {
      x1 =
        (parseFloat(this.state.startHour) + 12) * 60 +
        parseFloat(this.state.startminutes);
    }

    let y1 = parseFloat(this.state.serviceSlotMinutes);
    let y2 = parseFloat(this.state.gapminutes);
    // console.log('duration minutes y1=', y1);
  
    let b1;
    if (this.state.breakampm === "am") {
      b1 =
        parseFloat(this.state.breakHour) * 60 +
        parseFloat(this.state.breakminutes);
    } else {
      let breakHour = this.state.breakHour;
      // console.log("1 breakHour:", breakHour);
      if (parseFloat(breakHour) === 12) breakHour = 0;
      // console.log("2 breakHour:", breakHour);
      b1 = (breakHour + 12) * 60 + parseFloat(this.state.breakminutes);
    }
    let b2 = b1 + parseFloat(this.state.breakduration);
    let n = parseFloat(this.state.repeat);
    // -------------------------------------


    let z = x1;
    let out = [];
    let bf = false; // break flag is false on start (break not taken yet)
    let errMsg = "",
      errFlag = false;
    for (var i = 1; i <= n; i++) {
      let z1 = z + y1 + y2;
      // console.log(i, ". z:", z, " z1:", z1, " b1:", b1, " bf:", bf);
      if (z1 <= b1) {
        console.log("path 1");    let obj = { timeint: z, timestr: "", err: false, msg: "", bf };
        out.push(obj);
        z = z + (y1 + y2);
      } else {
    
        if (i === 1) {
 
          errMsg =
            "Start Time and Break Time is too close with respect to parameters specified. Failed to schedule. Please adjust plan parameters and schedule again.";
          errFlag = true;
          let obj = { timeint: 0, timestr: "", err: true, msg: errMsg, bf };
          out.push(obj);
          i = n + 1;
          break;
        } else {
       
          if ( !bf) {
            // console.log('include break time ..1 z:', z, ' b1:', b1, ' b2:', b2);
            z = b2; 
       
            bf = true;
            let obj = { timeint: z, timestr: "", err: false, msg: "", bf };
            out.push(obj);
          } else  {
            // console.log('After break ....')
            let obj = { timeint: z, timestr: "", err: false, msg: "", bf };
            out.push(obj);
          } 
          z = z + (y1 + y2);
          // console.log('z:', z);
        }
      }
      // console.log('i:', i);
    }

    // console.log('out:', out);
    
    await this.processSchedulingOutput(out, errFlag, errMsg);
    // console.log("this.state.outputSlots:", this.state.outputSlots);
  };

  processSchedulingOutput = async (out, errFlag, errMsg) => {
    // console.log("in processSchedulingOutput out: ", out);
    let output = [];
    let showSaveBtn = true;
    if (errFlag) {
      let obj = {
        timeint: 0,
        timestr: "",
        err: true,
        msg: errMsg,
        bf: false,
        duration: 0,
      };
      output.push(obj);
      showSaveBtn = false;
    } else {
      let j = 1;
      out.forEach(async (elmobj) => {
        let hrstr = "",
          mnstr = "";
        let hr = Math.floor(elmobj.timeint / 60);
        let mn = elmobj.timeint % 60;

        let ampm = "am";
        if (hr >= 12) {
          ampm = "pm";
          hr = hr - 12;
        }
        if (hr < 10) hrstr = "0" + hr.toString();
        else hrstr = hr.toString();
        if (mn < 10) mnstr = "0" + mn.toString();
        else mnstr = mn.toString();

        let obj = {
          timeint: elmobj.timeint,
          seq: j,
          timestr: hrstr + " : " + mnstr + " " + ampm,
          err: false,
          msg: "",
          bf: elmobj.bf,
          duration: this.state.serviceSlotMinutes,
        };
        // console.log('schedule obj:', obj)
        output.push(obj);
        j++;
      });
      showSaveBtn = true;
    }

    await this.setState({
      outputSlots: output,
      showSaveBtn,
      errMsg: ''
    });
  };


  calCustomService = async () => {
    let output = this.state.outputSlots;
    // console.log('calCustomService output:', output);
    let seq = output.length + 1;
    let x1; // Start time to work in this slot in minutes from the start of the day.
    if (this.state.startampm === "am") {
      x1 =
        parseFloat(this.state.startHour) * 60 +
        parseFloat(this.state.startminutes);
    } else {
      x1 =
        (parseFloat(this.state.startHour) + 12) * 60 +
        parseFloat(this.state.startminutes);
    }
    let hr = Math.floor(x1 / 60);
    let mn = x1 % 60;
    let duration =
      parseFloat(this.state.customDurationHours) * 60 +
      parseFloat(this.state.customDurationMinutes);
    let x2 = x1 + duration; // x2 signify end time of a schedule under construction.

    let errFlag = false;
    let errMsg = "";
    // console.log("this.state.outputSlots:", this.state.outputSlots);
    // console.log("startInt:", startInt);
    // console.log("startTime x1:", x1, " duration x2:", x2);

    for (var i = 0; i < this.state.outputSlots.length; i++) {
      let obj = this.state.outputSlots[i];
      // console.log('obj:', obj);
      let a1 = parseFloat(obj.timeint);
      let a2 = a1 + parseFloat(obj.duration);
      // console.log(' a1:', a1, ' a2:', a2 );
      // Err1 = this starting point is inside some prior schedules.
      if (a1 < x1 && x1 < a2) {
        errFlag = true;
        errMsg = "This construct overlaps with prior schedules.";
        break;
      }
      // Err2 = this end point is inside some prios schedule.
      if (a1 < x2 && x2 < a2) {
        errFlag = true;
        errMsg = "This construct overlaps with prior schedules.";
        break;
      }
      // Err3 = Some schedule lie entrely within this schedule
      if (a1 >= x1 && a2 <= x2) {
        errFlag = true;
        errMsg = "This construct overlaps with prior schedules.";
        break;
      }
    }
    if ( hr > 12 ) hr = hr - 12;
   
    if (!errFlag) {
      let obj = {
        timeint: x1,
        seq,
        timestr: hr + " : " + mn + " " + this.state.startampm,
        err: false,
        msg: "",
        bf: false,
        duration: duration,
      };
      // console.log('custom slot obj:', obj);
      output.push(obj);
      await this.setState({
        outputSlots: output,
        showSaveBtn: true,
        errFlag: false,
        errMsg: "",
      });
    } else {
      await this.setState({
        errFlag: true,
        errMsg,
      });
    }
  };

  handleDeleteThisSchedule = async (post) => {
    // console.log("post:", post);
    // console.log("post str:", JSON.stringify(post));
    let delout = [];
    let i = 1;
    this.state.outputSlots.forEach((obj) => {
      if (obj.seq !== post.seq) {
        let locobj = obj;
        locobj.seq = i; // re-sequencing
        delout.push(locobj);
        i++;
      }
    });
    await this.setState({
      outputSlots: delout,
    });
  };

  packDataForDB = async () => {
    let scheduleInfo = {
      startHour: this.state.startHour,
      startMinutes: this.state.startminutes,
      ampm: this.state.startampm,
      serviceSlots: parseFloat(this.state.serviceSlotMinutes),
      gaps: parseFloat(this.state.gapminutes),
      repeat: parseFloat(this.state.repeat),
      breakStartHour: parseFloat(this.state.breakHour),
      breakStartMinutes: parseFloat(this.state.breakminutes),
      breakampm: this.state.breakampm,
      breakDuration: parseFloat(this.state.breakduration)
    }
    let input = {
      eventId: this.props.theEvent.eventId,
      periodicTimeScheduleInfo: scheduleInfo,
      scheduledSlots: this.state.outputSlots,
      timezone,
      schedulingType: this.state.timingType,
      module: "servicetimeslots",
      clientProgram: "ScheduleTime.js",
      clientFunction: "saveUpdateEvent",
    };
    return input;
  };

  saveUpdateEvent = async () => {
    let url = baandaServer + saveUpdtEvent;
    // let isValid = await this.validateData();
    // console.log("isValid:", isValid);
    // if (isValid.status) {
    let inp = await this.packDataForDB();
    // console.log(" url:", url, " inp:", inp);
    try {
      let resp = await axios.post(url, inp);
      // console.log("rest:", resp);
      if (resp.data.status === "success") {
        await this.setState({
          errMsg: "Time scheduling saved.",
          eventId: resp.data.Msg.eventId,
          theEvent: resp.data.Msg.theEvent,
        });
        this.returnToCreateShift();
      } else {
        await this.setState({
          errMsg: resp.data.Msg,
        });
      }
    } catch (err) {
      console.log('saveUpdtEvent err:', err.message);
    }
    
  };

  returnToCreateShift = async() => {
    this.props.returnToCaller(this.state.theEvent);
  }

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ScheduleTime...');

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let buttonPanel;
    buttonPanel = (
      <div>
        {this.state.showSaveBtn ? (
          <button
            className="btn_reg_60"
            type="button"
            onClick={this.saveUpdateEvent}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Save
          </button>
        ) : null}
        {infoModalButton}
      </div>
    );

    let calcRepeatButtonPanel;
    calcRepeatButtonPanel = (
      <div>
        <button
          className="btn_reg_75"
          type="button"
          onClick={this.calServiceTimeSlots}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Schedule
        </button>
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.clearUpdateDirect}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Clear
        </button>
      </div>
    );

    let customButtonPanel;
    customButtonPanel = (
      <div>
        <button
          className="btn_reg_50"
          type="button"
          onClick={this.calCustomService}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Add
        </button>
      </div>
    );

    let scheduleProcessPanel;
    scheduleProcessPanel = (
      <div>
        <div className="row">
        {/* <div className="row row-team-trait"> */}
          <div className="col">
            <strong>Scheduling Type&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="periodic"
                  checked={this.state.timingType === "periodic"}
                  onChange={this.handleTimingType}
                />{" "}
                Repetative
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="custom"
                  checked={this.state.timingType === "custom"}
                  onChange={this.handleTimingType}
                />{" "}
                Custom
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let timePanel;
    if (this.state.timingType === "periodic") {
      timePanel = (
        <div>
          <div className="text-center service-sch-header">
            Start Time is&nbsp;{this.state.startHour}&nbsp;:&nbsp;
            {this.state.startminutes}
            &nbsp;&nbsp;{this.state.startampm}&nbsp;&nbsp;TZ:&nbsp;{timezone}
          </div>
          <div className="row srv-period-row-time">
            <div className="col-5 service-sch-lbl text-right">Hours</div>
            <div className="col-7 text-left">
              <input
                name="startHour"
                type="number"
                value={this.state.startHour}
                onChange={this.onChangeHours}
                className="sch-plan-hrs-min"
                step="1"
              />
              &nbsp; (1 to 12)
            </div>
          </div>
          <div className="row srv-period-row-time">
            <div className="col-5 service-sch-lbl text-right">Minutes</div>
            <div className="col-7 text-left">
              <input
                name="startminutes"
                type="number"
                value={this.state.startminutes}
                onChange={this.onChangeMinutes}
                className="sch-plan-hrs-min"
                step="1"
              />
              &nbsp; (0 to 59)
            </div>
          </div>
          <div className="row srv-period-row-ampm">
            <div className="col text-center radio-font-style">
              <strong>Time of the day&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="am"
                    checked={this.state.startampm === "am"}
                    onChange={this.handleAmPm}
                  />{" "}
                  AM
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="pm"
                    checked={this.state.startampm === "pm"}
                    onChange={this.handleAmPm}
                  />{" "}
                  PM
                </label>
              </div>
            </div>
          </div>
          <hr className="text-center hr-time-sch-adj-top" />
          <div className="row gap-row">
            <div className="col gaps-in-min">
              Each service slot-time &nbsp;
              <input
                name="serviceSlotMinutes"
                type="number"
                value={this.state.serviceSlotMinutes}
                onChange={this.onChangeMinutes}
                className="event-schedule-gap-min"
                step="1"
              />{" "}
              &nbsp;mins.
            </div>
          </div>
          <div className="row gap-row">
            <div className="col gaps-in-min">
              Gaps between services&nbsp;
              <input
                name="gapminutes"
                type="number"
                value={this.state.gapminutes}
                onChange={this.onChangeMinutes}
                className="event-schedule-gap-min"
                step="1"
              />{" "}
              &nbsp;mins.
            </div>
          </div>
          <hr className="text-center hr-time-sch-adj-bottom" />
          <div className="text-center service-sch-header">
            Break Start Time is&nbsp;{this.state.breakHour}&nbsp;:&nbsp;
            {this.state.breakminutes}&nbsp;{this.state.breakampm}
          </div>
          <div className="row srv-period-row-time">
            <div className="col-5 service-sch-lbl text-right">Hours</div>
            <div className="col-7 text-left">
              <input
                name="breakHour"
                type="number"
                value={this.state.breakHour}
                onChange={this.onChangeHours}
                className="sch-plan-hrs-min"
                step="1"
              />
              &nbsp; (1 to 12)
            </div>
          </div>
          <div className="row srv-period-row-time">
            <div className="col-5 service-sch-lbl text-right">Minutes</div>
            <div className="col-7 text-left">
              <input
                name="breakminutes"
                type="number"
                value={this.state.breakminutes}
                onChange={this.onChangeMinutes}
                className="sch-plan-hrs-min"
                // step="1"
                // min={0}
                // max={59}
              />
              &nbsp; (0 to 59)
            </div>
          </div>
          <div className="row srv-period-row-ampm">
            <div className="col text-center radio-font-style">
              <strong>Time of the day&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="am"
                    checked={this.state.breakampm === "am"}
                    onChange={this.handleBreakAmPm}
                  />{" "}
                  AM
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="pm"
                    checked={this.state.breakampm === "pm"}
                    onChange={this.handleBreakAmPm}
                  />{" "}
                  PM
                </label>
              </div>
            </div>
          </div>
          <div className="row gap-row">
            <div className="col gaps-in-min">
              Break Duration&nbsp;
              <input
                name="breakduration"
                type="number"
                value={this.state.breakduration}
                onChange={this.onChangeMinutes}
                className="event-schedule-gap-min"
                step="1"
              />{" "}
              &nbsp;mins.
            </div>
          </div>
          <hr className="text-center hr-time-sch-adj-top" />
          <div className="row gap-row">
            <div className="col gaps-in-min">
              Number of services&nbsp;
              <input
                name="repeat"
                type="number"
                value={this.state.repeat}
                onChange={this.onChangeRepeat}
                className="event-schedule-gap-min"
                step="1"
              />{" "}
              &nbsp;in a day
            </div>
          </div>
          <div className="sch-time-plan-btn-pos">{calcRepeatButtonPanel}</div>
        </div>
      );
    }

    if (this.state.timingType === "custom") {
      timePanel = (
        <div>
          <div className="text-center service-sch-header">
            Start Time is&nbsp;{this.state.startHour}&nbsp;:&nbsp;
            {this.state.startminutes}
            &nbsp;&nbsp;{this.state.startampm}&nbsp;&nbsp;TZ:&nbsp;{timezone}
          </div>
          <div className="row srv-period-row-time">
            <div className="col-5 service-sch-lbl text-right">Hours</div>
            <div className="col-7 text-left">
              <input
                name="startHour"
                type="number"
                value={this.state.startHour}
                onChange={this.onChangeHours}
                className="sch-plan-hrs-min"
                step="1"
              />
              &nbsp; (1 to 12)
            </div>
          </div>
          <div className="row srv-period-row-time">
            <div className="col-5 service-sch-lbl text-right">Minutes</div>
            <div className="col-7 text-left">
              <input
                name="startminutes"
                type="number"
                value={this.state.startminutes}
                onChange={this.onChangeMinutes}
                className="sch-plan-hrs-min"
                step="1"
              />
              &nbsp; (0 to 59)
            </div>
          </div>
          <div className="row srv-period-row-ampm">
            <div className="col text-center radio-font-style">
              <strong>Time of the day&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="am"
                    checked={this.state.startampm === "am"}
                    onChange={this.handleAmPm}
                  />{" "}
                  AM
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="pm"
                    checked={this.state.startampm === "pm"}
                    onChange={this.handleAmPm}
                  />{" "}
                  PM
                </label>
              </div>
            </div>
          </div>
          <hr className="text-center hr-time-sch-adj-top" />
          <div className="text-center service-sch-header">
            For the duration of:
          </div>
          <div className="row srv-period-row-time">
            <div className="col-5 service-sch-lbl text-right">Hours</div>
            <div className="col-7 text-left">
              <input
                name="customDurationHours"
                type="number"
                value={this.state.customDurationHours}
                onChange={this.onChangeHours}
                className="sch-plan-hrs-min"
                step="1"
              />
              &nbsp; (1 to 12)
            </div>
          </div>
          <div className="row srv-period-row-time">
            <div className="col-5 service-sch-lbl text-right">Minutes</div>
            <div className="col-7 text-left">
              <input
                name="customDurationMinutes"
                type="number"
                value={this.state.customDurationMinutes}
                onChange={this.onChangeMinutes}
                className="sch-plan-hrs-min"
                step="1"
              />
              &nbsp; (0 to 59)
            </div>
          </div>
          <div className="text-cnter">{customButtonPanel}</div>
          {/* {this.state.errFlag ? (
            <div className="text-center custom-planning-error">
              {this.state.errMsg}
            </div>
          ) : null} */}
        </div>
      );
    }

    let periodicPlan;
    periodicPlan = (
      <div className="time-plan-box ">
        {this.state.timingType === "periodic" ? (
          <div className="timing-plan">Plan Periodic Service Times</div>
        ) : (
          <div className="timing-plan">Custom Service Times</div>
        )}
        {timePanel}
      </div>
    );

    let listSchedules;
    let bool = true;
    if (this.state.outputSlots && this.state.outputSlots.length > 0) {
      listSchedules = (
        <div>
          {/* <div className="fixedsize_events_list"> */}
          {this.state.outputSlots.map((post, i) => {
            // console.log(i,'. post:', post);
            return (
              <div key={i}>
                <div
                  className={`${bool ? "row-time-sch-dr" : "row-time-sch-lt"}`}
                >
                  <div className="row">
                    <div className="col text-left time-schedule-output-lbl">
                      {post.err ? (
                        <div>
                          <font color="red">{post.msg}</font>
                        </div>
                      ) : (
                        <div>
                          {this.state.timingType === "periodic" ? null : (
                            <button
                              onClick={() =>
                                this.handleDeleteThisSchedule(post)
                              }
                              className="btn-custom-sch-delete"
                              style={{
                                cursor: this.state.disabled
                                  ? "default"
                                  : "pointer",
                              }}
                            >
                              <i className="fa fa-trash" />
                            </button>
                          )}
                          Service-slot&nbsp;{post.seq}.&nbsp;at&nbsp;
                          <b>{post.timestr.substring(0,2) === "00" ? "12 " + post.timestr.substring(3, 10) : post.timestr}</b>&nbsp;...&nbsp;for&nbsp;
                          {post.duration}&nbsp;minutes
                        </div>
                      )}
                      {/* Service-slot&nbsp;{post.seq}.&nbsp;at&nbsp;<b>{post.timestr}</b>&nbsp;...&nbsp;for&nbsp;{this.state.serviceSlotMinutes}&nbsp;minutes */}
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      );
    }

    let translationOfPlanPanel;
    translationOfPlanPanel = (
      <div className="time-plan-output-box">
        <div className="timing-plan">Service Time-slots per Plan</div>
        <div className="row">
          <div className="col">{listSchedules}</div>
        </div>
      </div>
    );

    let schedulePlanPanel;
    schedulePlanPanel = (
      <div>
        <div className="row">
          <div className="col-6 text-center">{periodicPlan}</div>
          <div className="col-6 text-center">{translationOfPlanPanel}</div>
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="scheduling-panels-pos">
        {scheduleProcessPanel}
        {schedulePlanPanel}
      </div>
    );

    return (
      <div>
        <div className="text-center time-schedule-head">Time Scheduling</div>
        <div className="text-center shift-time-name-pos"><b>Event:&nbsp;{this.props.theEvent.eventName}</b></div>
        {outputPanel}
        {buttonPanel}
        <div className={ this.state.errFlag ?  "text-center schedule-time-msg-err" : "text-center schedule-time-msg"}>{this.state.errMsg}</div>
        {/* <div className="text-center schedule-time-msg">{this.state.errMsg}</div> */}
        <ModalContainer />
      </div>
    );
  }
}

ScheduleTime.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ScheduleTime));
