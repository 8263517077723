import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import { Redirect, withRouter } from "react-router-dom";
// import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import ReportLanding from "../../../reports/ReportLanding";
import TaskMain from "../tasks/TaskMain";
import PayCtrlMain from "../payctrl/PayCtrlMain";
import GateCheck from "./gate/GateCheck";

import "./WorkforceRouter.css";

const payControlList = ["paycontrol"];
const taskButtonList = ["taskctrl"];

const readMeCode = "1130000000";

class WorkforceRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      reportsFlag: false,
      tasksFlag: false,
      payCtrlFlag: false,
      deviceMsgFlag: false,
      gateCtrlFlag: false,

      btnPayCtrlFlag: false,
      btnTaskCtrlFlag: false,

      authorizedFuncs: [],

      errMsg: "",
      errFlag: false,
      showErrMsgPanel: false,
    };
  }

  componentDidMount = async () => {
    // console.log("in WorkforceRouter...");
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setAuthorizedButtons();

    let errMsg = "";
    if (deviceSize === "small") {
      errMsg =
        'A device (not a smart phone) with a bigger display area is needed for reports (Rpts), tasks, or payCtrl (Crtl). Please access this module on a larger device.';
    }

    // Do some event related stuff to enable gate receiving of fee etc.

    await this.setState({
      deviceSize,
      errMsg,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  setAuthorizedButtons = async () => {
    // console.log("### this.props.authorizedFuncs: ", this.props.authorizedFuncs);
    let btnPayCtrlFlag = false;
    let btnTaskCtrlFlag = false;

    taskButtonList.forEach((obj2) => {
      // console.log("obj2:", obj2);
      if (this.props.authorizedFuncs.includes(obj2)) {
        btnTaskCtrlFlag = true;
      }
    });

    payControlList.forEach((obj3) => {
      // console.log("obj3:", obj3);
      if (this.props.authorizedFuncs.includes(obj3)) {
        btnPayCtrlFlag = true;
      }
    });

    this.setState({
      btnPayCtrlFlag,
      btnTaskCtrlFlag,
    });
  };

  handleSelectedFunction = async (selected) => {
    // console.log("selected:", selected);
    this.setState({
      reportsFlag: false,
      tasksFlag: false,
      payCtrlFlag: false,
      gateCtrlFlag: false,
      showErrMsgPanel: false,
    });

    if (selected === "reports") {
      if (this.state.deviceSize === "big") {
        this.setState({
          reportsFlag: true,
        });
      } else {
        this.setState({
          showErrMsgPanel: true,
        });
      }
    }

    if (selected === "tasks") {
      if (this.state.deviceSize === "big") {
        this.setState({
          tasksFlag: true,
        });
      } else {
        this.setState({
          showErrMsgPanel: true,
        });
      }
    }

    if (selected === "payctrl") {
      if (this.state.deviceSize === "big") {
        this.setState({
          payCtrlFlag: true,
        });
      } else {
        this.setState({
          showErrMsgPanel: true,
        });
      }
    }

    if (selected === "gate") {
      this.setState({
        gateCtrlFlag: true,
      });
    }
  };

  render() {
    // console.log("WorkforceRouter props :", this.props);
    // console.log("WorkforceRouter states:", this.state);
    console.log('WorkforceRouter...');

    let activePanel;
    if (this.state.reportsFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activePanel = (
        <div>
          <ReportLanding
            role={this.props.role}
            isMember={this.props.isMember}
            isCustomer={this.props.isCustomer}
            communityId={this.props.communityId}
            community={this.props.community}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    if (this.state.tasksFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activePanel = (
        <div>
          <TaskMain
            isMember={this.props.isMember}
            isCustomer={this.props.isCustomer}
            communityId={this.props.communityId}
            community={this.props.community}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    if (this.state.payCtrlFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activePanel = (
        <div>
          <PayCtrlMain
            isMember={this.props.isMember}
            isCustomer={this.props.isCustomer}
            communityId={this.props.communityId}
            community={this.props.community}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    if (this.state.deviceMsgFlag) {
      activePanel = <div>Will display device message</div>;
    }

    if (this.state.gateCtrlFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activePanel = (
        <div>
          <GateCheck
            isMember={this.props.isMember}
            isCustomer={this.props.isCustomer}
            communityId={this.props.communityId}
            community={this.props.community}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    let reports, tasks, payctrl; //, gate;
    if (this.state.deviceSize === "small") {
      reports = "Rpt";
      tasks = "Tsk";
      payctrl = "Ctl";
      // gate = "Gate";
    } else {
      reports = "Reports";
      tasks = "Tasks";
      payctrl = "Pay-Ctrl";
      // gate = "Gate";
    }

    let showTaskBtn = false;
    if (this.props.community.communityType === "coop" && this.props.store) {
      showTaskBtn = true;
    }
    if (this.props.community.communityType === "coop" && !this.props.store) {
      if (this.state.btnTaskCtrlFlag) showTaskBtn = true;
    }
    if (
      this.props.community.communityType === "standard" &&
      this.state.btnTaskCtrlFlag
    ) {
      showTaskBtn = true;
    }

    let actionLandingButtons = (
      <div>
        <div className="row">
          <div className="col market_header_spacing">
            {/* -------------------User Payment Pending ----------------------------------------- */}
            <button
              className={`${
                this.state.reportsFlag ? "btn_reg_70_active" : "btn_reg_70"
              }`}
              type="button"
              onClick={() => this.handleSelectedFunction("reports")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {this.state.deviceSize === 'big' ? reports : <i className="fas fa-paste" />}
            </button>
            {showTaskBtn ? (
              <button
                className={`${
                  this.state.tasksFlag ? "btn_reg_70_active" : "btn_reg_70"
                }`}
                type="button"
                onClick={() => this.handleSelectedFunction("tasks")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {this.state.deviceSize === 'big' ? tasks : <i className="fas fa-tasks" />}
              </button>
            ) : null}
            {this.state.btnPayCtrlFlag ? (
              <button
                className={`${
                  this.state.payCtrlFlag ? "btn_reg_70_active" : "btn_reg_70"
                }`}
                type="button"
                onClick={() => this.handleSelectedFunction("payctrl")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {this.state.deviceSize === 'big' ? payctrl : <i className="fas fa-gamepad" />}
              </button>
            ) : null}
            {/* {this.props.role !== "user" && this.props.community.communityType === 'standard' ? (
              <button
                className={`${
                  this.state.gateCtrlFlag ? "btn_reg_70_active" : "btn_reg_70"
                }`}
                type="button"
                onClick={() => this.handleSelectedFunction("gate")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {gate}
              </button>
            ) : null} */}
            <button
              className="btn_info_main"
              type="button"
              onClick={this.openAlertModal}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-info-circle" />
            </button>
            &nbsp;
          </div>
        </div>
        {activePanel}
      </div>
    );

    let errorPanel;
    if (this.state.showErrMsgPanel) {
      errorPanel = (
        <div className="catalog-error-box">
          <div className="cat-error-header">Message</div>
          <div>
            <p align="justify" className="device-error-msg">
              {this.state.errMsg}
            </p>
          </div>
        </div>
      );
    }

    let outputPanel;
    if (this.state.showErrMsgPanel) outputPanel = errorPanel;
    else {
      outputPanel = (
        <div className="action-landing-buttons">{actionLandingButtons}</div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

WorkforceRouter.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WorkforceRouter)
);
