import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import { sortArrayOfDates } from "../../../../../../utils/sortArrayOfDates";

import SwapSubscriptionCC from "../swap/SwapSubscriptionCC";

import "./CustPayCtrlDetail.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getLastFourCardNo = "/routes/payctrl/getLastFourCardNo?";
const getASubscriptionState = "/routes/payctrl/getASubscriptionState?";
const handleCustSubDetailProcess = "/routes/payctrl/handleCustSubDetailProcess";
const getPaymentFailedReason = "/routes/payctrl/getPaymentFailedReason?";
const retryChargingFailedPayment = "/routes/payctrl/retryChargingFailedPayment";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1131110000";

class custPayCtrlDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      last4: null,

      errMsg: "",
      errFlag: false,

      subscriptionState: null,
      subscriptionStatus: "",

      subscriptionActions: "messages",

      sortedMsgs: [],
      setPauseFeeFlag: true,
      showAmountDetailsFlag: false,

      confirmOps: false,

      sendMessage: "",
      subSelected: null,

      swapProcessFlag: false,
      payctrlMainFlag: true,

      payFailErrorMsg: "",
      subPayErr: false,

      handledErrorIssue: false,

      restrictAccess: "false",
    };
  }

  componentDidMount = async () => {
    await this.getLast4OfAttachedCard();
    await this.retrieveSubscriptionState();

    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    if (this.props.subSelected.lastSubscriptionState === "payment_failed") {
      await this.getTheErrorReason(this.props.subSelected.subscriptionId);
    }

    let sortedMsgs = await this.sortMessages(this.props.subSelected.messages);

    await this.setState({
      deviceSize,
      subSelected: this.props.subSelected,
      sortedMsgs,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getTheErrorReason = async (subId) => {
    let param =
      "paymentIntentId=" +
      this.props.subSelected.stripePaymentIntent.id +
      "&communityId=" +
      this.props.communityId +
      "&sellerStripAcc=" +
      this.props.sellerStripeId;
    let url = baandaServer + getPaymentFailedReason + param;

    try {
      // console.log("geterr url:", url);
      let resret = await axios.get(url);
      // console.log("resret:", resret);
      if (resret.data.status === "success") {
        this.setState({
          payFailErrorMsg: resret.data.Msg,
          subPayErr: true,
          errFlag: false,
          errMsg: "",
        });
      } else {
        this.setState({
          payFailErrorMsg: "",
          errMsg: resret.data.Msg + " Request support at info@baanda.com.",
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        payFailErrorMsg: "",

        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  sortMessages = async (msgs) => {
    let sorted = await sortArrayOfDates(msgs, "messageDate", "dsc");
    return sorted;
  };

  getLast4OfAttachedCard = async () => {
    let customerId = this.props.subSelected.subscriptionSetupObj.customer.id;
    let sellerStripeAcc = this.props.sellerStripeId;
    let params =
      "customerId=" + customerId + "&sellerStripeAcc=" + sellerStripeAcc;
    let url = baandaServer + getLastFourCardNo + params;
    try {
      let lret = await axios.get(url);
      if (lret.data.status === "success") {
        this.setState({
          last4: lret.data.Msg,
        });
      } else {
        this.setState({
          errMsg: lret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  retrieveSubscriptionState = async () => {
    let sellerStripeAcc = this.props.sellerStripeId;
    let subscriptionId = this.props.subSelected.subscriptionId;
    let params =
      "subscriptionId=" +
      subscriptionId +
      "&sellerStripeAcc=" +
      sellerStripeAcc;
    try {
      let url = baandaServer + getASubscriptionState + params;
      // console.log("retrieveSubscriptionState url:", url);
      let subret = await axios.get(url);
      if (subret.data.status === "success") {
        this.setState({
          subscriptionState: subret.data.Msg,
          subscriptionStatus: subret.data.Msg.status,
        });
      } else {
        this.setState({
          errMsg: this.state.errMsg + " | " + subret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  returnToDetails = () => {
    this.props.returnToCaller();
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleActions = async (e) => {
    let value = e.target.value;
    let msg = "";
    // console.log("value:", value);
    if (value === "requestpause") {
      if (this.state.deviceSize === "small") {
        msg = "Notify a pause-till date or open-ended";
      } else {
        msg =
          "The notify message should include the pause-till date, or open ended.";
      }
    }

    let swapProcessFlag = false;
    let payctrlMainFlag = true;
    if (value === "swap") {
      swapProcessFlag = true;
      payctrlMainFlag = false;
    }
    await this.setState({
      subscriptionActions: e.target.value,
      errMsg: msg,
      errFlag: false,
      swapProcessFlag,
      payctrlMainFlag,
    });
  };

  returnFromSwap = () => {
    this.setState({
      swapProcessFlag: false,
      payctrlMainFlag: true,
      subscriptionActions: "messages",
    });
  };

  postMessages = async () => {
    // console.log(
    //   "in postMessages this.state.sendMessage.length:",
    //   this.state.sendMessage.length
    // );
    if (this.state.sendMessage.length > 20) {
      let msgObj = {
        messageBy: "customer",
        messengerBaandaId: this.props.auth.user.baandaId,
        messengerName: this.props.auth.user.name,
        messageDate: new Date(),
        message: this.state.sendMessage,
      };
      let input = {
        reqFor: this.state.subscriptionActions,
        msgObj,
        communityId: this.props.subSelected.sellerCommunityId,
        subTranId: this.props.subSelected.subTranId,
        restrictAccess: this.state.restrictAccess,
      };
      await this.postInDB(input);
    } else {
      let msg = "";
      if (this.state.deviceSize === "small") {
        msg = "Notify with a minimum 20 chars.";
      } else
        msg =
          "Please enter a minimum of twenty characters.";

      this.setState({
        errMsg: msg,
        errFlag: true,
      });
    }
  };

  postInDB = async (input) => {
    try {
      let url = baandaServer + handleCustSubDetailProcess;
      // console.log("url:", url, " input:", input);
      let msgret = await axios.post(url, input);
      if (msgret.data.status === "success") {
        // console.log("msgret.data.Msg:", msgret.data.Msg);
        let sortedMsgs = await this.sortMessages(msgret.data.Msg.Msg.messages);
        this.setState({
          errMsg: "Successfully updated",
          errFlag: false,
          sendMessage: "",
          subscriptionActions: "messages",
          subSelect: msgret.data.Msg,
          sortedMsgs,
        });
      } else {
        this.setState({
          errMsg: msgret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("postMessage err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleCtrlProcess = async () => {
    // console.log(
    //   "handleCtrlProcess this.state.subscriptionActions:",
    //   this.state.subscriptionActions
    // );
    if (
      this.state.subscriptionActions === "messages" ||
      this.state.subscriptionActions === "requestpause" ||
      this.state.subscriptionActions === "cancel"
    ) {
      await this.postMessages();
    }

    if (this.state.subscriptionActions === "swap") {
      await this.swapCardInfo();
    }
  };

  showAmountDetails = async () => {
    this.setState({
      showAmountDetailsFlag: true,
    });
  };

  returnFromAmountDetails = async () => {
    this.setState({
      showAmountDetailsFlag: false,
    });
  };

  handleConfirm = async () => {
    this.setState((prevstate) => ({
      confirmOps: !prevstate.confirmOps,
    }));
  };

  handleFixedError = async () => {
    this.setState((prevstate) => ({
      handledErrorIssue: !prevstate.handledErrorIssue,
    }));
  };

  requestRetryOfInvoicePay = async () => {
    let url = baandaServer + retryChargingFailedPayment;
    if (
      this.props.subSelected.paymentFailedObj &&
      this.props.subSelected.paymentFailedObj.invoiceId !== ""
    ) {
      let input = {
        paymentFailedObj: this.props.subSelected.paymentFailedObj,
        subscriptionId: this.props.subSelected.subscriptionId,
      };
      try {
        let payret = await axios.post(url, input);
        if (payret.data.status === "success") {
          this.setState({
            errMsg: "Retry invoice state: " + payret.data.Msg,
            errFlag: false,
          });
        } else {
          this.setState({
            errMsg: payret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: "No invoice to be charged. Please contact info@baanda.com.",
        errFlag: true,
      });
    }
  };

  // swapCardInfo = async () => {
  //   alert("Will have swap in next push");
  // };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('CustPayCtrlDetail...');

    let ss = this.props.subSelected;

    let infoButto = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let exitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToDetails}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let processButton;
    if (this.state.subscriptionActions !== "swap") {
      processButton = (
        <button
          className="btn_reg_80_pctl"
          type="button"
          onClick={this.handleCtrlProcess}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Process
        </button>
      );
    } else {
      if (this.state.confirmOps) {
        processButton = (
          <button
            className="btn_reg_80"
            type="button"
            onClick={this.handleCtrlProcess}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Process
          </button>
        );
      }
    }

    let buttonAmtMore;
    buttonAmtMore = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={this.showAmountDetails}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        . . .
      </button>
    );

    let confirmPanel;
    if (this.state.subscriptionActions === "swap") {
      confirmPanel = (
        <div className="row pay-ctrl-seller-msg-pos">
          <div className="col text-center pay-ctrl-customer-msg-txt">
            <Checkbox
              checked={this.state.confirmOps}
              onChange={this.handleConfirm}
            />{" "}
            Confirm to swap CC for this subscription
          </div>
        </div>
      );
    }

    let amountDetailPanel;
    if (this.state.showAmountDetailsFlag) {
      let regdisc;
      if (this.state.deviceSize === "small") {
        regdisc = "Regi. Disc.";
      } else {
        regdisc = "Registration Disc";
      }
      amountDetailPanel = (
        <div className="ctrl-cust-amount-detail-box">
          <div className="text-center cust-pay-ctrl-amout-head">
            Amount Details&nbsp;&nbsp;
            <button
              className="btn_back_main"
              type="button"
              onClick={this.returnFromAmountDetails}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Monthly Fee
            </div>
            <div className="col-8 text-left cust-pay-ctrl-amout-txt">
              ${" "}
              {this.commaFormattedCurrency(
                this.props.subSelected.inputData.price.monthlyFee
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Registration
            </div>
            <div className="col-8 text-left cust-pay-ctrl-amout-txt">
              ${" "}
              {this.commaFormattedCurrency(
                this.props.subSelected.inputData.price.registrationFee
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Created on
            </div>
            <div className="col-8 text-left cust-pay-ctrl-amout-txt">
              {this.props.subSelected.created_at.substring(0, 10)}
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Discount
            </div>
            <div className="col-8 text-left cust-pay-ctrl-amout-txt">
              {this.props.subSelected.inputData.price.couponName !== ""
                ? this.props.subSelected.inputData.price.couponName
                : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Monthly Disc
            </div>
            <div className="col-8 text-left cust-pay-ctrl-amout-txt">
              {this.commaFormattedCurrency(
                this.props.subSelected.inputData.price.monthlyCouponPercent
              )}{" "}
              %
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Discount For
            </div>
            <div className="col-8 text-left cust-pay-ctrl-amout-txt">
              {this.props.subSelected.inputData.price.forMonths} months
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              {regdisc}
            </div>
            <div className="col-8 text-left cust-pay-ctrl-amout-txt">
              {this.commaFormattedCurrency(
                this.props.subSelected.inputData.price.regiCouponPercent
              )}{" "}
              %
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right cust-pay-ctrl-amout-lbl">
              Subscription Id
            </div>
            <div className="col-8 text-left cust-pay-ctrl-amout-txt">
              {this.props.subSelected.subscriptionId}
            </div>
          </div>
        </div>
      );
    }

    let actionLbl, swapcc, reqpause, cancel, messages; //, setpausefee;
    if (this.state.deviceSize === "small") {
      actionLbl = "Action: ";
      swapcc = "SwapCC";
      reqpause = "RqPaus";
      // setpausefee = 'Pause Fee'
      messages = "Msgs";
      cancel = "Cncl";
    } else {
      actionLbl = "Actions: ";
      swapcc = "Swap CC";
      reqpause = "Request Pause";
      // setpausefee = "Set Pause Fee";
      messages = "Messages";
      cancel = "Cancel";
    }

    let actionsPanel;
    actionsPanel = (
      <div className="row name-cust-panel-row">
        <div className="col text-center radio-font-cust-payctrl-style">
          <strong>{actionLbl}&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-cust-ctrl-label">
              <input
                className="form-check-input"
                type="radio"
                value="swap"
                checked={this.state.subscriptionActions === "swap"}
                onChange={this.handleActions}
              />{" "}
              {swapcc}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-cust-ctrl-label">
              <input
                className="form-check-input"
                type="radio"
                value="requestpause"
                checked={this.state.subscriptionActions === "requestpause"}
                onChange={this.handleActions}
              />{" "}
              {reqpause}
            </label>
          </div>

          {/* { this.state.setPauseFeeFlag ?  (
            <div className="form-check form-check-inline">
            <label className="form-check-cust-ctrl-label">
              <input
                className="form-check-input"
                type="radio"
                value="setpausefee"
                checked={this.state.subscriptionActions === "setpausefee"}
                onChange={this.handleActions}
              />{" "}
              {setpausefee}
            </label>
          </div>
          )  : null} */}
          <div className="form-check form-check-inline">
            <label className="form-check-cust-ctrl-label">
              <input
                className="form-check-input"
                type="radio"
                value="cancel"
                checked={this.state.subscriptionActions === "cancel"}
                onChange={this.handleActions}
              />{" "}
              {cancel}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-cust-ctrl-label">
              <input
                className="form-check-input"
                type="radio"
                value="messages"
                checked={this.state.subscriptionActions === "messages"}
                onChange={this.handleActions}
              />{" "}
              {messages}
            </label>
          </div>
        </div>
      </div>
    );

    let origin, msgrname;
    if (this.state.deviceSize === "small") {
      origin = "Origin";
      msgrname = "Msngr's Name";
    } else {
      origin = "Originator";
      msgrname = "Messenger's Name";
    }

    let messagePanel;
    let bool = true;
    if (this.state.subscriptionActions === "messages") {
      if (this.state.sortedMsgs.length > 0) {
        messagePanel = (
          <div>
            <div className="row sub-item-list-row">
              <div className="col-2 text-center pay-ctrl-comm-list-head">
                {origin}
              </div>
              <div className="col-7 text-left pay-ctrl-comm-list-head">
                {msgrname}
              </div>
              <div className="col-3 text-left pay-ctrl-comm-list-head">
                Dated
              </div>
            </div>
            <div className="fixedsize-comm-payctrl-msg-box">
              {this.state.sortedMsgs.map((obj, i) => {
                let msgby = obj.messageBy;
                if (obj.messageBy === "seller") msgby = "Seller";
                if (this.state.deviceSize === "small") {
                  if (obj.messageBy === "customer") msgby = "Cstmr";
                } else {
                  if (obj.messageBy === "customer") msgby = "Customer";
                }
                return (
                  <div key={i}>
                    <div
                      className={
                        bool ? "row-col-schdule-dr" : "row-col-schdule-lt"
                      }
                    >
                      <div className="row">
                        <div className="col-2 text-center pay-ctrl-msg-cust-lbl">
                          {msgby}
                        </div>
                        <div className="col-7 text-left pay-ctrl-msg-cust-lbl">
                          {obj.messengerName}
                        </div>
                        <div className="col-3 text-left pay-ctrl-msg-cust-date-lbl">
                          {obj.messageDate.substring(0, 10)}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col text-left pay-ctrl-msg-cust-txt">
                          {obj.message}
                        </div>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        messagePanel = (
          <div>
            <div className="text-center pay-ctrl-detail-no-msg">
              No prior messages exists as of now.
            </div>
            {/* {sendMessagePanel} */}
          </div>
        );
      }
    }

    let chars;
    if (this.state.deviceSize === "small") {
      chars = "CU";
    } else {
      chars = "Chars Used";
    }

    let sendMessagePanel;
    let placemsg =
      "Send a message to " + ss.customerName + " in 250 characters";
    sendMessagePanel = (
      <div className="">
        <div className="row cust-payctrl-detail-msgs">
          <div className="col-8 text-left cust-pay-ctrl-det-msg">
            Write Message:
          </div>
          <div className="col-4 text-center cust-pay-ctrl-det-msg-chars">
            {chars}: {this.state.sendMessage.length}
          </div>
        </div>
        <div className="row">
          <div className="col text-left">
            <textarea
              name="sendMessage"
              maxLength="250"
              placeholder={placemsg}
              rows="3"
              wrap="hard"
              spellCheck="true"
              className="input-payctrl-detail-cust-msg"
              onChange={this.onChange}
              value={this.state.sendMessage}
              required
            />
          </div>
        </div>
      </div>
    );

    let comm, card, reqfor;
    if (this.state.deviceSize === "small") {
      comm = "Comm";
      card = "Last4";
      reqfor = "ReqFor";
    } else {
      comm = "Community";
      card = "Card (Last4)";
      reqfor = "Request For";
    }

    let detailTopPanel;
    detailTopPanel = (
      <div>
        <div className="text-left pay-ctrl-cust-top-head">
          Customer (type: {ss.customerType})
        </div>
        {ss.customerType === "community" ? (
          <div className="row">
            <div className="col-3 text-right pay-ctrl-detail-cust-lbl">
              {comm}
            </div>
            <div className="col-9 text-left pay-ctrl-cust-detail-txt">
              {ss.customerCommunityName} (id: {ss.customerCommunityId})
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="col-3 text-right pay-ctrl-detail-cust-lbl">Name</div>
          <div className="col-9 text-left pay-ctrl-cust-detail-txt">
            {ss.customerName} ({ss.customerEmail})
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right pay-ctrl-detail-cust-lbl">Item</div>
          <div className="col-9 text-left pay-ctrl-cust-detail-txt">
            {ss.itemName}
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right pay-ctrl-detail-cust-lbl-amt">
            Amount
          </div>
          <div className="col-9 text-left pay-ctrl-cust-detail-txt">
            $ {this.commaFormattedCurrency(ss.inputData.price.monthlyFee)} /
            month&nbsp;{buttonAmtMore}
          </div>
        </div>
        {this.state.last4 ? (
          <div className="row">
            <div className="col-3 text-right pay-ctrl-detail-cust-lbl">
              {card}
            </div>
            <div className="col-9 text-left pay-ctrl-cust-detail-txt">
              ---- ---- ---- {this.state.last4.last4}
            </div>
          </div>
        ) : null}

        <div className="row">
          <div className="col-3 text-right pay-ctrl-detail-cust-lbl">
            {reqfor}
          </div>
          <div className="col-9 text-left pay-ctrl-cust-detail-txt">
            {ss.requestFor === "" ? (
              "No Requests"
            ) : (
              <font color="blue">{ss.requestFor.toUpperCase()}</font>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right pay-ctrl-detail-cust-lbl">
            Status
          </div>
          <div className="col-9 text-left pay-ctrl-cust-detail-txt">
            {this.props.subSelected.isCanceled ? "Cancelled " : null }
            {this.props.subSelected.isPaused ? "Paused " : null }
            &nbsp; -- Check messages for detail
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right pay-ctrl-detail-cust-lbl">
            Seller
          </div>
          <div className="col-9 text-left pay-ctrl-cust-detail-txt">
            {ss.sellerCommunityName} (id: {ss.sellerCommunityId})
          </div>
        </div>
      </div>
    );

    let reqretry, faillbl, fixedissue;
    if (this.state.deviceSize === "small") {
      reqretry = "Retry";
      faillbl = "Charge Failed";
      fixedissue = "Fixed Issue";
    } else {
      reqretry = "Retry Req";
      faillbl = "Subscription Charge Failed";
      fixedissue = "Fixed the issue";
    }

    let lastPayErrorPanel;
    if (
      this.props.subSelected.lastSubscriptionState === "payment_failed" &&
      this.state.subPayErr
    ) {
      lastPayErrorPanel = (
        <div>
          <div className="row">
            <div className="col-4 text-right pay-error-lbl">{faillbl}</div>
            <div className="col-8 text-left pay-error-lbl">
              {this.state.payFailErrorMsg}
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-right pay-error-instruction">
              <Checkbox
                checked={this.state.handledErrorIssue}
                onChange={this.handleFixedError}
              />{" "}
              {fixedissue}
            </div>
            <div className="col-8 text-left pay-error-lbl">
              {this.state.handledErrorIssue ? (
                <button
                  className="btn_reg_80"
                  type="button"
                  onClick={this.requestRetryOfInvoicePay}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  {reqretry}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      );
    }

    let payctrlHead;
    if (this.state.deviceSize === "small") {
      payctrlHead = "PayCtrl";
    } else {
      payctrlHead = "Customer's Payment Control";
    }

    let outputPanel;
    if (this.state.payctrlMainFlag) {
      outputPanel = (
        <div>
          <div className="pay-ctrl-cust-det-head">
            {payctrlHead} {processButton} {exitButton} {infoButto}
          </div>
          {detailTopPanel}
          {amountDetailPanel}
          <div className="text-center cust-action-line-pos">{actionsPanel}</div>
          {messagePanel}
          {sendMessagePanel}
          {confirmPanel}
          {lastPayErrorPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center cust-payctrl-msg-err"
                : "text-center cust-payctrl-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    if (this.state.swapProcessFlag) {
      outputPanel = (
        <div>
          <SwapSubscriptionCC
            returnToCaller={this.returnFromSwap}
            subSelected={this.props.subSelected}
            communityId={this.props.communityId}
            last4={this.state.last4.last4}
            customerId={this.state.subSelected.subscriptionSetupObj.customer.id}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

custPayCtrlDetail.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(custPayCtrlDetail)
);
