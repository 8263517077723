import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { CSVReader } from "react-papaparse";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./GroupSetup.css";

import axios from "axios";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const saveGroup = "/routes/dashboard/saveGroup";
const getMembersOfCommunity = "/routes/dashboard/getMembersOfCommunity?";
const getGroupsOfCommunity = "/routes/dashboard/getGroupsOfCommunity?";
const getBaandaIdByEmail = "/routes/users/getBaandaIdByEmail?";

const re =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const readMeCode = "1154000000";

const buttonRef = React.createRef();
// const Checkbox = (props) => <input type="checkbox" {...props} />;

class GroupSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      devicesize: "",

      groupType: "newgroup",
      groupName: "",
      groupId: 0,
      description: "",
      selectedGroup: null,
      broadcastGroupId: 0,
      processedData: [],

      totMemberData: [], // This holds the processed member data
      memberData: [],
      searchGroupName: "",

      showViaCsvFlag: false,

      csvData: [],

      loadAugmentType: "augment",

      dataLoadedFlag: false,
      showManualFlag: false, // Will be divided into internal and external handling.

      showMemberManualFlag: false,

      recMsg: "",
      recMsgFlag: false,

      showNewFlag: true,
      showWIPFlag: false,
      showCopyFlag: false,
      showGroupList: false,
      showRecipiantAddFlag: false,
      showNonMemberManualFlag: false,

      groupNames: [],

      selheight: 1,

      memberName: "",
      lastName: "",
      email: "",
      cell: "",

      members: [],

      memberType: "member",
      deviceSize: "",
      noGroupsFlag: false,
      showCsvErrFlag: false,
      csvErrMsg: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getMembersOfCommunity();

    await this.setState({
      deviceSize,
    });
  };

  checkGetBaanda = async (email) => {
    try {
      let param = "email=" + email;
      let url = baandaServer + getBaandaIdByEmail + param;
      // console.log('url:', url);
      let banret = await axios.get(url);
      // console.log('banret.data:', banret.data);
      if (banret.data.status === "success") {
        return banret.data.Msg;
      } else {
        this.setState({
          recMsg: banret.data.Msg,
          recMsgFlag: true,
        });
        return 0;
      }
    } catch (err) {
      this.setState({
        recMsg: err.message,
        recMsgFlag: true,
      });
      return 0;
    }
  };

  getMembersOfCommunity = async () => {
    try {
      let params =
        "communityId=" +
        this.props.communityId +
        "&memberName=" +
        "&coopStoreId=0";
      let url = baandaServer + getMembersOfCommunity + params;
      // console.log("url:", url);
      let memdata = await axios.get(url);
      // console.log("memdata:", memdata);
      if (memdata.data.status === "success") {
        let members = [];
        memdata.data.Msg.forEach((obj) => {
          let memobj = {
            baandaId: obj.baandaId,
            name: obj.name,
            cell: obj.cell,
            email: obj.email,
          };
          members.push(memobj);
        });
        await this.setState({
          members,
        });
      }
    } catch (err) {
      console.log("getMembersOfCommunity err:", err.message);
    }
  };

  openAlertModal = async () => {
    // console.log("in openAlertModal info:", readMeCode);
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        // message: msg,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({
      [e.target.name]: e.target.value,
      recMsg: "",
    });
  };

  handleGroupType = async (e) => {
    let value = e.target.value;
    // console.log("e.target.value:", value);

    await this.setState({
      groupType: value,
      showNewFlag: false,
      showWIPFlag: false,
      memberData: [],
      showCopyFlag: false,
    });

    switch (value) {
      case "newgroup":
        await this.setState({
          showNewFlag: true,
          groupName: "",
          description: "",
          memberData: [],
          totMemberData: [],
        });
        break;
      case "existinggroup":
        await this.setState({
          showWIPFlag: true,
          searchGroupName: "",
        });
        break;

      default:
        console.log("Should not reach here ...");
    }
  };

  handleMemberType = async (e) => {
    await this.setState({
      memberType: e.target.value,
      groupId: 0,
      selectedGroup: null,
      description: "",
      groupName: "",
      groupNames: [],
      searchGroupName: "",
      totMemberData: [],
      memberData: [],
      recMsg: "",
      groupType: "newgroup",
    });
  };

  handleAugmentOverwritie = async (e) => {
    await this.setState({
      loadAugmentType: e.target.value,
    });
  };

  closeWIPPanel = async (ops) => {
    // console.log("in closeWIPPanel .... ops:", ops);
    let memberData = [];
    let groupName = this.state.groupName;
    let description = this.state.description;

    if (ops === "done") {
      if (this.state.selectedGroup) {
        memberData = this.state.selectedGroup.members;
        groupName = this.state.selectedGroup.groupName;
        description = this.state.selectedGroup.description;
      }
    }
    // console.log("memberData::::", memberData);

    await this.setState({
      showNewFlag: true,
      showWIPFlag: false,
      showCopyFlag: false,
      showGroupList: false,
      memberData,
      groupName,
      description,
      email: "",
    });
  };

  getCommunityInfo = async () => {
    let parms = "communityId=" + this.props.communityId;
    let url = baandaServer + getCommunityInfo + parms;
    try {
      let retData = await axios.get(url);
      // console.log("MarketMain retData: ", retData);
      await this.setState({
        community: retData.data.joiningProcess,
      });
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
    }
  };

  packageDbData = async () => {
    let members = [];
    this.state.memberData.forEach((obj) => {
      if (this.state.memberType === "nonmember") {
        let isAmember = false;
        this.state.members.forEach((elm) => {
          if (obj.email.trim() === elm.email.trim()) isAmember = true;
        });
        if (!isAmember) {
          members.push(obj);
        }
      }

      if (
        this.state.memberType === "member" ||
        this.state.memberType === "mixed"
      ) {
        // if (parseFloat(obj.baandaId) !== 0) members.push(obj);
        members = [...this.state.memberData];
      }
    });

    let input = {
      communityId: this.props.communityId,
      groupId: this.state.groupId,
      groupName: this.state.groupName,
      description: this.state.description,
      members,
      memberType: this.state.memberType,
      baandaId: this.props.auth.user.baandaId,
    };

    return input;
  };

  saveGroups = async () => {
    if (this.state.groupName !== "default") {
      try {
        let url = baandaServer + saveGroup;
        let input = await this.packageDbData();
        // console.log("url:", url, " input:", input);
        let retrec = await axios.post(url, input);
        // console.log("retrec:", retrec);
        if (retrec.data.status === "success") {
          await this.setState({
            groupId: retrec.data.Msg,
            recMsg: "Succesfully Saved",
            recMsgFlag: false,
            memberData: input.members,
            processedData: [],
          });
        } else {
          await this.setState({
            recMsg: retrec.data.Msg,
            recMsgFlag: true,
          });
        }
      } catch (err) {
        console.log("err:", err.message);
      }
    } else {
      this.setState({
        recMsg: "Group name 'default' is not allowed.  ",
        recMsgFlag: true,
      });
    }
  };

  handleManualEntry = async () => {
    // console.log(
    //   "handleManualEntry this.state.memberData:",
    //   this.state.memberData,
    //   " this.state.memberType:",
    //   this.state.memberType
    // );
    if (this.state.memberType === "member") {
      // console.log("in here ... hme");
      await this.setState({
        showMemberManualFlag: true,
        totMemberData: this.state.members,
        // memberName: "",
      });
    }
    if (
      this.state.memberType === "nonmember" ||
      this.state.memberType === "mixed"
    ) {
      // console.log("in here nonmember... hme");
      await this.setState({
        showMemberManualFlag: true,
        totMemberData: this.state.memberData,
        // memberName: "",
      });
    }
  };

  exitManualEntry = async () => {
    await this.setState({
      showMemberManualFlag: false,
    });
  };

  handleCsv = async () => {
    await this.setState({
      showViaCsvFlag: true,
    });
  };

  exitShowErrPanel = async () => {
    await this.setState({
      showCsvErrFlag: false,
    });
  };

  exitHandleCsv = async () => {
    await this.setState({
      showViaCsvFlag: false,
      showCsvErrFlag: false,
    });
  };

  handleCsvValidateProcess = async () => {
    // console.log("Will process data ... from csv");
    let processedData = [];
    let isError = false;
    let errMsg = "";
    let showCsvErrFlag = false;
    let invalidEmails = "";
    if (this.state.csvData.length === 0) {
      isError = true;
      errMsg =
        "File loaded is empty. Follow the guidelines of Group Mgmt. info (i).";
    } else {
      // console.log("In validating csv file ...");
      let invlidNumberOfFields = 0,
        rowsWithEmptyFields = 0,
        invalidEmail = 0;
      // let i = 0;
      this.state.csvData.forEach(async (obj) => {
        // i++;
        // console.log(i, ". obj:", obj, " obj.data.length:", obj.data.length);
        let isOkFields = true;
        let allHasData = true;
        if (obj.data.length !== 3) {
          // console.log(i, " Not right number of data ...");
          isError = true;
          invlidNumberOfFields++;
          isOkFields = false;
        }
        if (isOkFields) {
          if (obj.data[0] === "" || obj.data[1] === "" || obj.data[2] === "") {
            rowsWithEmptyFields++;
            isError = true;
            allHasData = false;
          }
        }
        if (isOkFields && allHasData) {
          // console.log(i, ". in here emval obj.data[i]: ", obj.data[i]);
          // let emailval = await emailValidation(obj.data[2]);
          let emailval = re.test(obj.data[1].trim());
          // console.log("xxxxx");
          // console.log("emailval:", emailval);
          if (!emailval) {
            invalidEmail++;
            invalidEmails = invalidEmails + obj.data[1] + "; ";
            isError = true;
          }
        }
      });
      // console.log("1. isError:", isError, ' ');
      if (isError) {
        if (invlidNumberOfFields > 0) {
          errMsg =
            invlidNumberOfFields +
            " row(s) with invliad (not 3) number of fields. ";
        }
        if (rowsWithEmptyFields > 0) {
          errMsg = errMsg + rowsWithEmptyFields + " rows with empty fields. ";
        }
        if (invalidEmail > 0) {
          errMsg =
            errMsg +
            invalidEmail +
            " invalid Emial syntax (" +
            invalidEmails +
            ")";
        }
        showCsvErrFlag = true;
      }
    }
    // console.log(
    //   ">>> 2. isError: ",
    //   isError,
    //   " errMsg:",
    //   errMsg,
    //   " this.state.loadAugmentType:",
    //   this.state.loadAugmentType
    // );
    if (!isError) {
      if (this.state.loadAugmentType === "augment") {
        // console.log("Augmenting ...");
        processedData = this.state.processedData;
        this.state.csvData.forEach((obj) => {
          // console.log("ag obj:", obj);
          let itExists = false;
          this.state.memberData.forEach((elm) => {
            if (elm.email.trim() === obj.data[1].trim()) itExists = true;
          });
          if (!itExists) {
            let cell = obj.data[2].trim();
            if (cell.length === 10) cell = "+1" + cell;
            let csvObj = {
              baandaId: 0,
              name: obj.data[0].trim(),
              email: obj.data[1].trim(),
              cell,
            };
            processedData.push(csvObj);
          }
        });
      } else {
        // console.log("Not augmenting ....");
        this.state.csvData.forEach((obj) => {
          // console.log("3. obj:", obj);
          let cell = obj.data[2].trim();
          if (cell.length === 10) cell = "+1" + cell;
          let csvObj = {
            baandaId: 0,
            name: obj.data[0],
            email: obj.data[1],
            cell,
          };
          // console.log("csvObj:", csvObj);
          processedData.push(csvObj);
        });
        // console.log('3.1 processedData:', processedData);
      }
    }
    // else console.log("There is some error ...");

    // console.log(">>> isError:", isError);

    let showViaCsvFlag = false;
    if (isError) showViaCsvFlag = true;

    // console.log("3.2 processedData:", processedData);
    // If no error in processing and is all OK
    if (!showViaCsvFlag) {
      await this.populateMemberData(processedData);
    }

    await this.setState({
      showViaCsvFlag,
      showCsvErrFlag,
      csvErrMsg: errMsg,
      processedData,
      memberData: processedData,
    });
  };

  populateMemberData = async (pdata) => {
    // console.log("populateMemberData pdata:", pdata);
    // console.log('this.state.loadAugmentType:', this.state.loadAugmentType);
    let memberData;
    if (this.state.loadAugmentType === "augment")
      memberData = this.state.memberData;
    else memberData = [];
    pdata.forEach((obj) => {
      let mobj = {
        baandaId: 0,
        name: obj.memberName + " " + obj.lastName,
        email: obj.email,
        cell: obj.cell,
      };
      memberData.push(mobj);
    });

    await this.setState({
      memberData,
    });
  };

  handleOpenDialog = (e) => {
    // console.log("e.target.value:", e.target.value);
    // console.log("e.target.name:", [e.target.name]);
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  handleOnFileLoad = async (data) => {
    // console.log("------------AA---------------");
    // console.log(data);
    // console.log("---------------------------");
    await this.setState({
      csvData: data,
      dataLoadedFlag: true,
    });
  };

  handleOnRemoveFile = async (data) => {
    // console.log("-------------BB--------------");
    // console.log(data);
    // console.log("---------------------------");
  };

  handleProcessFile = async (data) => {
    // console.log("In handleProcessFile data:", data);
    // console.log(data);
    // console.log("---------------------------");
    await this.handleRemoveFile(data);
    await this.handleCsvValidateProcess();
    // this.exitHandleCsv();
  };

  handleRemoveFile = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current.removeFile(e);
    }
  };

  onfocus = async () => {
    let selht = 7;
    if (this.state.groupNames.length < 4)
      selht = this.state.groupNames.length + 2;
    await this.setState({
      selheight: selht,
    });
    // console.log('this.state.selheight:', this.state.selheight, ' selht:', selht);
  };

  onblur = async () => {
    await this.setState({
      selheight: 1,
    });
  };

  handleSelectGroupName = async () => {
    let value = document.getElementById("grp-mgmt-groups").value;
    // console.log("handleSelectGroupName value:", value);
    let data = JSON.parse(value);
    // console.log("valobj:", data);
    let memberType = "nonmember";
    if (data.memberType) memberType = data.memberType;
    await this.setState({
      searchGroupName: data.groupName,
      groupName: data.groupName,
      description: data.description,
      groupId: data.groupId,
      memberData: data.members,
      memberType,
      selectedGroup: data,
    });
    await this.onblur();
  };

  handleSearchGroupName = async () => {
    // console.log("handleSearchGroupName ...");
    try {
      let params =
        "communityId=" +
        this.props.communityId +
        "&groupName=" +
        this.state.searchGroupName;
      let url = baandaServer + getGroupsOfCommunity + params;
      let grpret = await axios.get(url);
      // console.log("grpret:", grpret);
      if (grpret.data.status === "success") {
        if (grpret.data.Msg.length > 0) {
          await this.setState({
            groupNames: grpret.data.Msg,
            noGroupsFlag: false,
            showGroupList: true,
          });
        } else {
          await this.setState({
            groupNames: [],
            noGroupsFlag: true,
            showGroupList: false,
          });
        }
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  handleRecipiantAdd = async () => {
    // console.log("handleRecipiantAdd");
    await this.setState({
      showRecipiantAddFlag: true,
      memberName: "",
      email: "",
      cell: "",
    });
  };

  validateIndividualEntry = async () => {
    // console.log('this.state.memberType:', this.state.memberType);

    let isValid = true;
    let msg = "";
    let baandaId = 0;

    if (this.state.memberType === "nonmember" && this.state.memberName === "") {
      isValid = false;
      msg = "Name is mandatory. | ";
    }
    if (this.state.email === "") {
      isValid = false;
      msg = msg + "email is mandatory | ";
    } else {
      // let emailval = await emailValidation(this.state.email);
      let emailval = re.test(this.state.email);
      if (!emailval) {
        isValid = false;
        msg = msg + "Invaild email syntax. | ";
      }
    }
    if (this.state.memberType === "nonmember" && this.state.cell !== "") {
      if (this.state.cell.length < 12) {
        isValid = false;
        msg =
          msg +
          "Invaild cell syntax [should be (222) 555-3234, a full number] | ";
      }
    }

    if (this.state.memberType === "nonmember") {
      let isOutsider = true;
      this.state.members.forEach((obj) => {
        if (obj.email === this.state.email) {
          isOutsider = false;
        }
      });
      if (!isOutsider) {
        isValid = false;
        msg = "Cannot enter member for a non-member group.";
      }
    }

    if (this.state.memberType === "mixed") {
      // console.log('in here 718 ...')
      let isOutsider = true;
      this.state.members.forEach((obj) => {
        if (obj.email === this.state.email) {
          isOutsider = false;
          baandaId = obj.baandaId
        }
      });
      // console.log('isOutsider:', isOutsider);
      if ( isOutsider ) {
        let retib = await this.checkGetBaanda(this.state.email);
        // console.log('retib:', retib);
        baandaId = retib.baandaId;
      }
   
    }



    if (isValid && this.state.memberType === "member") {
      let isMember = false;
      this.state.members.forEach(async (obj) => {
        if (obj.email === this.state.email) {
          isMember = true;
          await this.setState({
            memberName: obj.name,
            cell: obj.cell,
          });
        }
      });
      if (!isMember) {
        isValid = false;
        msg = "This email is not a member.";
      }
    }

    if (isValid) {
      this.state.memberData.forEach((obj) => {
        if (obj.email === this.state.email) {
          isValid = false;
          msg = "This email exists in the recipiant list.";
        }
      });
    }

    let output = {
      msg,
      baandaId
    }

    return { status: isValid, Msg: output };
  };

  handleAddMember = async () => {
    // console.log('handleAddMember ...')
    let chkValid = await this.validateIndividualEntry();
    // console.log("chkValid:", chkValid);
    // console.log("chkValid:", chkValid.Msg);
    if (chkValid.status) {
      // console.log("handleRecipiantAdd to add");
      let newMember = {
        baandaId: chkValid.Msg.baandaId,
        name: this.state.memberName,
        email: this.state.email,
        cell: this.state.cell,
      };

      let memberData = this.state.memberData;
      memberData.push(newMember);
      // let totMemberData = this.state.totMemberData;
      // totMemberData.push(newMember);

      await this.setState({
        showRecipiantAddFlag: false,
        memberData,
        // totMemberData
      });
    } else {
      await this.setState({
        individualAddMsg: chkValid.Msg,
      });
    }
  };

  handleAddRecipiantExit = async () => {
    // console.log("handleRecipiantAdd exit");
    await this.setState({
      showRecipiantAddFlag: false,
    });
  };

  //showManualFlag
  handleManualGroupingDone = async () => {
    await this.setState({
      showMemberManualFlag: false,
      // showManualFlag: false,
      showRecipiantAddFlag: false,
      email: "",
      cell: "",
      memberName: "",
    });
  };

  formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    let cleaned = ("" + str).replace(/\D/g, "");
    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  handleFilterByName = async () => {
    // console.log("handleFilterByName");
    let memberData = [...this.state.totMemberData];
    let output = [];
    if (this.state.memberName === "") {
      output = memberData;
      // console.log("1 ... output:", output);
    } else {
      memberData.forEach((obj) => {
        if (
          obj.name
            .toLocaleLowerCase()
            .includes(this.state.memberName.toLocaleLowerCase())
        ) {
          // console.log("matched ...");
          output.push(obj);
        }
      });
      // console.log("2 output:", output);
    }
    // }

    await this.setState({
      memberData: output,
    });
  };

  handleDeletelineitem = async (inpobj) => {
    // console.log("delete obj:", inpobj);
    let pd = [],
      mpd = [],
      tpd = [];
    this.state.processedData.forEach((elm) => {
      if (elm.email !== inpobj.email) pd.push(elm);
    });
    this.state.memberData.forEach((elm) => {
      if (elm.email.trim() !== inpobj.email.trim()) mpd.push(elm);
    });
    this.state.totMemberData.forEach((elm) => {
      if (elm.email.trim() !== inpobj.email.trim()) tpd.push(elm);
    });

    await this.setState({
      processedData: pd,
      memberData: mpd,
      totMemberData: tpd,
    });
  };

  closeAddMemberPanel = () => {
    this.setState({
      showMemberManualFlag: false
    })
  }

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("GroupSetup...");

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let buttonPanel = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={this.saveGroups}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let opsButtonPanel;
    opsButtonPanel = (
      <div>
        <button
          className="btn_reg_70"
          type="button"
          onClick={this.handleManualEntry}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Create
        </button>
        {this.state.memberType === "nonmember" ||
        this.state.memberType === "mixed" ? (
          <button
            className="btn_reg_70"
            type="button"
            onClick={this.handleCsv}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Via CSV
          </button>
        ) : null}

        {buttonPanel}
      </div>
    );

    let augmentDecisionPanel;
    augmentDecisionPanel = (
      <div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Please&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="augment"
                  checked={this.state.loadAugmentType === "augment"}
                  onChange={this.handleAugmentOverwritie}
                />{" "}
                Augment with&nbsp;&nbsp;<font color="#db8a3d">Or</font>
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="overwrite"
                  checked={this.state.loadAugmentType === "overwrite"}
                  onChange={this.handleAugmentOverwritie}
                />{" "}
                Overwrite on
              </label>
            </div>
            the existing data.
          </div>
        </div>
      </div>
    );

    let groupOriginPanel;
    groupOriginPanel = (
      <div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Group Type&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="newgroup"
                  checked={this.state.groupType === "newgroup"}
                  onChange={this.handleGroupType}
                />{" "}
                New Group
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="existinggroup"
                  checked={this.state.groupType === "existinggroup"}
                  onChange={this.handleGroupType}
                />{" "}
                Existing Group
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let memberTypePanel;
    memberTypePanel = (
      <div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Member Type&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="member"
                  checked={this.state.memberType === "member"}
                  onChange={this.handleMemberType}
                />{" "}
                Internal (members only)
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="nonmember"
                  checked={this.state.memberType === "nonmember"}
                  onChange={this.handleMemberType}
                />{" "}
                External (non-members only)
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="mixed"
                  checked={this.state.memberType === "mixed"}
                  onChange={this.handleMemberType}
                />{" "}
                Mixed
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let newNamePanel;
    newNamePanel = (
      <div>
        {memberTypePanel}
        <div className="row grp-name-row">
          <div className="col-3 text-right grp-text-lbl">Group Name</div>
          <div className="col-9 text-left">
            <input
              name="groupName"
              type="text"
              value={this.state.groupName}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="grp-text-entry"
              placeholder="Enter a new Group Name"
            />
          </div>
        </div>
        <div className="row grp-name-row">
          <div className="col-3 text-right grp-text-lbl">Description</div>
          <div className="col-9 text-left">
            <textarea
              name="description"
              maxLength="200"
              placeholder="A reference description for iternal use."
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="grp-text-entry"
              onChange={this.onChange}
              value={this.state.description}
              required
            />
          </div>
        </div>
        {/* {memberTypePanel} */}
      </div>
    );

    let csvErrorPanel;
    if (this.state.showCsvErrFlag) {
      csvErrorPanel = (
        <div className="grp-csv-err-box">
          <div className="text-center grp-csv-err-header">
            CSV Errors&nbsp;
            <button
              type="button"
              onClick={this.exitShowErrPanel}
              className="btn_reg_100"
              style={{
                cursor: this.state.disabled ? "default" : "pointer",
              }}
            >
              <i className="fa fa-times" />
            </button>
          </div>
          <div className="text-center grp-csv-err-msg">
            {this.state.csvErrMsg}
          </div>
        </div>
      );
    }

    let csvReaderPanel;
    csvReaderPanel = (
      <div className="text-center">
        <div className="grp-csv-reader-head text-center">Load from CSV</div>
        <div className="text-center grp-csv-basic-format">
          <p align="adjust">Please check for details via Info button (i).</p>
          <p align="adjust">
            Basic format of CSV should be comma-deliminated: <br /> name, email,
            cell-number.
          </p>
          <p align="adjust">
            <b>Example:</b> <br />
            John Smith, john.smith@domain.com, 1234567890 <br />
            Jane Roy, Jane.Roy@domain.com, 2346676789
          </p>
        </div>
        {csvErrorPanel}
        <div className="text-center">
          <CSVReader
            ref={buttonRef}
            onFileLoad={this.handleOnFileLoad}
            onError={this.handleOnError}
            noClick
            noDrag
            onRemoveFile={this.handleOnRemoveFile}
          >
            {({ file }) => (
              <div>
                <aside
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 10,
                  }}
                >
                  <button
                    type="button"
                    onClick={this.handleOpenDialog}
                    className="btn_reg_90"
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    Browse file
                  </button>
                  <button
                    type="button"
                    onClick={this.handleProcessFile}
                    className="btn_reg_90"
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    Process
                  </button>
                  <button
                    type="button"
                    onClick={this.exitHandleCsv}
                    className="btn_reg_90"
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    <i className="fa fa-times" />
                  </button>
                </aside>
                <div className="text-center csv-file-name">
                  {file ? "Data loaded from:" : null}&nbsp; {file && file.name}
                </div>
                <div className="text-center csv-file-name">
                  {file && this.state.csvData.length > 0
                    ? augmentDecisionPanel
                    : null}
                  &nbsp;
                </div>
              </div>
            )}
          </CSVReader>
        </div>
        <div
          className={
            !this.state.dataLoadedFlag
              ? "text-center pre-csv-load"
              : "text-center post-csv-load"
          }
        >
          {!this.state.dataLoadedFlag
            ? "Browse file to load csv data. Remove file for performance when loaded."
            : "Data loaded. Click Done to return. Check manual radio to review or edit."}
        </div>
      </div>
    );

    let manualFilterName = (
      <button
        className="btn_reg_80"
        type="button"
        onClick={this.handleFilterByName}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Search&nbsp;
        <i className="fa fa-search" />
      </button>
    );

    let manualFilterAdd = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleRecipiantAdd}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Add&nbsp;
        <i className="fa fa-plus" />
      </button>
    );

    let manualFilterDone = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleManualGroupingDone}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save&nbsp;
      </button>
    );

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.closeAddMemberPanel}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let individualEntryPanel;
    if (this.state.showRecipiantAddFlag) {
      individualEntryPanel = (
        <div className="individual-grp-entry-box">
          <div className="text-center grp-entry-box-header">
            Individual Recipiant
          </div>
          <div className="row">
            <div className="col-3 text-right man-group-lbl">Name</div>
            <div className="col-9 text-left man-group-lbl">
              <input
                name="memberName"
                type="text"
                value={this.state.memberName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="brd-recipiant-input"
                placeholder="Recipiant's first name"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right man-group-lbl">Email</div>
            <div className="col-9 text-left man-group-lbl">
              <input
                name="email"
                type="text"
                value={this.state.email}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="brd-recipiant-input"
                placeholder="Recipiant's email"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right man-group-lbl">Cell</div>
            <div className="col-5 text-left man-group-lbl">
              <PhoneInput
                placeholder="Recipiant cell#"
                value={this.state.contactCell}
                onChange={(cell) => this.setState({ cell })}
                country="US"
                className="recipiant-cell"
              />
            </div>
            <div className="col-4 text-right man-group-lbl">&nbsp;</div>
          </div>
          <div className="row">
            <div className="col text-center">
              <button
                className="btn_reg_70"
                type="button"
                onClick={this.handleAddMember}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Add&nbsp;&nbsp;
                <i className="fa fa-plus" />
              </button>
              <button
                className="btn_back_main"
                type="button"
                onClick={this.handleAddRecipiantExit}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <i className="fas fa-step-backward" />
              </button>
            </div>
          </div>
          <div className="text-center individual-entry-msg-err">
            {this.state.individualAddMsg}
          </div>
        </div>
      );
    }

    let bool = true;
    let manualEntryPanel;
    manualEntryPanel = (
      <div>
        <div className="text-center manual-panel-header">
          Manage group (&nbsp;{this.state.groupName}
          &nbsp;)
        </div>
        <div className="row">
          {/* <div className="col-3 text-right brd-rec-search-lbl">Group Name</div> */}
          {this.state.memberType === "member" ? (
            <div className="col text-center man-search-lbl">
              Member Name:&nbsp;
              <input
                name="memberName"
                type="text"
                value={this.state.memberName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="grp-member-name-search"
                placeholder="Type name or leave blank to see members "
              />
              &nbsp;{manualFilterName}
              {manualFilterAdd}
              {manualFilterDone}
              {backButton}
            </div>
          ) : (
            <div className="col text-center man-search-lbl">
              {manualFilterAdd}
              {manualFilterDone}
              {backButton}
            </div>
          )}
        </div>
        <div className="row">
          <div className="col text-center man-recipiant-show-header">
            Ops-buttons, Name, Email, Cell
          </div>
        </div>
        {individualEntryPanel}
        <div className="show-group-member-list">
          {this.state.memberData.map((obj, i) => (
            <div key={i}>
              <div
                className={`${
                  bool ? "row-color-man-rec-a" : "row-color-man-rec-b"
                }`}
              >
                <div className="row">
                  <div className="col recipiant-line-data text-left">
                    <button
                      className="btn_exit_del"
                      type="button"
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                      onClick={() => this.handleDeletelineitem(obj)}
                    >
                      <i className="fas fa-trash-alt" />
                    </button>
                    &nbsp;&nbsp;
                    {i + 1}.&nbsp;{obj.name};&nbsp;
                    {obj.email};&nbsp;
                    {obj.cell.length === 12
                      ? this.formatPhoneNumber(obj.cell.substring(2, 12))
                      : obj.cell}
                  </div>
                </div>
              </div>
              {(bool = !bool)}
            </div>
          ))}
        </div>
      </div>
    );

    let csvPanel;
    if (this.state.showViaCsvFlag) {
      csvPanel = (
        <div className="csg-handle-group-box">
          <div className="row">
            <div className="col text-center">{csvReaderPanel}</div>
          </div>
        </div>
      );
    }

    let manualPanel;
    if (this.state.showMemberManualFlag) {
      manualPanel = (
        <div className="manual-group-mgmt-box">
          <div className="row">
            <div className="col text-center">{manualEntryPanel}</div>
          </div>
        </div>
      );
    }

    let searchButtonPanel;
    searchButtonPanel = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={this.handleSearchGroupName}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search" />
      </button>
    );

    let searchGrouplist = this.state.groupNames.map((obj, i) => {
      return (
        <option key={i} value={JSON.stringify(obj)}>
          {obj.groupName}&nbsp;
        </option>
      );
    });

    let groupNameListPanel;
    if (this.state.showGroupList) {
      groupNameListPanel = (
        <div className="select_cat-panel_catalog">
          <div className="row ">
            <div className="col text-center ">
              <select
                size={this.state.selheight}
                id="grp-mgmt-groups"
                name="grp-mgmt-groups"
                onChange={this.handleSelectGroupName}
                className="brd-group-names-list"
                onFocus={this.onfocus}
                onBlur={this.onblur}
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Select a Group
                </option>

                {searchGrouplist}
              </select>
            </div>
          </div>
        </div>
      );
    }

    let doneSearchPanel = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={() => this.closeWIPPanel("done")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Done
      </button>
    );

    let exitSearchPanel = (
      <button
        className="btn_back_main"
        type="button"
        onClick={() => this.closeWIPPanel("exit")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let findExistingPanel;
    findExistingPanel = (
      <div className="grps-search-box">
        <div className="grp-wip-search-header">
          Search & Select Existing Groups
        </div>
        <div className="row grp-name-row">
          <div className="col-2 text-right brd-rec-search-lbl">Group</div>
          <div className="col-10 text-left">
            <input
              name="searchGroupName"
              type="text"
              value={this.state.searchGroupName}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="brd-grp-search-name"
              placeholder="Enter a Group Name to Search"
            />
            &nbsp;{searchButtonPanel}
            {doneSearchPanel}
            {exitSearchPanel}
          </div>
        </div>
        {this.state.noGroupsFlag ? (
          <div className="no-group-exisit">No group exists for the search.</div>
        ) : (
          groupNameListPanel
        )}
      </div>
    );

    let activePanel;
    if (this.state.showNewFlag) {
      activePanel = <div>{newNamePanel}</div>;
    }

    if (this.state.showWIPFlag) {
      activePanel = <div>{findExistingPanel}</div>;
    }

    let outputPanel;
    outputPanel = (
      <div className="manual-grp-mgmt-box">
        <div className="text-center recipiant-mgmt-header">
          Groups Management&nbsp;&nbsp;{infoModalButton}
        </div>
        {groupOriginPanel}
        {activePanel}
        {this.state.groupName && this.state.groupName.length > 0
          ? opsButtonPanel
          : null}
        {csvPanel}
        {manualPanel}
        <div
          className={
            this.state.recMsgFlag
              ? "text-center grp-save-msg-err"
              : "text-center grp-save-msg"
          }
        >
          {this.state.recMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

GroupSetup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(GroupSetup));
