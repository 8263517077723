import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import cutRibbon from "../../../../images/ribbon-cutting-4.jpg";

import "./OpenForBusiness.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const openYourBusiness = "/routes/dashboard/openYourBusiness";
const closeYourBusiness = "/routes/dashboard/closeYourBusiness";
const openCommForPreperation = "/routes/dashboard/openCommForPreperation";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const updtCentralStoreOfCoop = "/routes/dashboard/updtCentralStoreOfCoop";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1115800000";

class OpenForBusiness extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      canOpenBusiness: true,
      message: "",
      alreadyOpen: false,
      errFlag: false,
      showOpenButton: false,
      showCloseButton: false,
      enableCloseButton: false,
      showOpenForPrepButton: false,

      adminState: null,

      adminTasksStatus: null,

      enableCommunityStore: false,
    };
  }

  componentDidMount = async () => {
    let canOpenBusiness = true;
    let message = "";
    let errFlag = false;
    let showOpenButton = false;
    let showCloseButton = false;
    let showOpenForPrepButton = false;

    await this.getTheCommunityInfo(this.props.communityId);

    if (
      !this.state.adminTasksStatus.financialYearSetup &&
      !this.state.adminTasksStatus.subscriptionSetup
    ) {
      message =
        "You have to define your business's financial strat date and subscript to baanda platform the monthly payment to start your business. ";
      canOpenBusiness = false;
      errFlag = true;
    } else {
      if (!this.state.adminTasksStatus.openForBusinessFlag) {
        showOpenButton = true;
        showCloseButton = false;
      } else {
        showOpenButton = false;
        showCloseButton = true;
      }

      if (!this.state.adminTasksStatus.openForInternalSetupFlag)
        showOpenForPrepButton = true;
    }

    let enableCommunityStore = false;
    if (this.props.community.communityType === "coop") {
      enableCommunityStore = this.props.community.commStoreOfCoopOpen;
    }

    await this.setState({
      adminState: this.props.community.adminTaskStates,
      canOpenBusiness,

      message,
      errFlag,
      showOpenButton,
      showCloseButton,
      showOpenForPrepButton,
      enableCommunityStore,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getTheCommunityInfo = async (communityId) => {
    let parms = "communityId=" + communityId;
    let url = baandaServer + getCommunityInfo + parms;
    // console.log("url: ", url, " parms:", parms);
    try {
      let retData = await axios.get(url);
      // console.log("retData:", retData);
      await this.setState({
        adminTasksStatus: retData.data.Msg.adminTaskStates,
      });
    } catch (err) {
      console.log("Error:", err.message);
    }
  };

  handleCentralStore = async () => {
    let input = {
      communityId: this.props.communityId,
      enableCommunityStore: this.state.enableCommunityStore,
    };
    let url = baandaServer + updtCentralStoreOfCoop;
    // console.log("url:", url, " input:", input);
    try {
      let strret = await axios.post(url, input);
      if (strret.data.status === "success") {
        this.setState({
          message: "Successfully updated",
          errFlag: false,
        });
      } else {
        this.setState({
          message: strret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  openTheBusiness = async () => {
    let url = baandaServer + openYourBusiness;
    let input = {
      communityId: this.props.community.communityId,
      clientProgram: "OpenForBusiness",
      clientFunction: "openTheBusiness",
    };
    // console.log("url:", url, " input:", input);

    try {
      let bizret = await axios.post(url, input);
      // console.log("bizret:", bizret);
      if (bizret.data.status === "success") {
        this.setState({
          showOpenButton: false,
          showCloseButton: true,
          adminState: bizret.data.Msg,
        });
      } else {
        this.setState({
          showCloseButton: false,
          showOpenButton: true,
          message: bizret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("openTheBusiness err:", err.message);
      this.setState({
        showCloseButton: false,
        showOpenButton: true,
        message: err.message,
        errFlag: true,
      });
    }
  };

  closeYourBusiness = async () => {
    console.log("in close ...");
    let url = baandaServer + closeYourBusiness;
    let input = {
      communityId: this.props.community.communityId,
      clientProgram: "OpenForBusiness",
      clientFunction: "closeYourBusiness",
    };
    // console.log("url:", url, " input:", input);

    try {
      let bizret = await axios.post(url, input);
      // console.log("bizret:", bizret);
      if (bizret.data.status === "success") {
        this.setState({
          showOpenButton: true,
          showCloseButton: false,
          adminState: bizret.data.Msg,
        });
      } else {
        this.setState({
          showOpenButton: false,
          showCloseButton: true,
          message: bizret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("closeTheBusiness err:", err.message);
      this.setState({
        showOpenButton: false,
        showCloseButton: true,
        message: err.message,
        errFlag: true,
      });
    }
  };

  openForInternalSetup = async () => {
    let url = baandaServer + openCommForPreperation;
    let input = {
      communityId: this.props.community.communityId,
      clientProgram: "OpenForBusiness",
      clientFunction: "openForInternalSetup",
    };
    // console.log("url:", url, " input:", input);

    try {
      let bizret = await axios.post(url, input);
      // console.log("bizret:", bizret);
      if (bizret.data.status === "success") {
        await this.setState({
          showOpenForPrepButton: false,
          adminState: bizret.data.Msg,
          message:
            "The community is ready for internal setup. Go to Lobby & return, to access.",
          errFlag: false,
        });
      } else {
        await this.setState({
          showOpenForPrepButton: true,
          message: bizret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("openTheBusiness err:", err.message);
      await this.setState({
        showOpenForPrepButton: true,
        message: err.message,
        errFlag: true,
      });
    }
  };

  handleEnableCommunityStore = async () => {
    await this.setState((prevstate) => ({
      enableCommunityStore: !prevstate.enableCommunityStore,
    }));
  };

  handlecloseCommunityStore = () => {
    this.setState((prevstate) => ({
      enableCloseButton: !prevstate.enableCloseButton,
    }));
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("OpenForBusiness...");

    // let ak = this.props.community.adminTaskStates;
    let ak = this.state.adminState;
    // console.log("ak: ", ak);

    let subModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    // 01.
    let financialYearPanel;
    if (ak) {
      if (ak.financialYearSetup) {
        financialYearPanel = (
          <div className="text-left biz-individual-state-ok">
            Set Date for the Financial Year.
          </div>
        );
      } else {
        financialYearPanel = (
          <div className="text-left biz-individual-state-notok">
            Set Date for the Financial Year.
          </div>
        );
      }
    }

    // 02.
    let subscriptionPanel;
    if (ak) {
      if (ak.subscriptionSetup) {
        subscriptionPanel = (
          <div className="text-left biz-individual-state-ok">
            Initiate Membership Subscription/Rent
          </div>
        );
      } else {
        subscriptionPanel = (
          <div className="text-left biz-individual-state-notok">
            Initiate Membership Subscription/Rent
          </div>
        );
      }
    }

    // 03.
    let creditcardPanel;
    if (ak) {
      if (ak.creditCardSetup) {
        creditcardPanel = (
          <div className="text-left biz-individual-state-ok">
            CreditCard Payment Setup
          </div>
        );
      } else {
        creditcardPanel = (
          <div className="text-left biz-individual-state-notok">
            CreditCard Payment Setup
          </div>
        );
      }
    }

    // .04
    let templatePanel;
    if (ak) {
      if (ak.templateSetup) {
        templatePanel = (
          <div className="text-left biz-individual-state-ok">
            Templates (invite/reminder letter, invoice).
          </div>
        );
      } else {
        templatePanel = (
          <div className="text-left biz-individual-state-notok">
            Templates (invite/reminder letter, invoice).
          </div>
        );
      }
    }

    // .05
    let inviteNewMemberPanel;
    inviteNewMemberPanel = (
      <div className="text-left biz-individual-state">
        Invite New Members (on going)
      </div>
    );

    // .06
    let ownershipPanel;

    // .07
    let roleDeligationPanel;
    roleDeligationPanel = (
      <div className="text-left biz-individual-state">
        Role Assignments (on-going)
      </div>
    );

    // .08
    let manageGroupPanel;
    manageGroupPanel = (
      <div className="text-left biz-individual-state">
        Create & Manage Groups (on-going)
      </div>
    );

    // .09
    let defaultPanel;
    if (ak) {
      if (ak.defaultsSetup) {
        defaultPanel = (
          <div className="text-left biz-individual-state-ok">
            Pickup Addresses & Check Pay (Remit) To
          </div>
        );
      } else {
        defaultPanel = (
          <div className="text-left biz-individual-state-notok">
            Pickup Addresses & Check Pay (Remit) To
          </div>
        );
      }
    }

    // .10
    let shippingCostPanel;
    if (ak) {
      if (ak.shippingCostSetup) {
        shippingCostPanel = (
          <div className="text-left biz-individual-state-ok">
            Shipping From Addresses & Check Pay (Remit) To.
          </div>
        );
      } else {
        shippingCostPanel = (
          <div className="text-left biz-individual-state-notok">
            Shipping From Addresses & Check Pay (Remit) To.
          </div>
        );
      }
    }

    // .11
    let initialFinStatePanel;
    if (ak) {
      if (ak.legacyFinStateSetup) {
        initialFinStatePanel = (
          <div className="text-left biz-individual-state-ok">
            Initial Financial State Setup.
          </div>
        );
      } else {
        initialFinStatePanel = (
          <div className="text-left biz-individual-state-notok">
            Initial Financial State Setup.
          </div>
        );
      }
    }

    // .13
    let returnPolicyPanel;
    if (ak) {
      if (ak.returnPolicySetup) {
        returnPolicyPanel = (
          <div className="text-left biz-individual-state-ok">
            Return Policy Setup
          </div>
        );
      } else {
        returnPolicyPanel = (
          <div className="text-left biz-individual-state-notok">
            Return Policy Setup
          </div>
        );
      }
    }

    // .14
    let twilioPanel;
    if (ak) {
      if (ak.twilioSetupFlag) {
        twilioPanel = (
          <div className="text-left biz-individual-state-ok">
            SMS/MMS phone # setup
          </div>
        );
      } else {
        twilioPanel = (
          <div className="text-left biz-individual-state-notok">
            SMS/MMS phone # setup
          </div>
        );
      }
    }

    let openForInternalSetupPanel;
    if (ak && !ak.openForInternalSetupFlag) {
      openForInternalSetupPanel = (
        <div className="text-center internal-setup-open">
          Internal setup prior to opening the business to public{" "}
          <button
            className="btn-open-for-internal"
            type="button"
            onClick={this.openForInternalSetup}
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            Open
          </button>
          <div className="text-center biz-text-warning">
            It is wise to setup as much of the initial states as possible before
            opening <b>{this.props.community.commName}</b> to the public.
          </div>
        </div>
      );
    }

    let closeShopPanel;
    if (ak && ak.openForInternalSetupFlag && ak.openForBusinessFlag) {
      closeShopPanel = (
        <div className="text-center adm-close-shop-pos">
          <div className="row">
            <div className="col-8 text-right">
              <Checkbox
                checked={this.state.enableCloseButton}
                onChange={this.handlecloseCommunityStore}
              />{" "}
              <font color="red">
                <b>Confirm to close the store.</b>
              </font>
            </div>
            <div className="col-4 text-left">
              {this.state.enableCloseButton ? (
                <button
                  onClick={this.closeYourBusiness}
                  className="btn_reg_70_danger"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Close
                </button>
              ) : null}
            </div>
          </div>
        </div>
      );
    }

    let openBusinessPanel;
    if (ak && ak.openForInternalSetupFlag) {
      if (ak.openForBusinessFlag) {
        openBusinessPanel = (
          <div>
            <div className="text-center biz-is-open-for-business">
              {this.props.community.commName} is open for business.
              Congratulation.
            </div>
            {closeShopPanel}
          </div>
        );
      } else {
        if (this.state.showOpenButton) {
          openBusinessPanel = (
            <div>
              {" "}
              <div className="test-center biz-open-text">
                Click to open your business{" "}
                <button
                  className="btn-open-the-biz"
                  type="button"
                  onClick={this.openTheBusiness}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <img
                    src={cutRibbon}
                    alt="cut-ribbon"
                    className="baanda-cut-ribbon-img"
                  />
                </button>
              </div>
              <div className="text-center biz-text-warning">
                It is wise to setup as many of the initial states as possible
                before opening <b>{this.props.community.commName}</b> to the
                public.
              </div>
            </div>
          );
        }
      }
    }

    let commStoreForCoopOpenPanel;
    if (this.props.community.communityType === "coop") {
      commStoreForCoopOpenPanel = (
        <div className="text-center enable-coop-comm-store">
          <Checkbox
            checked={this.state.enableCommunityStore}
            onChange={this.handleEnableCommunityStore}
          />{" "}
          Enable community's store for retail &nbsp;&nbsp;
          <button
            onClick={this.handleCentralStore}
            className="btn_reg_70"
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Update
          </button>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div className="text-center business-open-box">
        <div className="biz-open-header">
          Community / Business Readiness State &nbsp;{subModalButton}
        </div>
        {financialYearPanel}
        {subscriptionPanel}
        {creditcardPanel}
        {templatePanel}
        {inviteNewMemberPanel}
        {ownershipPanel}
        {roleDeligationPanel}
        {manageGroupPanel}
        {defaultPanel}
        {shippingCostPanel}
        {initialFinStatePanel}
        {returnPolicyPanel}
        {twilioPanel}
        {commStoreForCoopOpenPanel}

        {openForInternalSetupPanel}
        {openBusinessPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center biz-open-message-err"
              : "text-center biz-open-message"
          }
        >
          {this.state.message}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

OpenForBusiness.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(OpenForBusiness));
