import React, { Component } from "react";

import axios from "axios";

import "./ShowNotification.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getMsgOnSynapse = "/routes/interact/getMsgOnSynapse?";
// const getAMsgFrompool = "/routes/interact/getAMsgFromPool?";

class ShowNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deviceSize: "",

      linkedPoolMsgId: 0,
      prevSynapseId: 0,
      thisSynapseId: 0,
      messageId: 0,

      retpool: null,
      mailSynapse: null,

      errMsg: '',
      errFlag: false
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setState({
      linkedPoolMsgId: this.props.showPost.linkedPoolMsgId,
      prevSynapseId: this.props.showPost.prevSynapseId,
      thisSynapseId: this.props.showPost.synapseId,
      messageIs: this.props.showPost.messageId,
      deviceSize,
    });

    await this.getPoolMsg();
  };

  getPoolMsg = async () => {
    // console.log('in getPoolMsg');  
    let params =
      "linkedPoolMsgId=" +
      this.state.linkedPoolMsgId +
      "&synapseId=" +
      this.state.prevSynapseId +
      "&thisSynapseId=" +
      this.state.thisSynapseId + "&messageId=" + this.state.messageId;
    let url = baandaServer + getMsgOnSynapse + params;
    // console.log('url:', url);
    try {
      let retmsg = await axios.get(url);
      // console.log("retmsg:", retmsg);
      if (retmsg.data.status === "success") {
        await this.setState({
          retpool: retmsg.data.Msg.mailPool,
          mailSynapse: retmsg.data.Msg.mailSynapse,
        });
      } else {
        this.setState({
          errMsg: retmsg.data.Msg,
          errFlag: false
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: false
      });
    }
  };

  returnToConverse = () => {
    this.props.returnToCaller();
  };
  
  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ShowNotification...');

    let buttonPanel;

    buttonPanel = (
        <button
        className="btn-notification-return"
        onClick={this.returnToConverse}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Return
      </button>
    )
    let showNotificationPanel = (
      <div className="notification-box">
        <div className="text-center noti-head">Notification</div>
         {this.state.retpool && this.state.retpool.messageDoc? <div className="noti-message">{this.state.retpool.messageDoc}</div> : <div className="noti-message">No message attached</div>}
        {buttonPanel}
      </div>
    );
    return (
      <div>
         {showNotificationPanel}
      </div>
    );
  }
}

export default ShowNotification;
