import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import { sortArrayOfObjects } from "../../../../../utils/sortArrayOfObjects";

import "./ChangeOrder.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveChangeOrder = "/routes/projects/saveChangeOrder";
const getATask = "/routes/task/getATask?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "0000000000";

class ChangeOrder extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newCONo: 1,

      lineItems: [],

      newLineItems: [],
      newCoComment: "",
      newDaysToFinishTheTask: 0,
      newVatAmount: 0,

      lineItemNo: 1,
      lineItemCost: 0,
      lineItemDescription: "",
      vatAmount: 0,

      confirmToSend: false,
      notifyTarget: false,

      lineItemMilestone: false,
      daysToFinishTheTask: 0,

      changeOrder: [],

      coComment: "",

      COTotal: 0,

      nextButtonFlag: false,
      prevButtonFlag: false,
      newButtonFlag: false,
      opsFlag: false,

      theTask: null,
    };
  }

  componentDidMount = async () => {
    let last = this.props.theTask.changeOrder.length;
    // console.log("last:", last);

    let changeOrder = this.props.theTask.changeOrder;
    // console.log("changeOrder:", changeOrder);
    let newCONo = 1;
    let lineItems = [];
    let daysToFinishTheTask = "";
    let coComment = "";
    let vatAmount = 0;
    let lineItemNo = 1;

    if (last > 0) {
      if (changeOrder[last - 1].status === "wip") {
        newCONo = last;
        lineItems = changeOrder[last - 1].lineItem;
        daysToFinishTheTask = changeOrder[last - 1].additionalDays;
        coComment = changeOrder[last - 1].comment;
        vatAmount = changeOrder[last - 1].estimatedTaxAmount;
        lineItemNo = changeOrder[last - 1].lineItem.length + 1;
      } else {
        newCONo = last + 1;
      }

      // lineItemNo = changeOrder[last - 1].lineItem.length + 1;
      // console.log("lineItemNo:", lineItemNo);
    }

    let prevButtonFlag = false;
    if (newCONo > 1) prevButtonFlag = true;

    await this.setState({
      theTask: this.props.theTask,
      newCONo,
      changeOrder,
      lineItems,
      daysToFinishTheTask,
      coComment,
      vatAmount,
      lineItemNo,
      prevButtonFlag,
      opsFlag: true,
    });

    await this.calcCOTotal();
  };

  openAlertModal = () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  returnToQuote = () => {
    this.props.returnToCaller();
  };

  handleLineItemDelete = async (obj) => {
    // console.log("obj:", obj);
    let newlines = [];
    this.state.lineItems.forEach((elm) => {
      if (elm.seqno !== obj.seqno) newlines.push(elm);
    });
    let nidx = 1;
    newlines.forEach((nlk) => {
      nlk.seqno = nidx;
      nidx++;
    });

    await this.setState({
      lineItems: newlines,
      lineItemNo: newlines.length + 1,
      lineItemDescription: "",
      lineItemCost: 0.0,
    });

    await this.calcCOTotal();
  };

  checkIfLineIsValid = async () => {
    let isValid = "success",
      msg = "";
    this.state.lineItems.forEach((li) => {
      if (li.lineItemNo === this.state.lineItemNo) {
        isValid = "error";
        msg = "Duplicate line number not allowed";
      }
    });

    if (this.state.lineItemDescription === "") {
      isValid = "error";
      msg += " Must have a description.";
    }

    // console.log("this.state.lineItemCost:", this.state.lineItemCost);
    if (parseFloat(this.state.lineItemCost) === 0) {
      isValid = "error";
      msg += " Must have a non-zero cost.";
    }

    return { status: isValid, Msg: msg };
  };

  addLineItem = async () => {
    let isValid = await this.checkIfLineIsValid();
    if (isValid.status === "success") {
      let nli = [...this.state.lineItems];
      // console.log("0. nli:", nli);
      let lineObj = {
        seqno: parseFloat(this.state.lineItemNo),
        cost: parseFloat(this.state.lineItemCost),
        description: this.state.lineItemDescription,
        milestone: this.state.lineItemMilestone,
      };
      nli.push(lineObj);
      // console.log("1 nli:", nli);
      let lisort = await sortArrayOfObjects(nli, "seqno", "dsc");
      // console.log("2. lisort:", lisort);

      let lines = [];
      let index = 1;
      lisort.forEach((obj) => {
        obj.seqno = index;
        lines.push(obj);
        index++;
      });

      let newline = nli.length + 1;
      this.setState({
        lineItems: lines,
        errMsg: "",
        errFlag: false,
        lineItemNo: newline,
        lineItemDescription: "",
        lineItemCost: 0.0,
        lineItemMilestone: false,
      });
    } else {
      this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }

    await this.calcCOTotal();
  };

  onChangeLineNo = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 1) {
      let tmp = parseFloat(value).toFixed(1);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        message: "",
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        message: "",
      });
    }
  };

  onChangeCurrency = async (e) => {
    let value = e.target.value;
    let name = [e.target.name][0];

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        message: "",
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        message: "",
      });
    }

    if (name === "vatAmount") await this.calcCOTotal();
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  // Calculate total of CO entries.
  calcCOTotal = async () => {
    let tot = 0;
    this.state.lineItems.forEach((obj) => {
      tot += parseFloat(obj.cost);
    });

    if (this.state.vatAmount > 0 || this.state.vatAmount.length > 0)
      tot += parseFloat(this.state.vatAmount);

    this.setState({
      COTotal: parseFloat(tot),
    });
  };

  handleConfirm = async () => {
    await this.setState((prevstate) => ({
      confirmToSend: !prevstate.confirmToSend,
    }));
  };

  handleNotify = async () => {
    await this.setState((prevstate) => ({
      notifyTarget: !prevstate.notifyTarget,
    }));
  };

  handleMilestone = async () => {
    await this.setState((prevstate) => ({
      lineItemMilestone: !prevstate.lineItemMilestone,
    }));
  };

  packageDataForSave = async (sta) => {
    let litems = [];
    this.state.lineItems.forEach((obj) => {
      let ltobj = {
        seqno: obj.seqno,
        milestone: obj.milestone,
        description: obj.description,
        cost: obj.cost,
      };
      litems.push(ltobj);
    });

    let newCOObj = {
      changeOrderNo: this.state.newCONo,
      lastUpdateDate: new Date(),
      status: sta,
      lineItem: litems,
      additionalDays: parseFloat(this.state.daysToFinishTheTask),
      comment: this.state.coComment,
      estimatedTaxAmount: this.state.vatAmount,
    };

    let changeOrder = [];
    if (this.state.changeOrder.length === 0) {
      changeOrder.push(newCOObj);
    } else {
      let brandNewFlag = true;
      this.state.changeOrder.forEach((coj) => {
        if (this.state.newCONo === coj.changeOrderNo) {
          brandNewFlag = false;
          changeOrder.push(newCOObj);
        } else {
          changeOrder.push(coj);
        }
      });
      if (brandNewFlag) changeOrder.push(newCOObj);
    }

    let newTaskNoteObj = {
      note:
        "Sending an updated specification change order #: " +
        this.state.newCONo +
        ". Please confirm.",
      party: "Business",
      noteById: this.props.auth.user.baandaId,
      byName: this.props.auth.user.name,
      byEmail: this.props.auth.user.email,
      onDate: new Date(),
    };

    let input = {
      taskId: this.props.theTask.taskId,
      changeOrderNo: this.state.newCONo,
      changeOrder,
      createdByBaandaId: this.props.auth.user.baandaId,
      notifyTarget: this.state.notifyTarget,
      newTaskNoteObj,
      signBoardShort: this.props.community.signBoardShort,
      signBoardLong: this.props.community.signBoardLong,
      clientProgram: "ChangeOrder",
      clientFunction: "packageDataForSave",
      coStatus: "",
      itemName: this.state.theTask.itemName,
      targetContact: this.props.theTask.targetContact,
    };

    return input;
  };

  getATask = async () => {
    let params = "taskId=" + this.props.theTask.taskId;
    let url = baandaServer + getATask + params;
    // console.log("url:", url);
    try {
      let tskret = await axios.get(url);
      // console.log("tskret.data:", tskret.data);
      if (tskret.data.status === "success") {
        if (tskret.data.Msg) {
          this.setState({
            theTask: tskret.data.Msg,
            // errMsg: "",
            // errFlag: false,
          });
        } else {
          this.setState({
            errMsg:
              "No tasks found for the taskId = " + this.state.theTask.taskId,
            errFlag: false,
          });
        }
      } else {
        this.setState({
          tasks: [],
          errMsg: tskret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        tasks: [],
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  saveAsWIP = async () => {
    let input = await this.packageDataForSave("wip");
    input.coStatus = "wip";
    // console.log("saveAsWIP input:", input);
    if (this.state.lineItems.length > 0) {
      await this.updateDatabase(input);
    } else {
      this.setState({
        errMsg: "Can't save an empty CO without any line item.",
        errFlag: true
      })
    }
  };

  sendReadyCO = async () => {
    // console.log("in sendReadyCO");
    let input = await this.packageDataForSave("ready");
    input.sendReadyFlag = true;
    input.coStatus = "ready";
    // console.log("sendReadyCO input:", input);
    await this.updateDatabase(input);
  };

  updateDatabase = async (input) => {
    let url = baandaServer + saveChangeOrder;
    // console.log("url:", url, " input:", input);
    try {
      let quret = await axios.post(url, input);
      if (quret.data.status === "success") {
        this.setState({
          errMsg: "Successfully updated",
          errFlag: false,
        });
        await this.getATask();
      } else {
        this.setState({
          errMsg: quret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  setCurrentCO = async (cco, dir) => {
    // console.log("cco:", cco, " dir:", dir);

    let currCONo = this.state.newCONo;
    if (dir === "prev") currCONo--;
    if (dir === "next") currCONo++;
    let currChangeOrder = null;
    cco.changeOrder.forEach((obj) => {
      if (obj.changeOrderNo === currCONo) currChangeOrder = obj;
    });
    let decisionFlag = false;
    if (currChangeOrder.status === "ready") decisionFlag = true;
    let prevButtonFlag = false;
    let nextButtonFlag = false;
    if (cco.changeOrder.length > 1) {
      if (currCONo > 1) prevButtonFlag = true;
      if (currCONo >= cco.changeOrder.length) nextButtonFlag = true;
    }

    let newButtonFlag = true;
    let opsFlag = false;
    if (currChangeOrder.status !== "locked") opsFlag = true;

    await this.setState({
      newCONo: currCONo,
      prevButtonFlag,
      nextButtonFlag,
      decisionFlag,
      currChangeOrder,
      changeOrder: cco.changeOrder,
      theTask: cco,
      lineItems: currChangeOrder.lineItem,
      lineItemNo: currChangeOrder.lineItem.length + 1,
      vatAmount: currChangeOrder.estimatedTaxAmount,
      daysToFinishTheTask: currChangeOrder.additionalDays,
      newButtonFlag,
      opsFlag,
    });

    await this.calcCOTotal();
  };

  prevCO = async () => {
    if (this.state.newCONo === this.state.theTask.changeOrder.length + 1) {
      let newLineItems = this.state.lineItems;
      let newCoComment = this.state.coComment;
      let newDaysToFinishTheTask = this.state.daysToFinishTheTask;
      let newVatAmount = this.state.vatAmount;
      let newNewCONo = this.state.newCONo;
      this.setState({
        newLineItems,
        newCoComment,
        newDaysToFinishTheTask,
        newVatAmount,
        newNewCONo,
      });
    }
    await this.setCurrentCO(this.state.theTask, "prev");
  };

  nextCO = async () => {
    await this.setCurrentCO(this.state.theTask, "next");
  };

  setNewCO = async () => {
    let lineItems = this.state.newLineItems;
    let coComment = this.state.newCoComment;
    let daysToFinishTheTask = this.state.newDaysToFinishTheTask;
    let vatAmount = this.state.newVatAmount;
    let lineItemNo = lineItems.length + 1;
    let newCONo = this.state.newNewCONo;
    await this.setState({
      lineItems,
      coComment,
      daysToFinishTheTask,
      vatAmount,
      lineItemNo,
      newCONo,
    });

    await this.calcCOTotal();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ChangeOrder...');

    let exitButton = (
      <button
        onClick={this.returnToQuote}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn-seller-co-exit"
      >
        X
      </button>
    );

    let helpButton = (
      <button
        className="btn-co-seller-modal"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let addLineButton = (
      <button
        onClick={this.addLineItem}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn-quote-lineitem-plus"
      >
        <i className="fa fa-plus" />
      </button>
    );

    let prevButton;
    if (this.state.prevButtonFlag) {
      prevButton = (
        <button
          onClick={this.prevCO}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          className="btn-sell-co-next-prev"
        >
          <i className="fa fa-step-backward" />
        </button>
      );
    }

    let newButton;
    if (this.state.newButtonFlag) {
      newButton = (
        <button
          onClick={this.setNewCO}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          className="btn-cust-co-new"
        >
          New
        </button>
      );
    }

    let nextButton;
    if (this.state.nextButtonFlag) {
      nextButton = (
        <button
          onClick={this.nextCO}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          className="btn-sell-co-next-prev"
        >
          <i className="fa fa-step-forward" />
        </button>
      );
    }

    let buttonPanel;
    if (this.state.opsFlag) {
      buttonPanel = (
        <div className="row tsk-co-button-panel-pos">
          <div className="col-3 text-right">
            <button
              onClick={this.saveAsWIP}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              className="btn-quote-draft"
            >
              Draft - WIP
            </button>
          </div>
          <div className="col-6 text-center quote-confirm-to-send">
            <Checkbox
              checked={this.state.confirmToSend}
              onChange={this.handleConfirm}
            />
            &nbsp;&nbsp;Confirm to Send&nbsp;&nbsp;&nbsp;
            {this.state.confirmToSend ? (
              <Checkbox
                checked={this.state.notifyTarget}
                onChange={this.handleNotify}
              />
            ) : null}
            {this.state.confirmToSend ? "  Text Notify?" : null}
          </div>
          <div className="col-3 text-left">
            {this.state.confirmToSend ? (
              <button
                onClick={this.sendReadyCO}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
                className="btn-quote-send"
              >
                Done - Send
              </button>
            ) : null}
          </div>
        </div>
      );
    }

    let headerPanel;
    headerPanel = (
      <div className="text-center tsk-co-header">
        <div className="row">
          <div className="col-6 text-center">
            Change Order (CO# : <font color="blue">{this.state.newCONo}</font> )
          </div>
          <div className="col-6 text-center">
            {" "}
            {exitButton} {helpButton} {prevButton} {nextButton} {newButton}{" "}
          </div>
        </div>
      </div>
    );

    let lineItemTopPanel;

    lineItemTopPanel = (
      <div className="co-quote-top-box">
        <div className="text-center quote-line-item-head">Add Line Items</div>
        <div className="row quote-line-item-row">
          <div className="col-2 text-right quote-line-item-lbl">Line #</div>
          <div className="col-2 text-left">
            <input
              name="lineItemNo"
              type="number"
              value={this.state.lineItemNo}
              onChange={this.onChangeLineNo}
              className="quote-lineitem-no"
              step=".1"
              placeholder="0"
              autoComplete="off"
              spellCheck="false"
            />
          </div>
          <div className="col-2 text-right quote-line-item-lbl">Cost</div>
          <div className="col-2 text-left">
            <input
              name="lineItemCost"
              type="number"
              value={this.state.lineItemCost}
              onChange={this.onChangeCurrency}
              className="quote-lineitem-cost"
              step=".1"
              placeholder="0"
              autoComplete="off"
              spellCheck="false"
            />
          </div>
          <div className="col-2 text-left quote-line-item-lbl">
            &nbsp;
            <Checkbox
              checked={this.state.lineItemMilestone}
              onChange={this.handleMilestone}
            />
            &nbsp;&nbsp;Milestone
          </div>
          <div className="col-2 text-left quote-line-item-lbl">
            {addLineButton}
          </div>
        </div>

        <div className="row quote-line-item-row">
          <div className="col-2 text-right quote-line-item-lbl">Describe</div>
          <div className="col-10 text-left">
            <textarea
              name="lineItemDescription"
              maxLength="1000"
              placeholder="Describe the line ..."
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="quote-line-item-desc"
              onChange={this.onChange}
              value={this.state.lineItemDescription}
              required
            />
          </div>
        </div>
      </div>
    );

    let commentPanel;
    commentPanel = (
      <div className="row tsk-co-comment-pos">
        <div className="col-2 text-right quote-line-item-lbl">Comment</div>
        <div className="col-10 text-left">
          <textarea
            name="coComment"
            maxLength="100"
            placeholder="Comment for the CO..."
            rows="2"
            wrap="hard"
            spellCheck="true"
            className="tsk-co-comment"
            onChange={this.onChange}
            value={this.state.coComment}
            required
          />
        </div>
      </div>
    );

    let lineItemsListPanel;
    let bool = true;
    lineItemsListPanel = (
      <div>
        <div className="row quote-list-header">
          <div className="col-2 text-center quote-list-head-lbl"># Ops</div>
          <div className="col-8 text-left quote-list-head-lbl">Description</div>
          <div className="col-2 text-center quote-list-head-lbl">Amount</div>
        </div>
        <div className="co-quote-list-box">
          {this.state.lineItems.map((obj, i) => {
            // console.log('874 obj:', obj);
            return (
              <div key={i}>
                <div
                  className={
                    bool
                      ? "row row-col-item-list-dr"
                      : "row row-col-item-list-lt"
                  }
                >
                  <div className="col-2 text-left quote-list-item-lbl">
                    <button
                      onClick={() => this.handleLineItemDelete(obj)}
                      className="btn-quote-line-item-del"
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      <i className="fa fa-trash" />
                    </button>
                    &nbsp;&nbsp;&nbsp;&nbsp;{obj.seqno}.&nbsp;
                  </div>
                  <div className="col-8 text-left quote-list-item-lbl-des">
                    {obj.description}.{" "}
                    {obj.milestone ? <i className="fa fa-star" /> : null}
                  </div>
                  <div className="col-2 text-right quote-list-item-lbl-cost">
                    {this.commaFormattedCurrency(obj.cost)}
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let bottomPanel;
    bottomPanel = (
      <div className="tsk-co-bottom-panel-pos">
        <div className="row">
          <div className="col-2 text-right co-tax-lbl">Tax</div>
          <div className="col-4 text-left">
            <input
              name="vatAmount"
              type="number"
              value={this.state.vatAmount}
              onChange={this.onChangeCurrency}
              className="co-quote-vat-amount"
              step=".1"
              placeholder="0"
              autoComplete="off"
              spellCheck="false"
            />
          </div>

          <div className="col-6 text-center co-tax-lbl">
            CO will require additional &nbsp;
            <input
              name="daysToFinishTheTask"
              type="number"
              value={this.state.daysToFinishTheTask}
              onChange={this.onChangeLineNo}
              className="quote-contingency-delay"
              step=".1"
              placeholder="0"
              autoComplete="off"
              spellCheck="false"
            />
            &nbsp;business days.
          </div>
        </div>
        <div className="row co-total-pos">
          <div className="col-2 text-right co-tax-lbl">CO Total</div>
          <div className="col-4 text-left co-tax-txt">
            $ {this.commaFormattedCurrency(this.state.COTotal)}
          </div>
          <div className="col-6">&nbsp;</div>
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="tsk-change-order-box">
        {headerPanel}
        {lineItemTopPanel}
        {lineItemsListPanel}
        {commentPanel}
        {bottomPanel}
        {buttonPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center task-co-msg-err"
              : "text-center task-co-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ChangeOrder.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangeOrder)
);
