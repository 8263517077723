import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./ReturnSetup.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveReturnSetup = "/routes/dashboard/saveReturnSetup";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1115300000";

class ReturnSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      clauseText: "",
      withinDays: 0,
      decisionDecider: "business",
      message: "",

      allowReturnPostage: false
    };
  }

  componentDidMount = async () => {
    await this.setState({
      clauseText: this.props.community.returnTraits.clauseText,
      withinDays: this.props.community.returnTraits.withinDays,
      decider: this.props.community.returnTraits.decider,
      allowReturnPostage: this.props.community.returnTraits.allowReturnPostage,
    });
  };

  openAlertModal = async () => {
    // console.log("in openAlertModal info:", readMeCode);
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDeciderType = async (e) => {
    this.setState({
      decisionDecider: e.target.value,
    });
  };

  onChangeNumberOFDays = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleReturnPolicySave = async () => {
    let coopStoreId = 0;
    if ( this.props.store ) coopStoreId = this.props.store.coopStoreId;
    let input = {
      communityId: this.props.communityId,
      coopStoreId,
      clauseText: this.state.clauseText,
      withinDays: this.state.withinDays,
      decider: this.state.decisionDecider,
      allowReturnPostage: this.state.allowReturnPostage
    };
    let url = baandaServer + saveReturnSetup;
    try {
      let retret = await axios.post(url, input);
      // console.log('retret:', retret);
      if (retret.data.status === "success") {
       this.setState({
          message: "Successfully Updated",
        });
      } else {
       this.setState({
          message: retret.data.Msg,
        });
      }
    } catch (err) {
      console.log("handleReturnPolicySave err:", err.message);
     this.setState({
        message: err.message,
      });
    }
  };

  handleAllowRetPostage = async () => {
   this.setState((prevstate) => ({
      allowReturnPostage: !prevstate.allowReturnPostage,
    }));
  };

  render() {
    // console.log("this.state:", this.state);
    // console.log("this.props:", this.props);
    console.log('ReturnSetup...');

    let buttonPanel = (
      <div>
        <button
          className="btn_reg_50"
          type="button"
          onClick={this.handleReturnPolicySave}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>
        &nbsp;&nbsp;
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
      </div>
    );

    let retSetupPanel;
    retSetupPanel = (
      <div>
        <div className="row return-setup-row">
          <div className="col-3 admin-return-setup-lbl text-right">Rules</div>
          <div className="col-9 text-left">
            <textarea
              name="clauseText"
              maxLength="1000"
              placeholder="Describe return rules and policy in 1000 words or less."
              rows="4"
              wrap="hard"
              spellCheck="true"
              className="return-clause_textarea"
              onChange={this.onChange}
              value={this.state.clauseText}
              required
            />
          </div>
        </div>
        <div className="row return-setup-row">
          <div className="col-3 admin-return-setup-lbl text-right">&nbsp;</div>
          <div className="col-9 text-left admin-ret-char-msg">
            (Max Char count = 1000. Present {this.state.clauseText.length})
          </div>
        </div>
        <div className="row return-setup-row">
          <div className="col-3 admin-return-setup-lbl text-right">
            Return by
          </div>
          <div className="col-4 text-left">
            <input
              name="withinDays"
              type="number"
              value={this.state.withinDays}
              onChange={this.onChangeNumberOFDays}
              // size="10"
              className="return-by-month"
              step="1"
              placeholder="0"
              autoComplete="off"
              spellCheck="false"
            />
            &nbsp;days.
          </div>
          <div className="col-5 admin-return-setup-lbl text-center">
          <Checkbox
              checked={this.state.allowReturnPostage}
              onChange={this.handleAllowRetPostage}
            /> Allow Return Postage
          </div>
        </div>
        <div className="row return-setup-row">
          <div className="col text-center radio-font-style">
            <strong>Return decision&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="business"
                  checked={this.state.decisionDecider === "business"}
                  onChange={this.handleDeciderType}
                />{" "}
                Business
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="noquestionsasked"
                  checked={this.state.decisionDecider === "noquestionsasked"}
                  onChange={this.handleDeciderType}
                />{" "}
                No questions
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="noreturns"
                  checked={this.state.decisionDecider === "noreturns"}
                  onChange={this.handleDeciderType}
                />{" "}
                No Returns
              </label>
            </div>
          </div>
        </div> 
      </div>
    );

    let outputPanel = (
      <div className="admin-return-box">
        <div className="admin-ret-setup-header">Return Policy Setup</div>
        {retSetupPanel}
        {buttonPanel}
        <div className="text-center admin-ret-setup-msg">
          {this.state.message}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ReturnSetup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ReturnSetup));
