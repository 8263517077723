import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import { Redirect } from "react-router-dom";

import queryString from "query-string";

import ModalContainer from "../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../actions/modalActions";
import "../../../modal/css/localModal.css";
import "../../../modal/css/template.css";

// Navigation imports
import MarketMain from "../market/MarketMain";
import BazzarFramework from "../../../architecture/BazzarFramework";

import "./Dashboard.css";

import noImageAvailable from "../market/image/notavailable.png";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAccessList = "/routes/dashboard/getAccessList?"; // This is a GET
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";

const getIfCustomerOfACommunity =
  "/routes/architecture/getIfCustomerOfACommunity?";

const readMeCode = "1100000000";

class Dashboard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      date: this.formatDate(),
      dashMsg: "Please select the item you would like to engage in.",
      dashMsgFlag: true, // Meaning, no error or empty lists (false to be displayed in red)
      accessList: [],
      list: [],

      commName: "",
      commCaption: "",
      communityId: 0,
      role: "",
      isCustomer: false,
      intent: "business",
      focus: "catalog",

      // active panel flags
      accessListPanelFlag: false,
      marketFlag: false,
      gotToLobbyFlag: false,

      deviceSize: "",

      showStoreFlag: false,
      showMyCommunities: true,
      comingFrom: "accesslist",

      targetCommId: 0,
      community: null,
      opstype: "",
    };

    this.doThisAtFirst();
  }

  openAlertModal = () => {
    // console.log('inAlertModal');
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  formatDate = () => {
    var date = new Date();
    var yyyy = date.getFullYear();
    var dd = date.getDate();
    var mm = date.getMonth() + 1;

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    var cur_day = yyyy + "-" + mm + "-" + dd;

    return cur_day;
  };

  // to be filled out if needed
  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    const dvalues = queryString.parse(this.props.location.search);
    // console.log("1. dashboard onmount dvalues:", dvalues);
    if (dvalues && dvalues.opstype === "store") {
      this.setState({
        targetCommId: dvalues.targetCommId,
      });
      await this.checkIsCustomer(parseFloat(dvalues.targetCommId));
      await this.getACommunityInfo(parseFloat(dvalues.targetCommId));
      let obj = {
        comingFrom: "bazzar",
        commName: this.state.community.commName,
        commCaption: this.state.community.commCaption,
        communityId: parseFloat(dvalues.targetCommId),
        isCustomer: this.state.isCustomer,
        opstype: dvalues.opstype,
      };
      // console.log('obj:', obj);
      this.returnFromBazzar(obj);
    } else await this.loadList();

    await this.setState({
      deviceSize,
    });
  };

  getACommunityInfo = async (commId) => {
    try {
      let param = "communityId=" + commId;
      let url = baandaServer + getCommunityInfo + param;
      // console.log('url:', url);
      let comret = await axios.get(url);
      if (comret.data.status === "success") {
        this.setState({
          community: comret.data.Msg,
        });
      } else {
        // console.log("Error : ", comret.data.Msg);
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  // async UNSAFE_componentWillUnmount() {
  doThisAtFirst = async () => {
    // console.log("I am in component will mount");
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }
    this.props.history.goForward();
  };

  checkIsCustomer = async (comId) => {
    let param =
      "communityId=" + comId + "&baandaId=" + this.props.auth.user.baandaId;
    let url = baandaServer + getIfCustomerOfACommunity + param;
    // console.log("checkIsCustomer url:", url);
    try {
      let isCust = await axios.get(url);
      // console.log("----------isCust.data:", isCust.data);
      if (isCust.data.status === "success") {
        let isCustomer = isCust.data.Msg;
        this.setState({
          isCustomer,
        });
      }
    } catch (err) {
      console.log("checkIsCustomer err:", err.message);
    }
  };

  loadList = async () => {
    let url =
      baandaServer +
      getAccessList +
      "baandaid=" +
      this.props.auth.user.baandaId;
    // console.log("loadList url:", url);
    try {
      let retData = await axios.get(url);
      // console.log("retData:", retData);
      if (retData.data.status === "success") {
        if (retData.data.Msg.length === 0) {
          await this.setState({
            showMyCommunities: false,
          });
          this.goToPublicStores();
        } else {
          this.setState({
            accessListPanelFlag: true,
            accessList: retData.data.Msg,
          });
        }
      } else {
        this.setState({
          dashMsg: retData.data.Msg,
          dashMsgFlag: true,
        });
      }
    } catch (err) {
      // console.log("err:", err.message);
      this.setState({
        dashMsg: err.message,
        dashMsgFlag: true,
      });
    }
  };

  // Handle navigation based on selection of intent and focus.
  handleSelectedCommunity = async (
    commName,
    commCaption,
    communityId,
    role,
    intent,
    focus
  ) => {
    // console.log("intent:", intent, " focus:", focus, " role:", role);
    await this.checkIsCustomer(communityId);
    // if (
    //   intent.toLowerCase() === "business" &&
    //   focus.toLowerCase() === "catalog"
    // ) {
    await this.setState({
      accessListPanelFlag: false,
      marketFlag: true,
      commName: commName,
      communityId,
      commCaption,
      role: role,
      intent: intent,
      focus: focus,
    });
    // }
  };

  returnToDashboard = async (msg) => {
    // console.log("----returnToDashboard msg:", msg);
    let showMyCommunities, showStoreFlag;
    if (msg === "accesslist") {
      showMyCommunities = true;
      showStoreFlag = false;
    }
    if (msg === "bazzar") {
      showMyCommunities = false;
      showStoreFlag = true;
    }
    // if (msg === "fromProject") {
    //   showMyCommunities = true;
    //   showStoreFlag = false;
    //   await this.loadList();
    // }

    await this.setState({
      accessListPanelFlag: true,
      marketFlag: false,
      showMyCommunities,
      showStoreFlag,
    });
  };

  goToLobby = async () => {
    // this.props.history.push("/lobby");
    await this.setState({
      accessListPanelFlag: false,
      marketFlag: false,
      gotToLobbyFlag: true,
    });
  };

  goToPublicStores = async () => {
    await this.setState({
      showStoreFlag: true,
      showMyCommunities: false,
      comingFrom: "bazzar",
    });
  };

  exitPublicStores = async () => {
    await this.setState({
      showStoreFlag: false,
      showMyCommunities: true,
      comingFrom: "accesslist",
    });
  };

  returnFromBazzar = async (obj) => {
    // console.log("returnFromBazzar obj:", obj);
    let role = "prospect";
    if (obj.isCustomer) role = "customer";
    await this.setState({
      commName: obj.commName,
      commCaption: obj.commCaption,
      communityId: obj.communityId,
      isCustomer: obj.isCustomer,
      role,
      opstype: obj.opstype,
      // role: obj.role,
      // intent: "business",
      // focus: "catalog",
      marketFlag: true,
      showStoreFlag: false,
      showMyCommunities: false,
      accessListPanelFlag: false,
      comingFrom: obj.comingFrom,
    });
  };

  render() {
    // console.log("Dashboard this.props: ", this.props);
    // console.log("Dashboard this.state:", this.state);
    console.log("this.state.list:");

    let noImgItemSelPanel;
    noImgItemSelPanel = (
      <div>
        <img src={noImageAvailable} alt="pic" className="dash-img-view" />
      </div>
    );

    let publicStoreButton = (
      <button
        // className="btn_public-store"
        className="btn_reg_90"
        type="button"
        style={{
          cursor: this.state.disabled ? "default" : "pointer",
        }}
        onClick={this.goToPublicStores}
      >
        {this.state.deviceSize === "big" ? (
          "Stores"
        ) : (
          <div>
            <i className="fas fa-shopping-basket" />{" "}
            <i className="fas fa-shopping-basket" />{" "}
          </div>
        )}
      </button>
    );

    let btnmycomm;
    if (this.state.deviceSize === "small") {
      btnmycomm = "Works";
    } else {
      btnmycomm = "Workplace";
    }

    let myCommunityButton;
    if (this.state.accessList.length > 0) {
      myCommunityButton = (
        <button
          className="btn_reg_80"
          // className="btn_my-community"
          type="button"
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
          onClick={this.exitPublicStores}
        >
          {this.state.deviceSize === "big" ? (
            btnmycomm
          ) : (
            <div>
              <i className="fas fa-user-ninja" />
              &nbsp;
              <i className="fas fa-user-ninja" />
            </div>
          )}
        </button>
      );
    }

    // The  formating of columns should be controlled by deviceSize. Now it is for browserr only
    let storePanel;
    if (this.state.showStoreFlag) {
      storePanel = (
        <div className="row">
          <div className="col">
            <div className="text-center">
              <div>
                <BazzarFramework
                  caller="engage"
                  baandaName={this.props.auth.user.name}
                  returnToCaller={(obj) => this.returnFromBazzar(obj)}
                  targetCommId={this.state.targetCommId}
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    let go;
    if (this.state.deviceSize === "big") {
      go = "Enter";
    } else {
      go = "";
    }

    // Engage Landing Module list.
    let itemListPanel;
    // colorFlag toggles between two colors of the list to display
    let colorflag = true;
    if (this.state.showMyCommunities) {
      itemListPanel = (
        <div>
          {/* <small className="text-center">
            <i>Click 'Go' to enter your community...</i>
          </small> */}
          <div className="header-spacing" />
          <div className="row">
            <div className="col-11 text-center selection_header_font">
              Your Communities
            </div>
            <div className="col-1">&nbsp;</div>
          </div>
          <br />
          <div className="fixedsize-dash-x">
            {this.state.accessList.map((item, i) => (
              <div key={i}>
                <div className={`${colorflag ? "dark-row" : "light-row"}`}>
                  <div className="row text-left">
                    <div className="col-1">
                      {item.imageLocation === "" ||
                      item.imageLocation === null ? (
                        noImgItemSelPanel
                      ) : (
                        <img
                          src={item.imageLocation}
                          alt="pic"
                          className="dash-img-viewx"
                        />
                      )}
                    </div>
                    <div className="col-9 caption_bold">
                      {this.state.deviceSize === "small"
                        ? item.commCaption.substring(0, 26) + "..."
                        : item.commCaption}
                    </div>

                    <div className="col-2 dash-enter-btn-pos">
                      <button
                        className="btn_reg_70"
                        type="button"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                        onClick={() =>
                          this.handleSelectedCommunity(
                            item.commName,
                            item.commCaption,
                            item.communityId,
                            item.role
                            // item.intent,
                            // item.intentFocus
                          )
                        }
                      >
                        {go}&nbsp;
                        <i className="fas fa-greater-than" />
                      </button>
                    </div>
                  </div>
                </div>

                {(colorflag = !colorflag)}
              </div>
            ))}
          </div>
        </div>
      );
    }

    let engageLandingPanel;

    let market;
    if (this.state.deviceSize === "big") {
      market = "The Bazaar";
    } else {
      market = "Bazaar";
    }

    engageLandingPanel = (
      <div>
        <div className="row page-top">
          <div className="col-4 access_list_header">{market}</div>
          <div className="col-8">
            {this.state.showMyCommunities
              ? publicStoreButton
              : myCommunityButton}
            &nbsp;&nbsp;
            <button
              // className="btn-modal_dash"
              className="btn_info_main"
              type="button"
              onClick={this.openAlertModal}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-info-circle" />
            </button>
            &nbsp;&nbsp;
            <button
              // className="btn-back-from-dash"
              className="btn_back_main"
              type="button"
              onClick={this.goToLobby}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
        </div>
        {this.state.showMyCommunities ? itemListPanel : storePanel}
      </div>
    );

    // This is your store, market for handling catalog, inventory, this.connects, intel etc. All for the community you selected.
    let marketPanel;
    if (this.state.marketFlag) {
      // console.log("Going to market from ... " + this.state.comingFrom);
      marketPanel = (
        <div>
          <MarketMain
            commName={this.state.commName}
            commCaption={this.state.commCaption}
            communityId={this.state.communityId}
            role={this.state.role}
            isCustomer={this.state.isCustomer}
            intent={this.state.intent}
            focus={this.state.focus}
            dashReturnMethod={(msg, opstype) =>
              this.returnToDashboard(msg, opstype)
            }
            comingFrom={this.state.comingFrom}
            opstype={this.state.opstype}
          />
        </div>
      );
    }

    let outputPanel;
    if (this.state.accessListPanelFlag || this.state.showStoreFlag) {
      outputPanel = <div>{engageLandingPanel}</div>;
    }
    if (this.state.marketFlag) {
      outputPanel = marketPanel;
    }
    if (this.state.gotToLobbyFlag) {
      return <Redirect to={`/lobby`} />;
    }

    return (
      <div className="text-center">
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

Dashboard.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
