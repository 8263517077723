import React, { Component } from "react";

import axios from "axios";

import "./GetEventsToEdit.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getEventToEdit = "/routes/interact/getEventsToEdit?";
const deleteAnEvent = "/routes/interact/deleteAnEvent";

const Checkbox = (props) => <input type="checkbox" {...props} />;

class GetEventsToEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      selectionType: "wip",
      selectedEvent: null,

      eventsToEdit: [],

      errMsg: "",

      showDeleteConfirmbox: false,
      toDeleteThisItem: null,

      deleteConfirm: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.fetchEvents();

    await this.setState({
      deviceSize,
    });
  };

  handleFetchType = async (e) => {
    await this.setState({
      selectionType: e.target.value,
    });
  };

  fetchEvents = async () => {
    // console.log("fetchEvents");
    let params =
      "communityId=" +
      this.props.communityId +
      "&hostBaandaId=" +
      this.props.hostBaandaId +
      "&mode=" +
      this.state.selectionType;
    try {
      let url = baandaServer + getEventToEdit + params;
      // console.log("url:", url);
      let ret = await axios.get(url);
      // console.log("ret: ", ret);
      if (ret.data.Msg.length > 0) {
        await this.setState({
          eventsToEdit: ret.data.Msg,
          errMsg: "",
        });
      } else {
        await this.setState({
          eventsToEdit: [],
          errMsg: "No events to refer.",
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  handleSelectEvent = async (eve) => {
    // console.log("Event:", eve);
    await this.setState({
      selectedEvent: eve,
    });
    this.returnToCreateEvent();
  };

  handleDeleteConfirm = async (eve) => {
    // console.log("handleDeleteConfirm eve:", eve);
    await this.setState({
      showDeleteConfirmbox: true,
      toDeleteThisItem: eve,
    });
  };

  handleDeleteEvent = async (eve) => {
    // console.log("handleDeleteEvent eve:", eve);
    try {
      let url = baandaServer + deleteAnEvent;
      let input = {
        eventId: eve.eventId,
        communityId: this.props.communityId,
        eventStatus: "prepmode",
      };
      // console.log("url:", url, " input:", input);
      let revent = await axios.post(url, input);
      if (revent.data.status === "success") {
        await this.setState({
          eventsToEdit: revent.data.Msg,
          errMsg: "",
          showDeleteConfirmbox: false,
          deleteConfirm: false,
        });
      }
    } catch (err) {
      console.log("Err:", err.message);
    }
  };

  returnToCreateEvent = () => {
    this.props.returnToCaller(
      this.state.selectedEvent,
      this.state.selectionType
    );
  };

  handleDeleteConfirmCheckbox = async () => {
    await this.setState((prevstate) => ({
      deleteConfirm: !prevstate.deleteConfirm,
    }));
  };

  cancelDeleteConfirm = async () => {
    await this.setState({
      deleteConfirm: false,
      showDeleteConfirmbox: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("GetEventsToEdit...");

    let fetchButtonPanel = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={this.fetchEvents}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {/* {fetchbtn}&nbsp; */}
        <i className="fa fa-search" />
      </button>
    );

    let deleteConfirmButtons;
    deleteConfirmButtons = (
      <div>
        {this.state.deleteConfirm ? (
          <button
            className="btn-event-delete-confirm"
            type="button"
            onClick={() => this.handleDeleteEvent(this.state.toDeleteThisItem)}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Delete
          </button>
        ) : null}

        <button
          className="btn-event-delete-confirm"
          type="button"
          onClick={this.cancelDeleteConfirm}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Cancel
        </button>
      </div>
    );

    let confirmPanel;
    if (this.state.showDeleteConfirmbox) {
      confirmPanel = (
        <div className="confirm-popup-box">
          <div className="text-center delete-confirm-header">
            Confirm To Delete
          </div>
          <div className="text-center checkbox-text">
            <Checkbox
              checked={this.state.deleteConfirm}
              onChange={this.handleDeleteConfirmCheckbox}
            />
            &nbsp;Check to confirm; deletion is permanent.
          </div>
          {deleteConfirmButtons}
        </div>
      );
    }

    let listEventsPanel;
    if (this.state.eventsToEdit.length === 0) {
      listEventsPanel = (
        <div className="text-center no-events-msg">{this.state.errMsg}</div>
      );
    } else {
      let bool = true;
      listEventsPanel = (
        <div className="fixedsize_events_list">
          {this.state.eventsToEdit.map((post, i) => {
            return (
              <div key={i}>
                <div
                  className={`${
                    bool ? "row-event-color-dr" : "row-event-color-lt"
                  }`}
                >
                  <div className="row event-list-row-pos">
                    <div className="col-9 text-left event-name-lbl">
                      {post.eventName}
                    </div>
                    <div className="col-3 text-center">
                      <button
                        onClick={() => this.handleSelectEvent(post)}
                        className="btn_reg_50"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Open
                      </button>
                      {post.eventStatus === "prepmode" ? (
                        <button
                          onClick={() => this.handleDeleteConfirm(post)}
                          className="btn_exit_del"
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-trash" />
                        </button>
                      ) : null}
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      );
    }

    let selectTypePanel;
    selectTypePanel = (
      <div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Select an Event&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="wip"
                  checked={this.state.selectionType === "wip"}
                  onChange={this.handleFetchType}
                />{" "}
                WIP (not-published)
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="published"
                  checked={this.state.selectionType === "published"}
                  onChange={this.handleFetchType}
                />{" "}
                Published
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="canceled"
                  checked={this.state.selectionType === "canceled"}
                  onChange={this.handleFetchType}
                />{" "}
                Canceled
              </label>
            </div>
            {fetchButtonPanel}
          </div>
        </div>
        <div>
          <div className="leventist-header">Event Names to Refer</div>
          {confirmPanel}
          {listEventsPanel}
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = <div>{selectTypePanel}</div>;

    return <div>{outputPanel}</div>;
  }
}

export default GetEventsToEdit;
