import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactLoading from "react-loading";

import axios from "axios";

import ReceiveMoneyViaCC from "../../../../../../account/finance/communities/ReceiveMoneyViaCC";

import "./BookAnEvent.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getBookingMetaData = "/routes/interact/getBookingMetaData?";
const postABooking = "/routes/interact/postABooking";

const transactionFeeServiceName =
  process.env.REACT_APP_FINANCIAL_TRANSACTION_FEE;
const baandaCommunityId = process.env.REACT_APP_BAANDA_COMMUNITY_ID;

class BookAnEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      showBookResponse: true,
      showReceivePayment: false,

      joinResponse: "",
      paymentTime: "now",
      response: "accept",

      commMeta: null,
      paymentUsing: "creditcard",
      eventData: null,

      selheight: 1,
      daysPlanToAttend: [],
      loadingFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let daysPlanToAttend = [];
    if (this.props.theEvent.eventOn.daysString.length === 1) {
      let dobj = {
        attendingOnDate: this.props.theEvent.eventOn.daysString[0],
      };
      daysPlanToAttend.push(dobj);
    }

    await this.setState({
      deviceSize,
      daysPlanToAttend,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  returnToOrigin = () => {
    this.props.returnToCaller("viewevent");
  };

  handlerResponseDone = () => {
    // console.log("Done ... BookAnEvent");
    this.props.returnToCaller();
  };

  handlePaymentTime = async (e) => {
    this.setState({
      paymentTime: e.target.value,
    });
  };

  handleResponseType = async (e) => {
    this.setState({
      response: e.target.value,
    });
  };

  handlePaymentUsing = async (e) => {
    this.setState({
      paymentUsing: e.target.value,
    });
  };

  getBookingMeta = async () => {
    let params =
      "baandaCommunityId=" +
      parseFloat(baandaCommunityId) +
      "&businessCommunityId=" +
      this.props.communityId;
    let url = baandaServer + getBookingMetaData + params;
    try {
      let metaret = await axios.get(url);
      if (metaret.data.status === "success") {
        this.setState({
          commMeta: metaret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
        return "success";
      } else {
        this.setState({
          errMsg: metaret.data.Msg,
          errFlag: true,
        });
        return "error";
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
      return "error";
    }
  };

  packageDataForDB = async () => {
    let paymentRequired = false;
    let isOK = "error";

    if (
      this.state.response === "accept" &&
      this.props.theEvent.priceToAttend > 0 &&
      this.state.paymentTime === "now"
    ) {
      isOK = await this.getBookingMeta();
      paymentRequired = true;
    } else isOK = "success";

    let payHandler = "";
    if (this.state.paymentUsing === "creditcard") payHandler = "stripe";
    if (this.state.paymentUsing === "crupto") payHandler = "undecided-jan2022";

    // console.log("this.state.daysPlanToAttend:", this.state.daysPlanToAttend);
    let attendingOnDate = "";
    if (this.props.theEvent.eventOnPublished.attendanceType === "anyday") {
      attendingOnDate = this.state.daysPlanToAttend[0].attendingOnDate;
    }

    let input = {
      communityId: this.props.communityId,
      paymentRequired,
      serviceFeeName: transactionFeeServiceName,
      theEvent: this.props.theEvent,
      responseType: this.state.response,
      textResponse: this.state.joinResponse,
      commMeta: this.state.commMeta,
      cartOf: this.props.cartOf,
      caller: this.props.caller,
      paymentType: this.state.paymentUsing,
      calledFrom: this.props.calledFrom,
      payHandler,
      attendingOnDate,
      synapseId: this.props.synapseId,
      attendee: this.props.attendee,
      paymentTime: this.state.paymentTime,
      paymentFor: "postAnEvent",
      clientProgram: "BookAnEvent",
      clientFunction: "handleBookingtheEvent",
      updatedBy: this.props.auth.user.baandaId,
    };

    return { status: isOK, Msg: input };
  };

  isReadyToBook = async () => {
    let isValid = "success";
    let msg = "";
    if (this.props.theEvent.eventOnPublished.attendanceType === "anyday") {
      if (this.state.daysPlanToAttend.length === 0) {
        isValid = "error";
        msg = "Must select adate to attend. ";
      }
    }

    return { status: isValid, Msg: msg };
  };

  handleBookingTheEvent = async () => {
    let isReady = await this.isReadyToBook();
    // console.log("isReady:", isReady);
    if (isReady.status === "success") {
      let input = await this.packageDataForDB();
      // console.log("input:", input);
      if (input.status === "success") {
        if (input.Msg.paymentRequired) {
          // Pay using CC now
          this.setState({
            eventData: input.Msg,
            showReceivePayment: true,
            showBookResponse: false,
            errMsg: "",
            errFlag: false,
          });
        } else {
          this.setState({
            showReceivePayment: false,
            loadingFlag: true,
          });
          await this.registerTheResponse(input);
        }
      } else {
        this.setState({
          errMsg: input.Msg,
          errFlag: true,
        });
      }
    } else {
      await this.setState({
        errMsg: isReady.Msg,
        errFlag: true,
      });
    }
  };

  registerTheResponse = async (input) => {
    input.Msg.paymentRequired = false;
    try {
      let url = baandaServer + postABooking;
      // console.log('url:', url, ' input.Msg:', input.Msg);
      let bookret = await axios.post(url, input.Msg);

      if (bookret.data.status === "success") {
        await this.setState({
          eventData: input.Msg,
          showBookResponse: true,
          showReceivePayment: false,
          errMsg: "",
          message: "Your response has been registered.",
          errFlag: false,
          loadingFlag: false,
          responseDone: true,
        });
      } else {
        this.setState({
          errMsg: bookret.Msg,
          errFlag: true,
          loadingFlag: false,
        });
      }
    } catch (err) {
      await this.setState({
        errMsg: err.message,
        errFlag: true,
        loadingFlag: false,
      });
    }
  };

  handleExit = async (msg) => {
    let pmsg = "";
    let showBookResponse = false;
    let responseDone = true;
    let message = "Youre response has been registered.";
    if (msg === "cancel") {
      pmsg = "Booking has been canceled by you.";
      showBookResponse = true;
      responseDone = false;
      message = "";
    }

    await this.setState({
      showReceivePayment: false,
      showBookResponse,
      responseDone,
      errMsg: pmsg,
      message,
    });
  };

  handleDateSelect = async () => {
    let value = document.getElementById("eventBookDateSelect").value;

    let valobj = JSON.parse(value);

    let daysPlanToAttend = [];
    daysPlanToAttend.push(valobj);

    await this.setState({
      selheight: 1,
      daysPlanToAttend,
    });
  };

  onfocus = async () => {
    await this.setState({
      selheight: 5,
    });
  };

  onblur = async () => {
    await this.setState({
      selheight: 1,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('BookAnEvent...');

    // Upload Spinner _____________________________________________
    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div>
          <ReactLoading
            type={"spokes"}
            color={"#237547"}
            height={25}
            width={25}
            className="comm-entry-spinner"
          />
        </div>
      );
    } else {
      uploadingSpin = null;
    }
    // Upload Panel _______________________________________________

    let returnPanel = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToOrigin}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let btntoJoin;

    if (
      this.props.theEvent.priceToAttend > 0 &&
      this.state.paymentTime === "now" &&
      this.state.response === "accept"
    ) {
      btntoJoin = (
        <button
          className="btn_reg_100 book-event-btn-pos"
          type="button"
          onClick={this.handleBookingTheEvent}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Respond
        </button>
      );
    }

    if (
      this.props.theEvent.priceToAttend > 0 &&
      this.state.paymentTime !== "now" &&
      this.state.response === "accept"
    ) {
      btntoJoin = (
        <button
          className="btn_reg_100 book-event-btn-pos"
          type="button"
          onClick={this.handleBookingTheEvent}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Pay @ Gate
        </button>
      );
    }

    if (
      this.props.theEvent.priceToAttend === 0 ||
      this.state.response !== "accept"
    ) {
      btntoJoin = (
        <button
          className="btn_reg_100 book-event-btn-pos"
          type="button"
          onClick={this.handleBookingTheEvent}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Respond
        </button>
      );
    }

    let yrIntent;
    if (this.state.deviceSize === "big") {
      yrIntent = "Your Response";
    } else {
      yrIntent = "Resp";
    }

    let actionResponsePanel;
    actionResponsePanel = (
      <div className="row join-event-payment-row">
        <div className="col text-center radio-font-style">
          <strong>{yrIntent}</strong>&nbsp;&nbsp;
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="accept"
                checked={this.state.response === "accept"}
                onChange={this.handleResponseType}
              />{" "}
              Accept
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="decline"
                checked={this.state.response === "decline"}
                onChange={this.handleResponseType}
              />{" "}
              Decline
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="tentative"
                checked={this.state.response === "tentative"}
                onChange={this.handleResponseType}
                disabled
              />{" "}
              Tentative
            </label>
          </div>
        </div>
      </div>
    );

    console.log("before date selection ...");
    let dateSelectionPanel;
    if (
      this.state.response !== "decline" &&
      this.props.theEvent.eventOnPublished.attendanceType === "anyday"
    ) {
      if (this.props.theEvent.eventOn.daysString.length > 1) {
        let strToobjArr = [];
        this.props.theEvent.eventOn.daysString.forEach((elm) => {
          let dj = {
            attendingOnDate: elm,
          };
          strToobjArr.push(dj);
        });

        let datelist;
        datelist = strToobjArr.map((obj, i) => {
          return (
            <option key={i} value={JSON.stringify(obj)}>
              {obj.attendingOnDate}&nbsp;
            </option>
          );
        });

        dateSelectionPanel = (
          <div className="text-right book-date-sel-pos">
            <select
              size={this.state.selheight}
              onFocus={this.onfocus}
              onBlur={this.onblur}
              id="eventBookDateSelect"
              name="eventBookDateSelect"
              onChange={this.handleDateSelect}
              onSelect={this.handleDateSelect}
              className="book-event-a-date"
              defaultValue={"DEFAULT"}
            >
              <option className="" value="DEFAULT" disabled>
                Select attending date
              </option>
              {datelist}
            </select>
          </div>
        );
      } else {
        <div className="text-right book-date-sel-pos">
          Event date:{" "}
          {this.state.daysPlanToAttend.length === 1
            ? this.state.daysPlanToAttend[0].attendingOnDate
            : "fix it "}
        </div>;
      }
    }

    let payusing;
    if (this.state.deviceSize === "big") {
      payusing = "Pay using";
    } else {
      payusing = "Pay using";
    }

    let paymentViaPanel;
    if (this.state.paymentTime === "now") {
      paymentViaPanel = (
        <div className="row join-event-payment-via-row">
          <div className="col text-center radio-font-style">
            <strong>{payusing}</strong>&nbsp;&nbsp;
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="creditcard"
                  checked={this.state.paymentUsing === "creditcard"}
                  onChange={this.handleResponseType}
                />{" "}
                Credit Card
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="crypto"
                  checked={this.state.paymentUsing === "crypto"}
                  onChange={this.handleResponseType}
                  disabled={true}
                />{" "}
                Crypto
              </label>
            </div>
          </div>
        </div>
      );
    }

    let paymode;
    if (this.state.deviceSize === "big") {
      paymode = "Payment Via";
    } else {
      paymode = "Pay via";
    }

    let feePanel;
    if (
      this.props.theEvent.priceToAttend > 0 &&
      (this.state.response === "accept" || this.state.response === "tentative")
    ) {
      feePanel = (
        <div>
          <div>
            {this.props.theEvent.priceToAttendAtGate > 0 ? (
              <div className="row join-event-payment-row">
                <div className="col text-center radio-font-style">
                  <strong>{paymode}</strong>&nbsp;&nbsp;
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="now"
                        checked={this.state.paymentTime === "now"}
                        onChange={this.handlePaymentTime}
                      />{" "}
                      Book now
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="atgate"
                        checked={this.state.paymentTime === "atgate"}
                        onChange={this.handlePaymentTime}
                        disabled
                      />{" "}
                      Pay at the gate
                    </label>
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.paymentTime === "now" ? (
              <div className="text-center event-fee-pay-now">
                Book online now: ${" "}
                {this.commaFormattedCurrency(this.props.theEvent.priceToAttend)}
              </div>
            ) : (
              <div className="text-center event-fee-pay-later">
                Pay at the gate: ${" "}
                {this.commaFormattedCurrency(
                  this.props.theEvent.priceToAttendAtGate
                )}
              </div>
            )}
          </div>
          {paymentViaPanel}
        </div>
      );
    } else {
      if (this.props.theEvent.priceToAttend === 0) {
        feePanel = (
          <div className="text-center event-no-join-fee">
            No fee to join the event.
          </div>
        );
      } else {
        feePanel = (
          <div className="text-center event-no-join-fee">
            Payment is not relevant for the decision.
          </div>
        );
      }
    }

    let responsePanel;
    responsePanel = (
      <div>
        {feePanel}
        {/* { this.state.paymentTime === 'now' ? paymentViaPanel  : null} */}
        {dateSelectionPanel}
        <div className="row booking-comment-row-pos">
          <div className="col-md-5 text-left event-join-response-lbl">
            Comment / response
          </div>
          <div className="col-md-6 text-center event-join-disabled-msg">
            Disabled options will be available soon.
          </div>
        </div>
        <div className="row ">
          <div className="col">
            <textarea
              name="joinResponse"
              maxLength="1000"
              placeholder="Attach a comment (optional) with your response."
              rows="5"
              wrap="hard"
              spellCheck="true"
              className="join-response-text"
              onChange={this.onChange}
              value={this.state.joinResponse}
              required
            />
          </div>
        </div>
        <div className="text-center">{btntoJoin}</div>
        <div className="row">
          <div className="col-4">&nbsp;</div>
          <div className="col-4 text-center">{uploadingSpin}</div>
          <div className="col-4">&nbsp;</div>
        </div>
        <div
          className={
            this.state.errFlag
              ? "text-center event-book-msg-err"
              : "text-center event-book-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    let outputPanel;
    if (this.state.showBookResponse) {
      outputPanel = (
        <div>
          <div className="service-book-header">
            Book Your Engagement&nbsp;&nbsp;{returnPanel}
            {actionResponsePanel}
            {responsePanel}
          </div>
        </div>
      );
    }

    let toPayPanel = (
      <div className="text-center event-book-to-pay-panel">
        Attendence Fee: ${" "}
        {this.commaFormattedCurrency(this.props.theEvent.priceToAttend)}
      </div>
    );

    if (this.state.showReceivePayment) {
      outputPanel = (
        <div>
          {toPayPanel}
          <ReceiveMoneyViaCC
            inputData={this.state.eventData}
            deviceSize={this.state.deviceSize}
            paymentAmount={parseFloat(this.props.theEvent.priceToAttend)}
            stripeaccId={this.state.eventData.commMeta.bizCommData.stripe.id}
            handleExit={(msg) => this.handleExit(msg)}
          />
        </div>
      );
    }

    if (this.state.responseDone) {
      outputPanel = (
        <div className="text-center booking-response-done">
          {this.state.message}
          <div>
            <button
              className="btn_reg_60"
              type="button"
              onClick={this.handlerResponseDone}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Done
            </button>
          </div>
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

BookAnEvent.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// const mapDispatchToProps = (dispatch) => ({
//   hideModal: () => dispatch(hideModal()),
//   showModal: (modalProps, modalType) => {
//     dispatch(showModal({ modalProps, modalType }));
//   },
// });

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(withRouter(BookAnEvent));
