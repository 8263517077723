import React, { Component } from "react";

import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./ExpenseFilter.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getExpenseCategories = "/routes/reports/getExpenseCategories?";

class ExpenseFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearchFlag: false,
      showCategorySelFlag: false,

      dateFirst: new Date(),
      dateSecond: new Date(new Date().setDate(new Date().getDate() + 1)),

      expCategories: [],
      selectedCategory: null,
      filterType: "all",
      selheightad: 1,
    };
  }

  expenseTypePanel = async (e) => {
    let value = e.target.value;
    let showCategorySelFlag = false;
    if (value === "category") {
      showCategorySelFlag = true;
      if (this.state.expCategories.length === 0)
        await this.getExpenseCategories();
    }
    this.setState({
      filterType: e.target.value,
      showCategorySelFlag,
    });
  };

  getExpenseCategories = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      this.props.coopStoreId;
    let url = baandaServer + getExpenseCategories + params;
    // console.log("url:", url);
    try {
      let metaret = await axios.get(url);
      // console.log("metaret.data:", metaret.data);
      if (metaret.data.status === "success") {
        this.setState({
          expCategories: metaret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: metaret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleDateFirst = async (date) => {
    let result = new Date(date);
    result.setDate(result.getDate() + 1);
    this.setState({
      dateFirst: date,
      dateSecond: result,
    });
  };

  handleDateSecond = async (date) => {
    this.setState({
      dateSecond: date,
    });
  };

  exitFilter = () => {
    let cond = null;
    this.props.returnToCaller(cond);
  };

  sendSearchCondition = () => {
    let cond = {
      dateFrom: this.state.dateFirst,
      dateTo: this.state.dateSecond,
      filterType: this.state.filterType,
      selectedCategory: this.state.selectedCategory,
    };
    this.props.returnToCaller(cond);
  };

  handleExpCatData = async () => {
    let value = document.getElementById("expCategoryData").value;
    // console.log("handleExpCatData value:", value);
    let data = JSON.parse(value);

    this.setState({
      selectedCategory: data,
      selheightad: 1,
    });
  };

  onfocusa = async () => {
    this.setState({
      selheightad: 5,
    });
  };

  onblura = async () => {
    this.setState({
      selheightad: 1,
    });
  };


  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ExpenseFilter...");

    let searchButton = (
      <button
        onClick={this.sendSearchCondition}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_80"
      >
        Fetch <i className="fas fa-search-plus" />
      </button>
    );

    let exitButton = (
      <button
        onClick={this.exitFilter}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let expCatPanel;
    if (this.state.filterType === "category") {
      let expCatList = this.state.expCategories.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj.categoryOf}
          </option>
        );
      });
      expCatPanel = (
        <div className="gl-filter-metadata-list-pos">
          <div className="row ship-addr-dropdown">
            <div className="col text-center ">
              <select
                size={this.state.selheightad}
                id="expCategoryData"
                name="expCategoryData"
                onChange={this.handleExpCatData}
                className="list-gl-metadata"
                onFocus={this.onfocusa}
                onBlur={this.onblura}
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Select a category
                </option>
                {expCatList}
              </select>
            </div>
          </div>
        </div>
      );
    }

    let dateSelectionPanel;
    dateSelectionPanel = (
      <div className="filter-gl-date-row">
        <div className="row">
          <div className="col-2 text-right gl-report-date-filter">From</div>
          <div className="col-4 text-left">
            <DatePicker
              selected={this.state.dateFirst}
              onChange={this.handleDateFirst}
              onSelect={this.handleDateFirst}
              name="prdob"
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="col-1 text-right gl-report-date-filter">To</div>
          <div className="col-5 text-left">
            <DatePicker
              selected={this.state.dateSecond}
              onChange={this.handleDateSecond}
              onSelect={this.handleDateSecond}
              name="prdob"
              dateFormat="MM/dd/yyyy"
              minDate={this.state.dateSecond}
            />
          </div>
        </div>
      </div>
    );

    let expenseTypePanel;
    expenseTypePanel = (
      <div className="row gl-type-row">
        <div className="col text-center radio-font-style">
          Filter by&nbsp;&nbsp;
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="all"
                checked={this.state.filterType === "all"}
                onChange={this.expenseTypePanel}
              />{" "}
              All
            </label>
          </div>
          {/* <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="summary"
                checked={this.state.filterType === "summary"}
                onChange={this.expenseTypePanel}
              />{" "}
              Summary (by Cat)
            </label>
          </div> */}
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="category"
                checked={this.state.filterType === "category"}
                onChange={this.expenseTypePanel}
              />{" "}
              Category
            </label>
          </div>
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="expense-filter-box">
        <div className="text-center ">
          Expense Filter &nbsp;&nbsp;{searchButton}&nbsp;{exitButton}
        </div>
        {expenseTypePanel}
        {dateSelectionPanel}
        {expCatPanel}
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

export default ExpenseFilter;
