import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import HandleReturnPost from "./HandleReturnPost";

import "./PriorRefundCancelOps.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getPriorRefundCancelReqs = "/routes/finance/getPriorRefundCancelReqs?";
const processRefundCancelResponse =
  "/routes/finance/processRefundCancelResponse?";
// const getInvoiceById = "/routes/finance/getInvoiceById?";
const getRefundInfoForResponse = "/routes/finance/getRefundInfoForResponse?";

// const readMeCode = "0100000000";

class PriorRefundCancelOps extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      priors: [],
      details: [],
      errMsg: "",
      errFlag: false,
      selectedLine: null,
      responseComment: "",

      invoice: null,
      talks: [],

      postReturnFlag: false,
      refundedAmount: 0,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getPriorRefundCancelActivities();

    await this.setState({
      deviceSize,
    });
  };

  handleRefundCancelResponse = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let url = baandaServer + processRefundCancelResponse;
    let input = {
      invoiceId: parseFloat(this.props.invoiceId),
      // refundCancelId: this.props.returnCancel.refundCancelId,
      refundCancelId: this.state.selectedLine.refundCancelId,
      responseComment: this.state.responseComment,
      communityId: this.props.communityId,
      coopStoreId,
      responseOps: "moreinfo",
      refundAmount: this.state.amountReturned,
      actionBy: "customer",
    };
    // console.log("url:", url, " input:", input);
    try {
      let canret = await axios.post(url, input);
      // console.log("canret:", canret);
      if (canret.data.status === "success") {
        this.setState({
          errMsg: "Successfully processed.",
          errFlag: false,
          responseComment: "",
          // details: canret.data.Msg.requestResponse,
          talks: canret.data.Msg.requestResponse,
        });
      } else {
        this.setState({
          errMsg: canret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  getPriorRefundCancelActivities = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&baandaId=" +
      this.props.auth.user.baandaId;
    let url = baandaServer + getPriorRefundCancelReqs + params;
    try {
      let prret = await axios.get(url);
      if (prret.data.status === "success") {
        this.setState({
          priors: prret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: prret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getPriorRefundCancelActivities err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  selectThisItemToCheck = async (obj) => {
    // console.log("obj:", obj);
    // await this.getTheInvoice(obj.refInvoiceId);
    await this.getOpsInfo(obj.refInvoiceId);
    let refAmount = 0;
    this.state.invoice.refunds.forEach((ref) => {
      if (ref.refundCancelId === obj.refundCancelId) {
        refAmount += ref.refundedAmount;
      }
    });

    let showPostFlag = false;
    if (obj.requestResponse.length > 0) {
      obj.requestResponse.forEach((rr) => {
        if (rr.actionBy === "business") showPostFlag = true;
      });
    }
    this.setState({
      refundedAmount: refAmount,
      selectedLine: obj,
      showPostFlag,
    });
    // this.state.priors.forEach((elm) => {
    //   if (elm.refundCancelId === obj.refundCancelId) {
    //     this.setState({
    //       details: elm.requestResponse,
    //       selectedLine: obj,
    //     });
    //   }
    // });
  };

  getOpsInfo = async (invoiceId) => {
    // console.log("in getOpsInfo invoiceId:", invoiceId);
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&invoiceId=" +
      invoiceId;
    let url = baandaServer + getRefundInfoForResponse + params;
    try {
      let retops = await axios.get(url);
      // console.log("retops.data:", retops.data);
      if (retops.data.status === "success") {
        // console.log("here 160 ....");
        let retcan = [];
        // console.log(
        //   "retops.data.Msg.refundCancel.length:",
        //   retops.data.Msg.refundCancel.length
        // );
        let talks = [];
        if (retops.data.Msg.refundCancel.length > 1) {
          // console.log("here  162");
          // console.log(
          //   "retops.data.Msg.refundCancel:",
          //   retops.data.Msg.refundCancel
          // );

          retops.data.Msg.refundCancel.forEach((rc) => {
            // console.log(
            //   "rc:",
            //   rc,
            //   " this.props.refundCancelId:",
            //   this.props.refundCancelId
            // );
            if (rc.refundCancelId === this.props.refundCancelId) {
              // rc.toReturnItems.forEach(trc => {
              //   retcan.push(trc)
              // })
              // console.log("172 ... rc.toReturnItems:", rc.toReturnItems);
              talks = rc.requestResponse;
              retcan.push(rc);
            }
          });
        } else {
          // console.log("here 174...");
          retcan = [...retops.data.Msg.refundCancel];
          talks = retcan[0].requestResponse;
        }
        // console.log("retcan:", retcan);
        this.setState({
          invoice: retops.data.Msg.theInvoice,
          // returnCancel: retops.data.Msg.refundCancel,
          returnCancel: retcan,
          talks,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: retops.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  processCustomerAction = async () => {
    if (this.state.responseComment.length < 10) {
      this.setState({
        errMsg: "Must have a comment of at least 10 character.",
        errFlag: true,
      });
    } else {
      await this.handleRefundCancelResponse();
    }
  };

  processPostReturns = () => {
    this.setState({
      postReturnFlag: true,
    });
  };

  returnFromPosting = () => {
    this.setState({
      postReturnFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("PriorRefunCancelOps...");

    let processButton = (
      <button
        className="btn_reg_80"
        type="button"
        onClick={this.processCustomerAction}
        style={{
          cursor: this.state.disabled ? "default" : "pointer",
        }}
      >
        Respond
      </button>
    );

    let postButton;
    if (this.state.invoice && this.state.showPostFlag) {
      postButton = (
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.processPostReturns}
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          Post
        </button>
      );
    }

    let invId, refcanId, biz, trmedium, refCanId;
    if (this.state.deviceSize === "big") {
      invId = "Selected invoiceId: ";
      refcanId = "Refunded Id: ";
      biz = "Business";
      trmedium = "Transaction Medium";
      refCanId = "RefundId";
    } else {
      invId = "Sel invId: ";
      refcanId = "rcId: ";
      biz = "Biz";
      trmedium = "Tr-M";
      refCanId = "Id";
    }

    let priorPanel;
    let bool = true;
    priorPanel = (
      <div>
        <div className="row cust-ret-mgmt-header">
          <div className="col-2">Ops</div>
          <div className="col-10 text-left">
            Invoice Id | {trmedium} | Status | Req. Date | {refCanId}
          </div>
        </div>
        <div className="fixedsize-customer-priors">
          {this.state.priors.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                  }
                >
                  <div className="col-3 text-center">
                    <button
                      className="btn_reg_50 cust-ret-open-pos"
                      type="button"
                      onClick={() => this.selectThisItemToCheck(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      {this.state.deviceSize === "big" ? "Open" : "O"}
                    </button>
                  </div>
                  {/* <div className="col-9 text-left item-category-label"> */}
                  <div className="col-9 text-left cust-ret-act-txt-prior">
                    {obj.refInvoiceId} | {obj.paymentMedium} | {obj.status} |{" "}
                    {obj.lastActivityDate.substring(0, 10)} |{" "}
                    {obj.refundCancelId}
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let responsePanel;
    if (this.state.selectedLine) {
      if (
        this.state.selectedLine.status === "approved" ||
        this.state.selectedLine.status === "declined"
      ) {
        responsePanel = (
          <div>
            {this.state.selectedLine.status === "approved" ? (
              <div className="text-center customer_response-final">
                The activity is final and closed. Approve: Refund Amt: $
                {this.commaFormattedCurrency(this.state.refundedAmount)}
              </div>
            ) : (
              <div className="text-center customer_response-final">
                The activity is final and closed. Request declined. Check
                conversation above.
              </div>
            )}
          </div>
        );
      } else {
        responsePanel = (
          <div>
            <div className="ret-cust-can-amt-pos">
              <div className="text-left ret-can-respond-comment-head">
                Comment
              </div>
              <div className="row">
                <div className="col ">
                  <textarea
                    name="responseComment"
                    maxLength="1000"
                    placeholder="Comments ..."
                    rows="3"
                    wrap="hard"
                    spellCheck="true"
                    className="input-cust-ret-can-textarea"
                    onChange={this.onChange}
                    value={this.state.responseComment}
                    required
                  />
                </div>
              </div>
              {/* {talkPanel} */}
              <div className="cust-resp-status-x">
                Last State: {this.state.selectedLine.status} {processButton}{" "}
                {this.state.selectedLine ? postButton : null}
              </div>
            </div>
          </div>
        );
      }
    }

    let exchangeDetailPanel;
    // if (this.state.details.length > 0) {
    if (this.state.selectedLine && this.state.talks.length > 0) {
      let bool1 = true;
      exchangeDetailPanel = (
        <div>
          <div className="text-left cust-conv-header">
            Prior Conversation&nbsp;::&nbsp;
            {invId} {this.state.selectedLine.refInvoiceId} | {refcanId}{" "}
            {this.state.selectedLine.refundCancelId}
          </div>
          <div className="row">
            <div className="col-1 text-left cust-prior-conv-head">Origin</div>
            <div className="col-3 text-center cust-prior-conv-head-date">
              Date
            </div>
            <div className="col-8 text-left cust-prior-conv-head">Comment</div>
          </div>

          <div className="fixedsize-customer-priors">
            {this.state.talks.map((obj, i) => {
              // console.log('362 obj:', obj)
              let actor = biz;
              if (this.state.deviceSize === "small") {
                if (obj.actionBy === "customer") actor = "You";
                else actor = biz;
              } else {
                if (obj.actionBy === "customer") actor = "You";
                else actor = biz;
              }

              let actdt;
              if (this.state.deviceSize === "big") {
                actdt = obj.actionDate.substring(0, 10);
              } else {
                actdt =
                  obj.actionDate.substring(5, 7) +
                  "/" +
                  obj.actionDate.substring(8, 10) +
                  "/" +
                  obj.actionDate.substring(2, 4);
              }
              return (
                <div key={i}>
                  <div
                    className={
                      bool1
                        ? "row row-col-details-dr"
                        : "row row-col-details-lt"
                    }
                  >
                    {/* <div className="col-9 text-left item-category-label"> */}
                    <div className="col-1 text-left cust-ret-act-txt">
                      {actor}
                    </div>
                    <div className="col-3 text-left cust-ret-act-txt">
                      {actdt}
                    </div>
                    <div className="col-8 text-left cust-ret-act-note-txt">
                      <p align="justify">{obj.note}</p>
                    </div>
                  </div>
                  {(bool1 = !bool1)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let postingPanel;
    if (this.state.postReturnFlag) {
      postingPanel = (
        <div>
          <HandleReturnPost
            communityId={this.props.communityId}
            store={this.props.store}
            returnToCaller={this.returnFromPosting}
            invoice={this.state.invoice}
            selectedLine={this.state.selectedLine}
          />
        </div>
      );
    }

    let outputPanel = (
      <div>
        {postingPanel}
        {priorPanel}
        {exchangeDetailPanel}
        {responsePanel}
        <div
          className={
            this.state.errFlag
              ? "text-center cust-resp-act-message-err"
              : "text-center cust-resp-act-message"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

PriorRefundCancelOps.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PriorRefundCancelOps)
);
