import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import axios from "axios";

import "./ReferenceHub.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getEventsOfABaandaByName = "/routes/interact/getEventsOfABaandaByName?";
// const getCatalogItems = "/routes/dashboard/getCatalogItems?";
const getWarehouseItems = "/routes/dashboard/getSomeItems?";

class referenceHub extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showProjectflag: false,
      showServiceTasksFlag: false,

      referenceType: "",
      refName: "",

      eventName: "",
      selheightmem: 1,

      events: [],
      selectedEvent: null,
      selectedPrepTask: null,
      prepTasks: [],

      errMsg: "",
      errFlag: false,

      showEventsFlag: false,
      showPayrollFlag: false,
      showWriteOffFlag: false,
      eventMsg: "",
      prepTskMsg: "",
      serviceType: "",

      startDate: new Date(),
      endDate: new Date(),

      selectedItem: null,
      inventoryType: "warehouse",

      itemName: "",
      items: [],
      itemVarience: [],
      itemSubToWriteOff: "",
      maxItemToWrietOff: "",
      showSubItemTowoFlag: false,
      refseqno: 0,

      qtyToWriteOff: 0,
    };
  }

  componentDidMount = async () => {
    let referenceType = "";
    let refName = "";
    let showEventsFlag = false;
    let showPayrollFlag = false;
    let showWriteOffFlag = false;
    if (
      this.props.categoryOf === "Event" &&
      this.props.subCategory === "Tasks"
    ) {
      refName = "Event >> Prep Task";
      referenceType = "Event";
      showEventsFlag = true;
    }

    if (this.props.categoryOf === "Professional Service Fee") {
      refName = "Human Resource Fee >> Payroll subcategories";
      referenceType = "Payroll";
      showPayrollFlag = true;
    }

    if (this.props.categoryOf === "Writeoff") {
      refName = "Writeoff >> Items (inventory or capital-asset)";
      referenceType = "Writeoff";
      showWriteOffFlag = true;
    }

    this.setState({
      referenceType,
      refName,
      showEventsFlag,
      showPayrollFlag,
      showWriteOffFlag,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      message: "",
      errFlag: false,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  returnToExpense = () => {
    let retval = {
      status: "cancel",
    };
    this.props.returnToCaller(retval);
  };

  returnToExpWithData = () => {
    let itemwo = null;
    if (this.props.categoryOf.toLowerCase() === "writeoff") {
      itemwo = {
        itemId: this.state.selectedItem.itemId,
        refseqno: this.state.refseqno,
        qtyToWriteOff: parseFloat(this.state.qtyToWriteOff),
      };
    }

    let retval = {
      type: this.state.referenceType,
      status: "done",
      selectedEvent: this.state.selectedEvent,
      selectedPrepTask: this.state.selectedPrepTask,
      targetServer: this.state.theHost,
      hrPaymentType: {
        serviceType: this.state.serviceType,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      },
      itemwo,
    };

    // console.log('retval:', retval);
    this.props.returnToCaller(retval);
  };

  getEventsOfABaanda = async () => {
    let param =
      "baandaId=" +
      this.state.theHost.baandaId +
      "&communityId=" +
      this.props.communityId +
      "&eventName=" +
      this.state.eventName;
    let url = baandaServer + getEventsOfABaandaByName + param;
    try {
      let everet = await axios.get(url);
      if (everet.data.status === "success") {
        this.setState({
          events: everet.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: everet.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getEventsOfABaanda err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleExpMemberSelect = async () => {
    let value = document.getElementById("expReferenceMembers").value;
    // console.log("handleExpMemberSelect value:", value);
    let data = JSON.parse(value);
    // console.log("valobj:", data);

    this.setState({
      theHost: data,
      selheightmem: 1,
    });
  };

  onfocussub = async () => {
    this.setState({
      selheightmem: 4,
    });
  };

  onblursub = async () => {
    this.setState({
      selheightmem: 1,
    });
  };

  getEventsForBaanda = async () => {
    await this.getEventsOfABaanda();
    let eventMsg = "";
    if (this.state.events.length === 0)
      eventMsg = "This member did not publish any event.";
    this.setState({
      showEventsFlag: true,
      prepTasks: [],
      eventMsg,
      prepTskMsg: "",
    });
  };

  selectThisEvent = async (event) => {
    // console.log("event:", event);
    let prepTskMsg = "";
    if (event.prepTasks.length === 0)
      prepTskMsg = "This event has no prep tasks.";

    this.setState({
      selectedEvent: event,
      prepTasks: event.prepTasks,
      prepTskMsg,
    });
  };

  selectThisPreptask = async (prep) => {
    // console.log("prep:", prep);
    this.setState({
      selectedPrepTask: prep,
    });
  };

  handlePayBasedOn = (e) => {
    this.setState({
      serviceType: e.target.value,
    });
  };

  handleInventoryType = (e) => {
    this.setState({
      inventoryType: e.target.value,
    });
  };

  handleStartDate = async (date) => {
    // console.log("1 date:", date);
    await this.setState({
      startDate: date,
      endDate: date,
    });
  };

  handleEndDate = async (date) => {
    // console.log("1 date:", date);
    await this.setState({
      endDate: date,
    });
  };

  searchForItems = async () => {
    await this.getItemsFromWarehouse();
  };

  getItemsFromWarehouse = async () => {
    let params;
    if (this.props.subCategory.toLowerCase() === "inventory") {
      params =
        "communityId=" +
        this.props.communityId +
        "&coopStoreId=" +
        this.props.coopStoreId +
        "&invClassification=catalog&itemType=goods&itemName=" +
        this.state.itemName;
    } else {
      params =
        "communityId=" +
        this.props.communityId +
        "&coopStoreId=" +
        this.props.coopStoreId +
        "&invClassification=asset&itemType=goods&itemName=" +
        this.state.itemName;
    }
    let url = baandaServer + getWarehouseItems + params;
    // console.log("getItemsFromWarehouse url:", url);
    try {
      let itmret = await axios.get(url);
      // console.log("itmret.data:", itmret.data);
      if (itmret.data.status === "success") {
        if (itmret.data.Msg.length > 0) {
          this.setState({
            errMsg: "Select the item to write off",
            errFlag: false,
            items: itmret.data.Msg,
          });
        } else {
          this.setState({
            errFlag: true,
            errMsg:
              "No items found with the search condition in the warehouse.",
          });
        }
      } else {
        this.setState({
          errFlag: true,
          errMsg: itmret.data.Msg,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleInventoryWriteoff = async (post) => {
    // console.log("post:", post);
    this.setState({
      selectedItem: post,
      itemVarience: post.itemVariance,
    });
  };

  handleSubItemWriteoff = async (post) => {
    // console.log("post:", post);
    let errMsg = "",
      errFlag = false;
    if (post.quantity === 0) {
      errMsg = "Nothing to write off from.";
      errFlag = true;
    }
    this.setState({
      itemSubToWriteOff: post.name,
      maxItemToWrietOff: post.quantity,
      refseqno: post.seqno,
      showSubItemTowoFlag: true,
      errMsg,
      errFlag,
    });
  };

  onChangeExpAmount = async (e) => {
    let value = e.target.value;
    // let name = [e.target.name];
    // console.log("name:", name[0]);
    // await this.setState({ [e.target.name]: e.target.value });
    if (
      value <= this.state.maxItemToWrietOff &&
      this.state.maxItemToWrietOff !== 0
    ) {
      if (this.countDecimals(value) > 2) {
        let tmp = parseFloat(value).toFixed(2);
        let val = parseFloat(tmp);
        this.setState({
          [e.target.name]: val,
        });
      } else {
        this.setState({
          [e.target.name]: value,
        });
      }
    } else {
      this.setState({
        errMsg: "There is nothing to write off from.",
        errFlag: true,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ReferenceHub...");

    let refExitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToExpense}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let eventNameSearch = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={this.getEventsForBaanda}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search" />
      </button>
    );
    let doneButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.returnToExpWithData}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Done
      </button>
    );

    let refTypeSelPanel;
    refTypeSelPanel = (
      <div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Reference type:&nbsp;&nbsp;</strong>
            <font color="green">{this.state.refName}</font>
          </div>
        </div>
      </div>
    );

    let memberList;
    if (this.props.members.length > 0) {
      memberList = this.props.members.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj.name}&nbsp;({obj.email})
          </option>
        );
      });
    }

    let selectMemberPanel;
    selectMemberPanel = (
      <div className="row ref-event-host-dd">
        <div className="col-3">&nbsp;</div>
        <div className="col-6">
          <select
            size={this.state.selheightmem}
            id="expReferenceMembers"
            name="expReferenceMembers"
            onChange={this.handleExpMemberSelect}
            className="exp-ref-member-dd"
            onFocus={this.onfocusbc}
            onBlur={this.onblurbc}
            defaultValue={"DEFAULT"}
          >
            <option value="DEFAULT" disabled>
              Select the member
            </option>
            {memberList}
          </select>
        </div>

        <div className="col-3">&nbsp;</div>
      </div>
    );

    let selectEventPanel;
    if (this.state.theHost) {
      selectEventPanel = (
        <div>
          <div className="row search-event-name-ref">
            <div className="col-3 text-right sevent-ref-name-text">
              Event Name
            </div>
            <div className="col-8 text-left">
              <input
                name="eventName"
                type="text"
                value={this.state.eventName}
                onChange={this.onChange}
                size="14"
                maxLength="14"
                className="event-ref-name-text"
                placeholder="Name in 14 chr"
              />
              &nbsp;{eventNameSearch}
            </div>
          </div>
        </div>
      );
    }

    let eventListpanel;
    let bool = true;
    // if (this.state.events.length > 0 && this.state.showEventsFlag) {
    if (this.state.events.length > 0 && this.state.showEventsFlag) {
      eventListpanel = (
        <div>
          <div className="row sub-item-list-row">
            <div className="col-2 text-center sub-item-list-head">Ops</div>
            <div className="col-9 text-center sub-item-list-head">
              Event Name | (eventId)
            </div>
          </div>
          <div className="fixedsize-exp-evt-list">
            {this.state.events.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-2 text-center">
                      <button
                        className="btn_reg_50"
                        type="button"
                        onClick={() => this.selectThisEvent(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Sel
                      </button>
                    </div>

                    <div className="text-left col-10">
                      {obj.eventName}&nbsp;&nbsp;({obj.eventId}){" "}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
          <div
            className={
              this.state.errFlag
                ? "text-center sub-item-msg-err"
                : "text-center sub-item-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    } else {
      eventListpanel = (
        <div className="text-center no-event-for-member">
          {this.state.eventMsg}
        </div>
      );
    }

    let prepTaskPanel;
    let bool1 = true;
    if (this.state.prepTasks.length > 0) {
      prepTaskPanel = (
        <div className="text-center">
          <div className="row prep-task-box-pos">
            <div className="col-2 text-center sub-item-list-head">Ops</div>
            <div className="col-9 text-left sub-item-list-head">
              Preptask Name | (Amount Allocated)
            </div>
          </div>
          <div className="fixedsize-prep-task-list">
            {this.state.prepTasks.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool1
                        ? "row row-col-schdule-dr"
                        : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-2 text-center">
                      <button
                        className="btn-sub-line-edit"
                        type="button"
                        onClick={() => this.selectThisPreptask(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        This
                      </button>
                    </div>

                    <div className="text-left col-10">
                      {obj.taskName}&nbsp;&nbsp;($
                      {this.commaFormattedCurrency(obj.amountAllocated)}){" "}
                    </div>
                  </div>
                  {(bool1 = !bool1)}
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      prepTaskPanel = (
        <div className="text-center no-prep-task-msg">
          {this.state.prepTskMsg}
        </div>
      );
    }

    let eventName = "";
    let prepTaskName = "";
    let showSelected = false;
    if (this.state.selectedEvent)
      eventName = this.state.selectedEvent.eventName;
    if (this.state.selectedPrepTask) {
      prepTaskName = this.state.selectedPrepTask.taskName;
      showSelected = true;
    }

    let payrollSplPanel;
    payrollSplPanel = (
      <div className="row worker-type-row-pos">
        <div className="col text-center radio-font-style">
          <strong>HR Payment is&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="time-based"
                checked={this.state.serviceType === "time-based"}
                onChange={this.handlePayBasedOn}
              />{" "}
              Time-based Pay
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="delivery-based"
                checked={this.state.serviceType === "delivery-based"}
                onChange={this.handlePayBasedOn}
              />{" "}
              Delivery-based Pay
            </label>
          </div>
        </div>
      </div>
    );

    let payOnDateRangePanel;
    if (this.state.serviceType === "time-based") {
      payOnDateRangePanel = (
        <div>
          <div className="text-center date-range-head">Payment Date Range</div>
          <div className="row">
            <div className="col-1 text-right exp-ref-date-range">Start</div>
            <div className="col-5">
              <DatePicker
                selected={this.state.startDate}
                onSelect={this.handleStartDate}
                onChange={this.handleStartDate}
                // minDate={new Date()}
                name="startDate"
                dateFormat="MM/dd/yyyy"
                className="expense-date"
              />
            </div>
            <div className="col-1 text-right exp-ref-date-range">End</div>
            <div className="col-5">
              <DatePicker
                selected={this.state.endDate}
                onSelect={this.handleEndDate}
                onChange={this.handleEndDate}
                minDate={this.state.endDate}
                name="endDate"
                dateFormat="MM/dd/yyyy"
                className="expense-date"
              />
            </div>
          </div>
        </div>
      );
    }

    // Writeoff section
    let subCategoryPanel;
    if (this.props.categoryOf === "Writeoff") {
      if (this.props.subCategory.toLowerCase() === "Inventory") {
        subCategoryPanel = (
          <div className="text-center exp-ref-wo-subcat">
            Sub Category: Inventory Item
          </div>
        );
      } else {
        subCategoryPanel = (
          <div className="text-center exp-ref-wo-subcat">
            Sub Category: Capital Asset
          </div>
        );
      }
    }

    // let inventoryFromPanel;
    // if (
    //   this.props.categoryOf === "Writeoff" &&
    //   this.props.subCategory.toLowerCase() === "inventory"
    // ) {
    //   inventoryFromPanel = (
    //     <div className="row writeoff-type-row-pos">
    //       <div className="col text-center radio-font-style">
    //         <strong>Writeoff inventory from&nbsp;</strong>
    //         <div className="form-check form-check-inline">
    //           <label className="form-check-label">
    //             <input
    //               className="form-check-input"
    //               type="radio"
    //               value="catalog"
    //               checked={this.state.inventoryType === "catalog"}
    //               onChange={this.handleInventoryType}
    //             />{" "}
    //             Catalog
    //           </label>
    //         </div>
    //         <div className="form-check form-check-inline">
    //           <label className="form-check-label">
    //             <input
    //               className="form-check-input"
    //               type="radio"
    //               value="warehouse"
    //               checked={this.state.inventoryType === "warehouse"}
    //               onChange={this.handleInventoryType}
    //             />{" "}
    //             Warehouse
    //           </label>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }

    let itemSearchButton;
    itemSearchButton = (
      <button
        className="btn_reg_50 exp-ref-item-search-btn-pos"
        type="button"
        onClick={this.searchForItems}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search" />
      </button>
    );

    let searchItemPanel;
    if (this.props.categoryOf === "Writeoff") {
      searchItemPanel = (
        <div>
          <div className="row exp-top-rows-pos">
            <div className="col-3 text-right exp-top-pnl-lbl">Item Name</div>
            <div className="col-6 text-left exp-bc-pos">
              <input
                name="itemName"
                type="text"
                value={this.state.itemName}
                onChange={this.onChange}
                size="60"
                maxLength="60"
                className="exp-ref-item-name"
                placeholder=""
              />
            </div>
            <div className="col-3 text-left">{itemSearchButton}</div>
          </div>
        </div>
      );
    }

    let showItemsPanel;
    let bool20 = true;

    // if (this.state.showpagi && this.props.calledFor === "draft")
    if (this.props.categoryOf === "Writeoff") {
      showItemsPanel = (
        <div>
          <div className="row inventory-main-header">
            <div className="col-10 text-right">Item Name</div>

            <div className="col-2 text-center">Ops</div>
          </div>
          <div className="the-wo-item-hl-box">
            {this.state.items.map((post, i) => (
              <div key={i}>
                <div
                  className={`${
                    bool20 ? "raw-color-inv-dr" : "raw-color-inv-lt"
                  }`}
                >
                  <div className="row">
                    <div className="col-10 text-left pickup-text">
                      {post.itemName}
                    </div>
                    <div className="col-2 text-center inv-list-but-pos">
                      <button
                        //   onClick={() => this.handleInventory(JSON.stringify(post))}
                        onClick={() => this.handleInventoryWriteoff(post)}
                        className="btn_reg_50"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Open
                      </button>
                    </div>
                  </div>
                </div>
                {(bool20 = !bool20)}
              </div>
            ))}
          </div>
        </div>
      );
    }

    let itemVariancePanel;
    let bool30 = true;
    if (this.props.categoryOf === "Writeoff") {
      itemVariancePanel = (
        <div>
          <div className="row inventory-main-header">
            <div className="col-8 text-left">Name & options</div>
            <div className="col-2 text-center">Qty</div>
            <div className="col-2 text-center">Ops</div>
          </div>
          <div className="the-wo-item-hl-box">
            {this.state.itemVarience.map((obj, i) => (
              <div key={i}>
                <div
                  className={`${
                    bool30 ? "raw-color-inv-dr" : "raw-color-inv-lt"
                  }`}
                >
                  <div className="row">
                    <div className="col-8 text-left pickup-text">
                      {obj.name};&nbsp;{obj.options}
                    </div>
                    <div className="col-2 text-center inv-list-but-pos">
                      {obj.quantity}
                    </div>
                    <div className="col-2 text-center inv-list-but-pos">
                      <button
                        //   onClick={() => this.handleInventory(JSON.stringify(obj))}
                        onClick={() => this.handleSubItemWriteoff(obj)}
                        className="btn_reg_50"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Open
                      </button>
                    </div>
                  </div>
                </div>
                {(bool30 = !bool30)}
              </div>
            ))}
          </div>
        </div>
      );
    }

    let finalwofPanel;
    if (
      this.props.categoryOf === "Writeoff" &&
      this.state.showSubItemTowoFlag
    ) {
      finalwofPanel = (
        <div className="row final-ro-row-pos">
          <div className="col-5 final-ro-lbl">
            {this.state.itemSubToWriteOff}
          </div>
          <div className="col-2 final-ro-lbl">
            Max: {this.state.maxItemToWrietOff}
          </div>
          <div className="col-3 ">
            WO:&nbsp;
            <input
              name="qtyToWriteOff"
              type="number"
              value={this.state.qtyToWriteOff}
              onChange={this.onChangeExpAmount}
              className="exp-percent-inp"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />{" "}
          </div>
          <div className="col-2">{!this.state.errFlag ? doneButton : null}</div>
        </div>
      );
    }

    // ------------------------------------------------------

    let eventPanel;
    let outputPanel;
    if (this.state.referenceType === "Event" && this.state.showEventsFlag) {
      eventPanel = <div>{selectEventPanel}</div>;
      outputPanel = (
        <div className="the-reference-box">
          <div className="text-center exp-ref-header">
            Expense Reference&nbsp;{refExitButton}
          </div>
          {refTypeSelPanel}
          {selectMemberPanel}
          {eventPanel}
          {eventListpanel}
          <div className="row">
            <div className="col-3">&nbsp;</div>
            <div className="col-6">{prepTaskPanel}</div>
            <div className="col-3">&nbsp;</div>
          </div>
          {showSelected ? (
            <div className="row">
              <div className="col-2">&nbsp;</div>
              <div className="col-8 show-exp-event-tasks">
                Selected Event: <font color="green">{eventName}</font> |
                PrepTask: <font color="green">{prepTaskName}</font>
              </div>
              <div className="col-2 text-left">{doneButton}</div>
            </div>
          ) : null}
        </div>
      );
    }

    if (
      this.state.referenceType.toLowerCase() === "payroll" &&
      this.state.showPayrollFlag
    ) {
      outputPanel = (
        <div className="the-reference-box">
          <div className="text-center exp-ref-header">
            Expense Reference&nbsp;{refExitButton}
          </div>
          {refTypeSelPanel}
          {selectMemberPanel}
          {payrollSplPanel}
          {payOnDateRangePanel}
          {this.state.theHost ? (
            <div className="row target-server-row">
              <div className="col-2">&nbsp;</div>
              <div className="col-8 show-exp-event-tasks">
                Target Server:{" "}
                <font color="green">
                  {this.state.theHost.name} ({this.state.theHost.email})
                </font>{" "}
                |
              </div>
              <div className="col-2 text-left">{doneButton}</div>
            </div>
          ) : null}
        </div>
      );
    }

    if (
      this.state.referenceType.toLowerCase() === "writeoff" &&
      this.state.showWriteOffFlag
    ) {
      outputPanel = (
        <div className="the-reference-box">
          <div className="text-center exp-ref-header">
            Expense Special Reference&nbsp;{refExitButton}
          </div>
          {refTypeSelPanel}
          {subCategoryPanel}

          {searchItemPanel}
          {showItemsPanel}
          {itemVariancePanel}
          {finalwofPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center exp-ref-msg-err"
                : "text-center exp-ref-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

referenceHub.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

//   const mapDispatchToProps = (dispatch) => ({
//     hideModal: () => dispatch(hideModal()),
//     showModal: (modalProps, modalType) => {
//       dispatch(showModal({ modalProps, modalType }));
//     },
//   });

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(withRouter(referenceHub));
