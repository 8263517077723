import React, { Component } from "react";

import "./ReturnPolicy.css";

class ReturnPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  returnToPagi = () => {
    this.props.returnToCaller();
  };
  render() {
    // console.log("this.props:", this.props);
    console.log('ReturnPolicy...');

    let decider = 'Not Set';
    if (this.props.returnTraits.decider === 'business') decider = "Business";
    if (this.props.returnTraits.decider === 'noquestionsasked') decider = "No questions asked."; 
    if (this.props.returnTraits.decider === 'noreturns') decider = "No returns allowed."; 

    let displayPanel;
    if (this.props.returnTraits && this.props.returnTraits.clauseText !== '') {
      displayPanel = (
        <div>
          <div className="row ret-cat-display-pos">
            <div className="col-3 ret-catalog-col-lbl text-right">Rules</div>
            <div className="col-9 ret-catalog-col-text text-left">
              {this.props.returnTraits.clauseText}
            </div>
          </div>
          <div className="row ret-cat-display-pos">
            <div className="col-3 ret-catalog-col-lbl text-right">Within</div>
            <div className="col-9 ret-catalog-col-text text-left">
              {this.props.returnTraits.withinDays}&nbsp;days.
            </div>
          </div>
          <div className="row ret-cat-display-pos">
            <div className="col-3 ret-catalog-col-lbl text-right">Decision</div>
            <div className="col-9 ret-catalog-col-text text-left">
              {decider}
            </div>
          </div>
        </div>
      );
    } else {
      displayPanel = (
        <div>
          <div className="row ret-cat-display-pos">
            <div className="col-3 ret-catalog-col-lbl text-right">Rules</div>
            <div className="col-9 ret-catalog-col-text text-left">
              No return policy has been set by this community
            </div>
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div className="return-message-box">
        <div className="catalog-ret-header">Return Policy</div>
        {displayPanel}
        <div>
          <button
            onClick={this.returnToPagi}
            className="btn-exit-return-policy"
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            X
          </button>
        </div>
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

export default ReturnPolicy;
