import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./HandleReturnPost.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateCustomerRefundState = "/routes/finance/updateCustomerRefundState";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1115111112";

class HandleReturnPost extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      selheightcustret: 1,
      selectedRefundObj: null,

      labelurl: "",
      labelTrack: "",
      labelExist: false,
      returnTaskDone: false,
      refundPostageRequested: false,
      customerTaskDone: false,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let selectedRefundObj = null;
    let labelurl = "";
    let labelTrack = "";
    let labelExist = false;
    this.props.invoice.refunds.forEach((ref) => {
      if (ref.refundCancelId === this.props.selectedLine.refundCancelId) {
        selectedRefundObj = ref;
        if (selectedRefundObj.returnPostageObject) {
          labelurl = selectedRefundObj.returnPostageObject.label_url;
          labelTrack = selectedRefundObj.returnPostageObject.trackingId;
          labelExist = true;
        }
      }
    });

    let refundPostageRequested = false;
    let customerTaskDone = false;
    this.props.invoice.refunds.forEach((re) => {
      if (re.refundCancelId === selectedRefundObj.refundCancelId) {
        refundPostageRequested = selectedRefundObj.requestReturnPostage;
        customerTaskDone = selectedRefundObj.customerRefundDoneFlag;
      }
    });

    this.setState({
      deviceSize,
      selectedRefundObj,
      labelurl,
      labelTrack,
      labelExist,
      refundPostageRequested,
      customerTaskDone,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleRefundSelected = async () => {
    // console.log("in custRefundList");
    let value = document.getElementById("custRefundList").value;
    // console.log("value:", value);
    let valobj = JSON.parse(value);
    // console.log("valobj:", valobj);
    await this.setState({
      selheightcustret: 1,
      selectedRefundObj: valobj,
    });
  };

  onfocuspkg = async () => {
    await this.setState({
      selheightcustret: 4,
    });
  };

  onblurpkg = async () => {
    this.setState({
      selheightcustret: 1,
    });
  };

  openPdfModal = async () => {
    await this.setState({
      showImageFlag: false,
    });
    let msg = {
      Header: "Shipping Label",
      Body: {
        s3Url: this.state.labelurl,
        fileName: this.state.labelTrack,
        deviceSize: this.state.deviceSize,
        footnote: "",
      },
      Footer: "",
    };

    this.props.showModal(
      {
        open: true,
        title: "Alert - Start Here Header XXX used??",
        message: msg,
        closeModal: this.closeModal,
      },
      "showPdf"
    );
  };

  handleCustomerReturnState = async () => {
    this.setState((prevstate) => ({
      returnTaskDone: !prevstate.returnTaskDone,
    }));
  };

  returnToRefundMgmt = () => {
    this.props.returnToCaller();
  };

  updateCustomerState11 = async () => {
    console.log("updateCustomerState sadsa..........");
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let input = {
      communityId: this.props.communityId,
      coopStoreId,
      invoiceId: this.props.invoice.invoiceId,
      refundCancelId: this.props.selectedLine.refundCancelId,
      customerStatus: this.state.returnTaskDone,
    };

    try {
      let url = baandaServer + updateCustomerRefundState;
      // console.log("url:", url, " input:", input);
      let cusret = await axios.post(url, input);
      // console.log("cusret.data:", cusret.data);
      if (cusret.data.status === "success") {
        this.props.returnToCaller();
      } else {
        this.setState({
          errMsg: cusret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("PriorRefunCancelOps...");

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToRefundMgmt}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let showLabelButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.openPdfModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Label
      </button>
    );

    let taskUpdateButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.updateCustomerState11}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Update
      </button>
    );

    let refundListPanel;
    if (this.state.selectedRefundObj) {
      if (this.props.invoice.spType !== "spotdeliverd") {
        if (this.state.selectedRefundObj.requestReturnPostage) {
          if (this.state.refundPostageRequested) {
            if (
              this.state.selectedRefundObj.returnPostageObject &&
              this.state.selectedRefundObj.returnPostageObject.carrierName !==
                ""
            ) {
              if ( this.state.customerTaskDone) {
                refundListPanel = (
                  <div className="cust-refund-list-msg">
                    Return postage has been provided by the seller as above.  
                  </div>
                );
              } else {
                refundListPanel = (
                  <div className="cust-refund-list-msg">
                    Return postage has been provided as above. Please print the label & return the item.
                  </div>
                );
              }

            } else {
              refundListPanel = (
                <div className="cust-refund-list-msg">
                  Return postage has not been provided yet. Contact seller.
                </div>
              );
            }
          } else {
            refundListPanel = (
              <div className="cust-refund-list-msg">
                No return postage has been requested.
              </div>
            );
          }
        } else {
          refundListPanel = (
            <div className="cust-refund-list-msg">
              No refund postage requested. RefunCancelId:{" "}
              {this.props.selectedLine.refundCancelId}
            </div>
          );
        }
      } else {
        refundListPanel = (
          <div className="cust-refund-list-msg">
            Items of this invoice needs in-person transaction.
          </div>
        );
      }
    }

    let itemsToReturnPanel;
    let bool = true;
    if (this.state.selectedRefundObj) {
      let ir = this.state.selectedRefundObj.itemsReturnedInThis;
      itemsToReturnPanel = (
        <div>
          <div className="row cust-return-item-list-pos">
            <div className="col-10 text-left cust-return-item-list-header">
              Item Name | Price | Quantity
            </div>
            <div className="col-1 text-left cust-return-item-list-header">
              Amt
            </div>
          </div>
          <div className="to-return-cust-items-box">
            {ir.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-10 text-left refresp-list-text">
                      {obj.itemName} | ${this.commaFormattedCurrency(obj.price)}{" "}
                      | {obj.quantity}{" "}
                    </div>
                    <div className="col-2 text-left refresp-list-text">
                      ${this.commaFormattedCurrency(obj.price * obj.quantity)}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let lblurl, trkid; // trackingurl,
    if (this.state.deviceSize === "big") {
      // trackingurl = "Tracking Url";
      lblurl = "Label Url";
      trkid = "Tracking Id";
    } else {
      // trackingurl = "Trk-U";
      lblurl = "lbl-U";
      trkid = "TrkId";
    }

    let postagePanel;
    if (this.state.selectedRefundObj) {
      if (this.state.selectedRefundObj.returnPostageObject) {
        let rpo = this.state.selectedRefundObj.returnPostageObject;
        postagePanel = (
          <div className="to-return-postage-box ">
            <div className="text-center cust-postage-detail-header">
              Postage Details
            </div>
            <div className="row">
              <div className="col-2 text-right cust-postage-detail-lbl">
                Carrier
              </div>
              <div className="col-10 text-left cust-postage-detail-lbl">
                {rpo.carrierName}
              </div>
            </div>
            <div className="row">
              <div className="col-2 text-right cust-postage-detail-lbl">To</div>
              <div className="col-10 text-left cust-postage-detail-lbl">
                {rpo.byName}
              </div>
            </div>
            <div className="row">
              <div className="col-2 text-right cust-postage-detail-lbl">
                {lblurl}
              </div>
              <div className="col-10 text-left cust-postage-detail-lbl">
                {rpo.label_url}
              </div>
            </div>
            <div className="row">
              <div className="col-2 text-right cust-postage-detail-lbl">
                {trkid}
              </div>
              <div className="col-10 text-left cust-postage-detail-lbl">
                {rpo.trackingId}
              </div>
            </div>
            <div className="row">
              <div className="col-2 text-right cust-postage-detail-lbl">
                Label
              </div>
              <div className="col-10 text-left cust-postage-detail-lbl">
                {showLabelButton}
                {rpo.trackingUrl === "" ? null : (
                  // <div className="text-center ship-tracking-url">
                  <a href={rpo.trackingUrl} target="_blank" rel="noreferrer">
                    Track Return Shipment
                  </a>
                  // </div>
                )}
              </div>
            </div>
          </div>
        );
      } else {
        if (this.props.invoice.spType === "ship") {
          postagePanel = (
            <div className="cust-refund-list-msg">
              Refund postage has not been allocated.
            </div>
          );
        } else {
          postagePanel = (
            <div className="test-center cust-refund-list-msg">
              In person return.
            </div>
          );
        }
      }
    }

    let completePanel;
    if ( this.props.invoice.spType === 'spotdeliverd') {
      completePanel = (
        <div className="text-center cust-return-state-pos">
          <div className="row">
            <div className="col text-center">
              For over the counter sell, return must be handled in-person.
            </div>
          </div>
        </div>
      );
    } else {
      if (this.state.customerTaskDone) {
        completePanel = (
          <div className="text-center cust-return-state-pos">
            <div className="row">
              <div className="col text-center">
                All your tasks is done. Contact seller if refund has not been
                handled.
              </div>
            </div>
          </div>
        );
      } else {
        completePanel = (
          <div className="text-center cust-return-state-pos">
            <div className="row">
              <div className="col-7 text-right">
                {" "}
                <Checkbox
                  checked={this.state.returnTaskDone}
                  onChange={this.handleCustomerReturnState}
                />{" "}
                Return Tasks Completed &nbsp;&nbsp;
              </div>
              <div className="col-5 text-left">
                {this.state.returnTaskDone ? taskUpdateButton : null}
              </div>
            </div>
          </div>
        );
      }
    }


    let outputPanel = (
      <div className="row">
        <div className="col">
          <div className="return-box">
            <div className="text-center cust-ret-post-header">
              Return Posting {infoButton} {backButton}
            </div>

            <div className="">{itemsToReturnPanel}</div>
            <div className="">{postagePanel}</div>
            <div className="">{refundListPanel}</div>
            <div className="">{completePanel}</div>
          </div>
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

HandleReturnPost.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HandleReturnPost)
);
