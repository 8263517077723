import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../../modal/components/ModalContainer";
import {
  showModal,
  hideModal,
} from "../../../../../../../actions/modalActions";
import "../../../../../../../modal/css/localModal.css";
import "../../../../../../../modal/css/template.css";

import StoreRecvPay from "./StoreRecvPay";

import "./RecvPayable.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCoopExchangeList = "/routes/dashboard/getCoopExchangeList?";
const saveCreditsPaid = "/routes/coop/saveCreditsPaid";
const getAStoreById = "/routes/coop/getAStoreById?";

// const yourtimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const readMeCode    = "1111211100";
// const readMeCode = "0000000000";

class RecvPayable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      stores: [],
      selectedStore: null,
      thisStore: null,

      errMsg: "",
      errFlag: false,

      coopCommFlag: false,
      coopStoreFlag: false,

      roleOfUser: "",

      showPayStateSummary: true,
      showStoreRecvPayFlag: false,
      // showCommunityRecvPayFlag: false,

      creditsRecorded: 0.0,
      creditNote: "",
      credits: 0,

      isDue: false,
      payFlag: false,
      pendingRent: 0,
      commToPayCr: 0,
      creditPayMsg: "",

      asOfNow: new Date(),
      carryOverAmt: 0,
      carryOverMsg: "",

      totalPayMsg: "",
      commToPay: 0,
      discountFlag: false,
      payDirection: "",
    };
  }

  componentDidMount = async () => {
    await this.getCoopPayStates();

    let coopCommFlag = false;
    let coopStoreFlag = false;
    let roleOfUser = "";
    if (this.props.role === "storeowner") {
      coopStoreFlag = true;
      roleOfUser = this.props.role;
    } else {
      coopCommFlag = true;
      roleOfUser = this.props.role;
    }

    this.setState({
      coopCommFlag,
      coopStoreFlag,
      roleOfUser,
    });

    if (this.props.role === "storeowner")
      await this.setPaymentValues(this.state.selectedStore);
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getCoopPayStates = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let param =
      "communityId=" + this.props.communityId + "&coopStoreId=" + coopStoreId;
    let url = baandaServer + getCoopExchangeList + param;
    // console.log("url:", url);
    try {
      let cpsret = await axios.get(url);
      if (cpsret.data.status === "success") {
        let selectedStore = null;
        if (this.props.store) {
          selectedStore = cpsret.data.Msg[0];
          selectedStore.contact = this.props.store.contact;
          selectedStore.displayStoreName = this.props.store.displayStoreName;
          selectedStore.displayStoreKeeper =
            this.props.store.displayStoreKeeper;
        }
        this.setState({
          stores: cpsret.data.Msg,
          selectedStore,
          errFlag: false,
          errMsg: "",
        });
      } else {
        this.setState({
          errMsg: cpsret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleSaveCredit = async () => {
    if (
      parseFloat(this.state.creditsRecorded) > 0 &&
      this.state.creditNote !== ""
    ) {
      let st = this.state.selectedStore;
      let direction = "";
      if (this.state.roleOfUser === "storeowner") direction = "storeTocomm";
      else direction = "commTostore";
      let creditObj = {
        amount: parseFloat(this.state.creditsRecorded),
        note: this.state.creditNote,
        direction,
      };
      let input = {
        communityId: st.communityId,
        coopStoreId: st.coopStoreId,
        creditObj,
      };
      let url = baandaServer + saveCreditsPaid;

      try {
        let cret = await axios.post(url, input);
        // console.log("cret:", cret);
        if (cret.data.status === "success") {
          this.setState({
            errMsg: "Successfully recorded",
            errFlag: false,
            creditsRecorded: 0.0,
            creditNote: "",
          });
        } else {
          this.setState({
            errMsg: cret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: "To record, credits must be a positive number and has a note.",
        errFlag: true,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  returnToFinOps = () => {
    this.props.returnToCaller();
  };

  handleShowCoopPayState = async (store) => {

    this.setState({
      coopStoreFlag: true,
      coopCommFlag: false,
      selectedStore: store,
    });
    await this.setPaymentValues(store);
  };

  ShowStoreListForOwner = () => {
    this.setState({
      coopStoreFlag: false,
      coopCommFlag: true,
      selectedStore: null,
      thisStore: null,
    });
  };

  calcMonthspassed = (startdate) => {
    // console.log("startdate:", startdate);
    let today = new Date();
    let std = new Date(startdate);
    return (
      today.getMonth() -
      std.getMonth() +
      12 * (today.getFullYear() - std.getFullYear())
    );
  };

  overdueByDays = (enddate) => {
    const date2 = new Date();
    const date1 = new Date(enddate);

    // One day in milliseconds
    const oneDay = 1000 * 60 * 60 * 24;

    // Calculating the time difference between two dates
    const diffInTime = date2.getTime() - date1.getTime();

    // Calculating the no. of days between two dates
    const diffInDays = Math.round(diffInTime / oneDay);

    return diffInDays;
  };

  handlePaymentReceive = async () => {
    this.setState({
      showStoreRecvPayFlag: true,
      showPayStateSummary: false,
    });
  };

  getTheRelatedStore = async (store) => {
    let params =
      "communityId=" + store.communityId + "&coopStoreId=" + store.coopStoreId;
    let url = baandaServer + getAStoreById + params;
    // console.log("url:", url);
    try {
      let stret = await axios.get(url);
      if (stret.data.status === "success") {
        this.setState({
          thisStore: stret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          thisStore: null,
          errMsg:
            "Store not found with coopStoreId=" +
            store.coopStoreId +
            " Contact baanda support.",
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        thisStore: null,
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  calcPendingRent = () => {
    // Add a month to period start date of the selected store (current record in coopPayState)
    let m1 = new Date(this.state.selectedStore.periodStartDate);
    let nextMonthDate = m1.setMonth(m1.getMonth() + 1);

    let nm = new Date(nextMonthDate).getMonth() + 1;

    let mstr = "";
    if (nm < 10) mstr = "0" + nm;
    else mstr = nm;
    let nyyyy = new Date(nextMonthDate).getFullYear();

    // First day of next months of period start date of current coop pay state is  ...
    let srtOfNMonth = mstr + "/01/" + nyyyy;

    // number of months to calculate rent pending
    let numOfmon = 0;
    if (new Date() > new Date(srtOfNMonth)) {
      numOfmon = this.calcMonthspassed(new Date(srtOfNMonth)) + 1;
    }
    let pendingRent =
      numOfmon * this.state.thisStore.agreementMaster.fixedRent +
      this.state.selectedStore.fixedRent;

    this.setState({
      pendingRent,
    });
  };

  calculateIfPaymentIsDue = () => {
    let isDue = false;
    if (new Date() > new Date(this.state.selectedStore.periodEndDate))
      isDue = true;
    this.setState({
      isDue,
    });
  };

  calculateCreditsDueByComm = () => {
    let commToPayCr = 0;
    this.state.selectedStore.credits.forEach((cr) => {
      if (cr.direction === "commTostore") commToPayCr -= cr.amount;
      if (cr.direction === "storeTocomm") commToPayCr += cr.amount;
    });

    let creditPayMsg = "";
    if (commToPayCr < 0)
      creditPayMsg =
        "Store to re-pay/adjust $" +
        this.commaFormattedCurrency(commToPayCr * -1) +
        " to the community.";
    if (commToPayCr > 0)
      creditPayMsg =
        "Community to re-pay/adjust $" +
        this.commaFormattedCurrency(commToPayCr) +
        " to the store.";
    if (commToPayCr === 0) creditPayMsg = "No credits to be adjusted.";

    this.setState({
      commToPayCr,
      creditPayMsg,
    });
  };

  setPaymentValues = async (store) => {
    await this.getTheRelatedStore(store);

    // For store to pay comm due to cash trade
    let x = parseFloat(this.state.selectedStore.saleCashAmount); // get the props value
    // For commuity to pay store due to CC based trade
    let y = parseFloat(this.state.selectedStore.saleCCAmount); // get the value from props

    this.calcPendingRent();

    let rm = this.state.pendingRent;
    this.calculateCreditsDueByComm();

    let cr = this.state.commToPayCr;
    let co = 0;
    let coMsg = "No Carry Over.";
    if (this.state.selectedStore.carryOver) {
      if (this.state.selectedStore.carryOver.direction === "commTostore") {
        co = this.state.selectedStore.carryOver * -1;
        coMsg =
          "Community to pay $" +
          this.commaFormattedCurrency(co) +
          " as carry forward.";
      }
      if (this.state.selectedStore.carryOver.direction === "storeTocomm") {
        co = this.state.selectedStore.carryOver;
        coMsg =
          "Store to pay $" +
          this.commaFormattedCurrency(co) +
          " as carry forward.";
      }
    }

    // CommunityToPay = ctp (prior to calculation of fines - if any)
    let ctp = y - x - rm + cr + co;

    // Calculate discount ... if applicable.
    let discountFrom = new Date(this.state.thisStore.membershipStartDate);
    let discountTill = discountFrom.setDate(
      discountFrom.getDate() +
        this.state.thisStore.agreementMaster.discountForMonths
    );
    let discountFlag = false;
    if (new Date() > discountTill) {
      discountFlag = true;
    }

    // calculate interest
    let lateFeeFlag = false;
    let lateFee = 0;
    let paydate = new Date(this.state.selectedStore.periodEndDate);

    let gracedDate = paydate.setDate(
      paydate.getDate() + this.state.thisStore.agreementMaster.graceInDays
    );

    if (new Date() > new Date(gracedDate)) {
      let d2 = new Date(gracedDate).getTime();

      let d1 = new Date().getTime();
      const aday = 1000 * 60 * 60 * 24;
      let daysLaps = Math.ceil((d1 - d2) / aday);

      let a1 = Math.abs(ctp);

      let feeWPer =
        a1 * (this.state.thisStore.agreementMaster.aprPercent / 100);

      lateFee = (feeWPer / 365) * daysLaps;
      lateFeeFlag = true;
    }

    let finalAmt = Math.abs(ctp) + lateFee;
    if (ctp < 0) finalAmt = finalAmt * -1;

    this.calculateIfPaymentIsDue();

    let payFlag = false;
    let payDirection = "";
    if (this.state.roleOfUser === "storeowner") {
      if (finalAmt < 0) {
        payFlag = true;
        payDirection = "storeTocomm";
      }
    } else {
      if (finalAmt > 0) {
        payFlag = true;
        payDirection = "commTostore";
      } else {
        payDirection = "storeTocomm";
      }
    }

    let totalPayMsg = "";
    if (finalAmt > 0)
      totalPayMsg =
        "Community to pay $" +
        this.commaFormattedCurrency(finalAmt) +
        " to the store";
    if (finalAmt < 0)
      totalPayMsg =
        "Store to pay $" +
        this.commaFormattedCurrency(finalAmt * -1) +
        " to the community";
    if (finalAmt === 0) totalPayMsg = "No payment due at this point of time.";

    this.setState({
      totalPayMsg,
      commToPay: Math.abs(finalAmt),
      carryOverAmt: co,
      carryOverMsg: coMsg,
      discountFlag,
      lateFee,
      lateFeeFlag,
      payFlag,
      payDirection,
    });
  };

  returnFromStoreRecvPay = (state) => {
    if (state === "success") {
      this.props.returnToCaller();
    } else {
      this.setState({
        showStoreRecvPayFlag: false,
        showPayStateSummary: true,
      });
    }
  };

  onChangeCredit = async (e) => {
    let value = e.target.value;
    if (value > 0) {
      if (this.countDecimals(value) > 2) {
        let tmp = parseFloat(value).toFixed(2);
        let val = parseFloat(tmp);
        this.setState({
          [e.target.name]: val,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          [e.target.name]: value,
          errMsg: "",
          errFlag: false,
        });
      }
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('RecvPayable...');

    let exitCoopPayHandler = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToFinOps}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let exitDetaileButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.ShowStoreListForOwner}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let payButton = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={this.handlePaymentReceive}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Pay
      </button>
    );

    let recordCreditButton = (
      <button
        className="btn_reg_90"
        type="button"
        onClick={this.handleSaveCredit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Record
      </button>
    );

    let helpButton;
    helpButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let storeHeadPanel;
    if (this.state.coopStoreFlag && this.state.selectedStore) {
      storeHeadPanel = (
        <div className="text-center co-ex-store-name">
          Store:{" "}
          {this.state.roleOfUser === "storekeeper"
            ? this.state.selectedStore.storeName
            : this.state.selectedStore.storeName}{" "}
          &nbsp;
          {this.state.roleOfUser === "coopowner" ? exitDetaileButton : null}
        </div>
      );
    }
    if (this.state.coopCommFlag) {
      storeHeadPanel = (
        <div className="text-center co-ex-comm-name">
          Cooperation Community HQ
        </div>
      );
    }

    let storeList;
    if (this.state.coopCommFlag) {
      let bool = true;
      storeList = (
        <div>
          <div className="row">
            <div className="col-5 text-left merchant-receive-header">
              StoreId Store-Name
            </div>
            <div className="col-5 text-left merchant-receive-header">
              Owner name - email
            </div>
            <div className="col-2 text-center inv-pend-header">Ops</div>
          </div>
          <div className="merchant-inv-receivable-box">
            {this.state.stores.map((post, i) => (
              <div key={i}>
                <div
                  className={`${
                    bool ? "recv-row-color-dr" : "recv-row-color-lt"
                  }`}
                >
                  <div className="row ">
                    <div className="col-4 text-left merchant-recv-lbl1">
                      {post.coopStoreId}&nbsp;{post.storeName}
                    </div>
                    <div className="col-6 text-left merchant-recv-lbl2">
                      {post.storeOwner}&nbsp;&nbsp;{post.ownerEmail}
                    </div>

                    <div className="col-2 text-center customer-inv-pend-open">
                      <button
                        onClick={() => this.handleShowCoopPayState(post)}
                        className="btn_reg_50_open"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Open
                      </button>
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            ))}
          </div>
        </div>
      );
    }

    // let toPayAmount = 0;
    // let toReceiveAmount = 0;
    // let payFlag = false;
    // let recvFlag = false;
    // let payDirection = "";
    // let payRecvMsg = "";

    let showSelectedStore;
    if (this.state.selectedStore) {
      let recordCreditPaidPanel;
      recordCreditPaidPanel = (
        <div className="coop-credit-box">
          <div className="row">
            <div className="col-3 text-right store-recvpay-lbl">
              Credit Note
            </div>
            <div className="col-9 text-left">
              <input
                name="creditNote"
                type="text"
                value={this.state.creditNote}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="credit-note-text"
                placeholder=""
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="row coop-credit-rowpos">
            <div className="col-3 text-right store-recvpay-lbl">
              Credits Paid$
            </div>
            <div className="col-9 text-left">
              <input
                name="creditsRecorded"
                type="number"
                value={this.state.creditsRecorded}
                onChange={this.onChangeCredit}
                className="coop-credit-paid"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />
              &nbsp;&nbsp;{recordCreditButton}
            </div>
          </div>
        </div>
      );

      showSelectedStore = (
        <div>
          <div className="text-center">
            <div className="row">
              <div className="col-3 text-right recv-pay-str-lbl">Owner</div>
              <div className="col-9 text-left recv-pay-str-info">
                {this.state.selectedStore.storeOwner}
              </div>
            </div>
            <div className="row">
              <div className="col-3 text-right recv-pay-str-lbl">Email</div>
              <div className="col-9 text-left recv-pay-str-info">
                {this.state.selectedStore.ownerEmail}
              </div>
            </div>
            <div className="row">
              <div className="col-3 text-right recv-pay-str-lbl">
                Period Start
              </div>
              <div className="col-9 text-left recv-pay-str-info">
                {this.state.selectedStore.periodStartDate.substring(0, 10)}
              </div>
            </div>
            <div className="text-center rec-pay-fin-head">
              {/* <u>Financial State</u> as of {new Date()} in zone: {yourtimezone}  */}
              <u>Financial State</u> as at {this.state.asOfNow.toString()}
            </div>
            <div className="row">
              <div className="col-3 text-right recv-pay-str-lbl">
                Sales Cash/Check
              </div>
              <div className="col-9 text-left recv-pay-str-info">
                ${" "}
                {this.commaFormattedCurrency(
                  this.state.selectedStore.saleCashAmount
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-3 text-right recv-pay-str-lbl">Sales CC</div>
              <div className="col-9 text-left recv-pay-str-info">
                ${" "}
                {this.commaFormattedCurrency(
                  this.state.selectedStore.saleCCAmount
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-3 text-right recv-pay-str-lbl">
                Carry Over
              </div>
              <div className="col-9 text-left recv-pay-str-info">
                $ {this.commaFormattedCurrency(this.state.carryOverAmt)} &nbsp;
                [{this.state.carryOverMsg}]
              </div>
            </div>

            <div className="row">
              <div className="col-3 text-right recv-pay-str-lbl">
                Rent To Pay
              </div>
              <div className="col-9 text-left recv-pay-str-info">
                $ {this.commaFormattedCurrency(this.state.pendingRent)}
                /month.
              </div>
            </div>

            <div className="row">
              <div className="col-3 text-right recv-pay-str-lbl">Credits</div>
              <div className="col-9 text-left recv-pay-str-info">
                {this.state.creditPayMsg}
              </div>
            </div>
            <div className="row">
              <div className="col-3 text-right recv-pay-str-lbl">Due Date</div>
              <div className="col-9 text-left recv-pay-str-info">
                {this.state.selectedStore.periodEndDate.substring(0, 10)}
              </div>
            </div>

            {this.state.lateFeeFlag ? (
              <div className="row">
                <div className="col-3 text-right recv-pay-str-lbl">
                  Late Fee Fine
                </div>
                <div className="col-9 text-left recv-pay-str-info">
                  $ {this.commaFormattedCurrency(this.state.lateFee)}
                </div>
              </div>
            ) : null}

            <div className="row">
              <div className="col-3 text-right recv-pay-str-lbl">
                Total Due Msg
              </div>
              <div className="col-9 text-left recv-pay-str-info-total">
                {this.state.totalPayMsg}
              </div>
            </div>

            {recordCreditPaidPanel}
            <div className="coop-recv-pay-btn-pos">
              {this.state.payFlag ? payButton : null}
            </div>
          </div>
        </div>
      );
    }

    let outputPanel;
    if (this.state.showPayStateSummary) {
      outputPanel = (
        <div className="coop-exchange-box">
          <div className="text-center coop-exchange-header">
            Coop Exchange &nbsp;{helpButton}&nbsp;{exitCoopPayHandler}
            {storeHeadPanel}
            {storeList}
            {showSelectedStore}
          </div>
          <div
            className={
              this.state.errFlag
                ? "text-center coex-rec-pay-msg-err"
                : "text-center coex-rec-pay-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    if (this.state.showStoreRecvPayFlag) {
      let commCreatorName = "";
      let commCreatorBaandaId = 0;
      let commCreatorEmail = "";
      let commCreatorCell = "";
      this.props.community.members.forEach((mem) => {
        if (mem.role === "creator") {
          commCreatorName = mem.name;
          commCreatorBaandaId = mem.baandaId;
          commCreatorEmail = mem.email;
          commCreatorCell = mem.cell;
        }
      });
      let communityInfo = {
        communityId: this.props.communityId,
        commName: this.props.commName,
        communityCheckPayTo: this.props.community.checkPayToName,
        stripeId: this.props.community.stripe.id,
        finMMDD: this.props.community.finMMDD,
        finYYYY: this.props.community.finYYYY,
        commCreatorName,
        commCreatorBaandaId,
        commCreatorEmail,
        commCreatorCell,
      };

      outputPanel = (
        <div className="coop-exchange-box">
          <StoreRecvPay
            returnToCaller={(state) => this.returnFromStoreRecvPay(state)}
            toPayAmount={this.state.commToPay}
            store={this.state.selectedStore}
            payFlag={this.state.payFlag}
            payRecvMsg={this.state.totalPayMsg}
            commName={this.props.commName}
            payDirection={this.state.payDirection}
            communityInfo={communityInfo}
            community={this.props.community}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

RecvPayable.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(RecvPayable)
);
