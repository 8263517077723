import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";

import DatePicker from "react-datepicker";
// import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./Converse.css";

import ShowMessages from "./present/ShowMessages";
import ShowNotification from "./present/ShowNotification";
import ShowEventResponse from "./present/ShowEventResponse";
import ShowBroadcast from "./present/ShowBroadcast";
import ShowEventPurchase from "./present/ShowEventPurchase";
import ComposeBmail from "../interact/bmail/ComposeBmail";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getSynapseMails = "/routes/interact/getSynapseMails?";
const deleteSynapseMail = "/routes/interact/deleteSynapseMail";
const getMailTagCategory = "/routes/interact/getMailTagCategory?";
const saveMailTagCategory = "/routes/interact/saveMailTagCategory";

const readMeCode = "1141000000";

class Converse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showpagi: true,
      showPaginationPanelFlag: false,
      listOfMessages: [],

      messages: [],
      currentPage: 1, // page selected to show the rows of items on that page
      noOfRowsPerPage: 13, // x (number of rows the show per page - constant)
      noOfButtonsToShow: 6, // y (number of buttons to show at the bottom - constant)
      fistPageOfSetIndex: 1, // f (first line of the set to display - vary -initiate with 1)
      messagesOnScreen: [],

      showMsgFlag: false,
      showNotificationFlag: false,
      showEventResponseFlag: false,
      showBroadcastFlag: false,
      showEventToPurchase: false,
      showPost: {},
      showWriteBamilFlag: false,

      deviceSize: "",

      toDelete: null,
      showConfirmDelete: false,
      deleteYesNo: "yes",

      showSearchPanel: false,
      selectedSearchType: "all",
      searchSender: "",
      searchSubject: "",
      dateFilterType: "all",
      // dateFirst: moment(),
      // dateSecond: moment(),
      dateFirst: new Date(),
      dateSecond: new Date(),

      mailCategories: [],
      showTagCategoryFlag: false,
      tagCategorySelected: "",
      seltagHeight: 1,
      selectedPostToTag: null,
      tagCategory: "",

      orderedBy: "dsc",
      attendee: "",

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let attendee = "";
    if (this.props.isMember) attendee = "member";
    else attendee = "customer";

    await this.setState({
      deviceSize,
      attendee,
    });

    await this.getmails();
    await this.getMailTagCategory();
    await this.setPosts();
    await this.setPageNumbers();
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getMailTagCategory = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&baandaId=" +
      this.props.auth.user.baandaId;
    let url = baandaServer + getMailTagCategory + params;
    // console.log('getMailTagCategory url:', url);
    let ret = await axios.get(url);
    // console.log('getMailTagCategory ret:', ret);
    if (ret.data.status.toLowerCase() === "success") {
      this.setState({
        mailCategories: ret.data.Msg,
      });
    } else {
      this.setState({
        errMsg: ret.data.Msg,
        errFlag: false,
      });
    }
    try {
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: false,
      });
    }
  };

  saveMailTagCategory = async () => {
    let itExists = false;
    if (this.state.tagCategory !== "") {
      this.state.mailCategories.forEach((obj) => {
        if (
          obj.tagCategory.toLowerCase() === this.state.tagCategory.toLowerCase()
        )
          itExists = true;
      });
    }
    let isError = false;
    let errmsg = "";
    if (
      this.state.tagCategory === "" &&
      this.state.tagCategorySelected === ""
    ) {
      isError = true;
      errmsg = "Cannot be tagged. Either Select a tag or define a new";
    }
    if (
      this.state.tagCategory !== "" &&
      this.state.tagCategorySelected !== ""
    ) {
      isError = true;
      errmsg = "Either select an existing category or define new but not both.";
    }

    if (!itExists && !isError) {
      let isNewCategory = false;
      let tagCategory = "";
      // console.log("this.state.tagCategory:", this.state.tagCategory);
      // console.log(
      //   "this.state.tagCategorySelected:",
      //   this.state.tagCategorySelected
      // );
      if (this.state.tagCategory !== "") {
        tagCategory = this.state.tagCategory;
        isNewCategory = true;
      }
      if (this.state.tagCategorySelected !== "") {
        tagCategory = this.state.tagCategorySelected;
      }
      let input = {
        synapseId: this.state.selectedPostToTag.synapseId,
        communityId: this.props.communityId,
        baandaId: this.props.auth.user.baandaId,
        tagCategory,
        isNewCategory,
      };
      let url = baandaServer + saveMailTagCategory;
      // console.log("saveMailTagCategory url:", url, " input:", input);
      try {
        let ret = await axios.post(url, input);
        if (ret.data.status === "success") {
          await this.setState({
            tagErr: "",
          });
          await this.exitTagManagement();
        } else {
          await this.setState({
            tagErr: ret.data.Msg,
          });
        }
      } catch (err) {
        console.log("saveMailTagCategory err:", err.message);
        this.setState({
          tagErr: err.message,
        });
      }
    } else {
      if (itExists) {
        this.setState({
          tagErr: "The new tag exists. Clear new field and select to tag.",
        });
      }
      if (isError) {
        this.setState({
          tagErr: errmsg,
        });
      }
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  getmails = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let param =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&baandaId=" +
      this.props.auth.user.baandaId +
      "&selectedSearchType=" +
      this.state.selectedSearchType +
      "&dateFilterType=" +
      this.state.dateFilterType +
      "&dateFirst=" +
      this.state.dateFirst +
      "&dateSecond=" +
      this.state.dateSecond +
      "&searchSender=" +
      this.state.searchSender +
      "&searchSubject=" +
      this.state.searchSubject +
      "&tagCategorySelected=" +
      this.state.tagCategorySelected +
      "&orderedBy=" +
      this.state.orderedBy;

    let url = baandaServer + getSynapseMails + param;
    console.log("getmails url:", url);
    try {
      let theList = await axios.get(url);
      console.log("theList.data:", theList.data);
      let sortedByDate = await this.sortBydate(theList.data.Msg);
      // console.log("######### sortedByDate:", sortedByDate);
      await this.setState({
        messages: sortedByDate,
      });
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: false,
      });
    }
  };

  sortBydate = async (inpArry) => {
    let sorted = await inpArry.sort((a, b) => {
      let key1 = new Date(a.sent_date);
      let key2 = new Date(b.sent_date);

      if (key1 < key2) return -1;
      else if (key1 === key2) return 0;
      else return -1;
    });

    return sorted;
  };

  deleteMail = async () => {};

  setPageNumbers = async () => {
    let tlen = this.state.messages.length; // tlen = total length of cart array to be picked up
    let f = this.state.fistPageOfSetIndex;
    let x = this.state.noOfRowsPerPage;
    let y = this.state.noOfButtonsToShow;
    let right = false,
      left = false;
    let showPaginationPanelFlag = true;

    let z = y; // Dynamic number of buttons to show (vary)
    // let st = 1; // starting page number is 1
    // console.log("1> f:", f, " z:", z, " x:", x, " y:", y, " tlen:", tlen);
    if (tlen <= x) {
      // console.log("here 1 ");
      showPaginationPanelFlag = false;
    } else if (Math.ceil(tlen / x) <= y) {
      // no action needed
      // console.log("here 2 ");
      z = Math.ceil(tlen / x); // z becomes the  number of page  number can be shown
    } else if (f <= 1) {
      // console.log("here 3 ");
      left = false;
      right = true;
    } else if (f + y > Math.ceil(tlen / x)) {
      // Math.ceil(tlen/x) gives the total number of buttons can be shown
      // console.log("here 4 ");
      left = true;
      right = false;
      z = Math.ceil(this.state.messages.length / this.state.noOfRowsPerPage); // end button number in z
      //   st = f; // start button number from f
    } else {
      // console.log("here 5 ");
      left = true;
      right = true;
      //   st = f;
      z = f + y - 1; //
    }

    let pageNumbers = [];
    for (let i = f; i <= z; i++) {
      pageNumbers.push(i);
    }

    await this.setState({
      leftArrowFlag: left,
      rightArrowFlag: right,
      showPaginationPanelFlag,
      pageNumbers,
    });
  };

  setPosts = async () => {
    // console.log("INSIDE setPosts this.state.messages:", this.state.messages);
    let indexOfLastMessage =
      this.state.currentPage * this.state.noOfRowsPerPage;
    let indexOfFirstMessage = indexOfLastMessage - this.state.noOfRowsPerPage;
    let slicedMessages;

    if (indexOfLastMessage >= this.state.messages.length) {
      // console.log("I am here xxxxx");
      slicedMessages = this.state.messages.slice(indexOfFirstMessage);
      // console.log("slicedMessages:", slicedMessages);
    } else {
      slicedMessages = this.state.messages.slice(
        indexOfFirstMessage,
        indexOfLastMessage
      );
    }
    await this.setState({
      messagesOnScreen: slicedMessages,
    });
  };

  paginate = async (input) => {
    // console.log("paginate input:", input);
    await this.setState({
      currentPage: input,
    });
    await this.setPosts();
  };

  handleLeft = async () => {
    // console.log("handle Left");
    let ff = this.state.fistPageOfSetIndex - this.state.noOfButtonsToShow;
    if (ff < 1) ff = 1;

    await this.setState({
      fistPageOfSetIndex: ff,
    });

    await this.setPageNumbers();
  };

  handleRight = async () => {
    // console.log("handle Right");
    let ff = this.state.fistPageOfSetIndex + this.state.noOfButtonsToShow;
    if (ff > this.state.messages.length)
      ff = this.state.messages.length - this.state.noOfButtonsToShow;

    await this.setState({
      fistPageOfSetIndex: ff,
    });

    await this.setPageNumbers();
  };

  handleMessages = async (post) => {
    // console.log("post:", post, " post.messageType:", post.messageType);
    await this.setState({
      showpagi: false,
      showPaginationPanelFlag: false,
      showMsgFlag: false,
      showNotificationFlag: false,
      showEventResponseFlag: false,
      showBroadcastFlag: false,
      showEventToPurchase: false,
      showPost: post,
    });
    if (post.messageType === "notification") {
      this.setState({
        showNotificationFlag: true,
      });
    }
    if (post.messageType === "converse") {
      // console.log("in converse ....");
      this.setState({
        showMsgFlag: true,
      });
    }
    if (post.messageType === "eventattendenceresponse") {
      this.setState({
        showEventResponseFlag: true,
      });
    }

    if (post.messageType === "serviceattendenceresponse") {
      // console.log("We will get off from here from tomorrow ...");
      this.setState({
        showEventToPurchase: true,
      });
    }
    if (post.messageType === "broadcast") {
      console.log("here here broadcast ");
      this.setState({
        showBroadcastFlag: true,
      });
    }
  };

  handleTagManagement = async (post) => {
    // console.log("post:", post);
    this.setState({
      showTagCategoryFlag: true,
      selectedPostToTag: post,
    });
    if (document.getElementById("convTagType"))
      document.getElementById("convTagType").value = "DEFAULT";
  };

  exitTagManagement = async () => {
    this.setState({
      showTagCategoryFlag: false,
      selectedPostToTag: null,
      tagCategory: "",
      tagCategorySelected: "",
    });

    await this.getmails();
    await this.getMailTagCategory();
    await this.setPosts();
    await this.setPageNumbers();
  };

  deleteTagCategory = async () => {
    alert("will delete a tag cat");
  };

  returnToConverse = async () => {
    await this.getmails();
    await this.setPosts();
    await this.setPageNumbers();

    this.setState({
      showpagi: true,
      showMsgFlag: false,
      showNotificationFlag: false,
    });
  };

  returnFromNotification = async () => {
    // console.log("returnFromNotification");
    await this.getmails();
    await this.setPosts();
    await this.setPageNumbers();

    this.setState({
      showpagi: true,
      showMsgFlag: false,
      showNotificationFlag: false,
    });
  };

  returnFromEventResponse = async () => {
    // console.log("returnFromNotification");
    await this.getmails();
    await this.setPosts();
    await this.setPageNumbers();

    this.setState({
      showpagi: true,
      showMsgFlag: false,
      showEventResponseFlag: false,
    });
  };

  returnFromShowBroadcast = async () => {
    // console.log("returnFromShowBroadcast");
    await this.getmails();
    await this.setPosts();
    await this.setPageNumbers();

    this.setState({
      showpagi: true,
      showMsgFlag: false,
      showEventResponseFlag: false,
      showEventToPurchase: false,
      showBroadcastFlag: false,
    });
  };

  returnFromWriteEmail = async () => {
    // console.log("Returned from compose ... success???");
    await this.getmails();
    await this.setPosts();
    await this.setPageNumbers();

    this.setState({
      showpagi: true,
      showMsgFlag: false,
      showEventResponseFlag: false,
      showEventToPurchase: false,
      showBroadcastFlag: false,
      showWriteBamilFlag: false,
      selectedSearchType: "all",
      dateFilterType: "all",
    });
  };

  handleMessageDelete = async (obj) => {
    // alert("TBD handleMessageDelete obj: " + JSON.stringify(obj));
    this.setState({
      toDelete: obj,
      showConfirmDelete: true,
      deleteYesNo: "yes",
    });
  };

  handleDeleteCofirmation = async (e) => {
    this.setState({
      deleteYesNo: e.target.value,
    });
  };

  handleOrderedBy = async (e) => {
    this.setState({
      orderedBy: e.target.value,
    });
  };

  handleDateFilterType = async (e) => {
    this.setState({
      dateFilterType: e.target.value,
    });
  };

  completeTheDelete = async () => {
    if (this.state.deleteYesNo === "yes") {
      // console.log("Will delete the letter ... ");
      try {
        let input = {
          synapseId: this.state.toDelete.synapseId,
        };
        let url = baandaServer + deleteSynapseMail;
        await axios.post(url, input);
        // console.log("delret:", delret);
        await this.getmails();
        await this.setPosts();
        await this.setPageNumbers();
      } catch (err) {
        console.log("completeTheDelete err:", err.log);
      }
    }
    this.setState({
      toDelete: null,
      showConfirmDelete: false,
      deleteYesNo: "no",
    });
  };

  openSearchPanel = async () => {
    this.setState({
      showSearchPanel: true,
    });

    if (document.getElementById("convSearchType"))
      document.getElementById("convSearchType").value = "DEFAULT";
  };

  exitSearchPanel = async () => {
    await this.setState({
      showSearchPanel: false,
      selectedSearchType: "all",
    });
  };

  handleconvSearchSelect = async () => {
    let value = document.getElementById("convSearchType").value;
    // console.log("handleconvSearchSelect value:", value);
    await this.setState({
      selectedSearchType: value,
      dateFilterType: "all",
    });

    await this.onblur();
  };

  handleTagSelect = async () => {
    let value = document.getElementById("convTagType").value;
    // console.log("handleconvSearchSelect value:", value);
    await this.setState({
      tagCategorySelected: value,
    });
  };

  onfocus = async () => {
    await this.setState({
      selconheight: 5,
    });
    if (document.getElementById("convSearchType"))
      document.getElementById("convSearchType").value = "DEFAULT";
  };

  onblur = async () => {
    await this.setState({
      selconheight: 1,
    });
  };

  onfocuscat = async () => {
    let seltagHeight = 5;
    if (this.state.mailCategories.length < 5)
      seltagHeight = this.state.mailCategories.length + 1;
    await this.setState({
      seltagHeight,
    });
    if (document.getElementById("convTagType"))
      document.getElementById("convTagType").value = "DEFAULT";
  };

  onblurcat = async () => {
    await this.setState({
      seltagHeight: 1,
    });
  };

  handleDateFirst = async (date) => {
    await this.setState({
      dateFirst: date,
    });
    // console.log(
    //   "handleDateFirst dob:",
    //   moment(this.state.dateFirist).format("MM/DD/YYYY")
    // );
  };

  handleDateSecond = async (date) => {
    await this.setState({
      dateSecond: date,
    });
  };

  performSearch = async () => {
    // console.log("in bmail perform search ...");
    await this.getmails();
    await this.getMailTagCategory();
    await this.setPosts();
    await this.setPageNumbers();

    this.exitSearchPanel();
  };

  handleNewBmail = async () => {
    await this.setState({
      showpagi: false,
      showMsgFlag: false,
      showEventResponseFlag: false,
      showEventToPurchase: false,
      showBroadcastFlag: false,
      showWriteBamilFlag: true,
    });
  };

  capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);
    // console.log('Converse...');

    let summaryPanel;
    let total = this.state.messages.length;
    let newmessages = 0;
    this.state.messages.forEach((obj) => {
      if (obj.status === "new") newmessages++;
    });

    let totallb, unread;
    if (this.state.deviceSize === "big") {
      totallb = "Total:";
      unread = "Unread";
    } else {
      totallb = "T:";
      unread = "UR";
    }

    summaryPanel = (
      <div className="sum-placement">
        {totallb}&nbsp;{total}{" "}
        <span className="g">
          {newmessages}&nbsp;{unread}
        </span>{" "}
      </div>
    );

    let dotLegend = (
      <div>
        Dot Colors:{" "}
        <span>
          <font color="green">Unread</font>
        </span>
        &nbsp;&nbsp;&nbsp;
        <span>
          <font color="#80460f">Replied</font>
        </span>
        &nbsp;&nbsp;&nbsp;
        {/* <span>
          <font color="#478ea8">Draft</font>
        </span> */}
        &nbsp;&nbsp;&nbsp;|| Ordered by Sent-Date
      </div>
    );

    // summaryPanel = <span className="list-box">Total:&nbsp;{total}</span>;

    let buttonPanelInfo = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let filterButton = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={this.openSearchPanel}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search" />
      </button>
    );

    let newBmailButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleNewBmail}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        { this.state.deviceSize === 'big' ? "New" : <i className="fas fa-plus-square" /> }
      </button>
    );

    let dateFilterPanel;
    dateFilterPanel = (
      <div>
        <div className="row conv-filt-date-row">
          <div className="col text-center radio-font-style">
            <strong>Date Filter&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="all"
                  checked={this.state.dateFilterType === "all"}
                  onChange={this.handleDateFilterType}
                />{" "}
                All
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="after"
                  checked={this.state.dateFilterType === "after"}
                  onChange={this.handleDateFilterType}
                />{" "}
                After
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="before"
                  checked={this.state.dateFilterType === "before"}
                  onChange={this.handleDateFilterType}
                />{" "}
                Before
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="between"
                  checked={this.state.dateFilterType === "between"}
                  onChange={this.handleDateFilterType}
                />{" "}
                Between
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let dateSelectionPanel;
    if (this.state.showSearchPanel && this.state.dateFilterType !== "all") {
      if (this.state.dateFilterType === "before") {
        dateSelectionPanel = (
          <div className="filter-date-row">
            <div className="row">
              <div className="col-md-6 text-center">
                Filter prior to the date
              </div>
              <div className="col-md-6 text-center">
                <DatePicker
                  selected={this.state.dateSecond}
                  onChange={this.handleDateSecond}
                  onSelect={this.handleDateSecond}
                  name="prdob"
                  dateFormat="MM/dd/yyyy"
                  minDate={this.state.dateFirst}
                  popperPlacement="bottom-start"
                />
              </div>
            </div>
          </div>
        );
      }
      if (this.state.dateFilterType === "after") {
        dateSelectionPanel = (
          <div className="filter-date-row">
            <div className="row">
              <div className="col-md-6 text-center">
                <DatePicker
                  selected={this.state.dateFirst}
                  onChange={this.handleDateFirst}
                  onSelect={this.handleDateFirst}
                  name="prdob"
                  dateFormat="MM/dd/yyyy"
                />
              </div>
              <div className="col-md-6 text-center">Filter after the date</div>
            </div>
          </div>
        );
      }
      if (this.state.dateFilterType === "between") {
        dateSelectionPanel = (
          <div className="filter-date-row">
            <div className="row">
              <div className="col-md-6 text-center">
                <DatePicker
                  selected={this.state.dateFirst}
                  onChange={this.handleDateFirst}
                  onSelect={this.handleDateFirst}
                  name="prdob"
                  dateFormat="MM/dd/yyyy"
                />
              </div>
              <div className="col-md-6 text-center">
                <DatePicker
                  selected={this.state.dateSecond}
                  onChange={this.handleDateSecond}
                  onSelect={this.handleDateSecond}
                  name="prdob"
                  dateFormat="MM/dd/yyyy"
                  minDate={this.state.dateFirst}
                />
              </div>
            </div>
          </div>
        );
      }
    }

    let searchTypePanel;
    if (this.state.showSearchPanel) {
      searchTypePanel = (
        <div>
          <select
            size={this.state.selconheight}
            onFocus={this.onfocus}
            onBlur={this.onblur}
            id="convSearchType"
            name="convSearchType"
            onChange={this.handleconvSearchSelect}
            onSelect={this.handleconvSearchSelect}
            className="conv-search-select"
            defaultValue={"DEFAULT"}
          >
            <option value="DEFAULT" disabled>
              Select a search type
            </option>
            <option value="all">All</option>
            <option value="sender">By Sender</option>
            <option value="subject">By Subject</option>
            <option value="events">Events Only</option>
            <option value="letter">Letters Only</option>
            <option value="unread">New / Unread</option>
            <option value="notification">Notification Only</option>
            <option value="sent">Sent (by you) Mails</option>
            <option value="service">Services Only</option>
            <option value="tags">Tag Category</option>
          </select>
        </div>
      );
    }

    let inputFilterPanel;
    if (this.state.selectedSearchType === "subject") {
      inputFilterPanel = (
        <div>
          <input
            name="searchSubject"
            type="text"
            value={this.state.searchSubject}
            onChange={this.onChange}
            size="50"
            maxLength="50"
            className="conv-serch-input"
            placeholder="Enter subject part/full"
          />
        </div>
      );
    }
    if (this.state.selectedSearchType === "sender") {
      inputFilterPanel = (
        <div>
          <input
            name="searchSender"
            type="text"
            value={this.state.searchSender}
            onChange={this.onChange}
            size="50"
            maxLength="50"
            className="conv-serch-input"
            placeholder="Enter sender's name part/full"
          />
        </div>
      );
    }

    let searchButton;
    searchButton = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={this.performSearch}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search-plus" />
      </button>
    );
    let exitSearchButton;
    exitSearchButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitSearchPanel}
        style={{
          cursor: this.state.disabled ? "default" : "pointer",
        }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let existingTags;
    if (
      this.state.showTagCategoryFlag ||
      this.state.selectedSearchType === "tags"
    ) {
      let taglist = this.state.mailCategories.map((obj, i) => {
        // console.log("(866) obj:", obj);
        return (
          <option key={i} value={JSON.stringify(obj.label)}>
            {obj.tagCategory}
          </option>
        );
      });

      let selectTagDropdown;
      selectTagDropdown = (
        <div>
          <div className="row select_tag-category">
            <div className="col text-center div_item_select">
              <select
                size={this.state.seltagHeight}
                onChange={this.handleTagSelect}
                onFocus={this.onfocuscat}
                onBlur={this.onblurcat}
                id="convTagType"
                name="convTagType"
                onSelect={this.handleTagSelect}
                className="conv-tag-select"
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Select a tag category
                </option>
                {taglist}
              </select>
            </div>
          </div>
        </div>
      );

      if (this.state.mailCategories.length === 0) {
        existingTags = (
          <div className="text-center conv-no-tags-msg">
            No tags to select from
          </div>
        );
      } else {
        existingTags = (
          <div className="bmail-search-tag-drop-pos">{selectTagDropdown}</div>
        );
      }
    }

    let save;
    if ( this.state.deviceSize === 'big') {
      save = "Save";
    } else {
      save = "Sve";
    }

    let tagHandlingPanel;
    if (this.state.showTagCategoryFlag) {
      tagHandlingPanel = (
        <div className="converse-tag-mgmt-box">
          <div className="row">
            <div className="col-3 conv-tag-panel-header text-left">
              {this.state.deviceSize === "big" ? "Tag Handler" : "Tags"}
            </div>
            <div className="col-5 text-center conv-curr-tag-header">
              Tag:{" "}
              {this.state.selectedPostToTag.tagCategory === ""
                ? "Not tagged"
                : this.state.selectedPostToTag.tagCategory}
            </div>
            <div className="col-4 text-center">
              <button
                className="btn_reg_60"
                type="button"
                onClick={this.saveMailTagCategory}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                {save}
              </button>
              <button
                className="btn_back_main"
                type="button"
                onClick={this.exitTagManagement}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                <i className="fas fa-step-backward" />
              </button>
            </div>
          </div>

          <div className="row">
            <div className="text-center col-md-6 text-center">
              {existingTags}
            </div>
            <div className="text-center col-md-6 text-center bmail-enter-a-tag-cat-pos">
              <input
                name="tagCategory"
                type="text"
                value={this.state.tagCategory}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="conv-new-tag-input"
                placeholder="Enter a new tag category"
              />
              &nbsp;
            </div>
          </div>
          <div className="text-center conv-tag-err">{this.state.tagErr}</div>
        </div>
      );
    }

    let searchDisplayOrder;
    if (this.state.deviceSize === "small") {
      searchDisplayOrder = (
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Ordered &nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="asc"
                  checked={this.state.orderedBy === "asc"}
                  onChange={this.handleOrderedBy}
                />{" "}
                Ascending
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="dsc"
                  checked={this.state.orderedBy === "dsc"}
                  onChange={this.handleOrderedBy}
                />{" "}
                Descending
              </label>
            </div>
          </div>
        </div>
      );
    } else {
      searchDisplayOrder = (
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Ordered (by Sent Date)&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="asc"
                  checked={this.state.orderedBy === "asc"}
                  onChange={this.handleOrderedBy}
                />{" "}
                Ascending
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="dsc"
                  checked={this.state.orderedBy === "dsc"}
                  onChange={this.handleOrderedBy}
                />{" "}
                Descending
              </label>
            </div>
          </div>
        </div>
      );
    }

    let searchPanel;
    if (this.state.showSearchPanel) {
      searchPanel = (
        <div className="converse-search-panel-box">
          <div className="text-center conv-search-header">
            Conversation Search Box&nbsp;&nbsp;{searchButton}&nbsp;
            {exitSearchButton}
          </div>
          {this.state.selectedSearchType === "tags" ? null : (
            <div className="row date-row-placement">
              <div className="col text-center">{dateFilterPanel}</div>
            </div>
          )}
          <div className="row">
            <div className="col text-center">{searchDisplayOrder}</div>
          </div>
          <div className="row bmail-search-row-pos">
            <div className="col-md-6 text-center">{searchTypePanel}</div>
            <div className="col-md-6 text-center conv-ser-text-enter-pos">{inputFilterPanel}</div>
          </div>
          {this.state.selectedSearchType === "tags" ? (
            <div className="row">
              <div className="col-md-6 text-center">{existingTags}</div>
              <div className="col-md-6 text-center">
                Select a tag of interest
              </div>
            </div>
          ) : null}
          {this.state.selectedSearchType === "tags" ? null : (
            <div className="date-selection-row-pos">{dateSelectionPanel}</div>
          )}
        </div>
      );
    }

    let purposHeader, open, tag, mailhead;
    if (this.state.deviceSize === "big") {
      purposHeader = "Conversations";
      open = "Open";
      tag = "Tag ";
      mailhead = "Subject | Sent-date | Sent By | Message Type";
    } else {
      purposHeader = "Talks";
      open = "O";
      tag = "T";
      mailhead = "Subject";
    }

    let bool = true;
    let pagipanel;
    if (this.state.showpagi) {
      pagipanel = (
        <div>
          {this.state.deviceSize === "big" ? (
            <div className="row message-head-row">
              <div className="col-4 bmail-list-header ">{purposHeader}</div>
              <div className="col-4 bmail-list-summary">{summaryPanel}</div>
              <div className="col-4 show-pickups-header bmail-top-btn-pos">
                {filterButton}&nbsp;
                {buttonPanelInfo}&nbsp;
                {this.props.isMember ? newBmailButton : null}
              </div>
            </div>
          ) : (
            <div className="row message-head-row">
              <div className="col-2 bmail-list-header">{purposHeader}</div>
              <div className="col-4 bmail-list-summary">{summaryPanel}</div>
              <div className="col-6 show-pickups-header bmail-top-btn-pos">
                {filterButton}&nbsp;
                {buttonPanelInfo}&nbsp;
                {this.props.isMember ? newBmailButton : null}
              </div>
            </div>
          )}

          {searchPanel}
          {tagHandlingPanel}
          <div className="row color-legend-row">
            <div className="col text-center pickup-text-header">
              {dotLegend}
            </div>
          </div>
          <div className="row">
            <div className="col-8 text-left pickup-text-header-mh">
              {mailhead}
            </div>
            <div className="col-4 text-center pickup-text-header-mh">Ops</div>
          </div>
          {this.state.messagesOnScreen.map((post, i) => (
            <div key={i}>
              <div className={`${bool ? "row-color-a" : "row-color-b"}`}>
                <div className="row">
                  <div className="col-8 text-left pickup-text">
                    {this.state.deviceSize === "small" ? (
                      <div>
                        {post.subject.length > 30
                          ? post.subject.substring(0, 29)
                          : post.subject}
                      </div>
                    ) : (
                      <div>
                        {" "}
                        {post.subject}&nbsp;|&nbsp;
                        {post.sent_date.substring(0, 10)}&nbsp;|&nbsp;
                        {post.sentByName}&nbsp;|&nbsp;
                        {post.messageType === "eventattendenceresponse"
                          ? "Event Invite"
                          : this.capitalizeFirstLetter(post.messageType)}
                      </div>
                    )}
                  </div>
                  <div className="col-1 text-left">
                    {post.status === "replied" ? (
                      <div className="small-box-brown" />
                    ) : (
                      <div>
                        {post.status === "new" ? (
                          <div className="small-box-green" />
                        ) : (
                          <div>
                            {post.status === "draft" ? (
                              <div className="small-box-blue" />
                            ) : null}
                          </div>
                        )}{" "}
                      </div>
                    )}
                  </div>
                  <div className="col-3 text-left">
                    <button
                      onClick={() => this.handleMessages(post)}
                      className="btn-conv-msg-open"
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      {open}
                    </button>
                    <button
                      onClick={() => this.handleTagManagement(post)}
                      className="btn-conv-tag"
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      {tag}
                      {this.state.deviceSize === "big" ? (
                        <i className="fa fa-map-pin" />
                      ) : null}
                    </button>
                    <button
                      onClick={() => this.handleMessageDelete(post)}
                      className="btn-conv-msg-delete"
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      <i className="fas fa-trash-alt" />
                    </button>
                  </div>
                </div>
              </div>
              {(bool = !bool)}
            </div>
          ))}
        </div>
      );
    }

    let pageNumberPanel;
    if (this.state.showPaginationPanelFlag) {
      pageNumberPanel = (
        <div>
          <ul className="pagination">
            {this.state.leftArrowFlag ? (
              <li key={0}>
                <button
                  onClick={this.handleLeft}
                  className="btn-leftright-converse"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <i className="fas fa-angle-double-left" />
                </button>
              </li>
            ) : null}
            {this.state.pageNumbers.map((number) => (
              <li key={number + 1}>
                <button
                  onClick={() => this.paginate(number)}
                  className="btn-converse-page-no"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  {number}
                </button>
              </li>
            ))}
            {this.state.rightArrowFlag ? (
              <li key={10000000}>
                <button
                  onClick={this.handleRight}
                  className="btn-leftright-converse-converse"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <i className="fas fa-angle-double-right" />
                </button>
              </li>
            ) : null}
          </ul>
        </div>
      );
    }

    let outputPanel;
    if (this.state.showpagi && this.state.showPaginationPanelFlag) {
      outputPanel = (
        <div>
          {pagipanel}
          <div className="text-center converse-page-row">{pageNumberPanel}</div>
        </div>
      );
    }

    if (this.state.showpagi && !this.state.showPaginationPanelFlag) {
      outputPanel = <div>{pagipanel}</div>;
    }

    let deleteConfirmPanel;
    if (this.state.showConfirmDelete) {
      deleteConfirmPanel = (
        <div className="converse-delete-confirm-box">
          <h5>Delete Confirmation</h5>
          <div className="row name-panel-row">
            <div className="col text-center radio-font-style">
              <strong>Are you sure&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="yes"
                    checked={this.state.deleteYesNo === "yes"}
                    onChange={this.handleDeleteCofirmation}
                  />{" "}
                  Yes (Delete)
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="no"
                    checked={this.state.deleteYesNo === "no"}
                    onChange={this.handleDeleteCofirmation}
                  />{" "}
                  No (Exit)
                </label>
              </div>
            </div>
          </div>
          <div className="text-center">
            <button
              onClick={this.completeTheDelete}
              className="btn_reg_50_go bmail-delete-confirm-go-pos"
              style={{
                cursor: this.state.disabled ? "default" : "pointer",
              }}
            >
              Go
            </button>
          </div>
        </div>
      );
    }

    if (this.state.showMsgFlag) {
      outputPanel = (
        <ShowMessages
          returnToCaller={this.returnToConverse}
          communityId={this.props.communityId}
          baandaId={this.props.auth.user.baandaId}
          showPost={this.state.showPost}
          isMember={this.props.isMember}
        />
      );
    }

    if (this.state.showNotificationFlag) {
      outputPanel = (
        <ShowNotification
          returnToCaller={this.returnFromNotification}
          communityId={this.props.communityId}
          baandaId={this.props.auth.user.baandaId}
          showPost={this.state.showPost}
        />
      );
    }

    if (this.state.showBroadcastFlag) {
      outputPanel = (
        <ShowBroadcast
          returnToCaller={this.returnFromShowBroadcast}
          communityId={this.props.communityId}
          baandaId={this.props.auth.user.baandaId}
          showPost={this.state.showPost}
          authorizedFuncs={this.props.authorizedFuncs}
        />
      );
    }

    if (this.state.showEventResponseFlag) {
      outputPanel = (
        <ShowEventResponse
          returnToCaller={this.returnFromShowBroadcast}
          communityId={this.props.communityId}
          baandaId={this.props.auth.user.baandaId}
          showPost={this.state.showPost}
          authorizedFuncs={this.props.authorizedFuncs}
          attendee={this.state.attendee}
        />
      );
      // outputPanel = <div>Show Event Response</div>;
    }

    if (this.state.showEventToPurchase) {
      outputPanel = (
        <ShowEventPurchase
          returnToCaller={this.returnFromShowBroadcast}
          communityId={this.props.communityId}
          baandaId={this.props.auth.user.baandaId}
          showPost={this.state.showPost}
          authorizedFuncs={this.props.authorizedFuncs}
        />
      );
      // outputPanel = <div>Show Event to purchase</div>;
    }

    if (this.state.showWriteBamilFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <ComposeBmail
            returnToCaller={this.returnFromWriteEmail}
            communityId={this.props.communityId}
            baandaId={this.props.auth.user.baandaId}
            authorizedFuncs={this.props.authorizedFuncs}
            caller="new"
            store={store}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        {deleteConfirmPanel}
        <ModalContainer />
      </div>
    );
  }
}

Converse.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Converse));
