import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./NewReturns.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAnInvoice = "/routes/invoice/getAnInvoice?";
const getCommunityReturnLaw = "/routes/dashboard/getCommunityReturnLaw?";
const refundCancelRequest = "/routes/finance/initRefundCancelRequest";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1115111111";

class NewReturns extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      invoiceId: 0,
      theInvoice: null,

      errFlag: false,
      selectedItems: [],
      thisSelectedItem: null,
      comment: "",
      showCommentFlag: false,
      confirmRequest: false,
      requestReturnPostage: false,

      toReturnItems: [],
      invoiceItems: [],
      returnLaw: null,
      showRequestButton: false,
      showPolicyFlag: false,

      canReturnItems: [],
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.handleCommunityReturnLaw();

    await this.setState({
      deviceSize,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({
      [e.target.name]: e.target.value,
      errMsg: "",
      errFlag: false,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  setItemsCanBeReturned = async (invoice) => {
    let canReturnItems = [];
    this.state.invoiceItems.forEach((atm) => {
      let returned = false;
      invoice.returnedRequestedItems.forEach((itm) => {
        if (parseFloat(itm.itemId) === parseFloat(atm.itemId)) returned = true;
      });
      if (!returned) canReturnItems.push(atm);
    });

    this.setState({
      canReturnItems,
      errMsg: "",
    });
  };

  getAnInvoice = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let param =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&role=customer&baandaId=" +
      this.props.auth.user.baandaId +
      "&invoiceId=" +
      this.state.invoiceId;
    let url = baandaServer + getAnInvoice + param;
    // console.log("url:", url);
    try {
      let invret = await axios.get(url);
      // console.log("invret:", invret);
      if (invret.data.status === "success") {
        if (invret.data.Msg) {
          let invoiceItems = [];
          if (invret.data.Msg.invoiceRaw) {
            // console.log("in here 111");
            invret.data.Msg.invoiceRaw.invoiceLineItems.forEach((inv) => {
              let invObj = {
                itemId: inv.itemId,
                itemName: inv.itemName,
                quantity: inv.quantity,
                price: inv.price,
                seqno: inv.subItem.seqno,
              };
              invoiceItems.push(invObj);
            });
          } else {
            // console.log("in here 222");
            invret.data.Msg.itemDetails.forEach((inv) => {
              let invObj = {
                itemId: inv.itemId,
                itemName: inv.itemName,
                quantity: inv.quantity,
                price: inv.price,
              };
              invoiceItems.push(invObj);
            });
          }
          await this.setState({
            theInvoice: invret.data.Msg,
            invoiceItems,
            errMsg: "",
            errFlag: false,
          });
          await this.setItemsCanBeReturned(invret.data.Msg);
        } else {
          this.setState({
            theInvoice: null,
            invoiceItems: [],
            errMsg: "You do not have an invoice with the Id dpecified.",
            errFlag: false,
          });
        }
      } else {
        this.setState({
          errMsg: invret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getAnInvoice err:", err.message);
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  selectThisItemForReturn = async (obj) => {
    // console.log("obj:", obj);
    let subItemFlag = true;
    // console.log('this.state.theInvoice.invoiceRaw:', this.state.theInvoice.invoiceRaw);
    if (!this.state.theInvoice.invoiceRaw) subItemFlag = false;
    // console.log('subItemFlag:', subItemFlag);
    let seqno = 99999999;
    if (obj.seqno >= 0) seqno = obj.seqno;

    let selected = [...this.state.selectedItems];
    let itExists = false;
    this.state.selectedItems.forEach((elm) => {
      // console.log("elm.itemId:", elm.itemId, " obj.itemId:", obj.itemId);
      if (elm.itemId === obj.itemId) itExists = true;
    });
    if (!itExists) {
      let selObj = {
        itemId: obj.itemId,
        itemName: obj.itemName,
        price: obj.price,
        quantity: obj.quantity,
        seqno,
        subItemFlag,
      };
      selected.push(selObj);
    }

    await this.setState({
      selectedItems: selected,
      showCommentFlag: true,
      thisSelectedItem: obj,
    });
  };

  checkTheInvoiceId = async () => {
    await this.getAnInvoice();
    if (this.state.theInvoice) {
      let numberOfRequests = this.state.theInvoice.refunds.length;
      let msg = "";
      let errFlag = false;
      let showRequestButton = true;
      if (numberOfRequests > 0) {
        if (numberOfRequests === 1) {
          msg = "You have " + numberOfRequests + " return request; ";
        } else {
          msg = "You have " + numberOfRequests + " return requests; ";
        }
      }
      if (this.state.theInvoice.initPayMedium !== "creditcard") {
        showRequestButton = false;
        msg += "For cash transaction, return in-person; ";
      }
      if (this.state.theInvoice.spType === "spotdeliverd") {
        msg += "For item handovers, the return in-person";
        showRequestButton = false;
      }

      // let atleaseOneReqExist = false;
      // if (this.state.theInvoice.refunds.length > 0) {
      //   this.state.theInvoice.refunds.forEach((obj) => {
      //     if (obj.status === "request") atleaseOneReqExist = true;
      //   });
      // }
      // if ( !atleaseOneReqExist ) msg = '';

      // console.log("showRequestButton:", showRequestButton, " msg:", msg);
      this.setState({
        showRequestButton,
        errMsg: msg,
        errFlag,
        selectedItems: []
      });
    } else {
      this.setState({
        errMsg: "No invoice found with the number.",
        errFlag: true,
        showRequestButton: false,
        showPolicyFlag: false,
      });
    }
  };

  handleComment = async () => {
    // console.log(
    //   "-----In Attach......... this.state.comment:",
    //   this.state.comment
    // );
    if (this.state.comment.length === 0 || this.state.comment.length < 20) {
      await this.setState({
        errMsg: "Comment must be at least 20 characters.",
        errFlag: true,
      });
    } else {
      let sel = [];
      this.state.selectedItems.forEach((obj) => {
        if (obj.itemId !== this.state.thisSelectedItem.itemId) sel.push(obj);
        else {
          let selObj = {
            itemId: obj.itemId,
            itemName: obj.itemName,
            comment: this.state.comment,
          };
          sel.push(selObj);
        }
      });

      this.setState({
        selectedItems: sel,
        showCommentFlag: false,
        thisSelectedItem: null,
        // invoiceItems: invId,
      });
    }
  };

  handleCommunityReturnLaw = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let param =
      "communityId=" + this.props.communityId + "&coopStoreId=" + coopStoreId;
    let url = baandaServer + getCommunityReturnLaw + param;
    // console.log('url:', url);
    try {
      let law = await axios.get(url);
      // console.log("handle Law:", law.data);
      if (law.data.status === "success") {
        if (law.data.Msg) {
          this.setState({
            returnLaw: law.data.Msg,
            errMsg: "",
            errFlag: false,
          });
        } else {
          this.setState({
            errMsg:
              "Return policy is not set yet. Request the Community/Store contact to set it up",
            errFlag: true,
          });
        }
      } else {
        this.setState({
          errMsg: law.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleReturnLaw = async () => {
    this.setState({
      showPolicyFlag: true,
      showRequestButton: false,
      errMsg: "",
      errFlag: false,
    });
  };

  clearReturnLaw = async () => {
    this.setState({
      showPolicyFlag: false,
      showRequestButton: true,
    });
  };

  packageDataForReq = async () => {
    let requestResponse = [
      {
        actionDate: new Date(),
        note: this.state.comment,
        actionBy: "customer",
      },
    ];

    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let input = {
      communityId: this.props.communityId,
      coopStoreId,
      baandaId: this.props.auth.user.baandaId,
      customerName: this.state.theInvoice.customerName,
      customerEmail: this.state.theInvoice.customerEmail,
      invoiceId: parseFloat(this.state.invoiceId),
      paymentMedium: this.state.theInvoice.initPayMedium,
      status: "request",
      requestResponse,
      refundReqDate: new Date(),
      requestReturnPostage: this.state.requestReturnPostage,
      toReturnItems: this.state.selectedItems,
      clientProgram: "NewReturns.js",
      clientFunction: "handleRequest",
    };

    return input;
  };

  handleRequest = async () => {
    // let items = [];

    if (this.state.comment.length < 5) {
      this.setState({
        errMsg: "Reason must be at least 5 characters long.",
        errFlag: true,
      });
    } else {
      let input = await this.packageDataForReq();
      let url = baandaServer + refundCancelRequest;
      // console.log("url:", url, " input:", input);
      try {
        let reqret = await axios.post(url, input);
        if (reqret.data.status === "success") {
          this.setState({
            errMsg: "Successfully applied.",
            errFlag: false,
            invoiceId: 0,
            theInvoice: null,
            selectedItems: [],
            thisSelectedItem: null,
            comment: "",
            showCommentFlag: false,
            confirmRequest: false,
            toReturnItems: [],
            invoiceItems: [],
            showRequestButton: false,
            showPolicyFlag: false,
          });
        } else {
          this.setState({
            errMsg: reqret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        console.log("handleRequest Err:", err.message);
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    }
  };

  deselectThisItemToReturn = async (obj) => {
    // console.log("obj:", obj);
    let modsel = [];
    this.state.selectedItems.forEach((sel) => {
      if (obj.itemName !== sel.itemName) modsel.push(sel);
    });

    this.setState({
      selectedItems: modsel,
    });
  };

  handleReturnRequest = async () => {
    await this.setState((prevstate) => ({
      confirmRequest: !prevstate.confirmRequest,
    }));
    // console.log("this.state:", this.state);
  };

  handlePostageRequest = async () => {
    await this.setState((prevstate) => ({
      requestReturnPostage: !prevstate.requestReturnPostage,
    }));
    // console.log("this.state:", this.state);
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("NewReturns...");

    let checkbtn;
    if (this.state.deviceSize === "small") {
      checkbtn = "";
    } else {
      checkbtn = "Chk";
    }

    let buttonSearch;
    buttonSearch = (
      <button
        className="btn_reg_75"
        onClick={this.checkTheInvoiceId}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {checkbtn}&nbsp;
        <i className="fa fa-search" />
      </button>
    );

    let buttonModalInvRet;
    buttonModalInvRet = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let request, returnlaw;
    if (this.state.deviceSize === "small") {
      request = "Req";
      returnlaw = "Cls";
    } else {
      request = "Request";
      returnlaw = "Clause";
    }

    let requestButton;
    requestButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.handleRequest}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {request}
      </button>
    );

    let returnLawButton;
    returnLawButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.handleReturnLaw}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {returnlaw}
      </button>
    );

    let invoiceid;
    if (this.state.deviceSize === "small") {
      invoiceid = "InvId";
    } else {
      invoiceid = "Invoice Id";
    }

    let invoiceIdPanel;
    invoiceIdPanel = (
      <div>
        <div className="row cust-ret-invoice-id-pos">
          <div className="col-3 text-right cust-ret-invoice-id">
            {invoiceid}
          </div>
          <div className="col-9 text-left">
            <input
              name="invoiceId"
              type="number"
              value={this.state.invoiceId}
              onChange={this.onChange}
              size="14"
              maxLength="14"
              className="cust-ret-invoice-id-text"
              placeholder=""
            />{" "}
            &nbsp;
            {buttonSearch}
            {returnLawButton}
            {buttonModalInvRet}
          </div>
        </div>
      </div>
    );

    let opssel, ops;
    if (this.state.deviceSize === "small") {
      opssel = "Sel";
      ops = "Del";
    } else {
      opssel = "Select";
      ops = "Delete";
    }

    let bool = true;
    let invoiceItemListPanel;
    if (this.state.invoiceItems.length > 0 && !this.state.showPolicyFlag) {
      let canCk = true;
      if ( !this.state.showRequestButton) canCk = false;
      invoiceItemListPanel = (
        <div>
          <div className="row ret-can-items-row">
            <div className="col-2 text-right custret-inv-item-ops">
              {opssel}
            </div>

            <div className="col-10 text-center custret-inv-item-text-head">
              Invoice Items
            </div>
          </div>
          {this.state.canReturnItems.length > 0 ? (
            <div className="fixedsize-custret-inv-items">
              {this.state.canReturnItems.map((obj, i) => {
                return (
                  <div key={i}>
                    <div className="row">
                      <div className="col-2 text-right">
                        {canCk ? (
                          <button
                            className="btn_reg_40"
                            type="button"
                            onClick={() => this.selectThisItemForReturn(obj)}
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                          >
                            Ck
                          </button>
                        ) : null}
                      </div>
                      <div className="col-10 text-left custret-inv-item-text">
                        {obj.itemName}&nbsp;(${" "}
                        {this.commaFormattedCurrency(obj.price)} | Qty:
                        {obj.quantity})
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="fixedsize-custret-inv-items">
              No items that can be returned exists in this invoice.
            </div>
          )}
        </div>
      );
    }

    let selecteditemsPanel;
    if (this.state.selectedItems.length > 0 && !this.state.showPolicyFlag) {
      selecteditemsPanel = (
        <div>
          <div className="row ret-can-items-row">
            <div className="col-2 text-right custret-inv-item-ops">{ops}</div>

            <div className="col-10 text-center custret-inv-item-text-head">
              Items for Refund
            </div>
          </div>
          <div className="fixedsize-custret-inv-items">
            {this.state.selectedItems.map((obj, i) => {
              // console.log("690 obj:", obj);
              return (
                <div key={i}>
                  <div className="row">
                    <div className="col-2 text-right">
                      <button
                        className="btn_exit_del new-ret-btn-del-pos"
                        type="button"
                        onClick={() => this.deselectThisItemToReturn(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                    <div className="col-10 text-left custret-inv-item-text">
                      {obj.itemName}&nbsp;(${" "}
                      {this.commaFormattedCurrency(obj.price)} | Qty:
                      {obj.quantity})
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let reason, message;
    if (this.state.deviceSize === "small") {
      reason = "Reason";
      // attach = "Atch";
      message = "Between 20 to 1000 chars; (Count: ";
    } else {
      reason = "A reason";
      // attach = "Attach";
      message = "Must be between 20 to 1000 chars; (Curr count: ";
    }

    let commentPanel;
    if (this.state.selectedItems.length > 0 && !this.state.showPolicyFlag) {
      commentPanel = (
        <div>
          <div className="row text-center comment-position">
            <div className="col-2 text-right search-lbl">{reason}</div>
            <div className="col-10 text-left">
              <textarea
                name="comment"
                maxLength="1000"
                placeholder="Return reason in 500 chars of less"
                rows={this.state.deviceSize === "small" ? "2" : "3"}
                wrap="hard"
                spellCheck="true"
                className="return-reason-text"
                onChange={this.onChange}
                value={this.state.comment}
                required
              />{" "}
              &nbsp;
            </div>
            {/* <div className="col-1 text-right search-lbl">&nbsp;</div> */}
          </div>
          <div className="row text-center comment-send-message-pos">
            <div className="col  text-center return-req-message">
              {message}&nbsp;
              {this.state.comment.length})
            </div>
          </div>
          <div className="row ret-can-request-pos">
            {this.props.community.returnTraits.allowReturnPostage &&
            this.state.theInvoice &&
            ( this.state.theInvoice.spType === "ship" || this.state.theInvoice.spType === "pickup")? (
              <div className="col-5 text-right confirm-post-return-lbl">
                <Checkbox
                  checked={this.state.requestReturnPostage}
                  onChange={this.handlePostageRequest}
                />{" "}
                Req. Return Postage
              </div>
            ) : (
              <div className="col-5 text-left">&nbsp;</div>
            )}
            {this.state.showRequestButton ? (
              <div className="col-4 text-right confirm-post-return-lbl">
                <Checkbox
                  checked={this.state.confirmRequest}
                  onChange={this.handleReturnRequest}
                />
                &nbsp;
                {this.state.deviceSize === "small"
                  ? "Confirm"
                  : "Confirm to request"}
              </div>
            ) : (
              <div className="col-4 text-right confirm-post-return-lbl">
                &nbsp;
              </div>
            )}

            <div className="col-3 text-left">
              {/* {this.state.confirmRequest */}
              {this.state.showRequestButton && this.state.confirmRequest
                ? requestButton
                : null}
            </div>
          </div>
        </div>
      );
    }

    let policyPanel;
    if (this.state.showPolicyFlag && this.state.returnLaw) {
      policyPanel = (
        <div className="return-policy-box">
          <div className="text-center return-policy-header">
            Return & Cancellation Policy
          </div>
          <div className="text-center return-policy-generic">
            <p align="justify">
              Good and services may be returned or cancelled based on the policy
              set forth by the community (see below). For e-commerce, request
              here. For in-person cash/check transactions, approach the business
              in person. For pause/cancel a subscription, Please switch to
              Operation (Ops) - PayCtrl.
            </p>
          </div>
          <div className="text-center return-policy-specific">
            <p align="justify">{this.state.returnLaw.clauseText}</p>
          </div>
          <div className="text-center return-policy-specific">
            Allowed to return within {this.state.returnLaw.withinDays} days of
            the purchase for goods. The final decision:{" "}
            {this.state.returnLaw.decider}.
          </div>
          <div className="text-center">
            <button
              className="btn_reg_50 cust-clear-btn-pos"
              type="button"
              onClick={this.clearReturnLaw}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Clear
            </button>
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        {invoiceIdPanel}
        {invoiceItemListPanel}
        {selecteditemsPanel}
        {commentPanel}
        {policyPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center cust-return-msg-err"
              : "text-center cust-return-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

NewReturns.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NewReturns)
);
