import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./ServicesMgmt.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateCatalogItem = "/routes/dashboard/updateCatalogItem";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1112530000";

class ServicesMgmt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      catalog: null,

      serviceRenderUnit: "hour",
      bookingType: "none",
      retainerPercentage: 0,
      registrationFee: 0,
      customQuoteFee: 1,
      renderedVia: "inperson",

      enentTagOnly: false,
      isQuoteFeeInclusive: false,
      specialInstruction: "",
      reqInvitation: "yes",

      canCancelBefore: 0,
      cancelFeeInPercent: 0,
      cancelNote: "",
    };
  }

  componentDidMount = async () => {
    let customQuoteFee = 0;
    if (this.props.catalog.service.customQuotingFee)
      customQuoteFee = this.props.catalog.service.customQuotingFee;

    let serviceRenderUnit = "hour";
    if (this.props.catalog.serviceRenderUnit !== "")
      serviceRenderUnit = this.props.catalog.serviceRenderUnit;

    await this.setState({
      catalog: this.props.catalog,
      serviceRenderUnit,
      bookingType: this.props.catalog.service.bookingType,
      enentTagOnly: this.props.catalog.eventTaggingOnly,
      retainerPercentage: this.props.catalog.retainerPercentage,
      registrationFee: this.props.catalog.registrationFee,
      isQuoteFeeInclusive: this.props.catalog.service.isQuoteFeeInclusive,
      canCancelBefore: this.props.catalog.service.canCancelBefore,
      cancelFeeInPercent: this.props.catalog.service.cancelFeeInPercent,
      cancelNote: this.props.catalog.service.cancelNote,
      customQuoteFee,
      reqInvitation: this.props.catalog.service.reqInvitation
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleBookingType = async (e) => {
    await this.setState({
      bookingType: e.target.value,
    });
  };

  handleRenderVia = async (e) => {
    await this.setState({
      renderedVia: e.target.value,
    });
  };

  handleReqInvitation = async (e) => {
    await this.setState({
      reqInvitation: e.target.value,
    });
  };

  handleRenderUnits = async (e) => {
    await this.setState({
      serviceRenderUnit: e.target.value,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  returnToCatalog = async () => {
    this.props.returnToCaller(this.state.catalog);
  };

  handleQuoteFeeRule = async () => {
    await this.setState((prevstate) => ({
      isQuoteFeeInclusive: !prevstate.isQuoteFeeInclusive,
    }));
  };

  handleEventTagOnly = async () => {
    await this.setState((prevstate) => ({
      enentTagOnly: !prevstate.enentTagOnly,
    }));
  };

  handleSave = async () => {
 
    let reqInvitation = this.state.reqInvitation;
    if (!this.state.enentTagOnly) reqInvitation = "no";

    let service = {
      bookingType: this.state.bookingType,
      customQuotingFee: this.state.catalog.service.customQuotingFee,
      isQuoteFeeInclusive: this.state.isQuoteFeeInclusive,
      type: this.state.catalog.service.type,
      scheduleType: this.state.catalog.service.scheduleType,
      renderedVia: this.state.renderedVia,
      customFirstResponder: this.state.catalog.service.customFirstResponder,
      reqInvitation,
      specialInstruction: this.state.specialInstruction,
      canCancelBefore: this.state.canCancelBefore,
      cancelFeeInPercent: this.state.cancelFeeInPercent,
      cancelNote: this.state.cancelNote,
    };

    let input = {
      bookingType: this.state.bookingType,
      communityId: this.state.catalog.communityId,
      coopStoreId: this.props.coopStoreId,
      itemId: this.state.catalog.itemId,
      updtmode: "servicemgmt",
      eventTaggingOnly: this.state.enentTagOnly,
      retainerPercentage: parseFloat(this.state.retainerPercentage),
      registrationFee: parseFloat(this.state.registrationFee),
      serviceRenderUnit: this.state.serviceRenderUnit,
      service,
      clientProgram: "ServiceMgmt.js",
      clientFunction: "handleSave",
    };

    let url = baandaServer + updateCatalogItem;
    // console.log("url:", url);
    // console.log("input:", input);

    try {
      let retserv = await axios.post(url, input);
      // console.log("retserv:", retserv);
      if (retserv.data.status === "success") {
        await this.setState({
          catalog: retserv.data.Msg,
          errMsg: "Successfully updated.",
          errFlag: false,
        });
      } else {
        await this.setState({
          errMsg: retserv.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  onChangePrice = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  onChangePercentage = async (e) => {
    let value = e.target.value;

    if (value < 100 && value >= 0) {
      if (this.countDecimals(value) > 2) {
        let tmp = parseFloat(value).toFixed(2);
        let val = parseFloat(tmp);
        await this.setState({
          [e.target.name]: val,
        });
      } else {
        await this.setState({
          [e.target.name]: value,
        });
      }
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ServiceMgmt...');

    let modalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let buttonPanel;
    buttonPanel = (
      <div className="cat-service-button-row">
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.handleSave}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>

        <button
          className="btn_back_main"
          type="button"
          onClick={this.returnToCatalog}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
        {modalButton}
      </div>
    );

    let bookingTypePanel;
    if (
      this.state.catalog &&
      this.state.catalog.service.type !== "membership"
    ) {
      bookingTypePanel = (
        <div className="row cat-service-rows">
          <div className="col-5 text-right catalog-label">Booking Type</div>
          <div className="col-7 text-left catalog-data">
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="budget-ops"
                  type="radio"
                  value="full"
                  checked={this.state.bookingType === "full"}
                  onChange={this.handleBookingType}
                />{" "}
                Pay-Upfront
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="budget-ops"
                  type="radio"
                  value="retainer"
                  checked={this.state.bookingType === "retainer"}
                  onChange={this.handleBookingType}
                />{" "}
                Retain
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="budget-ops"
                  type="radio"
                  value="none"
                  checked={this.state.bookingType === "none"}
                  onChange={this.handleBookingType}
                />{" "}
                Negotiated
              </label>
            </div>
          </div>
        </div>
      );
    } else {
      bookingTypePanel = (
        <div className="row cat-service-rows">
          <div className="col-5 text-right catalog-label">Booking Type</div>
          <div className="col-7 text-left catalog-data">
            Membership Registration.
          </div>
        </div>
      );
    }

    let renderedViaPanel;
    renderedViaPanel = (
      <div className="row cat-service-rows">
        <div className="col-5 text-right catalog-label">Renderd Via</div>
        <div className="col-7 text-left catalog-data">
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="budget-ops"
                type="radio"
                value="inperson"
                checked={this.state.renderedVia === "inperson"}
                onChange={this.handleRenderVia}
              />{" "}
              In-person
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="budget-ops"
                type="radio"
                value="online"
                checked={this.state.renderedVia === "online"}
                onChange={this.handleRenderVia}
              />{" "}
              On-line
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="budget-ops"
                type="radio"
                value="hybrid"
                checked={this.state.renderedVia === "hybrid"}
                onChange={this.handleRenderVia}
              />{" "}
              Hybrid
            </label>
          </div>
        </div>
      </div>
    );

    let serviceUnitPanel;
    if (
      this.state.catalog &&
      this.state.catalog.service.type !== "membership"
    ) {
      serviceUnitPanel = (
        <div className="row cat-service-rows">
          <div className="col-5 text-right catalog-label">Service Unit</div>
          <div className="col-7 text-left catalog-data">
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="budget-ops"
                  type="radio"
                  value="hour"
                  checked={this.state.serviceRenderUnit === "hour"}
                  onChange={this.handleRenderUnits}
                />{" "}
                Hour
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="budget-ops"
                  type="radio"
                  value="task"
                  checked={this.state.serviceRenderUnit === "task"}
                  onChange={this.handleRenderUnits}
                />{" "}
                The Task
              </label>
            </div>
            {this.state.catalog &&
            this.state.catalog.service.type === "custom" ? (
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="budget-ops"
                    type="radio"
                    value="project"
                    checked={this.state.serviceRenderUnit === "project"}
                    onChange={this.handleRenderUnits}
                  />{" "}
                  The Project
                </label>
              </div>
            ) : null}
          </div>
        </div>
      );
    }

    let quoteFeePanel;
    if (this.state.catalog && this.state.customQuoteFee > 0) {
      quoteFeePanel = (
        <div className="row cat-service-rows">
          <div className="col text-center catalog-label">
            To Quote Fee&nbsp;&nbsp;
            {/* <div className="col-3 text-left catalog-data"> */}
            $&nbsp;{this.commaFormattedCurrency(this.state.customQuoteFee)}
            &nbsp;&nbsp;&nbsp;
            <Checkbox
              checked={this.state.isQuoteFeeInclusive}
              onChange={this.handleQuoteFeeRule}
            />
            &nbsp;&nbsp;Part of the service fee if retained
          </div>
        </div>
      );
    }

    let cancelPanel;
    if (this.state.catalog && this.state.catalog.service.type === "scheduled") {
      cancelPanel = (
        <div>
          <div className="row cancel-row-position">
            <div className="col-5 text-right catalog-label">
              Can cancel before
            </div>
            <div className="col-7 text-left catalog-cancel-lbl">
              <input
                name="canCancelBefore"
                type="number"
                value={this.state.canCancelBefore}
                onChange={this.onChangePrice}
                className="can-cancel-before"
                step="1"
                // max="100"
                placeholder="0"
                autoComplete="off"
                spellCheck="false"
              />
              &nbsp; hours
            </div>
          </div>
          <div className="row cancel-row-position">
            <div className="col-5 text-right catalog-label">Cancel fee</div>
            <div className="col-7 text-left catalog-cancel-lbl">
              <input
                name="cancelFeeInPercent"
                type="number"
                value={this.state.cancelFeeInPercent}
                onChange={this.onChangePrice}
                className="can-cancel-fee-percent"
                step=".01"
                max="100"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />{" "}
              in %
            </div>
          </div>
          <div className="text-center">
            <textarea
              name="cancelNote"
              maxLength="1000"
              placeholder="Your cancelation laws ..."
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="schedule-cancel-textarea"
              onChange={this.onChange}
              value={this.state.cancelNote}
              required
            />
          </div>
          <div className="row">
            <div className="col-6 text-center special-instruction-lbl">
              Enter cancelation laws
            </div>
            <div className="col-6 text-center special-instruction-char-count">
              Max chars: 200&nbsp;&nbsp;&nbsp;Used:&nbsp;
              {this.state.cancelNote.length}
            </div>
          </div>
        </div>
      );
    }

    let tagOnlyPanel;
    if (this.props.catalog.service.type !== "custom") {
      tagOnlyPanel = (
        <div className="row cat-service-rows">
          <div className="col text-center catalog-label">
            <Checkbox
              checked={this.state.enentTagOnly}
              onChange={this.handleEventTagOnly}
            />
            &nbsp;&nbsp;Check, if this is a schduled service on the calendar.
          </div>
        </div>
      );
    }

    let registrationPanel;
    // if (
    //   this.state.catalog &&
    //   this.state.catalog.service.type === "membership"
    // ) {
    //   registrationPanel = (
    //     <div>
    //       <div className="row">
    //         <div className="col-5 text-right catalog-label">Registration</div>
    //         <div className="col-7 text-left catalog-label">
    //           <input
    //             name="registrationFee"
    //             type="number"
    //             value={this.state.registrationFee}
    //             onChange={this.onChangePrice}
    //             className="registration_service_field"
    //             step=".01"
    //             max="100"
    //             placeholder="0.00"
    //             autoComplete="off"
    //             spellCheck="false"
    //           />
    //           &nbsp;$&nbsp;Fee
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }

    let canApplyPanel;
    if (this.state.enentTagOnly) {
      canApplyPanel = (
        <div>
          <div className="row cat-service-rows">
            <div className="col-5 text-right catalog-label">By invitation</div>
            <div className="col-7 text-left catalog-data">
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="budget-ops"
                    type="radio"
                    value="yes"
                    checked={this.state.reqInvitation === "yes"}
                    onChange={this.handleReqInvitation}
                  />{" "}
                  Can Apply
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="budget-ops"
                    type="radio"
                    value="no"
                    checked={this.state.reqInvitation === "no"}
                    onChange={this.handleReqInvitation}
                  />{" "}
                  No Vacancy
                </label>
              </div>
            </div>
          </div>
          <div className="text-center">
            <textarea
              name="specialInstruction"
              maxLength="1000"
              placeholder="Describe attendance limitation instructions ..."
              rows="3"
              wrap="hard"
              spellCheck="true"
              className="special-instruction-textarea"
              onChange={this.onChange}
              value={this.state.specialInstruction}
              required
            />
          </div>
          <div className="row">
            <div className="col-6 text-center special-instruction-lbl">
              Enter attendance limitation instructions
            </div>
            <div className="col-6 text-center special-instruction-char-count">
              Max chars: 500&nbsp;&nbsp;&nbsp;Used:&nbsp;
              {this.state.specialInstruction.length}
            </div>
          </div>
        </div>
      );
    }

    let servicePanel;
    servicePanel = (
      <div>
        <div className="text-center cat-service-header">Service Management</div>
        {bookingTypePanel}
        {this.state.bookingType === "retainer" ? (
          <div className="row">
            <div className="col-5 text-right catalog-label">Retainer</div>
            <div className="col-7 text-left catalog-label">
              <input
                name="retainerPercentage"
                type="number"
                value={this.state.retainerPercentage}
                onChange={this.onChangePercentage}
                className="retainer_service_field"
                step=".01"
                max="100"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />
              &nbsp;%&nbsp;
            </div>
          </div>
        ) : null}
        {serviceUnitPanel}
        {renderedViaPanel}
        {quoteFeePanel}
        {registrationPanel}
        {cancelPanel}
        {tagOnlyPanel}
        {canApplyPanel}
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="cat-show-services-box">
        {servicePanel}
        <div className="cat-service-rows-errmsg">{buttonPanel}</div>
        <div
          className={
            this.state.errFlag
              ? "text-center cat-service-msg-err"
              : "text-center cat-service-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );
    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ServicesMgmt.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ServicesMgmt));
