import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import BeginSetup from "./BeginSetup";

import "./StripeSetup.css";

const readMeCode = "1151000000";

class StripeSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",

      deviceSize: "",
      updateCompleteFlag: false,
      subscriptionFlag: false,
      initCompleteFlag: false,
      initStartFlag: false,
      buttonsFlag: true
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setState({
      deviceSize
    });
  };
 
  openAlertModal = async () => {
 
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };


  handleSelectedFunction = async selection => {
    // console.log("selection:", selection);
  };

  handleBeginSetup = async () => {
    // console.log("handleBeginSetup");
    await this.setState({
      initStartFlag: true,
      buttonsFlag: false
    });
  };

  cancelledBeginSetup = async () => {
    await this.setState({
      initStartFlag: false,
      buttonsFlag: true
    });
  };

  savedBeginSetup = async () => {
    await this.setState({
      initStartFlag: false,
      buttonsFlag: true,
      initCompleteFlag: true
    });
  };

  

  render() {
    // console.log('this.props:', this.props);
    // console.log('this.state:', this.state);
    console.log('StripeSetup CC');

    let stripeBtnPanel;
    if (!this.state.initCompleteFlag) {
      stripeBtnPanel = (
        <div>
          <button
            className="btn_reg_140"
            type="button"
            onClick={this.openAlertModal}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Read Me First &nbsp;
            <i className="fas fa-info-circle" />
          </button>
          &nbsp;&nbsp;
          <button
            className="btn_reg_140"
            type="button"
            onClick={this.handleBeginSetup}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Begin Setup&nbsp;
            <i className="fas fa-thumbs-up" />
          </button>
        </div>
      );
    } else {
      stripeBtnPanel = (
        <div>
          <button
            className={`${
              this.state.updateCompleteFlag
                ? "btn_stripe_complete"
                : "btn_stripe_incomplete"
            }`}
            //   className="btn_stripe_active"
            type="button"
            onClick={() => this.handleSelectedFunction("update")}
          >
            <b>Update</b>
          </button>
          &nbsp;&nbsp;
          <button
            className="btn_stripe"
            //   className="btn_stripe_active"
            type="button"
            onClick={() => this.handleSelectedFunction("review")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <b>Review</b>
          </button>
          &nbsp;&nbsp;
          <button
            className={`${
              this.state.subscriptionFlag
                ? "btn_stripe_complete"
                : "btn_stripe_incomplete"
            }`}
            //   className="btn_stripe_active"
            type="button"
            onClick={() => this.handleSelectedFunction("subscript")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <b>Subscribe</b>
          </button>
          &nbsp;&nbsp;
          <button
            className="btn-modal_catalog"
            type="button"
            onClick={this.openAlertModal}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fas fa-info-circle" />
          </button>
        </div>
      );
    }

    let initPanel = (
      <div>
        <BeginSetup cancelledBeginSetup={this.cancelledBeginSetup} savedBeginSetup={this.savedBeginSetup} communityId={this.props.communityId} communityName={this.props.communityName}/>
      </div>
    );

    let outputPanel;
    if (this.state.buttonsFlag) {
      outputPanel = <div>{stripeBtnPanel}</div>;
    } else {
      if (this.state.initStartFlag) {
        outputPanel = initPanel;
      }
    }

    return (
      <div>
        <div className="stripe-header">
          Credit Card Enablement Setup
        </div>
        {outputPanel}

        <ModalContainer />
      </div>
    );
  }
}

StripeSetup.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

const mapDispatchToProps = dispatch => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(StripeSetup));
