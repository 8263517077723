import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ReactLoading from "react-loading";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./BuyReturnPostage.css";

const usps_account = process.env.REACT_APP_USPS_ACCOUNT;
const ups_account = process.env.REACT_APP_UPS_ACCOUNT;
const dhl_account = process.env.REACT_APP_DHL_ACCOUNT;
// const fedex_account = process.env.REACT_APP_FEDEX_ACCOUNT;

const BaandaCommunityId = process.env.REACT_APP_BAANDA_COMMUNITY_ID;
// const shippingServiceFee = process.env.REACT_APP_BUY_SHIPPING_SERVICE_FEE;

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";
const getShippingRates = "/routes/easypost/getShippingRates";
const getDefaultPkg = "/routes/shipping/getDefaultPkg?";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const postUseCredits = "/routes/finance/postUseCredits";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "0000000000";

class BuyReturnPostage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      packageWidth: 0,
      pkgLength: 0,
      packageHeight: 0,
      packageWeight: 0,

      toName: "",
      toStreet: "",
      toStreet2: "",
      toCity: "",
      toState: "",
      toZip: "",
      toCountry: "",

      validated: false,
      defaultPkgs: [],

      selheightpkg: 1,
      newRates: [],

      errFlag: false,
      errMsg: "",

      selectedRateMsg: "",
      selectedRateObj: null,
      selectedRate: 0,
      showSelectedRate: false,
      confirmRate: false,

      enoughCreditFlag: false,

      response: null
    };
  }

  componentDidMount = async () => {
    await this.getDefaultPackages();
    let ad = this.props.toAddressDefault;
    this.setState({
      toName: ad.name,
      toStreet: ad.address.street1,
      toCity: ad.address.city,
      toState: ad.address.state,
      toZip: ad.address.zip,
      toCountry: ad.address.country,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChangeDimension = async (e) => {
    // console.log("onChangeDimension ...");
    let name = [e.target.name];
    let value = e.target.value;
    let fieldName = name[0];
    let message = "";
    let errFlag = false;
    // console.log("fieldName:", fieldName, " value:", value);
    if (fieldName === "thePkgNumber") {
      let pkglen = 0,
        pkgwidth = 0,
        pkgheight = 0,
        pkgweight = 0;

      this.state.invoice.spInfo.regularPkg.forEach((obj) => {
        if (parseFloat(obj.packageNo) === parseFloat(value)) {
          // console.log("in here obj.width:", obj.width);
          pkglen = obj.length;
          pkgwidth = obj.width;
          pkgheight = obj.height;
          pkgweight = obj.weight;
          if (obj.regularCarrier && obj.regularCarrier.carrierName !== "") {
            message = "This package has already been shipped.";
            errFlag = true;
          }
        }
      });
      await this.setState({
        packageWidth: pkgwidth,
        pkgLength: pkglen,
        packageHeight: pkgheight,
        packageWeight: pkgweight,
        message,
        errFlag,
      });
    }

    if (this.countDecimals(value) === 0 && value > 0) {
      await this.setState({
        [e.target.name]: value,
        message,
      });
    }
  };

  onChangeWeight = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 1) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        message: "",
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        message: "",
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  checkToAddress = async () => {
    let status = "success";
    let msg = "";

    if (
      this.state.toStreet === "" ||
      this.state.toCity === "" ||
      this.state.toZip === "" ||
      this.state.toCountry === ""
    ) {
      status = "error";
      msg = "Must provide shipping destination address";
    } else {
      let param =
        "street1=" +
        this.state.toStreet +
        "&street2=" +
        this.state.toStreet2 +
        "&city=" +
        this.state.toCity +
        "&zip=" +
        this.state.toZip +
        "&country=" +
        this.state.toCountry +
        "&company=" +
        this.props.toName;
      let url = baandaServer + easypostAddressCheck + param;
      // console.log("checkToAddress url:", url);
      try {
        let check = await axios.get(url);
        // console.log("check:", check);
        if (check.data.status === "error") {
          status = "error";
          msg = check.data.Msg;
        }
      } catch (err) {
        console.log("err:", err.message);
        status = "error";
        msg = err.message;
      }
    }

    return { status, Msg: msg };
  };

  validateEntries = async () => {
    let valid = "success";
    let msg = "";

    let vaddr = await this.checkToAddress();
    if (vaddr.status === "error") {
      valid = "error";
      msg = vaddr.Msg + "; ";
    }

    if (
      this.state.packageWidth === 0 ||
      this.state.pkgLength === 0 ||
      this.state.packageHeight === 0 ||
      this.state.packageWeight === 0
    ) {
      valid = "error";
      msg += "Dimensions must be positive; ";
    }

    return { status: valid, Msg: msg };
  };

  handleCarrierSelected = async () => {
    let ret = await this.validateEntries();
    if (ret.status === "success") {
      let value = document.getElementById("shipRetPostCarrierSelect").value;

      let selectedCarrier = value;
      let selectedCarrierAc = "";
      if (value === "USPS") selectedCarrierAc = usps_account;
      if (value === "UPS") selectedCarrierAc = ups_account;
      if (value === "DHL") selectedCarrierAc = dhl_account;
      await this.setState({
        selheight: 1,
        selectedCarrierAc,
        selectedCarrier,
        validated: true,
        errFlag: false,
        errMsg: "",
      });
    } else {
      this.setState({
        errFlag: true,
        errMsg: ret.Msg,
      });
    }
  };

  formShipmentInput = async () => {
    let toAddr = {
      verify: ["delivery"],
      street1: this.state.toStreet,
      street2: this.state.toStreet2,
      city: this.state.toCity,
      state: this.state.toState,
      zip: this.state.toZip,
      country: this.state.toCountry,
      company: this.state.toName,
      phone: "",
    };

    let ca = this.props.deliveryData;
    let fromAddr = {
      verify: ["delivery"],
      street1: ca.street1,
      street2: ca.street2,
      city: ca.city,
      state: ca.state,
      zip: ca.zip,
      country: ca.country,
      company: this.props.customerName,
      phone: "",
    };

    let parcel = {
      length: parseFloat(this.state.pkgLength),
      width: parseFloat(this.state.packageWidth),
      height: parseFloat(this.state.packageHeight),
      weight: parseFloat(this.state.packageWeight),
    };

    let carrierAc = this.state.selectedCarrierAc;

    let input = {
      fromAddr,
      toAddr,
      parcel,
      carrierAc,
      shipId: "",
      rate: 0,
      communityId: this.props.communityId,
      clientProgram: "BuyReturnPostage",
      clientFunction: "handleGetShipping",
    };

    return input;
  };

  handleGetShipping = async () => {
    await this.setState({
      loadingFlag: true,
    });

    let input = await this.formShipmentInput();

    input.clientFunction = "handleGetShipping";

    // console.log("get shipment rates input:", input);
    try {
      let url = baandaServer + getShippingRates;
      // console.log("url:", url);
      let shipret = await axios.post(url, input);
      // console.log("shipret:", shipret);
      if (shipret.data.status === "success") {
        if (shipret.data.Msg.length > 0) {
          this.setState({
            newRates: shipret.data.Msg,
            errMsg: "",
            errFlag: false,
            showSelectedRate: false,
          });
        } else {
          let msg = "";
          if (this.state.selectedCarrier === "DHL")
            msg =
              "DHL provides shipping rates only if origin is domestic and destination is international.";
          this.setState({
            newRates: shipret.data.Msg,
            errMsg: msg,
            errFlag: false,
            showSelectedRate: false,
          });
        }
      } else {
        this.setState({
          errMsg: shipret.data.Msg,
          errFlag: true,
          showSelectedRate: false,
        });
      }
    } catch (err) {
      console.log("handleGetShipping err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
        showSelectedRate: false,
        confirmRate: false,
      });
    }

    await this.setState({
      loadingFlag: false,
    });
  };

  getDefaultPackages = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let param =
      "communityId=" + this.props.communityId + "&coopStoreId=" + coopStoreId;
    let url = baandaServer + getDefaultPkg + param;
    // console.log("url:", url);
    try {
      let pkgret = await axios.get(url);
      // console.log("pkgret.data:", pkgret.data);
      if (pkgret.data.status === "success") {
        this.setState({
          defaultPkgs: pkgret.data.Msg,
        });
      }
    } catch (err) {
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  handlePkgSelected = async () => {
    // console.log("in shipPackageRetPostSelect");
    let value = document.getElementById("shipPackageRetPostSelect").value;
    // console.log("value:", value);
    let valobj = JSON.parse(value);
    // console.log("valobj:", valobj);
    await this.setState({
      selheightpkg: 1,
      selectedPackage: valobj,
      pkgLength: valobj.pkgLength,
      packageHeight: valobj.pkgHeight,
      packageWidth: valobj.pkgWeight,
      packageWeight: valobj.pkgWeight,
      scaleType: valobj.scaleType,
    });
  };

  onfocuspkg = async () => {
    await this.setState({
      selheightpkg: 5,
    });
  };

  onblurpkg = async () => {
    this.setState({
      selheightpkg: 1,
    });
  };

  selectThisRate = async (obj) => {
    // console.log('selectThisRate obj:', obj);
    let selectedRateMsg = "Service: " + obj.service + "; ";
    if (isNaN(obj.delivery_days)) {
      selectedRateMsg += " Delivery " + obj.delivery_days + "; ";
    } else {
      selectedRateMsg += "Delivery in " + obj.delivery_days + " days; ";
    }
    selectedRateMsg += " Price is $" + obj.newRate;

    let errMsg = "";
    let errFlag = false;
    let enoughCreditFlag = true;
    if (
      parseFloat(this.props.credits.creditAvailable) < parseFloat(obj.newRate)
    ) {
      errMsg =
        "Insufficient credits to buy return postage. Please get credit first.";
      errFlag = true;
      enoughCreditFlag = false;
    } else {
      errMsg =
        "The credit avalability at transaction time will be requered for the completion.";
        errFlag = false;
    }

    this.setState({
      selectedRateMsg,
      selectedRateObj: obj,
      selectedRate: obj.newRate,
      showSelectedRate: true,
      directShipmentBuyflag: false,
      confirmRate: false,
      enoughCreditFlag,
      errMsg,
      errFlag,
    });
  };

  handleShippingRateBuy = async () => {
    this.setState((prevstate) => ({
      confirmRate: !prevstate.confirmRate,
    }));
  };

  getCommunityInfo = async (communityId) => {
    let parms = "communityId=" + parseFloat(communityId);
    let url = baandaServer + getCommunityInfo + parms;
    // console.log("getCommunityInfo url:", url);
    let community = null;
    try {
      let retData = await axios.get(url);
      // console.log("SellCredit retData: ", retData);
      if (retData.data.status === "success") {
        community = retData.data.Msg;
      } else {
        await this.setState({
          message: retData.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
      await this.setState({
        message: err.message,
        errFlag: true,
      });
    }

    return community;
  };

  inputForPostCredit = async () => {
    let baandaCommunity = await this.getCommunityInfo(BaandaCommunityId);
    let businessCommunity = await this.getCommunityInfo(this.props.communityId);
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    // console.log('this.state.selectedRateObj:', this.state.selectedRateObj);
    let input = {
      shipId: this.state.selectedRateObj.shipment_id,
      rate: this.state.selectedRateObj.newRate,
      easyPostRate: this.state.selectedRateObj.List,
      baandaBuyPostageRate: this.state.selectedRateObj.Rate,
      clientProgram: "HandleShippingDetails",
      clientFunction: "handleShippingBuyViaCredit",
      communityId: parseFloat(this.props.communityId),
      coopStoreId,
      commFinMMDD: businessCommunity.finMMDD,
      commFinYYYY: businessCommunity.finYYYY,
      BaandaCommunityId: parseFloat(BaandaCommunityId),
      baandaComFinMMDD: baandaCommunity.finMMDD,
      baandaComFinYYYY: baandaCommunity.finYYYY,
      baandaId: this.props.auth.user.baandaId,
      baandaName: this.props.auth.user.name,
      baandaEmail: this.props.auth.user.email,
      invoiceId: this.props.invoiceId,
      refundCancelId: this.props.returnCancel.refundCancelId,
      selectedCarrier: this.state.selectedCarrier,
      clientSideDate: new Date(),
      thePkgNumber: 1,
      totalNumberOfPkg: 1,
      buyType: "returnShipping",
    };

    return input;
  };

  handleShippingBuyViaCredit = async () => {
    let baseInp = await this.inputForPostCredit();
    // console.log("baseInp:", baseInp);

    try {
      let url = baandaServer + postUseCredits;
      // console.log("url:", url, " baseInp:", baseInp);
      let shipBuy = await axios.post(url, baseInp);
      // console.log("shipBuy:", shipBuy);
      if (shipBuy.data.status === "success") {
        // if (shipBuy.data.Msg.transStatus) {
        if (shipBuy.data.Msg.transStatus === "notok") {
          this.setState({
            directShipmentBuyflag: true,
            showSelectedRate: false,
            insufficientMsg: shipBuy.data.Msg.note,
            showLabelFlag: false,
            buyResponse: null,
            accStatus: shipBuy.data.Msg.accStatus,
            accMsg: shipBuy.data.Msg.accMsg,
            labelurl: "",
            labelTrack: "",
            trackerurl: "",
            errMsg: shipBuy.data.Msg.note,
            errFlag: true,
          });
        } else {
          if (
            shipBuy.data.Msg.buyResponse &&
            shipBuy.data.Msg.buyResponse.status === "success"
          ) {
            let response = {
              directShipmentBuyflag: false,
              showSelectedRate: false,
              insufficientMsg: "",
              message: "Shipment label successfully aquired",
              showLabelFlag: true,
              buyResponse: shipBuy.data.Msg.buyResponse.Msg,
              accStatus: shipBuy.data.Msg.buyResponse.Msg.accStatus,
              accMsg: shipBuy.data.Msg.accMsg,
              labelurl: shipBuy.data.Msg.buyResponse.Msg.label_url,
              labelTrack:
                "Track Id: " + shipBuy.data.Msg.buyResponse.Msg.trackId,
              public_url: shipBuy.data.Msg.buyResponse.Msg.public_url,
            };
            this.setState({
              response
            })
            this.props.returnToCaller("success");
          } else {
            console.log("We got an error on buyResponse 33333");
            this.setState({
              message: shipBuy.data.Msg,
              errFlag: true,
            })
          }
        }
        // }
      } else {
        this.setState({
          message: shipBuy.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("HandleShippingBuy err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  returnToRefund = () => {
    this.props.returnToCaller(null);
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('BuyReturnPostage...');

    // Upload Spinner _____________________________________________
    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        // <div>
        <ReactLoading
          type={"spokes"}
          color={"#237547"}
          height={25}
          width={25}
          className="comm-entry-spinner"
        />
        // </div>
      );
    } else {
      uploadingSpin = null;
    }
    // Upload Panel _______________________________________________

    let returnToRefundBtn;
    returnToRefundBtn = (
      <button
        onClick={this.returnToRefund}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let modalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let selectRateButton = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={this.handleShippingBuyViaCredit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Buy
      </button>
    );

    let pkglist;
    if (this.state.defaultPkgs.length > 0) {
      pkglist = this.state.defaultPkgs.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj.pkgName}&nbsp;
          </option>
        );
      });
    }

    let defaultPkgPanel;
    if (this.state.defaultPkgs.length > 0) {
      defaultPkgPanel = (
        <div>
          <select
            size={this.state.selheightpkg}
            onFocus={this.onfocuspkg}
            onBlur={this.onblurpkg}
            id="shipPackageRetPostSelect"
            name="shipPackageRetPostSelect"
            onChange={this.handlePkgSelected}
            onSelect={this.handlePkgSelected}
            className="ship_select_pkgs_return"
            defaultValue={"DEFAULT"}
          >
            <option className="" value="DEFAULT" disabled>
              Select default pkg
            </option>
            {pkglist}
          </select>
        </div>
      );
    } else {
      defaultPkgPanel = (
        <div className="text-center ship-default-pkg-not-set">
          No default pkgs defined.
        </div>
      );
    }

    let fd = this.props.deliveryData;
    let fromAddrPanel;
    fromAddrPanel = (
      <div className="">
        <div className="row">
          <div className="col-2 text-right ret-pos-addr-lbl">From</div>
          <div className="col-10 text-left ret-pos-from-addr">
            {this.props.customerName} @&nbsp;
            {fd.street1} {fd.street2 !== "" ? fd.street2 : ""}, {fd.city},{" "}
            {fd.state} {fd.zip} {fd.country}
          </div>
        </div>
      </div>
    );

    let toAddrPanel;
    toAddrPanel = (
      <div className="to-manual-panel-pos">
        <div className="row addr-row">
          <div className="col-3 ship-manual-lbl-post text-right">To Name</div>
          <div className="col-9 text-left">
            <input
              name="toName"
              type="text"
              value={this.state.toName}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="ship-st-1-post"
              placeholder=""
            />
          </div>
        </div>
        <div className="row addr-row">
          <div className="col-3 ship-manual-lbl-post text-right">Street</div>
          <div className="col-9 text-left">
            <input
              name="toStreet"
              type="text"
              value={this.state.toStreet}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="ship-st-1-post"
              placeholder=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <div className="row addr-row">
              <div className="col-3 ship-manual-lbl-post text-right">City</div>
              <div className="col-9 text-left">
                <input
                  name="toCity"
                  type="text"
                  value={this.state.toCity}
                  onChange={this.onChange}
                  size="50"
                  maxLength="50"
                  className="ship-man-city-post"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="row addr-row">
              <div className="col-3 ship-manual-lbl-post text-center">
                State
              </div>
              <div className="col-9 text-left">
                &nbsp;&nbsp;
                <input
                  name="toState"
                  type="text"
                  value={this.state.toState}
                  onChange={this.onChange}
                  size="2"
                  maxLength="2"
                  className="ship-man-state-post"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="row addr-row">
              <div className="col-3 ship-manual-lbl-post text-right">Zip</div>
              <div className="col-9 text-left">
                <input
                  name="toZip"
                  type="text"
                  value={this.state.toZip}
                  onChange={this.onChange}
                  size="10"
                  maxLength="10"
                  className="ship-man-zip-post"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="row addr-row">
              <div className="col-2 ship-manual-lbl-post text-left">
                Country
              </div>
              <div className="col-10 text-left">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  name="toCountry"
                  type="text"
                  value={this.state.toCountry}
                  onChange={this.onChange}
                  size="25"
                  maxLength="25"
                  className="ship-man-country-post"
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    let dim = "inchs";
    let weight = "lbs";
    let dimensionPanel;
    dimensionPanel = (
      <div>
        <div className="text-center pkg-dim-pos-ret">Package Dimension</div>
        <div className="row pkg-row-pos">
          <div className="col-3">{defaultPkgPanel}</div>
          <div className="col-9">
            <div className="row pkg-row-pos">
              <div className="col-3 text-right pkg-dim-lbl">Length</div>
              <div className="col-3 text-left">
                <input
                  name="pkgLength"
                  type="number"
                  value={this.state.pkgLength}
                  onChange={this.onChangeDimension}
                  className="ship-ret-dimension-text"
                  step="1"
                  placeholder="0"
                  autoComplete="off"
                  spellCheck="false"
                />
                &nbsp;{dim}
              </div>
              <div className="col-3 text-right pkg-dim-lbl">Width</div>
              <div className="col-3 text-left">
                <input
                  name="packageWidth"
                  type="number"
                  value={this.state.packageWidth}
                  onChange={this.onChangeDimension}
                  // size="10"
                  className="ship-ret-dimension-text"
                  step="1"
                  placeholder="0"
                  autoComplete="off"
                  spellCheck="false"
                />
                &nbsp;{dim}
              </div>
            </div>
            <div className="row pkg-row-pos">
              <div className="col-3 text-right pkg-dim-lbl">Height</div>
              <div className="col-3 text-left">
                <input
                  name="packageHeight"
                  type="number"
                  value={this.state.packageHeight}
                  onChange={this.onChangeWeight}
                  // size="10"
                  className="ship-ret-dimension-text"
                  step="1"
                  placeholder="0"
                  autoComplete="off"
                  spellCheck="false"
                />
                &nbsp;{dim}
              </div>
              <div className="col-3 text-right pkg-dim-lbl">Weight</div>
              <div className="col-3 text-left">
                <input
                  name="packageWeight"
                  type="number"
                  value={this.state.packageWeight}
                  onChange={this.onChangeWeight}
                  // size="10"
                  className="ship-ret-dimension-text"
                  step="1"
                  placeholder="0"
                  autoComplete="off"
                  spellCheck="false"
                />
                &nbsp;{weight}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    let carrierPanel;
    carrierPanel = (
      <div>
        <select
          size={this.state.selheight}
          onFocus={this.onfocus}
          onBlur={this.onblur}
          id="shipRetPostCarrierSelect"
          name="shipRetPostCarrierSelect"
          onChange={this.handleCarrierSelected}
          onSelect={this.handleCarrierSelected}
          className="ship_select_carrier"
          defaultValue={"DEFAULT"}
        >
          <option className="" value="DEFAULT" disabled>
            Select your carrier
          </option>
          <option className="" value="USPS">
            USPS
          </option>
          <option className="" value="UPS">
            UPS
          </option>
          <option className="" value="DHL">
            DHL (International)
          </option>
        </select>
      </div>
    );

    let showRatesButton;
    if (this.state.selectedCarrier !== "" && this.state.validated) {
      showRatesButton = (
        <button
          className="btn_reg_90 ret-post-show-rate-btn-pos"
          type="button"
          onClick={this.handleGetShipping}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Show Rates
        </button>
      );
    }

    let carrierRatePanel;

    carrierRatePanel = (
      <div>
        <div className="row">
          <div className="col-5 text-center ship-from-addr-lbl">
            {carrierPanel}
          </div>

          <div className="col-3 text-center spinner-pos-ship-buy">
            {uploadingSpin}
          </div>
          <div className="col-4 text-center ship-from-rates-pos">
            {showRatesButton}
          </div>
        </div>
      </div>
    );

    let bool = true;
    let rateToDisplayPanel;
    if (this.state.newRates.length > 0) {
      rateToDisplayPanel = (
        <div>
          <div className="row">
            <div className="col-2 text-center ship-line-items-dim-head">
              Ops
            </div>
            <div className="col-10 text-left ship-line-items-dim-head">
              Service || Delivery days || Rate [Select a service to pay/ship]
            </div>
          </div>
          <div className="fixedsize-ship-lineitem">
            {this.state.newRates.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={`${bool ? "row-color-xxrt" : "row-color-zzrt"}`}
                  >
                    <div className="row rate-row-pos">
                      <div className="col-2 text-left ">
                        <button
                          className="btn_reg_40"
                          type="button"
                          onClick={() => this.selectThisRate(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-check" />
                        </button>
                      </div>
                      <div className="col-10 text-left ship-rate-list-text">
                        {obj.service} || {obj.delivery_days} || $
                        {this.commaFormattedCurrency(obj.newRate)}
                      </div>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let selectedRatePanel;
    if (this.state.showSelectedRate) {
      selectedRatePanel = (
        <div className="text-center selected-rate-row-pos">
          <div className="row">
            <div className="col-1 text-right selected-rate-lbl">Selected: </div>
            <div className="col-7 text-left selected-rate-text">
              {this.state.selectedRateMsg}
            </div>
            {this.state.enoughCreditFlag ? (
              <div className="col-2 text-left selected-rate-text">
                <Checkbox
                  checked={this.state.confirmRate}
                  onChange={this.handleShippingRateBuy}
                />{" "}
                Confirm Buy
              </div>
            ) : (
              <div className="col-2 text-left selected-rate-text">&nbsp;</div>
            )}
            <div className="col-2 text-left selected-rate-lbl-btn">
              {this.state.confirmRate ? selectRateButton : null}
            </div>
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div className="return-buy-postage-box">
        <div className="row">
          <div className="col-8 rtn-post-header text-right">
            Get Return Postage {returnToRefundBtn} {modalButton}
          </div>
          <div className="col-4 rtn-post-credit text-center">
            <b>Credits</b> $
            {this.commaFormattedCurrency(this.props.credits.creditAvailable)}
          </div>
        </div>

        {fromAddrPanel}
        {toAddrPanel}
        {dimensionPanel}
        {carrierRatePanel}
        {rateToDisplayPanel}
        {selectedRatePanel}
        <div
          className={
            this.state.errFlag
              ? "text-center return-post-msg-err"
              : "text-center return-post-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );
    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

BuyReturnPostage.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BuyReturnPostage));
