import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import moment from "moment";
import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import CreateEvent from "./event/CreateEvent";
import ShowEventOfDay from "./event/ShowEventOfDay";

import "./CalendarHub.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCommCalendar = "/routes/interact/getCommCalendar?";

const eventSetupList = ["eventsetup"];

const readMeCode = "1145300000";

class CalendarHub extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",

      currentDate: moment(),
      monthNumber: 0,
      dayofMonth: 0,
      year: 0,
      monthName: "",
      firstOMWeekday: 0,
      firstOMWeekdayName: "",
      weekdays: [],

      activeMonth: 0,
      activeYear: 0,

      monTemplate: [],
      maptemp: [],

      testEvent1: [],
      testEvent2: [],

      showCalendar: true,
      showEventCreation: false,
      showEventsOfDay: false,
      todaysEvents: null,

      searchChannel: "eventType",

      searchState: false,
      eventType: "",
      eventTypeName: "",

      atest: false,
      searchEventNames: [],
      eventnameSelheight: 5,
      searchTheEvent: null,

      showErrMsgPanel: false,
      errMsg: "",

      eventButtonFlag: false,
    };
  }

  componentDidMount = async () => {
    let today = moment();

    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setAuthorizedButtons();

    await this.setMonthsCalendar(today);

    let showErrMsgPanel = false;
    let errMsg = "";
    // let showSearchFlag = true;
    if (deviceSize === "small") {
      // showSearchFlag = false;
      showErrMsgPanel = true;
      errMsg =
        "A device (not a smart phone) with a bigger display area is needed for the the community calendar and event handling. Please access this module on a larger device. ";
    }

    await this.setState({
      deviceSize,

      showErrMsgPanel,
      errMsg,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  setAuthorizedButtons = async () => {
    // console.log("### this.state.authorizedFuncs: ", this.props.authorizedFuncs);
    let eventButtonFlag = false;

    eventSetupList.forEach((obj1) => {
      if (this.props.authorizedFuncs.includes(obj1)) eventButtonFlag = true;
    });

    this.setState({
      eventButtonFlag,
    });
  };

  setMonthsCalendar = async (theday) => {
    let month = theday.format("M");
    let day = theday.format("D");
    let year = theday.format("YYYY");

    // console.log("11. month: ", month, " day:", day, " year:", year);
    let monthName = moment(month, "MM").format("MMMM");
    // console.log("22. monthName:", monthName);

    let firstDay = moment().startOf("month");
    // console.log(">> firstDay:", firstDay);

    let weekdayName = firstDay.format("dddd");
    let wsn = moment.weekdaysShort();
    // console.log("33. wsn:", wsn);

    await this.setUpCalendarRows(theday);
    await this.insertEventsInCalander();
    await this.convertToMatrix(theday);
    // await this.setRow(theday);

    await this.setState({
      monthNumber: month,
      dayofMonth: day,
      year,
      monthName,
      activeMonth: month,
      activeYear: year,
      firstOMWeekday: firstDay,
      firstOMWeekdayName: weekdayName,
      weekdays: wsn,
    });
  };

  setUpCalendarRows = async (today) => {
    var fd = today.startOf("month");
    // console.log("fd:", fd);
    var dom = fd.daysInMonth();
    // console.log("dom:", dom);
    let firstweekday = fd.day();
    // console.log("firstweekday:", firstweekday);

    let cells = [];
    let calCells = 42;
    let obj;
    let dayOfWeek;
    let td;
    let k = 0;

    for (var i = 0; i < calCells; i++) {
      if (i < 7) dayOfWeek = i;
      else dayOfWeek = i % 7;

      if (i < firstweekday) {
        td = await this.subtractDaysMoment(fd, firstweekday - i);
        // console.log("1. here");
        obj = {
          isEmpty: true,
          today: td,
          dayOfweek: dayOfWeek,
        };
      } else {
        if (i <= dom + firstweekday - 1) {
          td = await this.addDaysMoment(fd, k);
          obj = {
            isEmpty: false,
            today: td,
            dayOfweek: dayOfWeek,
          };
          k++;
        } else {
          td = await this.addDaysMoment(fd, k);
          obj = {
            isEmpty: true,
            today: td,
            dayOfweek: dayOfWeek,
          };
          k++;
        }
      }
      cells.push(obj);
    }

    // console.log("cells:", cells);
    this.setState({
      monTemplate: cells,
    });
  };

  insertEventsInCalander = async () => {
    let fd = moment(this.state.monTemplate[0].today).toDate();
    let ld = moment(
      this.state.monTemplate[this.state.monTemplate.length - 1].today
    ).toDate();

    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    try {
      let params =
        "communityId=" +
        this.props.communityId +
        "&coopStoreId=" +
        coopStoreId +
        "&firstDay=" +
        fd +
        "&lastday=" +
        ld;
      let url = baandaServer + getCommCalendar + params;
      // console.log("url:", url);
      let ret = await axios.get(url);
      // console.log("ret.data:", ret.data);
      if (ret.data.status === "success") {
        await this.sewCalendarToEvents(ret.data.Msg);
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  sewCalendarToEvents = async (cal) => {
    let evecal = [];
    // console.log("==========================");
    this.state.monTemplate.forEach((obj) => {
      let ttx = moment(obj.today).format("MM-DD-YYYY");
      // console.log("ttx:", ttx);
      let ceObj = {
        searchFlag: false,
        dayOfweek: obj.dayOfweek,
        isEmpty: obj.isEmpty,
        today: obj.today,
        event: null,
      };
      cal.forEach((elm) => {
        if (elm.calDateString === ttx) {
          ceObj.event = elm;
        }
      });
      evecal.push(ceObj);
    });
    await this.setState({
      monTemplate: evecal,
    });
    // console.log("evecal:", evecal);
    // console.log("==========================");
  };

  convertToMatrix = async (today) => {
    // var fd = today.startOf("month");
    // console.log("fd:", fd);
    // console.log("==========================");

    let i = 0;
    let arr1 = [],
      arr2 = [],
      arr3 = [],
      arr4 = [],
      arr5 = [],
      arr6 = [];
    let arrf = [];
    // console.log("this.state.monTemplate:", this.state.monTemplate);

    this.state.monTemplate.forEach((obj) => {
      if (i <= 6) {
        arr1.push(obj);
      }
      if (i > 6 && i <= 13) {
        // console.log(i, ". xx obj:", obj);
        arr2.push(obj);
      }
      if (i > 13 && i <= 20) {
        arr3.push(obj);
      }
      if (i > 20 && i <= 27) {
        arr4.push(obj);
      }
      if (i > 27 && i <= 34) {
        arr5.push(obj);
      }
      if (i > 34 && i <= 42) {
        arr6.push(obj);
      }

      i++;
    });

    arrf.push(arr1);
    arrf.push(arr2);
    arrf.push(arr3);
    arrf.push(arr4);
    arrf.push(arr5);
    arrf.push(arr6);

    await this.setState({
      maptemp: arrf,
    });

    // console.log("arrf:", arrf);
    // console.log("==========================");
  };

  // ==============================
  addDaysMoment = async (date, days) => {
    const copy = date.clone();
    copy.add(days, "days");
    return copy;
  };

  subtractDaysMoment = async (date, days) => {
    // console.log("2. here date:", date, " days:", days);
    const copy = date.clone();
    copy.subtract(days, "days");
    return copy;
  };

  handlePrivNext = async (inp) => {
    // console.log("inp:", inp);
    if (inp === "previous") {
      let pr = await this.getPreviousDate(); // returns
      // console.log("pr: ", pr);
      await this.setUpCalendarRows(pr);
      await this.insertEventsInCalander();
      await this.convertToMatrix(pr);
    }
    if (inp === "next") {
      let pn = await this.getNextDate(); // returns
      // console.log("pn: ", pn);
      await this.setUpCalendarRows(pn);
      await this.insertEventsInCalander();
      await this.convertToMatrix(pn);
    }
    if (inp === "event") {
      await this.handleEventCreation();
    }
  };

  handleEventCreation = async () => {
    await this.setState({
      showCalendar: false,
      showEventsOfDay: false,
      showEventCreation: true,
    });
  };

  returnFromEventCreation = async () => {
    // console.log("Return from publish ");
    let today = moment();
    await this.setMonthsCalendar(today);

    await this.setUpCalendarRows(today);
    await this.insertEventsInCalander();
    await this.convertToMatrix(today);

    await this.setState({
      showCalendar: true,
      showEventsOfDay: false,
      showEventCreation: false,
    });
  };

  returnFromShowEventOfDay = async () => {
    await this.setState({
      showCalendar: true,
      showEventsOfDay: false,
      showEventCreation: false,
    });
  };

  getPreviousDate = async () => {
    let pm = parseInt(this.state.activeMonth) - 1;
    let py = parseInt(this.state.activeYear);

    if (pm < 1) {
      pm = 12;
      py = py - 1;
    }
    if (
      pm === this.state.currentDate.format("M") &&
      py === this.state.currentDate.format("YYYY")
    ) {
      return this.state.currentDate;
    }
    let pmStr;
    if (pm < 10) pmStr = "0" + pm.toString();
    else pmStr = pm.toString();

    let tdate = pmStr + "-01-" + py.toString();
    let actOnDate = moment(tdate, "MM-DD-YYYY");
    // console.log("actOnDate:", actOnDate);
    await this.setState({
      activeMonth: pm.toString(),
      activeYear: py.toString(),
    });

    return actOnDate;
  };

  getNextDate = async () => {
    // console.log("this.state.activeMonth:", parseInt(this.state.activeMonth));
    let pm = parseInt(this.state.activeMonth) + 1;
    let py = parseInt(this.state.activeYear);
    if (pm > 12) {
      pm = 1;
      py = py + 1;
    }
    // console.log("pm:", pm, " py:", py);
    if (
      pm === this.state.currentDate.format("M") &&
      py === this.state.currentDate.format("YYYY")
    ) {
      return this.state.currentDate;
    }
    let pmStr;
    if (pm < 10) pmStr = "0" + pm.toString();
    else pmStr = pm.toString();
    let tdate = pmStr + "-01-" + py.toString();
    let actOnDate = moment(tdate, "MM-DD-YYYY");
    // console.log("actOnDate:", actOnDate);
    await this.setState({
      activeMonth: pm.toString(),
      activeYear: py.toString(),
    });

    return actOnDate;
  };

  handleCalendarOpen = async (obj) => {
    // console.log("handleCalendarOpen obj:", obj);
    let cleanEvents = [];
    // Eliminate any event  with EventId === 0 (garbage data situation)
    obj.event.events.forEach((elm) => {
      if (elm.eventId !== 0) cleanEvents.push(elm);
    });
    let cleanObj = JSON.parse(JSON.stringify(obj));
    cleanObj.event.events = cleanEvents;

    await this.setState({
      showCalendar: false,
      showEventsOfDay: true,
      showEventCreation: false,
      todaysEvents: cleanObj,
    });
  };

  handleSearch = async () => {
    await this.setState({
      showCalSearch: true,
      searchState: true,
      searchChannel: "eventType",
      eventType: "",
    });
  };

  closeSearch = async () => {
    await this.clearSearchMarkings();
    await this.setState({
      showCalSearch: false,
      searchState: false,
      searchTheEvent: null,
    });
  };

  clearSearchMarkings = async () => {
    let moncalc = [];
    this.state.monTemplate.forEach((obj) => {
      let arrobj = {
        dayOfweek: obj.dayOfweek,
        event: obj.event,
        isEmpty: obj.isEmpty,
        today: obj.today,
        searchFlag: false,
      };
      moncalc.push(arrobj);
    });

    await this.setState({
      monTemplate: moncalc,
    });

    let today = moment();
    if (parseFloat(this.state.activeYear) !== 0) {
      let yr = 0,
        mm = 0,
        dd = "10";
      yr = this.state.activeYear;
      if (parseFloat(this.state.activeMonth) < 10)
        mm = "0" + this.state.activeMonth;
      else mm = this.state.activeMonth;
      let datestr = yr + "-" + mm + "-" + dd;
      today = moment(datestr);
    }

    await this.convertToMatrix(today);
  };

  markMapTempIfMeetSearchCriteria = async () => {
    let moncalc = [];
    if (this.state.searchChannel === "eventType") {
      this.state.monTemplate.forEach((obj) => {
        let flag = false;
        if (obj.event && obj.event.events && obj.event.events.length > 0) {
          obj.event.events.forEach((elm) => {
            if (elm.eventId !== 0 && elm.eventType === this.state.eventType) {
              flag = true;
            }
          });
        }
        let arrobj = {
          dayOfweek: obj.dayOfweek,
          event: obj.event,
          isEmpty: obj.isEmpty,
          today: obj.today,
          searchFlag: flag,
        };
        moncalc.push(arrobj);
      });
    }

    if (this.state.searchChannel === "eventname") {
      this.state.monTemplate.forEach((obj) => {
        let flag = false;
        if (obj.event && obj.event.events && obj.event.events.length > 0) {
          obj.event.events.forEach((elm) => {
            if (
              elm.eventId !== 0 &&
              elm.eventName === this.state.searchTheEvent.eventName
            ) {
              flag = true;
            }
          });
        }
        let arrobj = {
          dayOfweek: obj.dayOfweek,
          event: obj.event,
          isEmpty: obj.isEmpty,
          today: obj.today,
          searchFlag: flag,
        };
        moncalc.push(arrobj);
      });
    }

    await this.setState({
      monTemplate: moncalc,
    });

    let today = moment();
    if (parseFloat(this.state.activeYear) !== 0) {
      let yr = 0,
        mm = 0,
        dd = "10";
      yr = this.state.activeYear;
      if (parseFloat(this.state.activeMonth) < 10)
        mm = "0" + this.state.activeMonth;
      else mm = this.state.activeMonth;
      let datestr = yr + "-" + mm + "-" + dd;
      today = moment(datestr);
    }

    await this.convertToMatrix(today);
  };

  handleHighlight = async () => {
    // console.log("handle highlight");
    await this.markMapTempIfMeetSearchCriteria();

    await this.setState({
      showCalSearch: false,
      searchState: true,
    });
  };

  handleMedium = async (e) => {
    let value = e.target.value;
    if (value === "eventname") {
      await this.getAllEventNames();
    }

    await this.setState({
      searchChannel: value,
    });
  };

  getAllEventNames = async () => {
    let en = []; // en is all event names
    this.state.monTemplate.forEach((obj) => {
      if (obj.event && obj.event.events.length > 0) {
        obj.event.events.forEach((elm) => {
          if (elm.eventId !== 0) {
            let exists = false;
            en.forEach((rep) => {
              if (en.shortName === elm.shortName) exists = true;
            });
            if (!exists) {
              let nnobj = {
                shortName: elm.shortName,
                eventName: elm.eventName,
              };
              en.push(nnobj);
            }
          }
        });
      }
    });
    await this.setState({
      searchEventNames: en,
    });
  };

  handleEventType = async (e) => {
    await this.setState({
      eventType: e.target.value,
    });
  };

  handleEventNameSelect = async () => {
    let value = document.getElementById("eventNameCommSelected").value;
    let obj = JSON.parse(value);
    // console.log("eventNameCommSelected obj:", obj);

    await this.setState({
      searchTheEvent: obj,
    });

    // await this.createTheTaskTeam(obj);
    // if (document.getElementById("eventNameCommSelected"))
    //   document.getElementById("eventNameCommSelected").value = "DEFAULT";
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("CalendarHub...");

    let wsn = this.state.weekdays;

    let leftButtonPanel = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={() => this.handlePrivNext("previous")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Prev
      </button>
    );

    let rightButtonPanel = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={() => this.handlePrivNext("next")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Next
      </button>
    );

    let eventButtonPanel;
    if (this.state.eventButtonFlag) {
      eventButtonPanel = (
        <button
          className="btn_reg_50"
          type="button"
          onClick={() => this.handlePrivNext("event")}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Event
        </button>
      );
    }

    let searchButtonPanel;
    searchButtonPanel = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={this.handleSearch}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search"></i>
      </button>
    );

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let closeSearchButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.closeSearch}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let searchFetchButton = (
      <button
        className="btn-go-filter"
        type="button"
        onClick={this.handleHighlight}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Flag
      </button>
    );

    let searchDetailPanel;
    if (this.state.searchChannel === "eventType") {
      searchDetailPanel = (
        <div className="text-center search-inner-box">
          <strong>Event Type &nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="service"
                checked={this.state.eventType === "service"}
                onChange={this.handleEventType}
              />{" "}
              Service
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="nonservice"
                checked={this.state.eventType === "nonservice"}
                onChange={this.handleEventType}
              />{" "}
              Non-service Events
            </label>
          </div>
        </div>
      );
    }
    if (this.state.searchChannel === "eventname") {
      let sellist;

      sellist = this.state.searchEventNames.map((obj, i) => {
        return (
          <option key={i + 2} value={JSON.stringify(obj)}>
            {obj.eventName}&nbsp;({obj.shortName})
          </option>
        );
      });

      let eventNameDropdownPanel;
      eventNameDropdownPanel = (
        <div>
          <div className="row">
            <div className="col">
              <select
                size={this.state.eventnameSelheight}
                // onFocus={this.onfocus}
                // onBlur={this.onblur}
                id="eventNameCommSelected"
                name="eventNameCommSelected"
                onChange={this.handleEventNameSelect}
                className="event-name-select"
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Select event name to search
                </option>
                {sellist}
              </select>
            </div>
          </div>
        </div>
      );

      searchDetailPanel = (
        <div className="text-center search-inner-box">
          {eventNameDropdownPanel}
        </div>
      );
    }

    let searchDefinePanel;
    if (this.state.showCalSearch) {
      searchDefinePanel = (
        <div className="define-cal-search-box">
          <div className="search-marker-header">
            Search & Flag Events By &nbsp;{closeSearchButton}
          </div>

          <div className="row row-medium">
            <div className="col">
              <strong>By&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="eventType"
                    checked={this.state.searchChannel === "eventType"}
                    onChange={this.handleMedium}
                  />{" "}
                  Event Type
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="eventname"
                    checked={this.state.searchChannel === "eventname"}
                    onChange={this.handleMedium}
                  />{" "}
                  Event Name
                </label>
              </div>
            </div>
          </div>
          {searchDetailPanel}
          <div className="text-center">{searchFetchButton}</div>
        </div>
      );
    }

    let headerPanel;
    headerPanel = (
      <div className="row">
        <div className="col-2 text-left">{leftButtonPanel}</div>
        <div className="col-5 text-center header-text-x">
          <p className="text-center cur-date-header">
            (Community)&nbsp;&nbsp;Today:&nbsp;
            {this.state.currentDate.format("MM-DD-YYYY")}
          </p>
          <p className="text-center active-mon-header">
            Calendar of&nbsp;
            {moment(parseInt(this.state.activeMonth), "MM").format("MMMM")}
            ,&nbsp;{this.state.activeYear}
          </p>
        </div>
        <div className="col-3 text-center calander-button-pos">
          {searchButtonPanel}&nbsp;{eventButtonPanel}&nbsp;{infoModalButton}
        </div>
        <div className="col-2 text-right">{rightButtonPanel}</div>
        {searchDefinePanel}
      </div>
    );

    let dayline = (
      <div className="row cal-weekrow-padding">
        <span className="col day-one">{wsn[0]}</span>
        <span className="col day-two">{wsn[1]}</span>
        <span className="col day-one">{wsn[2]}</span>
        <span className="col day-two">{wsn[3]}</span>
        <span className="col day-one">{wsn[4]}</span>
        <span className="col day-two">{wsn[5]}</span>
        <span className="col day-one">{wsn[6]}</span>
      </div>
    );

    let calBodyPanel;
    calBodyPanel = (
      <div>
        {this.state.maptemp.map((obj, i) => {
          // console.log(i, ". obj:", obj);
          return (
            <div key={i}>
              <div className="row something">
                <span
                  className={
                    obj[0].isEmpty
                      ? "col day-in-shade text-center"
                      : "col day-two-1 text-center"
                  }
                >
                  <div className="row">
                    <div className="col-4 top-left">
                      {obj[0].today.format("D")}
                    </div>
                    {obj[0].searchFlag ? (
                      <div className="col-4 text-center">
                        <i className="fa fa-flag-checkered" />
                      </div>
                    ) : (
                      <div className="col-4 text-center"></div>
                    )}
                    {obj[0].event && obj[0].event.events.length > 0 ? (
                      <div className="col-4 text-center">
                        <button
                          className="btn-cal-day-open"
                          type="button"
                          onClick={() => this.handleCalendarOpen(obj[0])}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-folder-open" />
                        </button>
                      </div>
                    ) : (
                      <div className="col-4 text-center">&nbsp;</div>
                    )}
                  </div>
                  {obj[0].event ? (
                    <div className="event-container">
                      {obj[0].event.events.map((elm, j) => {
                        if (elm.eventId !== 0) {
                          return (
                            <div key={j} className="row">
                              <div className="col text-left cell-events">
                                -&nbsp;{elm.shortName}
                              </div>
                            </div>
                          );
                        } else return null;
                      })}
                    </div>
                  ) : null}
                </span>

                <span
                  className={
                    obj[1].isEmpty
                      ? "col day-in-shade text-center"
                      : "col day-two-1 text-center"
                  }
                >
                  <div className="row">
                    <div className="col-4 top-left">
                      {obj[1].today.format("D")}
                    </div>
                    {obj[1].searchFlag ? (
                      <div className="col-4 text-center">
                        <i className="fa fa-flag-checkered" />
                      </div>
                    ) : (
                      <div className="col-4 text-center"></div>
                    )}
                    {obj[1].event && obj[1].event.events.length > 0 ? (
                      <div className="col-4 text-center">
                        <button
                          className="btn-cal-day-open"
                          type="button"
                          onClick={() => this.handleCalendarOpen(obj[1])}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-folder-open" />
                        </button>
                      </div>
                    ) : (
                      <div className="col-4 text-center">&nbsp;</div>
                    )}
                  </div>
                  {obj[1].event ? (
                    <div className="event-container">
                      {obj[1].event.events.map((elm, j) => {
                        if (elm.eventId !== 0) {
                          return (
                            <div key={j} className="row">
                              <div className="col text-left cell-events">
                                -&nbsp;{elm.shortName}
                              </div>
                            </div>
                          );
                        } else return null;
                      })}
                    </div>
                  ) : null}
                </span>

                <span
                  className={
                    obj[2].isEmpty
                      ? "col day-in-shade text-center"
                      : "col day-two-1 text-center"
                  }
                >
                  <div className="row">
                    <div className="col-4 top-left">
                      {obj[2].today.format("D")}
                    </div>
                    {obj[2].searchFlag ? (
                      <div className="col-4 text-center">
                        <i className="fa fa-flag-checkered" />
                      </div>
                    ) : (
                      <div className="col-4 text-center"></div>
                    )}
                    {obj[2].event && obj[2].event.events.length > 0 ? (
                      <div className="col-4 text-center">
                        <button
                          className="btn-cal-day-open"
                          type="button"
                          onClick={() => this.handleCalendarOpen(obj[2])}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-folder-open" />
                        </button>
                      </div>
                    ) : (
                      <div className="col-4 text-center">&nbsp;</div>
                    )}
                  </div>
                  {obj[2].event ? (
                    <div className="event-container">
                      {obj[2].event.events.map((elm, j) => {
                        if (elm.eventId !== 0) {
                          return (
                            <div key={j} className="row">
                              <div className="col text-left cell-events">
                                -&nbsp;{elm.shortName}
                              </div>
                            </div>
                          );
                        } else return null;
                      })}
                    </div>
                  ) : null}
                </span>

                <span
                  className={
                    obj[3].isEmpty
                      ? "col day-in-shade text-center"
                      : "col day-two-1 text-center"
                  }
                >
                  <div className="row">
                    <div className="col-4 top-left">
                      {obj[3].today.format("D")}
                    </div>
                    {obj[3].searchFlag ? (
                      <div className="col-4 text-center">
                        <i className="fa fa-flag-checkered" />
                      </div>
                    ) : (
                      <div className="col-4 text-center"></div>
                    )}
                    {obj[3].event && obj[3].event.events.length > 0 ? (
                      <div className="col-4 text-center">
                        <button
                          className="btn-cal-day-open"
                          type="button"
                          onClick={() => this.handleCalendarOpen(obj[3])}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-folder-open" />
                        </button>
                      </div>
                    ) : (
                      <div className="col-4 text-center">&nbsp;</div>
                    )}
                  </div>
                  {obj[3].event ? (
                    <div className="event-container">
                      {obj[3].event.events.map((elm, j) => {
                        if (elm.eventId !== 0) {
                          return (
                            <div key={j} className="row">
                              <div className="col text-left cell-events">
                                -&nbsp;{elm.shortName}
                              </div>
                            </div>
                          );
                        } else return null;
                      })}
                    </div>
                  ) : null}
                </span>

                <span
                  className={
                    obj[4].isEmpty
                      ? "col day-in-shade text-center"
                      : "col day-two-1 text-center"
                  }
                >
                  <div className="row">
                    <div className="col-4 top-left">
                      {obj[4].today.format("D")}
                    </div>
                    {obj[4].searchFlag ? (
                      <div className="col-4 text-center">
                        <i className="fa fa-flag-checkered" />
                      </div>
                    ) : (
                      <div className="col-4 text-center"></div>
                    )}
                    {obj[4].event && obj[4].event.events.length > 0 ? (
                      <div className="col-4 text-center">
                        <button
                          className="btn-cal-day-open"
                          type="button"
                          onClick={() => this.handleCalendarOpen(obj[4])}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-folder-open" />
                        </button>
                      </div>
                    ) : (
                      <div className="col-4 text-center">&nbsp;</div>
                    )}
                  </div>
                  {obj[4].event ? (
                    <div className="event-container">
                      {obj[4].event.events.map((elm, j) => {
                        if (elm.eventId !== 0) {
                          return (
                            <div key={j} className="row">
                              <div className="col text-left cell-events">
                                -&nbsp;{elm.shortName}
                              </div>
                            </div>
                          );
                        } else return null;
                      })}
                    </div>
                  ) : null}
                </span>

                <span
                  className={
                    obj[5].isEmpty
                      ? "col day-in-shade text-center"
                      : "col day-two-1 text-center"
                  }
                >
                  <div className="row">
                    <div className="col-4 top-left">
                      {obj[5].today.format("D")}
                    </div>
                    {obj[5].searchFlag ? (
                      <div className="col-4 text-center">
                        <i className="fa fa-flag-checkered" />
                      </div>
                    ) : (
                      <div className="col-4 text-center"></div>
                    )}
                    {obj[5].event && obj[5].event.events.length > 0 ? (
                      <div className="col-4 text-center">
                        <button
                          className="btn-cal-day-open"
                          type="button"
                          onClick={() => this.handleCalendarOpen(obj[5])}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-folder-open" />
                        </button>
                      </div>
                    ) : (
                      <div className="col-4 text-center">&nbsp;</div>
                    )}
                  </div>
                  {obj[5].event ? (
                    <div className="event-container">
                      {obj[5].event.events.map((elm, j) => {
                        if (elm.eventId !== 0) {
                          return (
                            <div key={j} className="row">
                              <div className="col text-left cell-events">
                                -&nbsp;{elm.shortName}
                              </div>
                            </div>
                          );
                        } else return null;
                      })}
                    </div>
                  ) : null}
                </span>

                <span
                  className={
                    obj[6].isEmpty
                      ? "col day-in-shade text-center"
                      : "col day-two-1 text-center"
                  }
                >
                  <div className="row">
                    <div className="col-4 top-left">
                      {obj[6].today.format("D")}
                    </div>
                    {obj[6].searchFlag ? (
                      <div className="col-4 text-center">
                        <i className="fa fa-flag-checkered" />
                      </div>
                    ) : (
                      <div className="col-4 text-center"></div>
                    )}
                    {obj[6].event && obj[6].event.events.length > 0 ? (
                      <div className="col-4 text-center">
                        <button
                          className="btn-cal-day-open"
                          type="button"
                          onClick={() => this.handleCalendarOpen(obj[6])}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-folder-open" />
                        </button>
                      </div>
                    ) : (
                      <div className="col-4 text-center">&nbsp;</div>
                    )}
                  </div>
                  {obj[6].event ? (
                    <div className="event-container">
                      {obj[6].event.events.map((elm, j) => {
                        if (elm.eventId !== 0) {
                          return (
                            <div key={j} className="row">
                              <div className="col text-left cell-events">
                                -&nbsp;{elm.shortName}
                              </div>
                            </div>
                          );
                        } else return null;
                      })}
                    </div>
                  ) : null}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );

    let activePanel;

    if (this.state.showCalendar && !this.state.showErrMsgPanel) {
      activePanel = (
        <div className="calendar-box">
          {headerPanel}
          {dayline}
          {calBodyPanel}
        </div>
      );
    }

    if (this.state.showEventCreation && !this.state.showErrMsgPanel) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activePanel = (
        <div>
          <CreateEvent
            returnToCaller={this.returnFromEventCreation}
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showEventsOfDay && !this.state.showErrMsgPanel) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activePanel = (
        <div>
          <ShowEventOfDay
            returnToCaller={this.returnFromShowEventOfDay}
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            todaysEvents={this.state.todaysEvents}
            store={store}
          />
        </div>
      );
    }

    let errorPanel;
    if (this.state.showErrMsgPanel) {
      errorPanel = (
        <div className="catalog-error-box">
          <div className="cat-error-header">Message</div>
          <div>
            <p align="justify" className="device-error-msg">
              {this.state.errMsg}
            </p>
          </div>
        </div>
      );
    }

    let outputPanel;
    if (this.state.showErrMsgPanel) {
      outputPanel = errorPanel;
    } else {
      if ( this.state.deviceSize === 'big') {
        outputPanel = activePanel;
      }
    }

    return (
      <div className="text-center">
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CalendarHub.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
  // setQAInitDone: () => dispatch(setQAInitDone(userData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CalendarHub));
