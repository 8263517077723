import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";
// import ReactLoading from "react-loading";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./SubscriptionMgmt.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateCatalogItem = "/routes/dashboard/updateCatalogItem";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1125500000";

class SubscriptionMgmt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      catalog: null,

      allowSubscription: false,
      allowPeriodicDelivery: false,

      subUpfrontPercentage: 10,
      subInterestRate: 0,
      posSubscriptionOnly: false,
      subscriptionNote: "",

      intervalType: "monthly",
      numberOfInstallment: 1,
      subscriptionType: "fixedendtime",

      registrationFee: 0,

      subMsg: "",
      errFlag: false,

      // subscription delivery schedule
      // deliveryType: "ship",
      subShipList: [],
      friendlyName: "",
      shipPrice: 0,
      scheduleQty: 0,
      schDiscount: 0,

      deliveryFrequency: "onetime",
      // periodicityType: "periodicityopen",
      numberOfDelivery: 1,

      pkLength: 0.0,
      pkWidth: 0.0,
      pkHeight: 0.0,
      pkWeight: 0.0,
      pkLbs: 0,
      pkOzs: 0,
    };
  }

  componentDidMount = async () => {
    let tcat = this.props.catalog.subscription;
    // console.log("tcat:", tcat);
    let subShipList = [];
    if (tcat.priodicDeliveryDetails)
      subShipList = [...tcat.priodicDeliveryDetails];
    this.setState({
      allowSubscription: tcat.allowSubscription,
      posSubscriptionOnly: tcat.posSubscriptionOnly,
      subUpfrontPercentage: tcat.upfrontPercentage,
      subInterestRate: tcat.interestRate,
      subscriptionNote: tcat.subscriptionNote,
      intervalType: tcat.intervalType,
      numberOfInstallment: tcat.numberOfInstallment,
      subscriptionType: tcat.subscriptionType,
      registrationFee: tcat.registrationFee,
      // deliveryType: tcat.deliveryType,
      deliveryFrequency: tcat.deliveryFrequency,
      subShipList,
      catalog: this.props.catalog,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handlePosSubscription = async () => {
    this.setState((prevstate) => ({
      posSubscriptionOnly: !prevstate.posSubscriptionOnly,
    }));
  };

  handleAllowSubscription = async () => {
    this.setState((prevstate) => ({
      allowSubscription: !prevstate.allowSubscription,
    }));
  };

  onChange = async (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      subMsg: "",
      errFlag: true,
    });
  };

  onChangePercentage = async (e) => {
    let value = e.target.value;

    if (value < 100 && value >= 0) {
      if (this.countDecimals(value) > 2) {
        let tmp = parseFloat(value).toFixed(2);
        let val = parseFloat(tmp);
        this.setState({
          [e.target.name]: val,
        });
      } else {
        this.setState({
          [e.target.name]: value,
        });
      }
    }
  };

  onChangeAmount = async (e) => {
    let value = e.target.value;

    if (value >= 0) {
      if (this.countDecimals(value) > 2) {
        let tmp = parseFloat(value).toFixed(2);
        let val = parseFloat(tmp);
        this.setState({
          [e.target.name]: val,
        });
      } else {
        this.setState({
          [e.target.name]: value,
        });
      }
    }
  };

  onChangeNumber = async (e) => {
    let value = e.target.value;

    if (this.countDecimals(value) === 0 && parseFloat(value) >= 0) {
      this.setState({
        [e.target.name]: value,
        subMsg: "",
        errFlag: true,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleIntervalType = async (e) => {
    this.setState({
      intervalType: e.target.value,
    });
  };

  // handleDeliveryType = async (e) => {
  //   this.setState({
  //     deliveryType: e.target.value,
  //     subShipList: [],
  //     subMsg: "",
  //     errFlag: true,
  //   });
  // };

  handleSubscriptionType = async (e) => {
    this.setState({
      subscriptionType: e.target.value,
    });
  };

  handleDeliveryFrequency = async (e) => {
    this.setState({
      deliveryFrequency: e.target.value,
      subMsg: "",
    });
  };

  // handlePeriodicityType = async (e) => {
  //   this.setState({
  //     periodicityType: e.target.value,
  //   });
  // };

  returnToCatalogAdmin = () => {
    this.props.returnToCaller(this.state.catalog);
  };

  validateSave = () => {
    let msg = "";
    let state = "success";

    if (
      this.state.deliveryFrequency === "periodic" &&
      this.state.subShipList.length === 0
    ) {
      state = "error";
      msg = " Periodic delivery needs at least one delivery detail to save.";
    }

    if (
      this.state.allowSubscription &&
      this.state.subscriptionType === "fixedendtime" &&
      this.state.deliveryFrequency !== "periodic"
    ) {
      if (parseFloat(this.state.numberOfInstallment) < 2) {
        state = "error";
        msg = " Number of installment payment must be greater or equal to 2.";
      }
    }

    return { status: state, Msg: msg };
  };

  handleSubscriptionSave = async () => {
    let valid = this.validateSave();

    if (valid.status === "success") {
      let subscriptionType = this.state.subscriptionType;
      if (this.state.allowSubscription === "periodic")
        subscriptionType = "periodic";

      let allowPeriodicDelivery = this.state.allowPeriodicDelivery;
      if (this.state.deliveryFrequency === "periodic")
        allowPeriodicDelivery = true;

      let coopStoreId = 0;
      if (this.props.store) coopStoreId = this.props.store.coopStoreId;

      let input = {
        itemId: this.state.catalog.itemId,
        communityId: this.state.catalog.communityId,
        coopStoreId,
        baandaId: this.props.auth.user.baandaId,
        updtmode: "subscription",
        clientProgram: "SubscriptionMgmt.js",
        clientFunction: "handleSubscriptionSave",
        subscription: {
          allowSubscription: this.state.allowSubscription,
          upfrontPercentage: this.state.subUpfrontPercentage,
          interestRate: parseFloat(this.state.subInterestRate),
          posSubscriptionOnly: this.state.posSubscriptionOnly,
          subscriptionNote: this.state.subscriptionNote,
          intervalType: this.state.intervalType,
          numberOfInstallment: parseFloat(this.state.numberOfInstallment),
          subscriptionType,
          deliveryFrequency: this.state.deliveryFrequency,
          registrationFee: this.state.registrationFee,
          allowPeriodicDelivery,
          // deliveryType: this.state.deliveryType,
          priodicDeliveryDetails: this.state.subShipList,
        },
      };
      let url = baandaServer + updateCatalogItem;
      // console.log("url:", url, " input:", input);
      try {
        let subret = await axios.post(url, input);
        // console.log("subret:", subret);
        if (subret.data.status === "success") {
          this.setState({
            subMsg: "Successfully updated",
            errFlag: false,
            catalog: subret.data.Msg,
          });
        } else {
          this.setState({
            subMsg: subret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        console.log("Err:", err.message);
        this.setState({
          subMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        subMsg: valid.Msg,
        errFlag: true,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  addTheSubScheduleLine = () => {
    let isUnique = true;
    let complete = true;
    let msg = "";
    this.state.subShipList.forEach((obj) => {
      if (obj.friendlyName === this.state.friendlyName) {
        isUnique = false;
        msg = "Name must be unique identifiable by buyers; ";
      } else {
        if (this.state.friendlyName === "") {
          complete = false;
          msg += "Must have a unique name.";
        }
        if (parseFloat(this.state.scheduleQty) === 0) {
          complete = false;
          msg += "Quantity must be positive";
        }
      }
    });
    // console.log(
    //   "this.state.subShipList.length:",
    //   this.state.subShipList.length
    // );
    if (this.state.subShipList.length === 0) {
      // console.log("here 1...this.state.friendlyName:", this.state.friendlyName);
      if (this.state.friendlyName === "") {
        complete = false;
        msg += "Name must be unique, non-blank, to facilitate buyers; ";
      }
      if (parseFloat(this.state.scheduleQty) === 0) {
        complete = false;
        msg += "Quantity must be positive. ";
      }
    }
    // console.log("isUnique:", isUnique, " complete:", complete, " msg:", msg);

    if (
      parseFloat(this.state.pkLength) <= 0 ||
      parseFloat(this.state.pkWidth) <= 0 ||
      parseFloat(this.state.pkHeight) <= 0
    ) {
      complete = false;
      msg += "Must define the shipping/pickup pkg size & weight. ";
    }

    if (
      parseFloat(this.state.pkLbs) <= 0 &&
      parseFloat(this.state.pkOzs) <= 0
    ) {
      complete = false;
      msg += "Must provide weight. ";
    }

    if (isUnique && complete) {
      let size = {
        length: parseFloat(this.state.pkLength),
        width: parseFloat(this.state.pkWidth),
        height: parseFloat(this.state.pkHeight),
        weightLbs: parseFloat(this.state.pkLbs),
        weightOzs: parseFloat(this.state.pkOzs),
      };
      let schObj = {
        friendlyName: this.state.friendlyName,
        shipPrice: parseFloat(this.state.shipPrice),
        scheduleQty: parseFloat(this.state.scheduleQty),
        schDiscount: parseFloat(this.state.schDiscount),
        size,
      };
      let subList = [...this.state.subShipList];
      subList.push(schObj);
      this.setState({
        subShipList: subList,
        friendlyName: "",
        shipPrice: 0,
        scheduleQty: 0,
        schDiscount: 0,
        pkLength: 0,
        pkWidth: 0,
        pkHeight: 0,
        pkWeight: 0,
        pkLbs: 0,
        pkOzs: 0,
        subMsg: "",
        errFlag: false,
      });
    } else {
      this.setState({
        subMsg: msg,
        errFlag: true,
      });
    }
  };

  returnHandleLineDelete = (line) => {
    let newSubList = [];
    this.state.subShipList.forEach((obj) => {
      if (obj.friendlyName !== line.friendlyName) newSubList.push(obj);
    });

    this.setState({
      subShipList: newSubList,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.State:", this.state);
    console.log("SubscriptionMgmt...");

    let modalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let exitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToCatalogAdmin}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let saveButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleSubscriptionSave}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let buttonPanel = (
      <div className="subscription-button-row">
        {exitButton}
        {saveButton}
        {modalButton}
        <div
          className={
            this.state.errFlag
              ? "text-center subscription-msg-error"
              : "text-center subscription-msg"
          }
        >
          {this.state.subMsg}
        </div>
      </div>
    );

    let intervalTypePanel;
    intervalTypePanel = (
      <div className="subscription-type-row">
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Duration Type: &nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="fixedendtime"
                  checked={this.state.subscriptionType === "fixedendtime"}
                  onChange={this.handleSubscriptionType}
                />{" "}
                Installment
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="continuous"
                  checked={this.state.subscriptionType === "continuous"}
                  onChange={this.handleSubscriptionType}
                />{" "}
                Open (e.g., membership)
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let shippingMgmtPanel;
    if (
      this.state.catalog &&
      !this.state.catalog.pkgComposedFlag &&
      this.state.catalog.itemVariance.length === 1
    ) {
      shippingMgmtPanel = (
        <div className="subscription-type-row">
          <div className="row name-panel-row">
            <div className="col text-center radio-font-style">
              Delivery: &nbsp;&nbsp;
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="onetime"
                    checked={this.state.deliveryFrequency === "onetime"}
                    onChange={this.handleDeliveryFrequency}
                  />{" "}
                  One-time
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="periodic"
                    checked={this.state.deliveryFrequency === "periodic"}
                    onChange={this.handleDeliveryFrequency}
                  />{" "}
                  Periodic
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let subscriptionAllowPanel;
    subscriptionAllowPanel = (
      <div>
        <div className="cat-subscription-head">Subscription Specs</div>
        <div className="row sub-allow-row">
          <div className="col-md-6 text-center">
            <Checkbox
              checked={this.state.allowSubscription}
              onChange={this.handleAllowSubscription}
            />
            &nbsp;Allow Subscription.&nbsp;&nbsp;
          </div>
          <div className="col-md-6 text-center">
            {this.state.allowSubscription ? shippingMgmtPanel : null}
          </div>
        </div>
      </div>
    );

    let dimensionPanel;
    dimensionPanel = (
      <div className="cat-pkt-size-pos">
        <div className="text-center">
          <font size="2">
            <u>Package Size</u>
          </font>
        </div>
        <div className="row">
          <div className="col-2 text-center sub-schedule-lbl">
            Length{" "}
            <input
              name="pkLength"
              type="number"
              value={this.state.pkLength}
              onChange={this.onChangePercentage}
              className="cat-sub-pkt-size"
              step=".1"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
            &nbsp;<font size="1">Inch</font>
          </div>
          <div className="col-2 text-center sub-schedule-lbl">
            Width{" "}
            <input
              name="pkWidth"
              type="number"
              value={this.state.pkWidth}
              onChange={this.onChangePercentage}
              className="cat-sub-pkt-size"
              step=".1"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
            &nbsp;<font size="1">Inch</font>
          </div>
          <div className="col-2 text-center sub-schedule-lbl">
            Height{" "}
            <input
              name="pkHeight"
              type="number"
              value={this.state.pkHeight}
              onChange={this.onChangePercentage}
              className="cat-sub-pkt-size"
              step=".1"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
            &nbsp;<font size="1">Inch</font>
          </div>
          {/* pkLbs: 0,
      pkOzs: 0, */}
          <div className="col-6 text-center sub-schedule-lbl">
            Weight{" "}
            <input
              name="pkLbs"
              type="number"
              value={this.state.pkLbs}
              onChange={this.onChangePercentage}
              className="cat-sub-wt-size"
              step=".1"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
            &nbsp;<font size="1">Lbs</font>&nbsp;&nbsp;&nbsp;
            <input
              name="pkOzs"
              type="number"
              value={this.state.pkOzs}
              onChange={this.onChangePercentage}
              className="cat-sub-wt-size"
              step=".1"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
            &nbsp;<font size="1">Oz</font>
          </div>
        </div>
      </div>
    );

    let getDeliveryInfoPanel;
    if (this.state.deliveryFrequency === "periodic") {
      getDeliveryInfoPanel = (
        <div>
          <div className="text-center sub-schedule-intry-box">
            <div className="row sub-sch-row-1">
              <div className="col-6">
                <div className="row">
                  <div className="col-6 text-right sub-schedule-lbl">
                    Unique Name
                  </div>
                  <div className="col-6 text-left">
                    <input
                      name="friendlyName"
                      type="text"
                      value={this.state.friendlyName}
                      onChange={this.onChange}
                      size="18"
                      maxLength="18"
                      className="sub-sch-friendly-name"
                      placeholder="In 18 chars"
                    />
                  </div>
                </div>
              </div>

              <div className="col-6">
                <div className="row">
                  <div className="col-6 text-right sub-schedule-lbl">
                    Ship Price / box $
                  </div>
                  <div className="col-6 text-left">
                    <input
                      name="shipPrice"
                      type="number"
                      value={this.state.shipPrice}
                      onChange={this.onChangePercentage}
                      className="sub-sch-ship-price"
                      step=".01"
                      placeholder="0.00"
                      autoComplete="off"
                      spellCheck="false"
                    />
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
            <div className="row sub-sch-row-1">
              <div className="col-6">
                <div className="row">
                  <div className="col-6 text-right sub-schedule-lbl">Qty</div>
                  <div className="col-6 text-left">
                    <input
                      name="scheduleQty"
                      type="number"
                      value={this.state.scheduleQty}
                      onChange={this.onChangeNumber}
                      className="sub-sch-qty"
                      step=".01"
                      placeholder="0.0"
                      autoComplete="off"
                      spellCheck="false"
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="row">
                  <div className="col-6 text-right sub-schedule-lbl">
                    Discount
                  </div>
                  <div className="col-6 text-left">
                    <input
                      name="schDiscount"
                      type="number"
                      value={this.state.schDiscount}
                      onChange={this.onChangePercentage}
                      className="sub-sch-discount"
                      step=".01"
                      max={50}
                      placeholder="0"
                      autoComplete="off"
                      spellCheck="false"
                    />
                    &nbsp;%&nbsp;&nbsp;
                    <button
                      className="btn_reg_50"
                      type="button"
                      onClick={this.addTheSubScheduleLine}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {dimensionPanel}
          </div>
        </div>
      );
    }

    let scheduleDeliveryPanel;
    if (this.state.deliveryFrequency === "periodic") {
      let bool = true;
      scheduleDeliveryPanel = (
        <div>
          {getDeliveryInfoPanel}
          <div className="row sub-sch-list-row-pos">
            <div className="col-1 text-center sub-schedule-lbl">&nbsp;</div>
            <div className="col-3 text-center sub-schedule-lbl">
              Shipping Name
            </div>
            <div className="col-2 text-center sub-schedule-lbl">Qty</div>
            <div className="col-2 text-center sub-schedule-lbl">Ship</div>
            <div className="col-2 text-center sub-schedule-lbl">Disc %</div>
            <div className="col-2 text-center sub-schedule-lbl-size">
              L-W-H-W(Lb/Oz)
            </div>
          </div>
          {
            <div className="sub-sch-list-box">
              {this.state.subShipList.map((sch, i) => (
                <div key={i}>
                  <div
                    className={`${
                      bool ? "row-sch-ship-color-dr" : "row-sch-ship-color-lt"
                    }`}
                  >
                    <div className="row">
                      <div className="col-1 text-center">
                        <button
                          onClick={() => this.returnHandleLineDelete(sch)}
                          className="btn_exit_del"
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-trash" />
                        </button>
                      </div>
                      <div className="col-3 text-center">
                        {sch.friendlyName}
                      </div>
                      <div className="col-2 text-center">
                        {this.commaFormattedCurrency(
                          parseFloat(sch.scheduleQty)
                        )}
                      </div>
                      <div className="col-2 text-center">
                        {this.commaFormattedCurrency(parseFloat(sch.shipPrice))}
                      </div>
                      <div className="col-2 text-center">
                        {this.commaFormattedCurrency(
                          parseFloat(sch.schDiscount)
                        )}
                      </div>
                      {/* pkWeight: 0.0,
      pkLbs: 0,
      pkOzs: 0, */}
                      <div className="col-2 text-center">
                        {sch.size && sch.size.length ? sch.size.length : ""}-
                        {sch.size && sch.size.width ? sch.size.width : ""}-
                        {sch.size && sch.size.height ? sch.size.height : ""}-
                        {sch.size.weightLbs}/{sch.size.weightOzs}
                      </div>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              ))}
            </div>
          }
        </div>
      );
    }

    let upfront = "Upfront Pay-Amount";
    let fincharge = "Finance Rate";
    let noofinstallment = "Number of Payments";
    let fixedSubPanel;
    fixedSubPanel = (
      <div className="fixed-subpanel-row">
        <div className="row cat-sub-row">
          <div className="col-5 cat-sub-lbl text-right">{upfront}</div>
          <div className="col-7 text-left">
            <input
              name="subUpfrontPercentage"
              type="number"
              value={this.state.subUpfrontPercentage}
              onChange={this.onChangePercentage}
              className="upfront_pay_amt"
              step=".01"
              max={50}
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
            &nbsp;&nbsp;% of the total item-price.
          </div>
        </div>

        <div className="row cat-sub-row">
          <div className="col-5 cat-sub-lbl text-right">{fincharge}</div>
          <div className="col-7 text-left">
            <input
              name="subInterestRate"
              type="number"
              value={this.state.subInterestRate}
              onChange={this.onChangePercentage}
              className="upfront_pay_amt"
              step=".01"
              max={50}
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
            &nbsp;&nbsp;% inerest/yr.
          </div>
        </div>

        <div className="row cat-sub-row-installment">
          <div className="col-5 cat-sub-lbl text-right">{noofinstallment}</div>
          <div className="col-7 text-left">
            <input
              name="numberOfInstallment"
              type="number"
              value={this.state.numberOfInstallment}
              onChange={this.onChangeNumber}
              className="upfront_pay_amt"
              step="1"
              // max={5}
              // placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
            &nbsp;&nbsp;(to be paid in ...)
          </div>
        </div>
      </div>
    );

    let continuousSubPanel;
    if (this.state.subscriptionType === "continuous") {
      continuousSubPanel = (
        <div>
          <div className="text-center continuous-sub-panel">
            Catalog price is the subscription amount.
          </div>
          <div className="row sub-cont-registration">
            <div className="col-5 cat-sub-lbl text-right">Registration Fee</div>
            <div className="col-7 text-left">
              <input
                name="registrationFee"
                type="number"
                value={this.state.registrationFee}
                onChange={this.onChangeAmount}
                className="registration_pay_amt"
                step="0.01"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          </div>
        </div>
      );
    }

    let subscriptionPanel;
    subscriptionPanel = (
      <div>
        <div className="sub-note-row text-center">
          Note: Subscription at this point is monthly only
        </div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Interval Type: &nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="weekly"
                  checked={this.state.intervalType === "weekly"}
                  onChange={this.handleIntervalType}
                  disabled
                />{" "}
                Weekly
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="monthly"
                  checked={this.state.intervalType === "monthly"}
                  onChange={this.handleIntervalType}
                />{" "}
                Monthly
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="yearly"
                  checked={this.state.intervalType === "yearly"}
                  onChange={this.handleIntervalType}
                  disabled
                />{" "}
                Yearly
              </label>
            </div>
          </div>
        </div>
        <div className="row cat-note-row">
          <div className="col-2 cat-sub-lbl text-right">Direction</div>
          <div className="col-10 text-left">
            <textarea
              name="subscriptionNote"
              maxLength="200"
              placeholder="Short (200 chars) direction and non-payment affect."
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="cat_sub_note_textarea"
              onChange={this.onChange}
              value={this.state.subscriptionNote}
              // required
            />
          </div>
        </div>
      </div>
    );

    let subMgmtPanel;
    if (
      this.state.allowSubscription &&
      this.state.deliveryFrequency === "onetime"
    ) {
      subMgmtPanel = (
        <div>
          {intervalTypePanel}
          {this.state.subscriptionType === "fixedendtime"
            ? fixedSubPanel
            : continuousSubPanel}
          {subscriptionPanel}
        </div>
      );
    }
    if (
      this.state.allowSubscription &&
      this.state.deliveryFrequency === "periodic"
    ) {
      subMgmtPanel = (
        <div>
          {scheduleDeliveryPanel}
          {/* {repeatShipTypePanel} */}
        </div>
      );
    }

    if (!this.state.allowSubscription) {
      subMgmtPanel = (
        <div className="no-subscription-allowed">
          Click Save and Back to disallow subscription/installment payment.
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div className="catalog-show-subscription-box">
        {subscriptionAllowPanel}
        {subMgmtPanel}
        {buttonPanel}
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

SubscriptionMgmt.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SubscriptionMgmt));
