import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import moment from "moment";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import ACustomerInvoice from "./ACustomerInvoice";

import "./CustomerPayPending.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCustPendingInvoices = "/routes/customer/getCustPendingInvoices?";

const readMeCode = "1110411111";
 
class CustomerPayPending extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      invoices: [],
      errMsg: "",
      errFlag: false,

      payPendingFlag: true,
      ACustomerInvoiceFlag: false,

      theInvoice: null,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getPendingInvoices();

    await this.setState({
      deviceSize,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getPendingInvoices = async () => {
    let coopStoreId = 0;
    if ( this.props.store ) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" + coopStoreId +
      "&baandaId=" +
      this.props.auth.user.baandaId;
    let url = baandaServer + getCustPendingInvoices + params;
    // console.log("url:", url);
    try {
      let invret = await axios.get(url);
      if (invret.data.status === "success") {
        this.setState({
          invoices: invret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: invret.data.Msg,
          errFlag: false,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: false,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleInvoiceToPay = async (inv) => {
    this.setState({
      theInvoice: inv,
      payPendingFlag: false,
      ACustomerInvoiceFlag: true,
    });
  };

  returnFromAnInvoice = async () => {
    await this.getPendingInvoices();
    this.setState({
      theInvoice: null,
      payPendingFlag: true,
      ACustomerInvoiceFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('CustomerPayPending...');

    let buttonModalInvRet;
    buttonModalInvRet = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let tableHeader, Open;
    if (this.state.deviceSize === "small") {
      tableHeader = "Id - Date | Pay-Amt | Pay-By | State";
      Open = "O";
    } else {
      tableHeader = "InvoiceId - Create Date | Pay-Amt | Pay By | State";
      Open = "Open";
    }

    let pendingInvoicesPanel;
    if (this.state.invoices.length > 0) {
      let bool = true;
      pendingInvoicesPanel = (
        <div>
          <div className="row">
            <div className="col-10 text-left inv-pend-header">
              {tableHeader}
            </div>
            <div className="col-2 text-center inv-pend-header">Ops</div>
          </div>
          <div className="customer-invoice-pending-box-yy">
            {this.state.invoices.map((post, i) => (
              <div key={i}>
                <div className={`${bool ? "cust-pend-color-dr" : "cust-pend-color-lt"}`}>
                  <div className="row">
                    <div className="col-10 text-left customer-inv-pend-lbl">
                      {post.invoiceId}&nbsp;-&nbsp;
                      {post.created_at.substring(0, 10)}
                      &nbsp;|&nbsp;$
                      {this.commaFormattedCurrency(
                        post.paySchedulePolicy.nextPayAmount
                      )}{" "}
                      |&nbsp;
                      {post.paySchedulePolicy.nextPayBy
                        ? post.paySchedulePolicy.nextPayBy.substring(0, 10)
                        : "Miss pay-by"}
                      &nbsp;|&nbsp;
                      {moment().isAfter(
                        moment(
                          post.paySchedulePolicy.nextPayBy.substring(0, 10)
                        )
                      ) ? (
                        <font color="red">Overdue</font>
                      ) : null}
                    </div>

                    <div className="col-2 text-center customer-inv-pend-open">
                      <button
                        onClick={() => this.handleInvoiceToPay(post)}
                        className="btn_reg_50_open"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        {Open}
                      </button>
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      pendingInvoicesPanel = <div>No pending invoice </div>;
    }

    let outputPanel;
    if (this.state.payPendingFlag) {
      outputPanel = (
        <div>
          <div className="text-center cust-pend-header">
            Pending Payments {buttonModalInvRet}
            {pendingInvoicesPanel}
          </div>
        </div>
      );
    }

    if (this.state.ACustomerInvoiceFlag) {
      let store = null;
      if ( this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <ACustomerInvoice
            communityId={this.props.communityId}
            community={this.props.community}
            theInvoice={this.state.theInvoice}
            store={store}
            returnToCaller={this.returnFromAnInvoice}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CustomerPayPending.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerPayPending)
);
