import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Budget from "./budget/BudgetMain";
import Expense from "./expenses/Expense";
import ReceivePaymentsInv from "./receivable/ReceiveMain";
import SellCredits from "./creditsell/SellCredits";
import LiabilityMain from "./liability/LiabilityMain";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./FinanceMain.css";

const receivePaymentlList = ["recvpayment"];
const expenseList = ["expense"];
const budgetList = ["budgeting"];
const buyCreditsList = ["buycredits"];

const readMeCode = "1111200000";

class FinanceMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",

      paymentActiveFlag: false,
      receiveActiveFlag: true,
      expenseActiveFlag: false,
      budgetActiveFlag: false,
      invoiceActiveFlag: false,
      payableActiveFlag: false,
      buyActiveFlag: false,

      btnReceiveShowFlag: false,
      btnExpenseShowFlag: false,
      btnBudgetShowFlag: false,
      btnBuyCreditsFlag: false,

      deviceSize: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setAuthorizedButtons();

    await this.setState({
      deviceSize,
    });
  };

  openAlertModal = async () => {
 
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  setAuthorizedButtons = async () => {
    // console.log("### this.state.authorizedFuncs: ", this.state.authorizedFuncs);

    let btnReceiveShowFlag = false;
    let btnExpenseShowFlag = false;
    let btnBudgetShowFlag = false;
    let btnBuyCreditsFlag = false;

    let receiveActiveFlag = false;
    // let payableActiveFlag = false;
    let expenseActiveFlag = false;
    let budgetActiveFlag = false;
    let buyActiveFlag = false;

    receivePaymentlList.forEach((obj1) => {
      if (this.props.authorizedFuncs.includes(obj1)) btnReceiveShowFlag = true;
    });

    expenseList.forEach((obj2) => {
      if (this.props.authorizedFuncs.includes(obj2)) btnExpenseShowFlag = true;
    });

    budgetList.forEach((obj3) => {
      if (this.props.authorizedFuncs.includes(obj3)) btnBudgetShowFlag = true;
    });

    buyCreditsList.forEach((obj3) => {
      if (this.props.authorizedFuncs.includes(obj3)) btnBuyCreditsFlag = true;
    });

    if (btnReceiveShowFlag) receiveActiveFlag = true;
    else if (btnExpenseShowFlag) expenseActiveFlag = true;
    else if (btnBudgetShowFlag) budgetActiveFlag = true;
    else if (btnBuyCreditsFlag) buyActiveFlag = true;

    // if ( this.props.role === 'storeowner') btnBudgetShowFlag = false;

    this.setState({
      btnReceiveShowFlag,
      btnExpenseShowFlag,
      btnBudgetShowFlag,
      btnBuyCreditsFlag,

      receiveActiveFlag,
      expenseActiveFlag,
      budgetActiveFlag,
      buyActiveFlag,
    });
  };

  handleSelect = async (choice) => {
    this.setState({
      invoiceActiveFlag: false,
      paymentActiveFlag: false,
      receiveActiveFlag: false,
      payableActiveFlag: false,
      expenseActiveFlag: false,
      budgetActiveFlag: false,
      buyActiveFlag: false,
    });
 
    if (choice === "receive") {
      await this.setState({
        receiveActiveFlag: true,
      });
    }
    if (choice === "expense") {
      await this.setState({
        expenseActiveFlag: true,
      });
    }
    if (choice === "budget") {
      await this.setState({
        budgetActiveFlag: true,
      });
    }
    // To be used when we allow manual creation of invoice
    if (choice === "payable") {
      await this.setState({
        payableActiveFlag: true
      });
    }
    if (choice === "buycredit") {
      await this.setState({
        buyActiveFlag: true,
      });
    }
  };

  render() {
    // console.log("financeMain this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('FinanceMain...');

    let receivebtn, expensebtn, payable;
    if (this.state.deviceSize === "small") {
      receivebtn = "Recv";
      expensebtn = "Exp";
      payable = "Pble";
    } else {
      receivebtn = "Recvable";
      expensebtn = "Expense";
      payable = "Payable";
    }

    let buttonPanel = (
      <div>
        <div className="row">
          <div className="col-2 header_finance_text text-center">Finance</div>
          <div className="col-10 header_finance_buttons">
            {this.state.btnReceiveShowFlag ? (
              <button
                className={`${
                  !this.state.receiveActiveFlag
                    ? "btn_reg_70"
                    : "btn_reg_70_active"
                }`}
                type="button"
                onClick={() => this.handleSelect("receive")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {receivebtn}
              </button>
            ) : null}
            {this.state.btnExpenseShowFlag ? (
              <button
                className={`${
                  !this.state.payableActiveFlag
                    ? "btn_reg_70"
                    : "btn_reg_70_active"
                }`}
                type="button"
                onClick={() => this.handleSelect("payable")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {payable}
              </button>
            ) : null}
            {this.state.btnExpenseShowFlag ? (
              <button
                className={`${
                  !this.state.expenseActiveFlag
                    ? "btn_reg_70"
                    : "btn_reg_70_active"
                }`}
                type="button"
                onClick={() => this.handleSelect("expense")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {expensebtn}
              </button>
            ) : null}
            {this.state.btnBudgetShowFlag ? (
              <button
                className={`${
                  !this.state.budgetActiveFlag
                    ? "btn_reg_70"
                    : "btn_reg_70_active"
                }`}
                type="button"
                onClick={() => this.handleSelect("budget")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Budget
              </button>
            ) : null}
            {this.state.btnBuyCreditsFlag ? (
              <button
                className={`${
                  !this.state.buyActiveFlag ? "btn_reg_70" : "btn_reg_70_active"
                }`}
                type="button"
                onClick={() => this.handleSelect("buycredit")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Credits
              </button>
            ) : null}
            &nbsp;
            <button
              className="btn_info_main"
              type="button"
              onClick={this.openAlertModal}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-info-circle" />
            </button>
          </div>
        </div>
      </div>
    );

    let outputPanel = buttonPanel;
    let store = null;
    if (this.props.store) store = this.props.store;

    let activePanel;
    if (this.state.buyActiveFlag) {
      activePanel = (
        <div>
          <SellCredits
            communityId={this.props.communityId}
            baandaId={this.props.auth.user.baandaId}
            community={this.props.community}
            role={this.props.role}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    if (this.state.receiveActiveFlag) {
      activePanel = (
        <div>
          <ReceivePaymentsInv
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.props.community}
            goToDashboard={this.props.goToDashboard}
            role={this.props.role}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    if (this.state.payableActiveFlag) {
      activePanel = (
        <div>
          <LiabilityMain
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.props.community}
            goToDashboard={this.props.goToDashboard}
            role={this.props.role}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    if (this.state.expenseActiveFlag) {
      activePanel = (
        <div>
          <Expense
            communityId={this.props.communityId}
            community={this.props.community}
            role={this.props.role}
            authorizedFuncs={this.props.authorizedFuncs}
            store={store}
          />
        </div>
      );
    }

    if (this.state.budgetActiveFlag) {
      activePanel = (
        <Budget
          communityId={this.props.communityId}
          community={this.props.community}
          goToDashboard={this.props.goToDashboard}
          role={this.props.role}
          authorizedFuncs={this.props.authorizedFuncs}
          store={this.props.store}
        />
      );
    }

    outputPanel = (
      <div>
        {buttonPanel}
        {activePanel}
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

FinanceMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    // console.log(
    //   "modalProps:" + JSON.stringify(modalProps) + "  |modalType:" + modalType
    // );
    dispatch(showModal({ modalProps, modalType }));
  },
  // setQAInitDone: () => dispatch(setQAInitDone(userData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FinanceMain));
