import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import axios from "axios";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./UpdateCompany.css";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1151120000";

class UpdateCompany extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company: {},

      cacity: "",
      caline1: "",
      caline2: "",
      country: "US",
      castate: "",
      capostal_code: "",
      caname: "",
      caphone: "",
      cataxid: "",

      companySkip: false,

      deviceSize: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.setState({
      deviceSize,
    });
    // console.log("componentDidMount company:", this.props.companyData);
    await this.loadDataInStateCompany();
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  loadDataInStateCompany = async () => {
    console.log("Company this.props:", this.props);
    let cd = this.props.companyData;
    let ccity = "";
    if (cd && cd.address && cd.address.city) ccity = cd.address.city;
    let cline1 = "";
    if (cd && cd.address && cd.address.line1) cline1 = cd.address.line1;
    let cline2 = "";
    if (cd && cd.address && cd.address.line2) cline2 = cd.address.line2;
    let cstate = "";
    if (cd && cd.address && cd.address.state) cstate = cd.address.state;
    let czip = "";
    if (cd && cd.address && cd.address.postal_code) {
      czip = cd.address.postal_code;
    }
    let cname = "";
    if (cd && cd.name) cname = cd.name;
    let cphone = "";
    if (cd && cd.phone) cphone = cd.phone;
    let ctaxid = "";
    if (cd && typeof cd.tax_id === "string") {
      ctaxid = cd.tax_id;
    } else ctaxid = "";

    await this.setState({
      companySkip: this.props.companySkip,
      cacity: ccity,
      caline1: cline1,
      caline2: cline2,
      country: "US",
      castate: cstate,
      capostal_code: czip,
      caname: cname,
      caphone: cphone,
      cataxid: ctaxid,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  loadCompanyData = async () => {
    let company = {
      address: {
        city: this.state.cacity,
        country: "US",
        line1: this.state.caline1,
        line2: this.state.caline2,
        state: this.state.castate,
        postal_code: this.state.capostal_code,
      },
      name: this.state.caname,
      phone: this.state.caphone,
      tax_id: this.state.cataxid,
      owners_provided: true,
    };
    return company;
  };

  handleBackCompany = async () => {
    let company = await this.loadCompanyData();
    // console.log("handleBackCompany company:", company);
    this.props.handleBack(company, this.state.companySkip);
  };

  handleNextCompany = async () => {
    let company = await this.loadCompanyData();
    // console.log("handleNextCompany company:", company);
    this.props.handleNext(company, this.state.companySkip);
  };

  handleSkip = async () => {
    await this.setState((prevstate) => ({
      companySkip: !prevstate.companySkip,
      confirmErrorMsg: "On Finish, invoice will be in your email.",
    }));
    // console.log("this.state:", this.state);
  };

  render() {
    // console.log("company this.state:", this.state);
    // console.log("company this.props:", this.props);
    console.log('UpdateCompany....');

    let commButtonCompany = (
      <div>
        <button
          onClick={this.handleBackCompany}
          className="btn_reg_60"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Back
        </button>
        &nbsp;
        <button
          onClick={this.handleNextCompany}
          className="btn_reg_60"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Next
        </button>
        &nbsp;
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
        &nbsp;
      </div>
    );

    let headerPanel = (
      <div className="row">
        <div className="col-5 header_comp_text">Company Profile</div>
        <div className="col-7 ">{commButtonCompany}</div>
      </div>
    );

    let bottomMsgPanel = (
      <div className="row">
        <div className="col message_text">
          &nbsp;<font color="red">*</font>&nbsp; are mandaroty fields. Please
          click 'Next', or Back, buttons to continue.
        </div>
      </div>
    );

    let cphone, cname, cline1, cline2, czip, cskip;
    if (this.state.deviceSize === "small") {
      cname = "Name";
      cphone = "Comp Ph#";
      cline1 = "St. Addr.-1";
      cline2 = "Apt#";
      czip = "Zip";
      cskip = "Skip company setup now";
    } else {
      cname = "Company Name";
      cphone = "Company Phone Number";
      cline1 = "Street Address";
      cline2 = "Apartment# / Suit#";
      czip = "Postal Code (ZIP)";
      cskip = "Skip company setup now";
    }
    let companyProfile;
    companyProfile = (
      <div>
        <div className="input-up-height" />
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;{cname}{" "}
          </div>
          <div className="col-8 comp_input text-left">
            <input
              name="caname"
              type="text"
              value={this.state.caname}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_companyname"
              placeholder="Company name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;{cphone}
          </div>
          <div className="col-8 comp_input text-left">
            <PhoneInput
              placeholder="Company phone#"
              value={this.state.caphone}
              onChange={(caphone) => this.setState({ caphone })}
              country="US"
              defaultCountry={"US"}
              countries={["US", "CA", "IN"]}
              className="input_caphone"
            />
            {/* &nbsp;<font color="red">*</font> */}
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;Tax Id
          </div>
          <div className="col-8 comp_input text-left">
            <input
              name="cataxid"
              type="text"
              value={this.state.cataxid}
              onChange={this.onChange}
              size="10"
              maxLength="10"
              className="input_companyname"
              placeholder="Tax ID / EIN"
            />
          </div>
        </div>
        <div className="text-center comp_addr_header">Company Address</div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;{cline1}
          </div>
          <div className="col-8 comp_input text-left">
            <input
              name="caline1"
              type="text"
              value={this.state.caline1}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_companyname"
              placeholder="Street Address"
            />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">{cline2}</div>
          <div className="col-8 comp_input text-left">
            <input
              name="caline2"
              type="text"
              value={this.state.caline2}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_companyname"
              placeholder="Apt"
            />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;City
          </div>
          <div className="col-8 comp_input text-left">
            <input
              name="cacity"
              type="text"
              value={this.state.cacity}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_companyname"
              placeholder="City"
            />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;State
          </div>
          <div className="col-8 comp_input text-left">
            <input
              name="castate"
              type="text"
              value={this.state.castate}
              onChange={this.onChange}
              size="2"
              maxLength="2"
              className="input_compstate"
              placeholder="State"
            />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-4 comp_label text-right">
            <font color="red">*</font>&nbsp;{czip}
          </div>
          <div className="col-8 comp_input text-left">
            <input
              name="capostal_code"
              type="text"
              value={this.state.capostal_code}
              onChange={this.onChange}
              size="10"
              maxLength="10"
              className="input_comppostal"
              placeholder="postal_code"
            />{" "}
          </div>
        </div>
        <div className="row">
          <div className="col-8 text-center biz_updt_confirm_company">
            <Checkbox
              checked={this.state.companySkip}
              onChange={this.handleSkip}
            />
            &nbsp;&nbsp;{cskip}
          </div>
          <div className="col-4">&nbsp;</div>
        </div>
        {bottomMsgPanel}
      </div>
    );

    let outputPanel;

    outputPanel = (
      <div>
        {headerPanel}
        <div className="company_input_box fixedsize_companysetup">
          {companyProfile}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

UpdateCompany.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpdateCompany));
