import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../../modal/components/ModalContainer";
import {
  showModal,
  hideModal,
} from "../../../../../../../actions/modalActions";
import "../../../../../../../modal/css/localModal.css";
import "../../../../../../../modal/css/template.css";

import "./ManageCreditMemo.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getInvoiceById = "/routes/finance/getInvoiceById?";
const creditMemoUpdt = "/routes/finance/creditMemoUpdt";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1111240000";

class ManageCreditMemo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceId: 0,
      invoice: null,
      cmAmt: 0,
      cmComment: "",
      cmConfirm: false,
    };
  }

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChangeId = async (e) => {
    let value = e.target.value;
    // await this.setState({ [e.target.name]: e.target.value });
    // console.log("value:", value);
    // console.log("this.countDecimals(value):", this.countDecimals(value));
    if (this.countDecimals(value) > 0) {
      let tmp = parseFloat(value).toFixed(0);
      let val = parseFloat(tmp);
      await this.setState({
        invoiceId: val,
      });
    } else {
      this.setState({
        invoiceId: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  searchInvId = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&invoiceId=" +
      this.state.invoiceId;
    let url = baandaServer + getInvoiceById + params;
    try {
      let invret = await axios.get(url);
      if (invret.data.status === "success") {
        this.setState({
          invoice: invret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: invret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  returnFromMCM = () => {
    this.props.returnToCaller();
  };

  onChangecmAmt = async (e) => {
    let value = e.target.value;
    let x =
      parseFloat(this.state.invoice.financeBreakdown.totalInvoiceAmount) -
      parseFloat(this.state.invoice.financeBreakdown.amountPaid);

    if (value <= x) {
      if (this.countDecimals(value) > 2) {
        let tmp = parseFloat(value).toFixed(2);
        let val = parseFloat(tmp);
        this.setState({
          cmAmt: val,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          cmAmt: parseFloat(value),
          errMsg: "",
          errFlag: false,
        });
      }
    } else {
      this.setState({
        errMsg: "CR Amount cannot exceed amount left to pay.",
        errFlag: true,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSkip = async () => {
    this.setState((prevstate) => ({
      cmConfirm: !prevstate.cmConfirm,
    }));
  };

  createInputForDB = () => {
    let input = {
      communityId: this.state.invoice.communityId,
      coopStoreId: this.state.invoice.coopStoreId,
      invoiceId: this.state.invoice.invoiceId,
      cmAmt: this.state.cmAmt,
      cmComment: this.state.cmComment,
      financeBreakdown: this.state.invoice.financeBreakdown,
      finMMDD: this.props.community.finMMDD,
      finYYYY: this.props.community.finYYYY,
      updatedByBaandaId: this.props.auth.user.baandaId,
      clientProgram: "ManageCreditMemo.js",
      clientFunction: "handlePost",
    };

    return input;
  };

  handlePost = async () => {
    let input = this.createInputForDB();
    let url = baandaServer + creditMemoUpdt;
    try {
      let crret = await axios.post(url, input);
      if (crret.data.status === "success") {
        this.setState({
          errMsg: "Successfully Credited invoice id=" + this.state.invoiceId,
          errFlag: false,
          invoiceId: 0,
          invoice: null,
          cmAmt: 0,
          cmComment: "",
          cmConfirm: false,
        });
      } else {
        this.setState({
          errMsg: crret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ManageCreditMemo...');

    let exitFromMCM = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnFromMCM}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let helpModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let searchButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.searchInvId}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Search
      </button>
    );

    let postCmButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handlePost}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Post
      </button>
    );

    let invoiceIdPanel;
    invoiceIdPanel = (
      <div className="row credit-memo-inv-id-pos">
        <div className="col-3 text-right credit-memo-lbl">Invoice Id</div>
        <div className="col-9 text-left">
          <input
            name="invoiceId"
            type="number"
            value={this.state.invoiceId}
            onChange={this.onChangeId}
            className="inv-id-redit-memo"
            step="1"
            placeholder="0"
            autoComplete="off"
            spellCheck="false"
          />
          &nbsp;{searchButton}
        </div>
      </div>
    );

    let invoiceInfoPanel;

    if (this.state.invoice) {
      let fb = this.state.invoice.financeBreakdown;
      let paidAmt = 0;
      if (isNaN(this.state.cmAmt)) paidAmt = 0;
      else paidAmt = parseFloat(this.state.cmAmt);
      invoiceInfoPanel = (
        <div>
          <div className="row">
            <div className="col-3 text-right credit-memo-lbl">Customer</div>
            <div className="col-9 text-left credit-memo-lbl">
              {this.state.invoice.customerName}&nbsp;(
              {this.state.invoice.customerEmail})
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right credit-memo-lbl">Invoice Amt</div>
            <div className="col-9 text-left credit-memo-lbl">
              {this.commaFormattedCurrency(fb.totalInvoiceAmount)}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right credit-memo-lbl">Paid Amt</div>
            <div className="col-9 text-left credit-memo-lbl">
              {this.commaFormattedCurrency(fb.amountPaid)}
            </div>
          </div>
          <div className="row cr-amt-row-pos">
            <div className="col-3 text-right credit-memo-lbl">Credit Amt</div>
            <div className="col-9 text-left credit-memo-lbl">
              <input
                name="cmAmt"
                type="number"
                value={this.state.cmAmt}
                onChange={this.onChangecmAmt}
                className="cr-memo-amt"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />{" "}
              &nbsp;Left:{" "}
              {this.commaFormattedCurrency(
                fb.totalInvoiceAmount -
                  (fb.amountPaid + fb.writeOffAmt + paidAmt)
              )}
            </div>
          </div>
          <div className="row cr-comment-row-pos">
            <div className="col-3 text-right credit-memo-lbl">Comment</div>
            <div className="col-9 text-left credit-memo-lbl">
              <textarea
                name="cmComment"
                maxLength="o000"
                placeholder="Describe your community."
                rows="3"
                wrap="hard"
                spellCheck="true"
                className="cr-comment-txt"
                onChange={this.onChange}
                value={this.state.cmComment}
                required
              />
            </div>
          </div>
          <div className="text-center cm-comment-count-pos">
            Max Char: 2000. Current: {this.state.cmComment.length}
          </div>
          {this.state.cmAmt > 0 && this.state.cmComment.length > 0 ? (
            <div className="row cm-post-pos">
              <div className="col-6 text-right">
                <Checkbox
                  checked={this.state.cmConfirm}
                  onChange={this.handleSkip}
                />
                &nbsp;<mark>Confirm. Posting is final.</mark>
              </div>
              {this.state.cmConfirm ? postCmButton : null}
            </div>
          ) : null}
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div className="manage-cm-box">
        <div className="fin-rec-cm-header">
          Provide Credit Memo &nbsp;&nbsp;{exitFromMCM} {helpModalButton}
          {invoiceIdPanel}
          {invoiceInfoPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center credit-memo-msg-err"
                : "text-center credit-memo-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ManageCreditMemo.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageCreditMemo)
);
