import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

// import CatalogItemDetails from "./CatalogItemDetails";
import CatalogServiceDetails from "./services/CatalogServiceDetails";
import CatalogGoodsDetails from "./goods/CatalogGoodsDetails";

import CartShow from "./CartShow";

import CatalogItemFilter from "./CatalogItemFilter";
import CommunityReturnPolicy from "./returns/ReturnPolicy";

import "./ViewPagiCatalog.css";
import noImage from "../image/blackback2.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const searchItemToEdit = "/routes/dashboard/searchItemToEdit?";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const searchItemsInStore = "/routes/coop/searchItemsInStore?";

const readMeCode = "1112010000";

class ViewPagiCatalog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showpagi: true,

      items: [],
      noOfDoc: 0,

      searchItemName: "",

      filterData: {
        onEntry: true,
        itemType: "goods",
        isPublished: true,
      },
      community: null,
 
      showCatalogOfGoods: false,
      showCatalogOfService: false,
      sendCatalogItemDetails: {},

      deviceSize: "",
      itemType: "goods",

      showFilterFlag: false,
      showPaginationPanelFlag: false,
      showReturnFlag: false,

      cartFlag: false,

      errMsg: '',
      errFlag: false,
      comingFrom: 'standard'
    };
  }

  componentDidMount = async () => {
 
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.setState({
      deviceSize,
      itemType: this.props.itemType,
    });

    let filter = null;
    let comingFrom = 'standard';
    if ( this.props.coopItemFilter ) {
      filter = this.props.coopItemFilter;
      comingFrom = 'coop';
    } else filter = this.state.filterData
    await this.getItemsAfterFilter(filter);
  
    await this.getCommunitiesInfo();
    await this.setState({
      comingFrom,
      filterData: filter
    });
  };

  getCommunitiesInfo = async () => {
    let parms = "communityId=" + this.props.communityId;
    let url = baandaServer + getCommunityInfo + parms;
    try {
      let retData = await axios.get(url);
      if (retData.data.status === "success") {
        await this.setState({
          community: retData.data.joiningProcess,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  // This is to show the info panel
  openAlertModal = () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getItemsAfterFilter = async (data) => {
    // console.log("getItemsAfterFilter----- data:", data);

    let coopStoreId = 0;
    if (this.props.store && this.props.store.coopStoreId) coopStoreId = this.props.store.coopStoreId;

    let params;
    if (data.onEntry) {
      params =
        "communityId=" +
        this.props.communityId +
        "&coopStoreId=" +
        coopStoreId +
        "&itemName=" +
        this.state.searchItemName +
        "&isPublished=" +
        data.isPublished;
    } else {
      if (data.filterType === "all") {
        params =
          "communityId=" +
          this.props.communityId +
          "&coopStoreId=" +
          coopStoreId +
          "&filterType=" +
          data.filterType +
          "&itemType=" +
          data.itemType +
          "&isPublished=" +
          data.isPublished;
        // console.log("here 111 all params:", params);
      }
      if (data.filterType === "category") {
        params =
          "communityId=" +
          this.props.communityId +
          "&coopStoreId=" +
          coopStoreId +
          "&categorySelected=" +
          data.category +
          "&filterType=" +
          data.filterType +
          "&itemType=" +
          data.itemType +
          "&isPublished=" +
          data.isPublished;
        // console.log("here 222 all params:", params);
      }
      if (data.filterType === "name") {
        params =
          "communityId=" +
          this.props.communityId +
          "&coopStoreId=" +
          coopStoreId +
          "&fileterByName=" +
          data.searchItemName +
          "&filterType=" +
          data.filterType +
          "&itemType=" +
          data.itemType +
          "&isPublished=" +
          data.isPublished;
      }
      if ( data.filterType === 'coopItemsText') {
        params = "communityId=" +
        this.props.communityId + "&searchText=" + data.searchText;
      }
    }
    // console.log('data.isPublished:', data.isPublished)
    // console.log("xxx params:", params);

    let url;
    if ( data.filterType === 'coopItemsText') {
      url = baandaServer +  searchItemsInStore + params;
    } else url = baandaServer + searchItemToEdit + params;
    // console.log("---- url:", url);
    try {
      let ret = await axios.get(url);
      // console.log(">>>>> ret:", ret);
      if (ret.data.status === "error") {
        throw new Error(`No items found with this condition`);
      } else {
        this.setState({
          items: ret.data.Msg.items,
          noOfDoc: ret.data.Msg.count
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true
      })
    }
  };

  handleFilter = async () => {
    await this.setState({
      showFilterFlag: true,
      showpagi: false,
      showReturnFlag: false,
    });
  };

  handleReturn = async () => {
    if ( this.props.coopItemFilter) {
      this.setState({
        showFilterFlag: false,
        showpagi: false,
        showReturnFlag: false,
      });
      this.props.returnToCaller();
    } else {
      this.setState({
        showFilterFlag: false,
        showpagi: false,
        showReturnFlag: true,
      });
    }
  };

  returnFromReturn = async () => {
    await this.setState({
      showFilterFlag: false,
      showpagi: true,
      showReturnFlag: false,
    });
  };

  returnAfterPagiFilter = async (data) => {
    console.log("returnAfterPagiFilter: data:", data);
    await this.getItemsAfterFilter(data);
    await this.setState({
      showFilterFlag: false,
      showpagi: true,
    });
  };

  handleItem = async (item) => {
 
    // console.log("invtry item:", item);

    let sendCatalogItemDetails = {
      baandaId: this.props.cartOf.baandaId,
      anItem: item,
      customerName: this.props.auth.user.name,
      customerEmail: this.props.auth.user.email,
      communityId: this.props.communityId,
      role: this.props.role,
    };

    let showCatalogOfGoods = false;
    let showCatalogOfService = false;
    if (item.itemType === "goods") showCatalogOfGoods = true;
    if (item.itemType === "service") showCatalogOfService = true;

    await this.setState({
      showCatalogOfGoods,
      showCatalogOfService,
      showpagi: false,
      sendCatalogItemDetails: sendCatalogItemDetails,
    });
  };

  returnFromDetails = async (filterCaption) => {
    // console.log("I am back from filterCaption:", filterCaption);
    // console.log('this.state.filterData:', this.state.filterData);
    await this.getItemsAfterFilter(this.state.filterData);
    this.setState({
      showCatalogOfGoods: false,
      showCatalogOfService: false,
      showpagi: true,
      cartFlag: false,
    });
  };

  handleCart = async () => {
    this.setState({
      cartFlag: true,
      showpagi: false,
      showCatalogOfGoods: false,
      showCatalogOfService: false,
      showPaginationPanelFlag: false,
    });
  };

  returnToMarket = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ViewPagiCatalog...');

    let purposHeader, colhead; // sumtot;
    if (this.state.deviceSize === "small") {
      purposHeader = "Catalog";
      colhead = "Item Name ";
      // sumtot = "Items";
    } else {
      purposHeader = "Catalog";
      colhead = "Item Name -- ItemId";
      // sumtot = "No. of Items";
    }

    let cartOfPanel = (
      <div className="text-center cart-of-header">
        Hello&nbsp;{this.props.cartOf.name}&nbsp;({this.props.cartOf.email})
      </div>
    );

    let retbutton, search;
    if (this.state.deviceSize === "small") {
      retbutton = "Plcy";
      search = "Srch";
    } else {
      retbutton = "Policy";
      search = "Search";
    }

    let buttonPanel = (
      <div>
        <button
          className="btn_reg_70"
          type="button"
          onClick={this.handleCart}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          { this.state.deviceSize === 'big' ? "Cart " : null }
          <i className="fas fa-shopping-cart" />
        </button>
        <button
          className="btn_reg_70"
          onClick={this.handleFilter}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          {this.state.deviceSize === 'big' ? search : <i className="fas fa-search" />}
        </button>
        <button
          className="btn_reg_70"
          onClick={this.handleReturn}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          {this.state.deviceSize === 'big' ? retbutton : <i className="fas fa-gavel" />}
        </button>
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
        {this.props.caller === "buyer" ? (
          <button
            className="btn_back_main"
            type="button"
            onClick={this.returnToMarket}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fas fa-step-backward" />
          </button>
        ) : null}
        &nbsp;&nbsp;
        {purposHeader}
      </div>
    );

    let summaryPanel;
    let total = 0;
    let totalServices = 0;
    let totalGoods = 0;
    let totalDA = 0;
    this.state.items.forEach((obj) => {
      total++;
      if (obj.itemType.toLowerCase() === "service") totalServices++;
      if (obj.itemType.toLowerCase() === "goods") totalGoods++;
      if (obj.itemType.toLowerCase() === "digitalcontent") totalDA++;
    });

    summaryPanel = (
      <div className="sum-placement-x">
        Total Items&nbsp;{total}{" "}
        <span className="boxes">{totalServices}&nbsp;Services</span>&nbsp;
        <span className="boxes">{totalGoods}&nbsp;Goods</span>&nbsp;
        <span className="boxes">{totalDA}&nbsp;DA</span>&nbsp;
      </div>
    );

    let bool = true;
    let itemsPanel;

    if (this.state.showpagi) {
      itemsPanel = (
        <div>
          <div>{cartOfPanel}</div>
          <div className="row show-pickups-header">
            <div className="col-md-6 text-center header-text-catalog">
              {buttonPanel}
            </div>
        
            <div className="col-md-6 text-center">{summaryPanel}</div>
          </div>
          <div className="row">
            <div className="col-9 text-left cat-item-brows-heade">
              {colhead}
            </div>
            <div className="col-3 text-center cat-item-brows-heade">Ops</div>
          </div>
          <div className="fixedsize_catalogview">
            {this.state.items.map((post, i) => {
              let pic = noImage;
         
              if (post.fileUploads) {
                post.fileUploads.forEach((element) => {
                  if (element.type === "image") {
                     
                    if (element.location !== "") {
                      pic = element.location;
                    }
                  }
                });
              }

              return (
                <div key={i}>
                  <div className={`${bool ? "row-cat-item-color-dr" : "row-cat-item-color-lt"}`}>
                    <div className="row">
                      <div className="col-2 text-center pic-placement">
                        <img src={pic} alt="pic" className="pic-cat-browse" />
                      </div>
                      <div className="col-8 text-left">
                        {this.state.deviceSize === "small" ? (
                          <div className="cat-item-text-for-brows">
                            {post.itemName.length > 25
                              ? post.itemName.substring(0, 24)
                              : post.itemName}
                            &nbsp;({post.itemType})&nbsp;
                            {post.currentInventory === 0
                              ? "NA"
                              : post.currentInventory}
                          </div>
                        ) : (
                            <div>
                          <div className="cat-item-text-for-brows">
                            {" "}
                            {post.itemName}
                            { this.props.coopItemFilter ? " [Store: " + post.coopStoreName + "]" : null} -- <font size="1">{post.itemId}</font>
                          </div>
                          <div className="cat-item-text-for-brows">
                            {post.itemDescription.substring(0,100)}...
                          </div>
                          </div>
                        )}
                      </div>
                      <div className="col-2 text-center item-open-button-pos">
                        <button
                          onClick={() => this.handleItem(post)}
                          className="btn_reg_50_open"
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          {this.state.deviceSize === 'big' ? "Open" : "O"}
                        </button>
                      </div>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
          <div className="text-center cat-rec-count-txt">Listed {this.state.items.length} record of {this.state.noOfDoc} available. {this.state.items.length < this.state.noOfDoc ? "Please refine your search." : null} [max items: 40]</div>
        </div>
      );
    }

    let outputPanel;
    let filterPanel;
    if (this.state.showFilterFlag) {
      let coopStoreId = 0;
      if ( this.props.store) coopStoreId = this.props.store.coopStoreId;
      outputPanel = (
        <div>
          <CatalogItemFilter
            returnToCaller={this.returnAfterPagiFilter}
            merchandiseType={this.props.itemType}
            communityId={this.props.communityId}
            authorizedFuncs={this.props.authorizedFuncs}
            caller="viewpagicatalog"
            coopStoreId={coopStoreId}
            role={this.props.role}
          />
        </div>
      );
    }

    if (this.state.showReturnFlag) {
      outputPanel = (
        <CommunityReturnPolicy
          returnTraits={this.props.community.returnTraits}
          returnToCaller={this.returnFromReturn}
        />
      );
    }

    if (this.state.showpagi && !this.state.showPaginationPanelFlag) {
      outputPanel = (
        <div>
          {filterPanel}
          {itemsPanel}
        </div>
      );
    }

    if (this.state.showCatalogOfGoods) {
      outputPanel = (
        <div>
          <CatalogGoodsDetails
            sendCatalogItemDetails={this.state.sendCatalogItemDetails}
            returnToDetails={this.returnFromDetails}
            searchItemName={this.state.searchItemName}
            authorizedFuncs={this.props.authorizedFuncs}
            cartOf={this.props.cartOf}
            caller={this.props.caller}
            community={this.props.community}
            comingFrom={this.state.comingFrom}
          />
        </div>
      );
    }

    if (this.state.showCatalogOfService) {
      let store = null;
      if ( this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <CatalogServiceDetails
            sendCatalogItemDetails={this.state.sendCatalogItemDetails}
            itemToShow={this.state.itemToShow}
            returnToDetails={this.returnFromDetails}
            community={this.props.community}
            authorizedFuncs={this.props.authorizedFuncs}
            cartOf={this.props.cartOf}
            seller={this.props.auth.user}
            caller={this.props.caller}
            comingFrom={this.state.comingFrom}
            store={store}
          />
        </div>
      );
    }

    if (this.state.cartFlag) {
      console.log("======= cartFlag ... going to cart:");
      let coopStoreId = 0;
      let displayStoreName = "";
      let store = null;
      if ( this.props.store) {
        coopStoreId = this.props.store.coopStoreId;
        displayStoreName = this.props.store.displayStoreName;
        store = this.props.store;
      }
      outputPanel = (
        <div>
          <CartShow
            baandaId={this.props.cartOf.baandaId}
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            displayStoreName={displayStoreName}
            commName={this.props.commName}
            community={this.props.community}
            store={store}
            searchItemName={this.state.searchItemName}
            returnToDetails={this.returnFromDetails}
            pointOfCall="catalog"
            caller={this.props.caller}
            cartOfBaandaId={this.props.cartOf.baandaId}
            cartOf={this.props.cartOf}
            authorizedFuncs={this.props.authorizedFuncs}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ViewPagiCatalog.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewPagiCatalog)
);
