import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
// import { withRouter } from "react-router-dom";

import ReactLoading from "react-loading";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./SetTwilioAccount.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const setTwilioSubAccount = "/routes/dashboard/setTwilioSubAccount";

const readMeCode = "1160000000";

class SetTwilioAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      twilioIsSetupFlag: false,
      customerCell: "",
      twilioAcc: null,

      errMsg: "",
      errFlag: false,
      community: null,
      loadingFlag: false,
    };
  }

  componentDidMount = async () => {
    await this.setTwilioSetupState();

    if (!this.state.twilioIsSetupFlag) {
      await this.loadFreshCommunity();
    }
  };

  setTwilioSetupState = async () => {
    console.log("in setTwilio...");
    let twilioAcc = null;
    let customerCell = this.props.community.contact[0].contactCell;
    let twilioIsSetupFlag = false;
    if (
      this.props.community.twilio &&
      this.props.community.twilio.number !== ""
    ) {
      twilioIsSetupFlag = true;
      twilioAcc = this.props.community.twilio;
    }
    this.setState({
      twilioIsSetupFlag,
      twilioAcc,
      customerCell,
      community: this.props.community,
    });
  };

  loadFreshCommunity = async () => {
    let param = "communityId=" + this.props.communityId;
    let url = baandaServer + getCommunityInfo + param;
    try {
      let comret = await axios.get(url);
      if (comret.data.status === "success") {
        if (
          comret.data.Msg &&
          comret.data.Msg.twilio &&
          comret.data.Msg.twilio.number !== ""
        ) {
          this.setState({
            twilioAcc: comret.data.status.twilio,
            twilioIsSetupFlag: true,
          });
        }
      } else {
        this.setState({
          errMsg: comret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  setTwilioAccount = async () => {
    console.log("set Twilio acount ...");
    this.setState({
      loadingFlag: true,
    });
    let url = baandaServer + setTwilioSubAccount;
    let input = {
      communityId: this.props.communityId,
      customerCell: this.props.community.contact[0].contactCell,
    };
    console.log("url:", url, " input:", input);
    try {
      let tret = await axios.post(url, input);
      console.log("tret.data:", tret.data);
      if (tret.data.status === "success") {
        this.setState({
          twilioIsSetupFlag: true,
          twilioAcc: tret.data.Msg.twilio,
          loadingFlag: false,
        });
      } else {
        this.setState({
          errMsg: tret.data.Msg,
          errFlag: true,
          loadingFlag: false,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
        loadingFlag: false,
      });
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div className="subscription-spinner-pos text-center">
          <ReactLoading
            type={"spokes"}
            color={"#1f3d6b"}
            height={30}
            width={30}
          />
        </div>
      );
    }
    let subModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let outputPanel;
    if (this.state.twilioIsSetupFlag) {
      outputPanel = (
        <div className="text-center">
          <div className="text-center setup-header">
            Twilio Setup&nbsp;{subModalButton}
          </div>
          <div className="row">
            <div className="col-5 text-right twilio-lbl">SMS From #</div>
            <div className="col-7 text-left twilio-txt">
              {this.state.twilioAcc.number}
            </div>
          </div>
          <div className="row">
            <div className="col-5 text-right twilio-lbl">Auth Token</div>
            <div className="col-7 text-left twilio-txt">
              {this.state.twilioAcc.authToken}
            </div>
          </div>
          <div className="row">
            <div className="col-5 text-right twilio-lbl">Account Sid</div>
            <div className="col-7 text-left twilio-txt">
              {this.state.twilioAcc.accountSid}
            </div>
          </div>
          <div className="row">
            <div className="col-5 text-right twilio-lbl">Bound Acc Sid</div>
            <div className="col-7 text-left twilio-txt">
              {this.state.twilioAcc.ownerAccountSid}
            </div>
          </div>
        </div>
      );
    } else {
      outputPanel = (
        <div className="text-center">
          <div className="text-center setup-header">
            Twilio Setup
            {/* &nbsp;{subModalButton} */}
          </div>
          <div className="text-center twilio-setup-msg">
            SMS/MMS Phone # hs not been set yet. Please click 'Setup' button to
            set it up.
          </div>
          <div className="text-center">
            <button
              className="btn_reg_70 twilio-setup-btn-pos"
              type="button"
              onClick={this.setTwilioAccount}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Setup
            </button>
          </div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        {uploadingSpin}
        <div
          className={
            this.state.errFlag
              ? "text-center twilio-msg-err"
              : "text-center twilio-msg"
          }
        >
          {this.state.errMsg}
        </div>
        <ModalContainer />
      </div>
    );
  }
}

SetTwilioAccount.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SetTwilioAccount);
