import React, { Component } from "react";

// import PhoneInput from "react-phone-number-input";

import "./CommunityContact.css";

class CommunityContact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      contacts: null,
      displayPhoneNo: ''
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    let contacts = this.props.community.contact[0];

    let phoneNo = contacts.contactCell;
    let displayPhoneNo = '';
    if ( phoneNo.substring(0,2) === '+1' ) {
        displayPhoneNo = '+1 ' + phoneNo.substring(2, 5) + "-" + phoneNo.substring(5, 8) + '-' + phoneNo.substring(8, 12);   
    } else {
        displayPhoneNo = phoneNo;
    }

    this.setState({
      contacts,
      deviceSize,
      displayPhoneNo,
    });
  };

  returnToReportList = () => {
    this.props.returnToCaller();
  };
  render() { 
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('CommunityContact...');

    let exitButton = (
        <button
        className="btn_back_main"
        onClick={this.returnToReportList}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    )

    let contactPanel;
    if (this.state.contacts) {
      contactPanel = (
        <div className="comm-contact-pos">
            
          <div className="row cont-row-pos">
            <div className="col-4 text-right comm-contact-lbl">Name</div>
            <div className="col-8 text-left comm-contact-text">
              {this.state.contacts.contactName}
            </div>
          </div>
          <div className="row cont-row-pos">
            <div className="col-4 text-right comm-contact-lbl">Email</div>
            <div className="col-8 text-left comm-contact-text">
              {this.state.contacts.contactEmail}
            </div>
          </div>
          <div className="row cont-row-pos">
            <div className="col-4 text-right comm-contact-lbl">Phone</div>
            <div className="col-8 text-left comm-contact-text">
              {this.state.displayPhoneNo}
            </div>
          </div>
          <div className="cust-contact-exit">{exitButton}</div>
        </div>
      );
    }

    return (
      <div>
        <div className="cust-communiity-contact-head">Community Contact</div>
        {contactPanel}
        
      </div>
    );
  }
}

export default CommunityContact;
