import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../actions/modalActions";
import "../../../modal/css/localModal.css";
import "../../../modal/css/template.css";

import axios from "axios";

import "./InvoiceDetails.css";

const noImage = process.env.REACT_APP_IMAGE_NOT_AVAILABLE;

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAnInvoice = "/routes/invoice/getAnInvoice?";

class invoiceDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      theInvoice: null,

      showItemFlag: false,
      selectedItemImg: "",

      errMsg: "",
      errFlag: false,

      deviceSize: "",
      lblBody: null,
    };
  }

  componentDidMount = async () => {
    await this.getTheInvoice();
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    this.setState({
      deviceSize,
    });
  };

  showLabel = async (obj) => {
    console.log("showLabel obj:", obj);
    let lblBody = {
      s3Url: obj.labelUrl,
      fileName: obj.trackingUrl,
      deviceSize: this.state.deviceSize,
      footnote: "",
    };

    await this.openPdfModal(lblBody);
  };

  openPdfModal = async (lblBody) => {
    await this.setState({
      showImageFlag: false,
    });
    let msg = {
      Header: "Shipping Label",
      Body: lblBody,
      Footer: "",
    };

    this.props.showModal(
      {
        open: true,
        title: "Alert - Show",
        message: msg,
        closeModal: this.closeModal,
      },
      "showPdf"
    );
  };

  getTheInvoice = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let parms =
      "invoiceId=" +
      this.props.invoiceId +
      "&communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&role=community";
    let url = baandaServer + getAnInvoice + parms;
    // console.log("url:", url);
    try {
      let inv = await axios.get(url);
      if (inv.data.status === "success") {
        this.setState({
          theInvoice: inv.data.Msg,
        });
      } else {
        this.setState({
          errMsg: inv.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getTheInvoice err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  returnToList = () => {
    this.props.returnToCaller();
  };

  // [e.target.name]: e.target.value
  showItemImage = (img) => {
    // console.log("in showItemImage ...img:", img);

    this.setState({
      showItemFlag: true,
      selectedItemImg: img,
    });
  };

  exitImagePanel = () => {
    this.setState({
      showItemFlag: false,
      selectedItemImg: "",
    });
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);
    console.log("InvoiceDetails...");

    let te = this.state.theInvoice;

    let exitButton = (
      <button
        onClick={this.returnToList}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitImagePanel}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let billAddresedTo;
    let dueDate = "";
    let fineApr = "0 %";
    if (te && te.invoiceCreationType === "manual" && te.manualInvoiceParams) {
      let mb = te.manualInvoiceParams.billToAddress;
      billAddresedTo =
        mb.line1 + ", " + mb.city + ", " + mb.state + " " + mb.postalCode;
      if (te.manualInvoiceParams.dueDate)
        dueDate = te.manualInvoiceParams.dueDate.substring(0, 10);
      if (te.manualInvoiceParams.fineApr)
        fineApr = te.manualInvoiceParams.fineApr + " %";
    }

    let invoiceCreationPanel;
    if (te) {
      if (te.invoiceCreationType === "manual") {
        invoiceCreationPanel = (
          <div className="inv-rep-creation-pos">
            <div className="text-left inv-report-lbl-head">
              Creation Process
            </div>
            <div className="row">
              <div className="col-2 text-right inv-report-lbl">Type</div>
              <div className="col-4 text-left inv-report-txt">
                {te.invoiceCreationType.toUpperCase()}
              </div>
              <div className="col-2 text-right inv-report-lbl">Status</div>
              <div className="col-4 text-left inv-report-txt">
                {te.invoiceCreationStatus}
              </div>
            </div>
            <div className="row">
              <div className="col-2 text-right inv-report-lbl">Bill Addr</div>
              <div className="col-10 text-left inv-report-txt">
                {billAddresedTo}
              </div>
            </div>
            <div className="row">
              <div className="col-2 text-right inv-report-lbl">Due Date</div>
              <div className="col-4 text-left inv-report-txt">{dueDate}</div>
              <div className="col-2 text-right inv-report-lbl">Fine APR</div>
              <div className="col-4 text-left inv-report-txt">{fineApr}</div>
            </div>
          </div>
        );
      } else {
        invoiceCreationPanel = (
          <div className="inv-rep-creation-pos">
            <div className="text-left inv-report-lbl-head">
              Creation Process
            </div>
            <div className="row">
              <div className="col-2 text-right inv-report-lbl">Type</div>
              <div className="col-4 text-left inv-report-txt">
                {te.invoiceCreationType.toUpperCase()}
              </div>
              <div className="col-2 text-right inv-report-lbl">&nbsp;</div>
              <div className="col-4 text-left inv-report-txt">&nbsp;</div>
            </div>
          </div>
        );
      }
    }

    let customerPanel;

    if (this.state.theInvoice) {
      if (this.state.deviceSize === "big") {
        customerPanel = (
          <div>
            <div className="text-left inv-report-lbl-head">Customer</div>
            <div className="row">
              <div className="col-2 text-right inv-report-lbl">Name</div>
              <div className="col-4 text-left inv-report-txt">
                {te.customerName}
              </div>
              <div className="col-2 text-right inv-report-lbl">Email</div>
              <div className="col-4 text-left inv-report-txt">
                {te.customerEmail}
              </div>
            </div>
          </div>
        );
      } else {
        customerPanel = (
          <div>
            <div className="text-left inv-report-lbl-head">Customer</div>

            <div className="row">
              <div className="col-3 text-right inv-report-lbl">Name</div>
              <div className="col-9 text-left inv-report-txt">
                {te.customerName}
              </div>
            </div>
            <div className="row">
              <div className="col-3 text-right inv-report-lbl">Email</div>
              <div className="col-9 text-left inv-report-txt">
                {te.customerEmail}
              </div>
            </div>
          </div>
        );
      }
    }

    let invamt, paidamt, stax, writeoff, lastpd, shipping;
    if (this.state.deviceSize === "big") {
      invamt = "Inv Amount";
      paidamt = "Paid Amount";
      stax = "Sales Tax";
      writeoff = "Write Off";
      lastpd = "Last Pay Date";
      shipping = "Shipping";
    } else {
      invamt = "Amt";
      stax = "sTax";
      paidamt = "Paid";
      writeoff = "wOff";
      lastpd = "LPD";
      shipping = "Ship";
    }

    let financePanel;
    if (this.state.theInvoice) {
      financePanel = (
        <div className="inv-rep-division-breaks">
          <div className="text-left inv-report-lbl-head">Finance Breakdown</div>
          <div className="row">
            <div className="col-2 text-right inv-report-lbl">{invamt}</div>
            <div className="col-4 text-left inv-report-txt">
              $
              {this.commaFormattedCurrency(
                te.financeBreakdown.totalInvoiceAmount
              )}
            </div>
            <div className="col-2 text-right inv-report-lbl">{paidamt}</div>
            <div className="col-4 text-left inv-report-txt">
              ${this.commaFormattedCurrency(te.financeBreakdown.amountPaid)}
            </div>
          </div>
          <div className="row">
            <div className="col-2 text-right inv-report-lbl">{stax}</div>
            <div className="col-4 text-left inv-report-txt">
              ${this.commaFormattedCurrency(te.financeBreakdown.salesTaxAmount)}
            </div>
            <div className="col-2 text-right inv-report-lbl">{writeoff}</div>
            <div className="col-4 text-left inv-report-txt">
              ${this.commaFormattedCurrency(te.financeBreakdown.writeOffAmt)}
            </div>
          </div>
          <div className="row">
            <div className="col-2 text-right inv-report-lbl">{lastpd}</div>
            <div className="col-4 text-left inv-report-txt">
              {te.financeBreakdown.lastPaymentDate
                ? te.financeBreakdown.lastPaymentDate.substring(0, 10)
                : "Not Paid Yet"}
            </div>
            <div className="col-2 text-right inv-report-lbl">{shipping}</div>
            <div className="col-4 text-left inv-report-txt">
              ${this.commaFormattedCurrency(te.financeBreakdown.shippingAmt)}
            </div>
          </div>
        </div>
      );
    }

    let itemDetailsPanel;
    let bool1 = true;
    if (this.state.theInvoice) {
      if (te.invoiceOrigin === "manual") {
        let lineItemsManual = te.itemDetails;
        itemDetailsPanel = (
          <div className="inv-rep-division-breaks">
            <div className="text-left inv-report-lbl-head">
              Invoice Line Items
            </div>
            <div className="rep-invoice-linteitem-box">
              {lineItemsManual.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        bool1
                          ? "row row-col-schdule-dr-inv"
                          : "row row-col-schdule-lt-inv"
                      }
                    >
                      <div className="col-10 text-left invoice-line-lbl">
                        {obj.description} (Unit: {obj.unit} -{" "}
                        {obj.subLineItemType}) | Price: $
                        {this.commaFormattedCurrency(obj.price / obj.quantity)}{" "}
                        Qty: {obj.quantity}{" "}
                        {obj.isTaxable
                          ? "(Taxable - by " + obj.taxPaidBy + ")"
                          : ""}
                      </div>

                      <div className="col-2 text-right invoice-line-cost">
                        ${" "}
                        {this.commaFormattedCurrency(obj.price * obj.quantity)}
                      </div>
                    </div>
                    {(bool1 = !bool1)}
                  </div>
                );
              })}
            </div>
          </div>
        );
        // Get the right items from invoice raw
      } else if (te.invoiceOrigin === "catalog") {
        let lineItems = [];
        if (te.invoiceRaw) {
          lineItems = te.invoiceRaw.invoiceLineItems;
        } else {
          if (te.itemDetails.length > 0) {
            lineItems = te.itemDetails;
          } else {
            if (te.periodicDeliveryObj) {
              let lineObj = {
                image: te.periodicDeliveryObj.itemImage,
                itemName: te.periodicDeliveryObj.lineItemName,
                itemType: "goods",
                unitType: te.periodicDeliveryObj.periodicShipData.type,
                price: te.periodicDeliveryObj.itemPrice,
                quantity: te.periodicDeliveryObj.quantity,
              };
              lineItems.push(lineObj);
            }
          }
        }

        itemDetailsPanel = (
          <div className="inv-rep-division-breaks">
            <div className="text-left inv-report-lbl-head">
              Invoice Line Items
            </div>
            <div className="rep-invoice-linteitem-box">
              {lineItems.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        bool1
                          ? "row row-col-schdule-dr-inv"
                          : "row row-col-schdule-lt-inv"
                      }
                    >
                      {obj.itemImage && obj.itemImage !== "" ? (
                        <div className="col-1 text-left ">
                          <button
                            onClick={() => this.showItemImage(obj.itemImage)}
                            style={{
                              cursor: this.state.disabled
                                ? "default"
                                : "pointer",
                            }}
                            className="re-item-img-btn"
                          >
                            <img
                              src={obj.itemImage}
                              className="item-image-thumnail"
                              alt="NoImg"
                            />
                          </button>
                        </div>
                      ) : (
                        <div className="col-1 text-left ">
                          <img
                            src={noImage}
                            className="item-image-thumnail"
                            alt="noImg"
                          />
                        </div>
                      )}
                      {this.state.deviceSize === "big" ? (
                        <div className="col-9 text-left invoice-line-lbl">
                          {obj.itemName} ({obj.itemType} - {obj.unitType}) |
                          Price: ${this.commaFormattedCurrency(obj.price)} Qty:{" "}
                          {obj.quantity}
                        </div>
                      ) : (
                        <div className="col-9 text-left invoice-line-lbl">
                          {obj.itemName}
                        </div>
                      )}

                      {this.state.deviceSize === "big" ? (
                        <div className="col-2 text-right invoice-line-cost">
                          ${" "}
                          {this.commaFormattedCurrency(
                            obj.price * obj.quantity
                          )}
                        </div>
                      ) : (
                        <div className="col-1 text-left invoice-line-cost">
                          $
                          {this.commaFormattedCurrency(
                            obj.price * obj.quantity
                          )}
                        </div>
                      )}
                    </div>
                    {(bool1 = !bool1)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        // console.log("in init...");
        let lineItems = te.itemDetails;
        itemDetailsPanel = (
          <div className="inv-rep-division-breaks">
            <div className="text-left inv-report-lbl-head">
              Invoice Line Items
            </div>
            <div className="rep-invoice-linteitem-box">
              {lineItems.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        bool1
                          ? "row row-col-schdule-dr-inv"
                          : "row row-col-schdule-lt-inv"
                      }
                    >
                      <div className="col-10 text-left invoice-line-lbl">
                        {obj.itemName} | Price: $
                        {this.commaFormattedCurrency(obj.price)} Qty:{" "}
                        {obj.quantity} (
                        {obj.itemType === "communityinitiation"
                          ? "Initiations"
                          : ""}
                        )
                      </div>

                      <div className="col-2 text-right invoice-line-cost">
                        ${" "}
                        {this.commaFormattedCurrency(obj.price * obj.quantity)}
                      </div>
                    </div>
                    {(bool1 = !bool1)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      }
    }

    let pstatus, paidAmt, pid, invtype, medium;
    if (this.state.deviceSize === "big") {
      pstatus = "Payment Status";
      paidAmt = "Paid Amount";
      pid = "Payment Id";
      invtype = "Invoice Type";
      medium = "Medium";
    } else {
      pstatus = "State";
      paidAmt = "Paid";
      pid = "PID";
      invtype = "Type";
      medium = "Used";
    }

    let paymentInfoPanel;
    if (this.state.theInvoice) {
      paymentInfoPanel = (
        <div className="inv-rep-division-breaks">
          <div className="text-left inv-report-lbl-head">
            Payment Information
          </div>
          <div className="row">
            <div className="col-2 text-right inv-report-lbl">{pstatus}</div>
            <div className="col-4 text-left inv-report-txt">
              {te.paidUpFlag ? "Paid in full" : "Payment pending"}
            </div>
            <div className="col-2 text-right inv-report-lbl">{paidAmt}</div>
            <div className="col-4 text-left inv-report-txt">
              {te.paymentInfo.length > 0
                ? "$" + this.commaFormattedCurrency(te.paymentInfo[0].amount)
                : "No payment made"}
            </div>
          </div>
          <div className="row">
            <div className="col-2 text-right inv-report-lbl">{medium}</div>
            <div className="col-4 text-left inv-report-txt">
              {te.paymentInfo.length > 0 ? (
                <div>
                  {te.paymentInfo[0].payMedium === "creditcard"
                    ? "Credit Card"
                    : te.paymentInfo[0].payMedium.toUpperCase()}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-2 text-right inv-report-lbl">{pid}</div>
            <div className="col-4 text-left inv-report-txt">
              {te.paymentInfo.length > 0 ? te.paymentInfo[0].paymentId : ""}
            </div>
          </div>
          <div className="row">
            <div className="col-2 text-right inv-report-lbl">{invtype}</div>
            <div className="col-4 text-left inv-report-txt">
              {te.invoiceType.toUpperCase()}
            </div>
            <div className="col-6 text-right inv-report-lbl">&nbsp;</div>
          </div>
        </div>
      );
    }

    let shipDetails = [];
    let shippingPanel;
    if (this.state.theInvoice) {
      // console.log("554 te.spInfo:", te.spInfo);

      if (te.spInfo && te.spInfo.regularPkg.length > 0) {
        te.spInfo.regularPkg.forEach((pk) => {
          if (pk.regularCarrier.carrierName !== "") {
            let shipObj = {
              type: "Regular",
              status: pk.status,
              carrierName: pk.regularCarrier.carrierName,
              labelUrl: pk.regularCarrier.label_url,
              shipDate: pk.regularCarrier.shippedDate,
              shippingPrice: pk.regularCarrier.shippingPrice,
              trackingId: pk.regularCarrier.trackingId,
              trackingUrl: pk.regularCarrier.trackingUrl,
            };
            shipDetails.push(shipObj);
          }
        });
      }

      if (
        te.spInfo &&
        te.spInfo.specialPkg &&
        te.spInfo.specialPkg.length > 0
      ) {
        te.spInfo.specialPkg.forEach((pk) => {
          let shipObj = {
            type: "Special",
            status: pk.status,
            carrierName: pk.regularCarrier.carrierName,
            labelUrl: pk.regularCarrier.label_url,
            shipDate: pk.regularCarrier.shippedDate,
            shippingPrice: pk.regularCarrier.shippingPrice,
            trackingId: pk.regularCarrier.trackingId,
            trackingUrl: pk.regularCarrier.trackingUrl,
          };
          shipDetails.push(shipObj);
        });
      }

      if (te.refunds.length > 0) {
        te.refunds.forEach((ref) => {
          if (
            ref.returnPostageObject &&
            ref.returnPostageObject.carrierName !== ""
          ) {
            let shipObj = {
              type: "Return | RefId:" + ref.refundCancelId,
              status: "",
              carrierName: ref.returnPostageObject.carrierName,
              labelUrl: ref.returnPostageObject.label_url,
              shipDate: ref.returnPostageObject.shippedDate,
              shippingPrice: ref.returnPostageObject.shippingPrice,
              trackingId: ref.returnPostageObject.trackingId,
              trackingUrl: ref.returnPostageObject.trackingUrl,
            };
            shipDetails.push(shipObj);
          }
        });
      }

      shippingPanel = (
        <div>
          <div className="text-left inv-report-lbl-head">
            Shipping Information
          </div>
          <div className="fixedsize-ship-info-box">
            {shipDetails.map((obj, i) => {
              // console.log("obj:", obj);
              return (
                <div key={i}>
                  <div className="row">
                    <div className="col-4 text-center inv-report-lbl">
                      Type: {obj.type}
                    </div>
                    <div className="col-4 text-center inv-report-txt">
                      Carrier: {obj.carrierName}
                    </div>
                    <div className="col-4 text-center inv-report-lbl">
                      Ship Date: {obj.shipDate.substring(0, 10)}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2 text-right inv-report-lbl">
                      Track URL
                    </div>
                    <div className="col-10 text-left inv-report-txt">
                      {obj.trackingUrl}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2 text-right inv-report-lbl">
                      Track Id
                    </div>
                    <div className="col-10 text-left inv-report-txt">
                      {obj.trackingId}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2 text-right inv-report-lbl">
                      Label URL <br />
                      <button
                        className="btn_reg_60"
                        type="button"
                        onClick={() => this.showLabel(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Label
                      </button>
                    </div>
                    <div className="col-10 text-left inv-report-txt">
                      {obj.labelUrl}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-2 text-right inv-report-lbl">Note</div>
                    <div className="col-8 text-left inv-report-txt">
                      Status : {obj.status} || A label can be used once only.
                    </div>
                    <div className="col-2 text-left inv-report-txt">
                      <a
                        href={obj.trackingUrl}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Track
                      </a>
                    </div>
                  </div>

                  <hr />
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let invoiceNote;
    if (this.state.theInvoice) {
      invoiceNote = (
        <div className="inv-rep-division-breaks">
          <div className="text-left inv-report-lbl-head">Invoice Note</div>
          {te.invoiceNote === "" ? (
            <div className="text-center inv-report-txt">
              No notes available.
            </div>
          ) : (
            <div className="text-center inv-report-txt">{te.invoiceNote}</div>
          )}
        </div>
      );
    }

    let itemImagePanel;
    if (this.state.showItemFlag) {
      itemImagePanel = (
        <div className="show-larger-item-image-box">
          <div className="text-center">{backButton}</div>
          <img
            src={this.state.selectedItemImg}
            height="190px"
            width="190px"
            alt="noImg"
          />
        </div>
      );
    }

    let headerText;
    if (this.state.deviceSize === "big") {
      headerText = "Invoice Details (Id :";
    } else {
      headerText = "Invoice (Id :";
    }

    let outputPanel;
    if (this.state.theInvoice) {
      outputPanel = (
        <div className="invoice-rep-details-box">
          <div className="text-center rep-inv-detail-header">
            {headerText} {te.invoiceId} - Date:{" "}
            {te.invoiceDate.substring(0, 10)}) {exitButton}
          </div>
          <div className="rep-invoice-content-box">
            {customerPanel}
            {financePanel}
            {itemImagePanel}
            {invoiceNote}
            {itemDetailsPanel}
            {invoiceCreationPanel}
            {paymentInfoPanel}
            {shippingPanel}
          </div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

// export default invoiceDetails;

invoiceDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(invoiceDetails));
