/*
 **  Author: Jit (Sarbojit Mukherjee)
 **  Desc:   Provides the basic landing for Baanda with two opetions ... to chat with Baanda
 **          or login / signin to get to the lobby
 **  Note:   Every program and aspects of Baanda_dev, as of this day, is being coded and handled by Jit
 **  Date:   July 9, 2018
 **  Version:0.01
 */
import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import queryString from "query-string";

import ModalContainer from "../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../actions/modalActions";
import "../../../modal/css/localModal.css";
import "../../../modal/css/template.css";

import "./Lobby.css";

// import backimg from "../auth/image/lobby1.jpg";
import lobbyshore from "../../../images/lobbyshore.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getNewMessages = "/routes/interact/getNewMessages?";
const isBaandaAdmin = "/routes/admin/isBaandaAdmin?";
const postNonBaandaInvMsgs = "/routes/join/postNonBaandaInvMsgs";

const baandaCreatorEmail = process.env.REACT_APP_BAANDA_CREATOR_EMAIL;
const appEnvironment = process.env.REACT_APP_ENVMT;

const readMeCode = "0100000000";

class Lobby extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dashboardFlag: true,

      newMsgs: 0,
      newInvites: 0,
      newOpportunity: 0,

      incomingParms: null,
      incomingState: null,
      inpObj: null,

      newMsgFlag: false,
      isBaandaAdmin: "no",

      adminDeviceErrorFlag: false,
      errMsg: "",

      errorMessage: "",
      errFlag: false,
    };

    //
  }

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  // Get invites, Messages, and invites
  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    // Jumpt to store section ...............................
    const values = queryString.parse(this.props.location.search);
    // console.log("1. lobby values:", values);
    let opstype = "";
    let targetCommId = 0;
    let showMainLobbyFlag = true;
    let directToStoreFlag = false;
    if (values && values.opstype === "store") {
      // console.log(
      //   "values:",
      //   values,
      //   " values.targetCommId:",
      //   values.targetCommId
      // );
      opstype = values.opstype;
      targetCommId = parseFloat(values.targetCommId);
      showMainLobbyFlag = false;
      directToStoreFlag = true;
    }
    // .....................................................

    let errMsg = "";
    if (deviceSize === "small") {
      errMsg =
        'A device (not a smart phone) with a bigger display area is needed for the Baanda admin tasks. Please access this module on a larger device. "Play football on coffee table, you cannot." Yoda (or Baanda) says :).';
    }

    await this.setState({
      deviceSize,
      errMsg,
    });

    if (this.props.auth.user.baandaId) {
      let cbret = await this.checkIfBaandaAdmin();

      if (cbret.status === "success") {
        let pipret = await this.processInputParams();

        if (pipret) {
          await this.getLobbyNewMessages();
        }
      }
    } else {
      this.props.history.push("/login");
    }

    // For jumping into store code .......................
    await this.setState({
      deviceSize,
      errMsg,
      opstype,
      targetCommId,
      showMainLobbyFlag,
      directToStoreFlag,
    });

    let param = '';
    if ( values && values.opstype === 'store') {
      param = "?opstype=" + values.opstype + "&targetCommId=" + values.targetCommId;
    }

    if (directToStoreFlag) {
      this.props.history.push({
        pathname: "/dashboard",
        search: param,
        state: { detail: this.state.loginData },
      });
    }
    // .....................................................
  };

  getLobbyNewMessages = async () => {
    let td = new Date();
    let today = new Date();
    today.setDate(td.getDate() - 1);

    // today.setDate(today.getDate() + 5);
    try {
      let params =
        "baandaId=" +
        this.props.auth.user.baandaId +
        "&email=" +
        this.props.auth.user.email;
      let url = baandaServer + getNewMessages + params;
      // console.log("url:", url);
      let newmsgs = await axios.get(url);
      // console.log("newmsgs:", newmsgs.data);
      // process newmsgs
      let newmsg = 0,
        newinv = 0,
        newopps = 0;
      let newMsgFlag = false;
      // let isDash = false;
      if (newmsgs.data.Msg && newmsgs.data.Msg.length > 0) {
        let expr = "";

        newmsgs.data.Msg.forEach((elm) => {
          // console.log("lobby elm:", elm);
          let allow = true;
          if (elm.offerValidTill) {
            let vt = new Date(elm.offerValidTill).getTime();
            if (today.getTime() > vt) {
              allow = false;
            }
          }
          // console.log("allow:", allow);
          if (allow) {
            expr = elm.msgType;
            switch (expr) {
              case "invite":
                newinv++;
                newMsgFlag = true;
                break;
              case "message":
                newmsg++;
                newMsgFlag = true;
                break;
              case "opportunity":
                newopps++;
                newMsgFlag = true;
                break;
              default:
                break;
            }
          }
        });
      }
      this.setState({
        newMsgs: newmsg,
        newInvites: newinv,
        newOpportunity: newopps,
        newMsgFlag,
      });
      return { status: "success", Msg: "" };
    } catch (err) {
      this.setState({
        errorMessage: err.message,
        errFlag: true,
      });
      return { status: "error", Msg: err.message };
    }
  };

  checkIfBaandaAdmin = async () => {
    // console.log("baandaCreatorEmail:", baandaCreatorEmail);
    if (baandaCreatorEmail === this.props.auth.user.email) {
      this.setState({
        isBaandaAdmin: "yes",
      });
      return { status: "success", Msg: "" };
    } else {
      let param = "email=" + this.props.auth.user.email;
      let url = baandaServer + isBaandaAdmin + param;
      // console.log("111 url:", url);
      try {
        let isad = await axios.get(url);
        // console.log("isad:", isad);
        if (isad.data.status === "success") {
          // console.log("isad.data.Msg:", isad.data.Msg);
          // console.log("isad.data.Msg.msg:", isad.data.Msg.msg);
          if (isad.data.Msg.msg === "yes") {
            this.setState({
              isBaandaAdmin: "yes",
            });
          }
          return { status: "success", Msg: "" };
        } else {
          this.setState({
            errorMessage: isad.data.Msg,
            errFlag: true,
          });
          return { status: "error", Msg: isad.data.Msg };
        }
      } catch (err) {
        console.log("checkIfBaandaAdmin err:", err.message);
        this.setState({
          errorMessage: err.message,
          errFlag: true,
        });
        return { status: "error", Msg: err.message };
      }
    }
  };

  processInputParams = async () => {
    let inpObj = null;
    if (this.props.location.state && this.props.location.state.detail) {
      if (this.props.location.state.detail.loginData !== "")
        inpObj = JSON.parse(this.props.location.state.detail.loginData);
      this.setState({
        inpObj,
      });
    }
    // console.log("inpObj:", inpObj);
    if (inpObj && inpObj.from === "login") {
      // console.log("in here 237 ... inpObj.person:", inpObj.person);
      if (inpObj.person === "stranger") {
        await this.sendInvitesToNonBaanda();
      }
    }
    return true;
  };

  sendInvitesToNonBaanda = async () => {
    let inp = this.state.inpObj;

    try {
      let postinp = {
        invitationId: inp.invitationId,
        inviteeEmail: inp.inviteeEmail,
        inviteeName: inp.inviteeName,
        inviteeBaandaId: this.props.auth.user.baandaId,
      };
      let url = baandaServer + postNonBaandaInvMsgs;
      // console.log("sendInvitesToNonBaanda url:", url, " postinp:", postinp);
      let pret = await axios.post(url, postinp);
      if (pret.data.status === "error") {
        throw new Error(pret.data.Msg);
      }
    } catch (err) {
      this.setState({
        errorMessage: err.message,
        errFlag: true,
      });
    }
  };

  createTeamHandler = () => {
    if (!this.props.auth.user.isInitProfileDone) {
      // Create  initial profile
      this.props.history.push("/profilemgmt");
    } else {
      // Go and create/edit communities to author & publish
      this.props.history.push("/createcommunity");
    }
  };

  joinTeamHandler = () => {
    if (!this.props.auth.user.isInitProfileDone) {
      // Create  initial profile
      this.props.history.push("/profilemgmt");
    } else {
      // Go and create/edit communities to author & publish
      this.props.history.push("/joincommunity");
    }
  };

  handleTheBuzz = () => {
    if (!this.props.auth.user.isInitProfileDone) {
      // Create  initial profile
      this.props.history.push("/profilemgmt");
    } else {
      // Go and create/edit communities to author & publish
      this.props.history.push("/thebuzz");
    }
  };

  handleBaandaAdmin = async () => {
    if (this.state.deviceSize === "big") {
      if (!this.props.auth.user.isInitProfileDone) {
        // Create  initial profile
        this.props.history.push("/profilemgmt");
      } else {
        // Go and create/edit communities to author & publish
        this.props.history.push("/theBaandaAdmin");
      }
    } else {
      await this.setState({
        adminDeviceErrorFlag: true,
      });
    }
  };

  closeDeviceErrorPanel = async () => {
    await this.setState({
      adminDeviceErrorFlag: false,
    });
  };

  dashboardHandler = () => {
    // Go to Dashboard (engage)
    if (!this.props.auth.user.isInitProfileDone) {
      // Create  initial profile
      this.props.history.push("/profilemgmt");
    } else {
      // Go and create/edit communities to author & publish
      this.props.history.push("/dashboard");
    }
  };

  popOpen = async () => {
    var popup = document.getElementById("myPopup");
    popup.classList.toggle("show");
  };

  popClose = async () => {
    var popup = document.getElementById("myPopup");
    popup.classList.toggle("unshow");
  };

  render() {
    // console.log("this.props: ", this.props);
    // console.log("this.state:", this.state);
    console.log("Lobby...");

    let joinMsg = "";

    if (this.state.newMsgFlag) {
      if (this.state.newMsgs > 0) {
        joinMsg = "Msg: " + this.state.newMsgs + " ";
      }
      if (this.state.newInvites > 0) {
        joinMsg = "Invites: " + this.state.newInvites + " ";
      }
      if (this.state.newOpportunity) {
        joinMsg = "Opps: " + this.state.newOpportunity;
      }
    }

    let lobbyLounge;

    let teamUp = (
      <div>
        <div className="row">
          <div className="col text-center domain-box-header-text">
            <button
              className="btn-lobby-big"
              type="button"
              onClick={this.createTeamHandler}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <b>Create</b>
            </button>
          </div>
        </div>
      </div>
    );

    let meetup = (
      <div>
        <div className="row">
          <div className="col text-center domain-box-header-text">
            <button
              className="btn-lobby-big"
              type="button"
              onClick={this.joinTeamHandler}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <b>Join</b>
            </button>
          </div>
        </div>
        {joinMsg === "" ? null : (
          <div className="row">
            <div className="col join-message text-center">
              {/* <Blink color="#18ed51" text={joinMsg} fontSize="14" /> */}
              {joinMsg}
            </div>
          </div>
        )}
      </div>
    );

    let dashboard;
    let spaceInbetween;

    dashboard = (
      <div>
        <div className="row">
          <div className="col text-center domain-box-header-text">
            <button
              className="btn-lobby-big"
              type="button"
              onClick={this.dashboardHandler}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <b>The Bazaar - Engage</b>
            </button>
          </div>
        </div>
      </div>
    );

    let errorPanel;
    if (this.state.adminDeviceErrorFlag) {
      errorPanel = (
        <div className="lobby-error-box">
          <div className="lobby-error-header text-center">Message</div>
          <div>
            <p align="justify" className="lobby-error-msg">
              {this.state.errMsg}
            </p>
            <button
              className="btn_back_main"
              type="button"
              onClick={this.closeDeviceErrorPanel}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
        </div>
      );
    }

    return (
      <div className="lobby">
        <div className="row lobbyheader">
          <div className="col">&nbsp;</div>
        </div>
        <div>
          {spaceInbetween}
          {errorPanel}
          {dashboard}
          {teamUp}
          {lobbyLounge}
          {meetup}
        </div>
        <div className="text-center lobby-app-env">
          {appEnvironment === "" || !appEnvironment
            ? null
            : "Environment: " + appEnvironment}
        </div>
        {this.state.deviceSize === "big" ? (
          <div className="text-center lobby-pic">
            <img src={lobbyshore} height="200px" width="740px" alt="" />
          </div>
        ) : (
          <div className="text-center lobby-pic">
            <img src={lobbyshore} height="120px" width="95%" alt="" />
          </div>
        )}

        <ModalContainer />
      </div>
    );
  }
}

Lobby.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Lobby));
