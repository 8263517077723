import React, { Component } from "react";

import axios from "axios";

import "./CloseOpen.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveOpenClose = "/routes/coop/saveOpenClose";

export default class CloseOpen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openClose: "open",
      reason: "",
    };
  }

  componentDidMount = async () => {
    let openClose = this.props.store.openClose.currentState;
    let reason = this.props.store.openClose.noteToCustomer;

    this.setState({
      openClose,
      reason
    })
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  handleOpsType = async (e) => {
    await this.setState({
      openClose: e.target.value,
    });
  };

  saveCloseOpen = async () => {
    let openClose = {
      currentState: this.state.openClose,
      noteToCustomer: this.state.reason,
      communityId: this.props.store.communityId,
      coopStoreId: this.props.store.coopStoreId
    };
    try {
      let url = baandaServer + saveOpenClose;
      let ocret = await axios.post(url, openClose);
      if (ocret.data.status === "success") {
        this.setState({
          errMsg: "Successfull updated",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: ocret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('CloseOpen...');

    let saveButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.saveCloseOpen}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let reasonPanel;
    reasonPanel = (
      <div className="row co-op-closeopen-row">
        <div className="col-3 text-right adm-creator-names-lbl">Inform</div>
        <div className="col-9 text-left">
          <input
            name="reason"
            type="text"
            value={this.state.reason}
            onChange={this.onChange}
            size="100"
            maxLength="100"
            className="coop-keeper-show-name"
            placeholder="Inform of time (physical store)"
          />
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="coop-store-closeopen-box">
        <div className="text-cenrter coop-close-open-head">
          Store Cloe Open Control
        </div>
        <div className="row coop-keep-openclose-row">
          <div className="col text-center radio-font-style">
            <strong>Store state&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="open"
                  checked={this.state.openClose === "open"}
                  onChange={this.handleOpsType}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                />{" "}
                Open
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="close"
                  checked={this.state.openClose === "close"}
                  onChange={this.handleOpsType}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                />{" "}
                Close
              </label>
            </div>
          </div>
        </div>
        {reasonPanel}
        {saveButton}
        <div
          className={
            this.state.errFlag
              ? "text-center coop-openclose-msg-err"
              : "text-center coop-openclose-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}
