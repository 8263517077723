import React, { Component } from "react";

import "./ViewQnAAgreement.css";

class ViewQnAAgreement extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  returnToCoopMgmt = () => {
    this.props.returnToCaller();
  };
  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ViewQnAAgreement...');

    let doneButton;
    doneButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToCoopMgmt}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let showAgreementPanel;
    let bool = true;
    showAgreementPanel = (
      <div>
        <div className="text-center coop-mgmt-agr-view-x">
          Agreement &nbsp;{doneButton}
        </div>
        <div className="coop-mgmt-agreement-box">
          {this.props.agreement.map((store, i) => {
            return (
              <div key={i}>
                <div className={`${bool ? "row-color-dr" : "row-color-lt"}`}>
                  <div className="row coop-view-qna-row">
                    <div className="col-1  text-right coop-view-qna">
                      {store.seqno}.
                    </div>
                    <div className="col-11 text-left coop-view-qna">
                      {store.anAgreementClause}
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
          <div className="admin-view-keeper-not text-left">
            <b>Keeper Note</b>:&nbsp;{this.props.agreementKeeperComment}
          </div>
        </div>
      </div>
    );

    let showQnAPAnel = (
      <div className="coop-mgmt-agreement-box">
        {this.props.freeformQnA.map((store, i) => {
          return (
            <div key={i}>
              <div className={`${bool ? "row-color-dr" : "row-color-lt"}`}>
                <div className="row coop-view-qna-row">
                  <div className="col">
                    <div className="row">
                      <div className="col-1  text-right coop-view-qna">
                        {store.seqno}.
                      </div>
                      <div className="col-11 text-left coop-view-qna">
                        {store.question}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-1  text-right coop-view-qna">
                        &nbsp;
                      </div>
                      <div className="col-11 text-left coop-view-qna">
                        {store.answer}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {(bool = !bool)}
            </div>
          );
        })}
      </div>
    );

    let outputPanel = (
      <div className="coop-store-mgmt-box">
        {showAgreementPanel}
        <div className="text-center coop-mgmt-agr-view-x"> Q & A</div>
        {showQnAPAnel}
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default ViewQnAAgreement;
