import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import ModalContainer from "../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../actions/modalActions";
import "../../../modal/css/localModal.css";
import "../../../modal/css/template.css";

import "./ViewPdfFile.css";

class ViewPdfFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      selheight: 1,

      showPdfSelectPanel: true,

      pdfFileSelected: null,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setState({
      deviceSize,
    });

    if (document.getElementById("myBizFileSelect"))
      document.getElementById("myBizFileSelect").value = "DEFAULT";
  };

  onfocus = async () => {
    let selht = 4;
    if (this.props.filesToView.length < 4)
      selht = this.props.filesToView.length + 1;
    await this.setState({
      selheight: selht,
    });
  };

  onblur = async () => {
    await this.setState({
      selheight: 1,
    });
  };

  goBackPlease = async () => {
    await this.setState({
      showPdfSelectPanel: false,
    });
    this.props.returnToCaller();
  };

  openPdfModal = () => {
    let msg = {
      Header: "View Documents",
      Body: {
        s3Url: this.state.pdfFileSelected.location,
        fileName: this.state.pdfFileSelected.fileName,

        footnote: "",
      },
      Footer: "",
    };

    this.props.showModal(
      {
        open: true,
        title: "Alert - Start Here Header",
        message: msg,
        closeModal: this.closeModal,
      },
      "showPdf"
    );
  };

  handleFileSelected = async () => {
    let value = document.getElementById("myBizFileSelect").value;
    // console.log("value:", value);
    let valueStr = JSON.parse(value);
    // console.log("valueStr:", valueStr);

    await this.setState({
      pdfFileSelected: valueStr,
      selheight: 1,
    });
    // console.log("value >>>:", value);
    await this.openPdfModal();
  };

  handleAdminOpsSelected = async () => {
    let value = document.getElementById("myBizFileSelect").value;
    console.log("11 valule:", value);
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ViewPdfFile...')

    let buttonPanel = (
      <div>
        <button
          onClick={this.goBackPlease}
          className="btn_viewpdf_apdep"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Return
        </button>
      </div>
    );

    let sellist;
    if (this.props.filesToView) {
      sellist = this.props.filesToView.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj.fileName}&nbsp;
          </option>
        );
      });
    }

    let pdfFilesPanel;
    pdfFilesPanel = (
      <div>
        <div>
          <div className="row select_panel_inventory">
            <div className="col text-center div_catitem_select">
              <select
                size={this.state.selheight}
                id="myBizFileSelect"
                name="myBizFileSelect"
                onChange={this.handleFileSelected}
                className="inv-item-select"
                onFocus={this.onfocus}
                onBlur={this.onblur}
              >
                <option value="DEFAULT" disabled>
                  Select an Category
                </option>
                {/* <option value="{ a: 1, b: 2}">Select an Category XX</option> */}
                {sellist}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="col text-center select_item_msg">
              Select a document. It may take few momnets to present doc from the
              cloud.
            </div>
          </div>
        </div>
      </div>
    );

    let outputPanel;
    if (this.state.showPdfSelectPanel) {
      outputPanel = (
        <div className="view-select-pdf-box">
          <div className="text-center pdf-view-header">{this.props.domain}</div>
          {pdfFilesPanel}
          {buttonPanel}
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ViewPdfFile.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewPdfFile);
