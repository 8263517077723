import React, { Component } from "react";

import axios from "axios";

import "./CatalogPaginated.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCatalogItems = "/routes/dashboard/getCatalogItems?";

class CatalogPaginated extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      catalogitems: [],
      pageNumbers: [],
      noOfRecords: 0,
      showPaginationPanelFlag: true,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getFilteredCatalogItems(this.props.pagiFilter);

    this.setState({
      deviceSize,
    });
  };

  getFilteredCatalogItems = async (data) => {
    // console.log("data:", data);
    let coopStoreId = 0;
    if ( this.props.store) coopStoreId = this.props.store.coopStoreId;

    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" + coopStoreId +
      "&filterType=" +
      data.filterType +
      "&invClassification=" +
      data.invClassification +
      "&itemType=" +
      data.itemType +
      "&category=" +
      data.category +
      "&filterByName=" +
      data.searchItemName +
      "&isPublished=" +
      data.isPublished;
    let url = baandaServer + getCatalogItems + params;
    // console.log("url:", url);

    try {
      let ret = await axios.get(url);
      // console.log("getCatalogItems ret:", ret);
      if (ret.data.status === "success") {
 
          await this.setState({
            catalogitems: ret.data.Msg.items,
            noOfRecords: ret.data.Msg.count
          });
 
      } else {
        await this.setState({
          errmsg: ret.data.Msg,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      await this.setState({
        errmsg: err.message,
      });
    }
  };


  paginate = async (input) => {
    await this.setState({
      currentPage: input,
    });
    await this.setPosts();
  };

  returnToCatalog = (obj) => {
    // console.log("obj:", obj);
    let input = {
      opsType: "adminanitem",
      catobj: obj,
    };
    this.props.returnToCaller(input);
  };

  handleClear = () => {
    let input = {
      opsType: "exittosearch",
    };
    this.props.returnToCaller(input);
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('CatalogPaginated...');

    let purposHeader, colhead, sumtot;
    if (this.state.deviceSize === "small") {
      purposHeader = "Catalog - Show Room";
      colhead = "Item Name (Qty)";
      sumtot = "Items";
    } else {
      purposHeader = "Catalog - Show Room";
      colhead = "Item Name - (Category) (Warehouse Qty Unit)";
      sumtot = "No. of Items";
    }

    if (this.state.merchandiseType === "service") {
      colhead = "Item Name (category)";
    }

    let summaryPanel;
    summaryPanel = (
      <div className="sum-placement">
        <span className="bl">
          {sumtot}&nbsp;=&nbsp;{this.state.catalogitems.length}
        </span>
      </div>
    );

    let bool = true;
    let pagipanel;
 
      pagipanel = (
        <div>
          {/* <hr /> */}
          <div className="row inventory-main-header">
            <div className="col-5 text-right">{purposHeader}</div>
            <div className="col-6 text-center">{summaryPanel}</div>
            <div className="col-1 text-center">
              <button
                className="btn_back_main"
                type="button"
                onClick={this.handleClear} 
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <i className="fas fa-step-backward" />
              </button>
            </div>
          </div>
          <div className="row">
            <div className="col-9 text-left pickup-text-header">{colhead}</div>
            <div className="col-3 text-center pickup-text-header">Ops</div>
          </div>
          <div className="fixedsize_catalog_paginated">
            {this.state.catalogitems.map((post, i) => (
              <div key={i}>
                <div className={`${bool ? "row-cat-mgmt-color-dr" : "row-cat-mgmt-color-lt"}`}>
                  <div className="row">
                    {this.state.merchandiseType === "goods" ? (
                      <div className="col-10 text-left pickup-text">
                        {this.state.deviceSize === "small" ? (
                          <div>
                            {post.itemName.length > 25
                              ? post.itemName.substring(0, 24)
                              : post.itemName}
                            &nbsp;(Q:{post.quantity})
                          </div>
                        ) : (
                          <div>
                            {" "}
                            {post.itemName}&nbsp;-&nbsp;({post.itemCategory}
                            )&nbsp;(
                            {post.quantity}&nbsp;{post.unitName})
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className="col-10 text-left pickup-text">
                        {this.state.deviceSize === "small" ? (
                          <div>
                            {post.itemName.length > 25
                              ? post.itemName.substring(0, 24)
                              : post.itemName}
                          </div>
                        ) : (
                          <div>
                            {" "}
                            {post.itemName}&nbsp;-&nbsp;({post.itemCategory})
                          </div>
                        )}
                      </div>
                    )}

                    <div className="col-2 text-left cat-item-but-pos">
                      <button
                        onClick={() => this.returnToCatalog(post)}
                        className="btn_reg_50"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Open
                      </button>
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            ))}
          </div>
          <div className="text-center">Presenting {this.state.catalogitems.length} records of the {this.state.noOfRecords} of the search outcome. {this.state.catalogitems.length < this.state.noOfRecords ? "Refine your search to view others." : null}</div>
        </div>
      );


    let outputPanel;
 
      outputPanel = <div>{pagipanel}</div>;
     

    return <div>{outputPanel}</div>;
  }
}

export default CatalogPaginated;
