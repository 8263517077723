import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactLoading from "react-loading";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./UpdateIdentity.css";

// import cosmicDoorway from "./image/cosmicDoorway.jpg";
import cosmicDoorway from "../../../../communityCreate/components/image/cosmicDoorway.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const idfilehandling = "/routes/stripe/idfilehandling";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const picTypes = [
  "image/jpeg",
  // "image/bmp",
  // "image/tiff",
  "image/png",
  // "image/gif",
  // "image/webp",
  "application/pdf",
];
const maxIdPicSize = 5000000; // 5MB

const readMeCode = "1151400000";

class UpdateIdentity extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      perFaceUpFile: null,
      perFaceUpImagePreviewUrl: null,
      perFaceUpFileNameToDisplay: "",
      fileNameToDisplay: "",
      perFaceDownFile: null,
      perFaceDownImagePreviewUrl: null,
      perFaceDownFileNameToDisplay: "",

      comFaceUpFile: null,
      comFaceUpImagePreviewUrl: null,
      comFaceUpFileNameToDisplay: "",
      comFaceDownFile: null,
      comFaceDownImagePreviewUrl: null,
      comFaceDownFileNameToDisplay: "",

      uploadFileType: "faceUp",

      pictureErrFlag: false,
      pictureErrMsg: "",
      //   File-upload state variables -----------------------
      highlight: false,
      currFilename: "",
      disabled: false,
      isFaceUpFlag: false,
      isFaceDownFlag: false,
      faceUpActiveFlag: true,
      faceDownActiveFlag: false,
      pFaceupStripeFileId: null,
      pFacedownStripeFileId: null,
      cFaceupStripeFileId: null,
      cFacedownStripeFileId: null,
      viewActiveFlag: false,
      uploadMsg: "Click or Tap Dropzone, or Drag'n-Drop, to select a file.",
      pictureMsg:
        "Credit card processors require you to upload both a personal ID and a company ID, but you can only upload one at a time.  See Help (i) for detailed instructions and information about the types of IDs they accept. Valid file types are jpeg, png of less than 5MB in size.",
      uploadBtnClicked: true,
      idSide: "Upload Id Card's FACE Side",
      directionMsg:
        "Please read the Help (i) for details. Your request may be rejected if the documents are not uploaded correctly.",
      showmsg: "",
      IdpictureSkip: false,
      loadingFlag: false,
      showmsgErrFlag: false,
      documentOfType: "person",

      idVerificationDone: false,
    };

    this.fileInputRef = React.createRef();
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.setState({
      deviceSize,
      IdpictureSkip: this.props.IdpictureSkip,
      showmsg: "",
    });

    await this.loadDataInStateIdentity();
  };

  loadDataInStateIdentity = async () => {
    let id = this.props.fileData;

    // person variable
    let pfufile, pfuiurl, pfuverifyid, pfdfile, pfdiurl, pfdverifyid;
    // comopany variable
    let cfufile, cfuiurl, cfuverifyid, cfdfile, cfdiurl, cfdverifyid;

    // Person
    if (id.person.faceup.file) pfufile = id.person.faceup.file;
    if (id.person.faceup.fileblob) pfuiurl = id.person.faceup.fileblob;

    if (id.person.faceup.verificationFieldId) {
      // console.log("inside the if ....");
      pfuverifyid = id.person.faceup.verificationFieldId;
    }
    // console.log("pfuverifyid:", pfuverifyid);
    if (id.person.facedown.file) pfdfile = id.person.facedown.file;
    if (id.person.facedown.fileblob) pfdiurl = id.person.facedown.fileblob;
    if (id.person.facedown.verificationFieldId)
      pfdverifyid = id.person.facedown.verificationFieldId;

    // Company
    if (id.company.faceup.file) cfufile = id.company.faceup.file;
    if (id.company.faceup.fileblob) cfuiurl = id.company.faceup.fileblob;
    if (id.company.faceup.verificationFieldId)
      cfuverifyid = id.company.faceup.verificationFieldId;
    if (id.company.facedown.file) cfdfile = id.company.facedown.file;
    if (id.company.facedown.fileblob) cfdiurl = id.company.facedown.fileblob;
    if (id.company.facedown.verificationFieldId)
      cfdverifyid = id.company.facedown.verificationFieldId;

    // person
    let pfufntodisp = "",
      pfdfntodisp = "",
      cfufntodisp = "",
      cfdfntodisp = "";
    if (this.state.documentOfType === "person") {
      if (pfufile && pfufile.name) pfufntodisp = pfufile.name;
      if (pfdfile && pfdfile.name) pfdfntodisp = pfdfile.name;
    } else {
      if (cfufile && cfufile.name) cfufntodisp = cfufile.name;
      if (cfdfile && cfdfile.name) cfdfntodisp = cfdfile.name;
    }

    let msg = "";

    if (pfuverifyid)
      msg = "Person fileId: " + id.person.faceup.verificationFieldId + ". ";
    else msg = "You have not yet uploaded PERSON verification file. ";

    if (cfuverifyid)
      msg =
        msg + "Company fileId: " + id.company.faceup.verificationFieldId + ". ";
    else msg = msg + "You have not yet uploaded COMPANY verification file. ";

    let idVerificationDone = false;
    let compDone = false;
    let personDone = false;
    if (this.props.fileData && this.props.fileData.company && this.props.fileData.company.faceup && this.props.fileData.company.faceup.verificationFieldId
      )  compDone = true;

      if (this.props.fileData && this.props.fileData.person && this.props.fileData.person.faceup && this.props.fileData.person.faceup.verificationFieldId
        )  personDone = true;
   
  
    console.log('compDone:', compDone, ' personDone:', personDone);
    if ( compDone && personDone) idVerificationDone = true;

    await this.setState({
      perFaceUpFile: pfufile,
      perFaceUpImagePreviewUrl: pfuiurl,
      perFaceUpFileNameToDisplay: pfufntodisp,
      perFaceDownFile: pfdfile,
      perFaceDownImagePreviewUrl: pfdiurl,
      perFaceDownFileNameToDisplay: pfdfntodisp,
      pFaceupStripeFileId: pfuverifyid,
      pFacedownStripeFileId: pfdverifyid,

      cerFaceUpFile: cfufile,
      cerFaceUpImagePreviewUrl: cfuiurl,
      cerFaceUpFileNameToDisplay: cfufntodisp,
      cerFaceDownFile: cfdfile,
      cerFaceDownImagePreviewUrl: cfdiurl,
      cerFaceDownFileNameToDisplay: cfdfntodisp,
      cFaceupStripeFileId: cfuverifyid,
      cFacedownStripeFileId: cfdverifyid,

      fileNameToDisplay: pfufntodisp,
      showmsg: msg,
      idVerificationDone
    });
    // }
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  // ---------------- File Upload Functions ------------------------------------
  onDragOver = async (evt) => {
    evt.preventDefault();
    await this.setState({ highlight: true });
  };

  onDragLeave = async () => {
    await this.setState({ highlight: false });
  };

  openFileDialog = () => {
    this.fileInputRef.current.click();
  };

  onDrop = async (event) => {
    event.preventDefault();
    // console.log("Inside onDrop : ", this.state.disabled);
    // // console.log("this.props:", this.props);
    // if (this.state.disabled) return; // investigate
    const files = event.dataTransfer.files;
    // console.log("files:", files);
    // console.log(">>>  onDrag files: ", files[0].name);
    // console.log("this.state.logoActiveFlag:", this.state.logoActiveFlag);
    if (this.state.idFaceUpFlag) {
      await this.setState({
        highlight: false,
        currFilename: files[0],
        fileNameToDisplay: files[0].name,
        perFaceUpFileNameToDisplay: files[0].name,
      });
    }
    if (this.state.idFaceDownFlag) {
      await this.setState({
        highlight: false,
        currFilename: files[0],
        fileNameToDisplay: files[0].name,
        perFaceDownFileNameToDisplay: files[0].name,
      });
    }
  };

  onFilesAdded = async (evt) => {
    // console.log("onFileAdded");
    let fullPath = evt.target.value;
    let filename;
    if (fullPath) {
      filename = fullPath.split(/(\\|\/)/g).pop();
    }

    const file = evt.target.files[0];
    // console.log("fileName:", filename);
    // console.log("===================================");
    // console.log("file:", file);
    // console.log("===================================");

    let isVerified = { status: true, msg: "" };
    // pic=0, video=1, & audio=2 -- only three kinds now & pic is active.
    isVerified = this.verifyFiles(file, this.state.uploadFileType);
    // console.log("isVerified:", isVerified);

    let smsg = "";
    if (this.state.pFaceupStripeFileId)
      smsg = "Person file_id: " + this.state.pFaceupStripeFileId + ". ";
    if (this.state.cFaceupStripeFileId)
      smsg = smsg + "Company File Id: " + this.state.cFaceupStripeFileId + ". ";
    // smsg = smsg + this.state.directionMsg;
    // const files = evt.target.files;
    if (isVerified.status) {
      // console.log("verified ... true ...");
      // console.log("this.state.uploadFileType:", this.state.uploadFileType);
      let reader = new FileReader();
      if (this.state.uploadFileType === "faceUp") {
        if (this.state.documentOfType === "person") {
          reader.onload = async (e) => {
            await this.setState({
              perFaceUpFile: file,
              perFaceUpImagePreviewUrl: reader.result,
              perFaceUpFileNameToDisplay: filename,
              fileNameToDisplay: filename,
              pictureErrFlag: false,
              pictureErrMsg: "",
              showmsgErrFlag: false,
              showmsg: smsg,
            });
          };
          reader.readAsDataURL(file);
        } else {
          reader.onload = async (e) => {
            await this.setState({
              comFaceUpFile: file,
              comFaceUpImagePreviewUrl: reader.result,
              comFaceUpFileNameToDisplay: filename,
              fileNameToDisplay: filename,
              pictureErrFlag: false,
              pictureErrMsg: "",
              showmsgErrFlag: false,
              showmsg: smsg,
            });
          };
          reader.readAsDataURL(file);
        }
      } else if (this.state.uploadFileType === "faceDown") {
        if (this.state.documentOfType === "person") {
          reader.onload = async (e) => {
            await this.setState({
              perFaceDownFile: file,
              perFaceDownImagePreviewUrl: reader.result,
              perFaceDownFileNameToDisplay: filename,
              fileNameToDisplay: filename,
              pictureErrFlag: false,
              pictureErrMsg: "",
              showmsgErrFlag: false,
              showmsg: smsg,
            });
          };
          reader.readAsDataURL(file);
        } else {
          reader.onload = async (e) => {
            await this.setState({
              comFaceDownFile: file,
              comFaceDownImagePreviewUrl: reader.result,
              comFaceDownFileNameToDisplay: filename,
              fileNameToDisplay: filename,
              pictureErrFlag: false,
              pictureErrMsg: "",
              showmsgErrFlag: false,
              showmsg: smsg,
            });
          };
          reader.readAsDataURL(file);
        }
      }
    } else {
      await this.setState({
        pictureErrFlag: true,
        publishDeactivate: true,
        pictureErrMsg: isVerified.msg,
      });
    }
  };

  verifyFiles = (file, selType) => {
    // console.log("verifyFiles file:", file, "  selType:", selType);
    let msg = "";
    let isValid = true;
    if (file && (selType === "faceUp" || selType === "faceDown")) {
      if (!picTypes.includes(file.type)) {
        isValid = false;
        msg = "Invalid file type (" + file.type + "). ";
      }
      if (file.size > maxIdPicSize) {
        isValid = false;
        msg =
          msg +
          "Your picture size (" +
          file.size / 1000 +
          "kb) is above the limit.";
      }
    }

    let retMsg;
    if (file) {
      retMsg = {
        status: isValid,
        msg: msg,
      };
    } else {
      retMsg = {
        status: false,
        msg: "File not selected.",
      };
    }

    return retMsg;
  };

  setUploadType = async (type) => {
    // console.log("upload type:", type);
    // console.log("$$$$ this.state:", this.state);
    let msg, filenametodisp;
    if (this.state.documentOfType === "person") {
      if (type === "faceup ")
        filenametodisp = this.state.perFaceUpFileNameToDisplay;
      else filenametodisp = this.state.perFaceDownFileNameToDisplay;
    } else {
      if (type === "faceup ")
        filenametodisp = this.state.comFaceUpFileNameToDisplay;
      else filenametodisp = this.state.comFaceDownFileNameToDisplay;
    }

    let smsg = "";
    if (this.state.pFaceupStripeFileId)
      smsg = "Person file_id: " + this.state.pFaceupStripeFileId + ". ";
    if (this.state.cFaceupStripeFileId)
      smsg = smsg + "Company File Id: " + this.state.cFaceupStripeFileId + ". ";
    // smsg = smsg + this.state.directionMsg;

    if (type === "faceUp") {
      msg = "Valid file types are jpeg, png of less than 5MB in size.";
      this.setState({
        faceUpActiveFlag: true,
        faceDownActiveFlag: false,
        idSide: "Upload Id Doc FACE Side",
        fileNameToDisplay: filenametodisp,
        viewActiveFlag: false,
        showmsg: smsg,
        showmsgErrFlag: false,
      });
    }
    if (type === "faceDown") {
      msg = "Valid file types are jpeg, png of less than 5MB in size.";
      this.setState({
        faceUpActiveFlag: false,
        faceDownActiveFlag: true,
        idSide: "Upload Doc BACK Side",
        fileNameToDisplay: filenametodisp,
        viewActiveFlag: false,
        showmsg: smsg,
        showmsgErrFlag: false,
      });
    }

    let dirmsg = msg + " Click info (i button) for details.";

    this.setState({
      uploadFileType: type,
      uploadBtnClicked: true,
      uploadMsg: "Click or Tap Dropzone, or Drag'n-Drop, to select a file.",
      pictureMsg: dirmsg,
    });
  };

  // --------- End of File blob uploads ---------------------------------------------

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        // message: msg,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  packagePicData = async () => {
    let id_files = {
      accId: this.props.fileData.accId,
      person: {
        personId: this.props.fileData.person.personId,
        faceup: {
          verificationFieldId: this.state.pFaceupStripeFileId,
          file: this.state.perFaceUpFile,
          fileblob: this.state.perFaceUpImagePreviewUrl,
        },
        facedown: {
          verificationFieldId: this.state.pFacedownStripeFileId, // temporarily not useing facedown ... placejolder  for future
          file: this.state.perFaceDownFile,
          fileblob: this.state.perFaceDownImagePreviewUrl,
        },
      },
      company: {
        faceup: {
          verificationFieldId: this.state.cFaceupStripeFileId,
          file: this.state.comFaceUpFile,
          fileblob: this.state.comFaceUpImagePreviewUrl,
        },
        facedown: {
          verificationFieldId: this.state.cFacedownStripeFileId,
          file: this.state.comFaceDownFile,
          fileblob: this.state.comFaceDownImagePreviewUrl,
        },
      },
    };

    console.log("id_files:", id_files);

    return id_files;
  };

  handleBackFromIdentity = async () => {
    // console.log("UpdateIdentity handle-back: ");
    let picData = await this.packagePicData();
    // console.log("handleBackFromIdentity picData:", picData);
    this.props.handleBack(picData, this.state.IdpictureSkip);
  };

  handleNextToIdentity = async () => {
    // console.log("handleNext data:");
    let picData = await this.packagePicData();
    // console.log("picData:", picData);

    this.props.handleNext(picData, this.state.IdpictureSkip);
  };

  handleViewId = async () => {
    let smsg = "";
    if (this.state.pFaceupStripeFileId)
      smsg = "Person file_id: " + this.state.pFaceupStripeFileId + ". ";
    if (this.state.cFaceupStripeFileId)
      smsg = smsg + "Company File Id: " + this.state.cFaceupStripeFileId + ". ";
    // smsg = smsg + this.state.directionMsg;
    await this.setState({
      viewActiveFlag: true,
      faceUpActiveFlag: false,
      faceDownActiveFlag: false,
      uploadBtnClicked: false,
      showmsg: smsg,
    });
  };

  handleExitView = async () => {
    await this.loadDataInStateIdentity();
    this.setState({
      viewActiveFlag: false,
      faceUpActiveFlag: true,
      faceDownActiveFlag: true,
      uploadBtnClicked: true,
      // showmsg: '',
    });
  };

  handleSkip = async () => {
    await this.setState((prevstate) => ({
      IdpictureSkip: !prevstate.IdpictureSkip,
    }));
  };

  uploadToVerifyId = async (e) => {
    // console.log("uploadToVerifyId");
    e.preventDefault();
    await this.setState({
      loadingFlag: true,
    });
    let retmsg;
    let errflg = false;
    let fupfileId = null;
    if (this.state.perFaceUpFile || this.state.comFaceUpFile) {
      const formData = new FormData();

      if (this.state.documentOfType === "person") {
        // console.log('>>> this.state.perFaceUpFile:', this.state.perFaceUpFile)
        formData.append("file", this.state.perFaceUpFile);
      } else {
        formData.append("file", this.state.comFaceUpFile);
      }

      formData.append("acc", this.props.fileData.accId);
      formData.append("communityId", this.props.communityId);
      formData.append("documentOfType", this.state.documentOfType);

      let url = baandaServer + idfilehandling;
      // console.log(">>> url:", url);
      // console.log('>>> formData:', formData);
      try {
        let retstrip = await axios.post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        // console.log("retstrip:", retstrip);

        retmsg =
          "Successfully created " +
          this.state.documentOfType +
          " verification document Id: <" +
          retstrip.data.msg +
          ">. Click Next to proceed.";
        // to be mindful, right now, we are only catching the face-up of ID verification. in future, we need to recevie information about the type (up or down).
        fupfileId = retstrip.data.msg;
      } catch (err) {
        console.log("err:", err.message);
        retmsg = "Error: " + err.message;
        errflg = true;
      }
    } else {
      retmsg = "To upload, you must select a file first.";
      errflg = true;
    }
    await this.setState({
      loadingFlag: false,
      perFaceUpFile: null,
      perFaceDownFile: null,
      showmsg: retmsg,
      showmsgErrFlag: errflg,

      perFaceUpImagePreviewUrl: null,
      perFaceUpFileNameToDisplay: "",
      fileNameToDisplay: "",

      perFaceDownImagePreviewUrl: null,
      perFaceDownFileNameToDisplay: "",
      uploadFileType: "faceUp",
      // pFaceupStripeFileId: fupfileId,
    });

    if (this.state.documentOfType === "person") {
      await this.setState({
        pFaceupStripeFileId: fupfileId,
      });
    } else {
      await this.setState({
        cFaceupStripeFileId: fupfileId,
      });
    }
  };

  handleIdTypeSelection = async (e) => {
    await this.setState({
      documentOfType: e.target.value,
    });
  };

  render() {
    console.log("updateIdentity this.state:", this.state);
    console.log("updateIdentity this.props:", this.props);
    console.log("UpdateIdentity...");

    // Spinner while uploading, getting stripe IF, updating mongodb, deleting the file, and returning.
    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div>
          <ReactLoading
            type={"spokes"}
            color={"#195670"}
            height={30}
            width={30}
          />
        </div>
      );
    } else {
      uploadingSpin = null;
    }

    // ================== File Upload Panel =============================
    // Upload Panel _______________________________________________
    let uploadpanel;
    if (this.state.uploadBtnClicked) {
      if (this.state.idVerificationDone) {
        uploadpanel = (
          <div>
            <div className="row company-id-verification-row-pos">
              <div className="col text-center company-id-verification-done-msg">
                Document verification is done. Once inspected by banking, it cannot
                be re-done.
              </div>
            </div>
            <div className="row">
              <div className="col text-center company-id-verification-done-msg">
                If you think you need to change the verification documents, then start
                again(delete this and re-enter all data.)
              </div>
            </div>
          </div>
        );
      } else {
        uploadpanel = (
          <div>
            <div className="row">
              <div className="col-6">
                <div
                  className={`dropzone ${
                    this.state.highlight ? "Highlight" : ""
                  }`}
                  onDragOver={this.onDragOver}
                  onDragLeave={this.onDragLeave}
                  onDrop={this.onDrop}
                  onClick={this.openFileDialog}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <p className="text-center top_of_card_id">File Dropzone</p>
                  <img alt="upload" className="Icon" src={cosmicDoorway} />
                  <input
                    ref={this.fileInputRef}
                    className="FileInput"
                    type="file"
                    multiple
                    onChange={this.onFilesAdded}
                  />
                </div>
              </div>
              <div className="col-6 upload_message">
                <div className="row">
                  <div className="col text-center ">
                    <p>{this.state.uploadMsg}</p>
                  </div>
                </div>
                <div className="pic_caption_msg text-center">
                  <p className="id_side">{this.state.idSide}</p>
                  <b>File:</b>&nbsp;<b>{this.state.fileNameToDisplay}</b>
                </div>
              </div>
            </div>
            <div className="cc-id-doctype-msg">
              <p align="justify">{this.state.pictureMsg}</p>
            </div>
            <div
              className={`${
                !this.state.pictureErrFlag
                  ? "save_review_msg pic_msg_placement"
                  : "save_review_msg_err pic_msg_placement"
              }`}
            >
              <p>{this.state.pictureErrMsg}</p>
            </div>
            <div className="row">
              <div className="col-6 text-center biz_updt_confirm_id">
                <Checkbox
                  checked={this.state.IdpictureSkip}
                  onChange={this.handleSkip}
                />
                &nbsp;&nbsp;Skip ID file load now.
              </div>
              <div className="col-6 text-center">
                <button
                  className="btn_reg_90"
                  type="button"
                  onClick={this.uploadToVerifyId}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Upload
                </button>
                {uploadingSpin}
              </div>
            </div>
          </div>
        );
      }
    }

    let viewPanel;
    if (this.state.viewActiveFlag) {
      viewPanel = (
        <div>
          <div className="row">
            <div className="col-6 text-center">
              {/* <div className="picture_review_card_id"> */}
              <p>Person</p>
              {this.state.perFaceUpImagePreviewUrl ? (
                <img
                  src={this.state.perFaceUpImagePreviewUrl}
                  alt="pic"
                  className="pic-preview-on-card-left"
                />
              ) : (
                <div className="view-msg">
                  {this.state.pFaceupStripeFileId ? (
                    <p align="justify">
                      The person ID verification file has already been loaded.
                    </p>
                  ) : (
                    <p align="justify">
                      You have not loaded person ID file yet.
                    </p>
                  )}
                </div>
              )}

              {/* </div> */}
            </div>
            <div className="col-6 text-center">
              {/* <div className="picture_review_card_id"> */}
              <p>Company</p>
              {this.state.comFaceUpImagePreviewUrl ? (
                <img
                  src={this.state.comFaceUpImagePreviewUrl}
                  alt="pic"
                  className="pic-preview-on-card-right"
                />
              ) : (
                <div className="view-msg">
                  {this.state.cFaceupStripeFileId ? (
                    <p align="justify">
                      The company verification file has already been loaded.
                    </p>
                  ) : (
                    <p align="justify">
                      You have not loaded company verification file yet.
                    </p>
                  )}
                </div>
              )}

              {/* </div> */}
            </div>
          </div>
          <div>
            &nbsp;
            <br />
          </div>
          <div className="row">
            <div className="col-6 text-center biz_updt_confirm_id">
              <Checkbox
                checked={this.state.IdpictureSkip}
                onChange={this.handleSkip}
              />
              &nbsp;&nbsp;Skip ID file load now.
            </div>
            <div className="col-6 text-center">
              <button
                className="btn_reg_80"
                type="button"
                onClick={this.uploadToVerify}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <b>Upload</b>
              </button>
            </div>
          </div>
        </div>
      );
    }

    let docori;
    if (this.state.deviceSize === "small") docori = "Orientation:";
    else docori = "ID Document Orientation:";

    let exitViewBtn;
    if (this.state.viewActiveFlag) {
      exitViewBtn = (
        <button
          className="btn_back_main"
          type="button"
          onClick={this.handleExitView}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
      );
    }

    let fileLoadBtn;
    fileLoadBtn = (
      <div className="file_load_id_btn_positions">
        <span>
          <b>{docori}</b>
          &nbsp;
          <button className="btn-upload-id-activexx" type="button">
            Face-Up
          </button>
          &nbsp;&nbsp;
          <button
            className={`${
              !this.state.viewActiveFlag ? "btn_reg_70_active" : "btn_reg_70"
            }`}
            type="button"
            onClick={this.handleViewId}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <b>View</b>
          </button>
          &nbsp;&nbsp;
          {exitViewBtn}
        </span>
        <div
          className={`${
            !this.state.uploadErrFlag
              ? "picture_msg text-center"
              : "picture_msg_err text-center"
          }`}
        >
          {this.state.uploadDirection}
        </div>
      </div>
    );

    // ================== End if File Upload Panel ======================
    let commButtonIdentity = (
      <div>
        <button
          onClick={this.handleBackFromIdentity}
          className="btn_reg_60"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Back
        </button>
        &nbsp;
        <button
          onClick={this.handleNextToIdentity}
          className="btn_reg_60"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Next
        </button>
        &nbsp;
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
        &nbsp;
      </div>
    );

    let inpName;
    if (this.state.deviceSize === "small") inpName = "ID Doc Upload";
    else inpName = "Verification Document Upload";

    let headerPanel = (
      <div className="row">
        <div className="col-5 header_comp_text">{inpName}</div>
        <div className="col-7 ">{commButtonIdentity}</div>
      </div>
    );

    // Shows messages at the bottom
    let bottomMsgPanel = (
      <div>
        {/* <div className="col message_text_id">{this.state.showmsg}</div> */}
        <div
          className={`${
            !this.state.showmsgErrFlag
              ? "col message_text_id"
              : "col message_text_id_err"
          }`}
        >
          {this.state.showmsg}
        </div>
        <div className="text-center cc-id-veri-gen-msg">
          {this.state.directionMsg}
        </div>
      </div>
    );

    let doctype;
    if (this.state.deviceSize === "small") doctype = "ID Doc Type: ";
    else doctype = "Select ID Document Type: ";

    let identityPanel;
    if (this.state.uploadBtnClicked) {
      identityPanel = (
        <div className="id_panel">
          <div className="row">
            <div className="col text-center radio-fonts">
              <strong>{doctype}&nbsp;&nbsp;&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="person"
                    checked={this.state.documentOfType === "person"}
                    onChange={this.handleIdTypeSelection}
                  />{" "}
                  Of-a-Person
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="company"
                    checked={this.state.documentOfType === "company"}
                    onChange={this.handleIdTypeSelection}
                  />{" "}
                  Of-a-Company
                </label>
              </div>
            </div>
          </div>

          <div className="input-up-height" />
          <div className="row">
            <div className="col person_label text-center">{fileLoadBtn}</div>
          </div>
          <div className="row">
            <div className="col person_label text-center">{uploadpanel}</div>
          </div>
          {bottomMsgPanel}
        </div>
      );
    }

    let viewtype;
    if (this.state.deviceSize === "small") {
      if (this.state.documentOfType === "company")
        viewtype = "Viewing Company Doc";
      else viewtype = "Viewing Person Id";
    } else {
      if (this.state.documentOfType === "company")
        viewtype = "Viewing company verification documet";
      else viewtype = "Viewing Person Identity document";
    }

    if (this.state.viewActiveFlag) {
      identityPanel = (
        <div>
          <div className="input-up-height" />
          <div className="row">
            <div className="col person_label text-center">
              <b>{viewtype}</b>
            </div>
          </div>
          <div className="row">
            <div className="col person_label text-center">{fileLoadBtn}</div>
          </div>
          <div className="row">
            <div className="col person_label text-center">{viewPanel}</div>
          </div>
          {bottomMsgPanel}
        </div>
      );
    }

    let outputPanel;

    outputPanel = (
      <div>
        {headerPanel}
        <div className="company_input_box new-update-identity-box">
          {identityPanel}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

UpdateIdentity.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpdateIdentity));
