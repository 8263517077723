import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
// import { PropTypes } from "prop-types";
import controllable from "react-controllables";

import EventOnHover from "./utils/EventOnHover";

import "./MainMap.css";

const K_SIZE = 40;
const googleapikey = process.env.REACT_APP_GOOGLE_API_KEY;

const MainMap = controllable(["center", "zoom", "hoverKey", "clickKey"])(
  class MainMap extends Component {

    constructor(props) {
      super(props);
      this.state = {
        pointsOfIntersts: this.props.data,
      };
    }

    componentDidMount = async () => {};

    static defaultProps = {
      zoom: 13,
    };

    _onChange = (center, zoom /* , bounds, marginBounds */) => {
      this.props.onCenterChange(center);
      this.props.onZoomChange(zoom);
    };

    _onChildMouseEnter = (key /*, childProps */) => {
      this.props.onHoverKeyChange(key);
    };

    _onChildMouseLeave = (/* key, childProps */) => {
      this.props.onHoverKeyChange(null);
    };

    render() {
      // In real app, get this value from database ... with coordinate of
      // start-to work address provided in the posting by the candidate.
      // console.log("MainMap this.props:", this.props);
      // console.log("MainMao this.state:", this.state);
      console.log('MainMap...');

      let dist = this.props.distance;
      let zoom = 13;

      let jdno = 0;

      if ( dist ) {
          let dstf = dist.indexOf("ft");
          if ( dstf === -1) dist.indexOf("mi");
          // console.log('dstf: ', dstf);
          if ( parseFloat(dstf) > 0 ) {
              jdno = 10000
          } else {
            let indexSpace = dist.indexOf(" ")
            let jds = dist.substring(0, indexSpace);
            // let xx = dist.substring(0,4);
  
            jdno = parseFloat(jds);  
          }
          // console.log('jdno: ', jdno);
      }

      let jdnoNum = parseFloat(jdno);
 
      if ( jdnoNum < 1) zoom = 16;
      else if ( jdnoNum > 1 && jdnoNum <= 10 ) zoom = 14
      else if ( jdnoNum > 10 && jdnoNum <= 20 ) zoom = 12
      else if ( jdnoNum > 20 && jdnoNum <= 40 ) zoom = 10
      else if ( jdnoNum > 40 && jdnoNum <= 100 ) zoom = 8
      else if ( jdnoNum > 100 && jdnoNum <= 300 ) zoom = 6
      else if ( jdnoNum > 300 && jdnoNum <= 600 ) zoom = 4
      else zoom = 10;

      // console.log('zoom: ', zoom);

      let gapi = {
        key: googleapikey,
        language: "en",
      };

      let center = {
          lat: 0,
          lng: 0
      }

      if (this.state.pointsOfIntersts.length > 0 ) {
        this.state.pointsOfIntersts.forEach((obj) => {
            // console.log('obj:', obj);
          if (obj.id === "2") {
            center = {
              lat: parseFloat(obj.lat),
              lng: parseFloat(obj.lng),
            };
          }
        });
      }

      // console.log('center:', center);

      const places = this.state.pointsOfIntersts.map((obj) => {
 
        const { id, shortDescription, intelForBubble, ...coords } = obj;
 
        return (
          <EventOnHover
            key={id}
            {...coords}
            id={obj.id === "1" ? "Y" : "C"}
            text={shortDescription}
            intel={intelForBubble}
            hover={this.props.hoverKey === obj.id}
          />
        );
      });

      return (
        <div>
          <div className="row">
            <div className="col">
              <div
                // style={{ height: "65vh", width: "80%" }}
                // className="map_sticky"
                className="map-box-five"
              >
                <GoogleMapReact
                  //   bootstrapURLKeys={googleapikey}
                  bootstrapURLKeys={gapi}
                  center={center}
                  defaultZoom={zoom}
                  hoverDistance={K_SIZE / 2}
                  onChange={this._onChange}
                  onChildClick={this._onChildClick}
                  onChildMouseEnter={this._onChildMouseEnter}
                  onChildMouseLeave={this._onChildMouseLeave}
                >
                  {places}
                </GoogleMapReact>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
);

// onBoundsChange={this._onBoundsChange}

export default MainMap;
