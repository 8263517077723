import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { hideModal } from "../../../../actions/modalActions";
import "../../../css/localModal.css";
import "./InfoModal.css";

class InfoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todotext: "",
    };

    this.closeModal = this.closeModal.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  closeModal(e) {
    e.preventDefault();
    // console.log("closeModal.func : " + this.state.todotext);
    const modTask = {
      todotext: this.state.todotext,
    };

    this.props.hideModal(modTask);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    // console.log("props InfoModal:" + JSON.stringify(this.props.message));
    // console.log('InfoModal..');
    const header = this.props.message.Header;

    // const steps = this.props.message.Body.steps;
    // console.log("steps len:", steps.length);

    let summary,
      step1,
      step1Msg,
      step2,
      step2Msg,
      step3,
      step3Msg,
      step4,
      step4Msg,
      step5,
      step5Msg,
      step6,
      step6Msg,
      step7,
      step7Msg,
      step8,
      step8Msg,
      step9,
      step9Msg,
      step10,
      step10Msg,
      footnote;
    if (this.props.message.Body.oneLineSummary) {
      summary = this.props.message.Body.oneLineSummary;
    }
    if (this.props.message.Body.steps[0]) {
      step1 = this.props.message.Body.steps[0].step;
      step1Msg = this.props.message.Body.steps[0].stepNote;
    }
    if (this.props.message.Body.steps[1]) {
      step2 = this.props.message.Body.steps[1].step;
      step2Msg = this.props.message.Body.steps[1].stepNote;
    }
    if (this.props.message.Body.steps[2]) {
      step3 = this.props.message.Body.steps[2].step;
      step3Msg = this.props.message.Body.steps[2].stepNote;
    }
    if (this.props.message.Body.steps[3]) {
      step4 = this.props.message.Body.steps[3].step;
      step4Msg = this.props.message.Body.steps[3].stepNote;
    }
    if (this.props.message.Body.steps[4]) {
      step5 = this.props.message.Body.steps[4].step;
      step5Msg = this.props.message.Body.steps[4].stepNote;
    }
    if (this.props.message.Body.steps[5]) {
      step6 = this.props.message.Body.steps[5].step;
      step6Msg = this.props.message.Body.steps[5].stepNote;
    }
    if (this.props.message.Body.steps[6]) {
      step7 = this.props.message.Body.steps[6].step;
      step7Msg = this.props.message.Body.steps[6].stepNote;
    }
    if (this.props.message.Body.steps[7]) {
      step8 = this.props.message.Body.steps[7].step;
      step8Msg = this.props.message.Body.steps[7].stepNote;
    }
    if (this.props.message.Body.steps[8]) {
      step9 = this.props.message.Body.steps[8].step;
      step9Msg = this.props.message.Body.steps[8].stepNote;
    }
    if (this.props.message.Body.steps[9]) {
      step10 = this.props.message.Body.steps[9].step;
      step10Msg = this.props.message.Body.steps[9].stepNote;
    }

    footnote = this.props.message.Body.footnote;

    return (
      <div className="container">
        <div className="modal-content-z">
          <div className="row text-center justify-content-center">
            <div className="modal-header-text-info">{header}</div>
          </div>

          <div className="modal-body">
            <div className="fixedsize-info-modal">
              <div className="row">
                <div className="col-12">
                  <div className="start-here-msg-info notes-text-info">
                    <font color="white">
                      <p align="justify">{summary}</p>
                    </font>
                    <hr />
                    <font color="#ededd1">
                      <p align="justify">
                        <b>{step1}</b>&nbsp;
                        {step1Msg}
                      </p>
                      {step2 ? (
                        <p align="justify">
                          <b>{step2}</b>&nbsp;
                          {step2Msg}
                        </p>
                      ) : null}
                      {step3 ? (
                        <p align="justify">
                          <b>{step3}</b>&nbsp;
                          {step3Msg}
                        </p>
                      ) : null}
                      {step4 ? (
                        <p align="justify">
                          <b>{step4}</b>&nbsp;
                          {step4Msg}
                        </p>
                      ) : null}
                      {step5 ? (
                        <p align="justify">
                          <b>{step5}</b>&nbsp;
                          {step5Msg}
                        </p>
                      ) : null}
                      {step6 ? (
                        <p align="justify">
                          <b>{step6}</b>&nbsp;
                          {step6Msg}
                        </p>
                      ) : null}
                      {step7 ? (
                        <p align="justify">
                          <b>{step7}</b>&nbsp;
                          {step7Msg}
                        </p>
                      ) : null}
                      {step8 ? (
                        <p align="justify">
                          <b>{step8}</b>&nbsp;
                          {step8Msg}
                        </p>
                      ) : null}
                      {step9 ? (
                        <p align="justify">
                          <b>{step9}</b>&nbsp;
                          {step9Msg}
                        </p>
                      ) : null}
                      {step10 ? (
                        <p align="justify">
                          <b>{step10}</b>&nbsp;
                          {step10Msg}
                        </p>
                      ) : null}
                      
                    </font>
                  </div>

                  <hr className="format" />
                  <div className="notes-text">
                    {/* <div className="start-here-msg notes-text"> */}
                    <font color="#abd9ed">
                      <p align="justify">
                        <b>Note :&nbsp;</b>
                        <font color="white">{footnote}</font>
                      </p>
                    </font>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.closeModal}
              onChange={this.onChange}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <strong>Close</strong> &nbsp;
              <div className="float-right">
                <i className="far fa-window-close" />
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

InfoModal.propTypes = {
  hideModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { hideModal })(InfoModal);
