import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import { sortArrayOfObjects } from "../../../../../../utils/sortArrayOfObjects";

import "./ComposePackage.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCategories = "/routes/dashboard/getCatagories?";
const saveItemMasterComp = "/routes/dashboard/saveItemMasterComp";
const getCompToShipItems = "/routes/dashboard/getCompToShipItems?";
const getACatalogItem = "/routes/dashboard/getACatalogItem?";
const deleteDigiFileFromDB = "/routes/dashboard/deleteDigiFileFromDB";
const deleteAnItemFromMaster = "/routes/dashboard/deleteAnItemFromMaster";

const readMeCode = "0000000000";

class ComposePackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invClassification: "catalog",
      itemType: "goods",
      functionType: "new",
      catOpsType: "select",

      itemId: 0,

      itemName: "",
      itemDescription: "",
      categoryName: "",
      prevCatagoryName: "",
      customQuotingFee: 0.0,
      unitName: "",

      categories: [],

      categoryNameMsg: "Category not specified (mandatory)",

      serviceType: "scheduled",
      scheduleType: "selected",

      deviceSize: "",
      loadingFlag: false,
      catSave: false,

      compMsg: "",
      compMsgFlag: false,

      showEditFlag: false,

      itemNameToSelect: "",

      editItems: [],
      editMsg: "",
      editErrFlag: false,
      fetchFlag: false,

      attachImageFlag: false,
      showItemMasterPanelFlag: true,

      itemSubDirName: "",
      imageS3FileData: null,

      selectedItemToEdit: null,

      canSellFrac: "no",
      canSellFraction: false,

      canDeleteItemFlag: false,
      monthlyShippingCost: 0,

      // ----------------------
      categoryQuantity: 1,
      categorySelected: "",
      itemComposition: [],
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    this.setState({
      deviceSize,
      //   loadingFlag: true,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChangeQuantity = async (e) => {
    let value = e.target.value;
    // await this.setState({ [e.target.name]: e.target.value });
    // console.log("e.target.value:", value);

    if (this.countDecimals(value) > 0) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        itemPrice: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  onChangeCurrency = async (e) => {
    let value = e.target.value;
    // await this.setState({ [e.target.name]: e.target.value });
    // console.log("e.target.value:", value);

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        itemPrice: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  returnToInvMgmt = () => {
    this.props.returnToCaller();
  };

  getCategories = async () => {
    // console.log("getCategories ...........");
    let params =
      "communityId=" +
      this.props.communityId +
      "&categoryOf=item&invClassification=" +
      this.state.invClassification +
      "&coopStoreId=" +
      this.props.coopStoreId +
      "&itemType=" +
      this.state.itemType +
      "&categoryName=" +
      this.state.categoryName;
    let url = baandaServer + getCategories + params;
    // console.log("********** getCategoris url:", url);
    try {
      let catret = await axios.get(url);
      // console.log("catret:", catret);
      if (catret.data.status === "success") {
        if (catret.data.Msg.length > 0) {
          let sorted = await sortArrayOfObjects(
            catret.data.Msg,
            "label",
            "dsc"
          );
          // console.log("sorted:", sorted);
          this.setState({
            categories: sorted,
            compMsg: "",
            compMsgFlag: false,
          });
        } else {
          this.setState({
            compMsg: `Add ${this.state.invClassification} category first to select.`,
            compMsgFlag: true,
            updateMsg: "",
            categories: [],
          });
        }
      } else {
        this.setState({
          compMsg: catret.data.Msg,
          compMsgFlag: true,
        });
      }
    } catch (err) {
      console.log("Error:", err.message);
      this.setState({
        invOrgErrMsg: err.message,
        invOrgErrFlag: true,
      });
    }
  };

  handleFunctionType = async (e) => {
    let value = e.target.value;
    // console.log("handleFunctionType value:", value);
    let showEditFlag = false;
    if (value === "edit") showEditFlag = true;
    this.setState({
      functionType: e.target.value,
      showEditFlag,
      canDeleteItemFlag: false,
    });

    await this.clearItemMasterForm("other");
  };

  clearItemMasterForm = async (inp) => {
    // console.log("clearItemMasterForm...");
    let compMsg = "",
      compMsgFlag = false;
    if (inp === "postsave") {
      compMsg = this.state.compMsg;
      compMsgFlag = this.state.compMsgFlag;
    }

    this.setState({
      // functionType: "new",
      editItems: [],
      fetchFlag: false,
      itemId: 0,
      itemName: "",
      itemDescription: "",
      categoryName: "",
      prevCatagoryName: "",
      customQuotingFee: 0.0,
      unitName: "",
      categories: [],
      categoryNameMsg: "Category not specified (mandatory)",
      serviceType: "scheduled",
      scheduleType: "selected",
      compMsg,
      compMsgFlag,
      itemNameToSelect: "",
      attachImageFlag: false,
      itemSubDirName: "",
      imageS3FileData: null,
      selectedItemToEdit: null,
      itemComposition: [],
    });
  };

  exitEditPanel = async () => {
    await this.setState({
      functionType: "new",
      showEditFlag: false,
      fetchFlag: false,
    });
  };

  handleCategory = async () => {
    let catname = this.state.categoryName;
    // console.log("catName:", catname);
    // let catSave = false;
    this.setState({
      showCategoryFlag: true,
      prevCatagoryName: catname,
      categorySelected: catname,
      catOpsType: "new",
      catSave: true,
      canDeleteItemFlag: false,
    });
  };

  exitCategory = async () => {
    let catName = this.state.categoryName;
    // console.log("1. catName: ", catName);
    // console.log("this.state.catSave:", this.state.catSave);
    if (!this.state.catSave) catName = this.state.prevCatagoryName;
    // console.log("2. catName: ", catName);
    this.setState({
      showCategoryFlag: false,
      categoryName: catName,
    });
  };

  handleCatOpsType = async () => {
    // let value = e.target.value;
    // // console.log("handleCatOpsType value:", value);
    // if (value === "select")
    await this.getCategories();
    this.setState({
      catOpsType: "select",
    });
  };

  handleCatOpsTypeClear = () => {
    this.setState({
      catOpsType: "new",
      categoryName: "",
    });
  };

  selectThisCategory = async (obj) => {
    // console.log("obj:", obj);
    this.setState({
      categoryName: obj.label,
      catOpsType: "new",
    });
  };

  handleCategorySave = async () => {
    let categorySelected = this.state.categoryName;
    this.setState({
      handleCategorySave: false,
      categorySelected,
      categoryName: "",
      showCategoryFlag: false,
      catOpsType: "new",
    });
    await this.exitCategory();
  };

  checkIfInCatalog = async (itemId) => {
    // console.log("checkIfInCatalog itemId:", itemId);
    let param = "itemId=" + itemId;
    let url = baandaServer + getACatalogItem + param;
    try {
      let catret = await axios.get(url);
      if (catret.data.status === "success") {
        if (!catret.data.Msg) {
          this.setState({
            canDeleteItemFlag: true,
          });
        }
      }
    } catch (err) {
      this.setState({
        compMsg: err.message,
        compMsgFlag: true,
      });
    }
  };

  selectThisItemToedit = async (obj) => {
    // console.log("selectThisItemToedit obj:", obj);
 
    await this.checkIfInCatalog(obj.itemId);
    this.setState({
      showEditFlag: false,
      itemName: obj.itemName,
      itemId: obj.itemId,
      itemDescription: obj.itemDescription,
      categoryName: obj.category,
      unitName: obj.unitName,
      itemSubDirName: obj.subBucket,
      serviceType: obj.service.type,
      scheduleType: obj.service.scheduleType,
      customQuotingFee: obj.service.customQuotingFee,
      // imageS3FileData,
      selectedItemToEdit: obj,
      // canSellFraction: obj.canSellFraction,
      // canSellFrac,
      monthlyShippingCost: obj.pkgComposedShippingCost,
      itemComposition: obj.pkgComposedOfItems,
    });
    // Load items to edit
  };

  randfn = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  setUploadType = async (imgType) => {
    // console.log("imgType:", imgType);
    await this.setState({
      attachImageFlag: false,
    });

    let randomct, commrn, subBucket;
    if (this.state.itemSubDirName === "") {
      randomct = this.randfn(100000000, 999999999);
      commrn = randomct.toString(16);
      subBucket =
        "itemmaster-cid" +
        this.props.communityId +
        "-itemId" +
        this.state.itemId +
        "-da-" +
        commrn;
    } else subBucket = this.state.itemSubDirName;

    switch (imgType) {
      case "image":
        await this.setState({
          attachImageFlag: true,
        });
        break;

      default:
        console.log("default");
    }

    // console.log(
    //   " setUploadType this.state.imageS3FileData:",
    //   this.state.imageS3FileData
    // );
    // console.log(" setUploadType subBucket:", subBucket);

    await this.setState({
      itemSubDirName: subBucket,
      showItemMasterPanelFlag: false,
    });
    // console.log("this.state.itemSubDirName:", this.state.itemSubDirName);
  };

  returnToUpload = async (fileData) => {
    // console.log("returnToUpload this.state:", this.state);
    let handledAssetType = "";
    if (this.state.attachImageFlag) handledAssetType = "image";

    // console.log("Check S3 fileData: ", fileData);
    if (!fileData.s3FileData) {
      // console.log("In here ... why? fileData.s3FileData:", fileData.s3FileData);
      await this.setState({
        attachImageFlag: false,
      });
    }
    let imageS3FileData = this.state.imageS3FileData;

    // let imageExists = this.state.imageExists;
    if (this.state.attachImageFlag) {
      // console.log("in here  2222");
      if (fileData.ifDeletedFlag) {
        imageS3FileData = null;
        await this.deleteDigiFileFromDb(fileData, "image");
      }
      if (fileData.s3FileData.key !== "") {
        // console.log("fileData.s3FileData:", fileData.s3FileData);
        imageS3FileData = {
          key: fileData.s3FileData.key,
          type: "image",
          location: fileData.s3FileData.location,
          caption: this.state.itemName,
        };
        await this.uploadFileInDB(imageS3FileData, "image");
      }
    }

    if (fileData.ifDeletedFlag) {
      await this.deleteDigiFileFromDb(fileData, handledAssetType);
      if (handledAssetType === "image") imageS3FileData = null;
    }

    await this.setState({
      attachImageFlag: false,
      openButtonPanel: true, // ????? is this needed?
      imageS3FileData,
      showItemMasterPanelFlag: true,
    });
  };

  deleteDigiFileFromDb = async (fileToDelete, type) => {
    // console.log("FileToDelete:", fileToDelete, " type:", type);
    // let eventSubDirName = this.state.eventSubDirName;
    let url = baandaServer + deleteDigiFileFromDB;
    let inpData;
    let imageS3FileData = this.state.imageS3FileData;

    if (type === "image") {
      inpData = {
        communityId: this.props.communityId,
        itemId: this.state.itemId,
        type: "image",
        caller: "item",
      };
    }

    // console.log("file deleted. url:", url);
    // console.log("file deleted inpData:", inpData);
    try {
      let retdel = await axios.post(url, inpData);
      // console.log("zz retdel:", retdel);
      if (retdel.data.status === "success") {
        if (type === "image") {
          imageS3FileData = null;
        }

        await this.setState({
          imageS3FileData,
        });
      }
    } catch (err) {
      console.log("Err: ", err.message);
      this.setState({
        compMsg: err.message,
        compMsgFlag: true,
      });
    }
  };

  validateInput = async () => {
    let isValid = true;
    let msg = "";
    if (this.state.itemName === "") {
      isValid = false;
      msg = msg + "Item must have a name. | ";
    }
    if (this.state.itemDescription === "") {
      isValid = false;
      msg = msg + "Item must have a description. | ";
    }

    if (this.state.itemComposition.length === 0) {
      isValid = false;
      msg = msg + "Must have at least one composition | ";
    } else {
      let qtyOk = true;
      this.state.itemComposition.forEach((elm) => {
        if (elm.quantity === 0) qtyOk = false;
      });
      if (!qtyOk) {
        isValid = false;
        msg = msg + "Quantity in a category line must be positive. | ";
      }
    }

    return { status: isValid, Msg: msg };
  };

  packInputForDB = async () => {
    let unitName = "Package";

    let itemVariance = [
      {
        seqNo: 1,
        name: this.state.itemName,
        options: "",
        quantity: 0,
        lastCost: 0,
        movingAvgCost: 0,
        image: null,
        opsState: true,
        inCatalog: false,
      },
    ];

    let input = {
      opsModeType: this.state.functionType,
      itemId: this.state.itemId,
      communityId: this.props.communityId,
      coopStoreId: this.props.coopStoreId,
      coopStoreName: this.props.coopStoreName,
      categoryOf: "catalog",
      itemType: "goods",
      category: "Composed Package",
      itemName: this.state.itemName,
      itemDescription: this.state.itemDescription,
      unitName,
      subBucket: "",
      baandaId: this.props.auth.user.baandaId,
      itemVariance,
      pkgComposedShippingCost: parseFloat(this.state.monthlyShippingCost),
      clientProgram:
        "dashboard/store/inventory/subscriptionComposition/ComposePackage.js",
      clientFunction: "handleItemMasterComposeSave",
      canSellFraction: false,
      pkgComposedOfItems: this.state.itemComposition,
    };

    return input;
  };

  handleItemMasterComposeSave = async () => {
    let isValid = await this.validateInput();
    if (isValid.status) {
      let url = baandaServer + saveItemMasterComp;
      let input = await this.packInputForDB();

      // console.log("handleItemMasterComposeSave input:", input);
      try {
        let retitem = await axios.post(url, input);
        // console.log("retitem.data:", retitem.data);
        if (retitem.data.status === "success") {
          await this.setState({
            compMsg: "Successfully Updated",
            compMsgFlag: false,
            itemId: retitem.data.Msg,
            functionType: "new",
          });
          await this.clearItemMasterForm("postsave");
        } else {
          await this.setState({
            compMsg: retitem.data.Msg,
            compMsgFlag: true,
          });
        }
      } catch (err) {
        // console.log("err:", err.message);
        await this.setState({
          compMsg: err.Msg,
          compMsgFlag: true,
        });
      }
    } else {
      this.setState({
        compMsg: "Error: " + isValid.Msg,
        compMsgFlag: true,
      });
    }
  };

  fetchItemsToEdit = async () => {
    // await this.setState({ fetchFlag: true });
    let coopStoreId = 0;
    if (this.props.coopStoreId) coopStoreId = this.props.coopStoreId;
    try {
      let params =
        "communityId=" +
        this.props.communityId +
        "&coopStoreId=" +
        coopStoreId +
        "&invClassification=" +
        this.state.invClassification +
        "&itemType=" +
        this.state.itemType +
        "&itemName=" +
        this.state.itemNameToSelect;
      let url = baandaServer + getCompToShipItems + params;
      // console.log("url:", url);
      let retitems = await axios.get(url);
      // console.log("retitems:", retitems);
      if (retitems.data.status === "success") {
        // console.log("success");
        if (retitems.data.Msg.length > 0) {
          this.setState({
            editItems: retitems.data.Msg,
            editMsg: "",
            editErrFlag: false,
            fetchFlag: false,
          });
        } else {
          this.setState({
            editItems: retitems.data.Msg,
            editMsg: "No items found. ",
            editErrFlag: true,
            fetchFlag: true,
          });
        }
      } else {
        // console.log("error1");
        this.setState({
          editItems: [],
          editMsg: retitems.data.Msg,
          editErrFlag: true,
          fetchFlag: true,
        });
      }
    } catch (err) {
      // console.log("error1");
      this.setState({
        editItems: [],
        editMsg: err.message,
        editErrFlag: true,
        fetchFlag: true,
      });
    }
  };

  handleCanSellFracType = async (e) => {
    let canSellFraction = false;
    let canSellFrac = e.target.value;
    if (canSellFrac === "yes") canSellFraction = true;
    await this.setState({
      // canSellFrac: e.target.value,
      canSellFrac,
      canSellFraction,
      canDeleteItemFlag: false,
    });
  };

  handleDeleteItem = async () => {
    // console.log("will delete item :", this.state.itemId);
    let inp = {
      itemId: this.state.itemId,
    };
    let url = baandaServer + deleteAnItemFromMaster;
    try {
      let delret = await axios.post(url, inp);
      if (delret.data.status === "success") {
        this.setState({
          invClassification: "catalog",
          itemType: "goods",
          functionType: "new",
          catOpsType: "new",
          compMsg: "Successfully deleted",
          compMsgFlag: false,
          showEditFlag: false,
          itemName: "",
          itemId: 0,
          itemDescription: "",
          categoryName: "",
          unitName: "Package",
          itemSubDirName: "",
          selectedItemToEdit: null,
          categoryQuantity: 1,
          categorySelected: "",
          itemComposition: [],
        });
      } else {
        this.setState({
          compMsg: delret.data.Msg,
          compMsgFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        compMsg: err.message,
        compMsgFlag: true,
      });
    }
  };

  handleCatQtyAdd = async () => {
    // console.log("handleCatQtyAdd...");
    let isValid = true;
    let msg = "";

    if (this.state.categorySelected === "") {
      isValid = false;
      msg = "Must select a category.";
    } else if (parseFloat(this.state.categoryQuantity) <= 0) {
      isValid = false;
      msg = "Quantity must be positive.";
    } else {
      this.state.itemComposition.forEach((obj) => {
        if (obj.category === this.state.categorySelected) {
          isValid = false;
          msg = "Category, in a composition, cannot be a duplicate.";
        }
      });
    }
    if (isValid) {
      let catCompObj = {
        category: this.state.categorySelected,
        quantity: parseFloat(this.state.categoryQuantity),
      };
      let nowItemComposition = [...this.state.itemComposition];
      nowItemComposition.push(catCompObj);
      this.setState({
        itemComposition: nowItemComposition,
        categorySelected: "",
        categoryQuantity: 1,
        compMsg: "",
        compMsgFlag: false,
      });
    } else {
      this.setState({
        compMsg: msg,
        compMsgFlag: true,
      });
    }
  };

  deleteThisLine = (obj) => {
    // console.log("obj:", obj);
    let edComp = [];
    this.state.itemComposition.forEach((it) => {
      if (it.category !== obj.category) edComp.push(obj);
    });

    this.setState({
      itemComposition: edComp,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ComposePackage...");

    let exitButn = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToInvMgmt}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let addCatQtyBtn = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={this.handleCatQtyAdd}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Add
      </button>
    );

    // ================================================

    let iname;
    if (this.state.deviceSize === "small") {
      iname = "iName";
    } else {
      iname = "Item Name";
    }
    let bool = true;

    let toEditItemsListPanel;
    if (this.state.showEditFlag) {
      if (this.state.editItems.length > 0) {
        toEditItemsListPanel = (
          <div className="fixedsize-items-toedit-list">
            {this.state.editItems.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-9 text-left item-category-label">
                      {obj.itemName}&nbsp;|&nbsp;Cat:{obj.category}
                    </div>
                    <div className="col-3 text-center">
                      <button
                        className="btn_reg_40"
                        type="button"
                        onClick={() => this.selectThisItemToedit(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-check" />
                      </button>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        );
      } else {
        if (this.state.fetchFlag) {
          // console.log("I was here fetchFlag: ", this.state.fetchFlag);
          toEditItemsListPanel = (
            <div className="text-center no-items-to-edit">
              No items found for the Classification ={" "}
              {this.state.invClassification}
              ,&nbsp;Item Type = {this.state.itemType}, and Item Name filter
              =&nbsp;{this.state.itemNameToSelect}.
            </div>
          );
        }
      }
    }

    let selectToEditPanel;
    if (this.state.showEditFlag) {
      selectToEditPanel = (
        <div className="show-comp-item-edit-box">
          <div className="text-center item-select-panel-header">
            Select Item to Edit &nbsp;
            <button
              className="btn_back_main"
              type="button"
              onClick={this.exitEditPanel}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
          <div className="row toedit-name-row">
            <div className="col-3 text-right toedit-name-lbl">{iname}</div>
            <div className="col-9 text-left">
              <input
                name="itemNameToSelect"
                type="text"
                value={this.state.itemNameToSelect}
                onChange={this.onChange}
                size="75"
                maxLength="75"
                className="item-edit-name"
                placeholder="Part/full item name ..."
              />{" "}
              &nbsp;{" "}
              <button
                className="btn_reg_50"
                type="button"
                onClick={this.fetchItemsToEdit}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Fetch
              </button>
            </div>
          </div>
          {toEditItemsListPanel}
        </div>
      );
    }

    let categoryBtn;
    categoryBtn = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.handleCategory}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Category
      </button>
    );

    let catNameEntryPanel;
    catNameEntryPanel = (
      <div className="row inv-org-row-spacing">
        <div className="col-3 text-right inv-org-lbl">Category</div>
        <div className="col-9 text-left">
          <input
            name="categoryName"
            type="text"
            value={this.state.categoryName}
            onChange={this.onChange}
            size="50"
            maxLength="50"
            className="org-category-name"
            placeholder="Enter new category name"
          />
        </div>
      </div>
    );

    // For selecting existing category ... do it after save.
    let catSelectPanel;
    if (this.state.catOpsType === "select") {
      if (this.state.categories.length > 0) {
        catSelectPanel = (
          <div className="fixedsize-sel-cat-box">
            {this.state.categories.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-9 text-left item-category-label">
                      {obj.label}
                    </div>
                    <div className="col-3 text-center">
                      <button
                        className="btn_reg_40"
                        type="button"
                        onClick={() => this.selectThisCategory(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-check" />
                      </button>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        );
      } else {
        catSelectPanel = (
          <div className="text-center no-categories-defined">
            Please create a new category for this asset.
          </div>
        );
      }
    }

    let categoryPanel;
    if (this.state.showCategoryFlag) {
      categoryPanel = (
        <div className="comp-item-cat-box">
          <div className="item-cat-header">
            Specify Item's Category&nbsp;&nbsp;
            <button
              className="btn_back_main"
              type="button"
              onClick={this.exitCategory}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
          <div className="text-center function-type-row">
            <button
              className="btn_reg_70"
              type="button"
              onClick={this.handleCatOpsType}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Select
            </button>
            <button
              className="btn_reg_50"
              type="button"
              onClick={this.handleCatOpsTypeClear}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Clr
            </button>
            <button
              className="btn_reg_60"
              type="button"
              onClick={this.handleCategorySave}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Done
            </button>
          </div>

          {this.state.catOpsType === "new" ? catNameEntryPanel : catSelectPanel}
        </div>
      );
    }

    let itemMasterInputPanel;
    itemMasterInputPanel = (
      <div className="text-center comp-pkg-mstr-pos">
        <div className="row">
          <div className="col-6">
            <div className="row inv-org-row-spacing">
              <div className="col-3 text-right inv-org-lbl">Item Name</div>
              <div className="col-9 text-left">
                <input
                  name="itemName"
                  type="text"
                  value={this.state.itemName}
                  onChange={this.onChange}
                  size="75"
                  maxLength="75"
                  className="org-category-name"
                  placeholder="Enter an item name ..."
                />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="row inv-org-row-spacing">
              <div className="col-3 text-right inv-org-lbl">Description</div>
              <div className="col-9 text-left">
                <textarea
                  name="itemDescription"
                  maxLength="500"
                  placeholder="Describe your item"
                  rows="2"
                  wrap="hard"
                  spellCheck="true"
                  className="inv-org-describe-pkg"
                  onChange={this.onChange}
                  value={this.state.itemDescription}
                  required
                />
              </div>
            </div>
          </div>
        </div>
        {selectToEditPanel}
      </div>
    );

    let theButtonPanel;
    theButtonPanel = (
      <div className="text-center itemmaster-button-row">
        {!this.state.showEditFlag ? (
          <button
            className="btn_reg_70"
            type="button"
            onClick={this.handleItemMasterComposeSave}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Save
          </button>
        ) : null}

        {this.state.canDeleteItemFlag ? (
          <button
            className="btn_exit_del"
            type="button"
            onClick={this.handleDeleteItem}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fa fa-trash" />
          </button>
        ) : null}
      </div>
    );

    let infoOpsPanel = (
      <div className="row">
        <div className="col-6 sub-pkg-classification">
          Classification : Catalog || Type: Goods || Unit: Package
        </div>
        <div className="col-6 sub-pkg-classification">
          <div className="col text-center radio-catlog-fonts">
            <strong>Operation Type: &nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="budget-ops"
                  type="radio"
                  value="new"
                  checked={this.state.functionType === "new"}
                  onChange={this.handleFunctionType}
                />{" "}
                New
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="budget-ops"
                  type="radio"
                  value="edit"
                  checked={this.state.functionType === "edit"}
                  onChange={this.handleFunctionType}
                />{" "}
                Edit
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let catgoryQuantityPanel;
    catgoryQuantityPanel = (
      <div className="item-category-add-box">
        <div className="row">
          <div className="col-2 text-center sub-pkg-lbl">{categoryBtn}</div>
          <div className="col-4 text-center sub-pkg-txt">
            {this.state.categorySelected}
          </div>
          <div className="col-6 sub-pkg-lbl">
            Quantity &nbsp;
            <input
              name="categoryQuantity"
              type="number"
              min="1"
              className="sub-pkg-cat-qty"
              onChange={this.onChangeQuantity}
              value={this.state.categoryQuantity}
              placeholder="Minimum quote fee (if any)"
              step="1"
            />
          </div>
        </div>
        {categoryPanel}
        <div className="row">
          <div className="col-8 text-center">&nbsp;</div>
          <div className="col-4">{addCatQtyBtn}</div>
        </div>
      </div>
    );

    let compositionPanel;
    let bool1 = true;
    compositionPanel = (
      <div className="sub-comp-list-pos">
        <div className="row">
          <div className="col-2 text-center sub-pkg-lbl">Ops</div>
          <div className="col-6 text-center sub-pkg-lbl">Category</div>
          <div className="col-4 text-center sub-pkg-lbl">Ops</div>
        </div>
        <div className="item-sub-composition-box">
          {this.state.itemComposition.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    bool1
                      ? "row row-col-sub-comp-dr"
                      : "row row-col-sub-comp-lt"
                  }
                >
                  <div className="col-2 text-center">
                    <button
                      className="btn_exit_del"
                      type="button"
                      onClick={() => this.deleteThisLine(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                  <div className="col-6 text-center  ">{obj.category}</div>
                  <div className="col-4 text-center  ">{obj.quantity}</div>
                </div>
                {(bool1 = !bool1)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let getMonthyShippingPanel;
    getMonthyShippingPanel = (
      <div className="row">
        <div className="col-4 text-right sub-pkg-lbl-ship">Monthly Shipping Cost</div>
        <div className="col-8 text-left ">
          $ <input
            name="monthlyShippingCost"
            type="number"
            min="1"
            className="sub-pkg-ship-amt"
            onChange={this.onChangeCurrency}
            value={this.state.monthlyShippingCost}
            placeholder="0.00"
            step=".01"
          />
        </div>
      </div>
    );

    let outputPanel;
    if (this.state.showItemMasterPanelFlag) {
      outputPanel = (
        <div className="item-sub-package-box">
          <div className="text-center var-comp-header">
            Variable Composition Item&nbsp;&nbsp;&nbsp;{exitButn}&nbsp;
            {infoButton}
          </div>
          {infoOpsPanel}
          {itemMasterInputPanel}
          {catgoryQuantityPanel}
          {compositionPanel}
          {/* {this.props.coopStoreName !== "" ? (
            <div className="text-center im-mgmt-coop-store-name">
              Store: {this.props.coopStoreName}
            </div>
          ) : "check"} */}
          {getMonthyShippingPanel}
          {theButtonPanel}
          <div className="row message-placement">
            <div
              className={
                this.state.compMsgFlag
                  ? "col text-center message-show-err"
                  : "col text-center message-show"
              }
            >
              {this.state.compMsg}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ComposePackage.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ComposePackage));
