import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./BrCompose.css";

import basicComposeTemplate from "./compose/images/basicComp.jpg";
import newsTemplate from "./compose/images/newsletter.jpg";
import sliderTemplate from "./compose/images/sliderComp.jpg";

import template4 from "./compose/images/bc-template4.png";

import BasicCompose from "./compose/templates/BasicCompose";
import NewsLetterCompose from "./compose/newsLetter/NewsLetCompose";
import SliderCompose from "./compose/slider/SliderCompose";

const readMeCode = "1144200000";

class BrCompose extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      btnBasicComp: false,
      btnNewsLetter: false,
      btnTemp3: false,
      btnTemp4: false,

      btnExpand: false,

      showTemp1: false,
      showTemp2: false,
      showTemp3: false,
      showTemp4: false,

      showTemplateBasic: false,
      showNewsLetter: false,
      showSlider: false,

      selectedTemplate: "",

      showTemplateOnPopup: false,
      popSelectedTemplate: "",

      showTeamplatesButtonsFlag: true,
      theBroadcast: null,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let btnBasicComp = false;
    let btnNewsLetter = false;
    let btnTemp3 = false;
    let btnTemp4 = false;

    console.log("----here 1");

    await this.setState({
      deviceSize,
      theBroadcast: this.props.theBroadcast,
      btnBasicComp,
      btnNewsLetter,
      btnTemp3,
      btnTemp4,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleExpandPop = async (template) => {
    console.log("show template pop:", template);
    this.setState({
      showTemplateOnPopup: true,
      popSelectedTemplate: template,
    });
  };

  exitFromPopup = async () => {
    this.setState({
      showTemplateOnPopup: false,
      popSelectedTemplate: "",
    });
  };

  returnFromBasicCompose = async (theBrd) => {
    console.log("returnFromBasicCompose theBrd:", theBrd);

    await this.setState({
      theBroadcast: theBrd,
      showTemplateBasic: false,
      showTemplateOnPopup: false,
      showNewsLetter: false,
      showSlider: false,
      showTeamplatesButtonsFlag: true,
    });
  };

  handleTemplate = (inp) => {
    console.log("inp:", inp);
    this.setState({
      showTemplateBasic: false,
      showNewsLetter: false,
      showSlider: false,

      showTeamplatesButtonsFlag: false,
    });

    switch (inp) {
      case "basicComposeTemplate":
        this.setState({
          showTemplateBasic: true,
        });
        break;
      case "newsLetterTemplate":
        this.setState({
          showNewsLetter: true,
        });
        break;
      case "sliderTemplate":
        this.setState({
          showSlider: true,
        });
        break;

      default:
        console.log("default - will not reach here");
    }
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let showTeamplatesButtons;
    if (this.state.showTeamplatesButtonsFlag) {
      showTeamplatesButtons = (
        <div>
          <div className="text-center comp-template-header">
            Templates for Composition &nbsp;{infoModalButton}
          </div>
          <div className="row compo-template-rows">
            {this.props.theBroadcast &&
            this.props.theBroadcast.mailCompositionType.includes("basic") ? (
              <div className="col-md-4 text-center">
                <button
                  onClick={() => this.handleTemplate("basicComposeTemplate")}
                  type="button"
                  className={
                    this.state.btnBasicComp
                      ? "btn-template-style-selected"
                      : "btn-template-style"
                  }
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <img
                    src={basicComposeTemplate}
                    alt="Template 1"
                    height="90"
                    width="60"
                  />
                </button>
                <div className="text-center br-comp-active">Basic</div>
              </div>
            ) : (
              <div className="col-md-4 text-center">
                <button
                  // onClick={() => this.handleTemplate("basicComposeTemplate")}
                  type="button"
                  className={
                    this.state.btnBasicComp
                      ? "btn-template-style-selected"
                      : "btn-template-style"
                  }
                  // style={{
                  //   cursor: this.state.disabled ? "default" : "pointer",
                  // }}
                >
                  <img
                    src={basicComposeTemplate}
                    alt="Template 1"
                    height="90"
                    width="60"
                  />
                </button>
                <div className="text-center br-comp-passive">Basic (NA)</div>
              </div>
            )}

            {this.props.theBroadcast &&
            this.props.theBroadcast.mailCompositionType.includes(
              "newsletter"
            ) ? (
              <div className="col-md-4 text-center">
                <button
                  onClick={() => this.handleTemplate("newsLetterTemplate")}
                  type="button"
                  className={
                    this.state.btnNewsLetter
                      ? "btn-template-style-selected"
                      : "btn-template-style"
                  }
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <img
                    src={newsTemplate}
                    alt="Template 1"
                    hight="100"
                    width="60"
                  />
                </button>
                <div className="text-center br-comp-active">News Letter</div>
              </div>
            ) : (
              <div className="col-md-4 text-center">
                <button
                  // onClick={() => this.handleTemplate("newsLetterTemplate")}
                  type="button"
                  className={
                    this.state.btnNewsLetter
                      ? "btn-template-style-selected"
                      : "btn-template-style"
                  }
                  // style={{
                  //   cursor: this.state.disabled ? "default" : "pointer",
                  // }}
                >
                  <img
                    src={newsTemplate}
                    alt="Template 1"
                    hight="100"
                    width="60"
                  />
                </button>
                <div className="text-center br-comp-passive">
                  News Letter (NA)
                </div>
              </div>
            )}

            {this.props.theBroadcast &&
            this.props.theBroadcast.mailCompositionType.includes("slider") ? (
              <div className="col-md-4 text-center">
                <button
                  onClick={() => this.handleTemplate("sliderTemplate")}
                  type="button"
                  className={
                    this.state.btnTemp3
                      ? "btn-template-style-selected"
                      : "btn-template-style"
                  }
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <img
                    src={sliderTemplate}
                    alt="Template 3"
                    hight="100"
                    width="60"
                  />
                </button>
                <div className="text-center br-comp-active">Slider</div>
              </div>
            ) : (
              <div className="col-md-4 text-center">
                <button
                  // onClick={() => this.handleTemplate("template4")}
                  type="button"
                  className={
                    this.state.btnTemp3
                      ? "btn-template-style-selected"
                      : "btn-template-style"
                  }
                  // style={{ cursor: this.state.disabled ? "default" : "pointer" }}
                >
                  <img
                    src={sliderTemplate}
                    alt="Template 1"
                    hight="100"
                    width="60"
                  />
                </button>
                <div className="text-center template-caption">Slider (NA)</div>
              </div>
            )}
          </div>

          <div className="row compo-template-rows">
            <div className="col-md-4 text-center">
              <button
                // onClick={() => this.handleTemplate("template7")}
                type="button"
                className={
                  this.state.btnTemp4
                    ? "btn-template-style-selected"
                    : "btn-template-style"
                }
                // style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <img src={template4} alt="Template 1" hight="100" width="60" />
              </button>
              <div className="text-center template-caption">Video</div>
            </div>
            <div className="col-md-4 text-center">
              <div className="text-center template-caption">&nbsp;</div>
            </div>
            <div className="col-md-4 text-left template-guide-text">
              <b>Template Usage:</b>&nbsp;
              <ul>
                <li>Select a template by clicking on it.</li>
                <li>Compose by providing guided information input</li>
                <li>Save while composing to retain your work</li>
                <li>Have fun for an artful business</li>
              </ul>
            </div>
          </div>
          <div className="text-center brd-release-msg">
            Template 1 is active. Other templates will be activated shortly. -v-
            shows the design of a template. If you are a business on Baanda
            platform please share your template imagination to
            support@baanda.com.
          </div>
        </div>
      );
    }

    let activeTemplate;
    if (this.state.showTemplateBasic) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activeTemplate = (
        <div>
          <BasicCompose
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            selectedTemplate={this.props.theBroadcast.mailCompositionType}
            returnToCaller={(theBrd) => this.returnFromBasicCompose(theBrd)}
            theBroadcast={this.props.theBroadcast}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showNewsLetter) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activeTemplate = (
        <div>
          <NewsLetterCompose
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            selectedTemplate={this.props.theBroadcast.mailCompositionType}
            returnToCaller={(theBrd) => this.returnFromBasicCompose(theBrd)}
            theBroadcast={this.props.theBroadcast}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showSlider) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activeTemplate = (
        <div>
          <SliderCompose
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            selectedTemplate={this.props.theBroadcast.mailCompositionType}
            returnToCaller={(theBrd) => this.returnFromBasicCompose(theBrd)}
            theBroadcast={this.props.theBroadcast}
            store={store}
          />
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        {showTeamplatesButtons}
        {activeTemplate}
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

BrCompose.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BrCompose)
);
