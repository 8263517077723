import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import axios from "axios";

// import ModalContainer from "../../modal/components/ModalContainer";
// import { showModal, hideModal } from "../../actions/modalActions";
// import "../../modal/css/localModal.css";
// import "../../modal/css/template.css";

import CommunityContact from "./contacts/CommunityContact";
import CustomersInvoice from "./invoice/CustomersInvoice";

import "./customerReportLanding.css";

const reports = [{ name: "Community contact" }, { name: "Your Invoices" }];

class CustomerReportLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      showReportList: true,
      showCommunityContacts: false,
      showCustomerInvoice: false,
      availableReports: [],

      message: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let areps = [];

    reports.forEach((rp) => {
      areps.push(rp);
    });

    await this.setState({
      deviceSize,
      availableReports: areps,
    });
  };

  selectThisReport = async (obj) => {
    // console.log("selectThisReport obj:", obj);
    let message = "";
    let showCommunityContacts = false;
    let showCustomerInvoice = false;
    if (obj.name === "Community contact") showCommunityContacts = true;
    if (obj.name === "Your Invoices") showCustomerInvoice = true;

    this.setState({
      message,
      showReportList: false,
      showCommunityContacts,
      showCustomerInvoice,
    });
  };

  returnFromReports = async () => {
    this.setState({
      showReportList: true,
      showCommunityContacts: false,
      showCustomerInvoice: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("customerReportLanding...");

    let sel;
    if (this.state.deviceSize === "big") {
      sel = "Select";
    } else {
      sel = "Sel";
    }

    let reportListPanel;
    let bool = true;
    if (this.state.availableReports.length > 0) {
      reportListPanel = (
        <div className="text-center">
          <div className="row sub-item-list-row">
            <div className="col-3 text-center sub-item-list-head">
              Operations
            </div>
            <div className="col-9 text-left sub-item-list-head">
              Report Names
            </div>
          </div>
          <div className="fixedsize-reports-list">
            {this.state.availableReports.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-3 text-center">
                      <button
                        className="btn_reg_60"
                        type="button"
                        onClick={() => this.selectThisReport(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        {this.state.deviceSize === 'big' ? sel : <i className="fas fa-check" />}
                      </button>
                    </div>
                    <div className="col-9 text-left report-names-lbl">
                      {obj.name}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
          <div className="text-center report-list-msg">
            {this.state.message}
          </div>
        </div>
      );
    }

    let reportHeader = (
      <div className="text-center mem-report-header">Reports</div>
    );

    let outputPanel;
    if (this.state.showReportList) {
      outputPanel = (
        <div className="report-list-cust-box">
          {reportHeader}
          {reportListPanel}
        </div>
      );
    }

    if (this.state.showCommunityContacts) {
      outputPanel = (
        <div className="report-list-cust-box">
          <CommunityContact
            returnToCaller={this.returnFromReports}
            community={this.props.community}
          />
        </div>
      );
    }

    if (this.state.showCustomerInvoice) {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <CustomersInvoice
            returnToCaller={this.returnFromReports}
            communityId={this.props.community.communityId}
            baandaId={this.props.auth.user.baandaId}
            store={store}
          />
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

CustomerReportLanding.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// const mapDispatchToProps = (dispatch) => ({
//   hideModal: () => dispatch(hideModal()),
//   showModal: (modalProps, modalType) => {
//     dispatch(showModal({ modalProps, modalType }));
//   },
// });

export default withRouter(connect(mapStateToProps)(CustomerReportLanding));
