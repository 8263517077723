import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import axios from "axios";
// import Select from "react-select";

// import _ from "lodash";

import ModalContainer from "../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../actions/modalActions";
import "../../../modal/css/localModal.css";
import "../../../modal/css/template.css";

// import Originate from "./interact/Originate";
import Converse from "./converse/Converse";
import BoardHub from "./interact/board/BoardHub";
import CalendarHub from "./calendar/CalendarHub";
import Broadcast from "./broadcast/Broadcast";

import "./ConnectHub.css";

const calendarList = ["calendar"];
// const broadcastList = ["broadcast"];

const readMeCode = "1140000000";

class ConnectHub extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",

      originFlag: false,
      converseFlag: false,
      // feelFlag: false,
      boardFlag: true,
      calendarFlag: false,
      // gossipFlag: false,
      broadcastFlag: false,

      draft: false,
      draftContent: {},

      deviceSize: "",

      calendarButtonFlag: false,
      broadcastButtonFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setAuthorizedButtons();

    await this.setState({
      deviceSize,
    });
  };

  // This is to show the info panel
  openAlertModal = async () => {
 
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  setAuthorizedButtons = async () => {
    // console.log("### this.state.authorizedFuncs: ", this.props.authorizedFuncs);
    let calendarButtonFlag = false;
    let broadcastButtonFlag = false;   // Make it true when working with campaign or release it

    calendarList.forEach((obj1) => {
      if (this.props.authorizedFuncs.includes(obj1)) calendarButtonFlag = true;
    });
    if (this.props.role === "customer") {
      calendarButtonFlag = false;
      broadcastButtonFlag = false;
    }

    if ( this.state.deviceSize === 'small') broadcastButtonFlag = false;

    if ( this.props.community.communityType === 'coop' && !this.props.store && this.props.role === 'storeowner') {
      // console.log("I am here when store = ", this.props.store);
      calendarButtonFlag = false;
    }

    this.setState({
      calendarButtonFlag,
      broadcastButtonFlag,
    });
  };

  handleSelect = async (choice) => {
    // console.log("The selection is: " + choice);
    await this.setState({
      originFlag: false,
      converseFlag: false,
      // feelFlag: false,
      boardFlag: false,
      calendarFlag: false,
      // gossipFlag: false,
      broadcastFlag: false,
    });

    if (choice === "origin") {
      await this.setState({
        originFlag: true,
      });
    }
    if (choice === "respond") {
      await this.setState({
        converseFlag: true,
      });
    }
    if (choice === "board") {
      await this.setState({
        boardFlag: true,
      });
    }
    if (choice === "calendar") {
      await this.setState({
        calendarFlag: true,
      });
    }

    if (choice === "broadcast") {
      await this.setState({
        broadcastFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ConnectHub...');

    let converse, board, broadcast, calendar;
    // let feel;
    if (this.state.deviceSize === "small") {
      // origin = "Orgn";
      converse = "Bml";
      board = "New";
      broadcast = "Cmn";
      calendar = "Cal";
    } else {
      // origin = "Originate";
      converse = "Bmail";
      board = "News";
      broadcast = "Campaign";
      calendar = "Calendar";
    }
    let connectHubButtons = (
      <div>
        <div className="row connet-hub-header-row">
          <div className="col-2 interact-header text-left">Connect</div>
          <div className="col-10 text-center con-hub-buttons">
            <button
              className={`${
                !this.state.boardFlag
                ? "btn_reg_75"
                : "btn_reg_75_active"
              }`}
              type="button"
              onClick={() => this.handleSelect("board")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {this.state.deviceSize === 'big' ? board : <i className="fas fa-newspaper" />} 
            </button>

            <button
              className={`${
                !this.state.converseFlag
                ? "btn_reg_75"
                : "btn_reg_75_active"
              }`}
              type="button"
              onClick={() => this.handleSelect("respond")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
               {this.state.deviceSize === 'big' ? converse : <i className="fas fa-envelope" />} 
            </button>
            {this.state.broadcastButtonFlag ? (
              <button
                className={`${
                  !this.state.broadcastFlag
                    ? "btn_reg_75"
                    : "btn_reg_75_active"
                }`}
                type="button"
                onClick={() => this.handleSelect("broadcast")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                 {this.state.deviceSize === 'big' ? broadcast : <i className="fas fa-paper-plane" /> } 
              </button>
            ) : null}
            {this.state.calendarButtonFlag ? (
              <button
                className={`${
                  !this.state.calendarFlag
                  ? "btn_reg_75"
                  : "btn_reg_75_active"
                }`}
                type="button"
                onClick={() => this.handleSelect("calendar")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                 {this.state.deviceSize === 'big' ? calendar : <i className="far fa-calendar" />} 
              </button>
            ) : null}
            <button 
              className="btn_info_main"
              type="button"
              onClick={this.openAlertModal}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-info-circle" />
            </button>
          </div>
        </div>
      </div>
    );

    let outputPanel;
    if (this.state.converseFlag) {
      let store = null;
      if ( this.props.store ) store = this.props.store;
      outputPanel = (
        <div>
          <Converse
            communityId={this.props.communityId}
            communityName={this.props.commName}
            authorizedFuncs={this.props.authorizedFuncs}
            isMember={this.props.isMember}
            store={store}
          />
        </div>
      );
    }

    if (this.state.boardFlag) {
      let store = null;
      if ( this.props.store ) store = this.props.store;
      outputPanel = (
        <div>
          <BoardHub
            communityId={this.props.communityId}
            communityName={this.props.commName}
            authorizedFuncs={this.props.authorizedFuncs}
            baandaId={this.props.auth.user.baandaId}
            isMember={this.props.isMember}
            role={this.props.role}
            store={store}
          />
        </div>
      );
    }

    if (this.state.calendarFlag) {
      let store = null;
      if ( this.props.store ) store = this.props.store;
      outputPanel = (
        <div>
          <CalendarHub
            communityId={this.props.communityId}
            communityName={this.props.commName}
            authorizedFuncs={this.props.authorizedFuncs}
            baandaId={this.props.auth.user.baandaId}
            role={this.props.role}
            caller="community"
            store={store}
          />
        </div>
      );
    }

    if (this.state.broadcastFlag) {
      let store = null;
      if ( this.props.store ) store = this.props.store;
      outputPanel = (
        <div>
          <Broadcast
            communityId={this.props.communityId}
            communityName={this.props.commName}
            authorizedFuncs={this.props.authorizedFuncs}
            baandaId={this.props.auth.user.baandaId}
            role={this.props.role}
            store={store}
          />
        </div>
      );
    }

    return (
      // <div className="fixedsize_connect">
      <div>
        {connectHubButtons}
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ConnectHub.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    // console.log(
    //   "modalProps:" + JSON.stringify(modalProps) + "  |modalType:" + modalType
    // );
    dispatch(showModal({ modalProps, modalType }));
  },
  // setQAInitDone: () => dispatch(setQAInitDone(userData))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ConnectHub));
