import React, { Component } from "react";

import "./Review.css";

class Review extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setState({
      deviceSize,
    });
  };

  returnToOrigin = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('Review...');

    let reviewPanel;
    reviewPanel = (
        <div className="fixedsize-review-show">
            Review  Content
        </div>
    )

    let outputPanel;
    outputPanel = (
      <div className="cat-show-review-box">
        Review Panel
        <button onClick={this.returnToOrigin}>R</button>
        {reviewPanel}
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

export default Review;
