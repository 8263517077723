import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./UpdateExternalAccount.css";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1151130000";

class UpdateExternalAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      external_accounts: {
        object: "bank_account",
        account_holder_name: "",
        account_holder: "",
        bank_name: "",
        country: "",
        currency: "",
        last4: "",
        routing_number: "",
        account_number: "",
      },

      eaname: "",
      eaholder: "company",
      eabankname: "",
      eacountry: "US",
      eacurrency: "usd",
      ealast4: "", // last for bank acc # (returned from Stripe ... double ckeck)
      earoutingno: "",
      eacccno: "",

      externalAccountSkip: false,
      deviceSize: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.setState({
      deviceSize,
    });

    this.loadDataInExternalAccount();
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  loadDataInExternalAccount = async () => {
    // console.log("loadDataInExternalAccount this.props:", this.props);
    let exv = this.props.externalAccountData;
    // console.log("exv: ", exv);

    // let exname = '';
    // if ( ex.account_holder_name )
    let exname = exv.account_holder_name;
    // let exbankname = '';
    // if ( ex.bank_name)
    let exbankname = exv.bank_name;
    // let rno = '';
    // if ( ex.routing_number )
    let rno = exv.routing_number;
    // let acno = '';
    // if ( ex.account_number !== '')
    let acno = exv.account_number;
    await this.setState({
      externalAccountSkip: this.props.externalAccountSkip,
      eaname: exname,
      // eaholder: ex.account_holder_type,
      eabankname: exbankname,
      eacountry: "US",
      eacurrency: "usd",
      ealast4: "", // last for of account holder's SSN
      earoutingno: rno,
      eacccno: acno,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  loadeadata = async () => {
    let extdata = {
      object: "bank_account",
      account_holder_name: this.state.eaname,
      account_holder_type: this.state.eaholder,
      bank_name: this.state.eabankname,
      country: this.state.eacountry,
      currency: this.state.eacurrency,
      last4: this.state.ealast4,
      routing_number: this.state.earoutingno,
      account_number: this.state.eacccno,
    };

    return extdata;
  };

  handleBackFromExternalAccount = async () => {
    let extdata = await this.loadeadata();
    // console.log("handleBackFromExternalAccount extdata:", extdata);
    this.props.handleBack(extdata, this.state.externalAccountSkip);
  };

  handleNextToExternalAccount = async () => {
    let extdata = await this.loadeadata();
    // console.log("handleNextToExternalAccount extdata:", extdata);
    this.props.handleNext(extdata, this.state.externalAccountSkip);
  };

  handleSkip = async () => {
    await this.setState((prevstate) => ({
      externalAccountSkip: !prevstate.externalAccountSkip,
      confirmErrorMsg: "On Finish, invoice will be in your email.",
    }));
    // console.log("this.state:", this.state);
  };

  render() {
    // console.log("updateSetup this.state:", this.state);
    // console.log("updateSetup this.props:", this.props);
    console.log('UpdateExternalAccount...');

    let commButtonCompany = (
      <div>
        <button
          onClick={this.handleBackFromExternalAccount}
          className="btn_reg_60"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Back
        </button>
        &nbsp;
        <button
          onClick={this.handleNextToExternalAccount}
          className="btn_reg_60"
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Next
        </button>
        &nbsp;
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
        &nbsp;
      </div>
    );

    let exheader;
    if (this.state.deviceSize === "small") exheader = "Bank Info";
    else exheader = "Your Banking Info";

    let headerPanel = (
      <div className="row">
        <div className="col-5 header_comp_text">{exheader}</div>
        <div className="col-7 ">{commButtonCompany}</div>
      </div>
    );

    let bottomMsgPanel = (
      <div className="row">
        <div className="col message_text">
          &nbsp;<font color="red">*</font>&nbsp; are mandaroty fields. Please
          click 'Next', or Back, buttons to continue.
        </div>
      </div>
    );

    // earoutingno: '',
    // eacccno: ''

    let ename, ebankname, erouting, eacno, easkip; //elast,
    if (this.state.deviceSize === "small") {
      ename = "AC holder";
      ebankname = "Bank Name";
      // elast = "Last-4";
      erouting = "Routing#";
      eacno = "Account #";
      easkip = "Skip External Account now.";
    } else {
      ename = "Account Holder Name";
      ebankname = "Account Holder's Bank Name";
      // elast = "Last 4 digits of SSN/EIN";
      erouting = "Bank's Routing No.";
      eacno = "Account Number";
      easkip = "Skip External Account now.";
    }
    let externalAccPanel;
    externalAccPanel = (
      <div>
        <div className="input-up-height" />
        <div className="row">
          <div className="col-4 ext_label text-right">
            <font color="red">*</font>&nbsp;{ename}{" "}
          </div>
          <div className="col-8 ext_input text-left">
            <input
              name="eaname"
              type="text"
              value={this.state.eaname}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_exttext"
              placeholder="Account Holder Name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 ext_label text-right">
            <font color="red">*</font>&nbsp;{ebankname}{" "}
          </div>
          <div className="col-8 ext_input text-left">
            <input
              name="eabankname"
              type="text"
              value={this.state.eabankname}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="input_exttext"
              placeholder="Bank Name"
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-4 ext_label text-right">
            <font color="red">*</font>&nbsp;{elast}{" "}
          </div>
          <div className="col-8 ext_input text-left">
            <input
              name="ealast4"
              type="text"
              value={this.state.ealast4}
              onChange={this.onChange}
              size="5"
              maxLength="5"
              className="input_extssn"
              placeholder="Last-4"
            />
          </div>
        </div> */}
        <div className="row">
          <div className="col-4 ext_label text-right">
            <font color="red">*</font>&nbsp;{erouting}{" "}
          </div>
          <div className="col-8 ext_input text-left">
            <input
              name="earoutingno"
              type="text"
              value={this.state.earoutingno}
              onChange={this.onChange}
              size="20"
              maxLength="20"
              className="input_exttext"
              placeholder="Routing No"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4 ext_label text-right">
            <font color="red">*</font>&nbsp;{eacno}{" "}
          </div>
          <div className="col-8 ext_input text-left">
            <input
              name="eacccno"
              type="text"
              value={this.state.eacccno}
              onChange={this.onChange}
              size="20"
              maxLength="20"
              className="input_exttext"
              placeholder="Account #"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-8 text-center biz_updt_confirm_ea">
            <Checkbox
              checked={this.state.externalAccountSkip}
              onChange={this.handleSkip}
            />
            &nbsp;&nbsp;{easkip}
          </div>
          <div className="col-4">&nbsp;</div>
        </div>
        {bottomMsgPanel}
      </div>
    );

    let outputPanel;

    outputPanel = (
      <div>
        {headerPanel}
        <div className="company_input_box fixedsize_extaccount">
          {externalAccPanel}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

UpdateExternalAccount.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UpdateExternalAccount));
