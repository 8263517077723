import React, { Component } from 'react';

import "./GateCheck.css";

class GateCheck extends Component {
    render() {
        return (
            <div>
                Gate Check - In next push
            </div>
        );
    }
}

export default GateCheck;