import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./Broadcast.css";

import BrDefine from "./parts/BrDefine";
import BrCompose from "./parts/BrCompose";
import ComopseText from "./parts/mms/ComposeText";
// import OrganizeRecipiants from "./parts/compose/recipiants/OrganizarRecipiants";
// import MarketingTeam from "./parts/team/MarketingTeam";
import Tags from "./parts/tags/BroadcastTags";
import PublishBroadcast from "./parts/publish/PublishBroadcast";

const readMeCode = "1144000000";

class Broadcast extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      showBtnDefine: true,
      showBtnCampaign: false,

      showBtnTeam: false,
      showBtnCompose: false,
      showBtnTag: false,
      showBtnMms: false,
      showBtnRecipiant: false,
      showBtnPublish: false,

      showOpsButtons: false,

      openDefine: true,
      openTeam: false,
      openCompose: false,
      openTag: false,
      openMms: false,
      openRecipiant: false,
      openPublish: false,

      opsButtonsSwitch: false,
      theBroadcast: null,

      showErrMsgPanel: true,
      errMsg: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let showErrMsgPanel = false;
    let errMsg = "";
    // let showSearchFlag = true;
    if (deviceSize === "small") {
      // showSearchFlag = false;
      showErrMsgPanel = true;
      errMsg =
        'A device (not a smart phone) with a bigger display area is needed for the broadcasting marketing campaign. Please access this module on a larger device. "Play football on coffee table, you cannot." Yoda (or Baanda) says :).';
    }

    await this.setState({
      deviceSize,
      showErrMsgPanel,
      errMsg,
    });
  };

  openAlertModal = async () => {
    // console.log("in openAlertModal info:", readMeCode);
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleEventOps = async (inp) => {
    await this.setState({
      showBtnDefine: false,
      showBtnCompose: false,

      // showBtnTeam: false,
      showBtnTag: false,
      showBtnMms: false,
      // showBtnRecipiant: false,
      // showBtnPublish: false,

      openDefine: false,
      openCompose: false,
      openCampaign: false,
      // openTeam: false,
      openTag: false,
      openMms: false,
      // openRecipiant: false,
      // openPublish: false,
    });

    switch (inp) {
      case "define":
        this.setState({
          showBtnDefine: true,
          openDefine: true,
        });
        break;
      case "compose":
        this.setState({
          showBtnCompose: true,
          openCompose: true,
        });
        break;
      case "team":
        this.setState({
          showBtnTeam: true,
          openTeam: true,
        });
        break;
      case "tag":
        this.setState({
          showBtnTag: true,
          openTag: true,
        });
        break;
      case "mms":
        this.setState({
          showBtnMms: true,
          openMms: true,
        });
        break;
      case "recipiant":
        this.setState({
          showBtnRecipiant: true,
          openRecipiant: true,
        });
        break;
      case "publish":
        this.setState({
          showBtnPublish: true,
          openPublish: true,
        });
        break;

      default:
        console.log("default - will not reach here");
    }
  };

  returnFromBrDefine = async (theBr) => {
    console.log("theBr:", theBr);
    let opsButtonsSwitch = false;

    if (theBr && theBr.broadcastId > 0) {
      opsButtonsSwitch = true;
    }
    await this.setState({
      theBroadcast: theBr,
      opsButtonsSwitch,
    });
  };

  returnedFromCalled = async (theBr) => {
    console.log("returnedFromCalled");
    await this.setState({
      theBroadcast: theBr,
    });
  };

  returnedFromCompose = async (theBr, action) => {
    console.log("returnedFromCompose action:", action);
    let openCompose = this.state.openCompose;
    if (action === "theBroadcastUpdate") openCompose = true;
    await this.setState({
      theBroadcast: theBr,
      openCompose,
    });
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let define, compose, campaign, mms; // tag
    // let define, compose, mms, recipiant, publish, team;  // tag
    // if (this.state.deviceSize === "small") {
    //   define = "Defin";

    //   mms = "Text";
    // } else {
    define = "Define";
    compose = "Compose";
    campaign = "Broadcast";
    mms = "SMS/MMS";
    // }

    let opsButtonPanel;
    opsButtonPanel = (
      <div className="row">
        <div className="col-2 text-center cal-event-header">Campaign</div>
        <div className="col-6 text-left event-ctrl-button">
          <b>EM</b>&nbsp;
          <button
            className={
              this.state.showBtnDefine ? "btn_reg_70_active" : "btn_reg_70"
            }
            type="button"
            onClick={() => this.handleEventOps("define")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {define}
          </button>
          {this.state.opsButtonsSwitch ? (
            <button
              className={
                this.state.showBtnCompose ? "btn_reg_80_active" : "btn_reg_80"
              }
              type="button"
              onClick={() => this.handleEventOps("compose")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {compose}
            </button>
          ) : null}
          {this.state.opsButtonsSwitch ? (
            <button
              className={
                this.state.showBtnCampaign ? "btn_reg_80_active" : "btn_reg_80"
              }
              type="button"
              // onClick={() => this.handleEventOps("campaign")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {campaign}
            </button>
          ) : null}
        </div>
        <div className="col-4 text-left event-ctrl-button">
          <button
            className={
              this.state.showBtnMms ? "btn_reg_90_active" : "btn_reg_90"
            }
            type="button"
            onClick={() => this.handleEventOps("mms")}
            style={{
              cursor: this.state.disabled ? "default" : "pointer",
            }}
          >
            {mms}
          </button>
          {/* ) : null} */}
          {infoModalButton}
        </div>
      </div>
    );

    let activePanel;
    console.log("this.state.openDefine:", this.state.openDefine);
    if (this.state.openDefine && !this.state.showErrMsgPanel) {
      let coopStoreId = 0;
      let store = null;
      if (this.props.store) {
        coopStoreId = this.props.store.coopStoreId;
        store = this.props.store;
      }
      console.log("here 1");
      activePanel = (
        <div>
          <BrDefine
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            communityName={this.props.communityName}
            returnToCaller={this.returnFromBrDefine}
            theBroadcast={this.state.theBroadcast}
            store={store}
          />
        </div>
      );
    }

    console.log("this.state.openCompose:", this.state.openCompose);
    if (this.state.openCompose && !this.state.showErrMsgPanel) {
      let coopStoreId = 0;
      let store = null;
      if (this.props.store) {
        coopStoreId = this.props.store.coopStoreId;
        store = this.props.store;
      }
      console.log("here 2");
      activePanel = (
        <div>
          <BrCompose
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            theBroadcast={this.state.theBroadcast}
            returnToCaller={this.returnedFromCompose}
            coopStoreId={coopStoreId}
            store={store}
          />
        </div>
      );
    }

    if (this.state.openMms && !this.state.showErrMsgPanel) {
      let coopStoreId = 0;
      let store = null;
      if (this.props.store) {
        coopStoreId = this.props.store.coopStoreId;
        store = this.props.store;
      }
      activePanel = (
        <div>
          <ComopseText
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            theBroadcast={this.state.theBroadcast}
            returnToCaller={this.returnedFromCalled}
            compositionComponent="mms"
            coopStoreId={coopStoreId}
            store={store}
          />
        </div>
      );
    }


    if (this.state.openTag && !this.state.showErrMsgPanel) {
      activePanel = (
        <div>
          <Tags
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            theBroadcast={this.state.theBroadcast}
            returnToCaller={this.returnedFromCalled}
            compositionComponent="tags"
          />
        </div>
      );
    }

    if (this.state.openPublish && !this.state.showErrMsgPanel) {
      console.log("======= inside openPublish");
      activePanel = (
        <div>
          <PublishBroadcast
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            theBroadcast={this.state.theBroadcast}
            returnToCaller={this.returnedFromCalled}
            compositionComponent="publish"
          />
        </div>
      );
    }

    let topPanel;
    topPanel = <div className="text-center">{opsButtonPanel}</div>;

    let errorPanel;
    if (this.state.showErrMsgPanel) {
      errorPanel = (
        <div className="catalog-error-box">
          <div className="cat-error-header">Message</div>
          <div>
            <p align="justify" className="device-error-msg">
              {this.state.errMsg}
            </p>
          </div>
        </div>
      );
    }

    let outputPanel;
    if (this.state.showErrMsgPanel) {
      outputPanel = errorPanel;
    } else {
      outputPanel = (
        <div className="broadcast-box text-center">
          {topPanel}
          <hr className="campaign-btn-divider" />
          {activePanel}
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

Broadcast.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Broadcast));
