import React, { Component } from "react";

import axios from "axios";

import GLSearchFilter from "./GLSearchFilter";
import GLDetail from "./GLDetail";

import "./GeneralLedger.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getGlData = "/routes/reports/getGlData?";
// const getGLSummaryByCategory = "/routes/reports/getGLSummaryByCategory?";

class GeneralLedger extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showGLRepFlag: true,
      showSearchFlag: false,
      filterBy: null,

      startDateStr: "",
      endDateStr: "",

      showGLDetailFlag: false,

      glData: [],
      selectedGLLine: null,
      readableRDT: '',

      errMsg: "",
      errFlag: false,
    };
  }

  exitReport = () => {
    // console.log("to exit ... from gl");
    this.props.returnToCaller();
  };

  returnFromFilter = async (filterBy) => {
    // console.log("filterBy:", filterBy);
    let startDateStr = "";
    let endDateStr = "";
    if (filterBy) {
      await this.getJournalData(filterBy);

      startDateStr = this.formatDate(filterBy.dateFrom);
      endDateStr = this.formatDate(filterBy.dateTo);
    }
    this.setState({
      showGLRepFlag: true,
      showSearchFlag: false,
      filterBy,
      startDateStr,
      endDateStr,
    });
  };

  showSearchPanel = () => {
    this.setState({
      showGLRepFlag: false,
      showSearchFlag: true,
      // readableRDT: '',
    });
  };

  padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  formatDate = (date) => {
    let dt = date;
    if (!this.dateIsValid(date)) dt = new Date(date);
    return [
      this.padTo2Digits(dt.getMonth() + 1),
      this.padTo2Digits(dt.getDate()),
      dt.getFullYear(),
    ].join("/");
  };

  dateIsValid = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  getJournalData = async (filterBy) => {
    // console.log('filterBy:', filterBy);
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let fnType = "";
    let readableRDT = "";
    if (filterBy.selectedCategory) {
      fnType = filterBy.selectedCategory.functionType;
      readableRDT = filterBy.selectedCategory.readableFnType;
    }
 
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&dateFrom=" +
      filterBy.dateFrom +
      "&dateTo=" +
      filterBy.dateTo +
      "&filterType=" +
      filterBy.filterType +
      "&functionType=" +
      fnType;  
    let url = baandaServer + getGlData + params;
    // console.log("url:", url);
    try {
      let glret = await axios.get(url);
      // console.log("glret.data:", glret.data);
      if (glret.data.status === "success") {
        // console.log('here 111');
        this.setState({
          glData: glret.data.Msg,
          readableRDT,
          errMsg: "",
          errFlag: false,
        });
      } else {
        // console.log('here 222');
        this.setState({
          errMsg: glret.data.Msg,
          errFlag: true,
          glData: [],
          readableRDT,
        });
      }
    } catch (err) {
      console.log ('err:', err);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  getHRVersion = (fn) => {
    // console.log("fn:", fn);
    let hrform = "xx";
    this.state.filterBy.GLMeta.forEach((elm) => {
      if (elm.functionType === fn) hrform = elm.readableFnType;
    });

    return hrform;
  };

  processRefAcc = (acc) => {
    // console.log("acc:", acc);
    this.setState({
      selectedGLLine: acc,
      showGLDetailFlag: true,
    });
  };

  returnFromGLDetails = () => {
    this.setState({
      showGLDetailFlag: false,
      selectedGLLine: null,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("GeneralLedger...")

    let exitButton = (
      <button
        onClick={this.exitReport}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let searchButton = (
      <button
        onClick={this.showSearchPanel}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_70"
      >
        Search
      </button>
    );

    // <i className="fas fa-search-plus" />
    let showGLDataPanel;
    if (this.state.filterBy && this.state.filterBy.filterType === "all") {
      if (this.state.glData.length > 0) {
        let bool = true;
        showGLDataPanel = (
          <div>
            <div className="gl-scroll-box">
              {this.state.glData.map((obj, i) => {
                // console.log("690 obj:", obj);
                return (
                  <div key={i}>
                    <div
                      className={
                        bool ? "row gl-line-row-dr" : "row gl-line-row-lt"
                      }
                    >
                      <div className="col-1 text-center gl-line-lbl">
                        {this.formatDate(obj.entry_at)}
                      </div>
                      <div className="col-4 text-left gl-line-desc">
                        {this.getHRVersion(obj.referenceDocType)} 
                        {obj.description === '' ? '' : "( " + obj.description + " )"}
                      </div>
                      <div className="col-2 text-left gl-line-lbl">
                        {obj.debitAccountName}
                      </div>
                      <div className="col-2 text-left gl-line-lbl">
                        {obj.creditAccountName}
                      </div>
                      <div className="col-2 text-right gl-line-lbl">
                        {this.commaFormattedCurrency(obj.Amount)}
                      </div>
                      <div className="col-1 text-center">
                        <button
                          onClick={() => {
                            this.processRefAcc(obj);
                          }}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                          className="btn_reg_30"
                        >
                          ...
                        </button>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        showGLDataPanel = (
          <div className="gl-scroll-box">
            No records available for the search condition.
          </div>
        );
      }
    }

    if (this.state.filterBy && this.state.filterBy.filterType === "summary") {
      if (this.state.glData.length > 0) {
        let bool = true;
        showGLDataPanel = (
          <div>
            <div className="gl-scroll-box">
              {this.state.glData.map((obj, i) => {
                // console.log("690 obj:", obj);
                return (
                  <div key={i}>
                    <div
                      className={
                        bool ? "row gl-line-row-dr" : "row gl-line-row-lt"
                      }
                    >
                      <div className="col-5 text-left gl-line-desc">
                        {obj.hrName}
                      </div>
                      <div className="col-2 text-left gl-line-lbl">
                        {obj.debitAccountName}
                      </div>
                      <div className="col-2 text-left gl-line-lbl">
                        {obj.creditAccountName}
                      </div>
                      <div className="col-2 text-right gl-line-lbl">
                        {this.commaFormattedCurrency(obj.Amount)}
                      </div>
                      <div className="col-1 text-center">&nbsp;</div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        showGLDataPanel = (
          <div className="gl-scroll-box">
            No records available for the search condition.
          </div>
        );
      }
    }

    if (this.state.filterBy && this.state.filterBy.filterType === "category") {
      if (this.state.glData.length > 0) {
        let bool = true;
        showGLDataPanel = (
          <div>
            <div className="gl-scroll-box">
              {this.state.glData.map((obj, i) => {
                // console.log("cat obj:", obj);
                return (
                  <div key={i}>
                    <div
                      className={
                        bool ? "row gl-line-row-dr" : "row gl-line-row-lt"
                      }
                    >
                      <div className="col-2 text-center gl-line-lbl">
                        {this.formatDate(obj.entry_at)}
                      </div>
                      <div className="col-3 text-left gl-line-lbl">
                        {obj.debitAccountName}
                      </div>
                      <div className="col-3 text-left gl-line-lbl">
                        {obj.creditAccountName}
                      </div>
                      <div className="col-2 text-right gl-line-lbl">
                        {this.commaFormattedCurrency(obj.Amount)}
                      </div>
                      <div className="col-2 text-center">
                        <button
                          onClick={() => {
                            this.processRefAcc(obj);
                          }}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                          className="btn_reg_30"
                        >
                          ...
                        </button>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        showGLDataPanel = (
          <div className="gl-scroll-box">
            No records available for the search condition.
          </div>
        );
      }
    }

    let displayPanel;
    if (this.state.filterBy && this.state.filterBy.filterType === "all") {
      displayPanel = (
        <div>
          <div className="row gl-title-pos">
            <div className="col-1 text-center">Date</div>
            <div className="col-4 text-left ">
              &nbsp;&nbsp;&nbsp;&nbsp;Reference
            </div>
            <div className="col-2 text-left">Debit A/C</div>
            <div className="col-2 text-left">Credit A/C</div>
            <div className="col-2 text-right">Amount $</div>
            <div className="col-1 text-left">Ops</div>
          </div>
          {showGLDataPanel ? (
            showGLDataPanel
          ) : (
            <div className="gl-scroll-box">Search to see</div>
          )}
        </div>
      );
    }

    if (this.state.filterBy && this.state.filterBy.filterType === "summary") {
      displayPanel = (
        <div>
          <div className="row gl-title-pos">
            <div className="col-5 text-left ">
              &nbsp;&nbsp;&nbsp;&nbsp;Category
            </div>
            <div className="col-2 text-left">Debit A/C</div>
            <div className="col-2 text-left">Credit A/C</div>
            <div className="col-2 text-right">Amount $</div>
            <div className="col-1 text-left">&nbsp;</div>
          </div>
          {showGLDataPanel ? (
            showGLDataPanel
          ) : (
            <div className="gl-scroll-box">Search to see</div>
          )}
        </div>
      );
    }

    if (this.state.filterBy && this.state.filterBy.filterType === "category") {
      displayPanel = (
        <div>
          <div className="row gl-title-pos">
            <div className="col-2 text-center">Date</div>
             
            <div className="col-3 text-left">Debit A/C</div>
            <div className="col-3 text-left">Credit A/C</div>
            <div className="col-2 text-right">Amount $</div>
            <div className="col-2 text-center">Ops</div>
          </div>
          {showGLDataPanel ? (
            showGLDataPanel
          ) : (
            <div className="gl-scroll-box">Search to see</div>
          )}
          <div className="text-center">Selected Category:&nbsp;{this.state.readableRDT}</div>
        </div>
      );
    }

    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let searchPanel;
    if (this.state.showSearchFlag) {
      searchPanel = (
        <div>
          <GLSearchFilter
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            returnToCaller={(cond) => this.returnFromFilter(cond)}
          />
        </div>
      );
    }

    let detailPanel;
    if (this.state.showGLDetailFlag) {
      detailPanel = (
        <div>
          <GLDetail
            returnToCaller={this.returnFromGLDetails}
            glLine={this.state.selectedGLLine}
          />
        </div>
      );
    }

    let headerPanel = (
      <div className="row">
        <div className="col-4 text-left gl-start-date">
          Starts:&nbsp;
          {this.state.startDateStr === "" ? (
            "Select"
          ) : (
            <font color="green">{this.state.startDateStr}</font>
          )}
        </div>
        <div className="col-4 gl-report-header text-center">
          General Ledger {searchButton} {exitButton}
        </div>
        <div className="col-4 text-right gl-end-date">
          Ends:&nbsp;
          {this.state.endDateStr === "" ? (
            "Select"
          ) : (
            <font color="red">{this.state.endDateStr}</font>
          )}
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="gl-present-box">
        {headerPanel}
        <div className="text-center gl-report-header">
          {searchPanel}
          {detailPanel}
          {displayPanel}
        </div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default GeneralLedger;
