import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import { optionsTemplates } from "./data/optionsTemplates";
import { emailValidation } from "../../../../utils/emailValidation";

import "./Templates.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getTemplateInfo = "/routes/reports/getTemplateInfo?";
const saveTemplate = "/routes/dashboard/saveTemplates";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1115500000";

class Templates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTemplate: {
        value: "templateReminder",
        label: "Payment Reminder",
      },

      subject: "Friendly Payment Reminder",
      salute: "Dear",
      letterBody:
        "This is a friendly reminder of your scheduled payment. The details regarding your bill are described below.",
      letterBodyMsg: "Write a letter to accompany payment information.",
      letterBodyErrFlag: false,
      decisionFlag: false,
      decisionMsg: false,

      line1: "",
      line2: "",
      line3: "",

      line4: "",
      line5: "",
      line6: "",

      errMsg: "",
      errMsgFlag: false,
      deviceSize: "",
    };

    // this.onChange = this.onChange.bind(this);
    this.doThisAtFirst();
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    if (
      this.state.selectedTemplate.value === "templateReminder" ||
      this.state.selectedTemplate.value === "inviteLetter"
    ) {
      await this.setState({
        line4: "Sincerely",
        line5: this.props.auth.user.name,
        line6: this.props.auth.user.email,
        deviceSize,
      });
    } else {
      await this.setState({
        line4: this.props.auth.user.name,
        line5: this.props.auth.user.email,
        line6: "",
        deviceSize,
      });
    }
    this.getTemplateInfo();
  };

  openAlertModal = async () => {
 
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  doThisAtFirst = async () => {
    // console.log("I am in component will mount");
    if (!this.props.auth.isAuthenticated) {
      this.props.history.push("/login");
    }
  };

  handleConfirm = async () => {
    await this.setState((prevstate) => ({
      decisionFlag: !prevstate.decisionFlag,
      decisionMsg: "On Save, a sample will be in your email.",
    }));
  };

  getTemplateInfo = async () => {
    let params =
      "templateType=" +
      this.state.selectedTemplate.value +
      "&communityId=" +
      this.props.communityId;
    let url = baandaServer + getTemplateInfo + params;
    // console.log("getTemplateInfo url:", url);
    try {
      let tempData = await axios.get(url);
      // console.log(">>>> tempData:", tempData);
      if (tempData.data.Msg.length === 0) {
        // console.log("Do not have related template in DB");
        if (
          this.state.selectedTemplate.value === "templateReminder" ||
          this.state.selectedTemplate.value === "inviteLetter"
        ) {
          this.setState({
            line4: "Sincerely",
            line5: this.props.auth.user.name,
            line6: this.props.auth.user.email,
          });
        } else {
          this.setState({
            line4: "Thank you",
            line5: this.props.auth.user.name,
            line6: this.props.auth.user.email,
          });
        }
      } else {
        // console.log(
        //   "this.state.selectedTemplate.value:",
        //   this.state.selectedTemplate.value
        // );
        if (
          this.state.selectedTemplate.value === "templateReminder" ||
          this.state.selectedTemplate.value === "inviteLetter"
        ) {
 
          this.setState({
            subject: tempData.data.Msg[0].subject,
            salute: tempData.data.Msg[0].salute,
            letterBody: tempData.data.Msg[0].letterBody,
            line4: tempData.data.Msg[0].invoiceSignature.bottomLine1,
            line5: tempData.data.Msg[0].invoiceSignature.bottomLine2,
            line6: tempData.data.Msg[0].invoiceSignature.bottomLine3,
          });
        } else {
     
          this.setState({
            line1: tempData.data.Msg[0].header.topLine1,
            line2: tempData.data.Msg[0].header.topLine2,
            line3: tempData.data.Msg[0].header.topLine3,
            line4: tempData.data.Msg[0].invoiceSignature.bottomLine1,
            line5: tempData.data.Msg[0].invoiceSignature.bottomLine2,
            line6: tempData.data.Msg[0].invoiceSignature.bottomLine3,
          });
        }
      }
    } catch (err) {
      console.log("getTemplateInfo err:", err.message);
      this.setState({
        errMsg: err.message,
        errMsgFlag: true,
      });
    }
  };

  onChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  validateTemplate = async () => {
    // console.log(
    //   "validateTemplate this.state.selectedTemplate.value: ",
    //   this.state.selectedTemplate.value
    // );
    let isValid = true;
    if (this.state.selectedTemplate.value === "templateReminder") {
      let isValidEmailAddr = emailValidation(this.state.line6);
      if (!isValidEmailAddr) {
        isValid = false;
      }
    }
    if (this.state.selectedTemplate.value === "templateInvoice") {
      let isValidEmailAddr = emailValidation(this.state.line6);
      if (!isValidEmailAddr) {
        isValid = false;
      }
    }

    if (!isValid) {
      await this.setState({
        errMsg: "Enter valid Reply email.",
        errMsgFlag: true,
      });
    } else {
      await this.setState({
        errMsg: "",
        errMsgFlag: false,
      });
    }

    return isValid;
  };

  handleSelectTemplateType = async (selectedOption) => {
    // console.log("selectedOption:", selectedOption);
    await this.setState({
      selectedTemplate: selectedOption,
      decisionFlag: false,
      decisionMsg: "",
    });

    if (this.state.selectedTemplate.value === "templateReminder") {
      await this.setState({
        subject: "Friendly Payment Reminder",
        salute: "Dear",
        letterBody:
          "This is a friendly reminder of your scheduled payment. The details regarding your bill are described below.",
        letterBodyMsg: "Write a letter to accompany payment information.",
      });
    }
    if (this.state.selectedTemplate.value === "inviteLetter") {
      await this.setState({
        subject: "An invitation to join <enter community name here>",
        salute: "Dear",
        letterBody:
          "This is an invitation to join our community <enter the name here>. Please click the link (picture) below to join. If you already have a Baanda account, then log in. The invite will appear on your Home Page. Please enter by clicking Join and preview what the community is all about.",
        letterBodyMsg: "Compose the email to invite someone to the community.",
      });
    }
    this.getTemplateInfo();
  };

  handleSaveTemplate = async () => {
    // alert("Will save the templates ...");

    let isValid = await this.validateTemplate();
    // console.log("isValid: ", isValid);
    if (!isValid) {
      return -1;
    }

    let url = baandaServer + saveTemplate;
    let subject = "",
      salute = "",
      letterBody = "";
    let line1 = "",
      line2 = "",
      line3 = "";
    if (this.state.selectedTemplate.value === "templateInvoice") {
      line1 = this.state.line1;
      line2 = this.state.line2;
      line3 = this.state.line3;
    } else if (this.state.selectedTemplate.value === "templateReminder") {
      subject = this.state.subject;
      salute = this.state.salute;
      letterBody = this.state.letterBody;
    } else if (this.state.selectedTemplate.value === "inviteLetter") {
      subject = this.state.subject;
      salute = this.state.salute;
      letterBody = this.state.letterBody;
    }
    let inputData = {
      subject,
      salute,
      letterBody,
      line1,
      line2,
      line3,
      line4: this.state.line4,
      line5: this.state.line5,
      line6: this.state.line6,
      decisionFlag: this.state.decisionFlag,
      communityId: this.props.communityId,
      communityName: this.props.communityName,
      templateType: this.state.selectedTemplate.value,
      creatorEmail: this.props.auth.user.email,
      creatorBaandaId: this.props.auth.user.baandaId,
      creatorName: this.props.auth.user.name,
    };
    // console.log("save url:", url + " inputdata:", inputData);

    try {
      let ret = await axios.post(url, inputData);
      // console.log("Saved return:", ret);
      // console.log("Msg:", ret.data.Msg);
      await this.setState({
        decisionMsg: ret.data.Msg,
      });
      // console.log("this.state:", this.state);
    } catch (err) {
      console.log("Save template error:", err.message);
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('Templates...');

    let subModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let messagePanel;
    if (this.state.errMsgFlag) {
      messagePanel = (
        <div className="text-center template_err_msg">{this.state.errMsg}</div>
      );
    }

    let templateType, creatorName, creatorEmail;
    if (this.state.deviceSize === "small") {
      templateType = "Type";
      creatorName = "Name";
      creatorEmail = "Y'r Email";
    } else {
      templateType = "Template Types";
      creatorName = "From Name";
      creatorEmail = "From Email";
    }

    let selectTemplatePanel;
    selectTemplatePanel = (
      <div className="text-center">
        <div className="row">
          <div className="col-3 text-center select-label">{templateType}</div>
          <div className="col-9 text-left">
            <Select
              value={this.state.selectedTemplate}
              options={optionsTemplates}
              className="template-select-dropdown"
              classNamePrefix="select"
              onChange={this.handleSelectTemplateType}
            />
          </div>
        </div>
      </div>
    );

    let buttonPanel = (
      <div>
        <div className="row">
          <div className="col-8 text-center test-mail">
            <Checkbox
              checked={this.state.decisionFlag}
              onChange={this.handleConfirm}
            />
            &nbsp; Send me a test mail.
          </div>
          <div className="col-4 text-center">
            <button
              className="btn_reg_70"
              type="button"
              onClick={this.handleSaveTemplate}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Save
            </button>
            &nbsp;{subModalButton}
          </div>
        </div>
        <div className="row">
          <div className="col text-center decision_format">
            {this.state.decisionMsg}
          </div>
        </div>
      </div>
    );

    let prname, premail;
    if (this.state.deviceSize === "small") {
      prname = "Name";
      premail = "Email";
    } else {
      prname = "Sender Name";
      premail = "Sender Email";
    }

    let templatePanel;
    if (this.state.selectedTemplate.value === "templateReminder") {
      templatePanel = (
        <div>
          <div className="row">
            <div className="col-3 text-right input-label">Subject</div>
            <div className="col-9 text-left">
              <input
                name="subject"
                type="text"
                value={this.state.subject}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="input_text_template"
                // placeholder="Your postal street address ..."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right input-label">Greeting</div>
            <div className="col-9 text-left">
              <input
                name="salute"
                type="text"
                value={this.state.salute}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="input_text_template"
                // placeholder="Your postal street address ..."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right input-label">Compose</div>
            <div className="col-9 text-left">
              <textarea
                name="letterBody"
                maxLength="1000"
                rows="5"
                wrap="hard"
                spellCheck="true"
                className="input_textarea_temp"
                onChange={this.onChange}
                value={this.state.letterBody}
                required
              />
              <div
                className={`${
                  !this.state.letterBodyErrFlag
                    ? "temp_input_msg"
                    : "temp_input_msg_err"
                }`}
              >
                <p>{this.state.letterBodyMsg}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-1">&nbsp;</div>
            <div className="col-10 letter-msg">
              <p align="justify">
                The email will contain contextual information like invoice number,
                amount due, pending amount, last payment date & amount, and payment
                due date, & amount.
              </p>
            </div>
            <div className="col-1">&nbsp;</div>
          </div>
          <div className="row respect">
            <div className="col-3 text-right input-label">Signoff Phrase:</div>
            <div className="col-9 text-left">
              <input
                name="line4"
                type="text"
                value={this.state.line4}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="input_text_template"
              />
            </div>
          </div>
          <div className="temp_input_msg_inv">
            <p>Bottom-left footer</p>
          </div>
          <div className="row">
            <div className="col-3 text-right input-label">{prname}</div>
            <div className="col-9 text-left">
              <input
                name="line5"
                type="text"
                value={this.state.line5}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="input_text_template"
              />
            </div>
          </div>
          <div className="temp_input_msg_inv">
            <p>Single bold bottom-left footer</p>
          </div>
          <div className="row">
            <div className="col-3 text-right input-label">{premail}</div>
            <div className="col-9 text-left">
              <input
                name="line6"
                type="text"
                value={this.state.line6}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="input_text_template"
                placeholder="email"
              />
            </div>
          </div>
          <div className="temp_input_msg_inv">
            <p>Single bold bottom-left footer</p>
          </div>
          {messagePanel}
          {buttonPanel}
        </div>
      );
    }

    let taddr1, taddr2, replyemail;
    if (this.state.deviceSize === "small") {
      taddr1 = "Addr1";
      taddr2 = "Addr2";
      replyemail = "ReplyTo";
    } else {
      taddr1 = "Header Addr1";
      taddr2 = "Header Addr2";
      replyemail = "Reply-To Name";
    }

    if (this.state.selectedTemplate.value === "templateInvoice") {
      templatePanel = (
        <div>
          <div className="text-center">
            <b>Invoice</b>
          </div>
          <div className="row">
            <div className="col-3 text-right input-label">Header</div>
            <div className="col-9 text-left">
              <input
                name="line1"
                type="text"
                value={this.state.line1}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="input_text_template"
              />
            </div>
          </div>
          <div className="temp_input_msg_inv">
            <p>Double bold top-left header</p>
          </div>
          <div className="row">
            <div className="col-3 text-right input-label">{taddr1}</div>
            <div className="col-9 text-left">
              <input
                name="line2"
                type="text"
                value={this.state.line2}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="input_text_template"
              />
            </div>
          </div>
          <div className="temp_input_msg_inv">
            <p>Single bold 2nd top-left header</p>
          </div>
          <div className="row">
            <div className="col-3 text-right input-label">{taddr2}</div>
            <div className="col-9 text-left">
              <input
                name="line3"
                type="text"
                value={this.state.line3}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="input_text_template"
              />
            </div>
          </div>
          <div className="temp_input_msg_inv">
            <p>Single bold 3rd top-left header</p>
          </div>
          <div className="row">
            <div className="col-1">&nbsp;</div>
            <div className="col-10 letter-msg">
              <p align="justify">
                The email that goes out will contain the itemized totals of the invoice detail.
              </p>
            </div>
            <div className="col-1">&nbsp;</div>
          </div>
          <div className="row">
            <div className="col-3 text-right input-label">Greeting</div>
            <div className="col-9 text-left">
              <input
                name="line4"
                type="text"
                value={this.state.line4}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="input_text_template"
              />
            </div>
          </div>
          <div className="temp_input_msg_inv">
            <p>One & half bold bottom-left footer</p>
          </div>
          <div className="row">
            <div className="col-3 text-right input-label">{replyemail}</div>
            <div className="col-9 text-left">
              <input
                name="line5"
                type="text"
                value={this.state.line5}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="input_text_template"
              />
            </div>
          </div>
          <div className="temp_input_msg_inv">
            <p>Single bold bottom-left footer</p>
          </div>
          <div className="row">
            <div className="col-3 text-right input-label">Email</div>
            <div className="col-9 text-left">
              <input
                name="line6"
                type="text"
                value={this.state.line6}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="input_text_template"
              />
            </div>
          </div>
          <div className="temp_input_msg_inv">
            <p>Single bold bottom-left footer</p>
          </div>
          {messagePanel}
          {buttonPanel}
        </div>
      );
    }

    if (this.state.selectedTemplate.value === "inviteLetter") {
      templatePanel = (
        <div>
          <div className="row">
            <div className="col-3 text-right input-label">Subject:</div>
            <div className="col-9 text-left">
              <input
                name="subject"
                type="text"
                value={this.state.subject}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="input_text_template"
                // placeholder="Your postal street address ..."
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right input-label">Greeting:</div>
            <div className="col-9 text-left">
              <input
                name="salute"
                type="text"
                value={this.state.salute}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="input_text_template"
                // placeholder="Your postal street address ..."
              />
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <textarea
                name="letterBody"
                maxLength="1000"
                //   placeholder="Write a short description about your community or catalog."
                rows="5"
                wrap="hard"
                spellCheck="true"
                className="input_textarea_temp"
                onChange={this.onChange}
                value={this.state.letterBody}
                required
              />
              <div
                className={`${
                  !this.state.letterBodyErrFlag
                    ? "temp_input_msg"
                    : "temp_input_msg_err"
                }`}
              >
                <p>{this.state.letterBodyMsg}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-1">&nbsp;</div>
            <div className="col-10 letter-msg">
              <p align="justify">
                This email is to invite someone to your commuity. They may, or
                may not, already have a Baanda account.
              </p>
            </div>
            <div className="col-1">&nbsp;</div>
          </div>
          <div className="row respect">
            <div className="col-3 text-right input-label">Signoff Phrase:</div>
            <div className="col-9 text-left">
              <input
                name="line4"
                type="text"
                value={this.state.line4}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="input_text_template"
              />
            </div>
          </div>
          <div className="temp_input_msg_inv">
            <p>Bottom-left footer</p>
          </div>
          <div className="row">
            <div className="col-3 text-right input-label">{creatorName}</div>
            <div className="col-9 text-left">
              <input
                name="line5"
                type="text"
                value={this.state.line5}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="input_text_template"
              />
            </div>
          </div>
          <div className="temp_input_msg_inv">
            <p>Single bold bottom-left footer</p>
          </div>
          <div className="row">
            <div className="col-3 text-right input-label">{creatorEmail}</div>
            <div className="col-9 text-left">
              <input
                name="line6"
                type="text"
                value={this.state.line6}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="input_text_template"
                placeholder="email"
              />
            </div>
          </div>
          <div className="temp_input_msg_inv">
            <p>Single bold bottom-left footer</p>
          </div>
          {messagePanel}
          {buttonPanel}
        </div>
      );
    }

    let outputPanel;

    outputPanel = (
      <div>
        {selectTemplatePanel}
        <div className="fixedsize_template">
          {templatePanel}
          <div className="template-space-bottom" />
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

Templates.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Templates));
