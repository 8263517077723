import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import ConductRefundCancel from "./ConductRefundCancel";

import "./ReturnOpsMain.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getRefundInfoForResponse = "/routes/finance/getRefundInfoForResponse?";

const readMeCode = "1113111111";

class ReturnOpsMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      theInvoice: null,
      returnCancel: [],

      retCanOpsType: "invoice",
      invLineItems: [],

      refundMustBeInText: "",
      refundPathCode: "CC",

      errMsg: "",
      errFlag: false,
      selectedItems: [],
      pendingRequest: 0,
      activeRequests: [],

      selheightpkg: 1,
      selectedRequest: null,
    };
  }

  componentDidMount = async () => {
    await this.getOpsInfo();
    let invLineItems = [];
    if (this.state.theInvoice.invoiceRaw) {
      this.state.theInvoice.invoiceRaw.invoiceLineItems.forEach((elm) => {
        let invobj = {
          itemId: elm.itemId,
          itemName: elm.itemName,
          itemType: elm.itemType,
          price: elm.price,
          quantity: elm.quantity,
          taxable: elm.taxable,
          unit: elm.unitType,
        };
        invLineItems.push(invobj);
      });
      this.setState({
        invLineItems,
      });
    } else if (this.state.theInvoice.itemDetails.length > 0) {
      let invLineItems = [];
      this.state.theInvoice.itemDetails.forEach((obj) => {
        // console.log("obj:", obj);
        let invobj = {
          itemId: obj.itemId,
          itemName: obj.itemName,
          itemType: obj.itemType,
          price: obj.price,
          quantity: obj.quantity,
          tax: "None",
          unit: obj.unitName,
        };
        // console.log("invobj:", invobj);
        invLineItems.push(invobj);
        // console.log("invLineItems:", invLineItems);
      });
      this.setState({
        invLineItems,
      });
    }

    let pendingRequest = 0;
    let activeRequests = [];
    let selectedRequest = null;
    if (this.state.theInvoice.refunds.length > 1) {
      this.state.theInvoice.refunds.forEach((ref) => {
        if (ref.status === "request") {
          pendingRequest++;
          activeRequests.push(ref);
          if ( ref.refundCancelId === this.props.refundCancelId ) {
            selectedRequest = ref;
          }
        }
      });
    }
    if (this.state.theInvoice.refunds.length === 1) {
      selectedRequest = this.state.theInvoice.refunds[0];
    }

    let refundMustBeInText = "";
    let refundPathCode = "CC";
    if (this.props.accessPath === "online") {
      if (this.state.theInvoice.invoiceCategory === "subscription") {
        refundMustBeInText = "You need to cancel subscription via creditcard";
      } else {
        refundMustBeInText = "You need to refund the creditcard";
      }
    } else {
      if (this.state.theInvoice.invoiceCategory === "subscription") {
        refundMustBeInText = "You need to cancel subscription via creditcard";
      } else if (this.state.theInvoice.initPayMedium === "creditcard") {
        refundMustBeInText =
          "You can refund in cash, check, or creditcard ... prefer ceditcard";
        refundPathCode = "any";
      } else {
        refundMustBeInText = "You can refund in cash, check.";
        refundPathCode = "cash";
      }
    }

    this.setState({
      refundMustBeInText,
      refundPathCode,
      selectedRequest,
      pendingRequest,
      activeRequests,
    });
  };

  // This is to show the info panel
  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getOpsInfo = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&invoiceId=" +
      this.props.invoiceId;
    let url = baandaServer + getRefundInfoForResponse + params;
    try {
      let retops = await axios.get(url);
      // console.log("retops.data:", retops.data);
      if (retops.data.status === "success") {
 
        let retcan = [];
        if (retops.data.Msg.refundCancel.length > 1) {
          retops.data.Msg.refundCancel.forEach((rc) => {
            if (rc.refundCancelId === this.props.refundCancelId) {
              retcan.push(rc);
            }
          });
        } else {
          retcan = [...retops.data.Msg.refundCancel];
        }
        this.setState({
          theInvoice: retops.data.Msg.theInvoice,
          // returnCancel: retops.data.Msg.refundCancel,
          returnCancel: retcan,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: retops.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  returnToReturnResponse = () => {
    this.props.returnToCaller();
  };

  handleRetCanOpsType = async (e) => {
    await this.setState({
      retCanOpsType: e.target.value,
    });
  };

  returnFromResponse = (msg) => {
    // console.log('returnFromResponse msg:', msg);
    if ( msg === 'done') this.props.returnToCaller();
    this.setState({
      retCanOpsType: "invoice",
      theInvoice: null,
      returnCancel: null,
    });
  };

  handleReqOptionSelected = async () => {
    // console.log("in shipPackageRetPostSelect");
    let value = document.getElementById("refundRequests").value;
    // console.log("value:", value);
    let valobj = JSON.parse(value);
    // console.log("valobj:", valobj);
    this.setState({
      selheightpkg: 1,
      selectedRequest: valobj,
    });
  };

  onfocuspkg = async () => {
    this.setState({
      selheightpkg: 5,
    });
  };

  onblurpkg = async () => {
    this.setState({
      selheightpkg: 1,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ReturnOpsMain...");

    let exitRetCanOpsButton;
    exitRetCanOpsButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToReturnResponse}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let opsSelectPanel = (
      <div className="row ret-can-ops-row">
        <div className="col text-center radio-font-style">
          <strong>View&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="invoice"
                checked={this.state.retCanOpsType === "invoice"}
                onChange={this.handleRetCanOpsType}
              />{" "}
              The Invoice
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="returncancel"
                checked={this.state.retCanOpsType === "returncancel"}
                onChange={this.handleRetCanOpsType}
              />{" "}
              Return-cancel log
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="respond"
                checked={this.state.retCanOpsType === "respond"}
                onChange={this.handleRetCanOpsType}
              />{" "}
              Respond
            </label>
          </div>
        </div>
      </div>
    );

    let theActivePanel;
    if (this.state.theInvoice && this.state.retCanOpsType === "invoice") {
      let bool = true;
      let shippingPrice = 0;
      if (this.state.theInvoice && this.state.theInvoice.invoiceRaw) {
        if (this.state.theInvoice.invoiceRaw.deliveryData) {
          if (
            this.state.theInvoice.invoiceRaw.deliveryData.selectedShipingPrice
          ) {
            shippingPrice =
              this.state.theInvoice.invoiceRaw.deliveryData.selectedShipingPrice
                .price;
          }
        }
      }
      theActivePanel = (
        <div>
          <div className="text-center ret-can-view-inv-head">
            Invoice Id: {this.props.invoiceId} | Refund Id:{" "}
            {this.props.refundCancelId}
          </div>
          <div className="text-left ret-can-view-inv-section">Customer</div>
          <div className="row ret-can-view-inv-rows">
            <div className="col-2 text-right ret-can-view-inv-lbl">Name</div>
            <div className="col-4 text-left ret-can-view-inv-txt">
              {this.state.theInvoice.customerName}
            </div>
            <div className="col-2 text-right ret-can-view-inv-lbl">Email</div>
            <div className="col-4 text-left ret-can-view-inv-txt">
              {this.state.theInvoice.customerEmail}
            </div>
          </div>
          <div className="row ret-can-view-inv-rows">
            <div className="col-2 text-right ret-can-view-inv-lbl">
              Pay Medium
            </div>
            <div className="col-4 text-left ret-can-view-inv-txt">
              {this.state.theInvoice.initPayMedium}
            </div>
            <div className="col-2 text-right ret-can-view-inv-lbl">
              Inv Date
            </div>
            <div className="col-4 text-left ret-can-view-inv-txt">
              {this.state.theInvoice.created_at.substring(0, 10)}
            </div>
          </div>
          <div className="text-left ret-can-view-inv-section">
            Invoice Finance
          </div>
          <div className="row ret-can-view-inv-rows">
            <div className="col-2 text-right ret-can-view-inv-lbl">Paid</div>
            <div className="col-4 text-left ret-can-view-inv-txt">
              ${" "}
              {this.commaFormattedCurrency(
                this.state.theInvoice.financeBreakdown.amountPaid
              )}
            </div>
            <div className="col-2 text-right ret-can-view-inv-lbl">
              Invoice Amt
            </div>
            <div className="col-4 text-left ret-can-view-inv-txt">
              ${" "}
              {this.commaFormattedCurrency(
                this.state.theInvoice.financeBreakdown.totalInvoiceAmount
              )}
            </div>
          </div>
          <div className="row ret-can-view-inv-rows">
            <div className="col-2 text-right ret-can-view-inv-lbl">
              Sales Tax
            </div>
            <div className="col-4 text-left ret-can-view-inv-txt">
              ${" "}
              {this.commaFormattedCurrency(
                this.state.theInvoice.financeBreakdown.salesTaxAmount
              )}
            </div>
            <div className="col-2 text-right ret-can-view-inv-lbl">
              Last pay Date
            </div>
            <div className="col-4 text-left ret-can-view-inv-txt">
              ${" "}
              {this.state.theInvoice.financeBreakdown.lastPaymentDate.substring(
                0,
                10
              )}
            </div>
          </div>
          <div className="row ret-can-view-inv-rows">
            <div className="col-2 text-right ret-can-view-inv-lbl">
              Shipping
            </div>
            {shippingPrice > 0 ? (
              <div className="col-4 text-left ret-can-view-inv-txt">
                $ {this.commaFormattedCurrency(shippingPrice)}
              </div>
            ) : (
              <div className="col-4 text-left ret-can-view-inv-txt">None</div>
            )}
            <div className="col-6 text-right ret-can-view-inv-lbl">&nbsp;</div>
          </div>

          <div className="row ret-can-inv-list-head-pos">
            <div className="col-10 text-left ret-can-inv-list-head-lbl">
              Item Name | type | price | quantity
            </div>
            <div className="col-2 text-left ret-can-inv-list-head-lbl">
              Cost
            </div>
          </div>
          <div className="ret-can-inv-line-item-box">
            {this.state.invLineItems.map((obj, i) => {
              let isRequested = false;

              this.state.returnCancel.forEach((irs) => {
                irs.toReturnItems.forEach((tri) => {
                  if (parseFloat(obj.itemId) === parseFloat(tri.itemId))
                    isRequested = true;
                });
              });

              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-10 text-left refresp-list-text">
                      {obj.itemName} | {obj.itemType} | $
                      {this.commaFormattedCurrency(obj.price)} | {obj.quantity}{" "}
                      &nbsp;{isRequested ? " | " : ""}
                      &nbsp;{isRequested ? <i className="fas fa-undo" /> : ""}
                    </div>
                    <div className="col-2 text-left refresp-list-text">
                      ${this.commaFormattedCurrency(obj.price * obj.quantity)}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
          <div className="text-center ret-can-view-inv-section">
            Refund Path: {this.state.refundMustBeInText}
          </div>
        </div>
      );
    }

    if (this.state.retCanOpsType === "returncancel" && this.state.theInvoice) {
      // if (this.state.selectedRequest) {
      let retcan;
      let inv = this.state.theInvoice;
      this.state.returnCancel.forEach((rc) => {
        if (rc.refundCancelId === this.props.refundCancelId) {
          retcan = rc;
        }
      });
      console.log("retcan:", retcan);
      let bool1 = true;
      theActivePanel = (
        <div>
          <div className="text-center ret-can-view-inv-head">
            Refund-Cancel Id: {this.props.refundCancelId}
          </div>
          <div className="text-left ret-can-view-inv-section">Customer</div>
          <div className="row ret-can-view-inv-rows">
            <div className="col-2 text-right ret-can-view-inv-lbl">Name</div>
            <div className="col-4 text-left ret-can-view-inv-txt">
              {inv.customerName}
            </div>
            <div className="col-2 text-right ret-can-view-inv-lbl">Email</div>
            <div className="col-4 text-left ret-can-view-inv-txt">
              {retcan.customerEmail}
            </div>
          </div>
          <div className="row ret-can-view-inv-rows">
            <div className="col-2 text-right ret-can-view-inv-lbl">
              Invoice Id
            </div>
            <div className="col-4 text-left ret-can-view-inv-txt">
              {inv.invoiceId}
            </div>
            <div className="col-2 text-right ret-can-view-inv-lbl">
              last Acted On
            </div>
            <div className="col-4 text-left ret-can-view-inv-txt">
              {retcan.lastActivityDate.substring(0, 10)}
            </div>
          </div>
          <div className="row ret-can-inv-list-head-pos">
            <div className="col-2 text-left ret-can-inv-list-head-lbl">
              Action By
            </div>
            <div className="col-8 text-left ret-can-inv-list-head-lbl">
              Comments
            </div>
            <div className="col-2 text-left ret-can-inv-list-head-lbl">
              Act Date
            </div>
          </div>
          <div className="ret-can-inv-log-lines">
            {retcan.requestResponse.map((obj, i) => {
              // console.log("690 obj:", obj);
              return (
                <div key={i}>
                  <div
                    className={
                      bool1
                        ? "row row-col-schdule-dr"
                        : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-2 text-center refresp-list-text">
                      {obj.actionBy}
                    </div>
                    <div className="col-8 text-left refresp-list-text">
                      <p align="justify" className="ret-can-comments-p">
                        {obj.refundCancelId === 0
                          ? " "
                          : obj.refundCancelId + ". "}{" "}
                        {obj.note}
                      </p>
                    </div>
                    <div className="col-2 center refresp-list-text">
                      {obj.actionDate.substring(0, 10)}
                    </div>
                  </div>
                  {(bool1 = !bool1)}
                </div>
              );
            })}
          </div>
          <div className="row ret-can-inv-list-head-pos">
            <div className="col-2 text-left ret-can-inv-list-head-lbl">
              Item Id
            </div>
            <div className="col-10 text-left ret-can-inv-list-head-lbl">
              Item Name | Price | quantity
            </div>
          </div>
          <div className="ret-can-inv-log-lines">
            {retcan.toReturnItems.map((obj, i) => {
              // console.log("690 obj:", obj);
              return (
                <div key={i}>
                  <div
                    className={
                      bool1
                        ? "row row-col-schdule-dr"
                        : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-2 text-center refresp-list-text">
                      {obj.itemId}
                    </div>
                    <div className="col-10 text-left refresp-list-text">
                      {obj.itemName} | ${this.commaFormattedCurrency(obj.price)}{" "}
                      | {obj.quantity}
                    </div>
                  </div>
                  {(bool1 = !bool1)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (this.state.retCanOpsType === "respond" && this.state.selectedRequest) {
      let store = null;
      if (this.props.store) store = this.props.store;
      let retcan;
      this.state.returnCancel.forEach((rc) => {
        if (rc.refundCancelId === this.state.selectedRequest.refundCancelId) {
          retcan = rc;
        }
      });
      theActivePanel = (
        <div>
          <ConductRefundCancel
            theInvoice={this.state.theInvoice}
            returnCancel={retcan}
            returnToCaller={this.returnFromResponse}
            refundPathCode={this.state.refundPathCode}
            communityId={this.props.communityId}
            community={this.props.community}
            invoiceId={this.props.invoiceId}
            refundCancelId={this.props.refundCancelId}
            selectedItems={this.props.selectedItems}
            invLineItems={this.state.invLineItems}
            store={store}
          />
        </div>
      );
    }

    let outputPanel = (
      <div>
        <div className="text-center ret-can-resp-header">
          Manage Returns & Cancels {exitRetCanOpsButton} {infoButton}
        </div>
        {opsSelectPanel}
        <div className="return-handling-ops-box">{theActivePanel}</div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ReturnOpsMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReturnOpsMain)
);
