import React, { Component } from "react";

import axios from 'axios';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./GLSearchFilter.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getGLMeta = "/routes/reports/getGLMeta?";

class GLSearchFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showGLRepFlag: true,
      showSearchFlag: false,
      dateSelectFlag: true,
      showCategorySelFlag: false,

      dateFirst: new Date(),
      dateSecond: new Date( new Date().setDate(new Date().getDate() + 1)),

      filterType: "all",
   

      categories: [],
      selectedCategory: null,

      glMeta: [],
      selectedGLMeta: null,
      selheightad: 1,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async() => {
    await this.getjounralAccPair();
  }

  getjounralAccPair = async() => {
    let url = baandaServer + getGLMeta;
    // console.log('url:', url);
    try {
       let metaret = await axios.get(url);
      //  console.log('metaret.data:', metaret.data);
       if ( metaret.data.status === 'success') {
        this.setState({
          glMeta: metaret.data.Msg,
          errMsg: '',
          errFlag: false
        });
       } else {
        this.setState({
          errMsg: metaret.data.Msg,
          errFlag: true
        });
       }
    } catch(err){
      this.setState({
        errMsg: err.message,
        errFlag: true
      });
    }
  }

  exitFilter = () => {
    let cond = null;
    this.props.returnToCaller(cond);
  };

  handleGLType = async (e) => {
    let value = e.target.value;
    let dateSelectFlag = false;
    let showCategorySelFlag = false;
    if (value === "all") dateSelectFlag = true;
    else showCategorySelFlag = true;
    this.setState({
      filterType: e.target.value,
      dateSelectFlag,
      showCategorySelFlag,
    });
  };

  handleDateFirst = async (date) => {
    let result = new Date(date);
    result.setDate(result.getDate() + 1);
    this.setState({
      dateFirst: date,
      dateSecond: result,
    });
  };

  handleDateSecond = async (date) => {
    this.setState({
      dateSecond: date,
    });
  };

  sendSearchCondition = () => {
    let cond = {
      dateFrom: this.state.dateFirst,
      dateTo: this.state.dateSecond,
      catDebit: '',
      catCredit: '',
      filterType: this.state.filterType,
      selectedCategory: this.state.selectedGLMeta,
      GLMeta: this.state.glMeta
    }
    // console.log('cond:', cond);
    this.props.returnToCaller(cond);
  }

  handleGLMetadata = async () => {
    let value = document.getElementById("glMetadata").value;
    // console.log("handleGLMetadata value:", value);
    let data = JSON.parse(value);
 
    this.setState({
      selectedGLMeta: data,
      selheightad: 1,
    });
  };

  onfocusa = async () => {
    // if (document.getElementById("fromAddressShip")) {
    //   document.getElementById("fromAddressShip").value = "DEFAULT";
    // }
    this.setState({
      selheightad: 9,
    });
  };

  onblura = async () => {
    this.setState({
      selheightad: 1,
    });
  };


  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('GLSearchFilter...');

    let searchButton = (
      <button
        onClick={this.sendSearchCondition}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_80"
      >
        Fetch <i className="fas fa-search-plus" />
      </button>
    );

    let exitButton = (
      <button
        onClick={this.exitFilter}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let glTypePanel;
    glTypePanel = (
      <div className="row gl-type-row">
        <div className="col text-center radio-font-style">
          Filter by&nbsp;&nbsp;
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="all"
                checked={this.state.filterType === "all"}
                onChange={this.handleGLType}
              />{" "}
              All (Details)
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="summary"
                checked={this.state.filterType === "summary"}
                onChange={this.handleGLType}
              />{" "}
              Summary (by Cat)
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="category"
                checked={this.state.filterType === "category"}
                onChange={this.handleGLType}
              />{" "}
              Category
            </label>
          </div>
          {/* <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="credit"
                checked={this.state.filterType === "credit"}
                onChange={this.handleGLType}
              />{" "} 
              Cat (credit-dtl)
            </label>
          </div> */}
        </div>
      </div>
    );

    let dateSelectionPanel;
    // if (this.state.dateSelectFlag) {
    dateSelectionPanel = (
      <div className="filter-gl-date-row">
        <div className="row">
          <div className="col-2 text-right gl-report-date-filter">From</div>
          <div className="col-4 text-left">
            <DatePicker
              selected={this.state.dateFirst}
              onChange={this.handleDateFirst}
              onSelect={this.handleDateFirst}
              name="prdob"
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="col-1 text-right gl-report-date-filter">To</div>
          <div className="col-5 text-left">
            <DatePicker
              selected={this.state.dateSecond}
              onChange={this.handleDateSecond}
              onSelect={this.handleDateSecond}
              name="prdob"
              dateFormat="MM/dd/yyyy"
              minDate={this.state.dateSecond}
            />
          </div>
        </div>
      </div>
    );
    // }

    let glMetaList;
    let glMetaPanel;
    if (this.state.filterType === "category") {
      glMetaList = this.state.glMeta.map((obj, i) => {
        return (
          <option key={i} value={JSON.stringify(obj)}>
            {obj.readableFnType}&nbsp;
          </option>
        );
      });
      glMetaPanel = (
        <div className="gl-filter-metadata-list-pos">
          <div className="row ship-addr-dropdown">
            <div className="col text-center ">
              <select
                size={this.state.selheightad}
                id="glMetadata"
                name="glMetadata"
                onChange={this.handleGLMetadata}
                className="list-gl-metadata"
                onFocus={this.onfocusa}
                onBlur={this.onblura}
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Select a category
                </option>
                {glMetaList}
              </select>
            </div>
          </div>
        </div>
      );
    }
   

    let outputPanel;
    outputPanel = (
      <div className="gl-filter-box">
        <div className="text-center ">
          GL Filter &nbsp;&nbsp;{searchButton}&nbsp;{exitButton}
        </div>
        {glTypePanel}
        {dateSelectionPanel}
        {glMetaPanel}
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default GLSearchFilter;
