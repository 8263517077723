import React, { Component } from "react";

import axios from "axios";

import "./ShowEventOfDay.css";

import ViewEvent from "./view/ViewEvent";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAnEvent = "/routes/inetract/getAnEvent?";

class ShowEventOfDay extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviseSize: "",
      selectedEventId: 0,

      showEvent: false,
      theEvent: null,
    };
  }

  componentDidMount = async () => {
    
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let te = this.props.todaysEvents.event.events;
    // console.log("te:", te);
    // console.log("eventId:", te[0].eventId);
    let theEvent = await this.getTheEvent(te[0].eventId);
    await this.setState({
      selectedEventId: te[0].eventId,
      theEvent,
      showEvent: true,
      deviceSize,
    });
  };

  returnToCalendar = () => {
    this.props.returnToCaller();
  };

  handleSelectedEvent = async (obj) => {
    // console.log("handle obj.eventId:", obj.eventId); 
    let theEvent = await this.getTheEvent(obj.eventId);
    // console.log('handleSelectedEvent theEvent:', theEvent);
    await this.setState({
      selectedEventId: obj.eventId,
      theEvent,
      showEvent: true
    });
  };

  getTheEvent = async (eventId) => {
    // console.log("getTheEvent eventId:", eventId);
    let param = "eventId=" + eventId;
    let theEvent = null;
    try {
      let url = baandaServer + getAnEvent + param;
      // console.log("url:", url);
      let reteve = await axios.get(url);
      // console.log("reteve:", reteve);
      theEvent = reteve.data.Msg; 
    } catch (err) {
      console.log("Err:", err.message);
    }
    return theEvent;
  };


  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ShowEventOfDay...');

    let te = this.props.todaysEvents;
    // console.log("te:", te);

    let eventIndexPanel = (
      <div>
        <div className="row">
          <div className="col text-left today-date">
            <b>Events of Date</b>:&nbsp;{te.event.calDateString}
          </div>
        </div>
        <div className="event-index-box">
          {te.event.events.map((obj, i) => {
            return (
              <div key={i}>
                <div className="row">
                  <div className="col">
                    <button
                      className={
                        this.state.selectedEventId === obj.eventId
                          ? "btn-each-event-active"
                          : "btn-each-event"
                      }
                      type="button"
                      onClick={() => this.handleSelectedEvent(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      {obj.shortName}
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );

    let buttonPanel = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.returnToCalendar}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Calendar
      </button>
    );

    let theEventPanel;
    if (this.state.showEvent) {
      theEventPanel = (
        <div>
          <ViewEvent
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            // hostBaandaId={this.state.theEvent.hostBaandaId}
            theEvent={this.state.theEvent}
            returnToCaller={this.returnFromPublish}
            caller="eventview"
          />
        </div>
      );
    }

    let outputPanel = (
      <div className="day-event-box">
        <div className="row">
          <div className="col-10 text-center">
            <h3>Today's Events</h3>
          </div>
          <div className="col-2 text-left">{buttonPanel}</div>
        </div>
        <div className="row">
          <div className="text-left col-md-3 col-sm-12">{eventIndexPanel}</div>
          <div className="col-md-9 col-sm-12">{theEventPanel}</div>
        </div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default ShowEventOfDay;