import React, { Component } from "react";

import axios from "axios";

import "./MyPaymentList.css";

import PaymentFiler from "./PaymentFilter";

import PaymentDetail from "./PaymentDetail";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getPaymentList = "/routes/reports/getPaymentList?";

class MyPaymentList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilter: false,
      showPayListFlag: false,
      showPaymentDetailFlag: false,
      showMainListPanel: true,
      payList: [],
      selectedPayObj: null,
    };
  }

  exitReport = () => {
    this.props.returnToCaller();
  };

  showSearchPanel = () => {
    this.setState({
      showFilter: true,
    });
  };

  returnFromFilter = async (cond) => {
    // console.log("cond:", cond);
    if (cond) {
      await this.getListOfPayments(cond);
    }
    this.setState({
      showFilter: false,
    });
  };

  getListOfPayments = async (cond) => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoerId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&dateFrom=" +
      cond.dateFrom +
      "&dateTo=" +
      cond.dateTo;
    let url = baandaServer + getPaymentList + params;
    // console.log("url:", url);
    try {
      let pret = await axios.get(url);
      if (pret.data.status === "success") {
        this.setState({
          payList: pret.data.Msg,
          showPayListFlag: true,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: pret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  formatDate = (date) => {
    let dt = date;
    if (!this.dateIsValid(date)) dt = new Date(date);
    return [
      this.padTo2Digits(dt.getMonth() + 1),
      this.padTo2Digits(dt.getDate()),
      dt.getFullYear(),
    ].join("/");
  };

  dateIsValid = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  showPaymentDetails = (obj) => {
    // console.log("obj:", obj);
    this.setState({
      showMainListPanel: false,
      showPaymentDetailFlag: true,
      selectedPayObj: obj,
    });
  };

  returnFromPaymentDetail = () => {
    this.setState({
      showPaymentDetailFlag: false,
      showPayListFlag: true,
      showFilter: false,
      showMainListPanel: true,
      selectedPayObj: null,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('MyPaymentList..');

    let exitButton = (
      <button
        onClick={this.exitReport}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let searchButton = (
      <button
        onClick={this.showSearchPanel}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_70"
      >
        Search
      </button>
    );

    let theHeaderLblPanel;
    theHeaderLblPanel = (
      <div className="row paylist-head-lbl-pos">
        <div className="col-1 text-center paylist-head-date-col">Date</div>
        <div className="col-8 text-left paylist-head-name-col">
          &nbsp;&nbsp;&nbsp;Payee Name (Email)
        </div>
        <div className="col-2 text-right paylist-head-amt-col">Amount $</div>
        <div className="col-1 text-center paylist-head-ops-col">Ops</div>
      </div>
    );

    let showTheListPanel;
    if (this.state.showPayListFlag) {
      if (this.state.payList.length > 0) {
        let bool = true;
        showTheListPanel = (
          <div>
            <div className="payment-list-scroll-box">
              {this.state.payList.map((obj, i) => {
                // console.log("690 obj:", obj);
                return (
                  <div key={i}>
                    <div
                      className={
                        bool ? "row gl-line-row-dr" : "row gl-line-row-lt"
                      }
                    >
                      <div className="col-1 text-center pay-line-lbl">
                        {this.formatDate(obj.paid_at)}
                      </div>
                      <div className="col-8 text-left pay-line-lbl-name">
                        {obj.partyName} ({obj.partyEmail})
                      </div>
                      <div className="col-2 text-right pay-line-lbl">
                        {this.commaFormattedCurrency(obj.paymentAmount)}
                      </div>
                      <div className="col-1 text-center">
                        <button
                          onClick={() => {
                            this.showPaymentDetails(obj);
                          }}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                          className="btn_reg_30"
                        >
                          ...
                        </button>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        showTheListPanel = (
          <div className="payment-list-scroll-box">
            No records available for the search condition.
          </div>
        );
      }
    } else {
      showTheListPanel = (
        <div className="payment-list-scroll-box">
          Select date-range in search for a list.
        </div>
      );
    }

    let searchPanel;
    if (this.state.showFilter) {
      let coopStoreId = 0;
      if (this.props.store) coopStoreId = this.props.store.coopStoreId;
      searchPanel = (
        <div>
          <PaymentFiler
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            returnToCaller={(cond) => this.returnFromFilter(cond)}
          />
        </div>
      );
    }

    let outputPanel;
    if (this.state.showMainListPanel) {
      outputPanel = (
        <div className="payment-present-box">
          <div className="text-center payment-list-header">
            Payment List &nbsp;&nbsp;{searchButton} {exitButton}
          </div>
          {searchPanel}
          {theHeaderLblPanel}
          {showTheListPanel}
        </div>
      );
    }

    if (this.state.showPaymentDetailFlag) {
      outputPanel = (
        <div className="payment-present-box">
          <PaymentDetail
            thePayment={this.state.selectedPayObj}
            returnToCaller={this.returnFromPaymentDetail}
          />
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

export default MyPaymentList;
