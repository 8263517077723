import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import "./SellCredits.css";

import PayForCCPlatform from "../../../../../../account/finance/communities/PayForCCPlatform";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const getServicePrice = "/routes/create/getServicePrice?";

const BaandaCommunityId = process.env.REACT_APP_BAANDA_COMMUNITY_ID;
const financialTransactionFee = process.env.REACT_APP_FINANCIAL_TRANSACTION_FEE;

class SellCredits extends Component {
  constructor(props) {
    super(props);

    this.state = {
      creditsTobuy: 0,
      note: "",

      creditsAvailable: 0,
      creditsAvailableMessage: "",
      baandaCommunity: null,

      showMainPurchaseFlag: true,
      takePaymentFlag: false,

      deviceSize: "",

      creditBuyFor: "shipping",
      paymentMedium: "creditcard",
      feeMsg: "Recalculate fee.",

      input: null,
      csFee: null,
      calculatedFee: 0,

      message: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let creditsAvailable = 0;
    if (this.props.community.credits.creditAvailable > 0)
      creditsAvailable = this.props.community.credits.creditAvailable;

    let baandaCommunity = await this.getCommunityInfo(BaandaCommunityId);
    await this.getServiceFee();

    let creditsAvailableMessage =
      "At the time of this load, you had $" +
      this.commaFormattedCurrency(
        this.props.community.credits.creditAvailable
      ) +
      " credits in your account.";

    await this.setState({
      creditsAvailable,
      deviceSize,
      baandaCommunity,
      creditsAvailableMessage,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      message: "",
      errFlag: false,
    });
  };

  getCommunityInfo = async (communityId) => {
    let parms = "communityId=" + parseFloat(communityId);
    let url = baandaServer + getCommunityInfo + parms;
    // console.log("getCommunityInfo url:", url);
    let community = null;
    try {
      let retData = await axios.get(url);
      // console.log("SellCredit retData: ", retData);
      if (retData.data.status === "success") {
        community = retData.data.Msg;
      } else {
        this.setState({
          message: retData.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }

    return community;
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  onChangeOfCreditsToBuy = async (e) => {
    let value = e.target.value;

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        creditsTobuy: val,
        message: "",
        errFlag: false,
        calculatedFee: 0,
        feeMsg: "Recalculate fee.",
      });
    } else {
      await this.setState({
        creditsTobuy: value,
        message: "",
        errFlag: false,
        calculatedFee: 0,
        feeMsg: "Recalculate fee.",
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  getServiceFee = async () => {
    try {
      let param = "serviceName=" + financialTransactionFee;

      let url = baandaServer + getServicePrice + param;
      // console.log("url:", url);
      let serPrice = await axios.get(url);
      // console.log("serPrice:", serPrice);
      if (serPrice.data.status === "success") {
        await this.setState({
          csFee: serPrice.data.Msg,
        });
      } else {
        console.log("Pricing has not been set");
      }
    } catch (err) {
      console.log("getServiceFee Err:", err.message);
    }
  };

  handleCreditBuy = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let input = {
      communityId: parseFloat(this.props.communityId),
      coopStoreId,
      commFinMMDD: this.props.community.finMMDD,
      commFinYYYY: this.props.community.finYYYY,
      baandaId: this.props.auth.user.baandaId,
      baandaEmail: this.props.auth.user.email,
      baandaCell: this.props.auth.user.cell.number,
      baandaName: this.props.auth.user.name,
      paymentMedium: this.state.paymentMedium,
      StripeAccId: this.state.baandaCommunity.stripe.id,
      description: "Purchase credits for shipping",
      paymentAmount: parseFloat(this.state.creditsTobuy),
      BaandaCommunityId: parseFloat(BaandaCommunityId),
      baandaComFinMMDD: this.state.baandaCommunity.finMMDD,
      baandaComFinYYYY: this.state.baandaCommunity.finYYYY,
      note: this.state.note,
      csFee: this.state.csFee,
      StripePaymentId: "",
    };

    // console.log("input:", input);

    if (parseFloat(this.state.creditsTobuy) < 20) {
      this.setState({
        message: "Minimum credit purchase amount is $20.00",
        errFlag: true,
      });
    } else {
      this.setState({
        showMainPurchaseFlag: false,
        takePaymentFlag: true,
        input,
      });
    }
  };

  handleBackFromPayment = async (status) => {
    // console.log("status:", status);
    if (status === "success") {
      let comm = await this.getCommunityInfo(this.props.communityId);
      // console.log("comm:", comm);
      if (comm) {
        await this.setState({
          creditAvailable: comm.credits.creditAvailable,
          creditsAvailableMessage:
            "Current credits available is $" +
            this.commaFormattedCurrency(comm.credits.creditAvailable) +
            " in your account.",
          creditsTobuy: 0,
          note: "",
          message: "Credits purchased successfully.",
          errFlag: false,
        });
      }
    } else {
      await this.setState({
        message: "Failed to purchase credits. ",
        errFlag: true,
      });
    }
    await this.setState({
      showMainPurchaseFlag: true,
      takePaymentFlag: false,
    });
  };

  calculateFee = () => {
    if (parseFloat(this.state.creditsTobuy) >= 20) {
      let stripeFee = parseFloat(this.state.creditsTobuy) * 0.029 + 0.3;
      let baandaFee =
        parseFloat(this.state.creditsTobuy) *
          (parseFloat(this.state.csFee.creditsCharged) / 100) +
        parseFloat(this.state.csFee.minimumAmt) / 100;
      let totalFee = stripeFee + baandaFee;
      this.setState({
        calculatedFee: totalFee,
        feeMsg:
          "Banking / financial transaction fee is $" +
          this.commaFormattedCurrency(totalFee) +
          ". The credit posted will be $" +
          this.commaFormattedCurrency(
            parseFloat(this.state.creditsTobuy) - totalFee
          ) +
          ". The overall shipping cost will be compareable $" +
          this.commaFormattedCurrency(parseFloat(this.state.creditsTobuy)) +
          " to direct post office cost and time, plust the transparent accounting.",
        message: "",
        errFlag: false,
      });
    } else {
      this.setState({
        message:
          "Minimum amount is $20 for fee calculation or credit purchase.",
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('SellCredit...');

    let buyCreditButton = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={this.handleCreditBuy}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Buy
      </button>
    );

    let outputPanel;
    outputPanel = (
      <div className="fin-sell-credits-box">
        <div className="text-center buy-credit-header">
          Purchase Shipping Credits
        </div>

        <div className="text-center present-credits-available">
          {this.state.creditsAvailableMessage}
        </div>
        <div className="row credits-buy-input-row">
          <div className="col-4 text-right credits-buy-lbl">Amount to buy</div>
          <div className="col-8 text-left">
            $
            <input
              name="creditsTobuy"
              type="number"
              value={this.state.creditsTobuy}
              onChange={this.onChangeOfCreditsToBuy}
              className="credits-to-buy-text"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            &nbsp;
            <button
              className="btn_reg_80"
              onClick={this.calculateFee}
              type="button"
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Calc Fee
            </button>
          </div>
        </div>
        <div className="row credit-note-row">
          <div className="col-4 text-right credits-buy-lbl">Note</div>
          <div className="col-8 text-left">
            <textarea
              name="note"
              maxLength="1000"
              placeholder="Keep a note for reference (optional)."
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="buy-credit-note"
              onChange={this.onChange}
              value={this.state.note}
              required
            />
          </div>
        </div>
        <div className="text-center buy-credit-message-row">
          <p align="justify" className="credit-buy-rules">
            Baanda credits are for shipping only at this time. This is a
            convenience provided so you can ship multiple packages without
            having to re-enter credit card information. Also, buying in bulk
            reduces your banking transaction fees.
          </p>
          <p align="justify" className="credit-buy-rules">
            We require a minimum $20 credit purchase. If you go below the
            minimum needed to ship your next parcel, the system will ask for
            credit card information. You can always return here to raise your
            credit level to avoid the inconvenience.
          </p>
          <p align="justify" className="credit-buy-rules">
            Note: Whether you buy credits or pay one-at-a-time, financial
            transaction fees will be charged by banks. Therefore, the credits in
            your account will show less than the amount you purchased.
          </p>
        </div>

        <div className="text-center credit-buy-button-pos">
          {buyCreditButton}
        </div>
        <div className="text-center credit-fee-msg">{this.state.feeMsg}</div>
        <div
          className={
            this.state.errFlag
              ? "text-center sell-credit-msg-err"
              : "text-center sell-credit-msg"
          }
        >
          {this.state.message}
        </div>
      </div>
    );

    let ccPanel;
    if (this.state.takePaymentFlag) {
      ccPanel = (
        <PayForCCPlatform
          //   inputData={this.state.baandaCommunity}
          deviceSize={this.state.deviceSize}
          paymentAmount={parseFloat(this.state.creditsTobuy)}
          handleExit={this.handleBackFromPayment}
          inputData={this.state.input}
          caller="creditbuy"
        />
      );
      outputPanel = <div className="fin-sell-credits-box">{ccPanel}</div>;
    }

    return <div>{outputPanel}</div>;
  }
}

SellCredits.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

//   const mapDispatchToProps = (dispatch) => ({
//     hideModal: () => dispatch(hideModal()),
//     showModal: (modalProps, modalType) => {
//       dispatch(showModal({ modalProps, modalType }));
//     },
//   });

export default connect(mapStateToProps)(withRouter(SellCredits));
