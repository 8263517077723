import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import axios from "axios";

import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import "./ManageDiscount.css";
// import { isValidPhoneNumber } from "react-phone-number-input";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateCatalogItem = "/routes/dashboard/updateCatalogItem";
const getACatalogItem = "/routes/dashboard/getACatalogItem?";

const readMeCode = "1112300000";

class ManageDiscount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      opsType: "sales",

      allowDiscount: false,
      begindate: new Date(),
      enddate: moment().add(1, "days").toDate(),

      salesName: "",
      salesPercentage: 0.0,
      salesStart: new Date(),
      salesEnd: new Date(),

      couponName: "",
      couponPercentage: 0.0,
      couponNumber: "",
      couponStart: new Date(),
      couponEnd: new Date(),

      specialName: "",
      specialStart: new Date(),
      specialEnd: new Date(),

      discounts: [],
      priorDiscounts: [],
      errMsg: "",
      errFlag: false,

      catalog: null,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getACatalog();

    await this.setState({
      deviceSize,
    });
  };

  openAlertModal = () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  setDBDiscounts = async () => {
    let discounts = this.state.discounts;
    let priorDiscounts = [];
    if (
      this.state.catalog.discounts &&
      this.state.catalog.discounts.length > 0
    ) {
      this.state.catalog.discounts.forEach((obj) => {
        if (moment().isBefore(moment(obj.endDate))) {
          let discobj = {
            inDb: true,
            type: obj.type,
            name: obj.name,
            startDate: obj.startDate,
            endDate: obj.endDate,
            percentage: obj.percentage,
            code: obj.code,
          };
          discounts.push(discobj);
        } else {
          let discobj = {
            inDb: true,
            type: obj.type,
            name: obj.name,
            startDate: obj.startDate,
            endDate: obj.endDate,
            percentage: obj.percentage,
            code: obj.code,
          };
          priorDiscounts.push(discobj);
        }
      });
    }
    await this.setState({
      discounts,
      priorDiscounts,
    });
  };

  getACatalog = async () => {
    let param = "itemId=" + this.props.catalog.itemId;
    let url = baandaServer + getACatalogItem + param;
    try {
      let retcat = await axios.get(url);
      if (retcat.data.status === "success") {
        this.setState({
          catalog: retcat.data.Msg,
        });
        await this.setDBDiscounts();
      } else {
        this.setState({
          errMsg: retcat.data.Msg,
          errFlag: false,
        });
      }
    } catch (err) {
      console.log("getACatalog err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: false,
      });
    }
  };

  onChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangePercentage = async (e) => {
    let value = e.target.value;

    if (value < 100 && value >= 0) {
      if (this.countDecimals(value) > 2) {
        let tmp = parseFloat(value).toFixed(2);
        let val = parseFloat(tmp);
        await this.setState({
          [e.target.name]: val,
        });
      } else {
        if (this.state.opsType === "sales") {
          await this.setState({
            [e.target.name]: value,
            salesPercentage: value,
          });
        }
        if (this.state.opsType === "coupons") {
          await this.setState({
            [e.target.name]: value,
            couponPercentage: value,
          });
        }
      }
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  returnToCatalog = () => {
    this.props.returnToCaller(this.state.catalog);
  };

  selectOpsType = async (e) => {
    this.setState({
      opsType: e.target.value,
      errMsg: "",
      errFlag: false,
    });
  };

  handleBeginDate = async (date) => {
    // console.log("1 date:", date);
    let endDate = moment(date).add(1, "days").local().toDate();
    // console.log("endDate", endDate);
    this.setState({
      begindate: date,
      enddate: endDate,
    });
  };

  handleEndDate = async (date) => {
    // console.log("2 date:", date);
    this.setState({
      enddate: date,
    });
  };

  validateSetting = async () => {
    let msg = "";
    let state = "success";
    if (this.state.opsType === "coupons" && this.state.couponName === "") {
      msg = "Coupon must have a name; ";
      state = "error";
    }
    if (this.state.opsType === "coupons" && this.state.couponPercentage === 0) {
      msg += "Coupon's percentage discount cannot be 0; ";
      state = "error";
    }
    if (this.state.opsType === "sales" && this.state.salesPercentage === 0) {
      msg += "Sales percentage discount cannot be 0; ";
      state = "error";
    }
    if (this.state.opsType === "sales" && this.state.salesName === "") {
      msg += "Sales must have a name; ";
      state = "error";
    }

    return { status: state, Msg: msg };
  };

  handleThisSet = async () => {
    let isValid = await this.validateSetting();
    // console.log("isValid:", isValid);

    if (isValid.status === "success") {
      let discobj;
      let disc = [...this.state.discounts];
      let errMsg = "Done " + this.state.opsType + " setting";
      let errFlag = false;
      let itExists = false;
      this.state.discounts.forEach((obj) => {
        if (obj.type === this.state.opsType) {
          itExists = true;
        }
      });

      let discarr = [];
      if (!itExists) {
        if (this.state.opsType === "sales") {
          discobj = {
            inDb: false,
            type: this.state.opsType,
            name: this.state.salesName,
            startDate: this.state.begindate,
            endDate: this.state.enddate,
            percentage: this.state.salesPercentage,
            code: "",
          };

          disc.forEach((obj) => {
            if (!obj.inDB && obj.type !== "sales") {
              discarr.push(obj);
            }
          });
          discarr.push(discobj);
        }
        if (this.state.opsType === "coupons") {
          discobj = {
            inDb: false,
            type: this.state.opsType,
            name: this.state.couponName,
            startDate: this.state.begindate,
            endDate: this.state.enddate,
            percentage: this.state.couponPercentage,
            code: this.state.couponNumber,
          };

          disc.forEach((obj) => {
            if (!obj.inDB && obj.type !== "coupons") {
              discarr.push(obj);
            }
          });
          discarr.push(discobj);
        }
        if (this.state.opsType === "special") {
          discobj = {
            inDb: false,
            type: this.state.opsType,
            name: this.state.specialName,
            startDate: this.state.begindate,
            endDate: this.state.enddate,
            percentage: 0,
            code: "",
          };

          disc.forEach((obj) => {
            if (!obj.inDB && obj.type !== "special") {
              discarr.push(obj);
            }
          });
          discarr.push(discobj);
        }
      } else {
        discarr = this.state.discounts;
        errMsg =
          "A '" +
          this.state.opsType +
          "' is already set. There can be only one active '" +
          this.state.opsType +
          "' at a time. Remove and then reset for a new " +
          this.state.opsType;
        errFlag = true;
      }

      this.setState({
        discounts: discarr,
        errMsg,
        errFlag,
      });
    } else {
      this.setState({
        errFlag: true,
        errMsg: isValid.Msg,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleDelete = async (obj) => {
    // console.log("handleDelete obj:", obj);
    let tempArr = [];
    this.state.discounts.forEach((elm) => {
      if (elm.type !== obj.type) {
        tempArr.push(elm);
      }
    });

    await this.setState({
      discounts: tempArr,
    });
  };

  setUpdtInput = async () => {
    let toUpdtDisc = this.state.priorDiscounts;
    this.state.discounts.forEach((obj) => {
      toUpdtDisc.push(obj);
    });

    let discountEnabled = true;
    if (this.state.opsType === "stop") discountEnabled = false;

    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let input = {
      communityId: this.props.communityId,
      coopStoreId,
      updtmode: "discounts",
      discounts: toUpdtDisc,
      itemId: this.state.catalog.itemId,
      discountEnabled,
      clientProgram: "ManageDiscount.js",
      clientFunction: "handleSave",
    };
    return input;
  };

  setPostSave;

  handleSave = async () => {
    let input = await this.setUpdtInput();
    // console.log("input:", input);
    try {
      let url = baandaServer + updateCatalogItem;
      let retupdt = await axios.post(url, input);
      // console.log("retupdt:", retupdt);
      if (retupdt.data.status === "success") {
        let discounts = [];
        let priorDiscounts = [];
        retupdt.data.Msg.discounts.forEach((elm) => {
          let dobj = {
            code: elm.code,
            endDate: elm.endDate,
            name: elm.name,
            percentage: elm.percentage,
            startDate: elm.startDate,
            type: elm.type,
          };
          if (moment().isBefore(moment(elm.endDate))) {
            discounts.push(dobj);
          } else {
            priorDiscounts.push(dobj);
          }
        });

        this.setState({
          catalog: retupdt.data.Msg,
          discounts,
          priorDiscounts,
          errMsg: "Successfully updated",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: retupdt.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("handleSave err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleAllowDiscount = async () => {
    await this.setState((prevstate) => ({
      allowDiscount: !prevstate.allowDiscount,
    }));
  };

  padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  formatDate = (date) => {
    let dt = new Date(date);
    return [
      this.padTo2Digits(dt.getMonth() + 1),
      this.padTo2Digits(dt.getDate()),
      dt.getFullYear(),
    ].join("/");
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("MAnageDiscount...");

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let datePanel;
    datePanel = (
      <div className="text-center">
        <div className="row">
          <div className="col-md-6">
            Starts On&nbsp;
            <DatePicker
              selected={this.state.begindate}
              onSelect={this.handleBeginDate}
              onChange={this.handleBeginDate}
              minDate={new Date()}
              name="begindate"
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="col-md-6">
            Ends On&nbsp;
            <DatePicker
              selected={this.state.enddate}
              onChange={this.handleEndDate}
              // minDate={this.state.begindate}
              minDate={moment(this.state.begindate)
                .add(1, "days")
                .local()
                .toDate()}
              name="enddate"
              dateFormat="MM/dd/yyyy"
            />
          </div>
        </div>
      </div>
    );

    let opsSelectPanel = (
      <div className="text-center ops-select-row">
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Discount type&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="sales"
                  checked={this.state.opsType === "sales"}
                  onChange={this.selectOpsType}
                />{" "}
                Sales
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="coupons"
                  checked={this.state.opsType === "coupons"}
                  onChange={this.selectOpsType}
                />{" "}
                Coupons
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="list"
                  checked={this.state.opsType === "list"}
                  onChange={this.selectOpsType}
                />{" "}
                List Current
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let buttonPanel;
    buttonPanel = (
      <div className="cat-disc-button-row">
        {this.state.opsType !== "list" && this.state.opsType !== "stop" ? (
          <button
            className="btn_reg_80"
            type="button"
            onClick={this.handleThisSet}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Set This
          </button>
        ) : null}
        {/* {this.state.discounts.length > 0 || this.state.opsType === "stop" ? ( */}
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.handleSave}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>
        {/* ) : null} */}

        <button
          className="btn_back_main"
          type="button"
          onClick={this.returnToCatalog}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
        <div
          className={
            !this.state.errFlag
              ? "text-center cat-dis-msg"
              : "text-center cat-dis-msg-err"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    let salesPanel;
    if (this.state.opsType === "sales") {
      salesPanel = (
        <div className="">
          <div className="row cat-mgmt-input-row">
            <div className="col-3 cat-mng-lbl text-right">Name</div>
            <div className="col-9 cat-mng-lbl text-left">
              <input
                name="salesName"
                type="text"
                value={this.state.salesName}
                onChange={this.onChange}
                size="35"
                maxLength="35"
                className="catalog-sales-name"
                placeholder="Give the sale a name."
              />{" "}
            </div>
          </div>
          <div className="row cat-mgmt-input-row">
            <div className="col-3 cat-mng-lbl text-right">Percentage</div>
            <div className="col-9 cat-mng-lbl text-left">
              <input
                name="salesPercentage"
                type="number"
                value={this.state.salesPercentage}
                onChange={this.onChangePercentage}
                // size="10"
                className="catalog-sales-percentage"
                step=".01"
                max="50"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="text-center cat-sales-msg">
            The sale will be available for all buyers during the period stated.
          </div>
        </div>
      );
    }

    let couponPanel;
    if (this.state.opsType === "coupons") {
      couponPanel = (
        <div className="">
          <div className="row cat-mgmt-input-row">
            <div className="col-3 cat-mng-lbl text-right">Name</div>
            <div className="col-9 cat-mng-lbl text-left">
              <input
                name="couponName"
                type="text"
                value={this.state.couponName}
                onChange={this.onChange}
                size="35"
                maxLength="35"
                className="catalog-sales-name"
                placeholder="Coupon Name"
              />{" "}
            </div>
          </div>
          <div className="row cat-mgmt-input-row">
            <div className="col-3 cat-mng-lbl text-right">Percentage</div>
            <div className="col-9 cat-mng-lbl text-left">
              <input
                name="couponPercentage"
                type="number"
                value={this.state.couponPercentage}
                onChange={this.onChangePercentage}
                // size="10"
                className="catalog-sales-percentage"
                step=".01"
                max="50"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="row cat-mgmt-input-row">
            <div className="col-3 cat-mng-lbl text-right">Coupon Code</div>
            <div className="col-9 cat-mng-lbl text-left">
              <input
                name="couponNumber"
                type="text"
                value={this.state.couponNumber}
                onChange={this.onChange}
                size="15"
                maxLength="15"
                className="catalog-coupon-number"
                placeholder="alphanumeric"
              />{" "}
            </div>
          </div>
          <div className="text-center cat-sales-msg">
            This discount will be available within the date range when a buyer
            enters the stated coupon code.
          </div>
        </div>
      );
    }

    let specialPanel;
    if (this.state.opsType === "special") {
      specialPanel = (
        <div className="">
          <div className="row cat-mgmt-input-row">
            <div className="col-3 cat-mng-lbl text-right">Name</div>
            <div className="col-9 cat-mng-lbl text-left">
              <input
                name="specialName"
                type="text"
                value={this.state.specialName}
                onChange={this.onChange}
                size="35"
                maxLength="35"
                className="catalog-sales-name"
                placeholder="Name of the special offering."
              />{" "}
            </div>
          </div>
          {/* <div className="row cat-mgmt-input-row">
            <div className="col-3 cat-mng-lbl text-right">Percentage</div>
            <div className="col-9 cat-mng-lbl text-left">
              <input
                name="salesPercentage"
                type="number"
                value={this.state.salesPercentage}
                onChange={this.onChangePercentage}
                // size="10"
                className="catalog-sales-percentage"
                step=".01"
                max="50"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          </div> */}
          <div className="text-center cat-sales-msg">
            This is offered via manual evaluation by owner and only at in-person
            sell.
          </div>
        </div>
      );
    }

    let listPanel;
    if (this.state.opsType === "list") {
      if (this.state.discounts.length > 0) {
        listPanel = (
          <div className="fixedsize_cat_disc_list">
            {this.state.discounts.map((obj, i) => {
              return (
                <div key={i}>
                  <div className="row">
                    <div className="col-1 text-right">
                      <button
                        className="btn_exit_del cat-disc-list-del"
                        type="button"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                        onClick={() => this.handleDelete(obj)}
                      >
                        <i className="fas fa-trash-alt" />
                      </button>
                    </div>
                    <div className="col-5 text-left cat-disc-list-lbl">
                      {obj.name}
                    </div>
                    <div className="col-6 text-left cat-disc-list-lbl">
                      {obj.type}&nbsp;({this.formatDate(obj.startDate)} to{" "}
                      {this.formatDate(obj.endDate)})&nbsp;@
                      {this.commaFormattedCurrency(obj.percentage)}%{" "}
                      {obj.inDB ? "From DB" : "New"}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      } else {
        listPanel = (
          <div className="text-center list-panel-msg">
            No active sales or discounts have been set.
          </div>
        );
      }
    }

    let outputPanel;
    outputPanel = (
      <div className="cat-discount-box">
        <div className="cat-discount-head">
          Manage Discount&nbsp;&nbsp;{infoButton}
        </div>
        {opsSelectPanel}

        {this.state.opsType !== "list" && this.state.opsType !== "stop"
          ? datePanel
          : null}
        {salesPanel}
        {couponPanel}
        {specialPanel}
        {listPanel}
        {buttonPanel}
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ManageDiscount.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageDiscount)
);

// export default ManageDiscount;
