import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import "./Contact.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveUpdtEvent = "/routes/interact/saveUpdtEvent";
const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";

class Contact extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      theEvent: null,
      eventId: 0,

      line1: "",
      line2: "",
      state: "",
      city: "",
      zip: "",
      country: "US",
      email: "",
      name: "",
      phone: "",

      contact: {
        name: "",
        email: "",
        phone: "",
        address: {
          line1: "",
          line2: "",
          city: "",
          state: "",
          zip: "",
          country: "US",
        },
      },
      contactMsg: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    if (this.props.theEvent && this.props.theEvent.contact.name !== "") {
      let con = this.props.theEvent.contact;
      await this.setState({
        eventId: this.props.theEvent.eventId,
        line1: con.address.line1,
        line2: con.address.line2,
        city: con.address.city,
        zip: con.address.zip,
        state: con.address.state,
        country: con.address.country,
        email: con.email,
        name: con.name,
        phone: con.phone,
        theEvent: this.props.theEvent,
        deviceSize,
      });
    } else {
      await this.setState({
        deviceSize,
        name: this.props.auth.user.name,
        email: this.props.auth.user.email,
        phone: this.props.auth.user.cell.number,
        theEvent: this.props.theEvent,
        eventId: this.props.theEvent.eventId,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  checkAddress = async () => {
    let param =
      "street1=" +
      this.state.line1 +
      "&city=" +
      this.state.city +
      "&zip=" +
      this.state.zip +
      "&state=" +
      this.state.state +
      "&country=" +
      this.state.country;
    let url = baandaServer + easypostAddressCheck + param;
    let status = "success";
    let msg = "";
    try {
      let check = await axios.get(url);
      // console.log("check:", check);
      if (check.data.status === "success") {
        this.setState({
          contactMsg: "",
          errFlag: false,
        });
      } else {
        status = "error";
        msg = check.data.Msg;
      }
    } catch (err) {
      console.log("err:", err.message);
      status = "error";
      msg = err.message;
    }

    return { status, Msg: msg };
  };

  packDataForDB = async () => {
    let addr = {};
    let isValid = 'success';
    let msg = '';
    if ( this.state.line1 !== '') {
      addr = {
        line1: this.state.line1,
        line2: this.state.line2,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
      };
      let check = await this.checkAddress();
      // console.log('check:', check);
      if ( check.status === 'error') {
        isValid = 'error';
        msg = check.Msg;
      }
    } else {
      addr = {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
      };
    }

    let input;
    if ( isValid === 'success') {
        input = {
        eventId: this.state.eventId,
        communityId: this.props.communityId,
        contact: {
          name: this.state.name,
          email: this.state.email,
          phone: this.state.phone,
          address: addr,
        },
        module: "contact",
        clientProgram: "contact.js",
        clientFunction: "saveUpdateContact",
      };
    } else {
      input = msg;
    }
    

    return { status: isValid, Msg: input};
  };

  saveUpdateContact = async () => {
    let url = baandaServer + saveUpdtEvent;

    let inp = await this.packDataForDB();
    // console.log('inp:', inp);
    if ( inp.status === 'success') {
      // console.log(" url:", url, " inp:", inp);
      
      let resp = await axios.post(url, inp.Msg);
      // console.log(">>>>> rest:", resp);
      if (resp.data.status === "success") {
  
        let locEvent = resp.data.Msg.theEvent;
  
        this.setState({
          contactMsg: "Successfully Updated",
          eventId: resp.data.Msg.eventId,
          theEvent: locEvent,
        });
  
        // await this.props.returnToCaller(locEvent);
      }
    } else {
      console.log('should be here .... ')
      this.setState({
        contactMsg: inp.Msg,
      });
    }

  };

  // returnToOrigin = () => {
  //   this.props.returnToCaller(this.state.theEvent);
  // };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('Contact...');
   

    let buttonPanel;
    if (
      this.props.theEvent.eventStatus === "published" ||
      this.props.theEvent.eventStatus === "canceled"
    ) {
      buttonPanel = (
        <div className="text-center post-publish-notify-msg">
          For a published event, this is for view only. You can copy this and
          re-publish.
        </div>
      );
    } else {
      buttonPanel = (
        <div>
          <button
            className="btn_reg_60"
            type="button"
            onClick={this.saveUpdateContact}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Save
          </button>
        </div>
      );
    }

    let name, email, phone, street, line2, city, state, zip;
    if (this.state.deviceSize === "small") {
      name = "Name";
      email = "Email";
      phone = "Phone";
      street = "Street";
      line2 = "Strt-2";
      city = "City";
      state = "State";
      zip = "Zip";
    } else {
      name = "Name";
      email = "Email";
      phone = "Phone";
      street = "Street Address";
      line2 = "Street line2";
      city = "City";
      state = "State";
      zip = "Zip";
    }

    let contactPanel;
    contactPanel = (
      <div>
        <div className="row">
          <div className="col-3 event-contact-lbl text-right">{name}</div>
          <div className="col-9 text-left">
            <input
              name="name"
              type="text"
              value={this.state.name}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="event-contact-text"
              placeholder="Contact Name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3 event-contact-lbl text-right">{email}</div>
          <div className="col-9 text-left">
            <input
              name="email"
              type="text"
              value={this.state.email}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="event-contact-text"
              placeholder="Contact's Emails"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3 event-contact-lbl text-right">{phone}</div>
          <div className="col-9 text-left">
            <PhoneInput
              placeholder="Contact cell#"
              value={this.state.phone}
              onChange={(phone) => this.setState({ phone })}
              country="US"
              className="event-contact-phone"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3 event-contact-lbl text-right">{street}</div>
          <div className="col-9 text-left">
            <input
              name="line1"
              type="text"
              value={this.state.line1}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="event-contact-text"
              placeholder="Street Address - Optional"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3 event-contact-lbl text-right">{line2}</div>
          <div className="col-9 text-left">
            <input
              name="line2"
              type="text"
              value={this.state.line2}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="event-contact-text"
              placeholder="Address"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3 event-contact-lbl text-right">{city}</div>
          <div className="col-9 text-left">
            <input
              name="city"
              type="text"
              value={this.state.city}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="event-contact-text"
              placeholder="City"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-3 event-contact-lbl text-right">{state}</div>
          <div className="col-2 text-left">
            <input
              name="state"
              type="text"
              value={this.state.state}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="event-contact-state"
              placeholder="2-Chars"
            />
          </div>
          <div className="col-3 event-contact-lbl text-right">{zip}</div>
          <div className="col-4 text-left">
            <input
              name="zip"
              type="text"
              value={this.state.zip}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="event-contact-zip"
              placeholder="Zip"
            />
          </div>
        </div>
        {buttonPanel}
        <div className="text-center contact-msg">{this.state.contactMsg}</div>
      </div>
    );

    return (
      <div>
        <div className="text-center">Contact</div>
        {contactPanel}
      </div>
    );
  }
}

Contact.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// const mapDispatchToProps = (dispatch) => ({
//   hideModal: () => dispatch(hideModal()),
//   showModal: (modalProps, modalType) => {
//     dispatch(showModal({ modalProps, modalType }));
//   },
// });

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(withRouter(Contact));
