import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import RegisterPayableInvoice from "./payables/RegisterPayableInvoice";
import ListPayables from "./payables/ListPayables";

import "./LiabilityMain.css";

const readMeCode = "1111250000";

class LiabilityMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      payableOpsType: "",

      showOpsFlags: true,
      showRegisterInvoiceFlag: false,
      showListOfPayablesFlag: false,
    };
  }

  componentDidMount = async () => {};

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleOpsType = async (e) => {
    let value = e.target.value;
    let showRegisterInvoiceFlag = false;
    let showListOfPayablesFlag = false;
    if (value === "new") showRegisterInvoiceFlag = true;
    if (value === "payable") showListOfPayablesFlag = true;
    this.setState({
      payableOpsType: e.target.value,
      showRegisterInvoiceFlag,
      showListOfPayablesFlag,
    });
  };

  returnFromActions = () => {
    // console.log('return from ation ...')
    this.setState({
      showRegisterInvoiceFlag: false,
      showListOfPayablesFlag: false,
      showOpsFlags: true,
      payableOpsType: "",
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('LiabilityMain...');

    let modalHelpButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let opsTypePanel;
    opsTypePanel = (
      <div className="row rec-main-select-ops-row">
        <div className="col text-center radio-font-style">
          <strong>Operation Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="payable"
                checked={this.state.payableOpsType === "payable"}
                onChange={this.handleOpsType}
              />{" "}
              Payables
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="new"
                checked={this.state.payableOpsType === "new"}
                onChange={this.handleOpsType}
              />{" "}
              Invoice Received
            </label>
          </div>
        </div>
      </div>
    );

    let outputPanel;
    if (this.state.showOpsFlags) {
      outputPanel = (
        <div className="liability-main-box">
          <div className="lia-header text-center">
            Liability Management &nbsp;&nbsp;{modalHelpButton}
          </div>
          {opsTypePanel}
        </div>
      );
    }

    if (this.state.showRegisterInvoiceFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputPanel = (
        <div className="liability-main-box">
          <RegisterPayableInvoice
            store={store}
            community={this.props.community}
            role={this.props.role}
            returnToCaller={this.returnFromActions}
          />
        </div>
      );
    }

    if (this.state.showListOfPayablesFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputPanel = (
        <div className="liability-main-box">
          <ListPayables
            store={store}
            community={this.props.community}
            role={this.props.role}
            returnToCaller={this.returnFromActions}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

LiabilityMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LiabilityMain));
