import React, { Component } from "react";

import axios from "axios";

import "./InventoryMove.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getItemToEdit = "/routes/dashboard/getItemToEdit?";
const getAnItemFromIM = "/routes/dashboard/getAnItem?";
const moveItems = "/routes/dashboard/moveItems";

class InventoryMove extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      moveType: "all",
      moveDirection: "tocatalog",
      catalog: null,
      item: null,

      totalCatalogQty: 0,
      totalItemMasterQty: 0,
      subMergedArray: [],

      toMoveQty: 0,
      selectedItem: null,

      moveFlag: false,
 
      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getAnItemFromItemMaster();
    await this.getCatalogItem();
    await this.mergeCatIMSubItems();

    let totalCatalogQty = 0;
    this.state.catalog.itemVariance.forEach((obj) => {
      totalCatalogQty = totalCatalogQty + obj.quantity;
    });

    let totalItemMasterQty = 0;
    this.state.item.itemVariance.forEach((obj) => {
      totalItemMasterQty = totalItemMasterQty + obj.quantity;
    });

    await this.setState({
      deviceSize,
      totalCatalogQty,
      totalItemMasterQty,
    });
  };

  onChangePrice = async (e) => {
    // let yy = [e.target.name];
    // console.log("yy:", yy);
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleSubItemMove = async (inp) => {
    // console.log("inp:", inp);
    await this.setState({
      moveFlag: true,
      selectedItem: inp,
    });
  };

  handleMove = async () => {
    let errMsg = "",
      errFlag = false;
    let moveFlag = false;
    let toMoveQty = this.state.toMoveQty;
    let selectedItem = this.state.selectedItem;
    let subarr = [];
    this.state.subMergedArray.forEach((obj) => {
      if (obj.seqno === this.state.selectedItem.seqno) {
        let iserr = false;
        if (this.state.moveDirection === "tocatalog") {
          // console.log(
          //   "obj.itemqty:",
          //   obj.itemqty,
          //   " this.state.toMoveQty:",
          //   this.state.toMoveQty
          // );
          if (obj.itemqty < this.state.toMoveQty) {
            iserr = true;
            errMsg = "Cannot move more than the you have in the warehouse.";
            errFlag = true;
          }
        } else {
          if (obj.catqty < this.state.toMoveQty) {
            iserr = true;
            errMsg = "Cannot move more than the you have in the catalog.";
            errFlag = true;
          }
        }
        if (!iserr) {
          let siobj = {
            catqty: obj.catqty,
            itemqty: obj.itemqty,
            moveQty: parseFloat(this.state.toMoveQty),
            name: obj.name,
            options: obj.options,
            seqno: obj.seqno,
            movedFlag: true,
          };
          subarr.push(siobj);
          toMoveQty = 0;
          selectedItem = null;
        } else {
          moveFlag = true;
          subarr.push(obj);
        }
      } else {
        subarr.push(obj);
      }
    });

    await this.setState({
      moveFlag,
      selectedItem,
      toMoveQty,
      subMergedArray: subarr,
      errMsg,
      errFlag,
    });
  };

  getAnItemFromItemMaster = async () => {
    let param =
      "requestBy=itemId&itemId=" +
      this.props.itemId;
    try {
      let url = baandaServer + getAnItemFromIM + param;
      // console.log("url:", url);
      let itret = await axios.get(url);
      // console.log("itret:", itret);
      if (itret.data.status === "success") {
        await this.setState({
          errorPanelFlag: false,
          errorMsg: "",
          item: itret.data.Msg[0],
        });
      } else {
        await this.setState({
          errorPanelFlag: true,
          errorMsg: itret.Msg,
        });
      }
    } catch (err) {
      console.log("getAnItemFromItemMaster err:", err.message);
    }
  };

  getCatalogItem = async () => {
    let param =
      "communityId=" +
      this.props.communityId +
      "&itemId=" +
      this.props.itemId + "&coopstoreId=" + this.props.coopstoreId +
      "&clientProgram=AnItemsInventory&clientFunction=getCatalogItem";
    let url = baandaServer + getItemToEdit + param;
    // console.log("getCatalogItem url:", url);
    try {
      let catret = await axios.get(url);
      // console.log("---->> catret:", catret);
      if (catret.data.Msg.length > 0) {
        await this.setState({
          catalog: catret.data.Msg[0],
        });
      } else {
        await this.setState({
          catalog: null,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  mergeCatIMSubItems = async () => {
    let subMergedArray = [];

    this.state.item.itemVariance.forEach((obj) => {
      this.state.catalog.itemVariance.forEach((elm) => {
        let itexists = false;
        subMergedArray.forEach((sma) => {
          if (sma.seqno === obj.seqno) itexists = true;
        });
        if (!itexists && obj.seqno === elm.seqno) {
          let mrgobj = {
            seqno: obj.seqno,
            name: obj.name,
            options: obj.options,
            catqty: elm.quantity,
            itemqty: obj.quantity,
            moveQty: 0,
            movedFlag: false,
          };
          subMergedArray.push(mrgobj);
        }
      });
    });
    await this.setState({
      subMergedArray,
    });
  };

  saveItemsToMove = async () => {
    // console.log("here in save in database ...");
    let itemMasterVariance = [];
    let itemCatalogVariance = [];
    let imTotalQty = this.state.item.quantity;
    let catTotalQty = 0;
    if (this.state.moveDirection === "tocatalog") {
      this.state.subMergedArray.forEach((obj) => {
        // console.log('tocat obj:', obj);
        this.state.item.itemVariance.forEach((elm1) => {
          // console.log('tocat elm1:', elm1);
          let itemqty = 0;
          let catqty = 0;
          if (this.state.moveType === "all") {
            itemqty = 0;
            catqty = parseFloat(obj.catqty) + parseFloat(obj.itemqty);
            imTotalQty = 0;
            catTotalQty = catTotalQty + parseFloat(obj.itemqty);
          } else {
            itemqty = parseFloat(obj.itemqty) - parseFloat(obj.moveQty);
            catqty = parseFloat(obj.catqty) + parseFloat(obj.moveQty);
            imTotalQty = imTotalQty - parseFloat(obj.moveQty);
            catTotalQty = catTotalQty + parseFloat(obj.moveQty);
          }
          if (obj.seqno === elm1.seqno) {
            let itemobj = {
              seqno: elm1.seqno,
              image: elm1.image,
              lastcost: elm1.lastcost,
              movingAvgCost: elm1.movingAvgCost,
              name: elm1.name,
              opsState: elm1.opsState,
              options: elm1.options,
              quantity: itemqty,
            };
            let catmobj = {
              seqno: elm1.seqno,
              image: elm1.image,
              lastcost: elm1.lastcost,
              movingAvgCost: elm1.movingAvgCost,
              name: elm1.name,
              opsState: elm1.opsState,
              options: elm1.options,
              quantity: catqty,
            };
            itemMasterVariance.push(itemobj);
            itemCatalogVariance.push(catmobj);
          }
        });
      });
    }
    if (this.state.moveDirection === "toitemmaster") {
      this.state.subMergedArray.forEach((obj) => {
        // console.log('obj:', obj);
        this.state.item.itemVariance.forEach((elm1) => {
          // console.log('elm1:', elm1);
          let itemqty = 0;
          let catqty = 0;
          if (this.state.moveType === "all") {
            itemqty = parseFloat(obj.catqty) + parseFloat(obj.itemqty);
            catqty = 0;
            imTotalQty = imTotalQty + parseFloat(obj.catqty);
            catTotalQty = 0;
          } else {
            itemqty = parseFloat(obj.itemqty) + parseFloat(obj.moveQty);
            catqty = parseFloat(obj.catqty) - parseFloat(obj.moveQty);
            imTotalQty = imTotalQty + parseFloat(obj.moveQty);
            catTotalQty = catTotalQty - parseFloat(obj.moveQty);
          }
          if (obj.seqno === elm1.seqno) {
            let itemobj = {
              seqno: elm1.seqno,
              image: elm1.image,
              lastcost: elm1.lastcost,
              movingAvgCost: elm1.movingAvgCost,
              name: elm1.name,
              opsState: elm1.opsState,
              options: elm1.options,
              quantity: itemqty,
            };
            let catmobj = {
              seqno: elm1.seqno,
              image: elm1.image,
              lastcost: elm1.lastcost,
              movingAvgCost: elm1.movingAvgCost,
              name: elm1.name,
              opsState: elm1.opsState,
              options: elm1.options,
              quantity: catqty,
            };
            itemMasterVariance.push(itemobj);
            itemCatalogVariance.push(catmobj);
          }
        });
      });
    }
    try {
      let url = baandaServer + moveItems;
      let input = {
        itemId: this.state.item.itemId,
        itemMasterVariance,
        itemCatalogVariance,
        imTotalQty,
        catTotalQty,
        clientProgram: "InventoryMove.js",
        clientFunction: "saveItemsToMove",
      };
      // console.log("url:", url);
      // console.log("input:", input);
      let retmv = await axios.post(url, input);
      if (retmv.data.status === "success") {
        await this.setState({
          errMsg: "Successfully Updated",
          errFlag: false,
        });
        await this.getAnItemFromItemMaster();
        await this.getCatalogItem();
        await this.mergeCatIMSubItems();
      } else {
        await this.setState({
          errMsg: retmv.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleMoveType = async (e) => {
    await this.setState({
      moveType: e.target.value,
    });
  };

  handleDirectionType = async (e) => {
    await this.setState({
      moveDirection: e.target.value,
    });
  };

  returnToItemsInventory = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('InventoryMove...');

    let moveTypePanel;
    moveTypePanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Item movement type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="all"
                checked={this.state.moveType === "all"}
                onChange={this.handleMoveType}
              />{" "}
              All
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="partial"
                checked={this.state.moveType === "partial"}
                onChange={this.handleMoveType}
              />{" "}
              Partial
            </label>
          </div>
        </div>
      </div>
    );

    let moveDirectionPanel;
    moveDirectionPanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Direction&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="tocatalog"
                checked={this.state.moveDirection === "tocatalog"}
                onChange={this.handleDirectionType}
              />{" "}
              Warehouse to Catalog
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="toitemmaster"
                checked={this.state.moveDirection === "toitemmaster"}
                onChange={this.handleDirectionType}
              />{" "}
              Catalog to Warehouse
            </label>
          </div>
        </div>
      </div>
    );

    let totalStockPanel;
    if (this.state.catalog && this.state.item) {
      totalStockPanel = (
        <div className="row total-stock-row">
          <div className="col-md-6 move-cat-stock">
            Total Qty in Catalog {this.state.totalCatalogQty}
          </div>
          <div className="col-md-6 move-item-stock">
            Total Qty in Warehouse {this.state.totalItemMasterQty}
          </div>
        </div>
      );
    }

    let enterMoveQtyPanel;
    if (this.state.moveFlag) {
      enterMoveQtyPanel = (
        <div className="move-qty-box">
          <div className="text-center">
            <b>Enter Quantity</b>
          </div>
          <div>
            <input
              name="toMoveQty"
              type="number"
              value={this.state.toMoveQty}
              onChange={this.onChangePrice}
              className="move-qty"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
            &nbsp;
            <button
              className="btn_reg_60"
              type="button"
              onClick={this.handleMove}
              style={{
                cursor: this.state.disabled ? "default" : "pointer",
              }}
            >
              Done
            </button>
          </div>
        </div>
      );
    }

    let bool = true;
    let subItemsListPanel;
    let subit = [...this.state.subMergedArray];
    subItemsListPanel = (
      <div>
        <div className="row sub-item-list-row">
          <div className="col-5 text-center sub-item-list-head">
            Item Option
          </div>
          <div className="col-2 text-center sub-item-list-head">WH-Qty</div>
          <div className="col-2 text-center sub-item-list-head">Cat-Qty</div>
          <div className="col-2 text-center sub-item-list-head">
            To-Move-Qty
          </div>
          <div className="col-1 text-center sub-item-list-head">&nbsp;</div>
        </div>
        <div className="fixedsize-sub-items-list">
          {subit.map((obj, i) => {
            let showMoveButton = false;
            if ( this.state.moveType === 'partial' && this.state.moveDirection === 'toitemmaster') {
              if ( obj.catqty > 0 ) showMoveButton = true;
            }
            if ( this.state.moveType === 'partial' && this.state.moveDirection === 'tocatalog') {
              if ( obj.itemqty > 0 ) showMoveButton = true;
            }
            return (
              <div key={i}>
                <div
                  className={
                    bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                  }
                >
                  <div
                    className={
                      this.state.selectedItem &&
                      this.state.selectedItem.seqno === obj.seqno
                        ? "col-5 text-left subitem-disp-lbl-selected"
                        : "col-5 text-left subitem-disp-lbl"
                    }
                  >
                    {obj.options === '' ? 'No option specified' : obj.name + ' ' + obj.options}
                  </div>
                  <div className="col-2 text-center subitem-disp-lbl">
                    {obj.itemqty}
                  </div>
                  <div className="col-2 text-center subitem-disp-lbl">
                    {obj.catqty}
                  </div>
                  <div className="col-2 text-center subitem-disp-lbl">
                    {obj.moveQty}
                  </div>
                  <div className="col-1 text-left">
                    { !showMoveButton ? null : (
                      <button
                        className="btn_reg_30"
                        type="button"
                        onClick={() => this.handleSubItemMove(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-check" />
                      </button>
                    )}
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
        <div
          className={
            this.state.errFlag
              ? "text-center item-move-msg-err"
              : "text-center item-move-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    let exitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToItemsInventory}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );
    let moveButton = (
      <button
        className="btn_reg_50"
        type="button"
        onClick={this.saveItemsToMove}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let buttonPanel = (
      <div>
        {exitButton}
        {moveButton}
      </div>
    );

    let outputPanel = (
      <div className="inv-move-box text-center">
        <div className="text-center inv-move-header">
          Inventory Movement Mgmt.
        </div>
        {this.state.item ? (
          <div className="text-center inv-move-item-name">
            Item: <b>{this.state.item.itemName}</b>
          </div>
        ) : null}

        {moveDirectionPanel}
        {moveTypePanel}
        {totalStockPanel}
        <div className="row">
          <div className="col-8">&nbsp;</div>
          <div className="col-4 text-left">{enterMoveQtyPanel}</div>
        </div>
        {buttonPanel}
        {subItemsListPanel}
        {/* <div
          className={
            this.state.errFlag
              ? "text-center move-save-msg-err"
              : "text-center move-save-msg"
          }
        >
          {this.state.errMsg}
        </div> */}
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default InventoryMove;
