
// import siteplanModal from './displays/entrance/SiteMap';
import alertModalRcc from './displays/entrance/StartHereModal';
import infoModalRcc from './displays/overview/InfoModal';
import chartsRcc from './displays/reports/VisualChart';
import showPdfRcc from './displays/present/ReadPdf';
import showInvestRcc from './displays/present/ShowInvestDoc';

import showHelpRcc from './help/ShowHelp';


const modalTypes = {
  // siteplanModal,
  alertModalRcc,
  infoModalRcc,
  chartsRcc,
  showPdfRcc,
  showInvestRcc,
  showHelpRcc
};

export default modalTypes;
 