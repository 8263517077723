import React, { Component } from "react";

import "./PaymentDetail.css";

class PaymentDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  returnTowhereYouCameFrom = () => {
    this.props.returnToCaller();
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  formatDate = (date) => {
    let dt = date;
    if (!this.dateIsValid(date)) dt = new Date(date);
    return [
      this.padTo2Digits(dt.getMonth() + 1),
      this.padTo2Digits(dt.getDate()),
      dt.getFullYear(),
    ].join("/");
  };

  dateIsValid = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('PaymentDetails...');

    let tp = this.props.thePayment;

    let exitButton = (
      <button
        onClick={this.returnTowhereYouCameFrom}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let outputPanel;

    outputPanel = (
      <div>
        <div className="text-cener pay-detail-header">
          Payment Detail &nbsp;&nbsp;{exitButton}
          <div className="row top-row-pos">
            <div className="col-3 text-right">Paid by</div>
            <div className="col-9 text-left">{tp.partyName}</div>
          </div>
          <div className="row">
            <div className="col-3 text-right">Email</div>
            <div className="col-9 text-left">{tp.partyEmail}</div>
          </div>
          <div className="row">
            <div className="col-3 text-right">Cell</div>
            <div className="col-9 text-left">{tp.partyCell}</div>
          </div>
          <div className="row">
            <div className="col-3 text-right">Amount $</div>
            <div className="col-9 text-left">
              {this.commaFormattedCurrency(tp.paymentAmount)}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right">Pay Medium</div>
            <div className="col-9 text-left">{tp.paymentMedium}</div>
          </div>
          <div className="row">
            <div className="col-3 text-right">Note</div>
            <div className="col-9 text-left">{tp.paymentNote}</div>
          </div>
          <div className="row">
            <div className="col-3 text-right">On Date</div>
            <div className="col-9 text-left">{this.formatDate(tp.paid_at)}</div>
          </div>
        </div>
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

export default PaymentDetail;
