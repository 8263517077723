import React, { Component } from "react";
import axios from "axios";
import moment from "moment";

import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";

import "./PlaceTime.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveUpdtEvent = "/routes/interact/saveUpdtEvent";
// const clearSchedule = "/routes/interact/clearSchedule";

// const Checkbox = (props) => <input type="checkbox" {...props} />;

class PlaceTime extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      selectedDays: [],
      //   selectedDaysx: [],

      startHour: 8,
      startminutes: 0,
      startampm: "am",

      durationHours: 0,
      durationMinutes: 0,

      eventId: 0,
      schedule: null,
      errMsg: "",
      errFlag: false,

      showSaveBtn: true,
      showClearBtn: false,
      saveClearMsg: "",
      attendanceType: "anyday",

      oldEventOn: null,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let eventId = 0;
    let selectedDays = [];
    if (this.props.theEvent.eventId && this.props.theEvent.eventId > 0) {
      eventId = this.props.theEvent.eventId;
    }

    // console.log("this.props.theEvent.eventOn:", this.props.theEvent.eventOn);

    let startHour = 8;
    let startminutes = 0;
    let startampm = "am";
    let durationHours = 0;
    let durationMinutes = 0;

    if (this.props.theEvent.eventOn.days.length > 0) {
      this.props.theEvent.eventOn.days.forEach((obj) => {
        let idt = new Date(obj);
        if (moment().isBefore(moment(idt).add(1, "days")))
          selectedDays.push(idt);
        // let idt = new Date(obj);
      });
    }

    // console.log("selectedDays:", selectedDays);

    // console.log(
    //   "this.props.theEvent.eventOn.timeRange.fromHour:",
    //   this.props.theEvent.eventOn.timeRange.fromHour
    // );
    if (this.props.theEvent.eventOn.timeRange.fromHour > 0) {
       
      startHour = this.props.theEvent.eventOn.timeRange.fromHour;
    }
    

    if (
      this.props.theEvent.eventOn &&
      this.props.theEvent.eventOn.days.length > 0
    ) {
      if (this.props.theEvent.eventOn.timeRange.fromMinute)
        startminutes = this.props.theEvent.eventOn.timeRange.fromMinute;
      // if (this.props.theEvent.eventOn.timeRange.fromAmPm)
      startampm = this.props.theEvent.eventOn.timeRange.fromAmPm;
      if (this.props.theEvent.eventOn.timeRange.durationHours >= 0)
        durationHours = this.props.theEvent.eventOn.timeRange.durationHours;
      if (this.props.theEvent.eventOn.timeRange.durationMinutes >= 0)
        durationMinutes = this.props.theEvent.eventOn.timeRange.durationMinutes;
    }

    await this.setState({
      deviceSize,
      eventId,
      selectedDays,
      startHour,
      startminutes,
      startampm,
      durationHours,
      durationMinutes,
      oldEventOn: this.props.theEvent.eventOn,
      attendanceType: this.props.theEvent.eventOn.attendanceType,
    });
  };

  handleDayClick = async (day, { selected }) => {
    // console.log('day:', day);
    const { selectedDays } = this.state;
    let yesterday = moment(new Date()).add(-1, "days");
 

    if (moment(day).isAfter(yesterday)) {
 
      if (selected) {
        const selectedIndex = selectedDays.findIndex((selectedDay) =>
          DateUtils.isSameDay(selectedDay, day)
        );
        selectedDays.splice(selectedIndex, 1);
      } else {
        selectedDays.push(day);
      }
  
      await this.setState({ selectedDays });
    } else {
      if (selected) {
        const selectedIndex = selectedDays.findIndex((selectedDay) =>
          DateUtils.isSameDay(selectedDay, day)
        );
        selectedDays.splice(selectedIndex, 1);
      }
 
      await this.setState({
        selectedDays,
      });
    }
  };

  onChangeHours = async (e) => {
    e.preventDefault();
    let value = parseFloat(e.target.value);
    if (value <= 12 && value >= 0) {
      await this.setState({ [e.target.name]: e.target.value });
    }
  };

  onChangeMinutes = async (e) => {
    e.preventDefault();
    let value = parseFloat(e.target.value);
    if (value <= 59 && value >= 0) {
      await this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleAmPm = async (e) => {
    await this.setState({
      startampm: e.target.value,
    });
  };

  handleattendanceType = async (e) => {
 
    await this.setState({
      attendanceType: e.target.value,
    });
  };

  validate = async () => {
    let isValid = "success";
    let msg = "";
    if (this.state.selectedDays.length === 0) {
      isValid = "error";
      msg = "Must have at least one event date to save. | ";
    }

    if (
      parseFloat(this.state.durationHours) === 0 &&
      parseFloat(this.state.durationMinutes) === 0
    ) {
      isValid = "error";
      msg += "Must have some duration for an event to be meaningful; ";
    }

    this.state.selectedDays.forEach((dt) => {
      let yesterday = moment(new Date()).add(-1, "days");

      if (yesterday.isAfter(moment(dt))) {
        isValid = false;
        msg += "Must un-schedule leftover past scheduled days to save; ";
      }
    });

    let nowt = new Date();
    let hrs = nowt.getHours();
    let schhrs = this.state.startHour;
    if (this.state.startampm === "pm") schhrs += 12;
    if (this.state.selectedDays.length === 0) {
      isValid = false;
      msg += "Must select days to to schedule; ";
    } else {
      let isTodayIn = false;
      let yr1 = nowt.getFullYear();
      let tm1 = nowt.getMonth();
      let mo1 = "";
      if (tm1 < 10) mo1 = "0" + tm1;
      else mo1 = tm1;
      let td1 = nowt.getDate();
      let dt1 = "";
      if (td1 < 10) dt1 = "0" + dt1;
      else dt1 = td1;
      let todaystr = mo1 + dt1 + yr1;
      // console.log('todaystr:', todaystr)
      this.state.selectedDays.forEach((dto) => {
        let yr = dto.getFullYear();
        let tm = dto.getMonth();
        let mo = "";
        if (tm < 10) mo = "0" + tm;
        else mo = tm;
        let td = dto.getDate();
        let dt = "";
        if (td < 10) dt = "0" + dt;
        else dt = td;
        let scdtstr = mo + dt + yr;
        // console.log("scdtstr:", scdtstr);
        if (scdtstr === todaystr) {
          isTodayIn = true;
        }
      });
      // console.log('isTodayIn:', isTodayIn);
      if (isTodayIn) {
        // console.log('hrs:', hrs, ' schhrs:', schhrs);
        if (hrs > schhrs) {
          isValid = false;
          msg += "Your schedule time, for today, has already passed;";
        }
      }
    }
    // console.log('msg:', msg);

    return { status: isValid, Msg: msg };
  };

  formatTommddyyyy = (dtstr) => {
    // console.log('dtstr:', dtstr);
    // let outstr = '';
    let yyyy = dtstr.substring(0,4);
    // console.log('yyyy:', yyyy);
    let dd = dtstr.substring(8,10);
    // console.log('dd:', dd);
    let mo = dtstr.substring(5,7);
    // console.log('mo:', mo);
    // outstr = mo + "-" + dd + "-" + yyyy;
    return mo + "-" + dd + "-" + yyyy;
  }

  packDataForDB = async () => {
    // console.log("@@@ this.state.selectedDays:", this.state.selectedDays);

    let daysString = [];
    let days = [];
    let tos = [];
    let lastEventScheduleDate = null;
    let i = 0;

    this.state.selectedDays.forEach((obj) => {
      let dd = obj.toISOString().slice(0, 10);
      // console.log("dd:", dd, " formatdd:", this.formatTommddyyyy(dd));

      daysString.push(this.formatTommddyyyy(dd));
      days.push(moment(dd));
    });

    // For sorting ...
    daysString.forEach((ddd) => {
      // console.log(i, ". ddd:", ddd);
      let tosobj = {
        i,
        date: moment(ddd, "MM-DD-YYYY"),
      };
      tos.push(tosobj);
      i++;
    });
    let sorted = tos.sort((a, b) => a.date - b.date);
    lastEventScheduleDate = sorted[sorted.length - 1].date;
    // console.log(
    //   "daysString:",
    //   daysString,
    //   " lastEventScheduleDate:",
    //   lastEventScheduleDate
    // );

    let schedule = {
      eventId: this.state.eventId,
      communityId: this.props.communityId,
      eventOn: {
        attendanceType: this.state.attendanceType,
        days,
        daysString,
        timeRange: {
          fromHour: this.state.startHour,
          fromMinute: this.state.startminutes,
          fromAmPm: this.state.startampm,
          durationHours: this.state.durationHours,
          durationMinutes: this.state.durationMinutes,
        },
      },
      eventStatus: this.props.theEvent.eventStatus,
      lastEventScheduleDate,
      module: "schedule",
      clientProgram: "PlaceTime.js",
      clientFunction: "saveUpdatePlaceTime",
    };

    return schedule;
  };

  saveUpdatePlaceTime = async () => {
    let url = baandaServer + saveUpdtEvent;
    let isValid = await this.validate();
    if (isValid.status === "success") {
      let inp = await this.packDataForDB();
      // console.log(" url:", url, " inp:", inp);
      let resp = await axios.post(url, inp);
      // console.log("rest:", resp);
      if (resp.data.status === "success") {
        await this.setState({
          theEvent: resp.data.Msg.theEvent,
          eventId: resp.data.Msg.eventId,
          errMsg: "Successfully updated",
          errFlag: false,
        });
        // console.log("this.state.theEvent:", this.state.theEvent);
        this.props.returnToCaller(this.state.theEvent);
      } else {
        await this.setState({
          errMsg: resp.data.Msg,
          errFlag: true,
        });
      }
    } else {
      await this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }
  };


  returnToCreateEvent = async () => {
    let theEvent = this.state.theEvent;
    let eventOn = {
      days: this.state.selectedDays,
      timeRange: {
        fromHour: this.state.startHour,
        fromMinute: this.state.startminutes,
        fromAmPm: this.state.startampm,
        durationHours: this.state.durationHours,
        durationMinutes: this.state.durationMinutes,
      },
    };
    theEvent.eventOn = eventOn;
    this.props.returnToCaller(theEvent);
  };

  render() {
    // console.log("the.props:", this.props);
    // console.log("the.state:", this.state);
    console.log('PlaceTime...');
 
    let saveButton;
    if (
      this.props.theEvent.eventStatus === "published" ||
      this.props.theEvent.eventStatus === "canceled"
    ) {
      saveButton = (
        <div className="text-center post-publish-notify-msg">
          For a published event, this is for view only. You can copy this and
          re-publish.
        </div>
      );
    } else {
      saveButton = (
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.saveUpdatePlaceTime}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>
      );
    }

    let buttonPanel;
    buttonPanel = (
      <div>
        {this.state.showSaveBtn ? <div>{saveButton}</div> : null}
        {this.state.showClearBtn ? (
          <button
            className="btn_reg_60"
            type="button"
            onClick={this.clearSchdules}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Clear
          </button>
        ) : null}
        <div className="text-center save-clear-msg">
          {this.state.saveClearMsg}
        </div>
      </div>
    );

    let dayPicPanel;
    dayPicPanel = (
      <div className="cal-daypick-box">
        <DayPicker
          selectedDays={this.state.selectedDays}
          onDayClick={this.handleDayClick}
          disabledDays={{ before: new Date() }}
        />
      </div>
    );

    let timePanel;
    timePanel = (
      <div>
        <div className="text-center event-sch-header">
          Start Time is&nbsp;{this.state.startHour}&nbsp;:&nbsp;
          {this.state.startminutes < 10
            ? "0" + this.state.startminutes
            : this.state.startminutes}
          &nbsp;&nbsp;{this.state.startampm}
        </div>
        <div className="row">
          <div className="col-5 event-sch-lbl text-right">Hours</div>
          <div className="col-7 text-left">
            <input
              name="startHour"
              type="number"
              value={this.state.startHour}
              onChange={this.onChangeHours}
              className="event-schedule-hours"
              step="1"
            />
            &nbsp; (1 to 12)
          </div>
        </div>
        <div className="row">
          <div className="col-5 event-sch-lbl text-right">Minutes</div>
          <div className="col-7 text-left">
            <input
              name="startminutes"
              type="number"
              value={this.state.startminutes}
              onChange={this.onChangeMinutes}
              className="event-schedule-hours"
              step="1"
            />
            &nbsp; (0 to 59)
          </div>
        </div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Time of the day&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="am"
                  checked={this.state.startampm === "am"}
                  onChange={this.handleAmPm}
                />{" "}
                AM
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="pm"
                  checked={this.state.startampm === "pm"}
                  onChange={this.handleAmPm}
                />{" "}
                PM
              </label>
            </div>
          </div>
        </div>
        <div className="text-center event-sch-header">Event Duration</div>
        <div className="row">
          <div className="col-5 event-sch-lbl text-right">Duration Hours</div>
          <div className="col-7 text-left">
            <input
              name="durationHours"
              type="number"
              value={this.state.durationHours}
              onChange={this.onChangeHours}
              className="event-schedule-hours"
              step="1"
            />
            &nbsp; (1 to 12)
          </div>
        </div>
        <div className="row">
          <div className="col-5 event-sch-lbl text-right">Minutes</div>
          <div className="col-7 text-left">
            <input
              name="durationMinutes"
              type="number"
              value={this.state.durationMinutes}
              onChange={this.onChangeMinutes}
              className="event-schedule-hours"
              step="1"
            />
            &nbsp; (0 to 59)
          </div>
        </div>
        {this.state.selectedDays.length > 1 ? (
          <div className="row attend-days-type">
            <div className="col text-center radio-font-style">
              <strong>Attend on&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="anyday"
                    checked={this.state.attendanceType === "anyday"}
                    onChange={this.handleattendanceType}
                  />{" "}
                  Any one day
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="everyday"
                    checked={this.state.attendanceType === "everyday"}
                    onChange={this.handleattendanceType}
                  />{" "}
                  All the days
                </label>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );

    let outputPanel = (
      <div>
        <div className="text-center schedule-header">Schedule your Event</div>
        <div className="row">
          <div className="col-md-6">{timePanel}</div>
          <div className="col-md-6">{dayPicPanel}</div>
        </div>
        <div className="text-center cal-place-time-button-pos">{buttonPanel}</div>
        <div
          className={
            this.state.errFlag
              ? "text-center event-schedule-msg-err"
              : "text-center event-schedule-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default PlaceTime;
