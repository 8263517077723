import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./CreateTask.css";

import MakeTeam from "./MakeTeam";
import DefineTaskList from "./DefineTaskList";
import Operate from "./Operate";
import View from "./View";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAnEvent = "/routes/inetract/getAnEvent?";

const readMeCode = "1145100000";

class CreateTask extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      eventInitiated: true,

      createTeamBtn: false,
      taskListBtn: true,
      defineTaskBtn: false,
      opsTaskBtn: false,
      taskViewBtn: false,

      showCreateTeam: false,
      showTaskList: true,
      showDefine: false,
      showOperation: false,
      showView: false,

      theEvent: null,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setState({
      deviceSize,
      theEvent: this.props.theEvent,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  goToEventCreation = async () => {
   this.setState({
      createTeamBtn: false,
      taskListBtn: false,
      defineTaskBtn: false,
      opsTaskBtn: false,
      taskViewBtn: false,

      showCreateTeam: false,
      showTaskList: false,
      showDefine: false,
      showOperation: false,
      showView: false,
    });
    this.props.returnToCaller(this.state.theEvent);
  };

  handleTaskOps = async (inp) => {
    this.setState({
      createTeamBtn: false,
      taskListBtn: false,
      defineTaskBtn: false,
      opsTaskBtn: false,
      taskViewBtn: false,

      showCreateTeam: false,
      showTaskList: false,
      showDefine: false,
      showOperation: false,
      showView: false,
    });

    switch (inp) {
      case "team":
        this.setState({
          createTeamBtn: true,
          showCreateTeam: true,
        });
        break;
      case "tasklist":
        this.setState({
          taskListBtn: true,
          showTaskList: true,
        });
        break;
      case "operate":
        this.setState({
          opsTaskBtn: true,
          showOperation: true,
        });
        break;
      case "view":
        this.setState({
          taskViewBtn: true,
          showView: true,
        });
        break;
      default:
        console.log("switch xxx");
    }
  };

  getTheEvent = async (eventId) => {
    // console.log("getTheEvent eventId:", eventId);
    let param = "eventId=" + eventId;
    let theEvent = null;
    try {
      let url = baandaServer + getAnEvent + param;
      // console.log("url:", url);
      let reteve = await axios.get(url);
      // console.log("reteve:", reteve);
      theEvent = reteve.data.Msg;
    } catch (err) {
      console.log("Err:", err.message);
    }
    return theEvent;
  };

  returnFromMakeEvent = async (theEvent) => {
    // console.log("========== returnFromMakeEvent: ");
    let updatedEvent = await this.getTheEvent(theEvent.eventId);
    this.setState({
      theEvent: updatedEvent,
      // createTeamBtn: true,
      // showCreateTeam: true,
      // showTaskList: false,
    });
  };

  returnFromTasks = async (theEvent) => {
    // console.log("=========== returnFromTasks theEvent:", theEvent);
    let updatedEvent = await this.getTheEvent(theEvent.eventId);
    this.setState({
      theEvent: updatedEvent,
      // createTeamBtn: false,
      // showCreateTeam: false,
      // showTaskList: false,
    });
  };

  returnFromOperate = async (theEvent) => {
    // console.log("=========== returnFromOperate theEvent:", theEvent);
    let updatedEvent = await this.getTheEvent(theEvent.eventId);
    this.setState({
      theEvent: updatedEvent,
 
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('CreateTask...');

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let team, viewlist, operations, view;
    if (this.state.deviceSize === "small") {
      team = "Team";
      viewlist = "TLst";

      operations = "Ops";
      view = "View";
    } else {
      team = "Team";
      viewlist = "TaskList";

      operations = "Operate";
      view = "View";
    }

    let opsButtonPanel;
    opsButtonPanel = (
      <div className="row">
        <div className="col-3 text-center task-panel-header">Task Mgmt.</div>
        <div className="col-9 text-left event-ctrl-button">
          {this.state.eventInitiated ? (
            <button
              className={
                this.state.taskListBtn ? "btn_reg_75_active" : "btn_reg_75"
              }
              type="button"
              onClick={() => this.handleTaskOps("tasklist")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {viewlist}
            </button>
          ) : null}

          {this.state.eventInitiated ? (
            <button
              className={
                this.state.createTeamBtn ? "btn_reg_75_active" : "btn_reg_75"
              }
              type="button"
              onClick={() => this.handleTaskOps("team")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {team}
            </button>
          ) : null}

          {this.state.eventInitiated ? (
            <button
              className={
                this.state.opsTaskBtn ? "btn_reg_75_active" : "btn_reg_75"
              }
              type="button"
              onClick={() => this.handleTaskOps("operate")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {operations}
            </button>
          ) : null}
          {this.state.eventInitiated ? (
            <button
              className={
                this.state.taskViewBtn ? "btn_reg_75_active" : "btn_reg_75"
              }
              type="button"
              onClick={() => this.handleTaskOps("view")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {view}
            </button>
          ) : null}
          <button
            className="btn_back_main"
            type="button"
            onClick={this.goToEventCreation}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            <i className="fas fa-step-backward" />
          </button>

          {infoModalButton}
        </div>
      </div>
    );

    let outputpanel;
    if (this.state.showCreateTeam && this.state.theEvent) {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputpanel = (
        <div className="cal-task-box">
          <div className="task-mgmt-ctrl-pos">{opsButtonPanel}</div>
          <hr className="create-task-hr" />
          <MakeTeam
            theEvent={this.state.theEvent}
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            returnToCaller={this.returnFromMakeEvent}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showTaskList && this.state.theEvent) {
      // console.log("Inside showTaskList:", this.state.showTaskList);
      let store = null;
      if (this.props.store) store = this.props.store;
      outputpanel = (
        <div className="cal-task-box">
          <div className="task-mgmt-ctrl-pos">{opsButtonPanel}</div>
          <hr className="create-task-hr" />
          <DefineTaskList
            theEvent={this.state.theEvent}
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            returnToCaller={this.returnFromTasks}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showOperation && this.state.theEvent) {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputpanel = (
        <div className="cal-task-box">
          <div className="task-mgmt-ctrl-pos">{opsButtonPanel}</div>
          <hr className="create-task-hr" />
          <Operate
            theEvent={this.state.theEvent}
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            returnToCaller={this.returnFromOperate}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showView && this.state.theEvent) {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputpanel = (
        <div className="cal-task-box">
          <div className="task-mgmt-ctrl-pos">{opsButtonPanel}</div>
          <hr className="create-task-hr" />
          <View
            theEvent={this.state.theEvent}
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            // returnToCaller={this.returnFromView}
            store={store}
          />
        </div>
      );
    }

    return (
      <div>
        {outputpanel}
        <ModalContainer />
      </div>
    );
  }
}

CreateTask.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateTask));
