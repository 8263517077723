import React, { Component } from 'react';

import './CryptoAddress.css';

class CryptoAddress extends Component {
    render() {
        return (
            <div className="text-center crypto-config-msg">
                <h4>Crypto Configuration</h4>
                Trade using crypto currency and its setup will be avalable in the future release.
            </div>
        );
    }
}

export default CryptoAddress;