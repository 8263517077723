import React, { Component } from "react";
import { PropTypes } from "prop-types";

import "./EventOnHover.css";

class EventOnHover extends Component {
  static propTypes = {
    // use hover from controllable
    hover: PropTypes.bool,
    text: PropTypes.string
  };
  
  constructor(props) {
    super(props);

    this.state = {
      name:''
    }
  }

  static defaultProps = {};

  render() {
    // console.log('this.props:', this.props);
    // console.log('this.props.text:', this.props.text)
    console.log('EventOnHover...');

    const vis = this.props.hover ? "visible box-to-display" : "invisible";

    return (
      <div className="place-style">
        <div>{this.props.id}</div>
        <div className={vis}>
          <div className="row">
            <div className="col">
              <div className="intel_font text-center">
                <div>{this.props.text}</div>
                {/* <div>{this.props.nameOfPlace}</div> */}
                <p align="adjust">{this.props.address}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EventOnHover;
