import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./DefaultsSetup.css";
import axios from "axios";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateAdminValues = "/routes/create/updateAdminValues";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";

const readMeCode = "1152000000"; // Engage -> Dashboard -> Adminstration -> DefaultSetup

class DefaultsSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addrName: "",

      street1: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
      country: "USA",
      company: "",
      direction: "",

      pickupAddresses: [],
      checkPayTo: "",
      pickupAfterDays: 1,
      bankName: "",
      accountNo: "",

      deviceSize: "",
      message: "Enter/edit pickup addresses.",
      errFlag: false,
      invoiceTerms: "",
      delayFineApr: 0.0,
    };
  }

  componentDidMount = async () => {
    await this.getCommunity();
  };

  getCommunity = async () => {
    try {
      let url =
        baandaServer +
        getCommunityInfo +
        "communityId=" +
        this.props.communityId;
      let comm = await axios.get(url);
      // console.log("comm:", comm);
      if (comm.data.status === "success") {
        let pickupAddresses = [];

        // console.log(
        //   "comm.data.Msg.pickupAddresses:",
        //   comm.data.Msg.pickupAddresses
        // );

        if (
          comm.data.Msg.pickupAddresses &&
          comm.data.Msg.pickupAddresses.length > 0
        )
          pickupAddresses = comm.data.Msg.pickupAddresses;
        let checkPayTo = "";
        if (comm.data.Msg.checkPayToName)
          checkPayTo = comm.data.Msg.checkPayToName;
        let pickupAfterDays = 1;
        if (comm.data.Msg.pickupAfterDays)
          pickupAfterDays = comm.data.Msg.pickupAfterDays;
        let bankName = "";
        if (comm.data.Msg.bankName) bankName = comm.data.Msg.bankName;
        let accountNo = "";
        if (comm.data.Msg.accountNo) accountNo = comm.data.Msg.accountNo;
        let invoiceTerms = "";
        if (comm.data.Msg.invoiceTerms)
          invoiceTerms = comm.data.Msg.invoiceTerms;
          let delayPaymentFineAPR =0.00;
        if ( comm.data.Msg.delayPaymentFineAPR) delayPaymentFineAPR = comm.data.Msg.delayPaymentFineAPR; 

        await this.setState({
          pickupAddresses,
          checkPayTo,
          pickupAfterDays,
          bankName,
          accountNo,
          invoiceTerms,
          delayFineApr: delayPaymentFineAPR
        });
      }
    } catch (err) {
      console.log("getCommunity err:", err.message);
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({
       [e.target.name]: e.target.value,
       message: '', 
      });
  };

  checkAddress = async () => {
    let param =
      "street1=" +
      this.state.street1 +
      "&street2=" +
      this.state.street2 +
      "&city=" +
      this.state.city +
      "&zip=" +
      this.state.zip +
      "&country=" +
      this.state.country +
      "&company=" +
      this.props.communityName;
    let url = baandaServer + easypostAddressCheck + param;
    let status = "success";
    let msg = "";
    try {
      let check = await axios.get(url);
      // console.log("check:", check);
      if (check.data.status === "success") {
        await this.setState({
          message: "",
          errFlag: false,
        });
      } else {
        status = "error";
        msg = check.data.Msg;
      }
    } catch (err) {
      console.log("err:", err.message);
      status = "error";
      msg = err.message;
    }

    return { status, Msg: msg };
  };

  handleAdd = async () => {
    let isValid = await this.checkAddress();
    // console.log("isValid:", isValid);
    if (isValid.status === "success") {
      if (this.state.addrName !== "") {
        let addr = [...this.state.pickupAddresses];
        let nameExists = false;
        addr.forEach((obj) => {
          if (obj.addrName === this.state.addrName) {
            nameExists = true;
          }
        });
        if (!nameExists) {
          let addrobj = {
            addrName: this.state.addrName,
            street1: this.state.street1,
            street2: this.state.street2,
            city: this.state.city,
            zip: this.state.zip,
            state: this.state.state,
            country: this.state.country,
            direction: this.state.direction,
          };
          addr.push(addrobj);
          await this.setState({
            pickupAddresses: addr,
            addrName: "",
            street1: "",
            street2: "",
            city: "",
            state: "",
            zip: "",
            direction: "",
          });
        } else {
          await this.setState({
            message: "Duplicate location name is not allowed.",
            errFlag: true,
          });
        }
      } else {
        await this.setState({
          message: "Location name cannot be blank.",
          errFlag: true,
        });
      }
    } else {
      await this.setState({
        message: isValid.Msg,
        errFlag: true,
      });
    }
  };

  handleSave = async () => {
    let input = {
      communityId: this.props.communityId,
      pickupAddresses: this.state.pickupAddresses,
      invoiceTerms: this.state.invoiceTerms,
      pickupAfterDays: parseFloat(this.state.pickupAfterDays),
      checkPayToName: this.state.checkPayTo,
      bankName: this.state.bankName,
      accountNo: this.state.accountNo,
      delayPaymentFineAPR: this.state.delayFineApr,
      clientProgram: "DefaultSetup",
      clientFunction: "handleSave",
      opsName: "pickupnbank",
    };
    // console.log("input:", input);
    let url = baandaServer + updateAdminValues;
    try {
      let updt = await axios.post(url, input);
      // console.log("updt:", updt);
      if (updt.data.status === "success") {
        await this.setState({
          message: "Successfully updated.",
          errFlag: false,
        });
      } else {
        await this.setState({
          message: updt.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("handleSave err:", err.message);
      await this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  selectThisItemToDelete = async (obj) => {
    // console.log("selectThisItemToDelete obj:", obj);
    let pickaddr = [];
    this.state.pickupAddresses.forEach((adr) => {
      if (adr.addrName !== obj.addrName) pickaddr.push(adr);
    });

    this.setState({
      pickupAddresses: pickaddr,
    });
  };

  onChangeCurrency = async (e) => {
    // console.log("[e.target.name]:", [e.target.name]);
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        errMsg: "",
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        errMsg: "",
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('DefaultsSetup...');

    let buttonInfo = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );
 
    let entryPanel;
    entryPanel = (
      <div>
        <div className="row pickup-address-rows">
          <div className="col-3 text-right pickup-location-name">
            Location Name
          </div>
          <div className="col-9 text-left">
            <input
              name="addrName"
              type="text"
              value={this.state.addrName}
              onChange={this.onChange}
              size="30"
              maxLength="30"
              className="pickup-loaction-name"
              placeholder="Give this location a unique name"
            />
          </div>
        </div>
        <div className="text-center admin-pickup-addr-lbl">Pickup Address</div>
        <div className="row pickup-addr-row">
          <div className="col-6 text-center">
            <input
              name="street1"
              type="text"
              value={this.state.street1}
              onChange={this.onChange}
              size="30"
              maxLength="30"
              className="pickup-street-addr"
              placeholder="Street address"
            />
          </div>
          <div className="col-6 text-center">
            <input
              name="street2"
              type="text"
              value={this.state.street2}
              onChange={this.onChange}
              size="30"
              maxLength="30"
              className="pickup-street-addr"
              placeholder="Apt/Suite etc."
            />
          </div>
        </div>
        <div className="row pickup-addr-row">
          <div className="col-5 text-center">
            <input
              name="city"
              type="text"
              value={this.state.city}
              onChange={this.onChange}
              size="30"
              maxLength="30"
              className="pickup-city"
              placeholder="City"
            />
          </div>
          <div className="col-1 text-center">
            <input
              name="state"
              type="text"
              value={this.state.state}
              onChange={this.onChange}
              size="2"
              maxLength="2"
              className="pickup-state"
              placeholder="State"
            />
          </div>
          <div className="col-3 text-center">
            <input
              name="zip"
              type="text"
              value={this.state.zip}
              onChange={this.onChange}
              size="10"
              maxLength="10"
              className="pickup-zip"
              placeholder="ZIP"
            />
          </div>
          <div className="col-3 text-center">USA&nbsp;</div>
        </div>
        <div className="row pickup-addr-row">
          <div className="col-10 text-center">
            <input
              name="direction"
              type="text"
              value={this.state.direction}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="pickup-direction"
              placeholder="Pickup directions (if any) ..."
            />
          </div>
          <div className="col-2 text-center">
            <button
              className="btn_reg_60 "
              type="button"
              onClick={this.handleAdd}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Add&nbsp;+
            </button>
          </div>
        </div>
      </div>
    );

    let bool = true;
    let checoutAddrPanel;
    checoutAddrPanel = (
      <div>
        <div className="fixedsize-pickup-adr-list">
          {this.state.pickupAddresses.map((obj, i) => {
            // console.log("690 obj:", obj);
            return (
              <div key={i}>
                <div
                  className={
                    bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                  }
                >
                  <div className="col-1 text-right">
                    <button
                      className="btn-sub-line-del"
                      type="button"
                      onClick={() => this.selectThisItemToDelete(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                  <div className="col-11 text-left item-category-label">
                    {obj.addrName} | {obj.street1}&nbsp;{obj.street2},&nbsp;
                    {obj.city}&nbsp;{obj.state},&nbsp;{obj.zip}&nbsp; |{" "}
                    {obj.direction}
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let bankPanel;
    bankPanel = (
      <div className="pickup-bank-row">
        <div className="row">
          <div className="col-2 text-right check-pay-to-lbl">
            Check Remit To
          </div>
          <div className="col-4 text-left">
            <input
              name="checkPayTo"
              type="text"
              value={this.state.checkPayTo}
              onChange={this.onChange}
              size="30"
              maxLength="30"
              className="check-pay-to"
              placeholder=""
            />
          </div>

          <div className="col-6 text-center">
            Pick up after&nbsp;
            <input
              name="pickupAfterDays"
              type="number"
              value={this.state.pickupAfterDays}
              onChange={this.onChange}
              step="1"
              placeholder=""
              className="pick-up-after-days"
            />
            &nbsp;days of sale
          </div>
        </div>
        {/* <div className="row default-bank-row-pos">
          <div className="col-2 text-right check-pay-to-lbl">Your Bank</div>
          <div className="col-4 text-left">
            <input
              name="bankName"
              type="text"
              value={this.state.bankName}
              onChange={this.onChange}
              size="70"
              maxLength="70"
              className="default-bank-name"
              placeholder=""
            />
          </div>

          <div className="col-6 text-center">
            Account #&nbsp;
            <input
              name="accountNo"
              type="text"
              value={this.state.accountNo}
              onChange={this.onChange}
              size="30"
              maxLength="30"
              placeholder=""
              className="default-acc-no"
            />
          </div>
        </div> */}
        <div className="row default-bank-row-pos">
          <div className="col-2 text-right">Invoice Terms</div>
          <div className="col-10 text-left">
            <textarea
              name="invoiceTerms"
              maxLength="1000"
              placeholder="Default invoice terms for payments ..."
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="default-invoice-terms-textarea"
              onChange={this.onChange}
              value={this.state.invoiceTerms}
              required
            />
          </div>
        </div>
        <div className="row default-bank-row-pos">
          <div className="col-2 text-right check-pay-to-lbl">Late Fine APR</div>
          <div className="col-4 text-left">
            <input
              name="delayFineApr"
              type="number"
              value={this.state.delayFineApr}
              onChange={this.onChangeCurrency}
              className="default-fine-apr"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            /> &nbsp;%
          </div>

          <div className="col-6 text-center">&nbsp;</div>
        </div>
        <div className="text-center">
          <button
            className="btn_reg_60"
            type="button"
            onClick={this.handleSave}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Save
          </button>
        </div>
      </div>
    );

    let outputPanel = (
      <div className="admin-default-setup-box">
        <div className="default-setup-header">
          Defaults - Pickup Addresses, Banking Info, & Invoice Terms&nbsp;&nbsp;
          {buttonInfo}
          {entryPanel}
          {checoutAddrPanel}
          {bankPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center pickup-message-err"
                : "text-center pickup-message"
            }
          >
            {this.state.message}
          </div>
        </div>
      </div>
    );
    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

DefaultsSetup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DefaultsSetup));
