import React, { Component } from "react";

import axios from "axios";

import "./EditTargetAddress.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

class EditTargetAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      line1: "",
      city: "",
      state: "",
      postalCode: "",
      country: "US",

      shipline1: "",
      shipcity: "",
      shipstate: "",
      shippostalCode: "",

      errMsg: "",
      errFlag: false,

      useBaandaShipping: false,
      shippingAddress: true,
    };
  }

  componentDidMount = async () => {
    let shipline1 = this.props.shipline1;
    let shipcity = this.props.shipcity;
    let shipstate = this.props.shipstate;
    let shippostalCode = this.props.shippostalCode;
    if (shipline1 === "") {
      shipline1 = this.props.line1;
      shipcity = this.props.city;
      shipstate = this.props.state;
      shippostalCode = this.props.postalCode;
    }

    this.setState({
      line1: this.props.line1,
      city: this.props.city,
      state: this.props.state,
      postalCode: this.props.postalCode,
      shipline1,
      shipcity,
      shipstate,
      shippostalCode,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({
      [e.target.name]: e.target.value,
      errMsg: "",
    });
  };

  handleUseBaandaShip = async () => {
    this.setState((prevstate) => ({
      useBaandaShipping: !prevstate.useBaandaShipping,
    }));
  };

  handleShippingAddress = async () => {
    this.setState((prevstate) => ({
      shippingAddress: !prevstate.shippingAddress,
    }));
  };

  checkAddressBilling = async () => {
    let status = "success";
    let msg = "";

    if (
      this.state.line1 === "" ||
      this.state.city === "" ||
      this.state.postalCode === "" ||
      this.state.country === ""
    ) {
      status = "error";
      msg = "Must provide a billing address.";
    } else {
      let param =
        "street1=" +
        this.state.line1 +
        "&city=" +
        this.state.city +
        "&zip=" +
        this.state.postalCode +
        "&state=" +
        this.state.state +
        "&country=" +
        this.state.country +
        "&company=" +
        this.props.communityName;
      let url = baandaServer + easypostAddressCheck + param;
      try {
        let check = await axios.get(url);
        // console.log("check:", check);
        if (check.data.status === "error") {
          status = "error";
          msg = check.data.Msg;
        }
      } catch (err) {
        console.log("err:", err.message);
        status = "error";
        msg = err.message;
      }
    }

    return { status, Msg: msg };
  };

  checkAddressShipping = async () => {
    let status = "success";
    let msg = "";

    if (this.state.shippingAddress) {
      if (
        this.state.shipline1 === "" ||
        this.state.shipcity === "" ||
        this.state.shippostalCode === "" ||
        this.state.shipcountry === ""
      ) {
        status = "error";
        msg = "Must provide a shipping address.";
      } else {
        let param =
          "street1=" +
          this.state.shipline1 +
          "&city=" +
          this.state.shipcity +
          "&zip=" +
          this.state.shippostalCode +
          "&state=" +
          this.state.shipstate +
          "&country=" +
          this.state.country +
          "&company=" +
          this.props.communityName;
        let url = baandaServer + easypostAddressCheck + param;
        // console.log('checkAddressShipping url:', url);
        try {
          let check = await axios.get(url);
          // console.log("check:", check);
          if (check.data.status === "error") {
            status = "error";
            msg = check.data.Msg;
          }
        } catch (err) {
          console.log("err:", err.message);
          status = "error";
          msg = err.message;
        }
      }
    }

    return { status, Msg: msg };
  };

  returnToNewInvoice = (obj) => {
    this.props.returnToCaller(obj);
  };

  exitOnCancel = () => {
    let msg = {
      ops: "cancel",
    };
    this.returnToNewInvoice(msg);
  };

  saveExitAddress = async () => {
    let isValidBill = await this.checkAddressBilling();
    let msg = "",
      valid = true;

    if (isValidBill.status === "error") {
      valid = false;
      msg = "Invalid Billing address; ";
    }

    if (this.state.shippingAddress) {
      let isValidShip = await this.checkAddressShipping();
      if (isValidShip.status === "error") {
        valid = false;
        msg += " Invalid Shipping address;";
      }
    }
    // console.log("valid:", valid);
    if (!valid) {
      this.setState({
        errMsg: msg,
        errFlag: true,
      });
    } else {
      let msg = {
        ops: "save",
        line1: this.state.line1,
        state: this.state.state,
        postalCode: this.state.postalCode,
        city: this.state.city,
        shipline1: this.state.shipline1,
        shipstate: this.state.shipstate,
        shippostalCode: this.state.shippostalCode,
        shipcity: this.state.shipcity,
        country: this.state.country,
        useBaandaShipping: this.state.useBaandaShipping,
      };
      this.returnToNewInvoice(msg);
    }
  };

  render() {
    // console.log("this.state:", this.state);
    // console.log("this.props:", this.props);
    console.log('EditTragetAddress...');


    let exitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitOnCancel}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let doneButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.saveExitAddress}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let addrEditpanel = (
      <div>
        <div className="text-center ship-addr-spec">Billing Address</div>
        <div className="row inv-edit-addr-row-pos">
          <div className="col-3 text-right inv-edit-addr-lbl">Street</div>
          <div className="col-9 text-left">
            <input
              name="line1"
              type="text"
              value={this.state.line1}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="inv-edit-addr-street1"
              placeholder=""
            />
          </div>
        </div>
        <div className="row inv-edit-addr-row-pos">
          <div className="col-3 text-right inv-edit-addr-lbl">city</div>
          <div className="col-9 text-left">
            <input
              name="city"
              type="text"
              value={this.state.city}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="inv-edit-addr-city1"
              placeholder=""
            />
          </div>
        </div>
        <div className="row inv-edit-addr-row-pos">
          <div className="col-3 text-right inv-edit-addr-lbl">State</div>
          <div className="col-9 text-left">
            <input
              name="state"
              type="text"
              value={this.state.state}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="inv-edit-addr-state1"
              placeholder=""
            />
          </div>
        </div>
        <div className="row inv-edit-addr-row-pos">
          <div className="col-3 text-right inv-edit-addr-lbl">Postal Code</div>
          <div className="col-9 text-left">
            <input
              name="postalCode"
              type="text"
              value={this.state.postalCode}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="inv-edit-addr-postalCode1"
              placeholder=""
            />
          </div>
        </div>
        <div className="row inv-edit-addr-row-pos">
          <div className="col-3 text-right inv-edit-addr-lbl">Country</div>
          <div className="col-9 text-left inv-edit-addr-lbl">
            {this.state.country}
          </div>
        </div>
        {/* <div className="row inv-edit-addr-row-pos">
          <div className="col text-center">{doneButton}</div>
        </div> */}
      </div>
    );

    let shipEditpanel = (
      <div>
        <div className="row ship-decision-row">
          <div className="col-6 text-right ship-addr-spec">
            <Checkbox
              checked={this.state.shippingAddress}
              onChange={this.handleShippingAddress}
            />{" "}
            Shipping Address
          </div>

          <div className="col-6 text-left ship-addr-spec">
            <Checkbox
              checked={this.state.useBaandaShipping}
              onChange={this.handleUseBaandaShip}
            />{" "}
            Use Baanda Shipping
          </div>
        </div>
        <div className="row inv-edit-addr-row-pos">
          <div className="col-3 text-right inv-edit-addr-lbl">Street</div>
          <div className="col-9 text-left">
            <input
              name="shipline1"
              type="text"
              value={this.state.shipline1}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="inv-edit-addr-street1"
              placeholder=""
            />
          </div>
        </div>
        <div className="row inv-edit-addr-row-pos">
          <div className="col-3 text-right inv-edit-addr-lbl">city</div>
          <div className="col-9 text-left">
            <input
              name="shipcity"
              type="text"
              value={this.state.shipcity}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="inv-edit-addr-city1"
              placeholder=""
            />
          </div>
        </div>
        <div className="row inv-edit-addr-row-pos">
          <div className="col-3 text-right inv-edit-addr-lbl">State</div>
          <div className="col-9 text-left">
            <input
              name="shipstate"
              type="text"
              value={this.state.shipstate}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="inv-edit-addr-state1"
              placeholder=""
            />
          </div>
        </div>
        <div className="row inv-edit-addr-row-pos">
          <div className="col-3 text-right inv-edit-addr-lbl">Postal Code</div>
          <div className="col-9 text-left">
            <input
              name="shippostalCode"
              type="text"
              value={this.state.shippostalCode}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="inv-edit-addr-postalCode1"
              placeholder=""
            />
          </div>
        </div>
        <div className="row inv-edit-addr-row-pos">
          <div className="col-3 text-right inv-edit-addr-lbl">Country</div>
          <div className="col-9 text-left inv-edit-addr-lbl">
            {this.state.country}
          </div>
        </div>
        {/* <div className="row inv-edit-addr-row-pos">
          <div className="col text-center">{doneButton}</div>
        </div> */}
      </div>
    );

    let outputPanel = (
      <div>
        <div className="text-center inv-edit-addr-head">
          Edit Delivery Address&nbsp;{exitButton}
        </div>
        {addrEditpanel}
        {shipEditpanel}
        <div className="row inv-edit-addr-row-pos">
          <div className="col text-center">{doneButton}</div>
        </div>
        <div className={this.state.errFlag ? "text-center man-inv-ship-addr-msg-err":"text-center man-inv-ship-addr-msg-err"}>{this.state.errMsg}</div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default EditTargetAddress;
