import React, { Component } from "react";


import axios from "axios";
import FormatCompEntry from "./FormatCompEntry";

import { sortArrayOfObjects } from "../../../../../../../../utils/sortArrayOfObjects";

import "./CommBasicBody.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveBroadcastWip = "/routes/interact/saveBroadcastWip";

class CommBasicBody extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currSeqNo: 1,
      toEditSeqNo: 0,
      title: "",
      writeup: "",

      defaultTitleFormat: {
        fontFamily: "Arial, sans-serif",
        fontSize: "12pt",
        color: "black",
        bgColor: "#ffffff", // white
        isBold: false,
        align: 'left'
      },

      titleFormat: {
        fontFamily: "Arial, sans-serif",
        fontSize: "12pt",
        color: "black",
        bgColor: "#ffffff", // white
        isBold: false,
        align: 'left'
      },

      defaultWriteupFormat: {
        fontFamily: "Arial, sans-serif",
        fontSize: "11pt",
        color: "black",
        bgColor: "#ffffff", // white
        isBold: false,
        align: 'left'
      },

      writeupFormat: {
        fontFamily: "Arial, sans-serif",
        fontSize: "11pt",
        color: "black",
        bgColor: "#ffffff", // white
        isBold: false,
        align: 'left'
      },

      showFormatFlag: false,

      editFlag: false,

      errFlag: false,
      errMsg: "",

      compArray: [],
    };
  }

  componentDidMount = () => {
    let compArray = [...this.props.theBroadcast.mailComposition.basicCompBody];

    this.setState({
      compArray
    })
  };

  onChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeSeqno = async (e) => {
    let value = e.target.value;

    if (this.countDecimals(value) > 1) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        itemPrice: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleMerge = async () => {
    if (this.state.writeup !== "" || this.state.title !== "") {
      let coarr = [];
      console.log('this.state.toEditSeqNo:', this.state.toEditSeqNo);
      this.state.compArray.forEach(car => {
         
        if ( car.seqNo !== this.state.toEditSeqNo) coarr.push(car);
      })
     
      let coObj = {
        title: this.state.title,
        titleFormat: this.state.titleFormat,
        writeup: this.state.writeup,
        writeupFormat: this.state.writeupFormat,
        seqNo: this.state.currSeqNo,
      };
      coarr.push(coObj);

      // console.log("1 coarr:", coarr);
      let tmpArr = await sortArrayOfObjects(coarr, "seqNo", "dsc");
      // console.log("2 tmpArr:", tmpArr);

      let seq = 1;
      let resequenced = [];
      tmpArr.forEach((trr) => {
        trr.seqNo = seq;
        resequenced.push(trr);
        seq++;
      });

      this.setState({
        compArray: resequenced,
        currSeqNo: resequenced.length+1,
        titleFormat: this.state.defaultTitleFormat,
        writeupFormat: this.state.defaultWriteupFormat,
        editFlag: false,
        title: "",
        writeup: "",
        errMsg: "",
        errFlag: false,
        toEditSeqNo: 0
      });
    } else {
      this.setState({
        errMsg: "Both paragraph and title cannot be blank.",
        errFlag: true,
      });
    }
  };

  handleDeleteThis = (obj) => {
    console.log("handleDeleteThis obj:", obj);
    let darr = [];
    this.state.compArray.forEach((arr) => {
      if (arr.seqNo !== obj.seqNo) darr.push(arr);
    });

    let seq = 1;
    let resequenced = [];
    darr.forEach((trr) => {
      trr.seqNo = seq;
      resequenced.push(trr);
      seq++;
    });

    this.setState({
      compArray: resequenced,
      currSeqNo: resequenced.length + 1,
      title: "",
      writeup: "",
    });
  };

  handleEditThis = (obj) => {
    console.log("handleEditThis obj:", obj);
    let title, writeup, toEditSeqNo, currSeqNo;
    this.state.compArray.forEach(arr => {
      if (arr.seqNo === obj.seqNo) {
        title = arr.title;
        writeup = arr.writeup;
        toEditSeqNo = arr.seqNo;
        currSeqNo = arr.seqNo;
      }
    })

    this.setState({
      title,
      writeup,
      toEditSeqNo,
      currSeqNo,
      editFlag: true
    })
  };

  handleTitleFormat = (type) => {
    console.log("type:", type);
    let format;
    let btnType;
    if (type === "title") {
      format = this.state.titleFormat;
      btnType = "Intro";
    }
    if (type === "body") {
      format = this.state.writeupFormat;
      btnType = "Body";
    }

    this.setState({
      showFormatFlag: true,
      callingField: type,
      format,
      btnType,
    });
  };

  returnFromFormat = (newFormat) => {
    console.log(">> newFormat:", newFormat);
    let titleFormat = this.state.titleFormat;
    let writeupFormat = this.state.writeupFormat;
    console.log(">> this.state.btnType:", this.state.btnType);
    // if ( this.state.btnType === 'Intro') {
    //   console.log('yes ...')
    // } else {
    //   console.log('no ... this.state.btnType:', this.state.btnType);
    // }
    let cArr = [];
    this.state.compArray.forEach(crr => {
      if ( crr.seqNo !== this.state.toEditSeqNo) {cArr.push(crr);}
      else {
        if (this.state.btnType === "Intro") {
          crr.titleFormat = newFormat;
        }
        if (this.state.btnType === "Body") {
          crr.writeupFormat = newFormat;
        }
        cArr.push(crr)
      }
    })


    this.setState({
      showFormatFlag: false,
      writeupFormat,
      titleFormat,
      compArray: cArr
    });
  };

  packageInputForDB = () => {
    let input = {
      basicCompBody: this.state.compArray,
      communityId: this.props.communityId,
      broadcastId: this.props.broadcastId,
      templateType: this.props.selectedTemplate,
      updateType: "body",
      clientProgram: "CommBasicBody",
      clientFunction: "handleBodySave",
    };

    return input;
  }

  handleBodySave = async () => {
    console.log("inside uploadFileInDB");
    let input = this.packageInputForDB();
    let url = baandaServer + saveBroadcastWip;
    console.log("### url:", url, " input:", input);
    try {
      let resp = await axios.post(url, input);
      console.log("Upload resp:", resp);
      if (resp.data.status === "success") {
        console.log("In here ... 999");
        this.setState({
          errMsg: "Successfully updated.",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: resp.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("uploadFileInDB:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    let addButton = (
      <button
        className="btn_reg_90"
        type="button"
        onClick={this.handleMerge}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Merge <i className="fas fa-object-ungroup" />
      </button>
    );

    let formatBtn1 = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={() => this.handleTitleFormat("title")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-paint-roller" />
      </button>
    );

    let formatBtn2 = (
      <button
        className="btn_reg_40"
        type="button"
        onClick={() => this.handleTitleFormat("body")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-paint-roller" />
      </button>
    );

    let saveBtn;
    if ( this.state.compArray.length > 0) {
      saveBtn = (
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.handleBodySave}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>
      );
    }

    let editorialPanel;
    editorialPanel = (
      <div className="comp-body-edit-box">
        <div className="row com-body-entry-row">
          <div className="col-2 text-right basic-body-lbl">Para Title</div>
          <div className="col-9 text-left">
            <input
              name="title"
              type="text"
              value={this.state.title}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="com-basic-title"
              placeholder=""
            />{" "}
          </div>
          <div className="col-1 text-left">{formatBtn1}</div>
        </div>
        <div className="row com-body-entry-row">
          <div className="col-2 text-right basic-body-lbl">Paragraph</div>
          <div className="col-9 text-left">
            <textarea
              name="writeup"
              maxLength="2000"
              placeholder="This para writeup in 2000 chars or less."
              rows="5"
              wrap="hard"
              spellCheck="true"
              className="basic_body_textarea"
              onChange={this.onChange}
              value={this.state.writeup}
              required
            />
          </div>
          <div className="col-1 text-left format-body-btn-pos">
            {formatBtn2}
          </div>
        </div>

        <div className="row basic-edit-ctrl-pos">
          <div className="col-2 text-right basic-body-lbl">seqNo</div>
          {this.state.editFlag && this.state.compArray.length > 1 ? (
            <div className="col-2 text-left">
              <input
                name="currSeqNo"
                type="number"
                value={this.state.currSeqNo}
                onChange={this.onChangeSeqno}
                size="10"
                maxLength="10"
                className="basic-body-seqno"
                placeholder=""
              />{" "}
            </div>
          ) : (
            <div className="col-2 text-left">{this.state.currSeqNo}</div>
          )}

          <div className="col-4 text-center basic-body-char-cnt">
            Char count = {this.state.writeup.length}
          </div>
          <div className="col-4 text-center">{addButton}</div>
        </div>
      </div>
    );

    let listPanel;
    let bool = true;
    listPanel = (
      <div className="comp-body-list-box">
        {this.state.compArray.map((obj, i) => (
          <div key={i}>
            <div
              className={`${
                bool ? "row-basic-body-list-dr" : "row-basic-body-list-lt"
              }`}
            >
              <div className="row ">
                <div className="col-2 text-left">
                  <button
                    className="btn_exit_del"
                    type="button"
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                    onClick={() => this.handleDeleteThis(obj)}
                  >
                    <i className="fas fa-trash-alt" />
                  </button>
                  <button
                    className="btn_reg_40"
                    type="button"
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                    onClick={() => this.handleEditThis(obj)}
                  >
                    <i className="fas fa-pen" />
                  </button>
                </div>
                <div className="col-1 text-center">{obj.seqNo}</div>
                <div className="col-5 text-left">
                  {obj.title.substring(0, 40)}
                </div>
                <div className="col-4 text-left">
                  {obj.writeup.substring(0, 30)}
                </div>
              </div>
            </div>
            {(bool = !bool)}
          </div>
        ))}
      </div>
    );

    let formatPanel;
    if (this.state.showFormatFlag) {
      formatPanel = (
        <div className="">
          <FormatCompEntry
            callingField={this.state.callingField}
            format={this.state.format}
            selectedTemplate={this.props.selectedTemplate}
            returnToCaller={this.returnFromFormat}
          />
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        <div className="text-center basic-body-edit-head">
          Composition Body Editorial
        </div>
        <div className="row">
          <div className="col">{editorialPanel}</div>
        </div>
        {formatPanel}
        <div className="row body-list-head-pos">
          <div className="col-2 text-center basic-body-lbl">Ops</div>
          <div className="col-1 text-center basic-body-lbl">Seq#</div>
          <div className="col-5 text-left basic-body-lbl">Title</div>
          <div className="col-4 text-left basic-body-lbl">Paragraph</div>
        </div>
        <div className="row">
          <div className="col">{listPanel}</div>
        </div>
        <div className="row save-btn-row-pos">
          <div className="col text-center">{saveBtn}</div>
        </div>
        <div className="row save-btn-row-pos-msg">
          <div
            className={
              this.state.errFlag
                ? "col text-center basic-body-save-msg-err"
                : "col text-center basic-body-save-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default CommBasicBody;
