import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import Pickup from "./delivery/Pickup";
import Shipping from "./delivery/Shipping";
import Returns from "./delivery/ReturnResponse";

import "./SaleMain.css";

const returnList = ["returnhandling"];
const shippingList = ["shipping"];
const pickupList = ["pickup"];

const readMeCode = "1111100000";

class SaleMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shipActiveFlag: false,
      pickupActiveFlag: false,
      returnsActiveFlag: false,
      deviceSize: "",
      btnShippingFlag: false,
      btnPickupFlag: false,
      btnReturnFlag: false,

      store: this.props.store
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
 

    await this.setAuthorizedButtons();

    await this.setState({
      deviceSize,
    });
  };

  // This is to show the info panel
  openAlertModal = async () => {
 
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  setAuthorizedButtons = async () => {
    // console.log("### this.state.authorizedFuncs: ", this.state.authorizedFuncs);
    let btnShippingFlag = false;
    let btnPickupFlag = false;
    let btnReturnFlag = false;
    let shipActiveFlag = false;
    let pickupActiveFlag = false;
    let returnsActiveFlag = false;

    shippingList.forEach((obj1) => {
      if (this.props.authorizedFuncs.includes(obj1)) {
        btnShippingFlag = true;
        shipActiveFlag = true;
      }
    });
    pickupList.forEach((obj2) => {
      if (this.props.authorizedFuncs.includes(obj2)) btnPickupFlag = true;
      if (!shipActiveFlag) pickupActiveFlag = true;
    });
    returnList.forEach((obj3) => {
      if (this.props.authorizedFuncs.includes(obj3)) btnReturnFlag = true;
      if (!shipActiveFlag && !pickupActiveFlag) returnsActiveFlag = true;
    });

    this.setState({
      btnShippingFlag,
      btnPickupFlag,
      btnReturnFlag,
      shipActiveFlag,
      pickupActiveFlag,
      returnsActiveFlag,
    });
  };

  handleSelect = async (choice) => {
    // console.log("The selection is: " + choice);
    if (choice === "pickup") {
      await this.setState({
        shipActiveFlag: false,
        pickupActiveFlag: true,
        returnsActiveFlag: false,
      });
    }
    if (choice === "ship") {
      await this.setState({
        shipActiveFlag: true,
        pickupActiveFlag: false,
        returnsActiveFlag: false,
      });
    }
    if (choice === "returns") {
      await this.setState({
        shipActiveFlag: false,
        pickupActiveFlag: false,
        returnsActiveFlag: true,
      });
    }
  };

  render() {
    // console.log("sellMain this.props:", this.props);
    // console.log("sellMain this.state:", this.state);
    console.log('SaleMain...');

    let pickupBtnText, shipBtnText, returnBtn;

    if (this.state.deviceSize === "small") {
      pickupBtnText = "Pkup";
      shipBtnText = "Ship";
      returnBtn = "Rets";
    } else {
      pickupBtnText = "Pickup";
      shipBtnText = "To Ship";
      returnBtn = "Returns";
    }

    let buttonPanel = (
      <div>
        <div className="row">
          <div className="col-2 header_sales_text text-center">Delivery</div>
          <div className="col-10 hader_sales_buttons">
            {/* {this.props.authorizedFuncs.includes("logesticshipper") ||
            this.props.role === "creator" ? ( */}
            {this.state.btnShippingFlag ? (
              <button
                className={`${
                  !this.state.shipActiveFlag
                    ? "btn_reg_75"
                    : "btn_reg_75_active"
                }`}
                type="button"
                onClick={() => this.handleSelect("ship")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {shipBtnText}
              </button>
            ) : null}
            &nbsp;
            {/* {this.props.authorizedFuncs.includes("logesticpicker") ||
            this.props.role === "creator" ? ( */}
            {this.state.btnPickupFlag ? (
              <button
                className={`${
                  !this.state.pickupActiveFlag
                    ? "btn_reg_75"
                    : "btn_reg_75_active"
                }`}
                type="button"
                onClick={() => this.handleSelect("pickup")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {pickupBtnText}
              </button>
            ) : null}
            &nbsp;
            {/* {this.props.authorizedFuncs.includes("logesticreturn") ||
            this.props.role === "creator" ? ( */}
            {this.state.btnReturnFlag ? (
              <button
                className={`${
                  !this.state.returnsActiveFlag
                    ? "btn_reg_75"
                    : "btn_reg_75_active"
                }`}
                type="button"
                onClick={() => this.handleSelect("returns")}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                {returnBtn}
              </button>
            ) : null}
            &nbsp;
            <button
              className="btn_info_main"
              type="button"
              onClick={this.openAlertModal}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-info-circle" />
            </button>
          </div>
        </div>
      </div>
    );

    let activePanel;
    if (this.state.shipActiveFlag) {
      activePanel = (
        <div>
          <Shipping
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.props.community}
            role={this.props.role}
            store={this.state.store}
            calledFor="shipping"
          />
        </div>
      );
    }

    if (this.state.pickupActiveFlag) {
      activePanel = (
        <div>
          <Pickup
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.props.community}
            store={this.state.store}
            role={this.props.role}
            calledFor="pickup"
          />
        </div>
      );
    }

    if (this.state.returnsActiveFlag) {
      activePanel = (
        <div>
          <Returns
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.props.community}
            goToDashboard={this.props.goToDashboard}
            role={this.props.role}
            authorizedFuncs={this.props.authorizedFuncs}
            store={this.state.store}
            calledFor="returns"
          />
        </div>
      );
    }

    let outputPanel = (
      <div className="sale-main-button-pos">
        {buttonPanel}
        {activePanel}
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

SaleMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SaleMain));
