import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import LoadFileToS3 from "../../../../../../utils/components/fileUpload/LoadFileToS3";
// import { deleteS3File } from '../../../../../../utils/deleteS3File';
import { deleteS3File } from "../../../../../../utils/deleteS3File";

import "./ApDepriciate.css";

import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ViewPdfFiles from "../../../../../../utils/components/fileView/ViewPdfFile";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const apDepreciateSave = "/routes/dashboard/apDepreciateSave";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const maxpdfSize = 10000000; // 10mb  -- about 10 minutes

class ApDepriciate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      originalAssetValue: 0,
      currentAssetVallue: 0,

      actionType: "depreciate",
      apdepMsg:
        "Note: This will reduce the overall asset value by the amount, treated as an expense in the books (journal). It may be used as tax-deduction per the law.",

      actAmount: 0,
      actNote: "",
      attachCaption: "",
      actionDate: moment(),

      attachDocFlag: false,
      docSubDirName: "",
      docS3FileData: null,
      showApDepPanel: true,
      pdfViewButtonFlag: false,

      pdfFilesToView: null,
      showPdfFilesFlag: false,

      updateOkFlag: false,

      actionMsg: "",
      noAttachmentFlag: false,
      attachmentButtonFlag: true,
      confirmToUpdate: false,
      appreciationDriverFlag: false,
      appreciationDriver: "marketValue",
      updtErrMsg: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let oval = 0,
      cval = 0;
    if (this.props.item.assetValuation.length > 0) {
      // let i = 0;
      this.props.item.assetValuation.forEach((obj) => {
        if (obj.actionType === "item-received") {
          // if (i === 0) oval = obj.byValue;
          oval = oval + parseFloat(obj.byValue);
        }
        if (obj.actionType === "appreciate") {
          cval = cval + parseFloat(obj.byValue);
        }
        if (obj.actionType === "depreciate") {
          cval = cval - parseFloat(obj.byValue);
        }
        // i++;
      });
    }

    let pdfViewButtonFlag = false;
    if (this.props.item.fileUploads.length > 0) {
      this.props.item.fileUploads.forEach((obj) => {
        if (obj && obj.type === "pdf") pdfViewButtonFlag = true;
      });
    }

    await this.setState({
      deviceSize,
      originalAssetValue: oval,
      currentAssetVallue: cval,
      pdfViewButtonFlag,
    });
  };

  openAlertModal = () => {
    let msg = {
      Header: "Capital Asset Appreciate / Depreciate",
      Body: {
        oneLineSummary: `The list shows the communities you created, adminster or participate in.`,
        steps: [
          {
            step: "1: Identify",
            stepNote: "Identify the community you want to work on.",
          },
          {
            step: "2: Proceed to enagage",
            stepNote: "Click Go> buttton to enter into the selected community.",
          },
        ],
        footnote:
          "This displays your communities based onthe most recent usage. You will be able to filter and order based on your preference and set that as your default.",
      },
      Footer: "This is the footer",
    };
    this.props.showModal(
      {
        open: true,
        title: "Alert - Start Here Header",
        message: msg,
        closeModal: this.closeModal,
      },
      "infoModal"
    );
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  returnToTheItemMgmt = () => {
    this.props.returnToCaller(this.state.updateOkFlag);
  };

  validateBeforeUpload = async () => {
    let isValid = true,
      msg = "";
    if (this.state.actionType === "depreciate") {
      if (this.state.currentAssetVallue < this.state.amount) {
        isValid = false;
        msg = "Depreciation amount cannot exceed the current asset value. | ";
      }
    }
    if (
      this.state.actionType === "depreciate" ||
      this.state.actionType === "appreciate"
    ) {
      if (this.state.actAmount === 0) {
        isValid = false;
        msg =
          msg +
          "Must enter a positive amount to " +
          this.state.actionType +
          ". | ";
      }
    }
    if (!this.state.noAttachmentFlag) {
      if (this.state.attachCaption === "") {
        isValid = false;
        msg = msg + "Must have a doc name for the uploaded pdf. | ";
      } else {
        if (this.props.item.fileUploads.length > 0) {
          this.props.item.fileUploads.forEach((obj) => {
            if (obj.caption === this.state.attachCaption) {
              isValid = false;
              msg = "Duplicate doc Name. Require unique doc name.";
            }
          });
        }
      }
    }

    if (msg !== "") msg = "Errors: " + msg;

    return { status: isValid, Msg: msg };
  };

  apDepUpdate = async () => {
    let isValid = await this.validateBeforeUpload();
    // console.log("isValid:", isValid);
    if (isValid.status) {
      let fileUpload = null;
      // console.log("this.state.docS3FileData:", this.state.docS3FileData);
      if (this.state.docS3FileData) {
        // console.log(
        //   "in here 111 this.state.noAttachmentFlag:",
        //   this.state.noAttachmentFlag
        // );
        if (this.state.noAttachmentFlag) {
    
          // If s3file is uploaded and then NoAttachment is selected, delete the uploaded file.
          let s3FileToDelete = {
            key: this.state.docS3FileData.key,
            s3subdir: this.state.docS3FileData.subBucket,
          };
          await deleteS3File(s3FileToDelete);
          // console.log("delret:", delret);
        } else {
   
          fileUpload = {
            key: this.state.docS3FileData.key,
            type: "pdf",
            location: this.state.docS3FileData.location,
            subBucket: this.state.docS3FileData.subBucket,
            caption: this.state.attachCaption,
          };
        }
      }
      let inputData = {
        communityId: this.props.communityId,
        baandaId: this.props.auth.user.baandaId,
        itemId: this.props.item.itemId,
        appreciationDriver: this.state.appreciationDriver,
        assetValuation: {
          byValue: this.state.actAmount,
          actiontNote: this.state.actNote,
          actionDate: this.state.actionDate,
          actionType: this.state.actionType,
        },
        fileUpload,
      };

      // console.log("inputData:", inputData);

      let url = baandaServer + apDepreciateSave;
      try {
        let retupdt = await axios.post(url, inputData);
        // console.log("retupdt:", retupdt);
        if (retupdt.data.status === "success") {
          await this.setState({
            showApDepPanel: false,
            attachmentButtonFlag: false,
            pdfViewButtonFlag: false,
            attachDocFlag: false,
            updateOkFlag: true,
          });
        } else {
          await this.setState({
            updateOkFlag: false,
            updtErrMsg: retupdt.Msg,
          });
        }
      } catch (err) {
        console.log("err:", err.message);
      }
    } else {
      await this.setState({
        updtErrMsg: isValid.Msg,
      });
    }
  };

  handleAppreciationCause = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleActionType = async (e) => {
    let value = e.target.value;

    let msg;
    let appreciationDriverFlag = false;
    if (value === "depreciate") {
      msg =
        "Note: This will reduce the overall asset value by the amount, treated as an expense in the books (journal). It may be used as tax-deduction per the law.";
    } else if (value === "appreciate") {
      appreciationDriverFlag = true;
      msg =
        "Note: An amount (cash) spent will increase the value of the asset as an enhancement instead of treating as an expense.";
    } else {
      msg =
        "Attach a Pdf-document and/or a note with this Capital Asset without appreciating or depriciating.";
    }

    await this.setState({
      actionType: value,
      apdepMsg: msg,
      appreciationDriverFlag,
    });
  };

  onChangePrice = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  callerOfDocAttachment = async (fileData) => {
    // console.log(">>> fileData:", fileData);
    let docS3Data;
    if (fileData.ifDeletedFlag) {
      docS3Data = null;
    } else {
      docS3Data = this.state.docS3FileData;
      if (fileData.s3FileData) docS3Data = fileData.s3FileData;
    }
    await this.setState({
      attachDocFlag: false,
      docS3FileData: docS3Data, // If return is null, retain the original docS3FileData state.
      showApDepPanel: true,
    });
  };

  readyToAttach = async () => {
    let msg = "";
    let isValid = true;
    if (this.state.attachCaption.trim() === "") {
      isValid = false;
      msg = "The document name must be non-blank and unique.";
    }
    return { status: isValid, Msg: msg };
  };

  randfn = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  readyDocAttach = async () => {
    let retrtd = await this.readyToAttach();
    if (retrtd.status === true) {
      let randomct, commrn, subBucket, fileType;
      if (this.state.docSubDirName === "") {
        randomct = this.randfn(100000000, 999999999);
        commrn = randomct.toString(16);
        subBucket =
          "cid" +
          this.props.communityId +
          "-itemid" +
          this.props.item.itemId +
          "-" +
          this.state.actionType +
          commrn;
      } else subBucket = this.state.docSubDirName;
      let docS3FileData = null;
      if (this.state.docS3FileData) docS3FileData = this.state.docS3FileData;

      fileType = ["pdf"];
      await this.setState({
        docvalidFileType: fileType,
        docSubDirName: subBucket,
        docMaxFileSize: maxpdfSize,
        docS3FileData,
        showApDepPanel: false,
        attachDocFlag: true,
      });
    } else {
      await this.setState({
        actionMsg: retrtd.Msg,
      });
    }
  };

  handleAttachmentDecision = async () => {
    await this.setState((prevstate) => ({
      noAttachmentFlag: !prevstate.noAttachmentFlag,
    }));
    // console.log("this.state.noAttachmentFlag:", this.state.noAttachmentFlag);
    if (this.state.noAttachmentFlag) {
      await this.setState({
        attachmentButtonFlag: false,
      });
    } else {
      await this.setState({
        attachmentButtonFlag: true,
      });
    }
    // console.log(
    //   "this.state.attachmentButtonFlag: ",
    //   this.state.attachmentButtonFlag
    // );
  };

  handleConfirmDecision = async () => {
    await this.setState((prevstate) => ({
      confirmToUpdate: !prevstate.confirmToUpdate,
    }));
  };

  handleActionDate = async (date) => {
    await this.setState({
      actionDate: date,
    });
  };

  viewAttachedFiles = async () => {
    let pdfFiles = [];
    let fileObj = {};
    this.props.item.fileUploads.forEach((obj) => {
      if (obj && obj.type === "pdf") {
        fileObj = {
          fileName: obj.caption,
          location: obj.location,
        };
        pdfFiles.push(fileObj);
      }
    });

    if ( pdfFiles.length > 0 ) {
      await this.setState({
        pdfFilesToView: pdfFiles,
        showPdfFilesFlag: true,
        attachDocFlag: false,
        showApDepPanel: false,
        updateOkFlag: false,
      });
    } else {
      console.log('It should never come here');
    }

  };

  returnFromViewingFiles = async () => {
    

    await this.setState({
      pdfFilesToView: null,
      showPdfFilesFlag: false,
      attachDocFlag: false,
      showApDepPanel: true,
      updateOkFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ApDepriciate...');

    let buttonPanel;
    buttonPanel = (
      <div>
        <div className="row">
          <div className="col-md-4 apdep-confirm text-right">
            <Checkbox
              checked={this.state.confirmToUpdate}
              onChange={this.handleConfirmDecision}
            />
            &nbsp;Confirm to Update
          </div>
          <div className="col-md-8 text-left">
            {this.state.confirmToUpdate ? (
              <button
                className="btn-apdep-update"
                onClick={this.apDepUpdate}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Update
              </button>
            ) : null}
            {!this.state.noAttachmentFlag ? (
              <button
                className="btn-apdep-attach"
                onClick={this.readyDocAttach}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Attach &nbsp;
                <i className="fa fa-paperclip" />
              </button>
            ) : null}

            {this.state.pdfViewButtonFlag ? (
              <button
                className="btn-apdep-view"
                onClick={this.viewAttachedFiles}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                View&nbsp;
                <i className="fa fa-paperclip" />
              </button>
            ) : null}
            <button
              className="btn-apdep-cancel"
              onClick={this.returnToTheItemMgmt}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Cancel
            </button>
            <button
              className="btn-modal-apdep"
              type="button"
              onClick={this.openAlertModal}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-info-circle" />
            </button>
          </div>
        </div>
        <div className="apdep-action-msg">{this.state.actionMsg}</div>
        <div className="apdep-action-err-msg">{this.state.updtErrMsg}</div>
      </div>
    );

    let assetNamePanel = (
      <div className="text-center asset-item-header">
        <b>Name:</b>&nbsp;{this.props.item.itemName}&nbsp;|&nbsp;
        <b>Category:</b>&nbsp;{this.props.item.category}&nbsp;|&nbsp;
        <b>Class:</b>&nbsp;
        {this.props.item.categoryOf === "asset" ? "Capital Asset" : null}
      </div>
    );

    let assetActivityPanel = (
      <div>
        <div className="text-center action-header">
          Capital Asset's Activity
        </div>
        <div className="fixedsize-asset-activity">
          {this.props.item.assetValuation.length > 0 ? (
            <div>
              {this.props.item.assetValuation.map((obj, i) => (
                <div key={i}>
                  <div className="row">
                    <div className="col asset-activity-lines text-left">
                      {i + 1}.&nbsp;{moment(obj.actionDate).format("L")}
                      &nbsp;[Action:&nbsp;{obj.actionType}]&nbsp;--[By $
                      {this.commaFormattedCurrency(parseFloat(obj.byValue))}
                      ]&nbsp;
                      {obj.note === "" ? "No notes" : obj.actionNote}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center">No actions on this PO yet.</div>
          )}
        </div>
      </div>
    );

    let summaryPanel = (
      <div className="text-center asset-item-summary">
        <div className="asset-summary-head">
          <b>Summary Valuation</b>
        </div>
        <div className="asset-item-summary">
          <b>Original Value:</b>&nbsp;$
          {this.commaFormattedCurrency(this.state.originalAssetValue)}
          &nbsp;&nbsp;&nbsp;||&nbsp;&nbsp;&nbsp;
          <b>Current Value:</b>&nbsp;$
          {this.commaFormattedCurrency(this.state.currentAssetVallue)}
        </div>
      </div>
    );

    let activityTypeSelectionPanel;
    activityTypeSelectionPanel = (
      <div className="actiivty-row-placement">
        <div className="row">
          <div className="col text-center radio-fonts">
            <strong>Select Action: &nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="depreciate"
                  checked={this.state.actionType === "depreciate"}
                  onChange={this.handleActionType}
                />{" "}
                Depreciate
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="appreciate"
                  checked={this.state.actionType === "appreciate"}
                  onChange={this.handleActionType}
                />{" "}
                Appreciate
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="docattach"
                  checked={this.state.actionType === "docattach"}
                  onChange={this.handleActionType}
                />{" "}
                Note & Doc
              </label>
            </div>
          </div>
        </div>
        <div className="text-center apdepmsg-format">{this.state.apdepMsg}</div>
      </div>
    );

    // let apprecationCausePanel;
    // if (this.state.appreciationDriverFlag) {
    //   apprecationCausePanel = (
    //     <div className="actiivty-row-placement">
    //       <div className="row">
    //         <div className="col text-center radio-fonts">
    //           <strong>Driver: &nbsp;&nbsp;</strong>
    //           <div className="form-check form-check-inline">
    //             <label className="form-check-label">
    //               <input
    //               name='appreciationDriver'
    //                 className="form-check-input"
    //                 type="radio"
    //                 value="marketValue"
    //                 checked={this.state.appreciationDriver === "marketValue"}
    //                 onChange={this.handleAppreciationCause}
    //               />{" "}
    //               Market Value
    //             </label>
    //           </div>
    //           <div className="form-check form-check-inline">
    //             <label className="form-check-label">
    //               <input
    //               name='appreciationDriver'
    //                 className="form-check-input"
    //                 type="radio"
    //                 value="expensedImprovement"
    //                 checked={
    //                   this.state.appreciationDriver === "expensedImprovement"
    //                 }
    //                 onChange={this.handleAppreciationCause}
    //               />{" "}
    //               Expensed Improvement
    //             </label>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   );
    // }

    let actionDatePanel = (
      <div className="row asset-act-row">
        <div className="col-3 text-right asset-act-lbl">Action Date</div>
        <div className="col-9 text-left">
          <DatePicker
            selected={this.state.actionDate}
            onChange={this.handleActionDate}
            name="actionDate"
            dateFormat="MM/DD/YYYY"
          />
        </div>
      </div>
    );

    let actNowPanel = (
      <div>
        {actionDatePanel}
        {this.state.actionType === "docattach" ? null : (
          <div className="row asset-act-row">
            <div className="col-3 text-right asset-act-lbl">Amount&nbsp;$</div>
            <div className="col-9 text-left">
              <input
                name="actAmount"
                type="number"
                value={this.state.actAmount}
                onChange={this.onChangePrice}
                size="20"
                className="asset-act-amount"
                step=".01"
                placeholder=""
                autoComplete="off"
              />
            </div>
          </div>
        )}

        <div className="row asset-act-row">
          <div className="col-3 text-right asset-act-lbl">Comment</div>
          <div className="col-9 text-left">
            <textarea
              name="actNote"
              value={this.state.actNote}
              onChange={this.onChange}
              // size="50"
              maxLength="500"
              rows="2"
              className="asset-act-note"
              placeholder="Enter a Note  (optional - max 500 chars)"
            />
          </div>
        </div>
        <div className="row asset-act-row">
          <div className="col-3 text-right asset-act-lbl">
            <i className="fa fa-paperclip" />
            &nbsp;Doc Name
          </div>
          {this.state.attachmentButtonFlag ? (
            <div className="col-5 text-left">
              <input
                name="attachCaption"
                type="text"
                value={this.state.attachCaption}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="apdep-attach-caption"
                placeholder="Enter unique attachment name ..."
              />
            </div>
          ) : (
            <div className="col-5 text-left">&nbsp;</div>
          )}

          <div className="col-4 text-center asset-act-lbl">
            <i className="fa fa-paperclip" />
            &nbsp;
            <Checkbox
              checked={this.state.noAttachmentFlag}
              onChange={this.handleAttachmentDecision}
            />
            &nbsp;No attachment
          </div>
        </div>
      </div>
    );

    let attcahDocPanel;
    if (this.state.attachDocFlag) {
      attcahDocPanel = (
        <div className="apdep-loadfile-placement">
          <LoadFileToS3
            returnToCaller={this.callerOfDocAttachment}
            communityId={this.props.communityId}
            fileType={this.state.docvalidFileType}
            maxSize={this.state.docMaxFileSize}
            s3subdir={this.state.docSubDirName}
            requestor="Appreciation-Depreciation-Doc (pdf)"
            s3FileData={this.state.docS3FileData}
            type="pdf"
          />
        </div>
      );
    }

    let viewPdfPanel;
    if (this.state.showPdfFilesFlag) {
      viewPdfPanel = (
        <div>
          <ViewPdfFiles
            returnToCaller={this.returnFromViewingFiles}
            communityId={this.props.communityId}
            filesToView={this.state.pdfFilesToView}
            domain="Appreciate / Depriciate Documents"
          />
        </div>
      );
    }

    let outputPanel;
    if (this.state.showApDepPanel) {
      outputPanel = (
        <div className="inv-ciate-box">
          {assetNamePanel}
          {summaryPanel}
          {assetActivityPanel}
          {activityTypeSelectionPanel}
          {actNowPanel}
          {buttonPanel}
          <div className="apdep-space-below-button" />
        </div>
      );
    }

    if (this.state.attachDocFlag) {
      outputPanel = <div>{attcahDocPanel}</div>;
    }

    if (this.state.updateOkFlag) {
      outputPanel = (
        <div>
          {assetNamePanel}
          {buttonPanel}
        </div>
      );
    }

    if (this.state.showPdfFilesFlag) {
      outputPanel = <div>{viewPdfPanel}</div>;
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ApDepriciate.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApDepriciate);
