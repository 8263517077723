import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import moment from "moment";

// import axios from "axios";

import ManualInvRecvPayment from "../../../../finance/ManualInvRecvPayment";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./ACustomerInvoice.css";

class ACustomerInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      theInvoice: "",
      showInvoiceFlag: true,
      showPaymentFlag: false,
    };
  }

  componentDidMount = async () => {};

  returnToPayPending = () => {
    this.props.returnToCaller();
  };

  commaFormattedCurrency = (number) => {
    // console.log('number: ', number);
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleInvoicePayment = async () => {
    this.setState({
      showInvoiceFlag: false,
      showPaymentFlag: true,
    });
  };

  returnFromInvoicePayment = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ACustomerInvoice...');

    let te = this.props.theInvoice;
    let temb = this.props.theInvoice.manualInvoiceParams.billToAddress;

    let exitinvoiceButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToPayPending}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let payButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleInvoicePayment}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Pay
      </button>
    );

    let invdate, customername, totalamount, paidamount, dueamount, creationtype, invoiceterms;
    if (this.state.deviceSize === "big") {
      invdate = "Invoice Date";
      customername = "Customer Name";
      totalamount = "Total Amount";
      paidamount = "Paid Amount";
      dueamount = "Due Amount";
      creationtype = "Origin Type";
      invoiceterms = "Invoice Terms";
    } else {
      invdate = "Inv-Date";
      customername = "Cust-Name";
      totalamount = "Total-Amt";
      paidamount = "Paid";
      dueamount = "Due";
      creationtype = "Origin";
      invoiceterms = "Terms";
    }

    let headerPanel;
    headerPanel = (
      <div>
        <div className="text-center cust-inv-detail-header">
          Invoice Details &nbsp;&nbsp;{exitinvoiceButton}
        </div>
        <div className="row inv-header-row-pos">
          <div className="col-md-6 text-center">
            <div className="row">
              <div className="col-4 text-right cust-invoice-lbl">
                Invoice Id
              </div>
              <div className="col-8 text-left cust-invoice-txt">
                {te.invoiceId}
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right cust-invoice-lbl">{invdate}</div>
              <div className="col-8 text-left cust-invoice-txt">
                {te.invoiceDate.substring(0, 10)}
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right cust-invoice-lbl">
                {customername}
              </div>
              <div className="col-8 text-left cust-invoice-txt">
                {te.customerName}
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right cust-invoice-lbl">Email</div>
              <div className="col-8 text-left cust-invoice-txt">
                {te.customerEmail}
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right cust-invoice-lbl">
                Bill Address
              </div>
              <div className="col-8 text-left cust-invoice-txt">
                {temb.line1}, {temb.city}, {temb.state} {temb.postalCode}{" "}
                {temb.country}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="row">
              <div className="col-4 text-right cust-invoice-lbl">
                {totalamount}
              </div>
              <div className="col-8 text-left cust-invoice-txt">
                ${" "}
                {this.commaFormattedCurrency(
                  te.financeBreakdown.totalInvoiceAmount
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right cust-invoice-lbl">
                {paidamount}
              </div>
              <div className="col-8 text-left cust-invoice-txt">
                $ {this.commaFormattedCurrency(te.financeBreakdown.amountPaid)}
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right cust-invoice-lbl">
                {dueamount}
              </div>
              <div
                className={
                  te.financeBreakdown.totalInvoiceAmount -
                    te.financeBreakdown.amountPaid >
                  0
                    ? "col-8 text-left cust-invoice-txt-pending"
                    : "col-8 text-left cust-invoice-txt"
                }
              >
                {/* <div className="col-8 text-left cust-invoice-txt"> */}${" "}
                {this.commaFormattedCurrency(
                  te.financeBreakdown.totalInvoiceAmount -
                    te.financeBreakdown.amountPaid
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right cust-invoice-lbl">Sales Tax</div>
              <div className="col-8 text-left cust-invoice-txt">
                ${" "}
                {this.commaFormattedCurrency(
                  te.financeBreakdown.salesTaxAmount
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right cust-invoice-lbl">Shipping</div>
              <div className="col-8 text-left cust-invoice-txt">
                $ {this.commaFormattedCurrency(te.financeBreakdown.shippingAmt)}
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right cust-invoice-lbl">Due Date</div>
              <div className="col-8 text-left cust-invoice-txt">
                {this.props.theInvoice.manualInvoiceParams.dueDate.substring(
                  0,
                  10
                )}
                &nbsp;
                {moment().isAfter(
                  moment(
                    this.props.theInvoice.manualInvoiceParams.dueDate.substring(
                      0,
                      10
                    )
                  )
                ) ? (
                  <font color="red">&nbsp;|&nbsp;Overdue</font>
                ) : (
                  <font color="green">&nbsp;|&nbsp;Not overdue</font>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-4 text-right cust-invoice-lbl">
                {creationtype}
              </div>
              <div className="col-8 text-left cust-invoice-txt">
                {te.invoiceCreationType.toUpperCase()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    let detailsLinePanel;
    let bool = true;
    detailsLinePanel = (
      <div>
        {this.state.deviceSize === "big" ? (
          <div className="row inv-line-items-header">
            <div className="col-10 text-left inv-line-details-lbl">
              #. Description | Type | Unit | Price | Qty
            </div>
            <div className="col-2 text-right inv-line-details-lbl">Amount</div>
          </div>
        ) : (
          <div className="row inv-line-items-header">
            <div className="col-10 text-left inv-line-details-lbl">
              #. Desc | Unit | Price | Qty
            </div>
            <div className="col-2 text-right inv-line-details-lbl-amt">Amt</div>
          </div>
        )}

        <div className="inv-biz-show-lines-box">
          {te.itemDetails.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    bool ? "row row-col-details-dr" : "row row-col-details-lt"
                  }
                >
                  <div className="col-10 text-left inv-line-details-lbl-x">
                    {obj.lineNo}. {obj.description} 
                    { this.state.deviceSize === "big" ? "| " + obj.subLineItemType : null}
                    | {obj.unit} | {this.commaFormattedCurrency(obj.price)}{" "}
                    | {obj.quantity}
                  </div>
                  <div className="col-2 text-right inv-line-details-lbl-amt-val">
                    {this.commaFormattedCurrency(obj.price * obj.quantity)}
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let termsPanel;
    termsPanel = (
      <div className="row inv-terms-panel-pos">
        <div className="col-2 text-right inv-terms-panel-lbl">
          {invoiceterms}
        </div>
        <div className="col-9 text-left inv-terms-panel-box">
          {te.paySchedulePolicy.invoiceTerms !== ""
            ? te.paySchedulePolicy.invoiceTerms
            : "None specified"}
        </div>
        <div className="col-1 text-left">&nbsp;</div>
      </div>
    );

    let outputPanel;

    if (this.state.showInvoiceFlag) {
      outputPanel = (
        <div className="cust-invoice-detail-box">
          {headerPanel}
          { this.state.deviceSize === "big" ? <hr className="top-hr-inv" /> : null}
          
          {detailsLinePanel}
          {termsPanel}
          <div className="text-center cust-inv-pay-btn-pos">{payButton}</div>
        </div>
      );
    }

    if (this.state.showPaymentFlag) {
      let store = null;
      if ( this.props.store ) store = this.props.store;
      outputPanel = (
        <div>
          <ManualInvRecvPayment
            theInvoice={this.props.theInvoice}
            communityId={this.props.communityId}
            community={this.props.community}
            returnToCaller={this.returnFromInvoicePayment}
            caller="buyer"
            store={store}
          />
        </div>
      ); 
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ACustomerInvoice.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ACustomerInvoice)
);
