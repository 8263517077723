import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./ReturnResponse.css";

import ReturnOpsMain from "./returns/ReturnOpsMain";
import ConductCashRefund from "./returns/ConductCashRefund";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCommunityReturns = "/routes/finance/getCommunityReturns?";
const getAnInvoice = "/routes/invoice/getAnInvoice?";

const readMeCode = "1112111111";

class ReturnResponse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      presentReqs: [],

      errMsg: "",
      errFlag: false,

      showOnlineFlag: true,
      showInpersonFlag: false,
      showPolicyFlag: false,
      showAnInvoiceFlag: false,
      manageRefundFlag: false,

      accessPath: "online",

      invoiceId: 0,
      returnLaw: null,
      theInvoice: null,

      invoiceItems: [],
      selectedItems: [],
      thisSelectedItem: null,
      refundCancelId: 0,
      hasMoreItemsToReturn: false,
    };
  }

  componentDidMount = async () => {
    await this.getPresentRequests();
    this.setState({
      returnLaw: this.props.community.returnTraits,
    });
  };

  getPresentRequests = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let param =
      "communityId=" + this.props.communityId + "&coopStoreId=" + coopStoreId;
    let url = baandaServer + getCommunityReturns + param;
    try {
      let prret = await axios.get(url);
      if (prret.data.status === "success") {
        this.setState({
          presentReqs: prret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: prret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      errMsg: "",
      errFlag: false,
    });
  };

  selectThisItemForReview = async (obj) => {
    // console.log("selectThisItemForReview obj:", obj);
    // console.log("selectThisItemForReview this.state:", this.state);
    // this.performRefundOps(obj);
    this.setState({
      showOnlineFlag: false,
      showInpersonFlag: false,
      showPolicyFlag: false,
      showAnInvoiceFlag: false,
      manageRefundFlag: true,
      invoiceId: obj.refInvoiceId,
      refundCancelId: obj.refundCancelId,
      accessPath: "online",
    });
  };

  performInpersonRefund = async () => {
    // console.log("perform Inerson Refund this.state:", this.state);
    this.setState({
      showOnlineFlag: false,
      showInpersonFlag: false,
      showPolicyFlag: false,
      showAnInvoiceFlag: false,
      manageRefundFlag: true,
      invoiceId: this.state.theInvoice.invoiceId,
      accessPath: "inperson",
    });
  };

  handleAccessPath = async (e) => {
    let showOnlineFlag = false;
    let showInpersonFlag = false;
    let value = e.target.value;
    if (value === "inperson") showInpersonFlag = true;
    if (value === "online") {
      showOnlineFlag = true;
    }
    this.setState({
      accessPath: e.target.value,
      showOnlineFlag,
      showInpersonFlag,
      theInvoice: null,
      showAnInvoiceFlag: false,
    });
  };

  returnFromRefundOps = async () => {
    await this.getPresentRequests();
    let showOnlineFlag = false;
    let showInpersonFlag = false;
    if (this.state.accessPath === "inperson") showInpersonFlag = true;
    if (this.state.accessPath === "online") showOnlineFlag = true;
    this.setState({
      manageRefundFlag: false,
      showInpersonFlag,
      showOnlineFlag,
      invoiceId: 0,
      refundCancelId: 0,
      selectedItems: [],
    });
  };

  handleReturnLaw = async () => {
    this.setState({
      showPolicyFlag: true,
      showRequestButton: false,
      showAnInvoiceFlag: false,
      errMsg: "",
      errFlag: false,
    });
  };

  clearReturnLaw = async () => {
    this.setState({
      showPolicyFlag: false,
    });
  };

  getAnInvoice = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let param =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&role=community&baandaId=" +
      this.props.auth.user.baandaId +
      "&invoiceId=" +
      this.state.invoiceId;
    let url = baandaServer + getAnInvoice + param;
    // console.log("url:", url);
    try {
      let invret = await axios.get(url);
      // console.log("invret:", invret);
      if (invret.data.status === "success") {
        if (invret.data.Msg) {
          let invoiceItems = [];
          if (invret.data.Msg.invoiceRaw) {
            if (
              invret.data.Msg.invoiceRaw.subscription &&
              invret.data.Msg.invoiceRaw.subscription.allowSubscription
            ) {
              this.setState({
                errMsg:
                  "A subscription cannot be refunded. Request for subscripion pause or cancel. Refund via expense if damaged.",
                errFlag: true,
              });
            } else {
              invret.data.Msg.invoiceRaw.invoiceLineItems.forEach((inv) => {
                let invObj = {
                  itemId: inv.itemId,
                  itemName: inv.itemName,
                  quantity: inv.subItem.quantity,
                  price: inv.price,
                  refundReq: false,
                  seqno: inv.subItem.seqno,
                };

                invoiceItems.push(invObj);
              });
            }
          } else {
            invret.data.Msg.itemDetails.forEach((inv) => {
              let invObj = {
                itemId: inv.itemId,
                itemName: inv.itemName,
                quantity: inv.quantity,
                price: inv.price,
              };
              invoiceItems.push(invObj);
            });
          }
          this.setState({
            theInvoice: invret.data.Msg,
            invoiceItems,
            // errMsg: "",
            // errFlag: false,
          });
        } else {
          await this.setState({
            theInvoice: null,
            invoiceItems: [],
            errMsg: "You do not have an invoice with the Id dpecified.",
            errFlag: true,
          });
        }
      } else {
        await this.setState({
          errMsg: invret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getAnInvoice err:", err.message);
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }

    // console.log('this.state.errMsg:', this.state.errMsg);
  };

  checkTheInvoiceId = async () => {
    await this.getAnInvoice();

    let existsInrequests = false;
    if (this.state.theInvoice) {
      // console.log('in here check 11 this.state.presentReqs.length:', this.state.presentReqs.length);
      if (this.state.presentReqs.length > 0) {
        this.state.presentReqs.forEach((inv) => {
          if (inv.refInvoiceId === this.state.theInvoice.invoiceId)
            existsInrequests = true;
        });
      }
      // console.log("existsInrequests:", existsInrequests);
      if (existsInrequests) {
        this.setState({
          errMsg:
            "This invoice has been raised for refund request. Please check the On Line Requests.",
          errFlag: true,
        });
      } else {
        // console.log("in here check 22 this.state.errMsg:", this.state.errMsg);
        let msg = "";

        let hasMoreItemsToReturn = false;
        if (
          this.state.theInvoice.refunds &&
          this.state.theInvoice.refunds.length > 0
        ) {
          console.log("in here 304 ...");
          let msg = "";

          this.state.theInvoice.refunds.forEach((ref) => {
            // console.log("ref:", ref);
            ref.itemsReturnedInThis.forEach((obj) => {
              // console.log("obj:", obj);
              this.state.invoiceItems.forEach((ivi) => {
                // console.log("ivi:", ivi, " obj:", obj);
                if (obj.itemId === ivi.itemId) {
                  ivi.refundReq = true;
                }
              });
            });
          });

          this.setState({
            errMsg: msg,
            hasMoreItemsToReturn,
            errFlag: false,
          });
        }
        if (
          this.state.theInvoice.refunds &&
          this.state.theInvoice.refunds.length > 0
        ) {
          hasMoreItemsToReturn = true;
        }
        let showAnInvoiceFlag = true;
        if (this.state.errFlag) showAnInvoiceFlag = false;
        this.setState({
          showOnlineFlag: false,
          showInpersonFlag: true,
          showPolicyFlag: false,
          showAnInvoiceFlag,
          selectedItems: [],
          thisSelectedItem: null,
          hasMoreItemsToReturn,
          errMsg: msg,
          errFlag: false,
        });
      }
    } else {
      this.setState({
        errMsg: "No such invoice number exists for this community.",
        errFlag: true,
      });
    }
  };

  closeInvoicesummary = async () => {
    this.setState({
      showAnInvoiceFlag: false,
      invoiceId: 0,
      invoiceItems: [],
      selectedItems: [],
      thisSelectedItem: null,
    });
  };

  selectThisItemForReturn = async (obj) => {
    // console.log("obj:", obj);
    let subItemFlag = true;
    // console.log(
    //   "this.state.theInvoice.invoiceRaw:",
    //   this.state.theInvoice.invoiceRaw
    // );
    if (!this.state.theInvoice.invoiceRaw) subItemFlag = false;
    // console.log("subItemFlag:", subItemFlag);
    let seqno = 99999999;
    if (obj.seqno >= 0) seqno = obj.seqno;

    let selected = [...this.state.selectedItems];
    let itExists = false;
    this.state.selectedItems.forEach((elm) => {
      // console.log("elm.itemId:", elm.itemId, " obj.itemId:", obj.itemId);
      if (elm.itemId === obj.itemId) itExists = true;
    });
    if (!itExists) {
      let selObj = {
        itemId: obj.itemId,
        itemName: obj.itemName,
        price: obj.price,
        quantity: obj.quantity,
        seqno,
        subItemFlag,
      };
      selected.push(selObj);
    }

    await this.setState({
      selectedItems: selected,
      showCommentFlag: true,
      thisSelectedItem: obj,
    });
  };

  deselectThisItemToReturn = async (obj) => {
    // console.log("obj:", obj);
    let modsel = [];
    this.state.selectedItems.forEach((sel) => {
      if (obj.itemName !== sel.itemName) modsel.push(sel);
    });

    this.setState({
      selectedItems: modsel,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ReturnResponse...");

    let buttonModalInvRet;
    buttonModalInvRet = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let returnLawButton;
    returnLawButton = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.handleReturnLaw}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Clause
      </button>
    );

    let buttonSearch;
    buttonSearch = (
      <button
        className="btn_reg_40"
        onClick={this.checkTheInvoiceId}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search" />
      </button>
    );

    let policyPanel;
    if (this.state.showPolicyFlag && this.state.returnLaw) {
      policyPanel = (
        <div className="return-policy-box">
          <div className="text-center return-policy-header">
            Return & Cancellation Policy
          </div>
          <div className="text-center return-policy-generic">
            <p align="justify">
              <b>Generic</b>:Good and services may be returned or cancelled
              based on the policy set forth by the community (see below). For
              e-commerce, request here. For in-person cash/check transactions,
              approach the business in person. To pause/cancel subscription
              please swith to Operation - Pay-Ctrl.
            </p>
          </div>
          <div className="text-center return-policy-specific">
            <p align="justify">{this.state.returnLaw.clauseText}</p>
          </div>
          <div className="text-center return-policy-specific">
            Allowed to return within {this.state.returnLaw.withinDays} days of
            the purchase for goods. The final decision:{" "}
            {this.state.returnLaw.decider}.
          </div>
          <div className="text-center">
            <button
              className="btn_reg_70"
              type="button"
              onClick={this.clearReturnLaw}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Clear
            </button>
          </div>
        </div>
      );
    }

    let accessPathSelect;
    accessPathSelect = (
      <div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Access Path&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="online"
                  checked={this.state.accessPath === "online"}
                  onChange={this.handleAccessPath}
                />{" "}
                On line Requests
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="inperson"
                  checked={this.state.accessPath === "inperson"}
                  onChange={this.handleAccessPath}
                />{" "}
                In-person Request
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let outputPanel;
    let bool = true;
    if (this.state.showOnlineFlag) {
      if (this.state.presentReqs.length > 0) {
        outputPanel = (
          <div className="">
            <div className="row">
              <div className="col  text-center return-resp-header">
                Returns Request {buttonModalInvRet}
              </div>
            </div>
            {accessPathSelect}
            <div className="row cust-resp-list-head-row">
              <div className="col-11 text-left cust-resp-list-head">
                Last activity data - Customer Name ( Email ) | Invoice id |
                Status | RefundId
              </div>
              <div className="col-1 text-left cust-resp-list-head">More</div>
            </div>
            <div className="return-list-box">
              {this.state.presentReqs.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        bool
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-11 text-left refresp-list-text">
                        {obj.lastActivityDate.substring(0, 10)} -{" "}
                        {obj.customerName} ({obj.customerEmail}) |{" "}
                        {obj.refInvoiceId} | {obj.status} | {obj.refundCancelId}
                      </div>

                      <div className="col-1 text-left">
                        <button
                          className="btn_reg_40"
                          type="button"
                          onClick={() => this.selectThisItemForReview(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          ...
                        </button>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        outputPanel = (
          <div>
            <div className="row">
              <div className="col  text-center return-resp-header">
                Returns Request {buttonModalInvRet}
              </div>
            </div>
            {accessPathSelect}
            <div className="row cust-resp-list-head-row">
              <div className="col-11 text-left cust-resp-list-head">
                Last activity data - Customer Name ( Email ) | Invoice id |
                Status | RefundId
              </div>
              <div className="col-1 text-left cust-resp-list-head">More</div>
            </div>
            <div className="return-list-box">
              <div className="return-nolist-msgpos text-center">
                No return request to handle.
              </div>
            </div>
          </div>
        );
      }
    }

    let invoiceIdPanel;
    invoiceIdPanel = (
      <div>
        <div className="row cust-ret-invoice-id-pos">
          <div className="col-3 text-right cust-ret-invoice-id">Invoice Id</div>
          <div className="col-9 text-left">
            <input
              name="invoiceId"
              type="number"
              value={this.state.invoiceId}
              onChange={this.onChange}
              size="14"
              maxLength="14"
              className="cust-ret-invoice-id-text"
              placeholder=""
            />{" "}
            &nbsp;
            {buttonSearch}
            {returnLawButton}
          </div>
        </div>
      </div>
    );

    let invoiceItemListPanel;
    if (this.state.invoiceItems.length > 0) {
      invoiceItemListPanel = (
        <div>
          <div className="row ret-can-items-row">
            <div className="col-2 text-right custret-inv-item-ops">Select</div>

            <div className="col-10 text-center custret-inv-item-text-head">
              Invoice Items
            </div>
          </div>
          <div className="fixedsize-custret-inv-items">
            {this.state.invoiceItems.map((obj, i) => {
              return (
                <div key={i}>
                  <div className="row">
                    <div className="col-2 text-right">
                      {!obj.refundReq ? (
                        <button
                          className="btn_reg_30"
                          type="button"
                          onClick={() => this.selectThisItemForReturn(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          Ck
                        </button>
                      ) : (
                        <button className="btn_reg_30_disabled" type="button">
                          Rq
                        </button>
                      )}
                    </div>
                    <div className="col-10 text-left custret-inv-item-text">
                      {obj.itemName}&nbsp;(${" "}
                      {this.commaFormattedCurrency(obj.price)} | Qty:
                      {obj.quantity}){" "}
                      {obj.refundReq ? (
                        <font size="1">Refund-req exists/handled.</font>
                      ) : null}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let showAnInvoicePanel;
    if (this.state.showAnInvoiceFlag) {
      showAnInvoicePanel = <div>{invoiceItemListPanel}</div>;
    }

    let inPersonButtons;
    if (this.state.selectedItems.length > 0 && !this.state.showPolicyFlag) {
      inPersonButtons = (
        <div className="ret-inperson-btn-pos">
          <button
            className="btn_reg_80"
            onClick={this.performInpersonRefund}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Proceed
          </button>
          <button
            className="btn_reg_70"
            onClick={this.closeInvoicesummary}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Clear
          </button>
        </div>
      );
    }

    let selecteditemsPanel;
    if (this.state.selectedItems.length > 0 && !this.state.showPolicyFlag) {
      selecteditemsPanel = (
        <div>
          <div className="row ret-can-items-row">
            <div className="col-2 text-right custret-inv-item-ops">Ops</div>

            <div className="col-10 text-center custret-inv-item-text-head">
              Items for Refund
            </div>
          </div>
          <div className="fixedsize-custret-inv-items">
            {this.state.selectedItems.map((obj, i) => {
              return (
                <div key={i}>
                  <div className="row">
                    <div className="col-2 text-right">
                      <button
                        className="btn-sel-item-remove-from-list"
                        type="button"
                        onClick={() => this.deselectThisItemToReturn(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                      {/* )} */}
                    </div>
                    <div className="col-10 text-left custret-inv-item-text">
                      {obj.itemName}&nbsp;(${" "}
                      {this.commaFormattedCurrency(obj.price)} | Qty:
                      {obj.quantity})
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (this.state.showInpersonFlag) {
      outputPanel = (
        <div>
          <div className="row">
            <div className="col  text-center return-resp-header">
              Returns Request {buttonModalInvRet}
            </div>
          </div>
          {accessPathSelect}
          {invoiceIdPanel}
          {showAnInvoicePanel}
          {selecteditemsPanel}
          {inPersonButtons}
          {policyPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center com-ret-can-msg-err"
                : "text-center com-ret-can-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    if (this.state.manageRefundFlag && this.state.accessPath === "online") {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <ReturnOpsMain
            communityId={this.props.communityId}
            invoiceId={this.state.invoiceId}
            community={this.props.community}
            accessPath={this.state.accessPath}
            selectedItems={this.state.selectedItems}
            returnToCaller={this.returnFromRefundOps}
            refundCancelId={this.state.refundCancelId}
            store={store}
          />
        </div>
      );
    }

    if (this.state.manageRefundFlag && this.state.accessPath === "inperson") {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <ConductCashRefund
            communityId={this.props.communityId}
            theInvoice={this.state.theInvoice}
            invoiceId={this.state.theInvoice.invoiceId}
            community={this.props.community}
            accessPath={this.state.accessPath}
            selectedItems={this.state.selectedItems}
            returnToCaller={this.returnFromRefundOps}
            refundCancelId={this.state.refundCancelId}
            store={store}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ReturnResponse.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReturnResponse)
);
