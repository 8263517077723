const sortArrayOfDates = (data, property, ascDsc) => {
  
  // console.log('data:', data, ' property:', property, ' ascDsc:', ascDsc);
  
  let sorted;
  sorted = data.sort(compare(property, ascDsc));
  // console.log('sorted:', sorted);
  return sorted;
};

const compare = (property, ascDsc) => {
  let updown = 1;
  if (ascDsc === "dsc") {
    updown = -1;
  }
  return function (a, b) {
    const A = new Date(a[property]).getTime();
    const B = new Date(b[property]).getTime();
    // console.log("A:", A, " B:", B, ' a[property]:', a[property]);
    // console.log('updown:', updown);
    if (A > B) {
      return 1 * updown;
    } else if (A < B) {
      return -1 * updown;
    } else {
      return 0;
    }
  };
};

export { sortArrayOfDates } ;