// types is basically a set of constants
export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';

// Messaging from one component to another
export const SET_MSG_TRANSFER = 'SET_MSG_TRANSFER';

// Init questions answered
export const SET_INITQA_DONE = "SET_INITQA_DONE";
export const SET_INITPROFILE_DONE = "SET_INITPROFILE_DONE";


