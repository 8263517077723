import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import { sortArrayOfDates } from "../../../../utils/sortArrayOfDates";

import PayCtrlFilter from "./PayCtrlFilter";
import PayCtrlDetail from "./PayCtrlDetail";

import "./PayCtrlMain.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCommSubscribers = "/routes/payctrl/getCommSubscribers?";

const readMeCode = "1131000000";

class PayCtrlMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      total: 0,

      showListPanelFlag: false,
      showFilterFlag: false,
      showDetailsFlag: false,

      selectedSubscription: null,

      errMsg: "",
      errFlag: false,
      filterState: null,
    };
  }

  componentDidMount = async () => {
    let filter = {
      filterBy: "all",
      caller: this.props.community.commName,
      communityId: this.props.communityId,
      email: "",
    };

    await this.getsubscriptionList(filter);

    this.setState({
      showListPanelFlag: true,
      filterState: filter,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  sortList = async (msgs) => {
    let sorted = await sortArrayOfDates(msgs, "created_at", "dsc");
    return sorted;
  };

  getsubscriptionList = async (fil) => {
    let params =
      "communityId=" +
      fil.communityId +
      "&caller=" +
      fil.caller +
      "&filterBy=" +
      fil.filterBy +
      "&email=" +
      fil.email;
    let url = baandaServer + getCommSubscribers + params;
    // console.log("url:", url);
    try {
      let lret = await axios.get(url);
      // console.log("lret.data:", lret.data);
      if (lret.data.status === "success") {
        this.setState({
          list: await this.sortList(lret.data.Msg.list),
          total: lret.data.Msg.total,
          errMsg: "",
          errFlag: false,
        });
      }
    } catch (err) {
      console.log("getsubscriptionList err:", err.message);
    }
  };

  setSubFilter = async () => {
    this.setState({
      showListPanelFlag: false,
      showFilterFlag: true,
      showDetailsFlag: false,
    });
  };

  returnFromFilter = async (cond) => {
    // console.log("cond:", cond);
    let fil = {
      filterBy: cond.payCtrlType,
      caller: this.props.community.commName,
      communityId: this.props.communityId,
      email: cond.custemail,
    };
    
    await this.getsubscriptionList(fil);

    this.setState({
      showListPanelFlag: true,
      showFilterFlag: false,
      showDetailsFlag: false,
      filterState: fil,
    });
  };

  selectThisItemToManage = async (obj) => {
    this.setState({
      selectedSubscription: obj,
      showListPanelFlag: false,
      showFilterFlag: false,
      showDetailsFlag: true,
    });
  };

  returnFromDetails = async () => {
    await this.getsubscriptionList(this.state.filterState);
    this.setState({
      showListPanelFlag: true,
      showFilterFlag: false,
      showDetailsFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('PayCtrlMain...');

    let infoButto = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let filterButton = (
      <button
        className="btn_reg_80"
        type="button"
        onClick={this.setSubFilter}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Filter <i className="fa fa-filter" />
      </button>
    );

    let subscriptionListPanel;
    let bool = true;

    if (this.state.showListPanelFlag) {
      if (this.state.list.length > 0) {
        subscriptionListPanel = (
          <div>
            <div className="row sub-item-list-row">
              <div className="col-6 text-center pay-ctrl-comm-list-head">
                Item Description
              </div>
              <div className="col-5 text-center pay-ctrl-comm-list-head">
                Customer Req:(
                <i className="fa fa-star" /> New | <i className="fa fa-check" />{" "}
                Replied)
              </div>
              <div className="col-1 text-left pay-ctrl-comm-list-head">Ops</div>
            </div>
            <div className="fixedsize-comm-payctrl-box">
              {this.state.list.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        bool
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-6 text-left pay-ctrl-line-lbl-a">
                        {obj.lastSubscriptionState &&
                        obj.lastSubscriptionState === "payment_failed" ? (
                          <font color="red">{obj.itemName} - Last Payment Failed "</font>
                        ) : (
                          obj.itemName
                        )}{" "}
                        $
                        {this.commaFormattedCurrency(
                          obj.inputData.price.monthlyFee
                        )}
                      </div>

                      <div className="col-5 text-left pay-ctrl-line-lbl-b">
                        {obj.customerName} ({obj.customerEmail})&nbsp;(InvId: {obj.refInvoiceId})
                        {obj.customerRequest === "new" ? (
                          <i className="fa fa-star" />
                        ) : null}
                        {obj.customerRequest === "replied" ? (
                          <i className="fa fa-check" />
                        ) : null}
                      </div>
                      <div className="col-1 text-left">
                        <button
                          className="btn_reg_50 pay-ctr-open-btn"
                          type="button"
                          onClick={() => this.selectThisItemToManage(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          Open
                        </button>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
            <div
              className={
                this.state.errFlag
                  ? "text-center sub-item-msg-err"
                  : "text-center sub-item-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        );
      } else {
        subscriptionListPanel = (
          <div className="text-center pay-ctrl-list-no-sub">
            No subscription Item to list with filter condition.
          </div>
        );
      }
    }

    let outputPanel;
    if (this.state.showListPanelFlag) {
      outputPanel = (
        <div className="payment-ctrl-main-box">
          <div className="row">
            <div className="col-3 text-left pay-strl-total">
              Active Total ${this.commaFormattedCurrency(this.state.total)}
            </div>
            <div className="col-6 text-center pay-strl-header">
              Payment Controller
            </div>
            <div className="col-3 text-right">
              {infoButto} {filterButton}
            </div>
          </div>
          {subscriptionListPanel}
        </div>
      );
    }

    if (this.state.showFilterFlag) {
      outputPanel = (
        <div>
          <PayCtrlFilter
            returnToCaller={(cond) => this.returnFromFilter(cond)}
            communityId={this.props.communityId}
          />
        </div>
      );
    }

    if (this.state.showDetailsFlag) {
      outputPanel = (
        <div>
          <PayCtrlDetail
            returnToCaller={(cond) => this.returnFromDetails(cond)}
            communityId={this.props.communityId}
            subSelected={this.state.selectedSubscription}
            sellerStripeId={this.props.community.stripe.id}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

PayCtrlMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PayCtrlMain)
);
