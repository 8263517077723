import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ViewEvent from "../../calendar/event/view/ViewEvent";

import axios from "axios";

// import ReceiveMoneyViaCC from "../../../../../account/finance/communities/PayForCCPlatform";

import "./ShowEventResponse.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAnEvent = "/routes/inetract/getAnEvent?";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const saveMsgRead = "/routes/interact/saveMsgRead";

// const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

class ShowEventResponse extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      theEvent: null,

      loaderr: false,
      openViewFlag: false,
      showReponseFlag: false,

      takePaymentFlag: false,

      responseType: "accept",
      responseComment: "",
      notifyResponseFlag: "yes",

      handleInviteResponse: null,

      respErr: "",
      eventParticipationInfo: null,

      eventDaysSelheight: 1,
      eventTimeSelheight: 1,
      eventDateSelected: "",

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.markMsgRead();

    await this.getTheEvent();
    let eventDateSelected = this.state.eventDateSelected;
    let responseType = this.state.responseType;
    let responseComment = this.state.responseComment;
    if (this.state.theEvent) {
      if (this.state.theEvent.eventOn.attendanceType === "anyday") {
        this.state.theEvent.attendees.forEach((elm) => {
          // console.log("elm:", elm);
          if (
            parseFloat(elm.baandaId) ===
            parseFloat(this.props.auth.user.baandaId)
          ) {
            eventDateSelected = elm.dayString;
            responseType = elm.responseType;
            responseComment = elm.responseComment;
          }
        });
      }
    }

    await this.setState({
      deviceSize,
      eventDateSelected,
      responseType,
      responseComment,
    });
  };

  markMsgRead = async () => {
    let input = { synapseId: this.props.showPost.synapseId };
    let url = baandaServer + saveMsgRead;
    // console.log('url:', url, ' input:', input);
    try {
      let readret = await axios.post(url, input);
      // console.log('readret:', readret);
      if (readret.data.status === "error") {
        console.log(
          "markMsgRead for synapseId " +
            this.props.showPost.synapseId +
            " got error = " +
            readret.data.Msg
        );
        this.setState({
          errMsg: readret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log(
        "markMsgRead for synapseId " +
          this.props.showPost.synapseId +
          " got error = " +
          err.message
      );
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  returnToConverse = () => {
    // console.log("returnToConverse");
    this.props.returnToCaller();
  };

  getTheEvent = async () => {
    let params = "eventId=" + this.props.showPost.actions.refId;
    let url = baandaServer + getAnEvent + params;
    try {
      let retevent = await axios.get(url);
      if (retevent.data.status === "success") {
        await this.setState({
          theEvent: retevent.data.Msg,
          openViewFlag: true,
        });
      } else {
        await this.setState({
          loaderr:
            "Error retrieving event info of eventId: " + this.props.eventId,
        });
      }
    } catch (err) {
      console.log("getAnEvent Err: ", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };


  handleResponse = async (e) => {
    this.setState({
      responseType: e.target.value,
    });
  };

  handleNotify = async (e) => {
    this.setState({
      notifyResponseFlag: e.target.value,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  // Will need to handle payment and will be exactly like service marketing responses.
  // Handle this together ... Only difference should be ... service or nonservice.
  invitePayAndRespond = async () => {
    // TBD
  };

  getCommunityInfo = async (communityId) => {
    let parms = "communityId=" + communityId;
    let url = baandaServer + getCommunityInfo + parms;
    // console.log("url:", url);
    let output;
    try {
      let retData = await axios.get(url);
      // console.log("PublishBroadcast retData: ", retData);
      // await this.setState({
      //   community: retData.data.joiningProcess,
      // });
      output = { status: "success", Msg: retData.data.joiningProcess };
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
      output = { status: "error", Msg: err.message };
    }

    return output;
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleInviteResponseWithPay = async () => {
    let comm = await this.getCommunityInfo(this.props.communityId);
    let input = await this.packDataForDB();
    input.StripeAccId = comm.stripe.id;
    // console.log("input:", input);
    this.setState({
      takePaymentFlag: true,
      eventParticipationInfo: input,
    });
  };

  handleBackFromPayment = async () => {
    this.setState({
      takePaymentFlag: false,
    });
    this.returnToConverse();
  };

  onfocusSelDays = async () => {
    let ht;
    if (this.state.theEvent.eventOn.days.length > 4) ht = 4;
    else ht = this.state.theEvent.eventOn.days.length + 1;
    this.setState({
      eventDaysSelheight: ht,
    });
  };

  onblurSelDays = async () => {
    this.setState({
      eventDaysSelheight: 1,
    });
  };

  handleEventDayToAttend = async () => {
    let value = document.getElementById("eventAttendDay").value;
    // console.log("value:", value);
    let valueObj = JSON.parse(value);
    this.setState({
      eventDateSelected: valueObj,
    });

    await this.onblurSelDays();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ShowEventResponse...');

    let exitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToConverse}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let eventRespHeadPanel;
    eventRespHeadPanel = (
      <div className="text-center event-resp-headere">
        The Event &nbsp;{exitButton}
      </div>
    );

    let outputPanel;
    if (this.state.openViewFlag) {
      outputPanel = (
        <div className="converse-show-req-resp-box">
          {eventRespHeadPanel}
          <ViewEvent
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.hostBaandaId}
            theEvent={this.state.theEvent}
            returnToCaller={this.returnToConverse}
            synapseId={this.props.showPost.synapseId}
            calledFrom="bmail"
            caller="eventresponse"
            status={this.props.showPost.status}
            attendee={this.props.attendee}
          />
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

ShowEventResponse.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// const mapDispatchToProps = (dispatch) => ({
//   hideModal: () => dispatch(hideModal()),
//   showModal: (modalProps, modalType) => {
//     dispatch(showModal({ modalProps, modalType }));
//   },
// });

export default connect(mapStateToProps, null)(withRouter(ShowEventResponse));
