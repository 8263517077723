const emailValidation = (email) => {
    // console.log('In emailValidation');
    // console.log('email:', email);
    const re = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    let val = re.test(email);
    // console.log('val:', val)
    return val;    
}

export { emailValidation };


