import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import HandleShippingDetails from "./ship/HandleShippingDetails";
import ShipFilter from "./ship/ShipFilter";
import ManualShipping from "./ship/ManualShipping";
// import BulkPay from "./ship/BulkPay";

import "./Shipping.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getDeliveryData = "/routes/dashboard/getDeliveryData?";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";

const readMeCode = "1111010000";

class Shipping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPaginationPanelFlag: false,
      type: "ship",
      status: "new",
      communityId: 0,

      shippingData: [],
      errMsg: "",
      errFlag: false,

      shippings: [],
      currentPage: 1, // page selected to show the rows of items on that page
      noOfRowsPerPage: 12, // x (number of rows the show per page - constant)
      noOfButtonsToShow: 6, // y (number of buttons to show at the bottom - constant)
      fistPageOfSetIndex: 1, // f (first line of the set to display - vary -initiate with 1)
      shippingOnScreen: [],

      showpagi: true,
      showShipDetails: false,
      showFilter: false,
      showManualEntryFlag: false,
      // showBulkPay: false,

      rightArrowFlag: false,
      leftArrowFlag: false,

      invoiceSelected: null,
      coopStoreId: 0,
      community: null,
    };
  }

  componentDidMount = async () => {
    // console.log("in component did mount.");
    await this.getShippingInfo("tobe", "", "all", 0);
    // console.log("going to setPosts...");
    if (this.state.shippingData) {
      await this.setPosts();
      // console.log("going to setPageNumbers");
      await this.setPageNumbers();
    }

    await this.getCommunityInfo();

    let coopStoreId = 0;
    if ( this.props.store) coopStoreId =  this.props.store.coopStoreId;
    this.setState({
      coopStoreId,
      // community: this.props.community
    })
  };

  getCommunityInfo = async () => {
    let parms = "communityId=" + this.props.communityId;
    let url = baandaServer + getCommunityInfo + parms;
    try {
      let retData = await axios.get(url);
      this.setState({
        community: retData.data.Msg,
        errMsg: '',
        errFlag: false
      });
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true
      })
    }
  };

  getShippingInfo = async (specStatus, name, itemType, invoiceId) => {
    // console.log(">>>>>>>>>> itemType:", itemType);

    let coopStoreId = 0;
    if ( this.props.store) coopStoreId =  this.props.store.coopStoreId;

    let param =
      "type=" +
      this.state.type +
      "&specStatus=" +
      specStatus +
      "&invoiceId=" +
      invoiceId +
      "&communityId=" +
      this.props.communityId +
      "&itemType=" +
      itemType +
      "&customerName=" + name +
      "&coopStoreId=" + coopStoreId;

    let url = baandaServer + getDeliveryData + param;
    // console.log("url:", url); 
    try {
      let shipret = await axios.get(url);
      // console.log("shipret:", shipret);
      if (shipret.data.status === "success") {
        if (shipret.data.Msg.length > 0) {
          await this.setState({
            shippingData: shipret.data.Msg,
            shippings: shipret.data.Msg,
          });
        } else {
          await this.setState({
            errMsg: "No new shipping requested.",
            errFlag: false,
            shippingData: [],
            shippings: [],
          });
        }
      } else {
        await this.setState({
          errMsg: shipret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getShippingInfo err:", err.message);
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  // This is to show the info panel
  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  // =============== PAgination BudgetMain
  setPageNumbers = async () => {
    let tlen = this.state.shippingData.length; // tlen = total length of cart array to be picked up
    let f = this.state.fistPageOfSetIndex;
    let x = this.state.noOfRowsPerPage;
    let y = this.state.noOfButtonsToShow;
    let right = false,
      left = false;
    let showPaginationPanelFlag = true;

    let z = y; // Dynamic number of buttons to show (vary)
    // let st = 1; // starting page number is 1
    // console.log("1> f:", f, " z:", z, " x:", x, " y:", y, " tlen:", tlen);
    if (tlen <= x) {
      // console.log("here 1 ");
      showPaginationPanelFlag = false;
    } else if (Math.ceil(tlen / x) <= y) {
      // no action needed
      // console.log("here 2 ");
      z = Math.ceil(tlen / x); // z becomes the  number of page  number can be shown
    } else if (f <= 1) {
      // console.log("here 3 ");
      left = false;
      right = true;
    } else if (f + y > Math.ceil(tlen / x)) {
      // Math.ceil(tlen/x) gives the total number of buttons can be shown
      // console.log("here 4 ");
      left = true;
      right = false;
      z = Math.ceil(this.state.shippings.length / this.state.noOfRowsPerPage); // end button number in z
      //   st = f; // start button number from f
    } else {
      // console.log("here 5 ");
      left = true;
      right = true;
      //   st = f;
      z = f + y - 1; //
    }

    let pageNumbers = [];
    for (let i = f; i <= z; i++) {
      pageNumbers.push(i);
    }

    await this.setState({
      leftArrowFlag: left,
      rightArrowFlag: right,
      showPaginationPanelFlag,
      pageNumbers,
    });
  };

  setPosts = async () => {
    // console.log("INSIDE setPosts this.state.shippings:", this.state.shippings);
    let indexOfLastPickup = this.state.currentPage * this.state.noOfRowsPerPage;
    let indexOfFirstPickup = indexOfLastPickup - this.state.noOfRowsPerPage;
    let slicedShippings;

    if (indexOfLastPickup >= this.state.shippings.length) {
      // console.log("I am here xxxxx");
      slicedShippings = this.state.shippings.slice(indexOfFirstPickup);
      // console.log("slicedShippings:", slicedShippings);
    } else {
      slicedShippings = this.state.shippings.slice(
        indexOfFirstPickup,
        indexOfLastPickup
      );
    }
    await this.setState({
      shippingOnScreen: slicedShippings,
    });
  };

  paginate = async (input) => {
    // console.log("paginate input:", input);
    await this.setState({
      currentPage: input,
    });
    await this.setPosts();
  };

  handleLeft = async () => {
    // console.log("handle Left");
    let ff = this.state.fistPageOfSetIndex - this.state.noOfButtonsToShow;
    if (ff < 1) ff = 1;

    await this.setState({
      fistPageOfSetIndex: ff,
    });

    await this.setPageNumbers();
  };

  handleRight = async () => {
    // console.log("handle Right");
    let ff = this.state.fistPageOfSetIndex + this.state.noOfButtonsToShow;
    if (ff > this.state.shippings.length)
      ff = this.state.shippings.length - this.state.noOfButtonsToShow;

    await this.setState({
      fistPageOfSetIndex: ff,
    });

    await this.setPageNumbers();
  };
  // Pagination End  ===================================================

  handleShipping = async (shipobj) => {
    // console.log("shipobj:", shipobj);
    await this.getCommunityInfo();
    this.setState({
      showpagi: false,
      showShipDetails: true,
      showFilter: false,
      invoiceSelected: shipobj,
    });
  };

  returnFromHandleShipping = async () => {
    // To refresh list with latest work behavior ... e.g., if some item is packed then it shows; if some is shipped, it will disappear from the list etc.
    // console.log("returning to reload shipping...");
    await this.getShippingInfo("tobe", "", "all", 0);
    await this.setPosts();
    await this.setPageNumbers();

    await this.setState({
      showpagi: true,
      showShipDetails: false,
      showFilter: false,
      showBulkPay: false,
    });
  };

  handleFilter = async () => {
    await this.setState({
      showpagi: false,
      showShipDetails: false,
      showFilter: true,
      showBulkPay: false,
    });
  };

  returnFromFilter = async (obj) => {
    // console.log("returnFromFilter obj:", obj);
    if (obj.ops === "filter") {
      await this.setState({
        shippings: [],
        shippingOnScreen: [],
      });
      await this.getShippingInfo(
        obj.Msg.status,
        obj.Msg.customerName,
        obj.Msg.itemType,
        parseFloat(obj.Msg.invoiceId)
      );
      await this.setPosts();
      await this.setPageNumbers();
    }
    await this.setState({
      showpagi: true,
      showShipDetails: false,
      showFilter: false,
    });
  };

  handleManualEntry = async ( ) => {
    // console.log("manual" );
    await this.getCommunityInfo();
    await this.setState({
      showpagi: false,
      showShipDetails: false,
      showFilter: false,
      showManualEntryFlag: true  
    });
  };

  returnFromManualShipping = async() => {
    // console.log("return from manual" );
    await this.getShippingInfo("tobe", "", "all", 0);
    await this.setPosts();
    await this.setPageNumbers();

    await this.setState({
      showpagi: true,
      showShipDetails: false,
      showFilter: false,
      showManualEntryFlag: false  
    });
  }

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  render() {
    // console.log("this.state:", this.state);
    // console.log("this.props:", this.props);
    console.log('Shipping...');

    let purposHeader = "";

    let total = this.state.shippings.length;

    let filterButton;
    filterButton = (
      <button
        onClick={this.handleFilter}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_50 store-ship-filter-pos"
      >
        Filter
      </button>
    );

    let manualButton;
    manualButton = (
      <button
        onClick={this.handleManualEntry}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_60 "
      >
        Manual
      </button>
    );

    let summaryPanel = "";
    if (this.state.community) {
      summaryPanel = (
        <div className="sum-placement-ship">
          <span className="g">{total}&nbsp;to ship</span> &nbsp;{" "}
          <span className="g">
            Credits ATL* $
            {this.commaFormattedCurrency(
              this.state.community.credits.creditAvailable
            )}
          </span>
          {filterButton}
          {manualButton}
        </div>
      );
    }


    let bool = true;
    let pagipanel;
    if (this.state.showpagi) {
      pagipanel = (
        <div>
          <div className="show-pickups-header">{purposHeader}</div>
          <div className="text-center">{summaryPanel}</div>
          <div className="row">
            <div className="col-8 text-left pickup-text-header">
              Customer Name -- email & (invoiceId) Reg.Pkg Done=
              <i className="fa fa-check" /> Spl.Pkg.Req=
              <i className="fa fa-map-marker" />
            </div>
            <div className="col-2 text-center pickup-text-header">Date</div>
            <div className="col-2 text-center pickup-text-header">Ops</div>
          </div>
          <div className="fixedsize_delivery_paginated">
            {this.state.shippingOnScreen.map((post, i) => (
              <div key={i}>
                <div className={`${bool ? "row-color-a" : "row-color-b"}`}>
                  <div className="row">
                    <div className="col-8 text-left pickup-text">
                      {" "}
                      {post.customerName}&nbsp;-- {post.customerEmail}&nbsp;(
                      {post.invoiceId})&nbsp;&nbsp;
                      {post.spInfo.regularPkg.length > 0 ? (
                        <i className="fa fa-check" />
                      ) : null}
                      &nbsp;
                      {post.spSpecialExists ? (
                        <i className="fa fa-map-marker" />
                      ) : null}
                    </div>
                    <div className="col-2 text-center ship-invoice-date">
                      {post.invoiceDate.substring(0, 10)}
                    </div>
                    <div className="col-2 text-center store-ship-open-btn-pos">
                      <button
                        onClick={() => this.handleShipping(post)}
                        className="btn_reg_50"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Open
                      </button>
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            ))}
          </div>
        </div>
      );
    }

    let pageNumberPanel;
    if (this.state.showPaginationPanelFlag) {
      pageNumberPanel = (
        <div className="text-center">
          <ul className="pagination">
            {this.state.leftArrowFlag ? (
              <li key={0}>
                <button
                  onClick={this.handleLeft}
                  className="btn_reg_50"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <i className="fas fa-angle-double-left" />
                </button>
              </li>
            ) : null}
            {this.state.pageNumbers.map((number) => (
              <li key={number + 1}>
                <button
                  onClick={() => this.paginate(number)}
                  className="btn_reg_30"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  {number}
                </button>
              </li>
            ))}
            {this.state.rightArrowFlag ? (
              <li key={10000000}>
                <button
                  onClick={this.handleRight}
                  className="btn_reg_50"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <i className="fas fa-angle-double-right" />
                </button>
              </li>
            ) : null}
          </ul>
        </div>
      );
    }

    let outputPanel;
    if (this.state.shippingData) {
      if (this.state.shippingData.length > 0) {
        if (this.state.showpagi && this.state.showPaginationPanelFlag) {
          outputPanel = (
            <div>
              <div className="text-center shipping-mgmt-header">
                Shipping Management
              </div>
              {pagipanel}
              <div className="text-center ship-pagi-space-above">
                {pageNumberPanel}
              </div>
              {/* <div className="text-center shipping-credit-msg">* ATL: At the time of this load. The credit will be rechecked during postage buy.</div> */}
            </div>
          );
        }

        if (this.state.showpagi && !this.state.showPaginationPanelFlag) {
          outputPanel = (
            <div>
              <div className="text-center shipping-mgmt-header">
                Shipping Management
              </div>
              {pagipanel}
              {/* <div className="text-center shipping-credit-msg">* ATL: At the time of this load. The credit will be rechecked during postage buy.</div> */}
            </div>
          );
        }
      } else {
        outputPanel = (
          <div>
            <div className="text-center shipping-mgmt-header">
              Shipping Management
            </div>
            <div className="text-center only-manual-button-pos">
              {manualButton}
            </div>
            {/* manualButton */}
            <div className="text-center no-shipping-records">
              No new shipping requests to handle. Prior shipping records, if any, are
              available in Reports.
            </div>
          </div>
        );
      }
    } else {
      outputPanel = (
        <div>
          <div className="text-center shipping-mgmt-header">
            Shipping Management
          </div>
          <div className="text-center no-shipping-records">
            No new shipping requests to handle. Prior shipping records, if any, are
            available in Reports.
          </div>
        </div>
      );
    }

    if (this.state.showShipDetails) {
      outputPanel = (
        <div>
          <HandleShippingDetails
            invoice={this.state.invoiceSelected}
            returnToCaller={this.returnFromHandleShipping}
            shippingMeta={this.props.community.shippingMeta}
            community={this.state.community}
            commName={this.props.commName}
            communityId={this.props.communityId}
            store={this.props.store}
            role={this.props.role}
          />
        </div>
      );
    }

    if (this.state.showFilter) {
      outputPanel = (
        <div>
          <ShipFilter returnToCaller={(obj) => this.returnFromFilter(obj)} />
        </div>
      );
    }

    if ( this.state.showManualEntryFlag) {
      outputPanel = (
        <div>
          <ManualShipping
            invoice={this.state.invoiceSelected}
            returnToCaller={this.returnFromManualShipping}
            shippingMeta={this.props.community.shippingMeta}
            community={this.state.community}
            commName={this.props.commName}
            communityId={this.props.communityId}
            store={this.props.store}
            role={this.props.role}
          />
        </div>
      )
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

Shipping.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Shipping));
