import React, { Component } from "react";

import axios from "axios";

import "./InventoryDetails.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAnInventoryItem = "/routes/reports/getAnInventoryItem?";
const getCatalogPkgForDelivery = "/routes/dashboard/getCatalogPkgForDelivery?";

class inventoryDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      theInventory: null,

      today: new Date(),
      pkgComposedOfItems: "",

      packsThisMonth: 0,
      noOfPackagesMonth: 0,
      showPlans: [],

      year: 0,
      month: 0,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    await this.getTheItem();

    let pkgComposedOfItems = "";
    if (this.state.theInventory && this.state.theInventory.pkgComposedFlag) {
      this.state.theInventory.pkgComposedOfItems.forEach((elm) => {
        pkgComposedOfItems += elm.category + " = " + elm.quantity + "; ";
      });
    }

    this.setState({
      pkgComposedOfItems,
    });
  };

  onChangeNumber = async (e) => {
    let value = e.target.value;
    let namearr = [e.target.name];
    let name = namearr[0];
    // console.log("name:", name);
    let cno = true;
    if (name === "month" && (value < 1 || value > 12)) cno = false;
    if (cno) {
      if (this.countDecimals(value) > 0) {
        let tmp = parseFloat(value).toFixed(2);
        let val = parseFloat(tmp);
        this.setState({
          [e.target.name]: val,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          [e.target.name]: value,
          errMsg: "",
          errFlag: false,
        });
      }
    } else {
      this.setState({
        errMsg: "Invalid month - must be a number from 1 to 12",
        errFlag: true,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  getTheItem = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let parms =
      "itemId=" +
      this.props.itemId +
      "&communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&inventoryAt=" +
      this.props.inventoryAt;
    let url = baandaServer + getAnInventoryItem + parms;
    // console.log("url:", url);
    try {
      let inv = await axios.get(url);

      if (inv.data.status === "success") {
        this.setState({
          theInventory: inv.data.Msg,
        });
      } else {
        this.setState({
          errMsg: inv.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getTheItem err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  returnToList = () => {
    this.props.returnToCaller();
  };

  getMonthName = (mno) => {
    let mname = "";
    switch (mno) {
      case 1:
        mname = "January";
        break;
      case 2:
        mname = "February";
        break;
      case 3:
        mname = "March";
        break;
      case 4:
        mname = "April";
        break;
      case 5:
        mname = "May";
        break;
      case 6:
        mname = "June";
        break;
      case 7:
        mname = "July";
        break;
      case 8:
        mname = "August";
        break;
      case 9:
        mname = "September";
        break;
      case 10:
        mname = "October";
        break;
      case 11:
        mname = "November";
        break;
      case 12:
        mname = "December";
        break;

      default:
        mname = "Invalid-month-number";
    }

    return mname;
  };

  getPkgsPlanned = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&itemId=" +
      this.state.theInventory.itemId +
      "&year=" +
      this.state.year +
      "&month=" +
      this.state.month;
    let url = baandaServer + getCatalogPkgForDelivery + params;
    // console.log("url:", url);
    try {
      let plnret = await axios.get(url);
      // console.log("plnret.data:", plnret.data);
      if (plnret.data.status === "success") {
        let planned = 0;
        if (plnret.data.Msg) {
          planned = plnret.data.Msg.batch.length;
        }
        let packs = 0;
        let showPlans = [];
        let errMsg = "";
        let errFlag = false;
        if (planned > 0) {
          plnret.data.Msg.batch.forEach((pk) => {
            packs += parseFloat(pk.batchQty);
            let showStr = "";
            let start = true;
            pk.lines.forEach((lin) => {
              if (start) {
                showStr +=
                  pk.batchNumber + ". No of Packs: " + pk.batchQty + " | ";
              } else showStr += " | ";
              showStr += lin.pkgItemName;
              if (lin.showSubItem) {
                showStr += " (sub: " + lin.pkgSubItemName + ") ";
              }
              showStr += " Qty:" + lin.qtyOfThisInPack;
              start = false;
            });
            showPlans.push(showStr);
          });
          errMsg =
            "Number of batchs planned = " +
            planned +
            " | Total packages = " +
            packs;
        } else {
          errMsg =
            "No plans defined for the month of " +
            this.getMonthName(parseFloat(this.state.month)) +
            " of the year " +
            this.state.year;
          errFlag = true;
        }

        // console.log("planned:", planned);
        this.setState({
          noOfPackagesMonth: planned,
          packsThisMonth: packs,
          showPlans,
          errMsg,
          errFlag,
        });
      } else {
        this.setState({
          errMsg: plnret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("inventoryDetails...");

    let exitButton = (
      <button
        onClick={this.returnToList}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let itemNamePanel;
    if (this.state.theInventory) {
      itemNamePanel = (
        <div className="rep-inv-dtl-top-pos">
          <div className="row">
            <div className="col-3 text-right rep-inv-dtl-lbl">Item Name</div>
            <div className="col-9 text-left rep-inv-dtl-txt">
              {this.state.theInventory.itemName}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right rep-inv-dtl-lbl">Category</div>
            <div className="col-9 text-left rep-inv-dtl-txt">
              {this.props.inventoryAt === 'catalog' ? this.state.theInventory.itemCategory : this.state.theInventory.category}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right rep-inv-dtl-lbl">Inventory at</div>
            <div className="col-9 text-left rep-inv-dtl-txt">
              {this.props.inventoryAt}
            </div>
          </div>
          {this.state.theInventory.pkgComposedFlag ? (
            <div className="row">
              <div className="col-3 text-right rep-inv-dtl-lbl">Package</div>
              <div className="col-9 text-left rep-inv-dtl-txt">
                Composed (category = qty) plan is (
                {this.state.pkgComposedOfItems})
              </div>
            </div>
          ) : null}
          {!this.state.theInventory.pkgComposedFlag ? (
            <div className="row">
              <div className="col-3 text-right rep-inv-dtl-lbl">Package</div>
              <div className="col-9 text-left rep-inv-dtl-txt">
                Pre-defined Item (same as in warehouse)
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    let itemDetailsPanel;
    let bool1 = true;
    if (this.state.theInventory) {
      itemDetailsPanel = (
        <div className="inv-rep-division-breaks">
          <div className="text-left inv-report-lbl-head">
            Invoice Line Items
          </div>
          <div className="row">
            <div className="col-8 text-left invoice-line-lbl-headname">
              Name | Option
            </div>

            <div className="col-2 text-left invoice-line-lbl-avcost">
              Avg. Cost
            </div>
            <div className="col-1 text-left invoice-line-lbl-avqty">Qty</div>
            <div className="col-1 text-left invoice-line-lbl-avlk">Lkd</div>
          </div>
          <div className="rep-inventory-line-box">
            {this.state.theInventory.itemVariance.map((obj, i) => {
              // console.log("690 obj:", obj);

              return (
                <div key={i}>
                  <div
                    className={
                      bool1
                        ? "row rep-inv-item-dtl-drk"
                        : "row rep-inv-item-dtl-lit"
                    }
                  >
                    <div className="col-8 text-left invoice-line-lbl-name">
                      {obj.deleted ? (
                        <font color="red">{obj.name}</font>
                      ) : (
                        obj.name
                      )}{" "}
                      {obj.options === "" ? null : " | " + obj.options}
                    </div>

                    <div className="col-2 text-center invoice-line-lbl">
                      {this.commaFormattedCurrency(obj.movingAvgCost)}
                    </div>
                    <div className="col-1 text-center invoice-line-lbl">
                      {obj.quantity}
                    </div>
                    <div className="col-1 text-center invoice-line-lbl">
                      {obj.pkgQtyLocked}
                    </div>
                  </div>
                  {(bool1 = !bool1)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let compBtn;
    if (this.state.theInventory && this.state.theInventory.pkgComposedFlag) {
      compBtn = (
        <button
          onClick={this.getPkgsPlanned}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          className="btn_reg_70"
        >
          GetPlans
        </button>
      );
    }

    let compGetPanel;
    if (this.state.theInventory && this.state.theInventory.pkgComposedFlag) {
      compGetPanel = (
        <div className="row rep-comp-inv-pos">
          <div className="col-3 text center rep-inv-dtl-lbl">Get Plans for</div>
          <div className="col-3 text center">
            <b>Year (yyyy)</b>&nbsp;
            <input
              name="year"
              type="number"
              value={this.state.year}
              onChange={this.onChangeNumber}
              className="noOfPkg-field"
              step="1"
              placeholder="0"
              autoComplete="off"
              spellCheck="false"
            />
          </div>
          <div className="col-3 text center">
            <b>Month (1-12)</b>&nbsp;
            <input
              name="month"
              type="number"
              value={this.state.month}
              onChange={this.onChangeNumber}
              className="noOfPkg-field"
              step="1"
              placeholder="0"
              autoComplete="off"
              spellCheck="false"
            />
          </div>
          <div className="col-3 text center">{compBtn}</div>
        </div>
      );
    }

    let planPannel;
    if (this.state.theInventory && this.state.theInventory.pkgComposedFlag) {
      if (this.state.showPlans.length > 0) {
        let bool2 = true;
        planPannel = (
          <div className="rep-inventory-plan-box">
            {this.state.showPlans.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool2
                        ? "row rep-inv-item-dtl-drk"
                        : "row rep-inv-item-dtl-lit"
                    }
                  >
                    <div className="col-12 text-left invoice-line-lbl-name">
                      {obj}
                    </div>
                  </div>
                  {(bool2 = !bool2)}
                </div>
              );
            })}
          </div>
        );
      } else {
        planPannel = (
          <div className="rep-inventory-plan-box">
            <div className="text-center pkg-plan-todo-msg">
              Please enter valid year & month to view package plans.
            </div>
          </div>
        );
      }
    }

    let outputPanel;
    if (this.state.theInventory) {
      let nd = new Date();
      outputPanel = (
        <div className="inventory-rep-detail-box">
          <div className="text-center rep-inv-detail-header">
            Inventory Details (itemId : {this.props.itemId} - Date:{" "}
            {nd.getMonth() + 1}/{nd.getDate()}/{nd.getFullYear()}) {exitButton}
          </div>
          <div className="rep-inventory-content-box">
            {itemNamePanel}
            {itemDetailsPanel}
            {this.props.inventoryAt === "catalog" ? compGetPanel : null}
            {this.props.inventoryAt === "catalog" ? planPannel : null}
            {/* {planPannel} */}
            <div
              className={
                this.state.errFlag
                  ? "text-center rep-invtry-msg-err"
                  : "text-center rep-invtry-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

export default inventoryDetails;
