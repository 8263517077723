export const optionsTemplates = [
    {
      value: "templateInvoice",
      label: "Invoice Header Footer"
    },
    {
      value: "templateReminder",
      label: "Payment Reminder"
    },
    {
      value: "inviteLetter",
      label: "Invite Letter"
    },
  ];
  