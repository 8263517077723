import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./ShippingKeepAdm.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveShippingInfo = "/routes/coop/saveShippingInfo";
const getShippingInfo = "/routes.coop/getShippingInfo?";
const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";

const readMeCode = "0000000000";

class ShippingKeepAdm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entryType: "price",
      description: "",
      price: 0,

      message: "",
      errFlag: false,

      shippingPriceList: [],
      fromAddressList: [],
      defaultPackages: [],

      shippingObj: null,
      itemRange: "All",
      selheight: 3,

      addressName: "",
      street1: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
      country: "US",

      pkgName: "",
      scaleType: "fps",
      pkgLength: 0,
      pkgWidth: 0,
      pkgHeight: 0,
      pkgWeight: 0,
    };
  }

  componentDidMount = async () => {
    await this.getShippingMeta();
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getShippingMeta = async () => {
    let url =
      baandaServer +
      getShippingInfo +
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      this.props.store.coopStoreId;
    // console.log("url:", url);
    try {
      let shipret = await axios.get(url);
      // console.log("shipret.data:", shipret.data);
      // console.log('shipret.data.Msg.priceList.length:', shipret.data.Msg.priceList.length)
      if (shipret.data.status === "success") {
        this.setState({
          shippingPriceList: shipret.data.Msg.priceList,
          fromAddressList: shipret.data.Msg.fromAddress,
          defaultPackages: shipret.data.Msg.defaultPackages,
          message: "",
          errFlag: false,
        });
      } else {
        this.setState({
          message: shipret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  validatePkg = async () => {
    let msg = "";
    let state = "success";
    if (this.state.pkgName !== "") {
      this.state.defaultPackages.forEach((pkg) => {
        if (pkg.pkgName === this.state.pkgName) {
          state = "error";
          msg = "Package name muct be unique; ";
        }
      });
    } else {
      state = "error";
      msg += "A package must have a unique name; ";
    }

    if (this.state.pkgHeight === 0) {
      state = "error";
      msg += "Must have non-zero height; ";
    }
    if (this.state.pkgLength === 0) {
      state = "error";
      msg += "Must have non-zero length; ";
    }
    if (this.state.pkgWidth === 0) {
      state = "error";
      msg += "Must have non-zero width; ";
    }
    if (this.state.pkgWeight === 0) {
      state = "error";
      msg += "Must have non-zero weight; ";
    }

    return { status: state, Msg: msg };
  };

  checkAddress = async () => {
    let status = "success";
    let msg = "";
    if (
      this.state.street1 === "" ||
      this.state.city === "" ||
      this.state.postal_code === "" ||
      this.state.country === ""
    ) {
      status = "error";
      msg = "Must provide shipping address to be shipped";
    } else {
      let param =
        "street1=" +
        this.state.street1 +
        "&city=" +
        this.state.city +
        "&zip=" +
        this.state.zip +
        "&state=" +
        this.state.state +
        "&country=" +
        this.state.country +
        "&company= ";
      // + this.props.communityName;
      let url = baandaServer + easypostAddressCheck + param;
      try {
        let check = await axios.get(url);
        // console.log("check:", check);
        if (check.data.status === "error") {
          status = "error";
          msg = check.data.Msg;
        }
      } catch (err) {
        console.log("err:", err.message);
        status = "error";
        msg = err.message;
      }
    }

    return { status, Msg: msg };
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleEntryType = async (e) => {
    this.setState({
      entryType: e.target.value,
    });
  };

  handleAdminOpsSelected = async () => {
    let value = document.getElementById("keepShipAdmOpts").value;
    // console.log("ops value:", value);
    await this.setState({
      itemRange: value,
    });
  };

  handleAdd = async () => {
    // if (this.state.description !== "" && this.state.price !== 0) {
    if (this.state.description !== "") {
      let notAllowed = false;
      this.state.shippingPriceList.forEach((obj) => {
        if (obj.description === this.state.description) notAllowed = true;
      });

      if (!notAllowed) {
        let shipPrc = [...this.state.shippingPriceList];
        let sob = {
          description: this.state.description,
          price: this.state.price,
          itemRange: this.state.itemRange,
        };

        shipPrc.push(sob);

        this.setState({
          shippingPriceList: shipPrc,
          description: "",
          price: 0,
          message: "",
          errFlag: false,
        });
      } else {
        this.setState({
          message: "Error: Description must be unique.",
          errFlag: true,
        });
      }
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  selectThisPriceToDelete = async (obj) => {
    let prclist = [];
    this.state.shippingPriceList.forEach((elm) => {
      if (obj.description !== elm.description) prclist.push(elm);
    });

    await this.setState({
      shippingPriceList: prclist,
    });
  };

  handleAddAddr = async () => {
    let chk = await this.checkAddress();
    let msg = "";
    let errFlag = false;
    let notAllowed = false;
    if (chk.status === "error") {
      errFlag = true;
      msg = chk.Msg;
    }
    this.state.fromAddressList.forEach((obj) => {
      if (obj.name === this.state.addressName) notAllowed = true;
    });

    if (notAllowed) {
      msg += " No duplicate address name.";
      errFlag = true;
    }

    if (chk.status === "success" && !notAllowed) {
      let shipAdr = [...this.state.fromAddressList];

      let adrobj = {
        name: this.state.addressName,
        address: {
          street1: this.state.street1,
          street2: this.state.street2,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip,
          country: this.state.country,
        },
      };

      shipAdr.push(adrobj);

      this.setState({
        fromAddressList: shipAdr,
        message: "",
        errFlag,
      });
    } else {
      this.setState({
        message: msg,
        errFlag,
      });
    }
  };

  handleAddressDelete = async (obj) => {
    let adrlist = [];

    this.state.fromAddressList.forEach((elm) => {
      if (obj.name !== elm.name) adrlist.push(elm);
    });

    this.setState({
      fromAddressList: adrlist,
    });
  };

  handleShippingSave = async () => {
    // console.log("handleShippingSave");
    let url = baandaServer + saveShippingInfo;
    let input = {
      clientProgram: "ShippingCostSetup.js",
      clientFunction: "handleShippingSave",
      entryType: this.state.entryType,
      shippingObj: {
        priceList: this.state.shippingPriceList,
        fromAddress: this.state.fromAddressList,
        defaultPackages: this.state.defaultPackages,
      },
      communityId: this.props.communityId,
      coopStoreId: this.props.store.coopStoreId,
    };
    // console.log("url:", url, " input:", input);
    try {
      let sr = await axios.post(url, input);
      // console.log("sr", sr);
      if (sr.data.status === "success") {
        this.setState({
          message: "Successfully Updated",
          shippingPriceList: sr.data.Msg.priceList,
          fromAddressList: sr.data.Msg.fromAddress,
          defaultPackages: sr.data.Msg.defaultPackages,
          addressName: "",
          street1: "",
          street2: "",
          city: "",
          state: "",
          zip: "",
          errFlag: false,
        });
      } else {
        this.setState({
          message: "Error: " + sr.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("Error:", err.message);
      this.setState({
        message: "Error: " + err.message,
        errFlag: true,
      });
    }
  };

  addAPackage = async () => {
    let val = await this.validatePkg();

    if (val.status === "success") {
      let wippkgs = [...this.state.defaultPackages];
      let pkgObj = {
        pkgName: this.state.pkgName,
        scaleType: this.state.scaleType,
        pkgLength: parseFloat(this.state.pkgLength),
        pkgWidth: parseFloat(this.state.pkgLength),
        pkgHeight: parseFloat(this.state.pkgHeight),
        pkgWeight: parseFloat(this.state.pkgWeight),
      };
      wippkgs.push(pkgObj);
      this.setState({
        defaultPackages: wippkgs,
        pkgName: "",
        pkgLength: 0,
        pkgWidth: 0,
        pkgHeight: 0,
        pkgWeight: 0,
        errMsg: "",
        errFlag: false,
      });
    } else {
      this.setState({
        errMsg: val.Msg,
        errFlag: true,
      });
    }
  };

  selectThispkgToDelete = async (pkg) => {
    let newPkgs = [];
    this.state.defaultPackages.forEach((obj) => {
      if (pkg.pkgName !== obj.pkgName) {
        newPkgs.push(obj);
      }
    });

    this.setState({
      defaultPackages: newPkgs,
    });
  };

  handleScaleType = async (e) => {
    this.setState({
      scaleType: e.target.value,
    });
  };

  onChangeCurrency = async (e) => {
    // console.log("[e.target.name]:", [e.target.name]);
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        errMsg: "",
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        errMsg: "",
      });
    }
  };


  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ShippingKeepAdm...');

    let addLineButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.addAPackage}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Add <i className="fa fa-plus" />
      </button>
    );

    let keepEntryPanel;
    keepEntryPanel = (
      <div>
        <div className="row keep-ship-entry-of-pos">
          <div className="col text-center radio-font-style">
            <strong>Entry of&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="price"
                  checked={this.state.entryType === "price"}
                  onChange={this.handleEntryType}
                />{" "}
                Price
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="address"
                  checked={this.state.entryType === "address"}
                  onChange={this.handleEntryType}
                />{" "}
                Address
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="package"
                  checked={this.state.entryType === "package"}
                  onChange={this.handleEntryType}
                />{" "}
                Package
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let itemRangePanel;
    itemRangePanel = (
      <div>
        <select
          size={this.state.selheight}
          id="keepShipAdmOpts"
          name="keepShipAdmOpts"
          onChange={this.handleAdminOpsSelected}
          onSelect={this.handleAdminOpsSelected}
          className="adm-ship-size-select"
          defaultValue={"DEFAULT"}
        >
          <option className="admin-option-row-sel" value="DEFAULT" disabled>
            Items in an invoice (all)
          </option>
          <option className="" value="All">
            All items
          </option>
          <option className="" value="1-2">
            1-2 items
          </option>
          <option className="" value="3-5">
            3-5 items
          </option>
          <option className="" value="6-8">
            6-8 items
          </option>
          <option className="" value="9+">
            9+ items (Many)
          </option>
        </select>
      </div>
    );

    let priceEntryPanel;
    if (this.state.entryType === "price") {
      priceEntryPanel = (
        <div>
          <div className="row pickup-addr-row">
            <div className="col-3 ship-price-lbl text-right">Description</div>
            <div className="col-9 text-left">
              <input
                name="description"
                type="text"
                value={this.state.description}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="adm-ship-desc"
                placeholder="Shipping Service"
              />
            </div>
          </div>
          <div className="row pickup-addr-row">
            <div className="col-3 text-right ship-price-lbl">Price</div>
            <div className="col-4 text-left">
              <input
                name="price"
                type="number"
                value={this.state.price}
                onChange={this.onChangeCurrency}
                className="adm-shipping-price"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
            <div className="col-4 text-center item-range-pos">
              {itemRangePanel}
            </div>
          </div>
          <div className="row ship-addr-add-btn-pos">
            <div className="col text-center">
              <button
                className="btn_reg_60"
                type="button"
                onClick={this.handleAdd}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Add&nbsp;+
              </button>
            </div>
          </div>
        </div>
      );
    }

    let bool = true;
    let shipPriceListPanel;
    shipPriceListPanel = (
      <div className="price-list-row">
        <div className="fizedsize-keep-list-x">
          {this.state.shippingPriceList.map((obj, i) => {
            // console.log("690 obj:", obj);
            return (
              <div key={i}>
                <div
                  className={
                    bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                  }
                >
                  <div className="col-1 text-right keep-pricelist-del">
                    <button
                      className="btn_exit_del"
                      type="button"
                      onClick={() => this.selectThisPriceToDelete(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                  <div className="col-11 text-left price-list-lbl">
                    ${this.commaFormattedCurrency(parseFloat(obj.price))}
                    &nbsp;||&nbsp; {obj.description} (Items in Invoice:{" "}
                    {obj.itemRange})
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let buttonPanel;
    buttonPanel = (
      <div className="keep-adm-ship-btn-pos">
        <button
          className="btn_reg_70"
          type="button"
          onClick={this.handleShippingSave}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save&nbsp;
          <i className="fas fa-ship" />
        </button>
        &nbsp;&nbsp;
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
      </div>
    );

    let fromAddressPanel;
    if (this.state.entryType === "address") {
      fromAddressPanel = (
        <div>
          <div className="row adm-ship-addr-row">
            <div className="col-4 text-right ship-adr-name">
              From Address Name
            </div>
            <div className="col-8 text-left">
              <input
                name="addressName"
                type="text"
                value={this.state.addressName}
                onChange={this.onChange}
                size="40"
                maxLength="40"
                className="adm-ship-addr-name"
                placeholder="A unique name..."
              />
            </div>
          </div>
          <div className="row adm-ship-addr-row">
            <div className="col-md-6">
              <input
                name="street1"
                type="text"
                value={this.state.street1}
                onChange={this.onChange}
                size="70"
                maxLength="70"
                className="keep-addr-street1"
                placeholder="Street address ..."
              />
            </div>
            <div className="col-md-6">
              <input
                name="street2"
                type="text"
                value={this.state.street2}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="keep-addr-street2"
                placeholder="Apt# or Suit#..."
              />
            </div>
          </div>
          <div className="row keep-addr-line2-pos">
            <div className="col-md-6 text-left">
              <input
                name="city"
                type="text"
                value={this.state.city}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="keep-addr-city"
                placeholder="City..."
              />
            </div>
            <div className="col-2">
              <input
                name="state"
                type="text"
                value={this.state.state}
                onChange={this.onChange}
                size="2"
                maxLength="2"
                className="keep-addr-state"
                placeholder="State"
              />
            </div>
            <div className="col-2">
              <input
                name="zip"
                type="text"
                value={this.state.zip}
                onChange={this.onChange}
                size="10"
                maxLength="10"
                className="keep-addr-zip"
                placeholder="Zip"
              />
            </div>
            <div className="col-2 text-center comm-country-lbl">
              {this.state.country}
            </div>
          </div>
          <div className="row ship-addr-add-btn-pos">
            <div className="col text-center">
              <button
                className="btn_reg_60"
                type="button"
                onClick={this.handleAddAddr}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Add&nbsp;+
              </button>
            </div>
          </div>
        </div>
      );
    }

    let fromAddrListPanel;
    if (this.state.entryType === "address") {
      bool = true;
      fromAddrListPanel = (
        <div className="price-list-row">
          <div className="fizedsize-keep-list-x">
            {this.state.fromAddressList.map((obj, i) => {
              // console.log("690 obj:", obj);
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-1 text-right keep-pricelist-del">
                      <button
                        className="btn_exit_del"
                        type="button"
                        onClick={() => this.handleAddressDelete(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                    <div className="col-11 text-left price-list-lbl">
                      {obj.name} || {obj.address.street1}{" "}
                      {obj.address.street2 !== ""
                        ? ", " + obj.address.street2
                        : null}
                      , {obj.address.city}, {obj.address.state}{" "}
                      {obj.address.zip}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let mksFpsPanel;
    mksFpsPanel = (
      <div className="row ship-handler-type-row">
        <div className="col text-center radio-font-style">
          <strong>Scale Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="fps"
                checked={this.state.scaleType === "fps"}
                onChange={this.handleScaleType}
              />{" "}
              FPS (Foot-Lbs)
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="mks"
                checked={this.state.scaleType === "mks"}
                onChange={this.handleScaleType}
              />{" "}
              MKS (Meter-Kg)
            </label>
          </div>
        </div>
      </div>
    );

    let dim, weight;
    if (this.state.scaleType === "fps") {
      dim = "inchs";
      weight = "lbs";
    } else {
      dim = "cm";
      weight = "kg";
    }

    let aPkgPanel;
    aPkgPanel = (
      <div className="adm-pkg-input-box">
        <div className="row adm-pkg-inp-row1">
          <div className="col-2 text-right adm-pkg-lbl">Name</div>
          <div className="col-4 text-left inv-refid-lbl">
            <input
              name="pkgName"
              type="text"
              value={this.state.pkgName}
              onChange={this.onChange}
              size="15"
              maxLength="15"
              className="adm-pkg-name"
              placeholder=""
            />
          </div>
          <div className="col-6 text-center inv-refid-lbl">{mksFpsPanel}</div>
        </div>

        <div className="row adm-pkg-inp-row">
          <div className="col-2 text-right adm-pkg-lbl">Length</div>
          <div className="col-4 text-left adm-pkg-lbl">
            <input
              name="pkgLength"
              type="number"
              value={this.state.pkgLength}
              onChange={this.onChangeCurrency}
              className="pkg-length-weight"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            {dim}
          </div>
          <div className="col-2 text-right adm-pkg-lbl">Width</div>
          <div className="col-4 text-left adm-pkg-lbl">
            <input
              name="pkgWidth"
              type="number"
              value={this.state.pkgWidth}
              onChange={this.onChangeCurrency}
              className="pkg-length-weight"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            {dim}
          </div>
        </div>
        <div className="row adm-pkg-inp-row">
          <div className="col-2 text-right adm-pkg-lbl">Height</div>
          <div className="col-4 text-left adm-pkg-lbl">
            <input
              name="pkgHeight"
              type="number"
              value={this.state.pkgHeight}
              onChange={this.onChangeCurrency}
              className="pkg-length-weight"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            {dim}
          </div>
          <div className="col-2 text-right adm-pkg-lbl">Weight</div>
          <div className="col-4 text-left adm-pkg-lbl">
            <input
              name="pkgWeight"
              type="number"
              value={this.state.pkgWeight}
              onChange={this.onChangeCurrency}
              className="pkg-length-weight"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            {weight}
          </div>
        </div>
        <div className="row adm-pkg-inp-row">
          <div className="col-10 text-left">&nbsp;</div>
          <div className="col-2 text-left adm-coop-pkg-add">
            {addLineButton}
          </div>
        </div>
      </div>
    );

    let pkgLineBody;

    let bool4 = true;
    if (this.state.defaultPackages) {
      if (this.state.defaultPackages.length > 0) {
        pkgLineBody = (
          <div className="">
            <div className="row am-pkg-lines-row">
              <div className="col-1 text-center adm-pkg-line-header">Ops</div>
              <div className="col-3 text-left adm-pkg-line-header">Name</div>
              <div className="col-2 text-left adm-pkg-line-header">Length</div>
              <div className="col-2 text-left adm-pkg-line-header">Width</div>
              <div className="col-2 text-left adm-pkg-line-header">Height</div>
              <div className="col-2 text-left adm-pkg-line-header">Weight</div>
            </div>
            <div className="coop-adm-pkg-box-x">
              {this.state.defaultPackages.map((obj, i) => {
                let scale = "";
                let weight = "";
                if (obj.scaleType === "fps") {
                  scale = "in";
                  weight = "lb";
                } else {
                  scale = "cm";
                  weight = "gm";
                }
                return (
                  <div key={i}>
                    <div
                      className={
                        bool4
                          ? "row row-col-invline-dr"
                          : "row row-col-invline-lt"
                      }
                    >
                      <div className="col-1 text-center">
                        <button
                          className="btn_exit_del"
                          type="button"
                          onClick={() => this.selectThispkgToDelete(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-trash" />
                        </button>
                      </div>
                      <div className="col-3 text-left adm-pkg-line-text">
                        {obj.pkgName}
                      </div>
                      <div className="col-2 text-left adm-pkg-line-text">
                        {this.commaFormattedCurrency(obj.pkgLength)} {scale}
                      </div>
                      <div className="col-2 text-left adm-pkg-line-text">
                        {this.commaFormattedCurrency(obj.pkgWidth)} {scale}
                      </div>
                      <div className="col-2 text-left adm-pkg-line-text">
                        {this.commaFormattedCurrency(obj.pkgHeight)} {scale}
                      </div>
                      <div className="col-2 text-left adm-pkg-line-text">
                        {this.commaFormattedCurrency(obj.pkgWeight)} {weight}
                      </div>
                    </div>
                    {(bool4 = !bool4)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        pkgLineBody = (
          <div className="">
            <div className="row am-pkg-lines-row">
              <div className="col-1 text-center adm-pkg-line-header">Ops</div>
              <div className="col-3 text-left adm-pkg-line-header">Name</div>
              <div className="col-2 text-left adm-pkg-line-header">Length</div>
              <div className="col-2 text-left adm-pkg-line-header">Width</div>
              <div className="col-2 text-left adm-pkg-line-header">Height</div>
              <div className="col-2 text-left adm-pkg-line-header">Weight</div>
            </div>
            <div className="coop-adm-pkg-box-x">
              <div className="text-center">
                No package has been defined yet...
              </div>
            </div>
          </div>
        );
      }
    }

    let activePanel;
    if (this.state.entryType === "price") {
      activePanel = (
        <div>
          {priceEntryPanel}
          {shipPriceListPanel}
        </div>
      );
    }

    if (this.state.entryType === "address") {
      activePanel = (
        <div>
          {fromAddressPanel}
          {fromAddrListPanel}
        </div>
      );
    }

    if (this.state.entryType === "package") {
      activePanel = (
        <div>
          {aPkgPanel}
          {pkgLineBody}
        </div>
      );
    }

    let outputPanel = (
      <div className="keeper-ship-mgmt-box">
        <div className="text-center coop-keep-adm-ship-head">
          Store Shipping Mgmt.
        </div>
        {keepEntryPanel}
        {activePanel}
        {buttonPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center ship-list-msg-err"
              : "text-center ship-list-msg"
          }
        >
          {this.state.message}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ShippingKeepAdm.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShippingKeepAdm));
