import React, { Component } from "react";

import axios from "axios";

import moment from 'moment';

import "./FinancialSummary.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCommunityFinSummary = "/routes/reports/getCommunityFinSummary?";

class FinancialSummary extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errMsg: "",
      errFlag: false,

      currentState: null,

      today: null
    };
  }

  componentDidMount = async () => {
    await this.getFinancialSummary();
    
    let date = new Date();
    var newdate= (date.getMonth() + 1) + '/' + date.getDate() + '/' +  date.getFullYear();

    this.setState({
        today: newdate
    })
  };

  getFinancialSummary = async () => {
    let param = "communityId=" + this.props.communityId;
    let url = baandaServer + getCommunityFinSummary + param;
    // console.log('url:', url);
    try {
      let sum = await axios.get(url);
      if (sum.data.status === "success") {
        this.setState({
          currentState: sum.data.Msg,
        });
      } else {
        this.setState({
          errMsg: sum.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getFinancialSummary err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  exitReport = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("FinancialSummary");

    let exitButton = (
      <button
        onClick={this.exitReport}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let summaryPanel;

    if (this.state.currentState) {
      summaryPanel = (
        <div>
          <div className="text-center summary-report-header-row">
            Financial Year Started On:{" "}
            {this.state.currentState.finYearStartDateString}
          </div>
          <div className="text-center summary-report-time-row">
             At: {this.state.today}&nbsp;{moment().format('hh:mm A')}
          </div>
          <div className="row report-line">
              <div className="col-6 text-right summary-report-lbl">Total Cash In Coffer:</div>
              <div className="col-6 text-left summary-report-text">$ {this.commaFormattedCurrency(this.state.currentState.totalCashInCoffer)}</div>
          </div>
          <div className="row report-line">
              <div className="col-6 text-right summary-report-lbl">Total Asset:</div>
              <div className="col-6 text-left summary-report-text">$ {this.commaFormattedCurrency(this.state.currentState.totalAsset)}</div>
          </div>
          {/* <div className="row report-line">
              <div className="col-6 text-right summary-report-lbl">Total Equity:</div>
              <div className="col-6 text-left summary-report-text">$ {this.commaFormattedCurrency(this.state.currentState.totalEquity)}</div>
          </div> */}
          <div className="row report-line">
              <div className="col-6 text-right summary-report-lbl">Total Expense:</div>
              <div className="col-6 text-left summary-report-text">$ {this.commaFormattedCurrency(this.state.currentState.totalExpense)}</div>
          </div>
          <div className="row report-line">
              <div className="col-6 text-right summary-report-lbl">Total Inventory:</div>
              <div className="col-6 text-left summary-report-text">$ {this.commaFormattedCurrency(this.state.currentState.totalInventory)}</div>
          </div>
          <div className="row report-line">
              <div className="col-6 text-right summary-report-lbl">Total Liability:</div>
              <div className="col-6 text-left summary-report-text">$ {this.commaFormattedCurrency(this.state.currentState.totalLiability)}</div>
          </div>
          <div className="row report-line">
              <div className="col-6 text-right summary-report-lbl">Total Receivable:</div>
              <div className="col-6 text-left summary-report-text">$ {this.commaFormattedCurrency(this.state.currentState.totalReceivable)}</div>
          </div>
          {/* <div className="row report-line">
              <div className="col-6 text-right summary-report-lbl">Total Loan Received:</div>
              <div className="col-6 text-left summary-report-text">$ {this.commaFormattedCurrency(this.state.currentState.totalLoanReceived)}</div>
          </div> */}
          {/* <div className="row report-line">
              <div className="col-6 text-right summary-report-lbl">Total Loan Paid:</div>
              <div className="col-6 text-left summary-report-text">$ {this.commaFormattedCurrency(this.state.currentState.totalLoanPaid)}</div>
          </div> */}
        </div>
      );
    }

    let outputPanel = (
      <div className="members-list-box">
        <div className="text-center summary-report-header-text">
          Financial Summary&nbsp;{exitButton}
        </div>
        {summaryPanel}
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default FinancialSummary;
