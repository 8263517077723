import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import CustPayCtrlDetail from "./CustPayCtrlDetail";
import { sortArrayOfDates } from "../../../../../../utils/sortArrayOfDates";

import "./CustPayCtrlList.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getSubscribesTo = "/routes/payctrl/getSubscribesTo?";

class CustPayCtrlList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      list: [],
      selectedSubscription: null,

      showListPanel: false,
      showDetailPanel: false,

      errFlag: false,
      errMsg: "",
    };
  }

  componentDidMount = async () => {
    await this.getServicesSubscribedTo();
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    this.setState({
      showListPanel: true,
      deviceSize,
    });
  };

  sortList = async (msgs) => {
    let sorted = await sortArrayOfDates(msgs, "created_at", "dsc");
    return sorted;
  };

  getServicesSubscribedTo = async () => {
    let params =
      "customerBaandaId=" +
      this.props.auth.user.baandaId +
      "&sellerCommunityId=" +
      this.props.communityId;
    let url = baandaServer + getSubscribesTo + params;
    // console.log("getServicesSubscribedTo url:", url);
    try {
      let lret = await axios.get(url);
      if (lret.data.status === "success") {
        this.setState({
          list: await this.sortList(lret.data.Msg),
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: lret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  selectThisItemToManage = async (obj) => {
    // console.log("in here ... obj:", obj);
    this.setState({
      selectedSubscription: obj,
      showListPanel: false,
      showDetailPanel: true,
    });
  };

  returnFromDetails = async () => {
    await this.getServicesSubscribedTo();
    this.setState({
      selectedSubscription: null,
      showListPanel: true,
      showDetailPanel: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('CustPayCtrlList...');

    let open;
    if (this.state.deviceSize === "small") {
      open = "O";
    } else {
      open = "Open";
    }

    let subscriptionListPanel;
    if (this.state.showListPanel) {
      if (this.state.list.length > 0) {
        let bool = true;
        subscriptionListPanel = (
          <div>
            <div className="row sub-item-list-row">
              <div className="col-11 text-center pay-ctrl-cust-list-head">
                Item Description
              </div>
              <div className="col-1 text-left pay-ctrl-cust-list-head">Ops</div>
            </div>
            <div className="fixedsize-cust-payctrl-list-box">
              {this.state.list.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        bool
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-11 text-left pay-ctrl-cust-line-lbl">
                        {obj.lastSubscriptionState &&
                        obj.lastSubscriptionState === "payment_failed" ? (
                          <font color="red">
                            {obj.itemName} - Last Payment Failed "
                          </font>
                        ) : (
                          obj.itemName
                        )}{" "}
                        $
                        {this.commaFormattedCurrency(
                          obj.inputData.price.monthlyFee
                        )}
                      </div>
                      <div className="col-1 text-left cust-pay-ctrl-open-pos">
                        <button
                          className="btn_reg_50 open-pay-ctrl-btn-pos"
                          type="button"
                          onClick={() => this.selectThisItemToManage(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          {open}
                        </button>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
            <div
              className={
                this.state.errFlag
                  ? "text-center sub-item-msg-err"
                  : "text-center sub-item-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        );
      } else {
        subscriptionListPanel = (
          <div className="text-center pay-ctrl-list-no-sub">
            You do not subscribe to any service in this community.
          </div>
        );
      }
    }

    let custPayctrlPanel;
    if (this.state.showDetailPanel) {
      custPayctrlPanel = (
        <div>
          <CustPayCtrlDetail
            subSelected={this.state.selectedSubscription}
            communityId={this.props.communityId}
            sellerStripeId={this.props.community.stripe.id}
            returnToCaller={this.returnFromDetails}
          />
        </div>
      );
    }

    let outputPanel;
    if (this.state.showListPanel) {
      outputPanel = (
        <div className="payment-ctrl-cust-list-box">
          <div className="pay-ctrl-cust-head">
            Subscription Payment Control List
          </div>
          {subscriptionListPanel}
          <div
            className={
              this.state.errFlag
                ? "text-center pay-ctrl-cust-msg-err"
                : "text-center pay-ctrl-cust-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    if (this.state.showDetailPanel) {
      outputPanel = (
        <div className="payment-ctrl-cust-list-box">{custPayctrlPanel}</div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

CustPayCtrlList.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// const mapDispatchToProps = (dispatch) => ({
//   hideModal: () => dispatch(hideModal()),
//   showModal: (modalProps, modalType) => {
//     dispatch(showModal({ modalProps, modalType }));
//   },
// });

// export default withRouter(
//   connect(mapStateToProps, mapDispatchToProps)(CustPayCtrlList)
// );

export default withRouter(connect(mapStateToProps, null)(CustPayCtrlList));
