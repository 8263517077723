import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import LoadFileToS3 from "../../../../utils/components/fileUpload/LoadFileToS3";

import "./StoreSetup.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateStorePromoDA = "/routes/coop/updateStorePromoDA";
const saveKeeperStoreSetup = "/routes/coop/saveKeeperStoreSetup";
const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";
// const getUserByEmailForKeepSet = "/routes/users/getUserByEmailForKeepSet?";

const imageFileTypes = [
  "jpeg",
  "jpg",
  "bmp",
  "tiff",
  "png",
  "gif",
  "ebp",
  "svg",
];
// const audioFileTypes = ["mp3", "wav", "mpeg"];
const vedioFileTypes = ["mp4", "webm"];

const maxImageSize = 50000000; // 5MB
const maxVideoSize = 50000000; // 50mb  -- about 10 minutes

const readMeCode = "0000000000";

class StoreSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      s3SelfiFileData: null,
      s3StoreImageFileData: null,
      s3StoreVideoFileData: null,
      s3AdvFileData: null,
      s3AdvFileDataSmall: null,

      advType: "static",

      imageFileTypes: "",
      s3ImageFileData: null,
      maxDASize: 0,

      selectedFileType: "",
      imgRequestorMsg: "",
      loadImageFlag: false,

      fileUploads: [],

      s3CoopSWbucket: "",
      selheight: 1,

      errMsg:
        'Please check the proper sizing of a type for best customer experience. Click info "i" for more.',
      errFlag: false,

      displayStoreKeeper: "",
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "USA",
      displayStoreName: "",
      displayDescription: "",
      storePointEmail: "",
      keepContact: null,
    };
  }

  componentDidMount = async () => {
    await this.setState({
      s3CoopSWbucket: this.props.store.s3CoopSWbucket,
      fileUploads: this.props.store.fileUploads,
    });

    let displayStoreKeeper = "",
      displayDescription,
      displayStoreName = "";
    if (this.props.store) {
      if (
        this.props.store.displayStoreName &&
        this.props.store.displayStoreName !== ""
      ) {
        displayStoreName = this.props.store.displayStoreName;
      } else displayStoreName = this.props.store.storeName;
      if (
        this.props.store.displayStoreKeeper &&
        this.props.store.displayStoreKeeper !== ""
      ) {
        displayStoreKeeper = this.props.store.displayStoreKeeper;
      } else displayStoreKeeper = this.props.store.ownerName;
      if (
        this.props.store.displayDescription &&
        this.props.store.displayDescription !== ""
      ) {
        displayDescription = this.props.store.displayDescription;
      } else displayDescription = this.props.store.description;
    }

    if (this.props.store.fileUploads.length > 0)
      await this.setStateFilePerDBFileUpload(this.props.store.fileUploads);

    let street = "";
    let city = "";
    let state = "";
    let postalCode = "";
    let country = "USA";

    if (this.props.store.displayAddress) {
      street = this.props.store.displayAddress.street;
      city = this.props.store.displayAddress.city;
      state = this.props.store.displayAddress.state;
      postalCode = this.props.store.displayAddress.postalCode;
      country = this.props.store.displayAddress.country;
    } else if (this.props.store.storeLocation) {
      street = this.props.store.storeLocation.street;
      city = this.props.store.storeLocation.city;
      state = this.props.store.storeLocation.state;
      postalCode = this.props.store.storeLocation.postal_code;
      country = this.props.store.storeLocation.country;
    }

    let storePointEmail = "";
    // if (this.props.store.storePointContact) {
    //   storePointEmail = this.props.store.storePointContact.email;
    // } else {
    storePointEmail = this.props.auth.user.email;
    // }

    this.setState({
      displayStoreKeeper,
      displayStoreName,
      street,
      state,
      city,
      postalCode,
      country,
      displayDescription,
      storePointEmail,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  checkAddress = async () => {
    let param =
      "street1=" +
      this.state.street +
      "&city=" +
      this.state.city +
      "&zip=" +
      this.state.postalCode +
      "&state=" +
      this.state.state +
      "&country=" +
      this.state.country;
    let url = baandaServer + easypostAddressCheck + param;
    let status = "success";
    let msg = "";
    try {
      let check = await axios.get(url);
      // console.log("check:", check);
      if (check.data.status === "success") {
        await this.setState({
          message: "",
          errFlag: false,
        });
      } else {
        status = "error";
        msg = check.data.Msg;
      }
    } catch (err) {
      console.log("err:", err.message);
      status = "error";
      msg = err.message;
    }

    return { status, Msg: msg };
  };

  getKeepProfile = async () => {
    let isValid = true;
    
    let keepContact = {
      name: this.props.auth.user.name,
      cell: this.props.auth.user.cell.number,
      email: this.props.auth.user.email,
      baandaId: parseFloat(this.props.auth.user.baandaId),
    };

    this.setState({
      keepContact,
    });

    return isValid;
  };

  setStateFilePerDBFileUpload = async () => {
    let fileUploads = this.state.fileUploads;
    let s3SelfiFileData = null;
    let s3StoreImageFileData = null;
    let s3StoreVideoFileData = null;
    let s3AdvFileData = null;
    let s3AdvFileDataSmall = null;

    fileUploads.forEach((obj) => {
      switch (obj.type) {
        case "strCreatorSelfi":
          s3SelfiFileData = obj;
          break;
        case "storePromoVideo":
          s3StoreVideoFileData = obj;
          break;
        case "storePromoImage":
          s3StoreImageFileData = obj;
          break;
        case "storeAdvLarge":
          s3AdvFileData = obj;
          break;
        case "storeAdvSmall":
          s3AdvFileDataSmall = obj;
          break;

        default: // do nothing
      }
    });

    this.setState({
      s3SelfiFileData,
      s3StoreImageFileData,
      s3StoreVideoFileData,
      s3AdvFileData,
      s3AdvFileDataSmall,
    });
  };

  sets3CoopSWbucket = async () => {
    let subBucket = this.state.s3CoopSWbucket;
    if (subBucket === "" || !subBucket) {
      subBucket =
        "cid-" +
        this.props.store.communityId +
        "-coop-" +
        this.props.store.coopStoreId;
    }
    await this.setState({
      s3CoopSWbucket: subBucket,
    });
  };

  handleDAType = async () => {
    // console.log("in handleDAType");
    let value = document.getElementById("coopSetupImageSelect").value;
    // console.log("value:", value);
    let maxDASize = maxImageSize;
    let processingFileType = value;
    let fileUploadType = "image";
    let daFileType = imageFileTypes;

    // console.log("maxImageSize:", maxImageSize);
    // console.log("maxImageSize / 1000000:", maxImageSize / 1000000);
    let imgReq = "Image - MaxSize: " + maxImageSize / 1000000 + " MB";
    let s3FileData = null;
    if (value === "strCreatorSelfi") {
      imgReq = "Creators Selfi - MaxSize: " + maxImageSize / 1000000 + " MB";
      s3FileData = this.state.s3SelfiFileData;
    }
    if (value === "storePromoVideo") {
      daFileType = vedioFileTypes;
      maxDASize = maxVideoSize;
      fileUploadType = "video";
      imgReq = "Promo Video - MaxSize: " + maxVideoSize / 1000000 + " MB";
      s3FileData = this.state.s3StoreVideoFileData;
    }
    if (value === "storePromoImage") {
      imgReq = "Promo Image - MaxSize: " + maxImageSize / 1000000 + " MB";
      // console.log("imgReq:", imgReq);
      s3FileData = this.state.s3StoreImageFileData;
    }

    if (value === "storeAdvLarge") {
      imgReq =
        "Adv Image 1 (large)- MaxSize: " + maxImageSize / 1000000 + " MB";

      s3FileData = this.state.s3AdvFileData;
    }

    if (value === "storeAdvSmall") {
      imgReq =
        "Adv Image 3 (small) - MaxSize: " + maxImageSize / 1000000 + " MB";
      s3FileData = this.state.s3AdvFileDataSmall;
    }

    // console.log("this.state.s3CoopSWbucket:", this.state.s3CoopSWbucket);
    if (!this.state.s3CoopSWbucket || this.state.s3CoopSWbucket === "")
      await this.sets3CoopSWbucket();

    await this.setState({
      imageFileTypes: daFileType,
      selheight: 1,
      s3FileData,
      maxDASize,
      imgRequestorMsg: imgReq,
      processingFileType,
      fileType: fileUploadType,
      loadImageFlag: true,
    });
  };

  callerOfImageUpload = async (s3loadret) => {
    // console.log("image s3loadret:", s3loadret);
    // let s3ImageFileData = this.state.s3ImageFileData;
  

    let deletedFileSize = 0;
    let newFileSize = 0;
    let newFileuploads = [];

    if (s3loadret.ops === "cancel") {
      newFileuploads = [...this.state.fileUploads];
    } else {
      this.state.fileUploads.forEach((obj) => {
        // console.log("obj:", obj);
        // console.log(
        //   "this.state.processingFileType:",
        //   this.state.processingFileType
        // );
        if (obj.type === this.state.processingFileType) {
          if (s3loadret.ifDeletedFlag) {
            if (obj.size) {
              deletedFileSize = obj.size;
              // console.log("1. deletedFileSize:", deletedFileSize);
            }
            // s3ImageFileData = null;
          }
        } else newFileuploads.push(obj);
      });
      if (s3loadret.s3FileData) {
        // console.log("inside new DA");
        s3loadret.s3FileData.type = this.state.processingFileType;
        newFileuploads.push(s3loadret.s3FileData);
        if (s3loadret.s3FileData.size) newFileSize = s3loadret.s3FileData.size;
        // s3ImageFileData = s3loadret.s3FileData;
      }
    }
    // console.log("newFileuploads:", newFileuploads);
    // console.log(
    //   "deletedFileSize:",
    //   deletedFileSize,
    //   " newFileSize:",
    //   newFileSize
    // );

    if (s3loadret.ops === "done") {
      // console.log("Will update DA in db ...");
      let adjustOfDASize = newFileSize - deletedFileSize;
      let inputData = {
        adjustOfDASize,
        fileUploads: newFileuploads,
        s3CoopSWbucket: this.state.s3CoopSWbucket,
        communityId: this.props.store.communityId,
        coopStoreId: this.props.store.coopStoreId,
      };
      // console.log("inputData:", inputData);
      await this.updateDAInDB(inputData);
      await this.setStateFilePerDBFileUpload();
    } else {
      console.log("Was canceled ... did not update");
    }

    this.setState({
      loadImageFlag: false,
    });
  };

  onfocus = () => {
    this.setState({
      selheight: 6,
    });
  };

  onblur = () => {
    this.setState({
      selheight: 1,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  updateDAInDB = async (inputData) => {
    let url = baandaServer + updateStorePromoDA;

    try {
      let proret = await axios.post(url, inputData);
      if (proret.data.status === "success") {
        this.setState({
          fileUploads: proret.data.Msg,
          errMsg: "Successfully Updated",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: proret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  validateInput = async () => {
    let status = "success";
    let msg = "";
    let isValid = await this.checkAddress();
    if (isValid.status === "error") {
      status = "error";
      msg = "Invalid Display Store Address; ";
    }

    let strCreatorSelfi = false;
    let storePromoImage = false;
    this.state.fileUploads.forEach((img) => {
      // console.log("img:", img);
      if (img.type === "strCreatorSelfi") strCreatorSelfi = true;
      if (img.type === "storePromoImage") storePromoImage = true;
    });

    if (!strCreatorSelfi) {
      status = "error";
      msg += "Must load a store keeper selfi; ";
    }

    if (!storePromoImage) {
      status = "error";
      msg +=
        "Must load a store image with hight to width ration as 1 is to 2; ";
    }

    return { status, Msg: msg };
  };

  saveDisplayData = async () => {
    let isValid = await this.validateInput();

    if (isValid.status === "success") {
      let ret = await this.getKeepProfile();

      if (ret) {
        let url = baandaServer + saveKeeperStoreSetup;

        let location = "";
        this.state.fileUploads.forEach((img) => {
          if (img.type === "storePromoImage") location = img.location;
        });

        let displayAddress = {
          street: this.state.street,
          city: this.state.city,
          state: this.state.state,
          postalCode: this.state.postalCode,
          country: this.state.country,
        };
        let input = {
          storePointContact: this.state.keepContact,
          communityId: this.props.store.communityId,
          coopStoreId: this.props.store.coopStoreId,
          displayAddress,
          location,
          displayStoreName: this.state.displayStoreName,
          displayDescription: this.state.displayDescription,
          displayStoreKeeper: this.state.displayStoreKeeper,
          signBoardLong: this.props.community.signBoardLong,
          signBoardShort: this.props.community.signBoardShort,
        };
        // console.log('save storeBranding input: ', input);
        try {
          let ret = await axios.post(url, input);
          // console.log("ret.data:", ret.data);
          if (ret.data.status === "success") {
        
            this.setState({
              errMsg: "Successfully Updated",
              errFlag: false,
            });
      
          } else {
            this.setState({
              errMsg: ret.data.Msg,
              errFlag: true,
            });
          }
        } catch (err) {
          this.setState({
            errMsg: err.message,
            errFlag: true,
          });
        }
      }
    } else {
      this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('StoreSetup...');

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let creatorNamePanel;
    creatorNamePanel = (
      <div>
        <div className="row co-op-input-top-row">
          <div className="col-3 text-right adm-creator-names-lbl">
            Display Keeper Name
          </div>
          <div className="col-9 text-left">
            <input
              name="displayStoreKeeper"
              type="text"
              value={this.state.displayStoreKeeper}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="coop-keeper-show-name"
              placeholder="Semicolon ';' delimited names"
            />
          </div>
        </div>
        <div className="row store-point-email-pos">
          <div className="col-3 text-right adm-creator-names-lbl">
            Keeper's Email
          </div>
          <div className="col-9 text-left">
            {this.state.storePointEmail}
            {/* <input
              name="storePointEmail"
              type="text"
              value={this.state.storePointEmail}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="coop-keeper-show-name"
              placeholder="Email of contatct of the ..."
            /> */}
          </div>
        </div>
        <div className="row co-op-input-rows">
          <div className="col-3 text-right adm-creator-names-lbl">
            Show Store Name
          </div>
          <div className="col-9 text-left">
            <input
              name="displayStoreName"
              type="text"
              value={this.state.displayStoreName}
              onChange={this.onChange}
              size="25"
              maxLength="25"
              className="coop-store-show-name"
              placeholder="Store Name in 25 chars"
            />
          </div>
        </div>
        <div className="row co-op-input-rows">
          <div className="col-3 text-right adm-creator-names-lbl">
            At Street
          </div>
          <div className="col-9 text-left">
            <input
              name="street"
              type="text"
              value={this.state.street}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="coop-keeper-setup-street"
              placeholder="Store address for customer to go.."
            />
          </div>
        </div>
        <div className="row co-op-input-rows">
          <div className="col-3 text-right adm-creator-names-lbl">&nbsp;</div>
          <div className="col-4 text-left">
            <input
              name="city"
              type="text"
              value={this.state.city}
              onChange={this.onChange}
              size="25"
              maxLength="25"
              className="coop-keeper-setup-city"
              placeholder="city..."
            />
          </div>
          <div className="col-2 text-center">
            <input
              name="state"
              type="text"
              value={this.state.state}
              onChange={this.onChange}
              size="5"
              maxLength="5"
              className="coop-keeper-setup-state"
              placeholder="state"
            />
          </div>
          <div className="col-3 text-left">
            <input
              name="postalCode"
              type="text"
              value={this.state.postalCode}
              onChange={this.onChange}
              size="5"
              maxLength="5"
              className="coop-keeper-setup-zip"
              placeholder="zip"
            />
            &nbsp; {this.state.country}
          </div>
        </div>
        <div className="row co-op-input-rows">
          <div className="col-3 text-right adm-creator-names-lbl">
            Mkt Description
          </div>
          <div className="col-9 text-left">
            <textarea
              name="displayDescription"
              maxLength="2000"
              placeholder="Describe your store for end customers ..."
              rows="3"
              wrap="hard"
              spellCheck="true"
              className="coop-keep-show-desc"
              onChange={this.onChange}
              value={this.state.displayDescription}
              required
            />
          </div>
        </div>
        <div className="text-center adm-creator-save-btn-pos">
          <button
            className="btn_reg_60"
            type="button"
            onClick={this.saveDisplayData}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Save
          </button>
        </div>
      </div>
    );

    let promoFiletypePanel;
    promoFiletypePanel = (
      <div>
        <select
          size={this.state.selheight}
          onFocus={this.onfocus}
          onBlur={this.onblur}
          id="coopSetupImageSelect"
          name="coopSetupImageSelect"
          onChange={this.handleDAType}
          onSelect={this.handleDAType}
          className="adm-coop-keep-promo-select"
          defaultValue={"DEFAULT"}
        >
          <option className="" value="DEFAULT" disabled>
            Select file type
          </option>
          <option className="" value="strCreatorSelfi">
            Stor Keeper(s) Selfi
          </option>
          <option className="" value="storePromoImage">
            Landing Page Image
          </option>
          <option className="" value="storePromoVideo">
            Landing Page Video
          </option>
          <option className="" value="storeAdvLarge">
            Promo Bottom image (large)
          </option>
          <option className="" value="storeAdvSmall">
            Promo Bottom image (small)
          </option>
        </select>
      </div>
    );

    let loadImagePanel;
    if (this.state.loadImageFlag) {
      console.log("this.props.communityId:", this.props.communityId);
      console.log("this.state.imageFileTypes:", this.state.imageFileTypes);
      console.log("this.state.maxDASize:", this.state.maxDASize);
      console.log("this.state.s3CoopSWbucket:", this.state.s3CoopSWbucket);
      console.log("this.state.imgRequestorMsg:", this.state.imgRequestorMsg);
      console.log("this.state.s3FileData:", this.state.s3FileData);
      console.log("this.state.fileType:", this.state.fileType);
      loadImagePanel = (
        <div className="text-center">
          <LoadFileToS3
            returnToCaller={this.callerOfImageUpload}
            communityId={this.props.communityId}
            fileType={this.state.imageFileTypes}
            maxSize={this.state.maxDASize}
            s3subdir={this.state.s3CoopSWbucket}
            requestor={this.state.imgRequestorMsg}
            s3FileData={this.state.s3FileData}
            type={this.state.fileType}
          />
        </div>
      );
    }

    let outputPanel;
    if (this.state.loadImageFlag) {
      outputPanel = (
        <div className="coop-store-setup-box">
          <div className="text-center admin-ad-header">
            Store Setup Mgmt. &nbsp;{infoButton}
          </div>
          {loadImagePanel}
        </div>
      );
    } else {
      outputPanel = (
        <div className="coop-store-setup-box">
          <div className="text-center admin-ad-header">
            Community Promotion Image Mgmt.&nbsp;&nbsp;{infoButton}
          </div>
          <div className="row promo-file-sel-pos">
            <div className="col-4 text-right promo-lbl">
              Select Upload Type{" "}
            </div>
            <div className="col-8 text-left">{promoFiletypePanel}</div>
          </div>
          {creatorNamePanel}
          <div className="row">
            <div
              className={
                this.state.errFlag
                  ? "col text-center admin-promo-msg-err"
                  : "col text-center admin-promo-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

StoreSetup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreSetup);
