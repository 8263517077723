import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ReactS3 from "react-s3";
import ReactLoading from "react-loading";

import ModalContainer from "../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../actions/modalActions";
import "../../../modal/css/localModal.css";
import "../../../modal/css/template.css";

// import { Buffer } from 'buffer';

import "./LoadFileToS3.css";

import cosmicDoorway from "./img/cosmicDoorway.jpg";


window.Buffer = window.Buffer || require("buffer").Buffer;


const awsAccessKeyId = process.env.REACT_APP_ACCESS_KEY_ID;
const awsSecretAccessKey = process.env.REACT_APP_SECRET_ACCESS_KEY;
const awsRegion = process.env.REACT_APP_AWS_REGION;
const s3BucketName = process.env.REACT_APP_S3_BUCKET_NAME;

const picTypes = ["jpeg", "jpg", "bmp", "tiff", "png", "gif", "webp", "svg"];
const videoTypes = ["mp4", "webm"];
const audioTypes = [ "mp3", "wav", "mpeg" ];

const readMeCode = "2100000000";

class LoadFileToS3 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      btnSelectFlag: false,
      btnReviewFlag: false,
      btnLoadFlag: false,
      btnDeleteFlag: false,

      selectPanelFlag: false,

      // uploadFileType: "pic",
      fileExtension: '',

      highlight: false,
      currFilename: "",
      disabled: false,

      picFile: "",
      picImagePreviewUrl: "",
      picFileNameToDisplay: "",
      fileNameToDisplay: "",
      imgPreviewFlag: false,
      pictureErrFlag: false,
      pictureErrMsg: "",
      uploadMsg: "",

      s3FileData: null,
      config: null,
      loadingFlag: false,

      showFileNameFlag: false,
      showReviewFlag: false,
      showReviewPicUrlFlag: false,
      showReviewVideoUrlFlag: false,
      showReviewAudioUrlFlag: false,
      showViewPdfFlag: false,

      showSelectBtnFlag: true,
      showDeleteBtnFlag: true,
      showReviewBtnFlag: true,
      showUploadBtnFlag: false,
      showDoneBtnFlag: false,
      ifDeletedFlag: false,

      deviceSize: "",
    };

    this.fileInputRef = React.createRef();
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    let delrevbtn = true;
    let selbtn = true;
    let s3FileData = null;
    let fileNameToDisplay = "";

    // console.log('========================================');
    // console.log('awsSecretAccessKey:', awsSecretAccessKey);
    // console.log('========================================');

    if (this.props.s3FileData === null) {
      // console.log("here 1111");
      delrevbtn = false;
    } else {
      // console.log("here 2222 s3FileData:", s3FileData);
      selbtn = false;
      s3FileData = this.props.s3FileData;
      fileNameToDisplay = this.props.s3FileData.key.split("/").pop();
    }
    // console.log("===== >> s3FileData:", s3FileData);
    // console.log('$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$');
    this.setState({
      deviceSize,
      showDeleteBtnFlag: delrevbtn,
      showReviewBtnFlag: delrevbtn,
      showSelectBtnFlag: selbtn,
      s3FileData,
      fileNameToDisplay,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  openPdfModal = () => {
    let msg = {
      Header: "Company Setup",
      Body: {
        s3Url: this.state.s3FileData.location,
        // fileName: this.state.fileNameToDisplay,
        fileName: this.props.requestor,

        footnote: "Footnote",
      },
      Footer: "Footer",
    };

    this.props.showModal(
      {
        open: true,
        title: "Alert - Start Here Header",
        message: msg,
        closeModal: this.closeModal,
      },
      "showPdf"
    );
  };
  // File handling functions ====================
  onDragOver = async (evt) => {
    evt.preventDefault();
    await this.setState({ highlight: true });
  };

  onDragLeave = async () => {
    await this.setState({ highlight: false });
  };

  openFileDialog = () => {
    this.fileInputRef.current.click();
  };

  onDrop = async (event) => {
    event.preventDefault();
    if (this.state.disabled) return; // investigate
    const files = event.dataTransfer.files;

    await this.setState({
      highlight: false,
      currFilename: files[0],
      fileNameToDisplay: files[0].name,
    });
  };
  // ============================================

  handleCancel = async () => {
    // await this.setState({
    //   s3FileData: null,
    // });
    let s3FileData;
    let showReviewFlag = false;
    if (!this.props.s3FileData) {
      s3FileData = null;
    } else {
      s3FileData = this.props.s3FileData;
    }
    // Delete thee uploaded file if
    if (this.state.s3FileData && !s3FileData) {
      // console.log("going to delete a file");
      await this.handleDelete();
    }
    await this.setState({
      s3FileData,
      showReviewFlag,
    });

    this.returnToOrigin('cancel');
  };

  handleDone = async () => {
    this.returnToOrigin('done');
  }

  returnToOrigin = async (calledby) => {
    let retData = {
      s3FileData: this.state.s3FileData,
      ifDeletedFlag: this.state.ifDeletedFlag,
      ops: calledby
    };
    // console.log(">>> retData:", retData);

    this.props.returnToCaller(retData);
  };

  handleSelect = async () => {
    await this.setState({
      selectPanelFlag: true,
      showSelectBtnFlag: false,
      // highlight: true,
    });
  };

  verifyFiles = (file, type, size) => {
    // this.state.authorizedFuncs.includes("store")
    // console.log('verifyFiles ===============');
    // console.log('file:', file);
    // console.log("=== type:", type);
    // console.log("=== type.toString():", type.toString());
    // console.log('size:', size);
    let xten = file.name.split(".").pop();
    // console.log('xetn:', xten);
    let righttype = type.includes(xten.toLowerCase());
    // console.log('righttype:', righttype)
    // console.log('verifyFiles ===============');
    let isValid = true,
      msg = "";
    if (!righttype) {
      isValid = false;
      msg =
        "Incorrect file type (" +
        xten +
        ") selected. Should be " +
        // type.toString() +
        type.join(", ") +
        ". ";
    }
    if (file.size > size) {
      isValid = false;
      let sizeinkb = size / 1042;
      msg = msg + `File size must be less then ${sizeinkb} kb.`;
    }
    return { status: isValid, msg: msg };
  };

  handleReview = async () => {
    let showprevurl = false;
    let showpdf = false;
    let showvideo = false;
    let showaudio = false;
    let ftype;
    // console.log('review this.state.s3FileData:', this.state.s3FileData);
    if (this.state.s3FileData && this.state.s3FileData.key !== "") {
      ftype = this.state.s3FileData.key.split(".").pop().toLowerCase();
      // console.log('ftype:', ftype);
      if (ftype === "pdf") showpdf = true;
      if (picTypes.includes(ftype)) showprevurl = true;
      if (videoTypes.includes(ftype)) showvideo = true;
      if (audioTypes.includes(ftype)) showaudio = true;
    }
    if (showpdf) {
      // console.log("inside showpdf");
      await this.setState({
        showFileNameFlag: true,
        showReviewFlag: false,
        showViewPdfFlag: showpdf, // Open modal to show pdf.
        showReviewVideoFlag: false,
        showReviewPicUrlFlag: false, // Show picture from S3 URL
        showReviewAudioUrlFlag: false,
        showReviewBtnFlag: false,
        selectPanelFlag: false,
      });
      this.openPdfModal();
    } else if ( showvideo ){
      await this.setState({
        showFileNameFlag: true,
        showReviewVideoUrlFlag: showvideo,
        showReviewFlag: true,
        showViewPdfFlag: false, // Open modal to show pdf.
        showReviewPicUrlFlag: false, // Show picture from S3 URL
        showReviewAudioUrlFlag: false,
        showReviewBtnFlag: false,
        selectPanelFlag: false,
      });
    } else if ( showaudio ){
      await this.setState({
        showFileNameFlag: true,
        showReviewAudioUrlFlag: showaudio,
        showReviewFlag: true,
        showViewPdfFlag: false, // Open modal to show pdf.
        showReviewPicUrlFlag: false, // Show picture from S3 URL
        showReviewBtnFlag: false,
        selectPanelFlag: false,
      });
    } else if ( showprevurl ){
      await this.setState({
        showFileNameFlag: true,
        showReviewAudioUrlFlag: false,
        showReviewFlag: true,
        showViewPdfFlag: false, // Open modal to show pdf.
        showReviewPicUrlFlag: true, // Show picture from S3 URL
        showReviewBtnFlag: false,
        selectPanelFlag: false,
      });
    }
  };
  
  onFilesAdded = async (evt) => {
    let fullPath = evt.target.value;
    let filename;
    let fileExt;
    if (fullPath) {
      filename = fullPath.split(/(\\|\/)/g).pop();
      fileExt = fullPath.split('.').pop();
    }

    // console.log("fullPath:", fullPath);
    // console.log("filename:", filename);
    // console.log('fileExt:', fileExt);

    const file = evt.target.files[0];

    // console.log("Buffer evt.target.files[0]:", file);

    let isVerified = { status: false, msg: "" };
    if (filename) {
      isVerified = this.verifyFiles(
        file,
        this.props.fileType,
        this.props.maxSize
      );
      // console.log("isVerified:", isVerified);
    }

    // const files = evt.target.files;

    if (isVerified.status) {
      // console.log("inside 111");
      let reader = new FileReader();

      // console.log('this.props.fileType:', this.props.fileType);
      
      let viewpic = true;
      let fileExtension = "image";
      if (this.props.fileType.includes("pdf")) viewpic = false;
      if (this.props.fileType.includes("mp4")) { 
        viewpic = false;
        fileExtension = 'video/'+ fileExt
      }
      if (this.props.fileType.includes("webm")) { 
        viewpic = false;
        fileExtension = 'video/'+ fileExt
      }
      if (this.props.fileType.includes("mp3")) {
        // console.log('here I am 1111'); 
        viewpic = false;
        fileExtension = 'audio/'+ fileExt
      }
      if (this.props.fileType.includes("wav")) { 
        viewpic = false;
        fileExtension = 'audio/'+ fileExt
      }
      if (this.props.fileType.includes("mpeg")) { 
        viewpic = false;
        fileExtension = 'audio/'+ fileExt
      }
      if (this.props.fileType.includes("logo")) { 
        viewpic = false;
        fileExtension = 'logo'
      }

      // console.log('fileExtension:', fileExtension);

      reader.onload = async (e) => {
        await this.setState({
          picFile: file,
          picImagePreviewUrl: reader.result,
          picFileNameToDisplay: filename,
          fileNameToDisplay: filename,
          fileExtension,
          imgPreviewFlag: true,
          pictureErrFlag: false,
          pictureErrMsg: "",
          showUploadBtnFlag: true,
          showFileNameFlag: true,
          showReviewFlag: viewpic,
          uploadMsg: "",
          showSelectBtnFlag: false,
          // publishDeactivate: false,
        });
      };
      reader.readAsDataURL(file);
      // }
    } else {
      await this.setState({
        pictureErrFlag: true,
        publishDeactivate: true,
        pictureErrMsg: isVerified.msg,
        picFile: null,
        picImagePreviewUrl: null,
        picFileNameToDisplay: "",
        fileNameToDisplay: "",
        showReviewFlag: false,
        showFileNameFlag: false,
      });
    }
  };

  handleUpload = async () => {
    await this.setState({
      loadingFlag: true,
    });
    let subBucket = this.props.s3subdir;
    const config = {
      bucketName: s3BucketName,
      dirName: subBucket,
      region: awsRegion,
      accessKeyId: awsAccessKeyId,
      secretAccessKey: awsSecretAccessKey,
    };

    // console.log(">>> config:", config);
    // console.log('>>> this.state.picFile:', this.state.picFile);
    try {
      let s3FileData = await ReactS3.uploadFile(this.state.picFile, config);
      // console.log('-----------------------------------------------');
      // console.log('s3FileData:', s3FileData);
      // console.log('-----------------------------------------------');
      let retS3FileData = {
        subBucket: this.props.s3subdir,
        location: s3FileData.location,
        key : s3FileData.key,
        caption: '',
        type: this.props.type,
        size: this.state.picFile.size
      }
      await this.setState({
        s3FileData:  retS3FileData,
        showUploadBtnFlag: false,
        showDeleteBtnFlag: true,
        showReviewBtnFlag: true,
        showDoneBtnFlag: true,
        showReviewFlag: false,
        uploadMsg: "Uploaded onto cloud.",
        config,
        ifDeletedFlag: this.state.ifDeletedFlag,
      });
    } catch (err) {
      console.log("S3 upload error:", err);
    }
    await this.setState({
      loadingFlag: false,
    });
  };

  handleDelete = async () => {
    await this.setState({
      loadingFlag: true,
    });
    try {
      let delfilename = this.state.s3FileData.key.split("/").pop();
      // console.log("delfilename:", delfilename);
      const config = {
        bucketName: s3BucketName,
        dirName: this.props.s3subdir,
        region: awsRegion,
        accessKeyId: awsAccessKeyId,
        secretAccessKey: awsSecretAccessKey,
      };
      // console.log('handleDelete delfilename:', delfilename);
      // console.log('config:', config);
      await ReactS3.deleteFile(delfilename, config);
      // console.log('handleDelete ret:', ret);
      await this.setState({
        s3FileData: null,
        showUploadBtnFlag: false,
        showDeleteBtnFlag: false,
        showSelectBtnFlag: true,
        showReviewBtnFlag: false,
        showReviewFlag: false,
        showDoneBtnFlag: false,
        showReviewVideoUrlFlag: false,
        showReviewAudioUrlFlag: false,
        showReviewPicUrlFlag: false,
        uploadMsg: "Deleted from cloud",
        showFileNameFlag: true,
        ifDeletedFlag: true,
        selectPanelFlag: false,
      });
    } catch (err) {
      console.log("Delete error:", err.message);
      await this.setState({
        uploadMsg: "Error: Deletion Failed >> " + err.message,
        showFileNameFlag: true,
      });
    }
    await this.setState({
      loadingFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('LoadFileToS3...');

    // Upload Spinner _____________________________________________
    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div>
          <ReactLoading
            type={"spokes"}
            color={"#195670"}
            height={30}
            width={30}
          />
          <p>Please Wait. Time depends on file size & network.</p>
        </div>
      );
    }
    //----------------------------------------

    let select, review, upload, retrn, dele, cancel;
    if (this.state.deviceSize === "small") {
      select = "Sel";
      review = "Rvw";
      upload = "Lod";
      retrn = "Dne";
      dele = "Del";
      cancel = "Can";
    } else {
      select = "Select";
      review = "Review";
      upload = "Upload";
      retrn = "Done";
      dele = "Delete";
      cancel = "Cancel";
    }

    let buttonPanel;
    buttonPanel = (
      <div className="text-center">
        {this.state.showSelectBtnFlag ? (
          <button
            className="btn_reg_70_aws"
            onClick={this.handleSelect}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {this.state.deviceSize === 'big' ? select : <i className="fas fa-check" />}
          </button>
        ) : null}

        {this.state.showReviewBtnFlag ? (
          <button
            className="btn_reg_70_aws"
            onClick={this.handleReview}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {this.state.deviceSize === 'big' ? review : <i className="fas fa-glasses" />}
          </button>
        ) : null}

        {this.state.showUploadBtnFlag ? (
          <button
            className="btn_reg_70_aws"
            onClick={this.handleUpload}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {this.state.deviceSize === 'big' ? upload: <i className="fas fa-upload" />  }
          </button>
        ) : null}
        {this.state.showDoneBtnFlag || this.state.ifDeletedFlag ? (
          <button
            className="btn_reg_70_aws"
            // onClick={this.returnToOrigin}
            onClick={this.handleDone}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {this.state.deviceSize === 'big' ? retrn : <i className="fas fa-backward" /> }
          </button>
        ) : null}

        {this.state.showDeleteBtnFlag ? (
          <button
            // className="btn_file_delete s3-del-can-small-pos"
            className="btn_file_delete"
            onClick={this.handleDelete}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {this.state.deviceSize === 'big' ? dele : <i className="fas fa-trash-alt" />}
          </button>
        ) : null}
        {this.state.ifDeletedFlag ? null : (
          <button
            className="btn_file_delete"
            onClick={this.handleCancel}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {this.state.deviceSize === 'big' ? cancel : <i className="fas fa-window-close" />}
          </button>
        )}
        <button
          className="btn_info_main load_s3_info_btn"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        > 
          <i className="fas fa-info-circle" />
        </button>
      </div>
    );

    let fileReviewPanel;
    if (this.state.showReviewFlag) {
      fileReviewPanel = (
        <div className="text-center s3-img-placement">
          <img
            src={this.state.picImagePreviewUrl}
            alt="pic"
            className="s3-att-comm-preview"
          />
        </div>
      );
    }

    if (this.state.showReviewPicUrlFlag) {
      fileReviewPanel = (
        <div className="text-center s3-img-placement">
          <img
            src={this.state.s3FileData.location}
            alt="pic"
            className="s3-att-comm-preview"
          />
        </div>
      );
    } 


    if (this.state.showReviewVideoUrlFlag) {
      fileReviewPanel = (
        <div className="text-center s3-img-placement">
          <div>
            <video width="300" controls>
            {/* <video className="video-preview-on-card" width="400" controls> */}
              <source
                src={this.state.s3FileData.location}

                type="video/mp4"
              />
            </video>
          </div>
        </div>
      );
    }

    if (this.state.showReviewAudioUrlFlag) {
      fileReviewPanel = (
        <div className="text-center s3-audio-place">
          <div>
            <audio width="200" hight="50" controls> 
              <source
                src={this.state.s3FileData.location}
                type={this.state.fileExtension}
              /> 
            </audio>
          </div>
        </div>
      );
    }

    let fileNamePanel;
    if (this.state.showFileNameFlag) {
      fileNamePanel = (
        <div>
          <div className="row">
            <div className="col text-center file-name">
              File:&nbsp;{this.state.fileNameToDisplay}
            </div>
          </div>
          <div className="row">
            <div className="col text-center ">
              <p>{this.state.uploadMsg}</p>
            </div>
          </div>
        </div>
      );
    } else {
      fileNamePanel = (
        <div>
          <div className="row">
            <div className="col text-center file-name">
              Select a proper file to upload.
            </div>
          </div>
        </div>
      );
    }

    // Upload Panel _______________________________________________
    let selectPanel;
    if (this.state.selectPanelFlag) {
      selectPanel = (
        <div>
          <div className="row">
            <div className="col-6 text-center">
              <div
                className={`dropzone ${
                  this.state.highlight ? "highlight" : ""
                }`}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                onClick={this.openFileDialog}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <p className="text-center top_of_card">File Dropzone</p>
                <img alt="upload" className="Icon" src={cosmicDoorway} />
                <input
                  ref={this.fileInputRef}
                  className="FileInput"
                  type="file"
                  multiple
                  onChange={this.onFilesAdded}
                />
                <p className="text-center small-txt">Click to select</p>
              </div>
            </div>
            <div className="col-6 upload_message">
              {fileReviewPanel}
              {fileNamePanel}
              {uploadingSpin}
            </div>
          </div>
          <div className="tos3-save-review-msg pic_msg_placement">
            <p>{this.state.pictureMsg}</p>
          </div>
          <div
            className={`${
              !this.state.pictureErrFlag
                ? "tos3-save-review-msg text-center"
                : "tos3-save-review-msg-err text-center"
            }`}
          >
            {this.state.pictureErrMsg}
          </div>
        </div>
      );
    }

    let justReviewPanel;
    if (!this.state.selectPanelFlag & this.state.showReviewFlag) {
      justReviewPanel = (
        <div className="just-show-review">
          <div className="row">
            <div className="col-6 text-center upload_message">
              {fileNamePanel}
            </div>
            <div className="col-6 upload_message">{fileReviewPanel}</div>
          </div>
        </div>
      );
    }
    // let viewPdfPanel;
    // if (this.state.showViewPdfFlag) {
    //   viewPdfPanel = <div>Will see pdf here ... </div>;
    // }
    let outputPanel;

    outputPanel = (
      <div className="file-load-box">
        <div className="load-box-header text-center">
          <b>Request for:</b>&nbsp;{this.props.requestor}
        </div>
        {/* {viewPdfPanel}  */}
        {justReviewPanel}
        {selectPanel}
        {buttonPanel}
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

LoadFileToS3.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoadFileToS3));
