import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import { sortArrayOfObjects } from "../../../../utils/sortArrayOfObjects";

import "./QuoteMgmt.css";

import ViewQuote from "./utils/ViewQuote";
import ChangeOrder from "./utils/ChangeOrder";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";
const saveQuotes = "/routes/projects/saveQuotes";
const getATask = "/routes/task/getATask?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1111580000";

class QuoteMgmt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newTaskNote: "",

      groupType: "lineitems",

      lineItemNo: 1.0,
      lineItemDescription: "",

      lineItemCost: 0.0,

      lineItems: [],

      lineTerms: [],
      termLineItemNo: 1.0,
      termDescription: "",
      lineItemMilestone: false,

      vatAmount: 0.0,
      contingentPer: 0.0,
      contingentAmt: 0.0,
      retainerPer: 0.0,

      startAfterRetained: 0.0,
      contingencyMaxDelay: 0.0,
      daysToFinishTheTask: 0.0,

      confirmToSend: false,
      notifyTarget: false,
      quoteInvoicedAt: "invoiceatend",

      showQuoteMgmtFlag: true,
      showViewPanelFlag: false,
      showChangeOrderFlag: false,

      errMsg: "",
      errFlag: false,

      packagedViewData: null,

      serviceAddrStreet: "",
      serviceAddrCity: "",
      serviceAddrState: "",
      serviceAddrZip: "",
      serviceCountry: 'USA',

      changeOrderSeqno: 0,
      theTask: null,
    };
  }

  componentDidMount = async () => {
    let changeOrderSeqno = 0;
    let serviceAddrStreet = "";
    let serviceAddrCity = "";
    let serviceAddrState = "";
    let serviceAddrZip = "";
    let lineItems = [];
    let lineTerms = [];
    let lineItemNo = 1.0;
    let termLineItemNo = 1.0;
    let contingentPer = 0.0;
    let vatAmount = 0.0;
    let retainerPer = 0.0;
    let contingencyMaxDelay = 0.0;
    let daysToFinishTheTask = 0.0;
    let quoteInvoicedAt = "invoiceatend";
    let startAfterRetained = 0;

    let changeOrderCalc = [];

    await this.getATask();

    // if ( this.props.theTask && this.props.theTask.quotes.length > 0) {
    if (this.state.theTask && this.state.theTask.quotes.length > 0) {
      // let qtnew =  this.state.theTask.quotes[this.state.theTask.quotes.length - 1];
      let qtnew = this.state.theTask.quotes[0];
      // console.log("qtnew:", qtnew);
      if (qtnew.serviceAt) {
        serviceAddrStreet = qtnew.serviceAt.street;
        serviceAddrCity = qtnew.serviceAt.city;
        serviceAddrState = qtnew.serviceAt.state;
        serviceAddrZip = qtnew.serviceAt.zip;
      }

      qtnew.lineItem.forEach((li) => {
        let liobj = {
          lineItemCost: li.cost,
          lineItemNo: li.seqno,
          lineItemDescription: li.description,
          lineItemMilestone: li.milestone,
        };
        lineItems.push(liobj);
      });
      lineItemNo = qtnew.lineItem.length + 1;

      qtnew.lineTerms.forEach((lt) => {
        let ltobj = {
          termLineItemNo: lt.seqno,
          termDescription: lt.description,
        };
        lineTerms.push(ltobj);
      });
      termLineItemNo = qtnew.lineTerms.length + 1;

      changeOrderSeqno = qtnew.changeOrderSeqno;

      contingentPer = qtnew.contingencyPercent;
      vatAmount = qtnew.estimatedTaxAmount;
      quoteInvoicedAt = qtnew.invoiceAt;
      retainerPer = qtnew.retainerPercent;
      contingencyMaxDelay = qtnew.maxDelayAllowed;
      daysToFinishTheTask = qtnew.daysToFinishTheTask;
      startAfterRetained = qtnew.startByAfterRetained;

      // Calculate Change order calc.
      if (this.state.theTask.quotes.length > 1) {
        this.state.theTask.quotes.forEach((chq) => {
          let colineTotal = 0;
          chq.lineItem.forEach((litm) => {
            colineTotal += litm.cost;
          });

          let changeOrderCalcObj = {
            chorderNo: chq.changeOrderSeqno,
            colineTotal,
            vatAmount: chq.vatAmount,
            daysToFinishTheTask: chq.daysToFinishTheTask,
            quoteInvoicedAt: chq.quoteInvoicedAt,
          };
          changeOrderCalc.push(changeOrderCalcObj);
        });
      }
    }

    this.setState({
      changeOrderSeqno,
      // theTask: this.props.theTask,
      serviceAddrStreet,
      serviceAddrCity,
      serviceAddrState,
      serviceAddrZip,
      lineItems,
      lineTerms,
      lineItemNo,
      contingentPer,
      vatAmount,
      quoteInvoicedAt,
      retainerPer,
      contingencyMaxDelay,
      startAfterRetained,
      daysToFinishTheTask,
      termLineItemNo,
    });
  };

  openAlertModal = () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getATask = async () => {
    let params = "taskId=" + this.props.theTask.taskId;
    let url = baandaServer + getATask + params;
    // console.log("url:", url);
    try {
      let tskret = await axios.get(url);
      // console.log("tskret.data:", tskret.data);
      if (tskret.data.status === "success") {
        if (tskret.data.Msg) {
          this.setState({
            theTask: tskret.data.Msg,
            errMsg: "",
            errFlag: false,
          });
        } else {
          this.setState({
            errMsg:
              "No tasks found for the taskId = " + this.state.theTask.taskId,
            errFlag: false,
          });
        }
      } else {
        this.setState({
          tasks: [],
          errMsg: tskret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        tasks: [],
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  returnToTaskDetails = () => {
    this.props.returnToCaller("cancel");
  };

  handleGroupType = async (e) => {
    let value = e.target.value;
    let showViewPanelFlag = false;
    let showChangeOrderFlag = false;
    let showQuoteMgmtFlag = false;
    if (value === "view") {
      await this.packageDataForView();
      showViewPanelFlag = true;
    }
    if (value === "changeorder") showChangeOrderFlag = true;
    if (value === "lineitems" || value === "conditions" || value === "sumup")
      showQuoteMgmtFlag = true;
    this.setState({
      groupType: e.target.value,
      showViewPanelFlag,
      showChangeOrderFlag,
      showQuoteMgmtFlag,
      errMsg: "",
      errFlag: false,
    });
  };

  returnFromQuoteView = async () => {
    await this.getATask();
    this.setState({
      showViewPanelFlag: false,
      showQuoteMgmtFlag: true,
      showChangeOrderFlag: false,
      groupType: "lineitems",
    });
  };

  returnFromChageOrder = async () => {
    await this.getATask();
    this.setState({
      showViewPanelFlag: false,
      showQuoteMgmtFlag: true,
      showChangeOrderFlag: false,
      groupType: "lineitems",
    });
  };

  handleInvoicedAt = async (e) => {
    await this.setState({
      quoteInvoicedAt: e.target.value,
    });
  };

  onChangeCurrency = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        message: "",
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        message: "",
      });
    }
  };

  onChangeContingentPer = async (e) => {
    let value = e.target.value;
    // console.log("parseFloat(value):", parseFloat(value));
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      this.setState({
        [e.target.name]: val,
        errMsg: "",
      });
    } else {
      this.setState({
        [e.target.name]: value,
        errMsg: "",
      });
    }
  };

  onChangeLineNo = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 1) {
      let tmp = parseFloat(value).toFixed(1);
      let val = parseFloat(tmp);
      await this.setState({
        [e.target.name]: val,
        message: "",
      });
    } else {
      await this.setState({
        [e.target.name]: value,
        message: "",
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  checkIfLineIsValid = async () => {
    let isValid = "success",
      msg = "";
    this.state.lineItems.forEach((li) => {
      if (li.lineItemNo === this.state.lineItemNo) {
        isValid = "error";
        msg = "Duplicate line number not allowed";
      }
    });

    if (this.state.lineItemDescription === "") {
      isValid = "error";
      msg += " Must have a description.";
    }

    return { status: isValid, Msg: msg };
  };

  addLineItem = async () => {
    let isValid = await this.checkIfLineIsValid();
    if (isValid.status === "success") {
      let nli = [...this.state.lineItems];
      let lineObj = {
        lineItemNo: parseFloat(this.state.lineItemNo),
        lineItemCost: parseFloat(this.state.lineItemCost),
        lineItemDescription: this.state.lineItemDescription,
        lineItemMilestone: this.state.lineItemMilestone,
      };
      nli.push(lineObj);
      // console.log("1 nli:", nli);
      let lisort = await sortArrayOfObjects(nli, "lineItemNo", "dsc");
      // console.log("2. lisort:", lisort);

      let lines = [];
      let index = 1;
      lisort.forEach((obj) => {
        obj.lineItemNo = index;
        lines.push(obj);
        index++;
      });

      let newline = nli.length + 1;
      this.setState({
        lineItems: lines,
        errMsg: "",
        errFlag: false,
        lineItemNo: newline,
        lineItemDescription: "",
        lineItemCost: 0.0,
        lineItemMilestone: false,
      });
    } else {
      this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }
  };

  checkIfTermIsValid = async () => {
    let isValid = "success",
      msg = "";
    this.state.lineTerms.forEach((li) => {
      if (li.lineItemNo === this.state.lineItemNo) {
        isValid = "error";
        msg = "Duplicate line number not allowed";
      }
    });

    if (this.state.termDescription === "") {
      isValid = "error";
      msg += " Must have a description.";
    }

    return { status: isValid, Msg: msg };
  };

  addTermLines = async () => {
    let isValid = await this.checkIfTermIsValid();
    if (isValid.status === "success") {
      let nli = [...this.state.lineTerms];
      let lineObj = {
        termLineItemNo: parseFloat(this.state.termLineItemNo),
        termDescription: this.state.termDescription,
      };
      nli.push(lineObj);
      // console.log("1 nli:", nli);
      let lisort = await sortArrayOfObjects(nli, "termLineItemNo", "dsc");
      // console.log("2. lisort:", lisort);

      let lines = [];
      let index = 1;
      lisort.forEach((obj) => {
        obj.termLineItemNo = index;
        lines.push(obj);
        index++;
      });

      let newline = nli.length + 1;
      this.setState({
        lineTerms: lines,
        errMsg: "",
        errFlag: false,
        termLineItemNo: newline,
        termDescription: "",
      });
    } else {
      this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  totalLineItems = () => {
    let lineTotal = 0;
    this.state.lineItems.forEach((obj) => {
      lineTotal += obj.lineItemCost;
    });

    // console.log("lineTotal:", lineTotal);

    return lineTotal;
  };

  handleConfirm = async () => {
    await this.setState((prevstate) => ({
      confirmToSend: !prevstate.confirmToSend,
    }));
  };

  handleNotify = async () => {
    await this.setState((prevstate) => ({
      notifyTarget: !prevstate.notifyTarget,
    }));
  };

  handleMilestone = async () => {
    await this.setState((prevstate) => ({
      lineItemMilestone: !prevstate.lineItemMilestone,
    }));
  };

  handleLineItemDelete = (obj) => {
    // console.log("obj:", obj);
    let newlines = [];
    this.state.lineItems.forEach((elm) => {
      if (elm.lineItemNo !== obj.lineItemNo) newlines.push(elm);
    });
    let nidx = 1;
    newlines.forEach((nlk) => {
      nlk.lineItemNo = nidx;
      nidx++;
    });

    this.setState({
      lineItems: newlines,
      lineItemNo: newlines.length + 1,
      lineItemDescription: "",
      lineItemCost: 0.0,
    });
  };

  handleTermLineDelete = (obj) => {
    // console.log("obj:", obj);
    let newlines = [];
    this.state.lineTerms.forEach((elm) => {
      if (elm.termLineItemNo !== obj.termLineItemNo) newlines.push(elm);
    });
    let nidx = 1;
    newlines.forEach((nlk) => {
      nlk.termLineItemNo = nidx;
      nidx++;
    });

    this.setState({
      lineTerms: newlines,
      termLineItemNo: newlines.length + 1,
      termDescription: "",
    });
  };

  packageDataForView = async () => {
    let manager;
    if (this.props.theTask.taskReligateChain.length > 0) {
      manager =
        this.props.theTask.taskReligateChain[
          this.props.theTask.taskReligateChain.length - 1
        ];
    } else {
      manager = {
        pmBaandaId: this.props.auth.user.baandaId,
        pmCell: this.props.auth.user.cell.number,
        pmEmail: this.props.auth.user.email,
        pmName: this.props.auth.user.name,
      };
    }

    let input = {
      changeOrderSeqno: this.state.changeOrderSeqno,
      theTask: this.state.theTask,
      lineItems: this.state.lineItems,
      lineTerms: this.state.lineTerms,
      serviceAt: {
        street: this.state.serviceAddrStreet,
        city: this.state.serviceAddrCity,
        state: this.state.serviceAddrState,
        zip: this.state.serviceAddrZip,
      },
      contingency: {
        maxDelay: parseFloat(this.state.contingencyMaxDelay),
        percent: parseFloat(this.state.contingentPer),
      },
      daysToFinishTheTask: parseFloat(this.state.daysToFinishTheTask),
      startByAfterRetained: parseFloat(this.state.startAfterRetained),
      retainerPer: parseFloat(this.state.retainerPer),
      invoiceAt: this.state.quoteInvoicedAt,
      taxAmount: parseFloat(this.state.vatAmount),
      manager,
    };

    this.setState({
      packagedViewData: input,
    });
  };

  packageDataForSave = async () => {
    let litems = [];
    this.state.lineItems.forEach((obj) => {
      let ltobj = {
        seqno: obj.lineItemNo,
        milestone: obj.lineItemMilestone,
        description: obj.lineItemDescription,
        cost: obj.lineItemCost,
      };
      litems.push(ltobj);
    });

    let terms = [];
    this.state.lineTerms.forEach((elm) => {
      let tobj = {
        seqno: elm.termLineItemNo,
        description: elm.termDescription,
      };
      terms.push(tobj);
    });

    let newTaskNoteObj = {
      note: "Sending an updated quote. Please check.",
      party: "business",
      noteById: this.props.auth.user.baandaId,
      byName: this.props.auth.user.name,
      byEmail: this.props.auth.user.email,
      onDate: new Date(),
    };

    let input = {
      changeOrderSeqno: this.state.changeOrderSeqno,
      taskId: this.state.theTask.taskId,
      lineItems: litems,
      lineTerms: terms,
      serviceAt: {
        street: this.state.serviceAddrStreet,
        city: this.state.serviceAddrCity,
        state: this.state.serviceAddrState,
        zip: this.state.serviceAddrZip,
      },
      retainerPercent: parseFloat(this.state.retainerPer),
      // retainerPaidOn: null,
      startByAfterRetained: parseFloat(this.state.startAfterRetained),
      contingencyPercent: parseFloat(this.state.contingentPer),
      maxDelayAllowed: parseFloat(this.state.contingencyMaxDelay),
      daysToFinishTheTask: parseFloat(this.state.daysToFinishTheTask),
      estimatedTaxAmount: parseFloat(this.state.vatAmount),
      invoiceAt: this.state.quoteInvoicedAt,
      createdByBaandaId: this.props.auth.user.baandaId,
      status: "",
      taskStatus: "wip",
      sendReadyFlag: false,
      notifyTarget: this.state.notifyTarget,
      newTaskNoteObj,
      communityId: this.props.communityId,
      signBoardShort: this.props.community.signBoardShort,
      signBoardLong: this.props.community.signBoardLong,
      clientProgram: "QuoteMgmt",
      clientFunction: "packageDataForSave",
    };

    return input;
  };

  saveAsWIP = async () => {
    // console.log("this.state.groupType:", this.state.groupType);
    let isValid = await this.validateInputCompletion();
    // console.log("isValid:", isValid);
    if (isValid.status === "success") {
      let input = await this.packageDataForSave("wip");
      input.status = "wip";
      await this.updateDatabase(input);
    } else {
      this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }
  };

  checkAddress = async () => {
    let param =
      "street1=" +
      this.state.serviceAddrStreet +
      "&city=" +
      this.state.serviceAddrCity +
      "&zip=" +
      this.state.serviceAddrZip +
      "&state=" +
      this.state.serviceAddrState +
      "&country=" +
      this.state.serviceCountry;
    let url = baandaServer + easypostAddressCheck + param;
    let status = "";
    let msg = "";
    try {
      let check = await axios.get(url);
      // console.log("check:", check);
      if (check.data.status === "success") {
        status = 'success';
      } else {
        status = "error";
        msg = check.data.Msg;
      }
    } catch (err) {
      status = "error";
      msg = err.message;
    }

    return { status, Msg: msg};
  }

  validateInputCompletion = async (calledBy) => {
    let msg = "";
    let isError = "success";
    // console.log("this.state.retainerPer:", parseFloat(this.state.retainerPer));
    if (parseFloat(this.state.retainerPer) <= 0) {
      // console.log("Should be here ....");
      msg = " Retainer must be a positive number;";
      isError = true;
    }
    if (parseFloat(this.state.contingentPer) < 0) {
      msg += " Contingency cannot be negative;";
      isError = true;
    }
    if (parseFloat(this.state.vatAmount) < 0) {
      msg += " Tax cannot be negative;";
      isError = true;
    }
    if (parseFloat(this.state.startAfterRetained) < 0) {
      msg += " Work cannot begin in the past.";
      isError = true;
    }
    if (parseFloat(this.state.contingencyMaxDelay) < 0) {
      msg += " Contingency delay cannot be negative.";
      isError = true;
    }
    if (parseFloat(this.state.daysToFinishTheTask) < 0) {
      msg += " Time require to do th job cannot be negative.";
      isError = true;
    }

    if ( calledBy === 'ready' ) {
      if ( this.state.serviceAddrStreet === '') {
        msg += " Must have a service (At) address.";
        isError = true;
      } else {
        let chkadr = await this.checkAddress();
        if ( chkadr.status === 'error') {
          msg += chkadr.Msg;
          isError = true;
        }
      }
    }

    return { status: isError, Msg: msg };
  };

  sendReadyQuote = async () => {
    // console.log("in sendReadyQuote");
    let isValid = await this.validateInputCompletion('ready');
    if (isValid.status === "success") {
      let input = await this.packageDataForSave();
      
      input.sendReadyFlag = true;
      input.status = "ready";
      input.taskStatus = "ready";
   
      await this.updateDatabase(input);
    } else {
      this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }
  };

  updateDatabase = async (input) => {
    let url = baandaServer + saveQuotes;
    console.log("url:", url, " input:", input);
    try {
      let quret = await axios.post(url, input);
      if (quret.data.status === "success") {
        this.setState({
          errMsg: "Successfully updated",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: quret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true, 
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('QuoteMgmt...');

    let sd = this.state.theTask;

    let exitButton = (
      <button
        onClick={this.returnToTaskDetails}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let helpButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let addLineButton = (
      <button
        onClick={this.addLineItem}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_40"
      >
        <i className="fa fa-plus" />
      </button>
    );

    let addTermsButton = (
      <button
        onClick={this.addTermLines}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_40"
      >
        <i className="fa fa-plus" />
      </button>
    );

    let buttonPanel = (
      <div className="row">
        <div className="col-3 text-right">
          <button
            onClick={this.saveAsWIP}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            className="btn_reg_60"
          >
            Draft
          </button>
        </div>
        {this.state.groupType === "sumup" ? (
          <div className="col-6 text-center quote-confirm-to-send">
            <Checkbox
              checked={this.state.confirmToSend}
              onChange={this.handleConfirm}
            />
            &nbsp;&nbsp;Confirm to Send&nbsp;&nbsp;&nbsp;
            {this.state.confirmToSend ? (
              <Checkbox
                checked={this.state.notifyTarget}
                onChange={this.handleNotify}
              />
            ) : null}
            {this.state.confirmToSend ? "  Text Notify?" : null}
          </div>
        ) : (
          <div className="col-6">&nbsp;</div>
        )}

        <div className="col-3 text-left">
          {this.state.confirmToSend ? (
            <button
              onClick={this.sendReadyQuote}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              className="btn_reg_110"
            >
              Send Quote
            </button>
          ) : null}
        </div>
      </div>
    );

    let selectGroupPanel;
    selectGroupPanel = (
      <div>
        <div className="row quote-group-sel-pos">
          <div className="col text-center radio-font-style">
            <strong>Work on&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="lineitems"
                  checked={this.state.groupType === "lineitems"}
                  onChange={this.handleGroupType}
                />{" "}
                Line Items
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="conditions"
                  checked={this.state.groupType === "conditions"}
                  onChange={this.handleGroupType}
                />{" "}
                Conditions
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="sumup"
                  checked={this.state.groupType === "sumup"}
                  onChange={this.handleGroupType}
                />{" "}
                Sum up
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="view"
                  checked={this.state.groupType === "view"}
                  onChange={this.handleGroupType}
                />{" "}
                View
              </label>
            </div>
            {this.props.theTask.taskStatus === "active" ? (
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="changeorder"
                    checked={this.state.groupType === "changeorder"}
                    onChange={this.handleGroupType}
                  />{" "}
                  Change Order
                </label>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );

    let quoteTopPanel;
    if (this.state.theTask) {
      quoteTopPanel = (
        <div className="quote-top-pos">
          <div className="text-center quote-header">
            Compose the Quote &nbsp;| CO#: {this.state.changeOrderSeqno} &nbsp;
            {exitButton}&nbsp;{helpButton}
          </div>
          {selectGroupPanel}
          <div className="row">
            <div className="col-2 text-right quote-lbl">Item Name</div>
            <div className="col-6 text-left quote-txt">{sd.itemName}</div>
            <div className="col-4 text-center quote-txt">
              Task Id: {sd.taskId}
            </div>
          </div>
        </div>
      );
    }

    let lineItemTopPanel;
    if (this.state.groupType === "lineitems") {
      lineItemTopPanel = (
        <div className="quote-top-box">
          <div className="text-center quote-line-item-head">Add Line Items</div>
          <div className="row quote-line-item-row">
            <div className="col-2 text-right quote-line-item-lbl">Line #</div>
            <div className="col-2 text-left">
              <input
                name="lineItemNo"
                type="number"
                value={this.state.lineItemNo}
                onChange={this.onChangeLineNo}
                className="quote-lineitem-no"
                step=".1"
                placeholder="0"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
            <div className="col-2 text-right quote-line-item-lbl">Cost</div>
            <div className="col-2 text-left">
              <input
                name="lineItemCost"
                type="number"
                value={this.state.lineItemCost}
                onChange={this.onChangeCurrency}
                className="quote-lineitem-cost"
                step=".1"
                placeholder="0"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
            <div className="col-2 text-left quote-line-item-lbl">
              <Checkbox
                checked={this.state.lineItemMilestone}
                onChange={this.handleMilestone}
              />
              &nbsp;&nbsp;Milestone
            </div>
            <div className="col-2 text-left quote-line-item-lbl">
              {addLineButton}
            </div>
          </div>

          <div className="row quote-line-item-row">
            <div className="col-2 text-right quote-line-item-lbl">Describe</div>
            <div className="col-10 text-left">
              <textarea
                name="lineItemDescription"
                maxLength="1000"
                placeholder="Describe the line ..."
                rows="2"
                wrap="hard"
                spellCheck="true"
                className="quote-line-item-desc"
                onChange={this.onChange}
                value={this.state.lineItemDescription}
                required
              />
            </div>
          </div>
        </div>
      );
    }

    if (this.state.groupType === "conditions") {
      lineItemTopPanel = (
        <div className="quote-top-box">
          <div className="text-center quote-line-item-head">
            Terms & conditions
          </div>
          <div className="row quote-line-item-row">
            <div className="col-2 text-right quote-line-item-lbl">Line #</div>
            <div className="col-2 text-left">
              <input
                name="termLineItemNo"
                type="number"
                value={this.state.termLineItemNo}
                onChange={this.onChangeLineNo}
                className="quote-lineitem-no"
                step=".1"
                placeholder="0"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
            <div className="col-6 text-right quote-line-item-lbl">&nbsp;</div>
            <div className="col-2 text-left quote-line-item-lbl">
              {addTermsButton}
            </div>
          </div>

          <div className="row quote-line-item-row">
            <div className="col-2 text-right quote-line-item-lbl">Describe</div>
            <div className="col-10 text-left">
              <textarea
                name="termDescription"
                maxLength="1000"
                placeholder="Describe the line ..."
                rows="2"
                wrap="hard"
                spellCheck="true"
                className="quote-line-item-desc"
                onChange={this.onChange}
                value={this.state.termDescription}
                required
              />
            </div>
          </div>
        </div>
      );
    }

    let lineItemsListPanel;
    if (this.state.groupType === "lineitems") {
      let bool = true;
      lineItemsListPanel = (
        <div>
          <div className="row quote-list-header">
            <div className="col-2 text-center quote-list-head-lbl"># Ops</div>
            <div className="col-8 text-left quote-list-head-lbl">
              Description
            </div>
            <div className="col-2 text-center quote-list-head-lbl">Amount</div>
          </div>
          <div className="quote-list-box">
            {this.state.lineItems.map((obj, i) => {
              // console.log('874 obj:', obj);
              return (
                <div key={i}>
                  <div
                    className={
                      bool
                        ? "row row-col-item-list-dr"
                        : "row row-col-item-list-lt"
                    }
                  >
                    <div className="col-2 text-left quote-list-item-lbl">
                      <button
                        onClick={() => this.handleLineItemDelete(obj)}
                        className="btn_exit_del"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;{obj.lineItemNo}.&nbsp;
                    </div>
                    <div className="col-8 text-left quote-list-item-lbl-des">
                      {obj.lineItemDescription}.{" "}
                      {obj.lineItemMilestone ? (
                        <i className="fa fa-star" />
                      ) : null}
                    </div>
                    <div className="col-2 text-right quote-list-item-lbl-cost">
                      {this.commaFormattedCurrency(obj.lineItemCost)}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (this.state.groupType === "conditions") {
      let bool1 = true;
      lineItemsListPanel = (
        <div>
          <div className="row quote-list-header">
            <div className="col-2 text-center quote-list-head-lbl"># Ops</div>
            <div className="col-10 text-left quote-list-head-lbl">
              Description
            </div>
          </div>
          <div className="quote-list-box">
            {this.state.lineTerms.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool1
                        ? "row row-col-item-list-dr"
                        : "row row-col-item-list-lt"
                    }
                  >
                    <div className="col-2 text-left quote-list-item-lbl">
                      <button
                        onClick={() => this.handleTermLineDelete(obj)}
                        className="btn_exit_del"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                      &nbsp;&nbsp;&nbsp;&nbsp;{obj.termLineItemNo}.&nbsp;
                    </div>
                    <div className="col-10 text-left quote-list-item-lbl-des">
                      {obj.termDescription}.
                    </div>
                  </div>
                  {(bool1 = !bool1)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let sumBottomPartPanel;
    sumBottomPartPanel = (
      <div className="quote-sumup-bottom">
        <div className="row">
          <div className="col-5">
            <div className="test-center quote-addr-head">
              <u>Service At</u>
            </div>
            <div className="row quote-addr-line-row">
              <div className="col-3 test-right quote-add-line-lbl">Street</div>
              <div className="col-9 text-left">
                <input
                  name="serviceAddrStreet"
                  type="text"
                  value={this.state.serviceAddrStreet}
                  onChange={this.onChange}
                  size="100"
                  maxLength="100"
                  className="service-addr-street"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row quote-addr-line-row">
              <div className="col-3 test-right quote-add-line-lbl">City</div>
              <div className="col-9 text-left">
                <input
                  name="serviceAddrCity"
                  type="text"
                  value={this.state.serviceAddrCity}
                  onChange={this.onChange}
                  size="50"
                  maxLength="50"
                  className="service-addr-city"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row quote-addr-line-row">
              <div className="col-3 test-right quote-add-line-lbl">State</div>
              <div className="col-9 text-left">
                <input
                  name="serviceAddrState"
                  type="text"
                  value={this.state.serviceAddrState}
                  onChange={this.onChange}
                  size="20"
                  maxLength="20"
                  className="service-addr-state"
                  placeholder=""
                />
              </div>
            </div>
            <div className="row quote-addr-line-row">
              <div className="col-3 test-right quote-add-line-lbl">Zip</div>
              <div className="col-9 text-left">
                <input
                  name="serviceAddrZip"
                  type="text"
                  value={this.state.serviceAddrZip}
                  onChange={this.onChange}
                  size="10"
                  maxLength="10"
                  className="service-addr-zip"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="col-1 text-center q-vline"></div>
          <div className="col-6">
            <div className="test-center quote-addr-head">
              <u>Timing & Billing</u>
            </div>
            <div className="row quote-time-row">
              <div className="col text-left quote-time-lbl">
                Work begins &nbsp;
                <input
                  name="startAfterRetained"
                  type="number"
                  value={this.state.startAfterRetained}
                  onChange={this.onChangeLineNo}
                  className="quote-contingency-delay"
                  step=".1"
                  placeholder="0"
                  autoComplete="off"
                  spellCheck="false"
                />
                &nbsp;business days after being retained
              </div>
            </div>
            <div className="row quote-time-row">
              <div className="col text-left quote-time-lbl">
                Contingency max delay &nbsp;
                <input
                  name="contingencyMaxDelay"
                  type="number"
                  value={this.state.contingencyMaxDelay}
                  onChange={this.onChangeLineNo}
                  className="quote-contingency-delay"
                  step=".1"
                  placeholder="0"
                  autoComplete="off"
                  spellCheck="false"
                />
                &nbsp;business days.
              </div>
            </div>
            <div className="row quote-time-row">
              <div className="col text-left quote-time-lbl">
                The task will require &nbsp;
                <input
                  name="daysToFinishTheTask"
                  type="number"
                  value={this.state.daysToFinishTheTask}
                  onChange={this.onChangeLineNo}
                  className="quote-contingency-delay"
                  step=".1"
                  placeholder="0"
                  autoComplete="off"
                  spellCheck="false"
                />
                &nbsp;business days.
              </div>
            </div>
            <div className="row quote-time-row">
              <div className="col text-left quote-time-lbl">
                <strong>Invoiced at&nbsp;&nbsp;</strong>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="invoiceatend"
                      checked={this.state.quoteInvoicedAt === "invoiceatend"}
                      onChange={this.handleInvoicedAt}
                    />{" "}
                    At the end
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="invoicedatmilestone"
                      checked={
                        this.state.quoteInvoicedAt === "invoicedatmilestone"
                      }
                      onChange={this.handleInvoicedAt}
                    />{" "}
                    At milestones
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    let sumupPanel;
    if (this.state.groupType === "sumup") {
      sumupPanel = (
        <div className="quote-sum-up-box">
          <div className="text-center quote-sumup-head">Summary Overview</div>
          <div className="row quote-sum-fin-row">
            <div className="col-2 text-right quote-sum-fin-lbl">
              Lines Total
            </div>
            <div className="col-10 text-left quote-sum-fin-text">
              $ {this.commaFormattedCurrency(this.totalLineItems())}
            </div>
          </div>
          <div className="row quote-sum-fin-row">
            <div className="col-2 text-right quote-sum-fin-lbl">
              Tax Estimate
            </div>
            <div className="col-10 text-left quote-sum-fin-text">
              ${" "}
              <input
                name="vatAmount"
                type="number"
                value={this.state.vatAmount}
                onChange={this.onChangeCurrency}
                className="quote-vat-amount"
                step=".1"
                placeholder="0"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="row quote-sum-fin-row">
            <div className="col-2 text-right quote-sum-fin-lbl">
              Contingency
            </div>
            <div className="col-4 text-left quote-sum-fin-text">
              ${" "}
              <input
                name="contingentPer"
                type="number"
                value={this.state.contingentPer}
                onChange={this.onChangeContingentPer}
                className="quote-vat-amount"
                step=".1"
                placeholder="0"
                autoComplete="off"
                spellCheck="false"
              />{" "}
              %
            </div>
            <div className="col-2 text-right quote-sum-fin-lbl">
              Contingency Amt
            </div>
            <div className="col-4 text-left quote-sum-fin-text">
              ${" "}
              {/* {this.commaFormattedCurrency(
                this.totalLineItems() *
                  (parseFloat(this.state.contingentPer) / 100)
              )} */}
              {this.commaFormattedCurrency(
                this.totalLineItems() *
                  (parseFloat(this.state.contingentPer) / 100)
              )}
            </div>
          </div>
          <div className="row quote-sum-fin-row">
            <div className="col-2 text-right quote-sum-fin-lbl">
              Total Estimate
            </div>
            <div className="col-10 text-left quote-sum-fin-text">
              ${" "}
              {this.commaFormattedCurrency(
                this.totalLineItems() +
                  parseFloat(this.state.vatAmount) +
                  this.totalLineItems() *
                    (parseFloat(this.state.contingentPer) / 100)
              )}
            </div>
          </div>
          <div className="row quote-sum-fin-row">
            <div className="col-2 text-right quote-sum-fin-lbl">Retainer</div>
            <div className="col-4 text-left quote-sum-fin-text">
              ${" "}
              <input
                name="retainerPer"
                type="number"
                value={this.state.retainerPer}
                onChange={this.onChangeContingentPer}
                className="quote-vat-amount"
                step=".1"
                placeholder="0"
                autoComplete="off"
                spellCheck="false"
              />{" "}
              %
            </div>
            <div className="col-2 text-right quote-sum-fin-lbl">
              Retainer Amt
            </div>
            <div className="col-4 text-left quote-sum-fin-text">
              ${" "}
              {this.commaFormattedCurrency(
                (this.totalLineItems() +
                  this.totalLineItems() *
                    (parseFloat(this.state.contingentPer) / 100)) *
                  (parseFloat(this.state.retainerPer) / 100)
              )}
            </div>
          </div>
          {sumBottomPartPanel}
        </div>
      );
    }

    let outputPanel;
    if (this.state.showViewPanelFlag) {
      outputPanel = (
        <div>
          <ViewQuote
            returnToCaller={this.returnFromQuoteView}
            inputdata={this.state.packagedViewData}
          />
        </div>
      );
    }
    if (this.state.showQuoteMgmtFlag) {
      let showbuttonPanel = true;
      if (
        this.state.groupType === "lineitems" &&
        this.props.theTask.taskStatus === "active"
      )
        showbuttonPanel = false;
      if (
        this.state.groupType === "sumup" &&
        this.props.theTask.taskStatus === "active"
      )
        showbuttonPanel = false;
      outputPanel = (
        <div className="biz-task-quote-box">
          {quoteTopPanel}
          {lineItemTopPanel}
          {lineItemsListPanel}
          {sumupPanel}

          <div className="quote-lower-button-pos text-center">
            {showbuttonPanel ? buttonPanel : null}
          </div>
          <div
            className={
              this.state.errFlag
                ? "text-center quote-comp-msg-err"
                : "text-center quote-comp-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    if (this.state.showChangeOrderFlag) {
      outputPanel = (
        <div>
          <ChangeOrder
            theTask={this.state.theTask}
            communityId={this.props.communityId}
            community={this.props.community}
            returnToCaller={this.returnFromChageOrder}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

QuoteMgmt.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(QuoteMgmt)
);
