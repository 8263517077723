import React, { Component } from "react";

import "./PickupFilter.css";

class PickupFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "tobe",
      customerName: "",
      itemType: 'regular',
      invoiceId: 0
    };
  }

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeNumber = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 0) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      this.setState({
        [e.target.name]: val,
        message: "",
      });
    } else {
      this.setState({
        [e.target.name]: value,
        message: "",
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleExit = () => {
    let retObj = { ops: "exit", Msg: null };
    this.returnToShipping(retObj);
  };

  handleFilter = () => {
    let request = {
      status: this.state.status,
      customerName: this.state.customerName,
      itemType: this.state.itemType,
      invoiceId: this.state.invoiceId
    };
    this.returnToShipping({ ops: "filter", Msg: request });
  };

  returnToShipping = (retObj) => {
    this.props.returnToCaller(retObj);
  };

  handleSelectStatus = async (e) => {
    await this.setState({
      status: e.target.value,
    });
  };

  handleItemType = async (e) => {
    await this.setState({
      itemType: e.target.value,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('PickupFilter...');

    let exitButton = (
      <button
        onClick={this.handleExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
       <i className="fas fa-step-backward" />
      </button>
    );

    let filterButton = (
      <button
        onClick={this.handleFilter}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_60"
      >
        Filter 
      </button>
    );

    let selectItemType = (
        <div className="row ship-status-row-pos">
          <div className="col text-center radio-font-style">
            <strong>Select Item Type&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="regular"
                  checked={this.state.itemType === "regular"}
                  onChange={this.handleItemType}
                />{" "}
                Regular
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="special"
                  checked={this.state.itemType === "special"}
                  onChange={this.handleItemType}
                />{" "}
                Special
              </label>
            </div>
          </div>
        </div>
      );

      let selectStatus;
    // let selectStatus = (
    //   <div className="row ship-status-row-pos">
    //     <div className="col text-center radio-font-style">
    //       <strong>Select pickup status&nbsp;&nbsp;</strong>
    //       <div className="form-check form-check-inline">
    //         <label className="form-check-label">
    //           <input
    //             className="form-check-input"
    //             type="radio"
    //             value="tobe"
    //             checked={this.state.status === "tobe"}
    //             onChange={this.handleSelectStatus}
    //           />{" "}
    //           New pickup request
    //         </label>
    //       </div>
    //       <div className="form-check form-check-inline">
    //         <label className="form-check-label">
    //           <input
    //             className="form-check-input"
    //             type="radio"
    //             value="ready"
    //             checked={this.state.status === "ready"}
    //             onChange={this.handleSelectStatus}
    //           />{" "}
    //           Ready for pickup
    //         </label>
    //       </div>
    //     </div>
    //   </div>
    // );

    let outputPanel;
    outputPanel = (
      <div className="pickup-filter-box">
        <div className="text-center pickup-filter-header">
          Filter Pickup Data{" "}
        </div>
        {selectItemType}
        {selectStatus}
        <div className="row pickup-customer-pos">
            <div className="col-4 text-right search-lbl">Customer Name</div>
            <div className="col-8 text-left">
              <input
                name="customerName"
                type="text"
                value={this.state.customerName}
                onChange={this.onChange}
                size="14"
                maxLength="14"
                className="pickup-cust-name"
                placeholder=""
              />
            </div>
          </div>
          <div className="row pickup-customer-pos">
            <div className="col-4 text-right search-lbl">Invoice Id</div>
            <div className="col-8 text-left">
              <input
                name="invoiceId"
                type="number"
                value={this.state.invoiceId}
                onChange={this.onChangeNumber}
                size="14"
                maxLength="14"
                className="pickup-cust-name"
                placeholder=""
              />
            </div>
          </div>
          <div className="pickup-name-msg text-center">Enter full, part to filter, or blank for all names.</div>
          <div className="text-center pickup-button-pos">
          {exitButton} &nbsp;{filterButton}
        </div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default PickupFilter;
