import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";
import axios from "axios";

import "./ShipPickup.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";

const readMeCode = "1112200000";

class ShipPickup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      pickupFlag: false,

      spobj: null,

      shippingObj: null,
      selectedPickupAddr: null, 
      pickShipDecision: "ship",

      street1: "",
      street2: "", 
      city: "",
      state: "",
      zip: "",
      country: "USA",

      message: "",
      errFlag: false,
      decidePicShipFlag: false,
      selectedShipingPrice: null,

      hasSpecialShippingFlag: false,
      regularShippingFlag: false,
      hasPeriodicFlag: false,

      shippingPrices: [],
      freeShippingFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let pickupFlag = false;
    let hasSpecialShippingFlag = false;
    let regularShippingFlag = false;
    let hasPeriodicFlag = false;
    let typeOfPeriodicShipping = '';
    let noOfRegularItemsToShip = 0;
    let periodicShipData = null;

    this.props.itemsInCart.forEach((elm) => {
      // console.log("elm:", elm);
      if (elm.pickupAllowed === "allowed") pickupFlag = true;
      if (elm.specialShipping === "periodic") {
        if ( elm.periodicShipData ){
          hasPeriodicFlag = true;
          if (elm.periodicShipData.type === 'planned') {
            // regularShippingFlag = true;
            noOfRegularItemsToShip++;
            typeOfPeriodicShipping = 'planned';
          } else {
            typeOfPeriodicShipping = 'boxed';
          }
        } 
      }
      if (elm.specialShipping === "regular") {
        regularShippingFlag = true;
          noOfRegularItemsToShip++;
      }
      if (elm.specialShipping === "special") hasSpecialShippingFlag = true;
      if (elm.periodicShipData ) periodicShipData = elm.periodicShipData;
    });

    let decidePicShipFlag = false;
    if (
      this.props.community.pickupAddresses &&
      this.props.community.pickupAddresses.length > 0
    )
      decidePicShipFlag = true;

    if ( this.props.caller === 'sller' ) {
      decidePicShipFlag = true;
    }  

    // console.log('noOfRegularItemsToShip:', noOfRegularItemsToShip);
    let shippingCalc = this.getShippingPricesForThisCart();
    // console.log('shippingCalc:', shippingCalc);
    let shippingPrices = shippingCalc.shipOptions;
    let freeShippingFlag = shippingCalc.freeShippingFlag;
    if ( this.props.store && this.props.coopStoreId !== 0) {
      shippingPrices = [...this.props.store.shippingMeta.priceList ]
    } else {
      shippingPrices = [...this.props.community.shippingMeta.priceList]
    }

    this.setState({
      pickupFlag,
      deviceSize,
      decidePicShipFlag,
      regularShippingFlag,
      hasSpecialShippingFlag,
      noOfRegularItemsToShip,
      hasPeriodicFlag,
      typeOfPeriodicShipping,
      shippingPrices,
      periodicShipData,
      freeShippingFlag
    });
  };

  openAlertModal = () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getShippingPricesForThisCart = () => {
    // console.log('1 ---------------------------------')
    let smeta;
    if ( this.props.store && this.props.coopStoreId !== 0) {
      smeta = this.props.store.shippingMeta;
    } else {
      smeta = this.props.community.shippingMeta;
    }

    // console.log('1 smeta:', smeta);
    let freeShippingFlag = false;

    let shipItemsInCartP1 = [];
    let amountToShip = 0;
    let createShipOptions = false;
    let numberOfRegularItems = 0;

    this.props.itemsInCart.forEach(ic => {
      // console.log('ic.specialShipping:', ic.specialShipping);
      if ( ic.specialShipping === 'regular') {
        amountToShip += ic.price * ic.quantity;
        shipItemsInCartP1.push(ic);
        createShipOptions = true;
        numberOfRegularItems++;
      }
      if ( ic.specialShipping === 'periodic') {
        shipItemsInCartP1.push(ic);
      }
    });

    // console.log('numberOfRegularItems:', numberOfRegularItems);
    // console.log('createShipOptions:', createShipOptions);

    let shipOptions = [];
    if ( createShipOptions ) {
      smeta.priceList.forEach(sm => {
        console.log('sm: ', sm);
        if ( sm.itemRange === "1-2" &&  numberOfRegularItems <= 2) shipOptions.push(sm);
        if ( sm.itemRange === "3-5" &&  (numberOfRegularItems > 2 && numberOfRegularItems <= 5)) shipOptions.push(sm);
        if ( sm.itemRange === "6-8" &&  (numberOfRegularItems > 5 && numberOfRegularItems <= 8)) shipOptions.push(sm);
        if ( sm.itemRange === "9-12" &&  (numberOfRegularItems > 8 && numberOfRegularItems <= 12)) shipOptions.push(sm);
        if ( sm.itemRange === "12+" &&  numberOfRegularItems > 12 ) shipOptions.push(sm);
      });
    };

    // console.log('amountToShip:', amountToShip, ' smeta.shippingFreeAboveAmt:', smeta.shippingFreeAboveAmt, ' smeta.shippingFree:', smeta.shippingFree);
    if ( smeta.shippingFree && amountToShip >= smeta.shippingFreeAboveAmt) {
      // console.log("in here ... 186");
      freeShippingFlag = true;
      // shipOptions = [];
      // console.log('freeShippingFlag:', freeShippingFlag)
    }
     
    let retObj = {
      shipOptions,
      freeShippingFlag
    }
    // console.log('2 ---------------------------------retObj:', retObj)
    return retObj;
  }

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  handlePickShipDecision = async (e) => {
    // console.log('in handlePickShipDecision');
    let value = e.target.value;
    await this.setState({
      pickShipDecision: value,
    });
    // console.log('handlePickShipDecision ... e.target.value:', e.target.value);
    // console.log('this.props.community.centerOfActivityLocation.postalAddress.zip:', this.props.community.centerOfActivityLocation.postalAddress.zip);
 
    if ( value === 'spotdeliverd') {
      let spobj = {
        addrName: "",
        pickupDirection: "Direct handover.",
        street1: this.props.community.centerOfActivityLocation.postalAddress.line1,
        street2: this.props.community.centerOfActivityLocation.postalAddress.line2,
        city: this.props.community.centerOfActivityLocation.postalAddress.city,
        state: this.props.community.centerOfActivityLocation.postalAddress.state,
        zip: this.props.community.centerOfActivityLocation.postalAddress.zip,
        country: this.props.community.centerOfActivityLocation.postalAddress.country,
        pickShipDecision: this.state.pickShipDecision,
        hasSpecialShippingFlag: false,
        selectedShipingPrice: 0,
      };
      // console.log("handlePickShipDecision spobj:", spobj);
      this.props.returnToCaller(spobj);
    }

  };

  handleShipSave = async () => {
    // this.returnToCheckout();
    let chk = await this.checkAddress();
    // console.log("chk:", chk);
    if (chk.status === "success") {
      if (
        this.state.pickShipDecision === "ship" &&
        !this.state.selectedShipingPrice &&
        this.state.regularShippingFlag && 
        !this.state.freeShippingFlag
      ) {
        await this.setState({
          message: "To be shipped, must select a shipping price model",
          errFlag: true,
        });
      } else {
        let spobj = {
          addrName: "",
          pickupDirection: "",
          street1: this.state.street1,
          street2: this.state.street2, 
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip,
          country: "USA",
          phone: '',
          pickShipDecision: this.state.pickShipDecision,
          hasSpecialShippingFlag: this.state.hasSpecialShippingFlag,
          hasRegularShipping: this.state.regularShippingFlag,
          selectedShipingPrice: this.state.selectedShipingPrice,
          periodicShipData: this.state.periodicShipData,
          freeShippingFlag: this.state.freeShippingFlag,
        };
        // console.log("spobj:", spobj);
        this.props.returnToCaller(spobj);
      }
    } else {
      await this.setState({
        message: chk.Msg,
        errFlag: true,
      });
    }
  };

  checkAddress = async () => {
    let status = "success";
    let msg = "";

    if (
      this.state.street1 === "" ||
      this.state.city === "" ||
      this.state.zip === "" ||
      this.state.country === ""
    ) {
      status = "error";
      msg = "Must provide shipping address to be shipped";
    } else {
      let param =
        "street1=" +
        this.state.street1 +
        "&street2=" +
        this.state.street2 +
        "&city=" +
        this.state.city +
        "&zip=" +
        this.state.zip +
        "&country=" +
        this.state.country +
        "&company=" +
        this.props.communityName;
      let url = baandaServer + easypostAddressCheck + param;
      try {
        let check = await axios.get(url);
        // console.log("check:", check);
        if (check.data.status === "success") {
          // await this.setState({
          //   message: "Successfully Added",
          //   errFlag: false,
          // });
        } else {
          status = "error";
          msg = check.data.Msg;
        }
      } catch (err) {
        console.log("err:", err.message);
        status = "error";
        msg = err.message;
      }
    }

    return { status, Msg: msg };
  };

  selectThisAddress = async (padr) => {
    // console.log("pickaddr:", padr);
    let spobj = {
      addrName: padr.addrName,
      pickupDirection: padr.direction,
      street1: padr.street1,
      street2: padr.street2,
      city: padr.city,
      state: padr.state,
      zip: padr.zip,
      country: padr.country,
      pickShipDecision: this.state.pickShipDecision,
      selectedShipingPrice: this.state.selectedShipingPrice,
      message: "",
      errFlag: false,
    };
    // console.log("spobj:", spobj);
    this.props.returnToCaller(spobj);
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  selectThisPrice = async (obj) => {
    await this.setState({
      selectedShipingPrice: obj,
      message: "",
      errFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ShipPickup...');

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let saveButton;
    saveButton = (
      <button
        className="btn_reg_65"
        type="button"
        onClick={this.handleShipSave}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let handedover, pickuporship;
    if ( this.state.deviceSize === 'small') {
      handedover = "Hand It";
      pickuporship = "Pick-Ship";
    } else {
      handedover = "Hand Over";
      pickuporship = "Pickup or Ship"
    }

    let pickupDecisionPanel;
    if (this.state.pickupFlag) {
      if (this.state.decidePicShipFlag) {
        pickupDecisionPanel = (
          <div>
            <div className="row name-panel-row">
              <div className="col text-center radio-font-style">
                <strong>{pickuporship}&nbsp;&nbsp;</strong>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="ship"
                      checked={this.state.pickShipDecision === "ship"}
                      onChange={this.handlePickShipDecision}
                    />{" "}
                    Ship
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="pickup"
                      checked={this.state.pickShipDecision === "pickup"}
                      onChange={this.handlePickShipDecision}
                    />{" "}
                    Pickup
                  </label>
                </div>
                {this.props.caller === "seller" ? (
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="spotdeliverd"
                        checked={this.state.pickShipDecision === "spotdeliverd"}
                        onChange={this.handlePickShipDecision}
                      />{" "}
                      {handedover}
                    </label>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
      }
    }

    let pickupListPanel;
    let bool = true;
    pickupListPanel = (
      <div>
        <div className="fixedsize-chk-pickups">
          {this.props.community.pickupAddresses.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                  }
                >
                  <div className="col-1 text-right">
                    <button
                      className="btn-sel-checout-adr"
                      type="button"
                      onClick={() => this.selectThisAddress(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      <i className="fa fa-check" />
                    </button>
                  </div>
                  <div className="col-11 text-left chk-pick-list-lbl">
                    {obj.addrName} | {obj.street1}&nbsp;{obj.street2},&nbsp;
                    {obj.city}&nbsp;{obj.state},&nbsp;{obj.zip}&nbsp; |{" "}
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let activePanel;
    if (this.state.pickupFlag && this.state.pickShipDecision === "pickup") {
      if (this.state.decidePicShipFlag) {
        activePanel = <div>{pickupListPanel}</div>;
      } else {
        activePanel = (
          <div className="text-center chk-shippick-msg-err">
            No pick up addressed has been defined by the business. Request the{" "}
            {this.props.community.commName} adminstrator to set at least one
            pickup address.
          </div>
        );
      }
    }

    if (this.state.pickShipDecision === "ship") {
      activePanel = (
        <div>
          <div className="text-left cat-checkout-ship-target"><u>Ship to address</u></div>
          <div className="row addr-row">
            <div className="col-3 pick-addr-lbl text-right">Street</div>
            <div className="col-9 text-left">
              <input
                name="street1"
                type="text"
                value={this.state.street1}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="ship-st-1"
                placeholder=""
              />
            </div>
          </div>
          <div className="row addr-row">
            <div className="col-3 pick-addr-lbl text-right">Apt/Suit</div>
            <div className="col-9 text-left">
              <input
                name="street2"
                type="text"
                value={this.state.street2}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="ship-st-2"
                placeholder=""
              />
            </div>
          </div>
          <div className="row addr-row">
            <div className="col-3 pick-addr-lbl text-right">City</div>
            <div className="col-9 text-left">
              <input
                name="city"
                type="text"
                value={this.state.city}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="ship-city"
                placeholder=""
              />
            </div>
          </div>
          <div className="row addr-row">
            <div className="col-3 pick-addr-lbl text-right">State</div>
            <div className="col-9 text-left">
              <input
                name="state"
                type="text"
                value={this.state.state}
                onChange={this.onChange}
                size="2"
                maxLength="2"
                className="ship-state"
                placeholder=""
              />
            </div>
          </div>
          <div className="row addr-row">
            <div className="col-3 pick-addr-lbl text-right">Zip</div>
            <div className="col-9 text-left">
              <input
                name="zip"
                type="text"
                value={this.state.zip}
                onChange={this.onChange}
                size="10"
                maxLength="10"
                className="ship-zip"
                placeholder=""
              />
            </div>
          </div>
          <div className="row addr-row">
            <div className="col-3 pick-addr-lbl text-right">Country</div>
            <div className="col-9 text-left">
              <input
                name="country"
                type="text"
                value={this.state.country}
                onChange={this.onChange}
                size="25"
                maxLength="25"
                className="ship-zip"
                placeholder=""
              />
            </div>
          </div>
        </div>
      );
    }

    let bool1 = true;
    let shipPriceListPanel;
    if (
      this.state.pickShipDecision === "ship" &&
      this.state.regularShippingFlag
    ) {
      if ( this.state.freeShippingFlag) {
        shipPriceListPanel = (
          <div className="price-list-row-x">
            <div className="text-left checkout-inv-ship-type">
              Select a shipping type
            </div>
            <div className="fixedsize-chk-ship-price-list">
               <div className="text-center checkout-free-ship-msg">
                Your shipping is free.
               </div>
            </div>
            {this.state.hasPeriodicFlag ? (
              <div className="text-center shippick-hs-periodic-msg">Cart has periodic {this.state.typeOfPeriodicShipping} shipping too.</div>
            ) : null}
          </div>
        );
      } else {
        shipPriceListPanel = (
          <div className="price-list-row-x">
            <div className="text-left checkout-inv-ship-type">
              Select a shipping type
            </div>
            <div className="fixedsize-chk-ship-price-list">
              {this.state.shippingPrices.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        bool1
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col text-left chk-price-list-lbl chk-price-list-lbl">
                        <button
                          className="btn-check-select-ship-price"
                          type="button"
                          onClick={() => this.selectThisPrice(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <i className="fa fa-check" />
                        </button>
                        ${this.commaFormattedCurrency(parseFloat(obj.price))}
                        &nbsp;||&nbsp; {obj.description} || No of cart-items {obj.itemRange}
                      </div>
                    </div>
                    {(bool1 = !bool1)}
                  </div>
                );
              })}
            </div>
            {this.state.hasPeriodicFlag ? (
              <div className="text-center shippick-hs-periodic-msg">Cart has periodic {this.state.typeOfPeriodicShipping} shipping too.</div>
            ) : null}
          </div>
        );
      }
    }

    if (
      this.state.pickShipDecision === "ship" &&
      !this.state.regularShippingFlag &&
      this.state.hasPeriodicFlag
    ){
      shipPriceListPanel = (
        <div className="text-center shippick-hs-periodic-msg">Cart has periodic {this.state.typeOfPeriodicShipping} shipping only.</div>
      )
    }

    let outputPanel;
    outputPanel = (
      <div>
        <div className="text-center ship-pick-panel-header">
          Manage Shipping-Pickup { this.props.store ? "( " + this.props.store.displayStoreName + ")" : null} &nbsp;{infoButton}
        </div>
        {pickupDecisionPanel}
        {activePanel}
        {shipPriceListPanel}
        {this.state.selectedShipingPrice !== null ? (
          <div className="text-center chkout-selected-ship-price">
            Selected:&nbsp;{this.state.selectedShipingPrice.description}&nbsp;$
            {this.commaFormattedCurrency(this.state.selectedShipingPrice.price)}
          </div>
        ) : null}
        {this.state.hasSpecialShippingFlag ? (
          <div className="text-center special-shipping-msg">
            You have at least one special shipping item.
          </div>
        ) : null}
        <div className="text-center ship-pickup-note">
          Note: If you want to pickup some items and some shipped, purchase them
          separately.
        </div>
        <div className="text-center">{this.state.pickShipDecision === 'ship'? saveButton: null}</div>
        <div
          className={
            this.state.errFlag
              ? "text-center chk-shippick-msg-err"
              : "text-center chk-shippick-msg"
          }
        >
          {this.state.message}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}


ShipPickup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShipPickup)
);
// export default ShipPickup;
