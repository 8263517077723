import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./ManualShipping.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";
const saveShippingInvoice = "/routes/shipping/saveShippingInvoice";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1111020000";

class ManualShipping extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toName: "",
      toStreet: "",
      toStreet2: "",
      toCity: "",
      toZip: "",
      toState: "",
      toCountry: "USA",
      toPhone: '',

      fromName: "",
      fromStreet: "",
      fromStreet2: "",
      fromCity: "",
      fromZip: "",
      fromState: "",
      fromCountry: "USA",
      fromPhone: '',

      lineItem: "",

      confirmManShip: false,

      errFlag: false,
      errMsg: "",
    };
  }

  componentDidMount = () => {
    this.setState({
      fromName: this.props.auth.user.name,
      fromPhone: this.props.auth.user.cell.number
    });
  };

  openAlertModal = () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  returnToShipPagi = () => {
    this.props.returnToCaller();
  };

  handleConfirmShipInv = async () => {
    this.setState((prevstate) => ({
      confirmManShip: !prevstate.confirmManShip,
    }));
  };

  checkToAddress = async () => {
    let status = "success";
    let msg = "";

    if (
      this.state.toStreet === "" ||
      this.state.toCity === "" ||
      this.state.toZip === "" ||
      this.state.toCountry === ""
    ) {
      status = "error";
      msg = "Must provide shipping destination address";
    } else {
      let param =
        "street1=" +
        this.state.toStreet +
        "&street2=" +
        this.state.toStreet2 +
        "&city=" +
        this.state.toCity +
        "&zip=" +
        this.state.toZip +
        "&country=" +
        this.state.toCountry +
        "&company=" +
        this.props.toName;
      let url = baandaServer + easypostAddressCheck + param;
      // console.log("checkToAddress url:", url);
      try {
        let check = await axios.get(url);
        // console.log("check:", check);
        if (check.data.status === "error") {
          status = "error";
          msg = check.data.Msg;
        }
      } catch (err) {
        console.log("err:", err.message);
        status = "error";
        msg = err.message;
      }
    }

    return { status, Msg: msg };
  };

  checkFromAddress = async () => {
    let status = "success";
    let msg = "";

    if (
      this.state.fromStreet === "" ||
      this.state.fromCity === "" ||
      this.state.fromZip === "" ||
      this.state.fromCountry === ""
    ) {
      status = "error";
      msg = "Must provide shipping destination address";
    } else {
      let param =
        "street1=" +
        this.state.fromStreet +
        "&street2=" +
        this.state.fromStreet2 +
        "&city=" +
        this.state.fromCity +
        "&zip=" +
        this.state.fromZip +
        "&country=" +
        this.state.fromCountry +
        "&company=" +
        this.state.fromName;
      let url = baandaServer + easypostAddressCheck + param;
      // console.log("checkFromAddress url:", url);
      try {
        let check = await axios.get(url);
        // console.log("check:", check);
        if (check.data.status === "error") {
          status = "error";
          msg = check.data.Msg;
        }
      } catch (err) {
        console.log("err:", err.message);
        status = "error";
        msg = err.message;
      }
    }

    return { status, Msg: msg };
  };

  validateEntry = async () => {
    try {
      let checkTo = await this.checkToAddress();
      // console.log("checkTo:", checkTo);
      if (checkTo.status === "error") throw new Error(checkTo.Msg);

      let checkFrom = await this.checkFromAddress();
      if (checkFrom.status === "error") throw new Error(checkFrom.Msg);

      if (this.state.lineItem === "")
        throw new Error("Must provide a package description.");

      if (this.state.lineItem.length < 4)
        throw new Error("Package description must be 3 characters or more.");

      return { status: "success", Msg: "" };
    } catch (err) {
      return { status: "error", Msg: err.message };
    }
  };

  createInputData = () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let input = {
      communityId: this.props.communityId,
      coopStoreId,
      customerName: this.state.toName,
      invoiceOrigin: "manual",
      manualInvoiceParams: {
        billToAddress: {
          line1: this.state.fromStreet,
          city: this.state.fromCity,
          country: this.state.fromCountry,
          postalCode: this.state.fromZip,
          state: this.state.fromState,
        },
        dueDate: null,
        fineApr: 0,
        refId: 0,
        refType: "service",
        shipToAddress: {
          line1: this.state.toStreet,
          city: this.state.toCity,
          country: this.state.toCountry,
          postalCode: this.state.toZip,
          state: this.state.toState,
        },
      },
 
      deliveryData: {
        toAddress: {
          shipToName: this.state.toName,
          street1: this.state.toStreet,
          street2: "",
          city: this.state.toCity,
          country: this.state.toCountry,
          postalCode: this.state.toZip,
          state: this.state.toState,
          phone: this.state.toPhone,
        },
        fromAddress: {
          shipFromName: this.state.fromName,
          street1: this.state.fromStreet,
          street2: "",
          city: this.state.fromCity,
          country: this.state.fromCountry,
          postalCode: this.state.fromZip,
          state: this.state.fromState,
          phone: this.state.fromPhone,
        },
      },
      itemDetails: [
        { 
          cost: 0,
          itemId: 0,
          discount: 0,
          itemName: this.state.lineItem,
          itemType: 'Manual mail',
          price: 0,
          quantity: 1,
          tax: 0,
          unitName: 'none'
        },
      ],
      totalDeliveryStatus: "tobe",
      spRegularExists: true,
      spRegularStatus: "tobe",
      invoiceCategory: "manual-shipping",
      spInfo: {
        numberOfRegularPackages: 1,
        numberOfSpecialPackages: 0,
        regularItems: [
          {
            name: this.state.lineItem,
            itemId: 0,
          },
        ],
        specialItems: [],
        periodicItems: [],
        manualItems: [],
        regularPkg: [],
        specialPkg: [],
        otherPkg: {},
      },
      custBaandaId: this.props.auth.user.baandaId,
      customerCell: this.props.auth.user.cell.number
    };

    return input;
  };

  handleManualInvShip = async () => {
    let isValid = await this.validateEntry();
    if (isValid.status === "success") {
      try {
        let input = this.createInputData();
        let url = baandaServer + saveShippingInvoice;
        // console.log("input:", input, " url:", url);
        let ret = await axios.post(url, input);
        if (ret.data.status === "success") {
          // console.log("Created invoice ... returning to pagi");
          this.returnToShipPagi();
        } else {
          this.setState({
            errMsg: ret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ManualShipping...');

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );
    let backButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToShipPagi}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let SaveButton = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleManualInvShip}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let fromPanel;
    fromPanel = (
      <div>
        <div className="row text-left cat-checkout-ship-target">
          <div className="col-2 text-right">
          <u>From address</u>
          </div>
          <div className="col-9 text-left">
      
            <PhoneInput
                placeholder="Enter Cell number - sender"
                value={this.state.fromPhone}
                onChange={(fromPhone) => this.setState({ fromPhone })}
                defaultCountry="US"
                country="US"
                className="manual-phone-flag"
              />
          </div>
          <div className="col-1 text-left">&nbsp;</div>
           
        </div>
        <div className="row addr-row">
          <div className="col-3 ship-manual-lbl text-right">Sender Name</div>
          <div className="col-9 text-left">
            <input
              name="fromName"
              type="text"
              value={this.state.fromName}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="ship-st-1"
              placeholder=""
            />
          </div>
        </div>
        <div className="row addr-row">
          <div className="col-3 ship-manual-lbl text-right">Street</div>
          <div className="col-9 text-left">
            <input
              name="fromStreet"
              type="text"
              value={this.state.fromStreet}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="ship-st-1"
              placeholder=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <div className="row addr-row">
              <div className="col-3 ship-manual-lbl text-right">City</div>
              <div className="col-9 text-left">
                <input
                  name="fromCity"
                  type="text"
                  value={this.state.fromCity}
                  onChange={this.onChange}
                  size="50"
                  maxLength="50"
                  className="ship-man-city"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="row addr-row">
              <div className="col-3 ship-manual-lbl text-center">State</div>
              <div className="col-9 text-left">
                &nbsp;&nbsp;
                <input
                  name="fromState"
                  type="text"
                  value={this.state.fromState}
                  onChange={this.onChange}
                  size="2"
                  maxLength="2"
                  className="ship-man-state"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="row addr-row">
              <div className="col-3 ship-manual-lbl text-right">Zip</div>
              <div className="col-9 text-left">
                <input
                  name="fromZip"
                  type="text"
                  value={this.state.fromZip}
                  onChange={this.onChange}
                  size="10"
                  maxLength="10"
                  className="ship-man-zip"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="row addr-row">
              <div className="col-2 ship-manual-lbl text-left">Country</div>
              <div className="col-10 text-left">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  name="fromCountry"
                  type="text"
                  value={this.state.fromCountry}
                  onChange={this.onChange}
                  size="25"
                  maxLength="25"
                  className="ship-man-country"
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    let toPanel;
    toPanel = (
      <div className="to-manual-panel-pos">
        {/* <div className="text-left cat-checkout-ship-target">
          <u>To address</u>
        </div> */}
        <div className="row text-left cat-checkout-ship-target">
          <div className="col-2 text-right">
          <u>To address</u>
          </div>
          <div className="col-9 text-left">
      
            <PhoneInput
                placeholder="Enter Cell number - receiver"
                value={this.state.toPhone}
                onChange={(toPhone) => this.setState({ toPhone })}
                defaultCountry="US"
                country="US"
                className="manual-phone-flag"
              />
          </div>
          <div className="col-1 text-left">&nbsp;</div>
           
        </div>
        <div className="row addr-row">
          <div className="col-3 ship-manual-lbl text-right">Recipiant Name</div>
          <div className="col-9 text-left">
            <input
              name="toName"
              type="text"
              value={this.state.toName}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="ship-st-1"
              placeholder=""
            />
          </div>
        </div>
        <div className="row addr-row">
          <div className="col-3 ship-manual-lbl text-right">Street</div>
          <div className="col-9 text-left">
            <input
              name="toStreet"
              type="text"
              value={this.state.toStreet}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="ship-st-1"
              placeholder=""
            />
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <div className="row addr-row">
              <div className="col-3 ship-manual-lbl text-right">City</div>
              <div className="col-9 text-left">
                <input
                  name="toCity"
                  type="text"
                  value={this.state.toCity}
                  onChange={this.onChange}
                  size="50"
                  maxLength="50"
                  className="ship-man-city"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="row addr-row">
              <div className="col-3 ship-manual-lbl text-center">State</div>
              <div className="col-9 text-left">
                &nbsp;&nbsp;
                <input
                  name="toState"
                  type="text"
                  value={this.state.toState}
                  onChange={this.onChange}
                  size="2"
                  maxLength="2"
                  className="ship-man-state"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="col-2">
            <div className="row addr-row">
              <div className="col-3 ship-manual-lbl text-right">Zip</div>
              <div className="col-9 text-left">
                <input
                  name="toZip"
                  type="text"
                  value={this.state.toZip}
                  onChange={this.onChange}
                  size="10"
                  maxLength="10"
                  className="ship-man-zip"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="col-3">
            <div className="row addr-row">
              <div className="col-2 ship-manual-lbl text-left">Country</div>
              <div className="col-10 text-left">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  name="toCountry"
                  type="text"
                  value={this.state.toCountry}
                  onChange={this.onChange}
                  size="25"
                  maxLength="25"
                  className="ship-man-country"
                  placeholder=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    let lineItemPanel;

    lineItemPanel = (
      <div className="to-manual-panel-pos">
        <div className="row addr-row">
          <div className="col-3 ship-manual-lbl text-right">Describe Pkg</div>
          <div className="col-9 text-left">
            <input
              name="lineItem"
              type="text"
              value={this.state.lineItem}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="ship-man-lineitem"
              placeholder=""
            />
          </div>
        </div>
      </div>
    );

    let buttonPanel;
    buttonPanel = (
      <div className="row">
        <div className="col-6 text-right man-ship-confirm">
          <Checkbox
            checked={this.state.confirmManShip}
            onChange={this.handleConfirmShipInv}
          />{" "}
          Confirm to save ship-invoice
        </div>
        <div className="col-6 text-left">
          {this.state.confirmManShip ? SaveButton : null}
        </div>
      </div>
    );

    let outputPanel = (
      <div className="shipping-manual-box">
        <div className="row">
          <div className="col-6 text-right man-ship-header">
            Manual Shipping
          </div>
          <div className="col-6 text-left">
            {infoButton} {backButton}
          </div>
        </div>
        {fromPanel}
        <hr />
        {toPanel}
        <hr />
        {lineItemPanel}
        <hr />
        {buttonPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center manual-ship-msg-err"
              : "text-center manual-ship-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ManualShipping.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManualShipping)
);
