import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import moment from "moment";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./PublishBroadcast.css";

import ReceiveMoneyViaCC from "../../../../../../account/finance/communities/PayForCCPlatform";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getServicePrice = "/routes/create/getServicePrice?";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const getABroadcast = "/routes/interact/getABroadcast?";
const getBaandaDna = "/routes/admin/getBaandaDna?";

const publishBroadcastFee = process.env.REACT_APP_MARKETING_BASE_FEE;
const publishMarketingViaEmail = process.env.REACT_APP_MARKETING_VIA_EMAIL;
const publishMarketingViaEmailInc = process.env.REACT_APP_MARKETING_VIA_EMAIL_INC;

const publishViaSMS = process.env.REACT_APP_MARKETING_VIA_SMS;
const publishViaSMSInc = process.env.REACT_APP_MARKETING_VIA_SMS_INC;
const publishViaMMS = process.env.REACT_APP_MARKETING_VIA_MMS;
const publishViaMMSInc = process.env.REACT_APP_MARKETING_VIA_MMS_INC;

const publishMarketingViaBmailOnly = process.env.REACT_APP_MARKETING_VIA_BMAIL;
const publishMarketingViaBmailInc =   process.env.REACT_APP_MARKETING_VIA_BMAIL_INC;

const baandaCommunityId = process.env.REACT_APP_BAANDA_COMMUNITY_ID;
const agreementName = process.env.REACT_APP_BROADCAST_PUBLISH_AGREEMENT_NAME;

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1144400000";

class PublishBroadcast extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      mailCompositionOK: false,
      mmsCompositionOK: false,
      recipiantsOK: false,
      taggedCatalogOK: false,
      subjectOK: false,
      readinessOK: false,

      paymentReceived: false,
      broadcastFee: 0,

      bmailFlag: true,
      textFlag: true,
      goPublishFlag: false,
      agreementFlag: false,

      csFee: null,
      totalFee: 0,
      incrementalFee: 0,
      baseServiceFee: 0,

      baseServiceObj: null,
      baseIncrementalObj: null,
      basePublishObj: null,

      customerCommunity: null,
      baandaCommunity: null,
      takePaymentFlag: false,
      broadcastInfo: null,

      brdMsg: "",
      brdErrFlag: false,

      emailTargetType: "emailall",

      theBroadcast: null,

      emailFeeObj: null,
      emailFeeIncObj: null,
      bmailFeeObj: null,
      bmailFeeIncObj: null,
      textFeeObj: null,
      textFeeIncObj: null,

      basePublishingFee: 0,
      emailingFee: 0,
      emailingIncFee: 0,
      emailOverLimit: 0,

      textingFee: 0,
      textingIncFee: 0,
      textOverLimit: 0,

      bmailingFee: 0,
      bmailingIncFee: 0,
      bmailOverLimit: 0,

      discountPercent: 0,

      serviceId: 0,
      publishDoneFlag: false,

      thisAgreement: [],
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let customerCommunity = this.state.customerCommunity;
    let brdMsg = "",
      brdErrFlag = false;
    let cm = await this.getCommunityInfo(this.props.communityId);
    if (cm.status === "success") {
      customerCommunity = cm.Msg;
    } else {
      brdErrFlag = true;
      brdMsg = "Failed to get community Info. " + cm.Msg;
    }
    await this.getTheBroadcast();

    let textFlag = true;
    if (this.state.theBroadcast.mmsComposition.textCampaign === "no")
      textFlag = false;

    await this.setState({
      deviceSize,
      broadcastVia: "bmail",
      customerCommunity,
      brdErrFlag,
      brdMsg,
      textFlag,
    });

    await this.calcBroacastReadiness();
  };

  openAlertModal = async () => {
    console.log("in openAlertModal info:", readMeCode);
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getBaandaDNA = async () => {
    let url = baandaServer + getBaandaDna;
    console.log("getBaandaDNA url:", url);
    try {
      let agrret = await axios.get(url);
      console.log("agrret:", agrret);
      if (agrret.data.status === "success") {
        console.log("in here 4444");
        let agree = [];
        agrret.data.Msg.agreements.forEach((obj) => {
          console.log("89 obj:", obj.agreementName, " - ", agreementName);
          if (obj.agreementName === agreementName) {
            agree = [...obj.agreementLines];
          }
        });
        console.log("agree:", agree);
        await this.setState({
          thisAgreement: agree,
          errMsg: "",
        });
      } else {
        this.setState({
          errMsg: agrret.data.Msg,
        });
      }
    } catch (err) {
      console.log("getBaandaDNA err:", err.message);
      this.setState({
        errMsg: err.message,
      });
    }
  };

  getTheBroadcast = async () => {
    let param = "broadcastId=" + this.props.theBroadcast.broadcastId;
    let url = baandaServer + getABroadcast + param;
    try {
      let brdret = await axios.get(url);
      if (brdret.data.status === "success") {
        await this.setState({
          theBroadcast: brdret.data.Msg[0],
        });
      } else {
        await this.setState({
          brdMsg: brdret.data.Msg,
          brdErrFlag: true,
        });
      }
    } catch (err) {
      console.log("getTheBroadcast Err:", err.message);
    }
  };

  getCommunityInfo = async (communityId) => {
    let parms = "communityId=" + communityId;
    let url = baandaServer + getCommunityInfo + parms;
    // console.log("url:", url);
    let output;
    try {
      let retData = await axios.get(url);
      // console.log("PublishBroadcast retData: ", retData);
      // await this.setState({
      //   community: retData.data.joiningProcess,
      // });
      output = { status: "success", Msg: retData.data.Msg };
    } catch (err) {
      console.log("getCommunityInfo err:", err.message);
      output = { status: "error", Msg: err.message };
    }

    return output;
  };

  calcBroacastReadiness = async () => {
    let mailCompositionOK = true,
      mmsCompositionOK = true,
      recipiantsOK = false,
      taggedCatalogOK = true,
      subjectOK = true,
      readinessOK = true;

    let tbm = null; // mail cmposition
    let tbr = null; // recipiants
    let tbmc = null; // mms cmposition
    let tbt = null; // tagged
    let subject = null; // Subject has been defined
    let tb = this.state.theBroadcast;
    // console.log("----- tb:", tb);
    if (!tb || tb === null) {
      mailCompositionOK = false;
      mmsCompositionOK = false;
      recipiantsOK = false;
      taggedCatalogOK = false;
      subjectOK = false;
      readinessOK = false;
    } else {
      tbm = tb.mailComposition;
      tbr = tb.recipiants;
      tbmc = tb.mmsComposition;
      subject = tb.letterSubject;
    }

    // console.log("subject:", subject);

    if (tbm) {
      if (tbm.banner.bannerStatement === "") mailCompositionOK = false;
      if (tbm.writeup.writeupOne === "") mailCompositionOK = false;
      if (tbm.template === "") mailCompositionOK = false;
    }

    if (tbr) {
      console.log("in here .... 111 tbr:", tbr);
      if (
        tbr.type === "group" ||
        tbr.type === "customer" ||
        tbr.type === "community"
      ) {
        recipiantsOK = true;
      }
    }

    if (tbmc) {
      if (tbmc.textCampaign === "yes" && tbmc.smsText === "")
        mmsCompositionOK = false;
    }

    if (tbt) {
      if (tbt.domainRefId === 0) taggedCatalogOK = false;
    }

    if (subject === "") subjectOK = false;

    if (
      this.state.emailTargetType === "emailall" ||
      this.state.emailTargetType === "emailnonmembers" ||
      this.state.bmailFlag
    ) {
      if (!subjectOK) readinessOK = false;
      if (!mailCompositionOK) readinessOK = false;
    }

    if (!recipiantsOK) readinessOK = false;

    if (this.state.textFlag) {
      if (!mmsCompositionOK) readinessOK = false;
    }

    await this.setState({
      mailCompositionOK,
      mmsCompositionOK,
      recipiantsOK,
      taggedCatalogOK,
      subjectOK,
      readinessOK,
      // csFee,
      // baseServiceObj: serviceFeeObj,
    });
  };

  getServiceFee = async (servicename) => {
    let communityId = 0;

    let serviceObj = null;
    let isOk = "success";

    let param =
      "intent=" +
      this.state.customerCommunity.intent +
      "&focus=" +
      this.state.customerCommunity.intentFocus +
      "&communityId=" +
      communityId +
      "&serviceName=" +
      servicename;

    let url = baandaServer + getServicePrice + param;
    console.log("getServiceFee url:", url);
    try {
      let serPrice = await axios.get(url);
      console.log("serPrice:", serPrice);
      if (serPrice.data.status === "success" && serPrice.data.Msg) {
        serviceObj = serPrice.data.Msg;
        await this.setState({
          serviceId: serPrice.data.Msg.serviceId,
        });
      } else {
        // console.log("Pricing has not been set");
        isOk = "error";
      }
    } catch (err) {
      console.log("getServiceFee Err:", err.message);
      isOk = "error";
    }

    return { status: isOk, Msg: serviceObj };
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleBmailSelect = async () => {
    // console.log(
    //   "handleBmailSelect this.state.emailTargetType:",
    //   this.state.emailTargetType
    // );
    if (this.state.emailTargetType === "noemail") {
      // console.log("here 111");
      await this.setState((prevstate) => ({
        bmailFlag: !prevstate.bmailFlag,
        goPublishFlag: false,
        brdMsg: "",
      }));
    }
  };

  handleTextSelect = async () => {
    await this.setState((prevstate) => ({
      textFlag: !prevstate.textFlag,
      goPublishFlag: false,
      brdMsg: "",
    }));

    await this.calcBroacastReadiness();
  };

  handleEmailTargetType = async (e) => {
    let value = e.target.value;
    let bmailFlag = this.state.bmailFlag;
    if (value !== "noemail") bmailFlag = true;
    await this.setState({
      emailTargetType: e.target.value,
      bmailFlag,
      goPublishFlag: false,
      brdMsg: "",
    });
  };

  handleAgreementSelect = async () => {
    await this.setState((prevstate) => ({
      agreementFlag: !prevstate.agreementFlag,
    }));
  };

  calculatePublishFee = async () => {
    let noOfRecipiant =
      this.state.theBroadcast.recipiants.targetGroupMembers.length;

    let basePublishObj = null;
    let emailFeeObj = this.state.emailFeeObj;
    let emailFeeIncObj = this.state.emailFeeIncObj;
    let bmailFeeObj = this.state.bmailFeeObj;
    let bmailFeeIncObj = this.state.bmailFeeIncObj;
    let textFeeObj = this.state.textFeeObj;
    let textFeeIncObj = this.state.textFeeIncObj;

    // 1. base Email prices (publishMarketingViaEmail)
    let basePublishingFee = this.state.basePublishingFee;
    let emailingFee = this.state.emailingFee;
    let emailingIncFee = this.state.emailingIncFee;
    let emailOverLimit = this.state.emailOverLimit;

    let textingFee = this.state.textingFee;
    let textingIncFee = this.state.textingIncFee;
    let textOverLimit = this.state.textOverLimit;

    let bmailingFee = this.state.bmailingFee;
    let bmailingIncFee = this.state.bmailingIncFee;
    let bmailOverLimit = this.state.bmailOverLimit;

    let discountPercent = 0;

    let baseobj = await this.getServiceFee(publishBroadcastFee);
    console.log(">>>>>>>>>>>>> baseobj:", baseobj);
    if (baseobj.status === "success") {
      basePublishingFee = baseobj.Msg.creditsCharged;
      basePublishObj = baseobj.Msg;
    }

    if (this.state.emailTargetType !== "noemail") {
      // Invoke database once only.
      if (this.state.emailFeeObj === null) {
        let emailobj = await this.getServiceFee(publishMarketingViaEmail);
        if (emailobj.status === "success") {
          emailFeeObj = emailobj.Msg;
          emailingFee = emailobj.Msg.creditsCharged;
        }
      }

      // 1a. incrementalEmail prices (publishMarketingViaEmailInc)(if noOfRecipiant > baseEmail blocksize)
      if (emailFeeObj && emailFeeObj.blocksize > noOfRecipiant) {
        if (this.state.emailFeeIncObj === null) {
          let eobjinc = await this.getServiceFee(publishMarketingViaEmailInc);
          if (eobjinc.status === "success") {
            emailFeeIncObj = eobjinc.Msg;
            emailOverLimit = noOfRecipiant - parseFloat(emailFeeObj.blockSize);

            let multiplier = Math.ceil(
              emailOverLimit / parseFloat(emailFeeIncObj.blockSize)
            );
            emailingIncFee =
              parseFloat(emailFeeIncObj.creditsCharged) * multiplier;
          }
        }
      }
    } else {
      emailingFee = 0;
      emailingIncFee = 0;
    }
    // 2.  base Text prices (publishMarketingViaText)
    if (this.state.textFlag) {
      console.log(
        "this.state.theBroadcast.mmsComposition:",
        this.state.theBroadcast.mmsComposition
      );
      // Invoke database once only.
      let textobj;
      if (this.state.theBroadcast.mmsComposition.mmsDAType === "none") {
        textobj = await this.getServiceFee(publishViaSMS);
        if (textobj.status === "success") {
          textFeeObj = textobj.Msg;
          textingFee = textobj.Msg.creditsCharged;
        }
      } else {
        textobj = await this.getServiceFee(publishViaMMS);
        if (textobj.status === "success") {
          textFeeObj = textobj.Msg;
          textingFee = textobj.Msg.creditsCharged;
        }
      }
      // 2a. incrementalText prices (publishMarketingViaTextInc)(if noOfRecipiant > baseEmail blocksize)
      if (textFeeObj && textFeeObj.blocksize > noOfRecipiant) {
        // if (this.state.textFeeIncObj === null) {
        let tobjinc;
        if (this.state.theBroadcast.mmsComposition.mmsDAType === "none") {
          tobjinc = await this.getServiceFee(publishViaSMSInc);
        } else {
          tobjinc = await this.getServiceFee(publishViaMMSInc);
        }

        if (tobjinc.status === "success") {
          textFeeIncObj = tobjinc.Msg;
          textOverLimit = noOfRecipiant - parseFloat(textFeeObj.blockSize);
          let multiplier = Math.ceil(
            textOverLimit / parseFloat(textFeeIncObj.blockSize)
          );
          textingIncFee = parseFloat(textFeeIncObj.creditsCharged) * multiplier;
        }
        // }
      }
    } else {
      textingFee = 0;
      textingIncFee = 0;
    }

    // 3. bmail (publishMarketingViaBmailOnly)
    if (this.state.bmailFlag) {
      // Invoke database once only.
      // if (this.state.bmailFeeObj === null) {
      let bmailobj = await this.getServiceFee(publishMarketingViaBmailOnly);
      if (bmailobj.status === "success") {
        bmailFeeObj = bmailobj.Msg;
        bmailingFee = bmailobj.Msg.creditsCharged;
      }
      // }
      // 3a. incrementalText prices (publishMarketingViaTextInc)(if noOfRecipiant > baseEmail blocksize)
      if (bmailFeeObj && bmailFeeObj.blocksize > noOfRecipiant) {
        if (this.state.bmailFeeIncObj === null) {
          let bobjinc = await this.getServiceFee(publishMarketingViaBmailInc);
          if (bobjinc.status === "success") {
            bmailFeeIncObj = bobjinc.Msg;
            bmailOverLimit = noOfRecipiant - parseFloat(bmailFeeObj.blockSize);
            let multiplier = Math.ceil(
              bmailOverLimit / parseFloat(bmailFeeIncObj.blockSize)
            );
            bmailingIncFee =
              parseFloat(bmailFeeIncObj.creditsCharged) * multiplier;
          }
        }
      }
    } else {
      bmailingFee = 0;
      bmailingIncFee = 0;
    }

    if (
      this.state.customerCommunity.discounts &&
      this.state.customerCommunity.discounts.serviceFeeDisountTill
    ) {
      if (
        moment().isBefore(
          moment(this.state.customerCommunity.discounts.serviceFeeDisountTill)
        )
      ) {
        discountPercent =
          this.state.customerCommunity.discounts.serviceFeeDisount; // This is in percent
      }
    } else {
      discountPercent = 5; // In percent for testing ...
    }

    let brdMsg = "";
    if (
      this.state.emailTargetType === "noemail" &&
      !this.state.bmailFlag &&
      !this.state.textFlag
    ) {
      brdMsg = "You have no target to broadcast.";
    }

    await this.setState({
      basePublishingFee,
      basePublishObj,
      emailFeeObj,
      emailFeeIncObj,
      bmailFeeObj,
      bmailFeeIncObj,
      textFeeObj,
      textFeeIncObj,
      emailingFee,
      emailingIncFee,
      emailOverLimit,
      textingFee,
      textingIncFee,
      textOverLimit,
      bmailingFee,
      bmailingIncFee,
      bmailOverLimit,
      discountPercent,
      brdMsg,
    });
  };

  publishBroadcast = async () => {
    await this.calculatePublishFee();
    let goPublishFlag = true;
    if (
      this.state.emailTargetType === "noemail" &&
      !this.state.bmailFlag &&
      !this.state.textFlag
    ) {
      goPublishFlag = false;
    }
    await this.setState({
      goPublishFlag,
    });
  };

  exitPublishBroadcast = async () => {
    await this.setState({
      goPublishFlag: false,
    });
  };

  postBrodcastPublication = async () => {
    // console.log("in postBrodcastPublication ...");
    // Get baanda community for baanda stripe Id
    let broadcastInfo = null;
    let baandaCommunity;
    let brdMsg = "",
      brdErrFlag = false;
    let takePaymentFlag = true;
    let totalPreDiscountFee =
      parseFloat(this.state.basePublishingFee) +
      parseFloat(this.state.emailingFee) +
      parseFloat(this.state.emailingIncFee) +
      parseFloat(this.state.textingFee) +
      parseFloat(this.state.textingIncFee) +
      parseFloat(this.state.bmailingFee) +
      parseFloat(this.state.bmailingIncFee);
    // console.log('totalPreDiscountFee:', totalPreDiscountFee);
    let discountAmount =
      totalPreDiscountFee * (this.state.discountPercent / 100);
    // console.log('discountAmount:', discountAmount);
    let totalFee = totalPreDiscountFee - discountAmount;
    // console.log('totalFee:', totalFee);

    let bc = await this.getCommunityInfo(baandaCommunityId);
    // console.log("baanda community bc:", bc);
    // console.log("bc: ", bc);

    if (bc && bc.status === "success") {
      console.log("in here 4444");
      baandaCommunity = bc.Msg;
      broadcastInfo = {
        broadcastId: this.state.theBroadcast.broadcastId,
        theBroadcast: this.state.theBroadcast,
        StripeAccId: baandaCommunity.stripe.id,
        broadcastFee: totalFee,
        paymentAmount: totalFee,
        callingFor: "finalizepublish",
        bmailFlag: this.state.bmailFlag,
        emailTargetType: this.state.emailTargetType,
        textFlag: this.state.textFlag,
        targetAudience: this.state.theBroadcast.recipiants,
        publisherEmail: this.props.auth.user.email,
        publisherBaandaId: this.props.auth.user.baandaId,
        publisherName: this.props.auth.user.name,
        publisherCommunityId: this.props.communityId,
        publisherCommunityName: this.props.communityName,
        baandaCommunityId: parseFloat(baandaCommunityId),
        transactionFor: "broadcast",
        serviceId: this.state.serviceId,
        // communityMembers: this.state.customerCommunity.members,
        description:
          "Service Fee for the community " +
          this.props.communityName +
          " broadcasting " +
          this.state.theBroadcast.broadcastName +
          ".",
        paymentInstitution: "stripe", // When we accomoadate crypto, it may be coinpayments
        clientProgram: "PublishEvent.js",
        clientFunction: "handlePublishEvent",
      };
    } else {
      brdMsg = "Baanda community info not found. " + bc.Msg;
      brdErrFlag = true;
      takePaymentFlag = false;
    }
    console.log("broadcastInfo:", broadcastInfo);

    // Switch on the get
    await this.setState({
      baandaCommunity,
      takePaymentFlag,
      broadcastInfo,
      brdMsg,
      brdErrFlag,
    });
  };

  handleBackFromPayment = async (data) => {
    console.log("handleBackFromPayment data:", data);
    // we flip back here.
    let msg = "";
    let publishErrFlag = false;
    let publishDoneFlag = false;
    if (data === "success") {
      msg = "Payment received and receipt in mail. Publishing asynchronously. Check outcome few minutes later.";
      publishDoneFlag = true;
    } else if (data === "error") {
      msg =
        "Error occured while publishing. Send mail to support@baanda.com with broadcastId = " + this.props.theBroadcast.broadcastId;
      publishErrFlag = true;
    } else {
      msg = "Canceled payment. Community not published.";
    }
    await this.setState({
      takePaymentFlag: false,
      goPublishFlag: false,
      publishMsg: msg,
      publishErrFlag,
      publishDoneFlag,
    });
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    let broadcastName = "";
    if (this.state.theBroadcast)
      broadcastName = this.state.theBroadcast.broadcastName;

    let infoModalButton = (
      <button
        className="btn_modal_cal_event"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let emailOptionSelect = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Target Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="emailall"
                checked={this.state.emailTargetType === "emailall"}
                onChange={this.handleEmailTargetType}
              />{" "}
              Email All
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="emailnonmembers"
                checked={this.state.emailTargetType === "emailnonmembers"}
                onChange={this.handleEmailTargetType}
              />{" "}
              Email Non-members
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="noemail"
                checked={this.state.emailTargetType === "noemail"}
                onChange={this.handleEmailTargetType}
              />{" "}
              No Email
            </label>
          </div>
          {this.state.theBroadcast &&
          this.state.theBroadcast.mmsComposition.textCampaign ===
            "no" ? null : (
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <Checkbox
                  checked={this.state.textFlag}
                  onChange={this.handleTextSelect}
                />
                &nbsp;Text
              </label>
            </div>
          )}

          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <Checkbox
                checked={this.state.bmailFlag}
                onChange={this.handleBmailSelect}
              />
              &nbsp;Bmail
            </label>
          </div>
        </div>
      </div>
    );

    let readinessPanel;
    readinessPanel = (
      <div>
        <div className="readiness-header">Readiness Checks</div>
        <div className="readiness-check-box text-left">
          <ul>
            <li>
              Mail Composition:&nbsp;
              {this.state.mailCompositionOK ? (
                <font color="green">
                  {" "}
                  <i className="fa fa-check" />
                </font>
              ) : (
                <font color="red">
                  {" "}
                  <i className="fa fa-times" />
                </font>
              )}
            </li>
            {this.props.theBroadcast.mmsComposition.textCampaign === "yes" ? (
              <li>
                MMS Composition:&nbsp;
                {this.state.mmsCompositionOK ? (
                  <font color="green">
                    {" "}
                    <i className="fa fa-check" />
                  </font>
                ) : (
                  <font color="red">
                    {" "}
                    <i className="fa fa-times" />
                  </font>
                )}
              </li>
            ) : null}

            <li>
              Target Recipiant Defined:&nbsp;
              {this.state.recipiantsOK ? (
                <font color="green">
                  {" "}
                  <i className="fa fa-check" />
                </font>
              ) : (
                <font color="red">
                  {" "}
                  <i className="fa fa-times" />
                </font>
              )}
            </li>
            <li>
              Cataloged Tagged:&nbsp;
              {this.state.taggedCatalogOK ? (
                <font color="green">
                  {" "}
                  <i className="fa fa-check" />
                </font>
              ) : (
                <font color="red">
                  {" "}
                  <i className="fa fa-times" />
                </font>
              )}
            </li>
            <li>
              Subject of the Broadcast:&nbsp;
              {this.state.subjectOK ? (
                <font color="green">
                  {" "}
                  <i className="fa fa-check" />
                </font>
              ) : (
                <font color="red">
                  {" "}
                  <i className="fa fa-times" />
                </font>
              )}
            </li>
            <li>
              Ready to Broadcast:&nbsp;
              {this.state.readinessOK ? (
                <font color="green">
                  {" "}
                  <i className="fa fa-check" />
                </font>
              ) : (
                <font color="red">
                  {" "}
                  <i className="fa fa-times" />
                </font>
              )}
            </li>
          </ul>
        </div>
      </div>
    );

    let publishPanel;
    publishPanel = (
      <div>
        {this.state.publishDoneFlag ? (
          <div className="text-center brd-publish-msg">
            {this.state.publishMsg}
          </div>
        ) : (
          <div className="text-center brd-publish-msg">
            <p>The quality of the broadcast is based on your imagiantion.</p>
          </div>
        )}

        <div className="text-center brd-publish-btn-placement">
          {this.state.readinessOK && !this.state.publishDoneFlag ? (
            <button
              className="btn_brd_publish_broadcast"
              type="button"
              onClick={this.publishBroadcast}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Publish
            </button>
          ) : (
            <div>
              {this.state.publishDoneFlag ? null : (
                <font color="red">
                  Ready to Broadcast must be green to publish.
                </font>
              )}
            </div>
          )}
        </div>
      </div>
    );

    let goPublishPanelOpsButtonPanel;
    goPublishPanelOpsButtonPanel = (
      <div className="text-center go-ops-pub-buttons">
        <Checkbox
          checked={this.state.agreementFlag}
          onChange={this.handleAgreementSelect}
        />{" "}
        I agree&nbsp;&nbsp;
        {/* {infoModalButton} */}
        <button
          className="btn_brd_ops_exit"
          type="button"
          onClick={this.exitPublishBroadcast}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          X
        </button>
        {this.state.agreementFlag ? (
          <button
            className="btn_brd_ops_paypost"
            type="button"
            onClick={this.postBrodcastPublication}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Pay & Post
          </button>
        ) : (
          <button
            className="btn_brd_ops_paypost-disabled"
            type="button"
            disabled
            // onClick={this.postBrodcastPublication}
            // style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Pay & Post
          </button>
        )}
      </div>
    );

    let broadcastAgreementPanel;
    if (this.state.thisAgreement.length > 0) {
      broadcastAgreementPanel = (
        <div>
          {this.state.thisAgreement.map((post, i) => (
            <div key={i}>
              <div className="comm-create-pub-lines">
                {post.seqno}.&nbsp; {post.text}
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      broadcastAgreementPanel = (
        <div className="text-center brd-agreement-err">
          Request Baanda Mgmt. to define this agreements for the agrrement name
          : {agreementName}
        </div>
      );
    }

    let goPublishPanel;
    if (this.state.goPublishFlag) {
      let totalPreDiscount =
        parseFloat(this.state.basePublishingFee) +
        parseFloat(this.state.emailingFee) +
        parseFloat(this.state.emailingIncFee) +
        parseFloat(this.state.textingFee) +
        parseFloat(this.state.textingIncFee) +
        parseFloat(this.state.bmailingFee) +
        parseFloat(this.state.bmailingIncFee);
      console.log("totalPreDiscount: ", totalPreDiscount);
      let discountAmount =
        totalPreDiscount * (parseFloat(this.state.discountPercent) / 100);
      let totalBroadcastFee = totalPreDiscount - discountAmount;
      console.log(
        "discountAmount:",
        discountAmount,
        " totalBroadcastFee:",
        totalBroadcastFee
      );
      goPublishPanel = (
        <div className="show-brd-go-publish-box">
          <div className="row">
            <div className="col-md-6 show-pub-agreement-box text-right">
              <p align="justify">
                Please read the agreement below and consent to the agreement to
                proceed:
              </p>
              {broadcastAgreementPanel}
            </div>
            <div className="col-md-6 brd-pub-pricing">
              <div className="row">
                <div className="col text-left">
                  Base Fee:$&nbsp;
                  <b>
                    {this.commaFormattedCurrency(
                      parseFloat(this.state.basePublishingFee)
                    )}
                  </b>
                </div>
              </div>
              <div className="row">
                <div className="col text-left">
                  Email Price:$&nbsp;
                  <b>
                    {this.commaFormattedCurrency(
                      parseFloat(this.state.emailingFee)
                    )}
                  </b>
                </div>
              </div>
              <div className="row">
                <div className="col text-left">
                  Total Recipiant:&nbsp;
                  <b>
                    {
                      this.state.theBroadcast.recipiants.targetGroupMembers
                        .length
                    }
                  </b>
                </div>
              </div>
              {this.state.emailingIncFee !== 0 ? (
                <div className="row">
                  <div className="col text-left">
                    (E) Over by {this.state.emailOverLimit}:$&nbsp;
                    <b>
                      {this.commaFormattedCurrency(
                        parseFloat(this.state.emailingIncFee)
                      )}
                    </b>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col text-left">(E) Over limit Fee: 0.00</div>
                </div>
              )}
              <div className="row">
                <div className="col text-left">
                  Texting Price:$&nbsp;
                  <b>
                    {this.commaFormattedCurrency(
                      parseFloat(this.state.textingFee)
                    )}
                  </b>
                </div>
              </div>
              {this.state.textingIncFee !== 0 ? (
                <div className="row">
                  <div className="col text-left">
                    (T) Over by {this.state.textOverLimit}:$&nbsp;
                    <b>
                      {this.commaFormattedCurrency(
                        parseFloat(this.state.textingIncFee)
                      )}
                    </b>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col text-left">(T) Over limit Fee: 0.00</div>
                </div>
              )}

              <div className="row">
                <div className="col text-left">
                  Bmail Price:$&nbsp;
                  <b>
                    {this.commaFormattedCurrency(
                      parseFloat(this.state.bmailingFee)
                    )}
                  </b>
                </div>
              </div>
              {this.state.bmailingIncFee !== 0 ? (
                <div className="row">
                  <div className="col text-left">
                    (B) Over by {this.state.bmailOverLimit}:$&nbsp;
                    <b>
                      {this.commaFormattedCurrency(
                        parseFloat(this.state.bmailingIncFee)
                      )}
                    </b>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col text-left">(B) Over limit Fee: 0.00</div>
                </div>
              )}
              {this.state.discountPercent > 0 ? (
                <div className="row">
                  <div className="col text-left">
                    Discount :&nbsp;
                    <b>{this.state.discountPercent} %</b>
                  </div>
                </div>
              ) : null}

              <div className="text-left">-----------------------</div>
              <div className="row">
                <div className="col text-left">
                  Total Fee:$&nbsp;
                  <b>{this.commaFormattedCurrency(totalBroadcastFee)}</b>
                </div>
              </div>
              <div className="row">
                <div className="col text-center brd-publish-pay-button">
                  {goPublishPanelOpsButtonPanel}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        <div className="brd-publish-header text-center">
          Publish the Broadcast &nbsp;&nbsp;{infoModalButton}
        </div>
        <div className="text-center brd-publish-name-header">
          Name: <b> {broadcastName}</b>
        </div>
        {/* <div className="row">
          <div className="col">{selectionPanel}</div>
        </div> */}
        <div className="row">
          <div className="col">{emailOptionSelect}</div>
        </div>
        <div className="row">
          <div className="col">{readinessPanel}</div>
        </div>
        <div className="row">
          <div className="col">{publishPanel}</div>
        </div>
        {goPublishPanel}
        <div className="text-center no-target-brd-msg">{this.state.brdMsg}</div>
      </div>
    );

    if (this.state.takePaymentFlag) {
      outputPanel = (
        <div>
          <ReceiveMoneyViaCC
            inputData={this.state.broadcastInfo}
            deviceSize={this.state.deviceSize}
            paymentAmount={parseFloat(this.state.totalFee)}
            handleExit={this.handleBackFromPayment}
            caller="broadcast"
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

PublishBroadcast.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PublishBroadcast));
