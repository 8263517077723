import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import PresentMaps from "../../../../../community/PresentMaps";

import "./AStoreLanding.css";

import advLargeNA from "../../../../../images/advLargeNA.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getProfile = "/routes/myaccount/getProfile?";

class AStoreLanding extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      creatorSelfi: "",
      storePromoImage: "",
      storePromoVideo: "",
      storeAdvLarge: "",
      adImageSmall: "",

      errMsg: "",
      errFlag: false,

      creatorVerbose: "Creators",
      communityVerbose: "Community",

      profile: null,

      showImage: true,
      showVideo: false,
      showMap: false,
      videoExists: false,
      imageExists: true,
      communityLocation: null,
    };
  }

  componentDidMount = async () => {
    // console.log("AStoreLanding componentDidMount ...");
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getUserProfile();

    let communityLocation;
    if (this.props.store.displayAddress) {
      communityLocation = {
        postalAddress: {
          line1: this.props.store.displayAddress.street,
          city: this.props.store.displayAddress.city,
          state: this.props.store.displayAddress.state,
          country: this.props.store.displayAddress.country,
          postalAddress: this.props.store.displayAddress.postalCode,
        },
      };
    } else {
      communityLocation = {
        postalAddress: {
          line1: "No street address",
          city: "No city",
          state: "NA",
          country: "USA",
          postalAddress: "00000",
        },
      };
    }

    if (this.props.store) {
      await this.setDAForDisplay();
    }

    await this.setState({
      deviceSize,
      communityLocation,
    });
  };

  getUserProfile = async () => {
    let params = "email=" + this.props.auth.user.email;
    let url = baandaServer + getProfile + params;
    // console.log("url:", url);
    try {
      let proret = await axios.get(url);
      // console.log("proret:", proret);

      if (proret.data.status === "success") {
        this.setState({
          profile: proret.data.Msg,
        });
      } else {
        this.setState({
          errMsg: proret.data.Msg,
          errFlag: false,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: false,
      });
    }
  };

  setDAForDisplay = async () => {
    // console.log("setDAForDisplay ...");
    let creatorSelfi = "",
      storePromoImage = "",
      storePromoVideo = "",
      storeAdvLarge = "",
      storeAdvSmall = "";

    // console.log("this.props.store.fileUploads:", this.props.store.fileUploads);
    this.props.store.fileUploads.forEach((da) => {
      if (da.type === "strCreatorSelfi") creatorSelfi = da.location;
      if (da.type === "storePromoImage") storePromoImage = da.location;
      if (da.type === "storePromoVideo") storePromoVideo = da.location;
      if (da.type === "storeAdvLarge") storeAdvLarge = da.location;
      if (da.type === "storeAdvSmall") storeAdvSmall = da.location;
    });

    if (creatorSelfi === "" && this.state.profile.fileUploads) {
      creatorSelfi = this.state.profile.fileUploads.location;
    }

    let videoExists = false;
    if (storePromoVideo !== "") videoExists = true;

    if (storeAdvLarge === "") storeAdvLarge = advLargeNA;

    this.setState({
      creatorSelfi,
      storePromoImage,
      storePromoVideo,
      storeAdvLarge,
      storeAdvSmall,
      videoExists,
    });
  };

  showvideoPlease = () => {
    this.setState({
      showImage: false,
      showVideo: true,
      showMap: false,
    });
  };

  showImagePlease = () => {
    this.setState({
      showImage: true,
      showVideo: false,
      showMap: false,
    });
  };

  showMapPlease = () => {
    this.setState({
      showImage: false,
      showVideo: false,
      showMap: true,
    });
  };

  phoneFormat = (inp) => {
    // console.log("phone inp:", inp);
    let input = inp;
    if (inp.length === 12) input = inp.substring(2, 12);
    if (inp.length === 11) input = inp.substring(1, 11);
    // console.log("input: ", input);
    if (!input || isNaN(input))
      return `input must be a number was sent ${input}`;
    if (typeof input !== "string") input = input.toString();
    if (input.length === 10) {
      return input.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
    } else if (input.length < 10) {
      return "was not supplied enough numbers please pass a 10 digit number";
    } else if (input.length > 10) {
      return "was supplied too many numbers please pass a 10 digit number";
    } else {
      return "something went wrong";
    }
  };

  returnToMarket = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("AStoreLanding...");

    let videoButton;
    if (this.state.videoExists) {
      videoButton = (
        <button
          className="btn_reg_50"
          type="button"
          onClick={this.showvideoPlease}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Video
        </button>
      );
    }

    let imageButton;
    if (this.state.imageExists) {
      imageButton = (
        <button
          className="btn_reg_50"
          type="button"
          onClick={this.showImagePlease}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          {this.state.deviceSize === "big" ? (
            "Image"
          ) : (
            <i className="fas fa-image" />
          )}
        </button>
      );
    }

    let mapButton;
    if (this.state.imageExists && this.props.store.displayAddress) {
      mapButton = (
        <button
          className="btn_reg_50"
          type="button"
          onClick={this.showMapPlease}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          {this.state.deviceSize === "big" ? (
            "Map"
          ) : (
            <i className="fas fa-map-marked-alt" />
          )}
        </button>
      );
    }

    let exitButton;
    if (this.props.caller && this.props.caller === "storeInfo") {
      exitButton = (
        <button
          className="btn_back_main"
          type="button"
          onClick={this.returnToMarket}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
      );
    }

    let showDAPanel;
    showDAPanel = (
      <div className="">
        <img
          src={this.state.creatorSelfi}
          className="main-landing-creator-img"
          alt="commImg"
        />
        {this.state.deviceSize === "small" ? (
          <div className="row">
            <div className="col text-center main-landing-comm-txt-small">
              {this.props.store.displayStoreKeeper}
            </div>
          </div>
        ) : null}
      </div>
    );

    let showCommDA;
    if (this.state.showImage && this.state.storePromoImage) {
      showCommDA = (
        <div className="text-center">
          <img
            src={this.state.storePromoImage}
            className="main-landing-comm-img"
            alt="commImg"
          />
        </div>
      );
    }

    if (this.state.showVideo && this.state.storePromoVideo) {
      let vext =
        "video/" +
        this.state.storePromoVideo.split(/[#?]/)[0].split(".").pop().trim();
      // console.log('vext:', vext);
      showCommDA = (
        <div className="text-center">
          <video className="main-land-video" controls>
            <source src={this.state.storePromoVideo} type={vext} />
          </video>
        </div>
      );
    }

    if (this.state.showMap && this.state.communityLocation) {
      showCommDA = (
        <div className="text-center main-land-map-placement">
          <PresentMaps
            baandaId={this.props.auth.user.baandaId}
            communityLocation={this.state.communityLocation}
            communityCaption={this.props.store.displayStoreName}
          />
        </div>
      );
    }

    let creatorPanel;
    if (this.props.store) {
      let creators = this.props.store.displayStoreKeeper;

      if (this.state.deviceSize === "big") {
        creatorPanel = (
          <div className="">
            {showDAPanel}
            <div className="row">
              <div className="col main-landing-cr-body text-center">
                {creators}
              </div>
            </div>
            <div className="row">
              <div className="col main-landing-cr-storename">
                {this.props.store.displayStoreName}
              </div>
            </div>
            {this.state.profile ? (
              <div className="row">
                <div className="col main-landing-cr-body text-center">
                  TZ: {JSON.parse(this.state.profile.geoCentricInfo).timezone}
                </div>
              </div>
            ) : null}

            <div className="row landing-main-contact">
              <div className="col  text-center main-landing-comm-lbltop">
                Contact
              </div>
            </div>
            <div className="row">
              <div className="col text-center main-landing-comm-txt">
                {this.props.store.displayStoreKeeper}
              </div>
            </div>
            <div className="row">
              <div className="col text-center main-landing-comm-txt">
                {this.props.store.contact.email}
              </div>
            </div>
            <div className="row">
              <div className="col text-center main-landing-comm-txt">
                {this.phoneFormat(this.props.store.contact.cell)}
              </div>
            </div>
            {this.props.store.displayAddress ? (
              <div>
                <div className="row">
                  <div className="col main-landing-comm-txt">
                    {this.props.store.displayAddress.street}
                  </div>
                </div>
                <div className="row">
                  <div className="col main-landing-comm-txt">
                    {this.props.store.displayAddress.city},{" "}
                    {this.props.store.displayAddress.state}{" "}
                    {this.props.store.displayAddress.postalCode}
                  </div>
                </div>
              </div>
            ) : null}

            {exitButton}
          </div>
        );
      } else {
        creatorPanel = (
          <div className="">
            <div className="row">
              <div className="col-6 text-center">{showDAPanel}</div>
              <div className="col-6">
                <div className="row">
                  <div className="col main-landing-cr-storename">
                    {this.props.store.displayStoreName}
                  </div>
                </div>
                <div className="row landing-main-contact">
                  <div className="col  text-center main-landing-comm-lbltop">
                    Contact {exitButton}
                  </div>
                </div>
                {this.state.deviceSize === "big" ? (
                  <div className="row">
                    <div className="col text-center main-landing-comm-txt-small">
                      {this.props.store.displayStoreKeeper}
                    </div>
                  </div>
                ) : null}
                <div className="row">
                  <div className="col text-center main-landing-comm-txt-small">
                    {this.props.store.contact.email}
                  </div>
                </div>
                <div className="row">
                  <div className="col text-center main-landing-comm-txt-small">
                    {this.phoneFormat(this.props.store.contact.cell)}
                  </div>
                </div>
                {this.props.store.displayAddress ? (
                  <div>
                    <div className="row">
                      <div className="col main-landing-comm-txt-small">
                        {this.props.store.displayAddress.street}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col main-landing-comm-txt-small">
                        {this.props.store.displayAddress.city},{" "}
                        {this.props.store.displayAddress.state}{" "}
                        {this.props.store.displayAddress.postalCode}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        );
      }
    }

    let communityPanel;
    if (this.props.store) {
      communityPanel = (
        <div>
          <div className="row">
            <div className="col-10">{showCommDA}</div>
            <div className="col-2 main-land-comm-button-pos">
              <div className="row">
                <div className="col text-center">
                  {this.state.showMap ? null : mapButton}
                </div>
              </div>
              <div className="row">
                <div className="col text-center">
                  {this.state.showImage ? videoButton : imageButton}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className={
                !this.state.showMap
                  ? "col text-left landing-coop-store-desc"
                  : "col text-left landing-coop-store-map-desc"
              }
            >
              <p align="justify" className="store-landing-desc-p">
                {this.props.store.displayDescription}
              </p>
            </div>
          </div>
        </div>
      );
    }

    let isAdvImgLargeOK = true,
      isAdvImgSmallOK = true;
    if (this.state.storeAdvLarge === "") isAdvImgLargeOK = false;
    if (this.state.storeAdvSmall === "") isAdvImgSmallOK = false;

    // console.log('this.state.storeAdvSmall:', this.state.storeAdvSmall);
    // console.log('isAdvImgSmallOK:', isAdvImgSmallOK);
    let adverstisementPanel;
    if (this.state.deviceSize === "big") {
      if (isAdvImgLargeOK) {
        adverstisementPanel = (
          <div className="text-center">
            <img
              src={this.state.storeAdvLarge}
              width="100%"
              height="140px"
              alt=""
            />
          </div>
        );
      } else {
        adverstisementPanel = (
          <div className="text-center">
            <img src={advLargeNA} width="100%" height="140px" alt="" />
          </div>
        );
      }
    } else {
      if (isAdvImgSmallOK) {
        adverstisementPanel = (
          <div className="text-center">
            <img
              src={this.state.storeAdvSmall}
              width="100%"
              height="70px"
              alt=""
            />
          </div>
        );
      } else {
        adverstisementPanel = (
          <div className="text-center">
            <img src={advLargeNA} width="100%" height="70px" alt="" />
          </div>
        );
      }
    }

    let outputPanel;
    if (this.state.deviceSize === "big") {
      outputPanel = (
        <div>
          <div className="row">
            <div className="col-md-3 store-landing-creator-box">
              {creatorPanel}
            </div>
            <div className="col-md-9 store-landing-comm-box">
              {communityPanel}
            </div>
          </div>
          {adverstisementPanel}
        </div>
      );
    } else {
      outputPanel = (
        <div>
          <div className="row">
            <div className="col store-landing-creator-box">{creatorPanel}</div>
          </div>
          {communityPanel}
          {adverstisementPanel}
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

AStoreLanding.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AStoreLanding)
);
