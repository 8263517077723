import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import EventDefine from "./parts/EventDefine";
import GetEventsToEdit from "./getEvents/GetEventsToEdit";
import Contact from "./parts/Contact";
import PlaceTime from "./parts/PlaceTime";
import Direct from "./parts/Direct";
import Publish from "./publish/PublishEvent";
import Upload from "./parts/Upload";

import CreateTask from "./task/CreateTask";
import CreateShifts from "./shifts/CreateShift";

import "./CreateEvent.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAnEvent = "/routes/inetract/getAnEvent?";

const readMeCode = "1145100000";

class CreateEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      showBtnDefine: true,
      showBtnContact: false,
      showBtnSpaceTime: false,
      showBtnDirection: false,
      showTaskButton: false,
      showBtnsaveWip: false,
      showBtnPublish: false,
      showBtnUpload: false,
      showBtnShifts: false,

      showTheNotifyButton: true,

      openDefineFlag: true,
      openContactFlag: false,
      openSpaceTimeFlag: false,
      openDirectionFlag: false,
      openTaskFlag: false,
      openUploadFlag: false,

      showEventCreation: true,
      showTaskCreation: false,
      showShiftCreation: false,
      showServiceCreation: false,

      openGetEventFlag: false,

      eventInitiated: false,
      eventId: 0,

      theEvent: null,
      removeMode: false,
      eventType: "nonservice",

      errMsg: '',
      errFlag: false
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setState({
      deviceSize,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleEventOps = async (inp) => {
    await this.setState({
      showBtnDefine: false,
      showBtnContact: false,
      showBtnSpaceTime: false,
      showBtnDirection: false,
      showTaskButton: false,
      showBtnPublish: false,
      showBtnUpload: false,
      showBtnTasks: false,
      showBtnShifts: false,
      showShiftCreation: false,

      openDefineFlag: false,
      openContactFlag: false,
      openSpaceTimeFlag: false,
      openDirectionFlag: false,
      openTaskFlag: false,
      openPublishFlag: false,
      openUploadFlag: false,
      openGetEventFlag: false,
    });

    switch (inp) {
      case "define":
        await this.setState({
          showBtnDefine: true,
          openDefineFlag: true,
        });
        break;
      case "contact":
        await this.setState({
          showBtnContact: true,
          openContactFlag: true,
        });
        break;
      case "getevents":
        await this.setState({
          openGetEventFlag: true,
        });
        break;
      case "spacetime":
        await this.setState({
          openSpaceTimeFlag: true,
          showBtnSpaceTime: true,
        });
        break;
      case "direction":
        await this.setState({
          openDirectionFlag: true,
          showBtnDirection: true,
        });
        break;
      case "publish":
        await this.setState({
          openPublishFlag: true,
          showBtnPublish: true,
        });
        break;
      case "upload":
        await this.setState({
          openUploadFlag: true,
          showBtnUpload: true,
        });
        break;
      case "task":
        await this.setState({
          openTaskFlag: true,
          showTaskButton: true,
          showEventCreation: false,
          showTaskCreation: true,
        });
        break;
      case "shifts":
        await this.setState({
          openShiftFlag: true,
          showBtnShifts: true,
          showShiftCreation: true,
        });
        break;

      default:
        console.log("switch default - will not reach here");
    }
  };

  getTheEvent = async (eventId) => {
    let param = "eventId=" + eventId;

    let url = baandaServer + getAnEvent + param;
    // console.log("url:", url);
    let event = null;
    try {
      let eret = await axios.get(url);
      if (eret.data.status === "success") {
        event = eret.data.Msg;
      } else {
        // console.log("Err data:", eret.data.Msg);
        this.setState({
          errMsg: eret.data.Msg,
          errFlag: true
        });
      }
    } catch (err) {
      // console.log("Err sys:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true
      });
    }
    return event;
  };

  returnToOrigin = () => {
    this.props.returnToCaller();
  };

  returnFromDefine = async () => {};

  returnFromContact = async (theEvent) => {
    // console.log("returnFromContact theEvent:", theEvent);
    let te = await this.getTheEvent(theEvent.eventId);

    await this.setState({
      theEvent: te,
      eventId: theEvent.eventId,
      eventInitiated: true,
      showBtnContact: true,
      openContactFlag: true,
    });
  };

  returnFromPlaceTimeEvents = async (theEvent) => {
    // console.log("returnFromPlaceTimeEvents theEvent:", theEvent);
    let te = await this.getTheEvent(theEvent.eventId);
    await this.setState({
      theEvent: te,
      eventId: theEvent.eventId,
      showBtnSpaceTime: true,
      openSpaceTimeFlag: true,
    });
  };

  returnFromEventDefine = async (theEvent) => {
    await this.checkIfNotifyNeeded(theEvent);
    // console.log("returnFromEventDefine theEvent:", theEvent);
    let te = await this.getTheEvent(theEvent.eventId);
    await this.setState({
      theEvent: te,
      eventId: theEvent.eventId,
      eventType: te.eventType,
      openGetEventFlag: false,
      showBtnDefine: true,
      openDefineFlag: true,
      openContactFlag: false,
      openSpaceTimeFlag: false,
      openDirectionFlag: false,
      eventInitiated: true,
    });
  };

  returnFromDirect = async (theEvent) => {
    // console.log("returned from Direct");
    let te = await this.getTheEvent(theEvent.eventId);
    await this.setState({
      theEvent: te,
      eventId: theEvent.eventId,
    });
  };

  returnFromUpload = async (theEvent, opsFlow) => {
    // console.log("returned from Upload opsFlow:", opsFlow);
    let te = await this.getTheEvent(theEvent.eventId);
    if (opsFlow === "justupdate") {
      // console.log("I am here ...zzzz1111");
      await this.setState({
        theEvent: te,
        eventId: theEvent.eventId,
        openDefineFlag: false,
        openContactFlag: false,
        openSpaceTimeFlag: false,
        openDirectionFlag: false,
        openTaskFlag: false,
        openPublishFlag: false,
        openUploadFlag: true,
        openGetEventFlag: false,
        showBtnDefine: false,
        showBtnContact: false,
        showBtnSpaceTime: false,
        showBtnDirection: false,
        showTaskButton: false,
        showBtnPublish: false,
        showBtnUpload: true,
      });
    } else {
      // console.log("I am here ...zzzz2222");
      await this.setState({
        theEvent: te,
        eventId: theEvent.eventId,
        openDefineFlag: true,
        openContactFlag: false,
        openSpaceTimeFlag: false,
        openDirectionFlag: false,
        openTaskFlag: false,
        openPublishFlag: false,
        openUploadFlag: false,
        openGetEventFlag: false,
        showBtnDefine: true,
        showBtnContact: false,
        showBtnSpaceTime: false,
        showBtnDirection: false,
        showTaskButton: false,
        showBtnPublish: false,
        showBtnUpload: false,
      });
    }
  };

  checkIfNotifyNeeded = async (selEvent) => {
    // console.log('checkIfNotifyNeeded selEvent.eventType:', selEvent.eventType);
    let showTheNotifyButton = true;
    if (selEvent.eventType === "service") {
      if (selEvent.services) {
        // console.log('selEvent.services.serviceFeeType:', selEvent.services.serviceFeeType);
        if (selEvent.services.serviceFeeType === "catalog")
          showTheNotifyButton = false;
      }
    }
    // console.log('showTheNotifyButton:', showTheNotifyButton);
    this.setState({
      showTheNotifyButton,
    });
  };

  returnFromGetEvents = async (selectedEvent, type) => {
    // console.log('selectedEvent:', selectedEvent, ' type:', type);
    await this.checkIfNotifyNeeded(selectedEvent);
    let removeMode = false;
    if (type === "published" && selectedEvent != null) {
      removeMode = true;
    }
    await this.setState({
      theEvent: selectedEvent,
      eventId: selectedEvent.eventId,
      eventType: selectedEvent.eventType,
      openGetEventFlag: false,
      showBtnDefine: true,
      openDefineFlag: true,
      openContactFlag: false,
      openSpaceTimeFlag: false,
      eventInitiated: true,
      openDirectionFlag: false,
      removeMode,
    });
  };

  returnFromPublish = async (status) => {
    if (status === "success") {
      this.props.returnToCaller();
    }
  };

  returnFromTask = async (theEvent) => {
    let te = await this.getTheEvent(theEvent.eventId);
    await this.setState({
      theEvent: te,
      eventId: theEvent.eventId,
      showEventCreation: true,
      openDefineFlag: true,
      showTaskCreation: false,
    });
  };

  returnFromShift = async (theEvent) => {
    await this.checkIfNotifyNeeded(theEvent);
    // console.log('returnFromShift theEvent:', theEvent);
    let te = await this.getTheEvent(theEvent.eventId);
    await this.setState({
      theEvent: te,
      eventId: theEvent.eventId,
      eventType: theEvent.eventType,
      showBtnDefine: true,
      openDefineFlag: true,
      showShiftCreation: false,
      openShiftFlag: false,
      showBtnShifts: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("CreateEvent...");

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let define, contact, spacetime, direction, upload, task;
    if (this.state.deviceSize === "small") {
      define = "Def";
      contact = "Cont";
      spacetime = "Sch";
      direction = "Ntfy";
      upload = "Atch";
      // view = "View";
      task = "Task";
    } else {
      define = "Define";
      contact = "Contact";
      spacetime = "Schedule";
      direction = "Notify";
      upload = "Attach";
      // view = "View";
      task = "Tasks";
    }

    let opsButtonPanel;
    opsButtonPanel = (
      <div className="row">
        <div className="col-3 text-center cal-event-header">Event Mgmt.</div>
        <div className="col-9 text-left event-ctrl-button">
          <button
            className={
              this.state.showBtnDefine ? "btn_reg_70_active" : "btn_reg_70"
            }
            type="button"
            onClick={() => this.handleEventOps("define")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {define}
          </button>
          {this.state.eventInitiated ? (
            <button
              className={
                this.state.showBtnContact ? "btn_reg_70_active" : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleEventOps("contact")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {contact}
            </button>
          ) : null}

          {this.state.eventInitiated &&
          this.state.eventType === "nonservice" ? (
            <button
              className={
                this.state.showBtnSpaceTime ? "btn_reg_75_active" : "btn_reg_75"
              }
              type="button"
              onClick={() => this.handleEventOps("spacetime")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {spacetime}
            </button>
          ) : null}

          {this.state.theEvent &&
          this.state.showTheNotifyButton &&
          !this.props.store ? (
            <button
              className={
                this.state.showBtnDirection ? "btn_reg_70_active" : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleEventOps("direction")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {direction}
            </button>
          ) : null}
          {this.state.eventInitiated && !this.props.store ? (
            <button
              className={
                this.state.showBtnUpload ? "btn_reg_70_active" : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleEventOps("upload")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {upload}
            </button>
          ) : null}

          {this.state.eventInitiated && !this.props.store ? (
            <button
              className={
                this.state.showBtnTasks ? "btn_reg_70_active" : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleEventOps("task")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              {task}
            </button>
          ) : null}
          {this.state.eventInitiated && this.state.eventType === "service" ? (
            <button
              className={
                this.state.showBtnShifts ? "btn_reg_70_active" : "btn_reg_70"
              }
              type="button"
              onClick={() => this.handleEventOps("shifts")}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Shifts
            </button>
          ) : null}

          {infoModalButton}
        </div>
      </div>
    );

    let publish, getw; // can;

    if (this.state.deviceSize === "small") {
      publish = "Rev";
      getw = "ELst";
      // can = "Cal";
    } else {
      publish = "Review";
      getw = "EventList";
      // can = "Calendar";
    }

    let ctrlButtonPanel;
    ctrlButtonPanel = (
      <div className="text-center create-event-lower-btn-pos">
        {this.state.eventInitiated ? (
          <button
            className={
              this.state.showBtnPublish ? "btn_reg_70_active" : "btn_reg_70"
            }
            type="button"
            onClick={() => this.handleEventOps("publish")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {publish}
          </button>
        ) : null}

        <button
          className={
            this.state.showBtnsaveWip ? "btn_reg_80_active" : "btn_reg_80"
          }
          type="button"
          onClick={() => this.handleEventOps("getevents")}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          {getw}
        </button>

        <button
          className="btn_back_main"
          type="button"
          onClick={this.returnToOrigin}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
      </div>
    );

    let topPanel;
    topPanel = (
      <div className="text-center">
        {opsButtonPanel}
        {ctrlButtonPanel}
      </div>
    );

    let outputPanel;
    let activePanel;
    if (this.state.openDefineFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activePanel = (
        <div>
          <EventDefine
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            theEvent={this.state.theEvent}
            returnToCaller={this.returnFromEventDefine}
            store={store}
          />
        </div>
      );
    }

    if (this.state.openGetEventFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activePanel = (
        <div>
          <GetEventsToEdit
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            returnToCaller={this.returnFromGetEvents}
            store={store}
          />
        </div>
      );
    }

    if (this.state.openSpaceTimeFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activePanel = (
        <div>
          <PlaceTime
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            theEvent={this.state.theEvent}
            returnToCaller={this.returnFromPlaceTimeEvents}
            store={store}
          />
        </div>
      );
    }

    if (this.state.openContactFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activePanel = (
        <div>
          <Contact
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            theEvent={this.state.theEvent}
            returnToCaller={this.returnFromContact}
            store={store}
          />
        </div>
      );
    }

    if (this.state.openDirectionFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activePanel = (
        <div>
          <Direct
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            theEvent={this.state.theEvent}
            returnToCaller={this.returnFromDirect}
            store={store}
          />
        </div>
      );
    }

    if (this.state.openUploadFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activePanel = (
        <div>
          <Upload
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            theEvent={this.state.theEvent}
            returnToCaller={this.returnFromUpload}
            store={store}
          />
        </div>
      );
    }

    if (this.state.openPublishFlag) {
      let store = null;
      if (this.props.store) store = this.props.store;
      activePanel = (
        <div>
          <Publish
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            theEvent={this.state.theEvent}
            returnToCaller={this.returnFromPublish}
            store={store}
          />
        </div>
      );
    }

    // let outputPanel;
    if (this.state.showEventCreation) {
      outputPanel = (
        <div className="cal-event-box text-center">
          {topPanel}
          <hr className="event-btn-divider" />
          {activePanel}
        </div>
      );
    }

    if (this.state.showTaskCreation) {
      let store = null;
      if (this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <CreateTask
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            theEvent={this.state.theEvent}
            returnToCaller={this.returnFromTask}
            store={store}
          />
        </div>
      );
    }

    if (this.state.showShiftCreation) {
      console.log("showShiftCreation");
      let store = null;
      if (this.props.store) store = this.props.store;
      outputPanel = (
        <div>
          <CreateShifts
            communityId={this.props.communityId}
            communityName={this.props.communityName}
            authorizedFuncs={this.props.authorizedFuncs}
            hostBaandaId={this.props.auth.user.baandaId}
            theEvent={this.state.theEvent}
            returnToCaller={this.returnFromShift}
            store={store}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CreateEvent.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreateEvent));
