import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./CampaignFooter.css";

import fbicon from "../../../../images/icons8-facebook-48.png";
import twittericon from "../../../../images/icons8-twitter-48.png";

import websiteicon from "../../../../images/icons8-website-64.png";
import youtubeicon from "../../../../images/youtube.png";
import instagramicon from "../../../../images/instagram.png";
import baandaIcon from "../../../../images/logo/baandalogo-2.png";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveCommunityFooter = "/routes/admin/saveCommunityFooter";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1152000000";

class CampaignFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFacebook: false,
      activeTwitter: false,
      activeStore: false,
      activeBlog: false,
      activeWebsite: false,
      activeYoutube: false,
      activeInstagram: false,

      facebookLink: "",
      twitterLink: "",
      websiteLink: "",
      websiteWord: "",
      youtubeLink: "",
      instagramLink: "",
      storeLink: "",

      footerMessage: "",

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = () => {
    let activeFacebook = false;
    let activeTwitter = false;
    let activeStore = false;
    let activeBlog = false;
    let activeWebsite = false;
    let activeYoutube = false;
    let activeInstagram = false;

    let facebookLink = "";
    let twitterLink = "";
    let websiteLink = "";
    let websiteWord = "";
    let youtubeLink = "";
    let instagramLink = "";
    let storeLink =
      "https://baandaclient-qa4-v2.herokuapp.com/login?opstype=store&communityId=" +
      this.props.communityId;

    let footerMessage = "";
    if (this.props.community.campaignFooterObj) {
      let co = this.props.community.campaignFooterObj;
      activeFacebook = co.activeFacebook;
      activeTwitter = co.activeTwitter;
      activeStore = co.activeStore;
      activeBlog = co.activeBlog;
      activeWebsite = co.activeWebsite;
      activeYoutube = co.activeYoutube;
      activeInstagram = co.activeInstagram;

      facebookLink = co.facebookLink;
      twitterLink = co.twitterLink;
      websiteLink = co.websiteLink;
      websiteWord = co.websiteWord;
      youtubeLink = co.youtubeLink;
      instagramLink = co.instagramLink;
      footerMessage = co.footerMessage;
    }

    this.setState({
      activeFacebook,
      activeTwitter,
      activeStore,
      activeBlog,
      activeWebsite,
      activeYoutube,
      activeInstagram,

      facebookLink,
      twitterLink,
      websiteLink,
      websiteWord,
      youtubeLink,
      instagramLink,
      storeLink,

      footerMessage,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  isValidUrl = (ustr) => {
    console.log('ustr:', ustr);
    let url;
    try {
      url = new URL(ustr);
    } catch (e) {
      return false;
    }
    return url.protocol === "http:" || url.protocol === "https:";
  };

  validUrlBeforeSave = () => {
    let msg = "";
    let state = "success";
    let ret;
    if (this.state.activeFacebook) {
      ret = this.isValidUrl(this.state.facebookLink);
      console.log('ret:', ret); 
      if (!ret) {
        msg += "Invalid FB link protocol; ";
        state = "error";
      }
    }
    if (this.state.activeTwitter) {
      ret = this.isValidUrl(this.state.twitterLink);
      console.log('ret:', ret); 
      if (!ret) {
        msg += "Invalid Twitter link protocol; ";
        state = "error";
      }
    }
    if (this.state.activeStore) {
      ret = this.isValidUrl(this.state.storeLink);
      console.log('ret:', ret); 
      if (!ret) {
        msg += "Invalid Store link protocol; ";
        state = "error";
      }
    }
    if (this.state.activeWebsite) {
      ret = this.isValidUrl(this.state.websiteLink);
      console.log('ret:', ret); 
      if (!ret) {
        msg += "Invalid website link protocol; ";
        state = "error";
      }
    }
    if (this.state.activeInstagram) {
      ret = this.isValidUrl(this.state.instagramLink);
      console.log('ret:', ret); 
      if (!ret) {
        msg += "Invalid instagram link protocol; ";
        state = "error";
      }
    }
    if (this.state.activeYoutube) {
      ret = this.isValidUrl(this.state.youtubeLink);
      console.log('ret:', ret); 
      if (!ret) {
        msg += "Invalid YouTube link protocol; ";
        state = "error";
      }
    }

    return { status: state, Msg: msg };
  };

  handleFooterSave = async () => {
    let isValid = this.validUrlBeforeSave();
    console.log('isValid:', isValid);
    if (isValid.status === "success") {
      let url = baandaServer + saveCommunityFooter;
      let input = {
        communityId: this.props.communityId,
        campaignFooterObj: {
          activeFacebook: this.state.activeFacebook,
          activeTwitter: this.state.activeTwitter,
          activeStore: this.state.activeStore,
          activeWebsite: this.state.activeWebsite,
          activeYoutube: this.state.activeYoutube,
          activeInstagram: this.state.activeInstagram,
          facebookLink: this.state.facebookLink,
          twitterLink: this.state.twitterLink,
          websiteLink: this.state.websiteLink,
          websiteWord: this.state.websiteWord,
          youtubeLink: this.state.youtubeLink,
          instagramLink: this.state.instagramLink,
          storeLink: this.state.storeLink,
          footerMessage: this.state.footerMessage,
        },
      };
      console.log("input:", input);
      try {
        let footret = await axios.post(url, input);
        if (footret.data.status === "success") {
          this.setState({
            errMsg: "Successfully updated",
            errFlag: false,
          });
        } else {
          this.setState({
            errMsg: footret.dada.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        console.log("err:", err.message);
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: isValid.Msg,
        errFlag: true,
      });
    }
  };

  handleFacebook = async () => {
    this.setState((prevstate) => ({
      activeFacebook: !prevstate.activeFacebook,
    }));
  };

  handleTwitter = async () => {
    this.setState((prevstate) => ({
      activeTwitter: !prevstate.activeTwitter,
    }));
  };

  handleStore = async () => {
    this.setState((prevstate) => ({
      activeStore: !prevstate.activeStore,
    }));
  };

  handleInstagram = async () => {
    this.setState((prevstate) => ({
      activeInstagram: !prevstate.activeInstagram,
    }));
  };

  handleWebSite = async () => {
    this.setState((prevstate) => ({
      activeWebsite: !prevstate.activeWebsite,
    }));
  };

  handleYoutube = async () => {
    this.setState((prevstate) => ({
      activeYoutube: !prevstate.activeYoutube,
    }));
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    let buttonInfo = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let saveInfo = (
      <button
        className="btn_reg_60"
        type="button"
        onClick={this.handleFooterSave}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Save
      </button>
    );

    let socialMediaPopupPanel;

    socialMediaPopupPanel = (
      <div className="">
        <div className="row smrow-placement">
          <div className="col-1 sm-chkbox text-right">
            <img src={fbicon} height="35px" width="auto" alt="fb" />
          </div>
          <div className="col-3 sm-chkbox text-left">
            &nbsp;
            <Checkbox
              checked={this.state.activeFacebook}
              onChange={this.handleFacebook}
            />{" "}
            Activate Fb
          </div>
          {this.state.activeFacebook ? (
            <div className="col-8 text-left adm-foot-link-lbl">
              <b>Link</b>:&nbsp;
              <input
                name="facebookLink"
                type="text"
                value={this.state.facebookLink}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="adm-foot-link"
                placeholder="Your Facebook Link "
              />
            </div>
          ) : (
            <div className="col-8 text-left">&nbsp;</div>
          )}
        </div>
        <div className="row smrow-placement">
          <div className="col-1 sm-chkbox text-right">
            <img src={twittericon} height="35px" width="auto" alt="fb" />
          </div>
          <div className="col-3 sm-chkbox text-left">
            &nbsp;
            <Checkbox
              checked={this.state.activeTwitter}
              onChange={this.handleTwitter}
            />{" "}
            Activate Twitter
          </div>
          {this.state.activeTwitter ? (
            <div className="col-8 text-left adm-foot-link-lbl ">
              <b>Link</b>:&nbsp;
              <input
                name="twitterLink"
                type="text"
                value={this.state.twitterLink}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="adm-foot-link"
                placeholder="Your Twitter Link "
              />
            </div>
          ) : (
            <div className="col-8 text-left">&nbsp;</div>
          )}
        </div>
        <div className="row smrow-placement">
          <div className="col-1 sm-chkbox text-right">
            <img src={instagramicon} height="30px" width="auto" alt="Ig" />
          </div>
          <div className="col-3 sm-chkbox text-left">
            <Checkbox
              checked={this.state.activeInstagram}
              onChange={this.handleInstagram}
            />{" "}
            Activate Instagram
          </div>
          {this.state.activeInstagram ? (
            <div className="col-8 text-left sm-link-lbl">
              <b>Link</b>:&nbsp;
              <input
                name="instagramLink"
                type="text"
                value={this.state.instagramLink}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="adm-foot-link"
                placeholder="Your Instagram Link "
              />
            </div>
          ) : (
            <div className="col-8 text-left">&nbsp;</div>
          )}
        </div>
        <div className="row smrow-placement">
          <div className="col-1 sm-chkbox text-right">
            <img
              src={youtubeicon}
              height="30px"
              width="38px"
              style={{ borderRadius: "20%" }}
              alt="yt"
            />
          </div>
          <div className="col-3 sm-chkbox text-left">
            <i className="fa fa-facebook" />
            <Checkbox
              checked={this.state.activeYoutube}
              onChange={this.handleYoutube}
            />{" "}
            Activate Youtube
          </div>
          {this.state.activeYoutube ? (
            <div className="col-8 text-left sm-link-lbl">
              <b>Link</b>:&nbsp;
              <input
                name="youtubeLink"
                type="text"
                value={this.state.youtubeLink}
                onChange={this.onChange}
                size="30"
                maxLength="30"
                className="adm-foot-link"
                placeholder="Your Youtube Link "
              />
            </div>
          ) : (
            <div className="col-8 text-left">&nbsp;</div>
          )}
        </div>

        <div className="row smrow-placement">
          <div className="col-1 sm-chkbox text-right">
            <img src={baandaIcon} height="27px" width="auto" alt="baanda" />
          </div>
          <div className="col-3 sm-chkbox text-left">
            <Checkbox
              checked={this.state.activeStore}
              onChange={this.handleStore}
            />{" "}
            Activate Store
          </div>
          {this.state.activeStore ? (
            <div className="col-8 text-left adm-foot-comm-lbl ">
              <b>Link</b>:&nbsp;
              {this.state.storeLink}
            </div>
          ) : (
            <div className="col-8 text-left">&nbsp;</div>
          )}
        </div>
        <div className="row smrow-placement">
          <div className="col-1 sm-chkbox text-right">
            <img src={websiteicon} height="30px" width="35px" alt="baanda" />
          </div>
          <div className="col-3 sm-chkbox text-left">
            <Checkbox
              checked={this.state.activeWebsite}
              onChange={this.handleWebSite}
            />{" "}
            Your Website
          </div>
          {this.state.activeWebsite ? (
            <div className="col-4 text-left adm-foot-comm-lbl ">
              <input
                name="websiteLink"
                type="text"
                value={this.state.websiteLink}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="adm-foot-link"
                placeholder="Your Website Link "
              />
            </div>
          ) : (
            <div className="col-4 text-left">&nbsp;</div>
          )}
          {this.state.activeWebsite ? (
            <div className="col-4 text-left adm-foot-comm-lbl ">
              <input
                name="websiteWord"
                type="text"
                value={this.state.websiteWord}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="adm-foot-link"
                placeholder="Words for the link for your website "
              />
            </div>
          ) : (
            <div className="col-4 text-left">&nbsp;</div>
          )}
        </div>
        <hr />
        <div className="row footer-msg-pos">
          <div className="col-3 footer-msg-lbl text-right">Footer Message</div>
          <div className="col-9 text-left adm-foot-comm-lbl ">
            <textarea
              name="footerMessage"
              maxLength="500"
              placeholder="Include footer message within 500 chars."
              rows="3"
              wrap="hard"
              spellCheck="true"
              className="adm-foot-msg"
              onChange={this.onChange}
              value={this.state.footerMessage}
              required
            />
          </div>
        </div>
        <div className="row footer-msg-pos-char-cnt">
          <div className="col-3 footer-msg-lbl text-right">&nbsp;</div>
          <div className="col-9 text-left ">
            Characters user :{" "}
            {this.state.footerMessage ? this.state.footerMessage.length : null}
          </div>
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="campaign-footer-box">
        <div className="text-center adm-camp-footer-head">
          Campaign Footer Composition&nbsp;&nbsp; {buttonInfo}
        </div>
        {socialMediaPopupPanel}
        {saveInfo}
        <div
          className={
            this.state.errFlag
              ? "text-center adm-footer-msg-err"
              : "text-center adm-footer-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CampaignFooter.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CampaignFooter));
