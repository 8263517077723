import React, { Component } from 'react'

import './TeamGameSetup.css';

class TeamGameSetup extends Component {
  constructor(props){
    super(props);

    this.state = {

    }
  }

  returnToCatalogSetup = () => {
    this.props.returnToCaller(this.props.catalog)
  }

  render() {
    // console.log('this.props:', this.props);
    // console.log('this.state:', this.state);
    console.log('TeamGameSetup...');

    let exitButton;
    exitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToCatalogSetup}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    )

    let outputPanel;
    outputPanel = (
      <div className='catalog-game-setup-box'>
        <div className='cat-teamgame-head'>Marketing Game Setup &nbsp;&nbsp;{exitButton}</div>
         <div className='text-center mkt-game-msg-pos'>
            <p align="justify" className='mkt-game-msg'>
              This section will allow a community, or a store owner, to gamify their sales. It will be available in the next release (Q1-2023). This will vastly enhance the power of marketing, thus increase sales, via various kind of incentivisations while injecting the thrill of a game in sales.
            </p>
         </div>
      </div>
    )

    return (
      <div>
        {outputPanel}

      </div>
    )
  }
}

export default TeamGameSetup;