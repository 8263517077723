import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./CustomerReturnMgmt.css";

import NewReturns from "./NewReturns";
import PriorRefundCancelOps from "./PriorRefundCancelOps";

const readMeCode = "1114111111";

class CustomerReturnMgmt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      returnOpsType: "new",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setState({
      deviceSize,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  handleOpsSelection = async (e) => {
    await this.setState({
      returnOpsType: e.target.value,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('CustomerReturnMgmt...');

    let buttonModalInvRet;
    buttonModalInvRet = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let retreq, actret;
    if (this.state.deviceSize === "small") {
      retreq = "Ret-Cnc. Req.";
      actret = "Act. Ret-cnc.";
    } else {
      retreq = "Return-Cancel Request";
      actret = "Active Return-Cancel";
    }

    let opsTypePanel;
    opsTypePanel = (
      <div className="row cust-ret-mgmt-action-row">
        <div className="col text-center radio-font-style">
          <strong>Action Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="new"
                checked={this.state.returnOpsType === "new"}
                onChange={this.handleOpsSelection}
              />{" "}
              {retreq}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="prior"
                checked={this.state.returnOpsType === "prior"}
                onChange={this.handleOpsSelection}
              />{" "}
              {actret}
            </label>
          </div>
        </div>
      </div>
    );

    let store = null;
    if (this.props.store) store = this.props.store;

    let outputPanel;
    if (this.state.returnOpsType === "new") {
      outputPanel = (
        <div className="return-customer-box">
          <div className="text-center cust-return-header">
            Customer Return Mgmt {buttonModalInvRet}
          </div>
          {opsTypePanel}
          <NewReturns
            communityId={this.props.communityId}
            community={this.props.community}
            store={store}
          />
        </div>
      );
    }

    if (this.state.returnOpsType === "prior") {
      outputPanel = (
        <div className="return-customer-box">
          <div className="text-center cust-return-header">
            Customer Return Mgmt {buttonModalInvRet}
          </div>
          {opsTypePanel}
          <PriorRefundCancelOps
            communityId={this.props.communityId}
            store={store}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CustomerReturnMgmt.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerReturnMgmt)
);
