import React, { Component } from "react";

import "./GLDetail.css";
class GLDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  exitDetails = () => {
    this.props.returnToCaller();
  };

  padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  formatDate = (date) => {
    let dt = date;
    if (!this.dateIsValid(date)) dt = new Date(date);
    return [
      this.padTo2Digits(dt.getMonth() + 1),
      this.padTo2Digits(dt.getDate()),
      dt.getFullYear(),
    ].join("/");
  };

  dateIsValid = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('GLDetail...');

    let gl = this.props.glLine;

    let exitButton = (
      <button
        onClick={this.exitDetails}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let showPanel = (
      <div>
        <div className="row detail-row-pos">
          <div className="col-3 text-right">Description</div>
          <div className="col-9 text-left">{gl.description === '' ? "Not Available" : gl.description}</div>
        </div>
        <div className="row">
          <div className="col-3 text-right">Journal Id</div>
          <div className="col-9 text-left">{gl.journalId}</div>
        </div>
        <div className="row">
          <div className="col-3 text-right">Ref. Doc Type</div>
          <div className="col-9 text-left">{gl.referenceDocType}</div>
        </div>
        <div className="row">
          <div className="col-3 text-right">Reference Id</div>
          <div className="col-9 text-left">
            {gl.referenceIdType}: {gl.referenceId}
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right">Entry Date</div>
          <div className="col-9 text-left">{this.formatDate(gl.entry_at)}</div>
        </div>
        <div className="row">
          <div className="col-3 text-right">Entry Amount</div>
          <div className="col-9 text-left">
            $ {this.commaFormattedCurrency(gl.Amount)}
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right">Entered by</div>
          <div className="col-9 text-left">{gl.entered_by_bid} (baanda Id)</div>
        </div>
        <div className="row">
          <div className="col-3 text-right">Debit A/C</div>
          <div className="col-9 text-left">{gl.debitAccountName} </div>
        </div>
        <div className="row">
          <div className="col-3 text-right">Credit A/C</div>
          <div className="col-9 text-left">{gl.creditAccountName} </div>
        </div>
        <div className="row">
          <div className="col-3 text-right">Community</div>
          <div className="col-9 text-left">{gl.communityId} (communityId) </div>
        </div>
        {gl.coopStoreId === 0 ? null : (
          <div className="row">
            <div className="col-3 text-right">Coop Store</div>
            <div className="col-9 text-left">
              {gl.coopStoreId} (coopStoreId){" "}
            </div>
          </div>
        )}
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="gl-detail-box">
        <div className="text-center">Details of GL Line {exitButton}</div>
        {showPanel}
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

export default GLDetail;
