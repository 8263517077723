import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import "./ShowBroadcast.css";

import facebookIcon from "../../broadcast/parts/compose/images/icons8-facebook-48.png";
import twitterIcon from "../../broadcast/parts/compose/images/icons8-twitter-48.png";
import blogIcon from "../../broadcast/parts/compose/images/icons8-blog-48.png";
import websiteIcon from "../../broadcast/parts/compose/images/icons8-website-64.png";
import youtubeIcon from "../../broadcast/parts/compose/images/youtubeIcon.png";
import instagramIcon from "../../broadcast/parts/compose/images/instagramIcon.png";

// import CatalogItemDetails from "../../../market/catalog/CatalogItemDetails";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getABroadcast = "/routes/interact/getABroadcast?";
const getCommunityInfo = "/routes/dashboard/getCommunityInfo?";
const saveMsgRead = "/routes/interact/saveMsgRead";

let template1Comps = ["banner", "writeup1", "writeup2", "image1", "footer"];
// let template2Comps = ["bannerlogo", "writeup1", "writeup2", "image1", "footer"];

class ShowBroadcast extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      theBroadcast: null,
      brdShowErrFlag: false,
      brdShowMsg: "",

      activeComponents: [],

      showBroadcastLettrFlag: true,
      showCatalogItemFlag: false,

      sendCatalogItemDetails: {},
      cartOf: {},
      community: null,

      errMsg: '',
      errFlag: false
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.markMsgRead();

    await this.getTheBroadcast(this.props.showPost.actions.refId);
    await this.getCommunitiesInfo();

    let activeComponents = this.state.activeComponents;
    if (this.state.theBroadcast.mailComposition.template === "template1") {
      activeComponents = template1Comps;
    }

    await this.setState({
      deviceSize,
      activeComponents,
    });
  };

  markMsgRead = async() => {
    let input = { synapseId: this.props.showPost.synapseId};
    let url = baandaServer + saveMsgRead;
    // console.log('url:', url, ' input:', input);
    try {
      let readret = await axios.post(url, input);
      // console.log('readret:', readret);
      if ( readret.data.status === 'error') {
        // console.log('markMsgRead for synapseId ' + this.props.showPost.synapseId + ' got error = ' + readret.data.Msg);
        this.setState({
          errMsg: readret.data.Msg,
          errFlag: false
        });
      }
    } catch(err) {
      console.log('markMsgRead for synapseId ' + this.props.showPost.synapseId + ' got error = ' + err.message);
      this.setState({
        errMsg: err.message,
        errFlag: false
      });
    }
  }

  getCommunitiesInfo = async () => {
    let parms = "communityId=" + this.props.communityId;
    let url = baandaServer + getCommunityInfo + parms;
    // console.log("url:", url);
    try {
      let retData = await axios.get(url);
      // console.log("I am here ....2 ");
      // console.log("retData:", retData);
      if (retData.data.status === "success") {
        await this.setState({
          community: retData.data.joiningProcess,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: false
      });
    }
  };

  getTheBroadcast = async (broadcastId) => {
    let params = "broadcastId=" + broadcastId;
    let url = baandaServer + getABroadcast + params;
    let isOk = true;
    try {
      let theBrd = await axios.get(url);
      if (theBrd.data.status === "success") {
        await this.setState({
          theBroadcast: theBrd.data.Msg[0],
        });
      } else {
        await this.setState({
          brdShowMsg: theBrd.data.Msg,
          brdShowErrFlag: true,
        });
        isOk = false;
      }
    } catch (err) {
      console.log("err:", err.message);
      isOk = false;
      this.setState({
        errMsg: err.message,
        errFlag: false
      });
    }

    return isOk;
  };

  closeThisShowBroadcast = async () => {
    this.props.returnToCaller();
  };

  gotoCatalogItem = async () => {
    // console.log("gotoCatalogItem: ...");
    await this.getCommunitiesInfo();

    // console.log("inside gotoCatalogItem");
    let item = {
      bookingType: "full",
      commName: "Tiptop",
      communityId: 219,
      created_at: "2021-01-18T23:42:14.836Z",
      currentInventory: 4,
      digitalMediaId: 0,
      fileUploads: [],
      inventoryInCarts: 0,
      isPublished: true,
      itemCategory: "Utensils",
      itemDescription:
        "kjh jhk jhk jhkjhkjhkhjkh. lhkjh kjhkhjkjhkjh. lkjlkjljklk jlkjl kjlkjl kjlkj.",
      itemExposure: "community",
      itemId: 203,
      itemName: "Ugly Mug",
      itemPrice: 10,
      itemType: "goods",
      productCategory: "",
      productCode: "",
      restrictedGroups: [],
      retainerPercentage: 0,
      s3subBucket: "",
      serviceEstimate: 0,
      serviceId: 0,
      serviceRendereUnit: "hour",
      serviceType: "fixed",
      unitType: "Each",
      updated_at: "2020-12-12T19:18:35.243Z",
      updated_by_bid: 1000000070,
    };

    let sendCatalogItemDetails = {
      baandaId: this.props.auth.user.baandaId,
      customerName: this.props.auth.user.name,
      customerEmail: this.props.auth.user.email,
      itemId: 203,
      anItem: item,
      role: "creator",
      communityId: 219,
    };

    let cartOf = {
      baandaId: 1000000070,
      cell: "+13104227910",
      email: "sarbojit.project@gmail.com",
      exitDate: null,
      joinDate: null,
      name: "Gabrielle",
      role: "creator",
    };

    await this.setState({
      showBroadcastLettrFlag: false,
      showCatalogItemFlag: true,
      sendCatalogItemDetails,
      cartOf,
    });
  };

  returnFromCatalog = async () => {
    await this.setState({
      showBroadcastLettrFlag: true,
      showCatalogItemFlag: false,
    });
  };

  checkIfItHasHttps = async (str) => {
    var tarea = str;
    if (tarea.indexOf("http://") === 0 || tarea.indexOf("https://") === 0) {
        // console.log('New It has https');
        return true;
    } else {
        // console.log('New Does not have https');
        return false;
    }
}

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ShowBroadcast...");

    let catalogTagged = false;
    if (this.state.theBroadcast) {
      if (
        this.state.theBroadcast.tag.domain === "catalog" &&
        parseFloat(this.state.theBroadcast.tag.domainRefId) > 0
      )
        catalogTagged = true;
    }

    let buttonPanel = (
      <div className="text-center brd-button-placement">
        <button
          className="btn-show-brd-return"
          onClick={this.closeThisShowBroadcast}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Return
        </button>
        {catalogTagged ? (
          <button
            className="btn-show-brd-catalog"
            onClick={this.gotoCatalogItem}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Catalog
          </button>
        ) : null}
      </div>
    );

    let bannerPanel;
    if (this.state.activeComponents.includes("banner")) {
      let bp, ht;
      bp = this.state.theBroadcast.mailComposition.banner;
      ht = parseFloat(bp.selectedBannerheight);
      let textSize = bp.bannerTextSize;
      let missionTextSize = bp.missionTextSize;
      if (this.state.deviceSize === "small") {
        textSize = "14pt";
        missionTextSize = "9pt";
      }
      bannerPanel = (
        <div className="show-brd-banner-line">
          <table border="0" cellPadding="0" cellSpacing="0" width="100%">
            <tbody bgcolor={bp.bannerBkColor}>
              <tr width="100%" height={Math.ceil(ht / 3).toString() + "px"}>
                <td>&nbsp;</td>
              </tr>
              <tr width="100%" height={Math.ceil(ht / 3).toString() + "px"}>
                <td
                  style={{
                    color: bp.bannerTextColor,
                    // fontSize: bp.bannerTextSize,
                    fontSize: textSize,
                    fontFamily: bp.bannerTextFontFamily,
                    fontWeight: parseFloat(bp.bannerTextThickness),
                  }}
                >
                  {bp.bannerStatment}
                </td>
              </tr>

              <tr width="100%" height={Math.ceil(ht / 3).toString() + "px"}>
                <td
                  style={{
                    color: bp.missionTextColor,
                    // fontSize: bp.missionTextSize,
                    fontSize: missionTextSize,
                    fontFamily: bp.missionTextFontFamily,
                    fontWeight: parseFloat(bp.missionTextThickness),
                  }}
                >
                  {bp.missionStatement}
                </td>
              </tr>
              <tr width="100%" height={Math.ceil(ht / 3).toString() + "px"}>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }

    // writeup1
    let writeup1Panel;
    let wp;
    // let wp = this.state.theBroadcast.mailComposition.writeup;
    if (this.state.activeComponents.includes("writeup1")) {
      wp = this.state.theBroadcast.mailComposition.writeup;
      writeup1Panel = (
        <div className="show-brd-banner-line">
          <div
            style={{
              color: wp.writeupTextColor,
              fontSize: wp.writeupTextSize,
              paddingTop: "5px",
              paddingLeft: "10px",
              paddingRight: "10px",
              fontFamily: wp.writeupTextFontFamily,
              fontWeight: parseFloat(wp.writeupTextThickness),
              backgroundColor: wp.writeupBkColor
            }}
          >
            <p align="justify">{wp.writeupOne}</p>
          </div>
        </div>
      );
    }

    // writeup2
    let writeup2Panel;
    if (this.state.activeComponents.includes("writeup2")) {
      console.log("in writeup2");
      wp = this.state.theBroadcast.mailComposition.writeup;
      writeup2Panel = (
        <div className="show-brd-banner-line">
          <div
            style={{
              color: wp.writeupTextColor,
              fontSize: wp.writeupTextSize,
              paddingTop: "5px",
              paddingLeft: "10px",
              paddingRight: "10px",
              fontFamily: wp.writeupTextFontFamily,
              fontWeight: parseFloat(wp.writeupTextThickness),
              backgroundColor: wp.writeupBkColor
            }}
          >
            <p align="justify">{wp.writeupTwo}</p>
          </div>
        </div>
      );
    }

    let writeupPanel;
    if ( this.state.theBroadcast ) {
      wp = this.state.theBroadcast.mailComposition.writeup;
      writeupPanel = (
        <div
          className="brd-bmail-footer-panel"
          style={{ 
            backgroundColor: wp.writeupBkColor ,
            paddingLeft: "10px",
            paddingRight: "10px",
            marginTop: "1%", 
            marginLeft: "28px",
            marginRight: "28px"
          }}
        >
          <div className="row">
            <div className="col">{writeup1Panel}</div>
          </div>
          <div className="row">
            <div className="col">{writeup2Panel}</div>
          </div>
        </div>
      );
    }
    

    let image1Panel;
    if (this.state.activeComponents.includes("image1")) {
      let thiswidth = "450px";
      if (this.state.theBroadcast.mailComposition.image1Size === "small")
        thiswidth = "350px";
      if (this.state.theBroadcast.mailComposition.image1Size === "large")
        thiswidth = "550px";

      let location = "";
      this.state.theBroadcast.fileUploads.forEach((elm) => {
        if (elm.compositionComponent === "image1") location = elm.location;
      });

      if (this.state.theBroadcast.mailComposition.template === "template1") {
        image1Panel = (
          <div className="text-center conevrse-brd-img-placement">
            <img
              src={location}
              alt="NoImageLoaded"
              width={thiswidth}
              height="auto"
              className="conevrse-brd-img"
            />
          </div>
        );
      }
    }

    let footerPanel;
    if (this.state.activeComponents.includes("image1")) {
      let ftr = this.state.theBroadcast.mailComposition.footer;
      let links = "";
      let fblink, twitterlink, bloglink, pilink, wslink;
      ftr.socialMedia.forEach((obj) => {
        let thelink;  
        if ( this.checkIfItHasHttps(obj.link) ) thelink = obj.link;
        else thelink = "https://" + obj.link;

        if (obj.type === "facebook") {
          fblink = (
            <a href={thelink} target="_blank" rel="noopener noreferrer">
              {" "}
              <img src={facebookIcon} height="35px" width="auto" alt="FB" />
            </a>
          );
        }
        if (obj.type === "twitter") {
          twitterlink = (
            <a href={thelink} target="_blank" rel="noopener noreferrer">
              {" "}
              <img src={twitterIcon} height="35px" width="auto" alt="FB" />
            </a>
          );
        }
        if (obj.type === "website") {
          wslink = (
            <a href={thelink} target="_blank" rel="noopener noreferrer">
              {" "}
              <img src={websiteIcon} height="35px" width="auto" alt="WS" />
            </a>
          );
        }
        if (obj.type === "blog") {
          bloglink = (
            <a href={thelink} target="_blank" rel="noopener noreferrer">
              {" "}
              <img src={blogIcon} height="35px" width="auto" alt="FB" />
            </a>
          );
        }
        if (obj.type === "youtube") {
          pilink = (
            <a href={thelink} target="_blank" rel="noopener noreferrer">
              {" "}
              <img src={youtubeIcon} height="30px" width="auto" alt="YT" />
            </a>
          );
        }
        if (obj.type === "instagram") {
          pilink = (
            <a href={thelink} target="_blank" rel="noopener noreferrer">
              {" "}
              <img src={instagramIcon} height="30px" width="auto" alt="YT" />
            </a>
          );
        }
      });

      links = (
        <div>
          {fblink}
          {twitterlink}
          {pilink}
          {bloglink}
          {wslink}
        </div>
      );

      let linkrow;
      linkrow = <div className="text-center">{links}</div>;

      let footerSplMsgPanel;
      if (ftr.footerSplMessage !== "") {
        let footerTextSize = ftr.footerTextSize;
        if (this.state.deviceSize === "small") footerTextSize = "9pt";
        footerSplMsgPanel = (
          <div
            className="text-center footer-spl-msg-rows"
            style={{
              color: ftr.footerTextColor,
              fontSize: footerTextSize,
              fontFamily: ftr.footerTextFontFamily,
              fontWeight: parseFloat(ftr.footerTextThickness),
            }}
          >
            {ftr.footerSpecialMsg}
          </div>
        );
      }
      let footerContactPanel;
      console.log('111 ftr:', ftr);
      console.log('222 ftr.footerContacts:', ftr.footerContacts);
      if (ftr.footerContacts !== "") {
        let footerTextSize = ftr.footerTextSize;
        console.log('333 footerTextSize:', footerTextSize)
        if (this.state.deviceSize === "small") footerTextSize = "9pt";
        footerContactPanel = (
          <div
            className="text-center footer-spl-msg-rows"
            style={{
              color: ftr.footerTextColor,
              fontSize: footerTextSize,
              fontFamily: ftr.footerTextFontFamily,
              fontWeight: parseFloat(ftr.footerTextThickness),
            }}
          >
            {ftr.footerContacts}
          </div>
        );
      }

      footerPanel = (
        <div
          className="brd-bmail-footer-panel"
          style={{ 
            backgroundColor: ftr.footerBkColor,
            marginLeft: "28px",
            marginRight: "28px"
          }}
        >
          <div className="row">
            <div className="col">{linkrow}</div>
          </div>
          <div className="row">
            <div className="col">{footerSplMsgPanel}</div>
          </div>
          <div className="row">
            <div className="col">{footerContactPanel}</div>
          </div>
        </div>
      );
    }

    let outputPanel;
    if (this.state.showBroadcastLettrFlag) {
      outputPanel = (
        <div className="converse-show-broadcast-box">
          {buttonPanel}
          {bannerPanel}
          {writeupPanel}
          {image1Panel}
          {footerPanel}
        </div>
      );
    }

    if (this.state.showCatalogItemFlag) {
      outputPanel = (
        // <div>
        //   <CatalogItemDetails
        //     sendCatalogItemDetails={this.state.sendCatalogItemDetails}
        //     returnToDetails={this.returnFromCatalog}
        //     searchItemName=""
        //     authorizedFuncs={this.props.authorizedFuncs}
        //     cartOf={this.state.cartOf}
        //     community={this.state.community}
        //   />
        // </div>
        <div>Will show item details</div>
      );
    }

    return (
      <div>
        {outputPanel}
      </div>
    );
  }
}

ShowBroadcast.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

//   const mapDispatchToProps = (dispatch) => ({
//     hideModal: () => dispatch(hideModal()),
//     showModal: (modalProps, modalType) => {
//       // console.log(
//       //   "modalProps:" + JSON.stringify(modalProps) + "  |modalType:" + modalType
//       // );
//       dispatch(showModal({ modalProps, modalType }));
//     },
//     // setQAInitDone: () => dispatch(setQAInitDone(userData))
//   });

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(withRouter(ShowBroadcast));
