import React, { Component } from "react";

import axios from "axios";

import "./WipInvoiceList.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getWIPInvoices = "/routes/finance/getWIPInvoices?";

class WipInvoiceList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices: [],
      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    await this.getWIPInvoices();
  };

  getWIPInvoices = async () => {
    let coopStoreId = 0;
    if ( this.props.store ) coopStoreId = this.props.store.coopStoreId;
    let param = "communityId=" + this.props.communityId + "&coopStoreId=" + coopStoreId;
    let url = baandaServer + getWIPInvoices + param;
    // console.log("url:", url);
    try {
      let invret = await axios.get(url);
      if (invret.data.status === "success") {
        if (invret.data.Msg.length === 0) {
          this.setState({
            errMsg: "No work in progress invoices found.",
            errFlag: true,
          });
        } else {
          this.setState({
            errMsg: "",
            errFlag: false,
            invoices: invret.data.Msg,
          });
        }
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: false,
      }); 
    }
  };

  selectThisInvoice = (theInvoice) => {
    // console.log("theInvice:", theInvoice);
    this.props.returnToCaller(theInvoice);
  };

  exitToInvoiceEntry = () => {
    let theInvoice = null;
    this.props.returnToCaller(theInvoice);
  };

  render() {
    // console.log("this.state:", this.state);
    // console.log("this.props:", this.props);
    console.log('WipInvoiceList...');


    let addLineButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.exitToInvoiceEntry}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
       <i className="fas fa-step-backward" />
      </button>
    );

    let theList;
    let bool = true;
    theList = (
      <div>
        <div className="row wip-inv-list-header">
          <div className="col-10 text-left wip-inv-list-lbl">
            Invoice Id | Customer Name ( Email ) | Date
          </div>
          <div className="col-2 text-center wip-inv-list-lbl">Ops</div>
        </div>
        <div className="fixedsize-invoice-list-box">
          {this.state.invoices.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    bool ? "row row-col-details-dr" : "row row-col-details-lt"
                  }
                >
                  <div className="col-10 text-left task-pm-select-lbl">
                    {obj.invoiceId} | {obj.customerName} ({obj.customerEmail}) |{" "}
                    {obj.created_at.substring(0, 10)}
                  </div>
                  <div className="col-2 text-center">
                    <button
                      onClick={() => this.selectThisInvoice(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                      className="btn_reg_40"
                    >
                      Sel
                    </button>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="manual-invoice-list-box">
        <div className="text-center manual-inv-list-header">
          {" "}
          WIP Invoice list &nbsp;&nbsp;&nbsp;&nbsp; {addLineButton}
        </div>
        {theList}
        <div
          className={
            this.state.errFlag
              ? "text-center manual-inv-msg-err"
              : "text-center manual-inv-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

export default WipInvoiceList;
