import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { hideModal } from "../../../../actions/modalActions";
import "../../../css/localModal.css";
import "./ShowInvestDoc.css";

import PDFViewer from "../../../pdfviewer/PDFViewer";
import WebviewerBackend from "../../../pdfviewer/webviewer";

class ShowInvestDoc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      todotext: "",
    };

    this.closeModal = this.closeModal.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount = async () => {
    // console.log("~~~~~~~~~ this.props:", this.props);
  };

  closeModal(e) {
    e.preventDefault();

    // this.props.hideModal(modTask);
    this.props.hideModal();
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <div className="container">
        <div className="modal-content-z">
          <div className="row text-center justify-content-center">
            <div className="modal-header-text-info">
              {this.props.message.Header}
            </div>
          </div>

          {/* <div className="modal-body"> */}
          <div>
            <div className="fixedsize-pdf-modal">
              {/* <div>   */}
              <div className="row text-center message-section">
                <div className="col text-center pdf-msg">
                  {this.props.message.topline1}
                </div>
              </div>
              <div className="row">
                <div className="col-12 text-center">
                  {this.props.message.docType === "company" ? (
                    <div>
                      {" "}
                      <PDFViewer
                        backend={WebviewerBackend}
                        src={this.props.message.theurl}
                      />
                    </div>
                  ) : null}
                  {/* <PDFViewer
                    backend={WebviewerBackend}
                    src={this.props.message.theurl}
                  /> */}
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer-z">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={this.closeModal}
              onChange={this.onChange}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <strong>Close</strong> &nbsp;
              <div className="float-right">
                <i className="far fa-window-close" />
              </div>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

ShowInvestDoc.propTypes = {
  hideModal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { hideModal })(ShowInvestDoc);
