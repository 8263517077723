import React, { Component } from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./PaymentFilter.css";

class GLSearchFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showGLRepFlag: true,
      showSearchFlag: false,
      dateSelectFlag: true,
      showCategorySelFlag: false,

      dateFirst: new Date(),
      dateSecond: new Date(new Date().setDate(new Date().getDate() + 1)),

      filterType: "all",

      categories: [],
      selectedCategory: null,

      glMeta: [],
      selectedGLMeta: null,
      selheightad: 1,

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {};

  exitFilter = () => {
    let cond = null;
    this.props.returnToCaller(cond);
  };

  handleDateFirst = async (date) => {
    let result = new Date(date);
    result.setDate(result.getDate() + 1);
    this.setState({
      dateFirst: date,
      dateSecond: result,
    });
  };

  handleDateSecond = async (date) => {
    this.setState({
      dateSecond: date,
    });
  };

  sendSearchCondition = () => {
    let cond = {
      dateFrom: this.state.dateFirst,
      dateTo: this.state.dateSecond,
    };
    // console.log("cond:", cond);
    this.props.returnToCaller(cond);
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('PaymentFilter...');

    let searchButton = (
      <button
        onClick={this.sendSearchCondition}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_80"
      >
        Fetch <i className="fas fa-search-plus" />
      </button>
    );

    let exitButton = (
      <button
        onClick={this.exitFilter}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let dateSelectionPanel;
    dateSelectionPanel = (
      <div className="filter-gl-date-row">
        <div className="row">
          <div className="col-2 text-right gl-report-date-filter">From</div>
          <div className="col-4 text-left">
            <DatePicker
              selected={this.state.dateFirst}
              onChange={this.handleDateFirst}
              onSelect={this.handleDateFirst}
              name="prdob"
              dateFormat="MM/dd/yyyy"
            />
          </div>
          <div className="col-1 text-right gl-report-date-filter">To</div>
          <div className="col-5 text-left">
            <DatePicker
              selected={this.state.dateSecond}
              onChange={this.handleDateSecond}
              onSelect={this.handleDateSecond}
              name="prdob"
              dateFormat="MM/dd/yyyy"
              minDate={this.state.dateSecond}
            />
          </div>
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="gl-filter-box">
        <div className="text-center ">
          Payment Filter &nbsp;&nbsp;{searchButton}&nbsp;{exitButton}
          {dateSelectionPanel}
        </div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default GLSearchFilter;
