import React, { Component } from "react";

import "./Prioritize.css";

class Prioritize extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      todayprio: [],
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let clonedtoday = [...this.props.today];
    // console.log('1 clonedtoday:', clonedtoday);

    await this.setState({
      deviceSize,
      todayprio: clonedtoday,
    });
  };

  returnToEditor = () => {
    // console.log('1. this.props.today:', this.props.today);
    // console.log('1. this.state.today:', this.state.todayprio);
    this.props.returnToCallerx( this.props.today, 'cancel');
  };

  saveAndReturn = () => {
    // console.log('2 this.props.today:', this.props.today);
    // console.log('2. changed this.state.today:', this.state.todayprio);
    this.props.returnToCallerx( this.state.todayprio, 'modified');
  }

  handleUp = async (inp) => {
    // console.log("handleUp inp:", inp);
    let loctoday = this.state.todayprio;
    let temp1 = null,
      temp2 = null;
    // loctoday.forEach((obj) => {
    //   console.log('47 obj:', obj);
    //   if (obj.seqNo === inp.seqNo - 1) {
    //     temp1 = obj;
    //   }
    //   if (obj.seqNo === inp.seqNo) {
    //     temp2 = obj;
    //   }
    // });
    // console.log('1 loctoday:', loctoday);
    loctoday.forEach((obj) => {
      // console.log('47 obj:', obj, ' inp:', inp);
      if (obj.seqNo === inp  - 1) {
        temp1 = JSON.parse(JSON.stringify(obj));
      }
      if (obj.seqNo === inp ) {
        temp2 = JSON.parse(JSON.stringify(obj));
      }
    });
   
    let sn = 1;
    let loct = [];
 
    for (var j=0; j < loctoday.length; j++) {
      let obj = loctoday[j];
      // console.log(j, 'obj:',obj, ' obj.seqNo:', obj.seqNo, ' sn:', sn, ' inp:', inp);
      let inhere = false;
      if ( obj.seqNo === inp - 1) {
 
        inhere = true;
        temp2.seqNo = sn;
        loct.push(temp2);
      } 
      if ( obj.seqNo === inp ) {
 
        inhere = true;
        temp1.seqNo = sn;
        loct.push(temp1);
      }   
      if ( !inhere) {
        obj.seqNo = sn;
        loct.push(obj);
      }
      sn++;
    }
 
 
    await this.setState({
      todayprio: loct,
    });
  };

  handleDown = async (inp) => {
    // console.log("handleDown inp:", inp);
    let loctoday = this.state.todayprio;
    let temp1 = null,
      temp2 = null;
    // let up = inp.seqNo + 1;
    // let down = inp.seqNo;
    let up = inp + 1;
    let down = inp;
 

    // console.log('1 loctoday:', loctoday);
    loctoday.forEach((obj) => {
      // console.log('47 obj:', obj, ' inp:', inp);
      if (obj.seqNo === inp  ) {
        temp1 = JSON.parse(JSON.stringify(obj));
      }
      if (obj.seqNo === inp + 1) {
        temp2 = JSON.parse(JSON.stringify(obj));
      }
    });
    // console.log('temp1:', temp1);
    // console.log('temp2:', temp2);
   
    let sn = 1;
    let loctx = [];
 


    loctoday.forEach((obj) => {
 
      if (obj.seqNo === up) {
   
        temp1.seqNo = sn;
        loctx.push(temp1);
      }
      if (obj.seqNo === down) {
     
        temp2.seqNo = sn;
        loctx.push(temp2);
      }
      if ((obj.seqNo !== up) & (obj.seqNo !== down)) {
      
        obj.seqNo = sn;
        loctx.push(obj);
      }
      sn++;
    });
    // console.log("loctx:", loctx);


    await this.setState({
      todayprio: loctx,
    });
  };


  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('Prioritize...')

    let bool = true;
    let priorityPanel;
    priorityPanel = (
      <div>
        <div className="fixedsize_news_priority">
          {this.state.todayprio.map((obj, i) => {
            // console.log(i, ". 140  obj:", obj);
            return (
              <div key={i}>
                <div
                  className={`${
                    bool ? "row-color-priority-drk" : "row-color-priority-lit"
                  }`}
                >
                  <div className="row">
                    <div className="col-9 text-left news-posiion-list">
                      {obj.seqNo}. &nbsp;{obj.newsTitle}
                    </div>
                    <div className="col-3 text-center">
                      {obj.seqNo === 1 ? (
                        <button
                          className="btn-priority-noops"
                        >
                          &nbsp;
                        </button>
                      ) : (
                        <button
                          className="btn-priority-ops-1"
                          onClick={() => this.handleUp(obj.seqNo)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <b>Up</b>{obj.seqNo}
                        </button>
                      )}
                      {obj.seqNo === this.state.todayprio.length ? (
                        <button
                          className="btn-priority-noops-dn"
                        >
                          &nbsp;
                        </button>
                      ) : (
                        <button
                          className="btn-priority-opsx"
                          onClick={() => this.handleDown(obj.seqNo)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          <b>Dn</b>{obj.seqNo}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="hub-gap" />
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let backButton = (
      <button
        className="btn_back_main"
        onClick={this.returnToEditor}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let buttonPanel;
    buttonPanel = (
      <div>
        {/* <button
          onClick={this.returnToEditor}
          className="btn_reg_60"
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          Cancel
        </button> */}

        <button
          onClick={this.saveAndReturn}
          className="btn_reg_60"
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          Done
        </button>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="editor-priority-box text-center news-prio-head">
         Prioritize  &nbsp;{backButton}      
         <div className="text-center priority-msg-count">Number of Messages: {this.state.todayprio.length}</div>
        {priorityPanel}
        {buttonPanel}
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

export default Prioritize;
