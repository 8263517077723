import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import axios from "axios";

import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./DefineTaskList.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveUpdtEvent = "/routes/interact/saveUpdtEvent";
const getAnEvent = "/routes/inetract/getAnEvent?";

const readMeCode = "1145900000";

class DefineTaskList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      theEvent: null,
      tasklist: [],

      endDate: new Date(),
      startDate: new Date(),

      taskName: "",
      description: "",
      subTeam: [],
      taskId: 0,
      allocatedAmt: 0,
      allocatedAmount: 0,

      memberSelHeight: 4,
      updtMsg: "",
      updateErrFlag: false,
      openTaskDefFlag: false,

      showSaveButton: false,
    };
  }

  componentDidMount = async () => {
    let theTeam = [];

    // if (this.props.communityId && this.props.communityId > 0)
    //   await this.getMembers();
    if (this.props.theEvent && this.props.theEvent.theTeam)
      theTeam = this.props.theEvent.theTeam;

    let tasklist = [];
    this.props.theEvent.prepTasks.forEach((obj) => {
      tasklist.push(obj);
    });

    this.setState({
      theEvent: this.props.theEvent,
      theTeam,
      tasklist,
    });
    // if (document.getElementById("taskMemSelected"))
    //   document.getElementById("taskMemSelected").value = "DEFAULT";
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChangePrice = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      this.setState({
        [e.target.name]: val,
      });
    } else {
      this.setState({
        // [e.target.name]: value,
        [e.target.name]: value,
      });
    }
  };

  getTheEvent = async (eventId) => {
    let param = "eventId=" + eventId;

    let url = baandaServer + getAnEvent + param;
    // console.log("url:", url);
    let theEvent = null;
    try {
      let eret = await axios.get(url);
      if (eret.data.status === "success") {
        theEvent = eret.data.Msg;
      } else {
        // console.log("Err data:", eret.data.Msg);
      }
    } catch (err) {
      console.log("Err sys:", err.message);
    }
    return theEvent;
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  onChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  packDataForDB = async () => {
    let coopStoreId = 0;
    if (this.state.store) coopStoreId = this.props.store.coopStoreId;

    let adjTaskList = this.state.tasklist;
    // console.log("adjTaskList:", adjTaskList);

    let prepTask = this.props.theEvent.prepTasks;
    let newTaskList = [];
    adjTaskList.forEach((obj) => {
      let existingTask = false;
      let taskObj;
      prepTask.forEach((elm) => {
        if (elm.taskId === obj.taskId) {
          taskObj = {
            taskId: obj.taskId,
            subTeam: obj.subTeam,
            taskName: obj.taskName,
            description: obj.description,
            comments: elm.comments,
            startDate: obj.startDate,
            endDate: obj.endDate,
            amountAllocated: parseFloat(obj.amountAllocated),
            amountSpent: elm.amountSpent,
          };
          newTaskList.push(taskObj);
          existingTask = true;
        }
      });
      if (!existingTask) {
        taskObj = {
          taskId: obj.taskId,
          subTeam: obj.subTeam,
          taskName: obj.taskName,
          description: obj.description,
          comments: [],
          startDate: obj.startDate,
          endDate: obj.endDate,
          amountAllocated: parseFloat(obj.amountAllocated),
          amountSpent: 0,
        };
        newTaskList.push(taskObj);
      }
    });

    // console.log("newTaskList:", newTaskList);

    let input = {
      communityId: this.props.communityId,
      coopStoreId,
      eventId: this.state.theEvent.eventId,
      prepTasks: newTaskList,
      module: "preptasks",
      clientProgram: "DefineTaskList.js",
      clientFunction: "handleTaskSave",
    };

    return input;
  };

  handleTaskSave = async () => {
    let url = baandaServer + saveUpdtEvent;

    let inp = await this.packDataForDB();
    // console.log("inp:", inp);
    if (inp.prepTasks.length > 0) {
      // console.log(" url:", url, " inp:", inp);
      try {
        let resp = await axios.post(url, inp);
        // console.log("rest:", resp);
        if (resp.data.status === "success") {
          this.setState({
            // theEvent: resp.data.Msg.theEvent,
            updtMsg: "Tasks updated.",
            updateErrFlag: false,
            endDate: new Date(),
            startDate: new Date(),
            taskName: "",
            description: "",
            subTeam: [],
            taskId: 0,
            allocatedAmt: 0,
            allocatedAmount: 0,
            memberSelHeight: 4,
            openTaskDefFlag: false,
          });
          // await this.returnToCreateTask();
          // await this.props.returnToCaller(resp.data.Msg.theEvent);
          let tet = await this.getTheEvent(this.props.theEvent.eventId);
          this.setState({
            theEvent: tet.data.Msg,
            tasklist: tet.data.Msg.prepTasks,
          });
        }
      } catch (err) {
        console.log("handle Task Save err:", err.message);
      }
    } else {
      this.setState({
        updtMsg: "No valid & complete tasks to update.",
        updateErrFlag: true,
      });
    }
  };

  returnToCreateTask = async () => {
    this.props.returnToCaller(this.state.theEvent);
  };

  handleMemberSelect = async () => {
    let value = document.getElementById("taskMemSelected").value;
    let obj = JSON.parse(value);
    // console.log("obj:", obj);

    await this.createTheTaskTeam(obj);
    if (document.getElementById("taskMemSelected"))
      document.getElementById("taskMemSelected").value = "DEFAULT";
  };

  handleTaskDelete = async (task) => {
    let newTaskList = [];
    this.state.tasklist.forEach((obj) => {
      if (obj.taskId !== task.taskId) {
        newTaskList.push(obj);
      }
    });
    // if (task.taskId === this.state.taskId) {
    await this.setState({
      tasklist: newTaskList,
      taskName: "",
      openTaskDefFlag: false,
      endDate: new Date(),
      startDate: new Date(),
      description: "",
      subTeam: [],
      taskId: 0,
      allocatedAmt: 0,
      showSaveButton: true,
    });
  };

  addTaskName = async () => {
    let taskId = 0;
    let tasks = [...this.state.tasklist];
    let newtask = true;
    this.state.tasklist.forEach((obj) => {
      if (obj.taskName === this.state.taskName) newtask = false;
      if (obj.taskId > taskId) taskId = obj.taskId;
    });

    if (newtask && this.state.taskName !== "") {
      let aTask = {
        taskId: taskId + 1,
        taskName: this.state.taskName,
        subTeam: [],
        description: "",
        // allocatedAmountxx: 0,
        amountAllocated: 0,
        endDate: new Date(),
        startDate: new Date(),
      };

      tasks.push(aTask);
    }

    await this.setState({
      tasklist: tasks,
      taskName: "",
      openTaskDefFlag: false,
      endDate: new Date(),
      startDate: new Date(),
      description: "",
      subTeam: [],
      taskId: 0,
      allocatedAmount: 0,
    });
  };

  handleTaskDetail = async (task) => {
    let description = "";
    let startDate = new Date();
    let endDate = new Date();
    let subTeamaa = [];
    let amt = 0;
    this.state.tasklist.forEach((obj) => {
      if (obj.taskId === task.taskId) {
        description = obj.description;
        startDate = moment(obj.startDate).toDate();
        endDate = moment(obj.endDate).toDate();
        subTeamaa = obj.subTeam;
        amt = obj.amountAllocated;
      }
    });
    await this.setState({
      taskId: task.taskId,
      taskName: task.taskName,
      description,
      startDate,
      endDate,
      subTeam: subTeamaa,
      allocatedAmount: amt,
      openTaskDefFlag: true,
      showSaveButton: false,
    });
  };

  handleMemberAdd = async (mem) => {
    let subTeamy = [];
    this.state.subTeam.forEach((obj) => {
      subTeamy.push(obj);
    });

    let itExists = false;
    this.state.subTeam.forEach((obj) => {
      if (obj.baandaId === mem.baandaId) itExists = true;
    });
    if (!itExists) {
      let subTeamObj = {
        baandaId: mem.baandaId,
        email: mem.email,
        name: mem.name,
      };
      subTeamy.push(subTeamObj);
    }

    await this.setState({
      subTeam: subTeamy,
    });
  };

  handleMemberDelete = async (mem) => {
    let subTeam = [];
    this.state.subTeam.forEach((obj) => {
      if (obj.baandaId !== mem.baandaId) {
        subTeam.push(obj);
      }
    });
    await this.setState({
      subTeam,
    });
  };

  handleEndBy = async (date) => {
    await this.setState({
      endDate: date,
    });
  };

  handleStartDate = async (date) => {
    await this.setState({
      startDate: date,
      endDate: date,
    });
  };

  validateThisTask = async (task) => {
    console.log("validateThisTask task:", task);
    let isValid = true;
    let msg = "";

    if (task.subTeam.length === 0) {
      isValid = false;
      msg = msg + "Must assign at least one member for the task. | ";
    }

    if (task.description === "") {
      isValid = false;
      msg = msg + "Must provide a task description.";
    }
    return { status: isValid, Msg: msg };
  };

  handleUpdateThisTask = async () => {
    let aTask = {
      taskId: this.state.taskId,
      taskName: this.state.taskName,
      subTeam: this.state.subTeam,
      description: this.state.description,
      amountAllocated: parseFloat(this.state.allocatedAmount),
      endDate: this.state.endDate,
      startDate: this.state.startDate,
    };

    let isValid = await this.validateThisTask(aTask);

    let newTaskList = [];
    if (isValid.status) {
      if (this.state.tasklist.length > 0) {
        this.state.tasklist.forEach((obj) => {
          if (obj.taskId === this.state.taskId) {
            newTaskList.push(aTask);
          } else {
            newTaskList.push(obj);
          }
        });
      } else {
        newTaskList.push(aTask);
      }

      await this.setState({
        showSaveButton: true,
        taskId: 0,
        tasklist: newTaskList,
        taskName: "",
        startDate: new Date(),
        endDate: new Date(),
        description: "",
        subTeam: [],
        allocatedAmount: 0, // Reinit
        openTaskDefFlag: false,
        updateErrFlag: false,
        updtMsg: "",
      });
 
    } else {
      await this.setState({
        updateErrFlag: true,
        updtMsg: isValid.Msg,
      });
    }
  };

  handleClearThisTask = async () => {
    await this.setState({
      taskId: 0,
      taskName: "",
      startDate: new Date(),
      endDate: new Date(),
      description: "",
      subTeam: [],
      allocatedAmount: 0, // Reinit
      openTaskDefFlag: false,
      updateErrFlag: false,
      updtMsg: "",
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('DefineTaskList...')

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let taskNamePanel;
    taskNamePanel = (
      <div>
        <div className="row">
          <div className="col text-center">
            <input
              name="taskName"
              type="text"
              value={this.state.taskName}
              onChange={this.onChange}
              size="70"
              maxLength="70"
              className="event-task-name"
              placeholder="A new task name"
            />
            &nbsp;&nbsp;
            <button onClick={this.addTaskName} className="btn_reg_60">
              Add&nbsp;
              <i className="fa fa-plus" />
            </button>
            &nbsp;{infoModalButton}
          </div>
        </div>
      </div>
    );

    let buttonPanel = (
      <button
        onClick={this.handleTaskSave}
        className="btn_reg_50"
        type="button"
        style={{
          cursor: this.state.disabled ? "default" : "pointer",
        }}
      >
        Save
      </button>
    );

    let bool = true;
    let taskListPanel;
    taskListPanel = (
      <div className="cal-task-list-box">
        {this.state.tasklist.map((obj, i) => (
          <div key={i}>
            <div className={`${bool ? "row-task-team-a" : "row-task-team-b"}`}>
              <div
                className={
                  obj.taskId === this.state.taskId
                    ? "row task-active-row"
                    : "row"
                }
              >
                {/* <div className="row"> */}
                <div className="col-9 text-left">{obj.taskName}</div>
                <div className="col-3 text-center">
                  <button
                    onClick={() => this.handleTaskDetail(obj)}
                    className="btn_reg_70"
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    Define
                  </button>
                  <button
                    onClick={() => this.handleTaskDelete(obj)}
                    className="btn_exit_del"
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    <i className="fas fa-trash-alt" />
                  </button>
                </div>
              </div>
            </div>
            {(bool = !bool)}
          </div>
        ))}
      </div>
    );

    let teamPanel;
    if (this.state.theTeam && this.state.theTeam.length > 0) {
      teamPanel = (
        <div className="cal-sub-team-box">
          {this.state.theTeam.map((obj, i) => (
            <div key={i}>
              <div className="row">
                <div className="col-9 text-left sub-team-member">
                  {obj.name}&nbsp;({obj.email})
                </div>
                <div className="col-3 text-center">
                  <button
                    onClick={() => this.handleMemberDelete(obj)}
                    className="btn_exit_del"
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    <i className="fas fa-trash-alt" />
                  </button>
                  <button
                    onClick={() => this.handleMemberAdd(obj)}
                    className="btn_reg_40"
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    <i className="fa fa-plus" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }

    if (this.state.theTeam && this.state.theTeam.length === 0) {
      teamPanel = (
        <div className="team-not-created-msg">
          Please define your event team before giving responsibilities
        </div>
      );
    }

    let selectedSubTeamPanel;
    if (this.state.subTeam.length > 0) {
      let member = "";
      this.state.subTeam.forEach((obj) => {
        member = member + obj.name + " " + obj.email + " | ";
      });

      selectedSubTeamPanel = (
        <div className="cal-sub-team-selected-box">{member}</div>
      );
    }

    let taskDefinitionPanel;
    if (this.state.openTaskDefFlag) {
      taskDefinitionPanel = (
        <div className="cal-task-def-box">
          <div className="row task-date-row">
            <div className="col-md-6 task-def-lbl">
              Start:&nbsp;&nbsp;
              <DatePicker
                selected={this.state.startDate}
                onChange={this.handleStartDate}
                name="istartDate"
                minDate={moment().add("days", 1).toDate()}
                dateFormat="MM/dd/yyyy"
              />
            </div>
            <div className="col-md-6 task-def-lbl">
              End By: &nbsp;&nbsp;
              <DatePicker
                selected={this.state.endDate}
                onChange={this.handleEndBy}
                name="endDate"
                minDate={this.state.startDate}
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-2 text-right task-def-lbl">Describe</div>
            <div className="col-10 text-left">
              <textarea
                name="description"
                maxLength="1000"
                placeholder="Describe the task."
                rows="4"
                wrap="hard"
                spellCheck="true"
                className="task-description_textarea"
                onChange={this.onChange}
                value={this.state.description}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-2 text-right task-def-lbl">Select team</div>
            <div className="col-10 text-left">{teamPanel}</div>
          </div>
          <div className="row">
            <div className="col-2 text-right task-def-lbl">This task team</div>
            <div className="col-10 text-left">{selectedSubTeamPanel}</div>
          </div>
          <div className="row">
            <div className="col-2 text-right task-def-lbl">Allocated $:</div>
            <div className="col-4 text-left">
              <input
                name="allocatedAmount"
                type="number"
                value={this.state.allocatedAmount}
                onChange={this.onChangePrice}
                // onChange={this.onChange}
                size="20"
                className="task-budget"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
              />
            </div>
            <div className="col-6 text-left">
              <button
                onClick={this.handleUpdateThisTask}
                className="btn_reg_70"
                type="button"
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                Update
              </button>
              <button
                onClick={this.handleClearThisTask}
                className="btn_back_main"
                type="button"
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                <i className="fas fa-step-backward" />
              </button>
            </div>
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        {taskNamePanel}
        {taskListPanel}
        {taskDefinitionPanel}
        {this.state.showSaveButton ? buttonPanel : null}
        <div
          className={
            this.state.updateErrFlag ? "team-update-msg-err" : "team-update-msg"
          }
        >
          {this.state.updtMsg}
        </div>
      </div>
    );

    return (
      <div>
        <div className="task-list-header">Define Tasks</div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

DefineTaskList.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DefineTaskList));
// export default DefineTaskList;
