import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import axios from "axios";
import moment from "moment";

import "./ManageCarts.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCommunityCarts = "/routes/dashboard/getCommunityCarts?";
const reSelveACart = "/routes/dashboard/reSelveACart";

const readMeCode = "1112100000";

class ManageCarts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      carts: [],
      contents: null,
      showCartContentFlag: false,

      errMsg: '',
      errFlag: false
    };
  }

  componentDidMount = async () => {
    await this.getCommunityCarts();
  };

  openAlertModal = () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  getCommunityCarts = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      this.props.coopStoreId +
      "&customerName=";
    let url = baandaServer + getCommunityCarts + params;
    try {
      let retcart = await axios.get(url);
      // console.log("retcart:", retcart);
      if (retcart.data.status === "success") {
        this.setState({
          carts: retcart.data.Msg,
          errMsg: '',
          errFlag: false
        });
      } else {
        this.setState({
          errMsg: retcart.data.Msg,
          errFlag: false
        });
      }
    } catch (err) {
      console.log("Error:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: false
      });
    }
  };

  selectThisItemToDelete = async (dinp) => {
    dinp.clientProgram = "ManageCarts";
    dinp.clientFunction = "selectThisItemToDelete";
    // console.log("dinp:", dinp);
    let url = baandaServer + reSelveACart;
    try {
      let retcart = await axios.post(url, dinp);
      // console.log("retcart:", retcart);
      if (retcart.data.status === "success") {
        let rcart = [];
        this.state.carts.forEach((obj) => {
          if (obj.cartId !== dinp.cartId) rcart.push(obj);
        });

         this.setState({
          carts: rcart,
          showCartContentFlag: false,
          errMsg: '',
          errFlag: false
        });
      } else {
        this.setState({
          errMsg: retcart.data.Msg,
          errFlag: true
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true
      });
    }
  };

  showContentsOfCart = async (sinp) => {
    // console.log("showContentsOfCart sinp:", sinp);
    this.setState({
      contents: sinp.cartContent,
      showCartContentFlag: true,
      errMsg: '',
      errFlag: false
    });
  };

  unshowCartContents = async () => {
    this.setState({
      contents: null,
      showCartContentFlag: false,
      errMsg: '',
      errFlag: false
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ManageCarts...");

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let cartsPanel;

    if (this.state.carts.length > 0) {
      let bool = true;
      cartsPanel = (
        <div>
          <div className="row sub-item-list-row">
            <div className="col-2 text-center cart-list-header-lbl">Ops</div>
            <div className="col-2 text-center cart-list-header-lbl">Type</div>
            <div className="col-4 text-center cart-list-header-lbl">
              Cart Of
            </div>
            <div className="col-4 text-center cart-list-header-lbl">
              Last Touched
            </div>
          </div>
          <div className="fixedsize-sub-carts-list">
            {this.state.carts.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-2 text-center">
                      <button
                        className="btn_exit_main"
                        type="button"
                        onClick={() => this.selectThisItemToDelete(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                      <button
                        className="btn_reg_40"
                        type="button"
                        onClick={() => this.showContentsOfCart(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        ...
                      </button>
                    </div>
                    <div className="col-2 text-left item-category-label">
                      {obj.cartOfType}
                    </div>
                    <div className="col-4 text-left item-category-label">
                      {obj.customerName}
                    </div>
                    <div className="col-4 text-left item-category-label">
                      {moment(obj.cart_date).format("YYYY-MM-DD hh:mm A")}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    } else {
      cartsPanel = (
        <div className="text-center no-carts-msg">
          No carts in use right now.
        </div>
      );
    }

    let cartContentPanel;
    let bool1 = true;
    if (this.state.showCartContentFlag && this.state.contents) {
      cartContentPanel = (
        <div className="catshow-cart-mgmt-box-x">
          <div className="row sub-item-list-row">
            <div className="col-6 text-center cart-list-header-lbl">
              Item Name
            </div>
            <div className="col-2 text-center cart-list-header-lbl">Price</div>
            <div className="col-4 text-right cart-list-header-lbl">
              Quantity &nbsp;&nbsp;
              <button
                className="btn_back_main"
                type="button"
                onClick={this.unshowCartContents}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <i className="fas fa-step-backward" />
              </button>
            </div>
          </div>
          <div className="fixedsize-sub-carts-list-x">
            {this.state.contents.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool1
                        ? "row row-col-schdule-dr"
                        : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-6 text-left item-category-label">
                      {obj.itemName}
                    </div>
                    <div className="col-2 text-center item-category-label">
                      {this.commaFormattedCurrency(obj.price)}
                    </div>
                    <div className="col-4 text-center item-category-label">
                      {obj.quantity}
                    </div>
                  </div>
                  {(bool1 = !bool1)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        <div className="catshow-cart-mgmt-box">
          <div className="text-center cart-mgmt-header">
            Cart Management &nbsp;&nbsp;{infoButton}
          </div>
          {cartsPanel}
        </div>
        {cartContentPanel}
        <div className={this.state.errFlag ? "text-center catlog-cart-mgmt-msg-err" : "text-center catlog-cart-mgmt-msg"}>{this.state.errMsg}</div>
      </div>
    );
    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ManageCarts.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ManageCarts)
);
