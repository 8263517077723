import React, { Component } from "react";

import axios from "axios";
import LoadFileToS3 from "../../../../../../utils/components/fileUpload/LoadFileToS3";

import "./Upload.css";

const imageTypes = ["jpg", "jpeg", "bmp", "tiff", "png", "gif", "webp"];
const videoTypes = ["mp4", "webm"];
const pdfTypes = ["pdf"];

const maxImageSize = 5000000; // 5MB
const maxVideoSize = 150000000; // 150mb
const maxPdfSize = 10000000; // 50 MB

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveUpdtEvent = "/routes/interact/saveUpdtEvent";

class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      videoS3FileData: null,

      imageS3FileData: null,
      pdfS3FileData: null,

      eventSubDirName: "",

      typeSelectFlag: true,
      imageTypeFlag: false,
      videoTypeFlag: false,

      mmsMediaType: "mmsimage",

      openS3imageFlag: false,
      openS3videoFlag: false,
      openS3mmsimageFlag: false,
      openS3mmsvideoFlag: false,

      attachVideoFlag: false,
      attachImageFlag: false,
      attachPdfFlag: false,

      openButtonPanel: true,

      theEvent: null,
      eventId: 0,

      attachMsg:
        "Please wait a moment to upload depending on the size of your image or video. Max Media Sizes --- Image: 5MB, Video: 150MB, pdf: 10MB . ",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let eventId = 0;
    if (this.props.theEvent.eventId && this.props.theEvent.eventId > 0) {
      eventId = this.props.theEvent.eventId;
    }

    let fu = this.props.theEvent.fileUploads;
    let videoS3FileData = null;
    let imageS3FileData = null;
    let pdfS3FileData = null;

    let eventSubDirName = this.state.eventSubDirName;
    if (
      this.props.theEvent.s3subBucket &&
      this.props.theEvent.s3subBucket !== ""
    ) {
      eventSubDirName = this.props.theEvent.s3subBucket;
    }

    fu.forEach((obj) => {
      if (obj.type === "image") {
        imageS3FileData = obj;
      }
      if (obj.type === "video") {
        videoS3FileData = obj;
      }
      if (obj.type === "pdf") {
        pdfS3FileData = obj;
      }
    });

    await this.setState({
      deviceSize,
      theEvent: this.props.theEvent,
      eventId,
      videoS3FileData,
      imageS3FileData,
      pdfS3FileData,

      eventSubDirName,
    });
  };

  randfn = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  handleMMSMedium = async (e) => {
    await this.setState({
      mmsMediaType: e.target.value,
    });
  };

  setUploadType = async (imgType) => {
    // console.log("imgType:", imgType);
    await this.setState({
      attachVideoFlag: false,
      attachImageFlag: false,
      attachPdfFlag: false,
      openButtonPanel: false,
    });

    let randomct, commrn, subBucket;
    if (this.state.eventSubDirName === "") {
      randomct = this.randfn(100000000, 999999999);
      commrn = randomct.toString(16);
      subBucket =
        "event-cid" +
        this.props.communityId +
        "-eventId" +
        this.state.eventId +
        "-da-" +
        commrn;
    } else subBucket = this.state.eventSubDirName;

    switch (imgType) {
      case "image":
        await this.setState({
          attachImageFlag: true,
        });
        break;
      case "video":
        await this.setState({
          attachVideoFlag: true,
        });
        break;
      case "pdf":
        await this.setState({
          attachPdfFlag: true,
        });
        break;

      default:
        console.log("Bad image type :" + imgType);
    }

    await this.setState({
      eventSubDirName: subBucket,
    });
  };

  returnToUpload = async (fileData) => {
    if (fileData.ops === "done") {
      // console.log("Check S3 fileData: ", fileData);

      let videoS3FileData = this.state.videoS3FileData;
      let imageS3FileData = this.state.imageS3FileData;
      let pdfS3FileData = this.state.pdfS3FileData;

      let adjustedDASize = 0;

      let previousImageSize = 0;
      if (this.state.imageS3FileData)
        previousImageSize = this.state.imageS3FileData.size;
      let previousVideoSize = 0;
      if (this.state.videoS3FileData)
        previousVideoSize = this.state.videoS3FileData.size;
      let previousPdfSize = 0;
      if (this.state.pdfS3FileData)
        previousPdfSize = this.state.pdfS3FileData.size;

      if (this.state.attachVideoFlag) {
        if (fileData.ifDeletedFlag) {
          videoS3FileData = null;
        }
        let newFileSize = 0;
        if (fileData.s3FileData) {
          videoS3FileData = fileData.s3FileData;
          newFileSize = fileData.s3FileData.size;
          // await this.uploadFileInDB(videoS3FileData, "video");
        }
        adjustedDASize = newFileSize - previousVideoSize;
        await this.uploadFileInDB(videoS3FileData, adjustedDASize, "video");
      }

      // let imageExists = this.state.imageExists;
      if (this.state.attachImageFlag) {
        if (fileData.ifDeletedFlag) {
          imageS3FileData = null;
        }
        let newFileSize = 0;
        if (fileData.s3FileData) {
          // console.log("fileData.s3FileData:", fileData.s3FileData);
          imageS3FileData = fileData.s3FileData;
          newFileSize = fileData.s3FileData.size;
        }
        adjustedDASize = newFileSize - previousImageSize;
        await this.uploadFileInDB(imageS3FileData, adjustedDASize, "image");
      }

      if (this.state.attachPdfFlag) {
        if (fileData.ifDeletedFlag) {
          pdfS3FileData = null;
        }
        let newFileSize = 0;
        if (fileData.s3FileData) {
          // console.log("fileData.s3FileData:", fileData.s3FileData);
          pdfS3FileData = fileData.s3FileData;
          newFileSize = fileData.s3FileData.size;
        }
        // console.log('@@@@ pdfS3FileData:', pdfS3FileData);
        adjustedDASize = newFileSize - previousPdfSize;
        await this.uploadFileInDB(pdfS3FileData, adjustedDASize, "pdf");
      }

      await this.setState({
        attachVideoFlag: false,
        attachImageFlag: false,
        attachPdfFlag: false,
        openButtonPanel: true,

        videoS3FileData,
        imageS3FileData,
        pdfS3FileData,
      });
    }
    if (fileData.ops === "cancel") {
      await this.setState({
        attachVideoFlag: false,
        attachImageFlag: false,
        attachPdfFlag: false,
        openButtonPanel: true,
      });
    }
  };

  uploadFileInDB = async (digitalFile, adjustedDASize, type) => {
    // console.log("inside uploadFileInDB");
    let input = {
      digitalFile,
      eventId: this.state.eventId,
      s3subBucket: this.state.eventSubDirName,
      type: type,
      adjustedDASize,
      module: "attach",
      communityId: this.props.communityId,
    };
    // console.log(">>> uploadFileInDB input:", input);

    let url = baandaServer + saveUpdtEvent;
    try {
      let resp = await axios.post(url, input);
      // console.log("Upload resp:", resp);
      if (resp.data.status === "success") {
        await this.setState({
          theEvent: resp.data.Msg.theEvent,
          attachMsg: "Attachment handled successflly.",
        });

        await this.props.returnToCaller(this.state.theEvent, "justupdate");
      } else {
        this.setState({
          attachMsg: resp.data.Msg,
        });
      }
    } catch (err) {
      console.log("uploadFileInDB:", err.message);
      this.setState({
        attachMsg: err.message,
      });
    }
  };

  saveUpdateUpload = async () => {
    // This will repeat set state not really needed. Redundant.
    this.props.returnToCaller(this.state.theEvent, "done");
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("Upload...");

    let eventBodyBtnPanel;
    let buttonPanel;
    if (
      this.props.theEvent.eventStatus === "published" ||
      this.props.theEvent.eventStatus === "canceled"
    ) {
      eventBodyBtnPanel = (
        <div className="text-center upload-published-msg">
          This event has been published. Digital asset management is inactive.
          You can copy and re-publish if needed.
        </div>
      );
    } else {
      eventBodyBtnPanel = (
        <div>
          <button
            className="btn_reg_75"
            type="button"
            onClick={() => this.setUploadType("image")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Picture
          </button>
          <button
            className="btn_reg_75"
            type="button"
            onClick={() => this.setUploadType("video")}
            // onClick={() => this.testFn("video")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Video
          </button>
          <button
            className="btn_reg_75"
            type="button"
            onClick={() => this.setUploadType("pdf")}
            // onClick={() => this.testFn("video")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Pdf
          </button>
        </div>
      );
      buttonPanel = (
        <button
          className="btn_back_main"
          type="button"
          onClick={this.saveUpdateUpload}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
      );
    }

    let outputPanel;
    if (this.state.openButtonPanel) {
      outputPanel = (
        <div className="text-center">
          <div>{eventBodyBtnPanel}</div>
          {buttonPanel}
          <div className="text-center event-attach-message">
            {this.state.attachMsg}
          </div>
        </div>
      );
    }

    if (this.state.attachVideoFlag) {
      outputPanel = (
        <div>
          <LoadFileToS3
            returnToCaller={this.returnToUpload}
            communityId={this.props.communityId}
            fileType={videoTypes}
            maxSize={maxVideoSize}
            s3subdir={this.state.eventSubDirName}
            requestor="Event's Attachment"
            s3FileData={this.state.videoS3FileData}
            type="video"
          />
        </div>
      );
    }

    if (this.state.attachImageFlag) {
      outputPanel = (
        <div>
          <LoadFileToS3
            returnToCaller={this.returnToUpload}
            communityId={this.props.communityId}
            fileType={imageTypes}
            maxSize={maxImageSize}
            s3subdir={this.state.eventSubDirName}
            requestor="Event's Attachment"
            s3FileData={this.state.imageS3FileData}
            type="image"
          />
        </div>
      );
    }

    if (this.state.attachPdfFlag) {
      outputPanel = (
        <div>
          <LoadFileToS3
            returnToCaller={this.returnToUpload}
            communityId={this.props.communityId}
            fileType={pdfTypes}
            maxSize={maxPdfSize}
            s3subdir={this.state.eventSubDirName}
            requestor="Event's Attachment"
            s3FileData={this.state.pdfS3FileData}
            type="pdf"
          />
        </div>
      );
    }

    // if (this.state.attachMmsImageFlag) {
    //   outputPanel = (
    //     <div>
    //       <LoadFileToS3
    //         returnToCaller={this.returnToUpload}
    //         communityId={this.props.communityId}
    //         fileType={imageTypes}
    //         maxSize={maxmmsImageSize}
    //         s3subdir={this.state.eventSubDirName}
    //         requestor="Event's Attachment"
    //         s3FileData={this.state.mmsImageS3FileData}
    //         type="mmsimage"
    //       />
    //     </div>
    //   );
    // }

    // if (this.state.attachMmsVideoFlag) {
    //   outputPanel = (
    //     <div>
    //       <LoadFileToS3
    //         returnToCaller={this.returnToUpload}
    //         communityId={this.props.communityId}
    //         fileType={videoTypes}
    //         maxSize={maxmmsVideoSize}
    //         s3subdir={this.state.eventSubDirName}
    //         requestor="Event's Attachment"
    //         s3FileData={this.state.mmsVideoS3FileData}
    //         type="mmsvideo"
    //       />
    //     </div>
    //   );
    // }

    return <div>{outputPanel}</div>;
  }
}

export default Upload;
