import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import ReactS3 from "react-s3";
import axios from "axios";

// import _ from "lodash";

import AnItemsInventory from "./AnItemsInventory";
import ItemFilter from "./ItemFilter";
import Organizer from "./mgmt/Organizer";
import ItemComposition from "./mgmt/ItemComposition";
import ComposePackage from "./subscriptionComposition/ComposePackage";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./InventoryMgmt.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getItemsToActOn = "/routes/dashboard/getItemsToActOn?";

const readMeCode = "1111120000";

class InventoryMgmt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPaginationPanelFlag: false,

      messages: [],
      currentPage: 1, // page selected to show the rows of items on that page
      noOfRowsPerPage: 10, // x (number of rows the show per page - constant)
      noOfButtonsToShow: 6, // y (number of buttons to show at the bottom - constant)
      fistPageOfSetIndex: 1, // f (first line of the set to display - vary -initiate with 1)
      messagesOnScreen: [],

      inventories: [],

      showpagi: true,
      showFilterFlag: false,
      anItem: {},
      openItemFlag: false,
      invOrganizerFlag: false,
      s3Url: "",
      filterData: "",

      invClassification: "catalog",
      filterType: "all",
      category: "",
      searchItemName: "",

      deviceSize: "",
      errmsg: "",
      itemType: "Goods",

      itemCompositionFlag: false,
      subItemComposeFlag: false,

      store: this.props.store,
    };
  }

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    let showErrMsgPanel = false;
    let showPagi = true;
    let errmsg = "";
    if (deviceSize === "small") {
      showPagi = false;
      showErrMsgPanel = true;
      errmsg =
        'A device (not a smart phone) with a bigger display area is needed for the inventory managerial tasks. Please access this module on a larger device. "Play football on coffee table, you cannot." Yoda (or Baanda) says :).';
    } else {
      await this.getInventoryItems();
      await this.setPosts();
      await this.setPageNumbers();
    }

    await this.setState({
      deviceSize,
      showErrMsgPanel,
      showPagi,
      errmsg,
    });
  };

  getInventoryItems = async () => {
    // getInventoryItems = async (data) => {
    // console.log("----- data:", data);

    await this.setState({
      inventories: [],
    });

    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&filterType=" +
      this.state.filterType +
      "&invClassification=" +
      this.state.invClassification +
      "&itemType=" +
      this.state.itemType +
      "&category=" +
      this.state.category +
      "&filterByName=" +
      this.state.searchItemName +
      "&calledBy=inventorymgmt";

    let url = baandaServer + getItemsToActOn + params;
    // console.log("getInventoryItems ---- url:", url);
    try {
      let ret = await axios.get(url);
      // console.log("@@@@ ret:", ret);
      if (ret.data.status === "error") {
        throw new Error(ret.data.Msg);
      } else if (ret.data.Msg.length === 0) {
        await this.setState({
          errmsg:
            "No record for the filter. Please enter in item master per need.",
        });
      } else {
        await this.setState({
          inventories: ret.data.Msg,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  // Pagination =============================================
  setPageNumbers = async () => {
    let tlen = this.state.inventories.length; // tlen = total length of cart array to be picked up
    let f = this.state.fistPageOfSetIndex;
    let x = this.state.noOfRowsPerPage;
    let y = this.state.noOfButtonsToShow;
    let right = false,
      left = false;
    let showPaginationPanelFlag = true;

    let z = y; // Dynamic number of buttons to show (vary)
    // let st = 1; // starting page number is 1
    if (tlen <= x) {
      showPaginationPanelFlag = false;
    } else if (Math.ceil(tlen / x) <= y) {
      // no action needed
      z = Math.ceil(tlen / x); // z becomes the  number of page  number can be shown
    } else if (f <= 1) {
      left = false;
      right = true;
    } else if (f + y > Math.ceil(tlen / x)) {
      // Math.ceil(tlen/x) gives the total number of buttons can be shown
      left = true;
      right = false;
      z = Math.ceil(this.state.inventories.length / this.state.noOfRowsPerPage); // end button number in z
      //   st = f; // start button number from f
    } else {
      left = true;
      right = true;
      //   st = f;
      z = f + y - 1; //
    }

    let pageNumbers = [];
    for (let i = f; i <= z; i++) {
      pageNumbers.push(i);
    }

    await this.setState({
      leftArrowFlag: left,
      rightArrowFlag: right,
      showPaginationPanelFlag,
      pageNumbers,
    });
  };

  setPosts = async () => {
    let indexOfLastMessage =
      this.state.currentPage * this.state.noOfRowsPerPage;
    let indexOfFirstMessage = indexOfLastMessage - this.state.noOfRowsPerPage;
    let slicedMessages;

    if (indexOfLastMessage >= this.state.inventories.length) {
      slicedMessages = this.state.inventories.slice(indexOfFirstMessage);
    } else {
      slicedMessages = this.state.inventories.slice(
        indexOfFirstMessage,
        indexOfLastMessage
      );
    }

    slicedMessages.forEach((smg) => {
      let whqty = 0;
      smg.itemVariance.forEach((qty) => {
        // console.log('217 qty:', qty)
        if (!qty.deleted) whqty += qty.quantity;
      });
      smg.quantity = whqty;
    });

    await this.setState({
      messagesOnScreen: slicedMessages,
    });
  };

  paginate = async (input) => {
    await this.setState({
      currentPage: input,
    });
    await this.setPosts();
  };

  handleLeft = async () => {
    let ff = this.state.fistPageOfSetIndex - this.state.noOfButtonsToShow;
    if (ff < 1) ff = 1;

    await this.setState({
      fistPageOfSetIndex: ff,
    });

    await this.setPageNumbers();
  };

  handleRight = async () => {
    let ff = this.state.fistPageOfSetIndex + this.state.noOfButtonsToShow;
    if (ff > this.state.inventories.length)
      ff = this.state.inventories.length - this.state.noOfButtonsToShow;

    await this.setState({
      fistPageOfSetIndex: ff,
    });

    await this.setPageNumbers();
  };
  // ======================================================

  handleFilter = async () => {
    await this.setState({
      showFilterFlag: true,
      searchFlag: false,
      showpagi: false,
    });
  };

  handleInventory = async (inv) => {
    // console.log("handleInventory inv:", inv);
    let s3Url = null;

    if (inv.fileUploads && inv.fileUploads.length > 0) {
      inv.fileUploads.forEach((elm) => {
        // console.log('elm.type:', elm.type, ' s3Url:', elm.s3Url);
        if (elm.type === "image") {
          // console.log('here  111');
          s3Url = elm.location;
        }
      });
    }

    // Open inventry in a panel box.
    await this.setState({
      s3Url,
      anItem: inv,
      showpagi: false,
      openItemFlag: true,
    });
  };

  returnToInvMgmt = async () => {
    await this.getInventoryItems(this.state.filterData);
    await this.setPosts();
    await this.setPageNumbers();
    await this.setState({
      showpagi: true,
      openItemFlag: false,
      searchFlag: false,
      invOrganizerFlag: false,
    });
  };

  returnFromComposition = async () => {
    await this.getInventoryItems(this.state.filterData);
    await this.setPosts();
    await this.setPageNumbers();
    this.setState({
      showpagi: true,
      openItemFlag: false,
      searchFlag: false,
      invOrganizerFlag: false,
      itemCompositionFlag: false,
      subItemComposeFlag: false,
    });
  };

  returnAfterFilter = async (data) => {
    // console.log(">>>>>>>>>>>> data:", data);
    let icl, ft, cat, sin, itemType;
    if (data.ops === "fetch") {
      // console.log("In fetch data.filterType:", data.filterType);
      icl = data.invClassification;
      ft = data.filterType;
      cat = data.category;
      sin = data.searchItemName;
      itemType = data.itemType;
    } else {
      // console.log("in cancel");
      icl = this.state.invClassification;
      ft = this.state.filterType;
      cat = this.state.category;
      sin = this.state.searchItemName;
      itemType = this.state.itemType;
    }
    // console.log("sin:", sin);
    await this.setState({
      showpagi: true,
      openItemFlag: false,
      showFilterFlag: false,
      invClassification: icl,
      filterType: ft,
      category: cat,
      searchItemName: sin,
      itemType,
    });

    await this.getInventoryItems();
    await this.setPosts();
    await this.setPageNumbers();
  };

  handleInventoryMgmt = async () => {
    this.setState({
      showFilterFlag: false,
      anItem: {},
      openItemFlag: false,
      invOrganizerFlag: true,
    });
  };

  handleCompose = async () => {
    this.setState({
      showFilterFlag: false,
      openItemFlag: false,
      invOrganizerFlag: false,
      itemCompositionFlag: true,
      subItemComposeFlag: false,
    });
  };

  handleSubscriptionPkg = async () => {
    this.setState({
      showFilterFlag: false,
      openItemFlag: false,
      invOrganizerFlag: false,
      itemCompositionFlag: false,
      subItemComposeFlag: true,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('InventoryMgmt...');

    let purposHeader, colhead, sumtot; // service;
    if (this.state.deviceSize === "small") {
      purposHeader = "Inventory";
      colhead = "Item Name (Qty)";
      sumtot = "Items";
      //service = "Service";
    } else {
      purposHeader = "Inventory";
      colhead = "Item Name - (Category) (Qty)";
      sumtot = "No. of Items";
      //service = "Service"
    }

    if (this.state.itemType === "service") {
      colhead = "Item Name (All Services)";
    }

    let itemmstr;
    if (this.state.deviceSize === "small") {
      itemmstr = "Itmgmt";
    } else {
      itemmstr = "Item Mstr";
    }

    let buttonPanel = (
      <div>
        <button
          className="btn_reg_80"
          onClick={this.handleInventoryMgmt}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          {itemmstr}
        </button>

        <button
          className="btn_reg_80"
          type="button"
          onClick={this.handleCompose}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Compose
        </button>
        {this.props.store ? null : (
          <button
            className="btn_reg_80"
            type="button"
            onClick={this.handleSubscriptionPkg}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Pkg-Item
          </button>
        )}

        <button
          className="btn_reg_60"
          onClick={this.handleFilter}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Filter
        </button>
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
      </div>
    );

    let summaryPanel;
    summaryPanel = (
      <div className="sum-placement">
        <span className="bl">
          {sumtot}&nbsp;=&nbsp;{this.state.inventories.length}
        </span>
      </div>
    );

    let bool = true;
    let pagipanel;
    // if (this.state.showpagi && this.props.calledFor === "draft")
    if (this.state.showpagi) {
      pagipanel = (
        <div>
          <hr />
          <div className="row inventory-main-header">
            <div className="col-2 text-right">{purposHeader}</div>
            <div className="col-4 text-center">{summaryPanel}</div>
            <div className="col-6 text-center">{buttonPanel} </div>
          </div>
          <div className="row">
            <div className="col-9 text-left pickup-text-header">{colhead}</div>
            <div className="col-3 text-center pickup-text-header">Ops</div>
          </div>
          {this.state.messagesOnScreen.map((post, i) => (
            <div key={i}>
              <div
                className={`${bool ? "raw-color-inv-dr" : "raw-color-inv-lt"}`}
              >
                <div className="row">
                  <div className="col-10 text-left pickup-text">
                    {this.state.deviceSize === "small" ? (
                      <div>
                        {" "}
                        {post.itemName === "service" ? (
                          <div>
                            {post.itemName.length > 25
                              ? post.itemName.substring(0, 24)
                              : post.itemName}
                          </div>
                        ) : (
                          <div>
                            {post.itemName.length > 25
                              ? post.itemName.substring(0, 24)
                              : post.itemName}
                            &nbsp;(Q:{post.quantity})
                          </div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {/* {post.itemType === "service"
                          ? post.itemName
                          : post.itemName +
                            " - (" +
                            post.category +
                            ")  Qty:" +
                            post.quantity} */}
                        {post.itemType === "service"
                          ? post.itemName
                          : post.itemName + " - (" + post.category + ") "}
                      </div>
                    )}
                  </div>
                  <div className="col-2 text-center inv-list-but-pos">
                    <button
                      //   onClick={() => this.handleInventory(JSON.stringify(post))}
                      onClick={() => this.handleInventory(post)}
                      className="btn_reg_50"
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      Open
                    </button>
                  </div>
                </div>
              </div>
              {(bool = !bool)}
            </div>
          ))}
        </div>
      );
    }

    let pageNumberPanel;
    if (this.state.showPaginationPanelFlag) {
      pageNumberPanel = (
        <div>
          <ul className="pagination">
            {this.state.leftArrowFlag ? (
              <li key={0}>
                <button onClick={this.handleLeft} className="btn_reg_40">
                  <i className="fas fa-angle-double-left" />
                </button>
              </li>
            ) : null}
            {this.state.pageNumbers.map((number) => (
              <li key={number + 1}>
                <button
                  onClick={() => this.paginate(number)}
                  className="btn_reg_40"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  {number}
                </button>
              </li>
            ))}
            {this.state.rightArrowFlag ? (
              <li key={10000000}>
                <button
                  onClick={this.handleRight}
                  className="btn_reg_40"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <i className="fas fa-angle-double-right" />
                </button>
              </li>
            ) : null}
          </ul>
        </div>
      );
    }

    let outputPanel;
    if (this.state.showpagi && this.state.showPaginationPanelFlag) {
      outputPanel = (
        <div>
          {pagipanel}
          <div className="text-center space-above">{pageNumberPanel}</div>
        </div>
      );
    }

    if (this.state.showpagi && !this.state.showPaginationPanelFlag) {
      outputPanel = <div>{pagipanel}</div>;
    }

    let coopStoreId = 0;
    let coopStoreName = "";
    if (this.props.store) {
      coopStoreId = this.props.store.coopStoreId;
      coopStoreName = this.props.store.displayStoreName;
    }

    if (this.state.openItemFlag) {
      outputPanel = (
        <div>
          <AnItemsInventory
            returnToCaller={this.returnToInvMgmt}
            item={this.state.anItem}
            s3Url={this.state.s3Url}
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            coopStoreName={coopStoreName}
            communityName={this.props.commName}
          />
        </div>
      );
    }

    if (this.state.showFilterFlag) {
      outputPanel = (
        <div>
          <ItemFilter
            returnToCaller={this.returnAfterFilter}
            item={this.state.anItem}
            s3Url={this.state.s3Url}
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            coopStoreName={coopStoreName}
          />
        </div>
      );
    }

    if (this.state.invOrganizerFlag) {
      outputPanel = (
        <div>
          <Organizer
            returnToCaller={this.returnToInvMgmt}
            item={this.state.anItem}
            s3Url={this.state.s3Url}
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            coopStoreName={coopStoreName}
            caller="newitem"
          />
        </div>
      );
    }

    if (this.state.itemCompositionFlag) {
      outputPanel = (
        <div>
          <ItemComposition
            returnToCaller={this.returnFromComposition}
            baandaId={this.props.auth.user.baandaId}
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            coopStoreName={coopStoreName}
            item={this.props.item}
            catalog={this.state.catalog}
          />
        </div>
      );
    }

    if (this.state.subItemComposeFlag) {
      outputPanel = (
        <div>
          <ComposePackage
            returnToCaller={this.returnFromComposition}
            baandaId={this.props.auth.user.baandaId}
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            coopStoreName={coopStoreName}
            item={this.props.item}
            catalog={this.state.catalog}
          />
        </div>
      );
    }

    if (this.state.showErrMsgPanel) {
      outputPanel = (
        <div>
          <div className="cat-error-header">Message</div>
          <div>
            <p align="justify" className="inventory-error-msg">
              {this.state.errmsg}
            </p>
          </div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

InventoryMgmt.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(InventoryMgmt));
