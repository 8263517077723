import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import ModalContainer from "../../../../../../../modal/components/ModalContainer";
import {
  showModal,
  hideModal,
} from "../../../../../../../actions/modalActions";
import "../../../../../../../modal/css/localModal.css";
import "../../../../../../../modal/css/template.css";

import "./LegacyTransaction.css";

const readMeCode = "0000000000";

class LegacyTransaction extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  returnToPayPending = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('LegacyTransaction...');

    let modalHelpButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let exitLegacy = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToPayPending}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let outputPanel;
    outputPanel = (
      <div className="legacy-tr-box">
        <div className="text-center">
          Legacy Receiveable & Payable Mgmt. {modalHelpButton} {exitLegacy}{" "}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

LegacyTransaction.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LegacyTransaction));
