import React, { Component } from "react";

import axios from "axios";

import "./HandlePickupDetails.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAnInvoice = "/routes/invoice/getAnInvoice?";
const savePickupEvent = "/routes/shipping/savePickupEvent";

class HandlePickupDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      invoice: null,

      pickersName: "",
      pickersId: "",
      pickupNote: "",
      message: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getThisInvoice();

    await this.setState({
      deviceSize,
      pickersName: this.props.invoice.customerName,
    });
  };

  getThisInvoice = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let param =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&invoiceId=" +
      this.props.invoice.invoiceId +
      "&role=communityAdmin";
    let url = baandaServer + getAnInvoice + param;
    // console.log("url:", url);
    try {
      let retinv = await axios.get(url);
      // console.log("retinv:", retinv);
      if (retinv.data.status === "success") {
        await this.setState({
          message: "",
          errFlag: false,
          invoice: retinv.data.Msg,
        });
      } else {
        await this.setState({
          message: retinv.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("HandleShippingDetails getThisInvoice err:", err.message);
      await this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  returnToShipPagi = () => {
    this.props.returnToCaller();
  };

  handleExit = () => {
    this.returnToShipPagi();
  };

  handlePickupEvent = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let spRegularStatus = this.state.invoice.spRegularStatus;
    if (this.state.invoice.spRegularExists) spRegularStatus = "pickedup";
    let spSpecialStatus = this.state.invoice.spSpecialStatus;
    if (this.state.invoice.spSpecialExists) spSpecialStatus = "pickedup";
    let input = {
      pickersName: this.state.pickersName,
      pickersId: this.state.pickersId,
      pickupNote: this.state.pickupNote,
      spRegularStatus,
      spSpecialStatus,
      clientProgram: "HandlePickupDetails",
      clientFunction: "handlePickupEvent",
      invoiceId: this.state.invoice.invoiceId,
      communityId: this.props.communityId,
      coopStoreId,
    };
    let url = baandaServer + savePickupEvent;
    try {
      let pickret = await axios.post(url, input);
      if (pickret.data.status === "success") {
        await this.setState({
          message: "Pickup event successfully recorded. Please Exit.",
        });
      } else {
        await this.setState({
          message: "Error: " + pickret.data.Msg,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      await this.setState({
        message: "Error: " + err.message,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("HandlePickupDetails...");

    let exitButton = (
      <button
        onClick={this.handleExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let invoiceCustomerPanel;
    invoiceCustomerPanel = (
      <div className="row">
        <div className="col-3 text-center pick-handle-invoiceId">
          Invoice #: <b>{this.props.invoice.invoiceId}</b>
        </div>
        <div className="col-9 text-left pick-handle-customer">
          Customer: <b>{this.props.invoice.customerName}</b> - (
          {this.props.invoice.customerEmail})
        </div>
      </div>
    );

    let customerAddressPanel;
    let ca = this.props.invoice.invoiceRaw.deliveryData;
    customerAddressPanel = (
      <div className="row">
        <div className="col-3 text-Right pick-handle-address">
          Pickup from address
        </div>
        <div className="col-9 text-left pick-handle-customer">
          {ca.street1}
          {ca.street2 === "" ? null : " " + ca.street2},&nbsp;
          {ca.city}, {ca.state}&nbsp;{ca.zip}&nbsp;&nbsp;{ca.country}
        </div>
      </div>
    );

    let itemsDisplayPanel;
    let bool = true;
    if (this.state.invoice) {
      itemsDisplayPanel = (
        <div>
          <div className="row">
            <div className="col-7 text-left pick-line-items-name-head">
              Invoice Line Item
            </div>
            <div className="col-3 text-left pick-line-items-dim-head">
              Pkg. Size
            </div>
            <div className="col-2 text-left pick-line-items-qty-head">
              Qty (unit)
            </div>
          </div>
          <div className="fixedsize-pickup-lineitem">
            {this.state.invoice.invoiceRaw.invoiceLineItems.map((obj, i) => {
              // console.log("obj:", obj);
              let diml = '"';
              let dimw = "lbs";
              if (
                obj.itemType === "goods" &&
                obj.dimensions.scaleType === "mks"
              ) {
                diml = "cm";
                dimw = "gm";
              }
              return (
                <div key={i}>
                  <div className={`${bool ? "row-color-xx" : "row-color-zz"}`}>
                    {obj.itemTypes === "goods" ? (
                      <div className="row">
                        <div className="col-7 text-left pick-line-items-name">
                          {obj.itemName}
                        </div>
                        <div className="col-3 text-left pick-line-items">
                          {obj.dimensions.length}
                          {diml}, {obj.dimensions.width}
                          {diml}, {obj.dimensions.height}
                          {diml}, {obj.dimensions.weight}
                          {dimw}
                        </div>
                        <div className="col-2 text-left pick-line-items">
                          {obj.quantity} ({obj.unitType})
                        </div>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="col-7 text-left pick-line-items-name">
                          {obj.itemName}
                        </div>
                        <div className="col-3 text-left pick-line-items">
                          Pickup
                        </div>
                        <div className="col-2 text-left pick-line-items">
                          {obj.quantity} ({obj.unitType})
                        </div>
                      </div>
                    )}
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let pickedUpPanel;
    pickedUpPanel = (
      <div className="text-center special-self-pos">
        <div className="text-center special-self-header">Pickup</div>
        <div className="row spcl-row-pos">
          <div className="col-3 text-right self-post-lbl">Pickedup by</div>
          <div className="col-9 text-left">
            <input
              name="pickersName"
              type="text"
              value={this.state.pickersName}
              onChange={this.onChange}
              size="100"
              maxLength="100"
              className="picker-name"
              placeholder="Compare with the customer's name."
            />
          </div>
        </div>
        <div className="row spcl-row-pos">
          <div className="col-3 text-right self-post-lbl">Pickers ID</div>
          <div className="col-9 text-left">
            <input
              name="pickersId"
              type="text"
              value={this.state.pickersId}
              onChange={this.onChange}
              size="60"
              maxLength="60"
              className="picker-identity"
              placeholder="Picker's identity specs."
            />
          </div>
        </div>

        <div className="row spcl-row-pos">
          <div className="col-3 text-right self-post-lbl">Note</div>
          <div className="col-9 text-left">
            <input
              name="pickupNote"
              type="text"
              value={this.state.pickupNote}
              onChange={this.onChange}
              size="200"
              maxLength="200"
              className="pickup-note"
              placeholder="Ref. Note (optional)"
            />
          </div>
        </div>
        <div className="text-center">
          <button
            className="btn_reg_60"
            type="button"
            onClick={this.handlePickupEvent}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Save
          </button>
        </div>
        <div className="text-center self-special-msg">
          {this.state.specialSelfMsg}
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="pickup-handling-box">
        <div className="text-center picking-header">
          Pickup Items&nbsp;&nbsp;{exitButton}
        </div>
        {invoiceCustomerPanel}
        {customerAddressPanel}
        {itemsDisplayPanel}
        {pickedUpPanel}
        <div className="text-center pickup-msg">{this.state.message}</div>
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

export default HandlePickupDetails;
