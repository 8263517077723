import React, { Component } from "react";

import "./CustomerDetail.css";

class CustomerDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errMsg: "",
      errFlag: false,

      totalPurchaseAmt: 0,
      totalRefundAmt: 0,
      totalCancels: 0,
    };
  }

  componentDidMount = async () => {
    let totalPurchaseAmt = 0;
    let totalRefundAmt = 0;
    // let totalCancels = 0;
    this.props.theCustomer.purchase.forEach((obj1) => {
      totalPurchaseAmt += obj1.amount;
    });
    this.props.theCustomer.refunds.forEach((obj2) => {
      totalRefundAmt += obj2.amount;
    });

    this.setState({
      totalPurchaseAmt,
      totalRefundAmt,
      totalCancels: 0,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  returnToList = () => {
    this.props.returnToCaller();
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("CustomerDetails..")

    let exitButton = (
      <button
        onClick={this.returnToList}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let custHeader = (
      <div className="cust-rep-header-pos">
        <div className="cust-rep-header text-center">
          Customer Details {exitButton}
        </div>
        <div className="row">
          <div className="col-2 text-right rept-cust-detail-lbl">Name</div>
          <div className="col-6 text-left rept-cust-detail-txt">
            {this.props.theCustomer.name}
          </div>
          <div className="col-4 text-left rept-cust-detail-lbl">
            Purchase Amt :${" "}
            {this.commaFormattedCurrency(this.state.totalPurchaseAmt)}
          </div>
        </div>
        <div className="row">
          <div className="col-2 text-right rept-cust-detail-lbl">Email</div>
          <div className="col-6 text-left rept-cust-detail-txt">
            {this.props.theCustomer.email}
          </div>
          <div className="col-4 text-left rept-cust-detail-lbl">
            Refund Amt :${" "}
            {this.commaFormattedCurrency(this.state.totalRefundAmt)}
          </div>
        </div>
        <div className="row">
          <div className="col-2 text-right rept-cust-detail-lbl">Baanda Id</div>
          <div className="col-6 text-left rept-cust-detail-txt">
            {this.props.theCustomer.baandaId}
          </div>
          <div className="col-4 text-left rept-cust-detail-lbl">
            Canceled {this.state.totalCancels}
          </div>
        </div>
      </div>
    );

    let purchasePanel;
    let bool1 = true;
    purchasePanel = (
      <div className="cust-rep-purchase-pos">
        <div>
          <div className="row">
            <div className="col-2 text-left rep-cust-list-ref-head">
              Purchase
            </div>
            <div className="col-8 text-center rep-cust-list-ref-head">
            Invoice Id&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Amount
            </div>
            <div className="col-2 text-left rep-cust-list-ref-head">&nbsp;</div>
          </div>
          <div className="fixedsize-cust-pur-box">
            {this.props.theCustomer.purchase.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool1
                        ? "row row-col-schdule-dr"
                        : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col text-center invoice-list-lines">
                      {obj.invoiceId}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      |&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      {obj.date.substring(0, 10)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      |&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${" "}
                      {this.commaFormattedCurrency(obj.amount)}
                    </div>
                  </div>
                  {(bool1 = !bool1)}
                </div>
              );
            })}
          </div>
          <div
            className={
              this.state.errFlag
                ? "text-center sub-item-msg-err"
                : "text-center sub-item-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      </div>
    );

    let refundPanel;
    let bool2 = true;
    refundPanel = (
      <div className="cust-rep-refund-pos">
        <div className="row">
          <div className="col-2 text-left rep-cust-list-ref-head">Refund</div>
          <div className="col-8 text-center rep-cust-list-ref-head">
            Invoice Id&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Date&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Amount
          </div>
          <div className="col-2 text-left rep-cust-list-ref-head">&nbsp;</div>
        </div>
        <div className="fixedsize-cust-pur-box">
          {this.props.theCustomer.refunds.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    bool2 ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                  }
                >
                  <div className="col text-center invoice-list-lines">
                    {obj.invoiceId}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    |&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {obj.date.substring(0, 10)}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    |&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ${" "}
                    {this.commaFormattedCurrency(obj.amount)}
                  </div>
                </div>
                {(bool2 = !bool2)}
              </div>
            );
          })}
        </div>
        <div
          className={
            this.state.errFlag
              ? "text-center sub-item-msg-err"
              : "text-center sub-item-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="customer-rep-details-box">
        {custHeader}
        {purchasePanel}
        {refundPanel}
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default CustomerDetail;
