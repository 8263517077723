import React, { Component } from "react";

import "./ShipFilter.css";

class ShipFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "tobe",
      customerName: "",
      itemType: "all",

      invoiceId: 0
    };
  }

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleExit = () => {
    let retObj = { ops: "exit", Msg: null };
    this.returnToShipping(retObj);
  };

  handleFilter = () => {
    let request = {
      status: this.state.status,
      customerName: this.state.customerName,
      itemType: this.state.itemType,
      invoiceId: parseFloat(this.state.invoiceId)
    };
    this.returnToShipping({ ops: "filter", Msg: request });
  };

  returnToShipping = (retObj) => {
    this.props.returnToCaller(retObj);
  };

  handleSelectStatus = async (e) => {
    await this.setState({
      status: e.target.value,
    });
  };

  handleItemType = async (e) => {
    await this.setState({
      itemType: e.target.value,
    });
  };
 
  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ShipFilter...');

    let exitButton = (
      <button
        onClick={this.handleExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let filterButton = (
      <button
        onClick={this.handleFilter}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_60"
      >
        Filter
      </button>
    );

    let selectItemType = (
      <div className="row ship-status-row-pos">
        <div className="col text-center radio-font-style">
          <strong>Select Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="all"
                checked={this.state.itemType === "all"}
                onChange={this.handleItemType}
              />{" "}
              All
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="regular"
                checked={this.state.itemType === "regular"}
                onChange={this.handleItemType}
              />{" "}
              Regular
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="special"
                checked={this.state.itemType === "special"}
                onChange={this.handleItemType}
              />{" "}
              Special
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="periodic"
                checked={this.state.itemType === "periodic"}
                onChange={this.handleItemType}
              />{" "}
              Periodic 
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="periodic"
                checked={this.state.itemType === "periodic"}
                onChange={this.handleItemType}
              />{" "}
              Manual 
            </label>
          </div>
        </div>
      </div>
    );

    let selectStatus = (
      <div className="row ship-status-row-pos">
        <div className="col text-center radio-font-style">
          <strong>Select ship status&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="tobe"
                checked={this.state.status === "tobe"}
                onChange={this.handleSelectStatus}
              />{" "}
              New shipping request
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="ready"
                checked={this.state.status === "ready"}
                onChange={this.handleSelectStatus}
              />{" "}
              Ready (packed) to Ship
            </label>
          </div>
        </div>
      </div>
    );

    let reprintLabelPanel;
    reprintLabelPanel = (
      <div>
        <div className="row ship-custname-pos">
          <div className="col-4 text-right search-lbl">Invoice Id</div>
          <div className="col-8 text-left">
            <input
              name="invoiceId"
              type="number"
              value={this.state.invoiceId}
              onChange={this.onChange}
              size="14"
              maxLength="14"
              className="ship-filter-invoice"
              placeholder=""
            />
          </div>
        </div>
        <div className="ship-name-msg text-center">
          To print already procured label, enter the invoice Id.
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="ship-filter-box">
        <div className="text-center ship-filter-header">
          Filter Shipping Data{" "}
        </div>
        {selectItemType}
        {selectStatus}
        {reprintLabelPanel}
        <div className="row ship-custname-pos">
          <div className="col-4 text-right search-lbl">Customer Name</div>
          <div className="col-8 text-left">
            <input
              name="customerName"
              type="text"
              value={this.state.customerName}
              onChange={this.onChange}
              size="14"
              maxLength="14"
              className="ship-cust-name"
              placeholder=""
            />
          </div>
        </div>
        <div className="ship-name-msg text-center">
          Enter full, part to filter, or blank for all names.
        </div>
        <div className="text-center ship-button-pos">
          {exitButton} &nbsp;{filterButton}
        </div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default ShipFilter;
