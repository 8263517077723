import { useState } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

import "./SearchBazaar.css";
import searchPic from "../images/searchPic1.jpg";

const SpeechToText = (props) => {
  const {
    transcript,
    // listening,
    resetTranscript,
    // browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const [searchText, setSearchText] = useState("");

  const writeSearchText = (e) => {
    setSearchText(e.target.value);
  };

  const justExit = () => {
    props.returnToCaller("");
  };

  const doListen = () => {
    SpeechRecognition.startListening();
    // setSearchText(transcript);
  };

  const transferTo = () => {
    SpeechRecognition.stopListening();
    setSearchText(searchText + transcript);
    resetTranscript();
  };

  const goSearch = () => {
    props.returnToCaller(searchText);
  };

  const clearAll = () => {
    resetTranscript();
    setSearchText('');
  }

  let searchButton = (
    <button
      className="btn_searh_main"
      type="button"
      onClick={goSearch}
      style={{ cursor: "pointer" }}
    >
      <i className="fa fa-search-plus" />
    </button>
  );

  let searchExitButton = (
    <button
      className="btn_back_main"
      type="button"
      onClick={justExit} 
      style={{ cursor: "pointer" }}
    >
      <i className="fas fa-step-backward" />
    </button>
  );

  let micOnButton = (
    <button
      className="btn_searh_main"
      type="button"
      onClick={doListen}
      style={{ cursor: "pointer" }}
    >
      <i className="fa fa-microphone" />
    </button>
  );

  let micOffButton = (
    <button
      className="btn_searh_main"
      type="button"
      onClick={transferTo}
      style={{ cursor: "pointer" }}
    >
      <i className="fa fa-microphone-slash" />
    </button>
  );

  let clearTextButton = (
    <button
      className="btn_searh_main"
      type="button"
      onClick={clearAll}
      style={{ cursor: "pointer" }}
    >
      Clr
    </button>
  );

  let output = (
    <div>
      <div>
        <img src={searchPic} className="bazaar-search-img" alt="" />
        &nbsp;&nbsp;&nbsp;{searchExitButton}
        <div className="text-center">
          <b>Note: </b>Enter a word, or key phrase, to search. 
        </div>
        <div className="row text-center community-search-pos">
          <div className="col-2 text-right community-search-lbl">Search</div>
          <div className="col-10 text-left">
            <input
              name="searchText"
              type="text"
              value={searchText}
              onChange={writeSearchText}
              size="60"
              maxLength="60"
              className="comm-search-text"
              placeholder=""
            />
            &nbsp;{searchButton}
          </div>
        </div>
      </div>
      <div className="text-center bazzar-search-mics-pos">
        {/* { listening ? micOffButton : micOnButton} */}
        {micOnButton}&nbsp;{micOffButton}&nbsp;{clearTextButton}
      </div>
      <p>{transcript}</p>
    </div>
  );

  return <div>{output}</div>;
};

export default SpeechToText;
