import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./ViewCO.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateTaskCOCust = "/routes/projects/updateTaskCOCust";

const readMeCode = "0000000000";

class ViewCO extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      currChangeOrder: null,
      currCONo: 1,
      changeOrders: [],
      last: 1,
      currCOTotal: 0,

      proceedDecision: "oktoproceed",

      nextButtonFlag: false,
      prevButtonFlag: false,
      decisionFlag: false,

      theTask: null,
      errMsg: '',
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let changeOrders = this.props.theTask.changeOrder;
    let last = changeOrders.length;
    let currChangeOrder = changeOrders[last - 1];

    let currCONo = currChangeOrder.changeOrderNo;

    let prevButtonFlag = false;
    if (last > 1) prevButtonFlag = true;

    let total = 0;
    currChangeOrder.lineItem.forEach((elm) => {
      total += elm.cost;
    });
    total += currChangeOrder.estimatedTaxAmount;

    let decisionFlag = false;
    if ( currChangeOrder.status === 'ready' ) decisionFlag = true;

    this.setState({
      deviceSize,
      changeOrders,
      currChangeOrder,
      last,
      currCONo,
      prevButtonFlag,
      currCOTotal: total,
      decisionFlag,
      theTask: this.props.theTask
    });
  };

  openAlertModal = async () => {
 
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  returnToTask = () => {
    this.props.returnToCaller();
  };

  handleProceedDecision = async (e) => {
    await this.setState({
      proceedDecision: e.target.value,
    });
  };

  sendCoDecision = async() => {
    let co = [];
    this.state.changeOrders.forEach(obj => {
      if ( obj.changeOrderNo !== this.state.currCONo) co.push(obj);
    });

    let cco = this.state.currChangeOrder;
    if ( this.state.proceedDecision === 'oktoproceed') cco.status = 'locked';
    if ( this.state.proceedDecision === 'cancel') cco.status = 'canceled';
    co.push(cco);

    let input = {
      taskId: this.state.theTask.taskId,
      coController: 'seller',
      changeOrder: co,
      clientProgram: 'ViewCO',
      clientFunction: 'sendCoDecision'
    }
    let url = baandaServer + updateTaskCOCust;
    // console.log('url:', url, ' input:', input);
    try {
      let respret = await axios.post(url, input);
      // console.log('respret.data:', respret.data);
      if ( respret.data.status === 'success') {
        this.setState({
          errMsg: 'Successfully updated.',
          errFlag: false
        });
        await this.setCurrentCO(respret.data.Msg, 'same');  // same means, not prev or next.
      } else {
        this.setState({
          errMsg: respret.data.Msg,
          errFlag: true
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true
      });
    }
  }

  setCurrentCO = async (cco, dir ) => {
    let currCONo = this.state.currCONo;
    if ( dir === 'prev') currCONo--;
    if ( dir === 'next') currCONo++;
    let currChangeOrder = null;
    cco.changeOrder.forEach(obj => {
      if ( obj.changeOrderNo === currCONo) currChangeOrder = obj;
    });
    let decisionFlag = false;
    if ( currChangeOrder.status === 'ready' ) decisionFlag = true;
    let prevButtonFlag = false;
    let nextButtonFlag = false;
    if ( cco.changeOrder.length > 1 ) {
      if ( currCONo > 1 ) prevButtonFlag = true;
      if ( currCONo >= cco.changeOrder.length) nextButtonFlag = true;
    } 

    this.setState({
      currCONo,
      prevButtonFlag,
      nextButtonFlag,
      decisionFlag,
      currChangeOrder,
      changeOrders: cco.changeOrder,
      theTask: cco
    });
  }

  prevCO = async() => {
    await this.setCurrentCO(this.state.theTask, 'prev');
  }

  nextCO = async() => {
    await this.setCurrentCO(this.state.theTask, 'next');
  }

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ViewCO...');

    let helpModalButton = (
      <button
        className="btn-modal-cust-detail"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let exitButton = (
      <button
        onClick={this.returnToTask}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn-cust-task-detail-exit"
      >
        X
      </button>
    );

    let prevButton;
    if (this.state.prevButtonFlag) {
      prevButton = (
        <button
          onClick={this.prevCO}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          className="btn-cust-co-next-prev"
        >
          <i className="fa fa-step-backward" />
        </button>
      );
    }

    let nextButton;
    if (this.state.nextButtonFlag) {
      nextButton = (
        <button
          onClick={this.nextCO}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          className="btn-cust-co-next-prev"
        >
          <i className="fa fa-step-forward" />
        </button>
      );
    }

    let topPanel;
    if (this.state.currChangeOrder) {
      topPanel = (
        <div className="cust-tsk-co-top-panel-pos">
          <div className="row">
            <div className="col-2 text-right task-cust-co-lbl">Item Name</div>
            <div className="col-10 text-left task-cust-co-txt">
              {this.props.theTask.itemName}
            </div>
          </div>
          <div className="row">
            <div className="col-2 text-right task-cust-co-lbl">Time</div>
            <div className="col-10 text-left task-cust-co-txt">
              {this.state.currChangeOrder.additionalDays} additional days.
            </div>
          </div>
          <div className="row">
            <div className="col-2 text-right task-cust-co-lbl">Tax</div>
            <div className="col-10 text-left task-cust-co-txt">
              ${" "}
              {this.commaFormattedCurrency(
                this.state.currChangeOrder.estimatedTaxAmount
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-2 text-right task-cust-co-lbl">Comment</div>
            <div className="col-10 text-left task-cust-co-comment">
              {this.state.currChangeOrder.comment}
            </div>
          </div>
          {this.state.currChangeOrder.status === "ready" ? (
            <div className="row">
              <div className="col-2 text-right task-cust-co-lbl">Message</div>
              <div className="col-10 text-left task-cust-co-txt">
                Seller awaits your approval to continue.
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-2 text-right task-cust-co-lbl">Message</div>
              <div className="col-10 text-left task-cust-co-txt">
                This CO is locked per your approval.
              </div>
            </div>
          )}
          <div className="row">
              <div className="col-2 text-right task-cust-co-lbl">Status</div>
              <div className="col-10 text-left task-cust-co-txt">
                {this.state.currChangeOrder.status.toUpperCase()}
              </div>
            </div>
        </div>
      );
    }

    let bool = true;
    let lineItemsListPanel;
    if (this.state.currChangeOrder) {
      lineItemsListPanel = (
        <div>
          <div className="text-center view-conditions-pos">
            Service Line Items
          </div>
          <div className="row quote-list-header">
            <div className="col-1 text-center quote-list-item-lbl">#</div>
            <div className="col-9 text-left quote-list-head-lbl">
              Description
            </div>
            <div className="col-2 text-center quote-list-head-lbl">
              Amount $
            </div>
          </div>
          {/* <div className="quote-list-box"> */}
          <div className="co-lineitem-box">
            {this.state.currChangeOrder.lineItem.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool
                        ? "row row-col-item-list-dr"
                        : "row row-col-item-list-lt"
                    }
                  >
                    <div className="col-1 text-left quote-list-item-lbl">
                      &nbsp;{obj.seqno}.&nbsp;
                    </div>
                    <div className="col-9 text-left quote-view-lineitem-desc">
                      {obj.description}.{" "}
                      {obj.milestone ? <i className="fa fa-star" /> : null}
                    </div>
                    <div className="col-2 text-right quote-view-lineitem-cost">
                      {this.commaFormattedCurrency(obj.cost)}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let decision, oktoproceed, wipnotyet, canceled;
    if (this.state.deviceSize === "big") {
      decision = "Decision";
      wipnotyet = "WIP-Negotiate";
      oktoproceed = "OK to proceed";
      canceled = "Cancel";
    } else {
      decision = "Say";
      wipnotyet = "WIP";
      oktoproceed = "OK";
      canceled = "Cncl";
    }

    let decisionPanel;
    decisionPanel = (
      <div className="col text-center radio-font-style">
        <strong>{decision}&nbsp;&nbsp;</strong>
        <div className="form-check form-check-inline">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="radio"
              value="wip"
              checked={this.state.proceedDecision === "wip"}
              onChange={this.handleProceedDecision}
            />{" "}
            {wipnotyet}
          </label>
        </div>
        <div className="form-check form-check-inline">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="radio"
              value="oktoproceed"
              checked={this.state.proceedDecision === "oktoproceed"}
              onChange={this.handleProceedDecision}
            />{" "}
            <font color="green">{oktoproceed}</font>
          </label>
        </div>
        <div className="form-check form-check-inline">
          <label className="form-check-label">
            <input
              className="form-check-input"
              type="radio"
              value="cancel"
              checked={this.state.proceedDecision === "cancel"}
              onChange={this.handleProceedDecision}
            />{" "}
            <font color="red">{canceled}</font>
          </label>
        </div>
      </div>
    );

    let updatebtn;
    if (this.state.deviceSize === "big") {
      updatebtn = "Update";
    } else {
      updatebtn = "Updt";
    }

    let opsPanel;
    opsPanel = (
      <div>
         <div className="row">
          <div className="col-2 text-right task-cust-co-lbl">Note</div>
          <div className="col-10 text-left task-cust-co-comment">
            Send a new comment to request a change.
          </div>
        </div>
        <div className="row task-cust-co-row">
          <div className="col-md-6 text-center">{decisionPanel}</div>
          <div className="col-md-6 text-center">
            <button
              onClick={this.sendCoDecision}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              className="task-detail-cust-update"
            >
              {updatebtn}
            </button>
          </div>
        </div>
      </div>
    );

    let totalPanel = (
      <div className="row co-qv-total-pos">
        <div className="col-10 text-right quote-list-head-lbl">Total</div>
        <div className="col-2 text-center quote-list-head-lbl">
          {this.commaFormattedCurrency(this.state.currCOTotal)}
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="customer-task-co-view-box">
        <div className="row">
          <div className="col-md-6 text-center cust-tsk-chord-view">
            Change Order (Curr #: {this.state.currCONo})
          </div>
          <div className="col-md-6 text-center ">
            {helpModalButton} {exitButton} {prevButton} {nextButton}
          </div>
        </div>

        {topPanel}
        {lineItemsListPanel}
        {totalPanel}
        { this.state.decisionFlag ? opsPanel : null}
        <div className={this.state.errFlag ? 'text-center tsk-co-msg-err' : 'text-center tsk-co-msg'}>{this.state.errMsg}</div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ViewCO.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewCO));
