import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";
import LoadFileToS3 from "../../../../../../utils/components/fileUpload/LoadFileToS3";
import ReactLoading from "react-loading";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./ComposeText.css";

const imageTypes = ["jpg", "jpeg", "bmp", "tiff", "png", "gif", "webp"];
const videoTypes = ["mp4", "webm"];

const maxmmsImageSize = 500000; // .5MB
const maxmmsVideoSize = 3000000; // 3mb

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveBroadcastSms = "/routes/interact/saveBroadcastSms";
const getBroadcasts = "/routes/interact/getBroadcasts?";
const publishBroadcast = "/routes/interact/publishBroadcast";
const getGroupsOfCommunity = "/routes/dashboard/getGroupsOfCommunity?";
const checkBroadcastReadiness = "/routes/interact/checkSmsBroadcastReadiness?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "0000000000";

class ComposeText extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      smsHello: "",
      smsText: "",
      smsBye: "",

      broadcastName: "",

      workWith: "new",
      operationType: "compose",

      loadDAFileFlag: false,
      mmsMediaType: "none",

      composeFlag: true,
      interactionType: "personal",
      smsFrom: "",

      showWipPanel: false,
      showTestBtnFlag: false,
      showAttachmentFlag: false,
      showTextTestFlag: false,
      showGroupsFlag: false,

      broadcastId: 0,
      coopStoreId: 0,

      fileUploads: null,
      mmsDAType: "",

      errMsg: "",
      errFlag: false,

      attachMmsImageFlag: false,
      attachMmsVideoFlag: false,

      mmsImageS3FileData: null,
      mmsVideoS3FileData: null,

      broadcastSubDirName: "",

      previousDASize: 0,

      targetType: "group",
      groupName: "",
      groups: [],
      selectedGroup: null,

      recipiants: null,
      canBroadcast: false,
      broadcastFee: 0,
      confirmBroadcast: false,
      availableCredit: 0,

      loadingFlag: false,
      broadcastJustDone: false,

      domain: 'smsmms'
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let smsFrom = this.props.auth.user.name;

    this.setState({
      deviceSize,
      smsFrom,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  loadCommunityGroups = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&groupName=" ;
      // +
      // this.state.groupName;
    let url = baandaServer + getGroupsOfCommunity + params;
    console.log("url:", url);
    try {
      let gret = await axios(url);
      console.log("gret.data:", gret.data);
      if (gret.data.status === "success") {
        this.setState({
          groups: gret.data.Msg,
          showGroupsFlag: true,
          sendMsg: "",
          sendMsgFlag: false,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        sendMsg: err.message,
        sendMsgFlag: true,
      });
    }
  };

  closeShowGroup = () => {
    this.setState({
      showGroupsFlag: false,
    });
  };

  handleGroupSelect = (grp) => {
    console.log("grp:", grp);
    let targetMem = [];
    grp.members.forEach((mm) => {
      mm.messages = [];
      targetMem.push(mm);
    });
    let recipiants = {
      geoLocation: null,
      groupId: grp.groupId,
      groupName: "",
      lastSentDate: new Date(),
      targetGroupMembers: targetMem,
      type: "group",
    };
    this.setState({
      showGroupsFlag: false,
      selectedGroup: grp,
      groupName: grp.groupName,
      recipiants,
    });
  };

  onChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  checkRediness = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&broadcastId=" +
      this.state.broadcastId;
    let url = baandaServer + checkBroadcastReadiness + params;
    console.log("url:", url);
    try {
      let reret = await axios.get(url);
      if (reret.data.status === "success") {
        this.setState({
          availableCredit: parseFloat(reret.data.Msg),
        });
        return { status: "success", Msg: "" };
      } else {
        return { status: "error", Msg: reret.data.Msg };
      }
    } catch (err) {
      return { status: "error", Msg: err.message };
    }
  };

  validateForBroadcast = async () => {
    let status = "success";
    let msg = "";

    if (this.state.broadcastId === 0) {
      status = "error";
      msg =
        "Nothing to broadcast. Please compose and set target for broadcasting.";
    }
    if (status === "success") {
      let retready = await this.checkRediness();
      console.log("retready:", retready);
      status = retready.status;
      msg = retready.Msg;
    }
    return { status, Msg: msg };
  };

  calculateFeeReq = async () => {
    this.setState({
      broadcastFee: 0.5,
    });
  };

  loadSelectedGroup = async() => {
    let selectedGroup = null;
    this.state.brodcasts.forEach(brd => {
      if ( brd.broadcastId === this.state.broadcastId) {
        if ( brd.recipiants && brd.recipiants.groupId !== 0) {
          this.state.groups.forEach(grr => {
            if ( grr.groupId === brd.recipiants.groupId) {
              selectedGroup = grr;
            }
          })
        }
      }
    });
    this.setState({
      selectedGroup
    })
  }

  handleOperationType = async (e) => {
    let value = e.target.value;
    console.log("value:", value);
    let targetType = "group";
    let canBroadcast = true;
    let errMsg = "";
    let errFlag = false;
    let showGroupsFlag = false;
    if (value === "target") {
      console.log('in target ...')
      if (
        this.state.recipiants &&
        this.state.recipiants.targetGroupMembers.length > 0
      ) {
        if (this.state.recipiants.type === "customer") targetType = "customer";
        if (this.state.recipiants.type === "community") {
          targetType = "community";
        }
      } 
      await this.loadCommunityGroups();
      if ( this.state.broadcastId !== 0) {
        await this.loadSelectedGroup();
      }
      if ( this.state.recipiants.type === "group" ) showGroupsFlag = true;
    }
    if (value === "broadcast") {
      console.log("in broadcast ...");
      let brdret = await this.validateForBroadcast();
      console.log("brdret:", brdret);
      if (brdret.status === "error") {
        canBroadcast = false;
        errMsg = brdret.Msg;
        errFlag = true;
      } else {
        await this.calculateFeeReq();
        canBroadcast = true;
        errMsg = "";
        errFlag = false;
      }
    }

    let workWith = this.state.workWith;
    if ( this.state.broadcastJustDone) {
      workWith = 'new';
      this.initCmoposeNew();
    }

    this.setState({
      operationType: value,
      errMsg,
      errFlag,
      targetType,
      canBroadcast,
      broadcastJustDone: false,
      workWith,
      showGroupsFlag
    });
  };

  handleTargetType = async (e) => {
    let value = e.target.value;
    if (value === "group") {
      await this.loadCommunityGroups();
    }
    this.setState({
      targetType: e.target.value,
      errMsg: "",
      errFlag: false,
    });
  };

  initCmoposeNew = () => {
    this.setState({
      broadcastId: 0,
      broadcastName: "",
      broadcasts: [],
      fileUploads: null,
      mmsDAType: "none",
      mmsImageS3FileData: null,
      mmsVideoS3FileData: null,
      previousDASize: 0,
      recipiants: null,
      smsBye: "",
      smsFrom: "",
      smsHello: "",
      smsText: "",
      // operationType: "compose"
    });
  };

  handletextingDecision = async (e) => {
    let value = e.target.value;
    console.log("value:", value);
    let showWipPanel = false;
    let operationType = this.state.operationType;
    if (value === "wip") {
      showWipPanel = true;
      await this.getBroadcastRecs("prepmode");
    }
    if (value === "new") {
      this.initCmoposeNew();
      operationType = "compose";
    }
    this.setState({
      workWith: e.target.value,
      showWipPanel,
      operationType,
      errMsg: "",
      errFlag: false,
      broadcastJustDone: false
    });
  };

  closeWipPanel = () => {
    this.setState({
      showWipPanel: false,
    });
  };

  handleBroadcastType = async (e) => {
    this.setState({
      interactionType: e.target.value,
      broadcastJustDone: false
    });
  };

  handleMediaTypeOption = async (e) => {
    let value = e.target.value;
    console.log("handleMediaTypeOption value:", value);
    let loadDAFileFlag = false;
    let subBucket = "";
    let previousDASize = 0;
    let attachMmsImageFlag = false;
    let attachMmsVideoFlag = false;
    let composeFlag = true;
    if (value !== "none") {
      loadDAFileFlag = true;
      subBucket = this.getSubdirName();
      if (this.state.mmsImageS3FileData)
        previousDASize = this.state.mmsImageS3FileData.size;
      if (this.state.mmsVideoS3FileData)
        previousDASize = this.state.mmsVideoS3FileData.size;

      if (value === "image") {
        attachMmsImageFlag = true;
      }
      if (value === "video") attachMmsVideoFlag = true;
      composeFlag = false;
    }
    this.setState({
      mmsMediaType: value,
      loadDAFileFlag,
      attachMmsImageFlag,
      attachMmsVideoFlag,
      composeFlag,
      broadcastSubDirName: subBucket,
      previousDASize,
      broadcastJustDone: false
    });
  };

  getSubdirName = () => {
    let subBucket;
    if (this.state.broadcastSubDirName === "") {
      subBucket =
        "cid" +
        this.props.communityId +
        "-broadcastId-" +
        this.state.broadcastId;
    } else subBucket = this.state.broadcastSubDirName;

    return subBucket;
  };

  returnToComposeText = async (fileData) => {
    console.log("returnToComposeText fileData:", fileData);
    let previousDASize = 0;

    if (fileData.ops === "cancel") {
      this.setState({
        attachMmsVideoFlag: false,
        attachMmsImageFlag: false,
        composeFlag: true,
      });
    } else {
      // let showMediaTypeFlag = this.state.showMediaTypeFlag;
      let mmsMediaType = this.state.mmsMediaType;
      let mmsDAType = this.state.mmsDAType;

      let mmsVideoS3FileData = this.state.mmsVideoS3FileData;
      let mmsImageS3FileData = this.state.mmsImageS3FileData;
      if (this.state.attachMmsImageFlag) {
        if (this.state.mmsImageS3FileData)
          previousDASize = this.state.mmsImageS3FileData.size;
        console.log("In here ... 444");
        if (fileData.ifDeletedFlag) {
          console.log("Image Need to be deleted");
          mmsImageS3FileData = null;
          mmsMediaType = "none";
          mmsDAType = "none";
          // attachMmsImageFlag = false;
        }
        if (fileData.s3FileData && fileData.s3FileData.key !== "") {
          mmsImageS3FileData = {
            subBucket: fileData.s3FileData.subBucket,
            key: fileData.s3FileData.key,
            type: "image",
            location: fileData.s3FileData.location,
            size: fileData.s3FileData.size,
          };
          // await this.uploadFileInDB(mmsImageS3FileData, "mmsimage");
          // showMediaTypeFlag = true;
          mmsMediaType = "image";
          mmsDAType = "image";
        }
      }

      if (this.state.attachMmsVideoFlag) {
        console.log("In here ... 333");
        if (this.state.mmsVideoS3FileData)
          previousDASize = this.state.mmsVideoS3FileData.size;
        if (fileData.ifDeletedFlag) {
          console.log("Need video to be deleted");
          mmsVideoS3FileData = null;
          // await this.deleteDigiFileFromDb(fileData, "mmsvideo");
          // showMediaTypeFlag = false;
          mmsMediaType = "none";
          mmsDAType = "none";
        }
        if (fileData.s3FileData && fileData.s3FileData.key !== "") {
          mmsVideoS3FileData = {
            subBucket: fileData.s3FileData.subBucket,
            key: fileData.s3FileData.key,
            type: "video",
            location: fileData.s3FileData.location,
            size: fileData.s3FileData.size,
          };
          // await this.uploadFileInDB(mmsVideoS3FileData, "mmsvideo");
          // showMediaTypeFlag = true;
          mmsMediaType = "video";
          mmsDAType = "video";
          // attachMmsVideoFlag = false;
        }
      }

      await this.setState({
        attachMmsVideoFlag: false,
        attachMmsImageFlag: false,

        mmsVideoS3FileData,
        mmsImageS3FileData,

        mmsMediaType,
        mmsDAType,
        fileUploads: null,

        previousDASize,
        composeFlag: true,
      });

      console.log("Going to save in DB .....");
      await this.saveBroadcast();
    }

    console.log("XXX this.state:", this.state);
  };

  LoadSaveInput = () => {
    let textOnly = "yes";
    let mmsDAType = "";
    if (this.state.mmsMediaType !== "none") {
      textOnly = "no";
      mmsDAType = this.state.mmsMediaType;
    }

    let fileUploads = this.state.fileUploads;
    console.log(
      "this.state.mmsImageS3FileData:",
      this.state.mmsImageS3FileData
    );
    if (this.state.mmsImageS3FileData) {
      console.log("in here .... 286");
      fileUploads = this.state.mmsImageS3FileData;
    }

    if (this.state.mmsVideoS3FileData) {
      fileUploads = this.state.mmsVideoS3FileData;
    }

    console.log("FileUploads:", fileUploads);

    let mmsComposition = {
      textCampaign: textOnly,
      interactionType: this.state.interactionType,
      smsHello: this.state.smsHello,
      smsText: this.state.smsText,
      smsBye: this.state.smsBye,
      smsFrom: this.state.smsFrom,
      mmsDAType,
      fileUploads,
    };

    let input = {
      communityId: this.props.communityId,
      coopStoreId: this.state.coopStoreId,
      mmsComposition,
      broadcastId: this.state.broadcastId,
      broadcastName: this.state.broadcastName,
      broadcastType: "smsmms",
      clientProgram: "ComposeText",
      clientFunction: "saveBroadcast",
      previousDASize: this.state.previousDASize,
      recipiants: this.state.recipiants,
    };

    return input;
  };

  saveBroadcast = async () => {
    console.log("in saveBroadcast broadcastName:", this.state.broadcastName);
    if (this.state.broadcastName !== "") {
      try {
        let url = baandaServer + saveBroadcastSms;
        let inp = this.LoadSaveInput();
        console.log("url:", url, " inp:", inp);
        let saveret = await axios.post(url, inp);
        if (saveret.data.status === "success") {
          this.setState({
            broadcast: saveret.data.Msg,
            broadcastId: saveret.data.Msg.broadcastId,
            showTestBtnFlag: true,
            showAttachmentFlag: true,
            errMsg: "Successfully Saved",
            errFlag: false,
          });
        } else {
          this.setState({
            errMsg: saveret.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: "Broadcast Name cannot be blank and must be unique.",
        errFlag: true,
      });
    }
  };

  getBroadcastRecs = async (type) => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      this.state.coopStoreId +
      "&filterType=" +
      type +
      "&domain=" + this.state.domain;
    try {
      let url = baandaServer + getBroadcasts + params;
      console.log("url:", url);
      let retbrds = await axios.get(url);
      if (retbrds.data.status === "success") {
        if (retbrds.data.Msg.length > 0) {
          this.setState({
            brodcasts: retbrds.data.Msg,
          });
        } else {
          this.setState({
            brodcasts: [],
          });
        }
      } else {
        this.setState({
          brodcasts: [],
          errMsg: retbrds.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        brodcasts: [],
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  selectWip = async (wip) => {
    console.log("wip:", wip);
    let previousDASize = 0;
    let mmsImageS3FileData = null;
    let mmsVideoS3FileData = null;
    let loadDAFileFlag = null;
    let mmsMediaType = "none";
    if (wip.mmsComposition.fileUploads) {
      previousDASize = wip.mmsComposition.fileUploads.size;
      if (wip.mmsComposition.fileUploads.type === "image") {
        mmsImageS3FileData = wip.mmsComposition.fileUploads;
        mmsMediaType = "image";
      }
      if (wip.mmsComposition.fileUploads.type === "video") {
        mmsVideoS3FileData = wip.mmsComposition.fileUploads;
        mmsMediaType = "video";
      }
      loadDAFileFlag = true;
    }
    this.setState({
      broadcastId: wip.broadcastId,
      coopStoreId: wip.coopStoreId,
      broadcastName: wip.broadcastName,
      fileUploads: wip.mmsComposition.fileUploads,
      mmsDAType: wip.mmsComposition.mmsDAType,
      smsBye: wip.mmsComposition.smsBye,
      smsFrom: wip.mmsComposition.smsFrom,
      smsHello: wip.mmsComposition.smsHello,
      smsText: wip.mmsComposition.smsText,
      showWipPanel: false,
      showTestBtnFlag: true,
      showAttachmentFlag: true,
      previousDASize,
      mmsVideoS3FileData,
      mmsImageS3FileData,
      loadDAFileFlag,
      mmsMediaType,
      recipiants: wip.recipiants,
    });
  };

  setUploadType = async () => {
    console.log("in setUploadType ");
    this.setState({
      attachMmsVideoFlag: false,
      attachMmsImageFlag: false,
      composeFlag: false,
    });

    let attachMmsImageFlag = false,
      attachMmsVideoFlag = false;
    if (this.state.mmsMediaType === "image") attachMmsImageFlag = true;
    if (this.state.mmsMediaType === "video") attachMmsVideoFlag = true;

    this.setState({
      broadcastSubDirName: this.getSubdirName(),
      attachMmsImageFlag,
      attachMmsVideoFlag,
      sendMsg: "",
    });
  };

  testTextBox = async () => {
    this.setState({
      showTextTestFlag: true,
      errMsg: "",
      errFlag: false,
      composeFlag: false,
    });
  };

  closetestTextBox = async () => {
    this.setState({
      showTextTestFlag: false,
      openBroadcastTextFlag: true,
      composeFlag: true,
    });
  };

  getAutherPhoneToTest = () => {
    let pno = "";
    if (this.props.auth.user.cell) pno = this.props.auth.user.cell.number;

    if (pno.length > 0) {
      if (pno.length === 12) pno = pno.substring(2, 12);
      if (pno.length === 11) pno = pno.substring(1, 11);
    }

    let phns = this.formatPhoneNumber(pno);

    return phns;
  };

  formatPhoneNumber = (str) => {
    //Filter only numbers from the input
    console.log("xx str:", str);
    let cleaned = ("" + str).replace(/\D/g, "");

    //Check if the input is of correct length
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return null;
  };

  handleSendText = async () => {
    let ta;

    let pno = this.props.auth.user.cell.number.toString();
    if (pno.length > 0) {
      if (pno.length === 12) pno = pno.substring(2, 12);
      if (pno.length === 11) pno = pno.substring(1, 11);
    }
    ta = [pno];

    let fileUploads = null;
    if (this.state.mmsImageS3FileData)
      fileUploads = this.state.mmsImageS3FileData;
    if (this.state.mmsVideoS3FileData)
      fileUploads = this.state.mmsVideoS3FileData;

    let input = {
      targetAudience: ta,
      fileUploads,
      smsText: this.state.smsText,
      smsHello: this.state.smsHello,
      smsBye: this.state.smsBye,
      smsFrom: this.state.smsFrom,
      smsToName: this.props.auth.user.name,
      publisherName: this.props.auth.user.name,
      callingFor: "testing",
      interactionType: this.state.interactionType,
      theBroadcast: null,
      mmsMediaType: this.state.mmsMediaType,
    };
    let url = baandaServer + publishBroadcast;
    console.log(">> url:", url, " input:", input);
    try {
      // let url = baandaServer + publishBroadcast;
      // console.log(">> url:", url, " input:", input);
      let retpub = await axios.post(url, input);
      console.log("retpub:", retpub);
      if (retpub.data.status === "success") {
        await this.setState({
          errMsg: "SMS/MMS Successfully Sent.",
          errFlag: false,
          showTextTestFlag: false,
          composeFlag: true,
        });
      } else {
        this.setState({
          errMsg: retpub.data.Msg,
          errFlag: true,
          // showTextTestFlag: false,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
        showTextTestFlag: false,
      });
    }
  };

  handleLoadTarget = async () => {
    console.log("in load target this.state.targetType:", this.state.targetType);
    if (this.state.targetType === "group") {
      await this.loadGrpMembersAsTarget();
      await this.saveBroadcast();
    }
  };

  loadGrpMembersAsTarget = async () => {
    let tgm = [];
    this.state.selectedGroup.members.forEach((mem) => {
      let tgmObj = {
        baandaId: mem.baandaId,
        name: mem.name,
        email: mem.email,
        cell: mem.cell,
        messages: [],
      };
      tgm.push(tgmObj);
    });
    console.log("tgm:", tgm);

    let recipiants = {
      type: "group",
      groupId: this.state.selectedGroup.groupId,
      groupName: this.state.selectedGroup.groupName,
      targetGroupMembers: tgm,
    };

    this.setState({
      recipiants,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleConfirmBroadcast = async () => {
    this.setState((prevstate) => ({
      confirmBroadcast: !prevstate.confirmBroadcast,
    }));
  };

  handleBroadcast = async () => {
    this.setState({
      loadingFlag: true,
    });
    let input = {
      broadcastId: this.state.broadcastId,
      callingFor: "publish",
    };
    let url = baandaServer + publishBroadcast;
    console.log(">> url:", url, " input:", input);
    try {
      let retpub = await axios.post(url, input);
      console.log("retpub:", retpub);
      if (retpub.data.status === "success") {
        this.setState({
          errMsg: "Successfully published. Re-initiate (New) to republish.",
          errFlag: false,
          confirmBroadcast: false,
          loadingFlag: false,
          broadcastJustDone: true,
          // showTextTestFlag: false,
          // composeFlag: true,
        });
        this.initCmoposeNew();
      } else {
        this.setState({
          errMsg: retpub.data.Msg,
          errFlag: true,
          loadingFlag: false,
          // showTextTestFlag: false,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
        showTextTestFlag: false,
        loadingFlag: false,
      });
    }
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    // Upload Spinner _____________________________________________
    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div>
          <ReactLoading
            type={"spokes"}
            color={"#237547"}
            height={25}
            width={25}
            className="comm-entry-spinner"
          />
        </div>
      );
    } else {
      uploadingSpin = null;
    }
    // Upload Panel _______________________________________________

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let exitWipButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.closeWipPanel}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let groupBackButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.closeShowGroup}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let loadTarget = (
      <button
        className="btn_reg_100"
        type="button"
        onClick={this.handleLoadTarget}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Load Target
      </button>
    );

    let broadcastButton = (
      <button
        className="btn_reg_90"
        type="button"
        onClick={this.handleBroadcast}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Broadcast
      </button>
    );

    let targetTypePanel;
    targetTypePanel = (
      <div className="text-center smm-target-row-place">
        <div className="row">
          <div className="col text-center mms-ops-type">
            <strong>Target Type </strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="group"
                  checked={this.state.targetType === "group"}
                  onChange={this.handleTargetType}
                />{" "}
                Group
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="customer"
                  checked={this.state.targetType === "customer"}
                  onChange={this.handleTargetType}
                />{" "}
                Customer
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="community"
                  checked={this.state.targetType === "community"}
                  onChange={this.handleTargetType}
                />{" "}
                Community
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let showGroupPanel;
    if (this.state.showGroupsFlag) {
      if (this.state.groups.length > 0) {
        let bool = true;
        showGroupPanel = (
          <div className="sms-group-show-box">
            <div className="ssm-sel-grp-head">
              Select Group &nbsp;{groupBackButton}
            </div>
            {/* <div className="row sms-group-show-box-list">
              <div className="col"> */}
            {this.state.groups.map((post, i) => (
              <div key={i}>
                <div
                  className={`${
                    bool ? "raw-color-inv-dr" : "raw-color-inv-lt"
                  }`}
                >
                  <div className="row">
                    <div className="col-10 text-left pickup-text">
                      {post.groupName} | {post.description}
                    </div>
                    <div className="col-2 text-center inv-list-but-pos">
                      <button
                        //   onClick={() => this.handleInventory(JSON.stringify(post))}
                        onClick={() => this.handleGroupSelect(post)}
                        className="btn_reg_50"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Sel
                      </button>
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            ))}
            {/* </div>
            </div> */}
          </div>
        );
      } else {
        showGroupPanel = (
          <div className="sms-group-show-box">
            <div>Select Group {groupBackButton}</div>
            <div className="text-center">
              No groups exist in this community.
            </div>
          </div>
        );
      }
    }

    let targetPanel;
    if (this.state.operationType === "target") {
      if (this.state.broadcastId !== 0) {
        targetPanel = (
          <div>
            {targetTypePanel}
            {showGroupPanel}

            <div className="text-center target-load-btn-pos">{loadTarget}</div>
            {this.state.recipiants ? (
              <div className="text-center target-selected-msg">
                Selected recipiant type is '{this.state.recipiants.type}.'
                Number of recipiants is{" "}
                {this.state.recipiants.targetGroupMembers.length}.
              </div>
            ) : null}
          </div>
        );
      } else {
        targetPanel = (
          <div>
            <div className="text-center target-not-ready">
              Compose new or select a WIP to define targets.
            </div>
          </div>
        );
      }
    }

    let broadcastPanel;
    if (this.state.operationType === "broadcast") {
      if (this.state.canBroadcast) {
        broadcastPanel = (
          <div>
            <div className="ssm-broadcast-head">Broadcast SMS/MMS</div>
            <div className="row">
              <div className="col-6 text-right brd-sms-lbl">
                Available Credit
              </div>
              <div className="col-6 text-left brd-sms-txt">
                ${" "}
                {this.commaFormattedCurrency(
                  parseFloat(this.state.availableCredit)
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-6 text-right brd-sms-lbl">Broadcast Fee</div>
              <div className="col-6 text-left brd-sms-txt">
                ${" "}
                {this.commaFormattedCurrency(
                  parseFloat(this.state.broadcastFee)
                )}
              </div>
            </div>
            {parseFloat(this.state.availableCredit) -
              parseFloat(this.state.broadcastFee) >
            0 ? (
              <div className="row">
                <div className="col text-center brd-sms-credit-msg">
                  Broadcast Fee will be deducted from your credits.
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col text-center brd-sms-no-credit">
                  Insufficient credits to broadcast.
                </div>
              </div>
            )}

            {!this.state.broadcastJustDone ? (
              <div className="row">
                <div className="col-6 text-right sms-brd-confirm">
                  <Checkbox
                    checked={this.state.confirmBroadcast}
                    onChange={this.handleConfirmBroadcast}
                  />
                  &nbsp;&nbsp;Confirm to broadcast.
                </div>
                <div className="col-2 text-center">{uploadingSpin}</div>
                {this.state.confirmBroadcast ? (
                  <div className="col-4 text-left">{broadcastButton}</div>
                ) : (
                  <div className="col-4 text-left">&nbsp;</div>
                )}
              </div>
            ) : null}
          </div>
        );
      } else {
        broadcastPanel = (
          <div>
            <div className="ssm-broadcast-head">Broadcast SMS/MMS</div>
            <div className="row">
              <div className="col text-center broadcast-redi-err">
                Broadcast readiness incomplete.
              </div>
            </div>
          </div>
        );
      }
    }

    let selectWipPanel;
    if (this.state.workWith === "wip" && this.state.showWipPanel) {
      if (this.state.brodcasts.length > 0) {
        console.log("in here ....");
        let bool = true;
        selectWipPanel = (
          <div className="row list-wip-box">
            <div className="col">
              <div className="text-center">SMSMMS WIP {exitWipButton}</div>
              <div className="list-wip-box-scroll">
                {this.state.brodcasts.map((brd, i) => (
                  <div key={i}>
                    <div
                      className={`${
                        bool ? "row raw-color-inv-dr" : "row raw-color-inv-lt"
                      }`}
                    >
                      <div className="col-10 text-left pickup-text">
                        {brd.broadcastName}
                      </div>
                      <div className="col-2 text-center inv-list-but-pos">
                        <button
                          //   onClick={() => this.handleInventory(JSON.stringify(brd))}
                          onClick={() => this.selectWip(brd)}
                          className="btn_reg_40"
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          Sel
                        </button>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                ))}
              </div>
            </div>
          </div>
        );
      } else {
        selectWipPanel = (
          <div className="list-wip-box">
            <div className="text-center">SMSMMS WIP {exitWipButton}</div>
            <div className="text-center sms-no-wip-rec">
              No work-in-progress record found.
            </div>
          </div>
        );
      }
    }

    let campaignStartPanel;
    campaignStartPanel = (
      <div className="text-center brd-text-row-place">
        <div className="row">
          <div className="col text-center mms-ops-type">
            <strong>Campaign Start </strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="new"
                  checked={this.state.workWith === "new"}
                  onChange={this.handletextingDecision}
                />{" "}
                New
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="wip"
                  checked={this.state.workWith === "wip"}
                  onChange={this.handletextingDecision}
                />{" "}
                Work in progress
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="copy"
                  checked={this.state.workWith === "copy"}
                  onChange={this.handletextingDecision}
                  disabled
                />{" "}
                Copy Old
              </label>
            </div>
            {infoModalButton}
          </div>
        </div>
      </div>
    );

    let interactionTypePanel;
    interactionTypePanel = (
      <div className="text-center brd-text-row-place">
        <div className="row">
          <div className="col text-center mms-ops-type">
            <strong>Interaction Type </strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="personal"
                  checked={this.state.interactionType === "personal"}
                  onChange={this.handleBroadcastType}
                />{" "}
                Personalized
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="mass"
                  checked={this.state.interactionType === "mass"}
                  onChange={this.handleBroadcastType}
                />{" "}
                Mass - generic
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let opsTypePanel;
    opsTypePanel = (
      <div className="text-center brd-text-row-place">
        <div className="row">
          <div className="col text-center mms-ops-type">
            <strong>Operation Type </strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="compose"
                  checked={this.state.operationType === "compose"}
                  onChange={this.handleOperationType}
                />{" "}
                Compose
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="target"
                  checked={this.state.operationType === "target"}
                  onChange={this.handleOperationType}
                />{" "}
                Target
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="broadcast"
                  checked={this.state.operationType === "broadcast"}
                  onChange={this.handleOperationType}
                />{" "}
                Broadcast
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let broadcastNamePanel;
    broadcastNamePanel = (
      <div>
        <div className="row">
          <div className="col-3 text-right mms-text-lbl">Campaign Name</div>
          <div className="col-9 text-left">
            <input
              name="broadcastName"
              type="text"
              value={this.state.broadcastName}
              onChange={this.onChange}
              size="20"
              maxLength="20"
              className="mms-campaign-name"
              placeholder="Enter campaign Name"
            />{" "}
            &nbsp;
            {this.state.operationType === "compose" ? (
              <button
                className="btn_reg_60"
                type="button"
                onClick={this.saveBroadcast}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Save
              </button>
            ) : null}
          </div>
        </div>
        {opsTypePanel}
      </div>
    );

    let composeButtonPanel;
    let mmsMediaTypePanel;
    if (this.state.operationType === "compose" && this.state.composeFlag) {
      composeButtonPanel = (
        <div className="text-center">
          {this.state.loadDAFileFlag ? (
            <button
              className="btn_reg_70"
              type="button"
              onClick={this.setUploadType}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Upload
            </button>
          ) : null}
          {this.state.showTestBtnFlag ? (
            <button
              className="btn_reg_60"
              type="button"
              onClick={this.testTextBox}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              Test
            </button>
          ) : null}
        </div>
      );

      if (!this.state.fileUploads) {
        mmsMediaTypePanel = (
          <div className="text-center mms-content-type">
            <div className="row">
              <div className="col text-center radio-font-style">
                <strong>Attach Digital File&nbsp;&nbsp;</strong>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="none"
                      checked={this.state.mmsMediaType === "none"}
                      onChange={this.handleMediaTypeOption}
                    />{" "}
                    None (no MMS)
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="image"
                      checked={this.state.mmsMediaType === "image"}
                      onChange={this.handleMediaTypeOption}
                    />{" "}
                    Image
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="video"
                      checked={this.state.mmsMediaType === "video"}
                      onChange={this.handleMediaTypeOption}
                    />{" "}
                    Video
                  </label>
                </div>
              </div>
            </div>
          </div>
        );
      } else {
        mmsMediaTypePanel = (
          <div className="text-center mms-content-type-msg">
            Delete (click Upload) existing media file to access / alter
            attachment.
          </div>
        );
      }
    }

    let composePanel;
    if (this.state.operationType === "compose") {
      composePanel = (
        <div>
          <div className="text-center mms-compose-header">
            <u>Compose</u>
          </div>
          {this.state.interactionType === "personal" ? (
            <div className="row">
              <div className="col-4 text-right">Salute</div>
              <div className="col-4 text-left">
                <input
                  name="smsHello"
                  type="text"
                  value={this.state.smsHello}
                  onChange={this.onChange}
                  size="20"
                  maxLength="20"
                  className="mms-hail-input-txt"
                  placeholder="Personalize - blank otherwise"
                />
              </div>
              <div className="col-4 text-right">&nbsp;</div>
            </div>
          ) : (
            <div className="row">
              <div className="col  text-right ">&nbsp;</div>
            </div>
          )}

          <div className="row">
            <div className="col-8 event-directions-mms text-center">
              Messages (within 200 chars)
            </div>
            <div className="col-4 event-directions-mms text-left">
              Used:&nbsp;{this.state.smsText.length}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <textarea
                name="smsText"
                type="text"
                value={this.state.smsText}
                onChange={this.onChange}
                rows="3"
                wrap="hard"
                size="300"
                maxLength="300"
                className="notify-sms-text"
                placeholder="SMS in 150 chars or less"
              />
            </div>
          </div>
          {this.state.interactionType === "personal" ? (
            <div className="row sms-signoff-row">
              <div className="col-4 text-right sms-hello-bye">SignOff</div>
              <div className="col-4 text-left">
                <input
                  name="smsBye"
                  type="text"
                  value={this.state.smsBye}
                  onChange={this.onChange}
                  size="20"
                  maxLength="20"
                  className="mms-hail-input"
                  placeholder="Personalize - blank otherwise"
                />
              </div>
              <div className="col-4 text-right">&nbsp;</div>
            </div>
          ) : (
            <div className="row sms-signoff-row">
              <div className="col  text-right">&nbsp;</div>
            </div>
          )}

          {this.state.interactionType === "personal" ? (
            <div className="row sms-signoff-row">
              <div className="col-4 text-right sms-hello-bye">From </div>
              <div className="col-4 text-left">
                <input
                  name="smsFrom"
                  type="text"
                  value={this.state.smsFrom}
                  onChange={this.onChange}
                  size="20"
                  maxLength="20"
                  className="mms-hail-input"
                  placeholder="Sender's Name"
                />
              </div>
              <div className="col-4 text-right">&nbsp;</div>
            </div>
          ) : (
            <div className="row sms-signoff-row">
              <div className="col  text-right">&nbsp;</div>
            </div>
          )}

          {interactionTypePanel}
          {this.state.showAttachmentFlag ? mmsMediaTypePanel : null}
          {composeButtonPanel}
        </div>
      );
    }

    let phoneButtonPanel = (
      <div>
        <button
          className="btn-phone-send"
          type="button"
          onClick={this.handleSendText}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Send
        </button>
        <button
          className="btn-phone-exit"
          type="button"
          onClick={this.closetestTextBox}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          X
        </button>
      </div>
    );

    let phonePanel;
    if (this.state.showTextTestFlag) {
      let testName = "";
      let imageLocation = "";
      let videoType = "video/";
      if (this.state.mmsImageS3FileData) {
        imageLocation = this.state.mmsImageS3FileData.location;
      }
      let videoLocation = "";
      if (this.state.mmsVideoS3FileData) {
        videoLocation = this.state.mmsVideoS3FileData.location;

        let filetype = this.state.mmsVideoS3FileData.key.split(".").pop();
        console.log("video filetype:", filetype);
        videoType = videoType + filetype;
        console.log("videoType:", videoType);
      }

      let phns = this.getAutherPhoneToTest();
      testName = this.props.auth.user.name;

      phonePanel = (
        <div className="cell-phone-box">
          <div className="cell-phone-body-box">
            <div className="row">
              <div className="col-2 txt-phone-to text-right">To:</div>
              {/* {this.state.selectedGroup ? (
                <div className="col-10 txt-phone-to-no text-left">
                  {this.state.selectedGroup.groupName}
                </div>
              ) : ( */}
              <div className="col-10 txt-phone-to-no text-left">{phns}</div>
              {/* )} */}
            </div>
            <hr className="brd-hr-text" />
            {this.state.smsHello !== "" &&
            this.state.interactionType === "personal" ? (
              <div className="row brd-text-message-placement">
                <div className="col text-left brd-text-hello">
                  {this.state.smsHello + " " + testName + ","}
                </div>
              </div>
            ) : null}

            <div className="row brd-text-message-placement">
              <div className="col brd-text-body">
                {/* <div className="col text-center brd-text-body"> */}
                {this.state.smsText}
              </div>
            </div>
            {this.state.smsBye !== "" &&
            this.state.interactionType === "personal" ? (
              <div className="row brd-text-message-placement">
                <div className="col text-left brd-text-bye">
                  {this.state.smsBye + " " + this.state.smsFrom + "."}
                </div>
              </div>
            ) : null}
            <hr />
            {imageLocation === "" ? null : (
              <div>
                {/* <hr className="brd-hr-text" /> */}
                <div className="row text-image-test-pls">
                  <div className="text-image-position">
                    <img
                      src={imageLocation}
                      alt="textImage"
                      className="text-image-xx"
                    />
                  </div>
                </div>
              </div>
            )}
            {videoLocation === "" ? null : (
              <div>
                <hr className="brd-hr-text" />
                <div className="row text-video-placement">
                  <div className="text-video-position">
                    <video className="text-video-x" controls>
                      <source src={videoLocation} type={videoType} />
                    </video>
                  </div>
                </div>
              </div>
            )}
          </div>
          {phoneButtonPanel}
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        {broadcastNamePanel}

        {this.state.showTextTestFlag ? (
          <div className="test-phone-pos">{phonePanel}</div>
        ) : null}
        {this.state.operationType === "target" ? (
          <div className="target-panel-pos">{targetPanel}</div>
        ) : null}
        {this.state.operationType === "broadcast" ? (
          <div className="target-panel-pos">{broadcastPanel}</div>
        ) : null}

        {this.state.composeFlag ? composePanel : null}
      </div>
    );

    if (this.state.attachMmsImageFlag) {
      outputPanel = (
        <div className="brd-text-upload-pos">
          <LoadFileToS3
            returnToCaller={this.returnToComposeText}
            communityId={this.props.communityId}
            fileType={imageTypes}
            maxSize={maxmmsImageSize}
            s3subdir={this.state.broadcastSubDirName}
            requestor="Broadcast Text's Image Attachment"
            s3FileData={this.state.mmsImageS3FileData}
            type="mmsimage"
          />
        </div>
      );
    }

    if (this.state.attachMmsVideoFlag) {
      outputPanel = (
        <div className="brd-text-upload-pos">
          <LoadFileToS3
            returnToCaller={this.returnToComposeText}
            communityId={this.props.communityId}
            fileType={videoTypes}
            maxSize={maxmmsVideoSize}
            s3subdir={this.state.broadcastSubDirName}
            requestor="Broadcast Text's Video Attachment"
            s3FileData={this.state.mmsVideoS3FileData}
            type="mmsvideo"
          />
        </div>
      );
    }

    return (
      <div>
        {campaignStartPanel}
        {selectWipPanel}
        {outputPanel}

        <div
          className={
            this.state.errFlag
              ? "text-center send-test-text-err"
              : "text-center send-test-text"
          }
        >
          {this.state.errMsg}
        </div>
        <ModalContainer />
      </div>
    );
  }
}

ComposeText.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ComposeText));
