import React, { Component } from "react";

import axios from "axios";
 
import "./RegisterPIWIP.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getPayableWIPInvoices = "/routes/finance/getPayableWIPInvoices?";

class RegisterPIWIP extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoices: [],
    };
  }

  componentDidMount = async () => {
    await this.getInvoices();
  };

  getInvoices = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      this.props.coopStoreId;
    let url = baandaServer + getPayableWIPInvoices + params;
    // console.log('url:', url);
    try {
      let wipret = await axios.get(url);
      // console.log('wipret.data:', wipret.data);
      if (wipret.data.status === "success") {
        if (wipret.data.Msg.length >= 0) {
          this.setState({
            invoices: wipret.data.Msg,
            errMsg: "",
            errFlag: false,
          });
        } else {
          this.setState({
            invoices: [],
            errMsg: "No work-in-progress payble invoice exist.",
            errFlag: false,
          });
        }
      } else {
        this.setState({
          invoices: [],
          errMsg: wipret.data.Msg,
          errFlag: false,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: false,
      });
    }
  };

  returnToRegistration = () => {
    this.props.returnToCaller(null);
  };

  selectThisInvoice = (inv) => {
    this.props.returnToCaller(inv);
  }

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('RegisterPIWIP...');

    let exitButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToRegistration}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
       <i className="fas fa-step-backward" />
      </button>
    );

    let theList;
    let bool = true;
    theList = (
      <div>
        <div className="row wip-inv-list-header">
          <div className="col-10 text-left wip-inv-list-lbl">
            Invoice Id | Customer Name ( Email ) | Date
          </div>
          <div className="col-2 text-center wip-inv-list-lbl">Ops</div>
        </div>
        <div className="fixedsize-invoice-list-box">
          {this.state.invoices.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    bool ? "row row-col-details-dr" : "row row-col-details-lt"
                  }
                >
                  <div className="col-10 text-left task-pm-select-lbl">
                    {obj.invoiceId} | {obj.customerName} ({obj.customerEmail}) |{" "}
                    {obj.created_at.substring(0, 10)}
                  </div>
                  <div className="col-2 text-center">
                    <button
                      onClick={() => this.selectThisInvoice(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                      className="btn_reg_40"
                    >
                      Sel
                    </button>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="manual-invoice-list-box">
        <div className="text-center manual-inv-list-header">
          {" "}
          WIP Invoice list &nbsp;&nbsp;&nbsp;&nbsp; {exitButton}
        </div>
        {theList}
        <div
          className={
            this.state.errFlag
              ? "text-center manual-inv-msg-err"
              : "text-center manual-inv-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

     
     
    return <div>{outputPanel}</div>;
  }
}

export default RegisterPIWIP;
