import React, { Component } from "react";

import axios from "axios";
import "./PackageDimension.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const updateCatalogItem = "/routes/dashboard/updateCatalogItem";

class PackageDimension extends Component {
  constructor(props) {
    super(props);

    this.state = {
      catalog: null,

      scaleType: "fps",
      length: 1,
      width: 1,
      height: 1,
      weight: 1,
      comment: "",
      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let tpcm = this.props.catalog.packageDimension;
    // console.log('tpcm:', tpcm);
    this.setState({
      catalog: this.props.catalog,
      scaleType: tpcm.scaleType,
      length: tpcm.length,
      width: tpcm.width,
      height: tpcm.height,
      weight: tpcm.weight,
      comment: tpcm.comment,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
      errMsg: "",
      errFlag: false,
    });
  };

  onChangeValue = async (e) => {
    let value = e.target.value;

    // if (value < 100 && value >= 0) {
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      this.setState({
        [e.target.name]: val,
        errMsg: "",
        errFlag: false,
      });
    } else {
      this.setState({
        [e.target.name]: value,
        errMsg: "",
        errFlag: false,
      });
    }
    // }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleScaleType = async (e) => {
    await this.setState({
      scaleType: e.target.value,
    });
  };

  returnToCatalogAdmin = () => {
    this.props.returnToCaller(this.state.catalog);
  };

  validatePkg = () => {
    let msg = "";
    let flag = "success";
    if (
      parseFloat(this.state.length) <= 0 ||
      parseFloat(this.state.height) <= 0 ||
      parseFloat(this.state.width) <= 0 ||
      parseFloat(this.state.weight) <= 0
    ) {
      msg = "Dimensions must be positive numbers only. ";
      flag = "error";
    }

    return { status: flag, Msg: msg };
  };

  handleSave = async () => {
    // console.log("To save ...");
    let valid = this.validatePkg();
    if (valid.status === "success") {
      let packageDimension = {
        length: this.state.length,
        height: this.state.height,
        width: this.state.width,
        weight: this.state.weight,
        scaleType: this.state.scaleType,
        comment: this.state.comment,
      };

      let coopStoreId = 0;
      if (this.props.store) coopStoreId = this.props.store.coopStoreId;

      let input = {
        communityId: this.state.catalog.communityId,
        coopStoreId,
        itemId: this.state.catalog.itemId,
        updtmode: "dimensionmgmt",
        packageDimension,
        clientProgram: "PackageDimension.js",
        clientFunction: "handleSave",
      };
      let url = baandaServer + updateCatalogItem;
      // console.log("url:", url);
      // console.log("input:", input);

      try {
        let retserv = await axios.post(url, input);
        // console.log("retserv:", retserv);
        if (retserv.data.status === "success") {
          await this.setState({
            catalog: retserv.data.Msg,
            errMsg: "Successfully updated.",
            errFlag: false,
          });
        } else {
          await this.setState({
            errMsg: retserv.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        console.log("err:", err.message);
        await this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: valid.Msg,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('PackageDimension...');

    let buttonPanel;
    buttonPanel = (
      <div className="cat-dim-button-row">
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.handleSave}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>

        <button
          className="btn_back_main"
          type="button"
          onClick={this.returnToCatalogAdmin}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
      </div>
    );

    let dimensionsPanel;
    dimensionsPanel = (
      <div className="text-center">
        <div className="row cat-dim-rows">
          {/* <div className="col-4 text-right catalog-dimension-label">
            Scale Type
          </div> */}
          <div className="col text-center catalog-dimension-label">
            Scale Type&nbsp;&nbsp;
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="budget-ops"
                  type="radio"
                  value="fps"
                  checked={this.state.scaleType === "fps"}
                  onChange={this.handleScaleType}
                />{" "}
                Foot/Lbs (default)
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="budget-ops"
                  type="radio"
                  value="mks"
                  checked={this.state.scaleType === "mks"}
                  onChange={this.handleScaleType}
                  disabled
                />{" "}
                MKS (Meter / Kilogram)
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="budget-ops"
                  type="radio"
                  value="nonstandard"
                  checked={this.state.scaleType === "nonstandard"}
                  onChange={this.handleScaleType}
                  disabled
                />{" "}
                Non-standard (special)
              </label>
            </div>
          </div>
        </div>
        <div className="row cat-dim-rows">
          <div className="col-5 text-right catalog-dimension-label">Length</div>
          <div className="col-7 text-left catalog-dimension-label">
            <input
              name="length"
              type="number"
              value={this.state.length}
              onChange={this.onChangeValue}
              className="package-dimension-field"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            &nbsp;
            {this.state.scaleType === "fps" ? "In inches" : "In centimeters"}
          </div>
        </div>
        <div className="row cat-dim-rows">
          <div className="col-5 text-right catalog-dimension-label">Width</div>
          <div className="col-7 text-left catalog-dimension-label">
            <input
              name="width"
              type="number"
              value={this.state.width}
              onChange={this.onChangeValue}
              className="package-dimension-field"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            &nbsp;
            {this.state.scaleType === "fps" ? "In inches" : "In centimeters"}
          </div>
        </div>
        <div className="row cat-dim-rows">
          <div className="col-5 text-right catalog-dimension-label">Height</div>
          <div className="col-7 text-left catalog-dimension-label">
            <input
              name="height"
              type="number"
              value={this.state.height}
              onChange={this.onChangeValue}
              className="package-dimension-field"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            &nbsp;
            {this.state.scaleType === "fps" ? "In inches" : "In centimeters"}
          </div>
        </div>
        <div className="row cat-dim-rows">
          <div className="col-5 text-right catalog-dimension-label">Weight</div>
          <div className="col-7 text-left catalog-dimension-label">
            <input
              name="weight"
              type="number"
              value={this.state.weight}
              onChange={this.onChangeValue}
              className="package-dimension-field"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />{" "}
            &nbsp;
            {this.state.scaleType === "fps"
              ? "In Ounce(oz)"
              : "In grams (gms)"}
          </div>
        </div>
        <div className="row cat-dim-rows">
          <div className="col-5 text-right catalog-dimension-label">
            Comment
          </div>
          <div className="col-7 text-left catalog-dimension-label">
            <input
              name="comment"
              type="text"
              value={this.state.comment}
              onChange={this.onChange}
              size="50"
              maxLength="50"
              className="cat-dim-comment"
              placeholder="Special direction (optional)"
            />
          </div>
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div className="catshow-shipping-dimension">
        <div className="text-center ship-dimen-head">
          Shipping or Pickup Package Dimensions
        </div>
        {dimensionsPanel}
        {buttonPanel}
        <div className="cat-dim-gen-msg">
          The dimensions you specify will be used as a packaging standard for
          shipping or pickup mgmt. Non-standard (special) is
          used for non-standard packaging (e.g. shipping an animal). For
          non-standard, the dimensions will be ignored.
        </div>
        <div
          className={
            this.state.errFlag
              ? "text-center cat-dim-msg-err"
              : "text-center cat-dim-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

export default PackageDimension;
