import React, { Component } from "react";

import axios from "axios";

import "./ExpenseList.css";

import ExpenseFilter from "./ExpenseFilter";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getExpenseList = "/routes/reports/getExpenseList?";

class ExpenseList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearchFlag: false,
      showExpenseList: false,
      expData: [],
      filterType: "",
      startDateStr: "",
      endDateStr: "",

      dtRecipiantCell: "",
      dtRecipiantEmail: "",
      dtRecipiantName: "",
      dtExpenseNote: "",
      dtReceiptUrl: "",
      dtType: "",
      dtExpenseCategory: "",
      dtPaymentInfo: [],
      dtCurrencyType: "",
      dtCurrencyName: "",
      dtSpentDate: "",
      dtAmount: 0,
      dtExpenseMedium: "",
      showExpenseFlag: false,

      showReceiptImage: false,
    };
  }

  returnToWhereverYouCameFrom = () => {
    // this.setState({
    //   showReceiptImage: false,
    // })
    this.props.returnToCaller();
  };

  showSearchPanel = () => {
    this.setState({
      showSearchFlag: true,
    });
  };

  getExpenseData = async (cond) => {
    // console.log("cond:", cond);
    let category = "";
    if (cond.selectedCategory) category = cond.selectedCategory.categoryOf;
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&dateFrom=" +
      cond.dateFrom +
      "&dateTo=" +
      cond.dateTo +
      "&category=" +
      category +
      "&filterType=" +
      cond.filterType;

    let url = baandaServer + getExpenseList + params;
    try {
      let expret = await axios.get(url);
      // console.log("expret.data:", expret.data);
      if (expret.data.status === "success") {
        this.setState({
          expData: expret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: expret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  returnFromFilter = async (cond) => {
    // console.log("cond:", cond);
    let showExpenseList = false;
    let filterType = "";
    let startDateStr = "";
    let endDateStr = "";
    if (cond) {
      await this.getExpenseData(cond);
      showExpenseList = true;
      filterType = cond.filterType;
      startDateStr = this.formatDate(cond.dateFrom);
      endDateStr = this.formatDate(cond.dateTo);
    }
    this.setState({
      showSearchFlag: false,
      showExpenseList,
      startDateStr,
      endDateStr,
      filterType,
    });
  };

  padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  formatDate = (date) => {
    let dt = date;
    if (!this.dateIsValid(date)) dt = new Date(date);
    return [
      this.padTo2Digits(dt.getMonth() + 1),
      this.padTo2Digits(dt.getDate()),
      dt.getFullYear(),
    ].join("/");
  };

  dateIsValid = (date) => {
    return date instanceof Date && !isNaN(date);
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  processExpenseDetails = async (exp) => {
    // console.log("exp:", exp);
    let expcat = "";

    if (exp.expenseCategory !== "") expcat = exp.expenseCategory;
    if (exp.expenseCategoryOf !== "") expcat = exp.expenseCategoryOf;

    let receiptUrl = "";
    if (exp.fileUploads.length > 0) {
      receiptUrl = exp.fileUploads[0].location;
    }

    this.setState({
      dtRecipiantCell: exp.recipiantCell,
      dtRecipiantEmail: exp.recipiantEmail,
      dtRecipiantName: exp.recipiantName,
      dtExpenseNote: exp.expenseNote,
      dtReceiptUrl: receiptUrl,
      dtType: exp.documentType,
      dtExpenseCategory: expcat,
      dtPaymentInfo: exp.paymentInfo,
      dtCurrencyType: exp.currencyType,
      dtCurrencyName: exp.currencyName,
      dtAmount: parseFloat(exp.expenseAmount),
      dtSpentDate: exp.spent_at.substring(0, 10),
      dtExpenseMedium: exp.expenseMedium,
      showExpenseFlag: true,
    });
  };

  returnFromDetailExp = () => {
    this.setState({
      dtRecipiantCell: "",
      dtRecipiantEmail: "",
      dtRecipiantName: "",
      dtExpenseNote: "",
      dtReceiptUrl: "",
      dtType: "",
      dtExpenseCategory: "",
      dtPaymentInfo: [],
      dtCurrencyType: "",
      dtCurrencyName: "",
      dtSpentDate: "",
      dtAmount: 0,
      dtExpenseMedium: "",
      showExpenseFlag: false,
      showReceiptImage: false,
    });
  };

  showExpenseReceipt = () => {
    this.setState({
      showReceiptImage: true,
    });
  };

  returnFromShowImage = () => {
    this.setState({
      showReceiptImage: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ExpenseList...");

    let exitButton = (
      <button
        onClick={this.returnToWhereverYouCameFrom}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let searchButton = (
      <button
        onClick={this.showSearchPanel}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_70 exp-search-btn"
      >
        Search
      </button>
    );

    let retFromDetailButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnFromDetailExp}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let searchPanel;
    if (this.state.showSearchFlag) {
      let coopStoreId = 0;
      if (this.props.store) coopStoreId = this.props.coopStoreId;
      searchPanel = (
        <div>
          <ExpenseFilter
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            returnToCaller={(cond) => this.returnFromFilter(cond)}
          />
        </div>
      );
    }

    let expenselistHeader;
    let expenseListPanel;
    if (this.state.showExpenseList && this.state.filterType === "all") {
      expenselistHeader = (
        <div className="row">
          <div className="col-1 text-center">Date</div>
          <div className="col-3 text-left ">
            &nbsp;&nbsp;&nbsp;&nbsp;Category
          </div>
          <div className="col-5 text-left">Note</div>
          <div className="col-2 text-right">Amount $</div>
          <div className="col-1 text-left">Ops</div>
        </div>
      );
      if (this.state.expData.length > 0) {
        let bool = true;
        expenseListPanel = (
          <div>
            <div className="expense-list-scroll-box">
              {this.state.expData.map((obj, i) => {
                // console.log("690 obj:", obj);
                return (
                  <div key={i}>
                    <div
                      className={
                        bool ? "row gl-line-row-dr" : "row gl-line-row-lt"
                      }
                    >
                      <div className="col-1 text-center exp-line-lbl">
                        {this.formatDate(obj.spent_at)}
                      </div>
                      <div className="col-3 text-left exp-line-desc">
                        {obj.expenseCategory}
                      </div>
                      <div className="col-5 text-left exp-line-lbl">
                        {obj.expenseNote}
                      </div>
                      <div className="col-2 text-right exp-line-lbl">
                        {this.commaFormattedCurrency(obj.expenseAmount)}
                      </div>
                      <div className="col-1 text-center">
                        <button
                          onClick={() => {
                            this.processExpenseDetails(obj);
                          }}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                          className="btn_reg_30 exp-ops-btn-pos"
                        >
                          ...
                        </button>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
        );
      } else {
        expenseListPanel = (
          <div className="expense-list-scroll-box">
            No records available for the search condition.
          </div>
        );
      }
    }

    let headerPanel = (
      <div className="row">
        <div className="col-4 text-left gl-start-date">
          Starts:&nbsp;
          {this.state.startDateStr === "" ? (
            "Select"
          ) : (
            <font color="green">{this.state.startDateStr}</font>
          )}
        </div>
        <div className="col-4 gl-report-header text-center">
          Expense List&nbsp;&nbsp; {searchButton} {exitButton}
        </div>
        <div className="col-4 text-right gl-end-date">
          Ends:&nbsp;
          {this.state.endDateStr === "" ? (
            "Select"
          ) : (
            <font color="red">{this.state.endDateStr}</font>
          )}
        </div>
      </div>
    );

    let showExpenseDetailPanel;
    if (this.state.showExpenseFlag) {
      showExpenseDetailPanel = (
        <div className="rep-exp-detail-box">
          <div className="test-center exp-det-header">
            Expense Detail &nbsp;{retFromDetailButton}
          </div>
          <div className="row">
            <div className="col-3 text-right rep-exp-lbl">Recipient Name</div>
            <div className="col-9 text-left rep-exp-txt">
              {this.state.dtRecipiantName === ""
                ? "No particular recipiant"
                : this.state.dtRecipiantName}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right rep-exp-lbl">Email</div>
            <div className="col-9 text-left rep-exp-txt">
              {this.state.dtRecipiantEmail}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right rep-exp-lbl">Cell</div>
            <div className="col-9 text-left rep-exp-txt">
              {this.state.dtRecipiantCell}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right rep-exp-lbl">Category</div>
            <div className="col-9 text-left rep-exp-txt">
              {this.state.dtExpenseCategory}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right rep-exp-lbl">Description</div>
            <div className="col-9 text-left rep-exp-txt">
              {this.state.dtExpenseNote}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right rep-exp-lbl">Type</div>
            <div className="col-9 text-left rep-exp-txt">
              {this.state.dtType}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right rep-exp-lbl">Exp Date</div>
            <div className="col-9 text-left rep-exp-txt">
              {this.state.dtSpentDate}&nbsp;&nbsp;(Year-Month-Date)
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right rep-exp-lbl">Currency</div>
            <div className="col-9 text-left rep-exp-txt">
              {this.state.dtCurrencyName} | {this.state.dtCurrencyType}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right rep-exp-lbl">Medium</div>
            <div className="col-9 text-left rep-exp-txt">
              {this.state.dtExpenseMedium}
            </div>
          </div>
          {this.state.dtPaymentInfo.length > 0 ? (
            <div>
              <div className="row">
                <div className="col-3 text-right rep-exp-lbl">Amount</div>
                <div className="col-9 text-left rep-exp-txt">
                  ${" "}
                  {this.commaFormattedCurrency(
                    this.state.dtPaymentInfo[0].amount
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-3 text-right rep-exp-lbl">Medium</div>
                <div className="col-9 text-left rep-exp-txt">
                  {this.state.dtPaymentInfo[0].payMedium}
                </div>
              </div>
              <div className="row">
                <div className="col-3 text-right rep-exp-lbl">Pay Note</div>
                <div className="col-9 text-left rep-exp-txt">
                  {this.state.dtPaymentInfo[0].description}
                </div>
              </div>
            </div>
          ) : null}
          {this.state.dtAmount ? (
            <div className="row">
              <div className="col-3 text-right rep-exp-lbl">Exp Amount</div>
              <div className="col-9 text-left rep-exp-txt">
                $ {this.commaFormattedCurrency(this.state.dtAmount)}
              </div>
            </div>
          ) : null}

          {this.state.dtReceiptUrl === "" ? (
            <div className="row">
              <div className="col-3 text-right rep-exp-lbl">Receipt</div>
              <div className="col-9 text-left rep-exp-txt">No Receipt </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-3 text-right rep-exp-lbl">
                <button
                  className="btn_reg_100"
                  type="button"
                  onClick={this.showExpenseReceipt}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Receipt
                </button>
              </div>
              <div className="col-9 text-left rep-exp-txt">
                {this.state.dtReceiptUrl}
              </div>
            </div>
          )}
        </div>
      );
    }

    let receiptShowPanel;
    if (this.state.showReceiptImage) {
      receiptShowPanel = (
        <div className="rep-receipt-box">
          <div className="text-center exp-rep-show-img-head">
            The Receipt let exitButton &nbsp;
            <button
              onClick={this.returnFromShowImage}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              className="btn_back_main"
            >
              <i className="fas fa-step-backward" />
            </button>
            {/* <div className="rep-scroll-receipt-box"> */}
              <img src={this.state.dtReceiptUrl} width="370" height="auto" alt="NoRcpt"/>
            {/* </div> */}
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div className="expense-present-box">
        <div className="text-center expense-list-header">
          {headerPanel}
          {searchPanel}
          <div className="row">
            <div className="col">{showExpenseDetailPanel}</div>
          </div>
          <div className="row">
            <div className="col">{receiptShowPanel}</div>
          </div>

          {expenselistHeader}
          {expenseListPanel}
        </div>
      </div>
    );
    return <div>{outputPanel}</div>;
  }
}

export default ExpenseList;
