import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ReceiveMoneyViaCC from "../../../../../account/finance/communities/ReceiveMoneyViaCC";

import "./Payment.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getStripeAccId = "/routes/dashboard/getStripeAccId?";
const receiveLoanInvestPay = "/routes/dashboard/receiveLoanInvestPay";

const Checkbox = (props) => <input type="checkbox" {...props} />;

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentType: "cash",

      deviceSize: "",

      checkPayTo: "",
      checkDate: moment(),
      checkBankName: "",
      checkNo: "",
      accountNo: "",
      routingNo: "",

      StripeAccId: "",
      isStripeReady: false,

      confirmInvLoan: false,
      loanInvestData: null,
      showPanelFlag: true,
      warningFlag: false,
      sendEmailFlag: false,
      processStatus: "",
      buttonPanelFlag: true,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.getStripeInfo();
    let warningFlag = false;
    if (this.props.ilState.docS3FileData === null) warningFlag = true;
    await this.setState({
      deviceSize,
      warningFlag,
    });
  };

  getStripeInfo = async () => {
    try {
      let accParm = "communityId=" + this.props.communityId;
      let gaurl = baandaServer + getStripeAccId + accParm;
      // console.log(">>>>> gaurl:", gaurl);
      let garet = await axios.get(gaurl);
      // console.log("============= garet:", garet);
      let isStripeReady = true;
      if (garet.data.status === "error") isStripeReady = false;
      let StripeAccId = garet.data.Msg;
      await this.setState({
        StripeAccId,
        isStripeReady,
      });
    } catch (err) {
      console.log("Failed to get accId err:", err.message);
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleExit = async (retData) => {
    // console.log("Returned from  Payment retdata:", retData);
    await this.setState({
      payByCCFlag: false,
      showPanelFlag: true,
      warningFlag: false,
    });
    this.props.returnToCaller(retData);
  };

  handlePaymentType = async (e) => {
    await this.setState({
      paymentType: e.target.value,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleConfirmReceivedPay = async () => {
    await this.setState((prevstate) => ({
      confirmInvLoan: !prevstate.confirmInvLoan,
    }));
  };

  handleEmailConfirm = async () => {
    await this.setState((prevstate) => ({
      sendEmailFlag: !prevstate.sendEmailFlag,
    }));
  };

  packageInvLoanData = async () => {
    let investDocs = [];

    // let fname = this.props.ilState.idS3FileData.key.split("/").pop();
    // let ext = fname.split(".").pop();
    let docs = {
      docName: this.props.ilState.idS3FileData.key.split("/").pop(),
      bucket: this.props.ilState.idS3FileData.bucket,
      contentType: "Id-picture",
      dirName: this.props.ilState.idSubDirName,
      key: this.props.ilState.idS3FileData.key,
      s3Url: this.props.ilState.idS3FileData.location,
    };
    investDocs.push(docs);
    if (this.props.ilState.docS3FileData) {
      docs = {
        docName: this.props.ilState.docS3FileData.key.split("/").pop(),
        bucket: this.props.ilState.docS3FileData.bucket,
        contentType: "Invest-Loan-Doc",
        dirName: this.props.ilState.docSubDirName,
        key: this.props.ilState.docS3FileData.key,
        s3Url: this.props.ilState.docS3FileData.location,
      };
      investDocs.push(docs);
    }
    // await this.setState({
    //   warningFlag: true,
    // });

    let loanInvestData = {
      communityId: this.props.communityId,
      communityCaption: this.props.communityCaption,
      investmentName: this.props.ilState.investmentName,
      investDescription: this.props.ilState.invDescription,
      ilType: this.props.ilState.investLoanType, // equity or loan
      investLoanForm: "cash", // May be service in coming releases.
      investLoanRefId: 0, // Will have serviceId as reference in future.
      // totalShares: this.props.ilState.share.totalShares,
      party: {
        originType: this.props.originType,
        orgName: this.props.orgName,
        orgAddress: this.props.orgAddress,
        name: this.props.ilState.invName,
        email: this.props.ilState.invEmail,
        baandaId: this.props.ilState.invBaandaId,
        idType: this.props.ilState.invIdType, // license, dmvid, passport
        idNumber: this.props.ilState.invIdNumber,
        idIssueDate: this.props.ilState.invIdIssueDate,
        idExpDate: this.props.ilState.invIdExpDate,
        phone: this.props.ilState.invPhone,
        IdNote: this.props.ilState.invNote,
      },
      StripeAccId: this.state.StripeAccId, // get stripeId of the community stripe community.stripe.id in db
      paymentAmount: parseFloat(this.props.ilState.investLoanAmt),
      partyName: this.props.ilState.invName,
      debitDoc: {
        willVestInMonths: this.props.ilState.loanVestInMonths,
        interestRate: this.props.ilState.loanInterestRate,
        interestType: "simple",
        compounded: "",
        payoutInMonth: 0,
      },
      equityDoc: {
        onDate: moment(),
        shares: this.props.ilState.numberOfShares,
        willVestInMonths: this.props.ilState.investVestInMonths,
        pricePerShare:
          parseFloat(this.props.ilState.investLoanAmt) /
          parseFloat(this.props.ilState.numberOfShares),
      },
      checkData: {
        checkNo: this.state.checkNo,
        checkDate: this.state.checkDate,
        paidTo: this.state.checkPayTo,
        checkBankName: this.state.checkBankName,
        accountNo: this.state.accountNo,
        routingNo: this.state.routingNo,
      },
      investDocs,
      paymentFor: "loanInvest",
      sendEmailFlag: this.state.sendEmailFlag,
      paymentType: this.state.paymentType,
      updated_by_baandaId: this.props.auth.user.baandaId,
      clientProgram: "payment",
      clientFunction: "packageInvLoanData",
    };

    // console.log("loanInvestData:", loanInvestData);

    await this.setState({
      loanInvestData,
    });
  };

  handleCCPay = async () => {
    await this.packageInvLoanData();
    await this.setState({
      payByCCFlag: true,
      showPanelFlag: false,
      warningFlag: false,
    });
  };

  handleRecord = async () => {
    await this.packageInvLoanData();
    try {
      let url = baandaServer + receiveLoanInvestPay;
      let retpay = await axios.post(url, this.state.loanInvestData);
      // console.log("retpay:", retpay);
      if (retpay.data.status === "success") {
        await this.setState({
          doneFlag: true,
          processErrorFlag: false,
          warningFlag: false,
          errorMsg: "",
          processStatus: "success",
          showPanelFlag: false,
          buttonPanelFlag: true,
        });
      } else {
        await this.setState({
          doneFlag: false,
          processErrorFlag: true,
          warningFlag: false,
          errorMsg: retpay.data.Msg,
          processStatus: "error",
          buttonPanelFlag: false,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  handleResult = async () => {
    this.handleExit(this.state.processStatus);
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);

    let showPanelText;
    if (this.props.ilState.investLoanType === "equity") {
      showPanelText = (
        <div className="show-inv-pay-text">
          <p align="justify">
            This investment, named <b>{this.props.ilState.investmentName}</b>,
            records an investment made by <b>{this.props.ilState.invName}</b> in
            the community <b>{this.props.communityName}</b>, to the amount of ${" "}
            <b>{this.props.ilState.investLoanAmt}</b> for{" "}
            <b>{this.props.ilState.numberOfShares}</b> shares on{" "}
            <b>{this.props.ilState.recordDate.format("L")}</b>.
          </p>
        </div>
      );
    } else {
      showPanelText = (
        <div className="show-inv-pay-text">
          <p align="justify">
            This loan, named <b>{this.props.ilState.investentName}</b>, records
            the loan extended by <b>{this.props.ilState.invName}</b> to the
            community <b>{this.props.communityName}</b> to the amount of $
            {this.props.ilState.investLoanAmt} at{" "}
            {this.props.ilState.loanInterestRate}%, that will get vested in{" "}
            {this.props.ilState.vestInMonths} months.
          </p>
        </div>
      );
    }

    let docPanel;
    if (
      this.props.ilState.docS3FileData &&
      this.props.ilState.docS3FileData.key !== ""
    ) {
      docPanel = (
        <div className="show-inv-pay-text">
          <p align="justify">
            The terms and conditions are detailed in the attached document (
            <b>{this.props.ilState.docS3FileData.key.split("/").pop()})</b>.
          </p>
        </div>
      );
    } else {
      docPanel = (
        <div className="show-inv-pay-text">
          <p align="justify">
            No separate terms and conditions has been attached, or has been
            uploaded.
          </p>
        </div>
      );
    }

    let notePanel = (
      <div className="show-inv-pay-text">
        <p align="justify">
          <b>Transaction Note</b>&nbsp;
          {this.props.ilState.invNote === ""
            ? "No notes"
            : this.props.ilState.invNote}
        </p>
      </div>
    );

    let checkboxPanel;
    if (this.state.paymentType !== "creditcard") {
      checkboxPanel = (
        <div className="row">
          <div className="col text-center ">
            Confirm receiving cash/check and the{" "}
            {this.props.ilState.investLoanType === "equity"
              ? "investment"
              : "lending"}
            &nbsp;statement&nbsp;&nbsp;
            <Checkbox
              checked={this.state.confirmInvLoan}
              onChange={this.handleConfirmReceivedPay}
            />{" "}
            &nbsp;
          </div>
        </div>
      );
    }

    let sendEmailPanel;
    sendEmailPanel = (
      <div className="row">
        <div className="col text-center ">
          Check to receive an email receipt for the{" "}
          {this.props.ilState.investLoanType === "equity"
            ? "investment"
            : "lending"}
          &nbsp;statement&nbsp;&nbsp;
          <Checkbox
            checked={this.state.sendEmailFlag}
            onChange={this.handleEmailConfirm}
          />{" "}
          &nbsp;
        </div>
      </div>
    );

    let payTypePanel = (
      <div>
        <div className="row">
          <div className="col text-center show-inv-pay-text">
            Please select the payment type for $
            {this.props.ilState.investLoanAmt}
          </div>
        </div>
        <div className="row">
          <div className="col text-center show-inv-pay-text">
            <div className="row">
              <div className="col text-center radio-fonts">
                <strong>Payment Process: &nbsp;&nbsp;</strong>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input-li"
                      type="radio"
                      value="cash"
                      checked={this.state.paymentType === "cash"}
                      onChange={this.handlePaymentType}
                    />{" "}
                    Cash
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="check"
                      checked={this.state.paymentType === "check"}
                      onChange={this.handlePaymentType}
                    />{" "}
                    Check
                  </label>
                </div>
                {this.state.isStripeReady ? (
                  <div className="form-check form-check-inline">
                    <label className="form-check-label">
                      <input
                        className="form-check-input"
                        type="radio"
                        value="creditcard"
                        checked={this.state.paymentType === "creditcard"}
                        onChange={this.handlePaymentType}
                      />{" "}
                      Credit Card
                    </label>
                  </div>
                ) : (
                  "Setup CC for CC-pay"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    let checkPanel;
    if (this.state.paymentType === "check") {
      checkPanel = (
        <div className="check-panel-place">
          <div className="row">
            <div className="col-3 text-right check-text-label">Pay to:</div>
            <div className="col-9 text-left check-text-format">
              <input
                name="checkPayTo"
                type="text"
                value={this.state.checkPayTo}
                onChange={this.onChange}
                size="48"
                maxLength="100"
                className="check-text"
                placeholder="Enter recipiant name as on check"
              />
            </div>
          </div>
          <div className="row">
            <div className="col text-center check-text-label">
              Check amount should be ${" "}
              {this.commaFormattedCurrency(
                parseFloat(this.props.ilState.investLoanAmt)
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right check-text-label">Check Date:</div>
            <div className="col-9 text-left check-text-format">
              <DatePicker
                selected={this.state.checkDate}
                onChange={this.handleCheckDate}
                // onSelect={this.handleCheckDate}
                name="checkDate"
                dateFormat="MM/DD/YYYY"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right check-text-label">Bank Name:</div>
            <div className="col-9 text-left check-text-format">
              <input
                name="checkBankName"
                type="text"
                value={this.state.checkBankName}
                onChange={this.onChange}
                size="48"
                maxLength="100"
                className="check-text"
                placeholder="Enter the bank name"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right check-text-label">Check No:</div>
            <div className="col-9 text-left check-text-format">
              <input
                name="checkNo"
                type="text"
                value={this.state.checkNo}
                onChange={this.onChange}
                size="48"
                maxLength="100"
                className="check-text"
                placeholder="Enter the bank name"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right check-text-label">AC No:</div>
            <div className="col-9 text-left check-text-format">
              <input
                name="accountNo"
                type="text"
                value={this.state.accountNo}
                onChange={this.onChange}
                size="48"
                maxLength="100"
                className="check-text"
                placeholder="Enter acc. noumber"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3 text-right check-text-label">Routing No:</div>
            <div className="col-9 text-left check-text-format">
              <input
                name="routingNo"
                type="text"
                value={this.state.routingNo}
                onChange={this.onChange}
                size="48"
                maxLength="100"
                className="check-text"
                placeholder="Enter the routing number"
              />
            </div>
          </div>
        </div>
      );
    }

    let buttonPanel;
    if ( this.state.buttonPanelFlag ) {
      buttonPanel = (
        <div>
          <div className="row">
            <div className="col-7">&nbsp;</div>
            <div className="col-5 text-center">
              <button
                onClick={this.handleExit}
                className="btn-ilpay"
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Return
              </button>
              {this.state.paymentType === "creditcard" ? (
                <button
                  onClick={this.handleCCPay}
                  className="btn-cc-pay"
                  style={{ cursor: this.state.disabled ? "default" : "pointer" }}
                >
                  CC-Pay
                </button>
              ) : null}
              {this.state.paymentType !== "creditcard" &&
              this.state.confirmInvLoan ? (
                <button
                  onClick={this.handleRecord}
                  className="btn-cc-pay"
                  style={{ cursor: this.state.disabled ? "default" : "pointer" }}
                >
                  Record
                </button>
              ) : null}
            </div>
          </div>
        </div>
      );
    }


    let showpanelFull;
    if (this.state.showPanelFlag) {
      showpanelFull = (
        <div>
          <div className="text-center show-inv-loan-header">
            {this.props.ilState.investLoanType === "equity"
              ? "Investment Recording & Payment"
              : "Loan Recording & Payment"}
          </div>
          <div className="row">
            <div className="col-6">
              {showPanelText}
              {docPanel}
            </div>
            <div className="col-6">
              <div className="text-center img-place">
                <img
                  src={this.props.ilState.idS3FileData.location}
                  alt="pic"
                  className="img-licp-view"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">{notePanel}</div>
          </div>
          {sendEmailPanel}
          {payTypePanel}
          {checkPanel}
          {checkboxPanel}
          {buttonPanel}
        </div>
      );
    }

    let ccPanel;
    if (this.state.payByCCFlag) {
      ccPanel = (
        <ReceiveMoneyViaCC
          inputData={this.state.loanInvestData}
          deviceSize={this.state.deviceSize}
          handleExit={this.handleExit}
        />
      );
    }

    let warningPanel;
    if (this.state.warningFlag) {
      warningPanel = (
        <div className="warn-box text-center">
          <p align="justify">
            <b>Warning:</b>&nbsp;No supporting document has been attached. To
            avoid future misunderstandings, it is recommended a document is
            attached. Even a scanned pdf of signed, by all parties, handwritten
            agreement is good.{" "}
          </p>
        </div>
      );
    }

    let errorPanel;
    if (this.state.processErrorFlag) {
      errorPanel = (
        <div className="warn-box">
          <b>Error</b>&nbsp;in the transaction.&nbsp;{this.state.errorMsg}
          <div className="text-center">
            <button
              className="btn-cc-pay"
              onClick={this.handleResult}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              OK
            </button>
          </div>
        </div>
      );
    }

    let doneMsgPanel;
    if (this.state.doneFlag) {
      doneMsgPanel = (
        <div className="paydone-box">
          Payment received and transaction recorded. The record will be
          available in the reports section (Click Activities then Reports).{" "}
          {this.state.sendEmailFlag
            ? "An email of the transaction has been sent."
            : null}
          <div className="text-center">
            <button
              className="btn-cc-pay"
              onClick={this.handleResult}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              OK
            </button>
          </div>
        </div>
      );
    }

    let outputPanel;
    if (this.state.payByCCFlag) {
      outputPanel = <div>{ccPanel}</div>;
    } else {
      outputPanel = (
        <div className="pay-box">
          {showpanelFull}
          {errorPanel}
          {doneMsgPanel}
        </div>
      );
    }

    return (
      <div>
        {warningPanel}
        {outputPanel}
      </div>
    );
  }
}

Payment.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// const mapDispatchToProps = (dispatch) => ({
//   hideModal: () => dispatch(hideModal()),
//   showModal: (modalProps, modalType) => {
//     dispatch(showModal({ modalProps, modalType }));
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Payment));
export default connect(mapStateToProps, null)(withRouter(Payment));
