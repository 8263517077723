import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import ModalContainer from "../../../../../../../modal/components/ModalContainer";
import {
  showModal,
  hideModal,
} from "../../../../../../../actions/modalActions";
import "../../../../../../../modal/css/localModal.css";
import "../../../../../../../modal/css/template.css";

import "./ShowPayableInvoice.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getBaandaServicePrice = "/routes/architecture/getBaandaServicePrice?";
const registerPayablePayments = "/routes/finance/registerPayablePayments";

const transactionFeeServiceName =
  process.env.REACT_APP_FINANCIAL_TRANSACTION_FEE;

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1177000000";

class ShowPayableInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      paidOnDate: new Date(),
      nextPayDate: new Date(),
      paidAmount: 0,
      note: "",

      paymentMethod: "cash",

      payConfirm: false,
      showUpdateLineFlag: true,
    };
  }

  componentDidMount = async () => {};

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  returnToList = () => {
    this.props.returnToCaller();
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handlePayDate = async (date) => {
    // console.log("1 date:", date);
    let x = date.getTime();
    // console.log("x:", x);
    let npd = new Date(x);
    npd.setDate(npd.getDate() + 1);
    this.setState({
      paidOnDate: date,
      nextPayDate: npd,
    });
  };

  handlenextByDate = async (date) => {
    // console.log("1 date:", date);
    this.setState({
      nextPayDate: date,
    });
  };

  onChangeCurrency = async (e) => {
    // console.log("[e.target.name]:", [e.target.name]);
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      this.setState({
        [e.target.name]: val,
        errMsg: "",
      });
    } else {
      this.setState({
        [e.target.name]: value,
        errMsg: "",
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleConfirmToSubmit = async () => {
    this.setState((prevstate) => ({
      payConfirm: !prevstate.payConfirm,
    }));
  };

  getBaandaServiceFeePercent = async () => {
    // let feePercent = 0;
    let status = "success";
    let errMsg = "";
    let errFlag = false;
    let msg = null;
    let param = "serviceName=" + transactionFeeServiceName;
    let url = baandaServer + getBaandaServicePrice + param;
    try {
      let prret = await axios.get(url);
      // console.log("prret.data:", prret.data);
      if (prret.data.status === "success") {
        msg = prret.data.Msg;
        errMsg = "";
        errFlag = false;
      } else {
        errMsg = prret.data.Msg;
        errFlag = true;
      }
    } catch (err) {
      errMsg = err.message;
      errFlag = true;
    }

    await this.setState({
      errMsg,
      errFlag,
    });

    return { status, Msg: msg };
  };

  packDataForDB = async () => {
    let leftToPayAmount =
      this.props.theInvoice.financeBreakdown.totalInvoiceAmount -
      this.props.theInvoice.financeBreakdown.amountPaid -
      parseFloat(this.state.paidAmount);

    let paySchedulePolicy = null;
    let paidUpFlag = true;
    if (leftToPayAmount > 0) {
      paySchedulePolicy = {
        installmentType: "",
        nextSchedulePayday: this.state.nextPayDate,
        nextPayAmount: parseFloat(leftToPayAmount), // This will be the total amount
        lastReminderSentDate: null,
        numberOfInstallment: 0,
        numberOfInstallmentPaid: 0,
        nextPayBy: this.state.nextPayDate,
        invoiceTerms: "",
      };
      paidUpFlag = false;
    } else {
      paySchedulePolicy = {
        installmentType: "",
        nextSchedulePayday: null,
        nextPayAmount: 0,
        lastReminderSentDate: null,
        numberOfInstallment: 0,
        numberOfInstallmentPaid: 0,
        nextPayBy: null,
        invoiceTerms: "",
      };
    }

    let financeBreakdown = { ...this.props.theInvoice.financeBreakdown };
    financeBreakdown.lastPaymentAmount = parseFloat(this.state.paidAmount);
    financeBreakdown.amountPaid =
      this.props.theInvoice.financeBreakdown.amountPaid +
      parseFloat(this.state.paidAmount);
    financeBreakdown.lastPaymentDate = this.state.paidOnDate;

    let sfret = await this.getBaandaServiceFeePercent();

    let coopStoreId = 0;
    let displayStoreName = "";
    if (this.props.store) {
      coopStoreId = this.props.store.coopStoreId;
      displayStoreName = this.props.store.displayStoreName;
    }

    let input = {
      invoiceId: this.props.theInvoice.invoiceId,
      paySchedulePolicy,
      financeBreakdown,
      paidAmount: parseFloat(this.state.paidAmount),
      paidUpFlag,
      sfretObj: sfret.Msg,
      finMMDD: this.props.community.finMMDD,
      finYYYY: this.props.community.finYYYY,
      communityId: this.props.community.communityId,
      commName: this.props.community.commName,
      coopStoreId,
      displayStoreName,
      invoiceNote: this.state.note,
      paymentMethod: this.state.paymentMethod,

      baandaServiceFeePercent: sfret.Msg.creditsCharged,
      baandaServiceFeeMinimum: sfret.Msg.minimumAmt,
      BaandaServiceFeeType: sfret.Msg.chargeType,
      baandaServiceDescription: sfret.Msg.shortDescription,

      invoiceOfId: this.props.theInvoice.invoiceOfId,
      customerName: this.props.theInvoice.customerName,
      customerEmail: this.props.theInvoice.customerEmail,
      customerCell: this.props.theInvoice.customerCell,
      updated_by_bid: this.props.auth.user.baandaId,
      theInvoice: this.props.theInvoice,
    };

    return input;
  };

  handlePayblePayment = async () => {
    let inp = await this.packDataForDB();
    // console.log("inp:", inp);
    let url = baandaServer + registerPayablePayments;
    try {
      let payret = await axios.post(url, inp);
      // console.log("payret.data:", payret.data);
      if (payret.data.status === "success") {
        this.setState({
          errMsg: "Successfully registered",
          errFlag: false,
          paidOnDate: new Date(),
          nextPayDate: new Date(),
          paidAmount: 0,
          note: "",
          paymentMethod: "cash",
          payConfirm: false,
          showUpdateLineFlag: false,
        });
      } else {
        this.setState({
          errFlag: true,
          errMsg: payret.data.Msg,
        });
      }
    } catch (err) {
      this.setState({
        errFlag: true,
        errMsg: err.message,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ShowPayableInvoice...');

    let helpModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let te = this.props.theInvoice;
    console.log("te:", te);
    console.log("te.itemDetails:", te.itemDetails);

    let exitRegisterInvoice = (
      <button
        className="btn_back_main"
        type="button"
        onClick={this.returnToList}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let paySubmitBtn = (
      <button
        className="btn_reg_70"
        type="button"
        onClick={this.handlePayblePayment}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Update
      </button>
    );

    let headerPanel = (
      <div className="row in-pbl-header-pos">
        <div className="col-2 text-center inv-pbl-header-invid">
          Invoice Id: {te.invoiceId}
        </div>
        <div className="col-8 text-center inv-pbl-header">
          Invoice Payable &nbsp;{helpModalButton}&nbsp;{exitRegisterInvoice}
        </div>
        <div className="col-2 text-center ">&nbsp;</div>
      </div>
    );

    let bool = true;
    let invLineBody;
    if (te.itemDetails) {
      invLineBody = (
        <div className="">
          <div className="row">
            <div className="col-6 text-left pbl-line-list-header">
              Item Description
            </div>
            <div className="col-4 text-left invoice-line-list-header">
              Price | Qty | Unit
            </div>
            <div className="col-2 text-right inv-pay-amount-head">
              Amount ${" "}
            </div>
          </div>
          <div className="invoice-pay-lineitem-box">
            {te.itemDetails.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-pay-line-dr" : "row row-pay-line-lt"
                    }
                  >
                    <div className="col-6 text-left invoice-line-item">
                      {" "}
                      {obj.description}
                    </div>
                    <div className="col-4 text-left invoice-line-item">
                      ${this.commaFormattedCurrency(obj.price)} |{" "}
                      {this.commaFormattedCurrency(obj.quantity)} | {obj.unit}
                    </div>
                    <div className="col-2 text-right invoice-line-item">
                      {this.commaFormattedCurrency(obj.price * obj.quantity)}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let vendorPanel = (
      <div>
        <div className="row">
          <div className="col-3 text-right inv-pbl-vend-lbl">Vendor Name</div>
          <div className="col-9 text-left inv-pbl-vend-txt">
            {te.customerName}
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right inv-pbl-vend-lbl">Email</div>
          <div className="col-9 text-left inv-pbl-vend-txt">
            {te.customerEmail}
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right inv-pbl-vend-lbl">Cell</div>
          <div className="col-9 text-left inv-pbl-vend-txt">
            {te.customerCell}
          </div>
        </div>
        <div className="row">
          <div className="col-3 text-right inv-pbl-vend-lbl">Vendor Id</div>
          <div className="col-9 text-left inv-pbl-vend-txt">
            {te.vendorReference[0] && te.vendorReference[0].referenceId !== ""
              ? te.vendorReference[0].referenceId
              : "N/A"}
          </div>
        </div>
      </div>
    );

    let toPayPanel = (
      <div>
        <div className="row pbl-topay-row-pos">
          <div className="col-2 text-right inv-pbl-vend-lbl">Total Amount</div>
          <div className="col-4 text-left inv-pbl-vend-txt">
            ${" "}
            {this.commaFormattedCurrency(
              te.financeBreakdown.totalInvoiceAmount
            )}
          </div>
          <div className="col-2 text-right inv-pbl-vend-lbl">Paid</div>
          <div className="col-4 text-left inv-pbl-vend-txt">
            $ {this.commaFormattedCurrency(te.financeBreakdown.amountPaid)}
          </div>
        </div>

        <div className="row">
          <div className="col-2 text-right inv-pbl-vend-lbl">To Pay</div>
          <div className="col-4 text-left inv-pbl-vend-txt">
            ${" "}
            {this.commaFormattedCurrency(
              te.financeBreakdown.totalInvoiceAmount -
                te.financeBreakdown.amountPaid
            )}
          </div>
          <div className="col-2 text-right inv-pbl-vend-lbl">Pay By</div>
          <div className="col-4 text-left inv-pbl-vend-txt">
            {te.paySchedulePolicy.nextPayBy.substring(0, 10)}
          </div>
        </div>
      </div>
    );

    let payDetail = (
      <div className="pbl-pay-panel-pos">
        <div className="row">
          <div className="col-2 text-right inv-pbl-vend-lbl">Pay Amt</div>
          <div className="col-4 text-left inv-pbl-vend-txt">
            <input
              name="paidAmount"
              type="number"
              value={this.state.paidAmount}
              onChange={this.onChangeCurrency}
              className="pay-invoice-numbers"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
          </div>
          <div className="col-2 text-right inv-pbl-vend-lbl">Left</div>
          <div className="col-4 text-left inv-pbl-vend-txt">
            {this.commaFormattedCurrency(
              te.financeBreakdown.totalInvoiceAmount -
                te.financeBreakdown.amountPaid -
                this.state.paidAmount
            )}
          </div>
        </div>

        <div className="row pbl-pay-date-pos">
          <div className="col-6">
            <div className="row">
              <div className="col-4 text-right inv-pbl-vend-lbl">Paid On</div>
              <div className="col-8 text-left inv-pbl-vend-txt">
                <DatePicker
                  selected={this.state.paidOnDate}
                  onSelect={this.handlePayDate}
                  onChange={this.handlePayDate}
                  minDate={new Date()}
                  name="paidOnDate"
                  dateFormat="MM/dd/yyyy"
                />
              </div>
            </div>
          </div>
          {this.state.paidAmount <
          te.financeBreakdown.totalInvoiceAmount -
            te.financeBreakdown.amountPaid ? (
            <div className="col-6">
              <div className="row">
                <div className="col-4 text-right inv-pbl-vend-lbl">Rest By</div>
                <div className="col-8 text-left inv-pbl-vend-txt">
                  <DatePicker
                    selected={this.state.nextPayDate}
                    onSelect={this.handlenextByDate}
                    onChange={this.handlenextByDate}
                    minDate={new Date()}
                    name="paidOnDate"
                    dateFormat="MM/dd/yyyy"
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="col-6">&nbsp;</div>
          )}
        </div>

        <div className="row pbl-note-pos">
          <div className="col-2 text-right inv-pbl-vend-lbl">Note</div>
          <div className="col-10 text-left ">
            <textarea
              name="note"
              maxLength="200"
              placeholder="Payment note (optional)."
              rows="2"
              wrap="hard"
              spellCheck="true"
              className="pbl-pay-note"
              onChange={this.onChange}
              value={this.state.note}
              required
            />
          </div>
        </div>
      </div>
    );

    let finalPanel;
    if (this.state.paidAmount > 0) {
      finalPanel = (
        <div className="row inv-final-row-pos">
          <div className="col-4 text-right inv-final-lbl">
            <Checkbox
              checked={this.state.payConfirm}
              onChange={this.handleConfirmToSubmit}
            />{" "}
            Confirm to submit (final)
          </div>
          {this.state.showUpdateLineFlag ? (
            <div className="col-4 text-left inv-final-lbl">
              {this.state.payConfirm ? paySubmitBtn : null}
            </div>
          ) : (
            <div className="col-4 text-left inv-final-lbl">
            Exit to continue with other payables.
          </div>
          )}

          <div className="col-4 text-center inv-final-lbl">&nbsp;</div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        {headerPanel}
        {vendorPanel}
        {invLineBody}
        {toPayPanel}
        <hr />
        {payDetail}
        {finalPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center pbl-payment-msg-err"
              : "text-center pbl-payment-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ShowPayableInvoice.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ShowPayableInvoice)
);
