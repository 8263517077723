import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

import "./Assign.css";

import { sortArrayOfDates } from "../../../../../../utils/sortArrayOfDates";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getAvailability = "/routes/interact/getAvailability?";
const saveUpdtEvent = "/routes/interact/saveUpdtEvent";
const getAllServersCalAllocations =
  "/routes/interact/getAllServersCalAllocations?";
const getAnEvent = "/routes/inetract/getAnEvent?";

const yourtimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1111211500";

class Assign extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      allocationList: [],
      allocationListStorage: [],

      nothingThereMsg: "",
      allocationErrFlag: false,

      errMsg: "",
      errFlag: false,
      thisAvailability: null,

      showAvailabilityPop: false,
      aMemAvailabilityData: null,
      showMoreOfAvailabiliy: false,

      showFilter: false,
      filterDateType: "startdate",

      begindate: new Date(),
      enddate: new Date(),

      beginDateString: "",
      endDateString: "",
      sortType: "asc",

      thisTaskServer: [],
      thisTaskComment: "",

      showAvailabilityReq: false,
      toReqForSlots: [],
      availerrflag: false,

      notifyViaBmail: true,
      notifyViaSMS: false,

      theEvent: null,
      publisherTimezone: "",

      onStartServerCalAllocation: [],
      availabilityNoteBmail:
        "Please specify your availability (ref: personal calendar) for the event.",
      availabilityNoteSMS:
        "Please specify your availability (ref: personal calendar) for the event.",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getEventFromDB();

    let begindate = new Date();
    // console.log("1. begindate:", begindate);
    let enddate = new Date();
    enddate.setDate(begindate.getDate() + 1);
    let publisherTimezone = "";
    if (this.state.theEvent) {
      await this.setAllocationList();
      await this.getAllServerAllocations();
      await this.associateAllocationList();
      if (this.state.theEvent.services) {
        publisherTimezone = this.state.theEvent.services.timezone;
      }
    }

    await this.setState({
      deviceSize,
      enddate,
      publisherTimezone,
    });
  };

  getEventFromDB = async () => {
    let param = "eventId=" + this.props.theEvent.eventId;
    let url = baandaServer + getAnEvent + param;
    try {
      let everet = await axios.get(url);
      // console.log("everet.data:", everet.data);
      if (everet.data.status === "success") {
        this.setState({
          theEvent: everet.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: everet.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  getAllServerAllocations = async () => {
    try {
      let input = {
        serviceTeam: this.state.theEvent.services.serviceTeam,
        eventId: this.props.theEvent.eventId,
      };
      let url = baandaServer + getAllServersCalAllocations;
      // console.log("-------url: ", url, " input:", input);
      let retval = await axios.post(url, input);
      // console.log("-------retval.data:", retval.data);
      if (retval.data.status === "success") {
        await this.setState({
          onStartServerCalAllocation: retval.data.Msg,
        });
      }
    } catch (err) {
      console.log("getAllServerAllocations err:", err.message);
    }
  };

  associateAllocationList = async () => {
    let alloc = [...this.state.allocationList];
    let eventId = this.props.theEvent.eventId;
    let servtemp = [];

    alloc.forEach((obj) => {
      let day = obj.day;
      let timestr = obj.time.timestr;

      this.state.onStartServerCalAllocation.forEach((elm) => {
        elm.allocatedIn.forEach((aka) => {
          // console.log(k, '. aka:', aka, ' eventId:', eventId);
          if (aka.eventId === eventId) {
            if (day === elm.calDateString && aka.startTimeStr === timestr) {
              let srvobj = {
                day,
                startTime: aka.startTime,
                startTimeStr: aka.startTimeStr,
                serverBaandaId: elm.baandaId,
                eventId,
              };
              servtemp.push(srvobj);
            }
          }
        });
      });
    });

    servtemp.forEach((obj) => {
      alloc.forEach((elm) => {
        if (obj.day === elm.day && obj.startTime === elm.time.timeint) {
          this.state.theEvent.services.serviceTeam.forEach((akb) => {
            if (akb.baandaId === obj.serverBaandaId) {
              elm.server.push(akb);
            }
          });
        }
      });
    });

    this.setState({
      allocationList: alloc,
      allocationListStorage: alloc,
    });
  };

  setAllocationList = async () => {
    let daysto = this.state.theEvent.services.selectedDaysString;
    // let daysto = this.state.theEvent.services.scheduledSelectedDays;
    let timesto = this.state.theEvent.services.scheduledSlots;
    let serviceTeam = this.state.theEvent.services.serviceTeam;
    let allOK = true;
    let msg = "";
    if (daysto.length === 0) {
      allOK = false;
      msg = "Days have not been selected. | ";
    }
    if (timesto.length === 0) {
      allOK = false;
      msg = msg + "Time slots have not been selected. | ";
    }
    if (serviceTeam.length === 0 && this.state.groupId === 0) {
      allOK = false;
      msg = msg + "Service team have not been selected. | ";
    }

    if (!allOK) {
      await this.setState({
        nothingThereMsg: msg,
        allocationErrFlag: true,
      });
    }
    // One should not assign someone in the past.
    let yesterday = moment().add(-1, "days");

    // If there has been allocaton of servers before.
    // this.props.services.prePubShifts will have length only if there has been
    let preFlag = false;
    let prePubShifts = [];
    if (
      this.state.theEvent.services &&
      this.state.theEvent.services.prePubShifts &&
      this.state.theEvent.services.prePubShifts.length > 0
    ) {
      preFlag = true;
      prePubShifts = [...this.state.theEvent.services.prePubShifts];
    }

    let allocationList = [];

    if (allOK) {
      daysto.forEach((obj) => {
        // console.log(a, ". obj:", obj);

        timesto.forEach((elm) => {
          // console.log(a, ".", b, ". elm:", elm);
          let daytimeobj = {
            // day: obj.substring(0, 10),
            day: obj,
            time: elm,
            server: [],
            comment: "",
          };
          // console.log("1. daytimeobj:", JSON.parse(JSON.stringify(daytimeobj)));
          // Do not push into allocationList if day is in the past. One cannot assign for the past.
          if (moment(obj).isAfter(yesterday)) {
            if (preFlag) {
              prePubShifts.forEach((emm) => {
                // console.log(a, ".", b, ".", c, ". emm:", emm);
                if (
                  emm.shiftDateString === daytimeobj.day &&
                  emm.startTime === daytimeobj.time.timeint
                ) {
                  if (emm.serviceProviders.length > 0) {
                    // console.log(
                    //   "here here emm.serviceProviders:",
                    //   emm.serviceProviders
                    // );
                    daytimeobj.server = [...emm.serviceProviders];
                    // console.log("%%%% daytimeobj.server:", daytimeobj.server);
                  }
                }
              });
            }
            // console.log("2. daytimeobj:", daytimeobj);
            allocationList.push(daytimeobj);
          }
        });
      });

      let sortedAL = sortArrayOfDates(allocationList, "day", "asc");

      await this.setState({
        allocationList: sortedAL,
        allocationListStorage: sortedAL,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  getTimezoneOffset = (tz, hereDate) => {
    hereDate = new Date(hereDate || Date.now());
    hereDate.setMilliseconds(0); // for nice rounding

    const hereOffsetHrs = (hereDate.getTimezoneOffset() / 60) * -1,
      thereLocaleStr = hereDate.toLocaleString("en-US", { timeZone: tz }),
      thereDate = new Date(thereLocaleStr),
      diffHrs = (thereDate.getTime() - hereDate.getTime()) / 1000 / 60 / 60,
      thereOffsetHrs = hereOffsetHrs + diffHrs;

    // console.log(
    //   tz,
    //   thereDate,
    //   "UTC" + (thereOffsetHrs < 0 ? "" : "+") + thereOffsetHrs
    // );
    return thereOffsetHrs;
  };

  calAdjustHours = () => {
    let pubOffset = this.getTimezoneOffset(
      this.state.publisherTimezone,
      new Date()
    );

    // let yourOffset = this.getTimezoneOffset("America/New_York", new Date());
    let yourOffset = this.getTimezoneOffset(yourtimezone, new Date());
    // console.log("yourtimezone:", yourtimezone, " yourOffset:", yourOffset);
    let adjustHrs = yourOffset - pubOffset;
    // console.log("adjustHrs:", adjustHrs);

    return adjustHrs;
  };

  calLineAdjustment = (elm, hta) => {
    // console.log("calLineAdjustment elm:", elm);
    let stTime = elm.startTime / 12;
    let adhr = stTime + hta;
    let outhr = parseFloat((" " + adhr.toString()).slice(1));
    // console.log("outhr:", outhr);
    if (adhr >= 24) {
      outhr = adhr - 24;
    } else if (adhr < 0) {
      outhr = adhr * -1;
    }

    let outobj = {
      calDateString: elm.calDateString,
      comment: elm.comment,
      duration: elm.duration,
      schTimeZone: elm.schTimezone,
      startTime: outhr * 60,
    };
    // console.log("outobj:", outobj);
    // At this time, we are not changing the date
    return outobj;
  };

  adjustAvailabilitTimes = (aval) => {
    // console.log('aval:', aval);
    // console.log('publisherTimezone:', this.state.publisherTimezone);
    let hrsToAdjust = this.calAdjustHours(); // hta
    // console.log("hrsToAdjust:", hrsToAdjust);
    let adjAvailability = [];
    aval.forEach((obj) => {
      let adjAlloc = [];
      obj.allocation.forEach((elm) => {
        let adjAlObj = this.calLineAdjustment(elm, hrsToAdjust);
        adjAlloc.push(adjAlObj);
      });
      let adjAvailObj = {
        allocation: adjAlloc,
        member: obj.member,
      };
      adjAvailability.push(adjAvailObj);
    });

    // console.log('adjAvailability:', adjAvailability);

    return adjAvailability;
  };

  getAvailability = async (obj) => {
    // console.log("getAvailability obj:", obj);
    let input = {
      communityId: this.props.communityId,
      team: this.props.theEvent.services.serviceTeam,
      // datestr: this.convertMMDDYYYYToYYYYMMDD(obj.day),
      datestr: obj.day, // MM-DD-YYYY
    };

    // console.log("input:", input);
    let url = baandaServer + getAvailability;

    // console.log("url:", url);
    let isOK = true;
    try {
      let retavail = await axios.post(url, input);
      // console.log("retavail.data:", retavail.data);

      if (retavail.data.status === "success") {
        let adjustedAvailability = this.adjustAvailabilitTimes(
          retavail.data.Msg
        );
        this.setState({
          errFlag: false,
          errMsg: "",
          // thisAvailability: retavail.data.Msg,
          thisAvailability: adjustedAvailability,
        });
      } else {
        await this.setState({
          errFlag: true,
          errMsg: retavail.data.Msg,
        });
        isOK = false;
      }
    } catch (err) {
      console.log("err:", err.message);
      isOK = false;
    }
    return isOK;
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  filterPartialList = async () => {
    await this.setState({
      showFilter: true,
    });
  };

  closeFilter = async () => {
    await this.setState({
      showFilter: false,
    });
  };

  handleAssign = async (obj) => {
    // console.log("xxx obj:", obj);
    let geta = await this.getAvailability(obj);
    // console.log("geta:", geta);
    if (geta) {
      this.setState({
        showAvailabilityPop: true,
        selectedDay: obj,
        thisTaskServer: obj.server, // Should be blank when it begins
      });
    }
  };

  closeAssign = async () => {
    this.setState({
      showAvailabilityPop: false,
      showMoreOfAvailabiliy: false,
      thisTaskServer: [],
    });
  };

  handleSaveAssignment = async () => {
    let allocationList = [...this.state.allocationList];
    let allocationListStorage = [...this.state.allocationListStorage];

    allocationList.forEach((obj) => {
      if (
        obj.day === this.state.selectedDay.day &&
        obj.time.timeint === this.state.selectedDay.time.timeint
      ) {
        obj.server = this.state.thisTaskServer;
        obj.comment = this.state.thisTaskComment;
      }
    });

    allocationListStorage.forEach((obj) => {
      if (
        obj.day === this.state.selectedDay.day &&
        obj.time.timeint === this.state.selectedDay.time.timeint
      ) {
        obj.server = this.state.thisTaskServer;
        obj.comment = this.state.thisTaskComment;
      }
    });

    await this.setState({
      allocationList,
      allocationListStorage,
      showAvailabilityPop: false,
      showMoreOfAvailabiliy: false,
      thisTaskServer: [],
      thisTaskComment: "",
    });
  };

  handleShowMore = async (obj) => {
    await this.setState({
      aMemAvailabilityData: obj,
      showMoreOfAvailabiliy: true,
    });
  };

  closeAvailabilityDetail = async () => {
    await this.setState({
      aMemAvailabilityData: null,
      showMoreOfAvailabiliy: false,
    });
  };

  handleAllocate = async (obj) => {
    let thisTaskServer = [...this.state.thisTaskServer];
    let ifExists = false;
    this.state.thisTaskServer.forEach((elm) => {
      if (obj.member.baandaId === elm.baandaId) ifExists = true;
    });
    if (!ifExists) thisTaskServer.push(obj.member);

    await this.setState({
      thisTaskServer,
      thisTaskComment: this.state.thisTaskComment,
    });
  };

  getDurationString = (dur) => {
    let duration = "";
    let dhrs = parseFloat(dur),
      dmin = 0;

    if (dhrs <= 59) {
      duration = dhrs + " mins.";
    } else {
      dmin = dhrs % 60;

      let dh = dhrs - dmin;
      if (dmin === 0) {
        duration = dh / 60 + " hrs.";
      } else {
        duration = dh / 60 + " hrs & " + dmin + " mins";
      }
    }

    return duration;
  };

  getStartTimex = (num) => {
    let sttm = parseFloat(num);
    let startTime = "";
    if (sttm < 60) {
      if (sttm < 10) startTime = "00 : 0" + sttm + "am";
      else startTime = "00 : " + sttm + "am";
    } else {
      let nohrem = sttm % 60; // reminder after divide by 60;
      let hrs = (sttm - nohrem) / 60;
      if (hrs <= 12) {
        if (hrs < 10) {
          if (nohrem < 10) startTime = "0" + hrs + " : 0" + nohrem + " am";
          else startTime = "0" + hrs + " : " + nohrem + " am";
        } else {
          if (nohrem < 10) startTime = hrs + " : 0" + nohrem + " am";
          else startTime = hrs + " : " + nohrem + " am";
        }
      } else {
        hrs = hrs - 12;
        if (hrs < 10) {
          if (nohrem < 10) startTime = "0" + hrs + " : 0" + nohrem + " pm";
          else startTime = "0" + hrs + " : " + nohrem + " pm";
        } else {
          if (nohrem < 10) startTime = hrs + " : 0" + nohrem + " pm";
          else startTime = hrs + " : " + nohrem + " pm";
        }
      }
    }

    return startTime;
  };

  handleFilterDateType = async (e) => {
    await this.setState({
      filterDateType: e.target.value,
    });
  };

  handleSortType = async (e) => {
    await this.setState({
      sortType: e.target.value,
    });
  };

  handleBeginDate = async (date) => {
    await this.setState({
      begindate: date,
      beginDateString: moment(date).format(),
    });
  };

  handleEndDate = async (date) => {
    await this.setState({
      enddate: date,
      // showDailyPopup: false,
      endDateString: moment(date).format(),
    });
  };

  filterTheList = async () => {
    let allocList = [];
    this.state.allocationListStorage.forEach((obj) => {
      if (
        moment(obj.day).isBefore(
          moment(this.state.endDateString.substring(0, 10)).add(1, "days")
        ) &&
        moment(obj.day).isAfter(
          moment(this.state.beginDateString.substring(0, 10)).add(-1, "days")
        )
      ) {
        allocList.push(obj);
      }
    });

    let tosort = [...allocList];
    let sorted = sortArrayOfDates(tosort, "day", this.state.sortType);
    await this.setState({
      allocationList: sorted,
      showFilter: false,
    });
  };

  removeFromThisAvailability = async (obj) => {
    let taskServers = [];
    this.state.thisTaskServer.forEach((elm) => {
      if (elm.baandaId !== obj.baandaId) {
        taskServers.push(elm);
      }
    });

    await this.setState({
      thisTaskServer: taskServers,
    });
  };

  indCalAllocatedInForDb = async () => {
    let allocatedInWip = [];
    let canUpdatePreShift = true;

    // For all allocations, for server, create temp individuual calendar array
    this.state.allocationListStorage.forEach((obj) => {
      if (obj.server.length === 0) canUpdatePreShift = false;

      obj.server.forEach((elm) => {
        let allocatedBy = {
          baandaId: this.props.auth.user.baandaId,
          email: this.props.auth.user.email,
          name: this.props.auth.user.name,
        };
        let ainobj = {
          baandaId: elm.baandaId,
          calDateString: obj.day,
          allocatedBy,
          commName: this.props.communityName,
          communityId: this.props.communityId,
          comment: obj.comment,
          duration: obj.time.duration,
          eventId: this.props.theEvent.eventId,
          eventName: this.props.theEvent.eventName,
          startTime: obj.time.timeint,
          startTimeStr: obj.time.timestr,
        };
        allocatedInWip.push(ainobj);
      });
    });

    // NOT WORKING SDSF ASDFDASF ADSF ADS$%@$#%%$ %$%@#%$@% $@# %

    let calInAlloc = [];
    // for the new individual calendar array, for each ... traverse the
    // existing calendar uploaded on component mount.
    allocatedInWip.forEach((elm) => {
      let dateIdexists = false;

      let tempobjarr = [];
      this.state.onStartServerCalAllocation.forEach((obj) => {
        if (
          elm.calDateString === obj.calDateString &&
          elm.baandaId === obj.baandaId
        ) {
          dateIdexists = true;
          let startTimeExists = false;
          obj.allocatedIn.forEach((oak) => {
            if (oak.startTime === elm.startTime) startTimeExists = true;
          });
          if (!startTimeExists) tempobjarr.push(obj);
        }
      });

      if (dateIdexists) {
        if (tempobjarr.length > 0) {
          tempobjarr.forEach((obj) => {
            // console.log('obj:', obj);
            let objclone = JSON.parse(JSON.stringify(obj));
            // console.log('objclone:', objclone);
            objclone.calendarDate = moment(
              objclone.calDateString,
              "YYYY-MM-DD"
            ).toDate();
            let ocobj = {
              allocatedBy: elm.allocatedBy,
              commName: elm.commName,
              communityId: elm.communityId,
              comment: elm.comment,
              duration: elm.duration,
              eventId: elm.eventId,
              eventName: elm.eventName,
              startTime: elm.startTime,
              startTimeStr: elm.startTimeStr,
            };
            objclone.allocatedIn.push(ocobj);
            calInAlloc.push(objclone);
          });
        }
      }
      if (!dateIdexists) {
        let dobj = {
          baandaId: elm.baandaId,
          calDateString: elm.calDateString,
          calendarDate: moment(elm.calDateString, "YYYY-MM-DD").toDate(),
          allocatedIn: [
            {
              allocatedBy: elm.allocatedBy,
              commName: elm.commName,
              communityId: elm.communityId,
              comment: elm.comment,
              duration: elm.duration,
              eventId: elm.eventId,
              eventName: elm.eventName,
              startTime: elm.startTime,
              startTimeStr: elm.startTimeStr,
            },
          ],
        };
        calInAlloc.push(dobj);
      }
    });

    let newCalToUpdate = [];
    calInAlloc.forEach((obj) => {
      let ifExists = false;
      newCalToUpdate.forEach((elm) => {
        if (
          obj.calDateString === elm.calDateString &&
          obj.baandaId === elm.baandaId
        ) {
          ifExists = true;
          obj.allocatedIn.forEach((aok) => {
            elm.allocatedIn.push(aok);
          });
        }
      });
      if (!ifExists) {
        newCalToUpdate.push(obj);
      }
    });

    return { newCalToUpdate, canUpdatePreShift };
  };

  packDataForDB = async () => {
    let seq = 1;
    let slotsAllocated = 0;
    let prePubShifts = [];
    // let allocatedIn = [];

    let indCalAlloc = await this.indCalAllocatedInForDb();

    if (indCalAlloc.canUpdatePreShift) {
      this.state.allocationListStorage.forEach((obj) => {
        let serviceProviders = [];
        // console.log("obj:", obj);
        if (obj.server.length > 0) {
          // console.log("## obj.server:", obj.server);
          obj.server.forEach((elm) => {
            // console.log("### elm:", elm);
            serviceProviders.push(elm);
          });
          slotsAllocated++;
        }
        let alloc = {
          seqNo: seq,
          shiftDate: moment(obj.day),
          shiftDateString: obj.day,
          startTime: obj.time.timeint,
          startTimeStr: obj.time.timestr,
          duration: obj.time.duration,
          comment: obj.comment,
          serviceProviders,
        };
        prePubShifts.push(alloc);
        seq++;
      });
    }

    let requestSlots = this.state.toReqForSlots;
    if (slotsAllocated === this.state.allocationListStorage.length) {
      requestSlots = [];
    }
    let availabilityNoteBmail = this.state.availabilityNoteBmail;
    if (availabilityNoteBmail === "")
      availabilityNoteBmail =
        "Please specify your availability (ref: personal calendar) for the event.";
    let availabilityNoteSMS = this.state.availabilityNoteSMS;
    if (availabilityNoteSMS === "")
      availabilityNoteSMS =
        "Please specify your availability (ref: personal calendar) for the event.";

    let input = {
      eventId: this.props.theEvent.eventId,
      eventName: this.props.theEvent.eventName,
      communityId: this.props.communityId,
      commName: this.props.communityName,
      canUpdatePreShift: indCalAlloc.canUpdatePreShift,
      prePubShifts,
      eventHostName: this.props.theEvent.hostName,
      eventHostEmail: this.props.theEvent.hostEmail,
      eventHostBaandaId: this.props.theEvent.hostBaandaId,
      notifyViaBmail: this.state.notifyViaBmail,
      notifyViaSMS: this.state.notifyViaSMS,
      availabilityNoteBmail: availabilityNoteBmail,
      availabilityNoteSMS: availabilityNoteBmail,
      serviceTeam: this.props.theEvent.services.serviceTeam,
      eventCreatorNote: this.state.thisTaskComment,
      requestSlots: requestSlots,
      timezone: this.props.theEvent.services.timezone,
      allocatedIn: indCalAlloc.newCalToUpdate, // Check
      action: "updateassign",
      module: "serviceserverallocation",
      clientProgram: "Assign.js",
      clientFunction: "saveUpdateEvent",
    };
    return input;
  };

  saveUpdateEvent = async () => {
    let url = baandaServer + saveUpdtEvent;
    let inp = await this.packDataForDB();
    // console.log("1. url:", url, " inp:", inp);
    try {
      let resp = await axios.post(url, inp);
      // console.log("rest:", resp);
      if (resp.data.status === "success") {
        this.setState({
          errMsg: "Allocations saved but not published.",
          errFlag: false,
          eventId: resp.data.Msg.eventId,
          theEvent: resp.data.Msg.theEvent,
          showAvailabilityReq: false,
        });
        this.props.returnToCaller(this.state.theEvent);
      } else {
        this.setState({
          errMsg: resp.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  sendTheRequest = async () => {
    if (
      this.props.theEvent &&
      this.props.theEvent.services &&
      this.props.theEvent.services.serviceTeam.length > 0
    ) {
      let url = baandaServer + saveUpdtEvent;
      let inp = await this.requestPackageDb();
      // console.log("2. url:", url, " inp:", inp);
      try {
        let resp = await axios.post(url, inp);
        // console.log("rest:", resp);
        if (resp.data.status === "success") {
          this.setState({
            errMsg: "Availability request sent.",
            errFlag: false,
            eventId: resp.data.Msg.eventId,
            theEvent: resp.data.Msg.theEvent,
            showAvailabilityReq: false,
          });
          await this.closeAvailabilityRequest();
          this.props.returnToCaller(this.state.theEvent);
        } else {
          this.setState({
            errMsg: resp.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        console.log("sendTheRequest err:", err.message);
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errMsg: "Must define a service team to request availability.",
        errFlag: true,
      });
    }
  };

  requestPackageDb = async () => {
    let input = await this.packDataForDB();
    input.action = "requestavailability";
    return input;
  };

  prepAvailabilityRequest = async () => {
    await this.calcDaysAndTimeRange();
    if (this.state.toReqForSlots.length > 0) {
      this.setState({
        showAvailabilityReq: true,
        availabilityReqMsg: "Enter comments to provide guidence.",
        availerrflag: false,
        errFlag: false,
        errMsg: "",
      });
    } else {
      this.setState({
        showAvailabilityReq: false,
        availabilityReqMsg: "",
        availerrflag: false,
        errMsg: "Please setup & Save days, time, and/or team for assignment.",
        errFlag: true,
      });
    }
  };

  calcDaysAndTimeRange = async () => {
    if (this.state.allocationList.length === 0) {
      this.setState({
        availabilityReqMsg:
          "Please form team, set days and time to request availability",
        availerrflag: true,
      });
    } else {
      let pro = [];
      this.state.allocationList.forEach((obj) => {
        let todaysobj = {
          day: obj.day,
          begintime: parseFloat(obj.time.timeint),
          begintimeStr: obj.time.timestr,
          duration: obj.time.duration,
        };
        pro.push(todaysobj);
      });
      // console.log("pro:", pro);
      let requestSlotsArr = [];

      pro.forEach((fp) => {
        if (requestSlotsArr.length > 0) {
          let itExists = false;
          requestSlotsArr.forEach((sp) => {
            if (sp.day === fp.day) {
              let timesobj = {
                begintime: fp.begintime,
                begintimeStr: fp.begintimeStr,
                duration: fp.duration,
              };
              sp.times.push(timesobj);
              itExists = true;
            }
          });
          if (!itExists) {
            let tempobj = {
              day: fp.day,
              times: [
                {
                  begintime: fp.begintime,
                  begintimeStr: fp.begintimeStr,
                  duration: fp.duration,
                },
              ],
            };
            requestSlotsArr.push(tempobj);
          }
        } else {
          let tempobj = {
            day: fp.day,
            times: [
              {
                begintime: fp.begintime,
                begintimeStr: fp.begintimeStr,
                duration: fp.duration,
              },
            ],
          };
          requestSlotsArr.push(tempobj);
        }
      });

      await this.setState({
        toReqForSlots: requestSlotsArr,
      });
    }
  };

  trnsformIntToTimeOfDay = async (tint) => {
    let hr = "";
    let mn = "";
    let hrint = 0;
    let ampm = "am";
    if (parseFloat(tint) < 60) {
      hr = "00";
      if (parseFloat(tint) < 10) mn = "0" + tint;
      else mn = tint;
    } else {
      let rem = tint % 60;
      hrint = (parseFloat(tint) - rem) / 60;
      if (hrint < 12) {
        if (hrint < 10) hr = "0" + hrint;
        else hr = hrint;
        ampm = "am";
      } else {
        hrint = parseFloat(tint) - 12;
        if (hrint < 10) hr = "0" + hrint;
        else hr = hrint;
        ampm = "pm";
      }
      if (rem < 10) mn = "0" + rem;
      else mn = rem;
    }

    let timeStr = hr + " : " + mn + " " + ampm;

    return timeStr;
  };

  closeAvailabilityRequest = async () => {
    await this.setState({
      showAvailabilityReq: false,
    });
  };

  handleBmailNotify = async () => {
    await this.setState((prevstate) => ({
      notifyViaBmail: !prevstate.notifyViaBmail,
    }));
  };

  handleSMSNotify = async () => {
    await this.setState((prevstate) => ({
      notifyViaSMS: !prevstate.notifyViaSMS,
    }));
  };

  getDayOfWeek = (dtstr) => {
    let day = new Date(dtstr).getDay();

    let weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return weekdays[day];
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("Assign...");

    let infoModalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let buttonPanel;
    buttonPanel = (
      <div className="assign-button-pos">
        <button
          className="btn_reg_75"
          type="button"
          onClick={this.prepAvailabilityRequest}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Request
        </button>
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.saveUpdateEvent}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>
        {infoModalButton}
        <div
          className={
            this.state.errFlag
              ? "text-center assign-save-msg-err"
              : "text-center assign-save-msg"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    let filterPanel;
    if (this.state.showFilter) {
      filterPanel = (
        <div className="show-filter-pop-box">
          <div className="text-center filter-heading">
            Filter a part-of-the-list{" "}
          </div>
          <div className="row row-team-trait-xx">
            <div className="col">
              <strong>Date Range&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="startdate"
                    checked={this.state.filterDateType === "startdate"}
                    onChange={this.handleFilterDateType}
                  />{" "}
                  Start Date
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="enddate"
                    checked={this.state.filterDateType === "enddate"}
                    onChange={this.handleFilterDateType}
                  />{" "}
                  End Date
                </label>
              </div>
            </div>
          </div>
          {this.state.filterDateType === "startdate" ? (
            <div>
              <div className="text-center filter-begin-date-lbl">
                Begin Date
              </div>
              <DatePicker
                selected={this.state.begindate}
                onSelect={this.handleBeginDate}
                onChange={this.handleBeginDate}
                minDate={this.state.begindate}
                name="begindate"
                dateFormat="MM/dd/yyyy"
              />
            </div>
          ) : (
            <div>
              <div className="text-center filter-end-date-lbl">End Date</div>
              <DatePicker
                selected={this.state.enddate}
                onChange={this.handleEndDate}
                minDate={this.state.begindate}
                name="enddate"
                dateFormat="MM/dd/yyyy"
              />
            </div>
          )}
          <div className="row date-disp-position-xx">
            <div className="col text-center date-display">
              {this.state.beginDateString === ""
                ? ""
                : "Start at: " +
                  this.state.beginDateString.substring(0, 10)}{" "}
              &nbsp;||&nbsp;
              {this.state.endDateString === ""
                ? ""
                : "End at: " + this.state.endDateString.substring(0, 10)}
            </div>
          </div>
          <div className="row row-team-trait-yy">
            <div className="col">
              <strong>Sort as Recent Date&nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="asc"
                    checked={this.state.sortType === "asc"}
                    onChange={this.handleSortType}
                  />{" "}
                  First
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="dsc"
                    checked={this.state.sortType === "dsc"}
                    onChange={this.handleSortType}
                  />{" "}
                  Last
                </label>
              </div>
            </div>
          </div>
          <div className="row filter-row-position">
            <div className="col text-center">
              <button
                className="btn_reg_60"
                type="button"
                onClick={this.closeFilter}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                Clear
              </button>
              {this.state.beginDateString !== "" &&
              this.state.endDateString !== "" ? (
                <button
                  className="btn_reg_60"
                  type="button"
                  onClick={this.filterTheList}
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  Filter
                </button>
              ) : null}
            </div>
          </div>
        </div>
      );
    }

    let bmail, text;
    if (this.state.deviceSize === "small") {
      bmail = "Bml";
      text = "Txt";
    } else {
      bmail = "Bmail";
      text = "Text";
    }

    let requestAvailabilityPanel;
    if (this.state.showAvailabilityReq) {
      requestAvailabilityPanel = (
        <div className="req-availability-box">
          <div className="text-center avail-req-header">
            Availability Instructions
          </div>
          <div className="avail-bmail-inst">
            Used:&nbsp;{this.state.availabilityNoteBmail.length}&nbsp;Chars.
            (Max: 1000 chars)
          </div>
          <div className="row">
            <div className="col-2 text-right">
              <b>{bmail}</b>
            </div>
            <div className="col-10 text-left">
              <textarea
                name="availabilityNoteBmail"
                maxLength="1000"
                placeholder="Special comments for bamil notification."
                rows="3"
                wrap="hard"
                spellCheck="true"
                className="avail-req-input"
                onChange={this.onChange}
                value={this.state.availabilityNoteBmail}
                required
              />
            </div>
          </div>
          <div className="avail-sms-inst">
            Used:&nbsp;{this.state.availabilityNoteBmail.length}&nbsp;Chars.
            (Max: 100 chars)
          </div>
          <div className="row">
            <div className="col-2 text-right">
              <b>{text}</b>
            </div>
            <div className="col-10 text-left">
              <input
                name="availabilityNoteSMS"
                type="text"
                value={this.state.availabilityNoteSMS}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="avail-req-input"
                placeholder="SMS Text Msg."
              />
            </div>
          </div>
          <div className="row avail-panel-button-row">
            <div className="col text-center assign-notify-checkbox">
              Notify via&nbsp;
              <Checkbox
                checked={this.state.notifyViaBmail}
                onChange={this.handleBmailNotify}
              />
              &nbsp; Bmail and/or&nbsp;
              <Checkbox
                checked={this.state.notifyViaSMS}
                onChange={this.handleSMSNotify}
              />
              &nbsp;Text (SMS) when saved. &nbsp;&nbsp;
              <button
                className="btn_back_main"
                type="button"
                onClick={this.closeAvailabilityRequest}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <i className="fas fa-step-backward" />
              </button>
              <button
                className="btn_reg_70"
                type="button"
                onClick={this.sendTheRequest}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Send&nbsp;
                <i className="fa fa-paper-plane" />
              </button>
            </div>
          </div>
          <div
            className={
              !this.state.availerrflag
                ? "text-center assign-avail-msg"
                : "text-center assign-avail-msg-err"
            }
          >
            {this.state.availabilityReqMsg}
          </div>
        </div>
      );
    }

    let filterButton = (
      <div>
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.filterPartialList}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Filter
        </button>
        &nbsp;&nbsp;<b>To Assign</b>:&nbsp;
        <font color="blue">{this.state.allocationList.length}</font>&nbsp;
        <b>slots.</b>
        {filterPanel}
      </div>
    );

    let headerPanel;
    headerPanel = (
      <div className="row assign-worksheet-row">
        <div className="col-2 text-center allocation-assign-head-checked">
          Assigned
        </div>
        <div className="col-3 text-center allocation-assign-head">On Date</div>
        <div className="col-5 text-center allocation-assign-head">
          For Duration
        </div>
        <div className="col-2 text-center allocation-assign-head-ops">Ops</div>
      </div>
    );

    let bool = true;
    let showListPanel;
    showListPanel = (
      <div className="toassign-list-box">
        {this.state.allocationList.map((obj, i) => {
          // console.log("bb obj:", obj);
          return (
            <div key={i}>
              <div className={`${bool ? "row-color-X" : "row-color-Y"}`}>
                <div className="row">
                  {obj.server.length === 0 ? (
                    <div className="col-2 text-center allocation-assign-lbl">
                      <font color="red">
                        <i className="fa fa-times" />
                      </font>
                    </div>
                  ) : (
                    <div className="col-2 text-center allocation-assign-lbl">
                      <font color="green">
                        <i className="fa fa-check" />
                      </font>
                    </div>
                  )}
                  <div className="col-3 text-center allocation-assign-lbl">
                    {this.getDayOfWeek(obj.day)}&nbsp;{obj.day}
                  </div>
                  <div className="col-5 center allocation-assign-lbl">
                    {obj.time.timestr}&nbsp;(For {obj.time.duration}
                    &nbsp;minutes)
                  </div>
                  <div className="col-2 text-left">
                    <button
                      className="btn_reg_60"
                      type="button"
                      onClick={() => this.handleAssign(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      Assign
                    </button>
                  </div>
                </div>
              </div>
              {(bool = !bool)}
            </div>
          );
        })}
      </div>
    );

    let assignSaveButtonPanel = (
      <div>
        <button
          className="btn_reg_80"
          type="button"
          onClick={this.handleSaveAssignment}
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          Allocate
        </button>
        <button
          className="btn_back_main"
          type="button"
          onClick={this.closeAssign}
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          <i className="fas fa-step-backward" />
        </button>
      </div>
    );

    let availibilityDetailPanel;
    if (this.state.showMoreOfAvailabiliy) {
      availibilityDetailPanel = (
        <div className="availability-detail-box">
          <div className="row">
            <div className="col text-center alloc-details">
              Availablity of&nbsp;
              <font color="black">
                {this.state.aMemAvailabilityData.member.name}
              </font>
            </div>
          </div>
          <div className="row">
            <div className="col text-center alloc-details">
              Email:&nbsp;
              <font color="black">
                {this.state.aMemAvailabilityData.member.email}&nbsp;
              </font>
              &nbsp;Cell:
              <font color="black">
                {this.state.aMemAvailabilityData.member.cell}
              </font>
            </div>
          </div>
          <div className="row">
            <div className="col-4 text-center member-availability-detail-header">
              Start-Time for Duration
            </div>
            <div className="col-8 text-center member-availability-detail-header">
              Member Comment
            </div>
          </div>
          <div className="text-center show-availability-detail-box">
            {this.state.aMemAvailabilityData.allocation.length === 0 ? (
              <div className="text-center no-availability-msg">
                Availability not specified by the member
              </div>
            ) : (
              <div>
                {this.state.aMemAvailabilityData.allocation.map((obj, i) => {
                  // console.log("cc obj:", obj);
                  return (
                    <div key={i}>
                      <div className="row">
                        <div className="col-4 text-left display-allocation-member">
                          {this.getStartTimex(obj.startTime)} &nbsp;
                          {this.getDurationString(obj.duration)}
                        </div>
                        <div className="col-8 text-left display-allocation-member">
                          {obj.comment}
                        </div>
                      </div>
                    </div>
                  );
                })}
                <hr className="hr-availabilit-divider" />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col text-center alloc-details">
              <button
                className="btn_back_main"
                type="button"
                onClick={this.closeAvailabilityDetail}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                <i className="fas fa-step-backward" />
              </button>
            </div>
          </div>
        </div>
      );
    }

    let assignmentPanel;
    if (this.state.showAvailabilityPop) {
      assignmentPanel = (
        <div className="assign-popup-box">
          <div className="text-center asignment-pop-header">
            Assign Service Providers
          </div>
          <div className="row">
            <div className="col text-center popup-lbl">
              Assign the slot for&nbsp;<b>{this.state.selectedDay.day}</b>
              &nbsp;starting at&nbsp;
              <b>{this.state.selectedDay.time.timestr}</b>&nbsp;for the
              duration&nbsp;<b>{this.state.selectedDay.time.duration}</b>
              &nbsp;minutes.
            </div>
          </div>
          <div className="row avail-list-header-row">
            <div className="col-9 text-center avail-list-header">
              Available Server Name (email)
            </div>
            <div className="col-3 text-center avail-list-header-ops">Ops</div>
          </div>
          <div className="availability-box">
            {this.state.thisAvailability.map((obj, i) => {
              // console.log("aa obj:", obj);
              return (
                <div key={i}>
                  <div className="row shift-assign-row-pos">
                    <div className="col-9 text-left display-alloc-lebel-comm">
                      {obj.member.name}&nbsp;({obj.member.email})
                    </div>
                    <div className="col-3 text-left">
                      <button
                        className="btn_reg_50"
                        type="button"
                        onClick={() => this.handleShowMore(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        ... <i className="fa fa-search-plus" />
                      </button>
                      {obj.allocation.length > 0 ? (
                        <button
                          // className="btn-alloc-assign"
                          className="btn_reg_60"
                          type="button"
                          onClick={() => this.handleAllocate(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          Add&nbsp;
                          <i className="fa fa-plus" />
                        </button>
                      ) : (
                        <button
                          // className="btn-alloc-assign-disabled"
                          className="btn_reg_60"
                          type="button"
                          disabled={true}
                        >
                          NA&nbsp;
                          {/* <i className="fa fa-plus" /> */}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {availibilityDetailPanel}
          <div className="row avail-list-header-row">
            <div className="col-9 text-center avail-list-header">
              Assigned Server Name (email)
            </div>
            <div className="col-3 text-center avail-list-header-ops">Ops</div>
          </div>
          <div className="availability-box">
            {this.state.thisTaskServer.map((obj, i) => {
              // console.log("xxx obj:", obj);
              return (
                <div key={i}>
                  <div className="row">
                    <div className="col-10 text-left display-alloc-lebel-comm">
                      {obj.name}&nbsp;({obj.email})
                    </div>
                    <div className="col-2 text-left">
                      <button
                        className="btn_exit_del"
                        type="button"
                        onClick={() => this.removeFromThisAvailability(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row server-comment-row">
            <div className="col-3 text-right">
              <b>Comments</b>
            </div>
            <div className="col-9 text-left">
              <input
                name="thisTaskComment"
                type="text"
                value={this.state.thisTaskComment}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="server-allocation-comment"
                placeholder="Comments for the servers (optional)"
              />
            </div>
          </div>
          {assignSaveButtonPanel}
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div>
        <div className="text-center assign-event-header">
          <b>Event</b>:&nbsp;{this.props.theEvent.eventName}
        </div>
        {filterButton}
        {headerPanel}
        {assignmentPanel}
        {requestAvailabilityPanel}
        {showListPanel}
        {buttonPanel}
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

Assign.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Assign));
