import React, { Component } from "react";

import Record from "./Record";
import ShowDocs from "./show/ShowDoc";

import "./InvestRouter.css";

class InvestRouter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      // opsSelectorFlag: true,
      investLendFlag: false,
      showDocsFlag: false,
      // showDraftFlag: false,

      opsSelectPanelFlag: true,
    };
  }

  handleReturnToInvestRouter = async () => {
    await this.setState({
      // opsSelectorFlag: true,
      investLendFlag: false,
      showDocsFlag: false,
      // showDraftFlag: false,
      opsSelectPanelFlag: true,
    });
  };

  handleInvestSelected = async () => {
    let value = document.getElementById("mySelectx").value;
    console.log(">>>>> value:", value);
    await this.setState({
      managePOFlag: false,
      investLendFlag: false,
      showDocsFlag: false,
      // showDraftFlag: false,
      selheight: 1,
    });

    switch (value) {
      case "investRecord":
        await this.setState({
          investLendFlag: true,
        });
        break;

      case "showDocs":
        await this.setState({
          showDocsFlag: true,
          opsSelectPanelFlag: false,
        });
        break;
      // case "showDrafts":
      //   await this.setState({
      //     showDraftFlag: true,
      //   });
      //   break;

      default:
    }
  };

  onfocus = async () => {
    await this.setState({
      selheight: 3,
    });
  };

  onblur = async () => {
    await this.setState({
      selheight: 1,
    });
  };

  // handleReturnFromShowDoc = async() => {
  //   await this.setState({
  //     opsSelectPanelFlag: true,
  //   })
  // }

  render() {
    console.log("InvestRouter this.props:", this.props);
    console.log("InvestRouter this.state:", this.state);

    let opsSelectPanel;
    if ( this.state.opsSelectPanelFlag ) {
      opsSelectPanel = (
        <div>
          <div className="bdgt_router_space" />
          <div className="row">
            <div className="col text-center">
              <select
                size={this.state.selheight}
                onFocus={this.onfocus}
                onBlur={this.onblur}
                id="mySelectx"
                name="mySelectx"
                onChange={this.handleInvestSelected}
                className="invest_select"
                defaultValue={"DEFAULT"}
              >
                <option value="DEFAULT" disabled>
                  Select your ops
                </option>
                <option value="investRecord">
                  Record an Investment or a Loan
                </option>
                <option value="showDocs">Show Loans-Invest Docs</option>
              </select>
            </div>
          </div>
        </div>
      );
    }


    let outputPanel;
    if (this.state.investLendFlag) {
      outputPanel = (
        <div>
          <Record
            returnToOpsRouter={this.handleReturnToInvestRouter}
            communityId={this.props.communityId}
            communityName={this.props.communityName}
          />
        </div>
      );
    }
    if (this.state.showDocsFlag) {
      outputPanel = (
        <div>
          <ShowDocs
            returnToOpsRouter={this.handleReturnToInvestRouter}
            communityId={this.props.communityId}
            communityName={this.props.communityName}
          />
        </div>
      );
    }

    return (
      <div>
        {opsSelectPanel}
        {outputPanel}
      </div>
    );
    // return <div>{outputPanel}</div>;
  }
}

export default InvestRouter;
