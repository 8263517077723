import React, { Component } from "react";

import axios from "axios";

import ReactLoading from "react-loading";

import "./CreateInvoice.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getZiptaxSalesTax = "/routes/dashboard/getZiptaxSalesTax?";

class CreateInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      invoiceLineItems: [],
      salesTax: 0,
      salesTaxBreakdown: null,

      total: 0,
      isSubscription: false,
      subscriptionAmt: 0,
      specialShippingCost: 0,

      isShipping: false,
      isSpecialShipping: false,

      totalOfTaxable: 0,
      salesTaxPortionCustomer: 0,
      salesTaxPortionMerchant: 0,
      salesTaxObj: null,
      subscription: null,
      requireTaskSetup: false,

      monthlyPayment: 0,
      monthsLeft: 0,
      payPending: 0,
      totalInvoiceAmount: 0,
      salesTaxCustomer: 0,
      salesTaxMerchant: 0,
      taxableAmount: 0,
      interestAmount: 0,

      regularShipFlag: false,
      loadingFlag: false,

      periodicDeliveryData: null,
    };
  }

  componentDidMount = async () => {
    // console.log("in createinvoice componentdidmount ....");
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.setState({
      deviceSize,
      loadingFlag: true,
    });

    let isTaxableItem = false;
    let totalNow = 0;

    let isSubscription = false;
    let subscriptionAmt = 0;
    let specialShippingCost = 0;
    let isShipping = false;
    let regularShipFlag = false;
    let totalOfTaxable = 0;
    let isSpecialShipping = false;
    let subscription = null;

    let requireTaskSetup = false;
    // let totalInvoiceAmount = 0;

    let monthlyPayment = 0;
    let monthsLeft = 0;
    let salesTaxPortionCustomer = 0;
    let salesTaxPortionMerchant = 0;
    let interestAmount = 0;
    let periodicDeliveryData = null;
    let periodicTaxAmtBiz = 0;
    let periodicTaxableAmt = 0;
    let periodicTaxAmtCust = 0;

    let periodicDiscountPercent = 0;
    let periodItemId = 0;
    // let i = 1;
    let invoiceLineItems = [];

    this.props.itemsInCart.forEach((obj) => {
      // console.log(i, "XX obj:", obj);
 
      let thisLineItemPrice = 0;
      let subscriptionType = "";
    
      let thisItemName = "";
      let discountPercent = 0;
      let registrationFee = 0;
      let discountObj = null;
      let invoicedPrice = 0;

      if (obj.periodicShipData && obj.periodicShipData.enabled) {
        if (obj.periodicShipData.type === "boxed") {
          invoicedPrice = parseFloat(obj.price);
          // console.log('in here boxed...invoicedPrice:',invoicedPrice)
        } else {
          // tbd
          invoicedPrice = parseFloat(obj.price);
          // console.log('in here planned...invoicedPrice:',invoicedPrice)
        }
        isShipping = true;
        periodicDiscountPercent = obj.discountPercent;
        periodItemId = obj.itemId;
      } else {
        invoicedPrice = parseFloat(obj.price);
      }
      // console.log("1. invoicedPrice:", invoicedPrice);

      if (obj.discountPercent > 0) {
        discountObj = obj.discount;
        discountPercent = obj.discountPercent;
        invoicedPrice =
          parseFloat(obj.price) * (1 - parseFloat(discountPercent) / 100);
        // console.log('invoicedPrice:', invoicedPrice, ' discountPercent:', discountPercent);
      }
      // If taxable, then add to the total taxable amount.
      if (obj.taxable) {
        if (obj.periodicShipData && obj.periodicShipData.enabled) {
          if (obj.periodicShipData.type === "boxed") {
            periodicTaxableAmt =
              (parseFloat(invoicedPrice) -
                parseFloat(obj.periodicShipData.boxedPack.shipPrice)) *
              parseFloat(obj.quantity);

            totalOfTaxable += periodicTaxableAmt;

            if (obj.taxInfo.taxPaidBy === "customer") {
              periodicTaxAmtCust = periodicTaxableAmt;
              salesTaxPortionCustomer += periodicTaxableAmt;
            } else {
              periodicTaxAmtBiz = periodicTaxableAmt;
              salesTaxPortionMerchant += periodicTaxableAmt;
            }
          } else {
            // console.log("in planned ........");
            periodicTaxableAmt =
              parseFloat(invoicedPrice) * parseFloat(obj.quantity);
            totalOfTaxable += periodicTaxableAmt;

            if (obj.taxInfo.taxPaidBy === "customer") {
              periodicTaxAmtCust = periodicTaxableAmt;
              salesTaxPortionCustomer += periodicTaxableAmt;
            } else {
              periodicTaxAmtBiz = periodicTaxableAmt;
              salesTaxPortionMerchant += periodicTaxableAmt;
            }
          }
        } else {
          totalOfTaxable += parseFloat(obj.price) * parseFloat(obj.quantity);
          if (obj.taxInfo.taxPaidBy === "customer") {
            salesTaxPortionCustomer =
              parseFloat(obj.price) * parseFloat(obj.quantity);
          } else {
            salesTaxPortionMerchant =
              parseFloat(obj.price) * parseFloat(obj.quantity);
          }
        }
        isTaxableItem = true;
        // console.log(i, "In taxable ...");
      }


      // totalInvoiceAmount +=
      //   parseFloat(invoicedPrice) * parseFloat(obj.quantity);


      // console.log("1a>> invoicedPrice:", invoicedPrice);
      // console.log("1b>> obj.quantity:", obj.quantity);

      if (obj.service && obj.service.type === "custom") requireTaskSetup = true;

      if (obj.subscription && obj.subscription.allowSubscription) {
        subscription = obj.subscription;
        isSubscription = true;
        // installmentItemIsTaxable = obj.taxable;
        // console.log('obj.subscription.subscriptionType:', obj.subscription.subscriptionType);
        if (obj.periodicShipData && obj.periodicShipData.enabled) {
          let shippingCost = 0;
          if (obj.periodicShipData.type === "boxed") {
            if (this.props.deliveryData.pickShipDecision === "ship") {
              thisLineItemPrice =
                parseFloat(obj.price) * parseFloat(obj.quantity);
              thisItemName =
                obj.itemName +
                " (Ship: $" +
                this.commaFormattedCurrency(
                  parseFloat(obj.periodicShipData.boxedPack.shipPrice) *
                    parseFloat(obj.quantity)
                ) +
                ")";
              shippingCost = parseFloat(
                obj.periodicShipData.boxedPack.shipPrice
              );
            } else {
              thisLineItemPrice =
                parseFloat(obj.price) * parseFloat(obj.quantity) -
                parseFloat(obj.periodicShipData.boxedPack.shipPrice) *
                  parseFloat(obj.quantity);
              thisItemName = obj.itemName;
            }
          } else {
            // console.log('Here in planned... this.props.deliveryData.pickShipDecision:', this.props.deliveryData.pickShipDecision)
            //Calculate line item for planned
            // console.log('220 thisItemName:', thisItemName);
            if (this.props.deliveryData.pickShipDecision === "ship") {
              // console.log('in planned ship obj.price:', obj.price, " obj.periodicShipData.pkgComposedShippingCost:", obj.periodicShipData.pkgComposedShippingCost, " obj.quantity:", obj.quantity)
              thisLineItemPrice =
                (parseFloat(obj.price) +
                  parseFloat(obj.periodicShipData.pkgComposedShippingCost)) *
                parseFloat(obj.quantity);
                // console.log('thisLineItemPrice:', thisLineItemPrice);
                // console.log('228 obj.price:', obj.price, ' obj.periodicShipData.pkgComposedShippingCost:', obj.periodicShipData.pkgComposedShippingCost, ' obj.itemName:', obj.itemName);
              thisItemName =
                obj.itemName +
                " (Prc: $" +
                this.commaFormattedCurrency(parseFloat(obj.price)) +
                " ship: $" +
                this.commaFormattedCurrency(
                  parseFloat(obj.periodicShipData.pkgComposedShippingCost)
                ) +
                ")";
              shippingCost = parseFloat(
                obj.periodicShipData.pkgComposedShippingCost
              );
            } else {
              // console.log('in planned pickup')
              thisItemName =
                obj.itemName +
                " (Prc: " +
                this.commaFormattedCurrency(parseFloat(obj.price)) +
                " | Ship $ not added)";
              thisLineItemPrice =
                parseFloat(obj.price) * parseFloat(obj.quantity);
            }
          }
          // console.log('251 thisItemName:', thisItemName);
          totalNow += thisLineItemPrice;
          // Since this is subscription, and only one subscription item can be in a cart,
          // this will be created (if periodic delivery, once only).
          periodicDeliveryData = {
            periodicShipData: obj.periodicShipData,
            itemId: obj.itemId,
            lineItemName: thisItemName,
            itemImage: obj.itemImage,
            itemPrice: parseFloat(obj.price), // already discounted
            preCalcPrice: thisLineItemPrice,
            taxableAmtCust: periodicTaxAmtCust,
            taxableAmtBiz: periodicTaxAmtBiz,
            periodicTaxableAmt,
            salesTaxObj: null,
            periodicDiscountPercent,
            shippingCost,
            periodItemId,
            quantity: parseFloat(obj.quantity),
            deliveryType: this.props.deliveryData.pickShipDecision,
            periodSubscriptionAmt: 0,
            tradeBetween: null
          };
        } else {
          // console.log('Non delivery subscription. ...')
          if (obj.subscription.subscriptionType === "continuous") {
            monthlyPayment = parseFloat(
              (invoicedPrice * obj.quantity).toFixed(2)
            );
            registrationFee = parseFloat(obj.subscription.registrationFee);
            if (obj.discountPercent > 0) {
              registrationFee =
                parseFloat(obj.subscription.registrationFee) *
                (1 - parseFloat(obj.discountPercent) / 100);
            } else
              totalNow +=
                parseFloat(registrationFee.toFixed(2)) +
                parseFloat(monthlyPayment.toFixed(2));
            // console.log(i, ' A totalNow:', totalNow, ' registrationFee:', registrationFee);
            thisLineItemPrice =
              parseFloat(registrationFee.toFixed(2)) +
              parseFloat(monthlyPayment.toFixed(2));

            subscriptionType = "membership";
            thisItemName =
              obj.itemName +
              " - Registration " +
              registrationFee.toFixed(2) +
              " ";
            if (obj.discountPercent > 0) {
              thisItemName +=
                " (price: $" +
                this.commaFormattedCurrency(registrationFee) +
                " discount: " +
                obj.discountPercent +
                "%)";
            }
            subscriptionAmt = invoicedPrice; // For continous, price is the subscription
          } else {
            if (parseFloat(obj.subscription.numberOfInstallment) > 0) {
              // console.log('Calculate installments ...')
              if (obj.discountPercent > 0) {
                thisItemName =
                  obj.itemName +
                  " (price: $" +
                  this.commaFormattedCurrency(parseFloat(obj.price)) +
                  " discount: " +
                  obj.discountPercent +
                  "%) Installments " +
                  obj.subscription.numberOfInstallment +
                  " - Pay Now > ";
                // console.log(i, ' thisItemName:', thisItemName);
              } else {
                thisItemName =
                  obj.itemName +
                  " price $" +
                  this.commaFormattedCurrency(invoicedPrice) +
                  " - First Pay > ";
              }
              // console.log('326 invoicePrice:', invoicedPrice, ' obj.quantity:', obj.quantity); 
              // console.log('1 - obj.subscription.upfrontPercentage/100:', 1 - obj.subscription.upfrontPercentage/100);
              let creditAmount = invoicedPrice * parseFloat(obj.quantity) * ( 1 - obj.subscription.upfrontPercentage/100);
              // console.log('creditAmount:', creditAmount);
              if (obj.subscription.interestRate > 0) {
                // Calculate simple interest (sr) = creditAmount (p) * interetRate (r) * time (t);
                // if time is not whole year, calculate year in fraction.

                thisItemName =
                  thisItemName +
                  "APR:" +
                  this.commaFormattedCurrency(obj.subscription.interestRate) +
                  "%";

                let rate = obj.subscription.interestRate / 100;
                // console.log(' rate:', rate);
                let time = parseFloat(obj.subscription.numberOfInstallment) - 1;
                // console.log(' time:', time);

                let interestPerYear =  creditAmount * rate;
                let interestPerMonth = interestPerYear / 12;
                // console.log('interestPerMonth:', interestPerMonth);
                interestAmount = interestPerMonth * time;
                // console.log('interetAmount:', interestAmount);
  
                // Add the interst to the totalInvoiceAmountx
                // totalInvoiceAmount += interestAmount;

                thisItemName +=
                  "=$" + this.commaFormattedCurrency(interestAmount);
              } 

              // console.log('thisItemName:', thisItemName);

              let totalCost = (invoicedPrice * parseFloat(obj.quantity)) + interestAmount;
              // console.log('totalcost (including interest):', totalCost);
              // upfront cost is upfrontPercentage of the totalcost (including interest)  
              let upfrontAmt = totalCost * (obj.subscription.upfrontPercentage/100) ;
               
              // console.log('upfrontAmt:', upfrontAmt);
              if (obj.subscription.numberOfInstallment > 0) {
                // console.log('will calc subscriptionAmt  totalCost:',  totalCost, ' upfrontAmt:', upfrontAmt, ' obj.subscription.numberOfInstallment - 1:', obj.subscription.numberOfInstallment );
                // subscription Amount - number of insallment must be at least 2.
                subscriptionAmt = (totalCost - upfrontAmt) /   obj.subscription.numberOfInstallment ;   
                // console.log('1. subscriptionAmt:', subscriptionAmt);
              }
              // console.log('2. subscriptionAmt:', subscriptionAmt);

              totalNow += parseFloat((upfrontAmt + subscriptionAmt).toFixed(2));
              
              thisLineItemPrice = parseFloat((upfrontAmt + subscriptionAmt).toFixed(2));
              // console.log('thisLineItemPrice:', thisLineItemPrice); 
              monthsLeft = parseFloat(obj.subscription.numberOfInstallment) - 1;
              thisItemName += " monthly $" + this.commaFormattedCurrency(subscriptionAmt) ;
            }
          }
        }

        subscriptionType = obj.subscription.subscriptionType;
      } else {
        totalNow += parseFloat((invoicedPrice * obj.quantity).toFixed(2));
        // console.log(i, 'C totalNow:', totalNow, ' invoicedPrice:', invoicedPrice, ' obj.quantity:', obj.quantity);
        // thisLineItemPrice = parseFloat(obj.price) * parseFloat(obj.quantity);
        thisLineItemPrice = parseFloat(
          (invoicedPrice * obj.quantity).toFixed(2)
        );
        if (obj.discountPercent > 0) {
          thisItemName =
            obj.itemName +
            " (price: $" +
            this.commaFormattedCurrency(obj.price) +
            " discount: " +
            obj.discountPercent +
            "%)";
        } else thisItemName = obj.itemName;
        
      }

      // console.log("1. totalNow:", totalNow);
      // console.log('2 thisItemName:', thisItemName);
      let checkTask;
      if (deviceSize === "small")
        checkTask = " (Click 'Ops->Tsk' for price after checkout)";
      else checkTask = " (Click 'Operation->Tasks' for price after checkout)";
      if (thisLineItemPrice === 0) thisItemName = thisItemName + checkTask;
      // console.log(i, "2-thisLineItemPrice:", thisLineItemPrice);
      // console.log('415 thisItemName:', thisItemName);
      let anInvoiceLine = {
        itemId: obj.itemId,
        itemName: thisItemName,
        itemImage: obj.itemImage,
        // price: thisLineItemPrice,
        amount: thisLineItemPrice,
        price: obj.price,
        quantity: obj.quantity,
        itemType: obj.itemType,
        unitType: obj.unitType,
        // payPending,
        monthlyPayment,
        monthsLeft,
        subscriptionType,
        interestAmount,
        specialShipping: obj.specialShipping,
        discountPercent: obj.discountPercent,
        discountObj,
        schedulesToCart: obj.schedulesToCart,
        service: obj.service,
        taxInfo: obj.taxInfo,
        taxable: obj.taxable,
        subItem: obj.subItem,
        dimensions: obj.dimensions,
      };
      invoiceLineItems.push(anInvoiceLine);

      if (obj.shipping && obj.specialShipping === "special" && this.props.deliveryData.pickShipDecision === 'ship') {
        // console.log(
        //   "in special shipping obj.shipping.cost:",
        //   obj.shipping.cost
        // );
        totalNow += parseFloat(obj.shipping.cost.toFixed(2));
        specialShippingCost += parseFloat(obj.shipping.cost.toFixed(2));
        isSpecialShipping = true;
        // totalInvoiceAmount += parseFloat(obj.shipping.cost.toFixed(2));
        // console.log(i, 'D totalNow:', totalNow, ' obj.shipping.cost:', obj.shipping.cost);
      }
      if (obj.shipping && obj.specialShipping === "regular") regularShipFlag = true;

      if (obj.shipping || obj.specialShipping !== "") isShipping = true;

    });

    if (
      this.props.deliveryData &&
      !this.props.deliveryData.freeShippingFlag &&
      this.props.deliveryData.selectedShipingPrice
    ) {
      // Calculating ... how much to pay now.
      totalNow += parseFloat(
        this.props.deliveryData.selectedShipingPrice.price.toFixed(2)
      );
      // Calculating total invoice
      // totalInvoiceAmount += parseFloat(
      //   this.props.deliveryData.selectedShipingPrice.price.toFixed(2)
      // );
    }

    let salesTaxPercent = 0;
    let salesTaxBreakdown = null;
    // let salesTaxObj = null;

    let deliverToAddr = "";
    // console.log("isTaxableItem:", isTaxableItem);
    let salesTaxObj;
    // console.log('isTaxableItem:', isTaxableItem);
    if (isTaxableItem) {
      deliverToAddr =
        this.props.deliveryData.street1 +
        ", " +
        this.props.deliveryData.city +
        " " +
        this.props.deliveryData.state +
        ", " +
        this.props.deliveryData.zip +
        " " +
        this.props.deliveryData.country;
      // console.log("deliveryToAddr:", deliverToAddr);
      salesTaxObj = await this.getSalesTaxBreakDown(
        deliverToAddr,
        salesTaxPortionCustomer // totalOfTaxable - only the portion for customer goes
      );
      // console.log("2. >> salesTaxObj:", salesTaxObj);
      // console.log("salesTaxPercent:", salesTaxPercent);
      salesTaxBreakdown = salesTaxObj.taxBreakdown;
      // console.log("salesTaxObj:", salesTaxObj);
      // console.log('salesTaxBreakdown:', salesTaxBreakdown);
      salesTaxPercent = salesTaxObj.taxBreakdown.taxSales;
      if ( periodicDeliveryData ) periodicDeliveryData.salesTaxObj = salesTaxBreakdown;
    }

    // let periodicTaxAmtBiz = 0;
    // let periodicTaxAmtCust = 0;
    // console.log("totalOfTaxable:", totalOfTaxable);
    // console.log("periodicTaxAmtBiz:", periodicTaxAmtBiz);
    // console.log("periodicTaxAmtCust:", periodicTaxAmtCust);
    // console.log("3. >> salesTaxObj:", salesTaxObj);
    // console.log("salesTaxPercent:", salesTaxPercent);
    let salesTax = parseFloat((totalOfTaxable * salesTaxPercent).toFixed(2));
    // console.log("salesTax:", salesTax);
    let salesTaxCustomer = parseFloat(
      (salesTaxPortionCustomer * salesTaxPercent).toFixed(2)
    );
    let salesTaxMerchant = parseFloat(
      (salesTaxPortionMerchant * salesTaxPercent).toFixed(2)
    );
    // console.log("salesTaxPortionCustomer:", salesTaxPortionCustomer);
    // console.log("salesTaxMerchant:", salesTaxMerchant);

    totalNow += salesTaxCustomer;

    // Add registration amount totalInvoiceAmountx
    // this.props.itemsInCart.forEach((itm) => {
    //   if (itm.subscription && itm.subscription.allowSubscription) {
    //     totalInvoiceAmount += parseFloat(itm.subscription.registrationFee);
    //   }
    // });
    // console.log(">> periodicDeliveryData:", periodicDeliveryData);

    if (
      periodicDeliveryData &&
      periodicDeliveryData.periodicShipData &&
      periodicDeliveryData.periodicShipData.enabled
    ) {
      // console.log("in periodic deli...");
      if (periodicDeliveryData.salesTaxObj) {
        // console.log("in taxable");
        let shipcost = 0;
        if (periodicDeliveryData.deliveryType === "ship") {
          if (periodicDeliveryData.periodicShipData.type === "boxed") {
            shipcost =
              periodicDeliveryData.periodicShipData.boxedPack.shipPrice *
              periodicDeliveryData.quantity;
          } else {
            shipcost =
              periodicDeliveryData.shippingCost * periodicDeliveryData.quantity;
          }
        }
        // console.log(
        //   "periodicDeliveryData.salesTaxObj.taxSales:",
        //   periodicDeliveryData.salesTaxObj.taxSales
        // );
        // console.log(
        //   "periodicDeliveryData.periodicTaxableAmt:",
        //   periodicDeliveryData.periodicTaxableAmt
        // );
        // console.log(
        //   "periodicDeliveryData.quantity:",
        //   periodicDeliveryData.quantity
        // );
        // console.log("shipcost:", shipcost);
        let psa =
          periodicDeliveryData.periodicTaxableAmt * (1 + periodicDeliveryData.salesTaxObj.taxSales) +
          shipcost;
          // console.log('psa:', psa);
          // console.log('parseFloat(psa.toFixed(2)):', parseFloat(psa.toFixed(2)));
          // console.log('round:', Math.round(psa * 100) / 100);
        // periodicDeliveryData.periodSubscriptionAmt = parseFloat(psa.toFixed(2));
        periodicDeliveryData.periodSubscriptionAmt = Math.round(psa * 100) / 100;
      } else {
        // console.log(
        //   "in non taxable periodicDeliveryData.deliveryType:",
        //   periodicDeliveryData.deliveryType
        // );
        // let shipcost = 0;
        // if (periodicDeliveryData.deliveryType === "ship") {
        //   if (periodicDeliveryData.periodicShipData.type === "boxed") {
        //     shipcost =
        //       periodicDeliveryData.periodicShipData.boxedPack.shipPrice *
        //       periodicDeliveryData.quantity;
        //   } else {
        //     shipcost =
        //       periodicDeliveryData.shippingCost * periodicDeliveryData.quantity;
        //   }
        // }
        // console.log('preCalcPrice:', periodicDeliveryData.preCalcPrice, ' quantity:', periodicDeliveryData.quantity, '  shipcost:', shipcost);
        periodicDeliveryData.periodSubscriptionAmt = Math.round(periodicDeliveryData.preCalcPrice * 100) / 100 ;
      }
      // console.log("invoice ...periodicDeliveryData:", periodicDeliveryData);
      subscriptionAmt = periodicDeliveryData.periodSubscriptionAmt;
    }

    this.setState({
      deviceSize,
      total: totalNow,
      totalInvoiceAmount: totalNow,
      salesTax,
      taxableAmount: salesTaxPortionCustomer + salesTaxPortionMerchant,
      salesTaxBreakdown,
      isSubscription,
      isShipping,
      regularShipFlag,
      totalOfTaxable,
      salesTaxPortionCustomer,
      salesTaxPortionMerchant,
      salesTaxCustomer,
      salesTaxMerchant,
      invoiceLineItems,
      isSpecialShipping,
      interestAmount,
      specialShippingCost,
      subscriptionAmt,
      subscription,
      requireTaskSetup,
      monthlyPayment,
      monthsLeft,
      loadingFlag: false,
      periodicDeliveryData,
    });
  };

  // SalesTax Calculation
  getSalesTaxBreakDown = async (deliverToAddr, totalOfTaxable) => {
    // console.log(
    //   "deliverToAddr:",
    //   deliverToAddr,
    //   " totalOfTaxable:",
    //   totalOfTaxable
    // );
    let salesTaxObj = {
      salesTax: 0,
      taxBreakdown: null,
      msg: "",
      status: "success",
    };

    try {
      let param = "address=" + deliverToAddr;
      let url = baandaServer + getZiptaxSalesTax + param;
      // console.log("url:", url);

      let resp = await axios.get(url);
      // console.log("resp.data: ", resp.data);

      if (resp.data.status === "success") {
        salesTaxObj.salesTax = parseFloat(
          (totalOfTaxable * resp.data.Msg.results[0].taxSales).toFixed(2)
        );
        salesTaxObj.taxBreakdown = resp.data.Msg.results[0];
        this.setState({
          salesTaxObj,
        });
      } else {
        salesTaxObj.status = "error";
        salesTaxObj.msg = resp.data.Msg;
      }
    } catch (err) {
      console.log("err:", err.message);
      salesTaxObj.status = "error";
      salesTaxObj.msg = err.message;
    }

    // console.log("1. >> salesTaxObj:", salesTaxObj);

    return salesTaxObj;
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  invoicePayUp = () => {
    let salesTaxPer = 0;
    if (this.state.salesTaxBreakdown)
      salesTaxPer = this.state.salesTaxBreakdown.taxSales;

      let deliveryData = null;
      if ( this.props.deliveryData ) {
        let dd = this.props.deliveryData;
        deliveryData = {
          addrName: dd.addrName,
          street1: dd.street1,
          street2: dd.street2,
          city: dd.city,
          state: dd.state,
          zip: dd.zip,
          country: dd.country,
          phone: '',  // To be filled out in the checkout.
          pickShipDecision: dd.pickShipDecision, 
          hasRegularShipping: dd.hasRegularShipping,
          hasSpecialShipping: dd.hasSpecialShipping,
          pickupDirection: dd.pickupDirection,
          selectedShipingPrice: dd.selectedShipingPrice,
        }
      }


    let theInvoice = {
      communityId: this.props.community.communityId,
      invoiceId: 0,
      invoiceLineItems: this.state.invoiceLineItems,
      toPayAmount: this.state.total,
      totalInvoiceAmount: this.state.totalInvoiceAmount,
      isSubscription: this.state.isSubscription,
      subscription: this.state.subscription,
      subscriptionAmt: this.state.subscriptionAmt,
      salesTax: this.state.salesTax,
      salesTaxBreakdown: this.state.salesTaxBreakdown,
      salesTaxCustomer: this.state.salesTaxCustomer,
      salesTaxMerchant: this.state.salesTaxMerchant,
      requireTaskSetup: this.state.requireTaskSetup,
      deliveryData,
      specialShippingCost: this.state.specialShippingCost,
      monthlyPayment: this.state.monthlyPayment,
      monthsLeft: this.state.monthsLeft,
      payPending: this.state.payPending,
      taxInfo: {
        taxableAmount: this.state.taxableAmount,
        salesTaxPer,
        stMerchantPortion: this.state.salesTaxPortionMerchant,
        stMerchantTax: this.state.salesTaxMerchant,
        salesTaxCustomer: this.state.salesTaxCustomer,
        taxObj: this.state.salesTaxObj,
      },
      periodicDeliveryData: this.state.periodicDeliveryData,
    };

    // console.log("In createInvoice pre-exit theInvoice:", theInvoice);
    this.props.returnToCaller(theInvoice);
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("CreateInvoice...");

    let uploadingSpin;
    if (this.state.loadingFlag) {
      uploadingSpin = (
        <div className="subscription-spinner-pos text-center">
          <ReactLoading
            type={"spokes"}
            color={"#1f3d6b"}
            height={30}
            width={30}
          />
        </div>
      );
    }

    let PayButton;
    PayButton = (
      <div className="row">
        <div className="col-9 text-left chekout-inv-name-lbl-ship">&nbsp;</div>
        <div className="col-3 text-center">
          {uploadingSpin}
          <button
            className="btn_reg_60"
            type="button"
            onClick={this.invoicePayUp}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Pay
          </button>
        </div>
      </div>
    );
    let amount;
    if (this.state.deviceSize === "small") {
      amount = "Amt";
    } else {
      amount = "Amount";
    }

    let itemsPanel;
    let bool = true;
    if (this.state.invoiceLineItems.length > 0) {
      itemsPanel = (
        <div>
          <div className="row">
            <div className="col-9 text-left invoice-item-list-name">
              Item Description
            </div>
            <div className="col-3 text-center invoice-item-list-amount">
              {amount} $
            </div>
          </div>
          <div>
            {this.state.invoiceLineItems.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-9 text-left chekout-inv-name-lbl">
                      {obj.itemName}&nbsp;(Qty: {obj.quantity}){" "}
                      {obj.specialShipping === "special"
                        ? " (Special Shipping)"
                        : null}
                      {obj.monthlyPayment > 0
                        ? " " +
                          this.commaFormattedCurrency(obj.monthlyPayment) +
                          "/mo "
                        : null}
                      {obj.monthsLeft > 0
                        ? " for " + obj.monthsLeft + " months"
                        : null}
                    </div>
                    <div className="col-3 text-right checkoutinv-amount-lbl">
                      {this.commaFormattedCurrency(obj.amount)}
                      {/* {this.commaFormattedCurrency(obj.price)} */}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let shippingPanel;
    if (this.state.isShipping) {
      shippingPanel = (
        <div className="ship-invoice-row">
          {this.props.deliveryData.pickShipDecision === "ship" &&
          this.state.regularShipFlag &&
          this.props.deliveryData.selectedShipingPrice ? (
            <div className="row">
              <div className="col-9 text-left chekout-inv-name-lbl-ship">
                Regular Shipping Price
              </div>
              <div className="col-3 text-right checkoutinv-amount-lbl-ship">
                {this.commaFormattedCurrency(
                  parseFloat(this.props.deliveryData.selectedShipingPrice.price)
                )}
              </div>
            </div>
          ) : null}
          {this.props.deliveryData.pickShipDecision === "ship" &&
          this.state.regularShipFlag &&
          this.props.deliveryData.freeShippingFlag? (
            <div className="row">
              <div className="col-9 text-left chekout-inv-name-lbl-ship">
                Regular Shipping (Free)
              </div>
              <div className="col-3 text-right checkoutinv-amount-lbl-ship">
                {this.commaFormattedCurrency(0)}
              </div>
            </div>
          ) : null}
          {this.props.deliveryData.pickShipDecision === "ship" &&
          this.state.periodicDeliveryData &&
          this.state.periodicDeliveryData.periodicShipData &&
          this.state.periodicDeliveryData.periodicShipData.enabled ? (
            <div className="row">
              <div className="col-9 text-left chekout-inv-name-lbl-ship">
                Periodic Shipping Price (included in price)
              </div>
              <div className="col-3 text-right checkoutinv-amount-lbl-ship">
                {/* {this.commaFormattedCurrency(
                  parseFloat(this.props.deliveryData.selectedShipingPrice.price)
                )} */}
                &nbsp;
              </div>
            </div>
          ) : null}
          {this.props.deliveryData.hasSpecialShippingFlag ? (
            <div className="row">
              <div className="col-9 text-left chekout-inv-name-lbl-ship">
                Special Shipping Price
              </div>
              <div className="col-3 text-right checkoutinv-amount-lbl-ship">
                {this.commaFormattedCurrency(
                  parseFloat(this.state.specialShippingCost)
                )}
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-9 text-left chekout-inv-name-lbl-ship">
              {this.props.deliveryData.pickShipDecision === "pickup"
                ? "Pickup from"
                : "Ship to"}
              :&nbsp;{this.props.deliveryData.street1}&nbsp;
              {this.props.deliveryData.street2}, {this.props.deliveryData.city}
              &nbsp;{this.props.deliveryData.state},{" "}
              {this.props.deliveryData.zip} {this.props.deliveryData.country}
            </div>
            <div className="col-3 text-right checkoutinv-amount-lbl">
              &nbsp;
            </div>
          </div>
          {this.props.deliveryData.pickShipDecision === "pickup" &&
          this.props.deliveryData.pickupDirection !== "" ? (
            <div className="row">
              <div className="col-9 text-left chekout-inv-name-lbl-ship">
                {this.props.deliveryData.pickupDirection}
              </div>
              <div className="col-3 text-right checkoutinv-amount-lbl">
                &nbsp;
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    let taxamt;
    if (this.state.deviceSize === "small") {
      taxamt = "TxAmt";
    } else {
      taxamt = "Taxable Amount";
    }

    let salesTaxPanel;
    if (this.state.salesTax > 0) {
      salesTaxPanel = (
        <div className="row inv-salestax-row">
          <div className="col-9 text-left chekout-inv-name-lbl-tax">
            Sales Tax ({taxamt}: $
            {this.commaFormattedCurrency(this.state.taxableAmount)}; Tax:{" "}
            {this.state.salesTaxBreakdown.taxSales * 100}% )
            {this.state.salesTaxPortionMerchant > 0
              ? "(Merchant Pay:$" +
                this.commaFormattedCurrency(this.state.salesTaxMerchant) +
                ")"
              : null}
          </div>
          <div className="col-3 text-right checkoutinv-amount-lbl-tax">
            {this.commaFormattedCurrency(
              parseFloat(this.state.salesTaxCustomer)
            )}
          </div>
        </div>
      );
    }

    let totalPanel = (
      <div className="inv-total-row-pos">
        <div className="row inv-total-row">
          <div className="col-9 text-left chekout-inv-name-lbl-total">
            Total Invoice Value
          </div>
          <div className="col-3 text-right checkoutinv-amount-lbl-total">
            {this.commaFormattedCurrency(parseFloat(this.state.total))}
          </div>
        </div>
      </div>
    );

    let outputPanel;
    outputPanel = (
      <div>
        <div className="text-center checkout-invoice-header">Invoice</div>
        <div className="fixedsize-checkout-invoice">
          {itemsPanel}
          {shippingPanel}
          {salesTaxPanel}
          {totalPanel}
          {PayButton}
        </div>
      </div>
    );

    return <div>{outputPanel}</div>;
  }
}

export default CreateInvoice;
