import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";
import "./Markets.css";

import AStoreLanding from "./landing/AStoreLanding";
import SearchTheMarket from "./search/SearchTheMarket";

import ViewCatalog from "../catalog/ViewPagiCatalog";

import notavailable from "../image/notavailable.png";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getInitTopStores = "/routes/coop/getInitTopStores?";
const searchForStoreByWords = "/routes/coop/searchForStores?";
const searchStoresByDistance = "/routes/coop/searchStoresByDistance?";
// const searchItemsInStore = "/routes/coop/searchItemsInStore?";

const readMeCode = "1110611111";

class Markets extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      stores: [],
      numberOfStores: 0,

      assorted: [],

      showAStoreInfoFlag: false,
      showStoreList: true,
      showSearchFlag: false,
      showCatalogFlag: false,
      showItemsList: false,
      showItemDetailsFlag: false,
      store: null,
      items: [],
      coopItemFilter: null,

      searchType: "",

      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getTopStores();

    this.setState({
      deviceSize,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getTopStores = async () => {
    let param = "communityId=" + this.props.communityId;
    let url = baandaServer + getInitTopStores + param;
    // console.log("url:", url);
    try {
      let stret = await axios.get(url);
      // console.log("stret:", stret);
      if (stret.data.status === "success") {
        if (stret.data.Msg.noOfStore > 0) {
          this.setState({
            errMsg: "",
            errFlag: false,
            stores: stret.data.Msg.sorted,
            numberOfStores: stret.data.Msg.noOfStore,
          });
        } else {
          this.setState({
            errMsg: "No open stores to show ...",
            errFlag: false,
          });
        }
      } else {
        this.setState({
          errMsg: stret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  getStoresbyWords = async (searchText) => {
    let params =
      "communityId=" + this.props.communityId + "&searchText=" + searchText;
    let url = baandaServer + searchForStoreByWords + params;
    // console.log("url:", url);
    try {
      let stret = await axios(url);
      // console.log("stret:", stret);
      if (stret.data.status === "success") {
        if (stret.data.Msg.noOfStore > 0) {
          this.setState({
            errMsg: "",
            errFlag: false,
            stores: stret.data.Msg.sorted,
            numberOfStores: stret.data.Msg.noOfStore,
          });
        } else {
          this.setState({
            errMsg: "No stores found for the search.",
            errFlag: false,
          });
        }
      } else {
        this.setState({
          errMsg: stret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  getStoresBydistance = async (loc) => {
    // console.log("loc:", loc);
    let params =
      "communityId=" +
      this.props.communityId +
      "&distance=" +
      loc.distance +
      "&longitude=" +
      loc.longitude +
      "&latitude=" +
      loc.latitude;
    let url = baandaServer + searchStoresByDistance + params;
    // console.log("searchStoresByDistance url:", url);
    try {
      let strret = await axios.get(url);
      // console.log("strret.data:", strret.data);
      if (strret.data.status === "success") {
        this.setState({
          stores: strret.data.Msg,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: strret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };


  handleStoreInfo = async (post) => {
    // console.log("info post:", post);

    this.setState({
      showAStoreInfoFlag: true,
      showStoreList: false,
      showSearchFlag: false,
      showCatalogFlag: false,
      store: post,
    });
  };

  returnFromStoreInfo = () => {
    // console.log("returnFromStoreInfo...");
    this.setState({
      showAStoreInfoFlag: false,
      showStoreList: true,
      showSearchFlag: false,
      showCatalogFlag: false,
      store: null,
    });
  };

  handleStoreSearch = () => {
    // console.log("handle store search ...");
    this.setState({
      showAStoreInfoFlag: false,
      showStoreList: false,
      showSearchFlag: true,
      showCatalogFlag: false,
    });
  };

  returnFromSearch = async (obj) => {
    // console.log("returnFromSearch obj:", obj);
    let showStoreList = false;
    let showItemsList = false;
    let coopItemFilter = null;
    let searchType = obj.searchType;
    if (obj && obj.searchType === "storeword") {
      await this.getStoresbyWords(obj.searchText);
      showStoreList = true;
    }
    if (obj && obj.searchType === "distance") {
      await this.getStoresBydistance(obj);
      showStoreList = true;
    }
    if (obj && obj.searchType === "itemword") {
      coopItemFilter = {
        searchText: obj.searchText,
        filterType: "coopItemsText",
      };
      showItemsList = true;
    }
    this.setState({
      showAStoreInfoFlag: false,
      showStoreList,
      showItemsList,
      showSearchFlag: false,
      showCatalogFlag: false,
      coopItemFilter,
      searchType,
    });
  };

  handleStoreOpen = (post) => {
    // console.log("handleStoreOpen post:", post);
    this.setState({
      showAStoreInfoFlag: false,
      showStoreList: false,
      showSearchFlag: false,
      showCatalogFlag: true,
      store: post,
    });
  };

  returnFromCatalog = () => {
    // console.log("returnFromCatalofg ...");
    this.setState({
      showAStoreInfoFlag: false,
      showStoreList: true,
      showSearchFlag: false,
      showCatalogFlag: false,
      showItemsList: false,
    });
  };

  backToStoreList = () => {
    // console.log("in backToStoreList");
    this.setState({
      showAStoreInfoFlag: false,
      showStoreList: true,
      showSearchFlag: false,
      showCatalogFlag: false,
      showItemsList: false,
    });
  }; 

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);
    console.log('Markets...');

    let search;
    if ( this.state.deviceSize === 'big') {
      search = "Search";
    } else {
      search = "Srch";
    }

    let buttonSearch;
    buttonSearch = (
      <button
        onClick={this.handleStoreSearch}
        className="btn_reg_75"
        style={{
          cursor: this.state.disabled ? "default" : "pointer",
        }}
      >
        {this.state.deviceSize === 'big' ? search : <i className="fas fa-search-plus" />}
      </button>
    );

    let infoButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let mainStorePanel;

    if (this.props.community.commStoreOfCoopOpen) {
      let communityPic = "";
      this.props.community.fileUploads.forEach((ig) => {
        if (ig.type === "commPromoImage") communityPic = ig.location;
      });
      if (communityPic === "") {
        this.props.community.fileUploads.forEach((ig) => {
          if (ig.type === "image") communityPic = ig.location;
        });
      }
      mainStorePanel = (
        <div>
          <div className="row">
            <div className="col-2 text-center pic-placement">
              <img
                src={communityPic}
                alt="storepic"
                className="pic-store-list-disp"
              />
            </div>
            <div className="col-10 text-left">
              <div className="row list-store-row">
                <div className="col-10 text-left coop-cat-store-name">
                  {this.props.community.commName}
                </div>
                <div className="col-2 text-left">
                  <button
                    onClick={() => this.handleStoreOpen(null)}
                    className={
                      this.state.deviceSize === "big"
                        ? "btn_reg_70"
                        : "coop-mkt-btn-central-pos btn_reg_60"
                    }
                    style={{
                      cursor: this.state.disabled ? "default" : "pointer",
                    }}
                  >
                    {this.state.deviceSize === "big" ? "Catalog" : <i className="fas fa-store" />}
                  </button>
                </div>
              </div>
              <div className="row list-store-row">
                <div className="col text-left cat-store-description">
                  {this.state.deviceSize === "big" ? (
                    <div>
                      {this.props.community.commDescription.substring(0, 100)}
                      ...
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="row list-store-row">
                <div className="col text-left cat-main-store-contact">
                  Contact: {this.props.community.contact[0].contactName}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let storeListPanel;
    if (this.state.showStoreList) {
      let bool = true;
      storeListPanel = (
        <div className="">
          {mainStorePanel}
          {/* <div className="coop-store-list-box"> */}
          <div
            className={
              this.props.community.commStoreOfCoopOpen
                ? "coop-store-list-box-short"
                : "coop-store-list-box-tall"
            }
          >
            <div className="row">
              <div className="col-2 text-center ">{infoButton}</div>
              <div className="col-8 text-center coop-store-list-head">
                Stores
              </div>
              <div className="col-2 text-left coop-list-search-btn-pos">
                {buttonSearch}
              </div>
            </div>

            {this.state.stores.map((post, i) => {
              // console.log("post:", post);
              let pic = "";

              if (this.state.searchType === "distance") {
                if (post.imageLocation !== "") pic = post.imageLocation;
                else pic = notavailable;
              } else {
                if (post.fileUploads) {
                  post.fileUploads.forEach((ele) => {
                    if (ele.type === "storePromoImage") {
                      if (ele.location !== "") {
                        pic = ele.location;
                      }
                    }
                  });
                  if (pic === "") pic = notavailable;
                }
              }

              let address = "";
              if (post.displayAddress)
                address =
                  post.displayAddress.street +
                  ", " +
                  post.displayAddress.city +
                  ", " +
                  post.displayAddress.state;

              return (
                <div key={i}>
                  <div
                    className={`${
                      bool ? "row-color-storelist-dr" : "row-color-storelist-lt"
                    }`}
                  >
                    <div className="row">
                      <div className="col-2 text-center pic-placement">
                        <img
                          src={pic}
                          alt="storepic"
                          className="pic-store-list-disp"
                        />
                      </div>
                      <div className="col-10 text-left">
                        <div className="row list-store-row">
                          <div className="col text-left cat-store-disp-name">
                            {/* {this.state.deviceSize === "big" ? (
                              <div> */}
                                {post.displayStoreName}  
                              {/* </div>
                            ) : null} */}
                          </div>
                        </div>
                        <div className="row list-store-row">
                          <div className="col text-left coop-cat-store-name">
                            <b>{post.coopStoreName}</b>
                            {this.state.searchType === "distance"
                              ? " (" + post.distance + " mi)"
                              : null}
                          </div>
                        </div>
                        <div className="row list-store-row">
                          <div className="col text-left cat-store-description">
                            {this.state.deviceSize === "big" ? (
                              <div>
                                {post.displayDescription.substring(0, 100)} ...
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <div className="row list-store-row">
                          <div className="col-7 text-left cat-store-contact">
                            {this.state.deviceSize === "big" ? "Keeper:" : "Kpr:"}{" "}
                            {post.displayStoreKeeper}&nbsp;
                            {this.state.deviceSize === "big"
                              ? "at " + address
                              : null}
                          </div>
                          <div className="col-5 text-right coop-list-ops-btn-pos ">
                            <button
                              onClick={() => this.handleStoreInfo(post)}
                              className="coop-mkt-btn-pos btn_reg_30"
                              style={{
                                cursor: this.state.disabled
                                  ? "default"
                                  : "pointer",
                              }}
                            >
                              ...
                            </button>
                            <button
                              onClick={() => this.handleStoreOpen(post)}
                              className={
                                this.state.deviceSize === "big"
                                  ? "btn_reg_70"
                                  : "coop-mkt-btn-pos btn_reg_40"
                              }
                              style={{
                                cursor: this.state.disabled
                                  ? "default"
                                  : "pointer",
                              }}
                            >
                              {this.state.deviceSize === "big"
                                ? "Catalog"
                                : <i className="fas fa-store" />}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
          {this.state.numberOfStores > 20 ? (
            <div className="store-counts text-center">
              Displayed {this.state.stores.length} stores out of{" "}
              {this.state.numberOfStores}{" "}
            </div>
          ) : null}
        </div>
      );
    }

    // let itemListPanel;
    // if (this.state.showItemsList && this.state.items) {
    //   let bool1 = true;
    //   itemListPanel = (
    //     <div className="">
    //       <div className="coop-store-list-box">
    //         <div className="row">
    //           <div className="col-2 text-left ">&nbsp;</div>
    //           <div className="col-8 text-center coop-store-list-head">
    //             Items&nbsp;&nbsp;{toStoresButton}
    //           </div>
    //           <div className="col-2 text-left coop-list-search-btn-pos">
    //             &nbsp;
    //           </div>
    //         </div>

    //         {this.state.items.map((item, i) => {
    //           console.log("item:", item);
    //           let pic = "";

    //           if (item.fileUploads) {
    //             item.fileUploads.forEach((ele) => {
    //               if (ele.type === "image") {
    //                 if (ele.location !== "") {
    //                   pic = ele.location;
    //                 }
    //               }
    //             });
    //             if (pic === "") pic = notavailable;
    //           }

    //           return (
    //             <div key={i}>
    //               <div
    //                 className={`${
    //                   bool1 ? "row-color-storelist-dr" : "row-color-storelist-lt"
    //                 }`}
    //               >
    //                 <div className="row">
    //                   <div className="col-2 text-center pic-placement">
    //                     <img
    //                       src={pic}
    //                       alt="storepic"
    //                       className="pic-store-item-disp"
    //                     />
    //                   </div>
    //                   <div className="col-8 text-left coop-item-row-pos">
    //                     <div className="row">
    //                       <div className="col-md-6 text-left coop-across-item-name">
    //                           {item.itemName}
    //                       </div>
    //                       <div className="col-md-6 text-left coop-across-coop-name">
    //                           <b>Store:</b> {item.coopStoreName}
    //                       </div>
    //                     </div>
    //                     <div className="row">
    //                       <div className="col">
    //                         {this.state.deviceSize === 'big' ? item.itemDescription.substring(0,80) + "..." : item.itemDescription.substring(0,30)}
    //                       </div>
    //                     </div>
    //                   </div>
    //                   <div className="col-2 text-center coop-item-open-btn-pos">
    //                     <button
    //                       onClick={() => this.handleItemOpen(item)}
    //                       className={
    //                         this.state.deviceSize === "big"
    //                           ? "btn_reg_50"
    //                           : "btn_reg_40"
    //                       }
    //                       style={{
    //                         cursor: this.state.disabled ? "default" : "pointer",
    //                       }}
    //                     >
    //                       {this.state.deviceSize === "big" ? "Open" : "O"}
    //                     </button>
    //                   </div>
    //                 </div>
    //               </div>
    //               {(bool1 = !bool1)}
    //             </div>
    //           );
    //         })}
    //       </div>
    //       {this.state.numberOfStores > 20 ? (
    //         <div className="store-counts text-center">
    //           Displayed {this.state.stores.length} stores out of{" "}
    //           {this.state.numberOfStores}{" "}
    //         </div>
    //       ) : null}
    //     </div>
    //   );
    // }

    let aStoreInfoPanel;
    if (this.state.showAStoreInfoFlag) {
      aStoreInfoPanel = (
        <div>
          <AStoreLanding
            returnToCaller={this.returnFromStoreInfo}
            communityId={this.props.communityId}
            community={this.props.community}
            store={this.state.store}
            caller="storeInfo"
          />
        </div>
      );
    }

    let theSearchPanel;
    if (this.state.showSearchFlag) {
      theSearchPanel = (
        <div>
          <SearchTheMarket
            returnToCaller={this.returnFromSearch}
            communityId={this.props.communityId}
          />
        </div>
      );
    }

    let catalogPanel;
    if (this.state.showCatalogFlag) {
      // if (this.state.showCatalogFlag && this.state.store) {
      console.log("this.state.store:", this.state.store);
      let coopStoreId = 0;
      if (this.state.store) coopStoreId = this.state.store.coopStoreId;
      catalogPanel = (
        <div>
          <ViewCatalog
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.props.community}
            coopStoreId={coopStoreId}
            store={this.state.store}
            returnToCaller={this.returnFromCatalog}
            role={this.props.role}
            authorization="cataloguser"
            authorizedFuncs={this.state.authorizedFuncs}
            cartOf={this.props.auth.user}
            caller="buyer"
          />
        </div>
      );
    }

    if (this.state.showItemsList) {
      console.log("Going to ViewCatalog ... from market for items across");
      catalogPanel = (
        <div>
          <ViewCatalog
            commName={this.props.commName}
            communityId={this.props.communityId}
            community={this.props.community}
            coopStoreId={0}
            store={null}
            returnToCaller={this.returnFromCatalog}
            role={this.props.role}
            authorization="cataloguser"
            authorizedFuncs={this.state.authorizedFuncs}
            cartOf={this.props.auth.user}
            caller="buyer"
            coopItemFilter={this.state.coopItemFilter}
          />
        </div>
      );
    }

    let outputPanel;
    if (this.state.showStoreList) {
      outputPanel = <div>{storeListPanel}</div>;
    }

    // if (this.state.showItemsList) {
    //   outputPanel = <div>{itemListPanel}</div>
    // }

    if (this.state.showAStoreInfoFlag) {
      outputPanel = <div>{aStoreInfoPanel}</div>;
    }

    if (this.state.showSearchFlag) {
      outputPanel = <div>{theSearchPanel}</div>;
    }

    if (this.state.showCatalogFlag || this.state.showItemsList) {
      outputPanel = <div>{catalogPanel}</div>;
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

Markets.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Markets);
