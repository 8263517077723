import React, { Component } from "react";

import axios from "axios";

import "./CustomersInvoice.css";

import InvoiceDetails from "../../reports/invoice/InvoiceDetails";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getCustomerInvoices = "/routes/invoice/getCustomerInvoices?";

class CustomersInvoice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      showInvoiceList: true,
      showInvoiceDetail: false,

      filterType: "all",

      invoiceList: [],
      invoiceIdForDetail: 0,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    await this.getInvoices();

    this.setState({
      deviceSize,
    });
  };

  getInvoices = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&baandaId=" +
      this.props.baandaId +
      "&coopStoreId=" +
      coopStoreId +
      "&filterType=" +
      this.state.filterType;
    let url = baandaServer + getCustomerInvoices + params;
    // console.log("url:", url);
    try {
      let inv = await axios.get(url);
      // console.log("inv:", inv);
      if (inv.data.status === "success") {
        this.setState({
          invoiceList: inv.data.Msg,
        });
      } else {
        this.setState({
          errMsg: inv.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getInvoices err:", err.messsage);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  handleFetchInvoices = async () => {
    // console.log("In handleFetchInvoices ...");
    await this.getInvoices();
    this.setState({
      showInvoiceDetail: false,
      showInvoiceList: true,
      showSearchPanel: false,
    });
  };

  commaFormattedCurrency = (number) => {
    // console.log("number:", number);

    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleInvoiceFilterType = async (e) => {
    await this.setState({
      filterType: e.target.value,
    });
    await this.getInvoices();
  };

  returnToReportList = () => {
    this.props.returnToCaller();
  };

  selectThisInvoiceToShow = async (obj) => {
    // console.log("obj:", obj);
    this.setState({
      invoiceIdForDetail: obj.invoiceId,
      showInvoiceDetail: true,
      showInvoiceList: false,
    });
  };

  returnFromInvDetails = async () => {
    this.setState({
      invoiceIdForDetail: 0,
      showInvoiceDetail: false,
      showInvoiceList: true,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('CustomerInvoice...');

    let exitButton = (
      <button
        onClick={this.returnToReportList}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let fetchButton;

    // fetchButton = (
    //   <button
    //     onClick={this.handleFetchInvoices}
    //     style={{ cursor: this.state.disabled ? "default" : "pointer" }}
    //     className="btn_reg_60"
    //   >
    //       {this.state.deviceSize === "big" ? "Search" : <i className="fas fa-search-plus" />} 
    //   </button>
    // );

    let invoiceTypePanel;
    invoiceTypePanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Filter by&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="all"
                checked={this.state.filterType === "all"}
                onChange={this.handleInvoiceFilterType}
              />{" "}
              All
            </label>
          </div>

          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="pending"
                checked={this.state.filterType === "pending"}
                onChange={this.handleInvoiceFilterType}
              />{" "}
              Pending
            </label>
          </div>
        </div>
      </div>
    );

    let showInvoiceList;
    let bool = true;
    if (this.state.showInvoiceList) {
      if (this.state.invoiceList.length > 0) {
        showInvoiceList = (
          <div>
            <div className="row sub-item-list-row">
              <div className="col-1 text-center cust-sub-item-list-head">Ops</div>
              <div className="col-9 text-left cust-sub-item-list-head">
                Date | invoiceId | Type | Amount
              </div>
              <div className="col-2 text-left cust-sub-item-list-head">
                # {this.state.invoiceList.length}
              </div>
            </div>
            <div className="fixedsize-cust-invoice-list">
              {this.state.invoiceList.map((obj, i) => {
                // console.log("690 obj:", obj);

                return (
                  <div key={i}>
                    <div
                      className={
                        bool
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-1 text-left ">
                        <button
                          className="btn-invoice-detail-open-xx"
                          type="button"
                          onClick={() => this.selectThisInvoiceToShow(obj)}
                          
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          ...
                        </button>
                      </div>

                      <div className="col-11 text-left cust-invoice-list-lines">
                        {obj.invoiceDate.substring(0, 10)} | {obj.invoiceId} |{" "}
                        {obj.invoiceCreationType} | ${" "}
                        {this.commaFormattedCurrency(
                          obj.financeBreakdown.totalInvoiceAmount
                        )}
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
            <div
              className={
                this.state.errFlag
                  ? "text-center sub-item-msg-err"
                  : "text-center sub-item-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        );
      } else {
        showInvoiceList = (
          <div className="fixedsize-cust-invoice-list">
            No invoice found with your search condition.
          </div>
        );
      }
    }

    let outputPanel;
    if (this.state.showInvoiceList) {
      outputPanel = (
        <div>
          <div className="cust-rep-inv-list">
            Invoices&nbsp;&nbsp;&nbsp; {exitButton} {fetchButton}
          </div>
          {invoiceTypePanel}
          {showInvoiceList}
        </div>
      );
    }

    if (this.state.showInvoiceDetail) {
      let store = null;
      if ( this.props.store ) store = this.props.store;
      outputPanel = (
        <div>
          <InvoiceDetails
            returnToCaller={this.returnFromInvDetails}
            communityId={this.props.communityId}
            invoiceId={this.state.invoiceIdForDetail}
            store={store}
          />
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

export default CustomersInvoice;
