import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./SendBmail.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getMembersOfCommunity = "/routes/dashboard/getMembersOfCommunity?";
const getCustomersOfCommunity = "/routes/dashboard/getCustomersOfCommunity?";
const saveSendMsgs = "/routes/interact/saveSendMsgs";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1143000000";

class SendBmail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      recipiantList: [],
      recpType: "community",

      confirmToSend: false,
      searchName: "",
      customerName: "",

      membersList: [],
      customerList: [],

      poolMsgId: 0,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    this.setState({
      deviceSize,
      recipiantList: this.props.recipiantList,
      poolMsgId: this.props.poolMsgId,
    });
  };

  openAlertModal = async () => {
    // console.log("in openAlertModal info:", readMeCode);
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  handleRecpType = async (e) => {
    await this.setState({
      recpType: e.target.value,
    });
  };

  returnToComposeBmail = (inp) => {
    // console.log("inp:", inp);
    let retobj = {
      ops: inp,
      recipiantList: this.state.recipiantList,
    };

    this.props.returnToCaller(retobj);
  };

  confirmToSend = async () => {
    await this.setState((prevstate) => ({
      confirmToSend: !prevstate.confirmToSend,
    }));
  };

  searchMembersInCommunity = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=0" +
      "&memberName=" +
      this.state.searchName;
    let url = baandaServer + getMembersOfCommunity + params;
    // console.log("url:", url);
    try {
      let memret = await axios.get(url);
      // console.log("memret.data:", memret.data);
      if (memret.data.status === "success") {
        if (memret.data.Msg.length > 0) {
          this.setState({
            membersList: memret.data.Msg,
            message: "",
            errFlag: false,
          });
        } else {
          this.setState({
            message: "No members found for the search condition.",
            errFlag: true,
          });
        }
      } else {
        this.setState({
          message: memret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  searchCustomerInCommunity = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&customerName=" +
      this.state.customerName;
    let url = baandaServer + getCustomersOfCommunity + params;
    // console.log("url:", url);
    try {
      let custret = await axios.get(url);
      // console.log("custret.data:", custret.data);
      if (custret.data.status === "success") {
        if (custret.data.Msg.length > 0) {
          this.setState({
            customerList: custret.data.Msg,
            message: "",
            errFlag: false,
          });
        } else {
          this.setState({
            message: "No customer found for the search condition.",
            errFlag: true,
          });
        }
      } else {
        this.setState({
          message: custret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  selectToSend = async (sel) => {
    let reclist = [...this.state.recipiantList];
    let itExists = false;
    reclist.forEach((obj) => {
      if (sel.baandaId === obj.baandaId) itExists = true;
    });
    if (!itExists) {
      let recobj = {
        baandaId: sel.baandaId,
        name: sel.name,
        email: sel.email,
        ccFlag: false,
      };
      reclist.push(recobj);
    }

    this.setState({
      recipiantList: reclist,
      confirmToSend: false,
    });
  };

  removeFromSent = (dsel) => {
    let recsel = [];
    this.state.recipiantList.forEach((obj) => {
      if (dsel.baandaId !== obj.baandaId) recsel.push(obj);
    });
    this.setState({
      recipiantList: recsel,
    });
  };

  CheckThisAsCopy = (cc) => {
    let recsel = [];
    this.state.recipiantList.forEach((obj) => {
      if (cc.baandaId === obj.baandaId) {
        obj.ccFlag = true;
      }
      recsel.push(obj);
    });
    this.setState({
      recipiantList: recsel,
    });
  };

  sendBmailsAndExit = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let inpData = {
      reqType: "sent", // send or draft
      poolMsgId: this.props.poolMsgId,
      authorBaandaId: this.props.auth.user.baandaId,
      sentByName: this.props.auth.user.name,
      communityId: this.props.communityId,
      coopStoreId,
      subject: this.props.subject,
      messageDoc: this.props.contentToSend,
      clientProgram: "SendBmail.js",
      clientFunction: "sendBmailsAndExit",
      prevSynapseId: 0,
      messageType: "converse",
      embeds: this.props.imagesUploaded,
      allRecipiants: this.state.recipiantList,
    };
    let url = baandaServer + saveSendMsgs;
    // console.log("url:", url, " inpData:", inpData);
    try {
      let retsend = await axios.post(url, inpData);
      // console.log("retsend:", retsend);
      if (retsend.data.status === "success") {
        this.returnToComposeBmail("sent");
      } else {
        this.setState({
          message: retsend.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("SendBmail...");

    let buttonPanelInfo = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let buttonCancelSend = (
      <button
        className="btn_back_main"
        type="button"
        onClick={() => this.returnToComposeBmail("cancel")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let buttonNameSearch = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.searchMembersInCommunity}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search" />
      </button>
    );

    let buttonCustomerNameSearch = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.searchCustomerInCommunity}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fa fa-search" />
      </button>
    );

    let send;
    if ( this.state.deviceSize === 'big') {
      send = "Send";
    } else {
      send = ""
    }

    let buttonSend = (
      <button
        className="btn_reg_70_bml"
        type="button"
        onClick={this.sendBmailsAndExit}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {send} <i className="fa fa-paper-plane" />
      </button>
    );

    let community, customer, confirm;
    if (this.state.deviceSize === "big") {
      community = "Community";
      customer = "Customer";
      confirm = "Confirm to send";
    } else {
      community = "Comm";
      customer = "Cust";
      confirm = "Confirm";
    }

    let selectRecpTypePanel;
    selectRecpTypePanel = (
      <div className="row name-panel-row type-confirm-row-pos">
        <div className="col-8 text-center radio-font-style new-test-size">
          <strong>Type&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-inline"
                type="radio"
                value="community"
                checked={this.state.recpType === "community"}
                onChange={this.handleRecpType}
              />{" "}
              {community}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-inline"
                type="radio"
                value="customer"
                checked={this.state.recpType === "customer"}
                onChange={this.handleRecpType}
              />{" "}
              {customer}
            </label>
          </div>
        </div>
        <div className="col-4 text-center bmail-confirm-send new-test-size">
          <Checkbox
            checked={this.state.confirmToSend}
            onChange={this.confirmToSend}
          />{" "}
          {confirm}
        </div>
      </div>
    );
    // selectRecpTypePanel = (
    //   <div className="row name-panel-row">
    //     {/* <div className="col-3 text-center radio-font-style">&nbsp;</div> */}

    //     <div className="col-9 text-center radio-font-style">
    //       <strong>To Type&nbsp; </strong>
    //       <div className="form-check form-check-inline-sb">
    //         <label className="form-check-label">
    //           <input
    //             className="form-check-input"
    //             type="radio"
    //             value="community"
    //             checked={this.state.recpType === "community"}
    //             onChange={this.handleRecpType}
    //           />{" "}
    //           Community
    //         </label>
    //       </div>

    //       <div className="form-check form-check-inline-sb">
    //         <label className="form-check-label">
    //           <input
    //             className="form-check-input"
    //             type="radio"
    //             value="customer"
    //             checked={this.state.recpType === "customer"}
    //             onChange={this.handleRecpType}
    //           />{" "}
    //           Customer
    //         </label>
    //       </div>
    //     </div>
    //     <div className="col-4 text-center bmail-confirm-send">
    //       <Checkbox
    //         checked={this.state.confirmToSend}
    //         onChange={this.confirmToSend}
    //       />{" "}
    //       Confirm to send
    //     </div>
    //   </div>
    // );

    let recipiantListPanel;
    let bool1 = true;
    recipiantListPanel = (
      <div>
        <div className="text-center select-recipiants-head">
          Selected Recipiants
        </div>
        <div className="fixedsize-selected-recipiant-box">
          {this.state.recipiantList.map((obj, i) => {
            return (
              <div key={i}>
                <div
                  className={
                    bool1 ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                  }
                >
                  <div className="col-4 text-center">
                    <button
                      className="btn_exit_main"
                      type="button"
                      onClick={() => this.removeFromSent(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      <i className="fa fa-trash" />
                    </button>
                    <button
                      className="btn_reg_40"
                      type="button"
                      onClick={() => this.CheckThisAsCopy(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      CC
                    </button>
                  </div>
                  <div
                    className={
                      obj.ccFlag
                        ? "col-8 text-left this-is-cc"
                        : "col-8 text-left this-is-not-cc"
                    }
                  >
                    {obj.name} ({obj.email}) {obj.ccFlag ? " (cc)" : null}
                  </div>
                </div>
                {(bool1 = !bool1)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let customersPanel;
    if (this.state.recpType === "customer") {
      let bool2 = true;
      customersPanel = (
        <div>
          {recipiantListPanel}
          <div className="text-center select-recipiants-head">
            Select Recipients from customers
          </div>
          <div className="fixedsize-to-select-from-list-box">
            {this.state.customerList.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool2
                        ? "row row-col-schdule-dr"
                        : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-2 text-center">
                      <button
                        className="btn_reg_40"
                        type="button"
                        onClick={() => this.selectToSend(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-check" />
                      </button>
                    </div>
                    <div className="col-10 text-left">
                      {obj.name} ({obj.email})
                    </div>
                  </div>
                  {(bool2 = !bool2)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let communityMembersPanel;
    if (this.state.recpType === "community") {
      let bool = true;
      communityMembersPanel = (
        <div>
          {recipiantListPanel}
          <div className="text-center select-recipiants-head">
            Select Recipients from members
          </div>
          <div className="fixedsize-to-select-from-list-box">
            {this.state.membersList.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-2 text-center">
                      <button
                        className="btn_reg_40"
                        type="button"
                        onClick={() => this.selectToSend(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-check" />
                      </button>
                    </div>
                    <div className="col-10 text-left">
                      {obj.name} ({obj.email})
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let selectFromCommPanel;
    if (this.state.recpType === "community") {
      selectFromCommPanel = (
        <div>
          <div className="row">
            <div className="col-2 text-right bmail-search-name-lbl">Name</div>
            <div className="col-7 text-right ">
              <input
                name="searchName"
                type="text"
                value={this.state.searchName}
                onChange={this.onChange}
                maxLength="60"
                className="bmail-search-name-txt"
                placeholder="Part/full name to search"
              />
            </div>
            <div className="col-2 text-left">{buttonNameSearch}</div>
          </div>
          {communityMembersPanel}
        </div>
      );
    }

    let selectFromCommCustomerPanel;
    if (this.state.recpType === "customer") {
      selectFromCommCustomerPanel = (
        <div>
          <div className="row">
            <div className="col-3 text-right search-name-lbl">Name</div>
            <div className="col-6 text-right search-name-lbl">
              <input
                name="customerName"
                type="text"
                value={this.state.customerName}
                onChange={this.onChange}
                maxLength="60"
                className="bmail-search-name-txt"
                placeholder="Part/full name to search"
              />
            </div>
            <div className="col-2 text-left">{buttonCustomerNameSearch}</div>
          </div>
          {customersPanel}
        </div>
      );
    }

    let compheadr;
    if ( this.state.deviceSize === 'big') {
      compheadr = "Compose & Send";
    } else {
      compheadr = "Compose";
    }

    let outputPanel;
    outputPanel = (
      <div className="bmail-send-mgmt-box text-center">
        <div className="row">
          {this.state.recipiantList.length > 0 ? (
            <div className="col-3 bmail-confirm-send">
              {/* <Checkbox
                checked={this.state.confirmToSend}
                onChange={this.confirmToSend}
              />{" "}
              Confirm to send */}
              &nbsp;
            </div>
          ) : (
            <div className="col-3 bmail-confirm-send">&nbsp;</div>
          )}

          <div className="col-4 text-center bmail-comp-send-head">
            {compheadr}
          </div>
          <div className="col-5 text-left">
            {this.state.recipiantList.length > 0 && this.state.confirmToSend
              ? buttonSend
              : null}
            {buttonPanelInfo}
            {buttonCancelSend}
          </div>
        </div>
        {selectRecpTypePanel}
        {selectFromCommPanel}
        {selectFromCommCustomerPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center recp-spec-message-err"
              : "text-center recp-spec-message"
          }
        >
          {this.state.message}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

SendBmail.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SendBmail));
