import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

// import axios from "axios";

import ModalContainer from "../../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../../actions/modalActions";
import "../../../../../../modal/css/localModal.css";
import "../../../../../../modal/css/template.css";

import "./ViewEvent.css";

import BookAnEvent from "../bookbuy/BookAnEvent";
import axios from "axios";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const isEmailInGroup = "/routes/group/isEmailInGroup?";

const yourtimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const yourTimeOffset = new Date().getTimezoneOffset() / 60;

class ViewEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",
      // digitalAssetExist: false,
      allImageVideoPdfExist: false,

      theEvent: null,
      eventId: 0,

      theEvent1: null,
      pdfFileName: "",

      videoFileType: "",

      showImage: false,
      showVideo: false,

      showMoreSchedule: false,
      moreDateString: "",
      moreStartTimeString: "",
      moreDuration: "",
      moreServers: "",

      isBlocked: false,
      isCanceled: false,
      isIncluded: false,
      participants: "",

      isAServer: false, // when true, meaning this is handling eventType===service and is a member of serviceTeam
      showEngageButtonFlag: false,
      counter: 0,

      showPurchasePanelFlag: false,
      showEventFlag: true,
      cartOf: null,

      showRespondFlag: false,
      synapseId: 0,
      timeDifference: 0,
      status: "",
      showTheNotifyButton: true,

      showInviteeListFlag: false,
      isAttending: false,

      attendeeSchedule: "",
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let cartOf = null;
    if (this.props.cartOf) cartOf = this.props.cartOf;
    else
      cartOf = {
        baandaId: this.props.auth.user.baandaId,
        cell: this.props.auth.user.cell.number,
        email: this.props.auth.user.email,
        name: this.props.auth.user.name,
      };
    let showImage = false,
      showPdf = false,
      showVideo = false;
    let pdfFileName = "";

    let videoFileType = "";
    if (this.props.theEvent.fileUploads.length > 0) {
      this.props.theEvent.fileUploads.forEach((obj) => {
        if (obj.type === "image") {
          showImage = true;
        }
        if (obj.type === "video") {
          if (!showImage) showVideo = true; // if no image then default to video
        }
      });
    }

    let status = "";
    if (this.props.status === "accept") status = "Attending";
    else if (this.props.status === "declined") status = "Declined";
    else status = this.props.status;
    if (!status) status = "";
    if (status === "replied") {
      this.props.theEvent.attendees.forEach((att) => {
        if (att.baandaId === this.props.auth.user.baandaId)
          status = "Attending";
      });
    }

    let synapseId = 0;
    if (this.props.calledFrom === "bmail") synapseId = this.props.synapseId;

    // console.log(
    //   "2. componentDidMount this.props.theEvent:",
    //   this.props.theEvent
    // );

    let timeDifference =
      this.props.theEvent.eventOnPublished.publisherTimeOffset - yourTimeOffset;
    if (!timeDifference) timeDifference = 0;

    let showEngageButtonFlag = false;
    if (this.props.theEvent.hostBaandaId !== this.props.auth.user.baandaId)
      showEngageButtonFlag = true;

    let showRespondFlag = false;
    // console.log('this.props.theEvent.audienceType:', this.props.theEvent.audienceType)
    if (this.props.theEvent.audienceType === "groups") {
      // console.log('In here  1111')
      let resp = await this.isEmailInTheGroup(
        this.props.theEvent.groups[0].groupId,
        this.props.auth.user.email
      );
      if (resp.status === "success") {
        if (resp.IsIn) showRespondFlag = true;
      }
    } else {
      this.props.theEvent.sendInvitesTo.forEach((elm) => {
        if (elm.email === this.props.auth.user.email) {
          if (
            this.props.status === "invited" ||
            this.props.status === "new" ||
            this.props.status === "read"
          )
            showRespondFlag = true;
        }
      });
    }

    if (this.props.theEvent.eventStatus === "cancel") showRespondFlag = false;

    await this.checkIfNotifyNeeded(this.props.theEvent);

    let showInviteeListFlag = false;
    let showInviteeList = "";
    // console.log(
    //   "this.props.theEvent.audienceType:",
    //   this.props.theEvent.audienceType
    // );
    if (this.props.theEvent.audienceType === "groups") {
      if (this.props.theEvent.groups.length > 0) {
        showInviteeListFlag = true;
        showInviteeList =
          "Group - Name: " +
          this.props.theEvent.groups[0].groupName +
          " (" +
          this.props.theEvent.groups[0].description +
          ")";
      } else {
        showInviteeList =
          "Please select your audience (group) before publishing.";
      }
    } else {
      if (this.props.theEvent.sendInvitesTo.length > 0) {
        this.props.theEvent.sendInvitesTo.forEach((ele) => {
          showInviteeList += ele.name + "(" + ele.email + "); ";
        });
      } else {
        showInviteeList = "Please select your audience before publishing.";
      }
    }

    let isAttending = false;
    this.props.theEvent.attendees.forEach((att) => {
      if (this.props.auth.user.baandaId === att.baandaId) {
        showRespondFlag = false;
        status = "Attending";
      }
    });

    // list scheduled to attend
    let schduleToAttend = "";
    if (this.props.todaysEvents && this.props.todaysEvents.event) {
      this.props.todaysEvents.event.events.forEach((evt) => {
        let start = this.formatTime(evt.startTime);
        let end = this.formatTime(evt.endTime);

        schduleToAttend +=
          start.hour +
          ":" +
          start.min +
          " " +
          start.ampm +
          " -to- " +
          end.hour +
          ":" +
          end.min +
          " " +
          end.ampm +
          "; ";
      });
    }

    await this.setState({
      deviceSize,
      theEvent: this.props.theEvent,
      eventId: this.props.theEvent.eventId,
      showEventFlag: true,
      showVideo,
      showImage,
      showPdf,
      videoFileType,
      pdfFileName,
      cartOf,
      showEngageButtonFlag,
      showRespondFlag,
      synapseId,
      timeDifference,
      status,
      showInviteeListFlag,
      showInviteeList,
      isAttending,
      attendeeSchedule: schduleToAttend,
    });
  };

  formatTime = (minutes) => {
    let bhr = Math.floor(minutes / 60);
    let bmn = minutes - bhr * 60;
    let mn = "";
    if (bmn < 10) mn = "0" + bmn;
    else mn = bmn;
    let hr = 0,
      ap = "am";
    if (bhr < 12) {
      hr = bhr;
    } else {
      hr = 24 - bhr;
      ap = "pm";
    }

    let timeIs = {
      hour: hr,
      min: mn,
      ampm: ap,
    };

    return timeIs;
  };

  isEmailInTheGroup = async (groupId, email) => {
    let params = "groupId=" + groupId + "&email=" + email;
    let url = baandaServer + isEmailInGroup + params;
    let answer;
    try {
      let resp = await axios.get(url);
      if (resp.data.status === "success") {
        answer = { status: "success", IsIn: resp.data.Msg };
      } else {
        answer = { status: "error", IsIn: resp.data.Msg };
      }
    } catch (err) {
      answer = { status: "error", IsIn: err.message };
    }

    return answer;
  };

  checkIfNotifyNeeded = async (selEvent) => {
    // console.log('checkIfNotifyNeeded selEvent.eventType:', selEvent.eventType);
    let showTheNotifyButton = true;
    if (selEvent.eventType === "service") {
      if (selEvent.services) {
        // console.log('selEvent.services.serviceFeeType:', selEvent.services.serviceFeeType);
        if (selEvent.services.serviceFeeType === "catalog")
          showTheNotifyButton = false;
      }
    }
    // console.log('showTheNotifyButton:', showTheNotifyButton);
    this.setState({
      showTheNotifyButton,
    });
  };

  handleShowPdf = async () => {
    await this.openPdfModal();
  };

  openPdfModal = async () => {
    await this.setState({
      showImageFlag: false,
      // showVideo: false,
    });

    let pdfFileLocation, pdfFileName;
    this.props.theEvent.fileUploads.forEach((obj) => {
      if (obj.type === "pdf") {
        pdfFileLocation = obj.location;
        pdfFileName = obj.key.split("/").pop();
      }
    });

    let msg = {
      Header: "Event Document",
      Body: {
        s3Url: pdfFileLocation,
        // fileName: this.state.fileNameToDisplay,
        fileName: pdfFileName,

        footnote: "Please wait to load pdf from cloud.",
      },
      Footer: "Please wait to load pdf from cloud.",
    };

    this.props.showModal(
      {
        open: true,
        title: "Alert - Start Here Header",
        message: msg,
        deviceSize: this.state.deviceSize,
        closeModal: this.closeModal,
      },
      "showPdf"
    );
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleShowImage = async () => {
    // console.log("handleShowImage");
    await this.setState({
      showImage: true,
      showVideo: false,
    });
  };

  handleShowVideo = async () => {
    // console.log("handleShowVideo ");
    await this.setState({
      showImage: false,
      showVideo: true,
    });
  };

  showServerList = () => {
    let str = "";
    this.props.theEvent.services.serviceTeam.forEach((obj) => {
      // str = str + obj.name + " (" + obj.email + " " + obj.cell + "); ";
      str = str + obj.name + "; ";
    });
    return str;
  };

  handleShowGroupMembers = async () => {
    alert("TBD show group members");
  };

  showMoreSchedulePop = async (obj) => {
    // console.log("obj:", obj);
    let servers = "";
    if (obj.serviceProviders.length === 0) {
      servers = "Servers not assigned.";
    } else {
      obj.serviceProviders.forEach((elm) => {
        servers =
          servers + elm.name + " (" + elm.email + " " + elm.cell + "); ";
      });
    }

    let duration = "";
    let dur = parseFloat(obj.duration);
    if (dur < 60) {
      duration = dur + " minutes";
    } else {
      let dd = duration % 60;
      duration = (dur - dd) / 60 + " hours";
      if (dd > 0) duration = duration + " " + dd + " minutes";
    }
    // console.log("duration:", duration);

    let participants = "";
    if (this.props.theEvent.eventStatus === "prepmode") {
      participants = obj.participants[0].name;
    } else {
      let theHost = false; 
      obj.participants.forEach((elm) => {
        if ( this.props.caller === 'personalcalendar') {
          if ( this.props.auth.user.email === this.props.theEvent.hostEmail) {
            participants += elm.name + " (" + elm.email + ", " + elm.cell + "); ";
            theHost = true;
          } else {
            if ( this.props.auth.user.email === elm.email) {
              participants += elm.name + " (" + elm.email + ", " + elm.cell + "); ";
            }
          }
        } else {
          participants += elm.name + " (" + elm.email + ", " + elm.cell + "); ";
        }
      });
      if ( this.props.caller === 'personalcalendar' && !theHost) {
        participants += " [>> Only event host can see all participants]"
      }
    }

    // console.log("participants:", participants);

    await this.setState({
      moreDateString: obj.shiftDateString,
      moreStartTimeString: obj.startTimeStr,
      moreServers: servers,
      moreDuration: duration,
      isBlocked: obj.blocked,
      isCanceled: obj.canceled,
      isIncluded: obj.isIncluded,
      participants,
      showMoreSchedule: true,
    });
  };

  closeShowMore = async () => {
    await this.setState({
      showMoreSchedule: false,
    });
  };

  handleUsersEnagement = async () => {
    // alert("This will show the same process as that of an invite response");
    await this.setState({
      showPurchasePanelFlag: true,
      showEventFlag: false,
    });
  };

  returnFromPurchase = async (msg) => {
    // console.log("msg:", msg);
    if (msg === "viewevent") {
      await this.setState({
        showPurchasePanelFlag: false,
        showEventFlag: true,
      });
    } else {
      await this.setState({
        showPurchasePanelFlag: false,
        showEventFlag: false,
      });
      this.props.returnToCaller();
    }
  };

  capitalizeFirstLetter = (string) => {
    // console.log("string:", string);
    let str = "";
    if (!string || string !== "")
      str = string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    // console.log("str:", str);
    return str;
  };

  // capitalizeFirstLetter = (string) => {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ViewEvent...");

    // ============================================
    // Check to see if showEngageButtonFlag is true
    let showEngageButtonFlag = false;
    // let yourResponse = "";

    if (this.props.theEvent.exposureType === "public") {
      if (this.props.theEvent.eventType === "nonservice") {
        if (this.props.theEvent.hostBaandaId !== this.props.auth.user.baandaId)
          showEngageButtonFlag = true;
      } else showEngageButtonFlag = true;
    } else {
      if (this.props.caller === "catalog") {
        showEngageButtonFlag = true;
      } else {
        this.props.theEvent.sendInvitesTo.forEach((esi) => {
          if (esi.baandaId === this.props.auth.user.baandaId) {
            showEngageButtonFlag = true;
            // yourResponse = esi.response;
          }
        });
      }
    }

    if (this.props.caller === "extinvitee") {
      if (this.state.showEngageButtonFlag) showEngageButtonFlag = true;
    }

    // ============================================
    let locPubShifts = [];
    let atLeastOneNA = false;
    let showSchedules = [];
    let prepro = [];
    if (this.props.theEvent.eventType === "nonservice") {
      // Set this up for non-service events
      prepro = JSON.parse(JSON.stringify(this.props.theEvent.eventOn));
    } else {
      if (this.props.theEvent.eventStatus === "published") {
        prepro = [...this.props.theEvent.services.publishedShifts];
      } else {
        prepro = [...this.props.theEvent.services.prePubShifts];
      }
      // console.log('prepro:', prepro);

      prepro.forEach((obj) => {
        // console.log("521 obj.shiftDateString:", obj.shiftDateString);
        let iAmAttending = false;
        let participants = [];
        this.props.theEvent.attendees.forEach((atn) => {
          if (this.props.auth.user.baandaId === atn.baandaId) {
            atn.schedules.forEach((sch) => {
              if (sch.startTime === obj.startTime) iAmAttending = true;
            });
          }
          atn.schedules.forEach((sch) => {
            if (sch.startTime === obj.startTime) {
              let partObj = {
                name: atn.name,
                email: atn.email,
                cell: atn.cell,
                baandaId: atn.baandaId,
              };
              participants.push(partObj);
            }
          });
        });
        if (participants.length === 0) {
          participants.push({
            name: "No participants yet.",
            email: "none",
            cell: "none",
            baandaId: 0,
          });
        }
        let readjObj = {
          seqNo: obj.seqNo,
          shiftDate: obj.shiftDate,
          shiftDateString: obj.shiftDateString,
          startTime: obj.startTime,
          startTimeStr: obj.startTimeStr,
          duration: obj.duration,
          comment: obj.comment,
          serviceProviders: obj.serviceProviders,
          participants,
          blocked: false,
          isFull: false,
          canceled: false,
          iAmAttending,
        };
        showSchedules.push(readjObj);
      });
    }

    // console.log("showSchedules:", showSchedules);

    let isAServer = false;
    if (this.props.theEvent.eventType === "service") {
      this.props.theEvent.services.serviceTeam.forEach((stm) => {
        if (stm.baandaId === this.props.auth.user.baandaId) isAServer = true;
      });
    }
    // console.log('showSchedules:', showSchedules);
    showSchedules.forEach((obj) => {
      let isIncluded = false;
      obj.serviceProviders.forEach((elm) => {
        if (
          parseFloat(elm.baandaId) === parseFloat(this.props.auth.user.baandaId)
        )
          isIncluded = true;
      });

      if (!isIncluded) atLeastOneNA = true;
      let shfObj = {
        blocked: obj.blocked,
        canceled: obj.canceled,
        comment: obj.comment,
        duration: obj.duration,
        isFull: obj.isFull,
        seqNo: obj.seqNo,
        serviceProviders: obj.serviceProviders,
        shiftDate: obj.shiftDate,
        shiftDateString: obj.shiftDateString,
        startTime: obj.startTime,
        startTimeStr: obj.startTimeStr,
        participants: obj.participants,
        isIncluded,
        iAmAttending: obj.iAmAttending,
      };
      locPubShifts.push(shfObj);
    });

    let daysToShow = [];
    if (this.props.caller && this.props.caller === "personalcalendar") {
      locPubShifts.forEach((lps) => {
        // console.log('lps:', lps);
        if (lps.shiftDateString === this.props.today) daysToShow.push(lps);
      });
    } else {
      daysToShow = [...locPubShifts];
    }

    let publishedProviders = [...daysToShow];
    // console.log('publishedProviders:', publishedProviders);
    // =============================================
    let inviteResponseFlag = false;
    if (this.props.theEvent.hostBaandaId === this.props.auth.user.baandaId)
      inviteResponseFlag = true;
    // =============================================

    let eventType = this.props.theEvent.eventType;
    let audienceType = this.props.theEvent.audienceType;

    let evn = this.props.theEvent;
    // let digitalAssetExist = false;

    let imageFileLocation, videoFileLocation; //, pdfFileLocation;
    let imageExist = false,
      videoExist = false,
      pdfExist = false;
    evn.fileUploads.forEach((obj) => {
      // digitalAssetExist = true;
      if (obj.type === "image") {
        imageFileLocation = obj.location;
        imageExist = true;
      }
      if (obj.type === "video") {
        videoFileLocation = obj.location;
        videoExist = true;
      }
      if (obj.type === "pdf") {
        // pdfFileLocation = obj.location;
        pdfExist = true;
      }
    });

    let videoFileType;
    if (videoExist)
      videoFileType = "video/" + videoFileLocation.split(".").pop();

    let imagePanel;
    if (imageFileLocation) {
      imagePanel = (
        <img src={imageFileLocation} alt="pic" className="event-showImage" />
      );
    }

    let videoPanel;
    if (videoFileLocation) {
      videoPanel = (
        <div className="event-view-video-place">
          <video className="video-event-size-for-card" controls>
            <source src={videoFileLocation} type={videoFileType} />
          </video>
        </div>
      );
    }

    let imageBtnPanel;
    if (imageExist) {
      imageBtnPanel = (
        <button
          className="ve-btn-image"
          type="button"
          onClick={this.handleShowImage}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Image
        </button>
      );
    }

    let pdfBtnPanel;
    if (pdfExist) {
      pdfBtnPanel = (
        <button
          className="ve-btn-pdf"
          type="button"
          onClick={this.handleShowPdf}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Pdf
        </button>
      );
    }

    let showGroupmembersButton;
    if (this.state.showVideo && imageExist) {
      showGroupmembersButton = (
        <button
          className="ve-btn-image"
          type="button"
          onClick={this.handleShowGroupMembers}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          ...
        </button>
      );
    }

    let videoBtnPanel;
    if (this.state.showImage && videoExist) {
      videoBtnPanel = (
        <button
          className="ve-btn-video"
          type="button"
          onClick={this.handleShowVideo}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Video
        </button>
      );
    }

    let digitalPanel;

    if (evn.fileUploads.length > 0) {
      if (this.state.showImage) {
        digitalPanel = (
          <div>
            <div className="row">
              <div className="col text-center event-image-col-pos">
                {imagePanel}
              </div>
            </div>
            <div className="row pv-btn-placement">
              <div className="col text-center">
                {videoExist ? videoBtnPanel : null}
                {pdfExist ? pdfBtnPanel : null}
              </div>
            </div>
          </div>
        );
      }
      if (this.state.showVideo) {
        digitalPanel = (
          <div>
            <div className="row">
              <div className="col text-center">{videoPanel}</div>
            </div>
            <div className="row pi-btn-placement">
              <div className="col text-center">
                {imageExist ? imageBtnPanel : null}
                {pdfExist ? pdfBtnPanel : null}
              </div>
            </div>
          </div>
        );
      }
    } else {
      digitalPanel = (
        <div className="text-center">No image, video or pdf attached.</div>
      );
    }

    let theReasonPanel;
    if (this.props.theEvent && this.props.theEvent.eventStatus === "canceled") {
      theReasonPanel = (
        <div className="text-center event-canceled-reason">
          The event has been canceled. {this.props.theEvent.theReason}
        </div>
      );
    }

    let describePanel;
    if (evn.fileUploads.length > 0) {
      describePanel = (
        <div className="row row-position">
          <div className="col-md-6 text-left">
            <div className="event-description">Description:</div>
            <p align="justify" className="attendee-direction">
              {evn.description}
            </p>
          </div>
          <div className="col-md-6">{digitalPanel}</div>
        </div>
      );
    } else {
      describePanel = (
        <div className="row row-position">
          <div className="col text-left attendee-direction-head">
            <div className="event-description">Description:</div>
            <p align="justify" className="attendee-direction">
              {evn.description}
            </p>
          </div>
        </div>
      );
    }

    let peopleInvitedPanel;
    if (this.state.showTheNotifyButton) {
      peopleInvitedPanel = (
        <div>
          <p align="justify" className="invitee-list">
            <b>Invitee List</b>
          </p>
          <div className="event-invitee-box text-left">
            {this.state.showInviteeList}
          </div>
        </div>
      );
    } else {
      peopleInvitedPanel = (
        <div>
          <p align="justify" className="invitee-list">
            <b>Invitee List</b>
          </p>
          <div className="event-invitee-box text-center">
            Per your specification, this is to be purchased via catalog only.
          </div>
        </div>
      );
    }

    let attendeePanel;
    let attendees = "";
    if (inviteResponseFlag) {
      if (evn && evn.attendees.length > 0) {
        evn.attendees.forEach((ele) => {
          let payStatus = "";
          if (evn.priceToAttend > 0) {
            if (ele.payStatus === "paid") payStatus = " - Paid";
            else payStatus = " - To Pay";
          }
          attendees += ele.name + "(" + ele.email + payStatus + "); ";
        });
      }

      attendeePanel = (
        <div>
          <p align="justify" className="invitee-list">
            <b>Attendees</b>
          </p>
          <div className="event-attendee-box text-left">{attendees}</div>
        </div>
      );
    }

    let eventDateTimePanel;
    if (evn && evn.eventType === "nonservice") {
      if (evn.eventOn.days.length > 0) {
        let dateString = "";
        if (evn.eventOnPublished.days.length > 0) {
          evn.eventOnPublished.days.forEach((obj) => {
            dateString = dateString + obj.substring(0, 10) + ", ";
          });
        } else {
          evn.eventOn.daysString.forEach((obj) => {
            dateString = dateString + obj + ", ";
          });
        }

        eventDateTimePanel = (
          <div className="event-view-specs">
            <p align="justify" className="event-date-head">
              <b>Hosts's Date(s), Time, and Fee</b>:
            </p>
            <p align="justify" className="event-on-dates">
              &nbsp;{dateString}
            </p>
            <p align="justify" className="event-on-time">
              <b>At Time</b>:&nbsp;{evn.eventOn.timeRange.fromHour}:
              {evn.eventOn.timeRange.fromMinute}&nbsp;
              {evn.eventOn.timeRange.fromAmPm}
              &nbsp;&nbsp;For:&nbsp;{evn.eventOn.timeRange.durationHours}
              &nbsp;Hours
              {evn.eventOn.timeRange.durationMinutes === 0
                ? null
                : evn.eventOn.timeRange.durationMinutes + " Minutes"}
              &nbsp;&nbsp;&nbsp;<b>Fee :</b>&nbsp;
              {evn.priceToAttend === 0
                ? "Free"
                : "$" + this.commaFormattedCurrency(evn.priceToAttend)}
              {evn.priceToAttendAtGate === 0
                ? null
                : " @Gate $" +
                  this.commaFormattedCurrency(evn.priceToAttendAtGate)}
            </p>
            <div className="text-left host-name-email">
              <b>Attendence Type:</b>:{" "}
              {this.capitalizeFirstLetter(evn.eventOnPublished.attendanceType)}
            </div>
            <div className="text-left host-name-email">
              <b>Timezone - Host: </b>
              {evn.eventOnPublished.publisherTimeZone}
            </div>
            <div className="text-left host-name-email">
              <b>Timezone - You: </b>
              {yourtimezone}
            </div>
            {this.state.timeDifference > 0 ? (
              <div className="text-left host-name-email">
                <b>Your schedule is ahead by {this.state.timeDifference} hrs</b>
              </div>
            ) : (
              <div className="text-left host-name-email">
                {this.state.timeDifference === 0 ? (
                  ""
                ) : (
                  <b>
                    Your schedule is behind by + {this.state.timeDifference} +
                    'hrs'{" "}
                  </b>
                )}
              </div>
            )}

            <div className="text-left host-name-email">
              <b>Host</b>:&nbsp;{evn.hostName}&nbsp;({evn.hostEmail})
            </div>
            {evn.priceToAttend > 0 ? (
              <div className="text-left host-name-email">
                <b>Refund Msg</b>:&nbsp;
                {evn.refundMessage !== ""
                  ? evn.refundMessage
                  : "No refund message provided."}
              </div>
            ) : null}
          </div>
        );
      } else {
        eventDateTimePanel = (
          <div className="text-center event-schedule-not-done">
            Warning: Event has not been schedulet yet.
          </div>
        );
      }
    } else {
      if (evn.services.prePubShifts.length === 0) {
        eventDateTimePanel = (
          <div className="text-left event-schedule-not-done">
            Warning: Event services has not been scheduled or assigned yet.
          </div>
        );
      } else {
        this.state.attendeeSchedule === ""
          ? (eventDateTimePanel = (
              <div className="text-left event-schedule-done">
                <b>Schedules</b>: Please check the service details and schedules
                in the section below.
              </div>
            ))
          : (eventDateTimePanel = (
              <div className="text-left event-schedule-done">
                <b>Schedules</b>: {this.state.attendeeSchedule}
              </div>
            ));
      }
    }

    let contactPanel;
    if (evn.contact.name !== "") {
      contactPanel = (
        <div className="event-contact-block">
          <div className="contact-header text-left">Contact:</div>
          <div className="contact-name-cal-view text-left">
            <b>Name</b>:&nbsp;{evn.contact.name};
          </div>
          <div className="contact-name-cal-view text-left">
            <b>Email</b>:&nbsp;
            {evn.contact.email}
            ;&nbsp;<b>Cell</b>:&nbsp;{evn.contact.phone}
          </div>

          {evn.contact.address.line1 === "" ? null : (
            <div className="contact-name-cal-view text-left">
              <b>Address</b>:&nbsp;{evn.contact.address.line1}&nbsp;
              {evn.contact.address.line2},&nbsp;
              {evn.contact.address.city},&nbsp;{evn.contact.address.state}&nbsp;
              {evn.contact.address.zip}
            </div>
          )}
        </div>
      );
    } else {
      contactPanel = (
        <div className="event-contact-block">
          <div className="contact-header text-left">Contact:</div>
          <div align="justify" className="contact-name-cal-view">
            Name:&nbsp;<b>{this.props.auth.user.name}</b>
            &nbsp;|&nbsp;Email:&nbsp;
            <b>{this.props.auth.user.email}</b>
          </div>
        </div>
      );
    }

    let attendeeDirectionPanel;
    if (evn.specialInstruction !== "") {
      attendeeDirectionPanel = (
        <div className="text-left">
          <p align="justify" className="text-left attendee-direction-head">
            <b>Direction:</b>&nbsp;{evn.specialInstruction}
          </p>
        </div>
      );
    }

    let intentSuccessPanel;
    intentSuccessPanel = (
      <div>
        <div className="row">
          {evn.intent === "" ? (
            <div className="col-md-6">&nbsp;</div>
          ) : (
            <div className="col-md-6 text-left pub-info">
              <b>Intent</b>:&nbsp;{evn.intent}
            </div>
          )}
          {evn.successCriteria === "" ? (
            <div className="col-md-6">&nbsp;</div>
          ) : (
            <div className="col-md-6 text-left pub-info">
              <b>Success Criteria</b>:&nbsp;{evn.successCriteria}
            </div>
          )}
        </div>
      </div>
    );

    let messageDispPanel;

    let prePublishInfoPanel;
    // if (this.props.caller === "eventcreator") {
    if (evn.hostBaandaId === this.props.auth.user.baandaId) {
      prePublishInfoPanel = (
        <div>
          <hr />
          <p align="justify" className="pub-info">
            Pre Publication event state information:
          </p>
          <div className="row">
            <div className="col-md-6 text-left publist-text">
              Invite Via:&nbsp;
              {!evn.inviteMedium.bmail ? null : "Via Bmails."}
              {!evn.inviteMedium.text ? null : "Via Text (SMS/MMS)."}
              {evn.inviteMedium.email === "noemail"
                ? "No email will be sent."
                : null}
              {evn.inviteMedium.email === "emailall"
                ? "Email will be sent to all."
                : null}
              {evn.inviteMedium.email === "emailnonmembers"
                ? "Email will be sent to non-members."
                : null}
            </div>
          </div>
          <div className="row">
            {this.props.theEvent ? (
              <div className="col-md-6 event-Type-service-non text-left">
                Event Type:&nbsp;
                <font color="#2c7514">
                  {this.props.theEvent.eventType === "service"
                    ? "Service"
                    : "Event (non-service)"}
                </font>
              </div>
            ) : (
              <div className="col-md-6">&nbsp;</div>
            )}

            <div className="col-md-6 text-left publist-text">
              {eventType === "service"
                ? "Note: Servers will be notified to check their personal calendars uppon publishing."
                : null}
            </div>
          </div>
          {audienceType === "none" ? null : messageDispPanel}
        </div>
      );
    }

    let showMoreOfSchedulePanel;
    if (this.state.showMoreSchedule) {
      if ( this.props.caller === "personalcalendar") {
        showMoreOfSchedulePanel = (
      
         <div 
            className="show-more-of-schedule-pop-personal" 
          > 
            <div className="text-center cal-pop-more">
              Scheduling Details &nbsp;{" "}
              <button
                className="btn_back_main"
                type="button"
                onClick={this.closeShowMore}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                <i className="fas fa-step-backward" />
              </button>
            </div>
            <div className="row">
              <div className="col-3 text-right cal-review-pop-lbl">Date:</div>
              <div className="col-9 text-left cal-review-pop-txt">
                {this.state.moreDateString}
              </div>
            </div>
            <div className="row">
              <div className="col-3 text-right cal-review-pop-lbl">
                Start Time
              </div>
              <div className="col-9 text-left cal-review-pop-txt">
                {this.state.moreStartTimeString}
              </div>
            </div>
            <div className="row">
              <div className="col-3 text-right cal-review-pop-lbl">Duration</div>
              <div className="col-9 text-left cal-review-pop-txt">
                {this.state.moreDuration}
              </div>
            </div>
  
            <div className="text-center cal-review-pop-lbl">Servers</div>
            <div className="text-left cal-review-server-box">
              {this.state.moreServers}
            </div>
            <div className="row">
              <div className="col-3 text-right cal-review-pop-lbl">Status</div>
              <div className="col-9 text-left cal-review-pop-txt">
                {this.state.isCanceled
                  ? "Event has been canceled."
                  : "Event is active."}
              </div>
            </div>
            <div className="row">
              <div className="col-3 text-right cal-review-pop-lbl">
                Involvement
              </div>
              <div className="col-9 text-left cal-review-pop-txt">
                {this.state.isIncluded
                  ? "You are the host."
                  : "Host's name is " + this.props.theEvent.hostName}
              </div>
            </div>
            <div className="text-center cal-review-pop-lbl">Participants</div>
            <div className="text-left cal-review-server-box">
              {this.state.participants}
            </div>
          </div>
        );
        

      } else {
        showMoreOfSchedulePanel = (
          // <div className="show-more-of-schedule-pop ">
         <div 
            className={
              this.props.caller === "eventview"
                ? "show-more-of-schedule-pop-comm"
                : "show-more-of-schedule-pop"
            }
          > 
            <div className="text-center cal-pop-more">
              Scheduling Details &nbsp;{" "}
              <button
                className="btn_back_main"
                type="button"
                onClick={this.closeShowMore}
                style={{
                  cursor: this.state.disabled ? "default" : "pointer",
                }}
              >
                <i className="fas fa-step-backward" />
              </button>
            </div>
            <div className="row">
              <div className="col-3 text-right cal-review-pop-lbl">Date:</div>
              <div className="col-9 text-left cal-review-pop-txt">
                {this.state.moreDateString}
              </div>
            </div>
            <div className="row">
              <div className="col-3 text-right cal-review-pop-lbl">
                Start Time
              </div>
              <div className="col-9 text-left cal-review-pop-txt">
                {this.state.moreStartTimeString}
              </div>
            </div>
            <div className="row">
              <div className="col-3 text-right cal-review-pop-lbl">Duration</div>
              <div className="col-9 text-left cal-review-pop-txt">
                {this.state.moreDuration}
              </div>
            </div>
  
            <div className="text-center cal-review-pop-lbl">Servers</div>
            <div className="text-left cal-review-server-box">
              {this.state.moreServers}
            </div>
            <div className="row">
              <div className="col-3 text-right cal-review-pop-lbl">Status</div>
              <div className="col-9 text-left cal-review-pop-txt">
                {this.state.isCanceled
                  ? "Event has been canceled."
                  : "Event is active."}
              </div>
            </div>
            <div className="row">
              <div className="col-3 text-right cal-review-pop-lbl">
                Involvement
              </div>
              <div className="col-9 text-left cal-review-pop-txt">
                {this.state.isIncluded
                  ? "You are the host."
                  : "You are not the host."}
              </div>
            </div>
            <div className="text-center cal-review-pop-lbl">Participants</div>
            <div className="text-left cal-review-server-box">
              {this.state.participants}
            </div>
          </div>
        );
      }

    }

    let serviceViewPanel;
    let bool = true;
    if (evn.eventType === "service") {
      serviceViewPanel = (
        <div className="service-review-panel">
          {/* {showMoreOfSchedulePanel} */}
          <div className="text-center service-review-header">
            Service Offering Overview
          </div>
          <div className="row">
            <div className="col-md-6 text-left serice-general-info">
              <div>
                <b>Render offered at</b>:{" "}
                {this.capitalizeFirstLetter(
                  this.props.theEvent.services.serviceMedium
                )}
              </div>
              <div>
                <b>Location & Directions:</b>&nbsp;
                {this.props.theEvent.services.location}
              </div>
            </div>
            <div className="col-md-6 serice-general-info">
              <div className="text-left">
                <b>Fee</b>:{" "}
                {this.props.theEvent.services.serviceFeeType === "catalog"
                  ? "As per catalog"
                  : "Free"}
              </div>
              {this.props.theEvent.services.serviceFeeType === "catalog" ? (
                <div>
                  <div className="text-left">
                    <b>Cataloged Item Name</b>:&nbsp;
                    {this.props.theEvent.services.taggedCatalog.itemName}
                  </div>
                  <div className="text-left">
                    <b>Item Price</b>:&nbsp;$&nbsp;
                    {this.commaFormattedCurrency(
                      this.props.theEvent.services.taggedCatalog.itemPrice
                    )}
                  </div>
                  <div className="text-left">
                    <b>Rendering Unit</b>:&nbsp;
                    {this.props.theEvent.services.taggedCatalog
                      .serviceRendereUnit === "hour"
                      ? "Per hour"
                      : this.props.theEvent.services.taggedCatalog
                          .serviceRendereUnit}
                  </div>
                </div>
              ) : null}
              <div className="text-left">
                <b>Server Type</b>:{" "}
                {this.capitalizeFirstLetter(
                  this.props.theEvent.services.serverType
                )}
              </div>
              {this.props.theEvent.services.serverType ===
              "individual" ? null : (
                <div>
                  <b>Group Name</b>:{" "}
                  {this.capitalizeFirstLetter(
                    this.props.theEvent.services.groupName
                  )}
                  &nbsp; &nbsp;{showGroupmembersButton}
                </div>
              )}
              <div className="text-left">
                <b>Recipiant Type</b>:{" "}
                {this.capitalizeFirstLetter(
                  this.props.theEvent.services.recipiantType
                )}
              </div>
              {this.props.theEvent.services.recipiantType ===
              "individual" ? null : (
                <div>
                  <b>Max Recipiants in a group</b>:&nbsp;
                  {this.props.theEvent.services.maxRecipantAllowed}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col text-left server-list">
              <b>Servers</b>:&nbsp;{this.showServerList()}
            </div>
          </div>
          {showMoreOfSchedulePanel}
          <div className="row">
            <div className="col-8 text-right disp-alloc-header-left">
              {" "}
              {this.state.deviceSize === "big"
                ? "Schedule Date (Start Time - duration minutes)"
                : "Date (Start Time - duration)"}
            </div>
            <div className="col-4 text-left disp-alloc-header-right">More</div>
          </div>
          <div className="row">
            <div className="schedule-list-box">
              {publishedProviders.map((obj, i) => {
                // console.log("xx obj:", obj);
                return (
                  <div key={i}>
                    <div
                      className={
                        bool
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-8 text-left display-alloc-lebel-comm">
                        {i + 1}.&nbsp;&nbsp;
                        {obj.serviceProviders.length > 0 ? (
                          <font color="green">
                            <i className="fa fa-check" />
                          </font>
                        ) : (
                          <font color="red">X</font>
                        )}
                        &nbsp;&nbsp;&nbsp;{obj.shiftDateString}&nbsp;(
                        {obj.startTimeStr}&nbsp;for&nbsp;
                        <i className="fa fa-forward" />
                        &nbsp;{obj.duration}&nbsp;mins.)
                        {obj.iAmAttending ? (
                          <font color="green">
                            &nbsp;
                            <i className="fa fa-user" />
                          </font>
                        ) : null}
                      </div>
                      {isAServer ? (
                        <div className="col-2 text-center">
                          {obj.isIncluded ? (
                            ""
                          ) : (
                            <div className="yna-indicator">*YNA</div>
                          )}
                        </div>
                      ) : (
                        <div className="col-2 text-center">&nbsp;</div>
                      )}

                      <div className="col-2 text-left">
                        <button
                          className="btn-alloc-schedule-more"
                          type="button"
                          onClick={() => this.showMoreSchedulePop(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          ...
                        </button>
                      </div>
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
          </div>
          {isAServer ? (
            <div className="row">
              <div className="col text-center yna-term-expansion">
                {atLeastOneNA
                  ? "*YNA: You are Not Assigned on this date-time."
                  : ""}
              </div>
            </div>
          ) : null}
        </div>
      );
    }

    let viewPanel;
    let respondbtn; //eventName,
    if (
      this.props.theEvent &&
      this.props.theEvent.eventStatus === "published"
    ) {
      respondbtn = (
        <button
          className="btn-user-engagement"
          type="button"
          onClick={this.handleUsersEnagement}
          style={{
            cursor: this.state.disabled ? "default" : "pointer",
          }}
        >
          RSVP
        </button>
      );
    }

    if (this.state.showEventFlag) {
      // console.log("In show event .........");
      viewPanel = ( 
        <div>
          {showEngageButtonFlag ? (
            <div className="row event-view-top-row-position">
              <div className="col-10 text-center event-view-name-lbl">
                {this.state.deviceSize === "big"
                  ? evn.eventName
                  : evn.shortName}
              </div>
              {this.state.showRespondFlag ? (
                <div className="col-2 text-left engage-btn-pos">
                  {respondbtn}
                </div>
              ) : (
                <div className="col-2 text-left engage-status-pos">
                  {this.props.status === "invited"
                    ? respondbtn
                    : this.capitalizeFirstLetter(this.state.status)}
                </div>
              )}
            </div>
          ) : (
            <div className="row row-position">
              <div className="col text-center event-view-name-lbl">
                {this.state.deviceSize === "big"
                  ? evn.eventName
                  : evn.shortName}
              </div>
            </div>
          )}
          {theReasonPanel}
          {describePanel}
          {peopleInvitedPanel}
          {attendeePanel}
          <div className="row">
            <div className="col-md-6">{eventDateTimePanel}</div>
            <div className="col-md-6">{contactPanel}</div>
          </div>
          {attendeeDirectionPanel}
          {intentSuccessPanel}
          {prePublishInfoPanel}
          {this.props.theEvent.eventType === "service" ? (
            <hr className="service-review-hr" />
          ) : null}
          {serviceViewPanel}
        </div>
      );
    }

    let classnamevar = "";
    if (this.props.caller === "eventcreator") classnamevar = "event-view-box";
    if (this.props.caller === "eventview")
      classnamevar = "event-view-box-from-calendar";
    if (this.props.caller === "eventresponse")
      classnamevar = "event-view-box-response";
    if (this.props.caller === "personalcalendar")
      classnamevar = "event-view-box-response-personal";
    if (this.props.caller === "bmail") classnamevar = "event-view-box-bmail";
    if (this.props.caller === "catalog")
      classnamevar = "event-view-box-catalog";
    if (this.props.caller === "extinvitee") classnamevar = "";

    let outputPanel;
    if (this.state.showEventFlag) {
      if (this.state.deviceSize === "big")
        outputPanel = <div className={classnamevar}>{viewPanel}</div>;
      else outputPanel = <div>{viewPanel}</div>;
    }

    if (this.state.showPurchasePanelFlag) {
      outputPanel = (
        <div className={classnamevar}>
          <BookAnEvent
            returnToCaller={this.returnFromPurchase}
            theEvent={this.state.theEvent}
            communityId={this.props.communityId}
            authorizedFuncs={this.props.authorizedFuncs}
            cartOf={this.state.cartOf}
            calledFrom={this.props.calledFrom}
            synapseId={this.props.synapseId}
            caller={this.props.caller}
            attendee={this.props.attendee}
          />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ViewEvent.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ViewEvent));
