import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import axios from "axios";

import ModalContainer from "../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../actions/modalActions";
import "../../../modal/css/localModal.css";
import "../../../modal/css/template.css";

import queryString from "query-string";

// import BazzarFramework from "../../../architecture/BazzarFramework";

import { emailValidation } from "../../../utils/emailValidation";

import { loginUser } from "../../../actions/authActions";
import "./Login.css";

import loginImg1 from "./image/loginImg1.jpg";
import loginImg2 from "./image/loginImg2.jpg";
import loginImg3 from "./image/loginImg3.jpg";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const resetPwdAndNotify = "/routes/users/resetPwdAndNotify";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "0000000000";

class Login extends Component {
  constructor() {
    super();

    this.state = {
      name: "",
      email: "",
      password: "",
      errors: {},

      forgotPwdFlag: false,
      opstype: "new",

      message: "",
      errFlag: false,
      loginMsg: "",

      loginData: "",
      loginData1: null,
      deviceSize: "",
      userData: null,
    };
  }

  componentDidMount = async () => {
    const values = queryString.parse(this.props.location.search);
    // console.log("1. Login onmount values:", values);
    if (!this.isObjEmpty(values)) {
      // console.log("In here ... 60 values.caller:", values.caller);
      if (values.caller === "emailchange") {
        this.setState({
          message: "Email has been changed. Use the new email to login.",
        });
      } else {
        // values.from = "login";
        // this.setState({
        //   loginData: JSON.stringify(values),
        //   loginData1: values,
        //   opstype: values.opstype,
        //   email: values.inviteeEmail,
        //   message: "",
        //   errFlag: false,
        // });
        values.from = "login";
        // console.log("2. Login onmount values A:", values);
        let opstype = "new";
        if (values.opstype) opstype = values.opstype;
        let email = "";
        if (values.inviteeEmail) email = values.inviteeEmail;
        let targetCommId = 0;
        // console.log("values.communityId:", values.communityId);
        if (parseFloat(values.communityId) > 0)
          targetCommId = parseFloat(values.communityId);

        this.setState({
          loginData: JSON.stringify(values),
          loginData1: values,
          targetCommId,
          opstype,
          email,
          message: "",
          errFlag: false,
        });
      }
    } else if (this.props.auth.isAuthenticated) {
      // console.log("Should go to lobby ...");
      this.props.history.push({
        pathname: "/lobby",
        search: "?query=test1",
        state: { detail: this.state.loginData },
      });
    }

    await this.setDeviceSize();
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  setDeviceSize = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.setState({
      deviceSize,
    });
  };

  isObjEmpty = (obj) => {
    let len = Object.getOwnPropertyNames(obj).length;
    if (len > 0) return false;
    return true;
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangeEmail = async (e) => {
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault(); // In form, we do not want to have default functions
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };

    await this.setState({
      userData,
    });

    // console.log(userData);
    await this.props.loginUser(userData);

    // console.log("Reached here .... this.props:", this.props);
    if (this.props.errors) {
      // console.log("here 140 ...");
      if (this.props.auth.isAuthenticated) {
        // Previous code...
        // let detailData = {
        //   loginData: this.state.loginData,
        //   calledFromDirect: "login",
        // };
        // this.props.history.push({
        //   pathname: "/lobby",
        //   search: "?query=test2",
        //   state: { detail: detailData },
        // });
        //
        // Jumping code ... to store.
        let detailData = {
          loginData: this.state.loginData,
          calledFromDirect: "login",
        };
        // console.log("This lobby push 22");
        let param = "";
        if (this.state.opstype === "store") {
          param =
            "?opstype=" +
            this.state.opstype +
            "&targetCommId=" +
            this.state.targetCommId;
        }
        // console.log("param:", param);
        this.props.history.push({
          pathname: "/lobby",
          search: param,
          state: { detail: detailData },
        });
      } else {
        if (this.props.errors.emailConfirm)
          this.setState({
            message: this.props.errors.emailConfirm,
            errFlag: true,
          });
      }
    } else {
      console.log("this.props.errors:", this.props.errors);
    }
  };

  handleForgotPwdCheckbox = async () => {
    await this.setState((prevstate) => ({
      forgotPwdFlag: !prevstate.forgotPwdFlag,
      message: "",
    }));
  };

  onClickGoogle(e) {
    e.preventDefault();
    // console.log('Got into Google Auth onclick function');
    this.props.googleLoginUser();
  }

  handleForgotPwdBtn = async () => {
    // console.log('going to validation this.state.email:', this.state.email);
    let emailval = await emailValidation(this.state.email);
    // console.log('emailval:', emailval);

    if (!emailval) {
      await this.setState({
        message: "Invalid email. Please check, fix and Reset.",
        errFlag: true,
      });
    } else {
      try {
        let url = baandaServer + resetPwdAndNotify;

        let inputData = {
          email: this.state.email,
        };

        let reset = await axios.post(url, inputData);
        // console.log("reset:", reset, " Msg:");
        if (reset.data.status === "error") {
          await this.setState({
            message: reset.data.Msg,
            errFlag: true,
          });
        } else {
          await this.setState({
            message: reset.data.Msg,
            errFlag: false,
          });
        }
      } catch (err) {
        console.log("axios reset error:", err.message);
      }
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("Login...");

    const { errors } = this.props;

    let forgotPwdCheckPanel;
    // forgotPwdCheckPanel = (
    //   <div className="checkbox_div">
    //     <input
    //       type="checkbox"
    //       onChange={this.handleForgotPwdCheckbox}
    //       name="confirm"
    //     />{" "}
    //     Forgot password?
    //   </div>
    // );
    forgotPwdCheckPanel = (
      <div className="checkbox_div">
        <Checkbox
          checked={this.state.forgotPwdFlag}
          onChange={this.handleForgotPwdCheckbox}
        />
        &nbsp;Forgot password?
      </div>
    );

    let intro;
    if (this.state.deviceSize === "small") {
      intro = "Intro";
    } else {
      intro = "Intro";
    }

    let introButton = (
      <button
        className="btn_reg_70_intro"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        {intro}&nbsp;
        {this.state.deviceSize === "big" ? (
          <i className="fas fa-info-circle" />
        ) : null}
      </button>
    );

    let buttonPanel;
    if (!this.state.forgotPwdFlag) {
      buttonPanel = (
        <div>
          <div className="row">
            <div className="col-10 text-left">{forgotPwdCheckPanel}</div>
            <div className="col-2">
              <input
                type="submit"
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
                className="btn_reg_70_login"
                value="Submit"
              />
            </div>
          </div>
        </div>
      );
    } else {
      buttonPanel = (
        <div>
          <div className="row">
            <div className="col-12 text-center forgot-password">
              {forgotPwdCheckPanel}
            </div>
            {/* <div className="col-3 text-center">
              <button
                className="btn-login-forgot"
                type="button"
                onClick={this.handleForgotPwdBtn}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Reset&nbsp;
                <i className="fas fa-exchange-alt" />
              </button>
            </div> */}
          </div>
          <div className="row">
            <div className="col-12 text-center">
              <button
                className="btn-login-forgot"
                type="button"
                onClick={this.handleForgotPwdBtn}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Reset&nbsp;
                <i className="fas fa-exchange-alt" />
              </button>
            </div>
          </div>
          {this.state.deviceSize === "big" ? (
            <div className="row">
              <div className="col-1">&nbsp;</div>
              <div className="col-10">
                <p align="justify" className="reset-direction">
                  Please enter the email you used to register. Then click on the
                  Reset button and you will receive an email with further
                  instructions.
                </p>
              </div>
              <div className="col-1">&nbsp;</div>
            </div>
          ) : (
            <div className="row">
              <div className="col-2">&nbsp;</div>
              <div className="col-9">
                <p align="justify" className="reset-direction">
                  Please enter the email you used to register. Then click on the
                  Reset button and you will receive an email with further
                  instructions.
                </p>
              </div>
              <div className="col-1">&nbsp;</div>
            </div>
          )}
        </div>
      );
    }

    let loginPanel;
    if (this.state.deviceSize === "small") {
      loginPanel = (
        <div className="text-center login-form-small">
          <div className="container">
            <div className="row">
              <div className="col-md-8 m-auto">
                <h1 className="text-center login-header">Log In</h1>
                {/* <p className="lead text-center">Sign in to Baanda</p> */}
                <form noValidate onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      className="email-text-lbl"
                      placeholder="Email Address"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="password-txt-lbl"
                      placeholder="Password"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChange}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>

                  <div>{buttonPanel}</div>
                </form>
                <div className="text-center">{introButton}</div>
                <div
                  className={
                    this.state.errFlag
                      ? "text-center invalid-loginid-pwd"
                      : "text-center valid-loginid-msg"
                  }
                >
                  {this.state.message}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      loginPanel = (
        <div>
          {this.state.deviceSize === "small" ? null : (
            <p className="top-padding" />
          )}
          <div className="container">
            <div className="row">
              <div className="col">
                <h1 className="text-center login-header">Login</h1>
                <form noValidate onSubmit={this.onSubmit}>
                  <div className="form-group">
                    <input
                      type="email"
                      className="email-text-lbl"
                      placeholder="Email Address"
                      name="email"
                      value={this.state.email}
                      onChange={this.onChangeEmail}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="password-txt-lbl"
                      placeholder="Password"
                      name="password"
                      value={this.state.password}
                      onChange={this.onChange}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>

                  <div>{buttonPanel}</div>
                </form>
                <div className="text-center">{introButton}</div>
                <div
                  className={
                    this.state.errFlag
                      ? "text-center invalid-loginid-pwd"
                      : "text-center valid-loginid-msg"
                  }
                >
                  {this.state.message}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let searchPanel;
    if (this.state.deviceSize === "big") {
      searchPanel = (
        <div className="text-center">
          <div className="bazzar-framework-box">
            {/* <img src={loginImg1} width="500px" height="600px" alt="" /> */}
            <div className="outer">
              <div className="inner">
                <img src={loginImg1} width="500px" height="600px" alt="" />
                <img src={loginImg2} width="500px" height="600px" alt="" />
                <img src={loginImg3} width="500px" height="600px" alt="" />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      searchPanel = (
        <div className="text-center">
          <div className="bazzar-framework-box">
            {/* <img src={loginImg1} width="345px" height="490px" alt="" /> */}
            <div className="outer">
              <div className="inner">
                <img src={loginImg1} width="345px" height="490px" alt="" />
                <img src={loginImg2} width="345px" height="490px" alt="" />
                <img src={loginImg3} width="345px" height="490px" alt="" />
              </div>
            </div>
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div className="row login-upper-margin">
        <div className="col-md-4 login-panel-position">{loginPanel}</div>
        {/* <div className="col-md-8 text-center">abcd</div> */}
        <div className="col-md-8 text-center">{searchPanel}</div>
      </div>
    );

    return (
      <div className="login">
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  // googleLoginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

const mapDispatchToProps = (dispatch) => ({
  loginUser: (userData) => dispatch(loginUser(userData)),
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
