import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../actions/modalActions";
import "../../../../modal/css/localModal.css";
import "../../../../modal/css/template.css";

import "./ShippingCostSetup.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const saveShippingPrice = "/routes/dashboard/saveShippingPrice";
const getShippingPrice = "/routes/dashboard/getShippingPrice?";
const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";

const Checkbox = (props) => <input type="checkbox" {...props} />;

const readMeCode = "1115200000";

class ShippingCostSetup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      entryType: "price",

      description: "",
      price: 0,

      deviceSize: "",
      message: "",
      errFlag: false,

      shippingPriceList: [],
      fromAddressList: [],
      shippingObj: null,
      itemRange: "All",
      selheight: 3,

      addressName: "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      zip: "",
      country: "USA",

      chargeType: "noofitems",
      allowFreeShip: false,
      abovePrice: 0
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.getShippingMeta();
    await this.setState({
      deviceSize,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  checkAddress = async () => {
    let status = "success";
    let msg = "";
    if (
      this.state.line1 === "" ||
      this.state.city === "" ||
      this.state.postal_code === "" ||
      this.state.country === ""
    ) {
      status = "error";
      msg = "Must provide shipping address to be shipped";
    } else {
      let param =
        "street1=" +
        this.state.line1 +
        "&city=" +
        this.state.city +
        "&zip=" +
        this.state.zip +
        "&state=" +
        this.state.state +
        "&country=" +
        this.state.country +
        "&company= ";
      // + this.props.communityName;
      let url = baandaServer + easypostAddressCheck + param;
      try {
        let check = await axios.get(url);
        // console.log("check:", check);
        if (check.data.status === "error") {
          status = "error";
          msg = check.data.Msg;
        }
      } catch (err) {
        console.log("err:", err.message);
        status = "error";
        msg = err.message;
      }
    }

    return { status, Msg: msg };
  };

  getShippingMeta = async () => {
    let url =
      baandaServer + getShippingPrice + "communityId=" + this.props.communityId;
    // console.log("url:", url);
    try {
      let shipret = await axios.get(url);
      // console.log("shipret:", shipret);
      //   let sr = shipret.data.Msg.shippingCosts;
      await this.setState({
        shippingPriceList: shipret.data.Msg.shippingMeta.priceList,
        fromAddressList: shipret.data.Msg.shippingMeta.fromAddress,
        chargeType: shipret.data.Msg.shippingMeta.shippingChargeType        ,
        allowFreeShip: shipret.data.Msg.shippingMeta.shippingFree,
        abovePrice: shipret.data.Msg.shippingMeta.shippingFreeAboveAmt
      });
    } catch (err) {
      this.setState({
        message: err.message,
        errFlag: true,
      });
    }
  };

  handleShippingSave = async () => {
    // console.log("handleShippingSave");
    let url = baandaServer + saveShippingPrice;
    let input = {
      clientProgram: "ShippingCostSetup.js",
      clientFunction: "handleShippingSave",
      entryType: this.state.entryType,
      shippingObj: {
        priceList: this.state.shippingPriceList,
        fromAddress: this.state.fromAddressList,
        shippingChargeType: this.state.chargeType,
        shippingFree: this.state.allowFreeShip,
        shippingFreeAboveAmt: parseFloat(this.state.abovePrice)
      },
      communityId: this.props.communityId,
    };
    // console.log("url:", url, " input:", input);
    try {
      let sr = await axios.post(url, input);
      // console.log("sr", sr);
      if (sr.data.status === "success") {
        await this.setState({
          message: "Successfully Updated",
          addressName: "",
          line1: "",
          line2: "",
          city: "",
          state: "",
          zip: "",
          errFlag: false,
        });
      } else {
        await this.setState({
          message: "Error: " + sr.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("Error:", err.message);
      this.setState({
        message: "Error: " + err.message,
        errFlag: true,
      });
    }
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangePrice = async (e) => {
    let value = e.target.value;
    // await this.setState({ [e.target.name]: e.target.value });
    // console.log("e.target.value:", value);

    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      await this.setState({
        itemPrice: val,
      });
    } else {
      await this.setState({
        [e.target.name]: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleEntryType = async (e) => {
    this.setState({
      entryType: e.target.value,
    });
  };

  deletePriceItems = (type) => {
    let newPrcList = [];
    this.state.shippingPriceList.forEach((prc) => {
      if (type === "All") {
        if (prc.itemRange === "All") newPrcList.push(prc);
      } else {
        if (prc.itemRange !== "All") newPrcList.push(prc);
      }
    });

    this.setState({
      shippingPriceList: newPrcList,
    });
  };

  handleChargeType = async (e) => {
    let value = e.target.value;
    if (value === "All") this.deletePriceItems("notallitems");
    else this.deletePriceItems("All");

    // console.log(e.target.value);
    this.setState({
      chargeType: e.target.value,
    });
  };

  handleAdd = async () => {
    // if (this.state.description !== "" && this.state.price !== 0) {
    if (this.state.description !== "") {
      let notAllowed = false;
      this.state.shippingPriceList.forEach((obj) => {
        if (obj.description === this.state.description) notAllowed = true;
      });

      if (!notAllowed) {
        let shipPrc = [...this.state.shippingPriceList];
        let sob = {
          description: this.state.description,
          price: this.state.price,
          itemRange: this.state.itemRange,
        };

        shipPrc.push(sob);

        await this.setState({
          shippingPriceList: shipPrc,
          description: "",
          price: 0,
          message: "",
          errFlag: false,
        });
      } else {
        await this.setState({
          message: "Error: Description must be unique.",
          errFlag: true,
        });
      }
    }
  };

  handleAddAddr = async () => {
    let chk = await this.checkAddress();
    let msg = "";
    let errFlag = false;
    let notAllowed = false;
    if (chk.status === "error") {
      errFlag = true;
      msg = chk.Msg;
    }
    this.state.fromAddressList.forEach((obj) => {
      if (obj.name === this.state.addressName) notAllowed = true;
    });

    if (notAllowed) {
      msg += " No duplicate address name.";
      errFlag = true;
    }

    if (chk.status === "success" && !notAllowed) {
      let shipAdr = [...this.state.fromAddressList];

      let adrobj = {
        name: this.state.addressName,
        address: {
          street1: this.state.line1,
          street2: this.state.line2,
          city: this.state.city,
          state: this.state.state,
          zip: this.state.zip,
          country: this.state.country,
        },
      };

      shipAdr.push(adrobj);

      this.setState({
        fromAddressList: shipAdr,
        message: "",
        errFlag,
      });
    } else {
      this.setState({
        message: msg,
        errFlag,
      });
    }
  };

  selectThisPriceToDelete = async (obj) => {
    let prclist = [];
    this.state.shippingPriceList.forEach((elm) => {
      if (obj.description !== elm.description) prclist.push(elm);
    });

    await this.setState({
      shippingPriceList: prclist,
    });
  };

  handleAddressDelete = async (obj) => {
    let adrlist = [];

    this.state.fromAddressList.forEach((elm) => {
      if (obj.name !== elm.name) adrlist.push(elm);
    });

    this.setState({
      fromAddressList: adrlist,
    });
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleAdminOpsSelected = async () => {
    let value = document.getElementById("shipItemsInInvoice").value;
    // console.log("ops value:", value);
    await this.setState({
      itemRange: value,
    });
  };

  handleFreeShip = async () => {
    await this.setState((prevstate) => ({
      allowFreeShip: !prevstate.allowFreeShip,
    }));
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ShippingCostSetup...");

    let selectCostAddrPanel;
    selectCostAddrPanel = (
      <div>
        <div className="row carrier-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Entry of&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="price"
                  checked={this.state.entryType === "price"}
                  onChange={this.handleEntryType}
                />{" "}
                Price
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="address"
                  checked={this.state.entryType === "address"}
                  onChange={this.handleEntryType}
                />{" "}
                Address
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let shipChargeTypePanel;
    shipChargeTypePanel = (
      <div>
        <div className="row carrier-panel-row">
          <div className="col text-center radio-font-style">
            Fee Type:&nbsp;&nbsp;
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="All"
                  checked={this.state.chargeType === "All"}
                  onChange={this.handleChargeType}
                />{" "}
                All Items
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="noofitems"
                  checked={this.state.chargeType === "noofitems"}
                  onChange={this.handleChargeType}
                />{" "}
                Number of Items
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let freeShipPanel;
    freeShipPanel = (
      <div className="text-center fee-grt-than-row">
        <Checkbox
          checked={this.state.allowFreeShip}
          onChange={this.handleFreeShip}
        />{" "}
        Total greater or equal to $
        <input
          name="abovePrice"
          type="number"
          value={this.state.abovePrice}
          onChange={this.onChangePrice}
          className="adm-shipping-price-free"
          step=".01"
          placeholder="0.00"
          autoComplete="off"
          spellCheck="false"
        />
      </div>
    );

    let itemRangePanel;
    itemRangePanel = (
      <div>
        <select
          size={this.state.selheight}
          // onFocus={this.onfocus}
          // onBlur={this.onblur}
          id="shipItemsInInvoice"
          name="shipItemsInInvoice"
          onChange={this.handleAdminOpsSelected}
          onSelect={this.handleAdminOpsSelected}
          className="admin_select"
          defaultValue={"DEFAULT"}
        >
          <option className="admin-option-row-sel" value="DEFAULT" disabled>
            Items in an invoice
          </option>
          <option className="" value="1-2">
            1-2 items
          </option>
          <option className="" value="3-5">
            3-5 items
          </option>
          <option className="" value="6-8">
            6-8 items
          </option>
          <option className="" value="9-12">
            9-12 items
          </option>
          <option className="" value="12-">
            12+ items
          </option>
        </select>
      </div>
    );

    let priceEntryPanel;
    if (this.state.entryType === "price") {
      priceEntryPanel = (
        <div>
          <div className="row pickup-addr-row">
            <div className="col-6 ship-price-lbl text-center">
              {" "}
              {shipChargeTypePanel}
            </div>
            <div className="col-6 text-center">{freeShipPanel}</div>
          </div>

          <div className="row pickup-addr-row">
            <div className="col-3 ship-price-lbl text-right">Description</div>
            <div className="col-9 text-left">
              <input
                name="description"
                type="text"
                value={this.state.description}
                onChange={this.onChange}
                size="100"
                maxLength="100"
                className="adm-ship-desc"
                placeholder="Shipping Service"
              />
            </div>
          </div>
          <div className="row pickup-addr-row">
            <div className="col-3 text-right ship-price-lbl">Price</div>
            <div className="col-4 text-left">
              <input
                name="price"
                type="number"
                value={this.state.price}
                onChange={this.onChangePrice}
                className="adm-shipping-price"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
            <div className="col-4 text-center item-range-pos">
              {this.state.chargeType === "noofitems" ? (
                itemRangePanel
              ) : (
                <div className="text-center all-item-same-fee">
                  All items same fee
                </div>
              )}
            </div>
          </div>
          <div className="row ship-addr-add-btn-pos">
            <div className="col text-center">
              <button
                className="btn_reg_60"
                type="button"
                onClick={this.handleAdd}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Add&nbsp;+
              </button>
            </div>
          </div>
        </div>
      );
    }

    let bool = true;
    let shipPriceListPanel;
    shipPriceListPanel = (
      <div className="price-list-row">
        <div className="fixedsize-ship-price-list">
          {this.state.shippingPriceList.map((obj, i) => {
            // console.log("690 obj:", obj);
            return (
              <div key={i}>
                <div
                  className={
                    bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                  }
                >
                  <div className="col-1 text-right">
                    <button
                      className="btn-ship-price-line-del"
                      type="button"
                      onClick={() => this.selectThisPriceToDelete(obj)}
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                  <div className="col-11 text-left price-list-lbl">
                    ${this.commaFormattedCurrency(parseFloat(obj.price))}
                    &nbsp;||&nbsp; {obj.description} (Items in Invoice:{" "}
                    {obj.itemRange})
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            );
          })}
        </div>
      </div>
    );

    let fromAddrListPanel;
    if (this.state.entryType === "address") {
      bool = true;
      fromAddrListPanel = (
        <div className="address-list-row">
          <div className="fixedsize-ship-price-list">
            {this.state.fromAddressList.map((obj, i) => {
              // console.log("690 obj:", obj);
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-1 text-right">
                      <button
                        className="btn-ship-price-line-del"
                        type="button"
                        onClick={() => this.handleAddressDelete(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                    <div className="col-11 text-left price-list-lbl">
                      {obj.name} || {obj.address.street1}{" "}
                      {obj.address.street2 !== ""
                        ? ", " + obj.address.street2
                        : null}
                      , {obj.address.city}, {obj.address.state}{" "}
                      {obj.address.zip}
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    let buttonPanel = (
      <div className="adm-ship-button-pos">
        {/* {this.state.shippingPriceList.length > 0 ? ( */}
        <button
          className="btn_reg_70"
          type="button"
          onClick={this.handleShippingSave}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save&nbsp;
          <i className="fas fa-ship" />
        </button>
        {/* ) : null} */}
        &nbsp;&nbsp;
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
      </div>
    );

    let fromAddressPanel;
    if (this.state.entryType === "address") {
      fromAddressPanel = (
        <div>
          <div className="row adm-ship-addr-row">
            <div className="col-4 text-right ship-adr-name">
              From Address Name
            </div>
            <div className="col-8 text-left">
              <input
                name="addressName"
                type="text"
                value={this.state.addressName}
                onChange={this.onChange}
                size="40"
                maxLength="40"
                className="adm-ship-addr-name"
                placeholder="A unique name..."
              />
            </div>
          </div>
          <div className="row adm-ship-addr-row">
            <div className="col-8">
              <b>Street</b>&nbsp;
              <input
                name="line1"
                type="text"
                value={this.state.line1}
                onChange={this.onChange}
                size="70"
                maxLength="70"
                className="ship-adm-addr-line1"
                placeholder="Street address ..."
              />
            </div>
            <div className="col-4">
              <b>Apt</b>&nbsp;
              <input
                name="line2"
                type="text"
                value={this.state.line2}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="ship-adm-addr-line2"
                placeholder="Apt/Suite #"
              />
            </div>
          </div>
          <div className="row admin-ship-addr-pos">
            <div className="col-md-6">
              <b>City</b>&nbsp;
              <input
                name="city"
                type="text"
                value={this.state.city}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="comm-addr-city"
                placeholder="City..."
              />
            </div>
            <div className="col-2">
              <b>State</b>&nbsp;
              <input
                name="state"
                type="text"
                value={this.state.state}
                onChange={this.onChange}
                size="2"
                maxLength="2"
                className="comm-addr-state"
                placeholder="State"
              />
            </div>
            <div className="col-3">
              <b>Zip</b>&nbsp;
              <input
                name="zip"
                type="text"
                value={this.state.zip}
                onChange={this.onChange}
                size="10"
                maxLength="10"
                className="ship-adm-addr-zip"
                placeholder="Zip"
              />
            </div>
            <div className="col-1 text-center comm-country-lbl">
              {this.state.country}
            </div>
          </div>
          <div className="row ship-addr-add-btn-pos">
            <div className="col text-center">
              <button
                className="btn_reg_60"
                type="button"
                onClick={this.handleAddAddr}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                Add&nbsp;+
              </button>
            </div>
          </div>
        </div>
      );
    }

    let activePanel;
    if (this.state.entryType === "price") {
      activePanel = (
        <div>
          {priceEntryPanel}
          {shipPriceListPanel}
        </div>
      );
    }

    if (this.state.entryType === "address") {
      activePanel = (
        <div>
          {fromAddressPanel}
          {fromAddrListPanel}
        </div>
      );
    }

    let outputPanel;

    outputPanel = (
      <div className="adm-ship-meta-box">
        <div className="shipping-header">Shipping Price & Address Setup</div>
        {selectCostAddrPanel}
        {activePanel}
        {buttonPanel}
        <div
          className={
            this.state.errFlag
              ? "text-center ship-list-msg-err"
              : "text-center ship-list-msg"
          }
        >
          {this.state.message}
        </div>
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ShippingCostSetup.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShippingCostSetup));
