import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./ShippingMgmt.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const easypostAddressCheck = "/routes/shared/easypostAddressCheck?";
const updateCatalogItem = "/routes/dashboard/updateCatalogItem";

const readMeCode = "1112111100";

class ShippingMgmt extends Component {
  constructor(props) {
    super(props);

    this.state = {
      catalog: null,

      shippingType: "regular",
      pickupAllowed: "nopickup",
      deliveryAllowed: "allowed",
      pickupDefinition: "standard",

      errMsg: "",
      errFlag: false,
      shippingCost: 0.0,
      description: "",

      line1: "",
      line2: "",
      city: "",
      state: "",
      postal_code: "",
      country: "US",

      pickupPlaces: [],
    };
  }
  componentDidMount = async () => {
    let pr = this.props.catalog;

    let pickupPlaces = [];
    if (pr.pickupLocations) pickupPlaces = pr.pickupLocations;
    let pickupAllowed = "nopickup";
    if (pr.pickupAllowed) pickupAllowed = pr.pickupAllowed;
    let pickupDefinition = this.state.pickupDefinition;
    if (pr.pickupFrom) pickupDefinition = pr.pickupFrom;
    let shippingType = "regular";
    if (pr.specialShipping) shippingType = pr.specialShipping;
    let shippingCost = 0.0;
    let description = "";
    if (pr.specialShipping === "special") {
      shippingCost = pr.shipping.cost;
      description = pr.shipping.description;
    }

    await this.setState({
      catalog: pr,
      pickupAllowed,
      pickupPlaces,
      pickupDefinition,
      shippingType,
      shippingCost,
      description,
    });
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  returnToCatalog = async () => {
    this.props.returnToCaller(this.state.catalog);
  };

  handleShippingType = async (e) => {
    await this.setState({
      shippingType: e.target.value,
      errFlag: false,
      errMsg: "",
    });
  };

  handlePickupType = async (e) => {
    await this.setState({
      pickupAllowed: e.target.value,
      errFlag: false,
      errMsg: "",
    });
  };

  handleDeliveryType = (e) => {
    this.setState({
      deliveryAllowed: e.target.value,
      errFlag: false,
      errMsg: "",
    });
  };

  handlePickupDefinition = async (e) => {
    this.setState({
      pickupDefinition: e.target.value,
      errFlag: false,
      errMsg: "",
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    this.setState({ [e.target.name]: e.target.value });
  };

  onChangePrice = async (e) => {
    let value = e.target.value;
    if (this.countDecimals(value) > 2) {
      let tmp = parseFloat(value).toFixed(2);
      let val = parseFloat(tmp);
      this.setState({
        [e.target.name]: val,
      });
    } else {
      this.setState({
        [e.target.name]: value,
      });
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleNewAdress = async () => {
    let params =
      "street1=" +
      this.state.line1 +
      "&street2=" +
      this.state.line2 +
      "&city=" +
      this.state.city +
      "&state=" +
      this.state.state +
      "&zip=" +
      this.state.postal_code +
      "&country=" +
      this.state.country;
    let url = baandaServer + easypostAddressCheck + params;
    // console.log("handleNewAdress url=", url);
    let addrarr = this.state.pickupPlaces;
    try {
      let veriret = await axios.get(url);
      // console.log("veriret:", veriret);
      if (veriret.data.status === "success") {
        let addrobj = {
          addrName: this.state.addrName,
          direction: this.state.direction,
          line1: this.state.line1,
          line2: this.state.line2,
          city: this.state.city,
          state: this.state.state,
          postal_code: this.state.postal_code,
          country: "US",
        };
        addrarr.push(addrobj);
        this.setState({
          errMsg: "Pickup Location Address Added.",
          errFlag: false,
          line1: "",
          line2: "",
          city: "",
          state: "",
          postal_code: "",
          pickupPlaces: addrarr,
        });
      } else {
        this.setState({
          errMsg: veriret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  checkValidity = (pickupLocations) => {
    let msg = "";
    let state = "success";
    if (this.state.pickupAllowed === "allowed") {
      if (pickupLocations.length === 0) {
        msg =
          "Must provide pickup address when pickup is allowed. For standard, check if defined in community/store. For Special Address, enter valid address.";
        state = "error";
      }
    }

    return { status: state, Msg: msg };
  };

  handleSave = async () => {
    let pickupFrom = this.state.pickupDefinition;
    if (this.state.pickupPlaces.length === 0) pickupFrom = "standard";

    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let pickupLocations = [];
    if (this.state.pickupAllowed === "allowed") {
      if (this.state.pickupDefinition === "standard") {
        let pl = [];
        if (coopStoreId === 0) {
          pl = [...this.props.community.pickupAddresses];
        } else {
          pl = [...this.props.store.pickupAddresses];
        }
        pl.forEach((ad) => {
          let adObj = {
            addrName: ad.addrName,
            line1: ad.street1,
            line2: ad.street2,
            city: ad.city,
            state: ad.state,
            postal_code: ad.zip,
            country: ad.country,
            direction: ad.direction,
          };
          pickupLocations.push(adObj);
        });
      } else {
        pickupLocations = [...this.state.pickupPlaces];
      }
    }

    let valid = this.checkValidity(pickupLocations);

    if (valid.status === "success") {
      let input = {
        communityId: this.props.communityId,
        coopStoreId,
        itemId: this.state.catalog.itemId,
        updtmode: "shipping",
        specialShipping: this.state.shippingType,
        shipping: {
          cost: parseFloat(this.state.shippingCost),
          description: this.state.description,
        },
        pickupAllowed: this.state.pickupAllowed,
        pickupFrom,
        pickupLocations,
        clientProgram: "ShippingMgmt.js",
        clientFunction: "handleSave",
      };
      let url = baandaServer + updateCatalogItem;
      // console.log("url:", url);
      // console.log("input:", input);

      try {
        let retship = await axios.post(url, input);
        // console.log('retship:', retship);
        if (retship.data.status === "success") {
          this.setState({
            catalog: retship.data.Msg,
            errMsg: "Successfully updated.",
            errFlag: false,
          });
        } else {
          this.setState({
            errMsg: retship.data.Msg,
            errFlag: true,
          });
        }
      } catch (err) {
        console.log("err:", err.message);
        this.setState({
          errMsg: err.message,
          errFlag: true,
        });
      }
    } else {
      this.setState({
        errFlag: true,
        errMsg: valid.Msg,
      });
    }
  };

  delSpecialAddress = async (adr) => {
    let spladr = [];
    this.state.pickupPlaces.forEach((obj) => {
      if (
        obj.line1 === adr.line1 &&
        obj.line2 === adr.line2 &&
        obj.postal_code === adr.postal_code
      ) {
        // do nothing.
      } else {
        spladr.push(obj);
      }
    });

    await this.setState({
      pickupPlaces: spladr,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("ShippingMgmt...");

    let modalButton = (
      <button
        className="btn_info_main"
        type="button"
        onClick={this.openAlertModal}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-info-circle" />
      </button>
    );

    let ShippingHeaderPanel;
    ShippingHeaderPanel = (
      <div>
        <div className="cat-tax-header">
          Shipping | Delivery | Pickup -- Setup
        </div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Shipping is: &nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="regular"
                  checked={this.state.shippingType === "regular"}
                  onChange={this.handleShippingType}
                />{" "}
                Regular/Standard
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="special"
                  checked={this.state.shippingType === "special"}
                  onChange={this.handleShippingType}
                />{" "}
                Special
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="noship"
                  checked={this.state.shippingType === "noship"}
                  onChange={this.handleShippingType}
                />{" "}
                No Shipping
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let pickupBasePanel;
    pickupBasePanel = (
      <div>
        <div className="row pickup-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Pickup is: &nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="allowed"
                  checked={this.state.pickupAllowed === "allowed"}
                  onChange={this.handlePickupType}
                />{" "}
                Allowed
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="nopickup"
                  checked={this.state.pickupAllowed === "nopickup"}
                  onChange={this.handlePickupType}
                />{" "}
                No Pickups
              </label>
            </div>
          </div>
        </div>
      </div>
    );

    let deliverypickupBasePanel;
    deliverypickupBasePanel = (
      <div>
        <div className="row pickup-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Delivery is: &nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="allowed"
                  checked={this.state.deliveryAllowed === "allowed"}
                  onChange={this.handleDeliveryType}
                  disabled
                />{" "}
                Allowed
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="nodelivery"
                  checked={this.state.deliveryAllowed === "nodelivery"}
                  onChange={this.handleDeliveryType}
                  disabled
                />{" "}
                No Delivery
              </label>
            </div>
            &nbsp; (Coming soon)
          </div>
        </div>
      </div>
    );

    let pickupFromPanel;
    if (this.state.pickupAllowed === "allowed") {
      pickupFromPanel = (
        <div>
          <div className="row pickup-panel-row">
            <div className="col text-center radio-font-style">
              <strong>Pickup is from: &nbsp;&nbsp;</strong>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="standard"
                    checked={this.state.pickupDefinition === "standard"}
                    onChange={this.handlePickupDefinition}
                  />{" "}
                  Default Address
                </label>
              </div>
              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="multiple"
                    checked={this.state.pickupDefinition === "multiple"}
                    onChange={this.handlePickupDefinition}
                  />{" "}
                  Special Addresses
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    }

    let buttonPanel;
    buttonPanel = (
      <div className="cat-disc-button-row">
        <button
          className="btn_reg_60"
          type="button"
          onClick={this.handleSave}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          Save
        </button>

        <button
          className="btn_back_main"
          type="button"
          onClick={this.returnToCatalog}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
        {modalButton}
        <div
          className={
            !this.state.errFlag
              ? "text-center cat-dis-msg"
              : "text-center cat-dis-msg-err"
          }
        >
          {this.state.errMsg}
        </div>
      </div>
    );

    let specialShipPanel;
    if (this.state.shippingType === "special") {
      specialShipPanel = (
        <div>
          <div className="row price-row">
            <div className="col-4 text-right cat-price">
            Price of Shipping&nbsp;$
            </div>
            <div className="col-8 text-left catalog-item-qty">
              <input
                name="shippingCost"
                type="number"
                value={this.state.shippingCost}
                onChange={this.onChangePrice}
                className="ship-price"
                step=".01"
                placeholder="0.00"
                autoComplete="off"
                spellCheck="false"
              />
            </div>
          </div>
          <div className="row price-row">
            <div className="col-4 text-right cat-price">Description</div>
            <div className="col-8 text-left catalog-item-qty">
              <input
                name="description"
                type="text"
                value={this.state.description}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="ship-description"
                placeholder="Shipping description"
              />
            </div>
          </div>
        </div>
      );
    }

    let picupAddressPanel;
    if (
      this.state.pickupAllowed === "allowed" &&
      this.state.pickupDefinition === "multiple"
    ) {
      picupAddressPanel = (
        <div>
          <div className="text-center cat-pickup-head-lbl">
            Pickup Postal Address
          </div>
          <div className="row spcial-addr-name-row">
            <div className="col">
              Name:{" "}
              <input
                name="addrName"
                type="text"
                value={this.state.addrName}
                onChange={this.onChange}
                size="20"
                maxLength="20"
                className="cat-ship-addr-name"
                placeholder="Address name"
              />
              &nbsp;Direction&nbsp;
              <input
                name="direction"
                type="text"
                value={this.state.direction}
                onChange={this.onChange}
                size="75"
                maxLength="75"
                className="cat-ship-addr-direc"
                placeholder="Direction"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <input
                name="line1"
                type="text"
                value={this.state.line1}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="cat-ship-addr-line1"
                placeholder="Street address ..."
              />
            </div>
            <div className="col-md-6">
              <input
                name="line2"
                type="text"
                value={this.state.line2}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="cat-ship-addr-line2"
                placeholder="Apt# or Suit#..."
              />
            </div>
          </div>
          <div className="row cat-addr-second-row">
            <div className="col-md-6 text-left">
              <input
                name="city"
                type="text"
                value={this.state.city}
                onChange={this.onChange}
                size="45"
                maxLength="40"
                className="cat-ship-addr-city"
                placeholder="City..."
              />
            </div>
            <div className="col-2">
              <input
                name="state"
                type="text"
                value={this.state.state}
                onChange={this.onChange}
                size="2"
                maxLength="2"
                className="cat-ship-addr-state"
                placeholder="State"
              />
            </div>
            <div className="col-2">
              <input
                name="postal_code"
                type="text"
                value={this.state.postal_code}
                onChange={this.onChange}
                size="10"
                maxLength="10"
                className="cat-ship-addr-zip"
                placeholder="Zip"
              />
            </div>
            <div className="col-2 text-center cat-country-lbl">
              {this.state.country}&nbsp;&nbsp;
              <button
                className="btn_pickup_add"
                type="button"
                onClick={this.handleNewAdress}
                style={{ cursor: this.state.disabled ? "default" : "pointer" }}
              >
                <i className="fa fa-plus" />
              </button>
            </div>
          </div>
        </div>
      );
    }

    let pickupPlacesPanel;
    let bool = true;
    if (
      this.state.pickupAllowed === "allowed" &&
      this.state.pickupDefinition === "multiple"
    ) {
      pickupPlacesPanel = (
        <div>
          <div className="fixedsize-code-pickup-box">
            {this.state.pickupPlaces.map((obj, i) => (
              <div key={i}>
                <div className={`${bool ? "row-color-a" : "row-color-b"}`}>
                  <div className="row">
                    <div className="col-2 text-center">
                      <button
                        onClick={() => this.delSpecialAddress(obj)}
                        className="btn-cat-pickaddr-del"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-trash" />
                      </button>
                    </div>
                    <div className="col-10 text-left pick-addr-lbl">
                      {obj.line1}&nbsp;{obj.line2 + ","}&nbsp;{obj.city},&nbsp;
                      {obj.state}&nbsp;{obj.postal_code}
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            ))}
          </div>
        </div>
      );
    }

    let outputPanel;
    outputPanel = (
      <div className="catalog-show-shipping-box">
        {ShippingHeaderPanel}
        {specialShipPanel}
        {pickupBasePanel}
        {deliverypickupBasePanel}
        {pickupFromPanel}
        {picupAddressPanel}
        {pickupPlacesPanel}
        {buttonPanel}
      </div>
    );

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

ShippingMgmt.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ShippingMgmt));
