import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";
// import _ from "lodash";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./AnItemsInventory.css";

import InventoryMove from "./mgmt/InventoryMove";
// import ItemComposition from "./mgmt/ItemComposition";
import ApDepriciate from "./mgmt/ApDepriciate";
// import WriteOff from "./mgmt/WriteOff";
import Production from "./digitalContent/Production";
import StockUp from "./stockgoods/StockUp";
import CreateSubItems from "./mgmt/CreateSubItems";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getItemToEdit = "/routes/dashboard/getItemToEdit?";
const getAnItemFromIM = "/routes/dashboard/getAnItem?";
const saveAnItemInCatalog = "/routes/dashboard/saveAnItemInCatalog";
const getCatalogPkgForDelivery = "/routes/dashboard/getCatalogPkgForDelivery?";

const readMeCode = "1111121000";

class AnItemsInventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdjustPanelFlag: true,
      inventoryMsgErrFlag: false,
      itemName: "",
      itemId: 0,
      itemType: "Goods",
      itemPrice: 0,
      adjustType: "Add",
      inventoryType: "catalog",
      inventory: 0,
      adjInventory: 0,
      costOfInventory: 0.0,
      adjustmentComment: "",
      inventoryMsg: "Enter adjust inventory qty and adjustment type.",

      itemDescriptionMsg: "",
      itemDecriptionErrFlag: false,

      adjComment: "",
      itemDescription: "",
      picItemImageUrl: "",
      showImgFlag: false,
      noImgFlag: false,

      successPanelFlag: false,
      errorPanelFlag: false,
      errorMsg: "",

      catalog: null,

      deviceSize: "",

      warehouseInv: 0,
      catalogInv: 0,

      inventoryMoveFlag: false,
      itemCompositionFlag: false,
      composeOkFlag: false,
      produceOkFlag: false,
      apDepriciateButtonFlag: false,
      apDepriciateFlag: false,
      subItemsPanelFlag: false,

      productionFlag: false,

      writeOffFlag: true,
      stockUpFlag: false,
      // showWriteOffPanelFlag: false,
      stockUpPanelFlag: false,
      // invOrganizerFlag: false,

      s3url: "",
      catalogedMsg: "",
      catalogMsgFlag: false,

      justCataloged: false,
      movingAvgCostPerUnit: 0,
      lastPerUnitCost: 0,
      item: null,
      quantity: 0,

      canCancelBefore: 0,
      catalogInventory: 0,

      catalogitFlag: false,
      catalogitMsg: "",

      isNewSubItem: false,
      subItemAllowedFlag: true,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    let showImgFlag = false;
    let noImgFlag = false;
    let s3url = "";
    // let catalogInv = 0;
    let catalogedMsg = "";
    let catalogMsgFlag = false;
    let subItemAllowedFlag = true;
    if (this.props.item.categoryOf === "catalog") {
      await this.getCatalogItem();
      // console.log("this.state.catalog >>>  ", this.state.catalog);
      if (this.state.catalog) {
        // console.log("here in catalog");
        if (this.state.catalog.fileUploads) {
          this.state.catalog.fileUploads.forEach((obj) => {
            if (obj.type === "image") {
              s3url = obj.location;
              showImgFlag = true;
            }
          });
          if (this.state.catalog.subscription.allowPeriodicDelivery ) {
            subItemAllowedFlag = false;
          }
        }
        // catalogInv = this.state.catalog.currentInventory;
        if (!showImgFlag) noImgFlag = true;
        catalogedMsg = "Cataloged. CatalogIt again for new sub-items.";
        catalogMsgFlag = true;
      } else {
        if (this.props.item.fileUploads.length > 0) {
          s3url = this.props.item.fileUploads[0].location;
          noImgFlag = false;
          showImgFlag = true;
        } else {
          noImgFlag = true;
          showImgFlag = false;
        }
        catalogedMsg = "New Item; it has NOT been cataloged yet.";
        catalogMsgFlag = false;
      }
    } else if (
      this.props.item.fileUploads &&
      this.props.item.fileUploads.length > 0
    ) {
      showImgFlag = true;
      s3url = this.props.item.fileUploads[0].location;
    } else {
      showImgFlag = false;
      noImgFlag = true;
    }

    let apDepriciateButtonFlag = false;

    let itemType = this.state.itemType;
    if (this.props.item.itemType === "service") {
      // composeOkFlag = false;
      itemType = "service";
    }

    let produceOkFlag = false;
    if (this.props.item.itemType === "education") {
      // composeOkFlag = false;
      produceOkFlag = true;
      itemType = "education";
    }

    let stockUpFlag = false;
    if (
      this.props.item.categoryOf === "catalog" &&
      this.props.item.itemType === "goods" && 
      !this.props.item.pkgComposedFlag 
    ) {
   
      stockUpFlag = true;
    }
    if (this.props.item.categoryOf === "raw-item") stockUpFlag = true;

    let composeOkFlag = false;

    let catalogInventory = 0;
    if (this.state.catalog) {
      if ( this.state.catalog.pkgComposedFlag) {
        let ret = await this.getPkgsPlanned();
        catalogInventory = ret.availableQty;
      } else {
        this.state.catalog.itemVariance.forEach((qobj) => {
          catalogInventory += qobj.quantity;
        });
      }
    } 

    

    let whqty = 0;
    this.props.item.itemVariance.forEach((qty) => {
      whqty += qty.quantity;
    });

    await this.setState({
      deviceSize,
      picItemImageUrl: s3url,
      showImgFlag,
      warehouseInv: whqty,
      catalogInventory,
      noImgFlag,
      apDepriciateButtonFlag,
      composeOkFlag,
      produceOkFlag,
      itemType,
      catalogedMsg,
      catalogMsgFlag,
      stockUpFlag,
      movingAvgCostPerUnit: this.props.item.movingAvgCostPerUnit,
      lastPerUnitCost: this.props.item.movingAvgCostPerUnit,
      quantity: this.props.item.quantity,
      item: this.props.item,
      subItemAllowedFlag
    });

    this.prepForInventoryEntry(this.props.item);
  };

  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getPkgsPlanned = async () => {

    let today = new Date();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    let availableQty = 0;

    let communityId = this.props.communityId;

    let params =
      "communityId=" +
      communityId +
      "&coopStoreId=" +
      this.props.coopStoreId +
      "&itemId=" +
      this.props.item.itemId +
      "&year=" +
      year +
      "&month=" +
      month;
    let url = baandaServer + getCatalogPkgForDelivery + params;
    // console.log("url:", url);
    try {
      let plnret = await axios.get(url);
      // console.log("plnret.data:", plnret.data);
      if (plnret.data.status === "success") {
        let planned = 0;
        if (plnret.data.Msg) {
          planned = plnret.data.Msg.batch.length;
        }

        let errMsg = "";
        let errFlag = false;
        let qty = 0;
        if (planned > 0) {
          plnret.data.Msg.batch.forEach((pk) => {
            qty += pk.batchQty;
          });
        }
        // console.log("qty:", qty);
        availableQty = qty;

        this.setState({
          errMsg,
          errFlag,
        });
      } else {
        this.setState({
          errMsg: plnret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }

    if ( this.state.catalog) {
      availableQty = availableQty - this.state.catalog.pkgNoOfSubscriptionReqs;
    }  

    let ret = {
      availableQty,
      onLoadPkgAvailability: availableQty,
    };

    // console.log(">>>>> ret:", ret);

    return ret;
  };

  getAnItemFromItemMaster = async () => {
    let param =
      "requestBy=itemId&itemId=" +
      this.props.item.itemId +
      "&clientProgram=AnItemsInventory&clientFunction=getAnItemFromItemMaster";
    try {
      let url = baandaServer + getAnItemFromIM + param;
      // console.log("url:", url);
      let itret = await axios.get(url);
      // console.log("itret:", itret);
      if (itret.data.status === "success") {
        await this.setState({
          errorPanelFlag: false,
          errorMsg: "",
          item: itret.data.Msg[0],
        });
      } else {
        await this.setState({
          errorPanelFlag: true,
          errorMsg: itret.Msg,
        });
      }
    } catch (err) {
      console.log("getAnItemFromItemMaster err:", err.message);
    }
  };

  getCatalogItem = async () => {
    let param =
      "communityId=" +
      this.props.communityId +
      "&itemId=" +
      this.props.item.itemId;
    let url = baandaServer + getItemToEdit + param;
    // console.log("getCatalogItem url:", url);
    try {
      let catret = await axios.get(url);
      // console.log("---->> catret:", catret);
      if (catret.data.Msg.length > 0) {
        await this.setState({
          catalog: catret.data.Msg[0],
        });
      } else {
        await this.setState({
          catalog: null,
        });
      }
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  prepForInventoryEntry = async (data) => {
    // console.log("prepForInventoryEntry data:", data);
    let inputFlg = false;
    let adjInventory = 0;
    let adjtypeflag = "all";
    let adjtype = "Add";
    let adjmsg = "Enter adjust inventory qty and adjustment type.";
    let type = false;

    await this.setState({
      adjustmentTypeFlag: adjtypeflag,
      inventoryInputFlag: inputFlg, // This makes Adjust enterable or just 1
      showAdjustPanelFlag: true,
      itemName: data.itemName,
      itemId: data.itemId,
      // itemType: data.itemType,
      adjustType: adjtype,
      inventory: data.currentInventory,
      adjInventory: adjInventory, // Sets the value to 1 if not  Goods
      costOfInventory: 0.0,
      adjustmentComment: "",
      inventoryMsg: adjmsg,
      //   inventoryUpdateSuccessFlag: false,
      digitalItemFlag: type,
      itemPrice: data.itemPrice,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({
      [e.target.name]: e.target.value,
    });
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  onChangeCurrency = async (e) => {
    let value = e.target.value;
    let namei = [e.target.name];
    // console.log('namei[0]:', namei[0], ' this.state.adjustType.toLowerCase():',  this.state.adjustType.toLowerCase(), ' value:', value, ' this.props.item.currentInventory:', this.props.item.currentInventory);
    if (
      namei[0] === "adjInventory" &&
      this.state.adjustType.toLowerCase() === "subtract" &&
      value > this.props.item.currentInventory
    ) {
      await this.setState({
        inventoryMsg: "Error: Cannot subtract more than you have.",
        inventoryMsgErrFlag: true,
      });
    } else {
      if (this.countDecimals(value) > 2) {
        let tmp = parseFloat(value).toFixed(2);
        let val = parseFloat(tmp);
        await this.setState({
          [e.target.name]: val,
          inventoryMsg: "Enter adjust inventory qty and adjustment type.",
          inventoryMsgErrFlag: false,
        });
      } else {
        await this.setState({
          [e.target.name]: value,
          inventoryMsg: "Enter adjust inventory qty and adjustment type.",
          inventoryMsgErrFlag: false,
        });
      }
    }
  };

  handleAdjustType = async (e) => {
    await this.setState({
      adjustType: e.target.value,
    });
  };

  returnToItemList = () => {
    this.props.returnToCaller();
  };

  handleMove = async () => {
    await this.setState({
      showAdjustPanelFlag: false,
      inventoryMoveFlag: true,
    });
  };

  handleCompose = async () => {
    await this.setState({
      showAdjustPanelFlag: false,
      inventoryMoveFlag: false,
      itemCompositionFlag: true,
    });
  };

  returnFromMove = async () => {
    await this.getAnItemFromItemMaster();
    await this.getCatalogItem();

    let catalogInventory = 0;
    if (this.state.catalog) {
      this.state.catalog.itemVariance.forEach((qobj) => {
        catalogInventory += qobj.quantity;
      });
    }
    // console.log(
    //   "returnFromMove  this.state.item.quantity:",
    //   this.state.item.quantity,
    //   " catalogInventory:",
    //   catalogInventory
    // );
    await this.setState({
      warehouseInv: this.state.item.quantity,
      catalogInventory,
      showAdjustPanelFlag: true,
      inventoryMoveFlag: false,
      itemCompositionFlag: false,
    });
  };

  returnFromComposition = async (exit) => {
    // console.log("Return from composer exit:", exit);
    await this.getAnItemFromItemMaster();
    if (exit.status === "composed") {
      let whq = this.state.warehouseInv;
      await this.setState({
        showAdjustPanelFlag: true,
        composeOkFlag: false,
        warehouseInv: parseFloat(whq) + parseFloat(exit.composedQty),
      });
    } else {
      await this.setState({
        showAdjustPanelFlag: true,
      });
    }

    await this.setState({
      showAdjustPanelFlag: true,
      inventoryMoveFlag: false,
      itemCompositionFlag: false,
    });
  };

  handleDigitialProduction = async () => {
    await this.setState({
      showProductionFlag: true,
    });
  };

  handleDeppAppreciate = async () => {
    await this.setState({
      inventoryMoveFlag: false,
      itemCompositionFlag: false,
      composeOkFlag: false,
      apDepriciateFlag: true,
      stockUpPanelFlag: false,
      showAdjustPanelFlag: false,
    });
  };

  handleStockUp = async () => {
    await this.setState({
      inventoryMoveFlag: false,
      itemCompositionFlag: false,
      composeOkFlag: false,
      apDepriciateFlag: false,
      stockUpPanelFlag: true,
      showAdjustPanelFlag: false,
    });
  };

  returnFromStockUp = async (item) => {
    await this.getAnItemFromItemMaster();
    await this.setState({
      inventoryMoveFlag: false,
      itemCompositionFlag: false,
      composeOkFlag: false,
      apDepriciateFlag: false,
      stockUpPanelFlag: false,
      showAdjustPanelFlag: true,
      warehouseInv: item.quantity,
      movingAvgCostPerUnit: item.movingAvgCostPerUnit,
      lastPerUnitCost: item.movingAvgCostPerUnit,
      quantity: item.quantity,
    });
  };

  returnFromApDepriciate = async (ret) => {
    // console.log("returnFromApDepriciate ret:", ret);
    if (ret) {
      await this.setState({
        inventoryMoveFlag: false,
        itemCompositionFlag: false,
        composeOkFlag: false,
        apDepriciateFlag: false,
        showAdjustPanelFlag: false,
        stockUpPanelFlag: false,
      });
      this.props.returnToCaller();
    } else {
      await this.setState({
        inventoryMoveFlag: false,
        itemCompositionFlag: false,
        composeOkFlag: true,
        apDepriciateFlag: false,
        stockUpPanelFlag: false,
        // invOrganizerFlag: false,
        showAdjustPanelFlag: true,
      });
    }
  };


  handleCatalog = async () => {
    let tpi = this.state.item;
    let isNewInCatalog = false;
    let quantity = 0;
    await this.getCatalogItem();
    // console.log("this.state.catalog:", this.state.catalog);
    if (this.state.catalog === null) {
      isNewInCatalog = true;
    } else {
      if (tpi.itemType === "service") {
        quantity = 1; // Catalog and itemMaster do not have subitems not non-goods
      }
    }

    let itemVar = [];

    if (tpi.itemType === "goods") {
      tpi.itemVariance.forEach((obj) => {
        let qty = 0;
        let catLastCost = 0;
        let catLastMvAvg = 0;
        let isNew = true;
        if (this.state.catalog) {
          this.state.catalog.itemVariance.forEach((elm) => {
            if (obj.seqno === elm.seqno) {
              isNew = false;
              qty = elm.quantity;
              catLastCost = elm.lastcost;
              catLastMvAvg = elm.movingAvgCost;
            }
          });
        }
        if (isNew) {
          qty = 0;
        }
        let ivObj = {
          image: obj.image,
          seqno: obj.seqno,
          name: obj.name,
          options: obj.options,
          quantity: qty,
          lastcost: catLastCost,
          movingAvgCost: catLastMvAvg,
          opsState: obj.opsState,
        };
        itemVar.push(ivObj);
      });
    }

    let service = null;
    if (tpi.itemType === "service") {
      service = {
        type: tpi.service.type,
        scheduleType: tpi.service.scheduleType,
        customFirstResponder: null,
        taggedTemplateId: 0,
        customQuotingFee: tpi.service.customQuotingFee,
        isQuoteFeeInclusive: true,
        bookingType: "none",
        renderedVia: "inperson",
        reqInvitation: "no",
        specialInstruction: "",
        canCancelBefore: 0,
        cancelFeePercent: 0,
        cancelNote: "",
      };
    }

    let pkgComposedFlag = false,
    pkgComposedOfItems = [];
    if ( this.props.item.pkgComposedFlag ) {
      pkgComposedFlag = true;
      pkgComposedOfItems = this.props.item.pkgComposedOfItems
    }

    let catalogItInp = {
      isNewInCatalog,
      communityId: this.props.communityId,
      coopStoreId: this.props.coopStoreId,
      coopStoreName: this.props.coopStoreName,
      itemId: tpi.itemId,
      itemName: tpi.itemName,
      commName: this.props.communityName,
      itemDescription: tpi.itemDescription,
      itemCategory: tpi.category,
      canSellInFraction: tpi.canSellFraction,
      itemType: tpi.itemType,
      service,
      itemVariance: itemVar,
      quantity,
      unitName: tpi.unitName,
      pkgComposedFlag,
      pkgComposedOfItems,
      pkgComposedShippingCost: tpi.pkgComposedShippingCost,
      baandaId: this.props.auth.user.baandaId,
    };

    // console.log("catalogItInp:", catalogItInp);

    try {
      let url = baandaServer + saveAnItemInCatalog;
      // console.log("url:", url, " catalogItInp:", catalogItInp);
      let catret = await axios.post(url, catalogItInp);
      if (catret.data.status === "success") {
        await this.setState({
          errorMsg: "Updated successfully",
          errorPanelFlag: false,
          justCataloged: true,
          catalogedMsg: "Cataloged. CatalogIt again for new sub-items.",
          catalogMsgFlag: true,
          catalog: catret.data.Msg,
          isNewSubItem: false,
        });
      } else {
        await this.setState({
          errorMsg: catret.data.Msg,
          errorPanelFlag: true,
          justCataloged: false,
        });
      }
    } catch (err) {
      console.log("Err:", err.message);
    }
  };

  handleProduction = async () => {
    await this.setState({
      productionFlag: true,
      showAdjustPanelFlag: false,
    });
  };

  returnFromProduction = async () => {
    await this.setState({
      productionFlag: false,
      showAdjustPanelFlag: true,
    });
  };

  handleSubItems = async () => {
    await this.setState({
      subItemsPanelFlag: true,
    });
  };

  returnFromSubItems = async (modified) => {
    // console.log("returnFromSubItems modified:", modified);
    await this.getAnItemFromItemMaster();

    let isNewSubItem = false;
    if (modified) isNewSubItem = true;

    await this.setState({
      subItemsPanelFlag: false,
      isNewSubItem,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('AnItemsInventory...');

    let ip = this.props.item;

    let successPanel;
    if (this.state.successPanelFlag) {
      successPanel = (
        <div className="success-box">
          <div className="text-center success-text">
            Inventory updated successfully
          </div>
          <div className="text-center">
            <button
              className="btn-adjust-ok"
              type="button"
              onClick={this.returnToItemList}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <b>OK</b>
            </button>
          </div>
        </div>
      );
    }

    let catalogItPanel;
    if (this.state.errorPanelFlag) {
      catalogItPanel = (
        <div
          className={
            this.state.catalogitFlag
              ? "text-center catalogit-msg"
              : "catalogit-msg-err"
          }
        >
          <b>Error</b>&nbsp;{this.state.catalogitMsg}
        </div>
      );
    }

    let production, catalogitem, ciate, stock, subtype; // writeoff;
    if (this.state.deviceSize === "small") {
      production = "Prod";
      // writeoff = "WOff";
      catalogitem = "Catl";
      ciate = "`cte";
      stock = "Stok";
      subtype = "SItm";
    } else {
      production = "Production";
      // writeoff = "WriteOff";
      catalogitem = "CatalogIt";
      ciate = "`ciate";
      stock = "StockUp";
      subtype = "SubItem";
    }

    let subItemsButton;
    if (
      this.props.item.itemType === "goods" &&
      this.props.item.categoryOf === "catalog" &&
      this.state.subItemAllowedFlag &&
      !this.props.item.pkgComposedFlag
    ) {
      subItemsButton = (
        <button
          className="btn_reg_70"
          type="button"
          onClick={this.handleSubItems}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          {subtype}
        </button>
      );
    }

    let buttonPanel = (
      <div>
        {this.state.produceOkFlag ? (
          <button
            className="btn_reg_80"
            type="button"
            onClick={this.handleProduction}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {production}
          </button>
        ) : null}
        {this.state.apDepriciateButtonFlag ? (
          <button
            className="btn_reg_60"
            type="button"
            onClick={this.handleDeppAppreciate}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {ciate}&nbsp;
            <i className="fa fa-th-large" />
          </button>
        ) : null}

        {this.state.catalog !== null &&
        this.props.item.itemType !== "service" && !this.props.item.pkgComposedFlag
        ? (
          <button
            className="btn_reg_60"
            type="button"
            onClick={this.handleMove}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            Move
          </button>
        ) : null}
        {this.props.item.categoryOf === "catalog" ? (
          <button
            className="btn_reg_70"
            type="button"
            onClick={this.handleCatalog}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {catalogitem}
          </button>
        ) : null}
        {/* {this.props.item.itemType === "goods" &&  !this.props.item.pkgComposedFlag ?  subItemsButton : null} */}
        {subItemsButton}
        {this.props.item.itemType === "goods" && this.state.stockUpFlag ?  (
          <button
            className="btn_reg_70"
            type="button"
            onClick={this.handleStockUp}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {stock}
          </button>
        ) : null}
 
        <button
          className="btn_back_main"
          type="button"
          onClick={this.returnToItemList}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-step-backward" />
        </button>
        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
      </div>
    );

    let invHead;
    if (this.state.deviceSize === "small") {
      invHead = "Inventory";
    } else {
      invHead = "Inventory Adjustment";
    }

    let getInventoryPanel;
    if (
      this.props.item.itemType !== "service" &&
      this.props.item.itemType !== "education"
    ) {
      getInventoryPanel = (
        <div>
          <div className="row inventory_row_placement">
            <div className="col-md-6 text-center item-dispx-inv ">
              <b>
                Qty in Warehouse:&nbsp;
                {this.state.warehouseInv}&nbsp;(unit: {ip.unitName})
              </b>
            </div>
            <div className="col-md-6 text-center item-dispx-inv ">
              {this.state.catalog ? (
                <b>
                  Qty in Catalog:&nbsp;
                  {this.state.catalogInventory}
                </b>
              ) : null}
            </div>
          </div>
        </div>
      );
    }
    // }

    let adjustPanel;
    if (this.state.showAdjustPanelFlag) {
      adjustPanel = (
        <div className="inv-detail-box">
          <div className="row">
            <div className="col text-center adjust_panel_header">{invHead}</div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center item-dispx-inv">
              <b>Name: </b> {ip.itemName} &nbsp;&nbsp;
            </div>
            {this.props.item.itemType === "service" ||
            this.props.item.itemType === "education" ? (
              <div className="col-md-6 text-center item-dispx-inv">
                <b>Id: </b>
                {ip.itemId}&nbsp;&nbsp;(Set the fee in catalog)
              </div>
            ) : (
              <div className="col-md-6 text-center item-dispx-inv">
                <b>Id: </b>
                {ip.itemId}&nbsp;&nbsp;&nbsp;<b>Total Cost: $</b>
                {this.commaFormattedCurrency(
                  parseFloat(this.state.movingAvgCostPerUnit) *
                    parseFloat(this.state.quantity)
                )}
              </div>
            )}
          </div>
          <div className="row inventory_row_img">
            <div className="col-md-6 text-right item-description">
              <p align="justify">
                <b>Description:&nbsp;</b>
                {ip.itemDescription}
              </p>
            </div>
            {this.props.item.itemType === "service" ||
            this.props.item.itemType === "education" ? (
              <div className="col-md-6 text-center item-dispx-inv">
                {this.props.item.itemType === "service"
                  ? "Load service offering image/video during the Catalog setting."
                  : "Load your education carriculum via Prduction."}
              </div>
            ) : (
              <div className="col-md-6 text-center item-dispx-inv">
                {/* {this.state.showImgFlag ? dispImgPanel : noImgPanel} */}
                Load item images via SubItem
              </div>
            )}
          </div>
          {getInventoryPanel}
          <div className="row">
            <div
              className={
                this.state.catalogMsgFlag
                  ? "col-md-6 text-center item-catalog-state"
                  : "col-md-6 text-center item-catalog-state-err"
              }
            >
              {this.props.item.categoryOf === "asset" ? (
                "This is an asset - not for catalog."
              ) : (
                <p align="justify">
                  <b>Catalog State:</b>&nbsp;{this.state.catalogedMsg}{" "}
                  {this.state.isNewSubItem ? (
                    <font color="black" size="3">
                      <b>Please click CatalogIt to update the catalog.</b>
                    </font>
                  ) : null}
                </p>
              )}
            </div>
            <div className="col-md-6 text-center item-dispx-inv">&nbsp;</div>
          </div>
          {this.props.item.itemType === "service" ||
          this.props.item.itemType === "education" ? null : (
            <div className="row">
              <div className="col text-center cost-disp">
                <b>Per unit</b>&nbsp;moving average cost is $
                <b>
                  {this.commaFormattedCurrency(
                    parseFloat(this.state.movingAvgCostPerUnit)
                  )}
                </b>
              </div>
            </div>
          )}
          <div className="row inventory_msg_placement">
            <div className="col text-center">{buttonPanel}</div>
          </div>
          <div>{catalogItPanel}</div>
          <div
            className={
              this.state.errFlag
                ? "text-center an-item-inv-msg-err"
                : "text-center an-item-inv-msg"
            }
          >
            {this.state.errorMsg}
          </div>
        </div>
      );
    }

    let coopStoreId = 0;
    if (this.props.coopStoreId > 0) coopStoreId = this.props.coopStoreId;

    let inventoryMovePanel;
    if (this.state.inventoryMoveFlag) {
      inventoryMovePanel = (
        <div>
          <InventoryMove
            returnToCaller={this.returnFromMove}
            baandaId={this.props.auth.user.baandaId}
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            itemId={this.props.item.itemId}
            s3Url={this.state.picItemImageUrl}
            warehouseInv={this.state.warehouseInv}
            catalogInv={this.state.catalogInventory}
            unitName={this.props.item.unitName}
          />
        </div>
      );
    }

    let ciatePanel;
    if (this.state.apDepriciateFlag) {
      ciatePanel = (
        <div>
          <ApDepriciate
            returnToCaller={this.returnFromApDepriciate}
            baandaId={this.props.auth.user.baandaId}
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            item={this.props.item}
          />
        </div>
      );
    }

    // let writeOffPanel;
    // if (this.state.showWriteOffPanelFlag) {
    //   writeOffPanel = (
    //     <div>
    //       <WriteOff
    //         returnToCaller={this.returnFromWriteOff}
    //         baandaId={this.props.auth.user.baandaId}
    //         communityId={this.props.communityId}
    //         coopStoreId ={coopStoreId}
    //         item={this.props.item}
    //       />
    //     </div>
    //   );
    // }

    // Associated with production of digital content (learning center)
    let productionPanel;
    if (this.state.productionFlag) {
      productionPanel = (
        <div>
          <Production returnToCaller={this.returnFromProduction} />
        </div>
      );
    }

    let stockUpPanel;
    if (this.state.stockUpPanelFlag) {
      stockUpPanel = (
        <div>
          <StockUp
            returnToCaller={(item) => this.returnFromStockUp(item)}
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            item={this.props.item}
          />
        </div>
      );
    }

    let subItemsPanel;
    if (this.state.subItemsPanelFlag) {
      subItemsPanel = (
        <div>
          <CreateSubItems
            returnToCaller={(inp, modified) =>
              this.returnFromSubItems(inp, modified)
            }
            communityId={this.props.communityId}
            coopStoreId={coopStoreId}
            item={this.state.item}
          />
        </div>
      );
    }

    let outputPanel;
    if (this.state.successPanelFlag) {
      outputPanel = <div>{successPanel}</div>;
    }
    if (this.state.showAdjustPanelFlag) {
      outputPanel = <div>{adjustPanel}</div>;
    }
    if (this.state.inventoryMoveFlag) {
      outputPanel = <div>{inventoryMovePanel}</div>;
    }

    if (this.state.apDepriciateFlag) {
      outputPanel = <div>{ciatePanel}</div>;
    }

    // if (this.state.showWriteOffPanelFlag) {
    //   outputPanel = <div>{writeOffPanel}</div>;
    // }

    if (this.state.productionFlag) {
      outputPanel = <div>{productionPanel}</div>;
    }

    if (this.state.stockUpPanelFlag) {
      outputPanel = <div>{stockUpPanel}</div>;
    }

    if (this.state.subItemsPanelFlag) {
      outputPanel = <div>{subItemsPanel}</div>;
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

AnItemsInventory.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AnItemsInventory));
