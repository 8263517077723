import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import "./CustomerMain.css";

import CustomerReturnMgmt from "./returns/CustomerReturnMgmt";
import TaskHandler from "./tasks/TaskHandler";
import CustomerReportLanding from "../../../../customerReports/customerReportLanding";
import CustomerPayPending from "./pending/CustomerPayPending";
import CustPayCtrlList from "./payctrl/CustPayCtrlList";

import SelectCoopStore from "../../coop/select/SelectCoopStore";

const readMeCode = "1110111111";

class CustomerMain extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      returnsAdminFlag: false,
      tasksFlag: false,
      customerReportFlag: false,
      pymentPendingFlag: false,
      payCtrlFlag: false,

      selectStoreFlag: false,
      showAllButtons: false,

      store: null,
      coopStoreId: 0
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let selectStoreFlag = false;
    let pymentPendingFlag = true;
    let showAllButtons = true;
    if (this.props.community.communityType === "coop") {
      // console.log("in here ... CDM...");
      selectStoreFlag = true;
      pymentPendingFlag = false;
      showAllButtons = false;
    }

    let coopStoreId = 0;
    if ( this.props.store ) coopStoreId = this.props.store.coopStoreId;

    await this.setState({
      deviceSize,
      selectStoreFlag,
      pymentPendingFlag,
      showAllButtons,
      coopStoreId
    });
  };

  openAlertModal = async () => {
 
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  getTasksOfTheCustomer = async () => {};

  handleSelectedFunction = async (selected) => {
    // console.log("selected:", selected);
    await this.setState({
      returnsAdminFlag: false,
      tasksFlag: false,
      customerReportFlag: false,
      pymentPendingFlag: false,
      payCtrlFlag: false,
      selectStoreFlag: false,
    });

    if (selected === "paypending") {
      await this.setState({
        pymentPendingFlag: true,
      });
    }
    if (selected === "returns") {
      await this.setState({
        returnsAdminFlag: true,
      });
    }
    if (selected === "tasks") {
      await this.setState({
        tasksFlag: true,
      });
    }
    if (selected === "reports") {
      await this.setState({
        customerReportFlag: true,
      });
    }
    if (selected === "payctrl") {
      await this.setState({
        payCtrlFlag: true,
      });
    }
    if (selected === "stores") {
      await this.setState({
        selectStoreFlag: true,
        showAllButtons: false,
      });
    }
  };

  returnFromStoreSelect = async (obj) => {
    // console.log("obj:", obj);
    let showAllButtons = true;
    if ( !obj ) showAllButtons = false;
    await this.setState({
      showAllButtons,
      // selectStoreFlag: false,
      // tasksFlag: true,
      store:obj
    });
  };

  

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);
    console.log('CustomerMain...');

    let ret, tasks, reports, pending, payctrl, select;
    if (this.state.deviceSize === "small") {
      ret = "Rtn";
      tasks = "Tsk";
      reports = "Rpt";
      pending = "Pnd";
      payctrl = "Ctl";
      select = "Str";
    } else {
      ret = "Returns";
      tasks = "Tasks";
      reports = "Reports";
      pending = "Pending";
      payctrl = "PayCtrl";
      select = "Stores";
    }

    let coopName = "Select a store to operate";
    console.log('this.state.store:', this.state.store);
    // console.log('this.state.store.length:', this.state.store)
    if (this.state.store ) {
      console.log('this.state.store.coopStoreName:', this.state.store.coopStoreName)
      coopName = this.state.store.coopStoreName;
    }
    console.log('coopName:', coopName);

    let showCoopName = (
      <div className="text-center customer-coop-store-name">{coopName}</div>
    );

    let customerButtons;
    customerButtons = (
      <div className="text-center customer-button-pos">
        {this.state.showAllButtons ? (
          <button
            className={`${
              this.state.pymentPendingFlag ? "btn_reg_70_active" : "btn_reg_70"
            }`}
            type="button"
            onClick={() => this.handleSelectedFunction("paypending")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {this.state.deviceSize === 'big' ? pending : <i className="fas fa-file-invoice-dollar" />}
          </button>
        ) : null}

        {this.state.showAllButtons ? (
          <button
            className={`${
              this.state.returnsAdminFlag ? "btn_reg_70_active" : "btn_reg_70"
            }`}
            type="button"
            onClick={() => this.handleSelectedFunction("returns")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {this.state.deviceSize === 'big' ? ret : <i className="fas fa-exchange-alt" />}
          </button>
        ) : null}

        {this.state.showAllButtons ? (
          <button
            className={`${
              this.state.tasksFlag ? "btn_reg_70_active" : "btn_reg_70"
            }`}
            type="button"
            onClick={() => this.handleSelectedFunction("tasks")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {this.state.deviceSize === 'big' ? tasks : <i className="fas fa-tasks" />}
          </button>
        ) : null}

        {this.state.showAllButtons ? (
          <button
            className={`${
              this.state.customerReportFlag ? "btn_reg_70_active" : "btn_reg_70"
            }`}
            type="button"
            onClick={() => this.handleSelectedFunction("reports")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {this.state.deviceSize === 'big' ? reports : <i className="fas fa-paste" />} 
          </button>
        ) : null}

        {this.state.showAllButtons && this.state.coopStoreId === 0 ? (
          <button
            className={`${
              this.state.payCtrlFlag ? "btn_reg_70_active" : "btn_reg_70"
            }`}
            type="button"
            onClick={() => this.handleSelectedFunction("payctrl")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {this.state.deviceSize === 'big' ? payctrl : <i className="fas fa-gamepad" />}
          </button>
        ) : null}

        {(this.state.showAllButtons || this.state.selectStoreFlag) && this.props.community.communityType === 'coop' ? (
          <button
            className={`${
              this.state.selectStoreFlag ? "btn_reg_70_active" : "btn_reg_70"
            }`}
            type="button"
            onClick={() => this.handleSelectedFunction("stores")}
            style={{ cursor: this.state.disabled ? "default" : "pointer" }}
          >
            {this.state.deviceSize === 'big' ? select : <i className="fas fa-check" /> }
          </button>
        ) : null}

        <button
          className="btn_info_main"
          type="button"
          onClick={this.openAlertModal}
          style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        >
          <i className="fas fa-info-circle" />
        </button>
      </div>
    );

    let store = null;
      if ( this.state.store ) store = this.state.store;

    let outputPanel;
    if (this.state.returnsAdminFlag) {
      let store = null;
      if ( this.state.store ) store = this.state.store;
      outputPanel = (
        <div>
          {customerButtons}
          {this.props.community.communityType === "coop" ? showCoopName : null}
          <div>
            <CustomerReturnMgmt
              communityId={this.props.communityId}
              community={this.props.community}
              store={store}
            />
          </div>
        </div>
      );
    }

    if (this.state.tasksFlag) {
      outputPanel = (
        <div>
          {customerButtons}
          {this.props.community.communityType === "coop" ? showCoopName : null}
          <div>
            <TaskHandler
              communityId={this.props.communityId}
              community={this.props.community}
              store={store}
            />
          </div>
        </div>
      );
    }

    if (this.state.customerReportFlag) {
      outputPanel = (
        <div>
          {customerButtons}
          {this.props.community.communityType === "coop" ? showCoopName : null}
          <div>
            <CustomerReportLanding
              communityId={this.props.communityId}
              community={this.props.community}
              store={store}
            />
          </div>
        </div>
      );
    }

    if (this.state.pymentPendingFlag) {
      outputPanel = (
        <div>
          {customerButtons}
          {this.props.community.communityType === "coop" ? showCoopName : null}
          <div>
            <CustomerPayPending
              communityId={this.props.communityId}
              community={this.props.community}
              store={store}
            />
          </div>
        </div>
      );
    }

    if (this.state.payCtrlFlag) {
      outputPanel = (
        <div>
          {customerButtons}
          {this.props.community.communityType === "coop" ? showCoopName : null}
          <div>
            <CustPayCtrlList
              communityId={this.props.communityId}
              community={this.props.community}
              store={store}
            />
          </div>
        </div>
      );
    }

    if (this.state.selectStoreFlag) {
      console.log('going to select store ...')
      console.log('this.props.community.communityType:', this.props.community.communityType);

      outputPanel = (
        <div>
          {customerButtons}
          {this.props.community.communityType === "coop" ? showCoopName : null}
          <div>
            <SelectCoopStore
              returnToCaller={(store) => this.returnFromStoreSelect(store)}
              communityId={this.props.communityId}
            />
          </div>
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <ModalContainer />
      </div>
    );
  }
}

CustomerMain.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerMain)
);
