import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ShowDetailDoc from "./ShowDetailDoc";

import "./ShowDoc.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getLoanInvestDocs = "/routes/dashboard/getLoanInvestDocs?";

class ShowDoc extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loanInvestments: [],
      currentPage: 1, // page selected to show the rows of items on that page
      noOfRowsPerPage: 10, // x (number of rows the show per page - constant)
      noOfButtonsToShow: 6, // y (number of buttons to show at the bottom - constant)
      fistPageOfSetIndex: 1, // f (first line of the set to display - vary -initiate with 1)
      messagesOnScreen: [],

      showpagi: true,
      showPaginationPanelFlag: false,

      showDetailViewFlag: false,
      dvObj: {},
      deviceSize: "",
    };
  }

  componentDidMount = async () => {
    console.log("loanInvestments componentDidMount....");
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";
    await this.setState({
      //   loanInvestments: this.props.drafts,
      //   calledFor: this.props.invokedBy,
      deviceSize,
    });
    await this.getData();
    await this.setPosts();
    await this.setPageNumbers();
  };

  getData = async () => {
    let params =
      "communityId=" +
      this.props.communityId +
      "&clientProgram=ShowDocs&clientFunction=getLoanInvestmentDocs";

    let url = baandaServer + getLoanInvestDocs + params;
    console.log("url:", url);
    try {
      let ret = await axios.get(url);
      console.log("ret:", ret);
      await this.setState({
        loanInvestments: ret.data.Msg,
      });
    } catch (err) {
      console.log("err:", err.message);
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  // Pagination functions start
  // =====================================================================
  setPageNumbers = async () => {
    let tlen = this.state.loanInvestments.length; // tlen = total length of cart array to be picked up
    let f = this.state.fistPageOfSetIndex;
    let x = this.state.noOfRowsPerPage;
    let y = this.state.noOfButtonsToShow;
    let right = false,
      left = false;
    let showPaginationPanelFlag = true;

    let z = y; // Dynamic number of buttons to show (vary)
    // let st = 1; // starting page number is 1
    // console.log("1> f:", f, " z:", z, " x:", x, " y:", y, " tlen:", tlen);
    if (tlen <= x) {
      // console.log("here 1 ");
      showPaginationPanelFlag = false;
    } else if (Math.ceil(tlen / x) <= y) {
      // no action needed
      // console.log("here 2 ");
      z = Math.ceil(tlen / x); // z becomes the  number of page  number can be shown
    } else if (f <= 1) {
      // console.log("here 3 ");
      left = false;
      right = true;
    } else if (f + y > Math.ceil(tlen / x)) {
      // Math.ceil(tlen/x) gives the total number of buttons can be shown
      // console.log("here 4 ");
      left = true;
      right = false;
      z = Math.ceil(
        this.state.loanInvestments.length / this.state.noOfRowsPerPage
      ); // end button number in z
      //   st = f; // start button number from f
    } else {
      // console.log("here 5 ");
      left = true;
      right = true;
      //   st = f;
      z = f + y - 1; //
    }

    let pageNumbers = [];
    for (let i = f; i <= z; i++) {
      pageNumbers.push(i);
    }

    await this.setState({
      leftArrowFlag: left,
      rightArrowFlag: right,
      showPaginationPanelFlag,
      pageNumbers,
    });
  };

  setPosts = async () => {
    // console.log(
    //   "INSIDE setPosts this.state.loanInvestments:",
    //   this.state.loanInvestments
    // );
    let indexOfLastMessage =
      this.state.currentPage * this.state.noOfRowsPerPage;
    let indexOfFirstMessage = indexOfLastMessage - this.state.noOfRowsPerPage;
    // console.log(
    //   "indexOfLastMessage:",
    //   indexOfLastMessage,
    //   " indexOfFirstMessage:",
    //   indexOfFirstMessage
    // );
    let slicedMessages;

    if (indexOfLastMessage >= this.state.loanInvestments.length) {
      // console.log("I am here xxxxx");
      slicedMessages = this.state.loanInvestments.slice(indexOfFirstMessage);
      // console.log("slicedMessages:", slicedMessages);
    } else {
      slicedMessages = this.state.loanInvestments.slice(
        indexOfFirstMessage,
        indexOfLastMessage
      );
    }
    await this.setState({
      messagesOnScreen: slicedMessages,
    });
  };

  paginate = async (input) => {
    // console.log("paginate input:", input);
    await this.setState({
      currentPage: input,
    });
    await this.setPosts();
  };

  handleLeft = async () => {
    // console.log("handle Left");
    let ff = this.state.fistPageOfSetIndex - this.state.noOfButtonsToShow;
    if (ff < 1) ff = 1;

    await this.setState({
      fistPageOfSetIndex: ff,
    });

    await this.setPageNumbers();
  };

  handleRight = async () => {
    // console.log("handle Right");
    let ff = this.state.fistPageOfSetIndex + this.state.noOfButtonsToShow;
    if (ff > this.state.loanInvestments.length)
      ff = this.state.loanInvestments.length - this.state.noOfButtonsToShow;

    await this.setState({
      fistPageOfSetIndex: ff,
    });

    await this.setPageNumbers();
  };
  // Pagination functions end
  // =====================================================================
  openLI = async (obj) => {
    console.log("obj:", obj);
    await this.setState({
      showpagi: false,
      showPaginationPanelFlag: false,
      showDetailViewFlag: true,
      dvObj: obj,
    });
  };

  returnFromDetailView = async () => {
    await this.getData();
    await this.setPosts();
    await this.setPageNumbers();

    await this.setState({
      showpagi: true,
      showPaginationPanelFlag: false,
      showDetailViewFlag: false,
    });
  };

  returnToCaller = () => {
    this.props.returnToOpsRouter();
  };

  render() {
    console.log("this.props:", this.props);
    console.log("this.state:", this.state);

    let totalLoanAmt = 0,
      totalInvestAmt = 0,
      i = 1,
      j = 1;
    this.state.loanInvestments.forEach((obj) => {
      console.log(
        "obj.ilType:",
        obj.ilType,
        " investId:",
        obj.investId,
        "  obj.investLoanAmount:",
        obj.investLoanAmount
      );
      if (obj.ilType === "equity") {
        totalInvestAmt = totalInvestAmt + obj.investLoanAmount;
        j++;
      } else {
        totalLoanAmt = totalLoanAmt + obj.investLoanAmount;
      }
      i++;
    });
    console.log(
      "totalLoanAmt:",
      totalLoanAmt,
      " totalInvestAmt:",
      totalInvestAmt,
      " i=",
      i,
      " j=",
      j
    );

    let totLoan, totEquity, subhead;
    if (this.state.deviceSize === "small") {
      totLoan = "Loan";
      totEquity = "Equity";
      subhead = "Loan Name (Type)";
    } else {
      totLoan = "Total Loan";
      totEquity = "Total Equity";
      subhead = "Loan Name -- Party Name (Type) on-Date";
    }

    let showHeader = "Loan - Invoice";
    let buttonPanel = (
      <button
        className="btn-il-show-cancel"
        onClick={this.returnToCaller}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Return
      </button>
    );
    let summaryPanel;
    summaryPanel = (
      <div className="sum-placement">
        <span className="show-pickups-header">{showHeader}</span>
        &nbsp;&nbsp;&nbsp;
        <span className="list-box">
          {totLoan}:&nbsp;$
          {this.commaFormattedCurrency(parseFloat(totalLoanAmt))}
        </span>
        &nbsp;
        <span className="list-box">
          {totEquity}:&nbsp;$
          {this.commaFormattedCurrency(parseFloat(totalInvestAmt))}
        </span>
        &nbsp;&nbsp;&nbsp;
        <span>{buttonPanel}</span>
      </div>
    );

    let bool = true;
    let pagipanel;
    // if (this.state.showpagi && this.props.calledFor === "draft")
    if (this.state.showpagi) {
      // console.log(
      //   "--------->>>> this.state.messagesOnScreen:",
      //   this.state.messagesOnScreen
      // );
      pagipanel = (
        <div>
          <div className="show-pickups-header">{summaryPanel}</div>
          {/* <div className="text-center">{summaryPanel}</div> */}
          <div className="row">
            <div className="col-9 text-left pickup-text-header">{subhead}</div>
            <div className="col-3 text-center pickup-text-header">Ops</div>
          </div>
          {this.state.messagesOnScreen.map((post, i) => (
            <div key={i}>
              <div className={`${bool ? "row-color-a" : "row-color-b"}`}>
                <div className="row">
                  <div className="col-10 text-left pickup-text">
                    {this.state.deviceSize === "small" ? (
                      <div>
                        {post.investmentName.length > 20
                          ? post.investmentName.substring(0, 19) +
                            "(" +
                            post.ilType +
                            ")"
                          : post.investmentName + +"(" + post.ilType + ")"}
                      </div>
                    ) : (
                      <div>
                        {" "}
                        {post.investmentName}&nbsp;--&nbsp;
                        {post.party.originType === "individual"
                          ? post.party.name
                          : post.party.orgName}
                        &nbsp;({post.ilType})&nbsp;
                        {post.createDate.substring(0, 10)}
                      </div>
                    )}
                  </div>
                  <div className="col-2 text-center">
                    <button
                      onClick={() => this.openLI(post)}
                      className="btn-il-show-open"
                      style={{
                        cursor: this.state.disabled ? "default" : "pointer",
                      }}
                    >
                      Open
                    </button>
                  </div>
                </div>
              </div>
              {(bool = !bool)}
            </div>
          ))}
        </div>
      );
    }

    let pageNumberPanel;
    if (this.state.showPaginationPanelFlag) {
      pageNumberPanel = (
        <div>
          <ul className="pagination">
            {this.state.leftArrowFlag ? (
              <li key={0}>
                <button
                  onClick={this.handleLeft}
                  className="btn-leftright"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <i className="fas fa-angle-double-left" />
                </button>
              </li>
            ) : null}
            {this.state.pageNumbers.map((number) => (
              <li key={number + 1}>
                <button
                  onClick={() => this.paginate(number)}
                  className="btn-page-item"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  {number}
                </button>
              </li>
            ))}
            {this.state.rightArrowFlag ? (
              <li key={10000000}>
                <button
                  onClick={this.handleRight}
                  className="btn-leftright"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <i className="fas fa-angle-double-right" />
                </button>
              </li>
            ) : null}
          </ul>
        </div>
      );
    }

    let outputPanel;
    if (this.state.showpagi && this.state.showPaginationPanelFlag) {
      outputPanel = (
        <div>
          {pagipanel}
          <div className="text-center space-above">{pageNumberPanel}</div>
        </div>
      );
    }

    if (this.state.showpagi && !this.state.showPaginationPanelFlag) {
      outputPanel = <div>{pagipanel}</div>;
    }

    if (this.state.showDetailViewFlag) {
      outputPanel = (
        <div>
          <ShowDetailDoc
            returnToCaller={this.returnFromDetailView}
            data={this.state.dvObj}
          />
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

ShowDoc.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

// const mapDispatchToProps = (dispatch) => ({
//   hideModal: () => dispatch(hideModal()),
//   showModal: (modalProps, modalType) => {
//     dispatch(showModal({ modalProps, modalType }));
//   },
// });

// export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ShowDoc));
export default connect(mapStateToProps, null)(withRouter(ShowDoc));
