import React, { Component } from "react";

import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import ModalContainer from "../../../../../modal/components/ModalContainer";
import { showModal, hideModal } from "../../../../../actions/modalActions";
import "../../../../../modal/css/localModal.css";
import "../../../../../modal/css/template.css";

import HandlePickupDetails from "./pickup/HandlePickupDetails";
import PickupFilter from "./pickup/PickupFilter";

import "./Pickup.css";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getDeliveryData = "/routes/dashboard/getDeliveryData?";

const readMeCode = "1111020000";

class Pickups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPaginationPanelFlag: false,

      type: "pickup",
      status: "new",
      communityId: 0,

      shippingData: [],
      errMsg: "",
      errFlag: false,

      pickups: [],
      currentPage: 1, // page selected to show the rows of items on that page
      noOfRowsPerPage: 12, // x (number of rows the show per page - constant)
      noOfButtonsToShow: 6, // y (number of buttons to show at the bottom - constant)
      fistPageOfSetIndex: 1, // f (first line of the set to display - vary -initiate with 1)
      pickupsOnScreen: [],

      showpagi: true,
      showPickupDetails: false,
      showFilter: false,

      rightArrowFlag: false,
      leftArrowFlag: false,

      invoiceSelected: null,
    };
  }

  componentDidMount = async () => {
    await this.listItemsToPickup();
  };

  listItemsToPickup = async () => {
    // console.log('in listItemsToPickup...')
    // console.log("in component did mount.");
    await this.getPickupInfo("tobe", "", "regular");
    // console.log("going to setPosts...");
    await this.setPosts();
    // console.log("going to setPageNumbers");
    await this.setPageNumbers();
  };

  getPickupInfo = async (status, name, itemType, invoiceId) => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let param =
      "type=" +
      this.state.type +
      "&status=" +
      status +
      "&communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&itemType=" +
      itemType +
      "&customerName=" +
      name +
      "&invoiceId=" +
      invoiceId;
    let url = baandaServer + getDeliveryData + param;
    // console.log("getPickupInfo url:", url);
    try {
      let shipret = await axios.get(url);
      // console.log("shipret:", shipret);
      if (shipret.data.status === "success") {
        if (shipret.data.Msg.length > 0) {
          await this.setState({
            // shippingData: shipret.data.Msg,
            pickups: shipret.data.Msg,
          });
        } else {
          await this.setState({
            pickups: [],
            errMsg: "No new pickup requested.",
            errFlag: false,
          });
        }
      } else {
        await this.setState({
          errMsg: shipret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getPickupInfo err:", err.message);
      await this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };
  // This is to show the info panel
  openAlertModal = async () => {
    this.props.showModal(
      {
        open: true,
        infoId: readMeCode,
        closeModal: this.closeModal,
      },
      "showHelp"
    );
  };

  // =============== PAgination BudgetMain
  setPageNumbers = async () => {
    let tlen = this.state.shippingData.length; // tlen = total length of cart array to be picked up
    let f = this.state.fistPageOfSetIndex;
    let x = this.state.noOfRowsPerPage;
    let y = this.state.noOfButtonsToShow;
    let right = false,
      left = false;
    let showPaginationPanelFlag = true;

    let z = y; // Dynamic number of buttons to show (vary)
    // let st = 1; // starting page number is 1
    // console.log("1> f:", f, " z:", z, " x:", x, " y:", y, " tlen:", tlen);
    if (tlen <= x) {
      // console.log("here 1 ");
      showPaginationPanelFlag = false;
    } else if (Math.ceil(tlen / x) <= y) {
      // no action needed
      // console.log("here 2 ");
      z = Math.ceil(tlen / x); // z becomes the  number of page  number can be shown
    } else if (f <= 1) {
      // console.log("here 3 ");
      left = false;
      right = true;
    } else if (f + y > Math.ceil(tlen / x)) {
      // Math.ceil(tlen/x) gives the total number of buttons can be shown
      // console.log("here 4 ");
      left = true;
      right = false;
      z = Math.ceil(this.state.pickups.length / this.state.noOfRowsPerPage); // end button number in z
      //   st = f; // start button number from f
    } else {
      // console.log("here 5 ");
      left = true;
      right = true;
      //   st = f;
      z = f + y - 1; //
    }

    let pageNumbers = [];
    for (let i = f; i <= z; i++) {
      pageNumbers.push(i);
    }

    await this.setState({
      leftArrowFlag: left,
      rightArrowFlag: right,
      showPaginationPanelFlag,
      pageNumbers,
    });
  };

  setPosts = async () => {
    // console.log("INSIDE setPosts this.state.pickups:", this.state.pickups);
    let indexOfLastPickup = this.state.currentPage * this.state.noOfRowsPerPage;
    let indexOfFirstPickup = indexOfLastPickup - this.state.noOfRowsPerPage;
    let slicedPiackups;

    if (indexOfLastPickup >= this.state.pickups.length) {
      slicedPiackups = this.state.pickups.slice(indexOfFirstPickup);
    } else {
      slicedPiackups = this.state.pickups.slice(
        indexOfFirstPickup,
        indexOfLastPickup
      );
    }
    await this.setState({
      pickupsOnScreen: slicedPiackups,
    });
  };

  paginate = async (input) => {
    // console.log("paginate input:", input);
    await this.setState({
      currentPage: input,
    });
    await this.setPosts();
  };

  handleLeft = async () => {
    // console.log("handle Left");
    let ff = this.state.fistPageOfSetIndex - this.state.noOfButtonsToShow;
    if (ff < 1) ff = 1;

    await this.setState({
      fistPageOfSetIndex: ff,
    });

    await this.setPageNumbers();
  };

  handleRight = async () => {
    // console.log("handle Right");
    let ff = this.state.fistPageOfSetIndex + this.state.noOfButtonsToShow;
    if (ff > this.state.pickups.length)
      ff = this.state.pickups.length - this.state.noOfButtonsToShow;

    await this.setState({
      fistPageOfSetIndex: ff,
    });

    await this.setPageNumbers();
  };
  // Pagination End  ===================================================

  handlePickup = async (pickupobj) => {
    // console.log("pickupobj:", pickupobj);
    await this.setState({
      showpagi: false,
      showPickupDetails: true,
      showFilter: false,
      invoiceSelected: pickupobj,
    });
  };

  returnFromHandlingPickup = async () => {
    // console.log("returnFromHandlingPickup ...");
    this.setState({
      showPickupDetails: false,
    });
    await this.listItemsToPickup();
    await this.setState({
      showpagi: true,
      showFilter: false,
    });
  };

  handleFilter = async () => {
    // console.log("in handlefilter");
    await this.setState({
      showpagi: false,
      showPickupDetails: false,
      showFilter: true,
    });
  };

  returnFromFilter = async (obj) => {
    // console.log("obj:", obj);
    if (obj.ops === "filter") {
      await this.setState({
        pickups: [],
        pickupsOnScreen: [],
      });
      await this.getPickupInfo(
        obj.Msg.status,
        obj.Msg.customerName,
        obj.Msg.itemType,
        obj.invoiceId
      );
      await this.setPosts();
      await this.setPageNumbers();
    }
    await this.setState({
      showpagi: true,
      showPickupDetails: false,
      showFilter: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("Pickup...");

    let purposHeader = "";

    let total = this.state.pickups.length;

    let filterButton;
    filterButton = (
      <button
        onClick={this.handleFilter}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_60 str-pickup-filter-btn"
      >
        Filter
      </button>
    );

    let summaryPanel = "";
    summaryPanel = (
      <div className="">
        <span className="pickup-stat-box">{total}&nbsp;to pickup</span>{" "}
        {filterButton}
      </div>
    );

    let pageNumberPanel;
    if (this.state.showPaginationPanelFlag) {
      pageNumberPanel = (
        <div className="text-center">
          <ul className="pagination">
            {this.state.leftArrowFlag ? (
              <li key={0}>
                <button
                  onClick={this.handleLeft}
                  className="btn-leftright"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <i className="fas fa-angle-double-left" />
                </button>
              </li>
            ) : null}
            {this.state.pageNumbers.map((number) => (
              <li key={number + 1}>
                <button
                  onClick={() => this.paginate(number)}
                  className="btn-pickup-page-item"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  {number}
                </button>
              </li>
            ))}
            {this.state.rightArrowFlag ? (
              <li key={10000000}>
                <button
                  onClick={this.handleRight}
                  className="btn-leftright"
                  style={{
                    cursor: this.state.disabled ? "default" : "pointer",
                  }}
                >
                  <i className="fas fa-angle-double-right" />
                </button>
              </li>
            ) : null}
          </ul>
        </div>
      );
    }

    let bool = true;
    let pagipanel;
    if (this.state.showpagi) {
      pagipanel = (
        <div>
          <div className="show-pickups-header">{purposHeader}</div>
          <div className="text-center">{summaryPanel}</div>
          <div className="row">
            <div className="col-8 text-left pickup-text-header">
              Customer Name -- email & (invoiceId)
            </div>
            <div className="col-2 text-center pickup-text-header">Date</div>
            <div className="col-2 text-center pickup-text-header">Ops</div>
          </div>
          <div className="fixedsize_delivery_paginated">
            {this.state.pickupsOnScreen.map((post, i) => (
              <div key={i}>
                <div className={`${bool ? "row-color-a" : "row-color-b"}`}>
                  <div className="row">
                    <div className="col-8 text-left pickup-text">
                      {" "}
                      {post.customerName}&nbsp;-- {post.customerEmail}&nbsp;(
                      {post.invoiceId})
                    </div>
                    <div className="col-2 text-center ship-invoice-date">
                      {post.invoiceDate.substring(0, 10)}
                    </div>
                    <div className="col-2 text-center">
                      <button
                        onClick={() => this.handlePickup(post)}
                        className="btn_reg_60"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        Open
                      </button>
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            ))}
          </div>
        </div>
      );
    }

    let outputPanel;
    if (this.state.showpagi && this.state.showPaginationPanelFlag) {
      outputPanel = (
        <div>
          <div className="text-center shipping-mgmt-header">
            Pickup Management
          </div>
          {pagipanel}
          <div className="text-center ship-pagi-space-above">
            {pageNumberPanel}
          </div>
        </div>
      );
    }

    if (this.state.showpagi && !this.state.showPaginationPanelFlag) {
      outputPanel = (
        <div>
          <div className="text-center shipping-mgmt-header">
            Pickup Management
          </div>
          {pagipanel}
        </div>
      );
    }

    if (this.state.showPickupDetails) {
      outputPanel = (
        <div>
          <HandlePickupDetails
            invoice={this.state.invoiceSelected}
            returnToCaller={this.returnFromHandlingPickup}
            communityId={this.props.communityId}
          />
        </div>
      );
    }

    if (this.state.showFilter) {
      outputPanel = (
        <div>
          <PickupFilter returnToCaller={(obj) => this.returnFromFilter(obj)} />
        </div>
      );
    }

    return (
      <div>
        {outputPanel}
        <div
          className={
            this.state.errFlag ? "text-center pickup-msg-err" : "pickup-msg"
          }
        >
          {this.state.errMsg}
        </div>
        <ModalContainer />
      </div>
    );
  }
}

Pickups.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  hideModal: () => dispatch(hideModal()),
  showModal: (modalProps, modalType) => {
    dispatch(showModal({ modalProps, modalType }));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Pickups));
