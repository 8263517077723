import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import moment from "moment";

import "./ListReceivable.css";

import ShowInvoiceDetails from "./ShowInvoiceDetails";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getListOfPayToReceive = "/routes/dshboard/getListOfPayToReceive?";

class ListReceivable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      receivables: [],

      payReceivable: true,
      invoiceDetailsFlag: false,
      selectedInvoice: null,
      errMsg: "",
      errFlag: false,
    };
  }

  componentDidMount = async () => {
    await this.getPaymentToReceive();
  };

  getPaymentToReceive = async () => {
 

    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;

    let param =
      "communityId=" + this.props.communityId + "&coopStoreId=" + coopStoreId;

    try {
      let url = baandaServer + getListOfPayToReceive + param;
      // console.log("url:", url);

      let ret = await axios.get(url);
      // console.log("getPaymentToReceive ret:", ret.data);
      if (ret.data.status === "success") {
        if (ret.data.Msg.length === 0) {
          this.setState({
            receivables: [],
            errMsg: "No pending invoices now.",
          });
        } else {
          this.setState({
            receivables: ret.data.Msg,
            errMsg: "",
          });
        }
      } else {
        this.setState({
          errFlag: true,
          errMsg: ret.data.Msg,
        });
      }
    } catch (err) {
      this.setState({
        errFlag: true,
        errMsg: err.message,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleInvoiceToPay = (inv) => {
    this.setState({
      payReceivable: false,
      invoiceDetailsFlag: true,
      selectedInvoice: inv
    });
  };

  returnFromInvoiceDetails = () => {
    this.setState({
      payReceivable: true,
      invoiceDetailsFlag: false,
      selectedInvoice: null
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ListReceivable...');

    let tableHeader1, tableHeader2, Open;
    if (this.state.deviceSize === "small") {
      tableHeader2 = "Id - Date | Pay-Amt | Pay-By | State";
      Open = "O";
    } else {
      tableHeader1 = "InvoiceId - Customer ";
      tableHeader2 = "Create Date | Pay-Amt | Pay By | State";
      Open = "Open";
    }

    let pendingInvoicesPanel;
    if (this.state.receivables.length > 0) {
      let bool = true;
      pendingInvoicesPanel = (
        <div>
          <div className="row">
            <div className="col-5 text-left merchant-receive-header">
              {tableHeader1}
            </div>
            <div className="col-5 text-left merchant-receive-header">
              {tableHeader2}
            </div>
            <div className="col-2 text-center inv-pend-header">Ops</div>
          </div>
          <div className="merchant-inv-receivable-box">
            {this.state.receivables.map((post, i) => (
              <div key={i}>
                <div
                  className={`${
                    bool ? "recv-row-color-dr" : "recv-row-color-lt"
                  }`}
                >
                  <div className="row ">
                    <div className="col-5 text-left merchant-recv-lbl1">
                      {post.invoiceId}&nbsp;-&nbsp;{post.customerName}
                    </div>
                    <div className="col-5 text-left merchant-recv-lbl2">
                      {post.created_at.substring(0, 10)}
                      &nbsp;|&nbsp;$
                      {this.commaFormattedCurrency(
                        post.paySchedulePolicy.nextPayAmount
                      )}{" "}
                      |&nbsp;
                      {post.paySchedulePolicy.nextPayBy
                        ? post.paySchedulePolicy.nextPayBy.substring(0, 10)
                        : "Miss pay-by"}
                      &nbsp;|&nbsp;
                      {moment().isAfter(
                        moment(
                          post.paySchedulePolicy.nextPayBy.substring(0, 10)
                        )
                      ) ? (
                        <font color="red">Overdue</font>
                      ) : (
                        <font color="green">To-Recv.</font>
                      )}
                    </div>

                    <div className="col-2 text-center customer-inv-pend-open">
                      <button
                        onClick={() => this.handleInvoiceToPay(post)}
                        className="btn_reg_50_open"
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        {Open}
                      </button>
                    </div>
                  </div>
                </div>
                {(bool = !bool)}
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      pendingInvoicesPanel = <div>No pending invoices at this time. </div>;
    }

    let outputPanel;
    if (this.state.payReceivable) {
      outputPanel = (
        <div>
          <div className="text-center merchant-receive-header">
            Receivables
            {pendingInvoicesPanel}
          </div>
        </div>
      );
    }

    if ( this.state.invoiceDetailsFlag) {
      let store = null;
      if ( this.props.store ) store = this.props.store;
      outputPanel = (
        <div>
           <ShowInvoiceDetails
              returnToCaller={this.returnFromInvoiceDetails}
              theInvoice={this.state.selectedInvoice}
              community={this.props.community}
              communityId={this.props.communityId}
              store={store}
           />
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

ListReceivable.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

//   const mapDispatchToProps = (dispatch) => ({
//     hideModal: () => dispatch(hideModal()),
//     showModal: (modalProps, modalType) => {
//       dispatch(showModal({ modalProps, modalType }));
//     },
//   });

export default withRouter(connect(mapStateToProps, null)(ListReceivable));
