import React, { Component } from "react";

import axios from "axios";

import { sortArrayOfObjects } from "../../../utils/sortArrayOfObjects";

import "./GoodsInventory.css";

import InventoryDetails from "./InventoryDetails";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getInventory = "/routes/reports/getInventory?";
const getCategories = "/routes/dashboard/getCatagories?";

class GoodsInventory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearchPanel: true,
      showInventoryList: false,
      showInventoryDetails: false,
      showCategoryFlag: false,

      dateFirst: new Date(),
      dateSecond: new Date().setDate(new Date().getDate() + 1),

      filterType: "all",
      inventoryAt: "catalog",
      itemName: "",

      selectedItem: null,

      catOpsType: "",
      itemCategory: "",
      categoryName: '',
      itemId: 0,

      errMsg: "",
      errFlag: false,
      inventoryList: [],
      categories: [],
    };
  }

  exitReport = () => {
    this.props.returnToCaller();
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  getFilerItems = async () => {
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&coopStoreId=" +
      coopStoreId +
      "&filterType=" +
      this.state.filterType +
      "&inventoryAt=" +
      this.state.inventoryAt +
      "&itemName=" +
      this.state.itemName +
      "&itemCategory=" +
      this.state.categoryName;

    let url = baandaServer + getInventory + params;
    // console.log("url:", url);
    try {
      let inv = await axios.get(url);
      // console.log("inv:", inv);
      if (inv.data.status === "success") {
        this.setState({
          inventoryList: inv.data.Msg,
        });
      } else {
        this.setState({
          errMsg: inv.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      console.log("getFilerItems err:", err.messsage);
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  getCategories = async () => {
    // console.log("getCategories ...........");
    let coopStoreId = 0;
    if (this.props.store) coopStoreId = this.props.store.coopStoreId;
    let params =
      "communityId=" +
      this.props.communityId +
      "&categoryOf=item&invClassification=catalog" +
      "&coopStoreId=" +
      coopStoreId +
      "&itemType=goods&categoryName=";

    let url = baandaServer + getCategories + params;
    // console.log("********** getCategoris url:", url);
    try {
      let catret = await axios.get(url);
      // console.log("catret:", catret);
      if (catret.data.status === "success") {
        if (catret.data.Msg.length > 0) {
          let sorted = await sortArrayOfObjects(
            catret.data.Msg,
            "label",
            "dsc"
          );
          // console.log("sorted:", sorted);
          this.setState({
            categories: sorted,
            compMsg: "",
            compMsgFlag: false,
          });
        } else {
          this.setState({
            compMsg: `Add ${this.state.invClassification} category first to select.`,
            compMsgFlag: true,
            updateMsg: "",
            categories: [],
          });
        }
      } else {
        this.setState({
          compMsg: catret.data.Msg,
          compMsgFlag: true,
        });
      }
    } catch (err) {
      console.log("Error:", err.message);
      this.setState({
        invOrgErrMsg: err.message,
        invOrgErrFlag: true,
      });
    }
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handleFetchInventory = async () => {
    await this.getFilerItems();
    this.setState({
      showInventoryDetails: false,
      showInventoryList: true,
      showSearchPanel: false,
    });
  };

  handleDateFirst = async (date) => {
    let result = new Date(date);
    result.setDate(result.getDate() + 1);
    this.setState({
      dateFirst: date,
      dateSecond: result,
    });
  };

  handleDateSecond = async (date) => {
    this.setState({
      dateSecond: date,
    });
  };

  handleInventoryAt = async (e) => {
    this.setState({
      inventoryAt: e.target.value,
    });
  };

  handleInvoiceFilterType = async (e) => {
    // console.log("e.target.value:", e.target.value);
    if (e.target.value === "category") {
      await this.handleCategory();
    }
    this.setState({
      filterType: e.target.value,
      categoryName: ''
    });
  };

  handleSearchInventories = async () => {
    this.setState({
      showInventoryDetails: false,
      showInventoryList: false,
      showSearchPanel: true,
      inventoryList: [],
      categoryName: '',
      filterType: "all",
    });
  };

  selectThisInvoiceToShow = async (obj) => {
    // console.log("obj:", obj);
    this.setState({
      itemId: obj.itemId,
      showInventoryDetails: true,
      showInventoryList: false,
      showSearchPanel: false,
    });
  };

  returnFromInvDetails = async () => {
    this.setState({
      itemId: 0,
      showInventoryDetails: false,
      showInventoryList: true,
      showSearchPanel: false,
    });
  };

  handleCategory = async () => {
    await this.getCategories();
    this.setState({
      showCategoryFlag: true,
      catOpsType: "select",
    });
  };

  exitCategory = () => {
    this.setState({
      showCategoryFlag: false,
      filterType: "all",
    });
  };

  handleCatOpsType = async () => {
    await this.getCategories();
    this.setState({
      catOpsType: "select",
    });
  };

  selectThisCategory = async (obj) => {
    // console.log("obj:", obj);
    this.setState({
      categoryName: obj.label,
      catOpsType: "",
      showCategoryFlag: false,
    });
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log("GoodsInventory...");

    let exitButton = (
      <button
        onClick={this.exitReport}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_back_main"
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let fetchButton = (
      <button
        onClick={this.handleFetchInventory}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_60"
      >
        Fetch
      </button>
    );

    let searchButton = (
      <button
        onClick={this.handleSearchInventories}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
        className="btn_reg_70"
      >
        Search
      </button>
    );

    let inventoryAtPanel;
    inventoryAtPanel = (
      <div className="row name-panel-row">
        <div className="col text-center radio-font-style">
          <strong>Inventory at&nbsp;&nbsp;</strong>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="warehouse"
                checked={this.state.inventoryAt === "warehouse"}
                onChange={this.handleInventoryAt}
              />{" "}
              Warehouse
            </label>
          </div>
          <div className="form-check form-check-inline">
            <label className="form-check-label">
              <input
                className="form-check-input"
                type="radio"
                value="catalog"
                checked={this.state.inventoryAt === "catalog"}
                onChange={this.handleInventoryAt}
              />{" "}
              Catalog
            </label>
          </div>
        </div>
      </div>
    );

    let inventorySearchByPanel;
    inventorySearchByPanel = (
      <div>
        <div className="row name-panel-row">
          <div className="col text-center radio-font-style">
            <strong>Filter By&nbsp;&nbsp;</strong>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="all"
                  checked={this.state.filterType === "all"}
                  onChange={this.handleInvoiceFilterType}
                />{" "}
                All
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="category"
                  checked={this.state.filterType === "category"}
                  onChange={this.handleInvoiceFilterType}
                />{" "}
                Category
              </label>
            </div>
            <div className="form-check form-check-inline">
              <label className="form-check-label">
                <input
                  className="form-check-input"
                  type="radio"
                  value="itemname"
                  checked={this.state.filterType === "itemname"}
                  onChange={this.handleInvoiceFilterType}
                />{" "}
                Item Name
              </label>
            </div>
          </div>
        </div>
        <div className="text-center rep-inv-cat-selected">
          {this.state.categoryName === ""
            ? null
            : "Selected category: " + this.state.categoryName}
        </div>
      </div>
    );

    let nameSearchPanel;
    if (this.state.filterType === "itemname") {
      nameSearchPanel = (
        <div>
          <div className="row inv-name-search-row">
            <div className="col-4 text-right invoice-report-lbl">Item Name</div>
            <div className="col-8 text-left">
              <input
                name="itemName"
                type="text"
                value={this.state.itemName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="item-name-text"
                placeholder="Enter part of full item name"
              />
            </div>
          </div>
        </div>
      );
    }

    let catSelectPanel;
    if (this.state.catOpsType === "select") {
      if (this.state.categories.length > 0) {
        let bool = true;
        catSelectPanel = (
          <div className="fixedsize-sel-cat-box">
            {this.state.categories.map((obj, i) => {
              return (
                <div key={i}>
                  <div
                    className={
                      bool ? "row row-col-schdule-dr" : "row row-col-schdule-lt"
                    }
                  >
                    <div className="col-9 text-left item-category-label">
                      {obj.label}
                    </div>
                    <div className="col-3 text-center">
                      <button
                        className="btn_reg_40"
                        type="button"
                        onClick={() => this.selectThisCategory(obj)}
                        style={{
                          cursor: this.state.disabled ? "default" : "pointer",
                        }}
                      >
                        <i className="fa fa-check" />
                      </button>
                    </div>
                  </div>
                  {(bool = !bool)}
                </div>
              );
            })}
          </div>
        );
      }
    }

    let categoryPanel;
    if (this.state.showCategoryFlag) {
      categoryPanel = (
        <div className="comp-item-cat-box">
          <div className="item-cat-header">
            Select Item's Category&nbsp;&nbsp;
            <button
              className="btn_back_main"
              type="button"
              onClick={this.exitCategory}
              style={{ cursor: this.state.disabled ? "default" : "pointer" }}
            >
              <i className="fas fa-step-backward" />
            </button>
          </div>
         
          {catSelectPanel}

        </div>
      );
    }

    let showInventoryList;
    let bool = true;
    if (this.state.showInventoryList) {
      if (this.state.inventoryList.length > 0) {
        showInventoryList = (
          <div>
            <div className="row sub-item-list-row">
              <div className="col-1 text-center sub-item-list-head">Ops</div>
              <div className="col-8 text-left sub-item-list-head">
                Item Name &nbsp;&nbsp;&nbsp;[In {this.state.inventoryAt}]
              </div>
              <div className="col-3 text-left sub-item-list-head">Category</div>
            </div>
            <div className="fixedsize-invoice-list-rep">
              {this.state.inventoryList.map((obj, i) => {
                return (
                  <div key={i}>
                    <div
                      className={
                        bool
                          ? "row row-col-schdule-dr"
                          : "row row-col-schdule-lt"
                      }
                    >
                      <div className="col-1 text-center">
                        <button
                          className="btn-invoice-detail-open"
                          type="button"
                          onClick={() => this.selectThisInvoiceToShow(obj)}
                          style={{
                            cursor: this.state.disabled ? "default" : "pointer",
                          }}
                        >
                          ...
                        </button>
                      </div>
                      <div className="col-8 text-left sub-item-list-head">
                        {obj.itemName}
                      </div>
                      { this.state.inventoryAt === "catalog" ? (
                        <div className="col-3 text-left sub-item-list-head">
                        {obj.itemCategory}
                      </div>
                      ): (
                        <div className="col-3 text-left sub-item-list-head">
                        {obj.category}
                      </div>
                      )}

                      
                    </div>
                    {(bool = !bool)}
                  </div>
                );
              })}
            </div>
            <div
              className={
                this.state.errFlag
                  ? "text-center sub-item-msg-err"
                  : "text-center sub-item-msg"
              }
            >
              {this.state.errMsg}
            </div>
          </div>
        );
      } else {
        showInventoryList = (
          <div className="fixedsize-invoice-list-rep">
            No invoice found with your search condition.
          </div>
        );
      }
    }

    let searchPanel;
    if (this.state.showSearchPanel) {
      searchPanel = (
        <div className="inventory-search-box">
          <div className="text-center invoice-report-header">
            Search Inventory&nbsp;{fetchButton}
          </div>
          {inventoryAtPanel}
          {inventorySearchByPanel}
          {categoryPanel}
          {nameSearchPanel}
        </div>
      );
    }

    let outputPanel;
    if (this.state.showInventoryDetails) {
      outputPanel = (
        <div>
          <InventoryDetails
            returnToCaller={this.returnFromInvDetails}
            communityId={this.props.communityId}
            itemId={this.state.itemId}
            inventoryAt={this.state.inventoryAt}
          />
        </div>
      );
    } else {
      outputPanel = (
        <div>
          <div className="text-center invoice-report-header">
            Inventory Reports {exitButton}{" "}
            {this.state.showSearchPanel ? null : searchButton}
          </div>
          {searchPanel}
          {showInventoryList}
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

export default GoodsInventory;
