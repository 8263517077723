import React, { Component } from "react";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import axios from "axios";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./ManualInvRecvPayment.css";

import ReceiveMoneyViaCC from "../../../account/finance/communities/ReceiveMoneyViaCC";

const baandaServer = process.env.REACT_APP_BAANDA_SERVER;
const getBaandaServicePrice = "/routes/architecture/getBaandaServicePrice?";
const getUserByBaandaId = "/routes/users/getUserByBaandaId?";
const handleInvoicePayment = "/routes/finance/handleInvoicePayment";

const transactionFeeServiceName =
  process.env.REACT_APP_FINANCIAL_TRANSACTION_FEE;

const Checkbox = (props) => <input type="checkbox" {...props} />;

class ManualInvRecvPayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deviceSize: "",

      paymentMethod: "creditcard",

      payHandler: "buyer",
      payPending: 0.0,
      payAmount: 0.0,
      nextPayBy: new Date(),
      paymentAllowed: true,
      cashReceivedFlag: false,

      baandaFeeType: "",
      checkPayTo: "",
      checkNo: "",
      checkBankName: "",
      checkAccountNo: "",
      checkRoutingNo: "",
      customerCell: "",
      toPayAmount: 0,

      errMsg: "",
      errFlag: false,

      payBySinglePayCCFlag: false,
      processPaymentFlag: true,
      showSuccessFlag: false,

      invoiceData: null,
    };
  }

  componentDidMount = async () => {
    let deviceSize;
    if (window.screen.width < 500) deviceSize = "small";
    else deviceSize = "big";

    let payHandler = "buyer";
    if (this.props.caller === "seller") payHandler = "seller";

    let payPending =
      this.props.theInvoice.financeBreakdown.totalInvoiceAmount -
      this.props.theInvoice.financeBreakdown.amountPaid;

    let checkPayTo = this.props.community.checkPayToName;
    if (this.props.store) checkPayTo = this.props.store.checkPayToName;

    this.setState({
      checkPayTo,
      deviceSize,
      payHandler,
      payPending,
      payAmount: payPending,
    });
  };

  returnToInvoice = (ops) => {
    if (this.state.showSuccessFlag) {
      this.props.returnToCaller("success");
    } else this.props.returnToCaller(ops);
  };

  commaFormattedCurrency = (number) => {
    let res = number.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return res;
  };

  handlePaymentMethod = async (e) => {
    let value = e.target.value;
    let paymentAllowed = true;

    if (value === "cash" || value === "check") {
      paymentAllowed = false;
    }
    await this.setState({
      paymentMethod: e.target.value,
      paymentAllowed,
    });
  };

  onChange = async (e) => {
    e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
  };

  onChangePayAmount = async (e) => {
    let value = e.target.value;
    if (value > this.state.payPending) {
      this.setState({
        errMsg:
          "Trying to pay $" +
          this.commaFormattedCurrency(parseFloat(value)) +
          ". You cannot exceed $" +
          this.commaFormattedCurrency(this.state.payPending),
        errFlag: true,
      });
    } else {
      if (this.countDecimals(value) > 2) {
        let tmp = parseFloat(value).toFixed(2);
        let val = parseFloat(tmp);
        await this.setState({
          [e.target.name]: val,
          doneMsg: "",
          errMsg: "",
          errFlag: false,
        });
      } else {
        await this.setState({
          [e.target.name]: value,
          doneMsg: "",
          errMsg: "",
          errFlag: false,
        });
      }
    }
  };

  // Return the number of decimal places
  countDecimals = (value) => {
    if (Math.floor(value) === value) return 0;
    let yy = value.toString().split(".");
    if (yy[1] === "undefined" || !yy[1] || yy[1] === null) {
      return 0;
    } else {
      return yy[1].length;
    }
  };

  handleBeginDate = async (date) => {
    await this.setState({
      begindate: date,
    });
  };

  handleCashReceived = async () => {
    await this.setState((prevstate) => ({
      cashReceivedFlag: !prevstate.cashReceivedFlag,
    }));
  };

  getCustomerCell = async () => {
    let param = "baandaId=" + this.props.theInvoice.invoiceOfId;
    let url = baandaServer + getUserByBaandaId + param;
    // console.log(" url:", url);
    try {
      let useret = await axios.get(url);
      if (useret.data.status.toLowerCase() === "success") {
        let cell = "";
        if (useret.data.Msg[0].profileInfo.cell)
          cell = useret.data.Msg[0].profileInfo.cell.number;
        this.setState({
          customerCell: cell,
          errMsg: "",
          errFlag: false,
        });
      } else {
        this.setState({
          errMsg: useret.data.Msg,
          errFlag: true,
        });
      }
    } catch (err) {
      this.setState({
        errMsg: err.message,
        errFlag: true,
      });
    }
  };

  getBaandaServiceFeePercent = async () => {
    // let feePercent = 0;
    let status = "success";
    let errMsg = "";
    let errFlag = false;
    let msg = null;
    let param = "serviceName=" + transactionFeeServiceName;
    let url = baandaServer + getBaandaServicePrice + param;
    try {
      let prret = await axios.get(url);
      // console.log("prret.data:", prret.data);
      if (prret.data.status === "success") {
        msg = prret.data.Msg;
        errMsg = "";
        errFlag = false;
      } else {
        errMsg = prret.data.Msg;
        errFlag = true;
      }
    } catch (err) {
      errMsg = err.message;
      errFlag = true;
    }

    await this.setState({
      errMsg,
      errFlag,
    });

    return { status, Msg: msg };
  };

  validateBeforeContinue = async () => {
    let isvalid = "success";
    let msg = "";
    if (this.props.caller === "seller") {
      if (this.state.payAmount <= 0) {
        msg = "Pay amount must be positive";
        isvalid = "error";
      }
    }

    return { status: isvalid, Msg: msg };
  };

  packageDataForDB = async () => {
    let sfret = await this.getBaandaServiceFeePercent();

    // console.log("sfret:", sfret);
    let baandaFeeType = "";
    let baandaFeePercent = 0;
    let baandaFeeMinimum = 0;
    let serviceShortDescription = "";
    // let sfretObj = null;
    if (sfret.status === "success") {
      baandaFeeType = sfret.Msg.chargeType;
      baandaFeePercent = sfret.Msg.creditsCharged;
      baandaFeeMinimum = sfret.Msg.minimumAmt;
      serviceShortDescription = sfret.Msg.shortDescription;
      // sfretObj = sfret.Msg;
    }

    let checkInfo = null;
    if (this.state.paymentMethod === "check") {
      // See that this is an input...
      checkInfo = {
        checkNo: this.state.checkNo,
        checkDate: new Date(),
        paidTo: this.state.checkPayTo,
        checkBankName: this.state.checkBankName,
        accountNo: this.state.checkAccountNo,
        routingNo: this.state.checkRoutingNo,
        checkImage: null,
      };
    }

    let customerCell = "";
    if (this.props.caller === "seller") {
      await this.getCustomerCell();
      customerCell = this.state.customerCell;
    } else {
      if (this.props.auth.user.cell)
        customerCell = this.props.auth.user.cell.number;
    }

    let coopStoreId = 0;
    let displayStoreName = "";
    let displayStoreKeeper = "";
    if (this.props.store) {
      coopStoreId = this.props.store.coopStoreId;
      displayStoreName = this.props.store.displayStoreName;
      displayStoreKeeper = this.props.store.displayStoreKeeper;
    }

    let input = {
      communityId: this.props.communityId,
      coopStoreId,
      displayStoreName,
      displayStoreKeeper,
      finMMDD: this.props.community.finMMDD,
      finYYYY: this.props.community.finYYYY,
      communityName: this.props.community.commName,
      stripeAccId: this.props.community.stripe.id,
      BaandaServiceFeeType: baandaFeeType,
      baandaServiceFeePercent: baandaFeePercent,
      baandaServiceFeeMinimum: baandaFeeMinimum,
      serviceShortDescription,
      paidAmount: parseFloat(this.state.payAmount),
      nextPaymentDate: this.state.nextPayBy,
      theInvoice: this.props.theInvoice,
      paymentMethod: this.state.paymentMethod,
      checkInfo,
      customerCell, // get the customer cell number
      servicePriceObj: sfret.Msg,
      paymentFor: "manualinvoice",
      StripePaymentId: "",
      updated_by_bid: this.props.auth.user.baandaId,
    };

    // console.log("input:", input);

    return input;
  };

  invoicePayUp = async () => {
    let isval = await this.validateBeforeContinue();
    if (isval.status === "success") {
      let input = await this.packageDataForDB();
      // console.log("input:", input);
      // console.log("this.state.paymentMethod:", this.state.paymentMethod);
      if (this.state.paymentMethod === "creditcard") {
        let coopStoreId = 0;
        if (this.props.store) coopStoreId = this.props.store.coopStoreId;
        input.coopStoreId = coopStoreId;
        this.setState({
          payBySinglePayCCFlag: true,
          processPaymentFlag: false,
          invoiceData: input,
          errMsg: "",
          errFlag: false,
        });
      } else {
        // console.log("in non creditcard mode ... ");
        await this.handleCashPayment(input);
        // console.log("cashret:", cashret);
      }
    } else {
      this.setState({
        payBySinglePayCCFlag: false,
        processPaymentFlag: true,
        invoiceDate: null,
        errMsg: isval.Msg,
        errFlag: true,
      });
    }
  };

  handleCashPayment = async (input) => {
    // console.log("handleCashPayment input:", input);
    try {
      let url = baandaServer + handleInvoicePayment;
      let updtret = await axios.post(url, input);
      // console.log("updtret.data:", updtret.data);
      if (updtret.data.status === "success") {
        // console.log("updtret.status inside ...");
        this.setState({
          errMsg: "Payment Received",
          errFlag: false,
          processPaymentFlag: false,
          payBySinglePayCCFlag: false,
          showSuccessFlag: true,
        });
      } else {
        this.setState({
          errMsg: updtret.data.Msg,
          errFlag: true,
        });
      }
      return { status: "success", Msg: "" };
    } catch (err) {
      return { status: "error", Msg: err.message };
    }
  };

  handleExit = async (output) => {
    if (output !== "cancel") {
      this.props.returnToCaller("totalout");
    } else {
      await this.setState({
        payBySinglePayCCFlag: false,
        processPaymentFlag: true,
      });
    }
  };

  render() {
    // console.log("this.props:", this.props);
    // console.log("this.state:", this.state);
    console.log('ManualInvRecvPayment...');

    let tpt = this.props.theInvoice;

    let exitinvoiceButton = (
      <button
        className="btn_back_main"
        type="button"
        onClick={() => this.returnToInvoice("cancel")}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        <i className="fas fa-step-backward" />
      </button>
    );

    let continueButton;
    continueButton = (
      <button
        className="btn_reg_90_continue manual-pay-continue-btn-pos"
        type="button"
        onClick={this.invoicePayUp}
        style={{ cursor: this.state.disabled ? "default" : "pointer" }}
      >
        Continue
      </button>
    );

    let creditcard, crypto, paywith;
    if (this.state.deviceSize === "small") {
      creditcard = "CC";
      crypto = "Crypto (FR)";
      paywith = "Use";
    } else {
      creditcard = "Credit Card";
      crypto = "Crypto Currency (FR)";
      paywith = "Pay using";
    }

    let paymentMethodPanel;
    if (this.state.payHandler === "seller") {
      paymentMethodPanel = (
        <div>
          <div className="row inv-pay-method-selection">
            <div className="col text-center radio-font-style">
              <strong>{paywith}&nbsp;&nbsp;</strong>
              {this.props.caller === "seller" ? (
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="cash"
                      checked={this.state.paymentMethod === "cash"}
                      onChange={this.handlePaymentMethod}
                    />{" "}
                    Cash
                  </label>
                </div>
              ) : null}
              {this.props.caller === "seller" ? (
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="check"
                      checked={this.state.paymentMethod === "check"}
                      onChange={this.handlePaymentMethod}
                    />{" "}
                    Check
                  </label>
                </div>
              ) : null}
              {this.props.community.stripe.id === null ||
              this.props.community.stripe.id === "" ? null : (
                <div className="form-check form-check-inline">
                  <label className="form-check-label">
                    <input
                      className="form-check-input"
                      type="radio"
                      value="creditcard"
                      checked={this.state.paymentMethod === "creditcard"}
                      onChange={this.handlePaymentMethod}
                    />{" "}
                    {creditcard}
                  </label>
                </div>
              )}

              <div className="form-check form-check-inline">
                <label className="form-check-label">
                  <input
                    className="form-check-input"
                    type="radio"
                    value="crypto"
                    checked={this.state.paymentMethod === "crypto"}
                    onChange={this.handlePaymentMethod}
                    disabled
                  />{" "}
                  {crypto}
                </label>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      paymentMethodPanel = (
        <div className="text-center man-inp-pay-method">Pay via Creditcard</div>
      );
    }

    let cashCheckPanel;
    let showContinueButton = false;
    if (
      this.state.paymentMethod === "cash" ||
      this.state.paymentMethod === "check"
    ) {
      cashCheckPanel = (
        <div className="text-center rec-cash-check-payment">
          <Checkbox
            checked={this.state.cashReceivedFlag}
            onChange={this.handleCashReceived}
          />{" "}
          Cash / Check Received
        </div>
      );
      if (this.state.cashReceivedFlag) showContinueButton = true;
    } else {
      showContinueButton = true;
    }

    let checkPaybleToPanel;
    if (this.state.paymentMethod === "check") {
      checkPaybleToPanel = (
        <div>
          <div className="row manual-pay-row">
            <div className="col-4 text-right manual-pay-lbl-chk">
              Check Pay to
            </div>
            <div className="col-8 text-left manual-pay-txt">
              <input
                name="checkPayTo"
                type="text"
                value={this.state.checkPayTo}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="inv-manual-check-to"
                placeholder=""
              />
            </div>
          </div>
          <div className="row manual-pay-row">
            <div className="col-4 text-right manual-pay-lbl-chk">
              Check Number
            </div>
            <div className="col-8 text-left manual-pay-txt">
              <input
                name="checkNo"
                type="text"
                value={this.state.checkNo}
                onChange={this.onChange}
                size="10"
                maxLength="10"
                className="inv-manual-check-no"
                placeholder=""
              />
            </div>
          </div>
          <div className="row manual-pay-row">
            <div className="col-4 text-right manual-pay-lbl-chk">Bank Name</div>
            <div className="col-8 text-left manual-pay-txt">
              <input
                name="checkBankName"
                type="text"
                value={this.state.checkBankName}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="inv-manual-bank-name"
                placeholder=""
              />
            </div>
          </div>
          <div className="row manual-pay-row">
            <div className="col-4 text-right manual-pay-lbl-chk">
              Account No
            </div>
            <div className="col-8 text-left manual-pay-txt">
              <input
                name="checkAccountNo"
                type="text"
                value={this.state.checkAccountNo}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="inv-manual-bank-acno"
                placeholder=""
              />
            </div>
          </div>
          <div className="row manual-pay-row">
            <div className="col-4 text-right manual-pay-lbl-chk">
              Routing No
            </div>
            <div className="col-8 text-left manual-pay-txt">
              <input
                name="checkRoutingNo"
                type="text"
                value={this.state.checkRoutingNo}
                onChange={this.onChange}
                size="50"
                maxLength="50"
                className="inv-manual-bank-acno"
                placeholder=""
              />
            </div>
          </div>
        </div>
      );
    }

    let dueDate = "";
    if (tpt.manualInvoiceParams.dueDate)
      dueDate = tpt.manualInvoiceParams.dueDate.substring(0, 10);
    if (tpt.paySchedulePolicy && tpt.paySchedulePolicy.nextSchedulePayday)
      dueDate = tpt.paySchedulePolicy.nextSchedulePayday.substring(0, 10);

    let paymentHeadPanel;
    paymentHeadPanel = (
      <div>
        <div className="row manual-pay-row">
          <div className="col-4 text-right manual-pay-lbl">Invoice Id</div>
          <div className="col-8 text-left manual-pay-txt">{tpt.invoiceId}</div>
        </div>
        <div className="row manual-pay-row">
          <div className="col-4 text-right manual-pay-lbl">Invoice of</div>
          <div className="col-8 text-left manual-pay-txt">
            {tpt.customerName}
          </div>
        </div>
        <div className="row manual-pay-row">
          <div className="col-4 text-right manual-pay-lbl">Email</div>
          <div className="col-8 text-left manual-pay-txt">
            {tpt.customerEmail}
          </div>
        </div>
        <div className="row manual-pay-row">
          <div className="col-4 text-right manual-pay-lbl">To Pay</div>
          <div className="col-8 text-left manual-pay-txt">
            ${" "}
            {this.commaFormattedCurrency(
              tpt.financeBreakdown.totalInvoiceAmount -
                tpt.financeBreakdown.amountPaid
            )}
          </div>
        </div>

        <div className="row manual-pay-row">
          <div className="col-4 text-right manual-pay-lbl">Pay By</div>
          <div className="col-8 text-left manual-pay-txt">{dueDate}</div>
        </div>
      </div>
    );

    let payInfoPanel;
    payInfoPanel = (
      <div>
        <div className="row manual-pay-row">
          <div className="col-4 text-right manual-pay-lbl">Pay Amount</div>
          <div className="col-4 text-left">
            <input
              name="payAmount"
              type="number"
              value={this.state.payAmount}
              onChange={this.onChangePayAmount}
              className="manual-pay-amt-field"
              step=".01"
              placeholder="0.00"
              autoComplete="off"
              spellCheck="false"
            />
          </div>
          {tpt.financeBreakdown.totalInvoiceAmount -
            tpt.financeBreakdown.amountPaid -
            this.state.payAmount >
          0 ? (
            <div className="col-4 text-left manual-pay-lbl-left">
              Pend: ${" "}
              {this.commaFormattedCurrency(
                tpt.financeBreakdown.totalInvoiceAmount -
                  tpt.financeBreakdown.amountPaid -
                  this.state.payAmount
              )}
            </div>
          ) : (
            <div className="col-4 text-left manual-pay-lbl">Paying in full</div>
          )}
        </div>
        {this.state.payAmount < this.state.payPending ? (
          <div className="row manual-pay-row">
            <div className="col-4 text-right manual-pay-lbl">Next pay By</div>
            <div className="col-8 text-left">
              <DatePicker
                selected={this.state.nextPayBy}
                onSelect={this.handleBeginDate}
                onChange={this.handleBeginDate}
                minDate={new Date()}
                name="nextPayBy"
                dateFormat="MM/dd/yyyy"
              />
            </div>
          </div>
        ) : null}
      </div>
    );

    let totalInvoicePanel;
    if (this.state.invoiceData) {
      totalInvoicePanel = (
        <div className="text-center to-pay-amount">
          Total Invoice Amount: $
          {this.commaFormattedCurrency(this.state.invoiceData.paidAmount)}
          <div className="text-center cc-show-customer-name">
            Payer: {this.props.theInvoice.customerName}
          </div>
        </div>
      );
    }

    let outputPanel;
    if (this.state.processPaymentFlag) {
      outputPanel = (
        <div className="manual-payment-box">
          <div className="text-center manual-inv-header">
            Invoice Payment &nbsp;{exitinvoiceButton}
          </div>
          {paymentHeadPanel}
          {this.props.caller === "seller" ? payInfoPanel : null}
          <div className="manual-pay-row">{paymentMethodPanel}</div>
          {checkPaybleToPanel}
          <div className="row">
            <div className="col-6 text-right confirm-check-cash-cust">
              {cashCheckPanel}
            </div>
            <div className="col-6 text-left confirm-check-cash-cust">
              {showContinueButton ? continueButton : null}
              {/* <div className="text-left inv-recv-fr-legend">FR: Future Release</div> */}
            </div>
          </div>
          <div
            className={
              this.state.errFlag
                ? "text-center man-inv-payment-msg-err"
                : "text-center man-inv-payment-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    if (this.state.payBySinglePayCCFlag) {
      outputPanel = (
        <div>
          {totalInvoicePanel}
          <ReceiveMoneyViaCC
            inputData={this.state.invoiceData}
            deviceSize={this.state.deviceSize}
            paymentAmount={parseFloat(this.state.payAmount)}
            stripeaccId={this.props.community.stripe.id}
            handleExit={(msg) => this.handleExit(msg)}
          />
        </div>
      );
    }

    if (this.state.showSuccessFlag) {
      outputPanel = (
        <div className="manual-payment-box">
          <div className="text-center manual-inv-header">
            Invoice Payment &nbsp;{exitinvoiceButton}
          </div>
          {paymentHeadPanel}

          <div
            className={
              this.state.errFlag
                ? "text-center man-inv-payment-msg-err"
                : "text-center man-inv-payment-msg"
            }
          >
            {this.state.errMsg}
          </div>
        </div>
      );
    }

    return <div>{outputPanel}</div>;
  }
}

ManualInvRecvPayment.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(withRouter(ManualInvRecvPayment));
